import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo, useState, memo} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {AppStore} from 'reducers';
import './crop-timeline.scss';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';
import {Portal} from 'components/fluro-dialog/portal';
import {Button} from 'react-md';
import {Field, Season} from 'containers/map/types';

// Width of the mapbar.
const defaultContainerWidth = 330;

export const CropTimeline = () => {
  const cropTypes = useSelector((s: AppStore) => s.global.cropTypes);
  const fieldsByFarmId = useSelector((s: AppStore) => s.map.fieldsByFarmId);
  const cropPerformance = useSelector((s: AppStore) => s.cropPerformance);
  const currentDate = useSelector((s: AppStore) => s.map.currentDate);
  const viewport = useSelector((s: AppStore) => s.viewport);
  const [fullscreen, setFullscreen] = useState(false);
  const [hoveredSeason, setHoveredSeason] = useState<number>(undefined);
  const [hoveredDate, setHoveredDate] = useState<string>(undefined);
  const {items, maxEndDate} = useMemo(() => {
    const fields = Object.keys(fieldsByFarmId)
      .filter(farmId => cropPerformance.farms[Number(farmId)])
      .flatMap(farmId => {
        const fields = Object.values(fieldsByFarmId[Number(farmId)]);
        return fields.map(field => {
          const dates =
            cropPerformance.farms[Number(farmId)]?.csgs?.[field.ID]?.seasons?.[field.SeasonID] // TODO CP is correct implementation of planting areas here
              ?.dates || {};
          return {
            field,
            images: Object.keys(dates).filter(d => dates[d].imagery),
          };
        });
      });
    const items = fields
      .filter(item => item.field.Seasons.length)
      .sort((a, b) => {
        const seasonA = a.field.Seasons[0];
        const seasonB = b.field.Seasons[0];
        return seasonA.startDate.localeCompare(seasonB.startDate);
      });
    let maxEndDate = '1970-01-01';
    items.forEach(item =>
      item.field.Seasons.forEach(s => {
        if (maxEndDate < s.endDate) {
          maxEndDate = s.endDate;
        }
      })
    );
    return {items, maxEndDate};
  }, [fieldsByFarmId, cropPerformance.farms]);

  if (!items.length) {
    return null;
  }

  const {min, dayWidth} = useMemo(() => {
    const min = moment(items[0].field.Seasons[0].startDate);
    const max = moment(maxEndDate);
    const range = max.diff(min, 'days');
    const width = fullscreen ? viewport.width : defaultContainerWidth;
    const dayWidth = width / range;
    return {min, dayWidth};
  }, [
    items[0].field.Seasons[0].startDate,
    maxEndDate,
    fullscreen,
    viewport.width,
    defaultContainerWidth,
  ]);

  const {date, currentDateStyle} = useMemo(() => {
    // currentDate can be undefined.
    const date = moment(currentDate, GLOBAL_APP_DATE_FORMAT);
    const currentDateStyle = currentDate ? {left: date.diff(min, 'days') * dayWidth} : {};
    return {date, currentDateStyle};
  }, [currentDate, dayWidth]);

  const Container = useMemo(
    () =>
      fullscreen
        ? ({children}: React.PropsWithChildren<{}>) => (
            <Portal id="dialog-root">
              <div className="crop-timeline-fullscreen">
                <div className="crop-timeline">
                  <div className="crop-timeline-controls">
                    <Button icon onClick={() => setFullscreen(false)}>
                      fullscreen_exit
                    </Button>
                  </div>
                  {children}
                </div>
              </div>
            </Portal>
          )
        : ({children}: React.PropsWithChildren<{}>) => (
            <div className="crop-timeline">
              <div className="crop-timeline-controls">
                <Button icon onClick={() => setFullscreen(true)}>
                  fullscreen
                </Button>
              </div>
              {children}
            </div>
          ),
    [fullscreen]
  );
  return (
    <Container>
      <div style={currentDateStyle} className="crop-timeline-current-date"></div>
      {items.map(item => (
        <CropTimelineRow
          key={item.field.ID}
          fullscreen={fullscreen}
          item={item}
          min={min}
          dayWidth={dayWidth}
          date={date}
          currentDate={currentDate}
          cropTypes={cropTypes}
          hoveredDate={hoveredDate}
          hoveredSeason={hoveredSeason}
          setHoveredDate={setHoveredDate}
          setHoveredSeason={setHoveredSeason}
        />
      ))}
    </Container>
  );
};

const CropTimelineRow = memo(
  ({
    fullscreen,
    item,
    min,
    dayWidth,
    date,
    currentDate,
    cropTypes,
    hoveredDate,
    hoveredSeason,
    setHoveredDate,
    setHoveredSeason,
  }: {
    fullscreen: boolean;
    item: {field: Field; images: string[]};
    dayWidth: number;
    min: moment.Moment;
    date: moment.Moment;
    currentDate: string;
    cropTypes: AppStore['global']['cropTypes'];
    hoveredDate?: string;
    hoveredSeason?: number;
    setHoveredDate: (date: string) => void;
    setHoveredSeason: (id: number) => void;
  }) => {
    return (
      <div className="crop-timeline-row">
        {item.field.Seasons.map(s => (
          <CropTimelineCell
            key={s.id}
            fullscreen={fullscreen}
            item={item}
            season={s}
            currentSeason={hoveredSeason === s.id}
            min={min}
            dayWidth={dayWidth}
            date={date}
            currentDate={currentDate}
            cropTypes={cropTypes}
            hoveredDate={hoveredSeason === s.id ? hoveredDate : undefined}
            setHoveredDate={setHoveredDate}
            setHoveredSeason={setHoveredSeason}
          />
        ))}
      </div>
    );
  }
);

const CropTimelineCell = memo(
  ({
    fullscreen,
    item,
    season,
    currentSeason,
    min,
    dayWidth,
    date,
    currentDate,
    cropTypes,
    hoveredDate,
    setHoveredDate,
    setHoveredSeason,
  }: {
    fullscreen: boolean;
    item: {field: Field; images: string[]};
    season: Season;
    currentSeason: boolean;
    dayWidth: number;
    min: moment.Moment;
    date: moment.Moment;
    currentDate: string;
    cropTypes: AppStore['global']['cropTypes'];
    hoveredDate?: string;
    setHoveredDate: (date: string) => void;
    setHoveredSeason: (id: number) => void;
  }) => {
    const a = moment(season.startDate);
    const b = moment(season.endDate);
    const offset = a.diff(min, 'days');
    const diff = b.diff(a, 'days');
    const width = diff * dayWidth;
    const left = offset * dayWidth;
    const color = cropTypes[season.cropType]?.color;
    const tooltipStyle = left > 150 ? {right: 0} : {};
    const age = !currentDate
      ? t({id: 'No current date'})
      : date.isBefore(a)
      ? t({id: 'Have not started yet'})
      : date.isAfter(b)
      ? t({id: 'Have finished already'})
      : t(
          {id: '{number1} / {number2} days passed'},
          {number1: date.diff(a, 'days'), number2: b.diff(a, 'days')}
        );
    return (
      <div
        key={season.id}
        style={{marginLeft: left, width, background: color}}
        className="crop-timeline-cell"
        onMouseOver={() => setHoveredSeason(season.id)}
        onMouseOut={() => setHoveredSeason(undefined)}
      >
        {fullscreen &&
          item.images.map(date => {
            const m = moment(date);
            const left = m.diff(a, 'days') * dayWidth;
            return (
              <div
                key={date}
                style={{left}}
                className="crop-timeline-cell-imagery"
                onMouseOver={() => setHoveredDate(date)}
                onMouseOut={() => setHoveredDate(undefined)}
              />
            );
          })}
        {currentSeason && (
          <div className="crop-timeline-cell-tooltip" style={tooltipStyle}>
            <div>
              {item.field.Name} (#{item.field.ID})
            </div>
            <div>
              {cropTypes[season.cropType]?.label || season.cropType} {season.params?.cropSubType}
            </div>
            <div>
              {moment(season.startDate).format('D MMM YYYY')} –{' '}
              {moment(season.endDate).format('D MMM YYYY')}
            </div>
            <div>{age}</div>
            {hoveredDate && (
              <div className="crop-timeline-cell-tooltip-imagery">
                Imagery: {moment(hoveredDate).format(GLOBAL_APP_DATE_FORMAT)}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
