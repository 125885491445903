import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {FluroDialog} from 'components';
import ImportSeasonsForm from '../map/features/farm/table-view/import-seasons';

const ImportSeasonsPopUp = ({onHide}: {onHide(): void}) => {
  return (
    <FluroDialog
      id={'import-seasons-pop-up'}
      className={'upload-seasons-pop-up'}
      title={'Import crops'}
      visible={true}
      onHide={onHide}
    >
      <ImportSeasonsForm />
    </FluroDialog>
  );
};

export default ImportSeasonsPopUp;
