import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {onRecordsUpdated} from '_reducers/crop-performance-filter/field-filter-reducer';

export const useUpdateFilter = () => {
  const dispatch = useDispatch();
  const cropPerformance = useSelector((s: AppStore) => s.cropPerformance);

  useEffect(() => {
    dispatch(onRecordsUpdated(cropPerformance.records));
  }, [cropPerformance.records]);
};
