import initialState from '../initial-state';
import {IAction, IInitialMapState} from '../../types';

import {ActionTypes} from '../types';
import {
  getDefaultNSettingValues,
  getNrxSeason,
  FertilizerApplication,
  NRxZone,
} from '../../utils/nrx-utils';

export default (state = initialState, action: IAction): Partial<IInitialMapState> => {
  switch (action.type) {
    case ActionTypes.MAP_NRX_SET_LISTS_DATA: {
      return {
        ...state,
        listsData: {
          ...state.listsData,
          ...action.lists,
        },
      };
    }

    case ActionTypes.MAP_NRX_UPDATE_POP_UP_SETTINGS: {
      const {nrxTabRate, nrxResult} = state.nRecommendation;
      const notCurrentTabRate = nrxTabRate === 'variable' ? 'blanket' : 'variable';
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxPopUpValues: {
            ...state.nRecommendation.nrxPopUpValues,
            ...action.value,
          },
          nrxResult: {
            ...nrxResult,
            [notCurrentTabRate]: {
              balanced: {},
              max_roi: {},
              max_yield: {},
            }, // reset not selected tab rate values, they will be requested once the tab is selected
          },
        },
      };
    }

    case ActionTypes.MAP_NRX_SET_RECOMMENDATION_RESULT: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...state.nRecommendation.nrxResult,
            [state.nRecommendation.nrxTabRate]: action.value,
          },
        },
      };
    }

    case ActionTypes.MAP_NRX_UPDATE_RECOMMENDATION_PROPS:
      const {nrxResult, nrxTabRate, selectedObjective} = state.nRecommendation;
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...nrxResult,
            [nrxTabRate]: {
              ...nrxResult[nrxTabRate],
              [selectedObjective]: {
                ...nrxResult[nrxTabRate][selectedObjective],
                features: nrxResult[nrxTabRate][selectedObjective].features.map((f: NRxZone) => {
                  if (f.properties.id === action.data.id) {
                    return {
                      ...f,
                      properties: {
                        ...f.properties,
                        ...action.data,
                      },
                    };
                  }
                  return f;
                }),
              },
            },
          },
        },
      };

    case ActionTypes.MAP_NRX_TOGGLE_SETTINGS_POP_UP: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          settingsPopUpOpen: action.value,
        },
      };
    }

    case ActionTypes.MAP_NRX_TOGGLE_RATE_TAB: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxTabRate: action.value,
        },
      };
    }
    case ActionTypes.MAP_NRX_SELECT_OBJECTIVE: {
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          selectedObjective: action.value,
        },
      };
    }

    case ActionTypes.MAP_NRX_LOAD_SEASONS_DATA: {
      return {
        ...state,
        fields: action.fields,
        nRecommendation: {
          ...state.nRecommendation,
          nrxPopUpValues: getDefaultNSettingValues(
            getNrxSeason(state.currentSeasonId, action.fields)
          ),
        },
      };
    }

    case ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA: {
      let fields = [...state.fields];
      const findSeason = (sId: any) =>
        Array.isArray(action.seasonID)
          ? action.seasonID.find(id => id === sId)
          : action.seasonID === sId;

      fields = fields.map(f => {
        if (Array.isArray(f.Seasons)) {
          f.Seasons = f.Seasons.map(s => {
            return findSeason(s.id) ? {...s, nrx: {...s.nrx, ...action.data}} : s;
          });
        }
        return f;
      });

      return {
        ...state,
        fields,
      };
    }

    case ActionTypes.MAP_NRX_FERTILIZER_APP_ADD: {
      return {
        ...state,
        fields: state.fields.map(f => {
          return f.ID === action.data.fieldID
            ? {
                ...f,
                fertilizerApplications: [...f.fertilizerApplications, action.data],
              }
            : f;
        }),
      };
    }

    case ActionTypes.MAP_NRX_FERTILIZER_APP_UPDATE: {
      return {
        ...state,
        fields: state.fields.map(f => {
          return f.ID === action.fieldId
            ? {
                ...f,
                fertilizerApplications: f.fertilizerApplications.map((a: FertilizerApplication) =>
                  a.id === action.data.id ? {...a, ...action.data} : a
                ),
              }
            : f;
        }),
      };
    }

    case ActionTypes.MAP_NRX_FERTILIZER_APP_REMOVE: {
      return {
        ...state,
        fields: state.fields.map(f => {
          return f.ID === action.fieldId
            ? {
                ...f,
                fertilizerApplications: f.fertilizerApplications.filter(
                  (a: FertilizerApplication) => a.id !== action.appId
                ),
              }
            : f;
        }),
      };
    }

    case ActionTypes.MAP_NRX_MERGE_ZONES: {
      const {nrxResult, nrxTabRate, selectedObjective} = state.nRecommendation;
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...nrxResult,
            [nrxTabRate]: {
              ...nrxResult[nrxTabRate],
              [selectedObjective]: {
                ...nrxResult[nrxTabRate][selectedObjective],
                features: action.zones,
              },
            },
          },
        },
      };
    }

    case ActionTypes.MAP_NRX_REVERT_MERGE_ZONES: {
      const {nrxResult, nrxTabRate, selectedObjective} = state.nRecommendation;
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          nrxResult: {
            ...nrxResult,
            [nrxTabRate]: {
              ...nrxResult[nrxTabRate],
              [selectedObjective]: {
                ...nrxResult[nrxTabRate][selectedObjective],
                features: action.zones,
              },
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
