import moment from 'moment';
import {SERVER_FORMAT_DATE} from '_constants';
import {CSGViewModel} from '../types';

/**
 * Populates missing dates between the start of the season and the first imagery
 * And between the last imagery and the end of the season
 * sowing date --- imagery1 --- harvest date
 *
 * Mutates dates object.
 */
export const populateMissingDates = (
  seasonStartDate: string,
  seasonEndDate: string,
  firstImageryDate: string,
  lastImageryDate: string,
  datesMutable: {[date: string]: CSGViewModel}
) => {
  const first = moment(firstImageryDate); // will be mutated later
  const last = moment(lastImageryDate); // will be mutated later
  const latestValue = datesMutable[last.format(SERVER_FORMAT_DATE)];

  // Data can be of 3 types:
  // (where "-" means no data and "+" means there is interpolated data)
  //
  // 1. sowing date --- no imageries ------------ harvest date
  // 2. sowing date --- imagery1 ---------------- harvest date
  // 3. sowing date --- imagery1 +++ imagery2 --- harvest date
  //
  // In case 1 the format is `data: null`,
  // all the other cases data is `data: {date: value}`.
  //
  // It means we need to fulfill all the "-" dates.
  // We fulfill dates before the first imagery with "no data" values,
  // and the dates after the last imagery with the last imagery value.

  let missingDaysCount = 0;

  // Fill the dates between the sowing date and the first imagery.
  // Use precise moment diff and ceil to count a day even it's only 2 hours in.
  missingDaysCount = Math.ceil(first.diff(moment(seasonStartDate), 'day', true));
  if (missingDaysCount > 0) {
    while (missingDaysCount--) {
      first.subtract(1, 'day');
      const date = first.format(SERVER_FORMAT_DATE);

      datesMutable[date] = {
        ...latestValue,
        date,
        imagery: false,
        growth: -10,
        cropStatus: 'No images',
        smoothSatelliteNdvi: 0,
      };
    }
  }

  // Fill the dates between the harvest date and the latest imagery
  // with the values of the latest imagery.
  // Use precise moment diff and ceil to count a day even it's, i.e only 2 hours in.
  missingDaysCount = Math.ceil(moment(seasonEndDate).diff(last, 'day', true));
  if (missingDaysCount > 0) {
    let daysFilled = 0;
    while (daysFilled < missingDaysCount) {
      daysFilled++;
      last.add(1, 'day');
      const date = last.format(SERVER_FORMAT_DATE);

      datesMutable[date] = {
        ...latestValue,
        date,
        imagery: false,
        // If the gap between the current date and the very latest imagery
        // is more than 10 days, treat the data as unreliable.
        reliable: daysFilled > 10 ? false : latestValue.reliable,
      };
    }
  }
  return datesMutable;
};
