import {FluroTabs, FluroTabsSkin} from 'components/fluro-tabs';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {setDiffMode} from './optis-reducer';

export const ModeToggle = () => {
  const dispatch = useDispatch();
  const optis = useSelector((s: AppStore) => s.optis);

  return (
    <FluroTabs
      skin={FluroTabsSkin.Toggle}
      onTabClick={(mode: Mode) => dispatch(setDiffMode(mode === Mode.ChangeMap))}
      selectedTab={optis.diffMode ? Mode.ChangeMap : Mode.DateRange}
      tabs={[
        {label: 'Date range', value: Mode.DateRange},
        {label: 'Change map', value: Mode.ChangeMap},
      ]}
    />
  );
};

enum Mode {
  DateRange = 'dateRange',
  ChangeMap = 'changeMap',
}
