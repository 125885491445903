import React, {memo, useCallback} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {showNote} from '_actions';
import {IInitialMapState} from 'containers/map/types';
import cn from 'classnames';
import styled from 'styled-components';

type Props = {
  children: any;
  isReadOnly?: boolean;
  message?: string;
  containerClass?: string;
  containerStyle?: any;
  fullWidth?: boolean;
};

const Container = styled.div`
  &.read-only-container {
    position: relative;
    cursor: pointer;
    width: ${({fullWidth}: Props) => (fullWidth ? '100%' : 'auto')};

    &::after {
      content: '';
      position: absolute;
      display: block;
      cursor: not-allowed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
`;

const ReadOnly = memo(
  ({containerClass, containerStyle, fullWidth, message, children, isReadOnly}: Props) => {
    const dispatch = useDispatch();

    const _isReadOnly = useSelector(
      ({map}: {map: IInitialMapState}) =>
        isReadOnly !== undefined ? isReadOnly : map.group.readOnly,
      shallowEqual
    );

    const handleClick = useCallback(() => {
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({
            id: 'readOnlyFarm',
            defaultMessage:
              'This farm is read-only, create your own farm to use this functionality.',
          }),
          level: 'warning',
        })
      );
    }, [message, _isReadOnly]);

    if (_isReadOnly) {
      const _children =
        children && typeof children === 'object' && children.props
          ? React.cloneElement(children, {disabled: true})
          : children;

      return (
        <Container
          className={cn('read-only-container', {[containerClass]: !!containerClass})}
          fullWidth={!!fullWidth}
          style={{
            ...(containerStyle || {}),
          }}
          onClick={handleClick}
        >
          {_children}
        </Container>
      );
    }

    return children;
  }
);
export default ReadOnly;
