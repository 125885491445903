export const TreesZoningMethods = [
  // FSB-2063
  {label: 'Equal Records', value: 'Records'},
  {label: 'Equal Interval', value: 'Intervals'},
  {label: 'Best performing', value: 'BestPerforming', percentage: true},
  {label: 'Worst performing', value: 'WorstPerforming', percentage: true},
];

export const ZoningClasses = [
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '5', value: '5'},
  {label: '6', value: '6'},
  {label: '7', value: '7'},
];

export const RegularZoningMethods = [
  {label: 'Best fit Jenks', value: 'jenks'},
  {label: 'StdDev', value: 'stddev'},
  {label: 'Equal Records', value: 'equalRecords'},
  {label: 'Equal Interval', value: 'equalInterval'},
  {label: 'Custom', value: 'custom'},
];

export const ZoningBuffers = [
  {label: 0, value: 0},
  {label: 25, value: 1},
  {label: 50, value: 2},
  {label: 75, value: 3},
];

export const ParamsToDelay = ['treeZoningPercentage', 'area'];
export const MinAreaValue = 0;
export const MaxAreaValue = 100;
