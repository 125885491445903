import {connect, ConnectedProps} from 'react-redux';
import {useEffect} from 'react';
import {setNitrogenScaleUrl, setNitrogenOverlayUrl} from './actions';
import axios from 'axios';
import {IInitialMapState} from './types';
import config from '_environment';

type TProps = ConnectedProps<typeof connector> & {
  baseSrc: string;
  getPixelHandler(val: Function): void;
};

const BinaryReader = ({
  baseSrc,
  pointsGroups,
  pointsCurrentGroupDate,
  setNitrogenScaleUrl,
  setNitrogenOverlayUrl,
  getPixelHandler,
  currentPointsGroup,
}: TProps): null => {
  const readFile = (byteArray: any) => {
    getPixelHandler((x: number, y: number, width: number) => {
      const offset = ((x | 0) + (y | 0) * width) * 4;

      try {
        const view = new DataView(byteArray, offset, 4);
        return view.getFloat32(0).toFixed(2);
      } catch (err) {
        console.log('[WARN] outside array index', err);
        return -1;
      }
    });
  };

  const initNitrogenImg = (baseSrc: string, imgType = 'nmap') => {
    const points = pointsGroups[pointsCurrentGroupDate]
      ? pointsGroups[pointsCurrentGroupDate]
          .filter(ts => !!ts.properties.n_result)
          .map(ts => {
            return {
              lat: ts.geometry.coordinates[0],
              lng: ts.geometry.coordinates[1],
              value: parseFloat(ts.properties.n_result) || 0,
            };
          })
      : [];

    const url = genUrl(baseSrc, imgType, points);

    axios
      .request({url, responseType: 'arraybuffer'})
      .then(({data: byteArray}) => (byteArray ? readFile(byteArray) : null));

    setNitrogenScaleUrl(genUrl(baseSrc, 'scale', points));
    setNitrogenOverlayUrl(genUrl(baseSrc, 'cmap', points));
  };

  const genUrl = (baseSrc = '', imgType = 'nmap', points: any[] = []) => {
    const src = baseSrc.replace(
      //@ts-ignore;
      `services/classification`,
      `nmap`
    );
    return `${src}?t=${imgType}&points=${encodeURIComponent(JSON.stringify(points))}`;
  };

  useEffect(() => {
    initNitrogenImg(baseSrc, 'bmap');
  }, [baseSrc, currentPointsGroup]);

  return null;
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  currentPointsGroup: map.pointsGroups[map.pointsCurrentGroupDate],
  pointsGroups: map.pointsGroups,
  pointsCurrentGroupDate: map.pointsCurrentGroupDate,
});

const connector = connect(mapStateToProps, {
  setNitrogenScaleUrl,
  setNitrogenOverlayUrl,
});
export default connector(BinaryReader);
