import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {getCropColorById, getCropLabelById, removeDuplicates} from '_utils';
import {Button, FontIcon, SelectField, SelectionControl, TextField} from 'react-md';
import {
  FluroAutocomplete,
  FluroButton,
  FluroDatePicker,
  FluroTablePagination,
  FluroTabs,
  FluroDataTable,
} from 'components';
import {Farm} from '../../../farm/types';
import {RequestStatus} from '../../../../types';
import CircularProgresslink from 'react-md/lib/Progress/CircularProgress';
import {
  getDatesInfoMessage,
  getFarmsWithFields,
  getSelectedFieldsInfoMessage,
} from '../order-planet-imagery-utils';
import {Field} from 'containers/map/types';
import {
  ExpandableArea,
  ExpandableRowContainer,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {t} from 'i18n-utils';
import cn from 'classnames';
import {FieldsOnMap} from './fields-on-map';
import {
  TimelineSeasonsChart,
  SeasonDataItem,
} from 'components/timeline-seasons-chart/timeline-seasons-chart';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {PlanetApi} from '_api';
import {PlanetOrder} from '../order-planet-imagery';
import {reportError} from '../../../error-boundary';
import {showNote} from '_actions';

enum SearchEntity {
  Subscription = 'subscription',
  Organization = 'organization',
  Farm = 'farm',
}

const mapEntityWithFarmProperty: {[searchEntity: string]: keyof Farm} = {
  [SearchEntity.Subscription]: 'subscriptionID',
  [SearchEntity.Organization]: 'organizationID',
  [SearchEntity.Farm]: 'id',
};

enum Sensor {
  Planet = 'planet-scope',
}

type EntityList = {[entityName: string]: {label: string; value: number}[]};

type FarmWithFields = {id: number; name: string; toggled?: boolean; fields: Field[]};

const EntitiesList: {label: string; value: SearchEntity}[] = [
  {label: 'Subscription', value: SearchEntity.Subscription},
  {label: 'Organization', value: SearchEntity.Organization},
  {label: 'Farm', value: SearchEntity.Farm},
];

const SensorsList: {label: string; value: string}[] = [
  {label: 'Planet Scope (3.7m/px)', value: 'planet-scope'},
];

/**
 Select entity to search by (Subscription, Organization or Farm) - @searchByEntity
 Then search entity and return its ID @selectedEntityId
 Set the start date and end date to order images for, the start date is limited to 1 Jan 2019, and to the current date
 Display fields grouped by farms with seasons timeline in the startDate - endDate range
 Display fields on the map
 */
type Props = {
  onBackToTableView: () => void;
  onAddNewOrder: (newOrder: PlanetOrder) => void;
};
const NewOrderView = ({onBackToTableView, onAddNewOrder}: Props) => {
  const farmsList = useSelector((store: AppStore) => store.farms.list);
  const currentDate = moment();
  const minDate = moment('01 Jan 2019 ');
  const dispatch = useDispatch();

  // inputs data
  const [selectedSensor, setSelectedSensor] = useState<Sensor>(Sensor.Planet);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(moment(currentDate).add(1, 'day'));
  const [selectedEntityId, setSelectedEntityId] = useState(0);
  const [orderNoteText, setOrderNoteText] = useState('');
  const [searchCustomerString, setSearchCustomerString] = useState('');

  // classified data
  const [farmsWithFields, setFarmsWithFields] = useState<FarmWithFields[]>([]);
  const [allFieldsList, setAllFieldsList] = useState<Field[]>([]);

  // internal state
  const [loadingStatus, setLoadingStatus] = useState<RequestStatus>(RequestStatus.Idle);
  const [cropTimelineVisible, toggleCropTimelineVisibility] = useState(false);
  const [searchByEntity, setSearchByEntity] = useState<SearchEntity>(SearchEntity.Subscription);
  const [expandedFarms, setExpandedFarms] = useState<number[]>([]);
  const [selectedFarms, setSelectedFarms] = useState<number[]>([]);
  const [selectedFields, setSelectedFields] = useState<number[]>([]);
  const [pagination, setPagination] = useState({start: 0, perPage: 10, page: 1});

  /**
   * Object with subscriptions, organizations, farms lists
   */
  const preparedEntitiesLists = useMemo(() => {
    const entitiesLists: EntityList = {
      subscription: [],
      organization: [],
      farm: [],
    };

    farmsList.forEach(farm => {
      farm.subscriptionID &&
        entitiesLists.subscription.push({label: farm.subscriptionName, value: farm.subscriptionID});
      farm.organizationID &&
        entitiesLists.organization.push({label: farm.organizationName, value: farm.organizationID});
      entitiesLists.farm.push({label: farm.name, value: farm.id});
    });

    entitiesLists.subscription = removeDuplicates(entitiesLists.subscription, 'value');
    entitiesLists.organization = removeDuplicates(entitiesLists.organization, 'value');

    return entitiesLists;
  }, [farmsList]);

  /**
   * Reset selected farms/fields once entity was changed
   */
  useEffect(() => {
    setSearchCustomerString('');
    // reset selected fields and farms
    toggleCropTimelineVisibility(false);
    setSelectedFarms([]);
    setSelectedFields([]);
  }, [searchByEntity]);

  /**
   * Fetch and classify farms data
   */
  useEffect(() => {
    async function prepareFarmsWithFields() {
      if (!selectedEntityId) return setFarmsWithFields([]);
      const farmProperty = mapEntityWithFarmProperty[searchByEntity];
      const filteredFarms = farmsList.filter(farm => farm[farmProperty] === selectedEntityId);
      setLoadingStatus(RequestStatus.Loading);
      const farmsWithFields: FarmWithFields[] = await getFarmsWithFields(filteredFarms);
      setLoadingStatus(RequestStatus.Success);

      let allFields: Field[] = [];
      farmsWithFields.forEach(farm => (allFields = [...allFields, ...farm.fields]));

      setFarmsWithFields(farmsWithFields);
      setAllFieldsList(allFields);
      setSelectedFarms(farmsWithFields.map(farm => farm.id));
      setSelectedFields(farmsWithFields.flatMap(farm => farm.fields.map(field => field.FieldID)));
    }

    prepareFarmsWithFields();
  }, [selectedEntityId]);

  const datesInfoMessage = useMemo(() => getDatesInfoMessage(currentDate, startDate, endDate), [
    startDate,
    endDate,
  ]);

  const onAutocompleteEntity = (
    entityId: number,
    selectedItemIndex: number,
    list: {label: string; value: number}[]
  ) => {
    const selectedEntity = list[selectedItemIndex];
    setSearchCustomerString(selectedEntity.label);
    setSelectedEntityId(selectedEntity.value);
  };

  const onSelectAllFarms = (value: boolean) => {
    if (value) {
      setSelectedFarms(farmsWithFields.map(farm => farm.id));
      setSelectedFields(farmsWithFields.flatMap(farm => farm.fields.map(field => field.FieldID)));
    } else {
      setSelectedFarms([]);
      setSelectedFields([]);
    }
  };

  const onExpandFarm = (farmId: number, value: boolean) => {
    setExpandedFarms(
      value
        ? [...expandedFarms, farmId]
        : expandedFarms.filter(expandedFarmId => expandedFarmId !== farmId)
    );
  };

  const onToggleFarmSelection = (farmId: number, value: boolean) => {
    setSelectedFarms(
      value
        ? [...selectedFarms, farmId]
        : selectedFarms.filter(selectedFarmId => selectedFarmId !== farmId)
    );
    const fieldIdsByFarm = farmsWithFields
      .find(farm => farm.id === farmId)
      .fields.map(f => f.FieldID);

    setSelectedFields(
      value
        ? [...selectedFields, ...fieldIdsByFarm]
        : selectedFields.filter(selectedFieldId => !fieldIdsByFarm.includes(selectedFieldId)) // select or deselect all farm's fields
    );
  };

  const onToggleFieldSelection = (farmId: number, fieldId: number, value: boolean) => {
    const toggledFieldFarm = farmsWithFields.find(farm => farm.id === farmId);

    const computedSelectedFieldsList = value
      ? [...selectedFields, fieldId]
      : selectedFields.filter(selectedFieldId => selectedFieldId !== fieldId);

    const isFarmSelected = toggledFieldFarm.fields.every(field =>
      computedSelectedFieldsList.includes(field.FieldID)
    );

    setSelectedFields(computedSelectedFieldsList);
    setSelectedFarms(
      isFarmSelected
        ? [...selectedFarms, farmId]
        : selectedFarms.filter(selectedFarmId => selectedFarmId !== farmId)
    );
  };

  const selectedFieldsObjects: Field[] = useMemo(() => {
    return allFieldsList.filter(field => selectedFields.includes(field.FieldID));
  }, [selectedFields, allFieldsList]);

  const selectedFieldsPrice = useMemo(() => getSelectedFieldsInfoMessage(selectedFieldsObjects), [
    selectedFieldsObjects,
  ]);

  const slicedFarmsWithFieldsList = useMemo(() => {
    return farmsWithFields.slice(pagination.start, pagination.start + pagination.perPage);
  }, [pagination, farmsWithFields]);

  const cropTimelineData = useMemo(() => {
    const cropTimelineData: SeasonDataItem[] = slicedFarmsWithFieldsList.map(farm => {
      return {
        farmId: farm.id,
        farmName: farm.name,
        seasons: farm.fields.flatMap(field => {
          if (!selectedFields.includes(field.FieldID)) return [];

          const intersectingFieldSeasons = field.Seasons.filter(season =>
            moment(season.startDate, GLOBAL_FORMAT_DATE).isBetween(startDate, endDate)
          );

          return intersectingFieldSeasons.map(season => ({
            seasonId: season.id,
            startDate: season.startDate,
            endDate: season.endDate,
            cropType: getCropLabelById(season.cropType),
            cropColor: getCropColorById(season.cropType),
          }));
        }),
      };
    });
    return cropTimelineData.filter(farm => farm.seasons.length);
  }, [selectedFieldsObjects, startDate, endDate, slicedFarmsWithFieldsList]);

  const submitNewOrder = () => {
    PlanetApi.createPlanetOrder({
      sensor: selectedSensor,
      start_date: startDate.format(GLOBAL_FORMAT_DATE),
      end_date: endDate.format(GLOBAL_FORMAT_DATE),
      note: orderNoteText,
      field_ids: [...new Set(selectedFields)], // use new set to clear possible duplicates
    })
      .then(({data}) => {
        onAddNewOrder(data.result);
        onBackToTableView();
        dispatch(
          showNote({
            title: 'Success',
            message: 'A new Planet order was successfully created.',
            level: 'success',
          })
        );
      })
      .catch(err => reportError(`submitNewOrder (Planet) ${err}`));
  };

  const willBeImplementedSoon = () => {
    alert('This feature is not ready, yet. It will be implemented very soon.');
  };

  const isEndDateValid = endDate.isAfter(startDate);

  return (
    <div className={'new-order-container'}>
      <h3>New order</h3>

      <div className="sections-parent">
        <section className="section-container with-border select-entity-section">
          <h4 className={'section-title'}>Select customer</h4>

          <FluroTabs
            containerClassName={'select-entity-type'}
            onTabClick={(value: SearchEntity) => setSearchByEntity(value)}
            selectedTab={searchByEntity}
            tabs={EntitiesList}
          />
          <FluroAutocomplete
            id={'select-customer-entity'}
            className={'select-entity-autocomplete'}
            value={searchCustomerString}
            menuItems={preparedEntitiesLists[searchByEntity]}
            placeholder={`Search ${searchByEntity}`}
            searchIcon
            onChange={(value: string) => setSearchCustomerString(value)}
            onAutocomplete={onAutocompleteEntity}
          />
        </section>

        <section className="section-container with-border select-dates-container">
          <h4 className={'section-title'}>Sensor and dates</h4>

          <div className="select-dates-inner-container">
            <SelectField
              id="select-sensor"
              className="select-sensor-type"
              label=""
              menuItems={SensorsList}
              value={selectedSensor}
              onChange={(value: Sensor) => setSelectedSensor(value)}
            />

            <FluroDatePicker
              hideFormat
              id={'start-date'}
              selected={startDate}
              onChange={setStartDate}
              minDate={minDate}
              maxDate={currentDate}
            />

            <div className={'text-between-datepickers'}>until</div>

            <FluroDatePicker
              id={'end-date'}
              hideFormat
              selected={endDate}
              onChange={setEndDate}
              error={!isEndDateValid}
              errorText={'The end date should be after the start date.'}
              minDate={minDate}
              maxDate={currentDate}
            />

            {datesInfoMessage}
          </div>
        </section>
      </div>

      {loadingStatus === RequestStatus.Loading ? (
        <CircularProgresslink id={'loading-farms'} />
      ) : farmsWithFields.length ? (
        <>
          <div className={'fields-table-and-map'}>
            <section className="section-container with-border">
              <div className={'select-fields-container'}>
                <h4 className={'section-title'}>Select fields</h4>
                <div className="expandable-rows-container">
                  {farmsWithFields.length > 1 && (
                    <ExpandableRowContainer>
                      <SelectionControl
                        id={'select-all-farms'}
                        name={'select-all-farms'}
                        label={`Select all farms (${Object.keys(selectedFarms).length}/${
                          farmsWithFields.length
                        })`}
                        type={'checkbox'}
                        className={'select-all-checkbox'}
                        checked={Object.keys(selectedFarms).length === farmsWithFields.length}
                        onChange={(v: boolean) => onSelectAllFarms(v)}
                      />
                    </ExpandableRowContainer>
                  )}
                  {slicedFarmsWithFieldsList.map(farm => {
                    const farmExpanded = expandedFarms.includes(farm.id);
                    const farmSelected = selectedFarms.includes(farm.id);
                    return (
                      <React.Fragment key={farm.id}>
                        <ExpandableRowContainer>
                          <SelectionControl
                            id={farm.id}
                            name={'grower selection'}
                            label={farm.name}
                            type={'checkbox'}
                            checked={farmSelected}
                            onChange={(value: boolean) => onToggleFarmSelection(farm.id, value)}
                          />
                          <ExpandableArea
                            onClick={() => onExpandFarm(farm.id, !farmExpanded)}
                            expanded={farmExpanded}
                          />
                        </ExpandableRowContainer>
                        {farmExpanded && (
                          <SubContainer>
                            <h3>{t({id: 'Fields'})}</h3>
                            {farm.fields.map(f => {
                              const fieldSelected = selectedFields.includes(f.FieldID);
                              return (
                                <SubItem
                                  key={f.FieldID}
                                  className={cn({
                                    selected: fieldSelected,
                                  })}
                                >
                                  <SelectionControl
                                    id={f.FieldID}
                                    name={'field selection'}
                                    label={f.Name}
                                    type={'checkbox'}
                                    checked={fieldSelected}
                                    onChange={(v: boolean) =>
                                      onToggleFieldSelection(farm.id, f.FieldID, v)
                                    }
                                  />
                                </SubItem>
                              );
                            })}
                          </SubContainer>
                        )}
                      </React.Fragment>
                    );
                  })}

                  <div className="pagination">
                    {farmsWithFields.length >= 10 && (
                      <FluroDataTable baseId={'farms-list'}>
                        <FluroTablePagination
                          page={pagination.page}
                          rows={farmsWithFields.length}
                          rowsPerPage={pagination.perPage}
                          rowsPerPageLabel={'Farms'}
                          onPagination={(start: number, perPage: number, page: number) =>
                            setPagination({start, perPage, page})
                          }
                        />
                      </FluroDataTable>
                    )}
                  </div>
                </div>
              </div>
              {selectedFields.length !== 0 && selectedFieldsPrice}
            </section>

            <FieldsOnMap fields={selectedFieldsObjects} />
          </div>

          {cropTimelineData.length !== 0 && (
            <section className="with-border crop-timeline-container">
              <h4
                onClick={() => toggleCropTimelineVisibility(!cropTimelineVisible)}
                className={'section-title'}
              >
                Crop timeline
                <FontIcon>
                  {cropTimelineVisible ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}
                </FontIcon>
              </h4>

              {cropTimelineVisible && (
                <TimelineSeasonsChart maxHeight={'auto'} seasonsData={cropTimelineData} />
              )}
            </section>
          )}

          <TextField
            id={'order-note'}
            className={'order-note'}
            placeholder={'Add a note...'}
            rows={3}
            name={'order-note'}
            value={orderNoteText}
            onChange={(value: string) => setOrderNoteText(value)}
          />
        </>
      ) : null}

      <div className="new-order-buttons">
        <FluroButton onClick={onBackToTableView} className={'cancel-btn'} blank raised>
          Cancel
        </FluroButton>

        {selectedFields.length !== 0 && (
          <>
            <Button onClick={willBeImplementedSoon} raised className={'preview-btn'}>
              Preview invoice
            </Button>

            <Button
              disabled={!isEndDateValid}
              onClick={submitNewOrder}
              primary
              raised
              className={'submit-btn'}
            >
              Submit for approval
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default NewOrderView;
