import {FluroTabs} from 'components';
import {FluroTabsSkin} from 'components/fluro-tabs';
import {toggleTableView} from 'containers/map/actions';
import {t} from 'i18n-utils';
import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getGetURLParam} from '_utils';
import {CarbonStep, setGuessedPractices} from '../carbon-reducer';
import {CarbonPanelCropPracticesTable} from './carbon-panel-crop-practices-table';
import {CarbonPanelCropPracticesPanel} from './carbon-panel-crop-practices-panel';

import './carbon-panel-crop-practices.scss';
import {useAppSelector} from '_hooks';
import {carbonYears} from '../base/base';
import {CarbonApi} from '_api/carbon';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/ui-helpers';
import SimplePreloader from '../../../components/simple-preloader';
import {reportError} from 'containers/error-boundary';

export const CarbonPanelCropPractices = () => {
  const dispatch = useDispatch();
  const carbon = useAppSelector(s => s.carbon);
  const fieldsByFarmId = useAppSelector(s => s.map.fieldsByFarmId);
  const fieldGeometries = useAppSelector(s => s.map.fieldGeometries);

  const [currentView, setCurrentView] = React.useState(
    getGetURLParam('tableView') ? View.Table : View.Map
  );

  // Even though we preset the tab from the url, we still need to call toggleTableView, so it's applied on a global level.
  useEffect(() => {
    if (getGetURLParam('tableView')) {
      selectView(View.Table);
    }
  }, []);

  // Fetch guessed practices.
  useEffect(() => {
    let hasGeometriesToRequest = false;
    const geom: {[fieldId: string]: GeoJSON.FeatureCollection} = {};
    Object.values(fieldsByFarmId).forEach(fields =>
      Object.values(fields).forEach(field => {
        if (
          fieldGeometries[field.MD5] &&
          carbon.enrolledFields[field.ID] &&
          !carbon.guessedPractices[field.ID]
        ) {
          geom[field.ID] = fieldGeometries[field.MD5];
          hasGeometriesToRequest = true;
        }
      })
    );
    const payload = {years: carbonYears, geom};

    if (hasGeometriesToRequest) {
      dispatch(setRequestStatus(AsyncStatusType.guessedPractices, Status.Pending));

      CarbonApi.getGuessedPractices(payload)
        .then(r => {
          dispatch(setRequestStatus(AsyncStatusType.guessedPractices, Status.Done));

          if (!r.data || !Object.keys(r.data).length) return;
          dispatch(setGuessedPractices({...carbon.guessedPractices, ...r.data}));
        })
        .catch(e => {
          dispatch(setRequestStatus(AsyncStatusType.guessedPractices, Status.Done));
          reportError('SPT getGuessedPractices error: ${e?.data?.result || e}');
        });
    }
  }, [fieldsByFarmId, fieldGeometries, carbon.enrolledFields, carbon.guessedPractices]);

  const selectView = (view: View) => {
    const globalTableView = view === View.Table ? 'carbon' : undefined;
    dispatch(toggleTableView(globalTableView));
    setCurrentView(view);
  };

  const panel = React.useMemo(() => {
    switch (currentView) {
      case View.Table:
        return <CarbonPanelCropPracticesTable />;
      case View.Map:
        return <CarbonPanelCropPracticesPanel selectView={selectView} />;
    }
  }, [currentView]);

  return (
    <div className="carbon-panel__crop-practices">
      <div className="title">
        <h3 className="tab-title">
          {t({id: CarbonStep.CropPractices})}
          {currentView === View.Table && (
            <SimplePreloader statusKeys={[AsyncStatusType.guessedPractices]} />
          )}
        </h3>
        <div className="tabs-wrap">
          <FluroTabs
            skin={FluroTabsSkin.Toggle}
            tabs={[
              {label: t({id: View.Table}), value: View.Table},
              {label: t({id: View.Map}), value: View.Map},
            ]}
            selectedTab={currentView}
            onTabClick={tab => selectView(tab as View)}
          />
        </div>
      </div>
      {panel}
    </div>
  );
};
export enum View {
  Table = 'Table',
  Map = 'Map',
}
