import {StateUserActivity, ActionType} from './types';

const initialState: StateUserActivity = {
  summary: {
    total_users: 0,
    total_farms: 0,
    total_fields: 0,
    total_area_ha: 0,
  },
  list: [],
  loaded: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SET_USER_ACTIVITY:
      const {summary, list} = action.payload;
      return {
        ...state,
        summary,
        list,
        loaded: true,
      };
    case ActionType.UPDATE_SUMMARY_USER_ACTIVITY:
      return {
        ...state,
        summary: action.summary,
      };
    default:
      return state;
  }
};
