import {AxiosPromise} from 'axios';
import Server from '../server';
import {ClimateFarmsResponse} from 'containers/profile/integration/types';

export default class {
  static getFarms(): AxiosPromise<ClimateFarmsResponse> {
    return Server.get(`sync/climate`, {params: {__skipPreloader: true}});
  }

  static syncFields(fieldIds: string[]) {
    return Server.post('sync/climate', `field_ids=${fieldIds.join(',')}`, {
      params: {__skipPreloader: true},
    });
  }

  static getSyncStatus(jobId: string) {
    return Server.get(`sync/climate/status/${jobId}`, {params: {__skipPreloader: true}});
  }
}
