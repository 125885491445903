import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Button} from 'react-md';
import moment from 'moment';
import {toggleFieldGeometries} from '../../actions';
import {
  createAreaOfInterest,
  getAndSetAreasOfInterestMeanIndex,
} from '../../actions/areas-of-interest-actions';
import {
  changeLowPerfAnomalyProp,
  toggleLowPerformingAreas,
  saveLowPerfAnomalies,
  setUploadingAnomaliesStatus,
} from '../../actions/anomalies-actions';
import {showNote} from '_actions';
import {buildAnomalyUrl, getCurrentImageName, calcPlural} from '_utils';
import {userFacingArea} from './anomalies-utils';
import AnomalyItem from 'components/anomalies-ui/anomaly-item';
import AnomalyLabelsDropdown from './labels-dropdown';
import {IAnomaly, TAnomalyProps} from './types';
import {IInitialMapState} from '../../types';
import ReadOnly from 'components/read-only-container';
import Mixpanel from '_utils/mixpanel-utils';
import {RequestStatus} from '../../../../types';

type Props = ConnectedProps<typeof connector>;
const emptyFunc = (): null => null;
class LowPerfAnomalies extends Component<Props> {
  getGeometries = () => {
    const {list} = this.props.lowPerfAnomalies;
    return list.uploadedROI?.features
      ? list.uploadedROI
      : list[getCurrentImageName()] || {features: [] as any, savedAsRoi: false};
  };

  saveLowPerfAnomalies = () => {
    const geometries = this.getGeometries();

    if (geometries.savedAsRoi) {
      this.saveAnomaliesAsROI(geometries);
    } else {
      if (!geometries.features.some((el: IAnomaly) => el.properties.label)) {
        this.props.showNote({
          title: t({id: 'note.info', defaultMessage: 'Info'}),
          message: t({
            id: 'Please label the shapes before saving them.',
            defaultMessage: 'Please label the shapes before saving them.',
          }),
          level: 'warning',
        });
        return;
      }
      this.props.saveLowPerfAnomalies(buildAnomalyUrl(), geometries, true);
      this.closeAnomaliesTab();
    }
  };

  saveAnomaliesAsROI = (visibleGeometries: {features: IAnomaly[]}) => {
    this.props.setUploadingAnomaliesStatus(RequestStatus.Loading);
    Promise.all(
      visibleGeometries.features.map((f: IAnomaly) => {
        const geometry = {
          type: 'Feature',
          geometry: {...f.geometry},
          properties: {
            ...f.properties,
            isLowPerf: false,
            saved: true,
            uploading: false,
          },
        };
        Mixpanel.addGeometry('Upload');
        // @ts-ignore
        return this.props.createAreaOfInterest(geometry, visibleGeometries.features.length > 1);
      })
    )
      .then(() => {
        this.props.setUploadingAnomaliesStatus(RequestStatus.Success);
        this.props.getAndSetAreasOfInterestMeanIndex();
        this.closeAnomaliesTab();
        !this.props.geometriesOnMap && this.props.toggleFieldGeometries(true); // toggle geometries on if they are not toggled
      })
      .catch(err => {
        console.log('saveAnomaliesAsROI ERR', err);
        this.props.setUploadingAnomaliesStatus(RequestStatus.Success);
      });
  };

  changeLowPerfAnomalyProp = (anomaly: IAnomaly, prop: keyof TAnomalyProps, value: any) => {
    this.props.changeLowPerfAnomalyProp(anomaly, prop, value);
  };

  closeAnomaliesTab = () => {
    this.props.toggleLowPerformingAreas(false);
  };

  render() {
    const {
      lowPerfAnomalies: {isVisible},
      measurement,
      isReadOnly,
      currentDate,
      uploadingAOIStatus,
    } = this.props;
    const visibleGeometries = this.getGeometries().features || [];
    const uploadProcess = visibleGeometries?.[0]?.savedAsRoi;
    const isUploading = uploadingAOIStatus === RequestStatus.Loading;

    if (!isVisible) return null;

    return (
      <div id={'anomalies'} className={'geometry-section low-perf-anomalies'}>
        <h3 className={'anomaly-header__title'}>
          {uploadProcess
            ? t(
                {
                  id: 'Shape uploaded',
                  defaultMessage: '{count, plural, one {Shape} other {Shapes}} uploaded',
                },
                {count: visibleGeometries}
              )
            : t({id: 'Low performing areas', defaultMessage: 'Low performing areas'})}
        </h3>

        {visibleGeometries.length ? (
          <>
            {!uploadProcess && (
              <div className="low-perf-anomalies__message">
                {t({
                  id: 'Only labeled areas will be saved',
                  defaultMessage: 'Only labeled areas will be saved',
                })}
              </div>
            )}
            {visibleGeometries.map((anomaly: IAnomaly, i: number) => (
              <AnomalyItem
                label={
                  <AnomalyLabelsDropdown
                    disabled={isReadOnly}
                    anomaly={anomaly}
                    label={anomaly.properties.label}
                    miniButton
                    onChange={(prop: keyof TAnomalyProps, value: any, geometry: IAnomaly) =>
                      this.changeLowPerfAnomalyProp(geometry, prop, value)
                    }
                    width={200}
                    className={'label-drop-down'}
                    position={'br'}
                  />
                }
                startDate={anomaly.properties.startDate}
                endDate={anomaly.properties.endDate}
                isChecked={!!anomaly.properties.checked}
                area={userFacingArea(anomaly.properties.area, measurement)}
                key={`${anomaly.properties.area}-${i}`}
                labelName={anomaly.properties.label}
                onCheck={value => this.changeLowPerfAnomalyProp(anomaly, 'checked', value)}
                title={''}
                onView={emptyFunc}
                openPopUp={false}
              />
            ))}
            <div className={'buttons'}>
              <ReadOnly>
                <Button
                  id={'save-geometries-btn'}
                  primary
                  className="element-full-width"
                  raised
                  disabled={!visibleGeometries || isUploading}
                  onClick={this.saveLowPerfAnomalies}
                >
                  Save
                </Button>
              </ReadOnly>

              <Button className="element-full-width" onClick={this.closeAnomaliesTab} raised>
                {t({id: 'Cancel', defaultMessage: 'Cancel'})}
              </Button>
            </div>
          </>
        ) : (
          <div className={'low-perf-anomalies__message'}>
            {t(
              {
                id: 'No low performing areas detected on',
                defaultMessage: 'No low performing areas detected on {date}',
              },
              {date: moment(currentDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: any}) => ({
  lowPerfAnomalies: map.lowPerfAnomalies,
  field: map.field,
  currentDate: map.currentDate,
  measurement: login.user.settings.measurement,
  geometry: map.geometry,
  isReadOnly: map.group.readOnly,
  geometriesOnMap: map.geometriesOnMap,
  uploadingAOIStatus: map.anomalies.uploadingAOIStatus,
});

const connector = connect(mapStateToProps, {
  saveLowPerfAnomalies,
  showNote,
  changeLowPerfAnomalyProp,
  toggleLowPerformingAreas,
  createAreaOfInterest,
  toggleFieldGeometries,
  setUploadingAnomaliesStatus,
  getAndSetAreasOfInterestMeanIndex,
});
export default connector(LowPerfAnomalies);
