import {t, FormattedMessage} from 'i18n-utils';
import {FontIcon} from 'react-md';
import React from 'react';
import './index.scss';
import {FluroButton} from '../index';
import cn from 'classnames';

type Props = {
  label: string;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  flat?: boolean;
  raised?: boolean;
  iconEl?: React.ReactElement;
  onClick?(): void;
};

const LabelOpenBtn = ({
  label,
  onClick,
  disabled,
  primary,
  flat,
  raised,
  iconEl,
  className = '',
}: Props) => {
  return (
    <FluroButton
      disabled={disabled}
      className={cn({'label-open-btn': true, [className]: true, 'default-icon': !iconEl})}
      iconBefore={!!iconEl}
      title={label}
      flat={flat}
      raised={raised}
      primary={primary}
      iconEl={iconEl ? iconEl : <FontIcon>arrow_drop_down</FontIcon>}
      onClick={onClick || null}
    >
      {label || t({id: 'Select a label'})}
    </FluroButton>
  );
};

export default LabelOpenBtn;
