import React from 'react';
import cn from 'classnames';
import './fluro-status-icon.scss';

export type FluroStatusIconProps = 'green' | 'light-green' | 'yellow' | 'gray' | 'red';

type Props = {
  status: FluroStatusIconProps;
  className?: string;
};
export const FluroStatusIcon = ({status, className = ''}: Props) => {
  return <div className={cn({'fluro-status-icon': true, [status]: true, [className]: true})} />;
};
