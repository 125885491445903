import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';

type Props = {
  id: string;
};

export class Portal extends Component<Props> {
  portalRoot = document.getElementById(this.props.id);
  render() {
    return ReactDOM.createPortal(this.props.children, this.portalRoot);
  }
}
