import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {ListItem, MenuButton} from 'react-md';
import {useDispatch, useSelector} from 'react-redux';
import exportTreeZoningToPdf from './export-tree-zoning-to-pdf';
import exportNRxToPdf from './export-nrx-to-pdf';
import {
  exportZoningShapeFile,
  exportNrxToShp,
  exportNrxToKml,
  exportNrxToAgx,
} from 'containers/map/actions/zoning-actions';
import {ReadOnly} from 'components';
import {DownloadIcon} from '../../../icons';
import {AppStore} from 'reducers';
import {isAgxField} from '_utils';

const ExportZoning = () => {
  const exportOptions = [];
  const dispatch = useDispatch();
  const {toggled, method, nrxResult, nrxTabRate, selectedObjective} = useSelector(
    (s: AppStore) => s.map.nRecommendation
  );
  const zoning = useSelector((s: AppStore) => s.map.zoning);
  const currentField = useSelector((s: AppStore) => s.map.field);
  const isTreeAnalysis = useSelector((s: AppStore) => s.map.treeDetection.layerType !== 'default');
  const isWholeFarmView = useSelector((s: AppStore) => s.map.wholeFarm.isWholeFarmView);

  const isNrx = toggled && method === 'apsim';
  const showNRecommendationButtons = isNrx && nrxResult[nrxTabRate][selectedObjective].type;

  const onExport = (exportFunc: () => void) => {
    dispatch(exportFunc());
  };

  if (showNRecommendationButtons) {
    if (nrxTabRate !== 'blanket') {
      exportOptions.push(
        <ListItem
          key={'export-nrx-to-shp'}
          onClick={() => onExport(exportNrxToShp)}
          primaryText={t({id: 'Export {ext}'}, {ext: 'SHP'})}
        />
      );
    }
    exportOptions.push(
      <ListItem
        key={'export-nrx-to-pdf'}
        onClick={() => onExport(exportNRxToPdf)}
        primaryText={t({id: 'Export {ext}'}, {ext: 'PDF'})}
      />,
      <ListItem
        key={'export-nrx-to-kml'}
        onClick={() => onExport(exportNrxToKml)}
        primaryText={t({id: 'Export {ext}'}, {ext: 'kml'})}
      />
    );

    if (isAgxField(currentField)) {
      exportOptions.push(
        <ListItem
          key={'export-nrx-to-agx'}
          onClick={() => onExport(exportNrxToAgx)}
          primaryText={t({id: 'Export to agX'})}
        />
      );
    }
  }

  if (!isWholeFarmView && isTreeAnalysis && !!zoning.treeZones.length) {
    exportOptions.push(
      <ListItem
        key={'export-tree-to-pdf'}
        onClick={() => onExport(exportTreeZoningToPdf)}
        primaryText={t({id: 'Export {ext}'}, {ext: 'PDF'})}
      />
    );
  }

  if (!isNrx && !isTreeAnalysis && zoning.zones.length !== 0) {
    exportOptions.push(
      <ListItem
        key={'export-regular-to-shp'}
        onClick={() => onExport(exportZoningShapeFile)}
        primaryText={t({id: 'Export {ext}'}, {ext: 'SHP'})}
      />
    );
  }

  if (
    showNRecommendationButtons ||
    (!isWholeFarmView && isTreeAnalysis && zoning.treeZones.length) ||
    (!isNrx && !isTreeAnalysis && !!zoning.zones.length)
  ) {
    return (
      <ReadOnly fullWidth>
        <MenuButton
          id="export-zoning-menu-btn"
          className="btn-with-icon element-full-width"
          raised
          primary
          block
          iconEl={<DownloadIcon />}
          menuItems={exportOptions}
          simplifiedMenu={false}
          anchor={{
            x: 'center',
            y: 'overlap',
          }}
        >
          {t({id: 'Export'})}
        </MenuButton>
      </ReadOnly>
    );
  }

  return null;
};

export default ExportZoning;
