import {useSelector} from 'react-redux';
import moment from 'moment';
import {AppStore} from 'reducers';
import {Field, Season} from 'containers/map/types';
import {useMemo} from 'react';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';

export const useFieldCurrentSeason = (): {
  getFieldCurrentSeasons: (f: Field, filterByPlantingAreas?: boolean) => Season[];
} => {
  const feature = useSelector((s: AppStore) => s.map.feature);
  const currentDates = useSelector((s: AppStore) => s.map.currentDates);
  const currentDate = useSelector((s: AppStore) => s.map.currentDate);
  const isReportView = useSelector((store: AppStore) => store.global.isReportView);

  const date = useMemo(
    () =>
      feature === 'crop-performance' || isReportView
        ? moment.utc(currentDate, GLOBAL_APP_DATE_FORMAT) // in crop perf the date could be outside currentDates
        : moment.utc(currentDates[currentDate]?.Date),
    [currentDates, currentDate, feature]
  );
  const getFieldCurrentSeasons = (f: Field, filterByPlantingAreas = false) => {
    if (!currentDate) {
      return [];
    }
    return filterByPlantingAreas
      ? getFieldSeasons(f, date.format()).filter(s => s.geometry)
      : getFieldSeasons(f, date.format());
  };
  return {getFieldCurrentSeasons};
};

export const getFieldSeasons = (f: Field, date: string) => {
  const d = moment.utc(date);
  return f.Seasons.filter(
    s => d.isSameOrAfter(moment.utc(s.startDate)) && d.isSameOrBefore(moment.utc(s.endDate))
  );
};

export const getFieldLastFinishedSeason = (f: Field) => {
  const d = moment.utc();
  let prevSeasonIndex = -1;
  // Start the iteration from the latest season.
  for (let i = f.Seasons.length; i--; ) {
    const s = f.Seasons[i];
    // And take the first season that both started and finished before the current date.
    if (d.isSameOrAfter(moment.utc(s.startDate)) && d.isSameOrAfter(moment.utc(s.endDate))) {
      prevSeasonIndex = i;
      break;
    }
  }
  return prevSeasonIndex > -1 ? f.Seasons[prevSeasonIndex] : undefined;
};
