import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import ReactDOM from 'react-dom';
import {FluroStep} from './common-steps';
import Joyride from 'react-joyride';
import {baseStepStyle} from './style';
import {LoginState} from '../login/types';
import {FontIcon} from 'react-md';
import {UIHelpersStore} from '../../modules/ui-helpers';
import {usePrevious} from '_hooks';

const DemoFarmInfo = () => {
  const {demoFarmInfoBtn, infoDialog} = useSelector(
    ({login, uiHelpers}: {login: LoginState; uiHelpers: UIHelpersStore}) => ({
      demoFarmInfoBtn: login.user.settings.onboarding.demoFarmInfoBtn,
      infoDialog: uiHelpers.dialogs.info,
    }),
    shallowEqual
  );

  const prev = usePrevious({demoFarmInfoBtn});

  const [run, setRun] = useState(false);
  const steps: FluroStep[] = [
    {
      title: 'Demo field details',
      content: (
        <div className={'demo-farm-info'}>
          You can reopen the popup with the details of this demo field by clicking on the{' '}
          <FontIcon>help_outline</FontIcon> icon next to the field name.
        </div>
      ),
      target: '.open-demo-farm-icon',
      placement: 'bottom',
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    if (prev?.demoFarmInfoBtn === false && demoFarmInfoBtn) {
      setRun(true);
    }
  }, [demoFarmInfoBtn]);

  return ReactDOM.createPortal(
    <Joyride
      steps={steps}
      run={run && !infoDialog.visible}
      continuous
      styles={baseStepStyle}
      hideBackButton
      disableOverlayClose
      locale={{back: 'Previous', last: 'Got it!'}}
    />,
    document.querySelector('body')
  );
};

export default DemoFarmInfo;
