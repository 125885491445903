import {FormattedMessage} from 'i18n-utils';
import {CheckIcon, DropDownArrowIcon, WarningSvg} from 'components/icons';
import './messages.scss';
import * as React from 'react';
import {useMemo} from 'react';
import {genKey} from '../../../_utils';

export const ConfirmPracticesMessage = ({
  goToTable = false,
  onClick,
}: {
  onClick?: () => void;
  goToTable?: boolean;
}) => (
  <div className="carbon-message-green">
    <FormattedMessage
      id="[carbon] confirm practices message"
      defaultMessage="Please <ln>fill in all missing information</ln> <i1></i1> and confirm (or change) <i2></i2> any low confidence practices to proceed."
      values={{
        ln: (txt: string) =>
          goToTable ? (
            <span onClick={onClick} className="go-to-map-ln">
              {txt}
            </span>
          ) : (
            txt
          ),
        i1: () => <DropDownArrowIcon style={{margin: '0 5px'}} />,
        i2: () => <CheckIcon style={{margin: '0 5px'}} />,
      }}
    />
  </div>
);
