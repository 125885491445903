import {useState, useEffect} from 'react';

type TProps = {
  keys: string[];
  callback: () => void;
  keysUpCallback?: () => void;
};

const MultiKeysPressed = ({keys, callback, keysUpCallback}: TProps): null => {
  const [keysPressed, setKeyPressed] = useState(new Set([]));
  const arePressed = () => {
    const required = new Set(keys);
    for (var elem of keysPressed) {
      required.delete(elem);
    }
    return required.size === 0;
  };

  const downHandler = ({key}: any) => {
    if (!keysPressed.has(key)) {
      setKeyPressed(keysPressed.add(key));
      if (arePressed()) callback();
    }
  };

  const upHandler = ({key}: any) => {
    keysPressed.delete(key);
    setKeyPressed(keysPressed);
    if (keys.includes(key) && keysUpCallback) {
      keysUpCallback();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [callback]);

  return null;
};

export default MultiKeysPressed;
