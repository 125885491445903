import * as React from 'react';
import {useEffect} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {setAreaType, setStatus, setData} from './optis-reducer';
import {OptisAreaType, optisAreaTypeOrder} from './optis-types';
import {AppStore} from 'reducers';
import {OptisApi} from '_api';
import {reportError} from 'containers/error-boundary';
import {RequestStatus} from 'types';
import {Button, FontIcon} from 'react-md';
import FluroDropdownMenu from 'components/fluro-dropdown-menu';

export const OptisAreaTypeSelect = () => {
  const dispatch = useDispatch();
  const optis = useSelector((s: AppStore) => s.optis);

  // TODO (stas): get meta and show subitems with names.
  // useEffect(() => {
  //   const areaTypes: OptisAreaType[] = ['State', 'CRD', 'County'];
  //   areaTypes.map(areaType => {
  //     OptisApi.getData({
  //       areaType,
  //       areaUnit: 'ac',
  //       from: optis.filter.years[0],
  //       to: optis.filter.years[optis.filter.years.length - 1],
  //       include: ['meta'],
  //     })
  //       .then(r => {
  //         if (r.data.status !== 'ok') {
  //           reportError(`Couldn't load optis meta data: ${r.data.status}`);
  //           return;
  //         }
  //         dispatch(
  //           setData({
  //             meta: r.data.result.meta,
  //             areaType,
  //           })
  //         );
  //       })
  //       .catch(e => {
  //         reportError(`Couldn't load optis meta data: ${e}`);
  //       });
  //   });
  // }, []);

  const menuItems = optisAreaTypeOrder.map(areaType => ({
    primaryText: areaType,
    className: areaType === 'Segment' ? 'disabled' : undefined,
    onClick: () => areaType !== 'Segment' && dispatch(setAreaType(areaType)),
  }));

  return (
    <Wrapper>
      <FluroDropdownMenu label={optis.areaType} menuItems={menuItems} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 90px;

  ul {
    font-size: 13px;
  }

  .disabled .md-fake-btn {
    opacity: 0.5;
    cursor: default;
  }
`;
