import {getNextClosestDate} from '_utils';
import moment from 'moment';

/*
 https://flurosat.atlassian.net/browse/FSB-3458

 NOTE: the dates array MUST be sorted newest to oldest dates
 */
export function preselectCompareDate(datesObj: {[key: string]: any}, currentDate: string) {
  const dates = Object.keys(datesObj);

  if (!dates.length || dates.length === 1) return currentDate;

  const currentDateIndex = dates.findIndex(date => date === currentDate);

  if (currentDateIndex) {
    return dates[currentDateIndex - 1];
  }

  return dates[1];
}
