import {DEFAULT_LOCALE, Locales} from 'i18n-utils';
import {browserLocale, getGetURLParam} from '_utils/pure-utils';
import config from '_environment';

export type LoginPayload = {
  email: string;
  password: string;
};

export type UpdatePasswordPayload = {
  password: string;
  confirmPassword: string;
  uuid: string;
};

export type LoginResponse<R> = {
  data: {
    status: string;
    result: R;
  };
  status: number;
};

export enum LoginActionTypes {
  LOGIN = 'login/LOGIN',
  LOGOUT = 'login/LOGOUT',
  ERROR = 'login/ERROR',
  UPDATE_USER_SETTINGS = 'login/update-user-settings',
  UPDATE_USER_DATA = 'user/update-user-settings',
}

export interface LoginState {
  message: string[];
  user: IUser;
  isAuth: boolean;
  token: string;
  farmEndDialog: boolean;
}

export interface IUser {
  id: number | 'new';
  emailConfirmed: boolean;
  photo: string;
  email: string;
  name: string;
  active: boolean;
  perm: number;
  groupIds: number[];
  groupId: string; // Deprecated
  agxSync: boolean;
  demoFarms: number[];
  settings: IUserSettings;
  firstName?: string;
  phone?: string;
  surname?: string;
  interestClass?: string;
  createdAt?: string;
  password?: string;
  defaultOrganizationID?: number | null;
  defaultSubscriptionID?: number | null;
}

export interface IUserSettings {
  zoning?: IUserZoning;
  locale: string;
  langLocale: Locales;
  measurement: 'ha' | 'ac';
  phone: string;
  onboarding?: IUserOnBoarding;
  notifications?: IUserNotifications;
  company?: IUserCompany;
  showDemoFarms?: boolean;
  pushInfo?: any;
  timezone?: string;
  // notificationFrequency?: number; //deprecated
  tspGroups?: any[]; // [{fieldId: string, groups: [{date: string, gs: string, tsp: [1, 2, 3]}]}]
  surname?: string; // ToDo remove duplicate prop
  scenarioPlanningTool?: 'cargill'; // user registered as scenarioPlanningTool user. The only workflow we have so far is Cargill
}

export interface IUserNotifications {
  push?: boolean;
  email?: boolean;
  agxSyncNotification?: string;
  firstImageryOnField?: string;
  firstImageryOnFieldTerravion: string;
  significantReleaseLink: string; // deprecated
}

export interface IUserZoning {
  doNotShowConfirmPopUp: boolean; // deprecate the prop
  favoriteZoningMethod: any; // TODO backend return Null
}

export interface IUserCompany {
  companyName: string;
  businessActivity: string;
  jobTitle: string;
  country: string;
  street: string;
  city: string;
  zip: string;
  licenses: CompanyLicenses;
}

export interface IUserOnBoarding {
  isStudiedDrawingFieldsFlow: boolean;
  fullTour: boolean;
  quickTour: boolean;
  isOnboarded: boolean;
  demoFarmInfoBtn: boolean;
}

export type CompanyLicenses = {
  PCA: boolean;
  CCA: boolean;
  CPCC: boolean;
};

export const InitialUser = {
  email: '',
  name: '',
  perm: 0,
  id: 0,
  groupIds: [],
  agxSync: false,
  photo: '',
  active: false,
  emailConfirmed: false,
  settings: {
    showDemoFarms: true,
    zoning: {
      doNotShowConfirmPopUp: false,
      favoriteZoningMethod: {},
    },
    langLocale: getGetURLParam('i18n')
      ? localStorage.getItem('lang') || browserLocale()
      : DEFAULT_LOCALE,
    // leave prop locale for backward compatibility
    locale: 'uk-UA',
    // metricSystem: '',
    measurement: config.featurePack === 'carbon' ? 'ac' : 'ha',
    phone: '',
    notifications: {
      push: false,
      email: false,
      agxSyncNotification: 'enabled',
      firstImageryOnField: 'enabled',
      firstImageryOnFieldTerravion: 'disabled',
    },
    onboarding: {
      isOnboarded: true,
      isStudiedDrawingFieldsFlow: false,
      fullTour: false,
      quickTour: false,
      demoFarmInfoBtn: false,
    },
    company: {
      companyName: '',
      businessActivity: '',
      jobTitle: '',
      country: '',
      street: '',
      city: '',
      zip: '',
      licenses: {
        PCA: false,
        CCA: false,
        CPCC: false,
      },
    },
    pushInfo: null,
    timezone: '2eirus',
    // notificationFrequency: 3,
    tspGroups: [], // [{fieldId: '', groups: [{date: '', gs: '', tsp: [1, 2, 3]}]}]
  },
} as IUser;
