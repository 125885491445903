import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {dialogToggle} from 'modules/ui-helpers';
import {Button} from 'react-md';
import Dialog from 'components/fluro-dialog';
import {genKey} from '_utils';
import {DialogFooter} from 'containers/admin/admin.styled';
import {CommonDialogContentWrapper} from './dialogs.styled';

const AlertDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();
  const {visible, payload} = useSelector((state: AppStore) => state.uiHelpers.dialogs.alert);

  const title = useMemo(() => {
    return payload?.title || 'Alert';
  }, [payload]);

  const okLabel = useMemo(() => {
    return payload?.okLabel || 'Ok';
  }, [payload]);

  const message = useMemo(() => {
    return payload?.message || '';
  }, [payload]);

  const onHide = useCallback(() => dispatch(dialogToggle('alert', false)), []);

  const onOk = useCallback(() => {
    if (payload?.okAction) {
      payload.okAction();
    }

    onHide();
  }, [payload]);

  return (
    <Dialog
      id={`alert-dialog-${id}`}
      visible={visible}
      title={title}
      focusOnMount={false}
      isDraggable
      onHide={onHide}
      width={400}
      portal
      actions={
        <DialogFooter>
          <Button primary raised onClick={onOk}>
            {okLabel}
          </Button>
        </DialogFooter>
      }
    >
      <CommonDialogContentWrapper>{message}</CommonDialogContentWrapper>
    </Dialog>
  );
};

export default AlertDialog;
