import * as React from 'react';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import chroma from 'chroma-js';
import {AppStore} from 'reducers';
import './optis-filter-status.scss';
import {
  geometryIdMap,
  OptisType,
  tillageColor,
  tillageLabels,
  winterCropColor,
  winterCropLabels,
} from './optis-types';
import {getColorScale, minMaxPctAcrossGeometries} from './optis-utils';
import {getYearRange} from '_utils';

export const OptisFilterStatus = () => {
  const optis = useSelector((s: AppStore) => s.optis);
  const year = getYearRange(optis.filter.years);
  const names: string[] = useMemo(() => {
    return optis.filter.geometries.map(id => optis.meta[optis.areaType][id]?.name);
  }, [optis.filter.geometries, optis.meta, optis.areaType]);

  const geometryIds = useMemo(() => {
    return Object.keys(optis.geometries)
      .map(Number)
      .filter(id => optis[optis.filter.type][id]);
  }, [optis.geometries, optis[optis.filter.type], optis.filter.type]);
  const {min, max} = minMaxPctAcrossGeometries(optis, geometryIds);
  const mid = Math.round(min + (max - min) / 2);
  const getColor = getColorScale(optis, min, max);

  return (
    <div className="optis-filter-status">
      {optis.areaType !== 'Segment' && (
        <div className="scale-container">
          <div className="scale">
            {scaleSteps.map(step => (
              <span
                className="scale-step"
                style={{background: getColor(step).css()}}
                key={step}
              ></span>
            ))}
          </div>

          <div className="scale-ticks">
            <div>{min}%</div>
            <div>{mid}%</div>
            <div>{max}%</div>
          </div>
        </div>
      )}
      <div className="buttons">
        <ActiveFilters filter={optis.filter} />
        {year && (
          <div className="disable-filter-button" style={{background: 'white', color: 'black'}}>
            {year}
          </div>
        )}
        {names.map(name => (
          <div
            className="disable-filter-button"
            style={{background: 'white', color: 'black'}}
            key={name}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

const ActiveFilters = ({filter}: {filter: AppStore['optis']['filter']}) => {
  switch (filter.type) {
    case OptisType.Tillage:
      return (
        <>
          {filter.value.map(v => (
            <div className="disable-filter-button" style={{background: tillageColor[v]}} key={v}>
              {tillageLabels[v]}
            </div>
          ))}
        </>
      );
    case OptisType.WinterCrop:
      return (
        <>
          {filter.value.map(v => (
            <div className="disable-filter-button" style={{background: winterCropColor[v]}} key={v}>
              {winterCropLabels[v]}
            </div>
          ))}
        </>
      );
  }
};

const scaleSteps = new Array(100).fill(0).map((v, i) => i / 100);
