import {t, FormattedMessage} from 'i18n-utils';
import React, {useState, useEffect} from 'react';
import './index.scss';
import cn from 'classnames';
import {NavLink} from 'react-router-dom';
import {Card} from 'react-md';
import {ToggleIcon} from 'containers/map/icons';
import {useSelector} from 'react-redux';
import {AppStore} from '../../reducers';
import NotificationSignal from 'components/NotificationSignal';

export type TabObj = {
  title: string;
  link: string;
  icon?: React.ReactElement;
  exact?: boolean; // to check exact path to set active class
};
type Props = {
  configTabs: TabObj[];
  locationPathname: string;
  className?: string;
};

const linkToShrinkViewFor = ['/admin/fields-lookup', '/admin/order-high-resolution-imagery'];

const NavPanel = ({className = '', configTabs, locationPathname}: Props) => {
  const [toggled, togglePanel] = useState(false);
  const viewportWidth = useSelector((store: AppStore) => store.viewport.width);

  useEffect(() => {
    if (linkToShrinkViewFor.includes(locationPathname)) {
      // always set naw panel to collapsed view
      // https://flurosat.atlassian.net/browse/FSB-3271?focusedCommentId=20693
      togglePanel(true);
    }
  }, [locationPathname]);

  const tabNotifications = useSelector((state: AppStore) => state.global.tabNotifications);

  useEffect(() => {
    if (viewportWidth < 768) togglePanel(true);
  }, []);

  return (
    <Card
      className={cn(`nav-panel ${className}`, {
        minimized: toggled,
      })}
    >
      <div className={'tabs'}>
        {configTabs.map(tab => (
          <NavLink
            className={cn('tab', {
              active: tab.exact
                ? locationPathname === `/${tab.link}`
                : locationPathname.includes(tab.link),
            })}
            key={tab.link}
            exact
            to={`/${tab.link}`}
          >
            {tabNotifications?.admin?.hasUpdates && tab.link.endsWith('email-logs') ? (
              <NotificationSignal adminTab value={tabNotifications?.admin?.count} />
            ) : null}
            {tab.icon && <div className="icon">{tab.icon}</div>}
            {!toggled && <span className={'text'}>{tab.title}</span>}
          </NavLink>
        ))}
      </div>

      <div onClick={() => togglePanel(!toggled)} className={'nav-panel-toggle'}>
        <ToggleIcon />
        {!toggled && 'Toggle sidebar'}
      </div>
    </Card>
  );
};

export default NavPanel;
