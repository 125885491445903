import {t} from 'i18n-utils';
import * as React from 'react';
import {useMemo} from 'react';
import cn from 'classnames';
import './line-chart.scss';
import {Field} from '../../types';
import './fields-with-planting-areas.scss';
import {useFieldCurrentSeason} from '../../../../_hooks/use-current-season';

type Props = {
  fields: Field[];
  filteredFields: number[];
  onFilterClear?: () => void;
  onFilterToggle?: (fieldIdToFilter: number) => void;
};

export const FieldsWithPlantingAreas = ({
  fields,
  filteredFields,
  onFilterClear,
  onFilterToggle,
}: Props) => {
  const {getFieldCurrentSeasons} = useFieldCurrentSeason();

  const fieldsWithPlatingAreas = useMemo(() => {
    return fields.filter(f => {
      const seasons = getFieldCurrentSeasons(f);
      return seasons.find(s => s.geometry);
    });
  }, [fields]);

  if (!fieldsWithPlatingAreas.length) return null;

  return (
    <div className={'line-chart fields-with-planting-areas'}>
      <div className="line-chart-title">
        <h3>{t({id: 'Planting areas'})}</h3>
        <div className="controls">
          <button
            className={'clear-filter-btn'}
            disabled={filteredFields.length === 0}
            onClick={e => {
              e.stopPropagation();
              onFilterClear();
            }}
          >
            <img src="/assets/filter.svg" alt="filter" title="Clear filters" width="20" />
          </button>
        </div>
      </div>
      <div className="fields">
        {fieldsWithPlatingAreas.map(field => {
          const active = !filteredFields.length || filteredFields.includes(field.ID);
          return (
            <div
              key={field.ID}
              onClick={() => onFilterToggle(field.ID)}
              className={cn('field-item', {active})}
            >
              {field.Name}
            </div>
          );
        })}
      </div>
    </div>
  );
};
