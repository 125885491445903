import turfArea from '@turf/area';
import convert from 'convert-units';
import pathToRegexp from 'path-to-regexp';
import Moment from 'moment';
import {extendMoment} from 'moment-range';

/*
 *
 * The main purpose of this utils file - non dependency of import store
 * (decouple components, storybook bug-fix)
 *
 * */

//@ts-ignore
const moment = extendMoment(Moment);
import React from 'react';
import {Season} from 'containers/map/types';
//duplicate from _constants for make storybook great again
const GLOBAL_FORMAT_DATE = 'YYYY-MM-DD';

export const genKey = function () {
  return Math.random().toString(36).substring(7);
};

export const hexToRgbA = (hex: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
  }
};

export const resetInputValue = (event: React.MouseEvent<HTMLElement>) => {
  //@ts-ignore
  event.target.value = '';
};

export const sum = (list: number[]) => list.reduce((acc, v) => acc + v, 0);

export const calcPlural = (string: string, value: Array<any> | number, customPlural?: string) => {
  const compareValue = Array.isArray(value) ? value.length : value;
  return compareValue <= 1 ? string : customPlural || `${string}s`;
};

export const contactSupportTeam = (text: string): React.ReactElement => (
  <span
    onClick={() => document.getElementById('intercom-launch')?.click()}
    className={'global-link'}
  >
    {' '}
    {text}
  </span>
);

export function setFieldIDToURL(fieldID: number, push: any) {
  const pattern = '/app/maps/:groupID/:fieldID?';
  let finalURL = '';

  const re = pathToRegexp(pattern);
  const result = re.exec(window.location.pathname);

  if (result) {
    const toPath = pathToRegexp.compile(pattern);

    finalURL = toPath({
      groupID: '' + result[1],
      fieldID: fieldID,
    });
  }

  if (finalURL) {
    push(finalURL.replace('/app', '') + window.location.search);
  }
}

export const setGetParamToURL = (param: string, value: string | number) => {
  const newURL = window.location.protocol + '//' + window.location.host + window.location.pathname;
  const params = new URLSearchParams(window.location.search);

  if (value == null) {
    params.delete(param);
  } else {
    params.set(param, `${value}`);
  }
  window.history.replaceState(null, null, newURL + '?' + params.toString());
};

export const bulkSetGetParamToURL = (params: {[key: string]: string | number}) => {
  Object.keys(params).forEach(key => {
    setGetParamToURL(key, params[key]);
  });
};

export const getGetURLParam = (param: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get ? params.get(param) : null;
};

export const unreachableError = (x: never, message: string): never => {
  throw new Error(message);
};

export function browserLocale() {
  let lang;

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    lang = navigator.languages[0];
    //@ts-ignore
  } else if (navigator.userLanguage) {
    // IE only
    //@ts-ignore
    lang = navigator.userLanguage;
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    //@ts-ignore
    lang = navigator.language;
  }

  return lang;
}

export function getFarmIdsFromUrl() {
  return getArrayValuesFromUrl('farmIds')?.map(Number);
}

export function getArrayValuesFromUrl(urlParam: string) {
  return getGetURLParam(urlParam)?.split(',').filter(Boolean);
}

export function toFixedFloat(val: any, num = 2) {
  if (isFinite(val) && val !== null) {
    val = parseFloat(val);

    if (val === 0) return val;

    return parseFloat(val.toFixed(num));
  }

  return 0;
}

export const isDateInRange = (date: string, startDate: string, endDate: string) => {
  const startDateM = moment(startDate, GLOBAL_FORMAT_DATE);
  const endDateM = moment(endDate, GLOBAL_FORMAT_DATE);
  const currentDateM = moment(date, GLOBAL_FORMAT_DATE);
  const range = moment().range(startDateM, endDateM);
  return (
    range.contains(currentDateM) || startDateM.isSame(currentDateM) || endDateM.isSame(currentDateM)
  );
};

export function sortDates(arrayDates: Array<any>, dateFormat = 'DD/MM/YYYY') {
  arrayDates.sort(function (a, b) {
    if (moment(a, dateFormat).isBefore(moment(b, dateFormat))) return -1;
    if (moment(a, dateFormat).isAfter(moment(b, dateFormat))) return 1;
    return 0;
  });
  return arrayDates;
}

export const createDateRange = (startDate: string, endDate: string) => {
  const range = moment.range(moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD'));

  return Array.from(range.by('day')).map(d => d.format('YYYY-MM-DD'));
};

/**
 * @returns The area in ha
 */
export function getArea(geometry: GeoJSON.Geometry | GeoJSON.GeometryCollection): number {
  switch (geometry.type) {
    case 'GeometryCollection':
      return geometry.geometries.reduce((acc, g) => acc + getArea(g), 0);
    default:
      return convert(turfArea(geometry)).from('m2').to('ha');
  }
}

export const elementContainsTarget = (element: HTMLElement, target: HTMLElement): boolean => {
  if (element === target) {
    return true;
  }
  if (!target.parentElement) {
    return false;
  }
  return elementContainsTarget(element, target.parentElement);
};

/**
 * Subscribes to click outside the element and ESC keydown.
 *
 * @returns a cleanup function. (!) Don't forget to return it from your useEffect.
 */
export const onClickOutsideAndEsc = (
  elementRef: React.MutableRefObject<undefined>,
  active: boolean,
  callback: () => void
) => {
  if (!active) return;
  const onMouseDown = (e: MouseEvent) => {
    if (active && !elementContainsTarget(elementRef.current, e.target as HTMLElement)) {
      callback();
    }
  };
  const onEscPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      callback();
    }
  };
  document.addEventListener('mousedown', onMouseDown);
  document.addEventListener('keydown', onEscPress);
  return () => {
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('keydown', onEscPress);
  };
};

export const getCropSrc = (src: string) => (src ? `/assets/crops/${src}` : '');

export const getYearRange = (years: number[]) => {
  if (years.length === 0) return '';
  if (years.length === 1) return years[0];

  // Whether the year period is continuos (2007 – 2010),
  // or has holes (2007, 2008, 2010).
  let continuous = true;
  years.sort();
  for (let i = years.length - 1; i > 0; i--) {
    if (years[i] - years[i - 1] > 1) {
      continuous = false;
      break;
    }
  }
  return continuous ? `${years[0]} – ${years[years.length - 1]}` : years.join(', ');
};

export const isObjectPropValuesTheSame = (data: any[], key: string | number) => {
  const obj: any = {};
  data.forEach((el: any) => {
    obj[el[key]] = null;
  });

  return Object.keys(obj).length <= 1;
};

export const getNodeText = (node: any): string => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

export const getSeasonByYear = (seasons: Season[], year: number) => {
  return seasons?.find(season => year === moment(season.startDate).year());
};
