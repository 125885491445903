import axios from 'axios';
import server from './server';
import service from './service';
import {SessionExpiredRequest} from '_reducers/global_types';

// integrations
export {default as AgworldApi} from './integrations/agworld';
export {default as AgxApi} from './integrations/agx';
export {default as EFCApi} from './integrations/efc';
export {default as ClimateApi} from './integrations/climate';
export {default as JohnDeereApi} from './integrations/john-deere';

export {default as UserApi} from './user';
export {default as AuthApi} from './auth';
export {default as FarmApi} from './farm';
export {default as FieldGeometryApi} from './field-geometry';
export {default as KmlApi} from './kml';
export {default as UsersApi} from './users';
export {default as TagsApi} from './tags';
export {default as SeasonApi} from './season';
export {default as ActivityApi} from './activity';
export {default as OptisApi} from './optis';
export {default as WeatherApi} from './weather';
export {default as NutrilogicApi} from './nutrilogic';
export {default as NrxApi} from './nrx';
export {default as AnomaliesApi} from './anomalies';
export {default as ZoningApi} from './zoning';
export {default as UserActivityApi} from './user-activity';
export {default as SubscriptionsApi} from './subscriptions';
export {default as AdminApi} from './admin';
export {default as CropTypesMatchingApi} from './admin-crop-types-matching';
export {default as AreasOfInterestApi} from './areas-of-interest';
export {default as ReportApi} from './report';
export {default as PlanetApi} from './order-planet-api';

export const retry = (r: SessionExpiredRequest) => {
  const baseURL = r.config.baseURL;
  r.config.baseURL = ''; // clean up base url because it's already included in r.config.url
  switch (baseURL) {
    case '/api/v1/':
      return server(r.config).then(r.resolve).catch(r.reject);
    case '/services/':
      return service(r.config).then(r.resolve).catch(r.reject);
    default:
      return axios(r.config).then(r.resolve).catch(r.reject);
  }
};

export * from './types';
