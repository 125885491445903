import initialState from '../initial-state';
import {IAction} from '../../types';

import {ActionTypes} from '../types';

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.MAP_ADD_ANALYTIC_POINT:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          points: [...state.analytics.points, action.point],
        },
      };

    case ActionTypes.MAP_UPDATE_ANALYTIC_POINT:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          points: [
            ...state.analytics.points.map(point => {
              if (action.positions[point.color]) {
                point.latlng = action.positions[point.color];
              }

              return point;
            }),
          ],
        },
      };

    case ActionTypes.MAP_REMOVE_ANALYTIC_POINT:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          points: state.analytics.points.filter(el => el.color !== action.color),
        },
      };

    case ActionTypes.MAP_TOGGLE_ANALYTIC_POINT_VISIBILITY:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          points: state.analytics.points.map((el, index: number) => {
            if (index === action.index) {
              el.visible = !(el.visible === true || el.visible === undefined);
            }
            return el;
          }),
        },
      };

    case ActionTypes.MAP_TOGGLE_TSP_POINT_VISIBILITY:
      const {pointsGroups: tspGroup, pointsCurrentGroupDate: tspCurrent} = state;

      return {
        ...state,
        pointsGroups: {
          ...state.pointsGroups,
          [tspCurrent]: tspGroup[tspCurrent].map((element: any) => {
            if (element.id === action.id) {
              element.properties.visible = !(
                element.properties.visible === true || element.properties.visible === undefined
              );
            }

            return element;
          }),
        },
      };

    case ActionTypes.MAP_TOGGLE_FIELD_MEAN: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          showKmlMean: action.value,
        },
      };
    }

    case ActionTypes.MAP_TOGGLE_SMOOTHED_DATA: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          showSmoothedData: action.value,
        },
      };
    }

    case ActionTypes.MAP_ADD_ANALYTIC_TRENDS_DATA: {
      const CCCI = action.values[0] || {};
      const MSAVI = action.values[1] || {};
      const NDVI = action.values[2] || {};
      const NDRE = action.values[3] || {};

      return {
        ...state,
        analytics: {
          ...state.analytics,
          fieldData: action.fieldData,
          trendsData: {
            CCCI,
            MSAVI,
            NDVI,
            NDRE,
          },
        },
      };
    }

    case ActionTypes.MAP_SET_ARABLE_SENSOR_DATA: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          arableData: action.data,
        },
      };
    }

    default:
      return state;
  }
};
