import {ActionType, SubscriptionEntityType, SubscriptionsStore} from './subscriptions.types';

const initialState: SubscriptionsStore = {
  subscriptions: [],
  organizations: [],
  farms: [],
  hasFarmsUpdate: false,

  organizationsBySubscriptionId: {},
  farmsByOrganizationId: {},

  users: [],
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.ClearCache: {
      return {
        ...state,
        organizationsBySubscriptionId: {},
        farmsByOrganizationId: {},
        subscriptions: [],
        organizations: [],
      };
    }
    case ActionType.SetEntities: {
      const prop = mapEntityTypeToStoreProperty(action.entityType, action.levelLoadId);

      return action.levelLoadId
        ? {
            ...state,
            [prop]: {
              ...state[prop],
              [action.levelLoadId]: [...action.entities],
            },
          }
        : {
            ...state,
            [prop]: [...action.entities],
          };
    }

    case ActionType.SetUsers:
      return {
        ...state,
        users: [...action.users],
      };

    case ActionType.HasFarmsUpdate:
      return {
        ...state,
        hasFarmsUpdate: action.value,
      };

    default:
      return state;
  }
};

export const mapEntityTypeToStoreProperty = (type: SubscriptionEntityType, id?: number) => {
  switch (type) {
    case SubscriptionEntityType.Farm:
      return id ? 'farmsByOrganizationId' : 'farms';

    case SubscriptionEntityType.Organization:
      return id ? 'organizationsBySubscriptionId' : 'organizations';

    case SubscriptionEntityType.Subscription:
      // subscriptions does not have top level entity
      return 'subscriptions';
  }
};
