import {hot} from 'react-hot-loader/root';
import React from 'react';
import {Route, Router, Switch, Redirect} from 'react-router-dom';
import {history} from './store';
import DemoFarmsDialog from 'containers/dialogs/demo-farms';
import {useSelector, shallowEqual} from 'react-redux';
import {AppStore} from './reducers';
import useAppAutoUpdater from './use-app-auto-updater';
import {TranslationsProvider} from './translations-provider';

// components
import {NotFound, Alert, FluroTooltip} from 'components';
import CommonDialogs from 'components/common-dialogs';

//containers
import {
  BootAuthContainer,
  BaseLayout,
  PrivateRoute,
  BasicAllocation,
  FlurosenseMap,
  InfoDialog,
  ScrollToTop,
} from 'containers';

import 'normalize.css';
import './App.scss';
import {DialogType, toggleDialog} from './_reducers/dialog';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const ResetPassword = React.lazy(() => import('containers/reset-password'));

const App = () => {
  const isAuth = useSelector((state: AppStore) => state.login.isAuth, shallowEqual);
  useAppAutoUpdater();

  return (
    <BootAuthContainer>
      <TranslationsProvider>
        <React.Suspense fallback={<></>}>
          <Router history={history}>
            <ScrollToTop>
              <BaseLayout>
                <Switch>
                  {/* keeping backward admin links: redirect user from /admin/maps.. to /maps... */}
                  <Route
                    path="/admin/maps"
                    render={props => (
                      <Redirect
                        to={props.location.pathname.replace('admin/', '') + props.location.search}
                      />
                    )}
                  />
                  <PrivateRoute exact path="/" component={BasicAllocation} />
                  <Route path="/login" component={React.lazy(() => import('containers/login'))} />
                  <Route path="/logout" component={React.lazy(() => import('containers/logout'))} />
                  <Route
                    path="/oauth/:source/:code"
                    component={React.lazy(() => import('containers/oauth'))}
                  />
                  <Route
                    path="/sign-up/:uuid?/:from?"
                    component={React.lazy(() => import('containers/registration'))}
                  />
                  <Route
                    path="/sign-up-confirmation/:uuid"
                    component={React.lazy(() => import('containers/sign-up-confirmation'))}
                  />
                  <Route
                    path="/reset-password/:uuid"
                    render={props => <ResetPassword uuid={props.match.params.uuid} />}
                  />
                  <Route
                    path="/reset-password/"
                    component={React.lazy(() => import('containers/reset-password/enter-email'))}
                  />
                  <Route
                    path="/farm-invite/:uuid"
                    component={React.lazy(() => import('containers/farm/accept-farm-invite'))}
                  />

                  <PrivateRoute
                    path="/maps/md5/:md5"
                    isAdminOnly
                    component={React.lazy(() => import('containers/admin/goto-field-by-md5'))}
                  />

                  <PrivateRoute path="/maps" component={FlurosenseMap} />

                  <PrivateRoute
                    path="/reportV2"
                    component={React.lazy(() => import('containers/reportV2/reportV2'))}
                  />

                  <Route
                    path="/carbon/login"
                    component={React.lazy(
                      () => import('containers/carbon/carbon-landing/carbon-landing')
                    )}
                  />

                  <Route
                    path="/carbon/sign-up-confirmation/:uuid"
                    component={React.lazy(
                      () => import('containers/carbon/carbon-landing/carbon-landing')
                    )}
                  />

                  <Route
                    path="/carbon/reset-password/:uuid"
                    component={React.lazy(
                      () => import('containers/carbon/carbon-landing/carbon-landing')
                    )}
                  />

                  <PrivateRoute
                    path="/carbon"
                    component={React.lazy(() => import('containers/carbon/carbon'))}
                  />

                  <PrivateRoute
                    isAdminOnly
                    path="/admin"
                    component={React.lazy(() => import('containers/admin'))}
                  />
                  <PrivateRoute
                    path="/profile"
                    component={React.lazy(() => import('containers/profile'))}
                  />

                  <Route
                    path="/privacypolicy"
                    component={React.lazy(() => import('containers/privacypolicy/privacypolicy'))}
                  />

                  <Route component={NotFound} />
                </Switch>

                {isAuth && <InfoDialog />}

                <CommonDialogs />

                <DemoFarmsDialog />
              </BaseLayout>
              <FluroTooltip />
              <Alert /> {/* moved it here to prevent showing old messages after login/logout*/}
            </ScrollToTop>
          </Router>
        </React.Suspense>
      </TranslationsProvider>
    </BootAuthContainer>
  );
};

export default hot(App);
