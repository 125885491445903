import {t} from 'i18n-utils';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {FluroDialog} from 'components';

import cn from 'classnames';
import {useAppSelector} from '../../_hooks';
import {toggleSelectPlatformsView} from '../profile/integration/actions';
import ProfileIntegration from '../profile/integration/integration';
import {getGetURLParam, setGetParamToURL} from '../../_utils';
import {toggleMapBar} from "../map/actions";

const IntegrationsPlatformsPopUp = () => {
  const dispatch = useDispatch();
  const visible = useAppSelector(s => s.dialog.currentDialog === DialogType.IntegrationPlatforms);
  const platforms = useAppSelector(store => store.integrations.platforms);

  useEffect(() => {
    if (
      getGetURLParam('integration-pop-up') === 'true' &&
      Object.values(platforms).some(p => p.selected)
    ) {
      setGetParamToURL('integration-pop-up', null);
      dispatch(toggleSelectPlatformsView(false));
    } else {
      dispatch(toggleSelectPlatformsView(true));
    }

    return () => {
      dispatch(toggleSelectPlatformsView(false));
    };
  }, []);

  const onHide = () => {
    dispatch(toggleDialog(DialogType.IntegrationPlatforms));
    dispatch(toggleMapBar(true));
  };

  return (
    <FluroDialog
      id="integration-platforms--dialog"
      visible={visible}
      isDraggable
      focusOnMount={false}
      portal
      dialogClassName={cn('integration-platforms-dialog')}
      onHide={onHide}
      stickyHeader
    >
      <ProfileIntegration isPopUpView={true} />
    </FluroDialog>
  );
};

export default IntegrationsPlatformsPopUp;
