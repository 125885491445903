import * as React from 'react';
import cn from 'classnames';
import './fluro-chip.scss';
import {FontIcon} from 'react-md';

export type Size = 'small' | 'medium';
export type Tone = 'light' | 'dimmed';

export const FluroChip = ({
  label,
  size = 'medium',
  tone = 'light',
  dropdown,
  active,
  onClick,
}: {
  label: string;
  size?: Size;
  tone?: Tone;
  dropdown?: boolean;
  active?: boolean;
  onClick?: (label: string) => void;
}) => {
  return (
    <button
      className={cn('fluro-chip', size, tone, {active, dropdown})}
      onClick={() => onClick?.(label)}
    >
      {label}
      {dropdown && <FontIcon className="right-icon">arrow_drop_down</FontIcon>}
    </button>
  );
};
