import {FluroTabs, TabWithSubButton} from 'components';
import {FluroSelectLite} from 'components/fluro-select-lite/fluro-select-lite';
import {FluroTabsSkin} from 'components/fluro-tabs';
import {t} from 'i18n-utils';
import * as React from 'react';
import {useMemo, useState} from 'react';
import {Button, FontIcon} from 'react-md';
import cn from 'classnames';
import {useAppDispatch, useAppSelector} from '_hooks';
import {
  addPractice,
  removePractice,
  updatePractice,
  setActiveCarbonPractice,
  assignPracticeToField,
  doAndPersist,
  setActivePlan,
} from '../carbon-reducer';
import {CarbonPractice, carbonPracticeOrder, CarbonPlanType, practiceColors} from './base';
import './carbon-plan.scss';
import {useCallback} from 'react';
import {dialogToggle, DialogType} from '../../../modules/ui-helpers';
import {PracticeStats} from './carbon-outcomes';
import {selectMeasurement} from '../../../_utils';

export type Practice = {
  name: string;
  color: string;
  practice: CarbonPractice;
  fields: {};
};
type CarbonPlanProps = {
  practiceStats?: {[practice: string]: PracticeStats};
};
export const CarbonPlan = ({practiceStats}: CarbonPlanProps) => {
  const dispatch = useAppDispatch();
  const carbon = useAppSelector(s => s.carbon);

  const [isRecommendedPlanHelpTextActive, setRecommendedPlanHelpTextActive] = useState(false);

  const selectedFieldList = useMemo(() => Object.keys(carbon.enrolledFields).map(Number), [
    carbon.enrolledFields,
  ]);

  const plan = carbon.plans[carbon.activePlan];

  const suggestField = (practice: CarbonPractice) => {
    // Find the next field that is selected and doesn't have a practice yet.
    const nextFieldId = Object.keys(carbon.enrolledFields)
      .map(Number)
      .find(fieldId => carbon.enrolledFields[fieldId] && !plan.practices[fieldId]);
    if (nextFieldId) {
      dispatch(
        doAndPersist(() => dispatch(assignPracticeToField({fieldId: nextFieldId, practice})))
      );
    }
  };

  const unsuggestField = (practice: CarbonPractice) => {
    // Find the last selected field that belongs to the given practice.
    const nextFieldId = Object.keys(carbon.enrolledFields)
      .map(Number)
      .reverse()
      .find(fieldId => carbon.enrolledFields[fieldId] && plan.practices[fieldId] === practice);
    if (nextFieldId) {
      dispatch(
        doAndPersist(() =>
          dispatch(assignPracticeToField({fieldId: nextFieldId, practice: CarbonPractice.NoValue}))
        )
      );
    }
  };

  const unusedPractises = useMemo(
    () => carbonPracticeOrder.filter(p => !plan.carbonPractices.includes(p)),
    [plan.carbonPractices]
  );

  const onTillageHelpOpen = useCallback(() => {
    dispatch(dialogToggle(DialogType.carbonTillagePractices, true));
  }, []);

  return (
    <div className="carbon-plan">
      <div className="tabs-container">
        <FluroTabs
          skin={FluroTabsSkin.Toggle}
          tabs={[
            {
              value: CarbonPlanType.Recommended,
              label: (
                <TabWithSubButton
                  label={t({id: CarbonPlanType.Recommended})}
                  onClick={e => {
                    e.stopPropagation();
                    if (
                      !isRecommendedPlanHelpTextActive &&
                      carbon.activePlan !== CarbonPlanType.Recommended
                    ) {
                      dispatch(setActivePlan(CarbonPlanType.Recommended));
                    }
                    setRecommendedPlanHelpTextActive(v => !v);
                  }}
                />
              ),
            },
            {value: CarbonPlanType.Custom, label: t({id: CarbonPlanType.Custom})},
          ]}
          selectedTab={carbon.activePlan}
          onTabClick={v => dispatch(setActivePlan(v as CarbonPlanType))}
        />
      </div>
      {/* <h2>{t({id: planType})}</h2> */}
      {isRecommendedPlanHelpTextActive && carbon.activePlan === CarbonPlanType.Recommended && (
        <div className="description">
          For this plan we automatically selected the fields with the greatest potential for
          sequestering carbon based on soil type, historical weather data and cropping practices.
        </div>
      )}

      {plan.carbonPractices.map(practice => {
        const practiceFieldsCount = practiceStats
          ? practiceStats[practice].fieldCount
          : selectedFieldList.filter(id => carbon.targetPractices[id]?.practices === practice)
              .length;
        const practiceFieldsArea = practiceStats ? practiceStats[practice].area : 0;

        return (
          <PracticeView
            key={practice}
            practice={practice}
            unusedPractises={carbonPracticeOrder.filter(
              p => p === practice || !plan.carbonPractices.includes(p)
            )}
            removable={plan.carbonPractices.length > 1}
            active={plan.activeCarbonPractice === practice}
            fieldCount={practiceFieldsCount}
            fieldArea={practiceFieldsArea}
            removePractice={p => {
              dispatch(doAndPersist(() => dispatch(removePractice(p))));
            }}
            updatePractice={(a, b) => {
              dispatch(
                doAndPersist(() => dispatch(updatePractice({oldPractice: a, newPractice: b})))
              );
            }}
            suggestField={suggestField}
            unsuggestField={unsuggestField}
            onTillageHelp={onTillageHelpOpen}
            onClick={() => dispatch(setActiveCarbonPractice(practice))}
          />
        );
      })}

      {carbon.activePlan === CarbonPlanType.Custom && (
        <div className="actions">
          <Button
            raised
            disabled={unusedPractises.length === 0}
            onClick={() => dispatch(addPractice())}
          >
            {t({id: 'ADD MORE PRACTICES'})}
          </Button>
        </div>
      )}
    </div>
  );
};

const PracticeView = ({
  practice,
  unusedPractises,
  removable,
  active,
  fieldCount,
  fieldArea,
  removePractice,
  updatePractice,
  suggestField,
  unsuggestField,
  onTillageHelp,
  onClick,
}: {
  practice: CarbonPractice;
  unusedPractises: CarbonPractice[];
  removable: boolean;
  active: boolean;
  fieldCount: number;
  fieldArea: number | null;
  removePractice: (practice: CarbonPractice) => void;
  updatePractice: (oldPractice: CarbonPractice, newPractice: CarbonPractice) => void;
  suggestField: (practice: CarbonPractice) => void;
  unsuggestField: (practice: CarbonPractice) => void;
  onTillageHelp: () => void;
  onClick?: () => void;
}) => {
  const practices = unusedPractises.map(practice => ({
    value: practice,
    label: t({id: practice}),
  }));
  const practiceCompact = practice.replace(whiteSpaceAndPlusRegexp, '');
  console.log('fieldArea', fieldArea);
  return (
    <div
      className={cn('practice', {
        active,
        [practiceCompact]: true,
      })}
      onClick={onClick}
    >
      <div className="practice-content">
        <div className="color-tongue" />
        <div className="section">
          <div className="label">{t({id: 'Select practice'})}</div>
          <div className="content">
            <FluroSelectLite
              items={practices}
              selectedItem={practice}
              onSelect={value => updatePractice(practice, value as CarbonPractice)}
              helpComponent={
                <FontIcon
                  onClick={e => {
                    e.stopPropagation();
                    onTillageHelp();
                  }}
                >
                  help_outline
                </FontIcon>
              }
            />
            {removable && (
              <Button
                icon
                onClick={e => {
                  e.stopPropagation();
                  removePractice(practice);
                }}
              >
                delete
              </Button>
            )}
          </div>
        </div>
        <div className="section">
          <div className="content">
            <div className="suggest-fields-controls">
              <Button icon onClick={() => unsuggestField(practice)}>
                remove
              </Button>
              <span style={{backgroundColor: practiceColors[practice]}} className={'fields-count'}>
                {fieldCount}
              </span>
              <Button icon onClick={() => suggestField(practice)}>
                add
              </Button>
              <span className={'fields-selected'}>
                {t({id: '{count} fields selected'}, {count: fieldCount})},{' '}
                {selectMeasurement(fieldArea)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const whiteSpaceAndPlusRegexp = /\W/g;
