import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {SelectionControl, SelectField, Portal} from 'react-md';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import {getCropTypesList, getCropLabelById} from '_utils';
import {
  toggleAllFieldsCheckboxes,
  saveFieldData,
  saveBulkCropAndSync,
  deleteSeason,
  removeField,
  addPivotMarker,
} from 'containers/map/actions';
import {Field, Season} from '../../../types';
import {CropPayload, FieldPayload} from '../types';
import EditFieldForm from '../forms/edit-field-form';
import {EditSeasonForm} from '../forms/edit-season-form';
import DeleteDialog from 'components/delete-dialog';
import {FieldItem} from './field';
import {toggleGlobalDialog} from '_actions';
import {FluroTabs, FluroDialog} from 'components';
import PlantingAreaSeason from './planting-area-season';
import {clearSeasonNotSavedGeometries, clearGeometries} from './planting-area-season.module';
import {PartFieldSeasonDisplay, TabsDialogWrapper} from './field.styled';
import {useAppSelector} from '_hooks';
import {dialogToggle, DialogType} from '../../../../../modules/ui-helpers';

//@ts-ignore
const moment = extendMoment(Moment);

type Props = {
  setFilteredFields(ids: number[]): void;
  filteredFieldIds: Field[];
};

enum CreateSeasonTab {
  WholeField = 'wholeField',
  PartOfField = 'partOfField',
}

const createSeasonTabs = [
  {
    label: <FormattedMessage id="Whole field" />,
    value: CreateSeasonTab.WholeField,
  },
  {
    label: <FormattedMessage id="Part of the field" />,
    value: CreateSeasonTab.PartOfField,
  },
];

const Fields = ({setFilteredFields, filteredFieldIds}: Props) => {
  const dispatch = useDispatch();

  const isWholeFarmView = useAppSelector(s => s.map.wholeFarm.isWholeFarmView);
  const currentGroupId = useAppSelector(s => s.global.currentGroupId);
  const highlightedFieldId = useAppSelector(s => s.map.highlightedFieldId);
  const selectedFieldId = useAppSelector(s => s.map.selectedFieldId);
  const fields = useAppSelector(s => s.map.fields);
  const field = useAppSelector(s => s.map.field);
  const globalDialogs = useAppSelector(s => s.global.dialogsState);
  const isDrawingMode = useAppSelector(s => s.map.drawControl.isDrawingMode);
  const farm = useAppSelector(s => s.map.group);
  const seasonGeometry = useAppSelector(s => s.partFieldSeason.geometry);

  const [createSeasonTab, switchCreateSeasonTab] = useState(CreateSeasonTab.WholeField);
  const [filterCrop, setFilterCrop] = useState('_all');
  const [showOnlySelectedCrops, onChangeCropVisibility] = useState(false);

  const cropTypes = useMemo(() => getCropTypesList(), []);

  useEffect(() => {
    if (filterCrop !== '_all') {
      setFilterCrop('_all');
    }
  }, [currentGroupId, isWholeFarmView]);

  const onFilterCrop = (filterCrop: string) => {
    setFilterCrop(filterCrop);
    dispatch(toggleAllFieldsCheckboxes(false));
  };

  const toggleAllFields = (value: boolean, fields: Array<Field>) => {
    if (filterCrop === '_all' || !value) return dispatch(toggleAllFieldsCheckboxes(value));

    dispatch(
      toggleAllFieldsCheckboxes(
        value,
        fields.map(f => f.ID)
      )
    );
  };

  const toggleEditField = (value: boolean, fieldId?: number) => {
    const dialogName = 'editField';
    const shouldReset = globalDialogs[dialogName]?.fieldId !== fieldId;

    dispatch(toggleGlobalDialog(dialogName, {visible: value, fieldId}, shouldReset));
  };

  /**
   * If seasonId is passed, edit the existing season.
   * If seasonId is not passed, create a new one.
   */
  const toggleEditSeason = (fieldId: number, seasonId?: number) => {
    const dialogName = 'editSeason';

    const visible = !globalDialogs[dialogName]?.visible;

    if (!visible) {
      clearSeasonNotSavedGeometries();
      dispatch(clearGeometries());
      switchCreateSeasonTab(CreateSeasonTab.WholeField);
    }

    dispatch(toggleGlobalDialog(dialogName, {visible, fieldId, seasonId}, true));
  };

  const onSaveField = (formData: FieldPayload) => {
    const fieldId = globalDialogs.editField?.fieldId;
    const fieldToChange = fields.find(f => f.ID === fieldId);

    let sendData: any = {};

    Object.keys(formData).forEach((key: keyof FieldPayload) => {
      if (formData[key] !== fieldToChange[key]) {
        sendData[key] = formData[key];
        if (key === 'PivotCenterCoordinates' || key === 'Pivot') {
          // requirement from the back-end Pivot and PivotCenterCoordinates always to be sent together
          sendData.Pivot = formData.Pivot;
          sendData.PivotCenterCoordinates = formData.PivotCenterCoordinates;
        }
      }
    });

    //@ts-ignore
    dispatch(saveFieldData(sendData, currentGroupId, fieldId)).then(() => {
      dispatch(toggleGlobalDialog('editField', {visible: false}, true));
    });
  };

  const onSaveCrop = (formData: CropPayload) => {
    const {fieldId = 0, seasonId = 0} = globalDialogs.editSeason || {};
    const {
      cropType,
      startDate,
      endDate,
      cropSubType,
      isCustomCropSubType,
      name,
      geometry,
    } = formData;

    const sendData = [
      {
        kml_id: fieldId,
        seasons: [
          {
            id: !seasonId ? null : seasonId,
            name,
            cropType,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            params: {
              cropSubType,
              isCustomCropSubType,
            },
            geometry,
          },
        ],
      },
    ];

    //@ts-ignore
    dispatch(saveBulkCropAndSync(currentGroupId, sendData)).then(() => {
      toggleEditSeason(0, 0);
      clearSeasonNotSavedGeometries();
      dispatch(clearGeometries());
    });
  };

  const onDeleteCrop = () => {
    const {fieldId = 0, seasonId = 0} = globalDialogs.editSeason || {};
    if (window.confirm(t({id: 'Are you sure?'})))
      //@ts-ignore
      dispatch(deleteSeason(currentGroupId, fieldId, seasonId)).then(() => toggleEditSeason(0, 0));
  };

  const onDeleteField = () => {
    const {fieldId = 0} = globalDialogs.editField || {};

    dispatch(removeField(currentGroupId, [fieldId]))
      //@ts-ignore
      .then(() => {
        dispatch(toggleGlobalDialog('editField', {visible: false}, true));
      })
      .catch((err: any) => console.warn(err));
  };

  const {currentFarmCrops, currentFarmRegions} = useMemo(() => {
    const seasons: string[] = [];
    const regions: {[region: string]: true} = {};

    fields.forEach(f => {
      regions[f.CountryRegion] = true;

      f.Seasons.forEach((s: Season) => {
        !seasons.includes(s.cropType) && seasons.push(s.cropType);
      });
    });
    return {
      currentFarmCrops: seasons,
      currentFarmRegions: Object.keys(regions),
    };
  }, [fields]);

  const filteredFields: Field[] = useMemo(() => {
    if (!isWholeFarmView) {
      const f: Field | undefined = fields.find((f: Field) => f.ID === selectedFieldId);

      if (f !== undefined) {
        return [f];
      }

      return [];
    }

    const result =
      filterCrop !== '_all'
        ? fields.filter(({Seasons = []}) => Seasons.find(s => s.cropType === filterCrop))
        : fields;

    if (result.length !== filteredFieldIds.length) {
      const newFilteredFieldsIDs = result.map(f => f.ID);
      setFilteredFields(fields.filter(f => !newFilteredFieldsIDs.includes(f.ID)).map(f => f.ID));
    }

    return result;
  }, [isWholeFarmView, fields, filteredFieldIds, filterCrop]);

  const {editSeason, editField} = globalDialogs;
  const fieldToEdit = filteredFields.find(
    f => f.ID === (editSeason?.fieldId || editField?.fieldId)
  );
  const moreThanOneSeasonFields = isWholeFarmView
    ? filteredFields.find(f => f.Seasons.length > 1)
    : field.Seasons?.length > 1; // to show the toggle only if it useful

  const seasonToEdit = fieldToEdit?.Seasons.find(s => s.id === editSeason?.seasonId);

  const seasonDates = fieldToEdit?.Seasons
    ? fieldToEdit.Seasons.filter(s =>
        seasonToEdit ? s.id !== seasonToEdit.id && !s.geometry_id : !s.geometry_id
      ).map(s => [s.startDate, s.endDate])
    : [];

  if (!fields.length) {
    return null;
  }

  return (
    <>
      {moreThanOneSeasonFields && (
        <SelectionControl
          id="toggle-only-selected-crops"
          type="switch"
          label={t({id: 'Show growing crops only'})}
          name="toggle-only-selected-crops"
          onChange={(val: boolean) => onChangeCropVisibility(val)}
          checked={showOnlySelectedCrops}
          className="toggle-label"
        />
      )}
      {isWholeFarmView && fields.length > 1 && (
        <div className="farm-field-list-sort-filter">
          <table>
            <tbody>
              <tr>
                <td>
                  {isWholeFarmView && (
                    <SelectionControl
                      label={t({id: 'Select all'})}
                      id="select-all-fields-checkbox"
                      type="checkbox"
                      name="select-all-fields-checkbox"
                      inline
                      onChange={(v: boolean) => toggleAllFields(v, fields)}
                      checked={fields.every(f => f._selected)}
                      className="select-all-fields-checkbox toggle-label"
                    />
                  )}
                </td>
                {currentFarmCrops.length >= 2 ? (
                  <td>
                    <SelectField
                      id="select-crop"
                      className="crop-filter-select"
                      placeholder={t({id: 'None'})}
                      menuItems={[
                        {label: <FormattedMessage id="all crops" />, value: '_all'},
                        ...cropTypes.filter(el => currentFarmCrops.includes(el.value)),
                      ]}
                      value={filterCrop}
                      onChange={onFilterCrop}
                      simplifiedMenu={false}
                      anchor={{
                        x: SelectField.HorizontalAnchors.INNER_RIGHT,
                        y: SelectField.VerticalAnchors.BOTTOM,
                      }}
                    />
                  </td>
                ) : currentFarmCrops.length ? (
                  <td>
                    {currentFarmCrops[0] === ''
                      ? t({id: 'No crops'})
                      : t({id: 'Only crops'}, {crop: getCropLabelById(currentFarmCrops[0])})}
                  </td>
                ) : (
                  <td>{t({id: 'No crops'})}</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {filteredFields.map(f => (
        <FieldItem
          key={f.ID}
          field={f}
          farm={farm}
          highlighted={highlightedFieldId === f.ID}
          showOnlySelectedCrops={showOnlySelectedCrops}
          currentFarmRegions={currentFarmRegions}
          toggleEditFieldPopup={toggleEditField}
          toggleEditSeasonPopup={toggleEditSeason}
        />
      ))}

      <FluroDialog
        id="edit-field-dialog"
        className={'edit-field-dialog'}
        visible={editField?.visible && !isDrawingMode}
        title={t({id: 'Field settings'})}
        onHide={() => toggleEditField(false)}
        isDraggable
        width={440}
      >
        <EditFieldForm
          field={{...fieldToEdit, ...editField}}
          addPivotMarker={values => dispatch(addPivotMarker(values))}
          onSubmit={data => onSaveField(data)}
          onDelete={() =>
            dispatch(
              dialogToggle(DialogType.deleteDialog, true, {
                title: t({id: 'Delete field?'}),
                onSubmit: onDeleteField,
              })
            )
          }
        />
      </FluroDialog>

      <Portal visible={!!editSeason?.visible}>
        <FluroDialog
          id="edit-season-dialog"
          className={'edit-season-dialog'}
          visible={editSeason?.visible}
          title={seasonToEdit ? t({id: 'Crop settings'}) : t({id: 'Add new crop'})}
          onHide={toggleEditSeason}
          // 690 - we have  geometry areas
          width={seasonGeometry.length ? 690 : 400}
          isDraggable
          hideOnClickOutside={false}
        >
          {!editSeason?.seasonId && !seasonGeometry.length && (
            <TabsDialogWrapper>
              <FluroTabs
                selectedTab={createSeasonTab}
                onTabClick={(value: CreateSeasonTab) => switchCreateSeasonTab(value)}
                tabs={createSeasonTabs}
              />
            </TabsDialogWrapper>
          )}

          <PartFieldSeasonDisplay visible={createSeasonTab === CreateSeasonTab.WholeField}>
            <EditSeasonForm
              seasonDates={[seasonDates]}
              season={seasonToEdit}
              onSubmit={onSaveCrop}
              onDelete={onDeleteCrop}
              fieldIds={[editSeason?.fieldId]}
              isAddingCrop={!seasonToEdit}
            />
          </PartFieldSeasonDisplay>

          <PartFieldSeasonDisplay visible={createSeasonTab === CreateSeasonTab.PartOfField}>
            <PlantingAreaSeason toggleEditSeason={toggleEditSeason} />
          </PartFieldSeasonDisplay>
        </FluroDialog>
      </Portal>
    </>
  );
};

export default Fields;
