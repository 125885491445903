// Farm types
import {
  FertilizerApplication,
  NrxPopUpValues,
  NRxSeason,
  NrxListsData,
  NRxObjectiveType,
  NrxTabRate,
  NRxZonesCollection,
} from './utils/nrx-utils';
import {TSensor, TFeature} from '../../types';
import {AnomalyTab, IAnomaly, LowPerformingAnomaliesStore} from './features/anomalies/types';
import {RequestStatus} from 'types';
import {ActionTypes} from './reducer/types';
import {GeoJSON} from 'geojson';
import {ProcessingStatus} from 'components/app-processing-status';
import {SeasonPayload} from 'containers/carbon/base/base';

export interface Farm {
  agx?: boolean;
  name: string;
  id: number;
  growerName?: string;
  readOnly: boolean;
  organizationID?: number;
  subscriptionID?: number;
  fileCount?: number;
  area?: number;
  createdAt?: string;
  externalUsers?: any;
  external_service?: any;
  external_service_id?: any;
  external_service_sync_account?: any;
}

// Fields types
export interface Field {
  ID: number; // this is the kml id
  FieldID?: number; // this is field id
  Country?: string;
  Area: number;
  Name: string;
  MD5: string;
  Seasons?: Season[];
  Pivot?: boolean; // detect is the field has center pivot, field must be round to detect it automatically
  PivotCenterCoordinates?: {Lat: number; Lon: number} | null; // store the field center pivot coordinates
  files?: any;
  _selected?: boolean;
  SrcType?: ExternalService;
  external_service?: ExternalService;
  external_service_id?: string;
  generatedNMapDates?: {[tspGroupDate: string]: string}; // tsp date : date generated for
  CropName?: string;
  CropSubtype?: string;
  CropType?: string;
  CropValue?: string;
  CreateTime?: string;
  Status?: number;
  MgrsTile?: string;
  PlantingDate?: string;
  HarvestDate?: string;
  SeasonID?: number;
  NDVI?: any;
  Delta?: any;
  GDD?: any;
  SouthLat?: any;
  NorthLat?: any;
  WestLon?: any;
  EastLon?: any;
  tags?: FieldTag[];
  // Region?: any; //DEPRECATED use CountryRegion instead
  Grower?: string;
  CountryRegion?: string;
  fertilizerApplications?: Array<FertilizerApplication>;
  treeDetectionRawData?: ITreeData[]; // store the tree detection data for the whole farm
  soilLayer?: FieldSoilLayers | null; // represents different type of the field soils (currently only US fields can have this data)
}

export enum FieldTag {
  AnomalyDetection = 'anomaly_detection',
  TreeDetection = 'tree_analysis',
  NRx = 'nrx',
}
export enum ExternalService {
  Agrian = 'agrian',
  Agworld = 'agworld',
  Agx = 'agx',
  Climate = 'climate',
  Terravion = 'terravion',
  EFC = 'efc',
  JohnDeere = 'john_deere',
  Proagrica = 'proagrica',
  ProductionWise = 'productionwise',
}

// Seasons types
export type Season = {
  id?: number;
  name?: string;
  cropType: string; // summer crop
  startDate: string; // YYYY-MM-DD(T00:00:00Z)
  endDate: string; // YYYY-MM-DD(T00:00:00Z)
  params?: {
    cropSubType?: string;
    isCustomCropSubType?: boolean;
    cropSource?: string; // summer crop source
    fallTillage?: string;
    fallTillageConfidence?: boolean;
    springTillage?: string;
    springTillageConfidence?: boolean;
    winterCrop?: string;
    winterCropSource?: string;
  };
  uiName?: string;
  gdd?: any;
  ndvi?: any;
  delta?: any;
  anomalies?: any;
  kmlId?: any;
  infoExt?: TInfoExt[];
  tissueSampling?: Array<any>;
  avgNdvi?: any;
  ndviDelta?: any;
  nrx?: NRxSeason;
  _selected?: boolean;
  geometry?: GeoJSON.Geometry | GeoJSON.GeometryCollection | null;
  geometry_id?: string;
};

// TIssue samplings types
export interface SamplingPoint {
  type: string;
  geometry: GeoJSON.Point; // TODO add types for geoJSON
  properties?: SamplingPointProps;
  id: any;
  silverIndex?: any;
  rxValue?: any;
}

export interface SamplingPointGroup {
  [key: string]: SamplingPoint[];
}

export interface SamplingPointProps {
  color?: any;
  checked?: boolean;
  timedate?: string;
  growthStage?: string;
  growth_stage?: string; //old
  samplingType?: string;
  n_result2?: any;
  n_result?: any;
  title?: string;
  samplingPointType?: string;
  plantMaterialType?: string; //old
  total_K?: string;
  total_P?: string;
  Ca?: string;
  Mg?: string;
  Na?: string;
  S?: string;
  Zn_ppm?: string;
  Mn_ppm?: string;
  Fe_ppm?: string;
  Cu_ppm?: string;
  B_ppm?: string;
  Cl?: string;
  Mo_ppm?: string;

  classes?: number;
  description?: string;
  fieldName?: string;
  farmName?: string;
  Sample_ID?: string;
  Nitrogen?: string;
  Nitrate_PPM?: string;
  GroupDate?: string;
  Growth_Stage?: string;
  Classes?: number;
  SamplingType?: string;
  Description?: string;
}

// Map reducer types

export type IWholeFarm = {
  isWholeFarmView: boolean;
  wholeFarmDates: any;
  fieldsWithDates: {[md5: string]: DatesObj};
  allFarmTSP: any[];
  treeZoning: WholeFarmTreeZoning;
};

export type WholeFarmTreeZoning = {
  fields: WholeFarmTreeZoningFields;
  zones: Zone[];
};

export type WholeFarmTreeZoningFields = {
  [fieldMD5: string]: {zoningImageSrc?: string; selected?: boolean};
};

export interface ITreeDetection {
  layerType: TreesTypes;
}

export type INRecommendation = {
  method: NRecommendationMethod;
  toggled: boolean;
  zonesWithNData: Array<any>;
  nrxResult: {
    variable: {
      balanced?: NRxZonesCollection;
      max_roi?: NRxZonesCollection;
      max_yield?: NRxZonesCollection;
    };
    blanket: {
      balanced?: NRxZonesCollection;
      max_roi?: NRxZonesCollection;
      max_yield?: NRxZonesCollection;
    };
  };
  selectedObjective: NRxObjectiveType;
  nrxPopUpValues: NrxPopUpValues;
  settingsPopUpOpen: boolean;
  nrxTabRate: NrxTabRate;
};

export type NRecommendationMethod = 'apsim' | 'nutrilogic';

export enum TrendsIndex {
  CCCI = 'CCCI',
  NDVI = 'NDVI',
  NDRE = 'NDRE',
  MSAVI = 'MSAVI',
}

export type TrendsData = {
  [index in TrendsIndex]?: AnalyticData;
};

export type Analytic = {
  showKmlMean: boolean;
  showSmoothedData?: boolean; // currently it means to load data from getCropPerformanceNdviStatus()
  fieldData?: {
    CCCI?: AnalyticData;
    NDVI?: AnalyticData;
    smoothedNDVI?: AnalyticData;
    NDRE?: AnalyticData;
    MSAVI?: AnalyticData;
    categories?: string[];
    categories2?: string[];
  };
  points: AnalyticPoint[];
  arableData: AnalyticsArablePoint[];
  // arablePoints: AnalyticPoint[];
  trendsData: TrendsData;
};

export type AnalyticData = {
  categories?: string[]; // is used to store image dates in GLOBAL_FORMAT_DATE
  series?: TrendsSeries[]; // store one chart line data
  series2?: TrendsSeries[]; // useless prop from back-end, will get rid from it
};

export type AnalyticPoint = {
  latlng: {lat: number; lng: number};
  color: string;
  label?: string;
  visible?: boolean;
  arableData?: AnalyticsArablePoint[];
};

export type AnalyticsArablePoint = {
  cgdd: number | null;
  gdd_cumulative: number | null;
  crop_water_demand: number | null;
  device: string;
  kc: number;
  cl: number;
  et: number;
  tdew: number;
  etc: number;
  kml_id: number;
  lat: number;
  long: number;
  maxt: number;
  meant: number;
  mint: number;
  ndvi: number;
  precip: number;
  time: string; // YYYY-MM-DD(T00:00:00Z)

  timeAppFormat: string; // DD/MM/YYYY - added for easier search for closest date (analytics-overlay.tsx)

  color: string; // added prop to use for analytics
};

export type TrendsSeries = {
  name: string;
  label?: string; // represents AOI or high perf anomaly title or lable (if title is not set) || Arable device name
  type?: 'arable'; // for ensuring the point type
  data: {y: number | undefined}[];
};

export type TLayersSource = 'mapbox' | 'google';

export interface IMean {
  fieldId?: number;
  date?: string;
  sensor: TSensor;
  value?: number;
  status: RequestStatus;
}

export enum SourceType {
  Satellite = 'satellite',
  SatelliteHD = 'satellite_hd',
  Plane = 'plane',
  Drone = 'dron',
  Machinery = 'machinery',
}

export type SourceMeta = {
  source: SourceType;
  available: boolean;
};

// seasonGeometry - is geometry inside of the field geometry, the geometry can be attaching to the new created season
export type DrawingEntity = 'farm' | 'pivot' | 'seasonGeometry'; // specific entities, it changes the logic of saving new created/edited geometries

export type IInitialMapState = {
  sourcesMeta: SourceMeta[];
  colorSchema: string; // should be ENUM
  layersSource: TLayersSource;
  soilMapLayer: boolean; // display soil map layer
  wholeFarm: IWholeFarm;
  cropVarietyColors: {[cropVariety: string]: string}; // Populated when new fields have been loaded.
  cropVarietyMode: boolean; // Whether all the fields have the same crop type, so we can show variety granularity instead.
  treeDetection: ITreeDetection;
  highlightedFieldId?: number; // on wholeFarm view the field highlighted in panel view
  selectedFieldId: string | number;
  field: Field;
  group: Farm;
  appProcessingStatus?: ProcessingStatus;
  remoteSensingImages: Array<any>;
  remoteSensingCloudCover: number;
  remoteSensingFilterProps: SourceType[];
  remoteSensingLayersSelected: any;
  remoteSensingLayersOptions: any;
  imageLayerOpacity: number;
  edit: boolean;
  fields: Array<Field>;
  fieldsByFarmId: {[farmId: number]: {[fieldId: number]: Field}};
  fieldGeometries: {[md5: string]: GeoJSON.FeatureCollection};
  sortFields: {
    byProp: string;
    descending: boolean;
    sortType: string;
  };

  isMapBarOpen: boolean;
  wholeTableViewOpen?: TFeature;

  geometriesOnMap: boolean;
  visibleFieldKml: boolean;
  toggledButtonOnMap: ButtonOnMap;

  currentDate: string;
  currentDates: any;

  currentSeason?: Season;
  currentSeasonId: number;

  currentSensor: TSensor; // to enum
  currentSensors: TSensor[];

  currentCompareDate: string;
  currentSensorCompare: TSensor;
  currentSensorsCompare: Array<any>;

  feature: TFeature; // to enum
  isCompareOn: boolean;
  isZoning: boolean;
  zoning: IZoning;

  temperatureData: IterisTemperatureDay[];
  preparedTemperatureData: {[dateKey: string]: IterisTemperatureDay};
  histogram: any;

  featureGroupReDrawId: string;
  currentPointId: string;
  currentPoint: SamplingPoint;

  pointsGroups: SamplingPointGroup;
  pointsCurrentGroupDate: string;
  currentFieldKml: any;

  currentFieldMean: IMean; // Mean value for specific "field + date + sensor" combination.

  nitrogen: any;

  isRemoteSensingDialog: boolean;
  currentRemoteSensingImage: TInfoExt;
  currentIsSetSpectra: TSensor[];

  geometry: any[];
  editGeometry: any;

  nitrogenOverlayURL: string;

  drawControl: {
    isDrawingMode: boolean;
    isEditingMode: boolean;
    drawingModeLayerType: string;
    drawingEntity?: DrawingEntity;
    fieldId?: number | string;
  };

  // Id of area of interest, low perf anomaly or premium anomaly. For low perf it's a string.
  openPopupId?: number | string;

  anomalies: {
    anomaliesSelectedTab: AnomalyTab;
    uploadingAOIStatus: RequestStatus;
    historyOpen: boolean;
  };

  premiumAnomalies: {
    list: IAnomaly[];
    status: RequestStatus;
  };

  lowPerfAnomalies: LowPerformingAnomaliesStore;

  nRecommendation: INRecommendation;

  analytics: Analytic;

  isAustralianFieldOrBowles?: boolean;
  infoExt: TInfoExt[];
  isEditingMode: boolean;

  listsData: NrxListsData;

  // the main reason of adding this value is
  // showing notification if user enter not exist or invalid date to the 'layerDate' url get param
  originalLayerDateFormURL?: string;

  locationMarkerPosition: [number, number];
};

export type IUpdateCurrentFieldMeanAction = {
  type: ActionTypes.MAP_UPDATE_FIELD_MEAN;
  mean: IMean;
};

export type SaveFieldsAction = {
  type: ActionTypes.MAP_SAVE_FIELDS;
  farmId: number;
  fieldsById: {[fieldId: number]: Field};
  cropVarietyColors: IInitialMapState['cropVarietyColors'];
};

export type UpdateSeasonAction = {
  type: ActionTypes.UPDATE_SEASON;
  payload: SeasonPayload;
};

export type IAction = {
  type: ActionTypes;
  fields: Array<Field>;
  fieldsById: {[fieldId: number]: Field};
  group: Farm;
  cropVarietyColors: IInitialMapState['cropVarietyColors'];
  cropVarietyMode: boolean;
  payload: SeasonPayload;
  byProp: string;
  sortType: any;
  descending: boolean;
  fieldId: string | number;
  fieldMD5s: string[];
  farmId: number;
  farmName: string;
  keepTableView: boolean;
  shouldShowKmlLayer: boolean;
  value: any;
  setInitialState: boolean;
  field: Field;
  infoExt: Array<TInfoExt>;
  anomalies: any;
  anomalyIds: number[];
  lowPerformingAreas: IInitialMapState['lowPerfAnomalies'];
  date: any;
  currentSeasonId: string | number;
  currentSensor: TSensor;
  sensor: string;
  data: any;
  list: any;
  feature: TFeature;
  zoning: any;
  points: any;
  isEnableRx: boolean;
  bins: any;
  range: any;
  zones: any;
  zonesRange: any;
  t: string;
  id: number | string;
  properties: {string: any};
  position: any;
  groupDate: string;
  marker: any;
  positions: any;
  url: string;
  index: any;
  image: any;
  layer: any;
  shape: any;
  editGeometry: any;
  geometry: any;
  state: any;
  geometries: any;
  fieldGeometries: {[md5: string]: GeoJSON.FeatureCollection};
  prop: string;
  pointProperty: keyof SamplingPointProps;
  pointIds: number[];
  toAdd: any;
  filter: any;
  timedate: string;
  samplingType: string;
  growthStage: string;
  tour: string;
  values: any;
  layerType: any;
  entityType: string;
  geoJSON: any;
  allDates: any;
  kmls: any;
  fieldsWithDates: any;
  allFarmTSP: any;
  shapes: any;
  point: any;
  suggestedPoints: boolean;
  color: string;
  md5: string;
  kml: any;
  files: any;
  items: any;
  season: Season;
  onlyLatest: boolean;
  kmlId: any;
  seasons: Array<Season>;
  name: string;
  fieldData: any;
  selectedKmlIds: Array<number>;
  activity: any;
  method: string;
  nRecommendationMethod: NRecommendationMethod;
  seasonID: number;
  appId: number;
  lists: any;
  mean: IMean;
  recalculateDates: boolean; // should call filterDates() for some reason
  arrayOfFeatures: GeoJSON.Feature<any>[];
  source: string;
  markerPosition: [number, number];
};

export interface IZoning {
  isFavoriteMethod: boolean;
  classes: string;
  method: string;
  smoothing: string;
  points: any;
  isMask: boolean;
  area: number;
  // b: number;
  bufferValue: number;
  pointsKey: number;
  isEnableRx: boolean;
  selectedByUser: boolean;
  currentUnits?: {value: string; label: string}; // user can select a units to attach to the exported zones (not for NRx)

  // application info
  application: {
    method: string;
    methodLabel: string;
    timing: string;
    timingLabel: string;
    reiType: string;
    reiValue: string;
    reiTypeLabel: string;
  };

  // old histogram data
  copyZones: Zone[];
  zones: Zone[];
  zonesRange: Array<any>;
  treeZonesImage: string;
  treeZones: Zone[];
  treeZoningPercentage: number;
}

export type Zone = {
  id: number;
  value?: number | string;
  min?: number;
  mid?: number;
  max?: number;
  area?: number;
  color: string;
  percent?: number;
  isTree?: boolean;
  number?: number; // number of trees
  yield_goal?: number; // NRx param
  yield_goal_units?: string; // NRx param
  resultROISettings?: number; // NRx param
  nitrogenAmount?: number | '-'; // NRx param represents amount of pure Nitrogen per zone with units
  name?: string;
  units?: string;
  selected?: boolean; // currently is used to merge NRx zones
  merged?: 'initial' | 'merged'; // a prop that represents is the zones is merged (NRx); initial - is a zone that was merged; merged = is a result of merged zones
  mergedZonesIds?: number[]; // a prop that contains the ID's of the zones that were merged into this result zone
};

export type CloudV2 = {
  field: {
    MD5: string;
  };
  data: {
    source: 'copernicus' | 'google';
    sensing: string;
    cloudy: number;
    cloudyV2: number;
  }[];
};

export interface ITreeData {
  agv_ndvi: number;
  average_diameter: number;
  ccci_tree_detection: string;
  ccci_tree_focus: string;
  description: string;
  diameter_tree_detection: string;
  group_id: number;
  id: number;
  imagery_type: string;
  kml_id: number;
  msavi_tree_detection: string;
  msavi_tree_focus: string;
  ndre_tree_detection: string;
  ndre_tree_focus: string;
  ndvi_tree_detection: string;
  ndvi_tree_focus: string;
  rgb_tree_focus: string;
  scale: string;
  sensing_date: string;
  tree_count: number;
}

export type TreesTypes = 'focus' | 'detection' | 'default';

export type TDateLayer = {
  classify: string;
  clouded: boolean;
  date: string;
  display: string;
  name: string;
  name2: string;
  sensor: string;
  tile: string;
  type: string;
  url: string;
};

export type DatesObj = {[date: string]: TInfoExt};

export type TInfoExt = DateBase &
  Layers & {
    avgNdvi: number;
    avgCcci?: number;
    avgNdre?: number;
    avgMsavi?: number;
    ndvi14: number;
    ndvi14date: string;
    ndviDelta: number;
    treeData?: ITreeData;
    appName?: Array<string>; // can represent an app for this specific date (tree detection as example)
  };

export type DateBase = {
  Cloud: number;
  CloudV2?: number;
  Clouded: boolean;
  Date: string;
  Hidden: number;
  Type: SourceType;
  Source?: 'google' | 'copernicus';
  cloudValueModified: boolean;
};

export type Layers = {
  CCCI?: TDateLayer;
  DIAMETER?: TDateLayer;
  EC?: TDateLayer;
  ELEVATION?: TDateLayer;
  MSAVI?: TDateLayer;
  NC?: TDateLayer;
  NDRE?: TDateLayer;
  NDVI?: TDateLayer;
  NMAP?: TDateLayer;
  NDWI?: TDateLayer;
  'N-application'?: TDateLayer;
  NONE?: TDateLayer;
  PGI?: TDateLayer;
  PH?: TDateLayer;
  RGB?: TDateLayer;
  'RGB-S'?: TDateLayer;
  TCI?: TDateLayer;
  TIRS?: TDateLayer;
  PTIRS?: TDateLayer;
  'TIRS-P'?: TDateLayer;
  VVI?: TDateLayer;
  YIELD?: TDateLayer;
};

export const iterateLayers = (
  date: TInfoExt,
  handleLayer: (layer: TDateLayer, sensor: keyof Layers) => void
) => {
  date.CCCI && handleLayer(date.CCCI, 'CCCI');
  date.DIAMETER && handleLayer(date.DIAMETER, 'DIAMETER');
  date.EC && handleLayer(date.EC, 'EC');
  date.ELEVATION && handleLayer(date.ELEVATION, 'ELEVATION');
  date.MSAVI && handleLayer(date.MSAVI, 'MSAVI');
  date.NC && handleLayer(date.NC, 'NC');
  date.NDRE && handleLayer(date.NDRE, 'NDRE');
  date.NDVI && handleLayer(date.NDVI, 'NDVI');
  date.NMAP && handleLayer(date.NMAP, 'NMAP');
  date.NDWI && handleLayer(date.NDWI, 'NDWI');
  date['N-application'] && handleLayer(date['N-application'], 'N-application');
  date.NONE && handleLayer(date.NONE, 'NONE');
  date.PGI && handleLayer(date.PGI, 'PGI');
  date.PH && handleLayer(date.PH, 'PH');
  date.RGB && handleLayer(date.RGB, 'RGB');
  date['RGB-S'] && handleLayer(date['RGB-S'], 'RGB-S');
  date.TCI && handleLayer(date.TCI, 'TCI');
  date.TIRS && handleLayer(date.TIRS, 'TIRS');
  date.PTIRS && handleLayer(date.PTIRS, 'PTIRS');
  date['TIRS-P'] && handleLayer(date['TIRS-P'], 'TIRS-P');
  date.VVI && handleLayer(date.VVI, 'VVI');
  date.YIELD && handleLayer(date.YIELD, 'YIELD');
};

export type ButtonOnMap =
  | 'FieldInfo'
  | 'Weather'
  | 'Histogram'
  | 'Drawing'
  | 'LayerSource'
  | 'TreeDetection'
  | 'Empty';

export enum GeoJsonType {
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
  GeometryCollection = 'GeometryCollection',
  Point = 'Point',
  LineString = 'LineString',
  MultiPoint = 'MultiPoint',
  MultiLineString = 'MultiLineString',
}

export type IterisTemperatureDay = {
  AvgTemp: number;
  DewPointAvg: number;
  DewPointMax: number;
  DewPointMin: number;
  GrowingStage: string;
  RelativeHumidityAvg: number;
  RelativeHumidityMax: number;
  RelativeHumidityMin: number;
  ShortWaveRadiationAvg: number;
  ShortWaveRadiationMax: number;
  TBase: number;
  TotalRain: number;
  WindSpeed2mAvg: number;
  date: string;
  dayDegrees: number;
  maxTemperature: number;
  minTemperature: number;
  rainFall: number;
  solar: number;
};

export type FieldSoilLayers = GeoJSON.FeatureCollection & {
  features: GeoJSON.Feature & {id: string; properties: {map_unit_name: string; texture: string}};
};
