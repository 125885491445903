import React, {ReactElement, ReactNode} from 'react';
import {
  TableColumn,
  TableHeader,
  TableRow,
  DataTable,
  TableColumnProps,
  TableRowProps,
  DataTableProps,
  TableBody,
  TableBodyProps,
  TablePagination,
  TablePaginationProps,
  TableHeaderProps,
  FontIcon,
} from 'react-md';
import cn from 'classnames';

import './fluro-table-components.scss';
import {t} from '../../i18n-utils';

export type ColumnContentType = 'number' | 'text' | 'date';

type DefaultTableComponentProps = {
  className?: string;
  children: ReactElement<any>[] | ReactElement<any> | ReactNode;
};

type FluroTableHeaderProps = TableHeaderProps & {sticky?: boolean};
// Table Header
export const FluroTableHeader = ({
  children,
  className = '',
  sticky,
  ...rest
}: FluroTableHeaderProps) => {
  return (
    <TableHeader {...rest} className={cn({'fluro-table-header': true, sticky, [className]: true})}>
      {children}
    </TableHeader>
  );
};

// Table Row

export const FluroTableRow = ({
  hoverBg = true,
  children,
  className = '',
  ...rest
}: {hoverBg?: boolean} & TableRowProps) => {
  return (
    <TableRow
      {...rest}
      className={cn('fluro-table-row', className, {'fluro-table-row--reset-bg': !hoverBg})}
    >
      {children}
    </TableRow>
  );
};

// Table Column
type FluroTableColumnProps = TableColumnProps & {
  type?: ColumnContentType;
  nowrap?: boolean;
  title?: string;
  sorting?: boolean;
};

export const FluroTableColumn = ({
  children,
  className = '',
  type = 'text',
  nowrap = false,
  title,
  ...rest
}: FluroTableColumnProps) => {
  return (
    <TableColumn
      {...rest}
      className={cn({
        'fluro-table-column': true,
        nowrap,
        [`type-${type}`]: true,
        [className]: true,
      })}
      //@ts-ignore
      title={title}
    >
      {children}
    </TableColumn>
  );
};

// Table body

export const FluroTableBody = ({children, className = '', ...rest}: TableBodyProps) => {
  return (
    <TableBody {...rest} className={`fluro-table-body ${className}`}>
      {children}
    </TableBody>
  );
};

// Data table
type FluroDataTableProps = DataTableProps & {
  elevated?: boolean;
};

export const FluroDataTable = ({
  children,
  className = '',
  fixedDividers,
  baseId,
  plain = true, // with or without default checkboxes
  elevated = true,
  ...rest
}: FluroDataTableProps) => {
  return (
    <DataTable
      {...rest}
      baseId={baseId}
      plain={plain}
      fixedDividers={fixedDividers}
      className={cn({'fluro-data-table': true, elevated, [className]: true})}
    >
      {children}
    </DataTable>
  );
};

export const FluroTablePagination = ({
  page,
  rows,
  rowsPerPage,
  rowsPerPageLabel = t({id: 'Rows per page'}),
  onPagination,
  rowsPerPageItems,
  className = '',
  ...rest
}: TablePaginationProps) => {
  return (
    <TablePagination
      {...rest}
      className={cn({'fluro-table-pagination': true, [className]: true})}
      page={page}
      rows={rows}
      rowsPerPage={rowsPerPage}
      rowsPerPageLabel={rowsPerPageLabel}
      onPagination={onPagination}
      rowsPerPageItems={rowsPerPageItems}
    />
  );
};

type FluroTableControlsProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string;
  withElevation?: boolean;
};
export const FluroTableControls = ({
  className = '',
  withElevation = true,
  ...rest
}: FluroTableControlsProps) => {
  return (
    <div
      className={cn({
        'fluro-table-controls': true,
        [className]: true,
        'with-elevation': withElevation,
      })}
      {...rest}
    />
  );
};

export const FluroTableColumnIconButton = ({
  mdIconValue,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {mdIconValue: string}) => {
  return (
    <div className="fluro-table-icon-button" {...rest}>
      <FontIcon>{mdIconValue}</FontIcon>
    </div>
  );
};

export const FluroTableFlexWrapper = ({
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {children: React.ReactNode}) => {
  return (
    <div className="fluro-table-flex-wrapper" {...rest}>
      {children}
    </div>
  );
};
