import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {ExpandedTailIcon} from 'components/svg-icons';
import {IInitialMapState} from 'containers/map/types';
import {sensorView} from '_utils';
import {drawDateIcon} from 'containers/map/utils';
import NoData from './no-data';

type Props = {
  expandToolbar: () => void;
};

type GlobalProps = ConnectedProps<typeof connector>;

const ExpandableTopbar = ({
  expandToolbar,
  currentDate,
  currentSensor,
  currentDates,
}: GlobalProps & Props) => {
  return (
    <div className="farm-toolbar" onPointerDown={expandToolbar}>
      <div className="farm-toolbar__date-and-sensor">
        {Object.keys(currentDates).length ? (
          <>
            {/*
           drawDateIcon is inside a js file, so it can't have an optional prop `type`
          //@ts-ignore*/}
            {drawDateIcon({date: currentDate})}
            <span>{sensorView(currentSensor)}</span>
          </>
        ) : (
          <span>
            <NoData />
          </span>
        )}
      </div>
      <ExpandedTailIcon className="expandable-tail" />
    </div>
  );
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  currentDate: map.currentDate,
  currentDates: map.currentDates,
  currentSensor: map.currentSensor,
});

const connector = connect(mapStateToProps);

export default connector(ExpandableTopbar);
