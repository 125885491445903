import * as React from 'react';
import {useState, useMemo, useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FontIcon, Button, SelectionControl} from 'react-md';
import {AppStore} from 'reducers';
import {getCropLabelById} from '_utils';
import {Dropdown} from 'components/dropdown/dropdown';
import {FieldTag} from '../../types';
import {loadZoningData, toggleTreeZoningFields} from '../../actions/zoning-actions';
import {getFieldSeason} from '../../utils/field';
import {reportError} from '../../../error-boundary';
import {CropSelectMd, FluroAutocomplete, FluroButton} from 'components';
import {selectFieldSeason, setCurrentFieldId} from '../../actions';
import {FormattedMessage, t} from 'i18n-utils';

export const FieldsDropdown = () => {
  const dispatch = useDispatch();
  const fields = useSelector((s: AppStore) => s.map.fields);
  const field = useSelector((s: AppStore) => s.map.field);
  const wholeFarm = useSelector((s: AppStore) => s.map.wholeFarm);
  const [selectedCropType, setSelectedCropType] = useState('all');
  const [selectedCropVariety, setSelectedCropVariety] = useState('all');

  const dropDownButtonText = useRef();

  const treeFields = useMemo(
    () =>
      fields.filter(f => {
        return f.tags?.includes(FieldTag.TreeDetection);
      }),
    [fields, wholeFarm.treeZoning.fields]
  );

  const cropTypes = useMemo(
    function calculateCropTypes() {
      const cropTypeValues: {[cropName: string]: boolean} = {};

      treeFields.forEach(f => {
        const cropTypeValue = getFieldSeason(f.SeasonID, f.ID)?.cropType;
        if (cropTypeValue) {
          cropTypeValues[cropTypeValue] = true;
        } else {
          reportError(`Unknown tree crop type = ${cropTypeValue}`);
        }
      });

      let cropTypes = Object.keys(cropTypeValues).map(cropValue => ({
        value: cropValue,
        label: t({id: 'cropValue'}),
      }));

      if (cropTypes.length > 1) {
        // add all crops only if there is more than one crop type
        cropTypes.unshift({
          value: 'all',
          label: t({id: 'All crops'}),
        });
      }

      if (cropTypes.length && !cropTypes.find(c => c.value === selectedCropType)) {
        // reset the selected crop type if the current is not in the range for some reason
        setSelectedCropType(cropTypes[0].value);
      }

      return cropTypes;
    },
    [treeFields]
  );

  const cropVarieties = useMemo(
    function calculateCropSubtype() {
      const cropVarietyValues: {[varietyName: string]: boolean} = {};

      treeFields
        .filter(f => f.CropType === getCropLabelById(selectedCropType) && f.CropSubtype)
        .forEach(f => (cropVarietyValues[f.CropSubtype] = true));

      let cropVarieties = Object.keys(cropVarietyValues).map(subtype => ({
        value: subtype,
        label: subtype,
      }));

      if (!cropVarieties.length) {
        setSelectedCropVariety('all');
        return [];
      }

      if (
        cropVarieties.length > 1 ||
        !treeFields.every(f => f.CropSubtype === cropVarieties[0].value) // if some fields doesn't have the variety
      ) {
        // add all varieties only if there is more than one crop type
        cropVarieties.unshift({value: 'all', label: 'All varieties'});
      }

      if (!cropVarieties.find(c => c.value === selectedCropVariety)) {
        // reset the selected variety if the current is not in the range
        setSelectedCropVariety(cropVarieties[0].value);
      }

      return cropVarieties;
    },
    [treeFields, selectedCropType]
  );

  const filteredFields = useMemo(() => {
    if (selectedCropType === 'all' && selectedCropVariety === 'all') return treeFields;

    return treeFields.filter(
      f =>
        f.CropType === getCropLabelById(selectedCropType) &&
        (selectedCropVariety === 'all' || f.CropSubtype === selectedCropVariety)
    );
  }, [treeFields, selectedCropType, selectedCropVariety]);

  useEffect(
    function unselectFilteredFields() {
      const fieldsToUnselect: string[] = [];
      const filteredFieldsIds = filteredFields.map(f => f.ID);
      treeFields.forEach(f => {
        if (
          !filteredFieldsIds.includes(f.ID) &&
          wholeFarm.treeZoning.fields[f.MD5]?.selected !== false
        ) {
          fieldsToUnselect.push(f.MD5);
        }
      });

      if (fieldsToUnselect.length) {
        // untoggle fields that didn't pass the filters
        dispatch(toggleTreeZoningFields(fieldsToUnselect, false));
      }
    },
    [filteredFields]
  );

  const selectedFields = useMemo(
    () => filteredFields.filter(f => wholeFarm.treeZoning.fields[f.MD5]?.selected).map(f => f.MD5),
    [filteredFields]
  );

  const onToggleField = useCallback(
    (field: string, value: boolean) => {
      const fieldsMD5 = field === 'all' ? filteredFields.map(f => f.MD5) : [field];
      dispatch(toggleTreeZoningFields(fieldsMD5, value));
    },
    [filteredFields]
  );

  const applySelection = () => {
    dispatch(loadZoningData());
    if (dropDownButtonText) {
      // @ts-ignore
      dropDownButtonText.current?.click(); // hack close the dropdown
    }
  };

  const onResetFields = useCallback(() => {
    dispatch(toggleTreeZoningFields(selectedFields, false));
  }, [selectedFields]);

  return (
    <Dropdown
      button={
        <Button raised className="more-fields-button">
          <span ref={dropDownButtonText}>
            {t({id: '{count} fields selected'}, {count: selectedFields.length})}
          </span>
          <span>
            <FontIcon className="add-more-fields-icon">keyboard_arrow_down</FontIcon>
          </span>
        </Button>
      }
    >
      <React.Fragment>
        <CropSelectMd // Crop types selector
          cropTypes={cropTypes}
          cropType={selectedCropType}
          label={''}
          onChange={setSelectedCropType}
          simplifiedMenu={true}
        />
        {cropVarieties.length !== 0 && (
          <FluroAutocomplete // crop variety selector
            id={`select-variety`}
            label={''}
            menuItems={cropVarieties}
            onAutocomplete={(variety: string) => setSelectedCropVariety(variety)}
            className={'crop-variety-select'}
            value={cropVarieties.find(v => v.value === selectedCropVariety).label}
          />
        )}
        {filteredFields.length > 1 &&
          filteredFields.length <= 10 && ( // bulk fields selector
            <div className="select-all-checkbox-container">
              <SelectionControl
                id={-1}
                name={'Select all'}
                label={
                  selectedCropType === 'all' ? t({id: 'Select whole farm'}) : t({id: 'Select all'})
                }
                type={'checkbox'}
                className={'select-all-checkbox'}
                checked={filteredFields.length === selectedFields.length}
                onChange={(value: boolean) => onToggleField('all', value)}
              />
            </div>
          )}

        {selectedFields.length > 1 && (
          <div className="reset-farms">
            <FluroButton blank raised className="reset-fields-btn" onClick={onResetFields}>
              {t({id: 'Reset fields'})}
            </FluroButton>
          </div>
        )}

        {filteredFields.map(field => (
          <div key={field.ID} className="field-item">
            <SelectionControl
              id={field.ID}
              name={field.Name}
              label={field.Name}
              type={'checkbox'}
              checked={selectedFields.includes(field.MD5)}
              onChange={(value: boolean) => onToggleField(field.MD5, value)}
            />
          </div>
        ))}

        <div className={'apply-selection-section'}>
          <Button
            onClick={applySelection}
            disabled={!filteredFields.length || !selectedFields.length}
            raised
            primary
          >
            <FormattedMessage
              id={'zoning.whole-farm-tree-apply-selection'}
              defaultMessage={'Apply selection'}
            />
          </Button>
        </div>
      </React.Fragment>
    </Dropdown>
  );
};
