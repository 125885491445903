import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import {CircularProgress} from 'react-md';
import {genKey} from '_utils';
import './index.scss';

class Img extends Component {
  img = null;
  state = {isLoaded: false};

  componentDidMount() {
    this.img = findDOMNode(this.refs.img);
    this.img && this.img.addEventListener('load', this.load.bind(this));
  }

  componentWillUnmount() {
    this.img && this.img.removeEventListener('load', this.load);
  }

  load() {
    this.setState({isLoaded: true});
  }

  render() {
    return (
      <div style={this.props.wrapperstyle ? this.props.wrapperstyle : null}>
        <img ref="img" {...this.props} src={this.props.src} alt={this.props.alt} />
        {this.state.isLoaded ? null : (
          <div className="img-circular-progress-container">
            <CircularProgress id={`img-${genKey()}`} />
          </div>
        )}
      </div>
    );
  }
}

export default Img;
