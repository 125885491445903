import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SelectField, FontIcon, TextField, Button} from 'react-md';
import {genKey, createParsedPropertiesMap, isAdmin} from '_utils';
import {useDispatch} from 'react-redux';
import {FieldSystemProp} from '../types';
import {
  setFieldPropsMapping,
  setFieldPropMapping,
  convertPropertyValue,
  addFieldChangeCurrentStep,
  bulkSetUploadFieldsProp,
  setUploadFields,
  setUploadedFieldFilesNames,
  setFieldsValueFromMappingProps,
} from 'modules/add-fields.module';
import {
  PropSelectWrapper,
  ImportTitle,
  MappingTableWrapper,
  ParsedDataTableWrapper,
} from './field-properties-parser.styled';

import 'react-virtualized/styles.css';

import {
  FluroButton,
  FluroAutocomplete,
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
} from 'components';
import {Farm} from '../../../../types';

import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from '../select-farm-component';
import {useAppSelector} from '_hooks';

export const systemProperties = [
  FieldSystemProp.Id,
  FieldSystemProp.Checked,
  FieldSystemProp.FarmId,
  FieldSystemProp.FileName,
  FieldSystemProp.NewFarmName,
  FieldSystemProp.Area,
];

const FieldPropertiesParser = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const {
    formState: {errors},
    control,
    watch,
  } = useForm<{
    farmName: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {farmName: ''},
  });

  const geoJSONs = useAppSelector(state => state.addFields.geoJsonFiles);
  const farmId = useAppSelector(state => state.map.group.id);
  const farms = useAppSelector(state => state.farms.list);
  const propsMap = useAppSelector(state => state.addFields.propsMapping);
  const uploadedFileNames = useAppSelector(state => state.addFields.uploadedFieldFilesNames);
  const [isNewFarm, setIsNewFarmState] = useState(false);
  const values = watch();

  const onClearUpload = () => {
    dispatch(setUploadFields([]));
    dispatch(setUploadedFieldFilesNames([]));
    dispatch(addFieldChangeCurrentStep('select-files-to-upload'));
  };

  const currentFarm = useMemo(
    () => farms.filter((f: Farm) => !f.readOnly).find(f => f.id === farmId),
    [farmId, farms]
  );

  const [fieldParseIndex, setFieldParseIndex] = useState(0);

  const [previewRowsNumber, setPreviewRowsNumber] = useState(3);

  const previewItems = useMemo(() => {
    const items = [];
    for (let i = 1; i <= geoJSONs.length; i++) {
      items.push({value: i, label: t({id: '{count} row(s)'}, {count: i})});
    }

    return items;
  }, [geoJSONs]);

  const properties = useMemo(() => {
    return geoJSONs[fieldParseIndex]?.properties || {};
  }, [geoJSONs.length, fieldParseIndex]);

  const propKeys = useMemo(
    // filter system props
    () => {
      return Object.keys(properties).filter(k => !systemProperties.includes(k as any));
    },
    [properties]
  );

  const propKeysMenuItem = useMemo(() => {
    return [
      {label: t({id: '-- File name --'}), value: FieldSystemProp.FileName},
      ...propKeys.map(k => ({label: k, value: k})),
    ];
  }, [propKeys]);

  const farmPropKeysMenuItems = useMemo(() => {
    if (currentFarm) {
      return [
        {
          label: t({id: 'Upload to current farm {farmName}'}, {farmName: currentFarm.name}),
          // we us random value - '__currentFarm__'+id for create not exist property to trigger select current farm at the farm select dropdown
          value: '__currentFarm__',
        },
        ...propKeysMenuItem,
      ];
    }
    return propKeysMenuItem;
  }, [currentFarm, propKeysMenuItem]);

  useEffect(() => {
    dispatch(setFieldPropsMapping(createParsedPropertiesMap(propKeys)));
  }, [propKeys]);

  const onChangePropsMap = useCallback((key: string, value: string) => {
    dispatch(setFieldPropMapping(key, value));
  }, []);

  const slicedGeoJsonObjects = useMemo(() => geoJSONs.slice(0, previewRowsNumber), [
    geoJSONs,
    previewRowsNumber,
    propKeys,
  ]);

  const columnType = (prop: string) =>
    prop?.includes('id') || prop?.includes('area') ? 'number' : 'text';

  const moveToTheNextStep = () => {
    if (isNewFarm && values.farmName) {
      // apply the new farm name
      dispatch(
        bulkSetUploadFieldsProp(
          geoJSONs.map(f => f.properties.id),
          FieldSystemProp.NewFarmName,
          values.farmName
        )
      );
    } else if (propsMap.farmName.includes('__currentFarm__') && currentFarm) {
      // apply the current farm id
      dispatch(
        bulkSetUploadFieldsProp(
          geoJSONs.map(f => {
            return f?.properties?.id;
          }),
          FieldSystemProp.FarmId,
          currentFarm.id
        )
      );
    } else {
      dispatch(setFieldsValueFromMappingProps('farmName'));
    }

    dispatch(setFieldsValueFromMappingProps('fieldName'));
    dispatch(addFieldChangeCurrentStep('view-fields-from-files'));
  };

  return (
    <ParsedDataTableWrapper>
      {uploadedFileNames.length > 1 ? (
        <PropSelectWrapper first>
          <SelectField
            id={`parse-structure-select-${id}`}
            label={t({id: 'Select the file you want to use to parse the properties'})}
            placeholder={t({id: 'Select field'})}
            menuItems={uploadedFileNames.map((name, i) => ({label: name, value: i}))}
            simplifiedMenu={true}
            value={fieldParseIndex}
            onChange={(v: number) => setFieldParseIndex(v)}
          />
        </PropSelectWrapper>
      ) : null}

      {geoJSONs.length > 1 ? (
        <PropSelectWrapper first>
          <SelectField
            id={`parse-structure-select-${id}`}
            label={t({id: 'Select rows number to the preview'})}
            placeholder={t({id: 'Select rows number'})}
            menuItems={previewItems}
            simplifiedMenu={true}
            value={previewRowsNumber}
            onChange={(v: number) => setPreviewRowsNumber(v)}
          />
        </PropSelectWrapper>
      ) : null}

      <ImportTitle>{t({id: 'Here is a sample of your data'})}</ImportTitle>

      <MappingTableWrapper>
        <FluroDataTable>
          <FluroTableHeader>
            <FluroTableRow>
              {propKeys.map((prop, i) => {
                return (
                  <FluroTableColumn type={columnType(prop)} adjusted={false} key={prop + i}>
                    {prop}
                  </FluroTableColumn>
                );
              })}
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {slicedGeoJsonObjects.map(obg => (
              <FluroTableRow key={obg.properties.id}>
                {propKeys.map((prop, i) => (
                  <FluroTableColumn
                    type={columnType(prop)}
                    adjusted={false}
                    key={`${prop}-val-${i}`}
                  >
                    {convertPropertyValue(obg.properties[prop])}
                  </FluroTableColumn>
                ))}
              </FluroTableRow>
            ))}
          </FluroTableBody>
        </FluroDataTable>
      </MappingTableWrapper>

      <ImportTitle>Select columns to import</ImportTitle>

      <div>
        {isNewFarm ? (
          <Controller
            name="farmName"
            control={control}
            render={({field: {value, onChange}}) => (
              <TextField
                id="add-new-farm-input-name-2"
                placeholder={t({id: 'Farm name'})}
                label={t({id: 'Farm name'})}
                errorText={errors?.farmName?.message}
                error={!!errors.farmName}
                value={value}
                onChange={(value: string) => onChange(value)}
                inlineIndicator={
                  <FontIcon
                    style={{right: 0, cursor: 'pointer'}}
                    onClick={() => setIsNewFarmState(false)}
                  >
                    clear
                  </FontIcon>
                }
              />
            )}
          />
        ) : (
          <FluroAutocomplete
            id={'select-farm-name'}
            menuItems={farmPropKeysMenuItems}
            label={t({id: 'Farm name'})}
            value={farmPropKeysMenuItems.find(item => item.value === propsMap.farmName)?.label}
            onAutocomplete={(v: any) => onChangePropsMap('farmName', v)}
            simplifiedMenu={false}
          />
        )}

        {!isNewFarm && (
          <div className={'d-flex justify-end'}>
            <FluroButton
              className="add-more-btn"
              iconEl={<FontIcon>add_circle_outline</FontIcon>}
              onClick={() => setIsNewFarmState(true)}
              raised
              blank
            >
              {t({id: 'Add a new farm'})}
            </FluroButton>
          </div>
        )}

        <FluroAutocomplete
          id={'select-field-name'}
          menuItems={propKeysMenuItem}
          label={t({id: 'Field name'})}
          value={propKeysMenuItem.find(item => item.value === propsMap.fieldName)?.label}
          onAutocomplete={(v: any) => onChangePropsMap('fieldName', v)}
          simplifiedMenu={false}
        />
      </div>

      <div className="new-fields-nav-container sticky">
        <FluroButton raised blank noPadding onClick={onClearUpload}>
          {t({id: 'Back'})}
        </FluroButton>

        <Button
          id={'go-next'}
          raised
          disabled={isNewFarm && !!errors.farmName}
          primary
          onClick={moveToTheNextStep}
        >
          {t({id: 'Next'})}
        </Button>
      </div>
    </ParsedDataTableWrapper>
  );
};

export default FieldPropertiesParser;
