import {t} from 'i18n-utils';
import * as React from 'react';
import {sum} from '_utils/pure-utils';
import {LegendList} from 'components/legend-list/legend-list';
import {Card} from 'components/card/card';
import {useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {Aggregation} from './types';
import {selectMeasurement} from '_utils';
import {
  ChartData,
  StackedSingleBarChart,
} from 'components/stacked-single-bar-chart/stacked-single-bar-chart';

export const CropPerformanceCard = ({
  title,
  subtitle,
  active,
  selectedLabels,
  selectedCountLabel,
  aggregation,
  data,
  info,
  onWidgetClick,
  onShowTooltip,
  onFilterClear,
  onFilterToggle,
}: {
  title: string;
  subtitle?: string;
  active: boolean;
  selectedLabels: string[];
  selectedCountLabel: string;
  aggregation: Aggregation;
  data: ChartData;
  info?: JSX.Element;
  onWidgetClick: () => void;
  onShowTooltip?: (id: string, content: JSX.Element) => void;
  onFilterClear?: () => void;
  onFilterToggle?: (value: string) => void;
}) => {
  const isReportView = useSelector((store: AppStore) => store.global?.isReportView);
  const total = sum(data.values);
  return (
    <Card
      active={active}
      title={title}
      subtitle={subtitle}
      resetFilterDisabled={selectedLabels.length === 0}
      info={info}
      onClick={onWidgetClick}
      onFilterClear={onFilterClear}
      isReportView={isReportView}
    >
      <StackedSingleBarChart
        data={data}
        selectedLabels={selectedLabels}
        onFilterToggle={onFilterToggle}
        onShowTooltip={onShowTooltip}
      />
      <LegendList
        selectedCount={selectedCountLabel}
        onClick={onFilterToggle}
        list={data.labels.map((label, i) => ({
          label,
          value: label,
          deselected: selectedLabels.length && !selectedLabels.includes(label),
          backgroundColor: data.colors[i],
          percentage: Math.round((data.values[i] / total) * 100),
          count:
            aggregation === Aggregation.COUNT
              ? t({id: '{count} crops'}, {count: data.values[i]})
              : `(${selectMeasurement(data.values[i])})`,
        }))}
      />
    </Card>
  );
};
