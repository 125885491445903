import {unreachableError} from '_utils';
import {aggregate} from '../helpers';
import {Aggregation, fieldsVariabilityColors, FieldVariabilityStatus} from '../types';

export const getFieldsVariabilityChartData = (
  variabilities: {status: FieldVariabilityStatus; id: number; fieldArea: number}[],
  aggregation: Aggregation
) => {
  const statuses = [
    FieldVariabilityStatus.noData,
    FieldVariabilityStatus.low,
    FieldVariabilityStatus.med,
    FieldVariabilityStatus.high,
  ].filter(status => variabilities.some(field => field.status === status));

  const values: {[status: string]: number} = {};

  variabilities.forEach(field => {
    const label = field.status;
    if (!values[label]) {
      values[label] = 0;
    }
    values[label] += aggregate(aggregation, field);
  });

  return {
    colors: statuses.map(status => fieldsVariabilityColors[status]),
    labels: statuses,
    values: statuses.map(status => values[status]),
  };
};

export const getFieldsVariabilityMapChartData = (
  variabilities: {status: FieldVariabilityStatus; id: number}[]
) => {
  const statuses = [
    FieldVariabilityStatus.noData,
    FieldVariabilityStatus.low,
    FieldVariabilityStatus.med,
    FieldVariabilityStatus.high,
  ].filter(status => variabilities.some(field => field.status === status));

  const count: {[status: string]: number} = {};

  variabilities.forEach(field => {
    count[field.status] = (count[field.status] || 0) + 1;
  });

  const data = statuses.map(label => ({
    id: label,
    label,
    value: count[label],
  }));
  const getColor = (slice: any) => fieldsVariabilityColors[slice.id as FieldVariabilityStatus];
  return {data, getColor};
};
