import {t, FormattedMessage} from 'i18n-utils';
import styled from 'styled-components';
type WrapperProps = {
  appearance?: string;
};

const getAppearanceColor = (appearance?: string) => {
  switch (appearance) {
    case 'primary':
      return '#fff';

    default:
      return '#2962ff';
  }
};

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 28px;

  .md-circular-progress-path {
    stroke: ${({appearance}: WrapperProps) => getAppearanceColor(appearance)};
  }

  .md-progress {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
