import {FormattedMessage, t} from 'i18n-utils';
import React, {ReactElement, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import SimplePreloader from 'components/simple-preloader';
import {
  AccessibleFakeButton,
  FontIcon,
  MenuButton,
  SelectField,
  SelectionControl,
  TextField,
} from 'react-md';
// @ts-ignore
import {Range} from 'rc-slider';
import {setCurrentFieldId, setFeature} from '../../actions';
import {
  exportSuggestedPointsToKml,
  loadSuggestedPoints,
  loadZoningData,
  saveSuggestedPoints,
  setZoning,
  updateCopyZonesRange,
  updateZonesRange,
} from '../../actions/zoning-actions';
import {pointsSetGroupDate} from '../../actions/sampling-points';
import {setProfileSettings} from '../../../login/actions';
import {
  convertUnit,
  FeetToMeter,
  formatDate,
  formatUnit,
  getImageStatus,
  isAdminPerm,
  isCloudy,
  normalizeSensorIndex,
  sensorView,
  toFixedFloat,
} from '_utils';
import {DownloadIcon} from '../../icons';
import NitrogenRecommendation from './nitrogen-recommendation';
import Zones from './zones';
import cn from 'classnames';

import './index.scss';
import {FieldTag, SourceType, Zone} from 'containers/map/types';
import {Ln, ReadOnly, FluroButton} from 'components';
import {
  MaxAreaValue,
  MinAreaValue,
  ParamsToDelay,
  RegularZoningMethods,
  TreesZoningMethods,
  ZoningBuffers,
  ZoningClasses,
} from './zoning-constants';
import Mixpanel from '_utils/mixpanel-utils';
import ExportZoning from './export-zoning/';
import {AsyncStatusType, Status} from 'modules/ui-helpers';
import {AppStore} from 'reducers';
import {TSensor} from '../../../../types';
import {useZoningTabs, ZoningTabFeature, ZoningTabs} from './productivity-map/tabs';
import {ProductivityMap} from './productivity-map';
import {showNote} from '../../../../_actions';

type CustomZoningFormProps = {
  zones: Zone[];
  zonesRange: any[];
  range: any;
  sliderOnChange: (values: any) => void;
  sliderOnStop: (values: any) => void;
  currentSensor: TSensor;
  mainZoningLoadingStatus: Status;
};

type ContainerProps = {
  isNRX?: boolean;
  isProductivityMap?: boolean;
  children?: any;
  tabs?: any;
};

const Container = ({isProductivityMap = false, isNRX = false, children, tabs}: ContainerProps) => {
  /*
   *
   * Temporary solution to handle planet images error for zoning tab
   *
   * */
  const currentDate = useSelector((s: AppStore) => s.map.currentDate);
  const dispatch = useDispatch();

  const field = useSelector((store: AppStore) => store.map.field);

  const isTreeAnalysisEnabled = useMemo(() => field.tags?.includes(FieldTag.TreeDetection), [
    field,
  ]);

  const isPlanetImage = useMemo(() => currentDate?.endsWith(SourceType.SatelliteHD), [currentDate]);

  useMemo(() => {
    if (isPlanetImage && !isTreeAnalysisEnabled) {
      dispatch(
        showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({
            id: 'Zone management is not available on this image yet, we will enable it soon.',
          }),
          level: 'error',
        })
      );
    }
  }, [isPlanetImage, currentDate, isTreeAnalysisEnabled]);

  return (
    <div className="section-container">
      <h3 className="element-full-width tab-title">
        {t({id: 'Zone Management'})}{' '}
        {isNRX && !isProductivityMap && (
          <SimplePreloader
            statusKeys={[AsyncStatusType.NRxRecommendation, AsyncStatusType.NRXSettings]}
          />
        )}
        {!isNRX && isProductivityMap && (
          <SimplePreloader statusKeys={[AsyncStatusType.productivityMap]} />
        )}
      </h3>

      {tabs && !isPlanetImage ? tabs : null}

      {isPlanetImage && !isTreeAnalysisEnabled ? null : children}
    </div>
  );
};

const CustomZoningForm = ({
  zones,
  range,
  zonesRange,
  currentSensor,
  sliderOnChange,
  sliderOnStop,
  mainZoningLoadingStatus,
}: CustomZoningFormProps) => {
  const preparedZones = zones.filter((z, i) => i);
  return (
    <div className="custom-zoning-container">
      <div className="title">{t({id: 'Custom method'})}</div>
      <div className={'sensor-range'}>
        {sensorView(currentSensor)}{' '}
        {preparedZones.map(z => normalizeSensorIndex(z.min, range)).join(' - ')}
      </div>
      {/*<div className="breakpoints">*/}
      {/*  breakpoints: {preparedZones.map(z => normalizeSensorIndex(z.min, range)).join(', ')}*/}
      {/*</div>*/}
      {preparedZones.length ? (
        <div>
          <Range
            className={'range-slider'}
            value={preparedZones.map(z => z.min)}
            onChange={sliderOnChange}
            onAfterChange={sliderOnStop}
            pushable={true}
            min={zonesRange[0]}
            max={zonesRange[1]}
            tipFormatter={(value: any) => `${value}`}
          />
        </div>
      ) : (
        mainZoningLoadingStatus !== Status.Pending && <div>{t({id: 'No zones length'})}</div>
      )}
    </div>
  );
};

type LabelValue = {
  label: string;
  value: string;
};

type ZoningProp = 'method' | 'area' | 'classes' | 'bufferValue' | 'treeZoningPercentage';

const MapZoning = () => {
  const {switchZoningTabFeature, zoningTabFeature, zoningTabFeatures} = useZoningTabs();

  const dispatch = useDispatch();
  const mainZoningLoadingStatus = useSelector(
    (state: AppStore) => state.uiHelpers.asyncStatuses.mainZoning.status
  );
  const nRecommendation = useSelector((store: AppStore) => store.map.nRecommendation);
  const wholeFarm = useSelector((store: AppStore) => store.map.wholeFarm);
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const currentSensor = useSelector((store: AppStore) => store.map.currentSensor);
  const feature = useSelector((store: AppStore) => store.map.feature);
  const zoning = useSelector((store: AppStore) => store.map.zoning);
  const fields = useSelector((store: AppStore) => store.map.fields);
  const histogram = useSelector((store: AppStore) => store.map.histogram);
  const userSettings = useSelector((store: AppStore) => store.login.user.settings);
  const farm = useSelector((store: AppStore) => store.map.group);
  const isFullAppTour = userSettings.onboarding.fullTour;
  const measurement = userSettings.measurement;
  const treeDetectionLayerType = useSelector(
    (store: AppStore) => store.map.treeDetection.layerType
  );
  const imageStatus = useSelector((store: AppStore) => getImageStatus(store.map));
  const isCloudyState = useSelector((store: AppStore) => isCloudy(store.map));
  const isAdmin = useSelector((store: AppStore) => isAdminPerm(store.login.user.perm));

  const field = useSelector((store: AppStore) => store.map.field);
  const isTreeAnalysis = useMemo(() => treeDetectionLayerType !== 'default', [
    treeDetectionLayerType,
  ]);

  let timeOut: ReturnType<typeof setTimeout>;

  const {isFavoriteMethod, method: zoningMethod, treeZoningPercentage} = zoning;
  const favoriteMethodProps =
    userSettings.zoning.favoriteZoningMethod && userSettings.zoning.favoriteZoningMethod[farm.id];
  const isNRecommendation = nRecommendation.toggled && nRecommendation.method === 'apsim';
  const {isNotImagery} = imageStatus;

  const percentageSelect =
    isTreeAnalysis && TreesZoningMethods.find(m => m.value === zoningMethod && m.percentage);

  const validateZoningRequestData = (prop: ZoningProp, value: any) => {
    const treeZoningPercentageValue =
      prop === 'treeZoningPercentage' ? value : treeZoningPercentage;
    return isTreeAnalysis && percentageSelect
      ? !(
          !treeZoningPercentageValue ||
          treeZoningPercentageValue < 1 ||
          treeZoningPercentageValue > 99
        )
      : true;
  };
  const onChange = (prop: ZoningProp, value: any) => {
    const isFavoriteMethod = prop === 'method' ? value.includes('-favorite') : false;
    const isValidRequest = validateZoningRequestData(prop, value);

    isTreeAnalysis
      ? Mixpanel.changeTreeZoningSettings(prop, value)
      : Mixpanel.changeRegularZoningSettings(prop, value);

    if (prop === 'area') {
      value = parseFloat(value);
      if (value < MinAreaValue || value > MaxAreaValue) value = 0;

      if (zoning.method === 'custom') {
        dispatch(updateZonesRange(zoning.copyZones.filter((z, i) => i).map(z => z.min)));
      }
    }

    if (isFavoriteMethod) {
      const {area, bufferValue, classes, method} =
        userSettings.zoning.favoriteZoningMethod &&
        userSettings.zoning.favoriteZoningMethod[farm.id];
      dispatch(
        setZoning({
          isFavoriteMethod: true,
          area,
          bufferValue,
          classes,
          method: method.replace('-favorite', ''),
          selectedByUser: true,
        })
      );
    } else {
      dispatch(
        setZoning({
          [prop]: value,
          isFavoriteMethod: false,
          selectedByUser: true,
        })
      );
    }

    if (ParamsToDelay.includes(prop)) {
      timeOut && clearTimeout(timeOut);
      timeOut = setTimeout(() => isValidRequest && dispatch(loadZoningData(true)), 750);
      return;
    }

    isValidRequest && dispatch(loadZoningData(true)); // cause for tree analysis there is button to run zoning
  };

  const savePoints = () => {
    dispatch(saveSuggestedPoints());
    dispatch(setFeature('tsp'));
    dispatch(pointsSetGroupDate(moment(currentDate, 'DD/MM/YYYY').format(formatDate())));
  };

  const saveTemplate = () => {
    const favoriteMethodValue = isFavoriteMethod ? null : zoning; // allow user to toggle favorite method
    const updatedSettings = {
      ...userSettings,
      zoning: {
        ...userSettings.zoning,
        favoriteZoningMethod: {
          ...userSettings.zoning.favoriteZoningMethod,
          [farm.id]: favoriteMethodValue,
        },
      },
    };
    dispatch(setProfileSettings(updatedSettings));
    dispatch(setZoning({isFavoriteMethod: !isFavoriteMethod}));
  };

  const onChangeCustom = (values: any) => dispatch(updateCopyZonesRange(values));

  const onSliderStop = (values: any) => {
    dispatch(updateZonesRange(values));
    setTimeout(() => dispatch(loadZoningData()), 1000);
  };

  const getFavoriteMethodItem = (favoriteMethod: LabelValue) => {
    return {
      label: (
        <>
          {favoriteMethod.label}
          <FontIcon>star</FontIcon>
        </>
      ),
      value: favoriteMethod.value + '-favorite',
    };
  };

  const zoningMethods = useMemo(() => {
    const selectedMethod = isFavoriteMethod ? zoningMethod + '-favorite' : zoningMethod;
    const favoriteMethod = RegularZoningMethods.find(
      method => method.value === favoriteMethodProps?.method
    );
    let computedRegularZoningMethods: {
      label: string | ReactElement;
      value: string;
    }[] = RegularZoningMethods;
    if (favoriteMethod) {
      computedRegularZoningMethods = [
        getFavoriteMethodItem(favoriteMethod),
        ...computedRegularZoningMethods,
      ];
    }

    const selectedMethodIndex = computedRegularZoningMethods.findIndex(
      m => m.value === selectedMethod
    );

    const sortedRegularMethods = [
      // it the selected method was hidden under menu button move it to the beginning of the list to make it visible
      ...computedRegularZoningMethods.filter(
        (m, i) => selectedMethodIndex > 3 && selectedMethodIndex === i
      ),
      ...computedRegularZoningMethods.filter((m, i) =>
        selectedMethodIndex > 3 ? selectedMethodIndex !== i : true
      ),
    ];

    const methods = isTreeAnalysis ? TreesZoningMethods : sortedRegularMethods;
    return (
      <div className="methods">
        {methods.slice(0, 4).map(method => {
          return (
            <div
              key={method.value}
              onClick={() => onChange('method', method.value)}
              className={cn('method', {
                selected: method.value === selectedMethod,
                favorite: method.value.includes('favorite'),
              })}
            >
              {t({id: method.label as string})}
            </div>
          );
        })}

        {methods.length > 4 && (
          <MenuButton
            id={`other-zoning-methods`}
            className="menu-button"
            listClassName="menu-button__list"
            flat
            menuItems={methods.slice(4).map(method => {
              return (
                <AccessibleFakeButton
                  key={method.value}
                  className="menu-button__item"
                  onClick={() => onChange('method', method.value)}
                >
                  {t({id: method.label as string})}
                </AccessibleFakeButton>
              );
            })}
          >
            <FontIcon>more_vert</FontIcon>
          </MenuButton>
        )}
      </div>
    );
  }, [isTreeAnalysis, favoriteMethodProps, zoning.method, zoning.isFavoriteMethod]);

  const maxMinZonesArea = toFixedFloat(convertUnit(measurement, 'ac', field.Area / 3), 1);

  const tabs = useMemo(
    () =>
      isAdmin ? (
        <ZoningTabs
          selectedTab={zoningTabFeature}
          onTabClick={switchZoningTabFeature}
          tabs={zoningTabFeatures}
        />
      ) : null,
    [zoningTabFeature, zoningTabFeatures, isAdmin]
  );

  const toggleWholeFarm = (value: boolean) => {
    if (value) {
      dispatch(setCurrentFieldId('WholeFarm'));
    } else {
      const treeFields = fields.filter(f => f.tags.includes(FieldTag.TreeDetection));
      if (treeFields.length) {
        const theFieldToSet = field.ID
          ? treeFields.find(f => f.ID === field.ID) || treeFields[0]
          : treeFields[0]; // get the previously selected field or the first from the tree fields list
        dispatch(setCurrentFieldId(theFieldToSet.ID));
      } else {
        dispatch(setCurrentFieldId(fields[0].ID));
      }
    }
  };

  if (isNotImagery || isCloudyState) {
    return <Container />;
  }

  if (zoningTabFeature === ZoningTabFeature.ProdMap) {
    return (
      <Container tabs={tabs} isProductivityMap>
        <ProductivityMap />
      </Container>
    );
  }

  const haveSuggestedPoints = !!Object.keys(zoning.points).length;

  return (
    <Container isNRX={isNRecommendation} tabs={tabs}>
      <NitrogenRecommendation />

      {!isNRecommendation && (
        <div className="zoning-method-container">
          {isTreeAnalysis && (
            <SelectionControl // toggle to switch back to the single field view
              id="toggle-whole-farm"
              label={t({id: 'Select multiple fields'})}
              className="whole-farm-toggle"
              name={'whole-farm-toggle'}
              type={'switch'}
              onChange={toggleWholeFarm}
              checked={wholeFarm.isWholeFarmView}
              labelBefore={true}
            />
          )}

          <div className="title">
            {t({id: 'Zoning method'})}
            <Ln
              blank
              external
              className="zoning-help-method"
              href="https://help.flurosense.com/en/articles/4484906-zone-management"
            >
              <FontIcon>help_outline</FontIcon>
            </Ln>
            {!isNRecommendation && !isTreeAnalysis && (
              <ReadOnly>
                <FluroButton
                  className={cn('save-zoning-template-icon', {highlighted: isFavoriteMethod})}
                  onClick={saveTemplate}
                  tooltipPosition={'left'}
                  tooltipLabel={
                    <FormattedMessage
                      id={'zoning.save-template-btn-hover-label'}
                      defaultMessage="Save template"
                    />
                  }
                  icon
                >
                  star_border
                </FluroButton>
              </ReadOnly>
            )}
          </div>

          {zoningMethods}
        </div>
      )}

      {!isNRecommendation && !percentageSelect ? (
        <div className={'zoning-classes-container'}>
          <div className={'title'}>{t({id: 'Zones'})}</div>
          <div className="zoning-classes">
            {ZoningClasses.map(zoningClass => (
              <div
                key={zoningClass.value}
                onClick={() => onChange('classes', zoningClass.value)}
                className={cn('class', {
                  selected: zoningClass.value === zoning.classes,
                })}
              >
                {zoningClass.label}
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {zoning.method === 'custom' ? (
        <CustomZoningForm
          zones={zoning.copyZones}
          zonesRange={zoning.zonesRange}
          sliderOnChange={onChangeCustom}
          sliderOnStop={onSliderStop}
          range={histogram.range}
          currentSensor={currentSensor}
          mainZoningLoadingStatus={mainZoningLoadingStatus}
        />
      ) : null}

      {!isNRecommendation && percentageSelect ? (
        <TextField
          id="select-percentage"
          label={`% ${percentageSelect.label}`}
          lineDirection="center"
          className="select-zoning-percentage"
          type="number"
          min={1}
          max={99}
          value={treeZoningPercentage}
          error={!treeZoningPercentage || treeZoningPercentage < 1 || treeZoningPercentage > 99}
          errorText={'The value should be round number between 1 and 99'}
          onChange={val => onChange('treeZoningPercentage', parseFloat(`${val}`))}
        />
      ) : null}

      {!isNRecommendation && !isTreeAnalysis && (
        <div className={'min-zone-container'}>
          <TextField
            id="select-zoning-area"
            label={t({id: 'Min zone area'})}
            lineDirection="center"
            placeholder={t({id: 'Min zone area'})}
            className="select-min-area"
            type="number"
            max={maxMinZonesArea}
            error={zoning.area > maxMinZonesArea}
            errorText={`Min zone value can't be > ${maxMinZonesArea} ${formatUnit(
              measurement,
              'ha'
            )}`}
            value={zoning.area}
            onChange={onChange.bind(this, 'area')}
          />
          <span className={'units'}>{t({id: measurement})}</span>
        </div>
      )}

      {!isNRecommendation && !isTreeAnalysis && (
        <SelectField
          id="select-buffer-value"
          placeholder={t({id: 'Buffer value'})}
          label={t({id: 'Buffer'})}
          className="select-buffer"
          menuItems={ZoningBuffers.map(el => ({
            label:
              measurement === 'ha'
                ? FeetToMeter(el.label) + ` ${t({id: 'm'})}`
                : el.label + ` ${t({id: 'ft'})}`,
            value: el.value,
          }))}
          onChange={onChange.bind(this, 'bufferValue')}
          value={
            isFavoriteMethod && favoriteMethodProps
              ? favoriteMethodProps.bufferValue
              : zoning.bufferValue
          }
          simplifiedMenu={true}
        />
      )}

      <Zones />

      <ExportZoning />

      {!isNRecommendation && !isTreeAnalysis && zoning.zones.length !== 0 && (
        <div className={'zoning-buttons-holder'}>
          <ReadOnly>
            <FluroButton
              disabled={
                haveSuggestedPoints ||
                (farm.readOnly && !isFullAppTour) ||
                !(feature === 'zoning' && currentDate && currentSensor !== 'NONE')
              }
              onClick={() => dispatch(loadSuggestedPoints())}
              className="element-full-width"
              raised
              secondary={!haveSuggestedPoints}
            >
              <SimplePreloader
                appearance="primary"
                content={<span>{t({id: 'Suggest Sampling Points'})}</span>}
                statusKeys={[AsyncStatusType.loadSuggestedPoints]}
              />
            </FluroButton>
          </ReadOnly>

          {haveSuggestedPoints ? (
            <div className={'suggested-points-actions'}>
              <FluroButton
                onClick={savePoints}
                data-cy="Save suggested points"
                tooltipLabel={t({id: 'Save points as Tissue Sampling'})}
                tooltipPosition="top"
                className="zoning-button"
                raised
                secondary
              >
                {t({id: 'Save points'})}
              </FluroButton>

              <FluroButton
                onClick={() => dispatch(exportSuggestedPointsToKml())}
                className="zoning-button"
                iconEl={<DownloadIcon />}
                raised
                secondary
              >
                {t({id: 'Export points'})}
              </FluroButton>
            </div>
          ) : null}
        </div>
      )}
    </Container>
  );
};

export default MapZoning;
