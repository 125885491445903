import {t, FormattedMessage} from 'i18n-utils';
import React, {useState} from 'react';
import {Popup} from 'react-leaflet';
import cn from 'classnames';
import './index.scss';
import {
  getGeometryLabelByValue,
  normalizeSensorIndex,
  convertUnit,
  getMeasurement,
  formatUnit,
  isAdmin,
} from '_utils';
import {getShapeCoordinates} from '_utils/geometry';
import {
  userFacingArea,
  userFacingPriority,
} from 'containers/map/features/anomalies/anomalies-utils';
import moment from 'moment';
import {FontIcon, Button} from 'react-md';
import LabelIcon from '../label-icon';
import {Unmuted, VigorIcon} from 'containers/map/icons';
import {IAnomaly, AnomalyHistoryItem} from 'containers/map/features/anomalies/types';
import ReadOnly from 'components/read-only-container';
import PermanentlyExcluded from '../anomalies-ui/permanently-excluded';

type IProps = {
  data: any;
  LabelDropdown?: React.ComponentType<{label: string}>;
  onOpen?: () => void; // onOpen is optional since we open popup separately from the popup component
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onSnooze?: () => void;
  measurement: string; // 'ha' | 'ac'
  isAnomaly?: boolean;
  sectionName: string;
  sensingDate: string;
  type?: string;
  range: number;
  onHistoryItemClick?: (anomaly: AnomalyHistoryItem) => void;
  currentSensor: string;
};

const MapPopup = ({
  data,
  LabelDropdown,
  onOpen,
  onClose,
  onDelete,
  onEdit,
  onSnooze,
  measurement,
  isAnomaly,
  sectionName,
  sensingDate,
  // type,
  range,
  onHistoryItemClick,
  currentSensor,
}: IProps) => {
  const [historyOpened, toggleHistory] = useState(false);
  const [lng = 0, lat = 0] = getShapeCoordinates(
    data.geometry,
    // Irrigation issue rings have 2 arrays in the coordinates for inner and outer circles.
    data.properties?.label === 'irrigation_issue' && data.geometry.coordinates.length > 1
  );

  const center = {lat, lng};
  const {
    anomaly_id,
    title,
    label = 'No label',
    startDate,
    endDate,
    area,
    snoozed,
    mean,
    children,
    parents,
    priority,
    clogged_nozzle_distance,
    uploading,
    type,
    exclusion_flag,
  } = data.properties;
  const meanIsShowed = mean > 0 && !['TCI', 'TIRS'].includes(currentSensor);

  return (
    <Popup
      minWidth={160}
      position={center}
      onOpen={onOpen}
      onClose={onClose}
      className={`map-popup ${!isAnomaly ? 'map-popup__item--geometry' : ''}`}
    >
      <div className={'map-popup__container'}>
        <div className="map-popup__header">
          <div className="map-popup__label">{sectionName}</div>
          {!!anomaly_id && <div className="map-popup__id">#{anomaly_id}</div>}
          <div className="map-popup__title">
            {t({id: 'Identified'})}:
            <span>
              {endDate
                ? `${moment.utc(startDate).format('MMM D')} - ${moment
                    .utc(endDate)
                    .format('MMM D')}`
                : `${moment.utc(startDate).format('MMM D')}`}
            </span>
          </div>
        </div>
        {historyOpened ? null : (
          <div className={`map-popup__item ${!isAnomaly ? 'map-popup__item--geometry' : ''}`}>
            <div className="map-popup__item__overview-row">
              <div className="map-popup__item__date">
                {moment.utc(sensingDate).format('DD MMM YY')}
              </div>
              <div className="map-popup__item__area">{userFacingArea(area, measurement)}</div>
              {meanIsShowed && (
                <div className="map-popup__item__ndvi">
                  <VigorIcon type={type} style={{margin: 0}} />
                  {`${currentSensor}: ${normalizeSensorIndex(mean, range)}`}
                </div>
              )}
            </div>
            <div className="map-popup__item__primary-row">
              <div className="map-popup__item__icon">
                <LabelIcon label={label} />
              </div>
              <div className="map-popup__item__info">
                <div className="map-popup__item__label">
                  {LabelDropdown ? (
                    <LabelDropdown label={getGeometryLabelByValue(label)} />
                  ) : (
                    getGeometryLabelByValue(label)
                  )}
                </div>
                <div className="map-popup__item__description">{title}</div>
                {clogged_nozzle_distance ? (
                  <div className={'map-popup__item__distance'}>
                    Distance to the field center{' '}
                    {parseFloat(
                      convertUnit(getMeasurement(), 'm', clogged_nozzle_distance)
                    ).toFixed(1)}{' '}
                    {formatUnit(getMeasurement(), 'm')}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {((priority && isAnomaly) || parents || children) && (
          <div className={'d-flex justify-between'}>
            {isAnomaly && priority && !historyOpened && (
              <div className={`map-popup__info__val priority ${priority ? priority : ''}`}>
                {t({id: priority})}
              </div>
            )}
            {parents || children ? (
              <History
                anomaly={data}
                measurement={measurement}
                toggleHistory={toggleHistory}
                historyOpened={historyOpened}
                onHistoryItemClick={onHistoryItemClick}
              />
            ) : null}
          </div>
        )}
        {!isAnomaly && !uploading ? (
          <div className="map-popup__actions">
            <ReadOnly>
              <Button
                id={'map-popup__action-delete'}
                raised
                iconEl={<FontIcon iconClassName={'fas fa-trash'} />}
                onClick={onDelete}
                className="map-popup__actions__item"
              >
                {t({id: 'Delete'})}
              </Button>
            </ReadOnly>

            <ReadOnly>
              <Button
                id={'map-popup__action-edit'}
                raised
                primary
                iconEl={<FontIcon iconClassName={'fas fa-edit'} />}
                onClick={onEdit}
                className="map-popup__actions__item"
              >
                {t({id: 'Edit'})}
              </Button>
            </ReadOnly>
          </div>
        ) : null}

        {isAnomaly ? (
          <div className="map-popup__actions">
            {isAdmin() && (
              <ReadOnly>
                <Button
                  id={'map-popup__action-delete'}
                  raised
                  iconEl={<FontIcon iconClassName={'fas fa-trash'} />}
                  onClick={onDelete}
                  className="map-popup__actions__item"
                >
                  {t({id: 'Delete'})}
                </Button>
              </ReadOnly>
            )}

            {!!exclusion_flag && <PermanentlyExcluded />}
            <Button
              raised
              primary={!snoozed}
              iconEl={<Unmuted />}
              onClick={onSnooze}
              className="map-popup__actions__item"
            >
              {snoozed ? t({id: 'Unsnooze'}) : t({id: 'Snooze'})}
            </Button>
          </div>
        ) : null}
      </div>
    </Popup>
  );
};

const History = ({
  anomaly,
  measurement,
  toggleHistory,
  historyOpened,
  onHistoryItemClick,
}: {
  anomaly: IAnomaly;
  measurement: string; // 'ha' | 'ac'
  toggleHistory: (open: boolean) => void;
  historyOpened: boolean;
  onHistoryItemClick: (anomaly: AnomalyHistoryItem) => void;
}) => {
  const {parents, children} = anomaly.properties;
  return (
    <div className="map-popup__history">
      <div
        onClick={() => toggleHistory(!historyOpened)}
        className={`map-popup__history__btn ${historyOpened ? 'opened' : ''}`}
      >
        {t({id: 'Detection history'})}
      </div>
      {historyOpened && (
        <div className="map-popup__history__list">
          {parents?.map(anomaly => (
            <HistoryItem
              key={anomaly.id}
              anomaly={anomaly}
              measurement={measurement}
              handleClick={() => onHistoryItemClick(anomaly)}
            />
          ))}
          {<HistoryItem anomaly={anomaly.properties} measurement={measurement} isCurrent />}
          {children?.map(anomaly => (
            <HistoryItem
              key={anomaly.id}
              anomaly={anomaly}
              measurement={measurement}
              handleClick={() => onHistoryItemClick(anomaly)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const HistoryItem = ({
  anomaly,
  measurement,
  handleClick,
  isCurrent = false,
}: {
  anomaly: AnomalyHistoryItem;
  measurement: string; // 'ha' | 'ac'
  handleClick?: () => void;
  isCurrent?: boolean;
}) => {
  const {label, sensing_date, anomaly_size, priority} = anomaly;
  const anomalyPriority = userFacingPriority(priority, label);
  return (
    <div
      className={cn({
        'map-popup__history__list__item': true,
        'map-popup__history__list__item--history': !isCurrent,
      })}
      onClick={handleClick}
    >
      <div className="map-popup__item__icon">
        <LabelIcon label={label} />
      </div>
      <div className="map-popup__item__info">
        <div className="map-popup__item__date">{`${moment
          .utc(sensing_date)
          .format('DD MMM')}`}</div>
        <div className="map-popup__item__label">{`${label || 'No label'}`.replace(/_/g, ' ')}</div>
      </div>
      <div className="map-popup__item__additional-info">
        <div className="map-popup__area">{userFacingArea(anomaly_size, measurement)}</div>
        <div className={`map-popup__info__val priority ${anomalyPriority}`}>{anomalyPriority}</div>
      </div>
    </div>
  );
};

export default MapPopup;
