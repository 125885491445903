import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from './reducers';
import axios from 'axios';
import {reportError} from './containers/error-boundary';
import {createIntlCache, createIntl, IntlShape, MessageDescriptor} from 'react-intl';
import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat';
import moment from 'moment';
import config from '_environment';

// will add more locales if need
import 'moment/locale/ru';
import 'moment/locale/fr-ch';
import 'moment/locale/pt-br';
import {CropType} from '_reducers/global_types';

type UseLocale = {
  intl: IntlShape;
};

export enum Locales {
  EnUS = 'en-US',
  FrCH = 'fr-FR',
  RuRU = 'ru-RU',
  PtBr = 'pt-BR',
}

export const langInfo: {label: string; value: Locales; flag?: string}[] = [
  {
    label: 'English',
    value: Locales.EnUS,
    flag: 'us',
  },
  {
    label: 'Russian',
    value: Locales.RuRU,
    flag: 'ru',
  },
  {
    label: 'Portuguese (BR)',
    value: Locales.PtBr,
    flag: 'pt',
  },
];

export const ALLOWED_LOCALES: Locales[] = [Locales.EnUS, Locales.PtBr, Locales.RuRU];

export const DEFAULT_LOCALE = Locales.EnUS;

const SERVER_LOCALE_PROPS = ['label_pt-br', 'label_ru-ru'] as (keyof CropType)[];

const cache = createIntlCache();

// Create default intl object to start init the application
// locale is DEFAULT_LOCALE cuz while we loading user selected language we show defaultMessages on EN
let intlObj: IntlShape = createIntl(
  {
    locale: DEFAULT_LOCALE,
    defaultLocale: DEFAULT_LOCALE,
  },
  cache
);

export const useLocale = (): UseLocale => {
  const [intl, setIntl] = useState<IntlShape>(intlObj);

  const locale = useSelector((state: AppStore) => state.login.user.settings.langLocale);
  const isAdmin = useSelector((state: AppStore) => state.login.user.perm === 3);

  const crops = useSelector((state: AppStore) => state.global.cropTypes);
  const cropsArray: CropType[] = useMemo(() => Object.values(crops), [crops]);

  const getMessages = useCallback(async () => {
    let allowedLocale = locale;

    try {
      if (!ALLOWED_LOCALES.includes(allowedLocale)) {
        reportError(`NOT SUPPORTED LOCALE ${allowedLocale}`);
        allowedLocale = DEFAULT_LOCALE;
      }

      const {data} = await axios.get(
        `/assets/translations/${allowedLocale}.json?${config.version}`,
        {params: {__skipPreloader: true}}
      );

      const key = `label_${locale.toLowerCase()}` as keyof CropType;

      let crops: {[id: string]: any} = {};

      cropsArray.forEach(crop => {
        crops[crop.value] = crop?.[key] || crop.label;
      });

      if (!isAdmin) {
        let shouldLoadCrops = false;

        if (!cropsArray.some(crop => crop[key]) && locale !== Locales.EnUS) {
          //check if non en-US current locale not loaded
          shouldLoadCrops = true;
        } else {
          // check if current locale is en-US and it not loaded
          if (
            locale === Locales.EnUS &&
            SERVER_LOCALE_PROPS.some(key => cropsArray.some(crop => crop[key]))
          ) {
            shouldLoadCrops = true;
          }
        }

        if (shouldLoadCrops) {
          const cropResult = await axios.get(config.baseUrl + 'api/v1/crops', {
            params: {lang: locale.toLowerCase()},
            headers: {[config.authHeader]: localStorage.getItem('token') || ''},
          });

          (cropResult.data?.result || []).forEach((crop: CropType) => {
            crops[crop.value] = crop.label;
          });
        }
      }

      intlObj = createIntl(
        {
          locale,
          messages: {...crops, ...data},
          defaultLocale: DEFAULT_LOCALE,
        },
        cache
      );

      moment.locale(locale || DEFAULT_LOCALE);

      setIntl(intlObj);
    } catch (e) {
      reportError(`Lang key [${locale}], Error: ${e.message}`);
    }
  }, [locale, cropsArray]);

  useEffect(() => {
    getMessages();
  }, [locale]);

  return {
    intl,
  };
};

export const t = (
  descriptor: MessageDescriptor,
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
): string => {
  if (!descriptor.id) return (descriptor.defaultMessage as string) || '';

  return intlObj.formatMessage(
    descriptor.defaultMessage
      ? descriptor
      : {...descriptor, defaultMessage: descriptor.id as string},
    values
  );
};

export * from 'react-intl';
