import {Field} from 'containers/map/types';
import {AppStore} from 'reducers';
import {CARBON_ALLOWED_REGIONS} from '_constants';
import {CarbonStep} from './carbon-reducer';
import {validateFarms} from './base/base';
import {showNote} from '../../_actions';
import {t} from '../../i18n-utils';

export const toggleFieldsInsideFarm = (
  fieldsByFarmId: AppStore['map']['fieldsByFarmId'],
  farmId: number,
  value: boolean
) => {
  const selection: {[fieldId: number]: boolean} = {};
  Object.keys(fieldsByFarmId[farmId])
    .map(Number)
    .forEach(fieldId => {
      if (isCarbonAllowedRegion(fieldsByFarmId[farmId][fieldId].CountryRegion)) {
        selection[fieldId] = value;
      }
    });
  return selection;
};

export const getFarmIdsThatHasFields = (
  fieldsByFarmId: AppStore['map']['fieldsByFarmId'],
  fields: {[fieldId: number]: boolean}
) => {
  return Object.keys(fieldsByFarmId)
    .map(Number)
    .filter(farmId => Object.values(fieldsByFarmId[farmId]).some(field => fields[field.ID]));
};

export const isCarbonAllowedRegion = (region: string) => {
  return region && CARBON_ALLOWED_REGIONS.includes(region.toLowerCase());
};

export const checkFieldEligibility = (
  fieldsByFarmId: AppStore['map']['fieldsByFarmId'],
  fieldId: number
) => {
  let field: Field;

  Object.values(fieldsByFarmId).forEach(fields => {
    Object.values(fields).forEach(f => {
      if (f.ID === fieldId) {
        field = f;
      }
    });
  });

  return field && isCarbonAllowedRegion(field.CountryRegion);
};

export const allowToChangeToTheStep = (stepToSet: CarbonStep, displayMessage: boolean = true) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const state = getState();
  let allowStep = true;
  let message = '';
  switch (stepToSet) {
    case CarbonStep.CropPractices:
      if (state.carbon.step === CarbonStep.Credits) return true; // allow move back at any case

      allowStep = Object.values(state.carbon.enrolledFields).some(Boolean); // if some field is selected === true
      message = allowStep ? message : 'Select fields to enroll first';
      break;

    case CarbonStep.Credits:
      if (state.carbon.step === CarbonStep.Fields) {
        allowStep = dispatch(allowToChangeToTheStep(CarbonStep.CropPractices));
        if (!allowStep) break;
      }
      // use the check from carbon-panel-crop-practices-table.tsx
      allowStep = validateFarms(
        state.map.fieldsByFarmId,
        state.carbon.enrolledFields,
        state.carbon.guessedPractices
      ).isValidFarm;

      break;
  }

  if (!allowStep && displayMessage && message) {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({
          id: 'Select fields to enroll first',
        }),
        level: 'warning',
      })
    );
  }
  return allowStep;
};
