import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {SelectField, Slider} from 'react-md';
import {IInitialMapState} from 'containers/map/types';
import {getLayersItems} from 'containers/map/utils';
import {sensorModel, sensorView} from '_utils';
import {setImageOverlayOpacity, setSensor} from 'containers/map/actions';
import InfoBlock from 'components/info-block';
import {TSensor} from '../../types';
import Mixpanel from '_utils/mixpanel-utils';
import {Ln} from '../../components';

const SelectorLayer = () => {
  const dispatch = useDispatch();

  const {
    currentSeason,
    currentSensor,
    currentSensors,
    feature,
    nRecommendation,
    imageLayerOpacity,
    wholeFarm,
  } = useSelector(
    ({map}: {map: IInitialMapState}) => ({
      currentSeason: map.currentSeason,
      currentSensor: map.currentSensor,
      currentSensors: map.currentSensors,
      feature: map.feature,
      nRecommendation: map.nRecommendation,
      imageLayerOpacity: map.imageLayerOpacity,
      wholeFarm: map.wholeFarm,
    }),
    shallowEqual
  );

  const addFeatureToLayersMenu = (layersArray: any[]) => {
    layersArray.unshift(
      <li key={'select-layers-label'} className={'md-list-item'}>
        <div className={'select-layers-label'}>layer type</div>
      </li>
    );
    layersArray.push(
      <React.Fragment key={'layer-opacity'}>
        <Slider
          id="opacity-image-overlay"
          label="Layer Opacity"
          defaultValue={100}
          className={'image-opacity-slider'}
          max={100}
          min={0}
          value={imageLayerOpacity}
          onChange={value => dispatch(setImageOverlayOpacity(value))}
        />
        <li className="md-list-item opacity-item">
          <InfoBlock>
            <Ln
              blank
              external
              href="https://help.flurosense.com/en/articles/4496648-vegetation-indices"
              className="layer-help-link global-help-link"
            >
              Layer help
            </Ln>
          </InfoBlock>
        </li>
      </React.Fragment>
    );
    return layersArray;
  };

  let layers = getLayersItems();
  if (layers.length) layers = addFeatureToLayersMenu(layers);

  const selectSensor = (sensor: TSensor) => {
    Mixpanel.layerChange(sensor);
    dispatch(setSensor(sensorModel(sensor)));
  };

  if (nRecommendation.toggled && feature === 'zoning' && currentSeason.cropType !== 'cotton') {
    return null;
  }

  return (
    <SelectField
      id="select-field-index"
      placeholder={t({id: 'Select Layer'})}
      className="select-layer-control"
      inputClassName={'onboarding__select-layers'}
      menuItems={layers}
      disabled={wholeFarm.isWholeFarmView ? !layers.length : !currentSensors.length}
      value={sensorView(currentSensor)}
      onChange={selectSensor}
      simplifiedMenu={false}
      anchor={{
        x: SelectField.HorizontalAnchors.INNER_RIGHT,
        y: SelectField.VerticalAnchors.BOTTOM,
      }}
    />
  );
};

export default SelectorLayer;
