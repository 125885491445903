import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {
  AnalyticsArablePoint,
  ButtonOnMap,
  CloudV2,
  DrawingEntity,
  FieldTag,
  Farm,
  Field,
  IInitialMapState,
  Season,
  ITreeData,
  IUpdateCurrentFieldMeanAction,
  SaveFieldsAction,
  TInfoExt,
  TLayersSource,
  TreesTypes,
} from './types';
import get from 'lodash.get';
import {Dispatch} from 'redux';

import config from '_environment';
import {isFeature, isSensor, RequestStatus, TFeature, TSensor} from 'types';

import {ActionTypes} from './reducer/types';
import {DialogType as GlobalDialogType} from '_reducers/dialog';

import {setGlobalParam, showNote, toggleGlobalDialog} from '_actions';
import {AppStore} from 'reducers';

import {ActivityApi, FarmApi, FieldGeometryApi, KmlApi, NrxApi, SeasonApi, WeatherApi} from '_api';
import {
  contactSupportTeam,
  geoJsonObjectToPolygon,
  getCropLabelById,
  getCurrentImage,
  getFieldIDFromURL,
  getGeometryMeanIndex,
  getGetURLParam,
  getImagePath,
  getLocationPath,
  isAdmin,
  preselectCompareDate,
  setFieldIDToURL,
  setGetParamToURL,
  sortFieldsByProp,
} from '_utils';
import {toggleNRecommendation, runNutrilogicRecommendation} from './actions/zoning-actions';
import {loadWholeFarmData} from './actions/whole-farm-actions';
import {populateColors} from '_utils/colors';
import {preselectRange, preselectSensor, getClosestDate} from './utils';

import {
  getAreasOfInterest,
  getAndSetAreasOfInterestMeanIndex,
} from './actions/areas-of-interest-actions';

import {handleOpenTS} from './tissue-sampling-upload/reducer';

import moment from 'moment';
import {
  ALLOWED_WHOLE_FARM_FEATURES,
  GLOBAL_APP_DATE_FORMAT,
  GLOBAL_FORMAT_DATE,
  WHOLE_TABLE_VIEW_FEATURES,
} from '_constants';

import {
  AsyncStatusType,
  dialogToggle,
  DialogType,
  setRequestStatus,
  SetRequestStatusAction,
  Status,
} from 'modules/ui-helpers';
import {messages, Messages} from 'containers/info';

import {history} from '../../store';
import {geometryCollection} from '@turf/helpers';
import {disableDrawShape, disableEdit, enableDrawShape, enableEdit} from './utils/draw';
//nrx
import {
  checkForPreparedSeasons,
  convertFertilizerValues,
  convertNRxSeasonValues,
  defaultNrxSeason,
  getNrxFertilizerListItemData,
  FertilizerApplication,
  loadNrxData,
  prepareApplication,
  prepareSeasonUpdateRequests,
} from './utils/nrx-utils';

import {
  updateSeasonData,
  getFieldSeason,
  calculateFieldSeason,
  getFieldSoilLayer,
} from './utils/field';

import {onFieldsUpdated} from '_reducers/crop-performance-filter/field-filter-reducer';

import {
  loadFieldTreeData,
  beforeTreeLayerTypeChanged,
  handleTreeAnalysisSensors,
} from './utils/trees';
//@ts-ignore types is absent
import {FieldPayload} from './features/farm/types';
import {reportError} from 'containers/error-boundary';
import DEMO_FARMS_URLS from '_constants/demo-fields-urls';
import {ProcessingStatus} from 'components/app-processing-status';
import {selectFarm} from 'containers/farm/actions';
import Mixpanel from '_utils/mixpanel-utils';
import axios from 'axios';
import {getFeatureLinks} from './mini-menu/mini-menu-links';
import {FieldSystemProp} from './features/farm/new-fields/types';
//@ts-ignore
import tokml from 'tokml';

export const toggleSeasonCheckbox = (seasonID: number, value: boolean) => (dispatch: any) => {
  return dispatch({
    type: ActionTypes.MAP_SEASON_TOGGLE,
    seasonID,
    value,
  });
};

export const toggleAllSeasonsCheckboxes = (value: boolean, onlyLatest = false) => ({
  type: ActionTypes.MAP_SEASONS_TOGGLE,
  value,
  onlyLatest,
});

export const setUpdateSeasonNrxData = (seasonID: number, seasonData: any) => (dispatch: any) => {
  if (!checkForPreparedSeasons(seasonID, seasonData)) {
    return Promise.resolve(
      dispatch({
        type: ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA,
        seasonID,
        data: seasonData,
      })
    );
  }
  const requests = prepareSeasonUpdateRequests(seasonID, seasonData);
  return Promise.all(requests)
    .then(data => {
      data.forEach(({data}) => {
        dispatch({
          type: ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA,
          seasonID: data.result.seasonID,
          data: {...seasonData, ...convertNRxSeasonValues(data.result, true)},
        });
      });
      return dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Processing has started. Results will be ready in 1 to 2 hours.'}),
          level: 'success',
        })
      );
    })
    .catch(err => {
      console.log('setUpdateSeasonNrxData ERROR', err);
    });
};

export const fApplicationAdd = (data: FertilizerApplication) => (
  dispatch: any,
  getState: () => AppStore
) => {
  return NrxApi.addFertilizerApplication(
    prepareApplication(data, getState().login.user.settings.measurement)
  )
    .then(({data}) => {
      dispatch({
        type: ActionTypes.MAP_NRX_FERTILIZER_APP_ADD,
        data: {
          ...getNrxFertilizerListItemData(data.result?.typeID || 0),
          ...convertFertilizerValues(data.result, true),
        },
      });
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your application was successfully added.'}),
          level: 'success',
        })
      );
    })
    .catch(err => console.log('getFertilizerApplication err', err));
};

export const fApplicationUpdate = (fieldId: number, data: FertilizerApplication) => (
  dispatch: any,
  getState: () => AppStore
) => {
  return NrxApi.updateFertilizerApplication(
    prepareApplication(data, getState().login.user.settings.measurement)
  )
    .then(({data}) => {
      dispatch({
        type: ActionTypes.MAP_NRX_FERTILIZER_APP_UPDATE,
        fieldId,
        data: {
          ...getNrxFertilizerListItemData(data.result?.typeID || 0),
          ...convertFertilizerValues(data.result, true),
        },
      });
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your application data was successfully updated.'}),
          level: 'success',
        })
      );
    })
    .catch(err => console.log('getFertilizerApplication err', err));
};

export const fApplicationRemove = (fieldId: number, appId: number) => (dispatch: any) => {
  return NrxApi.deleteFertilizerApplication(fieldId, appId)
    .then(() => {
      dispatch({
        type: ActionTypes.MAP_NRX_FERTILIZER_APP_REMOVE,
        fieldId,
        appId,
      });
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your application was successfully removed.'}),
          level: 'success',
        })
      );
    })
    .catch(err => console.log('getFertilizerApplication err', err));
};

/**
 * Just loads the fields without any side effects.
 * Used in multifarm crop perf and carbon.
 */
export const loadFields2 = (farmId: number) => (
  dispatch: Dispatch<SaveFieldsAction | SetRequestStatusAction>
) => {
  dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Pending));
  const fieldsById: {[fieldId: number]: Field} = {};
  return KmlApi.getFileList(farmId)
    .then(({data}) => {
      dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Done));
      data.result.fields.forEach(f => {
        fieldsById[f.ID] = f;
      });
      const cropVarietyColors = populateColors(data.result.fields, {});
      dispatch({
        type: ActionTypes.MAP_SAVE_FIELDS,
        farmId,
        fieldsById,
        cropVarietyColors,
      });
      return fieldsById;
    })
    .catch(e => {
      reportError(`Couldn't loadFields2 for farmId: ${farmId}. Error: ${e}`);
      dispatch(setRequestStatus(AsyncStatusType.fieldsData, Status.Done));
      return fieldsById;
    });
};

export const loadFields = (
  groupId: number,
  fieldID: number | string | null = null,
  shouldShowKmlLayer: boolean = false
) => (dispatch: any, getState: () => AppStore) => {
  const map = getState().map;
  dispatch(hardClear());
  dispatch(handleOpenTS(false));

  return KmlApi.getFileList(groupId)
    .then(({data}) => {
      const sortedFields = data.result.fields
        ? sortFieldsByProp(data.result.fields, 'Name', 'string')
        : [];
      const fields = sortedFields.map((f: Field) => {
        // TODO: declare field interface
        const season: any = f.Seasons && f.Seasons.length ? f.Seasons[f.Seasons.length - 1] : {};

        return {
          ...f,
          GDD: season.gdd || 0,
          NDVI: season.ndvi || 0,
          Delta: season.delta || 0,
        };
      });
      const cropVarietyColors = populateColors(sortedFields, map.cropVarietyColors);

      dispatch({
        type: ActionTypes.MAP_LOAD_FIELDS,
        fields,
        group: data.result.group,
        cropVarietyColors,
      });
      dispatch(onFieldsUpdated(fields));
      if (fieldID) {
        dispatch(setCurrentFieldId(fieldID, true, shouldShowKmlLayer));
        return;
      }

      if (data.result.fields && data.result.fields.length) {
        const fieldIDFromURL = getFieldIDFromURL().fieldId;
        const resultField = sortedFields.find((f: Field) => f.ID === fieldIDFromURL);

        const fieldId =
          fieldIDFromURL === 'WholeFarm'
            ? 'WholeFarm'
            : resultField
            ? resultField.ID
            : sortedFields[0].ID;

        if (!resultField && fieldIDFromURL !== 0 && fieldId !== 'WholeFarm') {
          dispatch(
            showNote({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: (
                <FormattedMessage
                  id="theFieldIdNotExist"
                  values={{fieldIDFromURL, a: (txt: string) => contactSupportTeam(txt)}}
                />
              ),
              level: 'warning',
              autoDismiss: 20, // the message is long, let it stay longer
            })
          );
        }

        dispatch(setCurrentFieldId(fieldId, true, shouldShowKmlLayer));
      }

      if (
        map.feature === 'farm' &&
        map.wholeTableViewOpen &&
        (data.result.fields.some((f: Field) => f.tags && f.tags.includes(FieldTag.NRx)) || true)
      ) {
        //to remove true statement
        dispatch(loadNrxData());
      }
    })
    .catch(err => console.error(err.data));
};

export const loadFieldData = (fieldId: number) => async (
  dispatch: any,
  getState: () => AppStore
) => {
  const {
    map: {currentDate, group},
  } = getState();

  if (!group.id) {
    dispatch(
      showNote({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: t({id: 'farmIdNotFound'}, {id: group.id}),
        level: 'error',
      })
    );

    return Promise.resolve();
  }

  return KmlApi.getKmlOne(group.id, fieldId)
    .then(async ({data}) => {
      const {kml} = data.result;
      let fieldSoilLayer = null;
      const treeData: Array<ITreeData> = (kml?.tags || []).includes(FieldTag.TreeDetection)
        ? await loadFieldTreeData(group.id, fieldId)
        : [];

      if (kml.Country === 'United States') {
        // we have soil map data only in US
        try {
          const soilLayerResult = await KmlApi.loadFieldSoilMap([kml.MD5]);
          fieldSoilLayer = soilLayerResult?.data
            ? getFieldSoilLayer(JSON.parse(soilLayerResult?.data), kml.MD5)
            : null;
        } catch (e) {
          reportError(`loadFieldSoilMap() err= ${e}`);
        }
      }

      const cloudV2Data: CloudV2 | undefined =
        getGetURLParam('cloudv2_test') === 'true'
          ? await ActivityApi.getCloudyV2Data(kml.MD5)
          : undefined;

      dispatch({
        type: ActionTypes.MAP_LOAD_FIELD_DATA,
        field: {
          ...kml,
          generatedNMapDates: kml.Properties,
          soilLayer: fieldSoilLayer,
          Seasons:
            kml.Seasons && kml.Seasons.length
              ? updateSeasonData(kml.Seasons, treeData, cloudV2Data)
              : [],
        },
      });
      // load AOI
      fieldId && dispatch(getAreasOfInterest(fieldId));

      const urlFeature = getGetURLParam('tab');
      if (urlFeature && isFeature(urlFeature)) {
        // fix issue when the page is loaded with a specific tab in the URL, but app still store the default value
        dispatch(setFeature(urlFeature));
      }
      const urlSensor = getGetURLParam('layer');
      if (urlSensor && isSensor(urlSensor)) {
        setSensor(urlSensor);
      }

      const seasonIdFromUrl = parseInt(getGetURLParam('season'));
      if (kml.Seasons && kml.Seasons.length) {
        const preparedSeason = calculateFieldSeason(kml.Seasons, currentDate);
        if (seasonIdFromUrl) {
          const newSeason = kml.Seasons.find((s: Season) => s.id === seasonIdFromUrl);
          dispatch(selectFieldSeason(newSeason ? newSeason : preparedSeason));
        } else {
          dispatch(selectFieldSeason(preparedSeason));
        }
      }

      if (kml.tags.includes(FieldTag.NRx)) {
        dispatch(toggleNRecommendation(true, 'apsim'));
      }
    })
    .catch(err => reportError(`loadFieldData() err= ${err}`));
};

const setDemoFarmsUrlParams = (
  farmId: number,
  fieldId: number,
  currentFieldUrl: string,
  dispatch: any
) => {
  if (currentFieldUrl) {
    const {history, location} = window;
    history.replaceState(null, null, `${location.origin}${location.pathname}${currentFieldUrl}`);

    const params = new URLSearchParams(currentFieldUrl);

    const messageId = params.get('message') as keyof Messages;
    if (messageId && messages[messageId]) {
      dispatch(dialogToggle('info', true, messageId));
    }
  }
};

export const loadHistogramData = (imageURL?: string) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {histogram} = getState().map;
  const currentImage = getCurrentImage();
  if (currentImage) {
    const imagePath = getImagePath(imageURL || currentImage.url);
    const {bins, range} = histogram;

    dispatch(setRequestStatus(AsyncStatusType.histogram, Status.Pending));
    return ActivityApi.getHistogram(imagePath, bins, range)
      .then(({data}) => {
        dispatch(setRequestStatus(AsyncStatusType.histogram, Status.Done));
        dispatch(setHistogramData(data, bins, range));
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.histogram, Status.Todo));
        reportError(`loadHistogramData ERR = ${err}`);
      });
  }

  return Promise.resolve();
};

/**
 * Highlight field in the panel view when Farm tab is open.
 * Can be triggered by clicking the field boundaries on the map.
 */
export const highlightField = (fieldId?: number) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.MAP_HIGHLIGHT_FIELD,
    fieldId,
  });
};

// TODO: reduce parameters;
export const setCurrentFieldId = (
  fieldId: any,
  tableView?: boolean,
  shouldShowKmlLayer?: boolean,
  farmId?: number
) => (dispatch: any, getState: () => AppStore) => {
  const {
    map: {feature, group, treeDetection},
  } = getState();

  if (farmId != null && farmId !== group.id) {
    dispatch(selectFarm(farmId));
  }

  let keepTableView = tableView != null ? tableView : WHOLE_TABLE_VIEW_FEATURES.includes(feature);

  const hasDEmoFarmURL = !!DEMO_FARMS_URLS[`${group.id}/${fieldId}`];

  // if selected farm is demo reset url params
  if (hasDEmoFarmURL) {
    setDemoFarmsUrlParams(group.id, fieldId, DEMO_FARMS_URLS[`${group.id}/${fieldId}`], dispatch);
  }

  setFieldIDToURL(fieldId, history.push);

  dispatch({
    type: ActionTypes.SET_SELECTED_FIELD_ID,
    fieldId,
    keepTableView,
    shouldShowKmlLayer,
  });

  if (fieldId === 'WholeFarm') {
    dispatch(loadWholeFarmData());
    dispatch(toggleFieldGeometries(false));
    dispatch(toggleFieldKml(true));
    setGetParamToURL('season', null);

    const urlFeature = getGetURLParam('tab');
    if (
      urlFeature &&
      isFeature(urlFeature) &&
      (ALLOWED_WHOLE_FARM_FEATURES.includes(urlFeature) || hasDEmoFarmURL)
    ) {
      // fix issue when the page is loaded with a specific tab in the URL, but app still store the default value
      dispatch(setFeature(urlFeature));
    } else if (!(urlFeature === 'zoning' && treeDetection.layerType !== 'default')) {
      dispatch(setFeature('farm'));
    }
  }
};

export const toggleTableView = (value?: TFeature) => {
  setGetParamToURL('tableView', value ? 'true' : undefined);
  return {
    type: ActionTypes.MAP_TOGGLE_WHOLE_TABLE_VIEW,
    value,
  };
};

export const toggleMapBar = (value: boolean) => (dispatch: any, getState: () => AppStore) => {
  const addingFields = getState().dialog.currentDialog === GlobalDialogType.AddNewField;
  if (addingFields && value) return; //do not open the right panel during adding fields

  dispatch({
    type: ActionTypes.MAP_TOGGLE_BAR,
    value,
  });
};

export const changeSeason = (newSeasonId: number, newSensor = '') => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {currentSensor} = getState().map;
  const seasonInfoExt = get(getFieldSeason(newSeasonId), 'infoExt', []);
  const calculatedSensor = newSensor || getGetURLParam('layer') || currentSensor;
  const sensorToSet =
    calculatedSensor === 'NONE' && seasonInfoExt.length ? 'NDVI' : (calculatedSensor as TSensor);

  dispatch({
    type: ActionTypes.SET_CURRENT_SEASON,
    currentSeasonId: newSeasonId,
    currentSensor: sensorToSet,
  });

  dispatch(setSensor(sensorToSet));
};

export const loadWeatherData = () => (dispatch: any, getState: () => AppStore) => {
  const {selectedFieldId, field, feature, currentSeason, nRecommendation} = getState().map;

  if (selectedFieldId === 'WholeFarm') return;
  let {startDate, endDate, cropType} = currentSeason;
  if (!startDate || !endDate)
    return dispatch({
      type: ActionTypes.MAP_SET_TEMPERATURE_DATA,
      data: [],
    });

  dispatch(getArableData());

  startDate = moment(startDate, GLOBAL_FORMAT_DATE).format('YYYYMMDD');
  endDate = moment(endDate, GLOBAL_FORMAT_DATE).format('YYYYMMDD');

  dispatch(setRequestStatus(AsyncStatusType.loadFieldWeather, Status.Pending));

  WeatherApi.getRangeByCropType(field.MD5, startDate, endDate, cropType)
    .then(({data: {weather}}) => {
      dispatch(setRequestStatus(AsyncStatusType.loadFieldWeather, Status.Done));
      Mixpanel.loadWeatherData();
      dispatch({
        type: ActionTypes.MAP_SET_TEMPERATURE_DATA,
        data: weather || [],
      });

      if (
        nRecommendation.toggled &&
        feature === 'zoning' &&
        nRecommendation.method === 'nutrilogic'
      ) {
        dispatch(runNutrilogicRecommendation());
      }
    })
    .catch(err => {
      dispatch(setRequestStatus(AsyncStatusType.loadFieldWeather, Status.Todo));

      if (!axios.isCancel(err)) {
        reportError(`loadWeatherData Err ${err}`);
      }
    });
};

export const getArableData = () => async (dispatch: any, getState: () => AppStore) => {
  const {analytics, field} = getState().map;

  let arableSensorData: AnalyticsArablePoint[] = [];

  if (analytics.arableData.length) {
    arableSensorData = analytics.arableData;
  } else {
    dispatch(setRequestStatus(AsyncStatusType.analyticsFieldArableData, Status.Pending));
    arableSensorData = await ActivityApi.getArableDevicesData(field.ID);
    dispatch(setRequestStatus(AsyncStatusType.analyticsFieldArableData, Status.Done));
  }

  if (!analytics.arableData.length) {
    dispatch({
      type: ActionTypes.MAP_SET_ARABLE_SENSOR_DATA,
      data: arableSensorData,
    });
  }

  return arableSensorData;
};

export const setDate = (date: string) => (dispatch: any, getState: () => AppStore) => {
  setGetParamToURL('layerDate', date);
  const {currentDate, wholeFarm, fields} = getState().map;
  if (currentDate === date) return;

  if (date) {
    Mixpanel.changeDate(moment(date, GLOBAL_APP_DATE_FORMAT).format(GLOBAL_FORMAT_DATE));
  }

  dispatch({
    type: ActionTypes.MAP_SET_CURRENT_DATE,
    date,
  });

  // Update Areas of Interest mean index.
  dispatch(getAndSetAreasOfInterestMeanIndex());

  if (wholeFarm.isWholeFarmView) {
    // set fields season to match date is available FSB-1682
    const mDateToSet = moment(date, GLOBAL_APP_DATE_FORMAT);
    fields.forEach((f: Field) => {
      if (f.Seasons && f.Seasons.length > 1) {
        let seasonToSet: any = null;

        f.Seasons.forEach((s: Season) => {
          if (mDateToSet.isSameOrAfter(s.startDate) && mDateToSet.isSameOrBefore(s.endDate)) {
            seasonToSet = s;
          }
        });
        if (seasonToSet && seasonToSet.id !== f.SeasonID) dispatch(selectFieldSeason(seasonToSet));
      }
    });
  }
};

export const setSensor = (sensor: TSensor) => (dispatch: any, getState: () => AppStore) => {
  const {currentSensor, currentDate, currentDates} = getState().map;
  dispatch(handleTreeAnalysisSensors(sensor));
  const sensorToSet = preselectSensor(currentDate, currentDates, sensor);

  setGetParamToURL('layer', sensorToSet);
  if (currentSensor === sensorToSet) return true;

  dispatch({
    type: ActionTypes.MAP_SET_SENSOR,
    sensor: sensorToSet,
  });

  dispatch(setHistogramOptions('range', preselectRange(sensorToSet)));

  // Update Areas of Interest mean index.
  dispatch(getAndSetAreasOfInterestMeanIndex());

  return true;
};

export const setCompareDate = (date: any) => ({
  type: ActionTypes.MAP_SET_COMPARE_DATE,
  date,
});

export const setSensorCompare = (sensor: TSensor) => ({
  type: ActionTypes.MAP_SET_SENSOR_COMPARE,
  sensor,
});
export const toggleCompare = (value: boolean) => (dispatch: any, getState: () => AppStore) => {
  const map = getState().map;
  const currentCompareValue = map.isCompareOn;

  if (value !== currentCompareValue) {
    dispatch({
      type: ActionTypes.MAP_TOGGLE_COMPARE,
      value,
    });
  }

  value && dispatch(setCompareDate(preselectCompareDate(map.currentDates, map.currentDate)));
};

export const toggleFieldGeometries = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_GEOMETRIES_ON_MAP,
  value,
});

export const toggleFieldKml = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_FIELD_KML,
  value,
});

export const setFeature = (newFeature: TFeature) => (dispatch: any, getState: () => AppStore) => {
  const state = getState();
  const {feature, currentDate, currentDates} = state.map;
  if (newFeature === feature) {
    return;
  }

  // Check if the user has an access to the feature.
  const hasFarms = isAdmin() || state.farms.list.length > 0 || state.login.user.groupIds.length > 0;
  const tab = getFeatureLinks(config.featurePack, state.login.user, hasFarms).find(
    l => l.feature === newFeature
  );
  if (!tab) {
    return;
  }

  setGetParamToURL('tab', newFeature);

  // When going back from crop-performance tab, make sure the date exists in the
  // list of the current field's imagery dates. Because in crop-performance we can
  // set any date, not only the imagery date.
  if (feature === 'crop-performance') {
    dispatch(setDate(getClosestDate(currentDate, currentDates)));
  }
  dispatch({
    type: ActionTypes.MAP_SET_FEATURE,
    feature: newFeature,
  });
  if (newFeature !== 'data-layers') {
    dispatch(toggleCompare(false));
  }
};

// -------- fields section --------

export const sortFields = (byProp: string, sortType = 'number', descending?: boolean) => ({
  type: ActionTypes.MAP_SORT_FIELDS,
  byProp,
  sortType,
  descending,
});

// -------- fields section end --------

export const setHistogramData = (data: any, bins: any, range: any) => ({
  type: ActionTypes.MAP_SET_HISTOGRAM_DATA,
  data,
  bins,
  range,
});

export const setHistogramOptions = (t: any, value: any) => ({
  type: ActionTypes.MAP_SET_HISTOGRAM_OPTIONS,
  t,
  value,
});

export const setSchema = (value: string) => ({
  type: ActionTypes.MAP_SET_COLOR_SCHEMA,
  value,
});

export const setImageOverlayOpacity = (value: number | string) => ({
  type: ActionTypes.MAP_SET_IMAGE_OVERLAY_OPACITY,
  value,
});

export const setNitrogenScaleUrl = (url: string) => ({
  type: ActionTypes.MAP_SET_NITROGEN_SCALE_URL,
  url,
});

export const setNitrogenMarker = (position: any, value: any) => ({
  type: ActionTypes.MAP_SET_NITROGEN_MARKER,
  position,
  value,
});

export const removeNitrogenMarker = (index: any) => ({
  type: ActionTypes.MAP_REMOVE_NITROGEN_MARKER,
  index,
});

export const setKmlCoordinates = (data: any) => (dispatch: Dispatch<any>) => {
  const geoJson =
    data.length > 1
      ? geometryCollection(geoJsonObjectToPolygon(data, true), data[0].properties)
      : geoJsonObjectToPolygon(data)[0];

  dispatch({
    type: ActionTypes.MAP_SET_FIELD_KML,
    data: geoJson,
  });
};

export const imageSensingSetCurrent = (image: TInfoExt) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_SET_CURRENT,
  image,
});

export const imageSensingToggleDialog = (value: any) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_TOGGLE_DIALOG,
  value,
});

export const remoteSensingOnChangeLayer = (layer: string, value: boolean) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_ON_CHANGE_LAYER,
  value,
  layer,
});

export const remoteSensingSetCloudCoverFilter = (value: any) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_SET_CLOUD_COVER,
  value,
});

export const hideDateImage = (date: string, value: number) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {group, field, wholeFarm, fields} = getState().map;

  const flag = value ? 0 : 1;
  const kmls = wholeFarm.isWholeFarmView ? fields.map((f: Field) => f.ID) : [field.ID];

  return KmlApi.hideTile(group.id, field.ID, {kmlid: kmls, date, flag})
    .then(() => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: value ? t({id: 'The image was shown.'}) : t({id: 'The image was hidden.'}),
          level: 'success',
        })
      );

      dispatch({
        type: ActionTypes.MAP_REMOTE_SENSING_TOGGLE_HIDDEN,
        date,
        value: flag,
      });
    })
    .catch(console.log);
};

export const setDatesFilterByType = (filter: string, toAdd: boolean) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: ActionTypes.MAP_REMOTE_SENSING_SET_FILTER,
    filter,
    toAdd,
  });
};

export const onChangeLayerOption = (prop: any, value: any) => ({
  type: ActionTypes.MAP_REMOTE_SENSING_TOGGLE_OPTION,
  value,
  prop,
});

export const setNitrogenOverlayUrl = (url: string) => ({
  type: ActionTypes.MAP_SET_NITROGEN_OVERLAY_URL,
  url,
});

export const hardClear = (setInitialState = false) => ({
  type: ActionTypes.MAP_HARD_CLEAR_STATE,
  setInitialState,
});

export const removeField = (groupId: any, kmlIds: Array<any> = [], isCarbon = false) => (
  dispatch: any,
  getState: () => AppStore
) => {
  return Promise.all(kmlIds.map(kmlId => KmlApi.dropField(groupId, kmlId)))
    .then(() => {
      const {fields, field} = getState().map;
      const updatedFields = fields.filter((field: Field) => !kmlIds.includes(field.ID));
      const isCurrentFieldRemoved = kmlIds.includes(field.ID);
      const newFieldToSet = isCurrentFieldRemoved
        ? updatedFields.length
          ? updatedFields[0].ID
          : ''
        : field.ID;

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Field was removed.'}),
          level: 'success',
        })
      );

      dispatch({
        type: ActionTypes.MAP_DELETE_FIELD,
        kmlId: kmlIds,
        farmId: groupId,
      });

      if (!isCarbon) {
        if (!newFieldToSet) {
          dispatch(hardClear());
        } else {
          dispatch(setCurrentFieldId(newFieldToSet, true, false));
        }
      }

      dispatch(dialogToggle(DialogType.deleteDialog, false));
    })
    .catch(console.log);
};

export const addNewField = (farmId: number, field: Field | Field[]) => {
  Mixpanel.addNewField(field);

  return {
    type: ActionTypes.MAP_ADD_NEW_FIELD,
    farmId,
    field,
  };
};

export const saveFieldName = (name: string, groupId: any, kmlId: any) => (dispatch: any) => {
  return KmlApi.saveData(groupId, kmlId, {name}).then(() => {
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Field Name was changed.'}),
        level: 'success',
        position: 'bl',
      })
    );

    dispatch({
      type: ActionTypes.MAP_CHANGE_FIELD_NAME,
      name,
      kmlId,
    });
  });
};

export const toggleDrawingMode = (value: any, layerType: string, entityType?: DrawingEntity) => (
  dispatch: any
) => {
  dispatch({
    type: ActionTypes.MAP_TOGGLE_DRAWING_MODE,
    value,
    layerType,
    entityType,
  });

  value ? enableDrawShape(layerType) : disableDrawShape(layerType);
};

export const toggleEditingMode = (value: boolean) => (dispatch: any) => {
  value ? enableEdit() : disableEdit();

  dispatch({
    type: ActionTypes.MAP_TOGGLE_EDITING_MODE,
    value,
  });
};

export const toggleEditingMode2 = (value: boolean, fieldId?: number) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_TOGGLE_EDITING_MODE,
    value,
    fieldId,
  });
};

/**
 * Updates a mean value of the current index (layer) for the current field.
 */
export const updateCurrentFieldMean = () => (
  dispatch: Dispatch<IUpdateCurrentFieldMeanAction>,
  getState: () => {map: IInitialMapState}
) => {
  const {currentFieldKml, currentSensor, currentDate} = getState().map;
  const {fluro_id: kmlId} = currentFieldKml?.properties || {};
  if (!kmlId) {
    return;
  }

  dispatch({
    type: ActionTypes.MAP_UPDATE_FIELD_MEAN,
    mean: {
      fieldId: kmlId,
      date: currentDate,
      sensor: currentSensor,
      value: undefined,
      status: RequestStatus.Loading,
    },
  });

  const currentImage = getCurrentImage();
  if (
    currentSensor === 'NONE' ||
    currentSensor === 'TCI' ||
    currentSensor === 'NC' ||
    !currentImage?.url
  ) {
    return;
  }

  const imagePath = getImagePath(currentImage.url);
  getGeometryMeanIndex(imagePath, currentFieldKml, true)
    .then(({data: {Means}}: any) => {
      dispatch({
        type: ActionTypes.MAP_UPDATE_FIELD_MEAN,
        mean: {
          fieldId: kmlId,
          date: currentDate,
          sensor: currentSensor,
          value: Means[kmlId],
          status: RequestStatus.Success,
        },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: ActionTypes.MAP_UPDATE_FIELD_MEAN,
        mean: {
          fieldId: kmlId,
          date: currentDate,
          sensor: currentSensor,
          value: undefined,
          status: RequestStatus.Error,
        },
      });
      console.log('err getting mean index', err);
    });
};

export const loadFieldGeometries = (md5s: string[]) => async (
  dispatch: Dispatch,
  getState: () => AppStore
) => {
  const fieldsByFarmId = getState().map.fieldsByFarmId;

  // inject farmId to each field to use it the geometry properties
  const fields = Object.keys(fieldsByFarmId)
    .map(farmId =>
      Object.values(fieldsByFarmId[parseInt(farmId)]).map(field => ({
        ...field,
        farmId: parseInt(farmId),
      }))
    )
    .flat();

  // const fields = Object.values(fieldsByFarmId).flatMap(fields => Object.values(fields));

  dispatch(setRequestStatus(AsyncStatusType.fieldGeometries, Status.Pending));
  const fieldGeometries = await FieldGeometryApi.featureCollections(md5s).then(
    ({data}) => data.data
  );
  dispatch(setRequestStatus(AsyncStatusType.fieldGeometries, Status.Done));

  if (!fieldGeometries) {
    reportError(`No field geometries for md5s ${md5s} at data2/fields api`);
    return;
  }

  // Add fluro_id to each feature of the FeatureCollection.
  const patchedFieldGeometries: {[md5: string]: GeoJSON.FeatureCollection} = {};
  fields.forEach(f => {
    if (!fieldGeometries[f.MD5]) {
      return;
    }
    const featureCollection = {...fieldGeometries[f.MD5]};
    featureCollection.features.forEach(feature => {
      if (!feature.properties) {
        feature.properties = {};
      }
      feature.properties.fluro_id = f.ID;
      feature.properties.fluro_farm_id = f.farmId;
    });
    patchedFieldGeometries[f.MD5] = featureCollection;
  });

  dispatch(updateFieldGeometries(patchedFieldGeometries));
};

export const updateFieldGeometries = (fieldGeometries: {
  [md5: string]: GeoJSON.FeatureCollection;
}) => ({
  type: ActionTypes.MAP_SET_FIELD_GEOMETRIES,
  fieldGeometries,
});

/**
 * Toggle popup for areas of interest and anomalies (both low perf and premium).
 */
export const togglePopup = (id?: number, type: 'anomaly' | 'crop-performance' = 'anomaly') => (
  dispatch: any,
  getState: () => {map: IInitialMapState}
) => {
  const {openPopupId} = getState().map;
  if (type === 'anomaly') {
    setGetParamToURL('anomaly', !id || id === openPopupId ? undefined : id);
  }
  dispatch({
    type: ActionTypes.MAP_TOGGLE_POPUP,
    id,
  });
};

export const toggleMapButton = (value: ButtonOnMap | false) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {toggledButtonOnMap} = getState().map;
  dispatch({
    type: ActionTypes.MAP_TOGGLE_BUTTON_ON_MAP,
    value: toggledButtonOnMap === value ? 'Empty' : value,
  });
};

export const changeLayersSource = (value: TLayersSource) => ({
  type: ActionTypes.MAP_TOGGLE_LAYER_SOURCE,
  value,
});

export const toggleSoilMapLayer = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_SOIL_MAP_LAYER,
  value,
});

export const toggleFieldCheckbox = (fieldId: number | string, value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_FIELD_CHECKBOX,
  fieldId,
  value,
});

export const toggleAllFieldsCheckboxes = (value: boolean, includedIds?: Array<number>) => (
  dispatch: any,
  getState: () => {map: IInitialMapState}
) => {
  const {fields} = getState().map;
  const filteredFields = includedIds
    ? fields.filter((f: Field) => includedIds.includes(f.ID))
    : fields;

  return dispatch({
    type: ActionTypes.MAP_TOGGLE_ALL_FIELDS_CHECKBOX,
    items: filteredFields.map((field: Field) => field.MD5),
    value,
  });
};

export const selectFieldSeason = (season: Season) => (dispatch: any, getState: () => AppStore) => {
  const {selectedFieldId} = getState().map;

  if (selectedFieldId === season.kmlId) {
    dispatch(changeSeason(season.id));
    setGetParamToURL('season', season.id);
  }

  dispatch({
    type: ActionTypes.MAP_CHANGE_FIELD_SEASON,
    season,
  });
};

export const saveBulkCrop = (
  farmId: any,
  cropData: any[],
  allowOverlapping = true,
  silent = false
) => (dispatch: Dispatch<any>, getState: () => AppStore) => {
  Mixpanel.addNewSeason(cropData);
  return SeasonApi.saveBulkCrop(farmId, cropData, allowOverlapping)
    .then(() => {
      if (!silent) {
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'Your crop details were saved successfully.'}),
            level: 'success',
          })
        );
      }
    })
    .catch(err => {
      if (
        typeof err?.data?.result === 'string' && // because in some cases (a wrong start/end date) the back returns {msg: string, bulk_index: number, season_index: number}
        !err?.data?.result?.includes('season dates overlap with another season')
      ) {
        reportError(`saveBulkCrop() err= ${err}`); // show the error only if it is really error
      }

      return Promise.reject(err);
    });
};

export const saveBulkCropAndSync = (
  farmId: any,
  cropData: any[],
  allowOverlapping = true,
  silent = false
) => async (dispatch: Dispatch<any>, getState: () => AppStore) => {
  const {wholeTableViewOpen} = getState().map;
  const effectedFieldsIds = cropData.map(data => data.kml_id);

  await dispatch(saveBulkCrop(farmId, cropData, allowOverlapping, silent));

  KmlApi.getFileListByIDs(farmId, effectedFieldsIds, true)
    .then(({data}) => dispatch(syncFieldSeasons(farmId, cropData, data)))
    .then(() => (wholeTableViewOpen ? dispatch(toggleAllFieldsCheckboxes(false)) : null));
};

export const saveFieldData = (fieldData: any, groupId: number, kmlId: number) => (
  dispatch: any
) => {
  return KmlApi.saveData(groupId, kmlId, fieldData)
    .then(() => {
      dispatch({
        type: ActionTypes.MAP_CHANGE_FIELD_DATA,
        fieldData,
        kmlId,
      });
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Field data was successfully saved.'}),
          level: 'success',
        })
      );
    })
    .catch(console.log);
};

export const saveBulkFieldData = (
  type: string,
  value: any,
  farmId: number,
  selectedKmlIds: number[]
) => (dispatch: any) => {
  return Promise.all(
    selectedKmlIds.map((kmlId: number) => KmlApi.saveData(farmId, kmlId, {[type]: value}))
  )
    .then(() => {
      dispatch({
        type: ActionTypes.MAP_BULK_CHANGE_FIELD_DATA,
        fieldData: {[type]: value},
        selectedKmlIds,
        farmId,
      });

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: '{type} was successfully saved'}, {type}),
          level: 'success',
        })
      );

      dispatch(toggleAllFieldsCheckboxes(false));
    })
    .catch(console.log);
};

export const deleteSeason = (groupId: any, kmlId: any, seasonId: number) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {selectedFieldId, field} = getState().map;

  return SeasonApi.deleteSeason(groupId, kmlId, seasonId)
    .then(({data}) => {
      const restSeasons = field.Seasons.filter((s: Season) => s.id !== seasonId);

      const seasonToSet = restSeasons.length
        ? (field.SeasonID !== seasonId &&
            field.Seasons.find((s: Season) => s.id === field.SeasonID)) ||
          field.Seasons[restSeasons.length - 1]
        : {
            kmlId,
            uiName: '',
            cropType: '',
            startDate: '',
            endDate: '',
            id: 0,
          };

      dispatch({
        type: ActionTypes.MAP_DELETE_SEASON,
        seasons: restSeasons,
        kmlId,
      });

      dispatch({
        type: ActionTypes.MAP_CHANGE_FIELD_SEASON,
        season: seasonToSet,
      });

      if (selectedFieldId === field.ID) {
        dispatch(changeSeason(seasonToSet.id));
      }

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your crop was deleted successfully.'}),
          level: 'success',
        })
      );

      if (getState().map.wholeFarm.isWholeFarmView) {
        dispatch(loadWholeFarmData());
      }
    })
    .catch(err => console.log('deleteSeason', err));
};

export const deleteBulkSeason = (groupId: number, seasons: Season[], fields?: Field[]) => (
  dispatch: any
) => {
  const requests: Promise<any>[] = [];
  seasons.map(s => requests.push(SeasonApi.deleteSeason(groupId, s.kmlId, s.id)));

  return Promise.all(requests)
    .then(() => {
      const changedFields = fields.filter(f => [...seasons.map(s => s.kmlId)].includes(f.ID));

      changedFields.forEach(f => {
        const restSeasons = f.Seasons.filter(
          (s: Season) => ![...seasons.map(s => s.id)].includes(s.id)
        );
        dispatch({
          type: ActionTypes.MAP_DELETE_SEASON,
          seasons: restSeasons,
          kmlId: f.ID,
        });
      });

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'deleteSeasonPlural'}, {count: seasons?.length || 0}),
          level: 'success',
        })
      );
    })
    .catch(err => reportError(`deleteBulkSeason() err= ${err}`));
};

export const syncFieldSeasons = (farmId: number, newData: any, resultFields: any) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const map = getState().map;
  const {
    field: currentField,
    group,
    wholeTableViewOpen,
    fields: oldFields,
    sortFields: {descending},
  } = map;
  const fieldsByFarmId = map.fieldsByFarmId;

  const getFieldOptions = (season: Season, fieldSeasons: Array<Season>) => {
    const {
      kmlId: ID,
      id: SeasonID,
      cropType,
      startDate: PlantingDate,
      endDate: HarvestDate,
      params,
      uiName: CropName,
      ndvi: NDVI = 0,
      delta: Delta = 0,
      gdd: GDD = 0,
    } = season;
    return {
      ID,
      CropName,
      CropSubtype: params ? params.cropSubType : '',
      CropType: getCropLabelById(cropType),
      PlantingDate,
      HarvestDate,
      SeasonID,
      Delta,
      NDVI,
      GDD,
      Seasons: fieldSeasons,
    };
  };

  const getOldField = (fieldId: number) => {
    return oldFields.find((f: Field) => f.ID === fieldId) || fieldsByFarmId[farmId][fieldId];
  };

  const result = newData.map((field: any) => {
    const fieldId = field.kml_id;
    const oldField = getOldField(fieldId);
    const isNrxField = oldField.tags && oldField.tags.includes(FieldTag.NRx);
    const changedSeason = field.seasons[0]; // ToDo add multiple seasons support
    const currentChangedLoopField = resultFields.result.fields.find((f: Field) => f.ID === fieldId);
    if (currentChangedLoopField.Seasons.length) {
      const isChangedSeason = changedSeason.id
        ? currentChangedLoopField.Seasons.find((s: Season) => s.id === changedSeason.id)
        : currentChangedLoopField.Seasons.reduce((acc: any, s: Season) =>
            s.id > acc.id ? s : acc
          ); // way to get newest seasons by season.id property

      const mergedSeasons = isNrxField
        ? currentChangedLoopField.Seasons.map((s: Season) => {
            const oldSeason = oldField.Seasons.find((_s: Season) => _s.id === s.id);
            return {
              ...s,
              nrx:
                oldSeason && oldSeason.nrx
                  ? oldSeason.nrx
                  : {
                      ...defaultNrxSeason,
                      cropSubtype: get(s, 'params.cropSubType', ''),
                      cropType: s.cropType,
                      endDate: s.endDate,
                      startDate: s.startDate,
                      seasonID: s.id,
                      kmlID: s.kmlId,
                    },
            };
          })
        : currentChangedLoopField.Seasons;

      if (fieldId === currentField.ID) {
        SeasonApi.getSeasonList(group.id, currentField.ID).then(({data}) => {
          dispatch({
            type: ActionTypes.MAP_ADD_SEASON,
            seasons: data.result || [],
          });

          dispatch(changeSeason(isChangedSeason.id));
        });
      }

      return {
        ...getFieldOptions(isChangedSeason, mergedSeasons),
      };
    }

    return {};
  });

  // Fetch the fields into the store that's used in crop performance.
  // Possibly we need to replace everything above with just this.
  dispatch(loadFields2(farmId));

  dispatch({
    type: ActionTypes.MAP_SYNC_FIELD_SEASONS,
    fields: result || [],
  });
  dispatch(sortFields(wholeTableViewOpen ? 'CropType' : 'Name', 'string', descending));
};

export const prepareToOnBoarding = () => (dispatch: any, getState: () => AppStore) => {
  const state = getState();
  const demoGroup = state.farms.list.find(
    (f: Farm) => f.name === 'Landmark_Demo' || f.name === 'Demo farm'
  );
  if (!demoGroup) return;

  dispatch(toggleFieldGeometries(false));
  dispatch(toggleTableView());

  if (state.global.currentGroupId !== demoGroup.id) {
    const location = getLocationPath();
    dispatch(setGlobalParam('currentGroupId', demoGroup.id));
    history.push(`/${location}/${demoGroup.id}`);
    dispatch(loadFields(demoGroup.id));
  }
  const interval = setInterval(() => {
    const map = getState().map;
    const isLandmarkDemo = demoGroup.name === 'Landmark_Demo';
    const farmDate = isLandmarkDemo ? '12/10/2018-dron' : '29/07/2018-satellite';
    const farmName = isLandmarkDemo ? '10 ROBY 100 HILL(37.78 ha).kml' : 'farm-1';
    const demoFarm = map.fields.find((f: Field) => f.Name === farmName);

    if (demoFarm) {
      if (map.selectedFieldId !== demoFarm.ID) dispatch(setCurrentFieldId(demoFarm.ID));
      if (map.currentDates[farmDate] && map.currentDate !== farmDate) dispatch(setDate(farmDate));
      if (map.currentSensors.length && map.currentSensor !== 'CCCI') dispatch(setSensor('CCCI'));
    }
  }, 300);

  setTimeout(() => clearInterval(interval), 3000);
};

export const setTreeLayerType = (layer: TreesTypes) => (
  dispatch: any,
  getState: () => AppStore
) => {
  dispatch(beforeTreeLayerTypeChanged(layer));

  // set selected value to the url
  setGetParamToURL('treeDetectionLayerType', `${layer}`);

  return dispatch({
    type: ActionTypes.MAP_SET_TREE_LAYER_TYPE,
    layer,
  });
};

export const goToSetUpCrop = () => (dispatch: any) => {
  dispatch(setFeature('farm'));
};

export const addPivotMarker = (formValues: FieldPayload, tableView = false) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {
    map: {
      field,
      wholeFarm: {isWholeFarmView},
    },
    global: {dialogsState},
  } = getState();
  const dialog = dialogsState.editField?.visible ? 'editField' : 'editPivotTableView';
  const fieldId = dialogsState[dialog]?.fieldId;
  if (fieldId && (field.ID !== fieldId || isWholeFarmView)) {
    dispatch(setCurrentFieldId(fieldId));
  }

  tableView && dispatch(toggleTableView());
  dispatch(toggleGlobalDialog(dialog, {visible: true, ...formValues})); // save current form values in the store
  setTimeout(
    () => {
      dispatch(toggleDrawingMode(true, 'marker', 'pivot'));
    },
    isWholeFarmView || !field.SeasonID ? 1000 : 0
  );
};

export const cancelDrawingPivotCenter = () => (dispatch: any, getState: () => AppStore) => {
  const {
    dialogsState: {editField, editPivotTableView},
  } = getState().global;
  const openDialog = editField?.visible
    ? 'editField'
    : editPivotTableView?.visible
    ? 'editPivotTableView'
    : false;

  if (openDialog) {
    dispatch(toggleGlobalDialog(openDialog, {visible: false}, true));
    dispatch(toggleDrawingMode(false, 'marker'));
  }
};

export const setAppProcessingStatus = (value: ProcessingStatus) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const currentProcessingStatus = getState().map.appProcessingStatus;
  if (value !== currentProcessingStatus) {
    dispatch({
      type: ActionTypes.MAP_SET_APP_PROCESSING_STATUS,
      value,
    });
  }
};

export const startDrawShape = (shape = 'polygon', entityType?: DrawingEntity) => (
  dispatch: any
) => {
  dispatch(toggleMapBar(false));
  dispatch(toggleDrawingMode(true, shape, entityType));
};

export const clearOriginalLayerDateFormURL = () => ({
  type: ActionTypes.CLEAR_ORIGINAL_LAYER_DATE_FROM_URL,
});

export const updateImageCloudy = (date: string, value: number) => ({
  type: ActionTypes.UPDATE_IMAGE_CLOUDY,
  date,
  value,
});

export const setLocationMarkerCoordinates = (lat: number, lon: number) => ({
  type: ActionTypes.SET_LOCATION_MARKER_COORDINATES,
  markerPosition: [lat, lon],
});

export const saveEditField = (data: GeoJSON.Feature) => async (
  dispatch: any,
  getState: () => AppStore
) => {
  try {
    const s = getState();

    const result = await FarmApi.saveFields([
      {
        farm_id: s.global.currentGroupId,
        name: s.map.field.Name,
        kml: tokml(data),
      },
    ]);

    await KmlApi.dropField(s.global.currentGroupId, s.map.field.ID);

    await dispatch(loadFields(s.global.currentGroupId, result.data.result[0].field.ID, true));
  } catch (e) {
    console.error(e);
  }
};

/*
 *
 * The below action should be used only at carbon app,
 * where one source of truth is fieldsByFarmId object
 *
 * */
export const replaceFieldAtFieldsByFarmId = (farmId: number, fieldId: number, field: Field) => ({
  type: ActionTypes.REPLACE_FIELD_AT_FIELDS_BY_FARM_ID,
  farmId,
  fieldId,
  field,
});
