import React, {useCallback, useMemo} from 'react';
import {genKey} from '_utils';
import {useDispatch} from 'react-redux';
import {dialogToggle, DialogType} from 'modules/ui-helpers';
import {t} from 'i18n-utils';
import {DialogFooter} from '../../../admin/admin.styled';
import {Button} from 'react-md';
import Dialog from '../../../../components/fluro-dialog';
import {
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
} from 'components';
import {Wrapper} from './carbon-tillage-practices-dialog.styled';
import {Tillage, tillageColor, tillageLabels} from '../../../map/features/optis/optis-types';
import {useAppSelector} from '../../../../_hooks';

export const CarbonTillagePracticesDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();
  const type =
    useAppSelector(s => s.uiHelpers.dialogs[DialogType.carbonTillagePractices].payload) ||
    'tillage';

  const onClose = useCallback(() => {
    dispatch(dialogToggle(DialogType.carbonTillagePractices, false));
  }, []);

  if (type === 'tillage') {
    return (
      <Dialog
        id={`carbon-tillage--dialog-${id}`}
        visible={true}
        title={t({id: 'Tillage practices'})}
        focusOnMount={false}
        isDraggable
        onHide={onClose}
        width={500}
        portal
        actions={
          <DialogFooter>
            <Button primary raised onClick={onClose}>
              {t({id: 'Ok'})}
            </Button>
          </DialogFooter>
        }
      >
        <Wrapper className="main-gray-900">
          <div className="tillage-title">How is tillage classified?</div>

          <div className="tillage-desc">
            Tillage practice is determined based on the percent of the field covered by residue as
            observed in satellite imagery in the fall after harvest and spring at planting. The
            residue cover percentage groupings used here were selected for consistency with
            USDA-NRCS definitions.
          </div>

          <FluroDataTable className="tbl">
            <FluroTableHeader>
              <FluroTableRow>
                <FluroTableColumn>Tillage class</FluroTableColumn>
                <FluroTableColumn>Residue %</FluroTableColumn>
                <FluroTableColumn>Residue type</FluroTableColumn>
              </FluroTableRow>
            </FluroTableHeader>
            <FluroTableBody>
              <FluroTableRow hoverBg={false}>
                <FluroTableColumn rowSpan={2}>
                  <span
                    className="circle"
                    style={{backgroundColor: tillageColor[Tillage.NoTill]}}
                  />{' '}
                  <span>{tillageLabels[Tillage.NoTill]}</span>
                </FluroTableColumn>

                <FluroTableColumn>30 - 100%</FluroTableColumn>
                <FluroTableColumn>All non corn crops</FluroTableColumn>
              </FluroTableRow>

              <FluroTableRow hoverBg={false}>
                <FluroTableColumn>50 - 100%</FluroTableColumn>
                <FluroTableColumn>Corn</FluroTableColumn>
              </FluroTableRow>

              {/* --- */}

              <FluroTableRow hoverBg={false}>
                <FluroTableColumn rowSpan={2}>
                  <span className="circle" style={{backgroundColor: tillageColor[Tillage.Low]}} />{' '}
                  <span>{tillageLabels[Tillage.Low]}</span>
                </FluroTableColumn>

                <FluroTableColumn>16 - 30%</FluroTableColumn>
                <FluroTableColumn>All non corn crops</FluroTableColumn>
              </FluroTableRow>

              <FluroTableRow hoverBg={false}>
                <FluroTableColumn>16 - 50%</FluroTableColumn>
                <FluroTableColumn>Corn</FluroTableColumn>
              </FluroTableRow>

              {/* --- */}

              <FluroTableRow hoverBg={false}>
                <FluroTableColumn>
                  <span
                    className="circle"
                    style={{backgroundColor: tillageColor[Tillage.Conventional]}}
                  />{' '}
                  <span>{tillageLabels[Tillage.Conventional]}</span>
                </FluroTableColumn>

                <FluroTableColumn>0 - 15%</FluroTableColumn>

                <FluroTableColumn>All crops</FluroTableColumn>
              </FluroTableRow>
            </FluroTableBody>
          </FluroDataTable>

          <div className="tillage-title">How is tillage estimated?</div>

          <div className="tillage-desc">
            We estimate the tillage classes based on the remotely sensed residue cover levels, and
            previous year's crop.
          </div>
        </Wrapper>
      </Dialog>
    );
  }

  if (type === 'crop') {
    return (
      <Dialog
        id={`carbon-crop--dialog-${id}`}
        visible={true}
        title={t({id: 'Winter crop'})}
        focusOnMount={false}
        isDraggable
        onHide={onClose}
        width={500}
        portal
        actions={
          <DialogFooter>
            <Button primary raised onClick={onClose}>
              {t({id: 'Ok'})}
            </Button>
          </DialogFooter>
        }
      >
        <Wrapper className="main-gray-900">
          <div className="tillage-title">How is winter crop classified ?</div>

          <div className="tillage-desc">
            Winter crop status is classified with satellite-based estimates of green cover observed
            between fall harvest and the summer crop season.
            <br />
            <br />
            Using the timing and intensity of green cover observed on the field, a classification of{' '}
            <br />
            ● (1) fallow,
            <br />
            ● (2) cover crop,
            <br />
            ● or (3) winter wheat is applied.<br />
            <br />A field is estimated to be under winter cover if at least 30% of the field area is
            identified has having significant green cover.
          </div>
          <br/>
          <div className="tillage-title">How is winter wheat differentiated from cover crop?</div>

          <div className="tillage-desc">
            Cover crop is distinguished from winter wheat by keying on the late spring and early
            summer green cover signal. Cover crops are typically terminated in the spring before the
            summer crop is planted, showing a reduction in green cover, while winter wheat is taken
            for harvest later in the spring or summer, showing a sustained green signal through that
            period.
          </div>
        </Wrapper>
      </Dialog>
    );
  }

  return null;
};
