import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {Props, Messages} from '../types';
import {TreeDetectionIcon, GlassIcon, GreenBoldText, RedBoldText} from '../icons.styled';

import InfoBlock from 'components/info-block';
import {Footer} from '../helpers';

const data: Messages = {
  186460: {
    title: 'Tracking plant status',
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            With consistent <TreeDetectionIcon /> detection of <span>blackberry plants</span> in
            high resolution (aerial here) imagery, you can track the progress of the crops and field
            variability from <b>emergence</b> through to <b>senescence</b>.
          </div>
        </div>

        <InfoBlock appearance="info">
          Browse through the dates to explore the emergence/senescence of this berry field.
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  186461: {
    title: 'Plant detection + Zoning = Insight',
    element: props => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            As you will see in this block, blueberries in the top part of the block performed better
            (<GreenBoldText>green</GreenBoldText>) compared to the bottom part of the block (
            <RedBoldText>red</RedBoldText>).
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            <b>Grouping plants</b> into <b>performance “zones” or categories</b> makes the
            variability obvious, easy to quantify, <GlassIcon />
            scout and address.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },
};

export default data;
