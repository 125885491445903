import * as React from 'react';
import {PropsWithChildren, useState} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {FontIcon} from 'react-md';
import {useSelector} from 'react-redux';
import {AppStore} from '../../reducers';
// Moved from css to styled component here. Let's give it a week or two until removing the css file.

export const Card = ({
  children,
  active,
  title,
  subtitle,
  resetFilterDisabled = true,
  info,
  onClick,
  onFilterClear,
  isReportView = false,
}: PropsWithChildren<{
  active: boolean;
  title: string;
  subtitle?: string;
  resetFilterDisabled?: boolean;
  info?: JSX.Element;
  onClick: () => void;
  onFilterClear?: () => void;
  isReportView?: boolean;
}>) => {
  const [infoExpanded, setInfoExpanded] = useState(false);
  return (
    <CardStyled onClick={onClick} className={cn({card: true, active: active})}>
      <CardTitle className="card-title">
        <CardTitleText>
          {title}
          {subtitle && <CardSubtitle>({subtitle})</CardSubtitle>}
        </CardTitleText>
        {!isReportView && (
          <Controls className="controls">
            {info && (
              <HelpButton className="info-btn" onClick={() => setInfoExpanded(e => !e)}>
                help_outline
              </HelpButton>
            )}
            {onFilterClear && (
              <ResetFilterButton
                className={'clear-filter-btn'}
                disabled={resetFilterDisabled || !onFilterClear}
                onClick={e => {
                  e.stopPropagation();
                  onFilterClear();
                }}
              >
                <img src="/assets/filter.svg" alt="filter" title="Clear filters" width="20" />
              </ResetFilterButton>
            )}
          </Controls>
        )}
      </CardTitle>
      <CardInfo>
        {infoExpanded && (
          <>
            {info}
            <CollapseButton className="collapse-btn" onClick={() => setInfoExpanded(e => !e)}>
              <FontIcon>keyboard_arrow_up</FontIcon>
            </CollapseButton>
          </>
        )}
      </CardInfo>
      {children}
    </CardStyled>
  );
};

const CardStyled = styled.div`
  background-color: #fff;
  margin: 20px 0;
  border: 1px solid #d6d8da;
  border-radius: 4px;
  padding: 10px 16px 20px 16px;
  transition: border 0.2s;
  cursor: pointer;

  &.active,
  &:hover {
    border: 1px solid #757575;
  }
  .see-more-btn {
    margin: 10px auto 0;
    display: block;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
`;

const CardTitleText = styled.h3`
  margin: 0 0 8px 16px;
  white-space: normal;
`;

const CardSubtitle = styled.span`
  color: $main-gray-700;
  margin-left: 4px;
`;

const Controls = styled.div`
  display: flex;
`;

const HelpButton = styled(FontIcon)`
  padding: 8px 8px 14px 0;
  color: rgba(0, 0, 0, 0.24);
  &:hover {
    color: rgba(0, 0, 0, 0.54);
  }
`;

const ResetFilterButton = styled.button`
  font-size: 14px;
  margin: 0 8px 8px 0;
  padding: 6px 6px 2px;
  background-color: darkorange;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:hover:not([disabled]) {
    box-shadow: 0 0 5px #ccc;
  }
  &:disabled {
    background: #eeeeee;
    border: none;
  }
`;

const CardInfo = styled.div`
  color: $main-gray-700;
  margin-bottom: 12px;
`;

const CollapseButton = styled.div`
  display: flex;
  justify-content: center;
  .md-icon {
    color: rgba(0, 0, 0, 0.24);
  }
  &:hover .md-icon {
    color: rgba(0, 0, 0, 0.54);
  }
`;
