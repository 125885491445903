import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Draggable from 'react-draggable';
import {convertFromSquareMetersToMeasure} from '_utils';
import Histogram from 'components/histogram';
import {Button} from 'react-md';
import {AsyncStatusType, dialogToggle} from 'modules/ui-helpers';
import {AppStore} from 'reducers';
import {loadHistogramData} from '../actions';
import SimplePreloader from 'components/simple-preloader';
import './histogram-overlay.scss';

const HistogramOverlay = () => {
  const dispatch = useDispatch();
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const currentDates = useSelector((store: AppStore) => store.map.currentDates);
  const currentSensor = useSelector((store: AppStore) => store.map.currentSensor);
  const feature = useSelector((store: AppStore) => store.map.feature);
  const zoning = useSelector((store: AppStore) => store.map.zoning);
  const histogram = useSelector((store: AppStore) => store.map.histogram);
  const isVisibleHistogram = useSelector(
    (store: AppStore) => store.uiHelpers.dialogs.histogram.visible
  );
  const measurement = useSelector((store: AppStore) => store.login.user.settings.measurement);

  useEffect(() => {
    const currentImage = currentDates[currentDate]?.[currentSensor];
    if (isVisibleHistogram && currentImage) {
      dispatch(loadHistogramData(currentImage.url));
    }
  }, [isVisibleHistogram, currentDate, currentSensor, currentDates]);

  const disableOptions = () => currentSensor === 'NONE' || !currentDate;

  const closeHistogram = () => dispatch(dialogToggle('histogram', false));

  const stat = histogram.statistics.map((el: any) => ({
    ...el,
    area: convertFromSquareMetersToMeasure(el.area, measurement).toFixed(5),
  }));

  const series = histogram.series.map((el: any) => ({
    ...el,
    y: convertFromSquareMetersToMeasure(el.y, measurement).toFixed(2), //parseFloat((el.y * 2.47105).toFixed(2))
  }));

  return isVisibleHistogram ? (
    <Draggable
      handle=".map-histogram__handle"
      defaultPosition={{x: 50, y: 10}}
      defaultClassName={`map-histogram`}
      position={null}
      grid={[25, 25]}
    >
      <div>
        <h4 className="map-histogram__handle">
          Histogram
          <SimplePreloader statusKeys={[AsyncStatusType.histogram]} />
        </h4>
        <Button className={'close-dialog-button'} icon onClick={closeHistogram}>
          close
        </Button>
        {!disableOptions() ? (
          <Histogram
            id="map-histogram-left"
            series={series}
            categories={histogram.categories}
            statistics={stat}
            zones={zoning.copyZones}
            feature={feature}
            sensor={currentSensor}
            measurement={measurement}
          />
        ) : (
          <div className={'md-text-center'}>
            <b>Please select Observation Date and Sensor in the right bar</b>
          </div>
        )}
      </div>
    </Draggable>
  ) : null;
};

export default HistogramOverlay;
