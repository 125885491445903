import L from 'leaflet';

console.log(L);

export const enableDrawShape = (shapeType = 'polygon') => {
  try {
    if (L.toolbar._toolbars.draw._modes[shapeType])
      setTimeout(() => L.toolbar?._toolbars?.draw?._modes?.[shapeType]?.handler?.enable(), 0); // it is still a secret for me, why we need to run this actions async
  } catch (e) {
    console.warn(e.message);
  }
};

export const disableDrawShape = (shapeType = 'polygon') => {
  try {
    if (L.toolbar._toolbars.draw._modes[shapeType])
      setTimeout(() => L.toolbar?._toolbars?.draw?._modes?.[shapeType]?.handler?.disable(), 0);
  } catch (e) {
    console.warn(e.message);
  }
};

export const deleteLastPoint = () => {
  try {
    L.toolbar._toolbars.draw._modes.polygon.handler.deleteLastVertex();
  } catch (e) {
    console.warn(e.message);
  }
};

export const disableEdit = () => {
  try {
    if (L.toolbar._toolbars.edit.options.edit) {
      L.toolbar._toolbars.edit._modes.edit.handler.revertLayers();
      L.toolbar._toolbars.edit._modes.edit.handler.disable();
    }
  } catch (e) {
    console.warn(e.message);
  }
};

export const enableEdit = () => {
  try {
    L.toolbar._toolbars.edit._modes.edit.handler.enable();
  } catch (e) {
    console.warn(e.message);
  }
};
export const saveEdit = () => {
  try {
    L.toolbar._toolbars.edit._modes.edit.handler.save();
    L.toolbar._toolbars.edit._modes.edit.handler.disable();
  } catch (e) {
    console.warn(e.message);
  }
};
