import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {toggleTableView} from 'containers/map/actions';
import {Button} from 'react-md';
import {TFeature} from '../../types';

import './index.scss';

type Props = ConnectedProps<typeof connector> & {
  onClose?: () => void;
};

class ShrinkButton extends PureComponent<Props> {
  toggleTableView = (value?: TFeature) => {
    this.props.toggleTableView(value);
    this.props.onClose && this.props.onClose();
  };

  render() {
    return (
      <Button className={'button-shrink'} onClick={() => this.toggleTableView()} floating mini>
        keyboard_arrow_right
      </Button>
    );
  }
}

const connector = connect(null, {toggleTableView});
export default connector(ShrinkButton);
