import {FormattedMessage} from 'i18n-utils';
import React, {ReactElement, useCallback} from 'react';
import {ArrowInGearIcon, DrawMarkerIcon, UploadArrowInCircleIcon} from '../../../../icons';
import {useDispatch} from 'react-redux';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {AddFieldCurrentStep} from 'modules/add-fields.module';
import {toggleMapBar} from '../../../../actions';
import {reportError} from 'containers/error-boundary';
import {useAppSelector} from '_hooks';
import {isAdmin} from '_utils';

type Props = {
  changeCurrentStep: (step: AddFieldCurrentStep) => void;
};

type OptionProps = {
  titleId: string;
  subtitle?: string;
  icon: ReactElement;
  iconClassName: 'draw' | 'upload' | 'import';
  onClick: () => void;
};

const Option = ({titleId, subtitle, icon, iconClassName, onClick}: OptionProps) => {
  return (
    <div
      onClick={onClick}
      className={`global-bordered-container add-fields-option ${iconClassName}`}
    >
      <div className={`icon-container ${iconClassName}`}>{icon}</div>
      <div className="text-container">
        <div className="title">
          <FormattedMessage id={titleId} defaultMessage={titleId} />
        </div>
        {subtitle ? <div className="subtitle">{subtitle}</div> : null}
      </div>
    </div>
  );
};

const AddFieldsStep = ({changeCurrentStep}: Props) => {
  const dispatch = useDispatch();
  const hasFarms =
    isAdmin() || useAppSelector(s => s.farms.list.length > 0 || s.login.user.groupIds.length > 0);

  const onDrawFields = useCallback(() => {
    const locationButton: HTMLElement = document.querySelector('.location-button');
    if (!locationButton) {
      reportError('Tried to draw fields without the location button on the map');
      return;
    }
    locationButton.click();
    const searchLocation: HTMLElement = document.querySelector('#search-on-map-btn');
    searchLocation && searchLocation.click();
    changeCurrentStep('search-location');
    dispatch(toggleMapBar(false));
  }, []);

  const onUploadFields = useCallback(() => {
    changeCurrentStep('select-files-to-upload');
    dispatch(toggleMapBar(false));
  }, []);

  const onImportFields = useCallback(() => {
    dispatch(toggleDialog(DialogType.AddNewField, false)); // allow add fields to clear its state first

    // made async call to await toggleMapBar(true) after close 'add fields dialog' and then close map bar again
    setTimeout(() => {
      dispatch(toggleMapBar(false));
      dispatch(toggleDialog(DialogType.IntegrationPlatforms, true));
    }, 0);
  }, []);

  const onOptionSelected = () => {
    if (!hasFarms) {
      dispatch(toggleDialog(DialogType.AddNewField, true)); // because the component is not in the dialog
    }
  };

  return (
    <div className={'add-new-fields-container'}>
      <Option
        titleId={'importAFieldFromFarmManagementSystems'}
        subtitle={'MyJohnDeere, Climate, EFC, ...'}
        iconClassName={'import'}
        icon={<ArrowInGearIcon />}
        onClick={onImportFields}
      />
      <Option
        titleId={'uploadBoundary'}
        subtitle={'.KML or SHP'}
        iconClassName={'upload'}
        icon={<UploadArrowInCircleIcon />}
        onClick={() => {
          onOptionSelected();
          onUploadFields();
        }}
      />
      <Option
        titleId={'selectOrDrawAField'}
        iconClassName={'draw'}
        icon={<DrawMarkerIcon />}
        onClick={() => {
          onOptionSelected();
          onDrawFields();
        }}
      />
    </div>
  );
};

export default AddFieldsStep;
