import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {Button} from 'react-md';
import {Cycle} from 'containers/map/icons';
import {connect, ConnectedProps} from 'react-redux';
import {setOnboardingSeen} from '../login/actions';
import FluroDialog from 'components/fluro-dialog';

type Props = {
  onHide(): void;
};

type Actions = ConnectedProps<typeof connector>;

class ProcessingData extends PureComponent<Props & Actions> {
  render() {
    return (
      <FluroDialog
        onHide={this.props.onHide}
        visible
        portal
        id={'data-in-processing'}
        isDraggable
        className={'data-in-processing'}
        title={'Your data is being processed'}
        focusOnMount={false}
      >
        <div className="processing-container">
          <div className="left-side">
            <Cycle className={'processing-icon'} />
          </div>

          <div className="right-side">
            We are importing and processing data for your farm, it can take up to 24 hours. In the
            meantime, let's have a look around using a different farm.
            <Button raised primary className={'button'} onClick={this.startSetUpTour}>
              Let's do it!
            </Button>
          </div>
        </div>
      </FluroDialog>
    );
  }

  startSetUpTour = () => {
    this.props.setOnboardingSeen({fullTour: true});
    this.props.onHide();
  };
}

const connector = connect(null, {
  setOnboardingSeen,
});
export default connector(ProcessingData);
