import React, {useEffect, useMemo, useState} from 'react';
import {PlanetOrder} from './order-planet-imagery';
import {
  FluroButton,
  FluroDialog,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroTableBody,
  IdInTable,
  FluroStatusIcon,
} from 'components';
import {GLOBAL_FORMAT_DATE} from '_constants';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {getUsersList} from '../users/actions';
import {User} from '../users/types';
import {calcPlural, formatDate} from '_utils';
import {getFieldsAreaAndPrice} from './order-planet-imagery-utils';
import {Field} from '../../map/types';
import {AdminContentWrapper} from '../admin.styled';
import {FluroStatusIconProps} from 'components/fluro-status-icon/fluro-status-icon';

type Props = {
  orders: PlanetOrder[];
  onApproveOrder: (orderId: number, fieldsArea: string, orderCost: string) => void;
};

const statuses: {[key: string]: string} = {
  new: 'Pending approval',
  completed: 'Completed',
  failed: 'Failed',
  active: 'Active',
  awaiting: 'Awaiting (will be active soon)',
};

const statusIcon: {[key: string]: FluroStatusIconProps} = {
  new: 'yellow',
  completed: 'gray',
  failed: 'red',
  active: 'green',
  awaiting: 'light-green',
};

const OrdersTable = ({orders, onApproveOrder}: Props) => {
  const dateFormat = formatDate();
  const usersList = useSelector((store: AppStore) => store.users.list);
  const [orderFieldsList, setOrderFieldsList] = useState<Field[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!usersList.length) {
      dispatch(getUsersList());
    }
  }, []);

  return (
    <>
      <AdminContentWrapper>
        <FluroDataTable className="orders-list" fixedDividers={true} baseId="orders-list" plain>
          <FluroTableHeader>
            <FluroTableRow>
              <FluroTableColumn type={'number'}>Order ID</FluroTableColumn>
              <FluroTableColumn type={'number'}>Fields</FluroTableColumn>
              <FluroTableColumn type={'number'}>Size</FluroTableColumn>
              <FluroTableColumn type={'number'}>Price</FluroTableColumn>
              <FluroTableColumn type={'date'}>Date range</FluroTableColumn>
              <FluroTableColumn type={'date'}>Order date (UTC)</FluroTableColumn>
              <FluroTableColumn>Status</FluroTableColumn>
              <FluroTableColumn>User</FluroTableColumn>
              <FluroTableColumn>Action</FluroTableColumn>
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {orders.map(order => {
              const createdByUser =
                usersList.find(user => user.id === order.CreatedBy) || ({} as User);
              const [fieldsSize, fieldsPrice] = getFieldsAreaAndPrice(order.KMLs || []);
              return (
                <FluroTableRow key={order.ID}>
                  <FluroTableColumn type={'number'}>#{order.ID}</FluroTableColumn>
                  <FluroTableColumn nowrap type={'number'}>
                    <span onClick={() => setOrderFieldsList(order.KMLs)} className={'global-link'}>
                      {order.KMLs?.length} {calcPlural('field', order.KMLs)}
                    </span>
                  </FluroTableColumn>

                  <FluroTableColumn nowrap type={'number'}>
                    {fieldsSize}
                  </FluroTableColumn>
                  <FluroTableColumn nowrap type={'number'}>
                    $ {fieldsPrice}
                  </FluroTableColumn>

                  <FluroTableColumn type={'date'}>
                    {moment(order.StartDate, GLOBAL_FORMAT_DATE).format(dateFormat)} -{' '}
                    {moment(order.EndDate, GLOBAL_FORMAT_DATE).format(dateFormat)}
                  </FluroTableColumn>

                  <FluroTableColumn type={'date'}>
                    {moment.utc(order.CreatedAt).format(dateFormat + ' - hh:mm A')}
                  </FluroTableColumn>

                  <FluroTableColumn nowrap className={`status-column`}>
                    <FluroStatusIcon status={statusIcon[order.Status]} />
                    <span className="status-label">{statuses[order.Status] || order.Status}</span>
                  </FluroTableColumn>
                  <FluroTableColumn>
                    <div>
                      {createdByUser.name} {createdByUser.surname}
                    </div>
                    <IdInTable>#{createdByUser.id}</IdInTable>
                  </FluroTableColumn>

                  <FluroTableColumn>
                    <FluroButton
                      onClick={() => onApproveOrder(order.ID, fieldsSize, fieldsPrice)}
                      raised
                      primary
                      blank={false}
                      disabled={order.Status !== 'new'}
                    >
                      Approve
                    </FluroButton>
                  </FluroTableColumn>
                </FluroTableRow>
              );
            })}
          </FluroTableBody>
          {/*<TablePagination*/}
          {/*  page={pagination.page}*/}
          {/*  rows={filteredList.length}*/}
          {/*  rowsPerPage={pagination.perPage}*/}
          {/*  rowsPerPageLabel="Users"*/}
          {/*  onPagination={handlePagination}*/}
          {/*  rowsPerPageItems={calcRowsPerPageItems(filteredList.length)}*/}
          {/*/>*/}
        </FluroDataTable>
      </AdminContentWrapper>

      <FluroDialog
        id={'order-fields-pop-up'}
        title={'Order fields'}
        onHide={() => setOrderFieldsList([])}
        visible={!!orderFieldsList.length}
        dialogClassName={'order-fields-pop-up'}
      >
        <FluroDataTable fixedDividers={true} baseId="order-fields-list" plain>
          <FluroTableHeader>
            <FluroTableRow>
              <FluroTableColumn type={'number'}>Field ID</FluroTableColumn>
              <FluroTableColumn>Field name</FluroTableColumn>
              <FluroTableColumn type={'number'}>Area</FluroTableColumn>
              <FluroTableColumn type={'number'}>Price</FluroTableColumn>
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {orderFieldsList.map(field => {
              const [fieldsSize, fieldsPrice] = getFieldsAreaAndPrice([field]);
              return (
                <FluroTableRow key={field.ID}>
                  <FluroTableColumn type={'number'}>#{field.ID}</FluroTableColumn>
                  <FluroTableColumn>{field.Name}</FluroTableColumn>
                  <FluroTableColumn nowrap type={'number'}>
                    {fieldsSize}
                  </FluroTableColumn>
                  <FluroTableColumn nowrap type={'number'}>
                    ${fieldsPrice}
                  </FluroTableColumn>
                </FluroTableRow>
              );
            })}
          </FluroTableBody>
        </FluroDataTable>
      </FluroDialog>
    </>
  );
};

export default OrdersTable;
