import axios from 'axios';
import {v1 as uuidv1} from 'uuid';
import _config from '_environment';
const {baseUrl, authHeader} = _config;
import {requestLoading, responseLoading, responseUnathorized401} from './base';
import {isLocalhost} from '_utils';
import {getAdditionalHeaders} from './api-utils';

const service = axios.create({
  baseURL: `${baseUrl}services/`,
  validateStatus: function (status) {
    return status < 400;
  },
  headers: {
    [authHeader]: localStorage.getItem('token') || '',
    // Add version header to each request (FSB-2932).
    ...getAdditionalHeaders(),
    ...(!isLocalhost() ? {'x-fs-app-version': _config.app_version} : {}),
  },
});

service.interceptors.request.use(config => {
  const uuid = uuidv1();
  config.headers['X-Request-ID'] = uuid;
  config.headers['X-Correlation-ID'] = uuid;
  return config;
});
service.interceptors.request.use(requestLoading);
service.interceptors.response.use(responseLoading, error => Promise.reject(responseLoading(error)));
service.interceptors.response.use(responseUnathorized401, function (error) {
  return Promise.reject(responseUnathorized401(error));
});

export default service;
