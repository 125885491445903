import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppStore} from '../../reducers';
import {getGetURLParam, isAdmin, isAdminPerm} from '_utils';
import config from '_environment';
import {baseLinks} from 'containers/map/mini-menu/mini-menu-links';

type Props = RouteProps & {
  isAdminOnly?: boolean;
};

export default ({isAdminOnly, component: Component, ...rest}: Props) => {
  const perm = useSelector((state: AppStore) => state.login.user.perm);
  const isAuth = useSelector((state: AppStore) => state.login.isAuth);
  const isBaseLink = baseLinks.find(
    l => l.ln === rest.path || (isAdmin() && rest.path === '/admin')
  );
  const isCarbonDomain = config.featurePack === 'carbon';

  // if user logged-in but not admin - redirect to root page
  if (isAuth && isAdminOnly && !isAdminPerm(perm)) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        switch (true) {
          case isAuth && !isBaseLink && isCarbonDomain && !pathIs(rest.path, '/carbon'):
            return <Redirect to={'/carbon'} />;
          case isAuth && !isBaseLink && !isCarbonDomain && pathIs(rest.path, '/carbon'):
            return <Redirect to={'/'} />;
          case isAuth:
            return <Component {...props} />;
          case isCarbonDomain && pathIs(rest.path, '/carbon/login'):
            return <Component {...props} />;
          case isCarbonDomain:
            return <Redirect to={'/carbon/login'} />;
          default:
            return (
              <Redirect
                to={`/login?redirect=${
                  encodeURIComponent(window.location.pathname) +
                  (window.location.search ? window.location.search.replace('?', '&') : '')
                }`}
              />
            );
        }
      }}
    />
  );
};

const pathIs = (path: RouteProps['path'], target: string) =>
  typeof path === 'string' ? path.startsWith(target) : path.some(p => p.startsWith(target));
