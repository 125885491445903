import ReactTooltip from 'react-tooltip';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppStore} from '../../reducers';
import './index.scss';
import {InfoBlock} from '../index';
const FluroTooltip = () => {
  const {
    id,
    content,
    place = 'right',
    appearance,
    showIcon = false,
    tooltipClassName,
    width = '250px',
  } = useSelector((s: AppStore) => s.global.tooltip);

  useEffect(() => {
    ReactTooltip.rebuild(); // is used to draw and hide the tooltip
  }, [id, content]);

  return (
    <ReactTooltip
      className={`fluro-tooltip ${tooltipClassName}`}
      id={`${id}`}
      effect="solid"
      type="light"
      place={place}
      border={false}
      getContent={() => (
        <InfoBlock
          containerStyle={{width}}
          className={'no-top'}
          appearance={appearance}
          hideIcon={!showIcon}
        >
          {content}
        </InfoBlock>
      )}
    />
  );
};

export default FluroTooltip;
