import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, useMemo} from 'react';
import {GeoJSON} from 'react-leaflet';
import {useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {genKey, getCropColorById} from '_utils';

export const SeasonGeometryOverlay = () => {
  const currentSeason = useSelector((state: AppStore) => state.map.currentSeason);
  const geometriesOnMap = useSelector((state: AppStore) => state.map.geometriesOnMap);

  const id = useMemo(() => genKey(), [currentSeason]);

  const onEachFeature = useCallback((feature: any, layer: any) => {
    layer.setStyle({color: getCropColorById(currentSeason.cropType)});
    layer.setStyle({fillOpacity: 0});
  }, []);

  return currentSeason.geometry && geometriesOnMap ? (
    <GeoJSON key={id} data={currentSeason.geometry} onEachFeature={onEachFeature} />
  ) : null;
};

export default SeasonGeometryOverlay;
