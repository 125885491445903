import React, {useState} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps, withFormik} from 'formik';
import Yup from 'yup';
import {showNote, setGlobalParam} from '_actions/index';
import {saveFarm, selectFarm} from './actions';

import {FarmPayload} from 'containers/map/features/farm/types';

import {Button, FontIcon, TextField} from 'react-md';
import {Farm} from './types';
import {InfoBlock} from 'components';
import OrganizationSelect from '../admin/subscriptions/organization-select';

type Props = ConnectedProps<typeof connector> & {
  farm: FarmPayload;
  onSaveDone?: () => void;
  onDelete: () => void;
  isAdmin: boolean;
  // organizations: Organization[];
  userDefaultOrganization: number;
};

type ValuesProps = {
  name: string;
  growerName: string;
  organizationID: number | null;
};

const FarmEdit = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  farm,
  onDelete,
  isAdmin,
}: Props & FormikProps<ValuesProps>) => {
  const [showGrowerField, toggleGrowerField] = useState(!!values.growerName);

  const onToggleGrowerField = () => toggleGrowerField(true);

  return (
    <form className="farm-edit farm-edit__container" onSubmit={handleSubmit}>
      <TextField
        id="farm-name"
        lineDirection="center"
        label={t({id: 'Farm name'})}
        placeholder={t({id: 'Farm name'})}
        value={values.name}
        onChange={text => setFieldValue('name', text)}
        error={!!(errors.name && touched.name)}
        errorText={<div>{errors.name}</div>}
        className="text-field"
      />

      {showGrowerField ? (
        <TextField
          id="farm-grower"
          lineDirection="center"
          label={t({id: 'Grower name'})}
          placeholder={t({id: 'Grower name'})}
          value={values.growerName}
          onChange={text => setFieldValue('growerName', text)}
        />
      ) : (
        <div>
          <Button
            className={'farm-edit__add-grower-btn'}
            onClick={onToggleGrowerField}
            raised
            iconEl={<FontIcon>add_circle_outline</FontIcon>}
          >
            {t({id: 'add grower'})}
          </Button>
        </div>
      )}

      {isAdmin && farm.id === 0 && (
        <OrganizationSelect
          isMultiSelected={false}
          value={values.organizationID}
          onChange={val => setFieldValue('organizationID', val)}
        />
      )}

      <InfoBlock appearance={'info'} className={'inside-a-pop-up'}>
        <FormattedMessage
          id="If your fields were imported from a farm management system"
          defaultMessage="If your fields were imported from a farm management system, do not edit the farm and grower name from FluroSense, as they will be overwritten next time you sync your data.<br></br>Please set up your farms and growers names directly from your farm management system."
          values={{br: () => <br />}}
        />
      </InfoBlock>

      <div className="farm-edit__buttons">
        {farm.id ? (
          <Button id="edit-farm-remove-btn" raised swapTheming onClick={onDelete}>
            {t({id: 'Delete Farm'})}
          </Button>
        ) : null}

        <Button
          tabIndex={0}
          id="edit-farm-save-btn"
          className={'save-btn'}
          raised
          primary
          type="submit"
          disabled={isSubmitting}
        >
          {t({id: 'Save'})}
        </Button>
      </div>
    </form>
  );
};

const FormikForm = withFormik<Props, ValuesProps>({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    name: props.farm.name || '',
    growerName: props.farm.growerName || '',
    organizationID:
      (props.farm.id === 0 && props.isAdmin && props.userDefaultOrganization) ||
      props.farm.organizationID,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .trim()
      .min(2, 'Min. farm name is 2 characters')
      .max(50, 'Max. farm name is 50 characters'),
  }),

  handleSubmit: (values, {props, setSubmitting}) => {
    props.saveFarm({id: props.farm.id, ...values}).then((response: Farm) => {
      if (props.farm.id === 0) {
        props.selectFarm(response?.id);
      }

      setSubmitting(false);

      props.showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: props.farm.id === 0 ? t({id: 'Farm was saved'}) : t({id: 'Farm was updated'}),
        level: 'success',
      });

      props.onSaveDone && props.onSaveDone();
    });
  },

  displayName: 'FarmEditForm',
})(FarmEdit);

const connector = connect(null, {
  showNote,
  saveFarm,
  setGlobalParam,
  selectFarm,
});

export default connector(FormikForm);
