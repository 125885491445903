import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormattedMessage, t} from 'i18n-utils';
import {useDispatch, useSelector} from 'react-redux';
import {Line} from 'react-chartjs-2';
import {FontIcon, ListItem, MenuButton} from 'react-md';
import FluroDialog from 'components/fluro-dialog';
import moment from 'moment';
import {setDate, setFeature, setSensor} from 'containers/map/actions';
import 'chartjs-plugin-zoom';

import {
  toggleAnalyticSmoothedData,
  togglePointVisibility,
  toggleTSPVisibility,
} from 'containers/map/actions/analytics-actions';
import {changeAreaOfInterestProp} from 'containers/map/actions/areas-of-interest-actions';
import {changeLowPerfAnomalyProp, updatePremiumAnomaly} from '../../actions/anomalies-actions';
import {ArableIcon, CurveLine, LeafIcon} from '../../icons';
import './index.scss';
import Chart from 'chart.js';
import {
  downloadFile,
  formatDate,
  getDataSource,
  getImageStatus,
  normalizeSensorIndex,
  toFixedFloat,
} from '_utils';
//@ts-ignore
import Slider from 'react-slick';
import {getAllVisibleGeometries} from '../../utils';

import ReadOnly from 'components/read-only-container';
import {GLOBAL_APP_DATE_FORMAT, GLOBAL_FORMAT_DATE} from '_constants';
import {AnalyticPoint, TrendsSeries} from '../../types';
import cn from 'classnames';

import {analyticsToCSV, SMOOTHED_LINE_COLOR} from './helpers';
import ErrorBoundary from '../../../error-boundary';
import {AppStore} from '../../../../reducers';
import {TrendsData, TrendsIndex} from 'containers/map/types';
import {showNote, toggleTooltip} from '_actions';
import {InfoBlock, Ln} from 'components';
import Mixpanel from '_utils/mixpanel-utils';
import {GrowthStageLabel} from '../../../temperature-chart/temperature-chart';
import SimplePreloader, {HideWhileLoading} from '../../../../components/simple-preloader';
import {AsyncStatusType} from '../../../../modules/ui-helpers';
import {parseGeometryFile} from '../../utils/file';

const GrowthStageLabels = ({
  cropType,
  data,
  isPopup,
}: {
  cropType: string;
  data: GrowthStageLabel[];
  isPopup: boolean;
}) =>
  data && data.length ? (
    <>
      {data.map((l, i) => (
        <div
          key={`gs-label-key-${i}`}
          title={l.growingStage}
          style={{
            top: isPopup ? 55 : 52,
            left: l.model.x,
            maxWidth: isPopup ? l.maxWidth : 'auto',
            transform: isPopup ? 'unset' : 'translateX(-50%)',
          }}
          className="gs-label"
        >
          <div className="gs-label__icon" />
          <div className="gs-label__label">{`[${cropType}] ${l.growingStage}`}</div>
        </div>
      ))}
    </>
  ) : null;

const Analytics = () => {
  const dispatch = useDispatch();
  const analytics = useSelector((state: AppStore) => state.map.analytics);
  const analyticPoints = useSelector((state: AppStore) => state.map.analytics.points);
  const currentPointsGroup = useSelector(
    (state: AppStore) => state.map.pointsGroups[state.map.pointsCurrentGroupDate]
  );
  const trendsData = useSelector((state: AppStore) => state.map.analytics.trendsData);
  const {allFarmTSP, isWholeFarmView} = useSelector((state: AppStore) => state.map.wholeFarm);
  const currentSeason = useSelector((state: AppStore) => state.map.currentSeason);
  const isReadOnly = useSelector((state: AppStore) => state.map.group.readOnly);
  const remoteSensingCloudCover = useSelector(
    (state: AppStore) => state.map.remoteSensingCloudCover
  );
  const isOnBoardingNow = useSelector(
    (state: AppStore) => state.login.user.settings.onboarding.fullTour
  );
  const measurement = useSelector((state: AppStore) => state.login.user.settings.measurement);
  const imageStatus = useSelector((state: AppStore) => getImageStatus(state.map));
  const currentDate = useSelector((state: AppStore) => state.map.currentDate);
  const field = useSelector((state: AppStore) => state.map.field);
  const farm = useSelector((state: AppStore) => state.map.group);
  const currentDates = useSelector((state: AppStore) => state.map.currentDates);
  const currentSensor = useSelector((state: AppStore) => state.map.currentSensor);
  const premiumAnomalies = useSelector((state: AppStore) => state.map.premiumAnomalies.list);
  const geometry = useSelector((state: AppStore) => state.map.geometry);
  const lowPerfAnomalies = useSelector((state: AppStore) => state.map.lowPerfAnomalies.list);
  const preparedTemperatureData = useSelector(
    (state: AppStore) => state.map.preparedTemperatureData
  );
  const samplingPointGroupsDates = useSelector((state: AppStore) =>
    Object.keys(state.map.pointsGroups || {}).map(date =>
      moment(date, formatDate()).format(GLOBAL_FORMAT_DATE)
    )
  );
  const sliderRef = useRef(null);
  const [exportStatus, setExportStatus] = useState(false);
  const [popUpOpen, togglePopUpState] = useState(false);
  const [elementToShow, setElementToShow] = useState(1);
  const [growthStageLabels, setGrowthStageLabels] = useState<{
    [chartId: string]: GrowthStageLabel[];
  }>({});
  const growthStageLabelsRef = useRef({});
  const popUpOpenRef = useRef(false);
  const exportChartsRef = useRef<any>({});
  const leafImg = new Image();
  leafImg.src = '/assets/leaf.svg';

  const setGrowthStages = (growthStages: {[chartId: string]: GrowthStageLabel[]}) => {
    setGrowthStageLabels(growthStages);
    growthStageLabelsRef.current = growthStages;
  };
  const togglePopUp = (value: boolean) => {
    popUpOpenRef.current = value;
    togglePopUpState(value);
  };

  useEffect(() => {
    return unregisterDrawFunctions;
  }, []);

  useEffect(() => {
    unregisterDrawFunctions();
    registerDrawFunctions();
  }, [preparedTemperatureData, growthStageLabels]);

  const unregisterDrawFunctions = () =>
    Chart.plugins.unregister(
      //@ts-ignore
      Chart.plugins.getAll().filter((p: any) => p.id === 'fluro-analytics-chart')[0]
    );

  const registerDrawFunctions = () =>
    Chart.pluginService.register({
      id: 'fluro-analytics-chart',
      beforeDraw: chart => drawGSLabels(chart, true),
      afterRender: chart => drawGSLabels(chart, false),
    });

  const allFieldShapes = useMemo(() => {
    return [
      ...getAllVisibleGeometries().filter(g => g.properties.color),
      ...premiumAnomalies.filter((a: any) => a.properties.color),
    ];
  }, [geometry, lowPerfAnomalies, premiumAnomalies]);

  const drawOnChart = (ctx: any, point: any, chart: any, leaf = false, isCurrentDate = false) => {
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(point.x, chart.chartArea.top);
    ctx.strokeStyle = isCurrentDate ? '#43a047' : 'rgba(0, 0, 0, 0.6)';
    ctx.lineWidth = 1;
    if (!leaf && !isCurrentDate) {
      // for the Sampling points leaf the line is dashed
      ctx.setLineDash([5, 5]);
    }
    ctx.lineTo(point.x, chart.scales['x-axis-0'].top);
    ctx.stroke();
    if (leaf) {
      // draw Leaf image for the Sampling point date
      ctx.drawImage(leafImg, point.x - 15, chart.chartArea.top - 17);
    }
    ctx.restore();
  };

  const allowDrawGrowthLabels = (chart: any) => {
    return Object.keys(preparedTemperatureData).length && chart.data?.labels?.length;
  };

  const drawGSLabels = (chart: any, beforeDraw = false) => {
    if (allowDrawGrowthLabels(chart)) {
      try {
        const line = chart.getDatasetMeta(0);
        const chartArea = chart.chartArea;
        const ctx = chart.chart.ctx;

        if (line.type === 'line') {
          let growthLabels: GrowthStageLabel[] = [];
          chart.config.options.categories.forEach((labelDate: string, i: number) => {
            const model = line.data?.[i]?.getCenterPoint();
            const dateGrowingState = preparedTemperatureData[labelDate]?.GrowingStage;

            if (chart.config.options.currentDate === labelDate) {
              drawOnChart(ctx, model, chart, false, true);
            }

            if (beforeDraw && !isNaN(model.x) && samplingPointGroupsDates.includes(labelDate)) {
              //draw Sampling points leaf
              drawOnChart(ctx, model, chart, true);
            }

            // ===== collect GS Labels + Positions =====
            if (
              dateGrowingState &&
              !growthLabels.find(d => d.growingStage === dateGrowingState) && // because there is range of dates with the same GS
              line.data[i]
            ) {
              growthLabels.push({
                model,
                growingStage: dateGrowingState,
              });
            }
          });

          if (beforeDraw && chart.config.options.shouldShowGSLabels) {
            // ===== draw GS lines =====
            // growthLabels.forEach((lbl, i) => {
            //   // draw only last GS line for right panel charts && draw all GS lines for pop-up charts
            //   if (popUpOpenRef.current || i === growthLabels.length - 1)
            //     drawOnChart(ctx, lbl.model, chart);
            // });
          } else {
            // ===== calculate GS labels and positions =====
            if (growthLabels.length && !popUpOpenRef.current) {
              // left only latest GS label for non-pop-up chart
              growthLabels = [growthLabels[growthLabels.length - 1]];
            }

            // ===== calculate GS maxWidth =====
            growthLabels = growthLabels.map((gsc, i) => {
              let maxWidth =
                i < growthLabels.length - 1 // not the last one
                  ? growthLabels[i + 1].model.x - gsc.model.x
                  : chartArea.right - gsc.model.x;

              gsc.maxWidth = maxWidth < 15 ? 15 : maxWidth;
              return gsc;
            });

            setGrowthStages({
              ...growthStageLabelsRef.current,
              [chart.config.options.id]: [...growthLabels],
            });
          }
        }
      } catch (e) {
        console.warn(e.message);
      }
    }
  };

  const toggleVisibility = (id: string | number, isTSP?: boolean | string) => {
    dispatch(isTSP ? toggleTSPVisibility(id) : togglePointVisibility(id));
  };

  const exportToFile = async (fileType: string) => {
    setExportStatus(true);
    Mixpanel.analyticsExport(fileType);

    await new Promise(resolve => setTimeout(() => resolve(true), 1000)); // await export charts appear in the DOM
    const formatDate = moment(currentDate, 'DD/MM/YYYY').format('DD-MM-YYYY');
    const selectedChart = (index: string): any => exportChartsRef.current[`${index}Chart`];
    const zipName = `${farm.name} ${field.Name.replace(/\.[^/.]+$/, '')} ${formatDate}`
      .split(' ')
      .join('_');
    const fileName = (index: string) =>
      `${farm.name} ${field.Name.replace(/\.[^/.]+$/, '')} ${formatDate} ${index}.${fileType}`
        .split(' ')
        .join('_');

    const type = getDataSource(currentDates[currentDate][currentSensor]);

    const JSZip = await import('jszip');

    const zip = new JSZip.default();

    Object.keys(trendsData).forEach((index: TrendsIndex) => {
      if (!trendsData[index].series?.some(trendData => notHiddenPoints.includes(trendData.name))) {
        // no data available for the index chart
        return;
      }

      const chart = selectedChart(index);
      const itemName = `${zipName}/${fileType}/${fileName(index)}`;
      if (fileType === 'png')
        zip.file(itemName, chart.chartInstance.canvas.toDataURL('image/png').split('base64,')[1], {
          base64: true,
        });
      if (fileType === 'csv') {
        const dates = chart.props.options.categories;

        const points = chart.props.data.datasets.map((el: any) => ({
          values: el.data.map((data: number) => (isNaN(data) ? '-' : data)),
          name: el.pointLabel,
        }));
        zip.file(itemName, analyticsToCSV([dates, points, type]));
      }
    });

    zip.generateAsync({type: 'blob'}).then((content: any) => {
      downloadFile(content, `${zipName}.zip`);
      setExportStatus(false);
    });
  };

  const openPop = (selectedIndex: string) => {
    let slideToOpen = 1;
    document
      .querySelectorAll('.analytics-trend-container')
      .forEach(
        (el, index) => (slideToOpen = el.classList.contains(selectedIndex) ? index : slideToOpen)
      );

    togglePopUp(true);
    setElementToShow(slideToOpen);
  };

  const handleOnShow = () => {
    if (sliderRef?.current) sliderRef?.current?.slickGoTo(elementToShow, true);
  };

  const closePop = () => {
    togglePopUp(false);
    sliderRef.current = null;
  };

  const notHiddenPoints = useMemo(() => {
    let result = [];

    allFieldShapes
      .filter(g => g.properties.visible !== false)
      .forEach(g => result.push(g.properties.color));

    if (analyticPoints.length) {
      analyticPoints.forEach((point: AnalyticPoint) => {
        if (point.visible || point.visible === undefined) {
          result.push(point.color);
        }
      });
    }
    if (currentPointsGroup?.length && !isWholeFarmView) {
      currentPointsGroup.forEach((point: any) => {
        if (point.properties.visible || point.properties.visible === undefined) {
          result.push(point.properties.color);
        }
      });
    }

    if (isWholeFarmView) {
      allFarmTSP.forEach((point: any) => {
        if (point.properties.visible || point.properties.visible === undefined) {
          result.push(point.properties.color);
        }
      });
    }

    if (analytics.showSmoothedData) {
      result.push(SMOOTHED_LINE_COLOR);
    }

    // if (analytics.showKmlMean) {
    //   result.push(WHOLE_FIELD_LINE_COLOR);
    // }

    return result;
  }, [
    allFieldShapes,
    analyticPoints,
    currentPointsGroup,
    isWholeFarmView,
    analytics.showSmoothedData,
  ]);

  const getData = (dataToParse: any, layerIndex: string) => {
    const addedDates: any[] = [];
    dataToParse.categories = dataToParse.categories || [];
    dataToParse.series = dataToParse.series || [];

    const {labels, categories, series} = dataToParse.categories.reduce(
      ({labels = [], categories = [], series}: any, loopDate: string, i: number) => {
        const momentLoopDate = moment(loopDate);
        let result = {
          labels: [...labels, momentLoopDate.format(measurement === 'ha' ? 'DD-MMM' : 'MMM-DD')],
          categories: [...categories, loopDate],
          series:
            series ||
            dataToParse.series.filter((point: TrendsSeries) =>
              notHiddenPoints.includes(point.name)
            ),
        };

        // enrich data list with Sampling Points groups dates to display all the leafs on chart
        samplingPointGroupsDates.forEach(tsDate => {
          const loomMoment = moment(loopDate);
          const tsMoment = moment(tsDate);
          const tsMomentFormatted = moment(tsDate).format(GLOBAL_FORMAT_DATE);
          const isAfter =
            tsMoment.isAfter(loomMoment, 'day') &&
            (!dataToParse.categories[i + 1] ||
              tsMoment.isBefore(dataToParse.categories[i + 1], 'day'));

          const isBefore = tsMoment.isBefore(loomMoment, 'day');
          const isExistDay = dataToParse.categories.includes(tsDate);

          if ((isAfter || isBefore) && !isExistDay && !addedDates.includes(tsDate)) {
            const index = result.labels.indexOf(loomMoment.format('MMM-DD'));
            addedDates.push(tsDate);
            result = {
              labels: isAfter
                ? [...result.labels, tsMoment.format('MMM-DD')]
                : [tsMoment.format('MMM-DD'), ...result.labels],
              categories: isAfter
                ? [...result.categories, tsMomentFormatted]
                : [tsMomentFormatted, ...result.categories],
              series: result.series.map((point: TrendsSeries) => {
                return {
                  ...point,
                  data: isAfter
                    ? [...point.data.slice(0, index + 1), {}, ...point.data.slice(index + 1)]
                    : index === 0
                    ? [{}, ...point.data]
                    : [...point.data.slice(0, index - 1), {}, ...point.data.slice(index - 1)],
                };
              }),
            };
          }
        });

        return result;
      },
      {}
    );
    return {
      //@ts-ignore
      categories,
      //@ts-ignore
      currentDate: moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(GLOBAL_FORMAT_DATE),
      data: {
        labels,
        datasets:
          series.map((point: TrendsSeries) => {
            const isSmoothedData = point.name === SMOOTHED_LINE_COLOR;
            const isGeometryData = allFieldShapes.find(g => g.properties.color === point.name);
            const data = point
              ? point.data.map(data => {
                  if (data.y) {
                    return layerIndex === 'CCCI' || point.type === 'arable'
                      ? data.y.toFixed(2)
                      : normalizeSensorIndex(data.y * 255, -1, isSmoothedData ? 3 : 2);
                  }
                  return NaN;
                })
              : [];
            return {
              type: 'line',
              lineTension: 0.2,
              borderWidth: isGeometryData || isSmoothedData ? 2 : 1.2, // line width
              borderColor: point.name,
              backgroundColor: point.name,
              borderCapStyle: 'butt',
              // borderDash: point.name === '#000000' ? [2] : [],
              borderDashOffset: 0.0,
              pointBorderWidth: isGeometryData || isSmoothedData ? 2.5 : 1.5, // point at line radius
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: point.name,
              pointHoverBorderWidth: 1,
              pointRadius: isGeometryData || isSmoothedData ? 0 : 1,
              pointHitRadius: 10,
              pointLabel: isSmoothedData ? 'Field avg.' : point.label,
              data,
              fill: false,
            };
          }) || [],
      },
    };
  };

  const onClickChartPoint = useCallback(
    (e, element) => {
      if (element[0]) {
        // do not open chart dialog if click on the pin
        e.stopPropagation();

        try {
          const {sensor, categories} = element[0]._chart.options;
          const selectedDate = categories[element[0]._index];
          const formattedDateLabel = moment(selectedDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
          const currentDatesArr = Object.keys(currentDates);
          const date = currentDatesArr.find(d => d.startsWith(formattedDateLabel));

          if (date) {
            dispatch(setDate(date));
            dispatch(setSensor(sensor));
          } else {
            dispatch(
              showNote({
                title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                message: t({
                  id: 'The date is not available.',
                  defaultMessage: 'The date is not available.',
                }),
                level: 'warning',
              })
            );
          }
        } catch (e) {
          console.warn('Date not found');
        }
      }
    },
    [currentDates]
  );

  const tooltips = useMemo(() => {
    return {
      titleFontStyle: 'normal',
      callbacks: {
        title(item: Chart.ChartTooltipItem[]): string | string[] {
          return moment(this._chart.options.categories[item[0].index], 'YYYY-MM-DD').format(
            formatDate()
          );
        },
        // this callback need for round NDVI value to 2 decimal
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          const chartLine: any = data.datasets[tooltipItem.datasetIndex];
          let value = chartLine.data[tooltipItem.index];

          if (value && isFinite(value)) {
            value = toFixedFloat(value);
          }

          return value;
        },
      },
    };
  }, [currentDates]);

  const getTrends = (isPopUpTrend?: boolean) => {
    let layersKeys = Object.keys(trendsData) as Array<keyof TrendsData>;
    const width = isPopUpTrend ? 500 : 290;
    const height = isPopUpTrend ? 500 : 290;
    const trendsArray: any[] = [];
    const currentDateAsChartProp = moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(
      GLOBAL_FORMAT_DATE
    );

    // put NDVI to the top of list for avoid index "jumping"
    if (layersKeys.includes(TrendsIndex.NDVI)) {
      layersKeys = layersKeys.filter(index => index !== TrendsIndex.NDVI);
      layersKeys.unshift(TrendsIndex.NDVI);
    } else if (layersKeys.includes(currentSensor as TrendsIndex)) {
      layersKeys = layersKeys.filter(index => index !== currentSensor);
      layersKeys.unshift(currentSensor as TrendsIndex);
    }

    layersKeys.forEach((currentIndex, index) => {
      if (Object.keys(trendsData[currentIndex]).length) {
        const trendData = trendsData[currentIndex];
        const {data, categories}: any = trendData
          ? getData(trendData, currentIndex)
          : {categories: [], data: {}};
        const title = [
          `${farm.name} - ${field.Name.replace(/\.[^/.]+$/, '')}`.split('_').join(' '),
          `${currentSeason.uiName} - ${currentIndex}`,
        ];
        if (
          !data.datasets?.length ||
          (data?.datasets?.length === 1 && !data?.datasets[0].data?.length)
        )
          return;

        const currentTrend = (
          <div
            key={index}
            className={`analytics-trend-container ${currentIndex} ${isPopUpTrend ? `pop-up` : ''}`}
            onClick={e => (isPopUpTrend ? null : openPop(currentIndex))}
          >
            {!isPopUpTrend && exportStatus && (
              <div className="trends-export">
                <Line
                  data={data}
                  ref={chart => (exportChartsRef.current[`${currentIndex}Chart`] = chart)}
                  options={{
                    //@ts-ignore
                    currentDate: currentDateAsChartProp,
                    //@ts-ignore
                    categories,
                    spanGaps: true,
                    legend: {display: false},
                    title: {
                      display: true,
                      text: title,
                      //@ts-ignore
                      lineHeight: '1.4',
                      fontColor: '#666',
                      fontSize: 32,
                      padding: growthStageLabels[`analytics-chart-${index}`] ? 25 : 15,
                    },
                    layout: {
                      padding: {
                        left: 10,
                        right: 0,
                        bottom: 10,
                        top: 10,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            maxTicksLimit: 8,
                            labelOffset: 3,
                            fontSize: 30,
                            maxRotation: 55,
                            minRotation: 55,
                            padding: 5,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            labelOffset: 3,
                            fontSize: 30,
                            padding: 5,
                          },
                          gridLines: {
                            drawBorder: false, // draw the vertical line near ticks
                            drawTicks: false, // additional dash between tick and actual tick line
                          },
                        },
                      ],
                    },
                    animation: {
                      duration: 0,
                    },
                  }}
                  datasetKeyProvider={() => `${Math.random()}_export`}
                  width={1000}
                  height={1000}
                />
              </div>
            )}

            <Line
              data={data}
              options={{
                //@ts-ignore
                currentDate: currentDateAsChartProp,
                //@ts-ignore
                id: `analytics-chart-${index}`, // used to set growth stages
                //@ts-ignore
                categories,
                //@ts-ignore
                shouldShowGSLabels: true,
                //@ts-ignore
                sensor: currentIndex,
                spanGaps: true,
                hover: {mode: 'nearest'},
                legend: {display: false},
                title: {
                  display: true,
                  text: `${currentIndex}`,
                  //@ts-ignore
                  lineHeight: isPopUpTrend ? '3.5' : '2.8',
                  fontColor: '#666',
                  fontSize: 18,
                  padding: growthStageLabels[`analytics-chart-${index}`] ? 25 : 5,
                },
                layout: {
                  padding: {
                    right: 5,
                  },
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        maxTicksLimit: 8,
                        padding: 5,
                      },
                      gridLines: {
                        drawBorder: false, // draw the vertical line near ticks
                        drawTicks: false, // additional dash between tick and actual tick line
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        maxTicksLimit: 5,
                        maxRotation: 0,
                        labelOffset: 3,
                      },
                    },
                  ],
                },
                animation: {
                  duration: 0,
                },
                responsiveAnimationDuration: 0,
                onClick: onClickChartPoint,
                tooltips: tooltips,
              }}
              datasetKeyProvider={() => Math.random()}
              width={width}
              height={height}
            />

            {Object.keys(preparedTemperatureData) ? (
              <GrowthStageLabels
                isPopup={popUpOpen}
                cropType={currentSeason.cropType}
                data={growthStageLabels[`analytics-chart-${index}`]}
              />
            ) : null}
          </div>
        );

        trendsArray.push(currentTrend);
      }
    });

    return trendsArray;
  };

  const ChartElementsLabels = useMemo(() => {
    const pointsToShow = isWholeFarmView ? allFarmTSP : currentPointsGroup;
    return (
      <div className={'points-container'}>
        <span className={'subtitle'}>Legend:</span>
        {/*<div*/}
        {/*  data-tip={''}*/}
        {/*  data-for={'field-average'}*/}
        {/*  onMouseEnter={() =>*/}
        {/*    dispatch(*/}
        {/*      toggleTooltip({*/}
        {/*        id: 'field-average',*/}
        {/*        content: 'Average for the whole field.',*/}
        {/*        place: 'right',*/}
        {/*      })*/}
        {/*    )*/}
        {/*  }*/}
        {/*  onClick={this.toggleFarmMean}*/}
        {/*  className={`analytic-point farm ${!analytics.showKmlMean ? 'hidden' : ''}`}*/}
        {/*>*/}
        {/*  <CurveLine />*/}
        {/*</div>*/}
        <div
          data-tip={''}
          data-for={'field-smoothed-average'}
          onMouseEnter={() =>
            dispatch(
              toggleTooltip({
                id: 'field-smoothed-average',
                content: t({id: 'Whole field smoothed average.'}),
                place: 'right',
              })
            )
          }
          onClick={() => toggleSmoothedData()}
          className={cn('analytic-point farm smoothed', {hidden: !analytics.showSmoothedData})}
        >
          <CurveLine />
        </div>

        {pointsToShow?.length
          ? pointsToShow.map((element: any, i: number) => {
              return (
                <div
                  key={i}
                  title={'Click to hide/show this point data from trend'}
                  style={{backgroundColor: element.properties.color}}
                  className={cn('analytic-point', {hidden: element.properties.visible === false})}
                >
                  <LeafIcon onClick={() => toggleVisibility(element.id, 'TPS')} />
                </div>
              );
            })
          : null}

        {analyticPoints.length
          ? analyticPoints.map((point: AnalyticPoint, i: number) => {
              return (
                <div
                  key={i}
                  title={t({
                    id: 'Click to hide/show this point data from trend.',
                    defaultMessage: 'Click to hide/show this point data from trend.',
                  })}
                  onClick={() => toggleVisibility(i)}
                  className={cn('analytic-point custom', {hidden: point.visible === false})}
                  style={{backgroundColor: point.color}}
                >
                  {!!point.arableData && <ArableIcon className={'arable-icon'} />}
                </div>
              );
            })
          : null}

        {allFieldShapes.length
          ? allFieldShapes.map(g => {
              return (
                <div
                  key={g.properties.id || g.properties.anomaly_id || g.properties.area}
                  title={t({
                    id: 'Click to hide/show this geometry data from trend.',
                    defaultMessage: 'Click to hide/show this geometry data from trend.',
                  })}
                  onClick={() => {
                    if (g.properties.anomaly_id)
                      return dispatch(
                        updatePremiumAnomaly({
                          id: g.properties.anomaly_id,
                          prop: 'visible',
                          value: g.properties.visible === false,
                          g,
                        })
                      );
                    if (!g.properties.isLowPerf) {
                      return dispatch(
                        changeAreaOfInterestProp(g, 'visible', g.properties.visible === false)
                      );
                    } else {
                      return dispatch(
                        changeLowPerfAnomalyProp(g, 'visible', g.properties.visible === false)
                      );
                    }
                  }}
                  className={cn('analytic-point geometry', {
                    hidden: g.properties.visible === false,
                  })}
                  style={{borderColor: g.properties.color}}
                />
              );
            })
          : null}
      </div>
    );
  }, [
    isWholeFarmView,
    currentPointsGroup,
    analyticPoints,
    allFieldShapes,
    analytics.showSmoothedData,
  ]);

  const hasVisiblePoints = useMemo(() => {
    const visibleSamplingPoints = (isWholeFarmView ? allFarmTSP : currentPointsGroup)?.filter(
      p => p.properties.visible !== false
    );
    const visibleAnalyticPoints = analyticPoints.filter(p => p.visible !== false);
    const visibleFieldShapes = allFieldShapes.filter(p => p.properties.visible !== false);

    return [
      ...(visibleSamplingPoints || []),
      ...(visibleAnalyticPoints || []),
      ...(visibleFieldShapes || []),
    ].filter(p => p).length;
  }, [
    isWholeFarmView,
    currentPointsGroup,
    analyticPoints,
    allFieldShapes,
    analytics.showSmoothedData,
  ]);

  // const toggleFarmMean = () => {
  //   dispatch(toggleAnalyticKmlMean(!analytics.showKmlMean));
  // };

  const toggleSmoothedData = () => {
    dispatch(toggleAnalyticSmoothedData(!analytics.showSmoothedData));
  };

  const shouldNotShowContent = () => {
    const {isNotProcessing, isNotImagery} = imageStatus;

    return (
      (!isNotProcessing || !currentSeason?.id || !Object.keys(currentDates).length) && !isNotImagery
    );
  };

  const warningMessage = useMemo(() => {
    return hasVisiblePoints && remoteSensingCloudCover > 5 ? (
      <InfoBlock appearance={'warning'} className={`tab-info-block`}>
        <FormattedMessage
          id="[analytics] Some of your imagery have more than 5% cloud"
          defaultMessage="
          Some of your imagery have more than 5% cloud. If you dont want the cloudy data points to
          appear on the charts, please hide the cloudy imagery in the <span>data layers</span> tab first.
        "
          values={{
            span: (txt: string) => (
              <span onClick={() => dispatch(setFeature('data-layers'))} className={'global-link'}>
                {txt}
              </span>
            ),
          }}
        />
      </InfoBlock>
    ) : null;
  }, [remoteSensingCloudCover, hasVisiblePoints]);

  const getMainContent = () => {
    if (shouldNotShowContent()) {
      return null;
    }

    return (
      <>
        <HideWhileLoading
          statusKeys={[
            AsyncStatusType.analyticsGeometriesAndPoints,
            AsyncStatusType.analyticsFieldArableData,
          ]}
          content={isLoading => (
            <div className={'analytics-export'}>
              <ReadOnly>
                <MenuButton
                  id={`menu-button-export`}
                  className="export-button"
                  listClassName="export-button__menu-list"
                  icon
                  disabled={(isReadOnly && !isOnBoardingNow) || isLoading}
                  menuItems={[
                    <ListItem
                      key={1}
                      onClick={() => exportToFile('png')}
                      primaryText={t({id: 'Export {ext}'}, {ext: '.png'})}
                    />,
                    <ListItem
                      key={2}
                      onClick={() => exportToFile('csv')}
                      primaryText={t({id: 'Export {ext}'}, {ext: '.csv'})}
                    />,
                  ]}
                >
                  <FontIcon>more_vert</FontIcon>
                </MenuButton>
              </ReadOnly>
            </div>
          )}
        />

        {ChartElementsLabels}

        {warningMessage}

        {!popUpOpen && <div className={'analytics-trends'}> {getTrends()} </div>}

        <FluroDialog
          id="analytic-trend-pop"
          className={'analytic-trend-pop'}
          visible={popUpOpen}
          onHide={closePop}
          isDraggable
          onShow={handleOnShow}
          title={t({id: 'Trends', defaultMessage: 'Trends'})}
          width="560px"
          focusOnMount={false}
        >
          {ChartElementsLabels}
          <ErrorBoundary>
            <Slider
              ref={sliderRef}
              className={'trends-slider'}
              vertical
              verticalSwiping
              speed={500}
              adaptiveHeight
            >
              {getTrends(true)}
            </Slider>
          </ErrorBoundary>
        </FluroDialog>
      </>
    );
  };

  return (
    <div className={'section-container analytics-container'}>
      <h3 className={'tab-title'}>
        {t({id: 'Analytics', defaultMessage: 'Analytics'})}{' '}
        <Ln
          blank
          external
          className="help-ln"
          href={'https://help.flurosense.com/en/articles/4484911-time-based-analytics'}
        >
          <FontIcon>help_outline</FontIcon>
        </Ln>
        <SimplePreloader
          statusKeys={[
            AsyncStatusType.analyticsGeometriesAndPoints,
            AsyncStatusType.analyticsFieldArableData,
          ]}
        />
      </h3>
      {getMainContent()}
    </div>
  );
};

export default Analytics;
