import {t, FormattedMessage} from 'i18n-utils';
import React, {memo} from 'react';
import classNames from 'classnames';
import {clamp, toFixedFloat} from '_utils';

interface Props {
  value: number;
}

// More than 10: dark green
// More than 2: light green
// Between -2 and 2: grey
// Less than -2: orange
// Less than -10: red

const ChangeScale = memo((props: Props): any => {
  const value: number = clamp(-100, props.value, 100);

  const classes: any = {
    'change-scale--scale-1': value > 10,
    'change-scale--scale-2': value > 2 && value <= 10,
    'change-scale--scale-3': value <= 2 && value >= -2,
    'change-scale--scale-4': value < -2 && value >= -10,
    'change-scale--scale-5': value < -10,
  };

  return <span className={classNames('change-scale', classes)}>{toFixedFloat(props.value)}%</span>;
});

export default ChangeScale;
