import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, CircularProgress, SelectionControl} from 'react-md';
import config from '_environment';
import {showNote} from '_actions';
import {AgworldApi} from '_api';
import {
  ExpandableArea,
  ExpandableRowContainer,
  NoItemsTitle,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {RequestStatus} from 'types';
import {importAgworldGrowers} from './actions';
import Mixpanel from '_utils/mixpanel-utils';
import {ExternalService} from '../../map/types';
import HelpBtn from '../../help-popup/btn';
import HelpModal from '../../help-popup/modal';
import IntegrationCard from './helpers/integration-card';
import ColoredBorder from './helpers/colored-border';
import cn from 'classnames';
import {sortByStringKey} from '_utils';
import {reportError} from '../../error-boundary';
import {useAppSelector} from '_hooks';
import {FluroButton} from 'components';

/**
 * Agworld integration flow:
 *
 * 1. Fetch growers list
 * -> if failed – means a user isn't authorized, so we show the Auth button
 * -> if success – show the growers list
 *
 * 2. A user can select growers they want to import from Agworld to FluroSense.
 * This procedure can take up to 5 minutes (or even longer) depending on the
 * number of fields to import.
 */
type Props = {
  expanded: boolean;
  setExpanded(): void;
};

export type AgworldSyncRequestPayload = {
  growers: string[];
  farms: string[];
  fields: string[];
};

type AgworldEntity = {
  guid: string;
  name: string;
};

export const classifyAgworldHighestEntity = (data: AgworldSyncRequestPayload) => {
  if (data.growers) {
    return t({id: 'growers'});
  } else if (data.farms) {
    return t({id: 'farms'});
  } else if (data.fields) {
    return t({id: 'fields'});
  }
};

const NoEntities: AgworldEntity[] = [{name: 'no-entities', guid: null}];

const IntegrationAgworld = ({expanded, setExpanded}: Props) => {
  const dispatch = useDispatch();
  const platform = useAppSelector(store => store.integrations.platforms[ExternalService.Agworld]);

  const [authorized, setAuthorized] = useState(false);
  const [growers, setGrowers] = useState<AgworldEntity[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedGrowers, setSelectedGrowers] = useState<string[]>([]);
  const [expandedGrowers, setExpandedGrowers] = useState<{[growerId: string]: boolean}>({});

  const [farms, setFarms] = useState<{[growerId: string]: AgworldEntity[]}>({});
  const [selectedFarms, setSelectedFarms] = useState<{[growerId: string]: string[]}>({});
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const [fields, setFields] = useState<{[farmId: string]: AgworldEntity[]}>({});
  const [selectedFields, setSelectedFields] = useState<{[farmId: string]: string[]}>({});

  const [requests, setRequests] = useState<{[entityId: string]: RequestStatus}>({});

  const getGrowers = useCallback(() => {
    setLoading(true);
    AgworldApi.getGrowers()
      .then(res => {
        const sortedGrowers = sortByStringKey(res.data || [], 'name');
        setGrowers(sortedGrowers);
        setAuthorized(true);
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        setAuthorized(false); // Assume a user isn't authorized if no growers returned.
        showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: "Couldn't fetch Agworld growers.",
          level: 'error',
        });
      });
  }, []);

  useEffect(getGrowers, []);

  useEffect(() => {
    if (expanded && !growers.length && !loading) {
      getGrowers();
    }
  }, [expanded]);

  const toggleGrower = (growerId: string, value: boolean) => {
    setSelectedGrowers(selectedGrowers => {
      return value ? [...selectedGrowers, growerId] : selectedGrowers.filter(x => x !== growerId);
    });
    // If we have farms loaded for the grower, update them.
    if (farms[growerId]) {
      setSelectedFarms(selectedFarms =>
        value
          ? {...selectedFarms, [growerId]: farms[growerId].map(f => f.guid)}
          : {...selectedFarms, [growerId]: []}
      );

      const newFields: {[farmId: string]: string[]} = {};
      farms[growerId].forEach(farm => {
        if (fields[farm.guid]) {
          newFields[farm.guid] = value ? fields[farm.guid].map(f => f.guid) : [];
        }
      });
      setSelectedFields(newFields);
    }
  };

  const toggleAllGrowers = (value: boolean) => {
    setSelectedGrowers(value ? growers.map(g => g.guid) : []);

    const newFarms: {[growerId: string]: string[]} = {};
    Object.entries(farms).forEach(([growerId, farms]) => {
      newFarms[growerId] = value ? farms.map(f => f.guid) : [];
    });
    setSelectedFarms(newFarms);

    const newFields: {[farmId: string]: string[]} = {};
    Object.entries(fields).forEach(([farmId, fields]) => {
      newFields[farmId] = value ? fields.map(f => f.guid) : [];
    });
    setSelectedFields(newFields);
  };

  const getAgworldFarms = (growerId: string) => {
    setRequests(r => ({...r, [growerId]: RequestStatus.Loading}));
    AgworldApi.getFarms(growerId)
      .then(res => {
        let f = sortByStringKey(res.data || [], 'name');
        if (!f.length) {
          f = NoEntities;
        }
        setFarms(farms => ({...farms, [growerId]: f}));
        setRequests(r => ({...r, [growerId]: RequestStatus.Success}));
        if (selectedGrowers.includes(growerId)) {
          setSelectedFarms(selectedFarms => ({
            ...selectedFarms,
            [growerId]: f.map(f => f.guid),
          }));
        }
      })
      .catch(e => {
        setFarms(farms => ({...farms, [growerId]: NoEntities}));
        setRequests(r => ({...r, [growerId]: RequestStatus.Error}));
        reportError(`Agworld sync error fetching farms for grower ${growerId}: ` + e);
      });
  };

  const getAgworldFields = (growerId: string, farmId: string) => {
    setRequests(r => ({...r, [farmId]: RequestStatus.Loading}));

    AgworldApi.getFields(farmId)
      .then(res => {
        let f = sortByStringKey(res.data || [], 'name');
        if (!f.length) {
          f = NoEntities;
        }

        setFields(fields => ({...fields, [farmId]: f}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Success}));
        if (selectedFarms[growerId]?.includes(farmId)) {
          // If the expanded farm was selected, select all the including fields automatically.
          setSelectedFields(selectedFields => {
            return {
              ...selectedFields,
              [farmId]: f.map(f => f.guid),
            };
          });
        }
      })
      .catch(e => {
        setFields(fields => ({...fields, [farmId]: NoEntities}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Error}));
        reportError(`Agworld sync error fetching fields for farm ${farmId}: ` + e);
      });
  };

  const expandGrower = (growerId: string) => {
    const newValue = !expandedGrowers[growerId];
    setExpandedGrowers(x => ({
      ...x,
      [growerId]: newValue,
    }));
    if (newValue && !farms[growerId]) {
      getAgworldFarms(growerId);
    }
  };

  const expandFarm = (growerId: string, farmId: string) => {
    const newValue = !expandedFarms[farmId];
    setExpandedFarms(x => ({
      ...x,
      [farmId]: newValue,
    }));
    if (newValue && !fields[farmId]) {
      getAgworldFields(growerId, farmId);
    }
  };

  const toggleFarm = (growerId: string, farmId: string, value: boolean) => {
    const oldFarms = selectedFarms[growerId] || [];
    const newFarms = value ? [...oldFarms, farmId] : oldFarms.filter(x => x !== farmId);
    setSelectedFarms(selectedFarms => ({
      ...selectedFarms,
      [growerId]: newFarms,
    }));

    // Check if parent (grower) needs to be updated.
    if (oldFarms.length > 0 && newFarms.length === 0) {
      // Some farms of the grower were selected and now none of them are selected.
      // Deselect the grower.
      setSelectedGrowers(growers => growers.filter(id => id !== growerId));
    } else if (oldFarms.length === 0 && newFarms.length > 0) {
      // None of the farms of the grower were selected and now some of them are selected.
      // Select the grower.
      setSelectedGrowers(growers => [...growers, growerId]);
    }

    // If we have fields loaded for the farm, update them.
    if (fields[farmId]) {
      setSelectedFields(selectedFields =>
        value
          ? {...selectedFields, [farmId]: fields[farmId].map(f => f.guid)}
          : {...selectedFields, [farmId]: []}
      );
    }
  };

  const toggleField = (growerId: string, farmId: string, fieldId: string, value: boolean) => {
    const oldFields = selectedFields[farmId] || [];
    const newFields = value ? [...oldFields, fieldId] : oldFields.filter(x => x !== fieldId);
    setSelectedFields(selectedFields => ({
      ...selectedFields,
      [farmId]: newFields,
    }));

    // Check if the parent (farm) needs to be updated.
    if (selectedFarms[growerId]?.includes(farmId) && !newFields.length) {
      // The farm is selected but none of the fields inside this farm is selected anymore.
      // Deselect the farm.
      const oldFarms = selectedFarms[growerId];
      const newFarms = selectedFarms[growerId].filter(id => id !== farmId);
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length > 0 && newFarms.length === 0) {
        // Some farms of the grower were selected and now none of them are selected.
        // Deselect the grower.
        setSelectedGrowers(growers => growers.filter(id => id !== growerId));
      }
    } else if (!selectedFarms[growerId]?.includes(farmId) && newFields.length) {
      // The farm is not selected but the field inside this farm is selected.
      // Select the farm.
      const oldFarms = selectedFarms[growerId] || [];
      const newFarms = [...oldFarms, farmId];
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length === 0 && newFarms.length > 0) {
        // None of the farms of the grower were selected and now some of them are selected.
        // Select the grower.
        setSelectedGrowers(growers => [...growers, growerId]);
      }
    }
  };

  const onImportGrowers = () => {
    const growersNames = growers.filter(g => selectedGrowers.includes(g.guid)).map(g => g.name);
    Mixpanel.importSync(ExternalService.Agworld, growersNames);
    dispatch(
      importAgworldGrowers({
        // send all required fields, farms and growers, dealt with already in the backend
        fields: Object.values(selectedFields).reduce((acc, v) => [...acc, ...v], []),
        farms: Object.values(selectedFarms).reduce((acc, v) => [...acc, ...v], []),
        growers: Object.values(selectedGrowers),
      })
    );
  };

  return (
    <div className={cn('integration-platform agworld', {selected: platform.selected})}>
      <IntegrationCard
        authorized={authorized}
        expanded={expanded}
        logoSrc={'/assets/integration/agworld-logo-round.svg'}
        logoAlt={'agworld-logo'}
        onAuthorize={authorize}
        onToggle={setExpanded}
        platformName={'Agworld'}
        platformCompanyName={'Agworld'}
        platformInnerName={ExternalService.Agworld}
        isPlatformSelected={platform.selected}
        loading={loading && !growers.length}
      />

      {expanded && authorized && (
        <>
          {loading && !growers.length ? null : (
            <>
              <div className="integration-platform__description">
                {t({id: 'Selected growers will be imported to FluroSense.'})}
              </div>
              <div className={'entities-to-import-container'}>
                {growers.length > 1 && (
                  <SelectionControl
                    id={'select-all-agworld-growers'}
                    name={'select-all-agworld-growers'}
                    label={t(
                      {id: 'Select all growers ({count1}/{count2})'},
                      {count1: selectedGrowers.length, count2: growers.length}
                    )}
                    type={'checkbox'}
                    className={'select-all-checkbox'}
                    checked={selectedGrowers.length === growers.length}
                    onChange={(v: boolean) => toggleAllGrowers(v)}
                  />
                )}
                {growers.map(grower => {
                  const isGrowerSelected = selectedGrowers.includes(grower.guid);
                  return (
                    <React.Fragment key={grower.guid}>
                      <ExpandableRowContainer
                        className={cn({
                          selected: isGrowerSelected,
                        })}
                      >
                        <SelectionControl
                          id={grower.guid}
                          name={'grower selection'}
                          className={'highest-platform-entity'}
                          label={
                            grower.name +
                            selectedEntitiesNumber(farms[grower.guid], selectedFarms[grower.guid])
                          }
                          type={'checkbox'}
                          checked={isGrowerSelected}
                          onChange={(v: boolean) => toggleGrower(grower.guid, v)}
                        />
                        <ExpandableArea
                          onClick={() => expandGrower(grower.guid)}
                          expanded={expandedGrowers[grower.guid]}
                        />
                      </ExpandableRowContainer>
                      {expandedGrowers[grower.guid] && (
                        <SubContainer>
                          {requests[grower.guid] === RequestStatus.Loading && (
                            <span className="preloader">
                              <CircularProgress centered={false} id={grower.guid} />
                            </span>
                          )}
                          {farms[grower.guid]?.map(f => {
                            if (farms[grower.guid]?.length === 1 && f.name === 'no-entities') {
                              return (
                                <NoItemsTitle key={f.guid}>
                                  {t({id: 'No farms found'})}
                                </NoItemsTitle>
                              );
                            }

                            const isFarmSelected = (selectedFarms[grower.guid] || []).includes(
                              f.guid
                            );
                            return (
                              <React.Fragment key={f.guid}>
                                <SubItem
                                  className={cn({
                                    selected: isFarmSelected,
                                  })}
                                >
                                  <SelectionControl
                                    id={f.guid}
                                    name={'farm selection'}
                                    className={'medium-platform-entity'}
                                    label={
                                      f.name +
                                      selectedEntitiesNumber(fields[f.guid], selectedFields[f.guid])
                                    }
                                    type={'checkbox'}
                                    checked={isFarmSelected}
                                    onChange={(v: boolean) => toggleFarm(grower.guid, f.guid, v)}
                                  />
                                  <ExpandableArea
                                    onClick={() => expandFarm(grower.guid, f.guid)}
                                    expanded={expandedFarms[f.guid]}
                                  />
                                </SubItem>
                                {expandedFarms[f.guid] && (
                                  <SubContainer>
                                    {requests[f.guid] === RequestStatus.Loading && (
                                      <span className="preloader">
                                        <CircularProgress centered={false} id={f.guid} />
                                      </span>
                                    )}
                                    {fields[f.guid]?.map(field => {
                                      if (
                                        fields[f.guid]?.length === 1 &&
                                        field.name === 'no-entities'
                                      ) {
                                        return (
                                          <NoItemsTitle key={field.guid}>
                                            {t({id: 'No fields found'})}
                                          </NoItemsTitle>
                                        );
                                      }

                                      return (
                                        <SubItem key={field.guid}>
                                          <SelectionControl
                                            id={field.guid}
                                            name={'field selection'}
                                            className={'small-platform-entity'}
                                            label={field.name}
                                            type={'checkbox'}
                                            checked={(selectedFields[f.guid] || []).includes(
                                              field.guid
                                            )}
                                            onChange={(v: boolean) =>
                                              toggleField(grower.guid, f.guid, field.guid, v)
                                            }
                                          />
                                        </SubItem>
                                      );
                                    })}
                                  </SubContainer>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </SubContainer>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="action-buttons-holder">
                <FluroButton blank raised onClick={authorize}>
                  {t({id: 'Re-authorize'})}
                </FluroButton>

                <div className="md-text-right">
                  {platform.syncStatus === RequestStatus.Loading && (
                    <>
                      <HelpBtn
                        icon={'refresh'}
                        id="agworld-sync-status"
                        className="integration-platform__sync-status"
                      />
                      <HelpModal title="Sync in progress" id="agworld-sync-status" width={400}>
                        <div className="helper-text">
                          {t({id: 'You will be notified when it is ready'})}
                        </div>
                      </HelpModal>
                    </>
                  )}

                  <Button
                    raised
                    primary
                    className="md-btn--right"
                    disabled={
                      !selectedGrowers.length || platform.syncStatus === RequestStatus.Loading
                    }
                    onClick={onImportGrowers}
                  >
                    {platform.syncStatus === RequestStatus.Loading
                      ? t({id: 'Importing...'})
                      : t({id: 'Import'})}
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <ColoredBorder colorStart={'rgb(111, 190, 68)'} colorEnd={'rgba(111, 190, 68, 0.2)'} />
    </div>
  );
};

const authorize = () => {
  // @ts-ignore
  window.location = `${config.baseUrl}login/agworld`;
};

const selectedEntitiesNumber = (list: AgworldEntity[], selected?: string[]) => {
  if (!list) {
    return '';
  }
  return ` (${selected?.length || 0}/${list.length})`;
};

export default IntegrationAgworld;
