import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {FontIcon, SelectField, TextField, Button} from 'react-md';
import FluroDialog from 'components/fluro-dialog';
import {withFormik, FormikProps} from 'formik';
import Yup from 'yup';
import CropStatus from '../crop-status-icon';
import {
  getPropertiesFromSeasons,
  getNrxCropSubTypes,
  isSetNrxSeasonSowingData,
  isSetNrxSeasonCropMatch,
  getNrxDefaultSowingPropsByCropID,
  getNrxSeason,
  NRxSeason,
  NrxListsData,
} from '../../../../utils/nrx-utils';
import {capitalizeFirstLetter} from '_utils';

type ValuesProps = {
  seasonID: number | number[];
  cropID: number;
  cropSubtype: string;
  cropType: string;
  cropVarietyID: number;
};

type Props = {
  particularSeason: NRxSeason;
  nrxListsData: NrxListsData;
  selectedSeasons: NRxSeason[];

  // TODO (stas): Return type should be Promise, but dispatch returns dispatch => Promise
  // and I dunno how to work it around.
  setUpdateSeasonNrxData: (id: Array<number> | number, data: any) => any;
  togglePopUp: (popUp: string, value: boolean, seasonToSet?: number) => void;
};

class MatchCropPopUp extends PureComponent<Props & FormikProps<ValuesProps>> {
  onChange = (value: any, prop: keyof ValuesProps) => {
    this.props.setFieldValue(prop, value);
    if (prop === 'cropID') this.props.setFieldValue('cropVarietyID', 0);
  };

  onHide = () => {
    const {seasonID} = this.props.values;
    const confirm = (
      Array.isArray(seasonID)
        ? seasonID.every(sId => isSetNrxSeasonCropMatch(getNrxSeason(sId)))
        : isSetNrxSeasonCropMatch(getNrxSeason(seasonID))
    )
      ? true
      : window.confirm(
          t({
            id:
              'You need to set all parameters to start using the model, are you sure you want to cancel?',
          })
        );

    if (confirm) {
      this.props.togglePopUp('matchCropPopUpOpen', false);
      this.props.resetForm();
    }
  };

  render() {
    const {handleSubmit, errors, touched, values, nrxListsData} = this.props;
    const error = (value: keyof ValuesProps) => touched[value] && errors[value];
    const cropSubTypes = getNrxCropSubTypes(values.cropID);
    return (
      <FluroDialog
        id={`match-crop-type-pop-up`}
        className={'match-crop-type-pop-up'}
        visible={true}
        isDraggable
        title={t({id: 'Match crop type and varieties to available models'})}
        onHide={this.onHide}
        focusOnMount={false}
      >
        <div className={'info-text'}>
          <FontIcon>help_outline</FontIcon>
          {t({
            id:
              'APSIM does not have models available for your selected (crop type or variety). Please select the closest (crop type or variety) available from the following list:',
          })}
        </div>

        <div className={'columns-container'}>
          <div className={'column'}>
            <div className={'title'}>{t({id: 'Your selection'})}</div>
            <div className={'d-flex align-center'}>
              <TextField
                id="crop-type"
                label={t({id: 'Your crop type'})}
                lineDirection="center"
                value={values.cropType}
                disabled
              />
              <CropStatus
                status={Boolean(values.cropID)}
                message={
                  <span>
                    <FormattedMessage
                      id={'Crop is not <br /> available in the <br /> model'}
                      values={{br: () => <br />}}
                    />
                  </span>
                }
              />
            </div>

            <div className={'d-flex align-center'}>
              <TextField
                id="crop-custom-sub-type"
                label={t({id: 'Your variety'})}
                lineDirection="center"
                value={values.cropSubtype}
                disabled
              />

              <CropStatus
                status={Boolean(values.cropVarietyID)}
                message={
                  <span>
                    <FormattedMessage
                      id={'Variety is not <br /> available in the <br /> model'}
                      values={{br: () => <br />}}
                    />
                  </span>
                }
              />
            </div>
          </div>
          <div className={'column'}>
            <div className={'title'}>{t({id: 'APSIM match'})}</div>
            <SelectField
              id="crop-sub-type"
              label={t({id: 'APSIM crop type'})}
              placeholder={t({id: 'Select crop type'})}
              menuItems={nrxListsData.cropTypesList}
              simplifiedMenu={true}
              value={values.cropID}
              error={!!error('cropID')}
              errorText={error('cropID')}
              onChange={value => this.onChange(value, 'cropID')}
            />

            <SelectField
              id="crop-sub-type"
              label={t({id: 'APSIM variety'})}
              placeholder={t({id: 'Select a variety'})}
              menuItems={cropSubTypes}
              simplifiedMenu={true}
              value={values.cropVarietyID}
              error={!!error('cropVarietyID')}
              errorText={error('cropVarietyID')}
              onChange={value => this.onChange(value, 'cropVarietyID')}
              disabled={!cropSubTypes.length}
              onClick={() =>
                cropSubTypes.length ? null : alert(t({id: 'Select crop type first'}))
              }
            />
          </div>
        </div>

        <div className={'button-container'}>
          <Button raised primary onClick={() => handleSubmit()}>
            {t({id: 'Save changes'})}
          </Button>
        </div>
      </FluroDialog>
    );
  }
}

const FormicForm = withFormik<Props, ValuesProps>({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const {seasonID, cropType, cropSubtype, cropVarietyID, cropID} = props.particularSeason
      ?.seasonID
      ? props.particularSeason
      : getPropertiesFromSeasons(props.selectedSeasons);

    return {
      cropID: Array.isArray(cropID) ? 0 : cropID,
      cropVarietyID: Array.isArray(cropVarietyID) ? 0 : cropVarietyID,
      cropType: Array.isArray(cropType)
        ? t({id: 'Multiple crops'})
        : capitalizeFirstLetter(cropType),
      cropSubtype: Array.isArray(cropSubtype)
        ? t({id: 'Multiple varieties'})
        : capitalizeFirstLetter(cropSubtype || t({id: 'Empty'})),
      seasonID,
    };
  },

  validationSchema: Yup.object().shape({
    cropID: Yup.string().required(t({id: 'This field is required'})),
    cropVarietyID: Yup.number()
      .min(1, t({id: 'This field is required'}))
      .required(t({id: 'This field is required'})),
  }),

  handleSubmit: (values, {props, setSubmitting}) => {
    props
      .setUpdateSeasonNrxData(values.seasonID, {
        cropID: values.cropID,
        cropVarietyID: values.cropVarietyID,
        ...getNrxDefaultSowingPropsByCropID(values.seasonID, values.cropID),
      })
      .then((_: any) => setSubmitting(false));

    if (!isSetNrxSeasonSowingData(values.seasonID))
      return props.togglePopUp(
        'plantingSoilPopUpOpen',
        true,
        Array.isArray(values.seasonID) ? null : values.seasonID
      );

    props.togglePopUp('matchCropPopUpOpen', false);
  },

  displayName: 'MatchCropPopUp',
})(MatchCropPopUp);

export default FormicForm;
