import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
const ColoredBorder = ({colorStart, colorEnd}: {colorStart: string; colorEnd: string}) => {
  return (
    <div
      style={{background: `linear-gradient(to right, ${colorStart}, ${colorEnd}`}}
      className="integration-platform__colored-border"
    />
  );
};

export default ColoredBorder;
