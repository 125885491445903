import {FarmActionTypes, Farm} from './types';
import {t} from 'i18n-utils';
import {FarmApi} from '_api';
import {push} from 'react-router-redux';
import {setGlobalParam, showNote} from '_actions';
import {logout} from '../login/actions';
import {isAgxFarm} from '_utils/farm-utils';
import {ActionType as IntegrationsActionType} from 'containers/profile/integration/reducer';
import {checkAuth} from 'containers/login/actions';
import {loadFields} from '../map/actions';
import pathToRegexp from 'path-to-regexp';
import {reportError} from '../error-boundary';
import {ActionTypes} from 'containers/map/reducer/types';
import Mixpanel from '_utils/mixpanel-utils';
import {
  setRequestStatus,
  Status,
  AsyncStatusType,
  dialogToggle,
  DialogType as HelpersDialogType,
} from 'modules/ui-helpers';
import {AppStore} from '../../reducers';
import {DialogType, toggleDialog} from '_reducers/dialog';

export const getFarmsList = () => (dispatch: any) => {
  dispatch(setRequestStatus(AsyncStatusType.farmsList, Status.Pending));

  return FarmApi.getFarmsList()
    .then(async ({data}) => {
      if (data.result.list) {
        await FarmApi.getDemoFarmsList()
          .then(({data: {result = {}}}) => {
            const listOfFarms = data.result.list;
            dispatch({
              type: FarmActionTypes.LOAD_FARMS_LIST,
              farms: {...data.result, list: listOfFarms},
              demoFarms: result,
            });
          })
          .catch(() => {
            dispatch({
              type: FarmActionTypes.LOAD_FARMS_LIST,
              group: {...data.result},
              demoFarms: {},
            });
          });

        const hasAgx = data.result.list.some(isAgxFarm);

        dispatch({
          type: IntegrationsActionType.AGX_TOGGLE_FARMS_IMPORTED,
          farmsImported: hasAgx,
        });

        // if (data.result.list.length && global) {
        //   let {farmId} = getDataFromRedirectUrl();
        //   farmId = farmId || data.result.list[0]?.id | 1000;
        //
        //   dispatch({
        //     type: GlobalActionTypes.SET_GLOBAL_PARAM,
        //     propName: 'currentGroupId',
        //     value: parseInt(`${farmId}`),
        //   });
        // }

        dispatch(setRequestStatus(AsyncStatusType.farmsList, Status.Done));

        return data.result.list;
      }

      dispatch(setRequestStatus(AsyncStatusType.farmsList, Status.Done));

      return [];
    })
    .catch(err => reportError(`getFarmsList() err = ${err}`));
};

export const getOne = (farmId: number, skipLoader = false) => (dispatch: any) => {
  dispatch(setRequestStatus(AsyncStatusType.loadOneFarm, Status.Pending));
  return FarmApi.getFarmById(farmId, skipLoader)
    .then(({data: {result}}) => {
      dispatch(setRequestStatus(AsyncStatusType.loadOneFarm, Status.Done));
      return result;
    })
    .catch(err => {
      dispatch(setRequestStatus(AsyncStatusType.loadOneFarm, Status.Done));
      return err;
    });
};

export const selectFarm = (farmId: number) => (dispatch: any) => {
  const path = `/:view/:groupId`;
  const view = 'maps';
  const toPath = pathToRegexp.compile(path);
  dispatch(setGlobalParam('currentGroupId', farmId));

  try {
    dispatch(
      push(
        toPath({
          view,
          path,
          section: null,
          groupId: '' + farmId,
        }) + window.location.search || ''
      )
    );

    // if it is potato demo farm then go to the WholeFarm first
    return dispatch(loadFields(farmId, farmId === 13367 ? 'WholeFarm' : null));
  } catch (e) {
    return Promise.resolve(false);
    console.log('selectFarm', e.message);
  }
};

export const saveFarm = (params: Partial<Farm>) => async (
  dispatch: any,
  getState: () => AppStore
) => {
  const {group} = getState().map;

  return FarmApi[params.id === 0 ? 'saveFarm' : 'updateFarm'](params)
    .then(({data: {result}}) => {
      if (params.id === 0) {
        Mixpanel.addNewFarm(result);
      }

      dispatch({
        type: params.id === 0 ? FarmActionTypes.CREATE_FARM : FarmActionTypes.UPDATE_FARM,
        farm: result,
      });

      if (group.id === result.id) dispatch({type: ActionTypes.MAP_FARM_UPDATE, group: result}); // not good approach, we just keep value in two place, need to remove it
      return result;
    })
    .catch(err => {
      console.log('saveFarm() err', err);
      return {};
    });
};

export const removeFarm = (farmId: number, isCarbon?: boolean) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const farmsList = getState().farms.list;

  return FarmApi.removeFarm(farmId)
    .then(({data: {result}}) => {
      dispatch({type: FarmActionTypes.REMOVE_FARM, farmId});
      dispatch({type: ActionTypes.MAP_FARM_DELETE, farmId});

      dispatch(dialogToggle(HelpersDialogType.deleteDialog, false));
      dispatch(dialogToggle(HelpersDialogType.editFarm, false));

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Your farm was successfully deleted'}),
          level: 'success',
        })
      );

      if (!isCarbon) {
        const nextFarmId = farmsList[0]?.id;
        if (nextFarmId) {
          dispatch(loadFields(nextFarmId));
        }
        dispatch(push(nextFarmId ? `/maps/${nextFarmId}` : '/maps'));
        if (!nextFarmId) {
          dispatch(checkAuth());
        }
      }

      return result;
    })
    .catch(e => reportError(`Remove farm error: ${e}`));
};

export const removeUserFromFarm = (farmId: number, userId: number) => (dispatch: any) => {
  return FarmApi.removeUserFromFarm(farmId, userId)
    .then(() => {
      dispatch({type: FarmActionTypes.RELOAD_FARM});
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'User was deleted'}),
          level: 'success',
        })
      );
    })
    .catch(console.log);
};

export const sendInvite = (farmId: number, email: string[]) => (dispatch: any) => {
  return FarmApi.sendInviteToFarm(farmId, email)
    .then(() => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Invite was sent'}),
          level: 'success',
        })
      );
    })
    .catch(console.log);
};

export const acceptFarmInvite = (uuid: string) => (dispatch: any, getState: () => AppStore) => {
  const {login} = getState();

  return FarmApi.acceptFarmInvite(uuid)
    .then(({data: {result}}) => {
      if (result && result.UserID) {
        if (login.isAuth && login.user.id === result.UserID) {
          dispatch(push('/maps/' + result.GroupID + '?noCache'));
        } else {
          dispatch(push('/login?noCache'));
        }

        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'Invite was accepted', defaultMessage: 'Invite was accepted'}),
            level: 'success',
          })
        );

        setTimeout(() => window.location.reload(), 1500);
      } else {
        logout()(dispatch).then(() => {
          dispatch(push(`/sign-up?noCache&invite=${uuid}`));

          dispatch(
            showNote({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: t({
                id: 'Please create your account first',
                defaultMessage: 'Please create your account first',
              }),
              level: 'warning',
            })
          );
        });
      }
    })
    .catch(err => {
      console.log(err);
      dispatch(push(`/`));
    });
};

// perhaps unused function
export const showUserInvite = (val: any) => ({
  type: FarmActionTypes.SHOW_USER_INVITE,
  value: val,
});
