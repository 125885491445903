import {t, FormattedMessage} from 'i18n-utils';
import React, {Fragment, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {LoginState} from 'containers/login/types';
import {Badge, Button} from 'react-md';
import config from '_environment';
// @ts-ignore react-intercom.d.ts doesn't export IntercomAPI :(
import Intercom, {IntercomAPI} from 'react-intercom';

type Props = ConnectedProps<typeof connector>;
const IntercomComponent = ({email, userId, fullName, createdAt}: Props) => {
  const [visible, changeVisibility] = useState(false);
  const [unreadCount, changeUnreadCount] = useState(0);

  useEffect(() => {
    IntercomAPI('onShow', () => changeVisibility(true));
    IntercomAPI('onHide', () => changeVisibility(false));
    IntercomAPI('onUnreadCountChange', (intercomUnreadCount: number) =>
      changeUnreadCount(intercomUnreadCount)
    );
  }, []);

  if (!config.intercomSettings || email.endsWith('@flurosat.qa')) {
    return null;
  }
  return (
    <Fragment>
      <Intercom
        appID={config.intercomSettings.app_id}
        alignment={config.intercomSettings.alignment}
        horizontal_padding={60}
        vertical_padding={20}
        hide_default_launcher={true}
        custom_launcher_selector="#intercom-launch"
        email={email}
        user_id={userId}
        name={fullName}
        created_at={createdAt}
      />
      <Badge
        badgeContent={unreadCount}
        invisibleOnZero={true}
        secondary
        badgeId="intercom-badge"
        className="fluro-intercom-launch"
      >
        <Button
          id="intercom-launch"
          primary
          swapTheming
          icon
          tooltipLabel="Contact support Team"
          tooltipPosition="left"
        >
          {visible ? 'close' : 'message'}
        </Button>
      </Badge>{' '}
    </Fragment>
  );
};

const mapStateToProps = ({login}: {login: LoginState}) => ({
  email: login.user.email || '',
  userId: login.user.id,
  fullName: login.user.name + ' ' + (login.user.surname || ''),
  createdAt: login.user.createdAt || '',
});

const connector = connect(mapStateToProps, {});
export default connector(IntercomComponent);
