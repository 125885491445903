import {t, FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import moment from 'moment';
import {AppStore} from 'reducers';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE} from '_constants';
import {reduceCsgsForDate} from './records';
import {FontIcon} from 'react-md';

export enum PeriodType {
  week1 = '1 week',
  week2 = '2 weeks',
  month1 = '1 month',
}

export const periodOrder = [PeriodType.week1, PeriodType.week2, PeriodType.month1];

export const periodDetails: {[key in PeriodType]: {amount: number; unit: 'week' | 'month'}} = {
  [PeriodType.week1]: {amount: 1, unit: 'week'},
  [PeriodType.week2]: {amount: 2, unit: 'week'},
  [PeriodType.month1]: {amount: 1, unit: 'month'},
};

export type PeriodicData = {
  period: PeriodType;
  date: string;
  ndvis: {
    [fieldId: number]: number;
  };
  diffs: {
    [fieldId: number]: number;
  };
};

export const getPeriodicData = (
  cropPerformance: AppStore['cropPerformance'],
  currentDate: string,
  filterStatus: AppStore['cropPerformanceFilter']['filterStatus']
): PeriodicData => {
  if (!currentDate) {
    return;
  }
  const {amount, unit} = periodDetails[cropPerformance.period];
  const date = moment(currentDate, GLOBAL_APP_DATE_FORMAT)
    .subtract(amount, unit)
    .format(SERVER_FORMAT_DATE);

  const currentNdvis: {[fieldId: number]: number} = {};
  cropPerformance.records.forEach(r => {
    currentNdvis[r.fieldID] = r.smoothSatelliteNdvi;
  });

  const periodicRecords = reduceCsgsForDate(cropPerformance.farms, date).filter(
    r => r.cropType !== 'fallow' && !filterStatus[r.fieldID]
  );
  const ndvis: {[fieldId: number]: number} = {};
  const diffs: {[fieldId: number]: number} = {};
  periodicRecords.forEach(r => {
    ndvis[r.fieldID] = r.smoothSatelliteNdvi;
    diffs[r.fieldID] = 100 - Math.round((r.smoothSatelliteNdvi / currentNdvis[r.fieldID]) * 100);
  });
  return {period: cropPerformance.period, date, ndvis, diffs};
};

export const PeriodicHeader = ({
  period,
  onChangePeriod,
}: {
  period?: PeriodType;
  onChangePeriod: (options: {next?: boolean; prev?: boolean}) => void;
}) => {
  if (!period) {
    return null;
  }
  return (
    <div className="periodic">
      <div>{t({id: 'Biomass change'})}</div>
      <div className="periodic-controls">
        <FontIcon
          className={'change-period-btn'}
          style={{visibility: period === PeriodType.week1 ? 'hidden' : 'visible'}}
          onClick={e => {
            e.stopPropagation();
            onChangePeriod({prev: true});
          }}
        >
          keyboard_arrow_left
        </FontIcon>
        {t({id: period})}
        <FontIcon
          className={'change-period-btn'}
          style={{visibility: period === PeriodType.month1 ? 'hidden' : 'visible'}}
          onClick={e => {
            e.stopPropagation();
            onChangePeriod({next: true});
          }}
        >
          keyboard_arrow_right
        </FontIcon>
      </div>
    </div>
  );
};
