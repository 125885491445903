import {t, FormattedMessage} from 'i18n-utils';
import {Messages, Props} from '../types';
import InfoBlock from 'components/info-block';
import Ln from 'components/ln';
import React from 'react';
import {WeatherIcon, RemoteSensingIcon, BugIcon, RedBoldText, GreenBoldText} from '../icons.styled';
import {Footer} from '../helpers';

const data: Messages = {
  170: {
    title: 'Nitrogen deficiency detected early',
    width: 450,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            <b>Highlighted</b> in this <span>cotton field</span> are areas of nutrient deficiency in
            the crop ground-truthed by an agronomist, who used imagery to direct{' '}
            <b>plant tissue sampling</b>.
            <br />
            <br />
            Click on the <WeatherIcon /> weather icon on the map (lower left) and find the{' '}
            <span>the crop growth stage</span> corresponding to the image date at which the crop
            stress was first detected.
            <br />
            <br />
            For example, in this field we detected this crop stress first occurred between 1st
            flower and peak bloom.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Crop growth stages are estimated based on the weather data (Growing Degree Days, T_base)
            and then verified using satellite imagery.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  132758: {
    title: 'Variable rate PGR: before and after',
    width: 460,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            In the <b>top left corner</b> of this <span>cotton field</span> you see delayed crop
            development (<RedBoldText>red</RedBoldText>) compared to the rest of the field (
            <GreenBoldText>green</GreenBoldText>). The agronomist decided to use variable rate PGR’s
            to even out the crop growth.
            <br />
            <br />
            Use <b>Compare toggle</b> on <RemoteSensingIcon /> Data Layers tab to chose images in
            Australian mid-season - 17 Dec and on 31 Jan to see the PGR application effect.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Want to know more about PGR’s in cotton?{' '}
            <Ln
              external
              blank
              href="https://flurosat.com/case_studies/flurosense-helps-innovative-cotton-grower-optimise-pgr-application"
            >
              Read the case study here
            </Ln>
            .
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  1022: {
    title: 'Automatically detecting disease',
    width: 400,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            <b>Highlighted</b> in this <span>cotton field</span> are areas affected by disease{' '}
            <BugIcon />. Browse the Dates Dropdown to see how these areas of disease have{' '}
            <span>changed spatially</span> over the course of the growing season.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            <b>Crop Stress</b> detects areas of the field with unusual crop growth, by tracking crop
            vigour, chrolophyll and how they change over time.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },
};

export default data;
