import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import FluroDialog from 'components/fluro-dialog';

import {setId} from './reducer';

class HelpModal extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  componentWillUnmount() {
    this.props.setId.bind(this, '');
  }

  render() {
    return (
      <FluroDialog
        id={`help-popup-${this.props.id}`}
        visible={this.props.currentPopUpId === this.props.id}
        title={this.props.title || ''}
        focusOnMount={false}
        isDraggable
        onHide={this.props.setId.bind(this, '')}
        width={this.props.width === undefined ? null : this.props.width}
        height={this.props.height === undefined ? null : this.props.height}
        autosizeContent={false}
      >
        <div>{this.props.children}</div>
      </FluroDialog>
    );
  }
}

const mapStateToProps = state => ({
  currentPopUpId: state.helpPopup.currentPopUpId,
});

export default connect(mapStateToProps, {setId})(HelpModal);
