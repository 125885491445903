import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {sortDates, toFixedFloat, detectImageProcessing} from '_utils';
import {AnalyticData, AnalyticPoint, IInitialMapState, TInfoExt, TrendsSeries} from '../../types';
import {TSensor} from '../../../../types';
import {GLOBAL_FORMAT_DATE, SERVER_FORMAT_DATE} from '_constants';
import {SeasonApi} from '_api';
import {showNote} from '_actions';
import moment from 'moment';
import {AverageNDVIPayload} from '../crop-performance/types';

const layers: TSensor[] = ['CCCI', 'MSAVI', 'NDVI', 'NDRE'];
export const SMOOTHED_LINE_COLOR = '#444';
export const WHOLE_FIELD_LINE_COLOR = '#43a047';

export const FieldsWithArableData: {[farmId: number]: {[fieldId: string]: true}} = {
  52: {1294: true},
  14097: {258145: true, 258146: true},
  14098: {258148: true, 258149: true, 259206: true},
};

export const getLayerImages = (
  remoteSensingFilters: string[],
  infoExt: TInfoExt[],
  md5: string,
  remoteSensingCloudCover = 0,
  toObject = false,
  withDates = false
) => {
  const result: {[key: string]: any[]} = {CCCI: [], MSAVI: [], NDVI: [], NDRE: []};

  if (infoExt && infoExt.length) {
    infoExt.forEach(date => {
      if (
        date.Cloud > remoteSensingCloudCover ||
        date.Hidden ||
        remoteSensingFilters.includes(date.Type)
      )
        return;

      layers.forEach(layer => {
        if (date[layer])
          result[layer].push(
            withDates
              ? [moment(date.Date).format('DD/MM/YYYY'), `${md5}/${date.Date}/${date[layer].name}`]
              : `${md5}/${date.Date}/${date[layer].name}`
          );
      });
    });
  }

  if (toObject) return result;

  return [result.CCCI, result.MSAVI, result.NDVI, result.NDRE];
};

export function getAllFarmImages(
  remoteSensingFilters: string[],
  fields: any,
  remoteSensingCloudCover: number
) {
  const result: any = [];
  const resultLayers: {[key: string]: any[]} = {CCCI: [], MSAVI: [], NDVI: [], NDRE: []};

  Object.keys(fields).forEach(md5 => {
    const infoExt = Object.keys(fields[md5]).map(k => fields[md5][k]);
    result.push(
      getLayerImages(remoteSensingFilters, infoExt, md5, remoteSensingCloudCover, true, true)
    );
  });

  result.forEach((layerDate: any) => {
    layers.forEach(layer => {
      if (layerDate[layer]) resultLayers[layer] = [...resultLayers[layer], ...layerDate[layer]];
    });
  });

  return [
    removeDuplicateDate(sortDates(resultLayers.CCCI)),
    removeDuplicateDate(sortDates(resultLayers.MSAVI)),
    removeDuplicateDate(sortDates(resultLayers.NDVI)),
    removeDuplicateDate(sortDates(resultLayers.NDRE)),
  ];
}

function removeDuplicateDate(layers: any[]) {
  const obj: any = {};

  layers.forEach(l => {
    obj[l[0]] = l[1];
  });

  return [...Object.values(obj)];
}

export const getFieldMeanIndexes = () => async (dispatch: any, getState: any) => {
  const {
    currentSeason,
    // remoteSensingCloudCover,
    // remoteSensingFilterProps,
    field,
  }: IInitialMapState = getState().map;

  const {isImagesProcessing} = detectImageProcessing(getState().map);

  const hackConvert = (val: number) => toFixedFloat((val + 1) / 2, 3); // from -1 - 1 to 0 - 1

  const avgNdviPayload: AverageNDVIPayload = {
    start_date: currentSeason.startDate,
    end_date: currentSeason.endDate,
    crop_type: currentSeason.cropType,
    key: field.ID,
  };

  if (currentSeason.geometry_id) {
    avgNdviPayload['geometry_id'] = currentSeason.geometry_id;
  } else {
    avgNdviPayload['md5'] = field.MD5;
  }

  const fieldNdvi = currentSeason.id
    ? await SeasonApi.getCropPerformanceNdviStatus([avgNdviPayload])
    : ({} as any);
  const fieldMeanRawData = fieldNdvi?.data?.[field.ID]?.data;
  const result = {
    // CCCI: {data: [], name: WHOLE_FIELD_LINE_COLOR} as TrendsSeries,
    // NDRE: {data: [], name: WHOLE_FIELD_LINE_COLOR} as TrendsSeries,
    // MSAVI: {data: [], name: WHOLE_FIELD_LINE_COLOR} as TrendsSeries,
    // NDVI: {data: [], name: WHOLE_FIELD_LINE_COLOR} as TrendsSeries,
    smoothedNDVI: {data: [], name: SMOOTHED_LINE_COLOR} as TrendsSeries,
    categories: [] as string[],
    // categories2: [] as string[],
  };

  if (fieldMeanRawData) {
    // a special endpoint to get smoothed NDVI values for a field, FSB-1425
    Object.keys(fieldMeanRawData).forEach(date => {
      const formattedDate = moment(date, SERVER_FORMAT_DATE).format(GLOBAL_FORMAT_DATE);
      result.categories.push(formattedDate);
      result.smoothedNDVI.data.push({y: hackConvert(fieldMeanRawData[date].ndvi_smooth)});
    });
  } else {
    if (currentSeason.id && !isImagesProcessing) {
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: currentSeason.id
            ? t({id: 'Field average not found.', defaultMessage: 'Field average not found.'})
            : t({
                id: 'No imagery available to load the field average chart.',
                defaultMessage: 'No imagery available to load the field average chart.',
              }),
          level: 'warning',
        })
      );
    }
  }
  // [...(currentSeason.infoExt || [])].reverse().forEach(date => {
  //   if (
  //     date.Cloud <= remoteSensingCloudCover &&
  //     !date.Hidden &&
  //     !remoteSensingFilterProps.includes(date.Type)
  //   ) {
  //     if (!result.smoothedNDVI.data.length) {
  //       // in case we don't load smoothed NDVI
  //       result.categories.push(moment(date.Date, 'YYYYMMDD').format(GLOBAL_FORMAT_DATE));
  //     }
  //     result.categories2.push(moment(date.Date, 'YYYYMMDD').format(GLOBAL_FORMAT_DATE));
  //
  //     result.CCCI.data.push({y: hackConvert(date.avgCcci)});
  //     result.NDRE.data.push({y: hackConvert(date.avgNdre)});
  //     result.MSAVI.data.push({y: hackConvert(date.avgMsavi)});
  //     result.NDVI.data.push({y: hackConvert(date.avgNdvi)});
  //   }
  // });

  return result;
};

export const classifyTrendsData = (
  fieldMeanValues: {categories: string[]; smoothedNDVI: {data: {y: number | null}[]}},
  trendsValues: AnalyticData[],
  geometryLabels: {label: string; color: string}[]
) => {
  return trendsValues.map((indexData, i) => {
    if (fieldMeanValues.categories.length) {
      // the only reason for this is having a special endpoint for field avg NDVI, that has values for each date, FSB-1425
      const restructuredData = {categories: fieldMeanValues.categories, series: [] as any};
      restructuredData.series = indexData.series.map(serie => {
        if (!serie.data?.length) return serie;

        const label = geometryLabels.find(gObj => gObj.color === serie.name)?.label || '';

        // fill gaps with empty data, because there is no data for each season date
        const name = serie.name;
        let data = [] as {y: number | undefined}[];

        fieldMeanValues.categories.forEach((date: string) => {
          const searchIndex = indexData.categories.findIndex(
            shortListDate => shortListDate === date
          );
          data.push(indexData.series.find(s => s.name === name).data[searchIndex] || {y: null});
        });
        return {name, data, label};
      });
      return restructuredData;
    }
    return indexData;
  });
};

export const classifyMeanValues = (
  fieldMeanValues: {categories: string[]; smoothedNDVI: {data: {y: number | null}[]}},
  trendsNDVIValues: AnalyticData
) => {
  // the field mean feature doesn't use the clouded images, in the other hand a user can request analytics data with cloud filter > 5%
  // in this case we can have dates that are not in the fieldMeanValues.categories list. it affects the result NDVI data, because in
  // the classifyTrendsData() we are using fieldMeanValues.categories as source of dates. so here we enrich the list with missing dates (FSB-3969)
  const ndviDates = trendsNDVIValues.categories;
  const fieldMeanDates = fieldMeanValues.categories;
  if (!ndviDates.length) {
    return fieldMeanValues;
  }

  ndviDates.forEach(date => {
    if (!fieldMeanDates.includes(date)) {
      const missedDateMoment = moment(date, GLOBAL_FORMAT_DATE);
      const findNewDatePosition = fieldMeanDates.findIndex(
        // find a position of a missing date
        (date, index) =>
          moment(date, GLOBAL_FORMAT_DATE).isAfter(missedDateMoment) ||
          index === fieldMeanDates.length - 1
      );
      if (findNewDatePosition === fieldMeanDates.length - 1) {
        // use push, because splice can't add to the end of array using the last arrays index
        fieldMeanValues.categories.push(date);
        fieldMeanValues.smoothedNDVI.data.push({y: null}); // y: null is a way to skip the point on a chart
      } else {
        fieldMeanValues.categories.splice(findNewDatePosition, 0, date);
        fieldMeanValues.smoothedNDVI.data.splice(findNewDatePosition, 0, {y: null});
      }
    }
  });

  return fieldMeanValues;
};

export const classifyArableTrendsData = (
  arablePoints: AnalyticPoint[],
  fieldMeanCategories: string[]
) => {
  const arableNdviTrendData: TrendsSeries[] = [];
  arablePoints.forEach(point => {
    const serie: TrendsSeries = {
      label: point.label,
      name: point.color,
      type: 'arable',
      data: [],
    };
    fieldMeanCategories.forEach(date => {
      serie.data.push({
        y: point.arableData.find(dateObjs => dateObjs.time === date)?.ndvi || null,
      });
    });
    arableNdviTrendData.push(serie);
  });

  return arableNdviTrendData;
};

export function analyticsToCSV(data: any): string {
  if (!Array.isArray(data)) return '';
  let pointsCounter = 0;

  let result = '';
  data[0].forEach((label: string, i: number) => {
    let points = '';
    data[1].forEach((point: any) => {
      points += `${point.values[i]},`;
    });

    result += (`${label},` + points + `${data[2]},`).replace(/,$/, '\n');
  });

  let header = `${t({id: '[analyticsToCSV] dates'})}, ${data[1]
    .map((p: any) => {
      pointsCounter++;
      return p.name || `${t({id: '[analyticsToCSV] point'})} ${pointsCounter}`;
    })
    .join(',')} ,${t({id: '[analyticsToCSV] source'})} \n`;

  header += result;

  return header;
}
