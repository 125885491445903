import {t, FormattedMessage} from 'i18n-utils';
import React, {CSSProperties, ReactElement} from 'react';
import './index.scss';
import {FontIcon} from 'react-md';
import cn from 'classnames';
import {WarningSvg} from '../icons';
import {AppearanceValues} from './types';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: React.ReactNode;
  hideIcon?: boolean;
  icon?: React.ReactNode;
  visible?: boolean;
  appearance?: AppearanceValues;
  iconColor?: string;
  onIconClick?: () => void;
  containerStyle?: CSSProperties;
};

type Config = {
  [key in AppearanceValues]: {
    key: string;
    icon: string | ((color: string) => ReactElement);
  };
};

const styleConfig: Config = {
  info: {
    icon: 'info',
    key: 'info',
  },

  warning: {
    icon: color => <WarningSvg style={{width: '20px', color}} />,
    key: 'warning',
  },

  error: {
    icon: 'error_outline',
    key: 'error',
  },

  confirmation: {
    icon: 'check_circle_outline',
    key: 'confirm',
  },

  change: {
    icon: 'help_outline',
    key: 'change',
  },
  processing: {
    icon: 'sync',
    key: 'processing',
  },
  add: {
    icon: 'add_circle_outline',
    key: 'add',
  },
};

/*
 *
 * Info block message
 *
 * */
const InfoBlock = ({
  appearance = 'change',
  icon,
  children,
  className = '',
  hideIcon,
  visible = true,
  iconColor,
  onIconClick,
  containerStyle = {},
  ...rest
}: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <div
      className={cn(
        'info-block',
        `info-block__appearance-${styleConfig[appearance].key}`,
        className
      )}
      style={containerStyle}
      {...rest}
    >
      <IconComponent
        appearance={appearance}
        icon={icon}
        hideIcon={hideIcon}
        iconColor={iconColor}
        onIconClick={onIconClick}
      />
      <div className={'info-block__content'}>{children}</div>
    </div>
  );
};

const IconComponent = ({
  appearance,
  icon,
  hideIcon,
  iconColor,
  onIconClick,
}: Pick<Props, 'appearance' | 'icon' | 'hideIcon' | 'iconColor' | 'onIconClick'>) => {
  if (hideIcon) return null;
  const isFontIcon = typeof styleConfig[appearance].icon === 'string';
  return (
    <span
      className={cn('info-block__info-icon', `info-block__icon-${styleConfig[appearance].key}`, {
        _clickable: !!onIconClick,
      })}
      onClick={onIconClick}
    >
      {icon ? (
        icon
      ) : isFontIcon ? (
        <FontIcon style={{color: iconColor || '#546E7A'}}>{styleConfig[appearance].icon}</FontIcon>
      ) : (
        //@ts-ignore
        styleConfig[appearance].icon(iconColor || '#546E7A')
      )}
    </span>
  );
};

export default InfoBlock;
