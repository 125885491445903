import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import FluroDialog from 'components/fluro-dialog';
import {sensorView} from '_utils';
import {Button, SelectionControl} from 'react-md';
import Img from 'components/img/index';
import {convertTileDateToNormal} from '_utils';
import {DownloadIcon} from '../../icons';
import {TInfoExt} from '../../types';
import {TSensor} from '../../../../types';

type Props = {
  tile: TInfoExt;
  isRemoteSensingDialog: boolean;
  isSetSpectra: Array<TSensor>; // TODO: rename
  remoteSensingLayersSelected: {[key: string]: {checked: boolean}};
  remoteSensingLayersOptions: {
    rangeFloat: boolean;
    rangeGrey: boolean;
    formatPng: boolean;
    all: boolean;
  };
  onHide: () => void;
  onChangeLayer: (key: string, value: boolean) => void;
  onChangeFileFormat: (key: string, value: boolean) => void;
  numberSelectedLayers: number;
  downloadGeoTiff: () => void;
};

const RemoteSensingImageDialog = (props: Props) => (
  <FluroDialog
    focusOnMount={false}
    id="remote-sensing-dialog-container"
    visible={!!props.isRemoteSensingDialog}
    style={{zIndex: 999}}
    title={t({id: 'Download layers'})}
    isDraggable
    onHide={props.onHide}
    width={450}
    height={500}
  >
    <div className="remote-sensing__popup">
      <table>
        <thead>
          <tr>
            <th className="remote-sensing__checkbox">
              <SelectionControl
                id={`remote-sensor-select-all`}
                name={`remote-sensor-select-all`}
                label=""
                type="checkbox"
                checked={props.remoteSensingLayersOptions.all}
                onChange={props.onChangeLayer.bind(this, 'all')}
              />
            </th>
            <th colSpan={2} style={{textAlign: 'left'}}>
              {t({id: 'All layers'})}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.tile ? (
            props.isSetSpectra.map((spectraKey, i: number) => (
              <tr key={`remote-sensing-tile-${spectraKey}-${i}`}>
                <td className="remote-sensing__checkbox">
                  <div>
                    <SelectionControl
                      id={`remote-sensor-one-checkbox-${i}-${spectraKey}`}
                      name={`remote-sensor-one-checkbox-${i}-${spectraKey}`}
                      label=""
                      type="checkbox"
                      checked={props.remoteSensingLayersSelected[spectraKey].checked}
                      onChange={props.onChangeLayer.bind(this, spectraKey)}
                    />
                  </div>
                </td>
                <td className="remote-sensing__link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{display: 'inline-block'}}
                    href={
                      spectraKey === 'TCI' || spectraKey === 'NC'
                        ? props.tile[spectraKey].url
                        : props.tile[spectraKey].url.replace('.png', `_singleDate.png`)
                    }
                    title={spectraKey}
                  >
                    <Img
                      alt={'remote-sensing'}
                      wrapperstyle={{
                        border: '1px solid #333',
                        background: '#f1f1f1',
                      }}
                      style={{height: '65px', maxWidth: '90px'}}
                      src={
                        spectraKey === 'TCI' || spectraKey === 'NC'
                          ? props.tile[spectraKey].url
                          : props.tile[spectraKey].url.replace('.png', `_singleDate.png`)
                      }
                    />
                  </a>

                  {/*<div style={{width: '100%', textAlign: 'center'}}>*/}
                  {/*{props.tile[`Tif${spectraKey}`] ? <div>Download</div> : null}*/}
                </td>
                <td>
                  <b style={{marginLeft: '10px'}}>{sensorView(spectraKey)}</b>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{t({id: 'Spectra not found'})}</td>
            </tr>
          )}
        </tbody>
      </table>

      <div style={{marginTop: '15px', marginLeft: '20px'}}>
        <div>
          <b>
            {props.tile
              ? `${t({id: 'Date'})}: ` + convertTileDateToNormal(props.tile.Date)
              : t({id: 'Not Found Date'})}
          </b>
        </div>

        <table style={{marginTop: '30px'}}>
          <thead>
            <tr>
              <th>
                <b>{t({id: 'File Format'})}:</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="remote-sensing__checkbox">
                <SelectionControl
                  id={`remote-sensor-one-checkbox2`}
                  name={`remote-sensor-one-checkbox2`}
                  label={t({id: 'Geotiff [0,255]'})}
                  type="checkbox"
                  checked={props.remoteSensingLayersOptions.rangeGrey}
                  onChange={props.onChangeFileFormat.bind(this, 'rangeGrey')}
                />
              </td>
            </tr>
            <tr>
              <td className="remote-sensing__checkbox">
                <SelectionControl
                  id={`remote-sensor-one-checkbox`}
                  name={`remote-sensor-one-checkbox`}
                  label={t({id: 'Geotiff [-1,1]'})}
                  type="checkbox"
                  checked={props.remoteSensingLayersOptions.rangeFloat}
                  onChange={props.onChangeFileFormat.bind(this, 'rangeFloat')}
                />
              </td>
            </tr>
            <tr>
              <td className="remote-sensing__checkbox">
                <SelectionControl
                  id={`remote-sensor-one-checkbox4`}
                  name={`remote-sensor-one-checkbox4`}
                  label="Png"
                  type="checkbox"
                  checked={props.remoteSensingLayersOptions.formatPng}
                  onChange={props.onChangeFileFormat.bind(this, 'formatPng')}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div style={{textAlign: 'center', marginTop: '20px'}}>
      <Button
        id={'download-layers'}
        onClick={props.downloadGeoTiff}
        className="btn-with-icon"
        iconEl={<DownloadIcon />}
        disabled={
          !props.numberSelectedLayers ||
          (!props.remoteSensingLayersOptions.rangeFloat &&
            !props.remoteSensingLayersOptions.rangeGrey &&
            !props.remoteSensingLayersOptions.formatPng)
        }
        raised
        primary
      >
        {t({id: 'Download'})}
      </Button>
    </div>
  </FluroDialog>
);

export default RemoteSensingImageDialog;
