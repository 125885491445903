import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FeatureGroup, GeoJSON as ReactLeafletGeoJSON, ImageOverlay, Marker} from 'react-leaflet';
import {renderToString} from 'react-dom/server';
import L from 'leaflet';
import 'containers/map/leaflet-side-by-side';
import {centroid, featureCollection} from '@turf/turf';
import {AppStore} from 'reducers';
import {drawCompareTooltips} from '../utils';
import {useDispatch, useSelector} from 'react-redux';
import {Field, FieldTag, Season} from '../types';
import {highlightField, toggleFieldCheckbox, togglePopup} from '../actions';
import Supercluster from 'supercluster';
import {Feature, Point} from 'geojson';
import {isSameBounds} from '_utils';
import {useFieldCurrentSeason} from '_hooks/use-current-season';
import {CPFilterType, stage1Filters} from '_reducers/crop-performance-filter/field-filter-types';
import {getTreeLayerImageUrl} from '../utils/utils';
import {AsyncStatusType, Status} from 'modules/ui-helpers';
import {ClusterMarker} from '../features/crop-performance/map/cluster-marker';
import {getClusterMarkerDataAndColors} from '../features/crop-performance/map/cluster-marker-data';
import {updateBenchmarkRecord} from '../features/crop-performance/reducer';
import {getGeometryColors} from '../features/crop-performance/map/geometry-color';
import FieldSoilMapOverlay from './field-soil-map-overlay';
import {MAIN_SHAPE_COLOR} from "../../../_constants";

type Props = {
  leafletElement: L.Map;
  fitBounds: (bounds?: L.LatLngBounds) => void;
  wholeFarmBoundsCallback: (bounds?: L.LatLngBounds) => void;
};

const superclusterOptions = {
  radius: 60,
  extent: 256,
  maxZoom: 11,
};

const WholeFarmOverlay = ({leafletElement, fitBounds, wholeFarmBoundsCallback}: Props) => {
  const dispatch = useDispatch();
  const cropPerformance = useSelector((store: AppStore) => store.cropPerformance);
  const cropVarietyColors = useSelector((store: AppStore) => store.map.cropVarietyColors);
  const cropTypes = useSelector((store: AppStore) => store.global.cropTypes);
  const cropVarietyMode = useSelector((store: AppStore) => store.map.cropVarietyMode);
  const cropPerformanceFilter = useSelector((store: AppStore) => store.cropPerformanceFilter);
  const cropPerformanceBenchmarkRecords = useSelector(
    (store: AppStore) => store.cropPerformance.benchmark.recordsToProcess
  );
  const mapFields = useSelector((store: AppStore) => store.map.fields);
  const fieldsByFarmId = useSelector((store: AppStore) => store.map.fieldsByFarmId);
  const fieldGeometries = useSelector((store: AppStore) => store.map.fieldGeometries);
  const highlightedFieldId = useSelector((store: AppStore) => store.map.highlightedFieldId);
  const selectedFieldId = useSelector((store: AppStore) => store.map.selectedFieldId);
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const currentSensor = useSelector((store: AppStore) => store.map.currentSensor);
  const tab = useSelector((store: AppStore) => store.map.feature);
  const wholeFarm = useSelector((store: AppStore) => store.map.wholeFarm);
  const isCompareOn = useSelector((store: AppStore) => store.map.isCompareOn);
  const currentCompareDate = useSelector((store: AppStore) => store.map.currentCompareDate);
  const currentSensorCompare = useSelector((store: AppStore) => store.map.currentSensorCompare);
  const wholeFarmLoadingStatus = useSelector(
    (store: AppStore) => store.uiHelpers.asyncStatuses[AsyncStatusType.wholeFarmData].status
  );
  const treeDetection = useSelector((store: AppStore) => store.map.treeDetection);
  const isReportView = useSelector((store: AppStore) => store.global.isReportView);

  const isWholeFarmTreeDetectionCase =
    tab === 'zoning' && wholeFarm.isWholeFarmView && treeDetection.layerType !== 'default';

  const commonLayerRef = useRef<any>();
  const [geometriesReady, setGeometriesReady] = useState<boolean>(false);
  const [rerenderKey, setRerenderKey] = useState(0); // update this key whenever the rerender is needed.
  const [clusterMarkers, setClusterMarkers] = useState<{
    clusters: any[];
    clusteredGeometries: {[clusterId: number]: number[]};
    clusteredGeometryIsSeason: {[clusterId: number]: boolean};
  }>({clusters: [], clusteredGeometries: {}, clusteredGeometryIsSeason: {}});
  const [unclusteredIds, setUnclusteredIds] = useState<{[seasonId: string]: true}>({});
  const [unclusteredGeometries, setUnclusteredGeometries] = useState<GeoJSON.FeatureCollection[]>(
    []
  );
  const [allFieldsBounds, setAllFieldsBounds] = useState<L.LatLngBounds>();
  const [tabIsHidden, toggleTabIsHidden] = useState(document.hidden);
  const {getFieldCurrentSeasons} = useFieldCurrentSeason();

  const fields = useMemo(
    () =>
      tab === 'crop-performance'
        ? Object.keys(fieldsByFarmId)
            .filter(farmId => cropPerformance.farms[Number(farmId)])
            .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)]))
        : mapFields, // use map fields because it contains files object that is required for displaying whole farm layers
    [fieldsByFarmId, cropPerformance.farms, tab, mapFields, isReportView]
  );

  // 1. Filter out the fields without geometries.
  // 2. Replace field geometries with planting area geometries.
  // 3. Patch geometries with fluro_id and seasonId properties.
  const {preparedFields, preparedSeasonGeometries} = useMemo(() => {
    const preparedFields: Field[] = [];
    const preparedSeasonGeometries: {[seasonId: number]: GeoJSON.FeatureCollection} = {};
    fields.forEach(field => {
      if (!fieldGeometries[field.MD5]) {
        return;
      }
      preparedFields.push(field);

      const hasTreeDetectionImage = getTreeLayerImageUrl(false, field?.MD5);
      const selected = isWholeFarmTreeDetectionCase
        ? !!wholeFarm.treeZoning.fields[field?.MD5]?.selected // for the whole farm tree detection it is another selection flow
        : field?._selected;

      const seasons = getFieldCurrentSeasons(field);
      if (!seasons.length) {
        const geometry: GeoJSON.FeatureCollection = {...fieldGeometries[field?.MD5]};
        geometry.features?.forEach(feature => {
          feature.properties.fluro_id = field?.ID;
          feature.properties.seasonId = field?.ID;
          feature.properties.hasTreeDetectionImage = hasTreeDetectionImage;
          feature.properties.selected = selected;
        });
        // With no seasons fields use fieldId instead of seasonId.
        preparedSeasonGeometries[field?.ID] = geometry;
        return;
      }

      seasons.forEach(season => {
        const geometry: GeoJSON.FeatureCollection = {...fieldGeometries[field?.MD5]};
        if (season.geometry) {
          geometry.features = [
            {
              // replace the field geometry with planting areas
              type: 'Feature',
              properties: {},
              geometry: season.geometry,
            },
          ];
        }
        geometry.features?.forEach(feature => {
          feature.properties.fluro_id = field?.ID;
          feature.properties.seasonId = season.id;
          feature.properties.hasTreeDetectionImage = hasTreeDetectionImage;
          feature.properties.selected = selected;
        });
        preparedSeasonGeometries[season.id] = geometry;
      });
    });
    return {preparedFields, preparedSeasonGeometries};
  }, [
    fields,
    fieldGeometries,
    isWholeFarmTreeDetectionCase,
    wholeFarm.treeZoning.fields,
    currentDate, // current date is used inside the getFieldCurrentSeasons()
  ]);

  // It needs to be in ref so map.on('moveend') can always access the actual version of
  // the index instead of closured one if we'd use useState instead of useRef.
  const geospatialIndexRef = useRef<Supercluster>();

  const updateClusterMarkers = useCallback(() => {
    if (!geospatialIndexRef.current) {
      return;
    }
    const bounds = leafletElement.getBounds();

    const clusters = geospatialIndexRef.current.getClusters(
      [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
      Math.round(leafletElement.getZoom())
    );

    // Remember the unclustered crop ids, so we can show their geometries.
    const newUnclusteredIds: {[seasonId: number]: true} = {};
    const clusteredGeometries: {[clusterId: number]: number[]} = {};
    const clusteredGeometryIsSeason: {[clusterId: number]: true} = {};
    clusters.forEach(c => {
      if (!c.properties.cluster) {
        newUnclusteredIds[c.properties.seasonId] = true;
        return;
      }

      // All fields participate in clusterisation – including filtered out during crop performance filters.
      // Subtract the number of filtered out fields from the clusters.
      const leaves = geospatialIndexRef.current.getLeaves(c.properties.cluster_id, Infinity);
      const seasonIds = leaves
        .map(l => l.properties.seasonId)
        .filter(seasonId => !cropPerformanceFilter.filterStatus[seasonId]);

      leaves.forEach(
        leave => (clusteredGeometryIsSeason[leave.properties.seasonId] = leave.properties.isSeason)
      );
      clusteredGeometries[c.properties.cluster_id] = seasonIds;
      clusteredGeometryIsSeason[c.properties.cluster_id] = c.properties.isSeason;
      c.properties.point_count = seasonIds.length;
      c.properties.point_count_abbreviated = seasonIds.length;
    });

    setUnclusteredIds(newUnclusteredIds);

    // TODO: we don't have unclustered here, but
    // we want to have markers for them since they are tiny.
    setClusterMarkers({
      clusters,
      clusteredGeometries,
      clusteredGeometryIsSeason,
    });

    setRerenderKey(key => ++key);
  }, [cropPerformanceFilter.filterStatus]);

  useEffect(() => {
    leafletElement.on('moveend', updateClusterMarkers);
    return () => {
      leafletElement.off('moveend', updateClusterMarkers);
    };
  }, [updateClusterMarkers]);

  useEffect(
    function setSupercluster() {
      const fieldCentroids = preparedFields
        // .filter(f => !cropPerformanceFilter.filterStatus[f.ID])
        .flatMap(f => {
          const points: Feature<Point>[] = [];
          const fieldSeasons = getFieldCurrentSeasons(f);
          if (fieldSeasons.length) {
            fieldSeasons.forEach(s => {
              const geometry = preparedSeasonGeometries[s.id];
              const point = centroid(featureCollection(geometry.features));
              point.properties.seasonId = s.id;
              point.properties.isSeason = true; // set is season as a leave property, to get it later
              points.push(point);
            });
          } else {
            const geometry = preparedSeasonGeometries[f.ID];
            const point = centroid(featureCollection(geometry.features));
            point.properties.seasonId = f.ID; // use field id for the fields without seasons
            point.properties.isSeason = false; // set is season as a leave property, to get it later
            points.push(point);
          }

          // Convert GeoJSON.FeatureCollection to Turf.GeoJSON.FeatureCollection :|.
          return points;
        });
      geospatialIndexRef.current = new Supercluster(superclusterOptions).load(fieldCentroids);
      updateClusterMarkers();
    },
    [preparedFields, preparedSeasonGeometries, cropPerformanceFilter.filterStatus]
  );

  const renderClusterMarker = useCallback(
    (seasonIds: number[], isSeason: {[seasonId: number]: boolean}) => {
      const dataAndColors = getClusterMarkerDataAndColors(
        seasonIds,
        isSeason,
        fields,
        cropPerformance,
        cropPerformanceFilter,
        currentDate,
        cropTypes,
        cropVarietyMode,
        cropVarietyColors
      );
      return renderToString(<ClusterMarker dataAndColors={dataAndColors} />);
    },
    [
      fields,
      cropPerformance,
      cropPerformanceFilter,
      currentDate,
      cropTypes,
      cropVarietyMode,
      cropVarietyColors,
    ]
  );

  const geometryColors = useMemo(() => {
    return getGeometryColors(
      fields,
      cropPerformance,
      cropPerformanceFilter,
      currentDate,
      cropTypes,
      cropVarietyMode,
      cropVarietyColors
    );
  }, [
    fields,
    cropPerformance,
    cropPerformanceFilter,
    currentDate,
    cropTypes,
    cropVarietyMode,
    cropVarietyColors,
  ]);

  // Update geometry colors.
  useEffect(() => {
    const g = preparedFields
      .flatMap(f => {
        const seasons = getFieldCurrentSeasons(f);
        // Pass a dummy season with field id in case of no seasons.
        // We expect preparedSeasonGeometries to have geometries indexed by field id instead of season id
        // for this case.
        return seasons.length ? seasons : [{id: f.ID, startDate: '', endDate: '', cropType: ''}];
      })
      .filter(s => unclusteredIds[s.id])
      .map(season => {
        const geometry: GeoJSON.FeatureCollection = {...preparedSeasonGeometries[season.id]};
        geometry.features?.forEach(feature => {
          feature.properties.color = geometryColors.background[season.id];
          feature.properties.flStroke = geometryColors.stroke[season.id];
        });
        return geometry;
      });

    setUnclusteredGeometries(g);
    setRerenderKey(key => ++key);
  }, [preparedFields, unclusteredIds, preparedSeasonGeometries, geometryColors]);

  // Apply geometry colors and click events.
  const onEachFeature = (feature: Feature, layer: L.Path) => {
    const benchmarkCardSelected = cropPerformance.representation === CPFilterType.BIOMASS_OVER_TIME;
    const cropHasData = cropPerformance.records.find(
      ({seasonId}) => seasonId === feature.properties.seasonId
    );
    if (tab === 'crop-performance') {
      const filteredByProp = cropPerformanceFilter.filterStatus[feature.properties.seasonId];
      const stage1 = !stage1Filters.includes(filteredByProp) || feature.properties.color;
      const fillColor = feature.properties.color || 'white';
      const solid =
        (cropHasData && benchmarkCardSelected && !filteredByProp) || // highlight the border of crops that are not filtered
        (stage1 && fillColor !== 'white'); // exclude no crop (white) fields
      const selected =
        !benchmarkCardSelected &&
        (highlightedFieldId || selectedFieldId) === feature.properties.fluro_id;
      layer.setStyle?.({
        fillColor,
        fillOpacity: fillColor === 'white' ? 0.5 : 1,
        weight: selected ? 4 : solid ? 2 : 1,
        color: selected ? MAIN_SHAPE_COLOR : solid ? 'white' : '#999',
        opacity: solid ? 1 : 0.5,
      });
    } else if (isWholeFarmTreeDetectionCase) {
      layer.setStyle({
        color: feature.properties.hasTreeDetectionImage
          ? feature.properties.selected
            ? MAIN_SHAPE_COLOR
            : '#3388FF'
          : 'transparent',
        fillColor: feature.properties.hasTreeDetectionImage ? 'transparent' : 'rgb(255, 255, 255)',
        fillOpacity: feature.properties.hasTreeDetectionImage ? 1 : 0.5,
      });
    } else {
      layer.setStyle({
        color: feature.properties.selected ? MAIN_SHAPE_COLOR : '#3388FF',
      });
    }

    layer.on({
      click: e => {
        const {fluro_id, selected, seasonId} = e?.target?.feature?.properties;
        const id = seasonId || fluro_id; // seasonId could be empty if there are no seasons for the field
        if (fluro_id && !benchmarkCardSelected) {
          dispatch(highlightField(fluro_id));
          dispatch(toggleFieldCheckbox(fluro_id, !selected));
        }
        if (tab === 'crop-performance') {
          if (
            cropHasData &&
            !cropPerformanceFilter.filterStatus[id] && // not filtered by other filters
            benchmarkCardSelected &&
            !cropPerformanceFilter.filters[CPFilterType.BIOMASS_OVER_TIME].find(
              // and not already added
              filter => filter.value === id
            )
          ) {
            // Add the field to the biomass over time card.
            dispatch(updateBenchmarkRecord(id, !cropPerformanceBenchmarkRecords[id]));
          } else {
            dispatch(togglePopup(id, 'crop-performance'));
          }
        }
      },
    });
  };

  const unclusteredFieldsWithImagery = useMemo(() => {
    return preparedFields.filter(f => {
      const id = f.SeasonID || f.ID;
      return unclusteredIds[id] && f.files?.[currentDate]?.[currentSensor];
    });
  }, [preparedFields, unclusteredIds, currentDate, currentSensor, wholeFarm.treeZoning.fields]); // wholeFarm.treeZoning.fields contains whole farm tree detection layers

  const unclusteredCompareFieldsWithImagery = useMemo(() => {
    return preparedFields.filter(f => {
      const id = f.SeasonID || f.ID;
      return unclusteredIds[id] && f.files?.[currentCompareDate]?.[currentSensorCompare];
    });
  }, [preparedFields, unclusteredIds, currentCompareDate, currentSensorCompare]);

  useEffect(
    function onChangeFieldsBounds() {
      const features: GeoJSON.Feature[] = preparedFields
        .filter(
          f =>
            fieldGeometries[f.MD5] &&
            checkTreeDetection(isWholeFarmTreeDetectionCase, f, wholeFarm) &&
            checkCropPerformance(
              tab === 'crop-performance' &&
                cropPerformance.representation !== CPFilterType.BIOMASS_OVER_TIME, // bounds are not changing during benchmark chart manipulations
              f,
              geometryColors.background,
              getFieldCurrentSeasons
            )
        )
        .flatMap(f => fieldGeometries[f.MD5].features);
      if (!features.length) {
        return;
      }

      const preparedFieldsCollection = featureCollection(features);
      // @ts-ignore  featureCollection returns type that L.geoJSON doesn't expect, just misunderstanding between two libraries
      const preparedFieldsGeoJSON = L.geoJSON(preparedFieldsCollection);
      const preparedFieldsBounds = preparedFieldsGeoJSON.getBounds();
      if (!isSameBounds(preparedFieldsBounds, allFieldsBounds)) {
        setAllFieldsBounds(preparedFieldsBounds);
        wholeFarmBoundsCallback(preparedFieldsBounds);
      }
    },
    [preparedFields, fieldGeometries, wholeFarm.treeZoning.fields, tab, geometryColors]
  );

  useEffect(() => {
    if (!geometriesReady) {
      return;
    }
    // IMPORTANT: do not remove set maxZoom cuz without it leaflet can throw exception
    // for zero box coordinates. Also, make sure setMaxZoom is before fitBounds.
    leafletElement.setMaxZoom(20);

    fitBounds(allFieldsBounds);
  }, [geometriesReady, allFieldsBounds, tab]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => toggleTabIsHidden(document.hidden));
  }, []);

  useEffect(() => {
    // dirty hack to fix the issue when user comes back to a tab in map center is incorrect
    if (!tabIsHidden) {
      setTimeout(() => fitBounds(allFieldsBounds), 500);
    }
  }, [tabIsHidden]);

  if (!(preparedFields.length && (wholeFarm.isWholeFarmView || tab === 'crop-performance'))) {
    return null;
  }
  const showImages = tab !== 'crop-performance' && wholeFarmLoadingStatus !== Status.Pending;

  return (
    <>
      <FeatureGroup ref={commonLayerRef} key={rerenderKey}>
        {isCompareOn ? (
          <FieldGroup
            map={leafletElement}
            fields={unclusteredCompareFieldsWithImagery}
            rawFields={preparedFields}
            date={currentCompareDate}
            sensor={currentSensorCompare}
            geoJSON={unclusteredGeometries}
            isCompare={true}
            showImages={showImages}
            drawCompareTooltipsWithProps={() =>
              drawCompareTooltips(
                currentSensor,
                currentSensorCompare,
                currentDate,
                currentCompareDate
              )
            }
            onEachFeature={onEachFeature}
            commonLayerRef={commonLayerRef}
          />
        ) : null}

        <FieldGroup
          map={leafletElement}
          fields={unclusteredFieldsWithImagery}
          rawFields={preparedFields}
          date={currentDate}
          sensor={currentSensor}
          geoJSON={unclusteredGeometries}
          isCompare={false}
          // on crop perf tab we show just colored shapes instead of imagery
          showImages={showImages}
          onEachFeature={onEachFeature}
          onFieldGeometriesLayerAdd={() => setGeometriesReady(true)}
        />

        {clusterMarkers.clusters.map(m => {
          const clusterId = m.properties.cluster_id;
          const seasonIds = clusterMarkers.clusteredGeometries[clusterId];
          const [lng, lat] = m.geometry.coordinates;
          if (clusterId) {
            return (
              <Marker
                eventHandlers={{
                  click: (e: L.LeafletEvent) => {
                    // Expand clicked cluster – zoom in and change map center.
                    // @ts-ignore latlng is not exposed
                    const center = e.latlng;
                    const zoom = geospatialIndexRef.current.getClusterExpansionZoom(clusterId);
                    // TODO: flyTo should take into account the side panel.
                    leafletElement.flyTo(center, zoom);
                  },
                }}
                key={clusterId}
                position={{lat, lng}}
                icon={L.divIcon({
                  html: renderClusterMarker(seasonIds, clusterMarkers.clusteredGeometryIsSeason),
                  className: `marker-cluster marker-cluster-${'medium'}`,
                  iconSize: L.point(60, 60),
                })}
              />
            );
          }

          // Otherwise – if it's not a cluster and just a field – show a simple colored circle.
          // And show them only when the map is zoomed out enough. Otherwise we'd rather see the shapes.
          // Or if there are no clustered geometries
          if (
            leafletElement.getZoom() > 11.5 ||
            !Object.keys(clusterMarkers.clusteredGeometries).length
          ) {
            return null;
          }

          const seasonId = m.properties.seasonId;

          return (
            <Marker
              key={seasonId}
              position={{lat, lng}}
              icon={L.divIcon({
                html: renderClusterMarker([seasonId], {[seasonId]: m.properties.isSeason}),
                className: `marker-cluster marker-cluster-${'medium'}`,
                iconSize: L.point(60, 60),
              })}
              eventHandlers={{
                click: (e: L.LeafletEvent) => {
                  // Expand clicked cluster – zoom in and change map center.
                  // @ts-ignore latlng is not exposed
                  const center = e.latlng;
                  const zoom = 9;
                  leafletElement.flyTo(center, zoom);
                },
              }}
            />
          );
        })}
      </FeatureGroup>
    </>
  );
};

type FieldGroupProps = {
  map: L.Map;
  fields: Field[];
  rawFields: Field[];
  date: string;
  sensor: string;
  geoJSON: GeoJSON.FeatureCollection[];
  isCompare: boolean;
  showImages: boolean;
  commonLayerRef?: any;
  onFieldGeometriesLayerAdd?: () => void;
  onEachFeature: (feature: Feature, layer: L.Path) => void;
  drawCompareTooltipsWithProps?: () => void;
};

const FieldGroup = memo(
  ({
    map,
    fields,
    rawFields,
    date,
    sensor,
    geoJSON,
    isCompare,
    showImages,
    commonLayerRef,
    onFieldGeometriesLayerAdd,
    onEachFeature,
    drawCompareTooltipsWithProps,
  }: FieldGroupProps) => {
    const featureGroupRef = useRef() as any;
    const treesLayerType = useSelector((store: AppStore) => store.map.treeDetection.layerType);
    const imageLayerOpacity = useSelector((store: AppStore) => store.map.imageLayerOpacity);
    const currentSensor = useSelector((store: AppStore) => store.map.currentSensor);
    const colorSchema = useSelector((store: AppStore) => store.map.colorSchema);
    const currentSensorCompare = useSelector((store: AppStore) => store.map.currentSensorCompare);
    const soilMapLayerEnabled = useSelector((store: AppStore) => store.map.soilMapLayer);

    const getImageUrl = useCallback(
      (url: string, isCompareSensor = false) => {
        const sensor = isCompareSensor ? currentSensorCompare : currentSensor;
        return sensor === 'TCI' || sensor === 'NC'
          ? url
          : url.replace('.png', `${colorSchema === 'default' ? '' : '_' + colorSchema}.png`);
      },
      [currentSensorCompare, currentSensor, colorSchema]
    );

    let sideBySide: any = null;

    if (onFieldGeometriesLayerAdd) {
      useEffect(onFieldGeometriesLayerAdd, [featureGroupRef]);
    }

    useEffect(() => {
      return () => {
        sideBySide && sideBySide.remove();
      };
    }, []);

    useEffect(() => {
      const commonLayer = commonLayerRef?.current;
      if (!commonLayer || !featureGroupRef?.current) {
        return;
      }
      const currentLayerLeafletId = featureGroupRef.current._leaflet_id;
      const currentLayers: L.LayerGroup[] = commonLayer.getLayers();
      const leftSide = currentLayers.find(l => l.getLayerId(l) !== currentLayerLeafletId);
      const rightSide = currentLayers.find(l => l.getLayerId(l) === currentLayerLeafletId);

      //@ts-ignore
      sideBySide = L.control.sideBySide(
        (leftSide as L.FeatureGroup).getLayers().filter((l: any) => l.getElement),
        (rightSide as L.FeatureGroup).getLayers().filter((l: any) => l.getElement)
      );
      sideBySide.addTo(map);
      drawCompareTooltipsWithProps();
    }, [featureGroupRef, commonLayerRef]);

    return (
      <FeatureGroup ref={featureGroupRef}>
        {showImages &&
          fields.map(field => {
            const imageRawUrl = field?.files?.[date]?.[sensor]?.url;
            const imageUrl =
              treesLayerType !== 'default'
                ? getTreeLayerImageUrl(isCompare, field.MD5)
                : imageRawUrl && getImageUrl(imageRawUrl, isCompare);

            return imageUrl ? (
              <ImageOverlay
                // id={`selected-field-image-${field.ID}`}
                opacity={imageLayerOpacity * 0.01}
                bounds={[
                  [field.SouthLat, field.WestLon],
                  [field.NorthLat, field.EastLon],
                ]}
                key={field.ID}
                url={imageUrl}
              />
            ) : null;
          })}

        <ReactLeafletGeoJSON data={geoJSON as any} onEachFeature={onEachFeature} />

        {showImages &&
          soilMapLayerEnabled && // should be placed after fields boundaries to appear above them
          rawFields.map(field => {
            return field.soilLayer ? (
              <FieldSoilMapOverlay key={field.ID} id={field.ID} data={field.soilLayer} />
            ) : null;
          })}
      </FeatureGroup>
    );
  }
);

// Whether the field should be displayed based on it's tree detection flag.
const checkTreeDetection = (
  treeDetection: boolean,
  field: Field,
  wholeFarm: AppStore['map']['wholeFarm']
) => {
  return (
    !treeDetection || // tree detection mode is disabled
    (field.tags.includes(FieldTag.TreeDetection) && // or field has tree detection tag
      wholeFarm.treeZoning.fields[field.MD5]?.selected) // and is selected
  );
};

// Whether the field should be displayed based CP filter state.
const checkCropPerformance = (
  isCropPerformance: boolean,
  field: Field,
  seasonsColors: {[seasonId: string]: string},
  getFieldCurrentSeasons: (field: Field) => Season[]
) => {
  if (!isCropPerformance) return true;
  const fieldSeasons = getFieldCurrentSeasons(field);

  return fieldSeasons.length && fieldSeasons.find(s => seasonsColors[s.id]);
};

export default WholeFarmOverlay;
