import {useEffect} from 'react';
import {AppStore} from 'reducers';
import {useDispatch, useSelector} from 'react-redux';
import {RequestStatus} from '../../../types';
import {setCPLoadStatus} from '../features/crop-performance/reducer';

const CropPerformanceEffects = (): null => {
  const dispatch = useDispatch();
  const cropPerformanceFarms = useSelector((state: AppStore) => state.cropPerformance.farms);
  const cropPerformanceLoaded = useSelector((state: AppStore) => state.cropPerformance.loaded);

  useEffect(() => {
    const farmIds = Object.keys(cropPerformanceFarms).map(Number);
    const loadedStatus = farmIds.every(
      farmId =>
        ![RequestStatus.Idle, RequestStatus.Loading].includes(cropPerformanceFarms[farmId].status)
    );
    if (cropPerformanceLoaded !== loadedStatus) {
      dispatch(setCPLoadStatus(loadedStatus));
    }
  }, [cropPerformanceFarms, cropPerformanceLoaded]);

  return null;
};

export default CropPerformanceEffects;
