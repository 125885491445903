import {ColoredCircle} from 'components/fluro-select-lite/fluro-select-lite';
import {Dropdown} from 'components/dropdown/dropdown';
import {saveBulkCrop} from 'containers/map/actions';
import {tillageColor, tillageLabels} from 'containers/map/features/optis/optis-types';
import {t} from 'i18n-utils';
import * as React from 'react';
import {useMemo, useEffect, useCallback, useState, useRef} from 'react';
import {Button, Checkbox} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import {
  calcPlural,
  getSeasonByYear,
  getYearRange,
  selectMeasurement,
  sortFieldsByProp,
} from '_utils';
import {
  carbonYearsZA,
  SeasonPayload,
  validatePractices,
  validateFarm,
  CarbonPracticeChanges,
  carbonYears,
  carbonTillageOrder,
} from '../base/base';
import {CarbonSelect} from '../base/carbon-select';
import {CarbonStep, generateCarbonSequestration, saveCarbonPlan, setStep} from '../carbon-reducer';
import {getFarmIdsThatHasFields} from '../utils';
import './carbon-panel-crop-practices-table.scss';
import {FluroChip} from 'components/fluro-chip/fluro-chip';
import {
  YearQuarter,
  yearQuarterOrder,
  getCropPractices,
  getSeason,
  getCarbonCrops,
  getSeasonPayload,
  guessedToPayload,
} from '../base/crop-practice';

import {
  FluroDataTable,
  FluroTableBody,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroTableColumnIconButton,
  FluroTableFlexWrapper,
  FluroButton,
} from 'components';
import {dialogToggle, DialogType} from '../../../modules/ui-helpers';
import {ConfirmPracticesMessage} from '../base/messages';
import {CheckIcon} from '../../../components/icons';
import {ActionTypes} from 'containers/map/reducer/types';
import {Season} from 'containers/map/types';

export const CarbonPanelCropPracticesTable = () => {
  const dispatch = useDispatch();
  const carbon = useAppSelector(s => s.carbon);
  const fieldsByFarmId = useAppSelector(s => s.map.fieldsByFarmId);
  const allFarms = useAppSelector(s => s.farms.list);

  const [currentFarmId, setCurrentFarmId] = useState(undefined);

  const [yearsFilter, setYearsFilter] = useState([2017, 2018, 2019, 2020, 2021]);

  const crops = useAppSelector(s => s.global.cropTypes);
  const {summerCropItems, winterCropItems} = useMemo(() => getCarbonCrops(crops), [crops]);

  const tillageItems = carbonTillageOrder.map(tillage => ({
    value: tillage,
    label: tillageLabels[tillage],
    icon: <ColoredCircle color={tillageColor[tillage]} />,
  }));

  // Take only the farms that has selected fields.
  const {farmIds, farms} = useMemo(() => {
    const farmIds = getFarmIdsThatHasFields(fieldsByFarmId, carbon.enrolledFields);
    const farms = allFarms.filter(farm => farmIds.includes(farm.id));
    return {farmIds, farms};
  }, [fieldsByFarmId, carbon.enrolledFields, allFarms]);

  const changeCurrentFarmId = (id: number) => {
    if (isValidFarm) {
      applyGuessedConfidentPractices();
    }
    flushBulkChanges();
    setCurrentFarmId(id);
  };

  useEffect(() => {
    if (!currentFarmId) {
      setCurrentFarmId(farmIds[0]);
    }
  }, [farmIds]);

  const bulkChanges = useRef<{farmId: number; changes: CarbonPracticeChanges}>();
  const bulkChangesTimeout = useRef<ReturnType<typeof setTimeout>>();

  const flushBulkChanges = async () => {
    if (!bulkChanges.current) {
      return;
    }
    const {farmId, changes} = bulkChanges.current;
    const seasonPayload = getSeasonPayload(changes);
    await dispatch(saveBulkCrop(farmId, seasonPayload, false, true));
    bulkChanges.current = undefined;
    clearTimeout(bulkChangesTimeout.current); // Clear in case it was flushed by leaving the step.
  };

  /**
   * Beside the practices that users picked or confirmed low confidence guessed practices,
   * this function applies the medium/high confidence guessed practices automatically.
   */
  const applyGuessedConfidentPractices = () => {
    const farmId = bulkChanges.current?.farmId || currentFarmId;
    if (!fieldsByFarmId[farmId]) {
      return;
    }
    Object.keys(fieldsByFarmId[farmId])
      .map(Number)
      .forEach(fieldId => {
        carbonYears.forEach(year => {
          const season = getSeasonByYear(fieldsByFarmId[farmId][fieldId]?.Seasons, year);
          const guessed = carbon.guessedPractices[fieldId]?.[year];
          const {changed, payload} = guessedToPayload(farmId, fieldId, year, season, guessed);
          if (changed) {
            updateCrop(payload);
          }
        });
      });
  };

  useEffect(() => {
    return () => {
      // Flush bulk changes on destruction.
      if (isValidFarm) {
        applyGuessedConfidentPractices();
      }
      flushBulkChanges();
    };
  }, []);

  const updateCrop = (payload: SeasonPayload) => {
    if (!bulkChanges.current) {
      bulkChanges.current = {farmId: currentFarmId, changes: {}};
    }
    if (!bulkChanges.current.changes[payload.fieldId]) {
      bulkChanges.current.changes[payload.fieldId] = {};
    }
    if (!bulkChanges.current.changes[payload.fieldId][payload.year]) {
      bulkChanges.current.changes[payload.fieldId][payload.year] = payload;
    }
    bulkChanges.current.changes[payload.fieldId][payload.year] = {
      ...bulkChanges.current.changes[payload.fieldId][payload.year],
      ...payload,
    };

    dispatch({
      type: ActionTypes.UPDATE_SEASON,
      payload,
    });

    clearTimeout(bulkChangesTimeout.current);

    bulkChangesTimeout.current = setTimeout(flushBulkChanges, 5000);
  };

  const currentFarmName = useMemo(() => farms.find(farm => farm.id === currentFarmId)?.name || '', [
    currentFarmId,
    farms,
  ]);

  const onOpenHelpTillage = useCallback(
    (type = 'tillage') => dispatch(dialogToggle(DialogType.carbonTillagePractices, true, type)),
    []
  );

  /*
   *
   * Validate farms when switch farm at the farm select control
   *
   * */
  const validateAllFarms = () => {
    const farms: {[farmId: number]: {isValidFarm: boolean}} = {};

    farmIds.forEach(id => {
      const {isValidFarm} = validateFarm(
        id,
        fieldsByFarmId,
        carbon.enrolledFields,
        carbon.guessedPractices
      );

      farms[id] = {
        isValidFarm,
      };
    });

    return farms;
  };

  const validationFarmsResult = validateAllFarms();

  const {isValidFarm = true} = validationFarmsResult[currentFarmId] || {};

  const invalidFarmsNumber = useMemo(() => {
    return Object.values(validationFarmsResult).filter(data => !data.isValidFarm).length;
  }, [validationFarmsResult]);

  return (
    <div className="carbon-panel__crop-practices-table-container">
      <div className="description">
        Confirm cropping and tillage practices on these fields for the past 5 years. This
        information will be used to generate predicted environmental outcomes in the next step.
      </div>
      <div className="filters">
        <Dropdown
          button={
            <FluroChip
              dropdown
              active={yearsFilter.length > 0}
              label={`Crop ${getYearRange(yearsFilter) || 'year'}`}
            />
          }
        >
          {carbonYearsZA.map(year => (
            <Checkbox
              id={`year-filter-checkbox-${year}`}
              key={year}
              name="year-filter"
              label={year}
              value={year}
              checked={yearsFilter.includes(year)}
              onChange={value =>
                setYearsFilter(y => (value ? [...y, year] : y.filter(y => y !== year)))
              }
            />
          ))}
        </Dropdown>

        <div className={'farm-chips'}>
          <span className={'title-label'}>{calcPlural('Farm', farms)}:</span>
          {farms.map(farm => (
            <FluroChip
              key={farm.id}
              active={currentFarmId === farm.id}
              label={farm.name}
              onClick={() => changeCurrentFarmId(farm.id)}
            />
          ))}
        </div>
      </div>

      {!isValidFarm ? <ConfirmPracticesMessage /> : null}

      {currentFarmId && (
        <div className="cards-container">
          {sortFieldsByProp(Object.values(fieldsByFarmId[currentFarmId]), 'Name', 'string').map(
            field => {
              if (!carbon.enrolledFields[field.ID]) {
                return null;
              }

              return (
                <div className="field-card" key={field.ID}>
                  <div className="field-main-info">
                    <div className={'farm-field-names'}>
                      <div title={field.Name} className={'field-name'}>
                        {field.Name}
                      </div>
                      <div title={currentFarmName} className="farm-name">
                        {currentFarmName}
                      </div>
                    </div>

                    <div className="main-info-right">
                      <div>{field.CountryRegion}</div>
                      <div>{selectMeasurement(field.Area)}</div>
                    </div>
                  </div>
                  <FluroDataTable
                    className={'carbon-panel__crop-practices-table'}
                    responsive={false}
                    elevated={false}
                  >
                    <FluroTableHeader>
                      <FluroTableRow className="field-header-row">
                        <FluroTableColumn className="first-col" type={'date'}>
                          {t({id: 'Year'})}
                        </FluroTableColumn>
                        <FluroTableColumn>
                          <FluroTableFlexWrapper>
                            <span>{t({id: 'Spring tillage'})}</span>
                            <FluroTableColumnIconButton
                              onClick={() => onOpenHelpTillage()}
                              mdIconValue="help_outline"
                            />
                          </FluroTableFlexWrapper>
                        </FluroTableColumn>
                        <FluroTableColumn>{t({id: 'Summer crop'})}</FluroTableColumn>
                        <FluroTableColumn>
                          <FluroTableFlexWrapper>
                            <span>{t({id: 'Fall tillage'})}</span>
                            <FluroTableColumnIconButton
                              onClick={() => onOpenHelpTillage()}
                              mdIconValue="help_outline"
                            />
                          </FluroTableFlexWrapper>
                        </FluroTableColumn>
                        <FluroTableColumn>
                          <FluroTableFlexWrapper>
                            <span>{t({id: 'Winter crop'})}</span>
                            <FluroTableColumnIconButton
                              onClick={() => onOpenHelpTillage('crop')}
                              mdIconValue="help_outline"
                            />
                          </FluroTableFlexWrapper>
                        </FluroTableColumn>
                      </FluroTableRow>
                    </FluroTableHeader>
                    <FluroTableBody>
                      {carbonYearsZA
                        .filter(year => !yearsFilter.length || yearsFilter.includes(year))
                        .map(year => {
                          const p = getCropPractices(
                            field.ID,
                            year,
                            field.Seasons,
                            carbon.guessedPractices
                          );
                          const {
                            spring,
                            springConfidence,
                            summer,
                            summerConfidence,
                            fall,
                            fallConfidence,
                            winter,
                            winterConfidence,
                          } = p;

                          const errors = validatePractices(p, year);
                          const seasons: {[key: string]: Season} = {};
                          yearQuarterOrder.forEach(q => {
                            seasons[q] = getSeason(field.Seasons, q, year);
                          });

                          return (
                            <FluroTableRow
                              key={`${field.ID}-${year}-${spring}-${summer}-${fall}-${winter}`}
                              hoverBg={false}
                            >
                              <FluroTableColumn className="first-col" type={'date'}>
                                {year}
                              </FluroTableColumn>
                              <FluroTableColumn className="select-col">
                                <CarbonSelect
                                  error={errors.includes(YearQuarter.SpringTillage)}
                                  placeholder={t({id: 'Select tillage'})}
                                  selectedItem={spring}
                                  subtitle={springConfidence}
                                  items={tillageItems}
                                  disabled
                                  confirmComponent={
                                    springConfidence &&
                                    springConfidence.toLowerCase().startsWith('low') ? (
                                      <div
                                        className="check-icon-container"
                                        title="Confirm"
                                        onClick={e => {
                                          updateCrop({
                                            farmId: currentFarmId,
                                            fieldId: field.ID,
                                            year: year,
                                            springTillage: spring,
                                            season: seasons[YearQuarter.SpringTillage],
                                          });
                                        }}
                                      >
                                        <CheckIcon className="check-icon" />
                                      </div>
                                    ) : null
                                  }
                                  onSelect={value => {
                                    updateCrop({
                                      farmId: currentFarmId,
                                      fieldId: field.ID,
                                      year,
                                      springTillage: value,
                                      season: seasons[YearQuarter.SpringTillage],
                                    });
                                  }}
                                />
                              </FluroTableColumn>
                              <FluroTableColumn className="select-col">
                                <CarbonSelect
                                  error={errors.includes(YearQuarter.SummerCrop)}
                                  placeholder={t({id: 'Select crop type'})}
                                  selectedItem={summer}
                                  subtitle={summerConfidence}
                                  isSearchable
                                  items={summerCropItems}
                                  confirmComponent={
                                    summerConfidence &&
                                    summerConfidence.toLowerCase().startsWith('low') ? (
                                      <div
                                        className="check-icon-container"
                                        title="Confirm"
                                        onClick={e => {
                                          updateCrop({
                                            farmId: currentFarmId,
                                            fieldId: field.ID,
                                            year: year,
                                            summerCrop: summer,
                                            season: seasons[YearQuarter.SummerCrop],
                                          });
                                        }}
                                      >
                                        <CheckIcon className="check-icon" />
                                      </div>
                                    ) : null
                                  }
                                  onSelect={value => {
                                    updateCrop({
                                      farmId: currentFarmId,
                                      fieldId: field.ID,
                                      year,
                                      summerCrop: value,
                                      season: seasons[YearQuarter.SummerCrop],
                                    });
                                  }}
                                />
                              </FluroTableColumn>
                              <FluroTableColumn nowrap className="select-col">
                                {year === 2021 ? (
                                  <div className="text-msg">{t({id: 'In progress'})}</div>
                                ) : (
                                  <CarbonSelect
                                    error={errors.includes(YearQuarter.FallTillage)}
                                    placeholder={t({id: 'Select tillage'})}
                                    selectedItem={fall}
                                    subtitle={fallConfidence}
                                    items={tillageItems}
                                    confirmComponent={
                                      fallConfidence &&
                                      fallConfidence.toLowerCase().startsWith('low') ? (
                                        <div
                                          className="check-icon-container"
                                          title="Confirm"
                                          onClick={e => {
                                            updateCrop({
                                              farmId: currentFarmId,
                                              fieldId: field.ID,
                                              year: year + 1, // fall is the beginning of a new carbon year
                                              fallTillage: fall,
                                              season: seasons[YearQuarter.FallTillage],
                                            });
                                          }}
                                        >
                                          <CheckIcon className="check-icon" />
                                        </div>
                                      ) : null
                                    }
                                    onSelect={value => {
                                      updateCrop({
                                        farmId: currentFarmId,
                                        fieldId: field.ID,
                                        year: year + 1, // fall is the beginning of a new carbon year
                                        fallTillage: value,
                                        season: seasons[YearQuarter.FallTillage],
                                      });
                                    }}
                                  />
                                )}
                              </FluroTableColumn>
                              <FluroTableColumn nowrap className="select-col">
                                {year === 2021 ? (
                                  <div className="text-msg">{t({id: 'In progress'})}</div>
                                ) : (
                                  <CarbonSelect
                                    error={errors.includes(YearQuarter.WinterCrop)}
                                    placeholder={t({id: 'Select crop type'})}
                                    selectedItem={winter}
                                    isSearchable
                                    subtitle={winterConfidence}
                                    items={winterCropItems}
                                    confirmComponent={
                                      winterConfidence &&
                                      winterConfidence.toLowerCase().startsWith('low') ? (
                                        <div
                                          className="check-icon-container"
                                          title="Confirm"
                                          onClick={e => {
                                            updateCrop({
                                              farmId: currentFarmId,
                                              fieldId: field.ID,
                                              year: year + 1, // winter crop is a part of a new carbon year
                                              winterCrop: winter,
                                              season: seasons[YearQuarter.WinterCrop],
                                            });
                                          }}
                                        >
                                          <CheckIcon className="check-icon" />
                                        </div>
                                      ) : null
                                    }
                                    onSelect={value => {
                                      updateCrop({
                                        farmId: currentFarmId,
                                        fieldId: field.ID,
                                        year: year + 1, // winter crop is a part of a new carbon year
                                        winterCrop: value,
                                        season: seasons[YearQuarter.WinterCrop],
                                      });
                                    }}
                                  />
                                )}
                              </FluroTableColumn>
                            </FluroTableRow>
                          );
                        })}
                    </FluroTableBody>
                  </FluroDataTable>
                </div>
              );
            }
          )}
        </div>
      )}
      <div className={'floating-action'}>
        <FluroButton onClick={() => dispatch(setStep(CarbonStep.Fields))} blank raised>
          {t({id: 'Back'})}
        </FluroButton>

        <div className={'internal-container'}>
          <Button
            disabled={!isValidFarm}
            raised
            primary
            onClick={async () => {
              let isValid = true;

              const values = Object.values(validationFarmsResult);

              for (let i = 0; i < values.length; i++) {
                const {isValidFarm} = values[i];

                // if some of selected farm is not valid - switch user to the invalid farm
                if (!isValidFarm) {
                  isValid = false;
                  changeCurrentFarmId(farmIds[i]);
                  break;
                }
              }

              // do not proceed to the next step if has invalid farms
              if (!isValid) return;

              await flushBulkChanges();
              await dispatch(saveCarbonPlan());
              await dispatch(generateCarbonSequestration(carbon.planId));
              dispatch(setStep(CarbonStep.Credits));
            }}
          >
            {isValidFarm && invalidFarmsNumber
              ? t({id: 'Next farm'})
              : t({id: CarbonStep.CropPractices})}
          </Button>
        </div>
      </div>
    </div>
  );
};
