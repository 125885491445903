import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {Button, FontIcon, ListItem, MenuButton, SelectionControl} from 'react-md';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '_hooks';
import './carbon-panel-select-fields.scss';
import {t} from 'i18n-utils';
import {CarbonStep, saveCarbonPlan, enrollFields, setStep, removeFields} from '../carbon-reducer';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {allowToChangeToTheStep, isCarbonAllowedRegion, toggleFieldsInsideFarm} from '../utils';
import {FloatingContainer} from 'components/floating-container/floating-container';
import {dialogToggle, DialogType as HelpersDialogType} from '../../../modules/ui-helpers';
import {removeField, toggleEditingMode2, toggleMapBar} from 'containers/map/actions';
import {removeFarm} from '../../farm/actions';
import {capitalizeFirstLetter, convertUnit, getFarmById, sortFieldsByProp} from '_utils';
import {FluroButton, InfoBlock, ReadOnly} from 'components';
import {main_gray_600} from '_utils/colors';
import {toggleTooltip} from '../../../_actions';
import {CARBON_ALLOWED_REGIONS} from '../../../_constants';
import {WarningSvg} from '../../../components/icons';
import {Field} from '../../map/types';
import {GeoJSON, Map} from 'leaflet';
import {reportError} from '../../error-boundary';

export const eligibleMsg = (
  <>
    <div className={'font-weight--medium'}>Ineligible location.</div>
    Eligible states includes: {CARBON_ALLOWED_REGIONS.map(v => capitalizeFirstLetter(v)).join(', ')}
    .
  </>
);

const ENROLLMENT_MAX_COUNT = 200;

export const CarbonPanelSelectFields = ({leafletElement}: {leafletElement: Map}) => {
  const dispatch = useDispatch();
  const carbon = useAppSelector(s => s.carbon);
  const fieldsByFarmId = useAppSelector(s => s.map.fieldsByFarmId);
  const farms = useAppSelector(s => s.farms);
  const map = useAppSelector(s => s.map);
  const enrolledFields = useAppSelector(s => s.carbon.enrolledFields);
  const measurement = useAppSelector(s => s.login.user.settings.measurement);
  const fieldGeometries = useAppSelector(s => s.map.fieldGeometries);
  const isEditingMode = useAppSelector(s => s.map.drawControl.isEditingMode);

  const [expandedFarms, setExpandedFarms] = useState<{[farmId: number]: boolean}>({});

  const enrolledFieldsCount = useMemo(
    () => Object.keys(enrolledFields).filter(k => enrolledFields[parseInt(k)]).length,
    [enrolledFields]
  );

  const fields = useMemo(() => {
    const fields: Field[] = [];

    Object.keys(fieldsByFarmId).forEach(farmId => {
      Object.keys(fieldsByFarmId[parseInt(farmId)]).forEach(fieldId => {
        if (enrolledFields[parseInt(fieldId)]) {
          fields.push(fieldsByFarmId[parseInt(farmId)][parseInt(fieldId)]);
        }
      });
    });

    return fields;
  }, [fieldsByFarmId, enrolledFields]);

  const totalFieldsNumber = useMemo(() => {
    return Object.keys(fieldsByFarmId).reduce((counter, farmId) => {
      return Object.keys(fieldsByFarmId[parseInt(farmId)]).length + counter;
    }, 0);
  }, [fieldsByFarmId]);

  const totalEnrolledFieldsArea = useMemo(() => {
    return fields.reduce((acc, f) => acc + f.Area, 0);
  }, [fields]);

  const farmsToProcess = useMemo(() => {
    return Object.keys(fieldsByFarmId)
      .map(Number)
      .map(farmId => getFarmById(farmId))
      .filter(Boolean);
  }, [fieldsByFarmId, farms.list]);

  // Expand the first farm.
  React.useEffect(() => {
    // Expand the farm and show the fields if a user has only 1 farm.
    if (farms.list.length === 1) {
      toggleExpandFarm(farms.list[0].id);
    }
  }, [farms]);

  const toggleExpandFarm = (farmId: number, forceValue?: boolean) => {
    setExpandedFarms(e => ({...e, [farmId]: forceValue !== undefined ? forceValue : !e[farmId]}));
  };

  const farmIsSelected = (farmId: number) => {
    const fieldIds = Object.keys(fieldsByFarmId[farmId]).map(Number);
    return fieldIds.length > 0 && fieldIds.every(fieldId => carbon.enrolledFields[fieldId]);
  };

  const toggleFarm = (farmId: number, forceValue?: boolean) => {
    const selection = toggleFieldsInsideFarm(
      fieldsByFarmId,
      farmId,
      forceValue !== undefined ? forceValue : !farmIsSelected(farmId)
    );
    dispatch(enrollFields(selection));
  };

  const toggleField = (fieldId: number, forceValue?: boolean) => {
    dispatch(
      enrollFields({
        [fieldId]: forceValue !== undefined ? forceValue : !carbon.enrolledFields[fieldId],
      })
    );
  };

  const addFields = () => {
    dispatch(toggleMapBar(false));
    dispatch(toggleDialog(DialogType.AddNewField, true));
  };

  const confirmSelection = () => {
    const allowChangeStep = dispatch(allowToChangeToTheStep(CarbonStep.CropPractices));

    if (allowChangeStep) {
      dispatch(saveCarbonPlan()); // Save enrolled fields.
      dispatch(setStep(CarbonStep.CropPractices));
    }
  };

  const onEditField = useCallback(
    (field: Field) => {
      try {
        dispatch(toggleMapBar(false));

        const layer = new GeoJSON(fieldGeometries[field.MD5]);
        leafletElement.fitBounds(layer.getBounds(), {
          padding: [50, 50],
        });

        // added timeout to wait for fir bounds finished
        setTimeout(() => dispatch(toggleEditingMode2(true, field.ID)), 0);
      } catch (e) {
        reportError('Edit field boundary');
      }
    },
    [fieldGeometries, leafletElement]
  );

  return (
    <div className="carbon-panel__select-fields">
      <h3 className="tab-title">{t({id: CarbonStep.Fields})}</h3>
      <div className="description">
        Select fields to enroll <span>new</span> or <span>expanded practices</span> (reduced
        till/no-till/cover crops) to be implemented in <span>fall of 2021</span> and{' '}
        <span>spring of 2022.</span>
      </div>

      <div className="selected-fields-info">
        {enrolledFieldsCount}/{totalFieldsNumber} field(s) selected,{' '}
        {convertUnit(measurement, 'ac', totalEnrolledFieldsArea)} {t({id: measurement})}
      </div>

      {enrolledFieldsCount > ENROLLMENT_MAX_COUNT && (
        <InfoBlock iconColor={main_gray_600} appearance={'warning'} className={'notice-warn-block'}>
          <div className={'title'}>You have reached the enrollment limit.</div>A maximum of{' '}
          {ENROLLMENT_MAX_COUNT} fields can be enrolled. Deselect some fields from the list below to
          proceed.
        </InfoBlock>
      )}

      <div className="farm-list">
        {farmsToProcess.map(currentFarm => {
          const farmId = currentFarm.id;
          const fields = Object.values(fieldsByFarmId[farmId]);

          if (!fields.length) return null;

          const farmName =
            farms.list.find(f => f.id === farmId)?.name ||
            (map.group.id === farmId && map.group.name) || // if the list is not there and we're filling the current farm, use it
            '';
          const farmArea = convertUnit(
            measurement,
            'ac',
            fields.reduce((acc, f) => acc + f.Area, 0)
          );
          const classifiedFarmArea = farmArea > 1000 ? (6548.1 / 1000).toFixed(1) + 'k ' : farmArea;
          const selectedFields = fields.filter(f => carbon.enrolledFields[f.ID]);
          const isFarmSelected = farmIsSelected(farmId);

          return (
            <div className="farm" key={farmId}>
              <div className="farm__row">
                <div
                  className={'farm-checkbox-container'}
                  onClick={() => {
                    // expand farm when click on th master checkbox and if all fields are ineligible
                    if (fields.every(f => !isCarbonAllowedRegion(f.CountryRegion))) {
                      toggleExpandFarm(farmId, true);
                    }
                  }}
                >
                  <SelectionControl
                    id={farmId}
                    name={'farm selection'}
                    uncheckedCheckboxIcon={
                      selectedFields.length ? (
                        <FontIcon className={'intermediate-checkbox-icon'}>
                          indeterminate_check_box
                        </FontIcon>
                      ) : (
                        <FontIcon>check_box_outline_blank</FontIcon>
                      )
                    }
                    label={
                      <div title={farmName} className={'checkbox-label'}>
                        {farmName}
                        <div className={'subtext'}>
                          {t(
                            {id: '{count1} / {count2} fields selected'},
                            {count1: selectedFields.length, count2: fields.length}
                          )}
                        </div>
                      </div>
                    }
                    type={'checkbox'}
                    checked={isFarmSelected}
                    onChange={() =>
                      toggleFarm(farmId, isFarmSelected ? false : !selectedFields.length)
                    }
                  />
                </div>

                <Button className={'expand-btn'} icon onClick={() => toggleExpandFarm(farmId)}>
                  {expandedFarms[farmId] ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                </Button>

                <div className={'farm-area'}>
                  {classifiedFarmArea} {t({id: measurement})}
                </div>

                <ReadOnly containerClass={'read-only-container'} isReadOnly={currentFarm.readOnly}>
                  <ActionsButton
                    id={'fields-menu-button-export'}
                    actions={[
                      {
                        label: t({id: 'Edit name'}),
                        onClick: () =>
                          dispatch(
                            dialogToggle(HelpersDialogType.editFarmName, true, {
                              farmId,
                              farmName,
                            })
                          ),
                      },
                      {
                        label: t({id: 'Delete'}),
                        onClick: () =>
                          dispatch(
                            dialogToggle(HelpersDialogType.deleteDialog, true, {
                              title: t({id: 'Delete farm?'}),
                              onSubmit: () => {
                                dispatch(
                                  removeFields({
                                    fieldIds: Object.keys(fieldsByFarmId[farmId]).map(Number),
                                  })
                                );
                                dispatch(removeFarm(farmId, true));
                              },
                            })
                          ),
                      },
                    ]}
                  />
                </ReadOnly>
              </div>
              {expandedFarms[farmId] && (
                <div>
                  {sortFieldsByProp(fields, 'Name', 'string').map(field => {
                    return (
                      <div
                        className="field"
                        key={field.ID}
                        onMouseEnter={() => {
                          !isCarbonAllowedRegion(field.CountryRegion) &&
                            dispatch(
                              toggleTooltip({
                                id: `tooltip-${field.ID}`,
                                content: eligibleMsg,
                                place: 'top',
                              })
                            );
                        }}
                      >
                        <SelectionControl
                          id={field.ID}
                          //@ts-ignore
                          title={field.Name}
                          className={'field-checkbox'}
                          name={'field selection'}
                          label={<div className="field-name field-name-disabled">{field.Name}</div>}
                          type={'checkbox'}
                          checked={carbon.enrolledFields[field.ID]}
                          onChange={(v: boolean) => toggleField(field.ID)}
                          disabled={!isCarbonAllowedRegion(field.CountryRegion)}
                        />

                        <div className="field-item-right-block">
                          <div className="field-area">
                            {convertUnit(measurement, 'ac', field.Area)} {t({id: measurement})}
                          </div>

                          {!isCarbonAllowedRegion(field.CountryRegion) && (
                            <WarningSvg
                              data-tip=""
                              data-for={`tooltip-${field.ID}`}
                              className={'ineligible-icon'}
                            />
                          )}

                          <ReadOnly
                            containerClass={'read-only-container'}
                            isReadOnly={currentFarm.readOnly}
                          >
                            <ActionsButton
                              id={'fields-menu-button-export'}
                              actions={[
                                !field.Seasons.length && !field.external_service
                                  ? {
                                      label: t({id: 'Edit boundary'}),
                                      onClick: () => onEditField(field),
                                    }
                                  : null,
                                {
                                  label: t({id: 'Edit name'}),
                                  onClick: () =>
                                    dispatch(
                                      dialogToggle(HelpersDialogType.editFieldName, true, {
                                        field: field,
                                        farmId: farmId,
                                      })
                                    ),
                                },
                                {
                                  label: t({id: 'Delete'}),
                                  onClick: () =>
                                    dispatch(
                                      dialogToggle(HelpersDialogType.deleteDialog, true, {
                                        title: t({id: 'Delete field?'}),
                                        onSubmit: () => {
                                          dispatch(removeFields({fieldIds: [field.ID]}));
                                          dispatch(removeField(farmId, [field.ID], true));
                                        },
                                      })
                                    ),
                                },
                              ]}
                            />
                          </ReadOnly>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <FloatingContainer>
        <FluroButton blank raised onClick={addFields} disabled={isEditingMode}>
          + {t({id: 'Add fields'})}
        </FluroButton>
        <FluroButton
          raised
          primary
          disabled={enrolledFieldsCount > ENROLLMENT_MAX_COUNT}
          onClick={confirmSelection}
        >
          {t({id: 'Confirm selection'})}
        </FluroButton>
      </FloatingContainer>
    </div>
  );
};

type ActionsButtonProps = {
  id: string;
  actions: {label: string; onClick: () => void}[];
};

const ActionsButton = ({id, actions}: ActionsButtonProps) => {
  if (!actions.length) return null;
  return (
    <MenuButton
      id={id}
      className="actions"
      listClassName="actions-button__menu-list"
      icon
      menuItems={actions
        .filter(item => item)
        .map(({onClick, label}, index) => (
          <ListItem key={index} onClick={onClick} primaryText={label} />
        ))}
    >
      <FontIcon>more_vert</FontIcon>
    </MenuButton>
  );
};
