import {NrxApi} from '_api';
import {genKey} from '_utils';
import {EmailLog} from 'containers/admin/logs/email/types';
import {NrxSearchType} from '../containers/admin/logs/nrx/NrxLogsTable';

export enum ActionType {
  NrxSetDashboardData = '@admin/logs/nrx/dashboard/set-data',
  SetFilterType = '@admin/logs/filter/set-type',
  SetNrxSort = '@admin/logs/nrx/sort/set',
  SetNrxSearch = '@admin/logs/nrx/search/set',
  SetEmailLogs = '@admin/logs/email/log/set',
  UpdateEmailLogs = '@admin/logs/email/log/update',
  SetWatchUrl = '@admin/logs/email/watch-url/set',
}

export type Status =
  | 'success'
  | 'failed'
  | 'not_scheduled'
  | 'missing_input'
  | 'canceled'
  | 'acquired'
  | 'no_nrx_season'
  | 'netcdf_missing'
  | 'soilmap_missing'
  | 'new';

export type NrxItem = {
  id: string | number;
  count: number;
  crop: string;
  cropVariety: string;
  endDate: string;
  farmID: number;
  farmName: string;
  fieldName: string;
  kmlID: number;
  latestRecommendationDate: string;
  nrxSeasonID: number;
  seasonID: number;
  startDate: string;
  status: string;
  statusMsg: string;
  statusTime: string;
};

type NrxDashboard = {
  list: NrxItem[];
  sortByColumn: string;
  sortDesc: boolean;
  searchString: string;
  searchBy: NrxSearchType;
  searchEntityId: number | string;
};

export type LogsStore = {
  nrx: NrxDashboard;
  emailsList: EmailLog[];
  emailsListTotalRecords: number;
  filterType: string;
  emailsLogWatchUrl: string;
};

export const initialState: LogsStore = {
  nrx: {
    list: [],
    sortByColumn: 'statusTime',
    sortDesc: true,
    searchBy: 'field',
    searchString: '',
    searchEntityId: 0,
  },
  emailsList: [],
  emailsListTotalRecords: 0,
  emailsLogWatchUrl: '',
  filterType: 'crop_stress',
};

export const loadDashboard = () => (dispatch: any) => {
  return NrxApi.getDashboard().then(({data}) => {
    const list = (data?.result || []) as NrxItem[];

    dispatch({
      type: ActionType.NrxSetDashboardData,
      list: list.map(el => ({
        ...el,
        id: genKey(),
        statusTime: el.statusTime || '',
        latestRecommendationDate: el.latestRecommendationDate || '',
      })),
    });
  });
};

export const setFilterType = (filterType: string) => ({
  type: ActionType.SetFilterType,
  filterType,
});

export const setNrxSort = (sortBy: string, desc: boolean) => ({
  type: ActionType.SetNrxSort,
  sortBy,
  desc,
});

export const setNrxSearch = (
  values: Pick<NrxDashboard, 'searchBy' | 'searchString' | 'searchEntityId'>
) => ({
  type: ActionType.SetNrxSearch,
  ...values,
});

export const setEmailLogs = (list: EmailLog[], totalRecords: number = 0) => ({
  type: ActionType.SetEmailLogs,
  list,
  totalRecords,
});

export const updateEmailLogs = (list: Partial<EmailLog>[]) => ({
  type: ActionType.UpdateEmailLogs,
  list,
});

export const setWatchUrl = (url: string) => ({
  type: ActionType.SetWatchUrl,
  url,
});

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.NrxSetDashboardData: {
      return {
        ...state,
        nrx: {
          ...state.nrx,
          list: [...action.list],
        },
      };
    }

    case ActionType.SetFilterType: {
      return {
        ...state,
        filterType: action.filterType,
      };
    }

    case ActionType.SetNrxSort: {
      return {
        ...state,
        nrx: {
          ...state.nrx,
          sortByColumn: action.sortBy,
          sortDesc: action.desc,
        },
      };
    }

    case ActionType.SetNrxSearch: {
      return {
        ...state,
        nrx: {
          ...state.nrx,
          ...action,
        },
      };
    }

    case ActionType.SetEmailLogs: {
      return {
        ...state,
        emailsList: [...action.list],
        emailsListTotalRecords: action.totalRecords,
      };
    }

    case ActionType.UpdateEmailLogs: {
      return {
        ...state,
        emailsList: state.emailsList.map(el => {
          const upItem = action.list.find((item: any) => item.id === el.id);
          if (upItem) {
            return {
              ...el,
              ...upItem,
            };
          }

          return el;
        }),
      };
    }

    case ActionType.SetWatchUrl: {
      return {
        ...state,
        emailsLogWatchUrl: action.url,
      };
    }

    default:
      return state;
  }
};
