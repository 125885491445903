import {Field, Season} from 'containers/map/types';
import moment from 'moment';
import {AppStore} from 'reducers';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_TIME} from '_constants';
import {getFieldSeasons} from '_hooks/use-current-season';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {getBenchmarkMapChartData} from '../benchmark-card/helpers';
import {getBiomassMapChartData} from '../biomass/biomass';
import {getCropGrowthMapChartData} from '../crop-growth/crop-growth';
import {getCropStatusMapChartData} from '../crop-status/crops-status';
import {getCropStressMapChartData} from '../crop-stress-card';
import {getCropTypeMapChartData} from '../crop-type/crop-type';
import {getFieldsVariabilityMapChartData} from '../field-variability/field-variability';
import {ClusterChartData} from './cluster-marker';

export const getClusterMarkerDataAndColors = (
  seasonIds: number[],
  isSeason: {[seasonId: number]: boolean}, // Some fields could have no seasons.
  fields: Field[],
  cropPerformance: AppStore['cropPerformance'],
  cropPerformanceFilter: AppStore['cropPerformanceFilter'],
  currentDate: string,
  cropTypes: AppStore['global']['cropTypes'],
  cropVarietyMode: AppStore['map']['cropVarietyMode'],
  cropVarietyColors: AppStore['map']['cropVarietyColors']
) => {
  const ids: {[seasonId: number]: true} = {};
  seasonIds.forEach(seasonId => {
    if (!cropPerformanceFilter.filterStatus[seasonId]) {
      ids[seasonId] = true;
    }
  });
  const date = moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_TIME);
  const records = cropPerformance.records;

  let dataAndColors: {
    data: ClusterChartData[];
    getColor: (slice: any) => string;
  };

  switch (cropPerformance.representation) {
    case CPFilterType.CROP_TYPE: {
      const cropsToShow = fields
        .flatMap(f => getFieldSeasons(f, date))
        .filter(season => ids[season.id])
        .filter(season =>
          cropVarietyMode
            ? cropPerformanceFilter.filterStatus[season.id] !== CPFilterType.CROP_TYPE
            : true
        );
      // Some fields could have no seasons. We want to show them anyway in whole farm view.
      const seasonlessFieldsAsDummySeasons: Season[] = seasonIds
        .filter(id => !isSeason[id])
        .map(id => ({startDate: '', endDate: '', cropType: ''}));

      dataAndColors = getCropTypeMapChartData({
        cropsToShow: [...cropsToShow, ...seasonlessFieldsAsDummySeasons],
        cropVarietyMode,
        cropTypes,
        cropVarietyColors,
      });
      break;
    }
    case CPFilterType.CROP_STATUS: {
      const recordsToShow = records.filter(r => ids[r.seasonId]);
      dataAndColors = getCropStatusMapChartData(
        recordsToShow,
        cropPerformance.info?.colours,
        cropPerformance.useUnreliableData
      );
      break;
    }
    case CPFilterType.CROP_GROWTH: {
      const recordsToShow = records.filter(r => ids[r.seasonId]);
      dataAndColors = getCropGrowthMapChartData(recordsToShow, cropPerformance.useUnreliableData);
      break;
    }
    case CPFilterType.BIOMASS: {
      const recordsToShow = records.filter(r => ids[r.seasonId]);
      dataAndColors = getBiomassMapChartData(
        recordsToShow,
        cropPerformance.ndviQuartiles,
        cropPerformance.useUnreliableData
      );
      break;
    }
    case CPFilterType.FIELDS_VARIABILITY: {
      const fieldsVariabilityData = fields
        .flatMap(f => getFieldSeasons(f, date))
        .filter(s => ids[s.id] && cropPerformance.fieldsVariability[s.id])
        .map(s => ({status: cropPerformance.fieldsVariability[s.id].status, id: s.id}));
      dataAndColors = getFieldsVariabilityMapChartData(fieldsVariabilityData);
      break;
    }
    case CPFilterType.CROP_STRESS: {
      const fieldsToShow = fields.filter(f => ids[f.SeasonID]);
      dataAndColors = getCropStressMapChartData(fieldsToShow, cropPerformance.cropStressChartData);
      break;
    }
    case CPFilterType.BIOMASS_OVER_TIME: {
      const recordsToShow = records.filter(r => ids[r.seasonId]);
      dataAndColors = getBenchmarkMapChartData(recordsToShow, cropPerformanceFilter);
      break;
    }
    default:
      // unreachableError(cropPerformance.representation, 'nono');
      throw new Error(
        `NOT IMPLEMENTED map cluster marker for representation ${cropPerformance.representation}`
      );
  }
  return dataAndColors;
};
