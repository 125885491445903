import {combineReducers, Action} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {routerReducer, RouterState} from 'react-router-redux';

import login from 'containers/login/reducer';
import {LoginState} from 'containers/login/types';
import oAuth from 'containers/oauth/reducer';
import {reducer as farmReducer, FarmStore} from 'containers/farm/reducer';
import alertsList, {AlertState} from 'components/alert/reducer';
import dialog, {State as DialogState} from './_reducers/dialog';
import global from './_reducers/global';
import {GlobalState} from './_reducers/global_types';
import cropPerformanceFilter from '_reducers/crop-performance-filter/field-filter-reducer';
import {CPFilterState} from '_reducers/crop-performance-filter/field-filter-types';
import viewport, {State as ViewportState} from './_reducers/viewport';
import users from 'containers/admin/users/reducer';
import {InitialState as UsersStore} from 'containers/admin/users/types';
import features from 'containers/admin/features/reducer';
import registration from 'containers/registration/reducer';
import integrations from 'containers/profile/integration/reducer';
import {IntegrationsState} from 'containers/profile/integration/types';
import map from 'containers/map/reducer';
import {IInitialMapState as MapState} from 'containers/map/types';
import cropPerformance from 'containers/map/features/crop-performance/reducer';
import {CropPerformanceState} from 'containers/map/features/crop-performance/types';
import optis from 'containers/map/features/optis/optis-reducer';
import {OptisState} from 'containers/map/features/optis/optis-types';
import tsUpload from 'containers/map/tissue-sampling-upload/reducer';
import helpPopup from 'containers/help-popup/reducer';
import userActivity from 'containers/admin/user-activity/reduсer';
import {SubscriptionsStore, reducer as subscriptionsReducer} from 'modules/subscriptions';
import {UIHelpersStore, reducer as uiHelpersReducer} from 'modules/ui-helpers';
import {LogsStore, reducer as logsReducer} from 'modules/logs.module';
import {
  UserAccessStore,
  reducer as userAccessReducer,
} from 'containers/admin/users/access-view/access-view.module';
import {FieldsLookupStore, reducer as fieldsLookupReducer} from 'modules/fields-lookup.module';
import {PickersStore, reducer as pickersReducer} from 'modules/pickers.module';
import {AddNewFieldsStore, reducer as addFieldsReducer} from 'modules/add-fields.module';
import {
  CropTypesMatchingStore,
  reducer as cropTypesMatchingReducer,
} from 'containers/admin/crop-types-matching/crop-types-matching.module';
import {AdminFeaturesStore} from './containers/admin/features/types';
import {
  PartOfFieldSeasonStore,
  reducer as partFieldSeasonReducer,
} from 'containers/map/features/farm/field/planting-area-season.module';
import {
  ProductivityMapStore,
  reducer as productivityMapReducer,
} from 'containers/map/features/zoning/productivity-map/productivity-map.module';
import {carbonSlice, CarbonState} from 'containers/carbon/carbon-reducer';

import {ImagesCacheStore, reducer as imageCacheReducer} from 'modules/images-cache.module';
import {StateUserActivity} from './containers/admin/user-activity/types';

export type AppThunk<ActionType extends Action, ReturnType = void> = ThunkAction<
  ReturnType,
  AppStore,
  unknown,
  ActionType
>;

export type AppThunkDispatch<ActionType extends Action> = ThunkDispatch<
  AppStore,
  unknown,
  ActionType
>;

// TODO: add all reducer types;
export type AppStore = {
  uiHelpers: UIHelpersStore;
  routing: RouterState;
  login: LoginState;
  alertsList: AlertState;
  dialog: DialogState;
  global: GlobalState;
  cropPerformanceFilter: CPFilterState;
  viewport: ViewportState;
  oAuth: any;
  farms: FarmStore;
  users: UsersStore;
  registration: any;
  integrations: IntegrationsState;
  map: MapState;
  cropPerformance: CropPerformanceState;
  optis: OptisState;
  carbon: CarbonState;
  tsUpload: any;
  helpPopup: any;
  userActivity: StateUserActivity;
  pickers: PickersStore;
  addFields: AddNewFieldsStore;
  admin: {
    subscriptions: SubscriptionsStore;
    logs: LogsStore;
    features: AdminFeaturesStore;
    userAccess: UserAccessStore;
    fieldsLookup: FieldsLookupStore;
    cropTypesMatching: CropTypesMatchingStore;
  };
  partFieldSeason: PartOfFieldSeasonStore;
  productivityMap: ProductivityMapStore;
  imagesCache: ImagesCacheStore;
};

const store = {
  routing: routerReducer,
  login,
  alertsList,
  dialog,
  global,
  cropPerformanceFilter,
  viewport,
  oAuth,
  farms: farmReducer,
  users,
  registration,
  integrations,
  map,
  cropPerformance,
  optis,
  carbon: carbonSlice.reducer,
  tsUpload,
  helpPopup,
  userActivity,
  pickers: pickersReducer,
  addFields: addFieldsReducer,
  admin: combineReducers({
    subscriptions: subscriptionsReducer,
    features,
    userAccess: userAccessReducer,
    logs: logsReducer,
    fieldsLookup: fieldsLookupReducer,
    cropTypesMatching: cropTypesMatchingReducer,
  }),

  uiHelpers: uiHelpersReducer,
  partFieldSeason: partFieldSeasonReducer,
  productivityMap: productivityMapReducer,
  imagesCache: imageCacheReducer,
};

export default store;
