import {t, FormattedMessage} from 'i18n-utils';
import React, {ReactNode, useMemo} from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import {Button, FontIcon} from 'react-md';
import {AppStore} from 'reducers';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE} from '_constants';
import MultiKeysPressed from 'components/key-handler';
import {BrowserView} from 'react-device-detect';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import './selector-date-calendar.scss';
import {setDate} from 'containers/map/actions';
import FluroDatePicker, {FluroCalendarContainer} from 'components/fluro-date-picker';

export const SelectorDateCalendar = () => {
  const dispatch = useDispatch();
  const feature = useSelector((s: AppStore) => s.map.feature);
  const currentDate = useSelector((s: AppStore) => s.map.currentDate);
  const fieldsByFarmId = useSelector((s: AppStore) => s.map.fieldsByFarmId);
  const cropPerformance = useSelector((s: AppStore) => s.cropPerformance);
  const [legendVisible, setLegendVisible] = useState(false);

  const toggleLegend = () => setLegendVisible(v => !v);

  const fields = useMemo(
    () =>
      Object.keys(fieldsByFarmId)
        .filter(farmId => cropPerformance.farms[Number(farmId)])
        .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])),
    [fieldsByFarmId, cropPerformance.farms]
  );

  const highlightDates: {[className: string]: Date[]}[] = useMemo(() => {
    const dateStats: {[date: string]: {imagery: number; total: number}} = {};
    Object.keys(cropPerformance.farms).forEach(farmId => {
      const csgs = cropPerformance.farms[Number(farmId)].csgs;
      if (!csgs) {
        return;
      }
      Object.keys(csgs).forEach(fieldId => {
        const field = csgs[Number(fieldId)];
        Object.keys(field.seasons).forEach(season => {
          // let fieldIsCounted = false;
          Object.keys(field.seasons[season].dates).forEach(date => {
            // if (fieldIsCounted) return;
            const d = field.seasons[season].dates[date];
            if (!dateStats[date]) {
              dateStats[date] = {imagery: 0, total: 0};
            }
            if (d.imagery) {
              dateStats[date].imagery++;
            }
            dateStats[date].total++;
          });
        });
      });
    });

    const lessThanHalf = [] as Date[],
      moreThanHalf = [] as Date[],
      full = [] as Date[];

    Object.keys(dateStats).forEach(date => {
      if (!dateStats[date].imagery) return;
      let alpha = dateStats[date].imagery / dateStats[date].total;
      // We use 3 colors. Fully opaque colors for 1 result.
      // Days with > 0.5 fields have imagery should be colored with 75% alpha.
      // Days with < 0.5 fields have imagery should be colored with 25% alpha.
      if (alpha === 1) {
        full.push(moment(date, SERVER_FORMAT_DATE).toDate());
      } else if (alpha > 0.5) {
        moreThanHalf.push(moment(date, SERVER_FORMAT_DATE).toDate());
      } else {
        lessThanHalf.push(moment(date, SERVER_FORMAT_DATE).toDate());
      }
    });

    return [{lessThanHalf}, {moreThanHalf}, {full}];
  }, [cropPerformance.farms]);

  const periods = useMemo(() => {
    let periods: {minDate: string; maxDate: string}[] = [];
    const randomSeason = fields.find(f => f.Seasons?.[0])?.Seasons[0];
    if (randomSeason) {
      periods = [{minDate: randomSeason.startDate, maxDate: randomSeason.endDate}];
      fields.forEach(f => {
        const candidates: {minDate: string; maxDate: string}[] = [];
        f.Seasons.forEach(s => {
          periods.forEach(p => {
            if (p.maxDate < s.startDate || p.minDate > s.endDate) {
              candidates.push({minDate: s.startDate, maxDate: s.endDate});
              return;
            }
            if (p.minDate > s.startDate) {
              p.minDate = s.startDate;
            }
            if (p.maxDate < s.endDate) {
              p.maxDate = s.endDate;
            }
          });
        });
        const finalists: {minDate: string; maxDate: string}[] = [];
        candidates.forEach(c => {
          let intersects = false;
          periods.forEach(p => {
            if (p.maxDate < c.minDate || p.minDate > c.maxDate) {
              return;
            }
            intersects = true;
            if (p.minDate > c.minDate) {
              p.minDate = c.minDate;
            }
            if (p.maxDate < c.maxDate) {
              p.maxDate = c.maxDate;
            }
          });
          if (!intersects) {
            finalists.push(c);
          }
        });
        periods.push(...finalists);
      });
    }
    periods.sort((a, b) => a.minDate.localeCompare(b.minDate));
    return periods;
  }, [fields]);

  const momentPeriods = periods.map(p => ({min: moment(p.minDate), max: moment(p.maxDate)}));
  const firstDay = momentPeriods.length ? momentPeriods[0].min : '1970-01-01';
  const lastDay = momentPeriods.length ? momentPeriods[periods.length - 1].max : '9999-12-31';
  const today = moment();
  const date = currentDate
    ? moment(currentDate, GLOBAL_APP_DATE_FORMAT)
    : moment().isBefore(lastDay)
    ? moment()
    : moment(lastDay);
  const momentMinDate = moment(firstDay);
  const momentMaxDate = moment(lastDay).isAfter(today) ? today : moment(lastDay);
  const selectPrevDate = () => {
    dispatch(
      setDate(
        moment(date)
          .subtract(1, 'day')
          .format(GLOBAL_APP_DATE_FORMAT) + '-satellite'
      )
    );
  };
  const selectNextDate = () => {
    dispatch(
      setDate(
        moment(date)
          .add(1, 'day')
          .format(GLOBAL_APP_DATE_FORMAT) + '-satellite'
      )
    );
  };

  return (
    <div className="crop-performance-date-container">
      <Button
        className={'select-date-btn prev'}
        disabled={moment(date)
          .subtract(1, 'day')
          .isBefore(momentMinDate)}
        icon
        onClick={selectPrevDate}
      >
        keyboard_arrow_left
      </Button>
      <FluroDatePicker
        id={'crop-performance-date'}
        selected={date}
        onChange={date => dispatch(setDate(date.format(GLOBAL_APP_DATE_FORMAT) + '-satellite'))}
        calendarContainer={({children}) => (
          <CalendarLegend
            defaultCalendar={children}
            visible={legendVisible}
            toggleVisibility={toggleLegend}
          />
        )}
        hideFormat
        minDate={momentMinDate}
        maxDate={momentMaxDate}
        highlightDates={highlightDates}
      />
      <Button
        className={'select-date-btn next'}
        disabled={moment(date)
          .add(1, 'day')
          .isAfter(momentMaxDate)}
        icon
        onClick={selectNextDate}
      >
        keyboard_arrow_right
      </Button>

      <BrowserView>
        {feature === 'data-layers' ? null : (
          <>
            <MultiKeysPressed callback={selectPrevDate} keys={['Shift', 'ArrowLeft']} />
            <MultiKeysPressed callback={selectNextDate} keys={['Shift', 'ArrowRight']} />
          </>
        )}
      </BrowserView>
    </div>
  );
};

const CalendarLegend = ({
  visible,
  toggleVisibility,
  defaultCalendar,
}: {
  visible: boolean;
  toggleVisibility: () => void;
  defaultCalendar: ReactNode;
}) => {
  return (
    <FluroCalendarContainer defaultCalendar={defaultCalendar}>
      <div className={cn({help: true, expanded: visible})}>
        <button className="toggle-help-btn" onClick={toggleVisibility}>
          <span
            className="color"
            style={{
              background: `linear-gradient(to right, rgba(67, 160, 71, 1), rgba(67, 160, 71, 0.1))`,
            }}
          />
          Fields with cloud-free imagery <FontIcon>keyboard_arrow_down</FontIcon>
        </button>
        {visible && (
          <div className="help-content">
            <div className="help-col">
              <span className="color" style={{background: `#43a047`}} />
              <span>100% clear</span>
            </div>
            <div className="help-col">
              <span className="color" style={{background: `#a5d0a4`}} />
              <span>{'> 50% cloudy'}</span>
            </div>
            <div className="help-col">
              <span className="color" style={{background: `#e4f1e4`}} />
              <span>{'< 50% cloudy'}</span>
            </div>
            <div className="help-col">
              <span className="color empty" style={{background: `#fff`}} />
              <span>No new imagery (interpolated results)</span>
            </div>
          </div>
        )}
      </div>
    </FluroCalendarContainer>
  );
};
