import {IInitialMapState, Season, TInfoExt, TrendsIndex} from '../types';
import {getDefaultNSettingValues, NRxZonesCollection} from 'containers/map/utils/nrx-utils';
import {getGetURLParam} from '_utils';
import {parseSensor, RequestStatus} from 'types';

export const initialState: IInitialMapState = {
  colorSchema: 'singleDate',
  isEditingMode: false,
  layersSource: 'mapbox',
  soilMapLayer: false,
  appProcessingStatus: '',

  sourcesMeta: [],

  wholeFarm: {
    isWholeFarmView: false,
    wholeFarmDates: {},
    fieldsWithDates: {},
    allFarmTSP: [],
    treeZoning: {fields: {}, zones: []},
  },

  cropVarietyColors: {},
  cropVarietyMode: false,

  treeDetection: {
    layerType: 'default',
  },

  highlightedFieldId: undefined,
  selectedFieldId: 0,
  field: {
    MD5: '',
    ID: 0,
    Name: '',
    Area: 0,
  },
  group: {
    id: 0,
    name: '',
    readOnly: false,
  },

  // remote sensing
  remoteSensingImages: [],
  remoteSensingCloudCover: 5, // because the apps (CP and CS) are running with a cloud cover <=5%
  remoteSensingFilterProps: [],
  remoteSensingLayersSelected: {},
  remoteSensingLayersOptions: {
    formatGeotiff: true,
    formatPng: false,
    rangeGrey: true,
    rangeFloat: false,
    all: true,
  },

  imageLayerOpacity: 100,

  edit: false,

  fields: [],
  fieldsByFarmId: {},
  fieldGeometries: {},
  sortFields: {
    byProp: 'Name',
    descending: true,
    sortType: 'string',
  },

  isMapBarOpen: true,

  // toggle buttons on map
  toggledButtonOnMap: 'Empty',
  geometriesOnMap: true,
  visibleFieldKml: false,

  currentDate: getGetURLParam('layerDate'),
  currentDates: {},

  currentSeason: {} as Season,
  currentSeasonId: 0,

  currentSensor: parseSensor(getGetURLParam('layer')) || 'NDVI',
  currentSensors: [],

  currentCompareDate: '',
  currentSensorCompare: 'NDVI',
  currentSensorsCompare: [],

  feature: 'farm',
  isCompareOn: false,
  // zoning
  isZoning: false,
  zoning: {
    isFavoriteMethod: false,
    classes: '4',
    method: 'stddev',
    smoothing: '1',
    points: {},
    isMask: false,
    area: 0,
    // b: 0,
    bufferValue: 0,
    pointsKey: 0,
    isEnableRx: false,
    selectedByUser: false,

    // application info
    application: {
      method: '',
      methodLabel: '',
      timing: '',
      timingLabel: '',
      reiType: '',
      reiValue: '',
      reiTypeLabel: '',
    },

    // from histogram object
    copyZones: [],
    zones: [],
    zonesRange: [],
    // tree zoning
    treeZonesImage: '', // tree analysis zoning image
    treeZones: [],
    treeZoningPercentage: 1, // this value is send as additional param when we use BestPerforming | WorstPerforming, possible values 1 - 99
  },

  // temperature
  temperatureData: [],
  preparedTemperatureData: {},
  // histogram
  histogram: {
    isOn: false,

    categories: [],
    series: [],
    statistics: [],
    png: '',
    bins: 16,
    range: -1,

    categoriesCompare: [],
    seriesCompare: [],
    statisticsCompare: [],
  },

  // TS markers
  featureGroupReDrawId: 'featureGroupReDrawId',
  currentPointId: '',
  currentPoint: {
    id: 'new',
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [],
    },
    properties: {
      title: 'Untitled Point',
      samplingPointType: 'Petiole sample',
      timedate: '',
      n_result: '',
      n_result2: '',
      total_K: '',
      total_P: '',
      Ca: '',
      Mg: '',
      Na: '',
      S: '',
      Zn_ppm: '',
      Mn_ppm: '',
      Fe_ppm: '',
      Cu_ppm: '',
      B_ppm: '',
      Cl: '',
      Mo_ppm: '',
    },
  },

  pointsGroups: {},
  pointsCurrentGroupDate: '',
  currentFieldKml: {},

  currentFieldMean: {
    fieldId: undefined,
    date: undefined,
    sensor: 'NDVI',
    value: undefined,
    status: RequestStatus.Idle,
  },

  nitrogen: {
    scaleUrl: '',
    markers: {},
  },

  isRemoteSensingDialog: false,
  currentRemoteSensingImage: {} as TInfoExt,
  currentIsSetSpectra: [],

  geometry: [],
  editGeometry: {
    state: false,
    geometry: {},
  },

  nitrogenOverlayURL: '',

  drawControl: {
    isDrawingMode: false,
    isEditingMode: false,
    drawingModeLayerType: '',
  },

  // Try to parse number, otherwise use a string (for low perf anomalies).
  openPopupId: +getGetURLParam('anomaly') || getGetURLParam('anomaly') || undefined,

  anomalies: {
    anomaliesSelectedTab: 'areas-of-interest',
    uploadingAOIStatus: 0,
    historyOpen: false,
  },

  // high perform anomalies
  premiumAnomalies: {
    list: [],
    status: RequestStatus.Idle,
  },

  // anomalies
  lowPerfAnomalies: {
    list: {},
    isVisible: false,
    isLoaded: false,
  },

  //nitrogen recommendation
  nRecommendation: {
    method: 'apsim',
    toggled: false,
    zonesWithNData: [],
    nrxResult: {
      blanket: {
        balanced: {} as NRxZonesCollection,
        max_yield: {} as NRxZonesCollection,
        max_roi: {} as NRxZonesCollection,
      },
      variable: {
        balanced: {} as NRxZonesCollection,
        max_yield: {} as NRxZonesCollection,
        max_roi: {} as NRxZonesCollection,
      },
    },
    selectedObjective: 'balanced',
    settingsPopUpOpen: false,
    nrxTabRate: 'variable',
    nrxPopUpValues: {
      ...getDefaultNSettingValues(),
      recommendation_date: '',
    },
  },

  //analytics,
  analytics: {
    showKmlMean: true,
    showSmoothedData: true,
    points: [],
    trendsData: {
      [TrendsIndex.CCCI]: {},
      [TrendsIndex.NDVI]: {},
      [TrendsIndex.NDRE]: {},
    },
    arableData: [],
  },

  infoExt: [],

  //nrx
  listsData: {
    cropTypesList: [],
    prevCropTypesList: [],
    soilTypesList: [],
    soilTypesRawList: [],
    rowSpacingList: [],
    sowingDensityList: [],
    sowingDepthList: [],
    fertilizerTypes: [],
    fertilizerDosesList: [],
    liquidFertilizerDosesList: [],
    silageHayList: [],
    irrigationList: [],
    fieldsNearestSoilTypesList: {},
  },

  locationMarkerPosition: [0, 0],
};

export default initialState;
