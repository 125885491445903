import React from 'react';
import DateAndLayerControls from './date-and-layer-controls';
import SelectorFarms from './selector-farms';
import SelectorField from './selector-fields';
import {useAppSelector} from '_hooks';

export const MobileFarmAndFieldControls = () => {
  const farmId = useAppSelector(s => s.global.currentGroupId);
  const feature = useAppSelector(s => s.map.feature);
  const isCompareOn = useAppSelector(s => s.map.isCompareOn);
  const nRecommendation = useAppSelector(s => s.map.nRecommendation);
  const notInteractive =
    (feature === 'data-layers' && isCompareOn) ||
    (feature === 'zoning' && nRecommendation.toggled && nRecommendation.method === 'apsim');

  return (
    <div className="topbar__controls">
      <SelectorFarms farmId={farmId} />
      {farmId ? <SelectorField /> : null}
      {notInteractive ? null : <DateAndLayerControls />}
    </div>
  );
};
