import {t, FormattedMessage} from 'i18n-utils';
import {FontIcon} from 'react-md';
import styled from 'styled-components';

export const DemoIcon = styled(FontIcon)`
  &.md-icon {
    cursor: pointer;
    position: absolute;
    right: -25px;
    top: 10px;

    @media (max-width: 1040px) {
      font-size: 18px;
      top: 13px;
      right: 25px;
    }

    @media (max-width: 959px) {
      top: 3px;
    }

    @media (max-width: 560px) {
      top: 13px;
    }
  }
`;

export const SelectorFieldWrapper = styled.div`
  position: relative;

  .md-list {
    padding-top: 0; // fix for the mobile view

    .md-list-item:first-child {
      position: sticky;
      top: 0;
      z-index: 9;
      background: #fff;
    }
  }
`;

export const FarmsLoaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > svg {
    margin-right: 15px;
  }

  #farms-circle-loader .md-circular-progress-path {
    stroke: #fff;
  }
`;

type ExternalServiceIconWrapperProps = {
  staticPosition?: boolean;
};

export const ExternalServiceIconWrapper = styled.div`
  ${({staticPosition}: ExternalServiceIconWrapperProps) =>
    staticPosition
      ? ''
      : `position: absolute;
  right: 8px;
  top: 10px;`}

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 19px;
    height: 19px;
  }
`;
