import {ExternalService} from 'containers/map/types';

export type FarmStore = {
  list: Farm[];
  totalCount: number;
  reload: string;
  showInvite: boolean;
  demoFarmsObject: DemoFarm;
  demoFarmsIdsList: number[];
};

export enum FarmActionTypes {
  LOAD_FARMS_LIST = 'group/load-farms-list',
  CREATE_FARM = 'group/create-farm',
  UPDATE_FARM = 'group/update-farm',
  REMOVE_FARM = 'group/remove-farm',
  RELOAD_FARM = 'group/reload-farm',
  SHOW_USER_INVITE = 'group/show-user-invite',
}

export type FarmAction = {
  type: FarmActionTypes;
  farm: Farm;
  farmId: number;
  value: any;
  farms: {
    list: Farm[];
    totalCount: number;
  };
  demoFarms: DemoFarm;
};

export type DemoFarm = {[key: string]: {name: string; icon: string; id: number}};

export interface Farm {
  agx: boolean;
  area: number;
  externalUsers?: any;
  external_service?: ExternalService;
  srcType?: ExternalService;
  id: number;
  name: string;
  readOnly: boolean;
  growerName?: string;
  organizationID: number;
  organizationName: string;
  subscriptionID: number;
  subscriptionName: string;
}
