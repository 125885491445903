import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {Range} from 'rc-slider';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {OPTIS_YEAR_RANGE, setFilterYears} from './optis-reducer';
import {AppStore} from 'reducers';
import './slider-year.scss';

export const SliderYear = () => {
  const dispatch = useDispatch();
  const optis = useSelector((s: AppStore) => s.optis);

  const marks: {[percent: number]: string} = {};
  OPTIS_YEAR_RANGE.forEach((year, i) => {
    const percent = ((i / (OPTIS_YEAR_RANGE.length - 1)) * 100) | 0;
    marks[percent] = String(year);
  });

  const defaultValue = [100, 100];

  const onChange = useCallback(([a, b]: number[]) => {
    const selectedYears = Object.keys(marks)
      .map(Number)
      .filter(percent => percent >= a && percent <= b)
      .map(percent => marks[percent])
      .map(Number);
    dispatch(setFilterYears(selectedYears));
  }, []);

  const value = useMemo(() => {
    const percents = Object.keys(marks)
      .map(Number)
      .filter(
        percent =>
          Number(marks[percent]) === optis.filter.years[0] ||
          Number(marks[percent]) === optis.filter.years[optis.filter.years.length - 1]
      );
    return percents.length === 2 ? percents : [...percents, ...percents];
  }, [optis.filter.years]);

  return (
    <Wrapper>
      <Range
        marks={marks}
        step={null}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        className="slider-year"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px 20px 0;
`;
