import L from 'leaflet';

/*
 * Workaround for 1px lines appearing in some browsers due to fractional transforms
 * and resulting anti-aliasing.
 * https://github.com/Leaflet/Leaflet/issues/3575
 * https://github.com/Leaflet/Leaflet/issues/6101
 *
 * https://flurosat.atlassian.net/browse/FSB-2246
 */
(function() {
  // @ts-ignore
  const originalInitTile = L.GridLayer.prototype._initTile;
  L.GridLayer.include({
    // @ts-ignore
    _initTile: function(tile) {
      originalInitTile.call(this, tile);

      const tileSize = this.getTileSize();

      tile.style.width = tileSize.x + 1 + 'px';
      tile.style.height = tileSize.y + 1 + 'px';
    },
  });
})();
