import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import WelcomeOnboardingDialog from './first-onboarding-dialog';
import FullAppTour from './full-tour';
import QuickAppTour from './quick-tour';
import DemoFarmInfo from './demo-farm-info';

export default function OnBoarding() {
  return (
    <React.Fragment>
      <WelcomeOnboardingDialog />
      <QuickAppTour />
      <FullAppTour />
      <DemoFarmInfo />
    </React.Fragment>
  );
}
