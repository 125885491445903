import {FormattedMessage, t} from 'i18n-utils';
import React, {useState} from 'react';
import IntegrationAgworld from './integration-agworld';
import IntegrationAgx from './integration-agx';
import IntegrationClimate from './integration-climate';
import IntegrationAgrian from './integration-agrian';
import IntegrationEFC from './integration-efc';
import IntegrationJohnDeere from './integration-john-deere';
import OrderPlanetImagery from '../../map/features/data-layers/order-hight-resolution-imagery';
import cn from 'classnames';
import './integration.scss';
import {FluroButton, Ln} from 'components';
import {useAppDispatch, useAppSelector} from '_hooks';
import {Button, FontIcon} from 'react-md';
import {toggleSelectPlatformsView} from './actions';
import {ExternalService} from '../../map/types';
import {IntegrationPlatform} from './types';
import config from '_environment';
import {log} from 'util';

type Props = {
  isPopUpView: boolean;
};

const hiddenIntegrationsForCarbon: ExternalService[] = [
  ExternalService.Agx,
  ExternalService.Agworld,
  ExternalService.Agrian,
];

const ProfileIntegration = ({isPopUpView}: Props) => {
  const dispatch = useAppDispatch();

  const isSelectPlatformsView = useAppSelector(store => store.integrations.isSelectPlatformsView);
  const platforms = useAppSelector(store => store.integrations.platforms);
  const [expandedCard, setExpandedCard] = useState('');
  const isCarbonDomain = config.featurePack === 'carbon';

  const onToggleSelectPlatformsView = (value: boolean) => {
    dispatch(toggleSelectPlatformsView(value));
  };

  const onSaveSelectedPlatforms = () => {
    onToggleSelectPlatformsView(false);
    localStorage.setItem(
      'selectedPlatforms',
      //@ts-ignore
      Object.keys(platforms).filter((p: ExternalService) => platforms[p].selected)
    );
  };

  const onSetExpandedCard = (value: string) => {
    if (value === expandedCard) {
      setExpandedCard('');
    } else {
      setExpandedCard(value);
    }
  };

  const displayPlatform = (platform: IntegrationPlatform, component: JSX.Element) => {
    if (isPopUpView && !isSelectPlatformsView && !platforms[platform].selected) return null;

    if (isCarbonDomain && hiddenIntegrationsForCarbon.includes(platform)) return null; // hide specific platforms for Carbon app

    return component;
  };

  return (
    <div
      className={cn('integration-platforms', {
        'grid-view': isSelectPlatformsView,
        'pop-up': isPopUpView,
      })}
    >
      {!isSelectPlatformsView && !isPopUpView && (
        <h3 className={'admin-and-profile-title'}>{t({id: 'Your integrated platforms'})}</h3>
      )}

      <h3>{t({id: 'Import fields from your farm management system'})}</h3>

      <h5 className={'section-subtitle'}>
        {isSelectPlatformsView ? (
          t({id: 'Select the farm management systems you’d like to connect with:'})
        ) : (
          <FormattedMessage
            id="Login to your account, and select the fields you would like to import. <a>Learn more about integrations.</a>"
            defaultMessage="Login to your account, and select the fields you would like to import. <a>Learn more about integrations.</a>"
            values={{
              a: (txt: string) => (
                <Ln
                  external
                  blank
                  href="https://help.flurosense.com/en/collections/2564248-integrations"
                >
                  {txt}
                </Ln>
              ),
            }}
          />
        )}
      </h5>

      {!isSelectPlatformsView && isPopUpView && (
        <div className={'manage-connection-btn-container'}>
          <FluroButton
            className={'btn-with-icon'}
            blank
            raised
            iconEl={<FontIcon>settings</FontIcon>}
            onClick={() => onToggleSelectPlatformsView(true)}
          >
            {t({id: 'Manage connections'})}
          </FluroButton>
        </div>
      )}

      <OrderPlanetImagery />

      <div className={cn('integration-platforms-container', {grid: isSelectPlatformsView})}>
        {displayPlatform(
          ExternalService.JohnDeere,
          <IntegrationJohnDeere
            expanded={!isSelectPlatformsView}
            setExpanded={() => onSetExpandedCard('john-deere')}
          />
        )}

        {displayPlatform(
          ExternalService.Climate,
          <IntegrationClimate
            expanded={!isSelectPlatformsView}
            setExpanded={() => onSetExpandedCard('climate')}
          />
        )}

        {displayPlatform(
          ExternalService.Agx,
          <IntegrationAgx
            expanded={!isSelectPlatformsView}
            setExpanded={() => onSetExpandedCard('agx')}
          />
        )}
        {displayPlatform(
          ExternalService.Agworld,
          <IntegrationAgworld
            expanded={!isSelectPlatformsView}
            setExpanded={() => onSetExpandedCard('agworld')}
          />
        )}

        {displayPlatform(
          ExternalService.EFC,
          <IntegrationEFC
            expanded={!isSelectPlatformsView}
            setExpanded={() => onSetExpandedCard('efc')}
          />
        )}

        {displayPlatform(
          ExternalService.Agrian,
          <IntegrationAgrian
            expanded={!isSelectPlatformsView}
            setExpanded={() => onSetExpandedCard('agrian')}
          />
        )}
      </div>
      {isSelectPlatformsView && (
        <div className={'save-selection-btn-container'}>
          <Button
            className={'integration-card__authorize-button'}
            raised
            primary
            onClick={onSaveSelectedPlatforms}
          >
            {t({id: 'Save'})}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProfileIntegration;
