import {t, FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import {useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import _config from '_environment';
import {showNote} from '_actions';
import {reportError} from 'containers/error-boundary';

/**
 * Every 30 minutes fetches an app-config.js
 * to check if there is a new version released.
 */
export default () => {
  const dispatch = useDispatch();
  const checkUpdate = useCallback(async () => {
    try {
      // Prevent caching by adding timestamp get parameter.
      const resp = await fetch(`/assets/app-config.js?t=${+new Date()}`);
      const text = await resp.text();
      // The app-config.js looks like:
      // window.FLUROSENSE_CONFIG = { app_version: ..., };
      const appVersionRegexp = /app_version: '(.*)'/;
      const [, appVersion] = appVersionRegexp.exec(text);
      if (_config.app_version !== appVersion) {
        dispatch(
          showNote({
            title: '🚀Update',
            children: (
              <FormattedMessage
                id="A new version of FluroSense has been released"
                defaultMessage="A new version of FluroSense has been released. <a>Reload the page</a> to get the best of FluroSense and to make sure everything works as expected."
                values={{
                  a: (txt: string) => (
                    <a href="" onClick={() => localStorage.setItem('reloadPage', 'true')}>
                      {txt}
                    </a>
                  ),
                }}
              />
            ),
            level: 'info',
            autoDismiss: 0,
          })
        );
      }
    } catch (e) {
      reportError('Could not fetch or parse new config:' + e);
    }
  }, []);
  useEffect(() => {
    // @ts-ignore Add check update function to the global space, so it can be easily tested.
    window.checkUpdate = checkUpdate;
    // Check for an update every half an hour.
    setInterval(checkUpdate, 60 * 1000 * 30);
    // Reload all the other tabs with flurosat when a user choses to reload one (FSB-3075).
    window.addEventListener('storage', ({key, newValue}) => {
      if (key === 'reloadPage' && newValue != null) {
        window.location.reload(true);
        localStorage.removeItem('reloadPage');
      }
    });
  }, []);
};
