import {FormattedMessage} from 'i18n-utils';
import React, {useState, useEffect, useMemo} from 'react';
import InviteUsers from '../../../invite-users';
import {useDispatch} from 'react-redux';
import ReadOnly from 'components/read-only-container';
import {isAgxField, isAdminPerm} from '_utils';
import {Button, FontIcon} from 'react-md';

import Fields from './field/';

import {DialogType, toggleDialog as toggleGlobalDialog} from '_reducers/dialog';

import FarmInfo from 'containers/farm/farm-info';

import './index.scss';
import 'containers/farm/index.scss';
import FieldsInfoLine from './fields-info-line';
import {
  AsyncStatusType,
  dialogToggle,
  DialogType as UIDialogType,
  Status,
} from '../../../../modules/ui-helpers';
import {AddFieldsStep} from './new-fields/steps';
import {addFieldChangeCurrentStep} from '../../../../modules/add-fields.module';
import {useAppSelector} from '_hooks';

export const MapFarm = () => {
  const dispatch = useDispatch();
  const agxSync = useAppSelector(s => s.login.user.agxSync);
  const farms = useAppSelector(s => s.farms.list);
  const fields = useAppSelector(s => s.map.fields);
  const field = useAppSelector(s => s.map.field);
  const isAdmin = useAppSelector(s => isAdminPerm(s.login.user.perm));
  const isReadOnly = useAppSelector(s => s.map.group.readOnly);
  const currentFarmId = useAppSelector(s => s.global.currentGroupId);
  const isOnBoardingNow = useAppSelector(s => s.login.user.settings.onboarding.fullTour);
  const isWholeFarmView = useAppSelector(s => s.map.wholeFarm.isWholeFarmView);
  const asyncStatuses = useAppSelector(s => s.uiHelpers.asyncStatuses);
  const hasFarms = isAdmin || useAppSelector(s => s.farms.list.length > 0);

  const currentFarm = useMemo(() => farms.find(f => f.id === currentFarmId), [
    farms,
    currentFarmId,
  ]);
  const isFarmsLoading = useMemo(
    () => asyncStatuses[AsyncStatusType.farmsList].status === Status.Pending,
    [asyncStatuses[AsyncStatusType.farmsList].status]
  );

  const [isInvite, setIsInvite] = useState(false);
  const [filteredFieldIds, setFilteredFieldIds] = useState<number[]>([]);
  useEffect(() => {
    setFilteredFieldIds([]);
  }, [currentFarmId]);

  const filteredFields = fields.filter(f => !filteredFieldIds.includes(f.ID));

  const agxFieldsExist = isWholeFarmView
    ? fields.find(f => isAgxField(f)) && (isAdmin || agxSync)
    : isAgxField(field) && (isAdmin || agxSync);

  if (!hasFarms && !isFarmsLoading) {
    return (
      <div className="section-container">
        <div className="no-farm-container">
          <h3>
            <FormattedMessage id="no-farms.welcome" defaultMessage="Welcome" />
          </h3>

          <div className="welcome-description">
            <FormattedMessage
              id="no-farms-lets-start"
              defaultMessage="Let's start by selecting your fields."
            />
          </div>
          <div className="prefer-options-label">
            <FormattedMessage
              id="no-farms-upload-format-label"
              defaultMessage="Select the import format you prefer:"
            />
          </div>

          <AddFieldsStep changeCurrentStep={value => dispatch(addFieldChangeCurrentStep(value))} />
        </div>
      </div>
    );
  }

  return (
    <div className="section-container">
      <h3 className="tab-title">
        <FormattedMessage id="farm.title" defaultMessage="Farm set-up" />
      </h3>

      <InviteUsers
        isVisible={isInvite}
        groupId={currentFarmId}
        toggleInviteUser={() => setIsInvite(v => !v)}
      />

      {currentFarmId ? (
        <div className={'element-full-width'}>
          <FarmInfo
            isReadOnly={isReadOnly}
            isAgxUser={agxSync}
            isAgxField={isAgxField(field)}
            currentFarm={currentFarm}
            onEditFarm={() => dispatch(dialogToggle(UIDialogType.editFarm, true, currentFarm))}
            onAddInvite={() => setIsInvite(v => !v)} // TODO: tmp prop
          />

          <div className="farm-info">
            <FieldsInfoLine filteredIds={filteredFieldIds} />
          </div>

          <div className={'farm-buttons-container'}>
            <ReadOnly isReadOnly={isReadOnly && !isOnBoardingNow}>
              <Button
                id={'add-field'}
                className={'add-button on-boarding-add-fields'} // add-button - global class for buttons with + icon
                onClick={() => dispatch(toggleGlobalDialog(DialogType.AddNewField, true))}
                raised
                primary={!agxFieldsExist}
                iconEl={<FontIcon>add</FontIcon>}
              >
                <FormattedMessage id="farm.btn.addFields" defaultMessage="Add fields" />
              </Button>
            </ReadOnly>

            <Button
              id={'add-farm-btn'}
              className="add-button on-boarding-add-farm" // add-button - global class for buttons with + icon
              onClick={() => dispatch(dialogToggle(UIDialogType.editFarm, true, {id: 0, name: ''}))}
              raised
              iconEl={<FontIcon>add</FontIcon>}
            >
              <FormattedMessage id="farm.btn.addFarm" defaultMessage="Add Farm" />
            </Button>
          </div>

          <Fields setFilteredFields={setFilteredFieldIds} filteredFieldIds={filteredFields} />
        </div>
      ) : null}
    </div>
  );
};

export default MapFarm;
