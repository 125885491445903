import initialState from '../initial-state';
import {IAction} from '../../types';
import {ActionTypes} from '../types';
import {IAnomaly} from '../../features/anomalies/types';

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.MAP_AOI_ADD: {
      return {
        ...state,
        geometry: [...state.geometry, action.shape],
      };
    }

    case ActionTypes.MAP_AOI_REMOVE:
      return {
        ...state,
        geometry: state.geometry.filter(g => g.properties.id !== action.id),
      };

    case ActionTypes.MAP_AOI_UPDATE:
      return {
        ...state,
        geometry: state.geometry.map(g => (g.properties.id === action.id ? action.geometry : g)),
      };

    case ActionTypes.MAP_AOI_EDIT_TOGGLE:
      return {
        ...state,
        editGeometry: {
          state: action.state,
          geometry: action.geometry || state.editGeometry.geometry,
        },
      };

    case ActionTypes.MAP_AOI_CHANGE_PROP:
      const {prop, value, geometry} = action.data;
      const {id: currentId} = geometry.properties;

      return {
        ...state,
        geometry: state.geometry.map(g => {
          if (g.properties.id === currentId) {
            g.properties[prop] = value;
            return g;
          }
          return g;
        }),
      };

    case ActionTypes.MAP_AOIs_CHANGE_PROP: {
      return {
        ...state,
        geometry: state.geometry.map(g => {
          if (
            action.geometries.find(
              (updatedG: IAnomaly) => updatedG.properties.id === g.properties.id
            )
          ) {
            return {
              ...g,
              properties: {
                ...g.properties,
                [action.prop]: action.value,
              },
            };
          }
          return g;
        }),
      };
    }

    case ActionTypes.MAP_AOI_UPDATE_MEAN_INDEX:
      const updatedGeometry = state.geometry.map(geometry => {
        const id = geometry?.properties?.id;
        const means = action.data?.Means;
        if (id && means && means[id]) {
          geometry.properties.mean = means[id];
        }
        return geometry;
      });

      return {
        ...state,
        geometry: updatedGeometry,
      };
    default:
      return state;
  }
};
