import React from 'react';
import {Button} from 'react-md';
import {t} from 'i18n-utils';
import styled from 'styled-components';

const UserGuideBtn = styled(Button)`
  position: fixed;
  bottom: 65px;
  right: 8px;
  display: flex;
  align-items: center;
`;

export const CarbonUserGuideButton = () => {
  return (
    <UserGuideBtn
      onClick={() => {
        window.open('/assets/carbon/carbon_user_guide.pdf');
      }}
      primary
      swapTheming
      icon
      iconEl={<img src="/assets/icons/user_guide.svg" alt="user guide icon" />}
      tooltipLabel={t({id: 'User Guide'})}
      tooltipPosition="left"
    />
  );
};
