import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, SelectField} from 'react-md';
import MultiKeysPressed from 'components/key-handler';
import {createDatesMenuList} from 'containers/map/utils';
import {setDate} from 'containers/map/actions';
import {BrowserView} from 'react-device-detect';
import Mixpanel from '_utils/mixpanel-utils';
import {AppStore} from 'reducers';

const SelectorDate = () => {
  const feature = useSelector((state: AppStore) => state.map.feature);
  const currentDates = useSelector((state: AppStore) => state.map.currentDates);
  const currentDate = useSelector((state: AppStore) => state.map.currentDate);
  const isWholeFarmView = useSelector((state: AppStore) => state.map.wholeFarm.isWholeFarmView);

  const dispatch = useDispatch();

  const getDateItems = () => {
    return createDatesMenuList(currentDates, false, isWholeFarmView);
  };

  const onDateChanged = (date: string) => {
    dispatch(setDate(date));
    Mixpanel.dateChange(date);
  };

  const dateMenuItems = getDateItems();

  const getDirectionDate = (direction: string) => {
    const dates = Object.keys(currentDates);
    const indexCurrentDate = dates.findIndex(date => date === currentDate);
    const nextDate = dates[indexCurrentDate - 1] || currentDate;
    const prevDate = dates[indexCurrentDate + 1] || currentDate;

    if (direction === 'next') return nextDate;
    if (direction === 'prev') return prevDate;
  };

  const isDisabledArrow = useMemo(
    () => ({
      next: getDirectionDate('next') === currentDate,
      prev: getDirectionDate('prev') === currentDate,
    }),
    [currentDate, currentDates]
  );

  const selectNextDate = () => onDateChanged(getDirectionDate('next'));
  const selectPrevDate = () => onDateChanged(getDirectionDate('prev'));

  return (
    <div className={'selector-date-container'}>
      <Button
        className={'select-date-btn prev'}
        disabled={isDisabledArrow.prev}
        icon
        onClick={selectPrevDate}
      >
        keyboard_arrow_left
      </Button>
      <SelectField
        id="select-field-date"
        placeholder="Select Date"
        className={'select-observ-date onboarding__selector-dates'}
        menuItems={dateMenuItems}
        onChange={onDateChanged}
        value={currentDate}
        simplifiedMenu={false}
        anchor={{
          x: SelectField.HorizontalAnchors.INNER_LEFT,
          y: SelectField.VerticalAnchors.BOTTOM,
        }}
      />
      <Button
        className={'select-date-btn next'}
        disabled={isDisabledArrow.next}
        icon
        onClick={selectNextDate}
      >
        keyboard_arrow_right
      </Button>

      <BrowserView>
        {feature === 'data-layers' ? null : (
          <>
            <MultiKeysPressed callback={selectNextDate} keys={['Shift', 'ArrowRight']} />
            <MultiKeysPressed callback={selectPrevDate} keys={['Shift', 'ArrowLeft']} />
          </>
        )}
      </BrowserView>
    </div>
  );
};

export default SelectorDate;
