import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {Chip} from 'react-md';

type State = {label: string; value: number | string};
type Props = {
  state: State;
  onClick(val: State): void;
};

export default class StateChip extends PureComponent<Props> {
  handleRemove = () => {
    this.props.onClick(this.props.state);
  };

  render() {
    const {
      state: {label},
      ...props
    } = this.props;
    return <Chip {...props} onClick={this.handleRemove} removable label={label} />;
  }
}
