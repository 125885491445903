import {t, FormattedMessage} from 'i18n-utils';
import React, {useState} from 'react';
import {FluroDialog, InfoBlock, UploadFileZone} from 'components';
import {Button, DialogContainerProps} from 'react-md';
import {startDrawShape} from '../map/actions';
import {useDispatch} from 'react-redux';
import {uploadAnomalies} from '../map/actions/anomalies-actions';

const AddNewAnomalies = ({onHide}: Partial<DialogContainerProps>) => {
  const [isUpload, setUpload] = useState(false);
  const dispatch = useDispatch();
  const onDrawNew = () => {
    onHide();
    dispatch(startDrawShape());
  };
  const onUpload = (files: File[]) => {
    dispatch(uploadAnomalies(files));
  };
  return (
    <FluroDialog
      id={'add-new-anomalies'}
      title={'Add a new shape'}
      visible={true}
      onHide={onHide}
      className={'add-new-anomalies'}
    >
      {isUpload ? (
        <div className="upload-new-anomalies-container">
          <UploadFileZone
            id={'map-upload-kml'}
            accept={'.kml,.kmz,.zip'}
            multiple
            onChange={onUpload}
          />
          <InfoBlock className={'inside-a-pop-up'}>
            <div>You can upload .kml, .kmz or .shp files.</div>
            <div>
              If are uploading .shp files, zip archive them before sending and make sure the archive
              contains the following files extensions: .shp, .shx and .dbf.
            </div>
          </InfoBlock>
          <Button className={'back-button'} raised onClick={() => setUpload(false)}>
            Back
          </Button>
        </div>
      ) : (
        <div className="add-new-anomalies-container">
          <Button
            id={'draw-new-geometry-btn'}
            primary
            raised
            iconBefore={false}
            onClick={onDrawNew}
          >
            Draw a new shape
          </Button>
          <div className={'buttons-delimiter'}> Or </div>
          <Button
            id={'upload-new-geometry-btn'}
            raised
            iconBefore={false}
            onClick={() => setUpload(true)}
          >
            Upload a shape
          </Button>
        </div>
      )}
    </FluroDialog>
  );
};

export default AddNewAnomalies;
