/* eslint-disable max-len */
import React from 'react';
import {SVGIcon} from 'react-md';

export const FarmerIcon = props => (
  <SVGIcon {...props}>
    <path d="M11.1045,15.0054 C10.8245,14.9764 10.5425,15.0674 10.3315,15.2564 C10.1205,15.4464 10.0005,15.7164 10.0005,16.0004 L10.0005,17.0004 C10.0005,17.1024 9.9715,18.0004 9.0005,18.0004 C8.0285,18.0004 8.0005,17.1024 8.0005,17.0004 L8.0005,16.0004 C8.0005,15.7164 7.8795,15.4464 7.6685,15.2564 C7.4585,15.0674 7.1755,14.9764 6.8955,15.0054 C2.9005,15.4244 0.0005,18.7874 0.0005,23.0004 C0.0005,23.5524 0.4475,24.0004 1.0005,24.0004 L17.0005,24.0004 C17.5525,24.0004 18.0005,23.5524 18.0005,23.0004 C18.0005,18.7874 15.0995,15.4244 11.1045,15.0054"></path>
    <path d="M1,7 L4.011,7 C4.01,7.046 4,7.091 4,7.138 L4,10.172 C4,12.815 6.243,14.965 9,14.965 C11.757,14.965 14,12.815 14,10.172 L14,7.138 C14,7.091 13.99,7.046 13.989,7 L17,7 C17.454,7 17.851,6.694 17.967,6.255 C18.083,5.816 17.889,5.354 17.493,5.13 L15.931,4.244 C14.36,3.353 13.405,2.506 12.019,1.218 C11.186,0.444 9.985,0 8.725,0 C7.463,0 6.263,0.444 5.43,1.218 C4.953,1.661 4.578,2.061 4.229,2.433 C3.551,3.157 3.061,3.681 2.069,4.244 L0.507,5.13 C0.111,5.354 -0.083,5.816 0.033,6.255 C0.149,6.694 0.546,7 1,7"></path>
    <path d="M23,9 C22.447,9 22,9.448 22,10 L22,13 L21,13 L21,10 C21,9.448 20.553,9 20,9 C19.447,9 19,9.448 19,10 L19,13 L18,13 L18,10 C18,9.448 17.553,9 17,9 C16.447,9 16,9.448 16,10 L16,14 C16,14.552 16.447,15 17,15 L19,15 L19,23 C19,23.552 19.447,24 20,24 C20.553,24 21,23.552 21,23 L21,15 L23,15 C23.553,15 24,14.552 24,14 L24,10 C24,9.448 23.553,9 23,9"></path>
  </SVGIcon>
);

export const ExpandedTailIcon = props => (
  <SVGIcon {...props} size={16} viewBox="0 0 16 4">
    <line y1="0.5" x2="16" y2="0.5" stroke="#BCE0BD"/>
    <line y1="3.5" x2="16" y2="3.5" stroke="#BCE0BD"/>
  </SVGIcon>
);
