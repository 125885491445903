import {t, FormattedMessage} from 'i18n-utils';
import FluroDatePicker from 'components/fluro-date-picker';
import moment from 'moment';
import {Button} from 'react-md';
import FluroDialog from 'components/fluro-dialog';
import React, {useEffect, useState} from 'react';
import {GLOBAL_FORMAT_DATE} from '_constants';

type Props = {
  groupDate: string;
  visible: boolean;
  onHide(): void;
  updatePointsGroupDate(val: string): void;
};

const EditGroupPopUp = ({groupDate, onHide, visible, updatePointsGroupDate}: Props) => {
  const [updatedDate, updateDate] = useState('');

  useEffect(() => {
    const validGroupDate = moment(groupDate, GLOBAL_FORMAT_DATE).isValid()
      ? groupDate
      : moment().format(GLOBAL_FORMAT_DATE);

    updateDate(visible ? validGroupDate : '');
  }, [visible]);

  return (
    <FluroDialog
      title={t({id: 'Edit sampling date'})}
      onHide={onHide}
      visible={visible}
      id={'edit-group-date'}
      isDraggable
      width={'auto'}
      portal
    >
      <div className="add-new-group-container">
        <FluroDatePicker
          id={`group-date`}
          label={t({id: 'Date'})}
          selected={moment(updatedDate)}
          onChange={(val: any) => updateDate(val.format(GLOBAL_FORMAT_DATE))}
        />
        <div className={'buttons-wrapper'}>
          <Button
            id="update-group-date-btn"
            primary
            raised
            onClick={() => updatePointsGroupDate(updatedDate)}
            className="element-full-width"
          >
            {t({id: 'Save group'})}
          </Button>
        </div>
      </div>
    </FluroDialog>
  );
};

export default EditGroupPopUp;
