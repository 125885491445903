import {Notification} from 'react-notification-system';

// Wrap library's notification into our own Note type,
// so when we'll decide to change the library, we'll be able to easily do that.
export type Note = Notification;

export type AlertState = Note[];

const initialState: AlertState = [];

export default (state = initialState, action: Action): AlertState => {
  switch (action.type) {
    case ActionType.SHOW_NOTE:
      return [
        {
          ...action.note,
          position: action.note.position || 'br',
        },
      ];

    default:
      return state;
  }
};

// ACTIONS.

type Action = ShowNote;

export type ShowNote = {
  type: ActionType.SHOW_NOTE;
  note: Note;
};

export enum ActionType {
  SHOW_NOTE = 'alert/add-alert',
}
