import Server from './server';
import service from './service';
import axios, {AxiosPromise} from 'axios';
import {Farm, Field} from 'containers/map/types';
import cancelTokenStore from './cancel-tokens-store';

type FileListResponse = {
  status: string; // 'ok' if all good
  result: {
    group: Farm;
    fields: Field[];
  };
};
export default class {
  static getList(page: number, perPage: number) {
    return Server.get('dashboard', {params: {page, per_page: perPage}});
  }

  static getFileList(
    groupId: number,
    getAllLayers: boolean = false
  ): AxiosPromise<FileListResponse> {
    return Server.get(`kml/${groupId}${getAllLayers ? '?all=1' : ''}`, {
      params: {__skipPreloader: true},
    });
  }

  static getFileListByIDs(
    groupId: number,
    ids: number[] = [],
    skipPreloader = false
  ): AxiosPromise<FileListResponse> {
    const payload = `all=1${ids.length ? `&ids=` + ids.join(',') : ''}`;
    return Server.post(`kml/${groupId}`, payload, {params: {__skipPreloader: skipPreloader}});
  }

  static getKmlOne(groupId: number, kmlId: number) {
    return Server.get(`kml/${groupId}/${kmlId}`);
  }

  static uploadForm(formData: any) {
    return Server.post(`uploadForm`, formData);
  }

  static saveData(groupId: number, kmlId: number, fieldProps: any) {
    return Server.put(`kml/${groupId}/${kmlId}`, fieldProps);
  }
  static saveFileData(data: any, groupId: number, kmlId: number) {
    return Server.put(`files/kml/${groupId}/${kmlId}`, data);
  }

  static saveGrower(grower: string, groupId: number, kmlId: number) {
    return Server.post(`grower/${groupId}/${kmlId}`, {name: grower});
  }

  // deprecated now the regions are calculated automatically
  // static saveRegion(region: string, groupId: number, kmlId: number) {
  //   return Server.post(`region/${groupId}/${kmlId}`, {name: region});
  // }

  static generateKmlTilesForDates(groupId: number, kmlId: number, dates: string[]) {
    return Server.post(`kml/satellite/${groupId}/${kmlId}/generate`, dates);
  }

  static hideTile(groupId: number, kmlId: number, params: any) {
    return Server.post(
      `/kml/${groupId}/${kmlId}/files?date=${params.date}&flag=${params.flag}&kmlid=${params.kmlid}`,
      params
    );
    // return Server.put(`/kml/satellite/${groupId}/visible`, params);
  }

  static dropField(groupId: number, kmlId: number) {
    return Server.delete(`/kml/${groupId}/${kmlId}`);
  }

  static clonefields(
    fieldIds: {id: number; name: string}[],
    groupIds: number[],
    currentGroupID: number
  ) {
    return Server.post(`copy_field_to_group/${currentGroupID}`, {
      fieldIds,
      groupIds,
    });
  }

  static aerialsList(groupId: number, kmlId: number) {
    return Server.get(`kml/${groupId}/${kmlId}/aerials`);
  }

  static loadFieldData(groupId: number, kmlId: number, isAdmin: boolean = false) {
    return Server.get(`json${isAdmin ? '/admin' : ''}/maps/${groupId}/${kmlId}`);
  }

  static loadHistogram(png: string, bins = 4) {
    return Server.get(`histogram`, {params: {png, bins}});
  }

  static updateNitrogenDates(groupId: number, fieldId: number | string, data: any) {
    return Server.post(`/kml/${groupId}/${fieldId}/properties`, data);
  }

  static retrieveCLUFieldsBoundaries(lat: number, lon: number) {
    cancelTokenStore.cancel('loadCLUBoundaries');
    const source = cancelTokenStore.generateSource('loadCLUBoundaries');

    return Server.get(`clu`, {
      cancelToken: source.token,
      params: {lat, lon, __skipPreloader: true},
    });
  }

  static loadFieldSoilMap(md5s: string[]) {
    cancelTokenStore.cancel('loadFieldSoilMap');
    const source = cancelTokenStore.generateSource('loadFieldSoilMap');

    return service.post(
      `ssurgo/field_data_ui`,
      {
        md5s,
      },
      {cancelToken: source.token, params: {__skipPreloader: true}}
    );
  }
}
