import * as React from 'react';
import styled from 'styled-components';

export const OptisChartDescription = ({
  verb,
  categories,
  timePeriod,
}: {
  verb: string;
  categories: string;
  timePeriod: string;
}) => {
  return (
    <Wrap>
      Fields {verb} {categories} {timePeriod}
    </Wrap>
  );
};

export const OptisDiffModeDescription = ({
  categories,
  timePeriod,
}: {
  categories: string;
  timePeriod: string;
}) => {
  return (
    <Wrap>
      {categories} adoption change {timePeriod}
    </Wrap>
  );
};

const Wrap = styled.h4`
  margin-bottom: 8px;
  text-align: center;
`;
