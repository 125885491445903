const SET_ID = 'help-popup/set-id';

const initialState = {
  currentPopUpId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ID:
      return {
        currentPopUpId: action.id,
      };

    default:
      return state;
  }
};

export const setId = id => ({
  type: SET_ID,
  id,
});
