import {t} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import FluroAutocomplete from 'components/autocomplete';
import {escapeRegExp, isAdminPerm, setGetParamToURL} from '_utils';
import {selectFarm} from 'containers/farm/actions';
import {cancelDrawingPivotCenter} from 'containers/map/actions';
import {Farm, FarmStore} from 'containers/farm/types';
import get from 'lodash.get';
import {GlobalState} from '_reducers/global_types';
import {LoginState} from 'containers/login/types';
import {ActionTypes} from 'containers/map/reducer/types';
import {toggleGlobalDialog} from '_actions';
import cn from 'classnames';
import {UIHelpersStore, Status, dialogToggle} from 'modules/ui-helpers';
import {CircularProgress, FontIcon, Button} from 'react-md';
import {FarmsLoaderWrapper} from './header.styled';
import ExternalServiceIcon from './external-service-icon';

const NO_GROWER = ' __grower_less__';

type DataType = Array<
  React.ReactElement<any> | string | number | {[dataLabel: string]: string | number}
>;

type Props = ConnectedProps<typeof connected> & {
  farmId: number;
};

type TState = {
  farmId: number;
  growerName: string;
  focused: boolean;
  currentFarm: Partial<Farm>;
  farms: Array<Farm>;
  filteredFarms: Array<any>;
  demoFarms: number[];
};

class SelectorFarms extends PureComponent<Props, TState> {
  state: TState = {
    farmId: 0,
    filteredFarms: [],
    growerName: '',
    farms: this.props.farms,
    focused: false, // to hide grower name
    demoFarms: [],
    currentFarm: {
      name: '',
      agx: false,
      area: 0,
      id: 0,
      readOnly: false,
    },
  };

  componentDidUpdate(prevProps: Props) {
    const {farmId, user, farms} = this.props;
    const currentFarm = farms.find(g => g.id === farmId);
    if (
      farmId &&
      (farmId !== prevProps.farmId ||
        user.groupIds !== prevProps.user.groupIds ||
        farms.length !== prevProps.farms.length ||
        (currentFarm && currentFarm.name !== this.state.currentFarm.name))
    ) {
      this.setState({
        farmId,
        filteredFarms: [...this.buildList(farms, farmId)],
        currentFarm: {...currentFarm},
        growerName: get(currentFarm, 'growerName', ''),
      });
    }
    if (currentFarm && currentFarm.growerName !== this.state.growerName) {
      this.setState({growerName: currentFarm.growerName || ''});
    }
  }

  componentDidMount() {
    const {farmId, farms} = this.props;
    const currentFarm = farms.find(g => g.id === farmId);
    this.setState({
      farmId,
      filteredFarms: [...this.buildList(farms, farmId)],
      currentFarm: {...currentFarm},
      growerName: get(currentFarm, 'growerName', ''),
    });
  }

  manageDemoFarms = () => {
    this.props.toggleGlobalDialog('demoFarms', {visible: true});
    this.closeAutocompleteMenu();
  };

  closeAutocompleteMenu = () => {
    setTimeout(() => document.querySelector('body').click(), 100);
  };

  buildList = (farms: Array<Farm>, currentFarmID: number) => {
    const {demoFarmsObject, demoFarmsIdsList, user, isAdmin} = this.props;
    const demoFarmsCount = this.props.farms.filter(f => demoFarmsIdsList.includes(f.id)).length;
    const _growersObject: any = {};
    const listOfFarms: Array<any> = [];
    let {regularFarms, demoFarms} = farms.reduce(
      ({regularFarms, demoFarms}, farm) =>
        demoFarmsIdsList.includes(farm.id) // separate demo farms from other
          ? user.groupIds.includes(farm.id) // return only farms user have access even for admins
            ? {regularFarms, demoFarms: [...demoFarms, farm]}
            : {regularFarms, demoFarms}
          : {regularFarms: [...regularFarms, farm], demoFarms},
      {regularFarms: [], demoFarms: []}
    );

    regularFarms.forEach(g => {
      const growerName = g.growerName ? g.growerName.trim() : NO_GROWER;
      if (_growersObject[growerName]) {
        _growersObject[growerName].push({...g});
      } else {
        _growersObject[growerName] = [{...g}];
      }
    });

    // sort growers
    const sortedGrowers = Object.keys(_growersObject).sort((a, b) => {
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }

      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }

      return 0;
    });

    sortedGrowers.forEach(growerName => {
      if (growerName !== NO_GROWER) {
        listOfFarms.push(
          <div key={growerName} className="grower-name-item">
            {growerName}
          </div>
        );
      }

      SelectorFarms.getSortedFarms(_growersObject[growerName]).forEach((g, i) => {
        listOfFarms.push({
          label: [
            <div
              key={`${i}-one-${growerName}-div`}
              className={`${g.id === currentFarmID ? 'active-farm' : ''}`}
            >
              <div className="farm-name-item">{g.name}</div>
              {g.external_service && isAdmin ? (
                <ExternalServiceIcon value={g.external_service} />
              ) : null}
            </div>,
          ],
          value: g.id,
          key: `${i}-one-${growerName}`,
        });
      });
    });
    if (user.settings.showDemoFarms) {
      listOfFarms.push(
        <div key={'manage-demo-farm'} className={'manage-demo-farms'}>
          <div className="manage-demo-farms__btn">
            <FontIcon>star</FontIcon>
            <span>DEMO FARMS</span>
            <Button raised onClick={this.manageDemoFarms}>
              + {demoFarmsCount ? 'Manage' : 'Add'}
            </Button>
          </div>
          {demoFarms.map(f => (
            <div
              key={`demo-farm-item-${f.id}`}
              onClick={this.selectFarm.bind(this, f.id, true)}
              className={cn('md-list-tile md-text demo-farm-item', {
                'active-farm': currentFarmID === f.id,
              })}
            >
              <div className="farm-name-item">{demoFarmsObject[f.id]?.name}</div>
            </div>
          ))}
        </div>
      );
    }

    return listOfFarms;
  };

  selectFarm = (farmId: number, closeMenu?: boolean) => {
    setGetParamToURL('filterSources', null);
    this.props.resetSensitiveParams();
    this.props.selectFarm(farmId);
    this.props.cancelDrawingPivotCenter();
    if (closeMenu) {
      this.closeAutocompleteMenu();
    }
  };

  static getSortedFarms = (farms: Array<any>) => {
    return farms.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  };

  onAutocompleteFarm = (suggestion: number, suggestionIndex: number, matches: DataType) => {
    if (matches[suggestionIndex]) {
      this.selectFarm(suggestion);
    }
  };

  onFilter = (value: string) => {
    const r = new RegExp(escapeRegExp(value), 'i');
    return this.buildList(
      this.props.farms.reduce((matches, g) => {
        if (
          r.test(g.name) ||
          r.test(g.growerName || '') ||
          (r.test('Demo Farms') && this.props.demoFarmsIdsList.includes(g.id)) // show demo farms by "Demo farms" search request
        ) {
          matches.push(g);
        }

        return matches;
      }, []),
      this.state.farmId
    );
  };

  toggleFocus = (focused: boolean) => {
    this.setState({focused});
  };

  render() {
    const {currentFarm, growerName, filteredFarms, focused} = this.state;
    const {farmsLoadingStatus, isAdmin, user, farms} = this.props;
    const hasFarms = isAdmin || farms.length > 0 || user.groupIds.length > 0;

    if (!farmsLoadingStatus || farmsLoadingStatus === Status.Pending) {
      return (
        <FarmsLoaderWrapper id={'map__select-farm'} className="suggest-wrapper">
          <CircularProgress centered={false} id="farms-circle-loader" />
          <div>Farms loading...</div>
        </FarmsLoaderWrapper>
      );
    }

    if (!hasFarms) {
      return null;
      //
      // Add farm functionality
      //
      // return (
      //   <div className="suggest-wrapper">
      //     <Button
      //       className="add-button on-boarding-add-farm" // add-button - global class for buttons with + icon
      //       onClick={() => this.props.dialogToggle(DialogType.editFarm, true, {id: 0, name: ''})}
      //       raised
      //       iconEl={<FontIcon>add</FontIcon>}
      //     >
      //       <FormattedMessage id="farm.btn.addFarm" defaultMessage="Add Farm" />
      //     </Button>
      //   </div>
      // );
    }

    return (
      <div className="suggest-wrapper">
        {!focused && growerName ? (
          <span className="grower-name">{growerName.toUpperCase()}</span>
        ) : null}
        <FluroAutocomplete
          id={'map__select-farm'}
          title={currentFarm?.name || ''}
          placeholder={t({id: 'Farms'})}
          onFilter={this.onFilter}
          onBlur={() => this.toggleFocus(false)}
          onFocus={() => this.toggleFocus(true)}
          onAutocomplete={this.onAutocompleteFarm}
          className="farm-toolbar__select-farm"
          inputClassName={'select-farm-input onboarding__select-farm'}
          value={currentFarm?.name || ''}
          menuItems={filteredFarms}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  login,
  farms,
  global,
  uiHelpers,
}: {
  login: LoginState;
  farms: FarmStore;
  global: GlobalState;
  uiHelpers: UIHelpersStore;
}) => ({
  user: login.user,
  farms: farms.list,
  demoFarmsObject: farms.demoFarmsObject,
  demoFarmsIdsList: farms.demoFarmsIdsList,
  growerName: global.growerName,
  farmsLoadingStatus: uiHelpers.asyncStatuses.farms.status,
  isAdmin: isAdminPerm(login.user.perm),
});

const connected = connect(mapStateToProps, {
  selectFarm,
  cancelDrawingPivotCenter,
  toggleGlobalDialog,
  dialogToggle,
  resetSensitiveParams: () => ({type: ActionTypes.RESET_FARM_SENSITIVE_PARAMS}),
});
export default connected(SelectorFarms);
