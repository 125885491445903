import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo, memo, useCallback} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Dialog from 'components/fluro-dialog';
import {dialogToggle, UIHelpersStore} from 'modules/ui-helpers';
import {genKey} from '_utils';
import {toggleGlobalDialog} from '_actions';
import {setFeature, setCurrentFieldId, toggleTableView} from 'containers/map/actions';
import {IInitialMapState} from 'containers/map/types';
import {FontIcon, Button} from 'react-md';
import InfoBlock from 'components/info-block';

/*
 *
 * Crop status alert Dialog
 *
 * */
const StatusAlert = memo(() => {
  const dispatch = useDispatch();
  const {visible, message, fieldId, seasonId, selectedFieldId} = useSelector(
    ({map, uiHelpers}: {map: IInitialMapState; uiHelpers: UIHelpersStore}) => ({
      visible: uiHelpers.dialogs.statusAlert.visible,
      message: uiHelpers.dialogs.statusAlert.payload?.message || '',
      fieldId: uiHelpers.dialogs.statusAlert.payload?.fieldId,
      seasonId: uiHelpers.dialogs.statusAlert.payload?.seasonId,
      selectedFieldId: map.field.ID,
    }),
    shallowEqual
  );

  const id = useMemo(() => genKey(), []);

  const onClose = useCallback(() => dispatch(dialogToggle('statusAlert', false)), []);

  const onEdit = useCallback(() => {
    // close current status alert dialog
    onClose();

    // switch to farm tab cuz edit season dialog can be open only from the farm tab
    dispatch(setFeature('farm'));

    // close table view cuz edit season dialog working only with closed table view
    dispatch(toggleTableView());

    // if we the season not in the current field then switch to season's field
    if (selectedFieldId !== fieldId) {
      dispatch(setCurrentFieldId(fieldId));
    }

    // open edit season dialog
    dispatch(toggleGlobalDialog('editSeason', {visible: true, fieldId, seasonId}, true));
  }, [fieldId, seasonId, selectedFieldId]);

  return (
    <Dialog
      id={`status-alert-dialog-${id}`}
      visible={visible}
      title="Crop dates seem incorrect"
      focusOnMount={false}
      isDraggable
      onHide={onClose}
      autosizeContent={false}
      width={380}
      portal
    >
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>{message}</div>
        </div>

        <InfoBlock>
          {t({id: 'This has been identified by unexpected changes in the NDVI trend of the crop.'})}
        </InfoBlock>

        <div className="status-alert-dialog__btns">
          <Button raised onClick={onClose}>
            {t({id: 'Ignore'})}
          </Button>
          <Button onClick={onEdit} primary raised>
            {t({id: 'Edit crop'})}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

export default StatusAlert;

/*
 *
 * Crop status alert open button
 *
 * */
type AlertIconProps = {
  message: string;
  fieldId: number;
  seasonId: number;
};

export const AlertIcon = memo(({fieldId, seasonId, message = ''}: AlertIconProps) => {
  const dispatch = useDispatch();

  return (
    <FontIcon
      className="status-alert-dialog__w-icon-btn"
      onClick={() => dispatch(dialogToggle('statusAlert', true, {message, fieldId, seasonId}))}
    >
      error_outline
    </FontIcon>
  );
});
