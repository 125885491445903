import React from 'react';
import ConfirmDialog from './confirm.dialog';
import AlertDialog from './alert.dialog';
import FieldInfoDialog from 'containers/map/features/farm/field-info-dialog';
import EditFieldNameDialog from 'containers/map/features/farm/edit-field-name-dialog';
import EditCloudyDialog from 'containers/map/features/data-layers/edit-cloudy/edit-cloudy-dialog';
import {DialogType} from 'modules/ui-helpers';
import EditFarmDialog from 'containers/map/features/farm/dialogs/edit-farm';
import EditFarmNameDialog from 'containers/map/features/farm/dialogs/edit-farm-name';
import DeleteDialog from '../delete-dialog';
import {CarbonTillagePracticesDialog} from 'containers/carbon/panel/carbon-tillage-practices-dialog/carbon-tillage-practices-dialog';
import {useAppSelector} from '../../_hooks';

const CommonDialogs = () => {
  const dialogsState = useAppSelector(state => state.uiHelpers.dialogs);

  const isVisibleConfirmDialog = dialogsState.confirm.visible;
  const isVisibleAlertDialog = dialogsState.alert.visible;
  const isVisibleFieldInfoDialog = dialogsState.fieldInfo.visible;
  const isVisibleEditFieldNameDialog = dialogsState.editFieldName.visible;

  const isVisibleEditCloudyDialog = dialogsState[DialogType.editCloudy].visible;

  const isVisibleEditFarmDialog = dialogsState[DialogType.editFarm].visible;

  const isVisibleEditFarmNameDialog = dialogsState[DialogType.editFarmName].visible;

  const isDeleteDialog = dialogsState[DialogType.deleteDialog].visible;

  const isCarbonTillagePracticesDialog = dialogsState[DialogType.carbonTillagePractices].visible;

  return (
    <>
      {isVisibleConfirmDialog && <ConfirmDialog />}
      {isVisibleAlertDialog && <AlertDialog />}
      {isVisibleFieldInfoDialog && <FieldInfoDialog />}
      {isVisibleEditFieldNameDialog && <EditFieldNameDialog />}
      {isVisibleEditCloudyDialog && <EditCloudyDialog />}
      {isVisibleEditFarmDialog && <EditFarmDialog />}
      {isVisibleEditFarmNameDialog && <EditFarmNameDialog />}

      {isCarbonTillagePracticesDialog && <CarbonTillagePracticesDialog />}
      {isDeleteDialog && <DeleteDialog />}
    </>
  );
};

export default CommonDialogs;
