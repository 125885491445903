import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toggleTooltip} from '_actions';
import {Button, FontIcon, Slider, TextField} from 'react-md';
import {
  updateRecommendationSettings,
  getNRxRecommendation,
  toggleNRxSettingsPopUp,
} from '../../../actions/zoning-actions';
import {FluroAutocomplete, FluroDialog} from 'components';
import {
  classifyYieldUnits,
  getCropPriceUnit,
  getNrxSeason,
  NrxPopUpValues,
} from '../../../utils/nrx-utils';
import Mixpanel from '_utils/mixpanel-utils';
import {AppStore} from 'reducers';
import {t} from 'i18n-utils';

const CustomSlider = ({
  id,
  label,
  value,
  onChange,
  leftLabel,
  rightLabel,
}: {
  id: string;
  label: ReactElement;
  value: number;
  leftLabel: string;
  rightLabel: string;
  onChange(val: number): void;
}) => {
  return (
    <div className={'nrx-slider-container'}>
      <Slider
        id={id}
        className={'slider'}
        label={label}
        defaultValue={2}
        max={3}
        min={1}
        step={1}
        value={value}
        onChange={onChange}
      />

      <div className={'slider-dots-container'}>
        <div className={'dot'} />
        <div className={'dot'} />
        <div className={'dot'} />
      </div>

      <div className={'slider-labels'}>
        <span>{leftLabel}</span>

        <span>{rightLabel}</span>
      </div>
    </div>
  );
};

type Props = {
  forceVisible?: boolean;
};

const RecommendationPopUp = ({forceVisible}: Props) => {
  const dispatch = useDispatch();
  const fertilizerTypes = useSelector((store: AppStore) => store.map.listsData.fertilizerTypes);
  const nrxPopUpValues = useSelector((store: AppStore) => store.map.nRecommendation.nrxPopUpValues);
  const measurement = useSelector((store: AppStore) => store.login.user.settings.measurement);
  const nrxSeason = useSelector((store: AppStore) => getNrxSeason(store.map.currentSeasonId));
  const isSettingsPopUpVisible = useSelector(
    (store: AppStore) =>
      store.map.nRecommendation.settingsPopUpOpen && store.map.nRecommendation.toggled
  );

  const [popUpValues, setPopUpValues] = useState<NrxPopUpValues>({...nrxPopUpValues});

  useEffect(() => {
    if (isSettingsPopUpVisible) {
      setPopUpValues({...nrxPopUpValues});
    }
  }, [isSettingsPopUpVisible]);

  useEffect(() => {
    if (popUpValues.max_yield_goal === undefined && nrxPopUpValues.max_yield_goal) {
      setPopUpValues({
        ...popUpValues, // set properties if they appeared in props
        max_yield_goal: nrxPopUpValues.max_yield_goal,
        min_yield_goal: nrxPopUpValues.min_yield_goal,
      });
    }
  }, [nrxPopUpValues.max_yield_goal]);

  const onChange = (value: any, prop: keyof NrxPopUpValues) => {
    const checkForNull = ['N_constrain', 'crop_price', 'N_price'];

    if (prop === 'product') {
      const product = fertilizerTypes.find(p => p.type === value);
      return setPopUpValues({
        ...popUpValues,
        ...product,
        product: value,
        n_percentage: product.nitrogenPercentage,
      });
    } else if (checkForNull.includes(prop)) {
      return setPopUpValues({...popUpValues, [prop]: value >= 0 ? value : 0});
    }

    setPopUpValues({...popUpValues, [prop]: value});
  };

  const revertValue = (value: number) => (value === 1 ? 3 : value === 3 ? 1 : value);

  const updateRecommendation = (wrongNPercentage: boolean) => {
    if (wrongNPercentage) {
      return alert('Please set a correct fertilizer N% value');
    }

    dispatch(updateRecommendationSettings(popUpValues, true));
    dispatch(getNRxRecommendation());
    dispatch(toggleNRxSettingsPopUp(false));
    Mixpanel.updateNRxSettings();
    console.log('pop-up-values', popUpValues);
  };

  const measurementUnit = 't';
  const cropPriceUnit = measurement === 'ac' ? getCropPriceUnit(nrxSeason.cropID) : 't';
  const yieldUnits = classifyYieldUnits(measurement, nrxSeason.cropID);
  const bushelUnit = 'bu/ac';
  const wrongNPercentage = popUpValues.product === 'Other (solid)' && !popUpValues.n_percentage;
  const showMinMaxGoal =
    popUpValues.min_yield_goal !== undefined && popUpValues.max_yield_goal !== undefined;

  return (
    <FluroDialog
      id="n-recommendation-pop-up"
      title={t({id: 'Edit recommendation settings'})}
      visible={isSettingsPopUpVisible || forceVisible}
      onHide={() => dispatch(toggleNRxSettingsPopUp(false))}
      isDraggable
    >
      <div className="content-container">
        <div className={'export-settings-container'}>
          <div className={'inputs-container'}>
            <div className={'inputs-container__item fertilizer'}>
              <FluroAutocomplete
                id="fertilizer"
                label={t({id: 'Fertilizer'})}
                menuItems={fertilizerTypes}
                dataLabel={'type'}
                dataValue={'type'}
                onAutocomplete={(value: any) => onChange(value, 'product')}
                value={popUpValues.product}
                className={`select-product`}
              />

              <TextField
                id="select-product-value"
                label={t({id: 'N%'})}
                className="select-product-value"
                type="number"
                value={popUpValues.n_percentage}
                min={0}
                max={100}
                onChange={value =>
                  onChange(value > 100 ? 100 : value < 0 ? 0 : parseInt(`${value}`), 'n_percentage')
                }
                disabled={popUpValues.product !== 'Other (solid)'}
                errorText={t({id: 'enter N%'})}
                error={wrongNPercentage}
              />
            </div>
            <div className={'inputs-container__item fertilizer-price'}>
              <TextField
                id="select-urea-price"
                label={t({id: 'Fertilizer price'})}
                className="input-type-number"
                type="number"
                value={popUpValues.N_price}
                min={0}
                onChange={value => onChange(value, 'N_price')}
              />
              <span className={'units'}>$/{measurementUnit}</span>
            </div>
          </div>

          <div className={'inputs-container'}>
            <div className={'inputs-container__item crop-price'}>
              <TextField
                id="select-grain-price"
                label={t({id: 'Crop price'})}
                className="input-type-number"
                type="number"
                value={popUpValues.crop_price}
                min={0}
                step={measurement === 'ha' ? 5 : 0.01}
                onChange={value => onChange(value, 'crop_price')}
              />
              <span className={'units'}>$/{cropPriceUnit}</span>
            </div>

            <div className={'inputs-container__item fertilizer-amount'}>
              <TextField
                id="total-amount-of-nitrogen"
                label={t({id: 'Fert. available (opt.)'})}
                type="number"
                value={popUpValues.N_constrain}
                step={0.1}
                min={0}
                onChange={value => onChange(value, 'N_constrain')}
              />
              <span className={'units'}>{measurementUnit}</span>
            </div>
          </div>

          <div className={'inputs-container'}>
            <div className={'inputs-container__item full-width'}>
              <TextField
                id="Historical yield average"
                label={t({id: 'Historical yield average (opt.)'})}
                className="input-type-number bushels"
                type="number"
                value={popUpValues.historical_yield_avg}
                min={0}
                step={1}
                onChange={value => onChange(value, 'historical_yield_avg')}
              />
              <span className={'units'}>{yieldUnits}</span>
            </div>
          </div>

          {showMinMaxGoal && (
            <div className={'inputs-container'}>
              <div className={'inputs-container__item'}>
                <TextField
                  id="min-yield-goal"
                  label={t({id: 'Min. yield goal'})}
                  className="input-type-number bushels"
                  type="number"
                  value={popUpValues.min_yield_goal}
                  min={0}
                  step={1}
                  onChange={value => onChange(value, 'min_yield_goal')}
                />
                <span className={'units'}>{bushelUnit}</span>
              </div>

              <div className={'inputs-container__item'}>
                <TextField
                  id="max-yield-goal"
                  label={t({id: 'Max. yield goal'})}
                  className="input-type-number bushels"
                  type="number"
                  value={popUpValues.max_yield_goal}
                  step={1}
                  min={0}
                  onChange={value => onChange(value, 'max_yield_goal')}
                />
                <span className={'units'}>{bushelUnit}</span>
              </div>
            </div>
          )}

          <div className={'outlook-slider-container'}>
            <Slider
              id="season-outlook-slider"
              className={'season-outlook-slider'}
              trackClassName={'season-outlook-track'}
              label={t({id: 'Season outlook'})}
              defaultValue={5}
              max={20}
              min={0}
              value={popUpValues.season_outlook}
              onChange={value => onChange(value, 'season_outlook')}
            />
            <span className={'slider-value-label min'}>{t({id: 'Dry'})}</span>
            <span className={'slider-value-label mid'}>{t({id: 'Neutral'})}</span>
            <span className={'slider-value-label max'}>{t({id: 'Wet'})}</span>
          </div>

          <CustomSlider
            id="attitude-towards-risk-slider"
            label={
              <div className={'info-text-container'}>
                {t({id: 'Attitude towards risk'})}
                <FontIcon
                  data-tip={''}
                  data-for={'towards-risk'}
                  onMouseEnter={() =>
                    dispatch(
                      toggleTooltip({
                        id: 'towards-risk',
                        content: (
                          <>
                            {t({
                              id: 'Risk is measured using the return on investment (ROI) concept.',
                            })}
                            <ul className={'attitude-message'}>
                              <li>{t({id: 'Low risk: ROI is at least 3'})}</li>
                              <li>{t({id: 'Moderate risk: ROI is between 2 and 3'})}</li>
                              <li>{t({id: 'High risk: ROI is between 1 and 2'})}</li>
                            </ul>
                          </>
                        ),
                      })
                    )
                  }
                >
                  help_outline
                </FontIcon>
              </div>
            }
            value={revertValue(popUpValues.ROI_setting)}
            leftLabel={t({id: 'Low risk'})}
            rightLabel={t({id: 'High risk'})}
            onChange={value => onChange(revertValue(value), 'ROI_setting')} //revert values
          />
        </div>

        <div className={'disclaimer'}>
          {t({
            id:
              "Disclaimer: Economic risk levels are inferred from historical weather data and user-selected settings. Actual risk levels will be influenced by season's weather, crop price/quality, and other agronomic factors.",
          })}
        </div>

        <div className="md-text-center margin-top-10">
          <Button
            className={'update-recommendation-button'}
            raised
            primary
            onClick={() => updateRecommendation(wrongNPercentage)}
          >
            {t({id: 'Update and save recommendation'})}
          </Button>
        </div>
      </div>
    </FluroDialog>
  );
};

export default RecommendationPopUp;
