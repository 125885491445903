import * as React from 'react';
import styled from 'styled-components';

export const OptisChartTooltip = (props: React.PropsWithChildren<{}>) => {
  return <StyledTooltip>{props.children}</StyledTooltip>;
};

const StyledTooltip = styled.div`
  background: white;
  border: 1px solid #77777755;
  border-radius: 5px;
  padding: 8px;
`;
