import {InitialState, ActionType, User, ActionPayload} from './types';

const initialState: InitialState = {
  list: [],
  organizations: [],
  subscriptions: [],
  user: {
    id: 'new',
    email: '',
    name: '',
    perm: 1,
    active: 1,
    password: '',
    groupId: '',
    groupIds: [],
    emailConfirmed: 0,
    firstImageryOnFieldTerravion: false,
    defaultOrganizationID: null,
  } as User,
};

export default (state = initialState, action: ActionPayload) => {
  switch (action.type) {
    case ActionType.SET_ORGS:
      return {...state, organizations: [...action.organizations]};
    case ActionType.SET_SUBS:
      return {...state, subscriptions: [...action.subscriptions]};
    case ActionType.LOAD_USERS_LIST:
      return {...state, list: [...action.list]};

    case ActionType.LOAD_USERS_ONE:
      // TODO: legacy code fallback to single-group mode
      if (!action.user.groupIds && action.user.groupId) {
        action.user.groupIds = [parseInt(action.user.groupId)];
      }

      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
          firstImageryOnFieldTerravion:
            action.user.settings.notifications.firstImageryOnFieldTerravion === 'enabled',
        },
      };

    default:
      return state;
  }
};
