import React, {useEffect} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {useDispatch, useSelector} from 'react-redux';
import {Marker, Tooltip} from 'react-leaflet';
import * as Leaflet from 'leaflet';
import {AnalyticsPinImage} from '../../icons';
import {pointInside, getImageStatus, getAnalyticsItemColor} from '_utils';
import {
  addAnalyticPoint,
  removeAnalyticPoint,
  getTrendsData,
} from 'containers/map/actions/analytics-actions';
import {showNote} from '_actions';
import {AnalyticPoint} from '../../types';
import {AppStore} from 'reducers';
import moment from 'moment';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';
import {getClosestDate} from '../../utils';

type Props = {
  leafletElement: Leaflet.Map;
};

const AnalyticsOverlay = ({leafletElement}: Props) => {
  const dispatch = useDispatch();
  const currentFieldKml = useSelector((store: AppStore) => store.map.currentFieldKml);
  const analyticPoints = useSelector((store: AppStore) => store.map.analytics.points);
  const feature = useSelector((store: AppStore) => store.map.feature);
  const currentSensor = useSelector((store: AppStore) => store.map.currentSensor);
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const isDrawingMode = useSelector((store: AppStore) => store.map.drawControl.isDrawingMode);
  const currentSeason = useSelector((store: AppStore) => store.map.currentSeason);
  const {isNotProcessing} = useSelector((store: AppStore) => getImageStatus(store.map));

  useEffect(() => {
    leafletElement.on('click', handleClick);
    return () => {
      leafletElement.off('click', handleClick);
    };
  }, [isNotProcessing, currentSeason, analyticPoints.length]);

  const handleClick = (event: any) => {
    if (isDrawingMode) return;

    const isGeometryCollection = currentFieldKml?.geometry?.type === 'GeometryCollection';

    if (!isNotProcessing) {
      return dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: 'You can not add a pin, awaiting imagery',
          level: 'warning',
        })
      );
    }

    if (!currentSeason?.id) {
      return dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: 'You can not add a pin, please set your crop first',
          level: 'warning',
        })
      );
    }

    if (analyticPoints.length >= 5) {
      return dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({
            id: 'You can select up to 5 pins.',
            defaultMessage: 'You can select up to 5 pins.',
          }),
          level: 'warning',
        })
      );
    }

    if (
      isGeometryCollection
        ? currentFieldKml.geometry.geometries.every(
            (g: any) => !pointInside([event.latlng.lng, event.latlng.lat], g.coordinates[0])
          )
        : !pointInside(
            [event.latlng.lng, event.latlng.lat],
            currentFieldKml.geometry.coordinates[0]
          )
    ) {
      return dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({
            id: 'You can not add a pin outside the field.',
            defaultMessage: 'You can not add a pin outside the field.',
          }),
          level: 'warning',
        })
      );
    }

    if (currentSensor !== 'NMAP' && event.latlng && currentFieldKml) {
      const point: AnalyticPoint = {latlng: event.latlng, color: getAnalyticsItemColor()};
      dispatch(addAnalyticPoint(point)); // if click is on layer, add an analytic point
      dispatch(getTrendsData()); // and send request with updated points number
    }
  };

  const removePointFromMap = (color: string) => {
    dispatch(removeAnalyticPoint(color));
  };

  if (!analyticPoints.length || feature !== 'analytics') return null;

  const getArablePointPosition = (point: AnalyticPoint) => {
    const formattedCurrentDate = moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(
      GLOBAL_APP_DATE_FORMAT
    ); // remove source string from the date
    let coordinates = point.latlng;
    analyticPoints.forEach(loopPoint => {
      if (loopPoint.color !== point.color && !loopPoint.arableData) return;

      const sameDate =
        loopPoint.arableData.find(pointObj => pointObj.timeAppFormat === formattedCurrentDate) || // search for the current date first
        loopPoint.arableData.find(
          // then try to find closest date
          pointObj =>
            pointObj.timeAppFormat ===
            getClosestDate(
              formattedCurrentDate,
              loopPoint.arableData.reduce((result: {[date: string]: true}, dataObj) => {
                return {...result, [dataObj.timeAppFormat]: true};
              }, {})
            )
        );
      if (sameDate) {
        coordinates = {lat: sameDate.lat, lng: sameDate.long};
      }
    });

    return coordinates;
  };

  return (
    <>
      {analyticPoints.map((point: AnalyticPoint) => {
        const isArable = point.arableData;
        const icon = AnalyticsPinImage(
          point.color,
          point.visible === false,
          isArable ? 'arable' : undefined
        );
        const position = isArable ? getArablePointPosition(point) : point.latlng;
        return (
          <Marker
            key={point.color}
            position={position}
            eventHandlers={{click: isArable ? null : () => removePointFromMap(point.color)}}
            icon={icon}
            title={isArable ? point.label : point.color}
          >
            {isArable ? (
              <Tooltip direction={'top'} offset={[0, -35]}>
                {point.label}
              </Tooltip>
            ) : null}
          </Marker>
        );
      })}
    </>
  );
};

export default AnalyticsOverlay;
