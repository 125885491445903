import styled from 'styled-components';

export const Wrapper = styled.div`
  .tillage-title {
    margin-top: 5px;
    font-weight: 500;
    font-size: 16px;
  }

  .circle {
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
  }

  .tillage-desc {
    margin-top: 15px;
    font-size: 14px;
  }

  .tbl {
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;
