import {t, FormattedMessage} from 'i18n-utils';
import styled from 'styled-components';
import {TableColumn as Tc} from 'react-md';
import {useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import React from 'react';
import {FluroTableColumn} from '../../../../components';

type LogStatusLabelProps = {
  color: string;
};

export const LogStatusLabel = styled.span`
  padding: 5px 15px;
  border-radius: 3px;
  color: ${({color}: LogStatusLabelProps) => color};
  border: 1px solid ${({color}: LogStatusLabelProps) => color};
  white-space: nowrap;
`;

export const TableColumn = styled(Tc)`
  padding-left: 15px;
  padding-right: 20px;
  font-weight: 500;
  color: #000;
  font-size: 14px;
`;

export const RecipientColumn = styled(FluroTableColumn)`
  > div {
    max-width: 250px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }
`;

export const EntityColumn = styled(FluroTableColumn)`
  min-width: 200px;
`;

export const InfoLogItem = styled.div`
  margin-top: 15px;

  > section {
    display: flex;
    justify-content: flex-start;
  }

  div {
    margin-right: 10px;
  }

  b {
    color: rgba(0, 0, 0, 0.6);
  }
`;

type DialogContentWrapperStyled = {
  maxHeight: number;
};

type DialogContentWrapper = {
  children: any;
  excludedHeight?: number;
  innerRef?: (node: any) => void;
};

const DialogContentWrapperStyled = styled.div`
  overflow: auto;
  max-height: ${({maxHeight}: DialogContentWrapperStyled) => maxHeight}px;
`;

export const DialogContentWrapper = ({
  children,
  excludedHeight,
  innerRef,
}: DialogContentWrapper) => {
  const vHeight = useSelector((state: AppStore) => state.viewport.height - (excludedHeight || 190));
  return (
    <DialogContentWrapperStyled ref={innerRef} maxHeight={vHeight}>
      {children}
    </DialogContentWrapperStyled>
  );
};
