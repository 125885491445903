import {FormattedMessage, t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {setSchema} from '../../actions';
import {useDispatch, useSelector} from 'react-redux';
import {SelectField} from 'react-md';
import {ITreeData, SourceType} from '../../types';
import get from 'lodash.get';
import {getSingleDateSchema} from '../../utils';
import {getTreeDataByDate} from '../../utils/trees';
import {formatUnit} from '_utils';
import {AppStore} from 'reducers';
import {usePrevious} from '_hooks';
import {PRODUCTIVITY_MAP_ASSETS_HOST} from '../../features/zoning/productivity-map/productivity-map.module';
import {AsyncStatusType, Status} from 'modules/ui-helpers';
import './color-schema-overlay.scss';

const allowedIndexes: string[] = [
  'CCCI',
  'MSAVI',
  'NDWI',
  'NDRE',
  'NMAP',
  'NDVI',
  'TIRS',
  'VVI',
  'PGI',
  'EC',
  'PH',
  'YIELD',
  'ELEVATION',
  'DIAMETER',
  'PTIRS',
];

const schemas: Array<{label: any; value: string}> = [
  {label: <FormattedMessage id="Single Date" defaultMessage="Single Date" />, value: 'singleDate'},
  {
    label: <FormattedMessage id="Whole Season" defaultMessage="Whole Season" />,
    value: 'wholeSeason',
  },
];

const MapColorSchema = () => {
  const [expanded, setExpanded] = useState(true);
  const [imageSrc, setImageSrc] = useState('');
  const [errorSrc, setErrorSrc] = useState('');
  const [prevSchemaValue, setPrevSchemaValue] = useState('singleDate'); // store the before whole farm switching schema to set it back when a single field view is selected
  const dispatch = useDispatch();

  const viewport = useSelector((store: AppStore) => store.viewport);
  const currentSeasonId = useSelector((store: AppStore) => store.map.currentSeasonId);
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const currentDates = useSelector((store: AppStore) => store.map.currentDates);
  const currentSensor = useSelector((store: AppStore) => store.map.currentSensor);
  const isWholeFarmView = useSelector((store: AppStore) => store.map.wholeFarm.isWholeFarmView);
  const treeDetection = useSelector((store: AppStore) => store.map.treeDetection);
  const colorSchema = useSelector((store: AppStore) => store.map.colorSchema);
  const scaleUrl = useSelector((store: AppStore) => store.map.nitrogen.scaleUrl);
  const feature = useSelector((store: AppStore) => store.map.feature);
  const isZoning = useSelector((store: AppStore) => store.map.isZoning);
  const measurement = useSelector((store: AppStore) => store.login.user.settings.measurement);
  const productivityMapData = useSelector(
    (state: AppStore) => state.productivityMap.data[state.productivityMap.currentCacheKey]
  );
  const isProductivityMap = useSelector((state: AppStore) => state.productivityMap.isOn);
  const isProductivityMapLoading = useSelector(
    (state: AppStore) =>
      state.uiHelpers.asyncStatuses[AsyncStatusType.productivityMap].status === Status.Pending
  );

  const isBinaryImageLoading = useSelector(
    (state: AppStore) =>
      state.uiHelpers.asyncStatuses[AsyncStatusType.binaryImageLayerData].status === Status.Pending
  );

  const cachedImages = useSelector((store: AppStore) => store.imagesCache.data);

  const currentImageUrl = useMemo(() => currentDates?.[currentDate]?.[currentSensor]?.url, [
    currentDates,
    currentDate,
    currentSensor,
  ]);

  const prev = usePrevious({viewport, scaleUrl});

  useEffect(() => {
    if (isWholeFarmView) {
      setPrevSchemaValue(colorSchema);
      dispatch(setSchema('wholeSeason'));
    } else {
      dispatch(setSchema(prevSchemaValue));
    }
  }, [isWholeFarmView]);

  useEffect(() => {
    if (viewport.width < 561) {
      setExpanded(false);
    }
    if (isWholeFarmView) {
      dispatch(setSchema('wholeSeason'));
    }

    setImageSrc(calcScaleUrl());
  }, []);

  useEffect(() => {
    if (isProductivityMap && productivityMapData) {
      setImageSrc(PRODUCTIVITY_MAP_ASSETS_HOST + productivityMapData.thumbnail_zones_black_scale);
    } else {
      setImageSrc(calcScaleUrl());
    }

    setErrorSrc('');
  }, [
    colorSchema,
    currentDate,
    currentSensor,
    currentSeasonId,
    productivityMapData,
    isProductivityMap,
  ]);

  useEffect(() => {
    if (prev?.viewport?.width > 560 && viewport.width < 561) {
      setExpanded(false);
    } else if (prev?.viewport?.width < 561 && viewport.width > 560) {
      setExpanded(true);
    }
  }, [viewport]);

  useEffect(() => {
    // NMAP scale is loaded async, need to update state to get the image

    if (currentSensor === 'NMAP' && imageSrc === '' && scaleUrl && !prev?.scaleUrl) {
      setImageSrc(scaleUrl);
      setErrorSrc('');
    }
  }, [currentSensor, imageSrc, scaleUrl]);

  const toggleExpanded = () => setExpanded(!expanded);

  const calcScaleUrl = (isOld = false, removeThermal = false) => {
    if (currentSensor === 'TIRS') {
      return getSingleDateUrl(isOld, removeThermal);
    }

    return colorSchema !== 'singleDate'
      ? `/assets/schemas/${colorSchema}.png`
      : currentSensor === 'NMAP'
      ? scaleUrl
      : getSingleDateUrl(isOld);
  };

  const getSingleDateUrl = (isOld = false, removeThermal = false) => {
    const isFahrenheit =
      !removeThermal &&
      (currentSensor === 'TIRS' || currentSensor === 'PTIRS') &&
      measurement === 'ac'
        ? 'F'
        : ''; // a parameter to switch scale between Fahrenheit and Celsius
    return (currentDates?.[currentDate]?.[currentSensor]?.url || '').replace(
      '.png',
      `_singleDate.${isOld ? 'scale' : 'scaleInv'}${isFahrenheit}.png`
    );
  };

  const currentLayerData = useMemo(() => currentDates?.[currentDate]?.[currentSensor], [
    currentDates,
    currentDate,
    currentSensor,
  ]);

  const onErrorLoadScale = () => {
    const isFahrenheit = ['TIRS', 'PTIRS'].includes(currentSensor) && measurement === 'ac';

    setErrorSrc(imageSrc);

    if (isProductivityMap) {
      return;
    }

    let newImageSrc = calcScaleUrl(true);

    if (isFahrenheit && (imageSrc.includes('scaleF') || imageSrc.includes('scaleInv.png'))) {
      newImageSrc = calcScaleUrl(!imageSrc.includes('scaleF.png'), true);
    }

    if (
      (isFahrenheit ? !imageSrc.includes('scale.png') : true) &&
      newImageSrc !== errorSrc &&
      newImageSrc !== imageSrc
    ) {
      console.log('%cRollback to old scale', 'background: orange; font-weight: bold');
      setImageSrc(newImageSrc);
    }
  };

  const getTreeSchema = (treeData: {[fieldMd5: string]: ITreeData}) => {
    const firstFieldData = treeData[Object.keys(treeData)[0]]; // get data from the first field until Victor provides a new logic
    const scaleObject =
      typeof firstFieldData.scale === 'string'
        ? JSON.parse(firstFieldData.scale)
        : firstFieldData.scale;
    const scale = get(
      scaleObject,
      `tree_${treeDetection.layerType}_${currentSensor.toLowerCase()}`,
      undefined
    );
    return getSingleDateSchema(scale);
  };

  const isTreeDetection = treeDetection.layerType !== 'default' && getTreeDataByDate(currentDate);

  const scaleLabel = useMemo(() => {
    if (isProductivityMap) {
      return null;
    }

    if (isTreeDetection) {
      return getTreeSchema(isTreeDetection);
    }

    return (
      <>
        {['TIRS', 'PTIRS'].includes(currentSensor) || isWholeFarmView ? (
          <span className="color-schema-label">
            {isWholeFarmView ? (
              schemas.find(schema => schema.value === colorSchema)?.label
            ) : (
              <FormattedMessage
                id="thermal"
                defaultMessage="Thermal {unit}"
                values={{
                  unit: <span className={'thermal-scale'}>(°{formatUnit(measurement, 'C')})</span>,
                }}
              />
            )}
          </span>
        ) : (
          <SelectField
            id="color-schema-select"
            placeholder={t({id: 'Select color schema', defaultMessage: 'Select color schema'})}
            className="color-schema-select"
            label=""
            menuItems={schemas}
            value={colorSchema}
            onChange={(value: string) => dispatch(setSchema(value))}
            simplifiedMenu={false}
            anchor={{
              x: SelectField.HorizontalAnchors.RIGHT,
              y: SelectField.VerticalAnchors.TOP,
            }}
          />
        )}
      </>
    );
  }, [
    isTreeDetection,
    currentSensor,
    isWholeFarmView,
    schemas,
    colorSchema,
    setSchema,
    measurement,
    getTreeSchema,
    isProductivityMap,
    currentLayerData,
  ]);

  if (
    isZoning ||
    (feature === 'zoning' && !isProductivityMap) ||
    (feature === 'zoning' && isProductivityMap && isProductivityMapLoading) ||
    !allowedIndexes.includes(currentSensor) ||
    (!getSingleDateUrl() && !isTreeDetection)
    // || isWholeFarmView
  ) {
    return null;
  }

  if (!expanded) {
    return <div className={`map-color-schema__${colorSchema}`} onClick={toggleExpanded} />;
  }

  const canShowScale =
    errorSrc !== imageSrc && !isTreeDetection && currentDate && currentSensor !== 'NONE';

  const schemaImage = () => {
    if (
      canShowScale &&
      currentLayerData?.type === SourceType.SatelliteHD &&
      colorSchema === 'singleDate'
    ) {
      return isBinaryImageLoading ? (
        <div style={{color: '#bfbfbf'}}>Loading...</div>
      ) : (
        getSingleDateSchema(
          [
            `${cachedImages[currentImageUrl]?.meta?.min || 0}`,
            `${cachedImages[currentImageUrl]?.meta?.max || 0}`,
          ],
          true
        )
      );
    }

    if (canShowScale) {
      return (
        <img
          className={'onboarding-color-schema'}
          src={imageSrc}
          onError={onErrorLoadScale}
          alt={`color-schema-${colorSchema}`}
          onClick={toggleExpanded}
        />
      );
    }

    return null;
  };

  return (
    <div className={'map-color-schema'}>
      {scaleLabel}

      {/* single field wiew */}
      {canShowScale ? schemaImage() : null}

      {imageSrc === errorSrc && !isTreeDetection && (
        <div className={'error-color-schema-img'}>
          {t({
            id: 'Something went wrong, please try again later.',
            defaultMessage: 'Something went wrong, please try again later.',
          })}
        </div>
      )}
    </div>
  );
};

export default MapColorSchema;
