import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import moment from 'moment';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {EyeHiddenIcon} from 'containers/map/icons';
import {IsOnlyCloudy, NoCropSet, NoFields, SeasonInFuture} from './messages';
import {InfoBlock, Ln} from 'components';
import {
  getGetURLParam,
  getImageStatus,
  hasTrendsData,
  isAgxField,
  isCloudy,
  runIntercom,
} from '_utils';
import {setAppProcessingStatus, setFeature} from 'containers/map/actions';
import './index.scss';
import {TFeature} from '../../types';
import {AppearanceValues} from '../info-block/types';
import {checkFieldTagExist} from 'containers/map/utils';
import {FieldTag, SourceType} from 'containers/map/types';
import {cropPerfIsLoading} from 'containers/map/features/crop-performance/helpers';
import {AppStore} from 'reducers';
import {getFieldSeasons} from '_hooks/use-current-season';
import {SERVER_FORMAT_DATE, GLOBAL_APP_DATE_FORMAT} from '_constants';

type Props = ConnectedProps<typeof connector>;

export type ProcessingStatus =
  | ''
  | 'no-fields'
  | 'no-agx-crop-set'
  | 'no-crop-set'
  | 'only-fallow-seasons'
  | 'season-in-future'
  | 'is-processing'
  | 'manually-hidden'
  | 'is-only-cloudy'
  | 'not-enabled-premium-anomalies'
  | 'premium-anomalies-cloudy-issue'
  | 'premium-anomalies-vigor-issue'
  | 'no-premium-anomalies'
  | 'no-crop-performance'
  | 'analytic-filtered-satellite'
  | 'place-a-pin';

const AppProcessingStatus = ({
  fieldsByFarmId,
  field,
  currentSeason,
  imageStatus: {isNotProcessing},
  infoExt,
  isCloudy,
  feature,
  premiumAnomaliesLength,
  anomaliesSelectedTab,
  currentDateObj,
  currentDate,
  cropPerformance,
  appProcessingStatus,
  wholeFarm: {isWholeFarmView},
  remoteSensingFilterProps,
  hasTrendsData,
}: Props) => {
  const dispatch = useDispatch();
  const setMessage = (message: ProcessingStatus) => {
    dispatch(setAppProcessingStatus(message));
  };

  const {avgNdvi, Cloud, Type} = currentDateObj;
  const localSetFeature = (feature: TFeature) => dispatch(setFeature(feature));

  const fields = useMemo(
    () =>
      Object.keys(fieldsByFarmId)
        .filter(farmId => cropPerformance.farms[Number(farmId)])
        .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])),
    [fieldsByFarmId, cropPerformance.farms]
  );

  const {hasSeasons, isAgx} = useMemo(
    () => ({
      hasSeasons:
        isWholeFarmView || feature === 'crop-performance'
          ? fields.some(f => f.Seasons?.length)
          : field.Seasons?.length,
      isAgx:
        isWholeFarmView || feature === 'crop-performance'
          ? fields.some(f => isAgxField(f))
          : isAgxField(field),
    }),
    [isWholeFarmView, feature, fields, field]
  );
  const onlyFallowSeasons = useMemo(() => {
    const date = moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE);
    return fields.every(f => {
      const seasons = getFieldSeasons(f, date);
      return seasons?.every(season => season?.cropType === 'fallow');
    });
  }, [fields, currentDate]);

  useEffect(() => {
    if (!fields.length) {
      return setMessage('no-fields');
    } else if (!hasSeasons) {
      return setMessage(isAgx ? 'no-agx-crop-set' : 'no-crop-set');
    } else if (moment(currentSeason.startDate).isAfter(moment())) {
      return setMessage('season-in-future');
    } else if (!isNotProcessing) {
      return setMessage('is-processing');
    } else if (infoExt.length && infoExt.every(dateObj => dateObj.Hidden)) {
      return setMessage('manually-hidden');
    } else if (isCloudy) {
      return setMessage('is-only-cloudy');
      // crop stress tab
    } else if (
      feature === 'crop' &&
      anomaliesSelectedTab === 'crop-stress' &&
      !checkFieldTagExist(FieldTag.AnomalyDetection) &&
      // FSB-4061 HACK: Unblocking Victor to test anomalies wihtout the field tag, but using the url flag
      // getGetURLParam('premium-anomalies')
      !getGetURLParam('premium-anomalies')
    ) {
      return setMessage('not-enabled-premium-anomalies');
    } else if (
      feature === 'crop' &&
      anomaliesSelectedTab === 'crop-stress' &&
      !premiumAnomaliesLength &&
      Cloud > 10
    ) {
      return setMessage('premium-anomalies-cloudy-issue');
    } else if (
      feature === 'crop' &&
      anomaliesSelectedTab === 'crop-stress' &&
      !premiumAnomaliesLength &&
      avgNdvi < 0.4 &&
      Type?.includes('satellite')
    ) {
      return setMessage('premium-anomalies-vigor-issue');
    } else if (
      feature === 'crop' &&
      anomaliesSelectedTab === 'crop-stress' &&
      !premiumAnomaliesLength
    ) {
      return setMessage('no-premium-anomalies');
      // crop performance tab
    } else if (feature === 'crop-performance' && onlyFallowSeasons) {
      return setMessage('only-fallow-seasons');
    } else if (
      feature === 'crop-performance' &&
      !cropPerfIsLoading(cropPerformance) &&
      !cropPerformance.records.length
    ) {
      return setMessage('no-crop-performance');
    } else if (feature === 'analytics' && remoteSensingFilterProps.includes(SourceType.Satellite)) {
      return setMessage('analytic-filtered-satellite');
    } else if (feature === 'analytics' && !hasTrendsData) {
      return setMessage('place-a-pin');
    }
    return setMessage('');
  }, [
    fields,
    field,
    currentSeason,
    cropPerformance,
    isNotProcessing,
    infoExt,
    isCloudy,
    currentDateObj,
    feature,
    premiumAnomaliesLength,
    anomaliesSelectedTab,
    hasTrendsData,
    remoteSensingFilterProps,
    onlyFallowSeasons,
  ]);

  const dataLayersTab = (
    <span onClick={localSetFeature.bind(this, 'data-layers')} className={'global-link'}>
      {' '}
      data layers{' '}
    </span>
  );

  const calcAppearance: AppearanceValues = useMemo(() => {
    return 'change';
  }, [appProcessingStatus]);

  const infoMessage = useMemo(() => {
    switch (appProcessingStatus) {
      case 'no-fields':
        return <NoFields />;

      case 'no-agx-crop-set':
        return (
          <span>
            {t({
              id:
                'Please set up your crop on agX first, then sync your data on FluroSense. Once the crop is set up, we can retrieve the satellite imagery and generate the map layers required to use this tool.',
            })}
          </span>
        );

      case 'no-crop-set':
        return <NoCropSet />;

      case 'only-fallow-seasons':
        return <span>{t({id: 'Crop performance is not available on fallow crops.'})}</span>;

      case 'season-in-future':
        return <SeasonInFuture />;

      case 'is-processing':
        return t({
          id: 'We are importing and processing data for your farm, it can take up to 24 hours.',
        });

      case 'manually-hidden':
        return (
          <FormattedMessage
            id="All your images were manually hidden"
            defaultMessage="All your images were manually hidden. To reveal them, head to the {dataLayersTab} tab and click on the <eye/> icon."
            values={{dataLayersTab, eye: () => <EyeHiddenIcon className={'align-icon-in-text'} />}}
          />
        );

      case 'is-only-cloudy':
        return <IsOnlyCloudy />;

      case 'not-enabled-premium-anomalies':
        return (
          <FormattedMessage
            id="<span>Contact us</span> to enable automated <ln>crop stress detection and alerts</ln> on your fields."
            defaultMessage="<span>Contact us</span> to enable automated <ln>crop stress detection and alerts</ln> on your fields."
            values={{
              span: (txt: string) => (
                <span onClick={runIntercom} className="global-link">
                  {txt}{' '}
                </span>
              ),
              ln: (txt: string) => (
                <Ln
                  blank
                  external
                  href={'https://help.flurosense.com/en/articles/4484912-crop-stress-detection'}
                >
                  {txt}
                </Ln>
              ),
            }}
          />
        );

      case 'premium-anomalies-cloudy-issue':
        return (
          <span>
            <FormattedMessage
              id="We can not detect crop stress because your imagery is cloudy. To hide cloudy imagery, switch to the {dataLayersTab} tab, and adjust the cloud filter."
              defaultMessage=""
              values={{dataLayersTab}}
            />
          </span>
        );

      case 'premium-anomalies-vigor-issue':
        return (
          <span>
            {t({
              id:
                'Your crop vigor is not high enough to detect crop stress. Please try again when the crop is more developed.',
            })}
          </span>
        );

      case 'no-premium-anomalies':
        const date = moment(currentDate, 'DD/MM/YYYY');
        return date.isValid()
          ? t({id: 'No stress detected for {date}'}, {date: date.format('DD MMMM YYYY')})
          : t({id: 'No stress detected.'});

      case 'no-crop-performance':
        return t({
          id:
            'On some of your fields, the crop has not developed enough to run this feature, please try again at a later stage of development.',
        });

      case 'analytic-filtered-satellite':
        return (
          <span>
            <FormattedMessage
              id="Remember, the sentinel imagery is filtered out, it will not be displayed on the charts. To reveal the imagery, switch to the {dataLayersTab} tab and adjust the imagery filter."
              defaultMessage=""
              values={{dataLayersTab}}
            />
          </span>
        );

      case 'place-a-pin':
        return t({id: 'Click on the map to place a pin.'});

      default:
        return null;
    }
  }, [appProcessingStatus, currentDate]);

  const specificRulesPassed = useMemo(() => {
    let passed = true;
    // TODO (stas, illia): need to refactor this specific rules.
    // After onlyFallowSeasons was introduced, we can have a situation with
    // crop perf records, but we don't want to show anything, only the info message.
    if (feature === 'crop-performance' && cropPerformance.records.length && !onlyFallowSeasons) {
      return false;
    }

    return passed;
  }, [feature, cropPerformance.records]);

  return specificRulesPassed && appProcessingStatus && infoMessage ? (
    <InfoBlock
      appearance={calcAppearance}
      className={`processing-status status--${appProcessingStatus}`}
    >
      {infoMessage}
    </InfoBlock>
  ) : null;
};

const mapStateToProps = (s: AppStore) => ({
  appProcessingStatus: s.map.appProcessingStatus,
  wholeFarm: s.map.wholeFarm,
  fieldsByFarmId: s.map.fieldsByFarmId,
  field: s.map.field,
  feature: s.map.feature,
  currentSeason: s.map.currentSeason,
  imageStatus: getImageStatus(s.map),
  isCloudy: isCloudy(s.map),
  infoExt: s.map.infoExt,
  currentDateObj: s.map.currentDates[s.map.currentDate] || {},
  currentDate: s.map.currentDate,
  // CS
  premiumAnomalies: s.map.premiumAnomalies.list,
  premiumAnomaliesLength: s.map.premiumAnomalies.list.length,
  anomaliesSelectedTab: s.map.anomalies.anomaliesSelectedTab,

  // CP
  cropPerformance: s.cropPerformance,

  // analytics
  remoteSensingFilterProps: s.map.remoteSensingFilterProps,
  analytics: s.map.analytics,
  hasTrendsData: hasTrendsData(s.map.analytics),
});

const connector = connect(mapStateToProps, null);

export default connector(AppProcessingStatus);
