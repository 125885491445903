import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {reportError} from 'containers/error-boundary';
import {
  replaceFieldAtFieldsByFarmId,
  toggleTableView,
  updateFieldGeometries,
} from 'containers/map/actions';
import {AppStore} from 'reducers';
import {CarbonApi, CarbonPracticesResponse, TargetPracticesPayload} from '_api/carbon';
import {getGetURLParam, setGetParamToURL} from '_utils';
import {CarbonPlanType, CarbonPractice, carbonPracticeOrder, carbonYears} from './base/base';
import {getDsocParams, carbonPlanStateToPayload} from './base/base-carbon-plan';
import {YearQuarter} from './base/crop-practice';
import {FarmApi, KmlApi} from '../../_api';
//@ts-ignore
import tokml from 'tokml';
import {featureCollection} from '@turf/turf';
import {GeoJSON} from 'leaflet';

export enum CarbonStep {
  Fields = 'Enroll fields',
  CropPractices = 'Confirm practices',
  Credits = 'Get credits',
}

type SignedDocumentData = {status?: string; envelope_id?: string; address?: string};

const isStep = (step: string): step is CarbonStep => {
  return [CarbonStep.Fields, CarbonStep.CropPractices, CarbonStep.Credits].includes(
    step as CarbonStep
  );
};

export type CarbonState = {
  step: CarbonStep;
  planId?: number;
  year: number; // Year selected in panel that should be reflected on the map.
  quarter: YearQuarter; // Practice selected in panel that should be reflected on the map.
  enrolledFields: {[fieldId: number]: boolean}; // Fields enrolled at step 1 that are gonna be used at step 2 and 3.
  selectedId?: number; // Selected field on map to show popup.
  plans: {[plan in CarbonPlanType]: CarbonPlanState};
  guessedPractices: GuessedPractices; // Practices guessed by fssh/optis service (not entered by user).
  targetPractices: TargetPracticesPayload; // Practices a farmer wants to achieve to get the rewards (step 3).
  // Recommended or Custom plan is used.
  // Recommended plan is a single practice and a user can't select fields from the map.
  // Custom plan can include several practices and a user can select fields from the map.
  activePlan: CarbonPlanType;
  signedDocumentData: SignedDocumentData;
};

export type GuessedPractices = {[fieldId: number]: {[year: number]: CarbonPracticesResponse}};

export type CarbonPlanState = {
  id?: number;
  carbonPractices: CarbonPractice[];
  activeCarbonPractice: CarbonPractice;
  practices: {[fieldId: string]: CarbonPractice};
};

export const saveCarbonPlan = createAsyncThunk<void, void, {state: AppStore}>(
  'carbon/saveCarbonPlan',
  async (_, thunkAPI) => {
    const s = thunkAPI.getState();
    const carbon = s.carbon;
    const fieldsByFarmId = s.map.fieldsByFarmId;
    const fieldGeometries = s.map.fieldGeometries;

    const dsoc = getDsocParams(carbon, fieldsByFarmId, fieldGeometries);
    const payload = carbonPlanStateToPayload(carbon, dsoc);
    const method = carbon.planId ? 'updateCarbonPlan' : 'createCarbonPlan';
    const r = await CarbonApi[method](payload);
    if (r.data?.status !== 'ok') {
      reportError(`SPT: saveCarbonPlan failed at: ${method}`);
      return;
    }

    if (!carbon.planId) {
      thunkAPI.dispatch(setPlanId(r.data.result));
      return;
    }
  }
);

export const generateCarbonSequestration = createAsyncThunk<void, number, {state: AppStore}>(
  'carbon/saveCarbonPlan',
  async (id, thunkAPI) => {
    try {
      const rs = await CarbonApi.generateCarbonSequestration(id);
      if (rs.data?.status !== 'ok') {
        reportError(`SPT: /gen_soc failed for scenario: ${id}, err: ${rs.data?.result}`);
      } else {
        thunkAPI.dispatch(setTargetPractices(rs.data.result.body.step_3));
      }
    } catch (e) {
      reportError(`SPT: /gen_soc failed for scenario: ${id}, err: ${e.data?.result}`);
    }
  }
);

export const redirectToContract = createAsyncThunk<void, void, {state: AppStore}>(
  'carbon/saveCarbonPlan',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const id = state.carbon.planId;
    if (id) {
      const r = await CarbonApi.getCarbonDocumentLink(id);
      if (r?.data?.result?.url) {
        // redirect a user to the document signing
        window.location = r.data.result.url;
      }
    }
  }
);

let saveTimeout: ReturnType<typeof setTimeout>;
export const doAndPersist = createAsyncThunk<void, () => void, {state: AppStore}>(
  'carbon/doAndPersist',
  async (job: () => void, thunkAPI) => {
    job();

    clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      thunkAPI.dispatch(saveCarbonPlan());
      saveTimeout = undefined;
    }, 5000);
  }
);

export const setStep = createAsyncThunk<void, CarbonStep, {state: AppStore}>(
  'carbon/setStep',
  async (step: CarbonStep, thunkAPI) => {
    const state = thunkAPI.getState();
    const wholeTableViewOpen = state.map.wholeTableViewOpen;
    const prev = state.carbon.step;

    if (prev === step) {
      return;
    }

    // Turn off map popup when leaving step 2.
    if (prev === CarbonStep.CropPractices) {
      thunkAPI.dispatch(toggleGeometry(undefined));
    }

    // Turn off table view when leaving step 2.
    if (prev === CarbonStep.CropPractices && wholeTableViewOpen) {
      thunkAPI.dispatch(toggleTableView(undefined));
    }

    // Turn on table view when entering step 2.
    if (step === CarbonStep.CropPractices && !wholeTableViewOpen) {
      thunkAPI.dispatch(toggleTableView('carbon'));
    }

    thunkAPI.dispatch(updateStep(step));
  }
);

export const saveEditedField = createAsyncThunk<void, GeoJSON.Feature, {state: AppStore}>(
  'carbon/saveEditedField',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const field =
        state.map.fieldsByFarmId[data.properties.fluro_farm_id][data.properties.fluro_id];
      const enrolledFields = {...state.carbon.enrolledFields};

      const geometries = {...state.map.fieldGeometries};

      const result = await FarmApi.saveFields([
        {
          farm_id: data.properties.fluro_farm_id,
          name: field.Name,
          kml: tokml(data),
        },
      ]);

      await KmlApi.dropField(data.properties.fluro_farm_id, field.ID);

      const newField = result.data.result[0].field;

      // if edited field was selected we need to update enrolled fields
      if (enrolledFields[field.ID] !== undefined) {
        enrolledFields[newField.ID] = enrolledFields[field.ID];
        delete enrolledFields[field.ID];

        thunkAPI.dispatch(setEnrollFields(enrolledFields));
      }

      // remove prev geometry
      delete geometries[field.MD5];

      data.properties.fluro_id = newField.ID;

      //@ts-ignore
      geometries[newField.MD5] = featureCollection([data]);

      thunkAPI.dispatch(
        replaceFieldAtFieldsByFarmId(data.properties.fluro_farm_id, field.ID, newField)
      );

      thunkAPI.dispatch(updateFieldGeometries(geometries));

      // fit edited field
      setTimeout(() => {
        const layer = new GeoJSON(data);
        //@ts-ignore
        window.leafletElement.fitBounds(layer.getBounds(), {
          paddingTopLeft: [32, 32],
          paddingBottomRight: [432, 32],
        });
      }, 100);
    } catch (e) {
      reportError('Cannot edit field boundary: ' + e.message);
    }
  }
);

const urlStep = getGetURLParam('carbonStep');
const initialState: CarbonState = {
  step: isStep(urlStep) ? urlStep : CarbonStep.Fields,
  planId: undefined,
  year: carbonYears[0],
  quarter: YearQuarter.SummerCrop,
  enrolledFields: {},
  plans: {
    [CarbonPlanType.Recommended]: {
      carbonPractices: [CarbonPractice.NoTillCoverCrops],
      activeCarbonPractice: CarbonPractice.NoTillCoverCrops,
      practices: {},
    },
    [CarbonPlanType.Custom]: {
      carbonPractices: [CarbonPractice.NoTillCoverCrops],
      activeCarbonPractice: CarbonPractice.NoTillCoverCrops,
      practices: {},
    },
  },
  guessedPractices: {},
  targetPractices: {},
  activePlan: CarbonPlanType.Recommended,
  signedDocumentData: {},
};

export const carbonSlice = createSlice({
  name: 'carbon',
  initialState,
  reducers: {
    updateStep: (state, action: PayloadAction<CarbonStep>) => {
      state.step = action.payload;
      setGetParamToURL('carbonStep', state.step);
    },
    setYear: (state, action: PayloadAction<number>) => {
      state.year = action.payload;
    },
    setQuarter: (state, action: PayloadAction<YearQuarter>) => {
      state.quarter = action.payload;
    },
    enrollFields: (state, action: PayloadAction<{[fieldId: number]: boolean}>) => {
      state.enrolledFields = {...state.enrolledFields, ...action.payload};
    },
    toggleGeometry: (state, action: PayloadAction<number>) => {
      state.selectedId = state.selectedId === action.payload ? undefined : action.payload;
    },
    assignPracticeToField: (
      state,
      action: PayloadAction<{fieldId: number; practice: CarbonPractice}>
    ) => {
      const {fieldId, practice} = action.payload;
      const plan = state.plans[state.activePlan];
      plan.practices[fieldId] = practice;
      state.targetPractices[fieldId].practices = practice;
    },
    setGuessedPractices: (state, action: PayloadAction<GuessedPractices>) => {
      state.guessedPractices = action.payload;
    },
    setActiveCarbonPractice: (state, action: PayloadAction<CarbonPractice>) => {
      const plan = state.plans[state.activePlan];
      plan.activeCarbonPractice = action.payload;
    },
    setTargetPractices: (state, action: PayloadAction<TargetPracticesPayload>) => {
      state.targetPractices = action.payload;
    },
    setPlan: (state, action: PayloadAction<CarbonPlanState>) => {
      const plan = action.payload;
      const planType =
        plan.carbonPractices.length === 1 &&
        plan.carbonPractices[0] === CarbonPractice.NoTillCoverCrops
          ? CarbonPlanType.Recommended
          : CarbonPlanType.Custom;

      state.activePlan = planType;
      state.plans[planType] = plan;
    },
    setSignedDocumentData: (state, action: PayloadAction<SignedDocumentData>) => {
      state.signedDocumentData = action.payload;
    },
    addPractice: state => {
      const plan = state.plans[state.activePlan];
      const unusedPractice = carbonPracticeOrder.find(p => !plan.carbonPractices.includes(p));
      if (unusedPractice) {
        plan.carbonPractices = [...plan.carbonPractices, unusedPractice];
        plan.activeCarbonPractice = unusedPractice;
      }
    },
    removePractice: (state, action: PayloadAction<CarbonPractice>) => {
      const practice = action.payload;
      const plan = state.plans[state.activePlan];

      // Remove the practice.
      plan.carbonPractices = plan.carbonPractices.filter(p => p !== practice);

      // Update active practice if it was removed.
      if (plan.activeCarbonPractice === practice) {
        plan.activeCarbonPractice = plan.carbonPractices[0];
      }

      // Remove the practice from the fields.
      for (let fieldId in state.targetPractices) {
        if (plan.practices[fieldId] === practice) {
          plan.practices[fieldId] = CarbonPractice.NoValue;
          state.targetPractices[fieldId].practices = CarbonPractice.NoValue;
        }
      }
    },
    updatePractice: (
      state,
      action: PayloadAction<{oldPractice: CarbonPractice; newPractice: CarbonPractice}>
    ) => {
      const plan = state.plans[state.activePlan];

      // Update the practice.
      const index = plan.carbonPractices.indexOf(action.payload.oldPractice);
      const newPractices = [...plan.carbonPractices];
      newPractices.splice(index, 1, action.payload.newPractice);
      plan.carbonPractices = newPractices;

      // Update active practice if it was changed.
      if (plan.activeCarbonPractice === action.payload.oldPractice) {
        plan.activeCarbonPractice = action.payload.newPractice;
      }

      // Update the practice on the fields.
      for (let fieldId in state.targetPractices) {
        if (plan.practices[fieldId] === action.payload.oldPractice) {
          plan.practices[fieldId] = action.payload.newPractice;
          state.targetPractices[fieldId].practices = action.payload.newPractice;
        }
      }
    },
    setActivePlan: (state, action: PayloadAction<CarbonPlanType>) => {
      state.activePlan = action.payload;
      const plan = state.plans[state.activePlan];

      // Update the practice on the fields.
      // Nor for one of the plans, but the general ones, that are used in request.
      for (let fieldId in state.targetPractices) {
        state.targetPractices[fieldId].practices = plan.practices[fieldId];
      }
    },
    removeFields: (state, action: PayloadAction<{fieldIds: number[]}>) => {
      const enrolledFields = {...state.enrolledFields};

      Object.keys(enrolledFields).forEach((fieldId: string) => {
        if (action.payload.fieldIds.includes(parseInt(fieldId))) {
          delete enrolledFields[parseInt(fieldId)];
        }
      });

      state.enrolledFields = enrolledFields;
    },
    setPlanId: (state, action: PayloadAction<number>) => {
      state.planId = action.payload;
    },

    setEnrollFields: (state, action: PayloadAction<{[fieldId: number]: boolean}>) => {
      state.enrolledFields = {...action.payload};
    },
  },
  // extraReducers: builder => {
  //   builder.addCase(saveCarbonPlan.fulfilled, (state, action) => {
  //     state.planId = action.payload;
  //   });
  // },
});

export const {
  updateStep,
  setPlan,
  setYear,
  setQuarter,
  setActiveCarbonPractice,
  setTargetPractices,
  enrollFields,
  toggleGeometry,
  assignPracticeToField,
  setGuessedPractices,
  addPractice,
  removePractice,
  updatePractice,
  setActivePlan,
  removeFields,
  setEnrollFields,
  setPlanId,
  setSignedDocumentData,
} = carbonSlice.actions;
