import {RequestStatus} from 'types';

export type OptisState = {
  status: RequestStatus;
  geometries: {[id: number]: GeoJSON.Feature};
  meta: {[areaType in OptisAreaType]: {[id: number]: OptisMeta}};
  tillage: {[id: number]: {[year: number]: OptisItemTillage}};
  winterCrop: {[id: number]: {[year: number]: OptisItemWinterCrop}};
  parentId?: number;
  areaType: OptisAreaType;
  filter: OptisFilter;
  // Diff mode (change map) means that we show the diff of each stat
  // between the diffYearA and diffYearB.
  diffMode: boolean;
  diffYearA?: number;
  diffYearB?: number;
  avgMode: boolean; // whether the charts should show aggregated average stacked single bar chart (true) or bar charts with years (false)
};

export type OptisMeta = {
  name: string;
};

export type OptisDataType = 'meta' | 'tillage' | 'winterCrop' | 'geometries';

export enum OptisType {
  Tillage = 'tillage',
  WinterCrop = 'winterCrop',
}

export type OptisFilter =
  | {
      type: OptisType.Tillage;
      years: number[]; // treat empty year list as "all the years"
      value: Tillage[];
      geometries: number[];
    }
  | {
      type: OptisType.WinterCrop;
      years: number[]; // treat empty year list as "all the years"
      value: WinterCropType[];
      geometries: number[];
    };

export type OptisCounty = {
  countyFIPS: number;
  countyName: string;
  crdName: string;
  stateName: string;
  year: number;
  previousSummerCrop: string;
  winterCrop: WinterCropType;
  currentSummerCrop: string;
  residueCover: ResidueCover;
  tillage: Tillage;
  areaAc: number;
  areaHa: number;
};

/**
 * The value is in Acres.
 */
export type OptisItemTillage = {
  type: OptisType.Tillage;
  [Tillage.Conservation]: number;
  [Tillage.High]: number;
  [Tillage.Low]: number;
  [Tillage.Conventional]: number;
  [Tillage.NoTill]: number;
  // [Tillage.NoData]: number;
};

/**
 * The value is in Acres.
 */
export type OptisItemWinterCrop = {
  type: OptisType.WinterCrop;
  [WinterCropType.Perennial]: number;
  [WinterCropType.WinterCommodity]: number;
  [WinterCropType.CoverCrop]: number;
  [WinterCropType.NoCoverCrop]: number;
  // [WinterCropType.NoData]: number;
};

export type OptisAreaType = 'State' | 'CRD' | 'County' | 'Segment' | 'HUC8' | 'HUC10' | 'HUC12';
export const optisAreaTypeOrder: OptisAreaType[] = ['State', 'CRD', 'County', 'Segment'];

export const geometryIdMap: {[areaType in OptisAreaType]: string} = {
  State: 'STATE_FIPS',
  CRD: 'STASD_A',
  County: 'FIPS',
  Segment: 'segment_id',
  HUC8: 'HUC_CODE',
  HUC10: 'HUC_CODE',
  HUC12: 'HUC_CODE',
};

export enum WinterCropType {
  Perennial = 'Perennial',
  WinterCommodity = 'WinterCommodity',
  CoverCrop = 'CC',
  NoCoverCrop = 'NoCC',
  // NoData = 'NoData',
}

export const winterCropOrder = [
  WinterCropType.Perennial,
  WinterCropType.WinterCommodity,
  WinterCropType.CoverCrop,
];

export const winterCropFullOrder = [
  WinterCropType.Perennial,
  WinterCropType.WinterCommodity,
  WinterCropType.CoverCrop,
  WinterCropType.NoCoverCrop,
];

export const winterCropLabels = {
  [WinterCropType.Perennial]: 'Perennial',
  [WinterCropType.WinterCommodity]: 'Winter Commodity',
  [WinterCropType.CoverCrop]: 'Cover Crop',
  [WinterCropType.NoCoverCrop]: 'No Winter Crop',
  // [WinterCropType.NoData]: 'No Data',
};

export const winterCropColor = {
  [WinterCropType.Perennial]: '#ffbb00',
  [WinterCropType.WinterCommodity]: '#375e97',
  [WinterCropType.CoverCrop]: '#37681c',

  // [WinterCropType.Perennial]: 'rgb(240, 120, 24)',
  // [WinterCropType.WinterCommodity]: 'rgb(140, 116, 181)',
  // [WinterCropType.CoverCrop]: 'rgb(76, 176, 99)',
  [WinterCropType.NoCoverCrop]: '#777',
  // [WinterCropType.NoData]: '#777',
};

export const winterCropColorScale = {
  [WinterCropType.Perennial]: ['#ffffff', winterCropColor[WinterCropType.Perennial]],
  [WinterCropType.WinterCommodity]: ['#ffffff', winterCropColor[WinterCropType.WinterCommodity]],
  [WinterCropType.CoverCrop]: ['#ffffff', winterCropColor[WinterCropType.CoverCrop]],
  [WinterCropType.NoCoverCrop]: ['#ffffff', winterCropColor[WinterCropType.NoCoverCrop]],

  // [WinterCropType.Perennial]: 'YlOrBr',
  // [WinterCropType.WinterCommodity]: 'BuPu',
  // [WinterCropType.CoverCrop]: 'YlGn',
  // [WinterCropType.NoCoverCrop]: 'Greys',

  // [WinterCropType.NoData]: 'Greys',
};

export enum ResidueCover {
  High = 'HighRC',
  Moderate = 'ModRC',
  Low = 'LowRC',
  VeryLow = 'VLowRC',
  NoData = 'NoData',
}

export enum Tillage {
  Conservation = 'ConsTill', // this is a custom value, a sum of NoTill + High
  NoTill = 'NoTill',
  High = 'RedTillHighRC',
  Low = 'RedTillLowRC',
  Conventional = 'ConvTill',
  // NoData = 'NoData',
}

export const tillageOrder: [Tillage.Conventional, Tillage.Low, Tillage.Conservation] = [
  Tillage.Conventional,
  Tillage.Low,
  Tillage.Conservation,
];

export const tillageLabels = {
  [Tillage.Conservation]: 'Conservation Tillage',
  [Tillage.NoTill]: 'No Tillage',
  [Tillage.High]: 'Reduced Tillage High Residue',
  [Tillage.Low]: 'Reduced Tillage',
  [Tillage.Conventional]: 'Conventional Tillage',
  // [Tillage.NoData]: 'No data',
};

export const tillageColor = {
  [Tillage.Conservation]: '#5cc5ef',
  [Tillage.NoTill]: '#5cc5ef',
  [Tillage.High]: '#344d90',
  [Tillage.Low]: '#ffb745',
  [Tillage.Conventional]: '#e7552c',

  // [Tillage.NoTill]: '#6baed6',
  // [Tillage.High]: '#74c476',
  // [Tillage.Low]: '#fd8d3c',
  // [Tillage.Conventional]: '#cb181d',

  // [Tillage.NoData]: '#777',
};

export const tillageColorScale = {
  [Tillage.Conservation]: ['#ffffff', tillageColor[Tillage.Conservation]],
  [Tillage.NoTill]: ['#ffffff', tillageColor[Tillage.NoTill]],
  [Tillage.High]: ['#ffffff', tillageColor[Tillage.High]],
  [Tillage.Low]: ['#ffffff', tillageColor[Tillage.Low]],
  [Tillage.Conventional]: ['#ffffff', tillageColor[Tillage.Conventional]],

  // [Tillage.NoTill]: 'Blues',
  // [Tillage.High]: 'Greens',
  // [Tillage.Low]: 'Oranges',
  // [Tillage.Conventional]: 'Reds',

  // [Tillage.NoData]: 'Greys',
};
