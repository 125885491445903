import React, {useEffect} from 'react';
import SelectorField from './selector-fields';
import SelectorFarms from './selector-farms';
import {FarmsDropdown} from 'containers/map/features/crop-performance/farms-dropdown';
import {FieldsDropdown} from 'containers/map/features/zoning/tree-detection-whole-farm-fields-dropdown';
import {AddingFieldsFromMapSteps, AddingFieldsPreviewSteps} from '../../modules/add-fields.module';
import {useAppSelector} from '_hooks';

/**
 * Sets currentGroupId based on the url params and shows farm toolbar.
 */
export const FarmToolbar = () => {
  const feature = useAppSelector(s => s.map.feature);
  const {isWholeFarmView} = useAppSelector(s => s.map.wholeFarm);
  const addFieldCurrentStep = useAppSelector(s => s.addFields.addFieldCurrentStep);
  const farmId = useAppSelector(s => s.global.currentGroupId);

  const activeAddingFields = [...AddingFieldsFromMapSteps, ...AddingFieldsPreviewSteps].includes(
    addFieldCurrentStep
  );

  return (
    <div className="farm-toolbar onboarding__select-farm-field">
      {feature === 'crop-performance' ? <FarmsDropdown /> : <SelectorFarms farmId={farmId} />}

      {!activeAddingFields && feature !== 'crop-performance' && farmId ? (
        feature === 'zoning' && isWholeFarmView ? (
          <FieldsDropdown />
        ) : (
          <SelectorField />
        )
      ) : null}
    </div>
  );
};

export default FarmToolbar;
