import * as React from 'react';
import cn from 'classnames';
import './sticky.scss';

/**
 * Sticky container that sets the class `stuck` when its scrolled to "stuck" position.
 */

type Props = React.PropsWithChildren<{
  className?: string;
  position?: Position;
  elevateStuck?: boolean;
}>;

export const Sticky = ({
  children,
  className = '',
  position = Position.Bottom,
  elevateStuck = true,
}: Props) => {
  const [stuck, setStuck] = React.useState(false);
  const ref = React.useRef();
  React.useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(([e]) => setStuck(e.intersectionRatio < 1), {
      threshold: [1],
    });
    observer.observe(cachedRef);
    return () => {
      observer.unobserve(cachedRef);
    };
  }, []);
  return (
    <div
      ref={ref}
      className={cn({
        'fluro-sticky': true,
        'stuck-elevated': elevateStuck && stuck,
        stuck,
        [position]: true,
        [className]: true,
      })}
    >
      {children}
    </div>
  );
};

enum Position {
  Bottom = 'bottom',
}
