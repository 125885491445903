import React, {ReactNode, ReactElement, MouseEventHandler} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import './index.scss';
import {useDispatch, useSelector} from 'react-redux';
import {formatDate} from '_utils';
import cn from 'classnames';

import DatePicker, {CalendarContainer} from 'react-datepicker';
import {showNote} from '_actions';
import moment, {Moment} from 'moment';
import {AppStore} from '../../reducers';
import {FontIcon, Portal} from 'react-md';

type Props = {
  id: string;
  name?: string;
  label?: string;
  selected: Moment;
  portal?: boolean; // if true is provided the dialog-root will be used as the portal container, otherwise a normal datepicker will be rendered
  hideFormat?: boolean;
  disabled?: boolean;
  excludeDates?: Date[]; // dates to disable
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  placeholderText?: string;
  highlightDates?: Array<{[className: string]: Date[]} | Date>;
  minDate?: Moment;
  maxDate?: Moment;
  autoComplete?: string;
  className?: string;
  error?: boolean;
  errorText?: string;
  onDisabledClickMessage?: string;
  hasPopper?: boolean;
  calendarContainer?(props: {children: React.ReactNode[]}): React.ReactNode;
  onChange(date: Moment): void;
  onChangeRaw?(event: React.FocusEvent<HTMLInputElement>): void;
};

const CalendarPopper = ({children}: any) => {
  return <Portal visible>{children}</Portal>;
};

const FluroDatePicker = ({
  id,
  name,
  selected,
  portal,
  placeholderText,
  highlightDates,
  excludeDates,
  onChange,
  onDisabledClickMessage,
  disabled,
  className = '',
  label,
  autoComplete,
  minDate = moment('07-01-2015'),
  maxDate = moment().add(2, 'years'),
  hideFormat,
  error,
  errorText,
  showMonthDropdown = true,
  showYearDropdown = true,
  calendarContainer = null,
  hasPopper = false,
}: Props) => {
  const dispatch = useDispatch();
  const userLocale = useSelector((s: AppStore) => s.login.user.settings.locale);
  const onContainerClick = (e: MouseEvent) => {
    const isOnDisable = (e.target as any).closest('.react-datepicker__day--disabled');

    if (isOnDisable) {
      onDisabledClickMessage &&
        dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: onDisabledClickMessage,
            level: 'warning',
          })
        );
    }
  };

  // @ts-ignore
  const customArrows = {
    nextMonthButtonLabel: <FontIcon>keyboard_arrow_right</FontIcon>,
    previousMonthButtonLabel: <FontIcon>keyboard_arrow_left</FontIcon>,
  } as {nextMonthButtonLabel: string; previousMonthButtonLabel: string};

  return (
    <div
      onClick={(ev: any) => onContainerClick(ev)}
      className={cn(
        `fluro-datepicker md-text-field-container md-full-width md-text-field-container--input md-pointer--hover ${className}`,
        {disabled}
      )}
    >
      <label className="md-floating-label md-floating-label--floating md-floating-label--icon-offset md-text--secondary">
        {label} {hideFormat ? null : formatDate()}
      </label>
      <div className="md-text-field-icon-container">
        <i className="md-icon material-icons md-text-field-icon md-text-field-icon--positioned">
          date_range
        </i>
        <div className="md-text-field-divider-container md-text-field-divider-container--grow">
          {
            <DatePicker
              id={id}
              name={name || id}
              selected={selected && selected.isValid() ? selected?.toDate() : null}
              dateFormat={getDateFormat(userLocale)}
              highlightDates={highlightDates}
              excludeDates={excludeDates}
              // locale={browserLocale()} // to be implemented with the internationalization
              onChange={date =>
                Array.isArray(date) ? onChange(moment(date[0])) : onChange(moment(date))
              }
              autoComplete={autoComplete}
              placeholderText={placeholderText}
              showMonthDropdown={showMonthDropdown}
              showYearDropdown={showYearDropdown}
              minDate={minDate.toDate()}
              maxDate={maxDate.toDate()}
              className="md-text-field md-text-field--floating-margin md-full-width md-text md-pointer--hover"
              calendarContainer={calendarContainer}
              showPopperArrow={false}
              portalId={portal ? 'dialog-root' : ''}
              popperContainer={hasPopper ? CalendarPopper : undefined}
              disabled={disabled}
              {...customArrows}
            />
          }

          <hr
            className={cn('md-divider md-divider--text-field md-divider--expand-from-left', {
              'md-divider--error': error,
            })}
          />
        </div>
      </div>

      {error && errorText ? (
        <div className="md-text-field-message-container md-text-field-message-container--left-icon-offset md-full-width md-text--error">
          <div className="md-text-field-message md-text-field-message--active">{errorText}</div>
        </div>
      ) : null}
    </div>
  );
};

export const FluroCalendarContainer = ({
  defaultCalendar,
  children,
  className = '',
}: {
  className?: string;
  defaultCalendar?: ReactNode;
  children: ReactElement;
}) => {
  return (
    // @ts-ignore
    <CalendarContainer className={`react-datepicker ${className}`}>
      <div>{defaultCalendar}</div>
      {children}
    </CalendarContainer>
  );
};

const getDateFormat = (userLocale: string) => {
  // we need this function because the datepicker doesn't support DD or YYYY date formats and throws an error
  let dateFormat = 'dd MMM yyyy';
  if (predefinedDateFormats[userLocale]) dateFormat = predefinedDateFormats[userLocale];

  return dateFormat;
};

const predefinedDateFormats = {
  'en-US': 'MM/dd/yyyy',
  'uk-UA': 'dd MMM yyyy',
} as {[locale: string]: string};
export default FluroDatePicker;
