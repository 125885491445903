import {InitialUser, LoginActionTypes, LoginState} from './types';
import {getGetURLParam, isAdminPerm} from '../../_utils';
import {DEFAULT_LOCALE} from '../../i18n-utils';
import {FarmActionTypes} from '../farm/types';

export const initialState: LoginState = {
  message: [''],

  user: {...InitialUser},

  isAuth: false,
  token: '',
  farmEndDialog: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN:
      const {user, token} = action.response;
      return {
        ...state,
        isAuth: true,
        user: {
          ...user,
          demoFarms: initialState.user.demoFarms,
          groupIds: user.groupIds?.length
            ? user.groupIds
            : isAdminPerm(user.perm)
            ? [136] // manually assign a demo farm for an admin user without farms
            : user.groupIds,
          settings: {
            ...initialState.user.settings,
            ...user.settings,
            locale: user.settings.locale || initialState.user.settings.locale,
            langLocale:
              getGetURLParam('i18n_lang') ||
              (getGetURLParam('i18n')
                ? user.settings.langLocale || initialState.user.settings.langLocale
                : DEFAULT_LOCALE),
            measurement: user.settings.measurement || initialState.user.settings.measurement,
            timezone: user.settings.timezone || initialState.user.settings.timezone,
          },
        },
        token,
      };

    case LoginActionTypes.UPDATE_USER_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...initialState.user.settings,
            ...action.settings,
          },
        },
      };

    case LoginActionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
        },
      };

    case LoginActionTypes.LOGOUT:
      return {
        ...state,
        isAuth: false,
      };

    case LoginActionTypes.ERROR:
      const {status, message} = action.response;
      return Object.assign({}, state, {
        status: status,
        message: message,
      });

    case FarmActionTypes.CREATE_FARM: {
      return {
        ...state,
        user: {
          ...state.user,
          groupIds: [...state.user.groupIds, action.farm.id],
        },
      };
    }

    default:
      return state;
  }
};
