import {t, FormattedMessage} from 'i18n-utils';
import Ln from 'components/ln';
import {
  ShapeIcon,
  EyeIcon,
  SatelliteIcon,
  PlaneIcon,
  CropStressIcon,
  DroneImage,
  AnalyticsIcon,
  TrialIcon,
  RemoteSensingIcon,
} from '../icons.styled';
import React from 'react';
import {Props, Messages} from '../types';
import InfoBlock from '../../../components/info-block';
import {Footer} from '../helpers';

const data: Messages = {
  // ------- 1_Corn farm demo:
  160206: {
    title: 'Clogged pivot irrigation',
    width: 430,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            Here is a <span>corn pivot</span> with some <span>clogged nozzles</span>. 10-25% of
            pivots have this issue. So check yours!
            <br />
            <br />
            To show detected “rings”:
            <br />
            <b style={{color: '#43a047'}}>1</b>. click on the Drawing tools icon <ShapeIcon /> in
            the map menu on lower left,
            <br />
            <b style={{color: '#43a047'}}>2</b>. toggle <EyeIcon /> show/hide shapes.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            To see the “ring” you will need to switch from NDVI to the thermal layer (TIRS), which
            captures heat stress before is shows in NDVI.{' '}
            <Ln
              external
              blank
              href="https://flurosat.com/news/flurosat-releases-pivothermo-a-new-trusted-tool-for-automated-pivot-monitoring"
            >
              Read about how PivoThermo works here.
            </Ln>
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  185398: {
    title: 'Corn greensnap',
    width: 430,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            <CropStressIcon /> <b>Crop stress feature</b> detects the crop damage in the field,
            estimates the % of damaged field area and tracks it over time. Growers can present this
            crop damage to claim crop insurance.
            <br />
            <br />
            To see how crop stress evolves over time click on the “shape” on the <b>map</b>, then in
            the <b>date dropdown</b> choose <SatelliteIcon /> satellite or <PlaneIcon /> aerial
            imagery.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            <b>Crop Stress</b> detects areas of the field with unusual crop growth, by tracking crop
            vigour, chrolophyll and how they change over time.{' '}
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  185399: {
    title: 'Corn greensnap',
    width: 430,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            <CropStressIcon /> <b>Crop stress feature</b> detects the crop damage in the field,
            estimates the % of damaged field area and tracks it over time. Growers can present this
            crop damage to claim crop insurance.
            <br />
            <br />
            To see how crop stress evolves over time click on the “shape” on the <b>map</b>, then in
            the <b>date dropdown</b> choose <SatelliteIcon /> satellite or <PlaneIcon /> aerial
            imagery.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            <b>Crop Stress</b> detects areas of the field with unusual crop growth, by tracking crop
            vigour, chrolophyll and how they change over time.{' '}
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  172266: {
    title: 'Drone imagery',
    width: 560,
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div style={{paddingRight: 20}}>
            <DroneImage />
          </div>
          <div>
            You can upload <b>your own drone imagery</b> to monitor a specific area in your field
            and combine it with stress detection from satellite imagery.
            <br />
            <br />
            Use <b>Compare toggle</b> on <RemoteSensingIcon /> Remote Data Layers tab to switch
            between the satellite and drone imagery and see what difference the high resolution
            imagery makes.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Want to maximise the value of drone flights? Use satellite imagery to <b>locate</b> crop
            stress, and <b>export</b> it as shapefiles for <b>drone flight planning</b>.<br />
            <Ln external blank href="https://flurosat.com/drones-for-farming-guide">
              For more tips on how to best use drone imagery read our Drone guide.
            </Ln>
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },

  153851: {
    title: 'Monitoring efficacy of Nitrogen trials',
    element: (props: Props) => (
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div />
          <div>
            <b>Nitrogen trials</b> are popular across the crop types as a way to optimise nitrogen
            use efficiency by assessing the crop response.
            <br />
            <br />
            The N “strips” or “checks” are usually put in several areas of the field and the same
            application rate <TrialIcon /> is repeated in several places to validate the consistency
            of the plant response.
          </div>
        </div>

        <InfoBlock appearance="info">
          <i>
            Want to <b>visualise the crop biomass and chlorophyll response</b> to  N trials and see
            which N rate worked best? Draw or import the trial boundaries, and use <AnalyticsIcon />
            Analytics tab to quickly plot the charts.
          </i>
        </InfoBlock>

        <Footer {...props} />
      </div>
    ),
  },
};

export default data;
