import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadUserAccess, setRoles} from './access-view.module';
import {AppStore} from 'reducers';
import {PermissionLabel, Title, FarmId} from './access-view.styled';
import Ln from 'components/ln';
import {FluroTableColumn, FluroTableRow, FluroDataTable, FluroTableBody} from 'components';
type Props = {
  userId: number;
};

const AccessView = ({userId}: Props) => {
  const dispatch = useDispatch();

  const roles = useSelector((state: AppStore) => state.admin.userAccess.roles);

  useEffect(() => {
    userId && dispatch(loadUserAccess(userId));

    return () => {
      dispatch(
        setRoles({
          subs: [],
          orgs: [],
          farms: [],
        })
      );
    };
  }, [userId]);

  return (
    <>
      <FluroDataTable className="access-table" elevated={false} style={{width: '100%'}}>
        <FluroTableBody>
          {[
            <FluroTableRow key={'Subscriptions-key'}>
              <FluroTableColumn colSpan={2}>
                <Title>Subscriptions</Title>
              </FluroTableColumn>
            </FluroTableRow>,

            ...(roles.subs.length
              ? roles.subs.map(item => (
                  <FluroTableRow key={item.entityId}>
                    <FluroTableColumn>
                      <span>
                        {item.entityName} <span>(#{item.entityId})</span>
                      </span>
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <div>
                        {item.roles.map(p => (
                          <PermissionLabel key={p} value={p}>
                            {p.toUpperCase()}
                          </PermissionLabel>
                        ))}
                      </div>
                    </FluroTableColumn>
                  </FluroTableRow>
                ))
              : [
                  <FluroTableRow key={'no-assigned-subs'}>
                    <FluroTableColumn colSpan={2}>
                      <i>No assigned subscription roles/permissions</i>
                    </FluroTableColumn>
                  </FluroTableRow>,
                ]),

            <FluroTableRow key={'Organizations-key'}>
              <FluroTableColumn colSpan={2}>
                <Title>Organizations</Title>
              </FluroTableColumn>
            </FluroTableRow>,

            ...(roles.orgs.length
              ? roles.orgs.map(item => (
                  <FluroTableRow key={item.entityId}>
                    <FluroTableColumn>
                      <span>
                        {item.entityName} <span>(#{item.entityId})</span>
                      </span>
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <div>
                        {item.roles.map(p => (
                          <PermissionLabel key={p} value={p}>
                            {p.toUpperCase()}
                          </PermissionLabel>
                        ))}
                      </div>
                    </FluroTableColumn>
                  </FluroTableRow>
                ))
              : [
                  <FluroTableRow key={'no-assigned-organization'}>
                    <FluroTableColumn colSpan={2}>
                      <i>No assigned organization roles/permissions</i>
                    </FluroTableColumn>
                  </FluroTableRow>,
                ]),

            <FluroTableRow key={'Farms-key'}>
              <FluroTableColumn colSpan={2}>
                <Title>Farms</Title>
              </FluroTableColumn>
            </FluroTableRow>,

            ...(roles.farms.length
              ? roles.farms.map(item => (
                  <FluroTableRow key={item.entityId}>
                    <FluroTableColumn>
                      <span>
                        <Ln blank map href={`${item.entityId}`}>
                          {item.entityName}
                        </Ln>{' '}
                        <FarmId>(#{item.entityId})</FarmId>
                      </span>
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <div>
                        {item.roles.map(p => (
                          <PermissionLabel key={p} value={p}>
                            {p.toUpperCase()}
                          </PermissionLabel>
                        ))}
                      </div>
                    </FluroTableColumn>
                  </FluroTableRow>
                ))
              : [
                  <FluroTableRow key={'no-assigned-farms'}>
                    <FluroTableColumn colSpan={2}>
                      <i>No assigned farm roles/permissions</i>
                    </FluroTableColumn>
                  </FluroTableRow>,
                ]),
          ]}
        </FluroTableBody>
      </FluroDataTable>
    </>
  );
};

export default AccessView;
