import {Aggregation, Biomass, biomassColors, biomassLabels, CSGViewModel} from '../types';
import {toFixedFloat, unreachableError} from '_utils/pure-utils';
import {aggregate} from '../helpers';

export const getBiomassChartData = (
  records: CSGViewModel[],
  ndviQuartiles: {[key: string]: NdviQuartiles},
  useUnreliableData: boolean,
  aggregation: Aggregation
) => {
  const values: {[status: string]: {min: number; max: number; count: number}} = {};
  records.forEach(r => {
    const q = ndviQuartiles[`${r.cropType}_${r.cropVariety}`];
    if (!q) {
      return;
    }
    const label =
      r.cropStatus === 'No images' || (!r.reliable && !useUnreliableData)
        ? 'noimages'
        : classifyNdvi(r.smoothSatelliteNdvi, q.smoothSatellite);
    const ndvi = toFixedFloat(r.smoothSatelliteNdvi, 2);
    if (!values[label]) {
      values[label] = {count: 0, min: ndvi, max: ndvi};
    }
    values[label].count += aggregate(aggregation, r);
    if (values[label].min > ndvi) {
      values[label].min = ndvi;
    }
    if (values[label].max < ndvi) {
      values[label].max = ndvi;
    }
  });
  const order = ['low', 'medium', 'high', 'noimages'].filter(l => values[l]) as Biomass[];

  return {
    labels: order.map(s => biomassLabels[s]),
    values: order.map(s => values[s].count),
    colors: order.map(s => biomassColors[s]),
    labelTail: order.map(s =>
      s === 'noimages'
        ? ''
        : values[s].min === values[s].max
        ? `(${values[s].min})`
        : `(${values[s].min} - ${values[s].max})`
    ),
  };
};

export const getBiomassMapChartData = (
  records: CSGViewModel[],
  ndviQuartiles: {[key: string]: NdviQuartiles},
  useUnreliableData: boolean
) => {
  const biomassCount: {[status: string]: number} = {};
  records.forEach(r => {
    const q = ndviQuartiles[`${r.cropType}_${r.cropVariety}`];
    if (!q) {
      return;
    }
    const ndvi =
      r.cropStatus === 'No images' || (!r.reliable && !useUnreliableData)
        ? 'noimages'
        : classifyNdvi(r.smoothSatelliteNdvi, q.smoothSatellite);
    biomassCount[ndvi] = (biomassCount[ndvi] || 0) + 1;
  });
  const order = ['low', 'medium', 'high', 'noimages'].filter(l => biomassCount[l]) as Biomass[];

  const data = order.map(label => ({
    id: label,
    label: biomassLabels[label],
    value: biomassCount[label],
  }));
  const getColor = (slice: any) => biomassColors[slice.id as Biomass];
  return {data, getColor};
};

// -----------------

export const classifyNdvi = (value: number, q: Quartiles): Biomass => {
  if (!q) {
    return 'medium';
  }
  const {Q1, Q3, Q4} = q;
  if (Q1 === Q3 && Q3 === Q4) {
    return 'medium';
  }
  return value <= Q1 ? 'low' : value >= Q3 ? 'high' : 'medium';
};

type Quartiles = {Q1: number; Q3: number; Q4: number};
export type NdviQuartiles = {
  rawPlane: Quartiles;
  rawSatellite: Quartiles;
  smoothSatellite: Quartiles;
};

/**
 * 1. Rank the data from lowest to highest
 * 2. The first 25% of the data = red
 * 3. The next 50% of the data = normal
 * 4. The next 25% of the data = green
 *
 * In other words:
 * Q1 is 25%
 * Q3 is 75%
 *
 * https://en.wikipedia.org/wiki/Quartile
 */
export const calcNdviQuartiles = (records: CSGViewModel[]) => {
  // 1. Extract crop values.
  const ndvis: {
    [key: string]: {
      rawPlane: number[];
      rawSatellite: number[];
      smoothSatellite: number[];
    };
  } = {};
  records.forEach(record => {
    const key = `${record.cropType}_${record.cropVariety}`;
    if (!ndvis[key]) {
      ndvis[key] = {
        rawPlane: [],
        rawSatellite: [],
        smoothSatellite: [],
      };
    }
    ndvis[key].smoothSatellite.push(record.smoothSatelliteNdvi);
  });

  // 2. Calc ndvi quartiles.
  const ndviQuartiles: {[key: string]: NdviQuartiles} = {};
  Object.entries(ndvis).forEach(([key, ndvi]) => {
    ndviQuartiles[key] = {
      rawPlane: calcQuartiles(ndvi.rawPlane),
      rawSatellite: calcQuartiles(ndvi.rawSatellite),
      smoothSatellite: calcQuartiles(ndvi.smoothSatellite),
    };
  });
  return ndviQuartiles;
};

/**
 * Examples:
 *
 * 1 2 3
 *   |
 * 1   3
 * Q1 Q3
 *
 *
 * 1 2 3 4
 *   \ /
 * 1     4
 * Q1   Q3
 *
 *
 * 1 2 3 4 5
 *     |
 * 1 2   4 5
 * \ /   \ /
 * 1.5   4.5
 * Q1     Q3
 *
 *
 * 1 2 3 4 5 6
 *     \ /
 * 1 2     5 6
 * \ /     \ /
 * 1.5     5.5
 * Q1       Q3
 *
 *
 * 1 2 3 4 5 6 7
 *       |
 * 1 2 3   5 6 7
 *   |       |
 *   2       6
 *  Q1       Q3
 *
 *
 * 1 2 3 4 5 6 7 8
 *       \ /
 * 1 2 3     6 7 8
 *   |         |
 *   2         7
 *  Q1         Q3
 *
 *
 * 1 2 3 4 5 6 7 8 9
 *         |
 * 1 2 3 4   6 7 8 9
 *   \ /       \ /
 *   2.5       7.5
 *   Q1         Q3
 */
const calcQuartiles = (list: number[]) => {
  const sorted = list.filter(x => x != null).sort();
  if (sorted.length < 4) {
    return {Q1: sorted[1], Q3: sorted[1], Q4: sorted[1]};
  }
  const even = sorted.length % 2 === 0;
  const mid = sorted.length / 2;
  const leafSize = even ? mid - 1 : Math.floor(mid);
  const Q1 = calcMedian(sorted.slice(0, leafSize));
  const Q3 = calcMedian(sorted.slice(-leafSize));
  return {Q1, Q3, Q4: sorted[sorted.length - 1]};
};

const calcMedian = (list: number[]) => {
  const even = list.length % 2 === 0;
  const mid = list.length / 2;
  const median = even ? (list[mid] + list[mid - 1]) / 2 : list[Math.floor(mid)];
  return median;
};
