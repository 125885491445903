// @ts-ignore
import {EditControl} from 'react-leaflet-draw';
import {t} from 'i18n-utils';
import React, {useEffect} from 'react';
import {deepCopy, genKey, checkForGeometryCollection, booleanIntersectField} from '_utils';
import {splitPolygon} from '_utils/geometry';
import {
  toggleDrawingMode,
  toggleEditingMode,
  setFeature,
  toggleMapBar,
  toggleFieldGeometries,
  toggleTableView,
  setLocationMarkerCoordinates,
} from '../actions';

import {addFieldChangeCurrentStep, addNewFieldGeometry} from 'modules/add-fields.module';

import {
  addDraftAreaOfInterest,
  toggleEditAreaOfInterest,
} from '../actions/areas-of-interest-actions';
import {updateAnalyticPointPosition} from '../actions/analytics-actions';

import {setCurrentMarker, updateTSMarkersPosition} from '../actions/sampling-points';
import {showNote, toggleGlobalDialog} from '_actions';
import {DialogType, toggleDialog} from '_reducers/dialog';
// @ts-ignore Draw is not exposed, but we need to patch it
import {Draw} from 'leaflet';
import booleanContains from '@turf/boolean-contains';
import turfCircle from '@turf/circle';
import {setGeometry} from 'containers/map/features/farm/field/planting-area-season.module';
import {getRandomColor} from '_utils';
import {checkPointForFieldBoundaries} from 'modules/add-fields.module';
import {useAppSelector} from '_hooks';
import {useDispatch} from 'react-redux';
import {booleanPointInPolygon} from '@turf/turf';
import {FieldSystemProp} from '../features/farm/new-fields/types';

enum ErrorMessage {
  PinOutsideField = 'Please place the pin inside the field.',
  PivotOutsideField = 'The centre pivot should be inside the field.',
  PointsAreTooClose = 'The minimum distance between tissue sampling points is 1 meter.',
}

export const EditControlButton = ({leafletElement}: {leafletElement: L.Map}) => {
  const dispatch = useDispatch();
  const field = useAppSelector(s => s.map.field);
  const isWholeFarmView = useAppSelector(s => s.map.wholeFarm.isWholeFarmView);
  const currentSensor = useAppSelector(s => s.map.currentSensor);
  const feature = useAppSelector(s => s.map.feature);
  const currentPointId = useAppSelector(s => s.map.currentPointId);
  const currentSeasonId = useAppSelector(s => s.map.currentSeasonId);
  const currentFieldKml = useAppSelector(s => s.map.currentFieldKml);
  const isReadOnly = useAppSelector(s => s.map.group.readOnly);
  const measurement = useAppSelector(s => s.login.user.settings.measurement);
  const drewFieldsGeometries = useAppSelector(s => s.addFields.drewFieldsGeometries);
  const isMapBarOpen = useAppSelector(s => s.map.isMapBarOpen);
  const pointsGroups = useAppSelector(
    s => s.map.pointsGroups?.[s.map.pointsCurrentGroupDate] || []
  );
  const globalDialogs = useAppSelector(s => s.global.dialogsState);
  const drawControl = useAppSelector(s => s.map.drawControl);
  const editGeometry = useAppSelector(s => s.map.editGeometry);

  useEffect(() => {
    const originalOnTouch = Draw.Polyline.prototype._onTouch;
    Draw.Polyline.prototype._onTouch = (e: any) => {
      if (e.originalEvent.pointerType !== 'mouse' && e.originalEvent.pointerType !== 'touch') {
        return originalOnTouch.call(this, e);
      }
    };
  }, []);

  const onToggleMapBar = (value: boolean) => {
    if (isMapBarOpen !== value) dispatch(toggleMapBar(value));
  };

  const onEditStop = () => {
    if (editGeometry?.geometry?.properties) {
      editGeometry.geometry.properties.saved = undefined;
      dispatch(toggleEditAreaOfInterest(true, editGeometry.geometry));
    }
  };

  const warning = (msg: ErrorMessage) => {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: msg}),
        level: 'warning',
      })
    );
  };

  const onEdited = (e: any) => {
    const tissueSamplingPoints: {[id: string]: L.LatLng} = {};
    const customAnalyticPoints: {[title: string]: L.LatLng} = {};
    let errorTSPDistance = false;

    e?.layers?.eachLayer((l: any) => {
      if (l.fluroGeometryID !== undefined) {
        onToggleMapBar(true);

        let geoJSON = l.toGeoJSON();
        if (l.getRadius) {
          geoJSON = pointToCircle(geoJSON, l.getRadius());
        }
        geoJSON.properties.id = l.fluroGeometryID;

        if (l.geometryType === 'field') {
          createField(geoJSON, l);
        } else {
          dispatch(addDraftAreaOfInterest(geoJSON));
        }
      } else if (l?.fluroId === 'location-marker') {
        // update field boundaries point
        dispatch(checkPointForFieldBoundaries(l._latlng.lat, l._latlng.lng));
        dispatch(setLocationMarkerCoordinates(l._latlng.lat, l._latlng.lng));
      } else {
        onToggleMapBar(true);
        if (l && l.getLatLng && l.fluroId) {
          if (!fieldContains(currentFieldKml, l.toGeoJSON())) {
            warning(ErrorMessage.PinOutsideField);
            dispatch(toggleEditingMode(false));
            return;
          }

          tissueSamplingPoints[l.fluroId] = l.getLatLng();

          if (pointsAreTooClose(l, pointsGroups)) {
            errorTSPDistance = true;
            warning(ErrorMessage.PointsAreTooClose);
            return;
          }
        }
      }
      if (l.options.title && l.options.title.includes('#')) {
        customAnalyticPoints[l.options.title] = l.getLatLng();
      }
    });

    if (errorTSPDistance) {
      dispatch(toggleEditingMode(false));
      return;
    }

    if (Object.keys(tissueSamplingPoints).length) {
      dispatch(updateTSMarkersPosition(tissueSamplingPoints));
    }

    if (Object.keys(customAnalyticPoints).length) {
      dispatch(updateAnalyticPointPosition(customAnalyticPoints));

      if (!Object.keys(tissueSamplingPoints).length) {
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'Your pin was saved'}),
            level: 'success',
          })
        );
      }
    }
  };

  const onCreated = (e: any) => {
    const {drawingEntity, drawingModeLayerType} = drawControl;
    dispatch(toggleDrawingMode(false, drawingModeLayerType));

    let geoJSON = deepCopy(e.layer.toGeoJSON());

    // Tissue Sampling Point || Pivot
    if (e.layerType === 'marker') {
      drawingEntity === 'pivot' ? setFieldPivot(geoJSON, e) : createTSP(geoJSON, e);
      return;
    }

    if (e.layerType === 'polyline') {
      leafletElement?.removeLayer(e.layer);

      splitPolygon(currentFieldKml?.geometry, geoJSON.geometry).forEach(polygon => {
        createSeasonGeometry(polygon, e.layer);
      });
      return;
    }

    const fluroGeometryID =
      drawingEntity === 'farm' || drawingEntity === 'seasonGeometry'
        ? genKey()
        : newFluroGeometryID;

    e.layer.fluroGeometryID = fluroGeometryID;
    geoJSON.properties.id = fluroGeometryID;
    geoJSON.properties[FieldSystemProp.FieldName] = `Field ${drewFieldsGeometries.length + 1}`;

    if (e.layerType === 'circle') {
      geoJSON = pointToCircle(geoJSON, e.layer.getRadius());
    }

    const intersectGeometryCollectionOrGeometry = booleanIntersectField(geoJSON);

    // Planting area.
    if (drawingEntity === 'seasonGeometry') {
      geoJSON.properties.name = 'Geometry';
      geoJSON.properties.fill = getRandomColor();
      createSeasonGeometry(geoJSON, e.layer);
      return;
    }

    // Field.
    if (
      drawingEntity === 'farm' ||
      !currentFieldKml.geometry ||
      !intersectGeometryCollectionOrGeometry
    ) {
      createField(geoJSON, e.layer);
      e.layer.remove();
      return;
    }

    // Area of Interest.
    createAOI(geoJSON, e.layer);
    return;
  };

  const setFieldPivot = (geoJSON: GeoJSON.Feature, e: any) => {
    if (!fieldContains(currentFieldKml, geoJSON)) {
      leafletElement?.removeLayer(e.layer);
      dispatch(toggleDrawingMode(true, 'marker', 'pivot'));
      warning(ErrorMessage.PivotOutsideField);
      return;
    }

    leafletElement?.removeLayer(e.layer);
    onToggleMapBar(true);

    const popupToOpen = globalDialogs.editField?.visible ? 'editField' : 'editPivotTableView';
    const {lat, lng} = e.layer.getLatLng();
    const popupOptions = {visible: true, PivotCenterCoordinates: {Lat: lat, Lon: lng}};
    dispatch(toggleGlobalDialog(popupToOpen, popupOptions));
    if (popupToOpen === 'editPivotTableView') {
      toggleTableView('farm');
    }
  };

  const createTSP = (geoJSON: GeoJSON.Feature, e: any) => {
    if (pointsAreTooClose(e.layer, pointsGroups)) {
      leafletElement?.removeLayer(e.layer);
      warning(ErrorMessage.PointsAreTooClose);
      return;
    }

    if (!fieldContains(currentFieldKml, geoJSON)) {
      leafletElement?.removeLayer(e.layer);
      warning(ErrorMessage.PinOutsideField);
      return;
    }

    if (feature !== 'tsp') {
      dispatch(setFeature('tsp'));
    }

    leafletElement?.removeLayer(e.layer);
    const {lat, lng} = e.layer.getLatLng();
    dispatch(setCurrentMarker('new', [lat, lng]));
    onToggleMapBar(true);
  };

  const createField = (geoJSON: GeoJSON.Feature, layer: any) => {
    layer.geometryType = 'field';
    if (feature !== 'farm') {
      dispatch(setFeature('farm'));
    }
    dispatch(addNewFieldGeometry(geoJSON));
    dispatch(addFieldChangeCurrentStep('view-drew-fields'));
    dispatch(toggleDialog(DialogType.AddNewField, true));
  };

  const createAOI = (geoJSON: any, layer: any) => {
    layer.geometryType = 'geometry';
    if (feature !== 'crop') {
      dispatch(setFeature('crop'));
    }
    dispatch(addDraftAreaOfInterest(geoJSON));
    onToggleMapBar(true);
    dispatch(toggleFieldGeometries(true));
  };

  // Plantint area.
  const createSeasonGeometry = (geoJSON: GeoJSON.Feature, layer: any) => {
    layer.isSeasonGeometry = true;
    dispatch(setGeometry(geoJSON));
  };

  const isEnabledControls = currentSensor !== 'NMAP' && !isReadOnly;

  if (!leafletElement) {
    return null;
  }

  return (
    <EditControl
      //todo: figure out how to avoid key generation
      key={genKey()}
      position="topleft"
      onCreated={onCreated}
      onEdited={onEdited}
      onEditStop={onEditStop}
      draw={{
        rectangle: false,
        polyline: true,
        circlemarker: false,
        polygon: isEnabledControls,
        circle: isEnabledControls ? {metric: measurement === 'ha'} : false,
        marker:
          drawControl.drawingEntity === 'pivot' ||
          Boolean(
            currentPointId !== 'new' && // allow adding only one TSP per time
              currentSeasonId && // TSPs are stored in a field season
              field.ID &&
              !isWholeFarmView
          ),
      }}
    />
  );
};

export default EditControlButton;

const newFluroGeometryID = 0;

const pointToCircle = (geoJSON: any, radius: number) => {
  return turfCircle(geoJSON, radius, {
    units: 'meters',
    properties: {
      ...geoJSON.properties,
      radius,
    },
  });
};

const fieldContains = (field: any, geoJSON: GeoJSON.Feature) => {
  const isGeometryCollection = checkForGeometryCollection(field);

  return isGeometryCollection !== undefined && isGeometryCollection
    ? field.geometry.geometries.some((g: any) => {
        return booleanContains(g, geoJSON);
      })
    : booleanContains(field, geoJSON);
};

const pointsAreTooClose = (layer: any, points: any[]) => {
  // make circle with radius 3 meters;
  const circle = turfCircle(layer.toGeoJSON(), 1, {units: 'meters'});

  // TODO: move point Id to Properties
  return points
    .filter((p: any) => p.id !== layer.fluroId)
    .some(point =>
      booleanPointInPolygon([point.geometry.coordinates[1], point.geometry.coordinates[0]], circle)
    );
};
