import React, {ReactElement} from 'react';
import {ThunkAction} from 'redux-thunk';
import Mixpanel from '_utils/mixpanel-utils';
import {
  LoginState,
  LoginResponse,
  LoginPayload,
  LoginActionTypes,
  UpdatePasswordPayload,
  IUserSettings,
  IUserOnBoarding,
  IUser,
} from './types';

import {AuthApi, UserApi} from '_api';
import {hardClear} from 'containers/map/actions';
import {push} from 'react-router-redux';
import config from '_environment';
import {loadCropTypes, setGlobalParam, showNote, toggleSessionExpired} from '_actions';
import {getFarmsList, selectFarm} from 'containers/farm/actions';
import {Farm} from '../farm/types';
import {IInitialMapState} from '../map/types';
import {Ln} from 'components';
import {reportError} from '../error-boundary';
import {AppStore} from 'reducers';
import {retry} from '_api';
import {getDataFromRedirectUrl} from '../../_utils';
import {t, ALLOWED_LOCALES, Locales} from 'i18n-utils';
import {Dispatch, Action} from 'redux';
import {dialogToggle, DialogType} from '../../modules/ui-helpers';

const isCarbonDomain = config.featurePack === 'carbon';

export const setLogin = (response: {token: string; user: IUser}, loginType: string) => (
  dispatch: any
) => {
  Mixpanel.login(response?.user, loginType);
  dispatch({
    type: LoginActionTypes.LOGIN,
    response,
  });
};

//TODO: add dispatch generics

export const authenticate = (credentials: LoginPayload) => (
  dispatch: any,
  getStore: () => AppStore
) => {
  return AuthApi.authenticate(credentials).then(
    async ({data}: LoginResponse<Pick<LoginState, 'user' | 'token'>>) => {
      localStorage.setItem('token', data.result.token);

      if (!data.result.token || !data.result.user.active) {
        dispatch(
          showNote({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({
              id: 'notActiveAccount',
              defaultMessage: 'Your account is not active, please contact support@flurosat.com',
            }),
            level: 'info',
            autoDismiss: 30,
          })
        );
      } else {
        const {
          data: {result: profileResult},
        } = await UserApi.getProfile(); // get user data only from the profile request

        await dispatch(loadCropTypes());

        dispatch(getFarmsList());

        let {farmId} = getDataFromRedirectUrl();
        const groupIds = profileResult.user?.groupIds || [];
        farmId = farmId || groupIds[0];

        if (!isCarbonDomain && farmId) {
          dispatch(setGlobalParam('currentGroupId', farmId));
        }

        const localStoredLocale = localStorage.getItem('lang') as Locales;

        if (
          ALLOWED_LOCALES.includes(localStoredLocale) &&
          !profileResult.user.settings.langLocale
        ) {
          profileResult.user.settings.langLocale = localStoredLocale;
        }

        //hide login dialog for carbon domain
        dispatch(dialogToggle(DialogType.carbonLogin, false));
        dispatch(setLogin(profileResult, 'Login form'));
      }

      getStore().global.sessionExpiredRequests.forEach(retry);
      dispatch(toggleSessionExpired(false));

      return Promise.resolve();
    }
  );
};

export const oAuthLogin = (source: string, code: string) => (dispatch: any) => {
  //@ts-ignore
  if (!AuthApi[`${source}Auth`]) return Promise.reject('Undefined source');
  //@ts-ignore
  return AuthApi[`${source}Auth`](code)
    .then(({data}: LoginResponse<any>) => {
      if (data.result.user && data.result.user.signupUUID) {
        dispatch(push(`/sign-up/${data.result.user.signupUUID}/g`));
      } else {
        if (data.result.user.active) {
          localStorage.setItem('token', data.result.token);

          // hardest clear of the map store
          dispatch(hardClear(true));

          let {farmId} = getDataFromRedirectUrl();
          const groupIds = data.result.user?.groupIds || [];
          farmId = farmId || groupIds[0];

          if (!isCarbonDomain && farmId) {
            dispatch(setGlobalParam('currentGroupId', farmId));
          }

          dispatch(setLogin(data.result, 'oAuth'));

          dispatch(getFarmsList());
        }
      }

      return data;
    })
    .catch((err: Error) => reportError(`oAuthLogin(), source=${source}, err = ${err}`));
};

export const checkAuth = () => (dispatch: Dispatch<any>) => {
  return new Promise((resolve, reject) => {
    UserApi.getProfile()
      .then(async ({data, status}: LoginResponse<any>) => {
        if (status === 401) {
          return reject(401);
        }

        let {farmId} = getDataFromRedirectUrl();
        const groupIds = data.result?.user?.groupIds || [];
        farmId = farmId || groupIds[0];

        if (!isCarbonDomain && farmId) {
          dispatch(setGlobalParam('currentGroupId', farmId));
        }

        if (data.result) {
          dispatch(setLogin(data.result, 'Revalidate login'));
        } else {
          if (status === 200) return;
          reportError(`checkAuth() err = status: ${status}, data: ${data}`);
        }

        resolve(data);
      })
      .catch(err => {
        reportError(`checkAuth() err = ${err}`);
        reject(err);
      });
  });
};

export const logout = () => (dispatch: Dispatch<any>) => {
  const token = localStorage.getItem('token');
  localStorage.removeItem('token');
  localStorage.removeItem('selectedPlatforms');

  dispatch({type: LoginActionTypes.LOGOUT});

  dispatch(hardClear(true));

  console.log(token);

  return AuthApi.logout(token).catch(err => console.warn(err));
};

export const verifyEmail = (uuid: string) => () => {
  return AuthApi.verifyEmail(uuid).then(({data}) => {
    return data.result;
  });
};

export const sendEmailResetPassword = (email: string) => (dispatch: Dispatch<any>) => {
  return AuthApi.emailResetPassword(email)
    .then(({data}) => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Please check your inbox to reset your password.'}),
          level: 'success',
        })
      );

      dispatch(push('/login'));

      return data.result;
    })
    .catch(err => {
      if (err?.data?.result !== 'Email address not found')
        reportError(`sendEmailResetPassword() err = ${err}`);
    });
};

export const sendEmailResetPasswordCarbon = (email: string, cbDone?: () => void) => (
  dispatch: Dispatch<any>
) => {
  return AuthApi.emailResetPasswordCarbon(email)
    .then(({data}) => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Please check your inbox to reset your password.'}),
          level: 'success',
        })
      );

      cbDone?.();
      dispatch(push('/carbon/login'));

      return data.result;
    })
    .catch(err => {
      if (err?.data?.result !== 'Email address not found')
        reportError(`sendEmailResetPassword() err = ${err}`);
    });
};

export const resetPassword = (params: UpdatePasswordPayload, cbDone?: () => void) => (
  dispatch: Dispatch<any>
) => {
  return AuthApi.resetPassword(params)
    .then(({data}) => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Password was saved, please login with the new password.'}),
          level: 'success',
        })
      );

      dispatch(push('/login'));

      cbDone?.();

      return data;
    })
    .catch(err => {
      reportError(`resetPassword() err = ${err}`);
    });
};

export const resetPasswordCarbon = (params: UpdatePasswordPayload, cbDone?: () => void) => (
  dispatch: Dispatch<any>
) => {
  return AuthApi.resetPassword(params)
    .then(({data}) => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Password was saved, please login with the new password.'}),
          level: 'success',
        })
      );

      dispatch(push('/carbon/login'));

      cbDone?.();

      return data;
    })
    .catch(err => {
      reportError(`resetPassword() err = ${err}`);
    });
};

export const updateUserPassword = (
  params: UpdatePasswordPayload,
  setSubmitting: (val: boolean) => void
) => (dispatch: Dispatch<any>) => {
  return AuthApi.updateUserPassword(params)
    .then(({data}) => {
      setSubmitting(false);

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Password was changed.'}),
          level: 'success',
        })
      );

      return data;
    })
    .catch(err => reportError(`updateUserPassword() err = ${err}`));
};

export const setOnboardingSeen = (
  onboardingSettings: Partial<IUserOnBoarding>,
  rememberMessage = false
) => (dispatch: any, getState: () => AppStore) => {
  const message = rememberMessage ? (
    <span>
      Remember, you can always access these tours again at a later stage from your{' '}
      <Ln href={'/profile'}>user profile </Ln>
      page.
    </span>
  ) : (
    'Your onboarding settings were updated'
  );
  const settings = {
    ...getState().login.user.settings,
    onboarding: {
      ...getState().login.user.settings.onboarding,
      fullTour: false,
      quickTour: false,
      isOnboarded: true,
      ...onboardingSettings,
    },
  };
  return dispatch(setProfileSettings(settings, message));
};

export const setProfileSettings = (
  settings: IUserSettings,
  customMessage?: ReactElement | string | false
): ThunkAction<void, LoginState, null, Action> => (dispatch: Dispatch<any>) => {
  return UserApi.updateProfileSettings(settings)
    .then(({data}) => {
      if (customMessage !== false) {
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: customMessage || t({id: 'Profile was updated.'}),
            level: 'success',
          })
        );
      }

      dispatch({
        type: LoginActionTypes.UPDATE_USER_SETTINGS,
        settings,
      });

      return data;
    })
    .catch(err => reportError(`setProfileSettings() err = ${err}`));
};

export const updateUserProfile = (settings: IUserSettings) => (dispatch: Dispatch<any>) => {
  return UserApi.update(settings)
    .then(({data}) => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Profile was updated.'}),
          level: 'success',
        })
      );

      dispatch({
        type: LoginActionTypes.UPDATE_USER_DATA,
        data: settings,
      });

      return data;
    })
    .catch(err => reportError(`updateUserProfile() err = ${err}`));
};

export const updateUserDemoFarms = (demoFarms: number[]) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {login, farms} = getState();
  const currentFarmsWithoutDemos = login.user.groupIds // clear groups list from demo farms
    .filter((farmId: number) => !farms.demoFarmsIdsList.includes(farmId));
  const message = demoFarms.length
    ? demoFarms.length === 1
      ? 'The farm was added to your account'
      : 'The farms were added to your account'
    : 'Your account does not contain demo farms';

  UserApi.updateDemoFarmsList(demoFarms).then(() => {
    dispatch({
      type: LoginActionTypes.UPDATE_USER_DATA,
      data: {groupIds: [...new Set([...currentFarmsWithoutDemos, ...demoFarms])]},
    });

    // update farms list for load new added Demo farms
    dispatch(getFarmsList()).then((farmsList: Farm[]) => {
      const {
        map: {group},
      }: {map: IInitialMapState} = getState();
      const newDemoFarm = demoFarms.length
        ? farmsList.find(farm => demoFarms.includes(farm.id))
        : null;

      if (newDemoFarm) {
        return dispatch(selectFarm(newDemoFarm.id));
      }

      if (!newDemoFarm && !farmsList.find(farm => farm.id === group.id)) {
        dispatch(selectFarm(farmsList?.[0]?.id));
      }
    });

    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'addFarmsMsg'}, {demoFarmsCount: demoFarms.length}),
        level: 'success',
      })
    );
  });
};
