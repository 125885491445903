import Server from './server';
import service from './service';

export default class {
  static getFarmsList() {
    return Server.get('groups', {params: {__skipPreloader: true, page: 1, per_page: 50000}});
  }

  static getDemoFarmsList() {
    return Server.get('demoFarms', {params: {__skipPreloader: true}});
  }

  static getFarmById(id: number, __skipPreloader = false) {
    return Server.get(`groups/${id}`, {params: {__skipPreloader}});
  }

  static saveFarm(params: any) {
    return Server.post(`groups`, params);
  }

  static updateFarm(params: any) {
    return Server.put(`groups/${params.id}`, params);
  }

  static removeFarm(id: number) {
    return Server.delete(`groups/${id}`);
  }

  static getNameBy(id: number) {
    return Server.get(`groups/${id}/name`);
  }

  static removeUserFromFarm(groupID: number, userID: number) {
    return Server.delete(`groups/${groupID}/${userID}`);
  }

  static sendInviteToFarm(groupID: number, emails: string[]) {
    return Server.post(`farms/${groupID}/invite`, emails);
  }

  static acceptFarmInvite(uuid: string) {
    return Server.get(`farms/invite/${uuid}`);
  }

  static canopyCover(md5: string, dateTime: string, cropType: string) {
    return service.get(`data-service/canopy_cover/${md5}/${dateTime}?cropType=${cropType}`);
  }

  static saveFields(fields: any[]) {
    return Server.post('files/kml', fields);
  }
}
