import {
  CPFilter,
  CPFilterType,
  CPFilterState,
  stage0Filters,
  stage1Filters,
  stage2Filters,
  stage3Filters,
  stage4Filters,
} from './field-filter-types';
import {
  CropPerformanceState,
  CropStressChartData,
  CSGViewModel,
} from 'containers/map/features/crop-performance/types';
import {classifyNdvi} from 'containers/map/features/crop-performance/biomass/biomass';
import {Field, Season} from 'containers/map/types';
import {getFieldSeasons} from '_hooks/use-current-season';
import {unreachableError} from '_utils/pure-utils';
import {AppStore} from 'reducers';

export const applyFilters = (
  fields: Field[],
  records: CSGViewModel[],
  filters: CPFilterState['filters'],
  date: string,
  cropPerformance: AppStore['cropPerformance']
) => {
  const status: CPFilterState['filterStatus'] = {};
  let restSeasons = fields.flatMap(f => {
    const seasons = getFieldSeasons(f, date);
    const finalSeasons = seasons.length
      ? seasons
      : [{id: f.ID, startDate: '', endDate: '', cropType: ''}]; // Pass a dummy season in case of no seasons to show "no crop" fields.
    return finalSeasons;
  });

  restSeasons.forEach(season => (status[season.id] = undefined));
  stage0Filters.map(f => {
    return filterStage0(restSeasons, filters[f], f, status);
  });

  restSeasons = restSeasons.filter(season => !status[season.id]);

  // 1. Field based filters.
  stage1Filters.forEach(f => {
    filterStage1(restSeasons, filters[f], f, status);
  });

  restSeasons = restSeasons.filter(season => !status[season.id]);

  stage2Filters.forEach(f => {
    filterStage2(restSeasons, filters[f], f, status, cropPerformance);
  });

  // 2. Record based filters.
  let restRecords = records.filter(r => !status[r.seasonId]);
  stage3Filters.forEach(f => {
    filterStage3(restRecords, filters[f], f, status);
  });

  restRecords = restRecords.filter(r => !status[r.seasonId]);
  stage4Filters.forEach(f => {
    filterStage4(
      cropPerformance.ndviQuartiles,
      restRecords,
      filters[f],
      f,
      status,
      cropPerformance.cropStressChartData
    );
  });
  return status;
};

/**
 * Stage 1 filters are field and season based filters. No crop performance.
 * Instead of returning a value it mutates the `status` argument.
 *
 * @param seasons
 * @param filters
 * @param status – mutates
 * @param filterType
 */
const filterStage0 = (
  seasons: Season[],
  filters: CPFilter[],
  filterType: CPFilterType,
  status: CPFilterState['filterStatus']
) => {
  if (!filters.length) return seasons; // nothing to filter
  seasons.forEach(season => {
    const passed = filters.some(f => {
      if (f.stage === 0) {
        switch (f.type) {
          case CPFilterType.FIELDS_WITH_PLANTING_AREAS:
            return f.value === season.kmlId;
          default:
            unreachableError(f, `Stage 0 filter for "${f}" is not implemented`);
        }
      }
      return true;
    });
    if (!passed) {
      status[season.id] = filterType;
    }
  });
};

/**
 * Stage 1 filters are field and season based filters. No crop performance.
 * Instead of returning a value it mutates the `status` argument.
 *
 * @param seasons
 * @param filters
 * @param status – mutates
 * @param filterType
 */
const filterStage1 = (
  seasons: Season[],
  filters: CPFilter[],
  filterType: CPFilterType,
  status: CPFilterState['filterStatus']
) => {
  if (!filters.length) return; // nothing to filter

  seasons.forEach(season => {
    let passed = filters.some(f => {
      if (f.stage === 1) {
        switch (f.type) {
          case CPFilterType.CROP_TYPE:
            return season.cropType === f.value;

          case CPFilterType.CROP_VARIETY:
            const cropVariety = season?.params?.cropSubType;
            return f.value === cropVariety || (f.value == null && cropVariety === '');
          default:
            unreachableError(f, `Stage 1 filter for "${f}" is not implemented`);
        }
      }
    });
    if (!passed) {
      status[season.id] = filterType;
    }
  });
};

/**
 * Stage 2 filters are crop performance based filters.
 * Instead of returning a value it mutates the `status` argument.
 *
 * @param seasons
 * @param filters
 * @param status – mutates
 * @param filterType
 * @param cropPerformance
 */
const filterStage2 = (
  seasons: Season[],
  filters: CPFilter[],
  filterType: CPFilterType,
  status: CPFilterState['filterStatus'],
  cropPerformance: CropPerformanceState
) => {
  seasons.forEach(season => {
    const passed =
      !filters.length ||
      filters.some(f => {
        if (f.stage === 2) {
          switch (f.type) {
            case CPFilterType.FIELDS_VARIABILITY:
              return cropPerformance.fieldsVariability[season.id]?.status === f.value;

            default:
              unreachableError(f, `Stage 2 filter for "${f}" is not implemented`);
          }
        }
      });
    if (!passed) {
      status[season.id] = filterType;
    }
  });
};

/**
 * Stage 3 filters are crop performance based filters.
 * Instead of returning a value it mutates the `status` argument.
 *
 * @param records
 * @param filters
 * @param status – mutates
 * @param filterType – mutates
 */
const filterStage3 = (
  records: CSGViewModel[],
  filters: CPFilter[],
  filterType: CPFilterType,
  status: CPFilterState['filterStatus']
) => {
  records.forEach(r => {
    const passed =
      !filters.length ||
      filters.some(f => {
        if (f.stage === 3) {
          switch (f.type) {
            case CPFilterType.CROP_STATUS:
            case CPFilterType.TREE_STATUS:
              return f.value === r.cropStatus;
            default:
              unreachableError(f, `Stage 3 filter for "${f}" is not implemented`);
          }
        }
      });
    if (!passed) {
      status[r.seasonId] = filterType;
    }
  });
};

/**
 * Stage 3 filters are crop performance based filters.
 * Instead of returning a value it mutates the `status` argument.
 *
 * @param q – ndvi quartiles
 * @param records
 * @param filters
 * @param status – mutates
 * @param filterType
 * @param cropStressChartData
 */
const filterStage4 = (
  q: CropPerformanceState['ndviQuartiles'],
  records: CSGViewModel[],
  filters: CPFilter[],
  filterType: CPFilterType,
  status: CPFilterState['filterStatus'],
  cropStressChartData: CropStressChartData
) => {
  records.forEach(r => {
    const passed =
      !filters.length ||
      filters.some(f => {
        if (f.stage === 4) {
          switch (f.type) {
            case CPFilterType.CROP_GROWTH:
              return f.value === r.growth;
            case CPFilterType.BIOMASS:
              const qq = q[`${r.cropType}_${r.cropVariety}`];
              if (!qq) {
                return false;
              }
              const ndvi = classifyNdvi(r.smoothSatelliteNdvi, qq.smoothSatellite);
              return f.value === ndvi;

            case CPFilterType.CROP_STRESS: {
              return cropStressChartData[r.fieldID] && cropStressChartData[r.fieldID] === f.value; //TODO CP planting areas adjust crop stress
            }
            case CPFilterType.BIOMASS_OVER_TIME: {
              return true;
            }
            default:
              unreachableError(f, `Stage 4 filter for "${f}" is not implemented`);
          }
        }
      });
    if (!passed) {
      status[r.seasonId] = filterType;
    }
  });
};
