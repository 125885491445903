import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo} from 'react';
import {AsyncStatusType, Status} from 'modules/ui-helpers';
import {useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {CircularProgress} from 'react-md';
import {genKey} from '_utils';
import {Wrapper} from './simple-preloader.styled';

type Props = {
  statusKeys: AsyncStatusType[];
  loadingContent?: any;
  className?: string;
  content?: any;
  appearance?: 'primary';
};

const SimplePreloader = ({appearance, content, loadingContent, statusKeys, className}: Props) => {
  const id = useMemo(() => genKey(), []);
  const statuses = useSelector((state: AppStore) =>
    statusKeys.map(statusKey => state.uiHelpers.asyncStatuses[statusKey].status)
  );

  return statuses.some(status => status === Status.Pending) ? (
    <Wrapper appearance={appearance} className="simple-preloader">
      <CircularProgress className={className ? className : ''} centered={false} id={id} />
      {loadingContent ? loadingContent : null}
    </Wrapper>
  ) : (
    content || null
  );
};

type HideWhileLoadingProps = {
  statusKeys: AsyncStatusType[];
  content: (isLoading: boolean) => any;
};

export const HideWhileLoading = ({content, statusKeys}: HideWhileLoadingProps) => {
  const statuses = useSelector((state: AppStore) =>
    statusKeys.map(statusKey => state.uiHelpers.asyncStatuses[statusKey].status)
  );

  return content(statuses.some(status => status === Status.Pending));
};

export default SimplePreloader;
