import {ActionTypes} from '../reducer/types';
import {t} from 'i18n-utils';
import {getAnalyticsItemColor} from '_utils';
import {
  AnalyticsArablePoint,
  AnalyticData,
  IInitialMapState,
  SamplingPoint,
  AnalyticPoint,
} from '../types';
import {
  getAllFarmImages,
  getFieldMeanIndexes,
  getLayerImages,
  classifyTrendsData,
  classifyMeanValues,
  FieldsWithArableData,
  classifyArableTrendsData,
} from '../features/analytics/helpers';
import {getAllVisibleGeometries} from '../utils';
import {changeAreaOfInterestProp} from './areas-of-interest-actions';
import {changeLowPerfAnomalyProp, updatePremiumAnomaly} from './anomalies-actions';
import {reportError} from '../../error-boundary';
import {ActivityApi} from '_api';
import Mixpanel from '_utils/mixpanel-utils';
import {showNote} from '_actions';
import {AppStore} from 'reducers';
import {getLabelNameByValue, GLOBAL_APP_DATE_FORMAT, GLOBAL_FORMAT_DATE} from '_constants';
import moment from 'moment';
import {getArableData} from '../actions';
import axios from 'axios';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/ui-helpers';

export const addAnalyticPoint = (point: AnalyticPoint) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_ADD_ANALYTIC_POINT,
    point,
  });
};

export const removeAnalyticPoint = (color: any) => ({
  type: ActionTypes.MAP_REMOVE_ANALYTIC_POINT,
  color,
});

export const updateAnalyticPointPosition = (positions: any) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_UPDATE_ANALYTIC_POINT,
    positions,
  });
  dispatch(getTrendsData());
};

export const togglePointVisibility = (index: any) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_TOGGLE_ANALYTIC_POINT_VISIBILITY,
    index,
  });
};

export const toggleTSPVisibility = (id: any) => ({
  type: ActionTypes.MAP_TOGGLE_TSP_POINT_VISIBILITY,
  id,
});

export const getTrendsData = () => async (dispatch: any, getState: () => AppStore) => {
  const {
    currentSeason,
    field,
    pointsGroups,
    pointsCurrentGroupDate,
    analytics,
    remoteSensingFilterProps,
    remoteSensingCloudCover,
    premiumAnomalies,
    openPopupId,
    wholeFarm: {isWholeFarmView, fieldsWithDates, allFarmTSP},
  }: IInitialMapState = getState().map;

  const fieldMeanValues = analytics.fieldData || (await dispatch(getFieldMeanIndexes()));

  const seasonLayerImages: any = isWholeFarmView
    ? getAllFarmImages(remoteSensingFilterProps, fieldsWithDates, remoteSensingCloudCover)
    : getLayerImages(
        remoteSensingFilterProps,
        currentSeason.infoExt,
        field.MD5,
        remoteSensingCloudCover
      );

  const preparedGeometries = getAllVisibleGeometries();
  preparedGeometries.forEach(g => {
    const popup =
      openPopupId && (openPopupId === g.properties.id || openPopupId === g.properties.anomaly_id);

    if ((g.properties.checked || popup) && !g.properties.color) {
      !g.properties.isLowPerf
        ? dispatch(changeAreaOfInterestProp(g, 'color', getAnalyticsItemColor()))
        : dispatch(changeLowPerfAnomalyProp(g, 'color', getAnalyticsItemColor()));
    }
  });

  const geometryToGet = preparedGeometries
    .filter(g => g.properties.color)
    .map(g => {
      let coordinates = [];
      try {
        coordinates =
          g.geometry.type === 'GeometryCollection'
            ? g.geometry.geometries.map((g: any) => g.coordinates[0]).flat()
            : g.geometry.coordinates[0];
      } catch (err) {
        reportError(
          `error in the getTrendsData() in preparedGeometries.map, URL = ${window.location.href}; geometryID = ${g.properties.id}`
        );
      }

      return {
        name: g.properties.color,
        coords: coordinates,
      };
    });
  premiumAnomalies.list.forEach(g => {
    const popup = openPopupId && openPopupId === g.properties.anomaly_id;
    if (g.properties.color || (!g.properties.checked && !popup)) {
      return;
    }
    dispatch(
      updatePremiumAnomaly({
        id: g.properties.anomaly_id,
        prop: 'color',
        value: getAnalyticsItemColor(),
        g,
      })
    );
  });

  const coloredAnomalies = premiumAnomalies.list.filter(a => a.properties.color);
  const anomalyToGet = coloredAnomalies.map(a => ({
    name: a.properties.color,
    coords: a.geometry.coordinates[0],
  }));

  const preparedGeometriesLabels = [...premiumAnomalies.list, ...preparedGeometries].map(g => ({
    // need these values to associate label with a trend series and use them as labels when export analytics to CSV, OPS-394
    label: g.properties.title || getLabelNameByValue(g.properties.label || ''),
    color: g.properties.color,
  }));

  type NamedPoint = {
    lat: number;
    lng: number;
    name: string;
  };

  let tspPoints: NamedPoint[] = [];

  if (isWholeFarmView) {
    tspPoints = allFarmTSP.map((point: SamplingPoint) => {
      return {
        lat: point.geometry.coordinates[0],
        lng: point.geometry.coordinates[1],
        name: point.properties.color,
        type: 'TSP',
      };
    });
  } else {
    tspPoints = pointsGroups[pointsCurrentGroupDate]
      ? pointsGroups[pointsCurrentGroupDate].map((point: SamplingPoint) => {
          return {
            lat: point.geometry.coordinates[0],
            lng: point.geometry.coordinates[1],
            name: point.properties.color,
          };
        })
      : [];
  }

  const analyticPoints = analytics.points.length
    ? analytics.points
        .filter(point => !point.arableData)
        .map(point => {
          return {
            lat: point.latlng.lat,
            lng: point.latlng.lng,
            name: point.color,
          };
        })
    : [];

  const arablePoints = await dispatch(getArablePoints());

  const arableData = classifyArableTrendsData(arablePoints, fieldMeanValues.categories);

  if (
    ![...tspPoints, ...analyticPoints, ...geometryToGet, ...anomalyToGet].length ||
    !seasonLayerImages[0].length
  ) {
    if (
      analytics.showKmlMean &&
      (fieldMeanValues.smoothedNDVI.data.length || fieldMeanValues.CCCI?.data?.length)
    ) {
      const values = [
        {},
        {},
        {
          categories: fieldMeanValues.categories,
          series: [fieldMeanValues.smoothedNDVI, ...arableData],
        },
        {},
      ];
      dispatch({
        type: ActionTypes.MAP_ADD_ANALYTIC_TRENDS_DATA,
        values,
        fieldData: fieldMeanValues,
      });
    }
    return;
  }

  const arrayRequests = seasonLayerImages.map((layer: any[], index: number) => {
    return ActivityApi.getTrendsData(
      {
        images: layer.reverse(),
        points: [...tspPoints, ...analyticPoints],
        polygons: [...geometryToGet, ...anomalyToGet],
      },
      index
    )
      .then(({data}: {data: AnalyticData}) => {
        if (typeof data === 'string') data = {};
        return data;
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log('err', err.response);
          Mixpanel.errorMessage('An error occurred, try to reload the page.', err.response);
          dispatch(
            showNote({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: t({id: 'errorTryReloadPage'}),
              level: 'warning',
            })
          );
          reportError(err);
        }
      });
  });

  dispatch(setRequestStatus(AsyncStatusType.analyticsGeometriesAndPoints, Status.Pending));

  Promise.all(arrayRequests)
    .then((values: AnalyticData[]) => {
      if (!values.every(v => v)) return; // it means the request was cancelled

      dispatch(setRequestStatus(AsyncStatusType.analyticsGeometriesAndPoints, Status.Done));

      const combinedFieldMeanValues = classifyMeanValues(fieldMeanValues, values[2]);
      const restructuredValues = classifyTrendsData(
        combinedFieldMeanValues,
        values,
        preparedGeometriesLabels
      );
      const arableData = classifyArableTrendsData(arablePoints, fieldMeanValues.categories);

      if (restructuredValues[2]?.series?.length) {
        restructuredValues[2].series = [
          ...restructuredValues[2]?.series,
          combinedFieldMeanValues.smoothedNDVI,
          ...arableData,
        ];
      }

      dispatch({
        type: ActionTypes.MAP_ADD_ANALYTIC_TRENDS_DATA,
        values: restructuredValues,
        fieldData: combinedFieldMeanValues,
      });
    })
    .catch(err => {
      dispatch(setRequestStatus(AsyncStatusType.analyticsGeometriesAndPoints, Status.Todo));
      console.log('err', err);
    });
};

export const toggleAnalyticKmlMean = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_FIELD_MEAN,
  value,
});

export const toggleAnalyticSmoothedData = (value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_SMOOTHED_DATA,
  value,
});

export const getArablePoints = () => async (dispatch: any, getState: () => AppStore) => {
  const {
    field,
    group,
    currentSeason,
    analytics: {points},
  } = getState().map;
  const seasonStartDate = moment(currentSeason.startDate, GLOBAL_FORMAT_DATE);
  const seasonEndDate = moment(currentSeason.endDate, GLOBAL_FORMAT_DATE);

  if (points.some(p => p.arableData)) {
    return points.filter(p => p.arableData);
  }

  if (FieldsWithArableData[group.id]?.[field.ID]) {
    const points: {[deviceLabel: string]: AnalyticPoint} = {};
    const colors: string[] = [];
    const arableSensorData = await dispatch(getArableData());

    if (arableSensorData?.length) {
      arableSensorData.forEach((point: AnalyticsArablePoint) => {
        const pointDate = moment(point.time, GLOBAL_FORMAT_DATE);
        const pointId = point.device;

        if (!pointDate.isSameOrBefore(seasonEndDate) || !pointDate.isSameOrAfter(seasonStartDate))
          return; // pick only points in current season

        if (!points[pointId]) {
          const color = getAnalyticsItemColor(colors);
          colors.push(color);

          points[pointId] = {
            color: color,
            label: point.device,
            latlng: {lat: point.lat, lng: point.long},
            arableData: [
              {
                ...point,
                time: pointDate.format(GLOBAL_FORMAT_DATE),
                timeAppFormat: pointDate.format(GLOBAL_APP_DATE_FORMAT),
              },
            ],
          };
        } else {
          points[pointId].arableData.push({
            ...point,
            time: pointDate.format(GLOBAL_FORMAT_DATE),
            timeAppFormat: pointDate.format(GLOBAL_APP_DATE_FORMAT),
          });
        }
      });
    }

    Object.values(points).forEach(point => {
      // save the arable points as an analytic point
      dispatch(addAnalyticPoint(point));
    });

    return Object.values(points);
  }

  return [];
};
