import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {Button} from 'react-md';

type Props = {
  visible?: boolean;
  type: string;
  className?: string;
  iconEl: React.ReactElement<any>;
  onClick(): void;
};

const CustomDrawButton = ({visible = true, type, className = '', iconEl, onClick}: Props) => {
  const onClickHandle = (action: React.MouseEvent<HTMLElement>) => {
    action.stopPropagation();
    onClick();
  };

  return visible ? (
    <Button
      id={`custom-draw-btn-${type}`}
      className={`custom-draw-btn ${className}`}
      name="toggle-field-info"
      iconEl={iconEl}
      flat
      onClick={onClickHandle}
    >
      {type}
    </Button>
  ) : null;
};

export default CustomDrawButton;
