import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import cn from 'classnames';
import {FieldGeometryApi} from '_api';
import {toggleMapView, showNote} from '_actions';
import {
  loadFieldData,
  setKmlCoordinates,
  toggleFieldKml,
  toggleMapBar,
  updateFieldGeometries,
} from './actions';
import {FeatureGroup} from 'react-leaflet';
// containers
import {TiledMap} from './tiled-map';
import {MapBar} from './map-bar/map-bar';
import EditControl from './map-buttons/edit-control';
import CompareOverlay from './features/data-layers/compare-overlay';
import MapColorSchema from './schemes-on-map/color-schema/color-schema-overlay';
import SoilMapSchema from './schemes-on-map/soil-map-schema/soil-map-schema';

// map overlays
import NRecommendationOverlay from './features/zoning/nitrogen-recommendation/n-recommendation-overlay';
import AnalyticsOverlay from './features/analytics/analytics-overlay';
import TissueSamplingOverlay from './features/sampling-points/tissue-sampling-overlay';
import HistogramOverlay from './overlays/histogram-overlay';
import GeometriesOverlay from './features/anomalies/geometries-overlay';
import AnomaliesOverlay from './features/anomalies/anomalies-overlay';
import KmlFieldOverlay from './overlays/kml-field-overlay';
import WholeFarmOverlay from './overlays/whole-farm-overlay';
import CursorTooltipOverlay from './overlays/cursor-tooltip-overlay';
import MainFieldOverlay from './overlays/field-overlay';
import SeasonGeometryOverlay from './overlays/season-geometry-overlay';
import SeasonGeometriesAddingOverlay from './overlays/season-geometries-adding-overlay';
import AddingNewFieldsOverlay from './overlays/addning-new-fields-geometries';
import _config from '_environment';
import {AppStore} from 'reducers';

//on-boarding
import OnBoarding from '../onboarding';

// buttons
import MapButtons from './map-buttons';

// other
import TSUploadDialog from './tissue-sampling-upload/dialog';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';

import {kmlToGeoJSON, updateEachFeatureProperties} from '_utils/geometry';
import {isAdmin, isAdminPerm, validateBounds} from '_utils/';
import {getLayerImageUrl} from './utils/utils';

import L, {Map} from 'leaflet';
import './lib/svg-icon';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import CloudyTip from '../cloudy-tip';
import './lib/fluro-measurement';

import './index.scss';
import {markerIcon} from './icons';
import TemperatureChart from '../temperature-chart';

import Effects from './effects';

import {reportError} from '../error-boundary';
import {EmbedLogin} from 'containers/login/embed-login';

import {CropPerformanceFiltersDescription} from './crop-performance-filters-description';
import {CropPerformancePopup} from './crop-performance-popup';

import {ImageLayerPreloader} from 'components/image-layer-preloader';
import ProductivityMapOverlay from './features/zoning/productivity-map/productivity-map-overlay';
import {OptisOverlay} from './features/optis/optis-overlay';
import {OptisFilterStatus} from './features/optis/optis-filter-status';
import CLUFieldBoundariesOverlay from './overlays/clu-field-boundaries';
import {NoFarmsOverlay} from './overlays/no-farms-overlay';
import {EditControl2} from './edit-control-2';
import {EditFieldButtonsContainer} from './map-buttons/edit-field-buttons-container';

// https://github.com/PaulLeCam/react-leaflet/issues/255

//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
Object.keys(markerIcon.options).forEach(key => {
  //@ts-ignore
  L.Icon.Default.prototype.options[key] = markerIcon.options[key];
});
// for some reason Marker doesn't responsible for i a pin marker anymore and was replaced by lines above
// L.Marker.prototype.options.icon = markerIcon;

//@ts-ignore
L.drawLocal.draw.handlers.marker.tooltip.start = '';

type Props = ConnectedProps<typeof connector>;

interface State {
  fieldGeometry: L.GeoJSON;
  leafletElement?: L.Map;
}

/* Main map component */
class FlurosenseMap extends Component<Props, State> {
  originalImageOverlayRef?: L.ImageOverlay;
  wholeFarmBounds?: L.LatLngBounds;

  constructor(props: any) {
    super(props);

    this.state = {
      fieldGeometry: null,
      leafletElement: undefined, // set when <Map/> is mount and has a ref
    };
  }

  componentWillUnmount() {
    this.props.toggleMapView(false);
    //@ts-ignore
    window.leafletElement = null;
    this.setState({leafletElement: null});
  }

  componentDidMount() {
    this.props.toggleMapView(true);
    if (this.props.selectedFieldId !== 'WholeFarm') {
      this.loadKml();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.selectedFieldId !== prevProps.selectedFieldId &&
      this.props.fields.length &&
      this.props.currentGroupId
    ) {
      if (this.props.selectedFieldId !== 'WholeFarm') {
        this.loadKml();
      }
    }

    if (prevProps.geometry.length && !this.props.geometry.length && this.state.leafletElement) {
      this.clearGeometries(prevProps.geometry);
    }

    /*
     * fitBounds ony if it is not WholeFarm, in other case whole-farm-overlay.tsx can do it
     * */
    if (prevProps.feature !== this.props.feature && this.props.selectedFieldId !== 'WholeFarm') {
      this.fitBounds();
    }

    if (
      this.state.fieldGeometry &&
      this.state.leafletElement &&
      (!prevState.leafletElement || this.state.fieldGeometry !== prevState.fieldGeometry)
    ) {
      // Once _both_ map and field's kml data are ready, fly to the field.
      // Also, fly to the field when it's changed.
      this.fitBounds();
    }

    if (prevProps.groupId !== this.props.groupId && !this.props.fields.length) {
      this.state.leafletElement?.setMinZoom(null);
    }
  }

  goToNY = () => {
    // NY
    this.state.leafletElement?.fitBounds([
      [40.712, -74.227],
      [40.774, -74.125],
    ]);
  };

  /**
   * Loads both field's geometry in KML format and the field's data.
   */
  loadKml = () => {
    if (this.props.selectedFieldId === 0) {
      this.goToNY();

      return;
    }

    const field = this.props.fields.find(f => f.ID === this.props.selectedFieldId);
    if (!field) {
      reportError(`Cannot find kml ${this.props.selectedFieldId}`);
      return;
    }
    const {MD5, ID} = field;
    const {setKmlCoordinates, toggleFieldKml} = this.props;
    const fieldKml = new Promise((res, rej) => {
      const currentFieldGeometry = this.props.fieldGeometries[MD5];
      if (currentFieldGeometry) {
        this.setState({fieldGeometry: L.geoJSON(currentFieldGeometry)});
        // TODO (stas): get rid of map.currentFieldKml and use map.fieldGeometries[MD5] instead.
        setKmlCoordinates(currentFieldGeometry.features);
        res('ok');
        return;
      }
      FieldGeometryApi.kml(MD5)
        .then(({data}) => {
          const geoJson = kmlToGeoJSON(data);
          updateEachFeatureProperties(geoJson, {fluro_id: ID, id: ID});
          this.props.updateFieldGeometries({[MD5]: geoJson});
          this.setState({fieldGeometry: L.geoJSON(geoJson)});
          setKmlCoordinates(geoJson.features);
          res('ok');
        })
        .catch(err => {
          this.goToNY();
          reportError(err?.message);

          this.props.showNote({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message: t({id: 'KML file not found', defaultMessage: 'KML file not found'}),
            level: 'error',
          });
        });
    });

    // Wait until both field's kml geometry and field data are loaded.
    Promise.all([fieldKml, this.props.loadFieldData(ID)])
      .then(() => {
        if ((!this.props.currentSeasonId || !getLayerImageUrl()) && this.props.selectedFieldId) {
          toggleFieldKml(true);
        }

        if (this.props.viewport.width < 561) {
          // On mobile when we change the current field we want to see the map, so closing the map bar.
          this.props.toggleMapBar(false);
        }
      })
      .catch(err => console.log('error load field', err));
  };

  fitBounds = (bounds?: L.LatLngBounds, forceRightMargin?: boolean) => {
    try {
      if (!this.state.leafletElement) {
        return;
      }

      const defaultBounds =
        this.props.isWholeFarmView && this.wholeFarmBounds
          ? this.wholeFarmBounds
          : this.state.fieldGeometry?.getBounds();
      const marginRight = forceRightMargin || this.props.isMapBarOpen ? 400 : 60;
      const validBounds = validateBounds(bounds) || validateBounds(defaultBounds);
      if (validBounds) {
        this.state.leafletElement.fitBounds(validBounds, {
          // we do not need padding bounds in the small screens
          paddingBottomRight: this.props.viewport.width < 561 ? [0, 70] : [marginRight, 70],
        });
      }
    } catch (err) {
      reportError(`fitBounds Err = ${err}`);
    }
  };

  onRefMap = (node: Map) => {
    if (node) {
      this.setState({leafletElement: node});
      //@ts-ignore
      window.leafletElement = node;
      node.createPane('field-geometry');
      node.getPane('field-geometry').style.zIndex = '500';
    }
  };

  removeGeometryFromMap = (id: number) => {
    if (!this.state.leafletElement) {
      return;
    }
    this.state.leafletElement.eachLayer((l: any) => {
      if (l.fluroGeometryID === id) {
        this.state.leafletElement.removeLayer(l);
      }
    });
  };

  clearGeometries = (geometries: Array<any>) => {
    geometries.forEach(geometry => this.removeGeometryFromMap(geometry.properties.id));
  };

  isVisibleKmlLayer = () => this.props.visibleFieldKml;

  render() {
    const {
      field,
      isReadOnly,
      fields,
      isWholeFarmView,
      layersSource,
      isCompareOn,
      isVisibleCloudyTip,
      isVisibleHistogram,
      sessionExpired,
      feature,
      hasFarms,
    } = this.props;

    const {leafletElement} = this.state;

    /*
     *
     * Map view for NO FARMS mode
     *
     * */
    // if (!hasFarms) {
    //   return (
    //     <div
    //       className={cn({
    //         'map-v2__container': true,
    //       })}
    //     >
    //       {leafletElement && (
    //         <MapBar
    //           leafletElement={leafletElement}
    //           removeGeometryFromMap={this.removeGeometryFromMap}
    //         />
    //       )}
    //
    //       <TiledMap
    //         leafletElement={leafletElement}
    //         trackResize={!isReadOnly}
    //         layersSource={layersSource}
    //         onRefMap={this.onRefMap}
    //       >
    //         {leafletElement && <NoFarmsOverlay leafletElement={leafletElement} />}
    //
    //         {leafletElement && (
    //           <MapButtons
    //             hasFarms={hasFarms}
    //             leafletElement={leafletElement}
    //             fitBounds={this.fitBounds}
    //           />
    //         )}
    //       </TiledMap>
    //     </div>
    //   );
    // }

    /*
     *
     * Regular map view
     *
     * */
    return (
      <div
        className={cn({
          'map-v2__container': true,
          transparent: feature === 'crop-performance' || feature === 'optis',
        })}
      >
        <Effects />

        {sessionExpired && <EmbedLogin id="session-expired-embed-login" visible={true} />}

        {leafletElement && (
          <MapBar
            leafletElement={leafletElement}
            removeGeometryFromMap={this.removeGeometryFromMap}
          />
        )}

        {leafletElement && <CursorTooltipOverlay leafletElement={leafletElement} />}

        {isVisibleHistogram ? <HistogramOverlay /> : null}

        {!['crop-performance', 'optis'].includes(this.props.feature) &&
          (this.props.soilMapLayer ? <SoilMapSchema /> : <MapColorSchema />)}

        <TSUploadDialog />

        {isVisibleCloudyTip && <CloudyTip />}

        <TiledMap
          leafletElement={leafletElement}
          trackResize={!isReadOnly}
          layersSource={layersSource}
          onRefMap={this.onRefMap}
          hasFarms={hasFarms}
        >
          <EditControl2 />

          {/* Selected field geometry (KML, geojson) overlay */}
          {this.props.selectedFieldId &&
          this.isVisibleKmlLayer() &&
          // hide the field kml when soil map is visible
          !(this.props.soilMapLayer && this.props.field.soilLayer) &&
          this.props.feature !== 'optis' &&
          // For whole farm and for crop performance we show WholeFarmOverlay.
          (!this.props.isWholeFarmView || this.props.feature !== 'crop-performance') ? (
            <KmlFieldOverlay data={this.props.currentFieldKml} />
          ) : null}

          {/* Overlay to locate user to his position if user has no farms */}
          {leafletElement && !hasFarms && <NoFarmsOverlay leafletElement={leafletElement} />}

          {/* Areas of interest and anomalies */}
          <GeometriesOverlay />
          <AnomaliesOverlay />

          {/*Season geometry*/}
          <SeasonGeometryOverlay />

          {/*Season geometries overlay (using for create new season with geometry)*/}
          <SeasonGeometriesAddingOverlay />

          {/*Processing new fields geometry */}
          <AddingNewFieldsOverlay fitBounds={this.fitBounds} />

          {/* Selected field sensor image overlay (NDVI, NMAP, etc) */}
          {leafletElement &&
            this.props.feature !== 'optis' &&
            this.props.feature !== 'crop-performance' && (
              <MainFieldOverlay
                leafletElement={leafletElement}
                originalImageOverlayRef={(ref: L.ImageOverlay) =>
                  (this.originalImageOverlayRef = ref)
                }
              />
            )}

          {/* Compare one field overlay */}
          {field.ID &&
            isCompareOn &&
            leafletElement &&
            !isWholeFarmView &&
            this.props.feature !== 'optis' && (
              <CompareOverlay
                leafletElement={leafletElement}
                compareOriginalRef={this.originalImageOverlayRef}
                bounds={[
                  [field.SouthLat, field.WestLon],
                  [field.NorthLat, field.EastLon],
                ]}
              />
            )}

          <ProductivityMapOverlay />

          {this.props.feature === 'crop-performance' && <CropPerformancePopup />}

          {this.props.feature !== 'optis' && (
            <FeatureGroup key={this.props.featureGroupReDrawId}>
              <EditControl leafletElement={leafletElement} />

              {/* Tissue Sampling points on the map */}
              {!isWholeFarmView && this.props.selectedFieldId && <TissueSamplingOverlay />}

              {/* Checked field geometries (KML, geojson) and whole farm compare */}
              {(isWholeFarmView || this.props.feature === 'crop-performance') && (
                <WholeFarmOverlay
                  leafletElement={leafletElement}
                  fitBounds={this.fitBounds}
                  wholeFarmBoundsCallback={(bounds: L.LatLngBounds) =>
                    (this.wholeFarmBounds = bounds)
                  }
                />
              )}

              {leafletElement && (
                <CLUFieldBoundariesOverlay
                  leafletElement={leafletElement}
                  fitBounds={this.fitBounds}
                />
              )}

              {/*Analytics overlay*/}
              {(field || fields.length) && this.props.feature === 'analytics' && leafletElement && (
                <AnalyticsOverlay leafletElement={leafletElement} />
              )}

              <NRecommendationOverlay />
            </FeatureGroup>
          )}

          {this.props.feature === 'optis' && <OptisOverlay fitBounds={this.fitBounds} />}

          {leafletElement && (
            <MapButtons
              leafletElement={leafletElement}
              fitBounds={this.fitBounds}
              hasFarms={hasFarms}
            />
          )}
        </TiledMap>

        <EditFieldButtonsContainer map={leafletElement} />

        {this.props.selectedFieldId && <OnBoarding />}

        {this.props.isVisibleWeather ? <TemperatureChart /> : null}

        {_config.env === 'development' && <div className="git-branch">{_config.branch}</div>}

        {this.props.feature === 'crop-performance' && <CropPerformanceFiltersDescription />}

        {this.props.feature === 'optis' && <OptisFilterStatus />}

        <ImageLayerPreloader />
      </div>
    );
  }
}

const mapStateToProps = (s: AppStore) => ({
  currentGroupId: s.global.currentGroupId,
  isReadOnly: s.map.group.readOnly,
  isMapBarOpen: s.map.isMapBarOpen,
  selectedFieldId: s.map.selectedFieldId,
  soilMapLayer: s.map.soilMapLayer,
  fieldGeometries: s.map.fieldGeometries,
  fields: s.map.fields,
  field: s.map.field,
  isCompareOn: s.map.isCompareOn,
  groupId: s.map.group.id,

  visibleFieldKml: s.map.visibleFieldKml,
  currentDate: s.map.currentDate,
  currentSensor: s.map.currentSensor,
  geometry: s.map.geometry,

  feature: s.map.feature,
  featureGroupReDrawId: s.map.featureGroupReDrawId,

  isWholeFarmView: s.map.wholeFarm.isWholeFarmView,
  imageLayerOpacity: s.map.imageLayerOpacity,
  colorSchema: s.map.colorSchema,
  currentSeasonId: s.map.currentSeasonId,
  currentFieldKml: s.map.currentFieldKml,
  isVisibleWeather: s.map.toggledButtonOnMap === 'Weather',
  isVisibleHistogram: s.uiHelpers.dialogs.histogram.visible,
  isVisibleCloudyTip: s.uiHelpers.dialogs.cloudyTip.visible,
  measurement: s.login.user.settings.measurement,
  treeDetection: s.map.treeDetection,
  layersSource: s.map.layersSource,
  viewport: s.viewport,

  currentSensorCompare: s.map.currentSensorCompare,

  sessionExpired: s.global.sessionExpired,

  hasFarms: isAdminPerm(s.login.user.perm) || s.login.user.groupIds.length > 0,
});

const connector = connect(mapStateToProps, {
  toggleMapBar,
  toggleMapView,
  loadFieldData,
  setKmlCoordinates,
  toggleFieldKml,
  showNote,
  updateFieldGeometries,
});

export default connector(FlurosenseMap);
