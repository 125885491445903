import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ImageOverlay} from 'react-leaflet';
import {LatLngBoundsExpression} from 'leaflet';
import Mixpanel from '_utils/mixpanel-utils';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/ui-helpers';
import {AppStore} from 'reducers';
import axios from 'axios';
import {reportError} from '../../containers/error-boundary';
import {saveImage} from 'modules/images-cache.module';

type Props = {
  url: string;
  id?: string;
  onErrorCallback?(): void;
  lRef?: any;
  bounds: LatLngBoundsExpression;
  onClick?(val: any): void;
  isCompare?: boolean;
  interactive?: boolean; // to emit mouse events
  onMousemove?(ev: any): any;
  onMouseover?(ev: any): any;
  onMouseout?(ev: any): any;
  isZoning?: boolean;
  onStartLoadBinary?: () => void;
  onEndLoadBinary?: () => void;
};

const FluroImageOverlay = ({
  url,
  onErrorCallback,
  lRef,
  id,
  bounds,
  onClick,
  isCompare,
  interactive,
  onMousemove,
  onMouseover,
  onMouseout,
  isZoning,
  onStartLoadBinary,
  onEndLoadBinary,
}: Props) => {
  const dispatch = useDispatch();

  const imageLayerOpacity = useSelector((state: AppStore) => state.map.imageLayerOpacity);
  const selectedFieldId = useSelector((state: AppStore) => state.map.selectedFieldId);
  const isImageLayerLoading = useSelector(
    (state: AppStore) =>
      state.uiHelpers.asyncStatuses[AsyncStatusType.imageLayer].status === Status.Pending
  );

  const cachedImages = useSelector((state: AppStore) => state.imagesCache);

  const [imageDataUrl, setImageDataUrl] = useState<string>('');

  useEffect(function mountUnmountHooks() {
    return () => {
      toggleLoading(false);
    };
  }, []);

  const toggleLoading = useCallback(
    (value: boolean) => {
      // for zoning we do not want to block screen
      const requestImageType = isZoning
        ? AsyncStatusType.zoningImageOverlay
        : AsyncStatusType.imageLayer;
      const status = value ? Status.Pending : Status.Done;

      dispatch(setRequestStatus(requestImageType, status));
    },
    [isZoning]
  );

  const isOriginDomainUrl = useCallback((url: string) => url?.startsWith('/'), []);

  const onLoad = () => {
    if (!isOriginDomainUrl(url)) {
      toggleLoading(false);
    }
  };

  const onError = () => {
    if (!isOriginDomainUrl(url)) {
      onErrorCallback && onErrorCallback();
      toggleLoading(false);
    }
  };

  useEffect(
    function handleUrlChange() {
      if (selectedFieldId !== 'WholeFarm') {
        !isCompare && Mixpanel.pageView();
        toggleLoading(true);
      }
    },
    [url]
  );

  useEffect(() => {
    (async () => {
      try {
        /*
         *
         * Regular images loading
         * (in feature regular images will be loading from Origin domain /service)
         * */
        if (!isOriginDomainUrl(url)) {
          setImageDataUrl(url);
        } else {
          /*
           *
           * Planet images loading.
           * Prefetch image data need for extract http headers with min,max scale information to build color schema scale
           *
           * */

          if (cachedImages.data[url]) {
            toggleLoading(false);
            return;
          }

          onStartLoadBinary?.();

          const response = await axios.get(url, {
            responseType: 'blob',
            params: {__skipPreloader: true},
          });

          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = function () {
            setImageDataUrl(reader.result as string);

            let meta: any;

            try {
              meta = JSON.parse(response.headers['fs-index-scale'] || null);
            } catch (e) {
              reportError(
                `Invalid json value at fs-index-scale ${response.headers['fs-index-scale']}, Image URL: ${url}`
              );
            }

            dispatch(saveImage(url, reader.result as string, meta));

            toggleLoading(false);
            onEndLoadBinary?.();
          };
        }
      } catch (e) {
        onErrorCallback && onErrorCallback();
        toggleLoading(false);

        if (!isOriginDomainUrl(url)) {
          onEndLoadBinary?.();
        }
      }
    })();
  }, [url, cachedImages]);

  return useMemo(
    () =>
      imageDataUrl ? (
        <ImageOverlay
          url={cachedImages.data[url] ? cachedImages.data[url].data : imageDataUrl}
          ref={l => (lRef ? lRef(l) : '')}
          bounds={bounds}
          eventHandlers={{
            load: onLoad ? onLoad : () => {},
            error: onError ? onError : () => {},
            click: onClick ? onClick : () => {},
            mousemove: onMousemove ? onMousemove : () => {},
            mouseover: onMouseover ? onMouseover : () => {},
            mouseout: onMouseout ? onMouseout : () => {},
          }}
          interactive={interactive}
          opacity={isImageLayerLoading ? 0 : imageLayerOpacity * 0.01}
        />
      ) : null,
    [id, url, imageLayerOpacity, isImageLayerLoading, imageDataUrl]
  );
};

export default FluroImageOverlay;
