import moment from 'moment';

import {
  Season,
  SamplingPoint,
  TInfoExt,
  IInitialMapState,
  IAction,
  SourceMeta,
} from 'containers/map/types';

import {
  formatDate,
  getGetURLParam,
  getSourcesMeta,
  bulkSetGetParamToURL,
  sortObjectDates,
  preselectCompareDate,
} from '_utils';
import {calculateLayerType} from 'containers/map/utils/trees';

import {
  preselectSensor,
  preselectSensors,
  SeasonUtils,
  filterDates,
  filterDatesForRemoteSensing,
  preselectRange,
} from 'containers/map/utils';
import {getSortedPointsGroup} from 'containers/map/utils/sampling-points';

import {getFilterSourcesFromUrl} from '_utils';

import initialState from '../initial-state';
import {getDefaultNSettingValues, getNrxSeason} from '../../utils/nrx-utils';
import {TSensor} from 'types';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';

const setCurrentSeasonCase = (state: IInitialMapState, action: IAction): IInitialMapState => {
  let currentDates: any = {};
  let currentSeason = {} as Season;
  let pointsGroups: any = {};
  let seasonInfoExt: Array<TInfoExt> = [];
  let newSeasonId = Number(action.currentSeasonId) || 0;
  let sourcesMeta: SourceMeta[] = [];
  if (newSeasonId) {
    currentSeason = state.field.Seasons?.length
      ? state.field.Seasons.find(s => s.id === newSeasonId) || state.field.Seasons[0]
      : {id: 0, cropType: '', startDate: '', endDate: ''};

    seasonInfoExt = currentSeason.infoExt || [];
    sourcesMeta = getSourcesMeta(seasonInfoExt);

    if (newSeasonId === state.currentSeasonId) {
      currentSeason.tissueSampling = [...state.currentSeason.tissueSampling];
    }

    if (!Array.isArray(currentSeason.tissueSampling)) {
      currentSeason.tissueSampling = [];
    }

    currentSeason.tissueSampling.forEach((ts: SamplingPoint) => {
      const key = moment(ts.properties.timedate).format(formatDate());
      pointsGroups[key] = Array.isArray(pointsGroups[key]) ? [...pointsGroups[key], ts] : [ts];
    });
  }

  const filterSources = getFilterSourcesFromUrl(sourcesMeta);

  if (filterSources.length) {
    state.remoteSensingFilterProps = [...filterSources];
  }

  currentDates = filterDates(
    state.wholeFarm.isWholeFarmView ? state.wholeFarm.wholeFarmDates : seasonInfoExt,
    pointsGroups,
    state.field.generatedNMapDates,
    state.remoteSensingCloudCover,
    state.remoteSensingFilterProps
  );

  const originalDates = !state.wholeFarm.isWholeFarmView
    ? filterDates(seasonInfoExt, {}, {}, 100, state.remoteSensingFilterProps, true)
    : state.wholeFarm.wholeFarmDates; //get all dates

  /*
   *
   * If date from the URL param is cloudy try to find this date object in
   * the full list dates and put it to filtered dates list
   *
   * layerDate=DD-MM-YYYY-source
   *
   * */
  const dateUrlParam = getGetURLParam('layerDate');
  const urlDataObj = originalDates[dateUrlParam];

  if (urlDataObj && (urlDataObj.Cloud > state.remoteSensingCloudCover || urlDataObj.Hidden)) {
    urlDataObj.Hidden = 0;
    currentDates[dateUrlParam] = urlDataObj;
    currentDates = sortObjectDates(currentDates);
  }

  const currentDatesNumber = Object.keys(currentDates).length;

  // ------

  const currentDate = SeasonUtils.getSeasonClosestDate(
    dateUrlParam || moment().format(GLOBAL_APP_DATE_FORMAT) + '-satellite',
    currentDates,
    currentSeason.startDate
  );

  const currentCompareDate = preselectCompareDate(currentDates, currentDate);

  const currentSensor = preselectSensor(currentDate, currentDates, action.currentSensor) as TSensor;

  const layerType = calculateLayerType(
    currentDate,
    state.treeDetection.layerType,
    currentSensor,
    originalDates
  );
  const range: number = preselectRange(currentSensor);

  /// clear URL params

  if (state.currentSeasonId) {
    // do not clear if it is init
    bulkSetGetParamToURL({
      'nrx-toggle': null,
      'nrx-date': null,
      message: null,
      treeDetectionLayerType: null,
    });
  }
  return {
    ...state,
    currentSeason,
    currentDates: currentDates,
    currentSeasonId: newSeasonId,
    currentDate,
    currentSensors: preselectSensors(
      currentDate,
      currentDates,
      layerType,
      currentDates
    ) as TSensor[],
    currentSensor,
    currentCompareDate,
    currentSensorCompare:
      currentDatesNumber === 1 ? 'TCI' : preselectSensor(currentCompareDate, currentDates, 'NDVI'),
    currentSensorsCompare: preselectSensors(
      currentCompareDate,
      currentDates,
      layerType,
      currentDates
    ),
    remoteSensingImages: filterDatesForRemoteSensing(
      state.wholeFarm.isWholeFarmView ? state.wholeFarm.wholeFarmDates : seasonInfoExt,
      state.remoteSensingCloudCover,
      state.remoteSensingFilterProps
    ),
    histogram: {
      ...initialState.histogram,
      range,
      bins: state.histogram.bins,
    },

    zoning: {
      ...state.zoning,
      points: {},
    },

    nitrogen: {
      ...initialState.nitrogen,
      markers: {},
    },
    remoteSensingCloudCover: state.remoteSensingCloudCover,
    pointsGroups,
    infoExt: seasonInfoExt,
    sourcesMeta,
    ...getSortedPointsGroup(state.pointsCurrentGroupDate, currentSeason.tissueSampling),
    analytics: newSeasonId !== state.currentSeasonId ? initialState.analytics : state.analytics,
    // since anomalies depend on season, need to reset them when the season is changed
    premiumAnomalies: initialState.premiumAnomalies,
    treeDetection: {
      ...state.treeDetection,
      layerType,
    },
    temperatureData: [] as any,
    preparedTemperatureData: {},
    nRecommendation: {
      ...state.nRecommendation,
      nrxResult: {...initialState.nRecommendation.nrxResult},
      toggled: false,
      nrxPopUpValues: getDefaultNSettingValues(getNrxSeason(currentSeason.id)),
    },
    originalLayerDateFormURL:
      Object.keys(currentDates).length && currentDates?.[dateUrlParam] === undefined
        ? dateUrlParam
        : '',
  };
};

export default setCurrentSeasonCase;
