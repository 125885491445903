import get from 'lodash.get';
import {getTreeDataByDate} from './trees';
import {hackGetState} from '../../../store';
import {IInitialMapState} from '../types';
import {getGetURLParam} from '_utils';
import _config from '_environment';

export const getLayerImageUrl = (isCompare = false) => {
  return getTreeLayerImageUrl(isCompare) || getSimpleLayerImageUrl(isCompare);
};

export const getTreeLayerImageUrl = (isCompare: boolean, fieldMD5?: string) => {
  const state = hackGetState();
  const {
    currentSensor = '',
    currentDate,
    currentSensorCompare = '',
    currentCompareDate,
    treeDetection: {layerType},
    wholeFarm: {isWholeFarmView, treeZoning},
    feature,
    field,
  } = state.map;

  if (isWholeFarmView && feature === 'zoning') {
    // get tree detection image
    return (
      (treeZoning.fields[fieldMD5]?.selected !== false &&
        treeZoning.fields[fieldMD5]?.zoningImageSrc) ||
      ''
    );
  }

  const sensor = isCompare ? currentSensorCompare : currentSensor;
  const date = isCompare ? currentCompareDate : currentDate;
  const treeData = layerType !== 'default' && getTreeDataByDate(date)?.[fieldMD5 || field.MD5];
  return get(treeData, `${sensor.toLowerCase()}_tree_${layerType}`, '');
};

const getSimpleLayerImageUrl = (isCompare: boolean) => {
  let url = '';
  const excludedSensors = ['TCI', 'NC'];

  const state = hackGetState() as {map: IInitialMapState};
  const {
    field,
    colorSchema,
    currentSensor,
    nitrogenOverlayURL,
    currentDate,
    currentDates,
    currentCompareDate,
    currentSensorCompare,
  } = state.map;

  // FSB-2370 One can pass &onthefly=true to the url to use on-the-fly image generating service.
  // TODO (stas): This is a temporary solution just to test on-the-fly service.
  if (getGetURLParam('onthefly')) {
    const date = currentDates[currentDate]?.Date; // format example "20200116T002051"
    const sensor = currentSensor.toLowerCase();
    return `${_config.baseUrl}services/data-service/indices/${sensor}/${field.MD5}/${date}`;
  }

  const isTirs = currentSensor === 'TIRS';
  const d = isCompare ? currentCompareDate : currentDate;
  const cs = isCompare ? currentSensorCompare : currentSensor;

  if (currentSensor === 'NMAP' && nitrogenOverlayURL) {
    url = nitrogenOverlayURL;
  } else {
    url = get(currentDates, `${d}.${cs}.url`, '');
  }

  return colorSchema === 'default' ||
    excludedSensors.includes(cs) ||
    (isCompare && excludedSensors.includes(currentSensorCompare))
    ? url
    : url.replace('.png', isTirs ? '_singleDate.png' : `_${colorSchema}.png`);
};
