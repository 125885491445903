import React, {useCallback, useMemo, useState} from 'react';
import {ZoningTabsWrapper} from './productivity-map.styled';
import FluroTabs, {FluroTabsProps} from 'components/fluro-tabs';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import moment from 'moment';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';
import {formatDate} from '_utils';
import {FontIcon} from 'react-md';
import {toggleTooltip} from '_actions';
import {Dispatch} from 'redux';
import {t} from 'i18n-utils';

export enum ZoningTabFeature {
  MainZoning = 'MainZoning',
  ProdMap = 'ProdMap',
}

const zoningTabFeatures = (currentDate: string, dispatch: Dispatch) => [
  {
    label: currentDate,
    value: ZoningTabFeature.MainZoning,
  },
  {
    label: (
      <>
        {t({id: 'Productivity map'})}{' '}
        <span
          data-tip=""
          data-for="productivity-map-info"
          onMouseEnter={() =>
            dispatch(
              toggleTooltip({
                id: 'productivity-map-info',
                content: t({
                  id:
                    'Productivity map gives an indication of yield potential by combining all NDVI images across the growing season to highlight consistent zones.',
                }),
                place: 'left',
              })
            )
          }
          className="help-icon-tab"
        >
          <FontIcon>help_outline</FontIcon>
        </span>
      </>
    ),
    value: ZoningTabFeature.ProdMap,
  },
];

export const useZoningTabs = () => {
  const dispatch = useDispatch();
  const [zoningTabFeature, setZoningTabFeature] = useState(ZoningTabFeature.MainZoning);

  const currentDate = useSelector((store: AppStore) => store.map.currentDate);

  const formattedDate = useMemo(
    () => moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(formatDate()),
    [currentDate]
  );

  const switchZoningTabFeature = useCallback((value: ZoningTabFeature) => {
    setZoningTabFeature(value);
  }, []);

  return {
    switchZoningTabFeature,
    zoningTabFeatures: zoningTabFeatures(formattedDate, dispatch),
    zoningTabFeature,
  };
};

export const ZoningTabs = (props: FluroTabsProps) => (
  <ZoningTabsWrapper>
    <div>
      <FluroTabs {...props} />
    </div>
  </ZoningTabsWrapper>
);
