import Server from '../server';
import {AgworldSyncRequestPayload} from 'containers/profile/integration/integration-agworld';

export default class {
  static getGrowers() {
    return Server.get(`sync/agworld/growers`, {params: {__skipPreloader: true}});
  }

  static syncGrowers(payload: AgworldSyncRequestPayload) {
    return Server.post('sync/agworld/import', payload, {
      params: {__skipPreloader: true},
    });
  }

  static getFarms(grower_id: string) {
    return Server.get(`sync/agworld/growers/${grower_id}/farms`, {
      params: {__skipPreloader: true},
    });
  }
  static getFields(farm_id: string) {
    return Server.get(`sync/agworld/farms/${farm_id}/fields`, {
      params: {__skipPreloader: true},
    });
  }

  static getImportStatus(jobId: number) {
    return Server.get(`sync/agworld/import/${jobId}`, {params: {__skipPreloader: true}});
  }
}
