import {reportError} from 'containers/error-boundary';
import {AppStore, AppThunkDispatch} from 'reducers';
import {Dispatch} from 'redux';
import {RequestStatus} from 'types';
import {OptisApi} from '_api';
import {unreachableError} from '_utils';
import {
  OptisMeta,
  OptisState,
  OptisType,
  OptisAreaType,
  Tillage,
  WinterCropType,
  tillageOrder,
  winterCropOrder,
} from './optis-types';

export const defaultFilterValue = {
  [OptisType.Tillage]: [Tillage.Conservation],
  [OptisType.WinterCrop]: [WinterCropType.CoverCrop],
};

const IOWA_ID = 19;
export const OPTIS_YEAR_RANGE = [2015, 2016, 2017, 2018];

const initialState: OptisState = {
  status: RequestStatus.Idle,
  geometries: {},
  meta: {
    State: {},
    CRD: {},
    County: {},
    Segment: {},
    HUC8: {},
    HUC10: {},
    HUC12: {},
  },
  tillage: {},
  winterCrop: {},
  areaType: 'CRD',
  parentId: IOWA_ID,
  filter: {
    type: OptisType.Tillage,
    years: OPTIS_YEAR_RANGE.slice(-1),
    value: defaultFilterValue[OptisType.Tillage],
    geometries: [],
  },
  diffMode: false,
  avgMode: true,
};

export default (state = initialState, action: Action): OptisState => {
  switch (action.type) {
    case ActionType.SET_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }

    case ActionType.SET_GEOMETRIES: {
      return {
        ...state,
        geometries: action.geometries,
      };
    }

    case ActionType.SET_FILTER: {
      switch (action.filter.type) {
        // The cases are absolutely the same, yes :( Helping ts to identify the type for `value`.
        case OptisType.Tillage: {
          let value = action.filter.value
            ? [action.filter.value]
            : action.filter.type === state.filter.type
            ? state.filter.value // when the filter type is the same, leave the old value
            : state.areaType === 'Segment' // when the filter type was changed (tillage <-> winter crop)
            ? tillageOrder // for segment level select all the categories
            : defaultFilterValue[action.filter.type]; // on all the other levels just select the default categories
          let years = action.filter.year ? [action.filter.year] : state.filter.years;
          if (action.filter.multiselect && action.filter.type === state.filter.type) {
            const hasValue = state.filter.value.includes(action.filter.value);
            const hasYear = state.filter.years.includes(action.filter.year);
            const modifyYear =
              hasValue && action.filter.year && !(state.filter.years.length === 1 && hasYear);
            if (modifyYear) {
              years = hasYear
                ? state.filter.years.filter(year => year !== action.filter.year)
                : [...state.filter.years, action.filter.year];
              value = state.filter.value; // don't change value if we're updating the year
            } else {
              value = state.filter.value.includes(action.filter.value)
                ? state.filter.value.filter(v => v !== action.filter.value)
                : [...state.filter.value, action.filter.value];
            }
          }
          return {
            ...state,
            filter: {
              ...state.filter,
              type: action.filter.type,
              years,
              value,
            },
          };
        }
        // The cases are absolutely the same, yes :( Helping ts to identify the type for `value`.
        case OptisType.WinterCrop: {
          let value = action.filter.value
            ? [action.filter.value] // if the new filter value is selected, just use it
            : action.filter.type === state.filter.type
            ? state.filter.value // when the filter type is the same, leave the old value
            : state.areaType === 'Segment' // when the filter type was changed (tillage <-> winter crop)
            ? winterCropOrder // for segment level select all the categories
            : defaultFilterValue[action.filter.type]; // on all the other levels just select the default categories
          let years = action.filter.year ? [action.filter.year] : state.filter.years;
          if (action.filter.multiselect && action.filter.type === state.filter.type) {
            const hasValue = state.filter.value.includes(action.filter.value);
            const hasYear = state.filter.years.includes(action.filter.year);
            const modifyYear =
              hasValue && action.filter.year && !(state.filter.years.length === 1 && hasYear);
            if (modifyYear) {
              years = hasYear
                ? state.filter.years.filter(year => year !== action.filter.year)
                : [...state.filter.years, action.filter.year];
              value = state.filter.value; // don't change value if we're updating the year
            } else {
              value = state.filter.value.includes(action.filter.value)
                ? state.filter.value.filter(v => v !== action.filter.value)
                : [...state.filter.value, action.filter.value];
            }
          }
          return {
            ...state,
            filter: {
              ...state.filter,
              type: action.filter.type,
              years,
              value,
            },
          };
        }
      }
    }

    case ActionType.SET_FILTER_YEARS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          years: action.years,
        },
      };
    }

    case ActionType.SET_FILTER_GEOMETRY: {
      const geometries =
        !action.multiselect &&
        // When it's not a multiselect click, but currently selected only 1 geometry
        // and user clicked it – deselect it, so we have nothing selected,
        // so all the geometries are active.
        !(state.filter.geometries.length == 1 && state.filter.geometries.includes(action.id))
          ? [action.id]
          : state.filter.geometries.includes(action.id)
          ? state.filter.geometries.filter(id => id !== action.id)
          : [...state.filter.geometries, action.id];
      return {
        ...state,
        filter: {
          ...state.filter,
          geometries,
        },
      };
    }

    case ActionType.SET_DATA: {
      const filter = getNewFilter(state, action.areaType);
      return {
        ...state,
        areaType: action.areaType,
        parentId: action.parentId,
        tillage: action.tillage || state.tillage,
        winterCrop: action.winterCrop || state.winterCrop,
        geometries: action.geometries || state.geometries,
        // Years could be changed if the new data doesn't have years that are set in filter.
        filter: {...filter, years: action.filter.years},
        meta: {
          ...state.meta,
          [action.areaType]: action.meta || state.meta[action.areaType],
        },
        status: action.status || state.status,
        diffMode: action.diffMode,
      };
    }

    case ActionType.SET_DIFF_MODE: {
      return {
        ...state,
        diffMode: action.diffMode,
      };
    }

    case ActionType.SET_DIFF_YEARS: {
      return {
        ...state,
        diffYearA: action.yearA,
        diffYearB: action.yearB,
      };
    }

    default:
      return state;
  }
};

enum ActionType {
  SET_STATUS = 'optis/set-status',
  SET_GEOMETRIES = 'optis/set-geometries',
  SET_DATA = 'optis/set-data',
  SET_FILTER = 'optis/set-filter',
  SET_FILTER_YEARS = 'optis/set-filter-years',
  SET_FILTER_GEOMETRY = 'optis/set-filter-geometry',
  SET_DIFF_MODE = 'optis/set-diff-mode',
  SET_DIFF_YEARS = 'optis/set-diff-years',
}

type Action =
  | SetStatusAction
  | SetGeometriesAction
  | SetDataAction
  | SetFilterAction
  | SetFilterYearsAction
  | SetFilterGeometryAction
  | SetDiffModeAction
  | SetDiffYearsAction;

type SetStatusAction = {
  type: ActionType.SET_STATUS;
  status: RequestStatus;
};

type SetGeometriesAction = {
  type: ActionType.SET_GEOMETRIES;
  geometries: OptisState['geometries'];
};

type SetDataAction = {
  type: ActionType.SET_DATA;
  areaType: OptisState['areaType'];
  parentId?: OptisState['parentId'];
  meta?: {[id: number]: OptisMeta};
  tillage?: OptisState['tillage'];
  winterCrop?: OptisState['winterCrop'];
  geometries?: OptisState['geometries'];
  filter?: OptisState['filter'];
  status?: RequestStatus;
  diffMode: OptisState['diffMode'];
};

type SetFilterAction = {
  type: ActionType.SET_FILTER;
  filter: Filter;
};

type SetFilterYearsAction = {
  type: ActionType.SET_FILTER_YEARS;
  years: number[];
};

type SetFilterGeometryAction = {
  type: ActionType.SET_FILTER_GEOMETRY;
  id: number;
  multiselect: boolean;
};

type SetDiffModeAction = {
  type: ActionType.SET_DIFF_MODE;
  diffMode: boolean;
};

type SetDiffYearsAction = {
  type: ActionType.SET_DIFF_YEARS;
  yearA: number;
  yearB: number;
};

export const setStatus = (status: RequestStatus): SetStatusAction => ({
  type: ActionType.SET_STATUS,
  status,
});

export const setGeometries = (geometries: OptisState['geometries']): SetGeometriesAction => ({
  type: ActionType.SET_GEOMETRIES,
  geometries,
});

export const setData = ({
  areaType,
  parentId,
  meta,
  tillage,
  winterCrop,
  geometries,
  filter,
  status,
  diffMode,
}: {
  areaType: OptisState['areaType'];
  parentId?: OptisState['parentId'];
  meta?: {[id: number]: OptisMeta};
  tillage?: OptisState['tillage'];
  winterCrop?: OptisState['winterCrop'];
  geometries?: OptisState['geometries'];
  filter?: OptisState['filter'];
  status?: RequestStatus;
  diffMode?: OptisState['diffMode'];
}): SetDataAction => ({
  type: ActionType.SET_DATA,
  meta,
  tillage,
  winterCrop,
  geometries,
  filter,
  areaType,
  parentId,
  status,
  diffMode,
});

export const setAreaType = (areaType: OptisAreaType, parentId?: number) => (
  dispatch: AppThunkDispatch<SetStatusAction | SetDataAction>,
  getState: () => AppStore
) => {
  const optis = getState().optis;
  dispatch(setStatus(RequestStatus.Loading));
  return OptisApi.getData({
    areaType,
    areaUnit: 'ac',
    parentID: parentId,
    from: OPTIS_YEAR_RANGE[0],
    to: OPTIS_YEAR_RANGE[OPTIS_YEAR_RANGE.length - 1],
    include: ['tillage', 'winter_crops', 'geometry', 'meta'],
  })
    .then(r => {
      if (r.data.status !== 'ok') {
        reportError(`Couldn't load optis data: ${r.data.status}`);
        dispatch(setStatus(RequestStatus.Error));
        return;
      }
      let yearsCount = 0;
      let lastYear = 0;
      // Add Conservation tillage into data.
      Object.values(r.data.result.tillage as OptisState['tillage']).forEach(entity => {
        const yearsOfData = Object.values(entity);
        yearsOfData.forEach(year => {
          year[Tillage.Conservation] = year[Tillage.NoTill] + year[Tillage.High];
        });

        if (!yearsCount) {
          yearsCount = yearsOfData.length;
        }
        if (!lastYear) {
          const years = Object.keys(entity);
          lastYear = Number(years[years.length - 1]);
        }
      });

      const filter = !optis.filter.years.includes(lastYear)
        ? {...optis.filter, years: [lastYear]}
        : optis.filter;

      dispatch(
        setData({
          tillage: r.data.result.tillage,
          winterCrop: r.data.result.winter_crop,
          geometries: r.data.result.geometries,
          meta: r.data.result.meta,
          filter,
          areaType,
          parentId,
          status: RequestStatus.Success,
          // Turn off diffMode if there are less than 2 years of data (nothing to compare).
          diffMode: yearsCount < 2 ? false : optis.diffMode,
        })
      );
    })
    .catch(e => {
      reportError(`Couldn't load optis data: ${e}`);
      dispatch(setStatus(RequestStatus.Error));
    });
};

export const setFilter = (filter: Filter): SetFilterAction => ({
  type: ActionType.SET_FILTER,
  filter,
});

export const setFilterYears = (years: number[]): SetFilterYearsAction => ({
  type: ActionType.SET_FILTER_YEARS,
  years,
});

export const setFilterGeometry = (id: number, multiselect = false): SetFilterGeometryAction => ({
  type: ActionType.SET_FILTER_GEOMETRY,
  id,
  multiselect,
});

const getNewFilter = (state: OptisState, areaType: OptisAreaType) => {
  let newFilter = state.filter;

  if (state.areaType !== 'Segment' && areaType !== 'Segment') {
    return newFilter;
  }

  // When we switch to segment, make all the categories active.
  // When we switch from segment, make the default categories active.
  switch (state.filter.type) {
    case OptisType.Tillage: {
      let value = state.filter.value;
      if (state.areaType === 'Segment') {
        value = defaultFilterValue[state.filter.type];
      } else if (areaType === 'Segment') {
        value = tillageOrder;
      }
      newFilter = {
        ...state.filter,
        geometries: [], // Clear selected area when changing the level.
        value,
      };
      break;
    }
    case OptisType.WinterCrop: {
      let value = state.filter.value;
      if (state.areaType === 'Segment' && areaType === 'County') {
        value = defaultFilterValue[state.filter.type];
      } else if (areaType === 'Segment') {
        value = winterCropOrder;
      }
      newFilter = {
        ...state.filter,
        geometries: [], // Clear selected area when changing the level.
        value,
      };
      break;
    }
    default:
      unreachableError(
        state.filter,
        `Not supported optis type "${state.filter}" in "getNewFilter" fn`
      );
  }

  return newFilter;
};

type Filter =
  | {
      type: OptisType.Tillage;
      value?: Tillage;
      year?: number;
      multiselect?: boolean;
    }
  | {
      type: OptisType.WinterCrop;
      value?: WinterCropType;
      year?: number;
      multiselect?: boolean;
    };

export const setDiffMode = (diffMode: boolean) => (
  dispatch: Dispatch<SetDiffModeAction | SetDiffYearsAction>,
  getState: () => AppStore
) => {
  const optis = getState().optis;
  if (!optis.diffYearA || !optis.diffYearB) {
    const years = optis.filter.years;
    let a = years[years.length - 2];
    let b = years[years.length - 1];
    if (years.length === 1 || years[0] === years[years.length - 1]) {
      b = years[0];
      a = Math.max(b - 1, OPTIS_YEAR_RANGE[0]);
    }
    if (b !== a) {
      dispatch(setDiffYears(a, b));
    }
  }
  dispatch({type: ActionType.SET_DIFF_MODE, diffMode});
};

export const setDiffYears = (yearA: number, yearB: number): SetDiffYearsAction => ({
  type: ActionType.SET_DIFF_YEARS,
  yearA,
  yearB,
});
