import React, {useRef} from 'react';
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js';

import 'rc-slider/assets/index.css';
import './index.scss';
import {TFeature, TSensor} from '../../types';
import {Zone} from '../../containers/map/types';
import {t} from 'i18n-utils';

type LegendProps = {
  data: any[];
  id: string;
  measurement: string;
};

type HistogramProps = {
  id: string;
  categories: any[];
  series: any[];
  statistics: any[];
  measurement: string;
  feature: TFeature;
  sensor: TSensor;
  zones: Zone[];
};

function Legend({measurement, data, id}: LegendProps) {
  return data.length ? (
    <table cellSpacing="0" className="histogram-legend">
      <thead>
        <tr>
          <td>
            <b>{t({id: 'Range'})}</b>
          </td>
          <td>
            <b>{measurement === 'ha' ? t({id: 'Hectare'}) : t({id: 'Acre'})}</b>
          </td>
          <td style={{textAlign: 'right'}}>
            <b>%</b>
          </td>
        </tr>
      </thead>

      <tbody>
        {data.map((l, i) => (
          <tr key={`${id}-map-legend-item-${i}`}>
            <td className="range-cell" style={{backgroundColor: l.color}}>
              {l.range}
            </td>
            <td>{l.area}</td>
            <td>{l.percent}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <b>{t({id: 'No data'})}</b>
  );
}

// -----

function beforeDraw(chart: any) {
  // const helpers = Chart.helpers;
  const ctx = chart.chart.ctx;
  const chartArea = chart.chartArea;

  const zones = chart.data.zones;

  if (zones && zones.length) {
    const width = Math.floor(chartArea.right - chartArea.left);
    const height = chartArea.bottom - chartArea.top;

    ctx.save();

    const zonesConverted: Partial<Zone>[] = zones.map((z: Zone) => ({
      color: z.color,
      min: Math.floor((z.min * width) / 255),
      mid: 0,
      max: Math.floor((z.max * width) / 255),
    }));

    zonesConverted.forEach(z => {
      ctx.fillStyle = z.color;
      ctx.fillRect(z.min + chartArea.left, chartArea.top, z.max - z.min, height);
    });

    ctx.fillStyle = '#f1f1f1';
    ctx.fillRect(chartArea.left, chartArea.top, zonesConverted[0].min, height);

    if (zonesConverted[zonesConverted.length - 1].mid < width) {
      ctx.fillStyle = '#f1f1f1';
      ctx.fillRect(
        zonesConverted[zonesConverted.length - 1].max + chartArea.left,
        chartArea.top,
        width - zonesConverted[zonesConverted.length - 1].max,
        height
      );
    }

    ctx.restore();
  }
}

const Histogram = ({
  measurement,
  categories,
  feature,
  id,
  sensor,
  series,
  statistics,
  zones,
}: HistogramProps) => {
  const originalChart = Chart.controllers.bar.prototype.draw;

  Chart.helpers.extend(Chart.controllers.bar.prototype, {
    draw: function() {
      let chart = this.chart.chart;
      let ctx = chart.ctx;
      ctx.save();
      ctx.shadowColor = 'rgba(0,0,0,0.2)';
      ctx.shadowBlur = 1;
      ctx.shadowOffsetX = 3;
      ctx.shadowOffsetY = -3;
      originalChart.apply(this, arguments);
      ctx.restore();
    },
  });

  let colors: string[] = [];
  const datasets = series.map(s => {
    if (s && s.color) {
      colors.push(s.color);
    } else {
      colors.push('#f1f1f1');
    }

    return s;
  });
  return (
    <div className="histogram-popup">
      <div className="histogram-popup__bar">
        <Bar
          id={`${id}--bar`}
          className="histogram-popup__chart"
          data={{
            labels: categories,
            datasets: [{backgroundColor: colors, data: datasets}],
            //@ts-ignore
            zones: zones,
          }}
          options={{
            legend: {display: false},
            responsive: false,
            scales: {
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: measurement === 'ha' ? t({id: 'Hectare(s)'}) : t({id: 'Acre(s)'}),
                  },
                  ticks: {
                    padding: 5,
                  },
                  gridLines: {
                    display: feature !== 'zoning',
                    drawBorder: false, // draw the vertical line near ticks
                    drawTicks: false, // additional dash between tick and actual tick line
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: sensor + ` ${t({id: 'Index'})}`,
                  },
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    maxTicksLimit: 5,
                    fontSize: 12,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },
              ],
            },
          }}
          plugins={[{beforeDraw: feature === 'zoning' ? beforeDraw : null}]}
          width={275}
          height={300}
        />
      </div>

      <div className="legend-container">
        <div>
          <Legend id={id} measurement={measurement} data={statistics} />
        </div>
      </div>
    </div>
  );
};

export default Histogram;
