import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Popup} from 'react-leaflet';
import {getShapeCoordinates} from '_utils/geometry';
import {AppStore} from 'reducers';
import {togglePopup} from './actions';
import {PanelViewCard, PanelViewSimpleCard} from './features/crop-performance/panel-view-card';
import {classifyNdvi} from './features/crop-performance/biomass/biomass';
import {useFieldCurrentSeason} from '_hooks/use-current-season';

export const CropPerformancePopup = () => {
  const dispatch = useDispatch();
  const cropPerformance = useSelector((s: AppStore) => s.cropPerformance);
  const fieldGeometries = useSelector((s: AppStore) => s.map.fieldGeometries);
  const fieldsByFarmId = useSelector((s: AppStore) => s.map.fieldsByFarmId);
  const openPopupId = useSelector((s: AppStore) => s.map.openPopupId);
  const records = cropPerformance.records;
  const fields = useMemo(
    () =>
      Object.keys(fieldsByFarmId)
        .filter(farmId => cropPerformance.farms[Number(farmId)])
        .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])),
    [fieldsByFarmId, cropPerformance.farms]
  );
  if (!openPopupId) {
    return null;
  }
  const record = records.find(r => r.seasonId === openPopupId);
  const field = fields.find(f => f.Seasons.find(s => s.id === openPopupId));
  const season = field?.Seasons?.find(s => s.id === openPopupId);
  const geometry = (season && season.geometry) || fieldGeometries[field?.MD5];
  if (!geometry) {
    return null;
  }
  const [lng = 0, lat = 0] = getShapeCoordinates(geometry);
  const position = {lat, lng};

  // Use field and simplified card when there is no crop performance record yet.
  if (!record) {
    if (!season) {
      return null;
    }
    return (
      <Popup
        position={position}
        className={'map-popup'}
        autoPanPaddingBottomRight={[420, 20]}
        autoPanPaddingTopLeft={[60, 10]}
        onClose={() => dispatch(togglePopup(undefined))}
      >
        <PanelViewSimpleCard
          record={{
            fieldName: field.Name,
            cropType: season?.cropType || field.CropValue,
            cropVariety: field.CropSubtype,
            startDate: season?.startDate || field.PlantingDate,
            endDate: season?.endDate || field.HarvestDate,
          }}
        />
      </Popup>
    );
  }

  // Use crop performance record and rich card.
  const biomass = classifyNdvi(
    record.smoothSatelliteNdvi,
    cropPerformance.ndviQuartiles[`${record.cropType}_${record.cropVariety}`]?.smoothSatellite
  );
  return (
    <Popup
      position={position}
      className={'map-popup'}
      autoPanPaddingBottomRight={[420, 20]}
      autoPanPaddingTopLeft={[60, 10]}
      onClose={() => dispatch(togglePopup(undefined))}
    >
      <PanelViewCard
        record={record}
        biomass={biomass}
        showFarmName={Object.keys(cropPerformance.farms).length > 1}
      />
    </Popup>
  );
};
