import axios, {AxiosResponse} from 'axios';
import {IZoning, TDateLayer} from '../containers/map/types';
import {TSensor} from '../types';
import {getZones, getInitZones, getZoningUrlParams} from 'containers/map/utils';
import config from '_environment';
import {convertFromMesureToSquareMeters, getMeasurement} from '../_utils';
import service from '_api/service';
import cancelTokenStore from '_api/cancel-tokens-store';
import {
  PRODUCTIVITY_MAP_ASSETS_HOST,
  ProductivityMapMetaData,
} from '../containers/map/features/zoning/productivity-map/productivity-map.module';
import {Browser} from 'leaflet';

export default class {
  static getZoning = (zoning: IZoning, currentImage: TDateLayer, isCustom = false) => {
    cancelTokenStore.cancel('loadZoningData');
    const source = cancelTokenStore.generateSource('loadZoningData');

    const {classes, smoothing, area, bufferValue} = zoning;
    const measurement = getMeasurement();
    const areaParam = `&area=${convertFromMesureToSquareMeters(area, measurement)}`;
    const bufferParam = bufferValue ? `&buf=${bufferValue}` : '';
    const clearedBaseUrl = config.baseUrl.slice(0, -1);

    const zonesUrl = (custom = false) => {
      return `${currentImage.classify}?${
        custom ? getInitZones(zoning) : getZones(zoning.zones)
      }type=zoning${getZoningUrlParams(zoning)}`;
    };

    if (isCustom) {
      const zonesRefreshUrl = `${currentImage.classify}?type=zoning&c=${classes}
        &m=equalInterval&s=${smoothing}${areaParam}${bufferParam}`;
      return axios
        .get(`${clearedBaseUrl}${zonesRefreshUrl}`, {
          cancelToken: source.token,
          params: {__skipPreloader: true},
        })
        .then(({data: {range, zones}}) => {
          console.log('%cInitial custom', 'color: violet; font-weight: bold;');
          return axios
            .get(`${clearedBaseUrl}${zonesUrl(true)}`, {
              cancelToken: source.token,
              params: {__skipPreloader: true},
            })
            .then((response: any) => {
              response.initialZones = zones;
              response.zonesRange = range;
              return response;
            });
        });
    }

    return axios.get(`${clearedBaseUrl}${zonesUrl()}`, {
      cancelToken: source.token,
      params: {__skipPreloader: true},
    });
  };

  static getTreeZoning = (zoning: IZoning, date: string, md5: string, currentIndex: TSensor) => {
    cancelTokenStore.cancel(`loadZoningData-${md5}`);
    const source = cancelTokenStore.generateSource(`loadZoningData-${md5}`);

    const {method, classes, treeZoningPercentage} = zoning;
    const arg = ['BestPerforming', 'WorstPerforming'].includes(method)
      ? treeZoningPercentage * 0.01
      : classes;
    const params = `md5=${md5}&sensing_date=${date}&index=${currentIndex.toLowerCase()}&method=${method}&arg=${arg}`;
    return service.get(`tree-detection/zoning?${params}`, {
      cancelToken: source.token,
      params: {__skipPreloader: true},
    });
  };

  static getBulkTreeZoning = (
    zoning: IZoning,
    fields: {md5: string; sensing_date: string}[],
    currentIndex: TSensor
  ) => {
    cancelTokenStore.cancel(`loadZoningData-bulk`);
    const source = cancelTokenStore.generateSource(`loadZoningData-bulk`);
    const {method, classes, treeZoningPercentage} = zoning;

    const arg = ['BestPerforming', 'WorstPerforming'].includes(method)
      ? treeZoningPercentage * 0.01
      : classes;

    return service.post(
      'tree-detection/zoning',
      {
        fields,
        method,
        arg,
        index: currentIndex.toLowerCase(),
      },
      {
        cancelToken: source.token,
        params: {__skipPreloader: true},
      }
    );
  };

  static loadSuggestedPoints = (url: string, options: any) => {
    cancelTokenStore.cancel('loadSuggestedPoints');
    const source = cancelTokenStore.generateSource('loadSuggestedPoints');

    return axios.get(`${config.baseUrl.slice(0, -1)}${url}`, {
      cancelToken: source.token,
      params: {__skipPreloader: true, ...(options.params ? options.params : {})},
    });
  };

  static productivityMap = async (
    md5: string,
    sensor: string,
    startDate: string,
    endDate: string,
    shapefile_name: string
  ): Promise<ProductivityMapMetaData> => {
    cancelTokenStore.cancel(`productivityMap-${md5}`);
    cancelTokenStore.cancel(`productivityMap-geojson-${md5}`);

    const source = cancelTokenStore.generateSource(`productivityMap-${md5}`);
    const source2 = cancelTokenStore.generateSource(`productivityMap-geojson-${md5}`);

    const response = await service.get(
      `data-service/stack/${sensor}/${md5}/${startDate}/${endDate}`,
      {
        cancelToken: source.token,
        params: {
          __skipPreloader: true,
          export_shapefile: shapefile_name ? 'True' : 'False',
          shapefile_name,
        },
      }
    );

    if (response.status >= 400 && response.status < 500) {
      throw new Error(response.data?.detail || 'Server error');
    }

    if (response?.data?.geojson) {
      const geoJsonResponse = await axios.get(
        PRODUCTIVITY_MAP_ASSETS_HOST + response?.data?.geojson,
        {
          cancelToken: source2.token,
          headers: {
            'Access-Control-Allow-Origin': window.location.protocol + '//' + window.location.host,
          },
          params: {
            __skipPreloader: true,
          },
        }
      );

      if (geoJsonResponse.status >= 400 && geoJsonResponse.status < 500) {
        throw new Error('Server error');
      }

      response.data.geoJsonData = geoJsonResponse.data;
    }

    return response.data;
  };
}
