import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {MenuButton, ListItem, FontIcon} from 'react-md';
import {DownloadIcon, Unmuted} from '../../../icons';
import TableViewHeader from 'components/table-view-header';
import AnomalyLabelsDropdown from '../labels-dropdown';
import {FluroButton} from 'components';

type IProps = {
  onAddNew: () => void;
  disabled: boolean;
  isReadOnly: boolean;
  isAgx?: boolean;
  onExportKml: () => void;
  onExportAgx?: () => void;
  onExportShp: () => void;
  onChangeLabel: (prop?: string, value?: any) => void;
  onSnooze: () => void;
  snoozeItems: any;
  itemList: any;
  currentDate: any;
  tabTitle: string;
};

const AnomaliesTablePanel = ({
  onAddNew,
  disabled,
  isReadOnly,
  onChangeLabel,
  onExportKml,
  onSnooze,
  onExportShp,
  snoozeItems,
  itemList,
  tabTitle,
}: IProps) => {
  return (
    <TableViewHeader
      tabTitle={<div className={'tab-title'}>{tabTitle}</div>}
      justify="between"
      buttons={
        <>
          <FluroButton
            readonly
            id={`add-new-anomaly`}
            className={'add-new-anomaly'}
            raised
            primary
            iconEl={<FontIcon>add</FontIcon>}
            onClick={onAddNew}
          >
            Add new
          </FluroButton>
          <div className={'inner-container'}>
            <div className={'buttons-text'}>
              {t({id: 'Selected shapes', defaultMessage: 'Selected shapes'})}:
            </div>
            <FluroButton
              raised
              primary
              id="btn-anomaly-snooze"
              className={'btn-anomaly-snooze'}
              disabled={!snoozeItems.length}
              iconEl={<Unmuted />}
              onClick={onSnooze}
            >
              {t({id: 'Snooze'})}
            </FluroButton>
            <AnomalyLabelsDropdown
              anomaly={itemList}
              primary
              disabled={disabled || isReadOnly}
              label={t({id: '[anomaly] Label', defaultMessage: 'Label'})}
              iconEl={<FontIcon iconClassName={'fas fa-tag'} />}
              onChange={onChangeLabel}
            />

            <MenuButton
              id="export-shapes-btn"
              className="btn-with-icon"
              raised
              primary
              iconEl={<DownloadIcon />}
              disabled={disabled}
              menuItems={[
                <ListItem
                  key={1}
                  onClick={onExportShp}
                  primaryText={t({id: 'EXPORT TO SHP', defaultMessage: 'EXPORT TO SHP'})}
                />,

                <ListItem
                  key={2}
                  onClick={onExportKml}
                  primaryText={t({id: 'EXPORT TO KML', defaultMessage: 'EXPORT TO KML'})}
                />,
              ]}
            >
              {t({id: 'Export', defaultMessage: 'Export'})}
            </MenuButton>
          </div>
        </>
      }
    />
  );
};

export default AnomaliesTablePanel;
