import React, {useMemo} from 'react';
import {GeoJSON} from 'react-leaflet';
import {Feature} from 'geojson';

const onFeature = (feature: Feature, layer: any) => {
  if (layer) {
    layer.__fluroLayerToEdit = true;
    layer.setStyle({fillColor: 'transparent', zIndex: 0});

    layer.on('pm:edit', () => {
      layer.__fluroLayerEdited = true;
    });
  }
};

export const KmlFieldOverlay = ({data}: any) => {
  const preparedData = useMemo(
    () =>
      Array.isArray(data)
        ? data
        : data.type
        ? {...data, fluroMainLayer: true}
        : {
            type: 'FeatureCollection',
            features: [],
          },
    [data]
  );

  return (
    <GeoJSON
      key={Math.random()}
      style={{className: 'field-kml'}}
      data={preparedData}
      onEachFeature={onFeature}
    />
  );
};

export default KmlFieldOverlay;
