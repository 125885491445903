import React, {useCallback, useRef, useState} from 'react';
import {Autocomplete} from 'react-md';
import {MapBoxProvider} from 'leaflet-geosearch';
import {t} from 'i18n-utils';
import cn from 'classnames';
import config from '_environment';
import {pointInUS} from '_utils/geometry';
import {access_token} from '../../tiled-map';
import {BoundsTuple} from 'leaflet-geosearch/src/providers/provider';

type Props = {
  title: string;
  value: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  onAutocomplete: (location: UpdatedSearchResult) => void;
  onKeyDown: (value: number) => void;
  activeFieldCreation: boolean;
};

export interface SearchResult<TRawResult = any> {
  x: number;
  y: number;
  label: string;
  bounds: BoundsTuple | null;
  raw: TRawResult;
}

export interface UpdatedSearchResult extends Omit<SearchResult, 'x' | 'y'> {
  title: string;
  value: number;
  x: number;
  y: number;
}

const Search = ({
  title,
  value,
  onBlur,
  onChange,
  onAutocomplete,
  onKeyDown,
  activeFieldCreation,
}: Props) => {
  const [locationsData, setLocationsData] = useState<UpdatedSearchResult[]>([]);
  const provider = new MapBoxProvider({params: {access_token, limit: 10, autocomplete: true}});
  const searchRequestId = useRef(0);
  const timeOut = useRef<ReturnType<typeof setTimeout>>(null);
  const isCarbonDomain = config.featurePack === 'carbon';

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      onKeyDown(locationsData.length);
    }
  };

  const getLocation = async (value: string) => {
    const currentRequestId = searchRequestId.current + 1;
    searchRequestId.current = currentRequestId;

    const resultSearch: SearchResult[] = await provider.search({query: value});
    if (currentRequestId !== searchRequestId.current) {
      // Axios.Cancel simulation
      return;
    }

    console.log(resultSearch);

    const newLocationsData = resultSearch
      .filter(item => {
        if (!isCarbonDomain) return true; // filter only US results for carbon FSB-5013
        return pointInUS(parseFloat(item.raw.geometry.coordinates[1]), parseFloat(item.raw.geometry.coordinates[0]));
      })
      .map(matches => ({
        ...matches,
        value: matches.raw.id,
        title: matches.label,
        x: matches.x,
        y: matches.y,
      }));

    setLocationsData(newLocationsData);
  };

  const handleChange = (value: string) => {
    onChange(value);
    timeOut.current && clearTimeout(timeOut.current);
    timeOut.current = setTimeout(() => getLocation(value), 750);
  };

  const handleAutocomplete = (value: number) => {
    const result = locationsData.find(match => match.raw.id === value);
    onAutocomplete(result);
  };

  return (
    <Autocomplete
      //@ts-ignore
      title={title}
      value={value}
      onBlur={onBlur}
      id="toolbar-search"
      className={cn('toolbar-search', {'active-field-creation': activeFieldCreation})}
      type="search"
      data={locationsData as {value: number; label: string}[]}
      placeholder={t({id: 'Search location'})}
      onChange={handleChange}
      onAutocomplete={handleAutocomplete}
      onKeyDown={handleKeyDown}
      block
      dataLabel="label"
      dataValue="value"
      filter={null}
    />
  );
};

export default Search;
