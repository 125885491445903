import {t, FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {Button} from 'react-md';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {
  formatDate,
  isAdminPerm,
  isObjectPropValuesTheSame, naturalSortAlphaNum,
  sortByDateKey,
  sortByKey,
  toFixedFloat,
} from '_utils';
import {ExternalService, Season} from 'containers/map/types';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {AppStore} from 'reducers';
import {useSelectSeason} from './use-select-season';
import {CropInfo} from 'components';
import turfArea from '@turf/area';
import {convertFromSquareMetersToMeasure} from '_utils';
import {SeasonName, SeasonListItemWrapper} from './field.styled';
import {feature} from '@turf/helpers';
import cn from 'classnames';
import ExternalServiceIcon from '../../../../header/external-service-icon';

type Props = {
  seasons: Season[];
  selectedSeasonId: number;
  isReadOnly: boolean;
  showOnlySelectedCrops: boolean;
  toggleEditSeasonPopup(sId: number): void;
  externalService?: ExternalService;
};

export const SeasonList = ({
  seasons,
  selectedSeasonId,
  isReadOnly,
  showOnlySelectedCrops,
  toggleEditSeasonPopup,
  externalService,
}: Props) => {
  const selectSeason = useSelectSeason();
  const measurement = useSelector((s: AppStore) => s.login.user.settings.measurement);
  const isAdmin = useSelector((s: AppStore) => isAdminPerm(s.login.user.perm));

  const sortedSeasons = useMemo(() => {
    // check if all planting area seasons
    // has the same sowing date - sort by planting area name
    const pantingAreaSeasons = seasons.filter(s => s.geometry_id);
    if (pantingAreaSeasons.length && isObjectPropValuesTheSame(pantingAreaSeasons, 'startDate')) {
      return naturalSortAlphaNum(seasons, 'name');
    }

    return sortByDateKey(seasons, 'startDate', true);
  }, [seasons]);

  const renderSeason = useCallback(
    (s: Season) => {
      const {cropType, params, id, startDate, endDate, geometry, name} = s;
      const cropSubType = (params && params.cropSubType) || '';
      const selected = selectedSeasonId === id; //&& seasonIntersectsWithCurrentDate(s);

      if (showOnlySelectedCrops && !selected) {
        return null;
      }

      return (
        <SeasonListItemWrapper
          key={id}
          selected={selected}
          onClick={() => !selected && selectSeason(s)}
        >
          {geometry ? (
            <div className="top-block">
              <SeasonName className={cn({selected})}>{name ? name : null}</SeasonName>

              <div className="top-block__right">
                <span>
                  (
                  {toFixedFloat(
                    //@ts-ignore
                    convertFromSquareMetersToMeasure(turfArea(feature(geometry)), measurement)
                  )}
                  {t({id: measurement})})
                </span>
              </div>
            </div>
          ) : null}

          <div className="bottom-block">
            <CropInfo
              cropType={cropType}
              cropSubType={cropSubType}
              startDate={getCropDate(startDate)}
              endDate={getCropDate(endDate)}
              hasGeometry={!!geometry}
            />

            <div className="edit-icon-wrapper">
              {externalService && isAdmin ? (
                <div className="external-icon">
                  <ExternalServiceIcon staticPosition value={externalService} />
                </div>
              ) : null}

              {!isReadOnly && (
                <Button
                  icon
                  className="edit-season"
                  tooltipPosition="left"
                  tooltipLabel={t({id: 'Edit crop'})}
                  onClick={e => {
                    e.stopPropagation();
                    toggleEditSeasonPopup(s.id);
                  }}
                >
                  edit
                </Button>
              )}
            </div>
          </div>
        </SeasonListItemWrapper>
      );
    },
    [toggleEditSeasonPopup, selectSeason]
  );

  return <div>{sortedSeasons.map(renderSeason)}</div>;
};

const getCropDate = (date: string) => moment(date, GLOBAL_FORMAT_DATE).format(formatDate());
