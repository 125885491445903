import {
  FieldTag,
  IInitialMapState,
  Season,
  IZoning,
  NRecommendationMethod,
  SamplingPoint,
  TDateLayer,
  WholeFarmTreeZoning,
  Zone,
} from '../types';
import {t} from 'i18n-utils';
// @ts-ignore
import tokml from 'tokml';
import {
  clamp,
  convertFromMesureToSquareMeters,
  convertFromSquareMetersToMeasure,
  convertUnit,
  deepCopy,
  downloadFile,
  formatDate,
  formatUnit,
  getCurrentImage,
  getGetURLParam,
  getMeasurement,
  setGetParamToURL,
  sortByKey,
  toFixedFloat,
} from '_utils';
import {classifyTreesZone, getTreeDataByDate, getTreeZoningDate} from '../utils/trees';
import {ActivityApi, AgxApi, NrxApi, NutrilogicApi, SeasonApi, ZoningApi} from '_api';
import {AsyncStatusType, dialogToggle, setRequestStatus, Status} from '../../../modules/ui-helpers';
import {Dispatch} from 'redux';
import moment from 'moment';
import {ActionTypes} from '../reducer/types';
import {showNote} from '_actions';
import {point as turfPoint} from '@turf/helpers';
import booleanContains from '@turf/boolean-contains';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {savePoint} from './sampling-points';
import {getGDDTillTSDate, getImageUrl, getZones, prepareMinArea, showWarning} from '../utils';
import {AppStore} from '../../../reducers';
import {
  checkResponseForHighRoi,
  classifyYieldGoal,
  classifyYieldUnits,
  convertUSValues,
  getLastAvailableRecommendationDate,
  getNrxFertilizerListItemData,
  getNrxSeason,
  getTotalNitrogenFromValue,
  NrxPopUpValues,
  loadNrxData,
  NRxAutomaticallyChangedROISettingsMessage,
  NRxObjectiveResponseFeature,
  NRxObjectiveType,
  NRxResultsResponse,
  NrxTabRate,
  NRxZone,
  NRxZonesCollection,
  ZONES_COLOURS,
} from '../utils/nrx-utils';
import {reportError} from '../../error-boundary';
import turfArea from '@turf/area';
import {LoginState} from '../../login/types';
import axios from 'axios';
import Mixpanel from '_utils/mixpanel-utils';
import cancelTokenStore from '_api/cancel-tokens-store';
import {union as turfUnion} from '@turf/turf';

// zoning basic actions
/**
 *  Set zoning state, a powerful action
 */
export const setZoning = (zoning: Partial<IZoning> = {}) => ({
  type: ActionTypes.MAP_SET_ZONING,
  zoning,
});

/**
 * Toggle zoning layer on map, is used only on sampling points tab. allows to see points on zones
 */
export const toggleZoning = () => ({
  type: ActionTypes.MAP_TOGGLE_ZONING,
});
/**
 * Toggle zones input edit mode. Allows to edit zones name, value.
 */
export const toggleRx = (isEnableRx: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_ZONING_RX,
  isEnableRx,
});
/**
 * Call a request to get suggested points depending on the current field status (secret algorithm)
 */
export const loadSuggestedPoints = () => (dispatch: Dispatch<any>, getState: () => AppStore) => {
  dispatch(setRequestStatus(AsyncStatusType.loadSuggestedPoints, Status.Pending));

  const state = getState();
  const url = state.map.currentDates[state.map.currentDate][state.map.currentSensor].classify;
  const {method, classes, smoothing, area} = state.map.zoning;
  const farmName = state.map.group.name;
  const fieldName = state.map.field.Name;
  const date = moment(state.map.currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  let options: any = {
    params: {
      m: method,
      c: classes,
      s: smoothing,
      area: convertFromMesureToSquareMeters(area, state.login.user.settings.measurement),
      type: 'json',
      b: state.map.zoning.zones
        .filter((z, i) => i)
        .map(z => z.min)
        .join(','),
    },
  };

  if (state.map.zoning.bufferValue) {
    options.params.buf = state.map.zoning.bufferValue;
  }

  return ZoningApi.loadSuggestedPoints(url, options)
    .then(({data: {list, color}}) => {
      dispatch(
        setSuggestedPoints({
          type: 'FeatureCollection',
          features: list.map((marker: any) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [marker[0], marker[1]],
            },
            properties: {
              farmName,
              fieldName,
              date,
              classes: marker[2] || 0,
              color: color[marker[2]]
                ? `rgba(${color[marker[2]].R}, ${color[marker[2]].G}, ${color[marker[2]].B}, 1)`
                : 'orange',
              growthStage: '',
              growerName: '-',
            },
          })),
        })
      );
      dispatch(setRequestStatus(AsyncStatusType.loadSuggestedPoints, Status.Done));
    })
    .catch(e => {
      dispatch(setRequestStatus(AsyncStatusType.loadSuggestedPoints, Status.Done, e));
    });
};

export const exportSuggestedPointsToKml = () => (dispatch: any, getState: () => AppStore) => {
  const {group: farm, field, currentDate, zoning}: IInitialMapState = getState().map;
  const fileName = `${farm.name}_${field.Name}`;
  const formattedCurrentDate = moment(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);

  downloadFile(
    tokml({
      ...zoning.points,
      features: zoning.points.features,
    }),
    `Suggested_Sampling_Points_${fileName}_${formattedCurrentDate}.kml`
  );
};

/**
 * Save suggested points as sampling points, checks if the same points were saved before.
 */
export const saveSuggestedPoints = () => (dispatch: any, getState: () => AppStore) => {
  const {currentSeason, zoning, currentDate} = getState().map;

  if (!currentSeason) {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'Please create season before saving points.'}),
        level: 'warning',
        position: 'bl',
      })
    );

    return;
  }

  const ts = [...(currentSeason.tissueSampling || [])];
  let points: Array<any> = [];

  if (zoning.points && zoning.points.features.length) {
    points = [...zoning.points.features];
  }

  const notExistPoints = points.filter(point => {
    // TODO: reverse is dirty hack should be fixed on the backend
    const turfP = turfPoint([point.geometry.coordinates[1], point.geometry.coordinates[0]]);
    let isNotEsist = true;

    ts.forEach(tsPoint => {
      const turfTSPoint = turfPoint(tsPoint.geometry.coordinates);

      if (booleanContains(turfP, turfTSPoint)) {
        isNotEsist = false;
      }
    });

    return isNotEsist;
  });

  if (notExistPoints.length) {
    const preparedMarkers = notExistPoints.map(p => ({
      id: 'new',
      ...p,
      geometry: {
        type: 'Point',
        // TODO: fix reverse lat/lng
        coordinates: [p.geometry.coordinates[1], p.geometry.coordinates[0]],
      },
      properties: {
        title: 'Untitled Point',
        timedate: moment.utc(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE),
        n_result: 0,
        n_result2: 0,
        p_result: 0,
        k_result: 0,
        classes: p.properties.classes || 0,
      },
    }));

    const promises = preparedMarkers.map(p => dispatch(savePoint(p, true)));

    Promise.all(promises)
      .then(() => {
        dispatch(setSuggestedPoints({}));
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'Points was saved as tissue sampling.'}),
            level: 'success',
          })
        );
      })
      .catch(console.log);
  } else {
    dispatch(setSuggestedPoints({}));
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'Points already saved.'}),
        level: 'warning',
      })
    );
  }
};

/**
 * Updates suggested points state value, can be used to clear the points data
 */
export const setSuggestedPoints = (points: any) => ({
  type: ActionTypes.MAP_SET_ZONING_POINTS,
  points,
});

/**
 * Loads regular and tree detection zoning
 */
export const loadZoningData = (isRefreshZoning = false) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {
    map: {
      feature,
      zoning,
      isZoning,
      treeDetection: {layerType},
    },
  }: {map: IInitialMapState} = getState();

  dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Pending));

  const isTreeAnalysis = layerType !== 'default';
  const currentImage: TDateLayer = getCurrentImage();
  const currentImageDate = getTreeZoningDate();
  const {method} = zoning;
  const isCustom = (feature === 'zoning' || isZoning) && method === 'custom' && isRefreshZoning;

  if (currentImage || (isTreeAnalysis && currentImageDate)) {
    if (isTreeAnalysis) {
      return dispatch(getTreeDetectionZoning());
    }

    return ZoningApi.getZoning(zoning, currentImage, isCustom)
      .then(
        ({
          data,
          initialZones = [],
          zonesRange = [],
        }: {
          data: any;
          zonesRange: Array<any>;
          initialZones: Array<any>;
        }) => {
          dispatch(
            setZoning({
              zones: formatZoneObject(initialZones.length ? initialZones : data.zones),
              zonesRange: zonesRange.length ? [...zonesRange] : data.range,
            })
          );

          if (isCustom) dispatch(dialogToggle('histogram', true));

          dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done));
        }
      )
      .catch(e => {
        if (!axios.isCancel(e)) {
          dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done, e));
        }
      });
  }
};

export const getTreeDetectionZoning = () => (dispatch: any, getState: () => AppStore) => {
  const {
    zoning,
    fields,
    field,
    currentSensor,
    wholeFarm: {isWholeFarmView, treeZoning},
  } = getState().map;

  if (!isWholeFarmView) {
    return ZoningApi.getTreeZoning(zoning, getTreeZoningDate(field.MD5), field.MD5, currentSensor)
      .then(({data}) => {
        dispatch(
          setZoning({
            treeZonesImage: data.png,
            treeZones: classifyTreesZone(data.zoning),
          })
        );
        dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done));
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done, e));
        }
      });
  }
  const fieldsToRequest = fields.filter(
    f => treeZoning.fields[f.MD5]?.selected && getTreeZoningDate(f.MD5)
  );

  if (!fieldsToRequest.length) {
    dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Todo));
    dispatch(
      showNote({
        title: 'Info',
        message: 'Please, use the fields dropdown to select fields to run zoning on.',
        level: 'info',
      })
    );
    return; // prevent sending of the request if there is no fields passed a filter
  }

  ZoningApi.getBulkTreeZoning(
    zoning,
    fieldsToRequest.map(f => ({md5: f.MD5, sensing_date: getTreeZoningDate(f.MD5)})),
    currentSensor
  )
    .then(({data}) => {
      const wholeFarmZoning: WholeFarmTreeZoning = {
        fields: {},
        zones: classifyTreesZone(data.zoning),
      };

      fieldsToRequest.forEach(f => {
        wholeFarmZoning.fields[f.MD5] = {
          ...(treeZoning.fields[f.MD5] || {}), // save prev data
          zoningImageSrc: data[f.MD5],
        };
      });

      // result.forEach(({data}, index) => {
      //   wholeFarmZoning.fields[fieldsToRequest[index].MD5] =
      //     treeZoning.fields[fieldsToRequest[index].MD5] || {}; // try to get the current field data, if it exists
      //
      //   wholeFarmZoning.fields[fieldsToRequest[index].MD5].zoningImageSrc = data.png;
      //   wholeFarmZoning.fields[fieldsToRequest[index].MD5].zones = classifyTreesZone(
      //     data.zoning,
      //     isWholeFarmView
      //   );
      // });

      dispatch({
        type: ActionTypes.MAP_SET_WHOLE_FARM_TREE_ZONING_FIELDS,
        data: wholeFarmZoning,
      });

      dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done));
    })
    .catch(e => {
      if (!axios.isCancel(e)) {
        dispatch(setRequestStatus(AsyncStatusType.mainZoning, Status.Done, e));
      }
    });
};

const formatZoneObject = (zones: any[]) => {
  const measurement = getMeasurement();
  return zones.map((z, index) => {
    return {
      id: index + 1,
      min: toFixedFloat(z[1][0], 2),
      max: toFixedFloat(z[1][2], 2),
      mid: toFixedFloat(z[1][1], 2),
      area: toFixedFloat(measurement === 'ha' ? z[4][0] : z[3][0], 2),
      color: `rgba(${z[0][0]}, ${z[0][1]}, ${z[0][2]}, ${z[0][3]})`,
      percent: toFixedFloat(z[2][0], 1),
    };
  });
};

/**
 * Something related to custom zoning
 */
export const updateZonesRange = (values: any) => ({
  type: ActionTypes.MAP_UPDATE_ZONES_RANGE,
  values,
});

/**
 * Something related to custom zoning
 */
export const updateCopyZonesRange = (values: any) => ({
  type: ActionTypes.MAP_UPDATE_COPY_ZONES_RANGE,
  values,
});

/**
 * Update regular zoning zone prop
 */
export const updateZoningZone = (id: number, prop: string, value: any) => ({
  type: ActionTypes.MAP_UPDATE_ZONE_PROP,
  id,
  prop,
  value,
});

/**
 * Update regular zoning zone prop
 */
export const setZoningUnits = (value: {label: string; value: string}) => ({
  type: ActionTypes.MAP_SET_ZONING_UNITS,
  value,
});

// N recommendation basic (apsim, nutrilogic)
/**
 * Toggles nitrogen recommendation, could be apsim or nutrilogic
 */
export const toggleNRecommendation = (value: boolean, method: NRecommendationMethod) => (
  dispatch: any,
  getState: () => AppStore
) => {
  if (value) {
    if (method === 'nutrilogic') {
      Mixpanel.getNutrilogic();
      dispatch(runNutrilogicRecommendation());
    } else {
      const {
        nRecommendation: {nrxResult, nrxTabRate, selectedObjective},
        feature,
      } = getState().map;
      if (feature === 'zoning') {
        dispatch(loadNrxData()).then(() => {
          if (!nrxResult[nrxTabRate][selectedObjective].type) {
            dispatch(getNRxRecommendation());
          }
        });
      }
    }
  }
  if (method === 'apsim') {
    setGetParamToURL('nrx-toggle', value ? 'true' : 'false');
  }

  dispatch({
    type: ActionTypes.MAP_TOGGLE_N_RECOMMENDATION,
    value,
    nRecommendationMethod: method,
  });
};

// NRx

export const getNRxRecommendation = () => async (dispatch: any, getState: () => AppStore) => {
  const REQUEST_NUMBER = 3;

  const {
    map: {
      nRecommendation: {nrxPopUpValues, toggled, nrxTabRate},
      currentSeasonId,
      field,
    },
    login,
  } = getState();
  const season = getNrxSeason(currentSeasonId);
  let isSevenZonesRqst = false;
  const measurement = login.user.settings.measurement;
  const preparedData: Partial<NrxPopUpValues> & {
    md5: string;
    season_startdate: string;
    nrx_season_id: number;
    blanket_rate: boolean;
  } = {
    crop_price: nrxPopUpValues.crop_price,
    N_price: nrxPopUpValues.N_price,
    n_percentage: nrxPopUpValues.n_percentage,
    season_outlook: nrxPopUpValues.season_outlook,
    N_constrain: nrxPopUpValues.N_constrain,
    ROI_setting: nrxPopUpValues.ROI_setting,
    specific_gravity: nrxPopUpValues.specific_gravity,
    recommendation_date:
      nrxPopUpValues.recommendation_date ||
      getLastAvailableRecommendationDate(season.recommendationDates),
    md5: field.MD5,
    season_startdate: moment(season.startDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
    nrx_season_id: season.nrxSeasonID,
    isLiquid: nrxPopUpValues.isLiquid,
    blanket_rate: nrxTabRate === 'blanket',
    historical_yield_avg: classifyYieldGoal(
      nrxPopUpValues.historical_yield_avg,
      measurement,
      season.cropID,
      true
    ),
  };

  if (nrxPopUpValues.min_yield_goal !== undefined || nrxPopUpValues.max_yield_goal !== undefined) {
    preparedData.min_yield_goal = nrxPopUpValues.min_yield_goal;
    preparedData.max_yield_goal = nrxPopUpValues.max_yield_goal;
    isSevenZonesRqst = true;
  }

  if (!nrxPopUpValues.recommendation_date && preparedData.recommendation_date) {
    dispatch(updateRecommendationSettings({recommendation_date: preparedData.recommendation_date}));
  }

  const requestData = convertUSValues(preparedData, season.cropID, measurement, true);
  if (!getGetURLParam('nrx-date') && preparedData.recommendation_date) {
    setGetParamToURL('nrx-date', preparedData.recommendation_date); // set nrx-date URL param if it misses by default
  }

  if (toggled && requestData.recommendation_date) {
    // try to cancel prev requests
    for (let i = 0; i <= REQUEST_NUMBER; i++) {
      cancelTokenStore.cancel(`getNRxRecommendation${i}`);
    }

    dispatch(setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Pending));

    let resultResponse: NRxResultsResponse & {resultRoiSettings?: number} = undefined;
    const resultUnits = requestData.isLiquid ? 'gal/ac' : 'lbs/ac';

    for (let i = requestData.ROI_setting; i <= 3; i++) {
      // here we are trying to get some NRx results by increasing ROI settings
      if (!resultResponse) {
        try {
          if (i !== requestData.ROI_setting) {
            dispatch(updateRecommendationSettings({...nrxPopUpValues, ROI_setting: i})); // keep the pop-up values consistent
          }

          const response = await NrxApi.getNRxRecommendation(
            {...requestData, ROI_setting: i},
            isSevenZonesRqst,
            i // pass index to identify request for create cancel token
          );
          if (checkResponseForHighRoi(response.data) || i === 3) {
            resultResponse = response.data;
            resultResponse.resultRoiSettings = i;
            if (i !== nrxPopUpValues.ROI_setting) {
              dispatch(
                showNote({
                  title: t({id: 'note.info', defaultMessage: 'Info'}),
                  message: NRxAutomaticallyChangedROISettingsMessage(
                    nrxPopUpValues.ROI_setting,
                    i,
                    () => dispatch(toggleNRxSettingsPopUp(true))
                  ),
                  level: 'info',
                  autoDismiss: 30,
                })
              );
            }
          }
        } catch (err) {
          if (!err?.message?.startsWith('__canceled')) {
            dispatch(setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Done));
            reportError(`getNRxRecommendation err = ${err}`);

            dispatch(
              showNote({
                title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                message: t({
                  id: 'The model encountered an issue, please contact our support Team.',
                }),
                level: 'warning',
              })
            );
          }

          return {data: {}};
        }
      }
    }

    const resultRecommendation = {
      balanced: {} as NRxZonesCollection,
      max_roi: {} as NRxZonesCollection,
      max_yield: {} as NRxZonesCollection,
    };

    Object.keys(resultResponse).forEach((objective: NRxObjectiveType) => {
      if (!resultResponse[objective]?.map?.features?.length) return;

      const currentObjective = resultResponse[objective];

      resultRecommendation[objective] = {
        type: 'FeatureCollection',
        avgNrx: toFixedFloat(convertUnit(measurement, resultUnits, currentObjective.avg_nrx), 0),
        yieldIncrease: currentObjective.avg_yield_incr,
        yield_potential_units: classifyYieldUnits(measurement, season.cropID),
        yield_potential_max: classifyYieldGoal(
          resultResponse[objective].yield_potential_max,
          measurement,
          season.cropID
        ),
        yield_potential_min: classifyYieldGoal(
          resultResponse[objective].yield_potential_min,
          measurement,
          season.cropID
        ),
        features: sortByKey(
          currentObjective.map.features.map((f: NRxObjectiveResponseFeature) => {
            const zoneArea = convertFromSquareMetersToMeasure(turfArea(f), measurement);
            const fieldArea = convertUnit(measurement, 'ac', field.Area);
            const zonePercentSize = (zoneArea / fieldArea) * 100;
            //convert result if need
            let rxValue = toFixedFloat(convertUnit(measurement, resultUnits, f.properties.val), 0);
            let nitrogenAmount = getTotalNitrogenFromValue(
              rxValue,
              requestData.n_percentage,
              requestData.isLiquid,
              requestData.specific_gravity,
              measurement
            );

            if (
              // if only one zone was returned with an empty value, format the output
              resultResponse.resultRoiSettings === 3 &&
              currentObjective.map.features.length === 1 &&
              rxValue === 0
            ) {
              rxValue = '-';
            }
            const additionalProps: Partial<Zone> = {};
            if (isSevenZonesRqst) {
              additionalProps.yield_goal = f.properties.yield_goal || 0;
              additionalProps.yield_goal_units = t({id: 'bu/ac'});
            }

            if (f.properties.crop_yield) {
              additionalProps.yield_goal_units = classifyYieldUnits(measurement, season.cropID);
              additionalProps.yield_goal = classifyYieldGoal(
                f.properties.crop_yield,
                measurement,
                season.cropID
              );
            }

            return {
              ...f,
              properties: {
                resultROISettings: resultResponse.resultRoiSettings,
                area: zoneArea,
                value: rxValue,
                nitrogenAmount,
                percent: clamp(0, zonePercentSize, 100),
                ...additionalProps,
              },
            };
          }),
          'properties.value'
        )
          .reverse() // reverse sorted fields
          .map((zone, i, arr) => ({
            // set properties that are relied on the index after sorting to avoid unordered zones names and colors
            ...zone,
            properties: {
              ...zone.properties,
              id: i + 1,
              name: `Zone ${i + 1}`,
              color:
                arr.length > 1 && i + 1 === arr.length
                  ? ZONES_COLOURS[ZONES_COLOURS.length - 1]
                  : ZONES_COLOURS[i],
            },
          })), // from bigger to lower value
      };
    });

    dispatch({
      type: ActionTypes.MAP_NRX_SET_RECOMMENDATION_RESULT,
      value: resultRecommendation,
    });

    dispatch(setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Done));

    return Promise.resolve(true);
  }

  // try to cancel prev requests
  for (let i = 0; i <= REQUEST_NUMBER; i++) {
    cancelTokenStore.cancel(`getNRxRecommendation${i}`);
  }
  dispatch(setRequestStatus(AsyncStatusType.NRxRecommendation, Status.Done));

  return Promise.resolve(false);
};

export const updateNRxRecommendationProps = (data: any) => ({
  type: ActionTypes.MAP_NRX_UPDATE_RECOMMENDATION_PROPS,
  data,
});

export const updateRecommendationSettings = (
  value: Partial<NrxPopUpValues>,
  saveValues = false
) => (dispatch: any, getState: () => AppStore) => {
  if (saveValues) {
    const {login, map}: {login: LoginState; map: IInitialMapState} = getState();
    const measurement = login.user.settings.measurement;
    const nrxSeason = getNrxSeason(map.currentSeasonId);
    const clearedValues = {
      ...convertUSValues(value, nrxSeason.cropID, 'ha'), // to clear the values
      recommendation_date:
        value.recommendation_date ||
        getLastAvailableRecommendationDate(nrxSeason.recommendationDates),
      units: measurement,
    };
    dispatch(setRequestStatus(AsyncStatusType.NRXSettings, Status.Pending));

    NrxApi.saveRecommendationSettings(clearedValues, nrxSeason.nrxSeasonID)
      .then(() => {
        // update nrx season in state
        dispatch({
          type: ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA,
          seasonID: map.currentSeasonId,
          data: {roiSettings: {...(nrxSeason.roiSettings || {}), ...clearedValues}},
        });
        dispatch(setRequestStatus(AsyncStatusType.NRXSettings, Status.Done));
      })
      .catch(err => {
        dispatch(setRequestStatus(AsyncStatusType.NRXSettings, Status.Done, err));
      });
  }
  dispatch({
    type: ActionTypes.MAP_NRX_UPDATE_POP_UP_SETTINGS,
    value,
  });
};

export const updateRecommendationDate = (date: string) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {
    nRecommendation: {nrxPopUpValues},
    currentSeasonId,
  }: IInitialMapState = getState().map;
  const {recommendationDates} = getNrxSeason(currentSeasonId);
  let resultValue = '';

  if (recommendationDates.includes(date)) {
    resultValue = date;
  } else {
    if (!resultValue && !nrxPopUpValues.recommendation_date && recommendationDates.length)
      // set the last recommendation date if any is selected
      resultValue = getLastAvailableRecommendationDate(recommendationDates);

    dispatch(
      showNote({
        title: t({id: 'note.info', defaultMessage: 'Info'}),
        message: t({
          id: 'Results are not available for this recommendation date, please select another date.',
        }),
        level: 'info',
      })
    );
  }

  if (resultValue && nrxPopUpValues.recommendation_date !== resultValue) {
    dispatch(updateRecommendationSettings({recommendation_date: resultValue}));
    setGetParamToURL('nrx-date', date);
    return dispatch(getNRxRecommendation());
  }
};

export const toggleNRxSettingsPopUp = (value: boolean) => ({
  type: ActionTypes.MAP_NRX_TOGGLE_SETTINGS_POP_UP,
  value,
});

export const toggleNRxTabRate = (value: NrxTabRate) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {nrxResult, selectedObjective} = getState().map.nRecommendation;

  dispatch({
    type: ActionTypes.MAP_NRX_TOGGLE_RATE_TAB,
    value,
  });

  if (!nrxResult[value][selectedObjective].features) {
    // load new-opened tab recommendation
    dispatch(getNRxRecommendation());
  }
};

export const selectNRxObjective = (value: NRxObjectiveType) => ({
  type: ActionTypes.MAP_NRX_SELECT_OBJECTIVE,
  value,
});

export const calculateMergedZonesName = (zones: NRxZone[]): {name: string; ids: number[]} => {
  const mergedZonesIds = zones // get sorted zones id that will be merged
    .map(z => (z.properties.merged === 'merged' ? z.properties.mergedZonesIds : z.properties.id))
    .flat() // flat the array if there are mergedZonesIds
    .filter(id => id) // remove 0 ids
    .sort();

  const preparedIds = mergedZonesIds.map((id, index) => {
    const nextId = mergedZonesIds[index + 1];
    const prevId = mergedZonesIds[index - 1];
    const comaOrDash = nextId ? (nextId - id > 1 ? ', ' : '-') : '';

    if (nextId && prevId && id - prevId === 1 && nextId - id === 1) return null; // intermediate element

    return `${id}${comaOrDash}`;
  });

  return {name: preparedIds.join(''), ids: mergedZonesIds};
};

export const mergeNRxZones = () => (dispatch: any, getState: () => AppStore) => {
  const {
    field,
    nRecommendation: {nrxResult, nrxTabRate, nrxPopUpValues, selectedObjective},
  } = getState().map;
  const measurement = getState().login.user.settings.measurement;
  const zonesToMerge = [] as NRxZone[];
  const currentZones = nrxResult[nrxTabRate][selectedObjective].features
    .map((zone: NRxZone) => {
      if (zone.properties.selected) {
        zonesToMerge.push(zone);
        if (zone.properties.merged === 'merged') return zone; // makes able filtering old merged zones

        zone.properties.selected = false; // reset selected prop;
        zone.properties.merged = 'initial'; // set a prop to recognize the initial zone in future
      }
      return zone;
    })
    .filter((z: any) => !(z.properties.selected && z.properties.merged === 'merged')); // filter old merged zones (if won't do it after unmergining they will appear as regular zones)

  const fieldArea = convertUnit(measurement, 'ac', field.Area);

  const mergedZones = zonesToMerge.reduce(
    (resultZone: any, currentZone: any, index: number) => {
      if (!index) return currentZone; // skip the first zone because it is the default value
      const zone1Props = resultZone.properties;
      const zone2Props = currentZone.properties;
      const resultGeometry = turfUnion(resultZone, currentZone); // merge geometries
      const zoneArea = convertFromSquareMetersToMeasure(turfArea(resultGeometry), measurement); // regular area calculating
      const properties = {} as Zone;

      const calculateAvgValueByProp = (zone1: any, zone2: any, prop: string) => {
        return toFixedFloat(
          (zone1.properties.area * zone1.properties[prop] + // the formula is ((val1 * area1)+(val2 * area2))/(area1 + area2)
            zone2.properties.area * zone2.properties[prop]) /
            (zone1.properties.area + zone2.properties.area),
          0
        );
      };
      properties.id =
        nrxResult[nrxTabRate][selectedObjective].features.length +
        currentZones.filter((f: any) => f.properties.merged === 'initial').length; // get all the zones length and add initial zones length to get a uniq index
      // RECALCULATE ZONE VALUES
      properties.area = zoneArea;
      properties.selected = false;
      properties.merged = 'merged'; // important flag to find the result merged zones in future
      properties.color = zone1Props.color; // take the color from the first geometry (this one can be changed)
      properties.resultROISettings = zone2Props.resultROISettings; // this one is the same for all the NRx zones
      properties.value = calculateAvgValueByProp(resultZone, currentZone, 'value');
      properties.nitrogenAmount = calculateAvgValueByProp(
        resultZone,
        currentZone,
        'nitrogenAmount'
      );
      properties.yield_goal = calculateAvgValueByProp(resultZone, currentZone, 'yield_goal');
      properties.percent = clamp(0, (zoneArea / fieldArea) * 100, 100);

      return {...resultGeometry, properties};
    },
    {...zonesToMerge[0]}
  );

  const {name, ids} = calculateMergedZonesName(zonesToMerge);
  mergedZones.properties.name = name;
  mergedZones.properties.mergedZonesIds = ids;

  dispatch({
    type: ActionTypes.MAP_NRX_MERGE_ZONES,
    zones: sortByKey([mergedZones, ...currentZones], 'properties.value').reverse(),
  });
};

export const revertMergeNRxZones = () => (dispatch: any, getState: () => AppStore) => {
  const {nrxResult, nrxTabRate, selectedObjective} = getState().map.nRecommendation;
  dispatch({
    type: ActionTypes.MAP_NRX_REVERT_MERGE_ZONES,
    zones: nrxResult[nrxTabRate][selectedObjective].features
      .filter((f: any) => f.properties.merged !== 'merged') // simply filter merged zones
      .map((f: any) => ({...f, properties: {...f.properties, merged: false}})), // reset merged prop for the rest of the zones to make them "pure" again
  });
};

//// end NRx section

// nutrilogic // todo this section should be refactored

/**
 * Nutrilogic is a specific algorithm to get the N recommendation. it is based on Nitrate pmm value in a specific zone + GDD (n_result2)
 * The model can accept much more data, but it is not implemented, yet. It depends on sampling points sample date, not the selected layer date.
 * Steps description:
 * 1. Takes total GDD till sampling points group date.
 * 2. Sends to back-end current points to get grey index (it helps to detect which point to which zone belongs)
 * 3. Gets fertilizer for each point (i think it is senseless, because there is no connection between point,
 *    just n_result2 and GDD, need to consider this one) and updates zoning zones with result value.
 * 4.
 */

export const runNutrilogicRecommendation = () => async (
  dispatch: any,
  getState: () => AppStore
) => {
  const {
    pointsGroups,
    pointsCurrentGroupDate,
    feature,
    nRecommendation,
    temperatureData,
    currentSeason,
  } = getState().map;
  if (!pointsGroups[pointsCurrentGroupDate]) {
    return dispatch(showWarning(feature, 'no-tsp-group'));
  }

  if (temperatureData.length) {
    if (
      !moment(temperatureData[0].date, GLOBAL_FORMAT_DATE).isSame(
        moment(currentSeason.startDate, GLOBAL_FORMAT_DATE),
        'day'
      )
    ) {
      return dispatch(showWarning(feature, 'weather-missing'));
    }
  }

  const GDD = getGDDTillTSDate()(dispatch, getState); // 1

  if (!GDD) {
    // stop executing function if don't have GDD
    return dispatch(nutrilogicHandleNoGDDCase(pointsCurrentGroupDate, currentSeason));
  }
  await dispatch(getAndSavePointsPosition()); // 2
  const zonesWithFertilizer = await getFertilize(GDD)(dispatch, getState); //3

  if (
    !nRecommendation.zonesWithNData.length &&
    zonesWithFertilizer.length &&
    feature === 'zoning'
  ) {
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Your recommendation was generated.'}),
        level: 'success',
      })
    );
  }

  dispatch({
    type: ActionTypes.MAP_UPDATE_N_DATA,
    data: zonesWithFertilizer,
  });
};

const getFertilize = (gdd = 0) => async (dispatch: any, getState: () => AppStore) => {
  const state = getState().map;
  const {pointsGroups, pointsCurrentGroupDate, feature} = state;
  let noEmptyPoints = pointsGroups[pointsCurrentGroupDate].filter(
    (point: SamplingPoint) => point.properties.n_result2
  );

  if (!noEmptyPoints.length) {
    dispatch(showWarning(feature, 'empty-points')); // if no points whit nitrate ppm
    return [];
  }

  noEmptyPoints = await Promise.all(
    noEmptyPoints.map(async (point: SamplingPoint) => {
      // get rx value according to points nitrate ppm value
      const nitrateValue = point.properties.n_result2;
      const recommendationValue = await getNutrilogic([
        {Gdd: gdd, Nitrate: parseInt(nitrateValue, 10)},
      ]);
      point.rxValue = parseInt(recommendationValue, 10);
      return point;
    })
  );
  return state.zoning.zones.map((zone: any, i: number, arr: Array<any>) => {
    const zoneMinValue = zone.min;
    const zoneMaxValue = zone.max;
    noEmptyPoints.forEach((point: SamplingPoint) => {
      const {silverIndex, rxValue} = point;

      if (
        (silverIndex >= zoneMinValue && silverIndex < zoneMaxValue) ||
        (i === arr.length - 1 && silverIndex === zoneMaxValue)
      ) {
        // get point's zone
        zone.value = zone.value ? (zone.value + rxValue) / 2 : rxValue; // if already have value, calculate average
      }
    });
    return zone;
  });
};

const getNutrilogic = (data: any) => NutrilogicApi.petiole(data).then(({data}) => data.rxvalue);

const getAndSavePointsPosition = () => (dispatch: any, getState: () => AppStore) => {
  const {pointsGroups, pointsCurrentGroupDate} = getState().map;
  const currentPointsGroup = pointsGroups?.[pointsCurrentGroupDate];
  const path = getImageUrl();

  let arrayPoints = [] as any[];

  if (currentPointsGroup?.length) {
    // get coordinates from points
    arrayPoints = currentPointsGroup.map((point: SamplingPoint) => point.geometry.coordinates);
  }
  return ActivityApi.getPointsSilverIndex(path, arrayPoints)
    .then(({data}) => {
      currentPointsGroup.forEach(
        (point: SamplingPoint, i: number) => (point.silverIndex = data[i])
      );

      dispatch({
        type: ActionTypes.MAP_POINTS_UPDATE_WITH_NUTRILOGIC_DATA, // update TSP group with points, that have silverIndex
        value: currentPointsGroup,
        date: pointsCurrentGroupDate,
      });
    })
    .catch(err => console.log('err', err));
};

const nutrilogicHandleNoGDDCase = (pointsCurrentGroupDate: string, currentSeason: Season) => (
  dispatch: any
) => {
  const pointsDate = moment(pointsCurrentGroupDate, formatDate());
  let string = '';

  if (pointsDate.isBefore(moment(currentSeason.startDate, GLOBAL_FORMAT_DATE)))
    string = t({
      id:
        'Your recommendations can not be generated because your sampling date is before the sowing date.',
    });
  else if (pointsDate.isAfter(moment(currentSeason.endDate, GLOBAL_FORMAT_DATE)))
    string = t({
      id:
        'Your recommendations can not be generated because your sampling date is after the harvest date.',
    });
  else if (pointsDate.isAfter(moment()))
    string = t({
      id:
        'Your recommendations can not be generated because your sampling date is after the current date.',
    });

  if (string) {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: string,
        level: 'warning',
      })
    );
  }
};

/// end nutrilogic section

// trees zoning

export const setTreeZoneParam = (id: number, data: any) => ({
  type: ActionTypes.MAP_SET_TREE_ZONE_PARAM,
  id,
  data,
});

export const toggleTreeZoningFields = (fieldMD5s: string[], value: boolean) => ({
  type: ActionTypes.MAP_TOGGLE_WHOLE_FARM_TREE_ZONING_FIELD,
  fieldMD5s,
  value,
});

// end trees zoning section

export const saveZonesToAgX = (result: any) => (dispatch: any, getState: () => AppStore) => {
  const {
    map: {group, field, currentSeasonId, zoning},
    login: {user},
  } = getState();

  const requestData = {
    FsUserId: user.id,
    FieldID: field.ID,
    SeasonId: currentSeasonId,
    // FertilizerProduct: zoning.product.Name,
    // FertilizerProductId: zoning.product.Id,
    ApplicationMethodId: zoning.application.method,
    ApplicationTimingId: zoning.application.timing,
    ReiUnitId: zoning.application.reiType,
    ReiValue: zoning.application.reiValue,
    Zones: result,
  };

  AgxApi.saveZonesToAgX(`${group.id}/${field.ID}/${currentSeasonId}`, requestData).then(() => {
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Your data was sent to agX.'}),
        level: 'success',
      })
    );
  });
};

/// export zoning

export const exportZoningShapeFile = () => (dispatch: any, getState: () => AppStore) => {
  Mixpanel.exportZoning('SHP');
  const {
    field,
    zoning,
    currentDates,
    currentDate,
    currentSensor,
    group: farm,
  }: IInitialMapState = getState().map;
  const measurement = getMeasurement();
  const formattedCurrentDate = moment(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);
  const fileName = `Zones_${farm.name}_${field.Name}_${formattedCurrentDate}_${currentSensor}.zip`;

  const RXFields = `&fields=${JSON.stringify(
    [...zoning.zones]
      .reverse() // because zones are displayed to user in the reversed order
      .map((z: Zone) => {
        z.value = z.value ? parseFloat(`${z.value}`) : 0;
        z.units = zoning.currentUnits.label;
        z.name = z.name || '';
        return z;
      })
  )}`;

  const customZones = zoning.method === 'custom' ? getZones(zoning.zones) : '';

  const zipName = encodeURIComponent(fileName);
  const url =
    currentDates[currentDate][currentSensor].classify +
    '/' +
    encodeURIComponent(fileName) +
    `?area=${convertFromMesureToSquareMeters(
      prepareMinArea(zoning.area),
      measurement
    )}&measurement=${measurement}${RXFields}&c=${zoning.classes}&m=${zoning.method}&buf=${
      zoning.bufferValue
    }&type=zip&${customZones}field=${encodeURIComponent(zipName)}&fieldName=${encodeURIComponent(
      field.Name
    )}&farmName=${encodeURIComponent(farm.name)}&date=${formattedCurrentDate}&growerName=-`;
  //@ts-ignore
  window.open(url);
};

export const exportNrxToShp = () => (dispatch: any, getState: () => AppStore) => {
  const {
    nRecommendation: {nrxResult, nrxPopUpValues, nrxTabRate, selectedObjective},
    group: farm,
    field,
  }: IInitialMapState = getState().map;
  const measurement = getMeasurement();
  let gjson = deepCopy(nrxResult[nrxTabRate][selectedObjective]);
  const formattedNRxDate = moment(
    nrxPopUpValues.recommendation_date || getGetURLParam('nrx-date'),
    GLOBAL_FORMAT_DATE
  ).format(GLOBAL_FORMAT_DATE);
  const name = `Nitrogen_Recommendation_${farm.name}_${field.Name}_${formattedNRxDate}`;
  if (gjson?.features?.length) {
    gjson.features = gjson.features
      .filter((f: NRxZone) => f.properties.merged !== 'initial')
      .map((f: NRxZone) => {
        const properties = {
          date: formattedNRxDate,
          growerName: farm.growerName || '',
          farmName: farm.name,
          fieldName: field.Name,
          zoneID: f.properties.id,
          zoneName: f.properties.name || `Zone ${f.properties.id}`,
          [`area_${measurement}`]: f.properties.area?.toFixed(1),
          areaPercent: f.properties.percent?.toFixed(1),
          value: f.properties.value,
          product: nrxPopUpValues.product,
        };

        return {
          ...f,
          properties: {...properties, _order: Object.keys(properties)},
        };
      });
  }
  Mixpanel.exportNRx('SHP');
  SeasonApi.downloadShapefile([gjson], name).then(({data}) => {
    downloadFile(data, name + '.zip');
  });
};

export const exportNrxToKml = () => (dispatch: any, getState: () => AppStore) => {
  const {
    nRecommendation: {nrxResult, nrxTabRate, nrxPopUpValues, selectedObjective},
    group: farm,
    field,
  } = getState().map;
  const measurement = getMeasurement();

  const formattedNRxDate = moment(
    nrxPopUpValues.recommendation_date || getGetURLParam('nrx-date'),
    GLOBAL_FORMAT_DATE
  ).format(GLOBAL_FORMAT_DATE);
  const preparedData = {
    ...nrxResult[nrxTabRate][selectedObjective],
    features: nrxResult[nrxTabRate][selectedObjective].features
      .filter((f: NRxZone) => f.properties.merged !== 'initial')
      .map((f: NRxZone) => {
        return {
          ...f,
          properties: {
            area: `${toFixedFloat(f.properties.area, 1)} ${t({id: measurement})}`,
            value: f.properties.value,
            percentArea: toFixedFloat(f.properties.percent, 1),
            fieldName: field.Name,
            farmName: farm.name,
            date: formattedNRxDate,
            zoneName: f.properties.name || `${t({id: 'Zone'})} ${f.properties.id}`,
          },
        };
      }),
  };
  Mixpanel.exportNRx('KML');
  downloadFile(
    tokml(preparedData),
    `Nitrogen_Recommendation_${field.Name}_${formattedNRxDate}.kml`
  );
};

export const exportNrxToAgx = () => (dispatch: any, getState: () => AppStore) => {
  const {
    map: {
      nRecommendation: {nrxResult, nrxTabRate, nrxPopUpValues, selectedObjective},
      group: farm,
      field,
      currentSeasonId,
    },
    login,
  } = getState();

  const productMeasure = formatUnit(
    login.user.settings.measurement,
    nrxPopUpValues.isLiquid ? 'l/ha' : 'kg/ha'
  );

  const preparedData = {
    fertilizer_product_id: getNrxFertilizerListItemData(nrxPopUpValues.product).typeID,
    date: moment(nrxPopUpValues.recommendation_date, GLOBAL_FORMAT_DATE).format('YYYYMMDDTHHMMSS'),
    zones: nrxResult[nrxTabRate][selectedObjective].features
      .filter((f: NRxZone) => f.properties.merged !== 'initial')
      .map((f: NRxZone) => {
        return {
          ...f,
          properties: {
            fertilizer_rate: f.properties.value === '-' ? 0 : f.properties.value,
            fertilizer_rate_units: productMeasure,
          },
        };
      }),
  };
  Mixpanel.exportNRx('AgX');
  NrxApi.exportToAgX(farm.id, field.ID, currentSeasonId, preparedData)
    .then(() =>
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'The recommendation was exported to agX.'}),
          level: 'success',
        })
      )
    )
    .catch(() =>
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({id: 'errorTryReloadPage'}),
          level: 'warning',
        })
      )
    );
};
