import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback} from 'react';
import {Button} from 'react-md';
import {useDispatch} from 'react-redux';
import {setFeature} from './map/actions';
import FluroDialog from 'components/fluro-dialog';
import {dialogToggle} from 'modules/ui-helpers';
import InfoBlock from '../components/info-block';

const CloudyTip = () => {
  const dispatch = useDispatch();

  const close = useCallback(() => dispatch(dialogToggle('cloudyTip', false)), []);

  const goToData = useCallback(() => {
    close();
    dispatch(setFeature('data-layers'));
  }, [close]);

  return (
    <FluroDialog
      id="cloudy-tip--dialog"
      visible={true}
      isDraggable
      title="Filtered imagery"
      focusOnMount={false}
      width={400}
      portal
      onHide={close}
    >
      <div className="status-alert-dialog">
        <div className="status-alert-dialog__main">
          <div>
            Your imagery is filtered out either because it is cloudy, or because it was manually
            hidden. To reveal the imagery adjust the cloud and imagery source filters.
          </div>
        </div>

        <InfoBlock appearance="info">
          To better assess if your imagery is cloudy, you can select the RGB layer to access the
          extent of the cloud cover and decide on the analysis.
        </InfoBlock>

        <div className="status-alert-dialog__btns">
          <Button onClick={close} raised>
            Close
          </Button>

          <Button onClick={goToData} raised primary>
            Reveal the imagery
          </Button>
        </div>
      </div>
    </FluroDialog>
  );
};

export default CloudyTip;
