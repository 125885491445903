import {t} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Button} from 'react-md';
import L from 'leaflet';
import {
  BorderedEditIcon,
  CircleIcon,
  EyeHiddenIcon,
  EyeIcon,
  KmlIcon,
  PinIcon,
  PolygonIcon,
  ShapeIcon,
} from '../icons';
import CustomDrawButton from './custom-draw-button';
import {deleteLastPoint, saveEdit} from '../utils/draw';
import {
  toggleFieldGeometries,
  toggleFieldKml,
  toggleDrawingMode,
  toggleEditingMode,
  toggleTableView,
  toggleMapButton,
  saveEditField,
} from '../actions';
import {ButtonOnMap, IInitialMapState} from '../types';
import {GlobalState} from '_reducers/global_types';
import cn from 'classnames';
import ReadOnly from 'components/read-only-container';
import MultiKeysPressed from 'components/key-handler';
import {showNote} from '../../../_actions';

interface IState {
  editableLayers: boolean;
}

type IProps = ConnectedProps<typeof connector> & {
  leafletElement: L.Map;
  isReadOnly: boolean;
  isWholeFarmView: boolean;
  drawStatus: string;
  selectedFieldId: number | string;
};

class DrawingTools extends PureComponent<IProps, IState> {
  buttonName: ButtonOnMap;

  constructor(props: IProps) {
    super(props);
    this.state = {
      editableLayers: false,
    };
    this.buttonName = 'Drawing';
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const editableLayers = this.hasMapEditableLayers();
    if (this.state.editableLayers !== editableLayers) {
      this.setState({editableLayers});
    }
  }

  getDrawTooltips = () => {
    switch (this.props.drawStatus) {
      case 'polygon': {
        return (
          <React.Fragment>
            <div className={'helper-action'} onClick={deleteLastPoint}>
              Delete last point
            </div>
            <div className={'helper-action'} onClick={() => this.drawAction('')}>
              Cancel
            </div>
          </React.Fragment>
        );
      }
      case 'edit': {
        return (
          <React.Fragment>
            <div className={'helper-action'} onClick={this.saveEditedShapes}>
              Save
            </div>
            <div className={'helper-action'} onClick={() => this.drawAction('')}>
              Cancel
            </div>
          </React.Fragment>
        );
      }

      case '':
        return null;

      default:
        return (
          <div className={'helper-action'} onClick={() => this.drawAction('')}>
            Cancel
          </div>
        );
    }
  };

  hasMapEditableLayers = () => {
    let isEditable = false;

    this.props.leafletElement.eachLayer((l: any) => {
      if (!isEditable && (l.fluroId || l.fluroGeometryID || l?.options.fluroId)) {
        isEditable = true;
      }
    });

    return isEditable;
  };

  drawAction = (action: string) => {
    const {
      drawControl: {drawingEntity, drawingModeLayerType},
      globalDialogs: {editPivotTableView},
      toggleTableView,
    } = this.props;
    if (action && action !== 'edit') {
      return this.props.toggleDrawingMode(true, action);
    }

    if (!action && this.props.drawStatus !== 'edit') {
      if (editPivotTableView?.visible && drawingEntity === 'pivot') toggleTableView('farm'); // return back to table view if the pivot pop-up opened
      return this.props.toggleDrawingMode(false, drawingModeLayerType);
    }

    return this.props.toggleEditingMode(Boolean(action));
  };

  saveEditedShapes = () => {
    saveEdit();
    this.props.toggleEditingMode(false);
  };

  cancelEditingDrawing = () => {
    this.props.toggledButtonOnMap === this.buttonName &&
      this.props.toggleMapButton(this.buttonName); // hide the drawing tools pop-up
    this.drawAction('');
  };

  render() {
    const {
      isReadOnly,
      isWholeFarmView,
      currentSensor,
      currentSeasonId,
      selectedFieldId,
      geometriesOnMap,
      currentPointId,
      visibleFieldKml,
      drawStatus,
      toggledButtonOnMap,
      toggleMapButton,
      hasShapes,
      fields,
      drawControl,
    } = this.props;
    const isEnabledControls = currentSensor !== 'NMAP' && !isReadOnly;
    const {editableLayers} = this.state;
    return (
      <div
        className={cn('btn-on-map-pop-up', {
          active: toggledButtonOnMap === this.buttonName,
        })}
      >
        <Button
          id="toggle-drawing-tools"
          title={'Drawing tools'}
          className={`map-toggle-button drawing ${drawStatus ? 'hidden' : ''}`}
          name="toggle-field-info"
          iconEl={<ShapeIcon />}
          floating
          onClick={() => toggleMapButton(this.buttonName)}
          mini
        />

        {drawStatus && !drawControl.fieldId &&  (
          <div className={'drawing-tools-helpers'}>
            {this.getDrawTooltips()}
            <MultiKeysPressed callback={this.cancelEditingDrawing} keys={['Escape']} />
          </div>
        )}

        {drawStatus === 'edit'}

        <div
          className={cn('pop-up', 'drawing-tools', {
            hidden: drawStatus,
            'whole-farm': isWholeFarmView,
            'no-fields': !fields.length,
          })}
        >
          <h5 className={'pop-up__title'}>{t({id: 'Drawing tools'})}</h5>

          {currentPointId !== 'new' &&
          currentSeasonId &&
          selectedFieldId &&
          currentSensor !== 'NMAP' &&
          !isWholeFarmView ? (
            <ReadOnly>
              <CustomDrawButton
                className={'marker'}
                type={'Pin'}
                onClick={() => this.drawAction('marker')}
                iconEl={<PinIcon />}
              />
            </ReadOnly>
          ) : null}
          <ReadOnly>
            <CustomDrawButton
              type={'Polygon'}
              onClick={() => this.drawAction('polygon')}
              iconEl={<PolygonIcon />}
            />
          </ReadOnly>

          <ReadOnly>
            <CustomDrawButton
              type={'Circle'}
              onClick={() => this.drawAction('circle')}
              iconEl={<CircleIcon />}
            />
          </ReadOnly>

          {isEnabledControls && editableLayers && (
            <CustomDrawButton
              type={'Edit'}
              onClick={() => this.drawAction('edit')}
              iconEl={<BorderedEditIcon />}
            />
          )}

          {(!!selectedFieldId && selectedFieldId !== 'WholeFarm') || hasShapes ? (
            <div className={'toggle-geometries-on-map-btn'}>
              {hasShapes ? (
                <CustomDrawButton
                  className={'toggle-shapes'}
                  type={`${geometriesOnMap ? 'Hide' : 'Show'} shapes`}
                  onClick={() => this.props.toggleFieldGeometries(!geometriesOnMap)}
                  iconEl={geometriesOnMap ? <EyeIcon /> : <EyeHiddenIcon />}
                />
              ) : null}

              <CustomDrawButton
                visible={!!selectedFieldId && selectedFieldId !== 'WholeFarm'}
                className={'toggle-shapes'}
                type={`${visibleFieldKml ? 'Hide' : 'Show'} boundary`}
                onClick={() => this.props.toggleFieldKml(!visibleFieldKml)}
                iconEl={<KmlIcon />}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({map, global}: {map: IInitialMapState; global: GlobalState}) => ({
  currentPointId: map.currentPointId,
  currentSeasonId: map.currentSeasonId,
  fields: map.fields,
  feature: map.feature,
  geometriesOnMap: map.geometriesOnMap,
  currentSensor: map.currentSensor,
  visibleFieldKml: map.visibleFieldKml,
  drawControl: map.drawControl,
  globalDialogs: global.dialogsState,
  toggledButtonOnMap: map.toggledButtonOnMap,
  hasShapes:
    map.lowPerfAnomalies.list.length ||
    map.premiumAnomalies.list.length ||
    map.geometry.length ||
    map.currentSeason.geometry,
});

const connector = connect(mapStateToProps, {
  toggleFieldGeometries,
  toggleFieldKml,
  toggleDrawingMode,
  toggleEditingMode,
  toggleTableView,
  toggleMapButton,
  saveEditField,
  showNote,
});
export default connector(DrawingTools);
