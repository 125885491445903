import React, {useState, useEffect, useMemo} from 'react';
import cn from 'classnames';

import {toggleHeader} from '_actions';

import Logo from 'components/logo';
import {FarmToolbar} from 'containers/header/farm-toolbar';
import ExpandableTopbar from 'containers/header/expandable-topbar';
import {MobileFarmAndFieldControls} from './mobile-farm-and-field-controls';

import './header.scss';
import {useAppDispatch, useAppSelector} from '_hooks';
import {CarbonSteps} from 'containers/carbon/carbon-steps';
import {OptisAreaTypeSelect} from 'containers/map/features/optis/optis-area-type-select';
import {isMapFeature} from '../map/utils';

const Header = () => {
  const dispatch = useAppDispatch();
  const viewport = useAppSelector(s => s.viewport);
  const feature = useAppSelector(s => s.map.feature);
  const isHeaderExpanded = useAppSelector(s => s.global.isHeaderExpanded);

  // Overlay means a higher z-index.
  // Usually topbar should be overlayed by all the modal overlays.
  // But in expanded state it should overlay everything: the map, the minimize mapbar button, etc.
  const [overlayEnabled, setOverlayEnabled] = useState(false);

  const toggleMobileHeader = () => {
    if (viewport.width > 560) {
      // Header expansion is enabled only for narrow screens.
      return;
    }
    if (!isHeaderExpanded) {
      // Enable overlay when the header expansion animation started.
      setOverlayEnabled(true);
    }
    dispatch(toggleHeader());
  };

  const disableOverlay = () => {
    if (!isHeaderExpanded) {
      // Disable overlay when header shrinking animation finished.
      setOverlayEnabled(false);
    }
  };

  // Shrink header if viewport width was increased while header was expanded.
  // I.e. portrait to landscape device rotation.
  useEffect(() => {
    if (viewport.width > 560 && isHeaderExpanded) {
      setOverlayEnabled(false);
      dispatch(toggleHeader());
    }
  }, [viewport.width, isHeaderExpanded]);

  const topLeftElement = useMemo(() => {
    switch (feature) {
      case 'carbon':
        return isMapFeature(feature) ? <CarbonSteps /> : null;
      case 'optis':
        return <OptisAreaTypeSelect />;
      default:
        return viewport.width < 561 ? (
          <ExpandableTopbar expandToolbar={toggleMobileHeader} />
        ) : (
          <FarmToolbar />
        );
    }
  }, [feature, viewport.width]);

  return (
    <>
      <Logo />
      {/* Logo is outside the header so it's not animated when the header is expanding */}
      <header
        className={cn({
          'header-container': true,
          'md-toolbar--text-white': true,
          expanded: isHeaderExpanded,
          overlay: overlayEnabled,
        })}
        onTransitionEnd={disableOverlay}
      >
        {/* Click target just catches the click events to close the header. */}
        <div className="topbar__click-target" onPointerDown={toggleMobileHeader} />
        <div className="topbar__default-content">{topLeftElement}</div>
        {isHeaderExpanded && <MobileFarmAndFieldControls />}
      </header>
    </>
  );
};

export default Header;
