import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import ReactDOM from 'react-dom';
import {FieldTools, LastStep, FluroStep} from './common-steps';
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from 'react-joyride';
import {baseStepStyle} from './style';
import {setOnboardingSeen} from '../login/actions';
import {toggleGlobalDialog} from '_actions';
import {prepareToOnBoarding, setFeature, toggleMapBar} from '../map/actions';
import {LoginState} from '../login/types';
import {IInitialMapState} from '../map/types';

type Props = ConnectedProps<typeof connector>;
type State = {
  steps: FluroStep[];
  run: boolean | null;
  stepIndex: number;
};

class QuickTour extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      steps: [
        {
          title: 'Your farms and fields',
          content: (
            <div>
              <div className={'step-paragraph'}>
                <b>Navigate between</b> your farms and fields by selecting from, or typing directly
                into, these controls.
              </div>
              <div className={'step-paragraph'}>
                <b>A farm</b> is made up of one or more fields, and <b>a field</b> is defined as a
                boundary with a single crop per season growing in it.
              </div>
              <div>
                You can <b>set up your farms</b>, fields and crops using the farm set-up menu on the
                right.
              </div>
            </div>
          ),
          target: '.onboarding__select-farm-field',
          placement: 'bottom',
          disableBeacon: true,
          name: 'select-field-farm',
        },
        {
          title: 'Observation dates and crop health layers',
          content: `We automatically acquire new satellite imagery for your farms every 3-5 days. Each date, we generate vegetation indices, giving you a different insight into the health of your field.`,
          target: '.onboarding__selector-dates-layers',
          placement: 'bottom',
          disableBeacon: true,
          name: 'select-date-layer',
        },
        FieldTools, // Field tools
        {
          title: 'Main menu with Crop Analysis apps',
          content: (
            <div>
              Here you set up your fields/crops, select layers for analysis, and access the{' '}
              <b>Crop Analytics Apps</b> incl.
              <ul>
                <li>
                  <b>Crop Performance</b>
                </li>
                <li>
                  <b>Crop Stress</b>
                </li>
                <li>
                  <b>Crop Nutrition</b> and more.
                </li>
              </ul>
            </div>
          ),
          target: '.onboarding__tabs-nav',
          placement: 'left',
          disableBeacon: true,
          name: 'tabs-nav',
        },
        {
          title: 'Expanded view',
          content:
            'Click here to expand the right panel to a full table view, showing more information related to the feature you are looking at.',
          target: '.onboarding__toggle-expanded-view',
          placement: 'left',
          disableBeacon: true,
          name: 'expanded-view',
        },
        LastStep({
          closeOnboarding: () =>
            this.props.setOnboardingSeen({isOnboarded: true, quickTour: false}),
        }),
      ],
      run: this.props.showOnBoarding,
      stepIndex: 0,
    };
  }

  componentDidUpdate() {
    if (this.props.showOnBoarding && !this.state.run && this.state.run !== null) {
      this.props.prepareToOnBoarding();
      this.props.setFeature('farm');
      this.setState({run: true});
    }
  }

  prepareTabToTour = (index: number) => {
    this.setState({run: null});
    setTimeout(
      () =>
        this.setState({
          run: true,
          stepIndex: index,
        }),
      500
    );
  };

  closeOnBoarding = (rememberMessage = false) => {
    this.setState({run: null});
    return this.props.setOnboardingSeen({isOnboarded: true, fullTour: false}, rememberMessage);
  };

  openDemoFarmsDialog = () => {
    this.props.toggleGlobalDialog('demoFarms', {visible: true});
  };

  callback = async ({action, type, index, status}: CallBackProps) => {
    const calculatedIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    const nextStepToShow = this.state.steps[calculatedIndex];

    if (action === ACTIONS.CLOSE || status === STATUS.SKIPPED) {
      return this.closeOnBoarding(true);
    }

    if (status === STATUS.FINISHED) {
      return this.closeOnBoarding().then(() => this.openDemoFarmsDialog());
    } else {
      if (type === EVENTS.STEP_AFTER && nextStepToShow?.name === 'expanded-view') {
        !this.props.isMapBarOpen && this.props.toggleMapBar(true);
        this.setState({stepIndex: calculatedIndex});
      }

      if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        this.prepareTabToTour(calculatedIndex);
      }
    }
  };

  render() {
    const {stepIndex, run, steps} = this.state;

    if (!this.props.showOnBoarding) return null;

    return ReactDOM.createPortal(
      <Joyride
        steps={steps}
        stepIndex={stepIndex}
        run={run}
        continuous
        callback={this.callback}
        styles={baseStepStyle}
        disableOverlayClose
        locale={{back: 'Previous', last: 'See demo farms'}}
      />,
      document.querySelector('body')
    );
  }
}

const mapStateToProps = ({login, map}: {login: LoginState; map: IInitialMapState}) => ({
  userSettings: login.user.settings,
  showOnBoarding: login.user.settings.onboarding.quickTour,
  isMapBarOpen: map.isMapBarOpen,
});
const connector = connect(mapStateToProps, {
  setFeature,
  prepareToOnBoarding,
  setOnboardingSeen,
  toggleMapBar,
  toggleGlobalDialog,
});
export default connector(QuickTour);
