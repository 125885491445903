import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import {formatAvgNDVI, NoValueReason} from './';
import {Biomass, ReasonCode} from '../types';
import {PlaneIcon, SatelliteIcon} from 'containers/map/icons';

export const NdviView = ({
  iconType,
  ndvi,
  status,
  date,
  reason,
  isTableView = false,
}: {
  iconType?: 'satellite' | 'plane';
  ndvi: number;
  status?: Biomass;
  date?: string;
  reason?: ReasonCode;
  isTableView?: boolean;
}) => {
  if (ndvi == null) {
    return <NoValueReason reasonCode={reason || ReasonCode.Unknown} />;
  }
  return (
    <div className="cp__ndvi-container">
      {iconType === 'plane' ? <PlaneIcon /> : iconType === 'satellite' ? <SatelliteIcon /> : null}
      <div
        className={cn('crop-perf-cell', 'cp__ndvi', {
          _high: status === 'high',
          _medium: status === 'medium',
          _low: status === 'low',
        })}
      >
        {formatAvgNDVI(ndvi)}
        {isTableView && date && (
          <span className="crop-perf-sensor-date">({moment.utc(date).format('DD MMM')})</span>
        )}
      </div>
    </div>
  );
};
