import {parseGeometryFile} from 'containers/map/utils/file';
import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import {setFilter, setAreaType} from './optis-reducer';
import {OptisType} from './optis-types';
import {AppStore} from 'reducers';
import {TillageCard} from './tillage-card';
import {WinterCropCard} from './winter-crop-card';
import {OptisApi} from '_api';
import './optis.scss';
import {parseOptisCsv} from './optis-utils';
import {downloadFile} from '_utils';
import Papa from 'papaparse';
import {RequestStatus} from 'types';
import {SliderYear} from './slider-year';
import {SliderDiffYear} from './slider-diff-year';

export default () => {
  const dispatch = useDispatch();
  const optis = useSelector((s: AppStore) => s.optis);
  useEffect(() => {
    // Fetch the data by setting the area type.
    dispatch(setAreaType(optis.areaType, optis.parentId));
  }, []);

  return (
    <div className="optis">
      {optis.diffMode ? <SliderDiffYear /> : <SliderYear />}
      <h3 className="tab-title">
        Sustainability insights
        {optis.status === RequestStatus.Loading && (
          <span className="preloader">
            <CircularProgress id={`optis-panel-loader`} />
          </span>
        )}
      </h3>
      {/* <FileInput /> */}
      <TillageCard
        optis={optis}
        onFilterClick={f =>
          dispatch(
            setFilter({
              type: OptisType.Tillage,
              ...f,
            })
          )
        }
      />
      <WinterCropCard
        optis={optis}
        onFilterClick={f =>
          dispatch(
            setFilter({
              type: OptisType.WinterCrop,
              ...f,
            })
          )
        }
      />
    </div>
  );
};

const convertToFeature = (feature: any): GeoJSON.Feature => {
  return feature;
};

// const FileInput = () => {
//   return (
//     <>
//       <input
//         type="file"
//         id="file-selector"
//         onChange={async e => {
//           if (e.target.files.length) {
//             const file = e.target.files[0];
//             const fileType = file.name.endsWith('.csv') ? 'csv' : 'geometries';
//             switch (fileType) {
//               case 'csv':
//                 Papa.parse(file, {
//                   skipEmptyLines: true,
//                   // header: true,
//                   complete: ({data}: {data: any}) => {
//                     // Take only the records in range 2015–2019.
//                     // And the headers row.
//                     const a = data.filter(
//                       (cell: any, i: number) => i === 0 || Number(cell[3]) > 2015
//                     );
//                     const fileContent = JSON.stringify(a);
//                     downloadFile(fileContent, `data.json`, 'text/plain');
//                   },
//                 });
//                 break;
//               case 'geometries':
//                 parseGeometryFile(file)
//                   .then(r => {
//                     if (r.errors.length) {
//                       console.log('### r.errors', r.errors);
//                     }
//                     if (r.features) {
//                       const features: GeoJSON.Feature[] = [];
//                       const featureCollections =
//                         r.features instanceof Array ? r.features : [r.features];
//                       featureCollections.forEach(c => {
//                         console.log('DO SOMETHING WITH GEOMS, THEY ARE PARSED, ALL GOOD');
//                         features.push(...c.features);
//                         // setGeometries(JSON.stringify(c.features));
//                       });
//                       downloadFile(JSON.stringify(features), `geometries.json`, 'text/plain');
//                     }
//                   })
//                   .catch(console.log);
//                 break;
//             }
//           }
//         }}
//       ></input>
//     </>
//   );
// };
