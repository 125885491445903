import tj from '@mapbox/togeojson';
import moment from 'moment';
import {parseNumber} from '_utils';
import {GLOBAL_APP_DATE_FORMAT} from '../_constants';

// Allowed mime types
const allowedTSFielsTypes = {
  'text/csv': 'exel',
  'application/vnd.google-earth.kml+xml': 'kml',
  'application/vnd.ms-excel': 'exel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'exel',
};

export const tsProcessor = (files = []) => {
  let filesArr = [];

  files.forEach(f => {
    const processor = new FileProcessor(f);
    filesArr.push(processor.parse());
  });

  return Promise.all(filesArr);
};

class FileProcessor {
  constructor(file) {
    this.fileType = file.type;
    this.file = file;
    this.strData = '';
    this.rABS = null;
    this.processorType = this.getFileProcessorByType() || 'exel';
  }

  getFileProcessorByType() {
    return allowedTSFielsTypes[this.fileType];
  }

  parse() {
    return new Promise(resolve => {
      // if (!this.processorType) {
      //   resolve(this.payloadErr(`${this.file.name} was skipped. Do not allowed file mime type ${this.fileType}.`));
      //   return;
      // }

      const reader = new FileReader();
      this.rABS = !!reader.readAsBinaryString;

      reader.onload = e => {
        this.strData = e.target.result;

        if (!this.fileType) {
          // try defined KML without mime type
          try {
            const parser = new DOMParser();
            parser.parseFromString(this.strData, 'text/xml');

            this.processorType = 'kml';
          } catch (e) {
            console.warn(e.message);

            this.processorType = 'exel';
          }
        }

        resolve(this[this.processorType]());
      };

      if (this.rABS) reader.readAsBinaryString(this.file);
      else reader.readAsArrayBuffer(this.file);
    });
  }

  findHeader(data) {
    return data.findIndex(el => {
      const header = el.join('');
      return /lat|latitude/i.test(header) && /lng|lon|long|longitude/i.test(header);
    });
  }

  makeFieldName = () => `Untitled TSP`;

  exel() {
    const wb = window.XLSX.read(this.strData, {
      type: this.rABS ? 'binary' : 'array',
      cellDates: true,
      cellNF: false,
      cellText: false,
    });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    console.log(ws);

    // normalize date format
    Object.keys(ws || {}).forEach(k => {
      if (ws[k].t && ws[k].t === 'd') {
        delete ws[k].w;
        // ws[k].w = ws[k].v;
        ws[k].z = 'dd/mm/yyyy';
      }
    });

    const data = window.XLSX.utils.sheet_to_json(ws, {header: 1});

    const headerIndex = this.findHeader(data);

    if (headerIndex !== -1) {
      const preparedData = data.slice(headerIndex);
      const header = preparedData.shift();

      const fieldIndex = header.findIndex(el => {
        const col = (el + '').trim();
        return /^sample(\s|_|-)?(id|number|num)/i.test(col);
      });

      const latIndex = header.findIndex(el => {
        const col = (el + '').trim();
        return /^lat|latitude$/i.test(col);
      });

      const lngIndex = header.findIndex(el => {
        const col = (el + '').trim();
        return /^lng|lon|long|longitude$/i.test(col);
      });

      const dateIndex = header.findIndex(el => {
        const col = (el + '').trim();
        return /date|Date/i.test(col);
      });

      const nitrogenIndex = header.findIndex(el => {
        const col = (el + '').trim();
        return (
          /total\sn/i.test(col) ||
          /^n$/i.test(col) ||
          /^tot\.\sn$/i.test(col) ||
          /^tot\sn$/i.test(col) ||
          /^nitro$/i.test(col) ||
          /^nitrogen$/i.test(col)
        );
      });

      // filter rows without lat or lng values
      const preparedDataFilteretLatLng = preparedData.filter(col => col[latIndex] && col[lngIndex]);

      if (!preparedDataFilteretLatLng.length) {
        return this.payloadErr(`Not found lat/lng values in ${this.file.name}`);
      }
      return this.payload(
        preparedDataFilteretLatLng.map(col => {
          return {
            lat: parseNumber(col[latIndex]),
            lng: parseNumber(col[lngIndex]),
            sample_id: col[fieldIndex] || this.makeFieldName(),
            sample_date: getValidDate(col[dateIndex]),
            n_result: parseNumber(col[nitrogenIndex]) || '',
            n_result2:
              parseNumber(
                col[header.findIndex(el => /nitrate(\s|-)\(?ppm\)?/i.test((el + '').trim()))]
              ) || '',
            total_K: parseNumber(
              col[header.findIndex(el => /^total(\s|-)k(\s|-)\(%\)/i.test((el + '').trim()))] || ''
            ),
            total_P: parseNumber(
              col[header.findIndex(el => /^total(\s|-)p(\s|-)\(%\)/i.test((el + '').trim()))] || ''
            ),
            Ca: parseNumber(
              col[header.findIndex(el => /^ca(\s|-)\(%\)/i.test((el + '').trim()))] || ''
            ),
            Mg: parseNumber(
              col[header.findIndex(el => /^mg(\s|-)\(%\)/i.test((el + '').trim()))] || ''
            ),
            Na: parseNumber(
              col[header.findIndex(el => /^na(\s|-)\(%\)/i.test((el + '').trim()))] || ''
            ),
            S: parseNumber(
              col[header.findIndex(el => /^s(\s|-)\(%\)/i.test((el + '').trim()))] || ''
            ),
            Zn_ppm: parseNumber(
              col[header.findIndex(el => /^zn(\s|-)\(?ppm\)?/i.test((el + '').trim()))] || ''
            ),
            Mn_ppm: parseNumber(
              col[header.findIndex(el => /^mn(\s|-)\(?ppm\)?/i.test((el + '').trim()))] || ''
            ),
            Fe_ppm: parseNumber(
              col[header.findIndex(el => /^fe(\s|-)\(?ppm\)?/i.test((el + '').trim()))] || ''
            ),
            Cu_ppm: parseNumber(
              col[header.findIndex(el => /^cu(\s|-)\(?ppm\)?/i.test((el + '').trim()))] || ''
            ),
            B_ppm: parseNumber(
              col[header.findIndex(el => /^b(\s|-)\(?ppm\)?/i.test((el + '').trim()))] || ''
            ),
            Cl: parseNumber(
              col[header.findIndex(el => /^cl(\s|-)\(%\)/i.test((el + '').trim()))] || ''
            ),
            Mo_ppm: parseNumber(
              col[header.findIndex(el => /^mo(\s|-)\(?ppm\)?/i.test((el + '').trim()))] || ''
            ),
          };
        })
      );
    }

    return this.payloadErr(`Invalid structure of ${this.file.name}`);
  }

  kml() {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.strData, 'text/xml');

      const geoJSON = tj.kml(xmlDoc);

      const points = geoJSON.features.filter(feature => feature.geometry.type === 'Point');

      if (!points.length) {
        return this.payloadErr(`Not found Points in ${this.file.name}`);
      }

      return this.payload(
        points.map(point => {
          const {date, GroupDate} = point.properties;
          return {
            lat: parseNumber(point.geometry.coordinates[1]),
            lng: parseNumber(point.geometry.coordinates[0]),
            sample_id:
              point.properties.field ||
              point.properties.name ||
              point.properties.Sample_ID ||
              this.makeFieldName(),
            sample_date: getValidDate(date || GroupDate),
            n_result: '',
            n_result2: '',
            total_K: '',
            total_P: '',
            Ca: '',
            Mg: '',
            Na: '',
            S: '',
            Zn_ppm: '',
            Mn_ppm: '',
            Fe_ppm: '',
            Cu_ppm: '',
            B_ppm: '',
            Cl: '',
            Mo_ppm: '',
          };
        })
      );
    } catch (e) {
      return this.payloadErr(e.message);
    }
  }

  payload(data) {
    return {status: 'ok', data, type: this.fileType};
  }

  payloadErr(message) {
    return {status: 'error', message};
  }
}

const getValidDate = (date = '') => {
  return moment(date).isValid() // automatic detection
    ? moment(date).format()
    : moment(date, GLOBAL_APP_DATE_FORMAT).isValid() // try app format
    ? moment(date, GLOBAL_APP_DATE_FORMAT).format()
    : moment().format(); // get the current date
};
