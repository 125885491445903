import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sensorModel, sensorView} from '_utils';
import {getLayersItems, createDatesMenuList} from '../../utils';
import {SelectField} from 'react-md';

import {setCompareDate, setSensorCompare, setSensor, setDate} from '../../actions';
import {TSensor} from '../../../../types';

import {toggleLowPerformingAreas} from '../../actions/anomalies-actions';
import {AppStore} from 'reducers';

const MapCompare = () => {
  const dispatch = useDispatch();
  const visibleLowPerfAnomalies = useSelector(
    (store: AppStore) => store.map.lowPerfAnomalies.isVisible
  );
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const currentDates = useSelector((store: AppStore) => store.map.currentDates);
  const currentSensor = useSelector((store: AppStore) => store.map.currentSensor);
  const isWholeFarmView = useSelector((store: AppStore) => store.map.wholeFarm.isWholeFarmView);
  const currentCompareDate = useSelector((store: AppStore) => store.map.currentCompareDate);
  const currentSensorCompare = useSelector((store: AppStore) => store.map.currentSensorCompare);
  const currentSensorsCompare = useSelector((store: AppStore) => store.map.currentSensorsCompare);

  useEffect(() => {
    if (visibleLowPerfAnomalies) {
      dispatch(toggleLowPerformingAreas(false));
    }
  }, []);

  const selectLeftSideSensor = (val: TSensor) => {
    dispatch(setSensor(sensorModel(val)));
  };

  const selectLeftSideDate = (date: string) => {
    dispatch(setDate(date));
  };

  const selectRightSideSensor = (val: TSensor) => {
    const sensor = sensorModel(val);
    dispatch(setSensorCompare(sensor));
  };

  const selectRightSideDate = (date: string) => {
    dispatch(setCompareDate(date));
  };

  const clearedSensorsToCompare = isWholeFarmView
    ? currentSensorsCompare.filter(
        (sensor: string) => currentDates[currentCompareDate]?.[sensor]?.type
      )
    : currentSensorsCompare;

  const modyfiedSensorsToCompare = clearedSensorsToCompare.map(sensorView);
  const layers = getLayersItems();
  const dates = createDatesMenuList(currentDates, true, isWholeFarmView);

  return (
    <div className={'compare-container'}>
      <div className={'select-date-layer'}>
        <SelectField
          id="select-field-date"
          placeholder={t({id: 'Select Date'})}
          label={t({id: 'Date'})}
          className={'date'}
          menuItems={dates}
          onChange={selectLeftSideDate}
          value={currentDate}
          disabled={!dates.length}
          simplifiedMenu={true}
        />

        <SelectField
          id="select-field-index"
          placeholder={t({id: 'Select layer'})}
          className="layer"
          label={t({id: 'Layer'})}
          menuItems={layers}
          disabled={!layers.length || layers.length === 1}
          value={sensorView(currentSensor)}
          onChange={selectLeftSideSensor}
          simplifiedMenu={false}
          anchor={{
            x: SelectField.HorizontalAnchors.INNER_RIGHT,
            y: SelectField.VerticalAnchors.OVERLAP,
          }}
        />
      </div>

      <div className={'select-date-layer'}>
        <b className={'element-full-width'}>{t({id: 'Compare to'})}</b>
        <SelectField
          id="select-field-date-compare"
          placeholder={t({id: 'Select Compare Date'})}
          label={t({id: 'Date'})}
          className={'date'}
          menuItems={dates}
          onChange={selectRightSideDate}
          value={currentCompareDate}
          disabled={!dates.length}
          simplifiedMenu={true}
        />

        <SelectField
          id="select-field-index-compare"
          placeholder={t({id: 'Select Compare Layer'})}
          className="layer"
          label={t({id: 'Layer'})}
          menuItems={[{label: t({id: 'NONE'}), value: 'NONE'}, ...modyfiedSensorsToCompare]}
          disabled={!modyfiedSensorsToCompare.length}
          value={sensorView(currentSensorCompare)}
          onChange={selectRightSideSensor}
          simplifiedMenu={false}
          anchor={{
            x: SelectField.HorizontalAnchors.INNER_RIGHT,
            y: SelectField.VerticalAnchors.OVERLAP,
          }}
        />
      </div>
    </div>
  );
};

export default MapCompare;
