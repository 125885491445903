import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {main_info_color} from '_utils/colors';

type Props = {
  selectedCount?: string;
  className?: string;
  list: {
    label: string;
    value: string;
    percentage?: number;
    count?: string | JSX.Element;
    backgroundColor: string;
    deselected?: boolean;
  }[];
  diffMode?: boolean; // whether percentage should be signed (-/+) and colored (red/green)
  onClick?(val: string, e: React.MouseEvent): void;
};

export const LegendList = ({list, onClick, selectedCount, diffMode = false}: Props) => {
  return (
    <>
      {selectedCount && <SelectedCount>{selectedCount}</SelectedCount>}

      <LegendListStyled>
        {list.map(l => {
          const {deselected, backgroundColor, label, percentage, count} = l;
          const percentageSign = diffMode && percentage > 0 ? '+' : '';
          const percentageColor = diffMode ? (percentage < 0 ? 'red' : 'green') : 'inherit';
          const threeColumns = percentage != null && count != null;
          return (
            <LegendListItem
              key={l.label}
              onClick={e => onClick?.(l.value, e)}
              className={cn({
                'legend-list-item': true,
                'three-columns': threeColumns,
                deselected,
              })}
            >
              <div>
                <ColoredBullet
                  className="item-color"
                  style={{
                    backgroundColor,
                    border: backgroundColor === '#ffffff' ? '1px solid black' : undefined,
                  }}
                />
                <ItemName>
                  <FormattedMessage id={label} defaultMessage={label} />
                </ItemName>
              </div>
              {percentage != null && (
                <ItemPercentage style={{color: percentageColor}}>
                  {percentageSign}
                  {percentage || '<1'}%
                </ItemPercentage>
              )}
              {count != null && <ItemCount>{count}</ItemCount>}
            </LegendListItem>
          );
        })}
      </LegendListStyled>
    </>
  );
};

export const SelectedCount = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  color: #349e21;
`;

export const LegendListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const LegendListItem = styled.li`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &.three-columns {
    display: grid;
    grid-template-columns: 60% 10% 30%;
  }

  &.deselected {
    color: #a6acbe;
  }
`;

export const ItemName = styled.span`
  font-weight: 500;
  color: ${main_info_color};
  transition: color 0.2s;

  .deselected & {
    opacity: 0.3;
  }

  .legend-list-item:hover & {
    color: #29353a;
  }
`;

export const ItemPercentage = styled.span`
  text-align: right;
`;

export const ItemCount = styled.span`
  color: #a6acbe;
  margin-left: 4px;
  text-align: right;

  .deselected & {
    color: #a6acbe;
    font-weight: 400;
  }
`;

export const ColoredBullet = styled.span`
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 4px;
`;
