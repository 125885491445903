import React from 'react';
import cn from 'classnames';
import './index.scss';
import {t} from '../../i18n-utils';
import {FontIcon} from 'react-md';

export enum FluroTabsSkin {
  Default = 'defaultSkin',
  Toggle = 'toggleSkin',
  Chips = 'chipsSkin',
}

export type FluroTabsProps = {
  skin?: FluroTabsSkin;
  containerClassName?: string;
  tabClassName?: string;
  onTabClick(value: string): void;
  selectedTab: string;
  sticky?: boolean;
  stopPropagation?: boolean;
  tabs: {
    label: any;
    value: string;
  }[];
};

export const FluroTabs = ({
  skin = FluroTabsSkin.Default,
  tabs,
  selectedTab,
  containerClassName = '',
  tabClassName = '',
  onTabClick,
  sticky,
  stopPropagation = false,
}: FluroTabsProps) => {
  return (
    <div className={cn('fluro-tabs-container', containerClassName, skin, {sticky})}>
      {tabs.map(tab => {
        return (
          <div
            key={tab.value}
            onClick={e => {
              if (stopPropagation) {
                e.stopPropagation();
              }
              onTabClick(tab.value);
            }}
            className={cn(`tab ${tabClassName}`, {active: selectedTab === tab.value})}
          >
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};

type HelpButtonProps = {
  label: any;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: string;
};

export const TabWithSubButton = ({label, onClick, icon = 'help_outline'}: HelpButtonProps) => (
  <div className="fluro-tabs-help-btn">
    {label} <FontIcon onClick={onClick}>{icon}</FontIcon>
  </div>
);

export default FluroTabs;
