import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {GeoJSON} from 'react-leaflet';
import {Feature} from 'geojson';
import L from 'leaflet';
import {
  updateCLUFieldBoundariesProp,
  addFieldChangeCurrentStep,
  checkPointForFieldBoundaries,
  AddingFieldsZoomThreshold,
  setUploadFieldProp,
  setPropNewFieldGeometry,
} from 'modules/add-fields.module';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {FieldSystemProp} from '../features/farm/new-fields/types';
import {reportError} from '../../error-boundary';
import {MAIN_SHAPE_COLOR} from "../../../_constants";

type Props = {
  fitBounds: (bounds?: L.LatLngBounds, forceRightMargin?: boolean) => void;
};

const AddingNewFieldsOverlay = ({fitBounds}: Props) => {
  const dispatch = useDispatch();
  const cluFieldBoundaries = useSelector((s: AppStore) => s.addFields.cluFieldBoundaries);
  const drewFieldsGeometries = useSelector((s: AppStore) => s.addFields.drewFieldsGeometries);
  const geoJsonFiles = useSelector((s: AppStore) => s.addFields.geoJsonFiles);
  const currentStep = useSelector((s: AppStore) => s.addFields.addFieldCurrentStep);
  const [geometryKey, setGeometryKey] = useState(1);
  const geoJsonRef = useRef(null);

  const fieldsToProcess = useMemo(() => {
    setGeometryKey(geometryKey + 1);

    switch (currentStep) {
      case 'view-fields-from-files':
        return geoJsonFiles;

      case 'view-drew-fields':
      case 'draw-fields': // we should see drew geometries during adding new step
        return drewFieldsGeometries;

      case 'view-selected-boundaries':
        return cluFieldBoundaries.filter(f => f.properties.selectedForProcessing);

      default:
        return [];
    }
  }, [currentStep, geoJsonFiles, drewFieldsGeometries, cluFieldBoundaries]);

  useEffect(() => {
    if (currentStep === 'view-fields-from-files') {
      onFitBounds(); // center the map when open uploaded fields preview
    }
  }, [currentStep]);

  useEffect(() => {
    if (!fieldsToProcess.length) return; // avoid centering for no geometries case
    if (fieldsToProcess.length === 1 && currentStep === 'draw-fields') return; // do not zoom to the first drew fields (it is already in the view)

    onFitBounds(); // reflect on fields number changing
  }, [fieldsToProcess.length]);

  const onFitBounds = () => {
    if (geoJsonRef.current) {
      try {
        fitBounds(geoJsonRef.current.getBounds(), true);
      } catch (err) {
        reportError(`tried fitBounds in addning-new-fields-geometries.tsx ${err}`);
      }
    }
  };

  if (
    ![
      'view-fields-from-files',
      'view-drew-fields',
      'view-selected-boundaries',
      'draw-fields',
    ].includes(currentStep)
  )
    return null;

  const onSelectField = (id: number, value: boolean) => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(setUploadFieldProp(id, FieldSystemProp.Checked, value));
        break;
      case 'view-drew-fields':
        dispatch(setPropNewFieldGeometry(id, FieldSystemProp.Checked, value));
        break;
      case 'view-selected-boundaries':
        dispatch(updateCLUFieldBoundariesProp(id, FieldSystemProp.Checked, value));
    }
  };

  const onEachFeature = (feature: Feature, layer: L.Path) => {
    const selected = feature.properties[FieldSystemProp.Checked];
    layer.setStyle({
      color: '#fff',
      fillColor: selected ? MAIN_SHAPE_COLOR : 'transparent',
      className: `field-boundary-shape ${selected ? 'selected' : ''}`, // add the class for cypress needs
    });

    layer.on({
      click: e => {
        const properties =
          e?.target?.feature?.geometry?.properties || e?.target?.feature?.properties;
        if (properties?.id) {
          onSelectField(properties?.id, !properties?.[FieldSystemProp.Checked]);
        }
      },
      mouseover: e => {
        // highlight on hover
        const layer = e?.target;
        layer?.setStyle?.({fillColor: MAIN_SHAPE_COLOR});
      },
      mouseout: e => {
        // remove highlighting on mouse out
        const layer = e?.target;
        const selected =
          layer?.feature?.geometry?.properties?.[FieldSystemProp.Checked] || // CLU
          layer?.feature?.properties?.[FieldSystemProp.Checked]; // other
        layer?.setStyle?.({fillColor: selected ? MAIN_SHAPE_COLOR : 'transparent'});
      },
    });
  };

  return (
    <GeoJSON
      ref={geoJsonRef}
      key={geometryKey}
      fillOpacity={1}
      weight={2}
      //@ts-ignore
      data={fieldsToProcess}
      onEachFeature={onEachFeature}
    />
  );
};

export default AddingNewFieldsOverlay;
