import Server from './server';

export default class {
  static getAll() {
    return Server.get('admin/users', {params: {__skipPreloader: true}});
  }

  static getOne(userId: number) {
    return Server.get(`admin/users/${userId}`, {params: {__skipPreloader: true}});
  }

  static newUser(user: any) {
    return Server.post(`admin/users/${user.id}`, user);
  }

  static saveUser(user: any) {
    return Server.put(`admin/users/${user.id}`, user);
  }

  static rm(id: number) {
    return Server.delete(`admin/users/${id}`);
  }
}
