import * as React from 'react';
import {Pie, PieCustomLayerProps} from '@nivo/pie';

export const ClusterMarker = ({
  dataAndColors,
}: {
  dataAndColors: {
    data: ClusterChartData[];
    getColor: (slice: any) => string;
  };
}) => {
  if (!dataAndColors.data.length) {
    return null;
  }

  return (
    <div className="crop-type-chart-plot">
      <Pie
        data={dataAndColors.data}
        colors={dataAndColors.getColor}
        borderWidth={2}
        borderColor={'#fff'}
        width={60}
        height={60}
        innerRadius={0.5}
        layers={['slices', CenteredMetric]}
      />
    </div>
  );
};

export type ClusterChartData = {
  id: string | number;
  label: string;
  value: number;
};

const CenteredMetric = ({dataWithArc, centerX, centerY}: PieCustomLayerProps<ClusterChartData>) => {
  const total = dataWithArc.reduce((acc, datum) => acc + datum.value, 0);
  return (
    <text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="central">
      {total}
    </text>
  );
};
