import * as React from 'react';
import {useAppSelector} from '_hooks';
import {unreachableError} from '_utils';
import {CarbonStep} from './carbon-reducer';
import {CarbonPanelSelectFields} from './panel/carbon-panel-select-fields';
import './carbon-panel.scss';
import {CarbonPanelCropPractices} from './panel/carbon-panel-crop-practices';
import {CarbonPanelGetCredits} from './panel/carbon-panel-get-credits';
import {Map} from 'leaflet';

export const CarbonPanel = ({leafletElement}: {leafletElement: Map}) => {
  const carbon = useAppSelector(s => s.carbon);

  const panel = React.useMemo(() => {
    switch (carbon.step) {
      case CarbonStep.Fields:
        return <CarbonPanelSelectFields leafletElement={leafletElement} />;
      case CarbonStep.CropPractices:
        return <CarbonPanelCropPractices />;
      case CarbonStep.Credits:
        return <CarbonPanelGetCredits />;
      default:
        unreachableError(carbon.step, `Carbon panel for step ${carbon.step} is not implemented`);
    }
  }, [carbon.step, leafletElement]);

  return <div className="section-container">{panel}</div>;
};
