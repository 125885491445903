import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import {AppStore} from 'reducers';
import {showNote} from '_actions';
import {clearOriginalLayerDateFormURL} from 'containers/map/actions';
import {useDispatch, useSelector} from 'react-redux';

const NotificationEffects = (): null => {
  const [isRunOnce, setRunOnce] = useState(false);
  const dispatch = useDispatch();
  const originalLayerDateFormURL = useSelector(
    (state: AppStore) => state.map.originalLayerDateFormURL
  );
  const currentDate = useSelector((state: AppStore) => state.map.currentDate);
  const currentDates = useSelector((state: AppStore) => state.map.currentDates);
  const currentSensor = useSelector((state: AppStore) => state.map.currentSensor);
  const currentSeasonId = useSelector((state: AppStore) => state.map.currentSeasonId);

  useEffect(() => {
    if (!isRunOnce && currentDate) {
      if (originalLayerDateFormURL && originalLayerDateFormURL !== currentDate) {
        dispatch(
          showNote({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({
              id: 'notfoundURLDate',
              defaultMessage:
                'We could not find the date you entered in the URL, you were redirected to another date.',
            }),
            level: 'info',
          })
        );

        dispatch(clearOriginalLayerDateFormURL());
      }

      setRunOnce(true);
    }
  }, [currentDate, originalLayerDateFormURL]);

  useEffect(() => {
    // notify user about low not valid CCCI data, because of low NDVI for a date
    if (
      currentSensor === 'CCCI' &&
      currentDates[currentDate]?.avgNdvi &&
      currentDates[currentDate]?.avgNdvi <= 0.2
    ) {
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: `CCCI is not valid at this stage of crop development.`,
          level: 'warning',
        })
      );
    }
  }, [currentDate, currentSensor, currentSeasonId]);

  return null;
};

export default NotificationEffects;
