import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {withFormik, FormikProps} from 'formik';
import Yup from 'yup';
import {showNote} from '_actions';
import {GLOBAL_FORMAT_DATE} from '_constants';

import {fApplicationAdd, fApplicationUpdate, fApplicationRemove} from 'containers/map/actions';

import ApplicationItem from './fertilizer-app-item';
import {Button} from 'react-md';
import FluroDialog from 'components/fluro-dialog';
import moment from 'moment';
import {Season} from 'containers/map/types';
import {
  FertilizerApplication,
  NRxSeason,
  getNrxFertilizerListItemData,
  NrxListsData,
} from 'containers/map/utils/nrx-utils';

type Props = ConnectedProps<typeof connected> & {
  selectedSeasons: Array<Season>;
  particularSeason: NRxSeason;
  nrxListsData: NrxListsData;
  applicationDate?: string;
  changedApplications?: any;
  fertilizerApplications: Array<FertilizerApplication>;
  measurement: string;

  togglePopUp(pop: string, val: boolean, seasonId?: number): void;
};

type TValuesProps = {
  quantity: number;
  typeID: number;
  nitrogenPercentage: number;
  date: string;
  changedApplications: FertilizerApplication[];
};

class FertilizerPopUp extends PureComponent<Props & FormikProps<TValuesProps>> {
  onChange = (prop: keyof FertilizerApplication, value: any) => {
    this.props.setFieldValue(prop, value);
    if (prop === 'typeID' && this.props.values.typeID !== value) {
      const fertilizer = getNrxFertilizerListItemData(value);
      if (fertilizer) {
        this.props.setFieldValue('type', fertilizer.type);
        this.props.setFieldValue('nitrogenPercentage', fertilizer.nitrogenPercentage);
      }
    }
  };

  onChangePreviousApplication = (
    item: FertilizerApplication,
    prop: keyof FertilizerApplication,
    value: any
  ) => {
    const {changedApplications} = this.props.values;
    let data = {
      ...item,
      [prop]: value,
    };

    if (prop === 'typeID' && item.typeID !== value) {
      const fertilizer = getNrxFertilizerListItemData(value);
      data = fertilizer ? {...data, ...fertilizer} : data;
    }

    const result = changedApplications.find(a => a.id === item.id)
      ? changedApplications.map(a => (a.id === item.id ? {...a, ...data} : a))
      : [...changedApplications, data];

    this.props.setFieldValue('changedApplications', result);
  };

  onDeleteApplication = (fieldId: number, appId: number) => {
    this.props.fApplicationRemove(fieldId, appId);
    this.props.setFieldValue(
      'changedApplications',
      this.props.values.changedApplications.filter((a: any) => a.id !== appId)
    );
  };

  handleSubmit = (addNewApp = false) => {
    this.props.setFieldValue('addNewApp', addNewApp);
    setTimeout(this.props.handleSubmit, 100);
  };

  onHide = () => {
    const confirm =
      this.props.fertilizerApplications.length ||
      window.confirm(
        t({
          id:
            'You need to set at least one fertilizer application to start using the model, are you sure you want to cancel?',
        })
      );

    if (confirm) {
      this.props.togglePopUp('fertilizerPopUpOpen', false);
    }
  };

  isCurrentSeasonApplication = (date: string) => {
    return moment(date, GLOBAL_FORMAT_DATE).isSameOrBefore(
      moment(this.props.particularSeason.prevHarvestDate, GLOBAL_FORMAT_DATE)
    );
  };

  getFertilizers = () => {
    const {
      values: {changedApplications},
    } = this.props;

    const fertilizerApplications = (this.props.fertilizerApplications || []).map((
      a: any // merge saved applications with changed (if have some)
    ) =>
      changedApplications.find(c => c.id === a.id)
        ? {...a, ...changedApplications.find(c => c.id === a.id)}
        : {
            ...a,
            edit: false,
          }
    );

    return [
      ...fertilizerApplications.filter((a: any) => !a.prevSeason),
      ...fertilizerApplications.filter((a: any) => a.prevSeason),
    ];
  };

  getMaxMinDates = () => {
    const {endDate} = this.props.particularSeason;
    return moment(endDate, GLOBAL_FORMAT_DATE).isBefore(moment())
      ? moment(endDate, GLOBAL_FORMAT_DATE)
      : moment();
  };

  render() {
    const {
      values,
      nrxListsData,
      particularSeason,
      fertilizerApplications = [],
      measurement,
    } = this.props;
    const applications = this.getFertilizers();
    const disableAddNew =
      (!values.typeID && values.typeID !== 0) || !values.quantity || !values.nitrogenPercentage;
    const maxDate = this.getMaxMinDates();
    return (
      <FluroDialog
        id={`fertilizer-pop-up`}
        className={'fertilizer-pop-up'}
        visible={true}
        title={t({id: 'Previous fertilizer applications'})}
        isDraggable
        onHide={this.onHide}
        focusOnMount={false}
      >
        <div className={'fertilizer-pop-up-content'}>
          <div className={'add-new-container'}>
            <div className={'title'}>{t({id: 'Add'})}</div>

            <ApplicationItem
              id={0}
              typeID={values.typeID}
              nitrogenPercentage={this.props.values.nitrogenPercentage}
              prevSeason={this.isCurrentSeasonApplication(this.props.values.date)}
              quantity={values.quantity}
              date={this.props.values.date}
              fertilizerTypes={nrxListsData.fertilizerTypes}
              onChange={(prop: keyof FertilizerApplication, value: any) =>
                this.onChange(prop, value)
              }
              onDelete={null}
              maxDate={maxDate}
              measurement={measurement}
            />

            <Button disabled={disableAddNew} raised primary onClick={() => this.handleSubmit(true)}>
              {t({id: 'Add'})}
            </Button>
          </div>

          {applications.length !== 0 && (
            <div className={'season-application-container'}>
              <div className={'title'}>{t({id: 'Application records'})}</div>

              {applications.map((item: any) => (
                <ApplicationItem
                  key={item.id}
                  {...item}
                  prevSeason={this.isCurrentSeasonApplication(item.date)}
                  fertilizerTypes={nrxListsData.fertilizerTypes}
                  onDelete={() => this.onDeleteApplication(particularSeason.kmlID, item.id)}
                  onChange={(prop: keyof FertilizerApplication, value: any) =>
                    this.onChangePreviousApplication(item, prop, value)
                  }
                  maxDate={maxDate}
                  measurement={measurement}
                />
              ))}
            </div>
          )}
        </div>

        <div className={`button-container end`}>
          {fertilizerApplications.length !== 0 && (
            <Button
              disabled={values.changedApplications.some(f => f.quantity < 1 || f.quantity > 2000)}
              raised
              primary
              onClick={() => this.handleSubmit()}
            >
              {t({id: 'Save'})}
            </Button>
          )}
        </div>
      </FluroDialog>
    );
  }
}

const FormicForm = withFormik<Props, TValuesProps>({
  enableReinitialize: true,
  mapPropsToValues: (props: Props) => ({
    changedApplications: [],
    typeID: -1,
    quantity: 1,
    nitrogenPercentage: 0,
    date:
      props.applicationDate ||
      moment(props.particularSeason.prevHarvestDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
    addNewApp: false,
  }),

  validationSchema: Yup.object().shape({
    addNewApp: Yup.boolean(),
    type: Yup.string(),
    quantity: Yup.number(),
    date: Yup.string(),
    nitrogenPercentage: Yup.number(),
  }),

  handleSubmit: (values: any, {props, setSubmitting, resetForm}) => {
    if (values.addNewApp) {
      props.fApplicationAdd({
        seasonID: props.particularSeason.seasonID,
        nrxSeasonID: props.particularSeason.nrxSeasonID,
        fieldID: props.particularSeason.kmlID,
        quantity: values.quantity,
        option: 25,
        type: values.type,
        typeID: values.typeID,
        nitrogenPercentage: values.nitrogenPercentage,
        date: values.date,
      });
      resetForm();
    } else {
      values.changedApplications.forEach((v: any) =>
        props.fApplicationUpdate(props.particularSeason.kmlID, {
          ...v,
          nrxSeasonID: props.particularSeason.nrxSeasonID,
        })
      );
      props.togglePopUp('fertilizerPopUpOpen', false);
    }
    setSubmitting(true);
  },

  displayName: 'PlantingSoilParams',
})(FertilizerPopUp);

const connected = connect(null, {
  fApplicationAdd,
  fApplicationUpdate,
  fApplicationRemove,
  showNote,
});

export default connected(FormicForm);
