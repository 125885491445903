import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {useMap} from 'react-leaflet';
import {useEffect} from 'react';
import {useAppSelector} from '../../_hooks';
import config from '../../_environment';

const editStyle = {
  color: '#fff',
  fillColor: '#43A047',
  fillOpacity: 0.3,
};

export const EditControl2 = (): null => {
  const map = useMap();
  const isEditingMode = useAppSelector(s => s.map.drawControl.isEditingMode);
  const fieldId = useAppSelector(s => s.map.drawControl.fieldId);

  useEffect(() => {
    if (isEditingMode && map) {
      map.eachLayer(layer => {
        if (config.featurePack === 'carbon') {
          //@ts-ignore
          if (layer.feature?.properties?.fluro_id === fieldId) {
            //@ts-ignore
            layer.pm.enable();
            //@ts-ignore
            layer.setStyle(editStyle);
          }
        } else {
          //@ts-ignore
          if (layer.__fluroLayerToEdit && fieldId) {
            //@ts-ignore
            layer.pm.enable();
            //@ts-ignore
            layer.setStyle(editStyle);
          }
        }
      });
    }
  }, [isEditingMode, map, fieldId]);

  // useEffect(() => {
  //   if (map) {
  //     map.pm.addControls({
  //       position: 'topleft',
  //       drawCircle: false,
  //     });
  //   }
  // }, [map]);

  return null;
};
