import React from 'react';
import {useDispatch} from 'react-redux';
import cn from 'classnames';

import {
  AddFieldsStep,
  CompleteStep,
  DrawFieldsStep,
  SelectBoundariesOnMap,
  UploadStep,
  FieldsListPreview,
} from './steps';

import {addFieldChangeCurrentStep, AddFieldCurrentStep} from 'modules/add-fields.module';

import './index.scss';
import FieldPropertiesParser from 'containers/map/features/farm/new-fields/field-properties-parser/field-properties-parser';
import {defaultMaxListeners} from 'node:events';
import {useAppSelector} from '_hooks';

type Props = {
  onHide: () => void;
};

const NewFields = ({onHide}: Props) => {
  const dispatch = useDispatch();
  const currentStep = useAppSelector(s => s.addFields.addFieldCurrentStep);

  const previewFields = [
    'view-selected-boundaries',
    'view-drew-fields',
    'view-fields-from-files',
  ].includes(currentStep);

  const changeCurrentStep = (value: AddFieldCurrentStep) =>
    dispatch(addFieldChangeCurrentStep(value));

  return (
    <div
      className={cn('field-items-container', {
        'name-uploaded-fields': previewFields,
      })}
    >
      {currentStep === 'add-fields' && <AddFieldsStep changeCurrentStep={changeCurrentStep} />}

      {currentStep === 'select-boundaries' && <SelectBoundariesOnMap />}

      {currentStep === 'draw-fields' && <DrawFieldsStep />}

      {currentStep === 'select-files-to-upload' && (
        <UploadStep changeCurrentStep={changeCurrentStep} />
      )}

      {currentStep === 'parse-uploading-files' && <FieldPropertiesParser />}

      {previewFields && <FieldsListPreview />}

      {currentStep === 'complete' && <CompleteStep onHide={onHide} />}
    </div>
  );
};

export default NewFields;
