import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'react-md';
import {TreeFocus, TreeDetection, Tree} from '../icons';
import {setTreeLayerType, toggleMapButton} from '../actions';
import {TreesTypes} from '../types';
import CustomDrawButton from './custom-draw-button';
import {getTreeDataByDate, getAvailableSensor} from '../utils/trees';
import cn from 'classnames';
import Mixpanel from '_utils/mixpanel-utils';
import {AppStore} from '../../../reducers';

const ToggleTreeDetection = () => {
  const dispatch = useDispatch();
  const {layerType} = useSelector((store: AppStore) => store.map.treeDetection);
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const currentDates = useSelector((store: AppStore) => store.map.currentDates);
  const toggledButtonOnMap = useSelector((store: AppStore) => store.map.toggledButtonOnMap);
  const thisButtonName = 'TreeDetection';
  const toggled = useMemo(() => toggledButtonOnMap === thisButtonName, [toggledButtonOnMap]);
  const toggleButtonPopUp = () => dispatch(toggleMapButton(thisButtonName));
  const currentDateObjectTreeData = currentDates[currentDate]?.treeData;

  const treeData = useMemo(() => getTreeDataByDate(currentDate), [
    currentDate,
    currentDates,
    currentDateObjectTreeData,
  ]);
  const detectionData = useMemo(() => getAvailableSensor('detection', treeData), [treeData]);
  const focusData = useMemo(() => getAvailableSensor('focus', treeData), [treeData]);

  const buttonIcon = useMemo(() => {
    switch (layerType) {
      case 'detection':
        return <TreeDetection />;
      case 'focus':
        return <TreeFocus />;
      default:
        return <Tree />;
    }
  }, [layerType]);

  if (!treeData) {
    return null;
  }

  const onSelectLayerType = (layerType: TreesTypes) => {
    Mixpanel.treeLayerClicked(layerType);
    dispatch(setTreeLayerType(layerType));
  };

  return (
    <div
      className={cn('btn-on-map-pop-up', {
        active: toggled,
      })}
    >
      <Button
        id="toggle-tree-detection"
        title={t({id: 'Tree detection', defaultMessage: 'Tree detection'})}
        className={`map-toggle-button`}
        name="toggle-field-info"
        iconEl={buttonIcon}
        onClick={toggleButtonPopUp}
        floating
        mini
        disabled={!currentDates[currentDate]}
      />
      <div className={`pop-up tree-detection`}>
        <h5 className={'pop-up__title'}>
          {t({id: 'Tree analysis', defaultMessage: 'Tree analysis'})}
        </h5>

        <CustomDrawButton
          type={'Raw imagery'}
          className={layerType === 'default' ? 'primary' : ''}
          onClick={() => onSelectLayerType('default')}
          iconEl={<Tree />}
        />

        {focusData && (
          <CustomDrawButton
            type={'Tree focus'}
            className={layerType === 'focus' ? 'primary' : ''}
            onClick={() => onSelectLayerType('focus')}
            iconEl={<TreeFocus />}
          />
        )}

        {detectionData && (
          <CustomDrawButton
            type={'Tree detection'}
            className={layerType === 'detection' ? 'primary' : ''}
            onClick={() => onSelectLayerType('detection')}
            iconEl={<TreeDetection />}
          />
        )}
      </div>
    </div>
  );
};

export default ToggleTreeDetection;
