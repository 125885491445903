import {UserApi, SubscriptionsApi} from '_api';
import {AppStore} from 'reducers';

export enum ActionType {
  SetAccessData = '@access-data/set',
}

export type Role = 'read' | 'write' | 'admin';
export type EntType = 'subs' | 'orgs' | 'farms';

export type AccessItem = {
  roles: Role[];
  entityId: number;
  entityName: string;
  type: EntType;
};

export type UserAccessStore = {
  roles: {[key in EntType]: AccessItem[]};
  subscriptions: any[];
  organizations: any[];
};

const initialState: UserAccessStore = {
  roles: {
    subs: [],
    orgs: [],
    farms: [],
  },

  subscriptions: [],
  organizations: [],
};

export const loadUserAccess = (id: number) => (dispatch: any, getState: any) => {
  return UserApi.accessRoles(id).then(async ({data}) => {
    try {
      const state: AppStore = getState();
      const farms = state.farms.list;

      const subsResult = await SubscriptionsApi.loadSubscriptions();
      const orgsResult = await SubscriptionsApi.loadAllOrganizations();

      const subs = subsResult?.data?.result || [];
      const orgs = orgsResult?.data?.result || [];

      const entities: any = {subs, orgs, farms};

      const roles = data?.result?.roles || {};

      let resultData: any = {subs: {}, orgs: {}, farms: {}};

      Object.keys(roles).forEach(role => {
        const [entityType, permission] = role.split('.');

        roles[role].forEach((entityId: number) => {
          if (resultData[entityType][entityId]) {
            resultData[entityType][entityId].roles.push(permission);
          } else {
            resultData[entityType][entityId] = {
              roles: [permission],
              entityName: entities[entityType].find((f: any) => f.id === entityId)?.name || '',
              type: entityType,
              entityId,
            };
          }
        });
      });

      Object.keys(resultData).forEach(el => {
        resultData[el] = Object.values(resultData[el]);
      });

      dispatch(setRoles(resultData));
    } catch (e) {
      console.log(e.message);
    }
  });
};

export const setRoles = (roles: any) => ({
  type: ActionType.SetAccessData,
  roles,
});

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SetAccessData: {
      return {
        ...state,
        roles: {...action.roles},
      };
    }
    default:
      return state;
  }
};
