import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import {SelectField, Button, TextField} from 'react-md';
import {useForm} from 'react-hook-form';

import {FluroDatePicker, FluroDialog, FluroAutocomplete, InfoBlock} from 'components';
import Yup from 'yup';
import moment, {Moment} from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {
  getNrxSowingDepthList,
  getPropertiesFromSeasons,
  isSetNrxSeasonSowingData,
  isSetNrxSeasonCropMatch,
  getPrevSeasonMaxHarvestDate,
  getNrxSeason,
  NRxSeason,
  defaultSowingParams,
  areFieldsInAustralia,
} from '../../../../utils/nrx-utils';
import {IInitialMapState} from '../../../../types';
import {formatUnit} from '_utils';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch} from 'react-redux';

type Props = {
  setUpdateSeasonNrxData(seasonId: number, values: any): void;
  togglePopUp(pop: string, val: boolean, seasonId?: number | null): void;
  nrxListsData: Pick<IInitialMapState, 'listsData'>['listsData'];
  selectedSeasons: NRxSeason[];
  measurement: string;
};
const fieldIsRequiredString = t({id: 'Field is required'});
type Payload = {
  soilTypeLabelID: string;
  prevCropTypeID: number;
  soilTypeID: number;
  sowingDepth: number;
  sowingDensity: number;
  rowSpacing: number;
  prevHarvestDate: string;
  haySilage: number;
  irrigated: boolean;
};

const schema = (measurement: string) => {
  const isMetricUnits = measurement === 'ha';
  const maxDepth = isMetricUnits ? 255 : 9.8;
  const maxDensity = isMetricUnits ? 600 : 2428200;
  const maxRowSpacing = isMetricUnits ? 1100 : 43.3;
  return Yup.object().shape({
    soilTypeLabelID: Yup.string().min(1, fieldIsRequiredString),
    sowingDepth: Yup.number()
      .typeError(fieldIsRequiredString)
      .min(1, `${t({id: 'The min value is'})} 1`)
      .max(maxDepth, `${t({id: 'The max value is'})} ${maxDepth} ${formatUnit(measurement, 'mm')}`),
    sowingDensity: Yup.number()
      .typeError(fieldIsRequiredString)
      .min(1, `${t({id: 'The min value is'})} 1`)
      .max(
        maxDensity,
        `${t({id: 'The max value is'})} ${maxDensity} ${formatUnit(measurement, 'plants/m²')}`
      ),
    rowSpacing: Yup.number()
      .typeError(fieldIsRequiredString)
      .min(1, `${t({id: 'The min value is'})} 1`)
      .max(
        maxRowSpacing,
        `${t({id: 'The max value is'})} ${maxRowSpacing} ${formatUnit(measurement, 'mm')}`
      ),
    haySilage: Yup.number().min(0, fieldIsRequiredString),
    prevCropTypeID: Yup.number().min(1, fieldIsRequiredString),
    prevHarvestDate: Yup.string().min(1, fieldIsRequiredString),
  });
};

const PlantingSoilParams = ({
  setUpdateSeasonNrxData,
  togglePopUp,
  selectedSeasons,
  measurement,
  nrxListsData: {
    prevCropTypesList,
    silageHayList,
    soilTypesList,
    fieldsNearestSoilTypesList,
    irrigationList,
  },
}: Props) => {
  const dispatch = useDispatch();
  // Init consts
  const currentFieldId = selectedSeasons.length === 1 ? selectedSeasons[0]?.kmlID : 0;
  const preparedSoilTypeList = fieldsNearestSoilTypesList[currentFieldId] || soilTypesList; // specific list of soil type for Australian fields
  const fieldIds = selectedSeasons.map(s => s.kmlID);
  const isAustralian = !!dispatch(areFieldsInAustralia(fieldIds));
  const seasonData: NRxSeason = getPropertiesFromSeasons(selectedSeasons);
  const defaultParams =
    !seasonData.sowingDepth && !seasonData.rowSpacing ? defaultSowingParams(5) : {};

  // ------           -------

  // Init form
  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: {errors},
    reset,
    trigger,
  } = useForm<Payload>({
    mode: 'onChange',
    resolver: yupResolver(schema(measurement)),
    defaultValues: {
      soilTypeLabelID: seasonData.soilTypeLabelID,
      prevCropTypeID: seasonData.prevCropTypeID,
      soilTypeID: seasonData.soilTypeID,
      sowingDepth: seasonData.sowingDepth,
      sowingDensity: seasonData.sowingDensity,
      rowSpacing: seasonData.rowSpacing,
      haySilage: seasonData.haySilage,
      irrigated: seasonData.irrigated,
      ...defaultParams,
      prevHarvestDate: seasonData.prevHarvestDate
        ? getPrevSeasonMaxHarvestDate(
            Array.isArray(seasonData.prevHarvestDate)
              ? seasonData.prevHarvestDate.find(date => date)
              : seasonData.prevHarvestDate,
            true
          )
        : getPrevSeasonMaxHarvestDate(seasonData.startDate),
    },
  });
  useEffect(() => {
    register('soilTypeLabelID');
    register('prevCropTypeID');
    register('soilTypeID');
    register('sowingDepth');
    register('sowingDensity');
    register('rowSpacing');
    register('prevHarvestDate');
    register('haySilage');
    register('irrigated');
  }, [register]);

  const values = watch();

  // ------           -------

  // Methods

  const onChange = async (prop: keyof Payload, value: number | string | boolean) => {
    console.log('prop', prop);
    console.log('value', value);
    setValue(prop, value);
    if (prop === 'soilTypeLabelID') {
      setValue('soilTypeID', getNrxSowingDepthList(`${value}`)[0].value);
      await trigger('soilTypeID');
    }
    await trigger(prop);
  };

  const onHide = () => {
    const {seasonID} = seasonData;

    const confirm = (
      Array.isArray(seasonID)
        ? seasonID.every(sId => isSetNrxSeasonSowingData(getNrxSeason(sId)))
        : isSetNrxSeasonSowingData(getNrxSeason(seasonID))
    )
      ? true
      : window.confirm(
          t({
            id:
              'You need to set all parameters to start using the model, are you sure you want to cancel?',
          })
        );

    if (confirm) {
      reset();
      togglePopUp('plantingSoilPopUpOpen', false);
    }
  };

  const onSubmit = () => {
    setUpdateSeasonNrxData(seasonData.seasonID, values);

    if (!isSetNrxSeasonCropMatch([seasonData.seasonID]))
      return togglePopUp(
        // open next pop-up to fill Nrx season data
        'matchCropPopUpOpen',
        true,
        Array.isArray(seasonData.seasonID) ? null : seasonData.seasonID
      );

    togglePopUp('plantingSoilPopUpOpen', false);
  };
  // ------           -------

  // Calculated values
  const automaticSoilSelection =
    isAustralian || values.soilTypeLabelID === 'Automatic soil selection';

  const sowingDepthList = useMemo(
    () => getNrxSowingDepthList(values.soilTypeLabelID, automaticSoilSelection),
    [values.soilTypeLabelID]
  );

  // ------           -------

  return (
    <FluroDialog
      id={`planting-soil-params-pop-up`}
      className={'planting-soil-params-pop-up'}
      visible={true}
      isDraggable
      title={t({id: 'Planting and Soil Parameters'})}
      onHide={onHide}
      focusOnMount={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'rows-container'}>
          <div className={'title'}>{t({id: 'Soil parameters'})}</div>
          <div className={'soil-type-row-container'}>
            <FluroAutocomplete
              id="soil-type"
              label={t({id: 'Soil Type'})}
              title={values.soilTypeLabelID}
              menuItems={preparedSoilTypeList}
              fixedToTop={[
                {
                  value: 'Automatic soil selection',
                  label: t({id: 'Automatic soil selection'}),
                },
              ]}
              onAutocomplete={(value: number) => onChange('soilTypeLabelID', value)}
              value={values.soilTypeLabelID}
              className={`soil-type-select`}
              error={!!errors?.soilTypeLabelID?.message}
              errorText={errors.soilTypeLabelID?.message}
            />
            <SelectField
              id="soil-depth"
              label={t({id: 'Soil Depth'})}
              placeholder={t({id: 'Select a value'})}
              block
              className={'soil-depth-select'}
              menuItems={sowingDepthList}
              simplifiedMenu={true}
              value={automaticSoilSelection ? -1 : values.soilTypeID}
              error={!!errors.soilTypeID?.message}
              errorText={errors.soilTypeID?.message}
              disabled={!values.soilTypeLabelID || automaticSoilSelection}
              onClick={() =>
                values.soilTypeLabelID ? null : alert(t({id: 'Please, select soil type first'}))
              }
              onChange={value => onChange('soilTypeID', value)}
            />
          </div>

          {automaticSoilSelection && (
            <InfoBlock className={'soil-type-info inside-a-pop-up'}>
              {t({
                id:
                  'This soil type was automatically selected from the APsoil database based on the field’s geographic location. You may select other soils from this region by clicking on the “Soil Type” dropdown menu.',
              })}
            </InfoBlock>
          )}

          <div className={'title'}>{t({id: 'Planting parameters'})}</div>
          <div className={'planting-params-container'}>
            <TextField
              id="sowing-depth"
              label={`${t({id: 'Sowing depth'})} (${formatUnit(measurement, 'mm')})`}
              placeholder={t({id: 'Enter a value'})}
              className={'input-item'}
              type={'number'}
              value={values.sowingDepth}
              error={!!errors.sowingDepth?.message}
              errorText={errors.sowingDepth?.message}
              onChange={(value: string) => onChange('sowingDepth', parseFloat(value))}
            />

            <TextField
              id="density"
              label={`${t({id: 'Planting density'})} (${t({
                id: formatUnit(measurement, 'plants/m²'),
              })})`}
              placeholder={t({id: 'Enter a value'})}
              className={'input-item'}
              type={'number'}
              value={values.sowingDensity}
              error={!!errors.sowingDensity?.message}
              errorText={errors.sowingDensity?.message}
              onChange={(value: string) => onChange('sowingDensity', parseFloat(value))}
            />

            <TextField
              id="row-spacing"
              label={`${t({id: 'Row spacing'})} (${formatUnit(measurement, 'mm')})`}
              placeholder={t({id: 'Select a value'})}
              className={'input-item'}
              type={'number'}
              value={values.rowSpacing}
              error={!!errors.rowSpacing?.message}
              errorText={errors.rowSpacing?.message}
              onChange={(value: string) => onChange('rowSpacing', parseFloat(value))}
            />

            <SelectField
              id="irrigation"
              block
              label={t({id: 'Irrigation'})}
              placeholder={t({id: 'Select a value'})}
              menuItems={irrigationList}
              className={'input-item'}
              simplifiedMenu={true}
              value={values.irrigated ? 1 : 0}
              onChange={value => onChange('irrigated', !!value)}
              error={!!errors.irrigated?.message}
              errorText={errors.irrigated?.message}
            />
          </div>

          <div className="title">{t({id: 'Previous crop'})}</div>
          <div className="prev-crop-params-container">
            <SelectField
              id="crop-type"
              block
              label={t({id: 'Crop type'})}
              menuItems={prevCropTypesList}
              simplifiedMenu={true}
              className={'input-item'}
              value={values.prevCropTypeID}
              onChange={value => onChange('prevCropTypeID', value)}
              error={!!errors.prevCropTypeID?.message}
              errorText={errors.prevCropTypeID?.message}
            />

            <FluroDatePicker
              id="application-date"
              label={t({id: 'Harvest date'})}
              className={'input-item'}
              onChange={(value: Moment) =>
                onChange('prevHarvestDate', value.format(GLOBAL_FORMAT_DATE))
              }
              selected={moment(values.prevHarvestDate, GLOBAL_FORMAT_DATE)}
              maxDate={moment(
                getPrevSeasonMaxHarvestDate(seasonData.startDate, true),
                GLOBAL_FORMAT_DATE
              )}
              hideFormat={true}
            />

            <SelectField
              id="silage-hay"
              block
              label={t({id: 'Silage or hay?'})}
              placeholder={t({id: 'Select a value'})}
              className={'input-item'}
              menuItems={silageHayList}
              simplifiedMenu={true}
              value={values.haySilage}
              error={!!errors.haySilage?.message}
              errorText={errors.haySilage?.message}
              onChange={value => onChange('haySilage', value)}
            />
          </div>
        </div>

        <div className={'button-container'}>
          <Button raised primary type={'submit'}>
            {t({id: 'Save changes'})}
          </Button>
        </div>
      </form>
    </FluroDialog>
  );
};
export default PlantingSoilParams;
