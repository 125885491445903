import React from 'react';
import moment, {Moment} from 'moment';
import {
  calcPlural,
  convertUnit,
  formatBigNumber,
  formatDate,
  formatUnit,
  getMeasurement,
  toFixedFloat,
} from '_utils';
import {InfoBlock} from 'components';
import {Farm} from '../../farm/types';
import {KmlApi} from '_api';
import {Field} from '../../map/types';

export const getDatesInfoMessage = (currentDate: Moment, startDate: Moment, endDate: Moment) => {
  const dateFormat = formatDate();

  let infoMessageContent = null;
  // const currentDateInYear = moment(currentDate).add(1, 'year').format(dateFormat);

  // if (selectedDate.isBefore(currentDate, 'day')) {
  //   infoMessageContent = (
  //     <ul>
  //       <li>
  //         Historical imagery since {selectedDate.format(dateFormat)} (additional historical imagery
  //         since 2009 is included in the order at no extra cost).
  //       </li>
  //       <li>
  //         Future imagery (Monitoring): from {currentDate.format(dateFormat)} until{' '}
  //         {currentDateInYear}.
  //       </li>
  //     </ul>
  //   );
  // } else {
  //   infoMessageContent = ` Daily imagery (Monitoring): from ${currentDate.format(
  //     dateFormat
  //   )} until ${currentDateInYear}.`;
  // }
  // return (
  //   <InfoBlock appearance={'info'}>
  //     By selecting these dates, you will receive:
  //     {infoMessageContent}
  //   </InfoBlock>
  // );
  return (
    <InfoBlock appearance={'info'}>
      You will receive all available historical imagery where cloud% is {'<'} 20%, between{' '}
      {startDate.format(dateFormat)} and {endDate.format(dateFormat)} for the selected fields.
    </InfoBlock>
  );
};

export const getFieldsAreaAndPrice = (fields: Field[]) => {
  // example result: ["100 ha", "100 000"]
  const measurement = getMeasurement();
  let selectedFieldsRawArea = 0;
  fields.forEach(field => (selectedFieldsRawArea = selectedFieldsRawArea + field.Area));

  const selectedFieldsFinalArea =
    selectedFieldsRawArea < 1
      ? '< 1'
      : toFixedFloat(convertUnit(measurement, 'ac', selectedFieldsRawArea), 0);

  // the price for 1 ac = 1.5$
  const priceForTheFieldsArea = toFixedFloat(
    selectedFieldsFinalArea * (measurement === 'ha' ? 1.5 * 2.4711 : 1.5),
    0
  );

  return [
    `${formatBigNumber(selectedFieldsFinalArea)} ${formatUnit(measurement, 'ha')}`,
    `${formatBigNumber(priceForTheFieldsArea)}`,
  ];
};
export const getSelectedFieldsInfoMessage = (selectedFields: Field[]) => {
  const [fieldsArea, fieldsPrice] = getFieldsAreaAndPrice(selectedFields);

  return (
    <InfoBlock appearance={'info'}>
      You selected{' '}
      <span className={'green-colored'}>
        {formatBigNumber(selectedFields.length)} {calcPlural('field', selectedFields)}
      </span>{' '}
      covering an area of <span className={'green-colored'}>{fieldsArea}</span>. The price of this
      order is <span className={'green-colored'}>US {fieldsPrice}$</span>.
    </InfoBlock>
  );
};

export const getFarmsWithFields = async (farmsToRequest: Farm[]) => {
  return Promise.all(
    farmsToRequest.map(farm =>
      KmlApi.getFileListByIDs(farm.id, [], true)
        .then(({data}) => ({
          name: data.result.group.name,
          id: data.result.group.id,
          fields: data.result.fields.map(f => ({...f, _selected: true})),
          toggled: false,
        }))
        .catch(() => null)
    )
  );
};
