import {Season, TInfoExt} from '../types';
import moment from 'moment';
import {GLOBAL_APP_DATE_FORMAT, GLOBAL_FORMAT_DATE} from '_constants';
import {getClosestDate} from './index';
import {setGetParamToURL} from '_utils';

export default class {
  static getLastSeason(seasons: Season[]) {
    return seasons.length
      ? seasons.reduce((lastSeason, s) => {
          if (lastSeason) {
            return moment(s.startDate).isAfter(lastSeason.startDate) ? s : lastSeason;
          }
          return s;
        }, null)
      : null;
  }

  static getSeasonClosestDate(
    date: string,
    seasonDates: {[key: string]: TInfoExt},
    seasonStartDate: string,
    shouldSetToURL = true
  ) {
    const dateKeys = Object.keys(seasonDates);
    if (!dateKeys.length) return getClosestDate(date, seasonDates, shouldSetToURL);

    const momentCurrentDate = moment(date, GLOBAL_APP_DATE_FORMAT);
    const momentSeasonStart = moment(seasonStartDate, GLOBAL_FORMAT_DATE);

    if (!seasonDates[date] && momentCurrentDate.isBefore(momentSeasonStart)) {
      setGetParamToURL('layerDate', dateKeys[0]);
      return dateKeys[0];
    }

    return getClosestDate(date, seasonDates, shouldSetToURL);
  }
}
