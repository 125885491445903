import React, {useEffect, useState} from 'react';
import Search, {UpdatedSearchResult} from './search';
import L from 'leaflet';
import './index.scss';
import {t} from 'i18n-utils';
import {useDispatch, useSelector} from 'react-redux';
import {showNote} from '_actions';
import {AppStore} from 'reducers';
import {AddingFieldsFromMapSteps} from 'modules/add-fields.module';
import {setLocationMarkerCoordinates} from '../../actions';
import {usePrevious} from '_hooks';

type Props = {
  leafletElement: L.Map;
  toggleSearch: (value: boolean) => void;
};

const SearchLocation = ({leafletElement, toggleSearch}: Props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const addFieldCurrentStep = useSelector((s: AppStore) => s.addFields.addFieldCurrentStep);
  const isMapBarOpen = useSelector((s: AppStore) => s.map.isMapBarOpen);
  const prev = usePrevious({addFieldCurrentStep});
  const regExp = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g;
  const activeFieldAddingState = AddingFieldsFromMapSteps.includes(addFieldCurrentStep);

  const navigateToLocation = (location: UpdatedSearchResult) => {
    if (location.bounds) {
      leafletElement?.fitBounds(location.bounds, {
        paddingBottomRight: [isMapBarOpen ? 300 : 0, 70],
        maxZoom: 20,
      });
    } else {
      leafletElement?.panTo([location.y, location.x]);
    }
  };

  const handleAutocomplete = (location: UpdatedSearchResult) => {
    console.log('location', location);
    if (!location) return;
    handleChange(location.label);
    !activeFieldAddingState && toggleSearch(false); // do not hide the search if it is field creation stage
    if (leafletElement) {
      dispatch(setLocationMarkerCoordinates(location.y, location.x));
      navigateToLocation(location);
    }
  };

  const handleChange = (value: string) => {
    setSearch(value);
  };

  const navigateToCoordinates = () => {
    const bounds = search.split(',');
    handleAutocomplete({
      x: parseFloat(bounds[1]),
      y: parseFloat(bounds[0]),
      label: search,
    } as UpdatedSearchResult);
  };

  const handleEnterKey = (foundedItems: number) => {
    const isCoordinates = search.match(regExp);

    if (isCoordinates) {
      navigateToCoordinates();
      const firstMenuItem: HTMLElement = document.querySelector('.map-v2__main');
      firstMenuItem && firstMenuItem.click();
      return;
    }

    if (foundedItems === 1 && !isCoordinates) {
      const firstMenuItem: HTMLElement = document.querySelector(
        '#toolbar-search-menu-list .md-list-item'
      );
      firstMenuItem && firstMenuItem.click();
    } else if (foundedItems === 0 && !isCoordinates) {
      dispatch(
        showNote({
          title: t({id: 'warning', defaultMessage: 'Warning'}),
          message: t(
            {
              id: `cannotFindValue`,
              defaultMessage: `Cannot find {search}`,
            },
            {search}
          ),
          level: 'warning',
        })
      );
    }
  };

  useEffect(() => {
    if (
      // automatically close the search bar after field creating workflow was cancelled
      AddingFieldsFromMapSteps.includes(prev?.addFieldCurrentStep) &&
      !activeFieldAddingState
    ) {
      toggleSearch(false);
    }
  }, [addFieldCurrentStep]);

  const onSearchBlurred = () => {
    // prevent automatically closing search bar during fields creating workflow
    if (!activeFieldAddingState) {
      toggleSearch(false);
    }
  };

  return (
    <Search
      activeFieldCreation={activeFieldAddingState}
      title={search}
      value={search}
      onBlur={onSearchBlurred}
      onChange={handleChange}
      onAutocomplete={handleAutocomplete}
      onKeyDown={handleEnterKey}
    />
  );
};

export default SearchLocation;
