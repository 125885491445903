import React from 'react';
import {Button, ButtonProps} from 'react-md';
import './index.scss';
import cn from 'classnames';
import {ReadOnly} from '../index';

type Props = ButtonProps & {
  blank?: boolean; // remove shadow from the button
  noPadding?: boolean;
  readonly?: boolean; // wrap into <ReadOnly> container
};

const FluroButton = ({
  readonly = false,
  blank = false,
  noPadding,
  className,
  secondary,
  disabled,
  iconEl,
  ...otherProps
}: Props) => {
  return (
    <Container readonly={readonly}>
      <Button
        {...otherProps}
        secondary={secondary}
        disabled={disabled}
        iconEl={iconEl}
        className={cn(`fluro-button ${className}`, {
          blank,
          noPadding,
          secondary,
          disabled,
          'with-icon': !!iconEl,
        })}
      />
    </Container>
  );
};

type ContainerProps = {
  children: React.ReactElement;
  readonly: boolean;
};

const Container = ({children, readonly}: ContainerProps) =>
  readonly ? <ReadOnly>{children}</ReadOnly> : <>{children}</>;

export default FluroButton;
