import {toFixedFloat} from '_utils';
import {CSGAvgViewModel, CSGViewModel} from '../types';

export const calcAverage = (records: CSGViewModel[]): CSGAvgViewModel => {
  return {
    cropType: maxOccurences(records.map(r => r.cropType)),
    cropVariety: maxOccurences(records.map(r => r.cropVariety)),
    fieldArea: sum(records.map(r => r.fieldArea)),
    cropStatus: maxOccurences(records.map(r => r.cropStatus)),
    growth: maxOccurences(records.map(r => r.growth)),
    reliable: maxOccurences(records.map(r => r.reliable)),
    smoothSatelliteNdvi: toFixedFloat(
      averageNumber(records.map(r => r.smoothSatelliteNdvi).filter(x => x != null)),
      2
    ),
    cumulativeSmoothSatelliteNdvi: parseInt(
      averageNumber(
        records.map(r => r.cumulativeSmoothSatelliteNdvi).filter(x => x != null)
      ).toFixed(1)
    ),
  };
};

export const averageNumber = (values: number[]) => {
  return values.reduce((a, b) => a + b, 0) / values.length;
};

const maxOccurences = <T extends CSGAvgViewModel[keyof CSGAvgViewModel]>(values: T[]) => {
  const occurrences: Map<T, number> = new Map();
  let maxOccurencesKey: T;
  values.forEach(v => {
    occurrences.set(v, (occurrences.get(v) || 0) + 1);
    if (occurrences.get(v) > (occurrences.get(maxOccurencesKey) || 0)) {
      maxOccurencesKey = v;
    }
  });
  return maxOccurencesKey;
};

const sum = (values: number[]) => values.reduce((acc, v) => acc + v, 0);
