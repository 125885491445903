import store from 'store';
import {urlsToReason} from '_constants';
import _config from '_environment';
import pathToRegexp from 'path-to-regexp';
import {genKey} from '_utils';
import {showLoading, hideLoading, toggleSessionExpired, addSessionExpiredRequest} from '_actions';

const urlsToReasonKeys = Object.keys(urlsToReason);
const hasKey = (pattern: string, key: string) => {
  const obj = new URLSearchParams(pattern.split('?')?.[1] || '');
  return obj.has(key);
};

export function requestLoading(config: any) {
  let path = '';

  try {
    const url = new URL(config.url);
    path = url.pathname.replace('/api/v1', '');
  } catch (e) {
    path = config.url.split('?')[0];
  }

  if (/^\//.test(path)) {
    path = path.replace('/', '');
  }

  let pattern = null;

  for (let i = 0; i < urlsToReasonKeys.length; i++) {
    const patternKey = urlsToReasonKeys[i].split('?')?.[0];
    const regexp = pathToRegexp(patternKey);

    if (regexp.exec(path)) {
      pattern = urlsToReasonKeys[i];
      break;
    }
  }

  // set undefined path
  if (!pattern) {
    if (_config.env === 'development') {
      pattern = path;
      console.log('Loading path:', path);
    } else {
      pattern = '';
    }
  }

  const key = genKey();

  config.loadingURLPattern = {
    key,
    pattern,
  };

  // __skipPreloader: param allow skip display loader
  if (!hasKey(pattern, 'skip') && !config.params?.['__skipPreloader']) {
    store.dispatch && store.dispatch(showLoading(pattern, config.method, key));
  }

  if (config.params?.['__skipPreloader']) {
    delete config.params['__skipPreloader'];
  }

  return config;
}

export function responseLoading(response: any) {
  response.config &&
    store.dispatch &&
    store.dispatch(
      hideLoading(
        response.config.loadingURLPattern.pattern,
        response.config.method,
        response.config.loadingURLPattern.key
      )
    );

  return response;
}

export function responseUnathorized401(response: any) {
  if (response.status === 401 && !response.config.url.includes('/api/v1/profile')) {
    return new Promise((resolve, reject) => {
      store.dispatch(toggleSessionExpired(true));
      store.dispatch(
        addSessionExpiredRequest({
          config: response.config,
          resolve,
          reject,
        })
      );
    });
  }
  return response;
}
