import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import moment from 'moment';
import './index.scss';
import {TAnomalyProps} from '../types';
import {IInitialMapState} from '../../../types';
import {DialogType, toggleDialog} from '_reducers/dialog';

import {formatDate} from '_utils';
import {getPerformanceGeometries} from '../../../utils';

import AnomaliesTablePanel from './anomalies-table-panel';
import {
  exportAnomaliesToShp,
  exportAnomaliesToKml,
  selectedPremiumAnomaliesChangeProp,
} from '../anomalies-utils';

import {startDrawShape} from '../../../actions';
import {bulkUpdateAreasOfInterest} from '../../../actions/areas-of-interest-actions';

import {
  changeLowPerfAnomalyProp,
  updateBulkPremiumAnomaly,
} from 'containers/map/actions/anomalies-actions';

type Props = ConnectedProps<typeof connected> & {
  tabTitle?: string;
};

class AnomaliesPanel extends Component<Props> {
  render() {
    const {isReadOnly, isOnBoardingNow, currentDate, tabTitle, premiumAnomalies} = this.props;

    const {geometryToShow} = getPerformanceGeometries();
    const selectedPremiumAnomalies = premiumAnomalies.filter(a => a.properties.checked);
    const selectedGeometries = geometryToShow.filter(({properties}) => properties.checked);

    return (
      <div className="anomalies-panel">
        <AnomaliesTablePanel
          isReadOnly={isReadOnly && !isOnBoardingNow}
          disabled={!selectedPremiumAnomalies.length && !selectedGeometries.length}
          onAddNew={() => this.props.toggleDialog(DialogType.AddNewAnomaly)}
          onExportShp={() =>
            this.props.exportAnomaliesToShp([...selectedPremiumAnomalies, ...selectedGeometries])
          }
          onExportKml={() =>
            this.props.exportAnomaliesToKml([...selectedPremiumAnomalies, ...selectedGeometries])
          }
          onSnooze={() => this.props.selectedPremiumAnomaliesChangeProp('snoozed')}
          itemList={[...selectedPremiumAnomalies, ...selectedGeometries]}
          snoozeItems={selectedPremiumAnomalies}
          currentDate={moment(currentDate, 'DD/MM/YYYY').format(formatDate())}
          onChangeLabel={this.bulkUpdateProp}
          tabTitle={tabTitle}
        />
      </div>
    );
  }

  bulkUpdateProp = <K extends keyof TAnomalyProps>(prop: K, value: TAnomalyProps[K]) => {
    const {premiumAnomalies, updateBulkPremiumAnomaly, bulkUpdateAreasOfInterest} = this.props;
    const {geometryToShow} = getPerformanceGeometries();
    const selectedPremiumAnomalies = premiumAnomalies.filter(a => a.properties.checked);

    const lowPerfAnomalies = geometryToShow.filter(
      shape => shape.properties && shape.properties.isLowPerf && shape.properties.checked
    );
    const ROI = geometryToShow.filter(
      shape => shape.properties && !shape.properties.isLowPerf && shape.properties.checked
    );

    lowPerfAnomalies.length && this.props.changeLowPerfAnomalyProp(lowPerfAnomalies, prop, value);

    if (ROI.length) {
      bulkUpdateAreasOfInterest(ROI, prop, value);
    }

    if (selectedPremiumAnomalies.length) {
      updateBulkPremiumAnomaly({
        anomalies: selectedPremiumAnomalies,
        prop,
        value,
      });
    }
  };
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: any}) => ({
  currentDate: map.currentDate,
  isReadOnly: map.group.readOnly,
  isOnBoardingNow: login.user.settings.onboarding.quickTour,
  premiumAnomalies: map.premiumAnomalies.list,
  geometry: map.geometry,
});

const connected = connect(mapStateToProps, {
  updateBulkPremiumAnomaly,
  bulkUpdateAreasOfInterest,
  changeLowPerfAnomalyProp,
  exportAnomaliesToShp,
  exportAnomaliesToKml,
  startDrawShape,
  selectedPremiumAnomaliesChangeProp,
  toggleDialog,
});
export default connected(AnomaliesPanel);
