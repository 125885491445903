import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {useSelector} from 'react-redux';
import SelectorDate from 'containers/header/selector-date';
import SelectorLayer from 'containers/header/selector-layer';
import NoData from 'containers/header/no-data';
import {AppStore} from 'reducers';
import {SelectorDateCalendar} from './selector-date-calendar';
import {ModeToggle} from 'containers/map/features/optis/mode-toggle';

const DateAndLayerControls = () => {
  const tab = useSelector((state: AppStore) => state.map.feature);
  const currentDates = useSelector((state: AppStore) => state.map.currentDates);

  if (tab === 'optis') {
    return <ModeToggle />;
  }

  if (tab === 'crop-performance') {
    return <SelectorDateCalendar />;
  }

  if (!Object.keys(currentDates).length) {
    return <NoData />;
  }
  return (
    <>
      <SelectorDate />
      <SelectorLayer />
    </>
  );
};

export default DateAndLayerControls;
