import React from 'react';
import {connect} from 'react-redux';
import {FontIcon} from 'react-md';
import PropTypes from 'prop-types';

import {setId} from './reducer';

const HelpBtn = props => (
  <FontIcon
    title={props.title ? props.title : ''}
    className={`help-popup__btn ${props.className ? props.className : ''}`}
    onClick={props.setId.bind(this, props.id === props.currentPopUpId ? '' : props.id)}
  >
    {props.icon ? props.icon : 'help_outline'}
  </FontIcon>
);

HelpBtn.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

const mapStateToProps = state => ({
  currentPopUpId: state.helpPopup.currentPopUpId,
});

export default connect(mapStateToProps, {setId})(HelpBtn);
