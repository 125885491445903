import {t, FormattedMessage} from 'i18n-utils';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getTrendsData} from '../actions/analytics-actions';
import {usePrevious} from '_hooks';
import {AppStore} from '../../../reducers';

/**
 * The component doesn't have any rendering, it exists only to handle when to call the analytic request.
 */
const AnalyticEffects = (): null => {
  const dispatch = useDispatch();
  const [shouldLoadAnalytic, setShouldLoadAnalytic] = useState(false);

  const feature = useSelector((s: AppStore) => s.map.feature);
  const cloudPercentageCover = useSelector((s: AppStore) => s.map.remoteSensingCloudCover);
  const currentPoints = useSelector(
    (s: AppStore) => s.map.pointsGroups?.[s.map.pointsCurrentGroupDate] || []
  );
  const currentSeason = useSelector((s: AppStore) => s.map.currentSeason);
  const prev = usePrevious({
    currentPoints,
  });

  const onSetShouldLoadAnalytic = (value: boolean) =>
    shouldLoadAnalytic !== value && setShouldLoadAnalytic(value);

  useEffect(() => {
    if (shouldLoadAnalytic && feature === 'analytics') {
      setTimeout(() => {
        // might have several reasons to load the analytic in the same time, so to avoid it the timeout is here
        // - 1. season is set, one trigger. 2. in the new set season there are sampling points, amount of the points is changed it is trigger 2
        onSetShouldLoadAnalytic(false);
        dispatch(getTrendsData());
      }, 500);
    }
  }, [shouldLoadAnalytic, feature]);

  useEffect(() => {
    onSetShouldLoadAnalytic(true);
  }, [cloudPercentageCover]);

  useEffect(
    function checkSamplingPointsForChanges() {
      const pointsAmountOrPositionChanged =
        prev?.currentPoints?.length &&
        !prev?.currentPoints.every(loopPoint => {
          const samePoint = currentPoints.find(p => p.id === loopPoint.id);
          if (!samePoint) return false;
          const sameCoordinates =
            samePoint.geometry.coordinates[0] === loopPoint.geometry.coordinates[0] &&
            samePoint.geometry.coordinates[1] === loopPoint.geometry.coordinates[1];
          return samePoint && sameCoordinates;
        });
      if (prev?.currentPoints?.length !== currentPoints.length || pointsAmountOrPositionChanged) {
        onSetShouldLoadAnalytic(true);
      }
    },
    [currentPoints]
  );
  useEffect(
    function observeSeasonChanges() {
      if (currentSeason.id) {
        onSetShouldLoadAnalytic(true);
      }
    },
    [currentSeason]
  );

  return null;
};

export default AnalyticEffects;
