import {t, FormattedMessage} from 'i18n-utils';
import React, {memo} from 'react';
import cn from 'classnames';
import {AlertIcon} from './status-alert';
import {setOpacity} from '_utils/colors';
import {useSelector} from 'react-redux';
import {AppStore} from 'reducers';

interface Props {
  value: string;
  statusAlert?: string;
  fieldId?: number;
  seasonId?: number;
}

const CropStatus = memo(({fieldId, seasonId, statusAlert, value}: Props) => {
  const info = useSelector((s: AppStore) => s.cropPerformance.info);
  const status = value;
  const color = info?.colours[status];
  const valueStatusClass = `cp__status--${(status + '').toLowerCase().replace(' ', '-')}`;

  return (
    <span
      className={cn('cp__status', valueStatusClass)}
      style={{background: setOpacity(color, 0.1, true)}}
    >
      {t({id: status})}{' '}
      {statusAlert ? (
        <AlertIcon fieldId={fieldId} seasonId={seasonId} message={statusAlert} />
      ) : null}
    </span>
  );
});

export default CropStatus;
