import Server from '../server';
import {JohnDeereRequestPayload} from 'containers/profile/integration/integration-john-deere';

export default class {
  static getOrganizations() {
    return Server.get('sync/johndeere/organizations', {
      params: {__skipPreloader: true},
    });
  }

  static getClients(organizationId: string) {
    return Server.get(`sync/johndeere/organizations/${organizationId}/clients`, {
      params: {__skipPreloader: true},
    });
  }

  static getFarms(organizationId: string, clientId: string) {
    return Server.get(`sync/johndeere/organizations/${organizationId}/clients/${clientId}/farms`, {
      params: {__skipPreloader: true},
    });
  }

  static getFields(organizationId: string, farmId: string) {
    return Server.get(`sync/johndeere/organizations/${organizationId}/farms/${farmId}/fields`, {
      params: {__skipPreloader: true},
    });
  }

  static importData(dataToImport: JohnDeereRequestPayload) {
    return Server.post(`sync/johndeere/import`, dataToImport, {params: {__skipPreloader: true}});
  }

  static getImportStatus(jobId: number) {
    return Server.get(`sync/johndeere/import/${jobId}`, {params: {__skipPreloader: true}});
  }
}
