import React from 'react';
import cn from 'classnames';
import DateAndLayerControls from 'containers/header/date-and-layer-controls';
import {useAppSelector} from '_hooks';
import {isAdmin} from '_utils';
import {DialogType} from '../../../_reducers/dialog';

export default () => {
  const isCompareOn = useAppSelector(s => s.map.isCompareOn);
  const feature = useAppSelector(s => s.map.feature);
  const nRecommendation = useAppSelector(s => s.map.nRecommendation);
  const hasFarms =
    isAdmin() || useAppSelector(s => s.farms.list.length > 0 || s.login.user.groupIds.length > 0);
  const isAddingFields = useAppSelector(s => s.dialog.currentDialog === DialogType.AddNewField);

  const notInteractive =
    isAddingFields ||
    feature === 'carbon' ||
    (feature === 'data-layers' && isCompareOn) ||
    (feature === 'zoning' && nRecommendation.toggled && nRecommendation.method === 'apsim');

  if (notInteractive) {
    return null;
  }

  return (
    hasFarms && (
      <div
        className={cn({
          'top-controls-bar': true,
          'onboarding__selector-dates-layers': true,
        })}
      >
        <div className="observer-date-group ">
          <DateAndLayerControls />
        </div>
      </div>
    )
  );
};
