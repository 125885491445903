import React, {useCallback, useEffect, useMemo} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {validationSchema} from './user-edit.utils';
import {getOne, saveUser, rmUser, loadOrganizations} from '../actions';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {dialogToggle} from 'modules/ui-helpers';
import {history} from 'store';
import {SubscriptionsApi} from '_api';
import ImpersonateButton from '../impersonate-button';
import {User} from '../types';
import {Button, SelectField, SelectionControl, TextField, FontIcon} from 'react-md';
import {genKey} from '_utils';
//@ts-ignore
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DefaultOrganization from './default-organization';
import SelectFarmChips from 'containers/select-farm-chips';
import {MAPPING_ROLES_TO_PROPS} from '_constants';
import AccessView from '../access-view/AccessView';
import {showNote} from '_actions';
import {
  MainTitleBlock,
  ContentWrapper,
  CardBlock,
  CardBlockTwoColumn,
  InlineBlock,
  ConfirmEmailWrapper,
  BackWrapper,
} from './user-edit.styled';
import {Sticky, FluroButton} from 'components';

type FormUserData = User & {
  phoneFullNumber: string;
  isPhoneValid: boolean;
};

const PERMISSIONS = Object.keys(MAPPING_ROLES_TO_PROPS).map(k => ({
  value: parseInt(k, 10),
  label: MAPPING_ROLES_TO_PROPS[k].prop,
}));

const NEW_USER_ID = 'new';

export const UserEdit = ({match}: RouteComponentProps<{userId: string}>) => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const urlUserId = useMemo(() => match.params.userId, [match.params.userId]);

  const currentUserId = useSelector((state: AppStore) => state.login.user.id);

  const {
    handleSubmit,
    formState: {errors},
    register,
    control,
    setValue,
    watch,
    trigger,
  } = useForm<FormUserData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isPhoneValid: true,
    },
  });

  const name = watch('name');
  const surname = watch('surname');
  const email = watch('email');

  useEffect(() => {
    (async () => {
      const user = await getOne(urlUserId)(dispatch);

      register('id');
      register('isPhoneValid');
      register('phoneFullNumber');
      register('settings');

      setValue('id', urlUserId);
      setValue('name', user.name);
      setValue('email', user.email);
      setValue('surname', user.surname);
      setValue('surname', user.surname);
      setValue('surname', user.surname);
      setValue('phone', user?.settings?.phone || '');
      setValue('emailConfirmed', !!user.emailConfirmed);
      setValue('groupIds', user.groupIds);
      setValue('active', user.active);
      setValue('perm', user.perm);
      setValue('password', '');
      setValue('defaultOrganizationID', user.defaultOrganizationID);
      setValue('settings', {...user.settings});
      setValue('phoneFullNumber', user.phone);
    })();
  }, [urlUserId]);

  const isMe = useMemo(() => parseInt(urlUserId, 10) === currentUserId, [urlUserId, currentUserId]);

  const onRemoveUser = useCallback(() => {
    dispatch(
      dialogToggle('confirm', true, {
        okLabel: 'Delete user',
        okAction: () => {
          dispatch(rmUser(parseInt(urlUserId)));
        },

        title: 'Delete user',
        message: 'Are you sure?',
      })
    );
  }, [match.params.userId]);

  const backToList = useCallback(() => {
    history.push('/admin/users/');
  }, []);

  const defaultOrganizationName = useCallback(
    () => `Organization - ${name} ${surname} (${email})`,
    [name, surname, email]
  );

  const createDefaultOrg = async () => {
    // 1 - is default organization id
    const response = await SubscriptionsApi.createOrganization(1, {
      name: defaultOrganizationName(),
    });

    const newCreatedOrgId = response?.data?.result?.id || 0;

    if (newCreatedOrgId) {
      await dispatch(loadOrganizations());
      setValue('defaultOrganizationID', newCreatedOrgId);
    }
  };

  const onSave = useCallback(async data => {
    const phone = data.phoneFullNumber?.replace(/ /g, '');

    if (phone) {
      data.phone = phone;
    }

    await saveUser({
      ...data,
      perm: parseInt(data.perm),
      emailConfirmed: !!data.emailConfirmed,
      settings: {
        ...data.settings,
        phone: data.phone,
      },
    })(dispatch);

    dispatch(
      showNote({
        title: `Success`,
        message:
          data.id === NEW_USER_ID
            ? 'New user created, please contact the user to share the login details'
            : 'User was updated',
        level: 'success',
      })
    );
  }, []);

  return (
    <ContentWrapper>
      <BackWrapper onClick={backToList}>
        <FontIcon>arrow_back</FontIcon>
      </BackWrapper>
      <MainTitleBlock>
        <h3>User details</h3>
        {urlUserId !== NEW_USER_ID && (
          <ImpersonateButton email={email} isProfileView userId={parseInt(urlUserId)} />
        )}
      </MainTitleBlock>

      <form id={`user-edit-form-${id}`} autoComplete="off" onSubmit={handleSubmit(onSave)}>
        <CardBlockTwoColumn>
          <div>
            <Controller
              name="name"
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`name-${id}`}
                  label="First Name"
                  placeholder="First Name"
                  value={value}
                  onChange={onChange}
                  error={!!errors.name}
                  errorText={errors.name?.message}
                  //@ts-ignore
                  autoComplete="no"
                />
              )}
              control={control}
              defaultValue=""
            />

            <Controller
              name="email"
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`email-${id}`}
                  label="Email"
                  placeholder="Email"
                  value={value}
                  disabled={urlUserId !== NEW_USER_ID}
                  onChange={onChange}
                  error={!!errors.email}
                  errorText={errors.email?.message}
                  //@ts-ignore
                  autoComplete="no"
                />
              )}
              control={control}
              defaultValue=""
            />

            <Controller
              name="phone"
              render={({field: {value, onChange}}) => (
                <div className={`custom-phone-input input-half ${errors.phone ? 'error' : ''}`}>
                  <IntlTelInput
                    preferredCountries={['us', 'au']}
                    defaultCountry="au"
                    onPhoneNumberChange={(
                      isValidPhoneNumber: boolean,
                      value: any,
                      params: any,
                      fullValue: string
                    ) => {
                      onChange(value);
                      setValue('phoneFullNumber', fullValue);
                      setValue('isPhoneValid', isValidPhoneNumber);
                      isValidPhoneNumber && trigger('phone');
                    }}
                    autoHideDialCode={true}
                    dropdownContainer="div"
                    value={value}
                    nationalMode={false}
                    autoComplete="no"
                  />
                  {errors.phone ? <div className="error-text">{errors.phone?.message}</div> : null}
                </div>
              )}
              control={control}
              defaultValue=""
            />
          </div>

          <div>
            <Controller
              name="surname"
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`surname-${id}`}
                  label="Surname"
                  placeholder="Surname"
                  value={value}
                  onChange={onChange}
                  error={!!errors.surname}
                  errorText={errors.surname?.message}
                  //@ts-ignore
                  autoComplete="no"
                />
              )}
              control={control}
              defaultValue=""
            />

            <Controller
              name="password"
              render={({field: {value, onChange}}) => (
                <TextField
                  id={`password-${id}`}
                  label="Password"
                  type="password"
                  placeholder="Password"
                  value={value}
                  onChange={onChange}
                  error={!!errors.password}
                  errorText={errors.password?.message}
                  //@ts-ignore
                  autoComplete="new-password"
                />
              )}
              control={control}
              defaultValue=""
            />

            <ConfirmEmailWrapper>
              <Controller
                name="emailConfirmed"
                render={({field: {value, onChange}}) => (
                  <SelectionControl
                    id={`emailConfirmed-${id}`}
                    type="switch"
                    label="Confirm email"
                    name="emailConfirmed"
                    labelBefore={true}
                    checked={!!value}
                    onChange={onChange}
                  />
                )}
                control={control}
              />
            </ConfirmEmailWrapper>
          </div>
        </CardBlockTwoColumn>

        <CardBlock>
          <Controller
            name="defaultOrganizationID"
            render={({field: {value, onChange}}) => (
              <DefaultOrganization
                onSetOrg={onChange}
                defaultOrganizationID={value}
                createDefaultOrg={createDefaultOrg}
                createDefaultOrgName={defaultOrganizationName()}
              />
            )}
            control={control}
            defaultValue=""
          />
        </CardBlock>

        <CardBlock>
          <h3>User's farms and growers</h3>

          <Controller
            name="groupIds"
            render={({field: {value, onChange}}) => (
              <SelectFarmChips
                label="Select or search a farm to add it to the user"
                onSelect={onChange}
                selectedItems={value}
                error={!!errors.groupIds}
              />
            )}
            control={control}
          />
          {errors.groupIds ? (
            <div style={{color: 'red', fontSize: '12px'}}>Farm is required</div>
          ) : null}
        </CardBlock>

        <CardBlock>
          <h3>User's roles and permissions</h3>

          <InlineBlock style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <Controller
              name="perm"
              render={({field: {value, onChange}}) => (
                <SelectField
                  id={`perm-${id}`}
                  label="Permission"
                  style={{width: '300px'}}
                  placeholder="Permission"
                  menuItems={PERMISSIONS}
                  simplifiedMenu={false}
                  value={value}
                  onChange={onChange}
                  error={!!errors.perm}
                  errorText={errors.perm?.message}
                />
              )}
              control={control}
              defaultValue=""
            />
          </InlineBlock>

          <AccessView userId={parseInt(urlUserId)} />
        </CardBlock>

        <Controller
          name="active"
          render={({field: {value, onChange}}) => (
            <SelectionControl
              id={`active-${id}`}
              type="switch"
              label="Active account"
              name="active"
              labelBefore={true}
              checked={!!value}
              onChange={value => onChange(value ? 1 : 0)}
              disabled={isMe}
            />
          )}
          control={control}
          defaultValue=""
        />

        <Sticky>
          <FluroButton className={'close-btn'} blank onClick={backToList} raised>
            Close
          </FluroButton>

          {urlUserId !== NEW_USER_ID && !isMe ? (
            <FluroButton blank raised onClick={onRemoveUser}>
              Delete user
            </FluroButton>
          ) : null}

          <FluroButton raised primary type="submit">
            Save
          </FluroButton>
        </Sticky>
      </form>
    </ContentWrapper>
  );
};
