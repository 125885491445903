import {Dispatch} from 'redux';
import moment from 'moment';
import {
  CropPerformanceFarm,
  CropPerformanceState,
  CSGViewModel,
  FieldsVariability,
  FieldVariabilityStatus,
  RequestCancellationReason,
  CropStressChartData,
  CSGInfo,
  AverageNDVIPayload,
  CropStatusSequence,
  Aggregation,
} from './types';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {RequestStatus} from 'types';
import {AppStore, AppThunkDispatch} from 'reducers';
import {SaveFieldsAction} from 'containers/map/types';
import {ActionTypes as MapActionType} from 'containers/map/reducer/types';
import {GlobalActionTypes, SetGlobalParamAction} from '_reducers/global_types';
import {SeasonApi, AnomaliesApi} from '_api';
import {reportError} from 'containers/error-boundary';
import {calcNdviQuartiles} from './biomass/biomass';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE, SERVER_FORMAT_TIME} from '_constants';
import {getFieldLastFinishedSeason, getFieldSeasons} from '_hooks/use-current-season';
import {getArea, getGetURLParam, toFixedFloat} from '_utils/pure-utils';
import Mixpanel from '_utils/mixpanel-utils';
import {PeriodType} from './helpers/periodic';
import {StressedFieldsPerDate} from '../anomalies/types';
import {loadFields2} from 'containers/map/actions';
import {cropsLimitWarning} from './benchmark-card/helpers';
import {isAdminPerm} from '_utils';
import {populateMissingDates} from './helpers/missing-dates';

const initialState: CropPerformanceState = {
  info: undefined,
  farms: {},
  records: [],
  twoWeeksAgoRecords: [],
  representation: CPFilterType.CROP_TYPE,
  aggregation: Aggregation.COUNT,
  useUnreliableData: false,
  ndviQuartiles: {},
  period: PeriodType.week1,
  fieldsVariability: {},
  stressedFields: {},
  cropStressChartData: [],
  benchmark: {recordsToProcess: {}},
  loaded: false,
};

export default (state = initialState, action: Action): CropPerformanceState => {
  switch (action.type) {
    case ActionType.INFO_LOADED: {
      return {
        ...state,
        info: action.info,
      };
    }
    case ActionType.LOAD_STATUS: {
      return {
        ...state,
        loaded: action.value,
      };
    }

    case ActionType.SET_FARM_CROP_PERFORMANCE: {
      // Cancel the previous request for this farm if the new signal is passed.
      // Use case: changing dates on the same farm.
      if (action.farm.signal) {
        state.farms[action.farm.id]?.signal?.cancel(RequestCancellationReason.NEW_REQUEST);
      }

      const farms = {
        ...state.farms,
        [action.farm.id]: {
          ...state.farms[action.farm.id], // Keep the signal if none is passed.
          ...action.farm,
        },
      };

      return {
        ...state,
        farms,
      };
    }

    /**
     * Should be called when the date is changed.
     */
    case ActionType.SET_RECORDS: {
      return {
        ...state,
        ndviQuartiles: calcNdviQuartiles(action.records),
        records: action.records,
      };
    }

    case ActionType.TWO_WEEKS_AGO_SET_RECORDS: {
      return {
        ...state,
        twoWeeksAgoRecords: action.records,
      };
    }

    case ActionType.TOGGLE_FARMS: {
      // 1. Update farms.
      const newFarms = {...state.farms};
      action.farmIds.forEach(farmId => {
        switch (action.value) {
          case undefined:
            if (newFarms[farmId]) {
              delete newFarms[farmId];
            } else {
              newFarms[farmId] = {
                id: farmId,
                status: RequestStatus.Idle,
              };
            }
            break;
          case true:
            // Only add the farms that are not yet there.
            if (!newFarms[farmId]) {
              newFarms[farmId] = {
                id: farmId,
                status: RequestStatus.Idle,
              };
            }
            break;
          case false:
            delete newFarms[farmId];
            break;
        }
      });

      return {
        ...state,
        farms: newFarms,
      };
    }

    case ActionType.SET_REPRESENTATION:
      return {
        ...state,
        representation: action.representation,
      };

    case ActionType.SET_AGGREGATION:
      return {
        ...state,
        aggregation: action.aggregation,
      };

    case ActionType.SET_USE_UNRELIABLE_DATA:
      return {
        ...state,
        useUnreliableData: action.value,
      };

    case ActionType.SET_PERIOD:
      return {
        ...state,
        period: action.period,
      };

    case MapActionType.MAP_ADD_NEW_FIELD:
      // Reset crop performance for current farm, because new field has been added.
      return {
        ...state,
        farms: {
          ...state.farms,
          [action.farmId]: {
            id: action.farmId,
            status: RequestStatus.Idle,
          },
        },
      };

    case ActionType.SET_STRESSED_FIELDS: {
      return {
        ...state,
        stressedFields: {...state.stressedFields, [action.farmId]: action.stressedFields},
      };
    }

    case MapActionType.MAP_SET_CURRENT_DATE:
      return clearCache(state);

    case ActionType.CLEAR_CACHE:
      return clearCache(state);

    // Change farm.
    case GlobalActionTypes.SET_GLOBAL_PARAM:
      if (action.propName !== 'currentGroupId') {
        return state;
      }
      // When the farm is changed, cancel all the current requests.
      // If the newly selected farm was already loading, don't cancel it.
      Object.values(state.farms).forEach(f => {
        if (f.id !== action.value) {
          f.signal?.cancel(RequestCancellationReason.FARM_CHANGED);
        }
      });
      // And reset all the crop perf farms.
      const farms = state.farms[action.value]
        ? // If we had crop perf for the new farm, keep it.
          {[action.value]: state.farms[action.value]}
        : {
            [action.value]: {
              id: action.value,
              status: RequestStatus.Idle,
            },
          };
      return {
        ...state,
        representation: CPFilterType.CROP_TYPE,
        farms,
        ndviQuartiles: {},
      };

    case ActionType.SET_CROP_STRESS_CHART_DATA:
      return {
        ...state,
        cropStressChartData: action.cropStressChartData,
      };

    case ActionType.ADD_FIELDS_VARIABILITY: {
      return {
        ...state,
        fieldsVariability: {...state.fieldsVariability, ...action.fieldsVariability},
      };
    }
    case ActionType.UPDATE_BENCHMARK_RECORDS: {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          recordsToProcess: {...state.benchmark.recordsToProcess, ...action.records},
        },
      };
    }

    default:
      return state;
  }
};

// Action types.

enum ActionType {
  INFO_LOADED = 'crop-performance/info-loaded',
  SET_FARM_CROP_PERFORMANCE = 'crop-performance/set-farm-crop-performance',
  ADD_FIELDS_VARIABILITY = 'crop-performance/add-fields-variability',
  SET_RECORDS = 'crop-performance/set-records',
  TWO_WEEKS_AGO_SET_RECORDS = 'crop-performance/two-weeks-ago-set-records',
  TOGGLE_FARMS = 'crop-performance/toggle-farms',
  TOGGLE_FILTER = 'crop-performance/toggle-filter',
  CLEAR_FILTER = 'crop-performance/clear-filter',
  CLEAR_ALL_FILTERS = 'crop-performance/clear-all-filters',
  CLEAR_CACHE = 'crop-performance/clear-cache',
  SET_REPRESENTATION = 'crop-performance/set-representation',
  SET_AGGREGATION = 'crop-performance/set-aggregation',
  SET_PERIOD = 'crop-performance/set-period',
  LOAD_STATUS = 'crop-performance/load-status',
  SET_USE_UNRELIABLE_DATA = 'crop-performance/set-use-unreliable-data',

  SET_STRESSED_FIELDS = 'crop-performance/stressed-fields/set',
  SET_CROP_STRESS_CHART_DATA = 'crop-performance/crop-stress-chart-data/set',
  UPDATE_BENCHMARK_RECORDS = 'crop-performance/benchmark.update-records',
}

type Action =
  | InfoLoadedAction
  | CPSetLoadStatusAction
  | ToggleFarmsAction
  | SetFarmCropPerformanceAction
  | SetRecordsAction
  | ClearCacheAction
  | SetRepresentationAction
  | SetAggregationAction
  | SetUseUnreliableData
  | AddFieldMapAction
  | SetCurrentDateMapAction
  | SetGlobalParamAction<'currentGroupId'>
  | SetStressedFields
  | SetCropStressChartDataAction
  | SetPeriodAction
  | AddFieldsVariability
  | UpdateBenchmarkRecords;

type InfoLoadedAction = {
  type: ActionType.INFO_LOADED;
  info: CSGInfo;
};
type CPSetLoadStatusAction = {
  type: ActionType.LOAD_STATUS;
  value: boolean;
};

type ToggleFarmsAction = {
  type: ActionType.TOGGLE_FARMS;
  farmIds: number[];
  value?: boolean;
};

type SetFarmCropPerformanceAction = {
  type: ActionType.SET_FARM_CROP_PERFORMANCE;
  farm: CropPerformanceFarm;
};

type AddFieldsVariability = {
  type: ActionType.ADD_FIELDS_VARIABILITY;
  fieldsVariability: FieldsVariability;
};

type SetRecordsAction = {
  type: ActionType.SET_RECORDS | ActionType.TWO_WEEKS_AGO_SET_RECORDS;
  records: CSGViewModel[];
};

type ClearCacheAction = {
  type: ActionType.CLEAR_CACHE;
};

type SetRepresentationAction = {
  type: ActionType.SET_REPRESENTATION;
  representation: CPFilterType;
};

type SetAggregationAction = {
  type: ActionType.SET_AGGREGATION;
  aggregation: Aggregation;
};

type SetUseUnreliableData = {
  type: ActionType.SET_USE_UNRELIABLE_DATA;
  value: boolean;
};

type AddFieldMapAction = {
  type: MapActionType.MAP_ADD_NEW_FIELD;
  farmId: number;
};

type SetCurrentDateMapAction = {
  type: MapActionType.MAP_SET_CURRENT_DATE;
  date: string;
};

type SetPeriodAction = {
  type: ActionType.SET_PERIOD;
  period: PeriodType;
};

type SetStressedFields = {
  type: ActionType.SET_STRESSED_FIELDS;
  farmId: number;
  stressedFields: StressedFieldsPerDate[];
};

type SetCropStressChartDataAction = {
  type: ActionType.SET_CROP_STRESS_CHART_DATA;
  cropStressChartData: CropStressChartData;
};

type UpdateBenchmarkRecords = {
  type: ActionType.UPDATE_BENCHMARK_RECORDS;
  records: {[seasonId: number]: boolean};
};

// Action implementations.

export const setCPLoadStatus = (value: boolean): CPSetLoadStatusAction => ({
  type: ActionType.LOAD_STATUS,
  value,
});

export const toggleCropPerformanceFarms = (
  farmIds: number[],
  value?: boolean
): ToggleFarmsAction => ({
  type: ActionType.TOGGLE_FARMS,
  farmIds,
  value,
});

export const setFarmCropPerformance = (
  farm: CropPerformanceFarm
): SetFarmCropPerformanceAction => ({
  type: ActionType.SET_FARM_CROP_PERFORMANCE,
  farm,
});

export const addFieldsVariability = (
  fieldsVariability: FieldsVariability
): AddFieldsVariability => ({
  type: ActionType.ADD_FIELDS_VARIABILITY,
  fieldsVariability,
});

export const setRecords = (records: CSGViewModel[]): SetRecordsAction => ({
  type: ActionType.SET_RECORDS,
  records,
});

export const twoWeeksAgoSetRecords = (records: CSGViewModel[]): SetRecordsAction => ({
  type: ActionType.TWO_WEEKS_AGO_SET_RECORDS,
  records,
});

export const clearCropPerformanceCache = (): ClearCacheAction => ({
  type: ActionType.CLEAR_CACHE,
});

export const setRepresentation = (representation: CPFilterType) => (
  dispatch: Dispatch<SetRepresentationAction>,
  getState: () => AppStore
) => {
  if (getState().cropPerformance.representation !== representation) {
    // do not call the reducer if the value is the same
    // Mixpanel
    Mixpanel.cropPerformance_SelectCard(representation);

    dispatch({
      type: ActionType.SET_REPRESENTATION,
      representation,
    });
  }
};

export const setAggregation = (aggregation: Aggregation): SetAggregationAction => ({
  type: ActionType.SET_AGGREGATION,
  aggregation,
});

export const setUseUnreliableData = (value: boolean): SetUseUnreliableData => ({
  type: ActionType.SET_USE_UNRELIABLE_DATA,
  value,
});

export const loadInfo = () => (dispatch: Dispatch<InfoLoadedAction>) => {
  SeasonApi.getCropPerformanceInfo()
    .then(r => {
      dispatch({
        type: ActionType.INFO_LOADED,
        info: r.data,
      });
    })
    .catch(e => reportError('Failed to load Crop performance Info: ' + e));
};

export const loadCropPerformance = () => (
  dispatch: AppThunkDispatch<
    SetFarmCropPerformanceAction | SaveFieldsAction | AddFieldsVariability
  >,
  getState: () => AppStore
) => {
  const state = getState();
  const date = moment(state.map.currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_TIME);
  const farmIds = Object.values(state.cropPerformance.farms)
    .filter(f => [RequestStatus.Idle, RequestStatus.Error].includes(f.status))
    .map(f => f.id);

  farmIds.forEach(async farmId => {
    const farmName =
      state.farms.list.find(f => f.id === farmId)?.name ||
      (state.map.group.id === farmId && state.map.group.name) || // if the list is not there and we're filling the current farm, use it
      '';
    const fields = state.map.fieldsByFarmId[farmId] || (await dispatch(loadFields2(farmId)));

    // Remove this variable when the perf is fixed.
    const DO_NOT_SHOW_VARIABILITY_UNTIL_ITS_PERFORMANCE_IS_FIXED = false;
    if (
      isAdminPerm(state.login.user.perm) &&
      DO_NOT_SHOW_VARIABILITY_UNTIL_ITS_PERFORMANCE_IS_FIXED
    ) {
      const noPrevSeasonFieldIds: number[] = [];
      const fieldsVariabilityRequest = Object.values(fields)
        .map(f => {
          if (state.cropPerformance.fieldsVariability[f.ID]?.value) {
            // We already have variability data for the given field, don't request it again.
            return;
          }
          const season = getFieldLastFinishedSeason(f);
          if (!season) {
            // Don't request variability for the fields that don't have finished seasons yet.
            noPrevSeasonFieldIds.push(f.ID);
            return;
          }
          return {
            md5: f.MD5,
            start_date: season.startDate,
            end_date: season.endDate,
            crop_type: season.cropType,
            key: season.id,
          };
        })
        .filter(x => x);
      if (fieldsVariabilityRequest.length) {
        SeasonApi.getFieldVariabilityScore(fieldsVariabilityRequest)
          .then(({data}) => {
            const preparedData: FieldsVariability = {};

            Object.keys(data).forEach(fieldId => {
              const fieldVariabilityRawObj = data[parseInt(fieldId)];

              if (fieldVariabilityRawObj.var_score == null) {
                preparedData[fieldId] = {
                  value: 0,
                  status: FieldVariabilityStatus.noData,
                };
                return;
              }
              preparedData[fieldId] = {
                value: fieldVariabilityRawObj.var_score,
                status: classifyVariability(fieldVariabilityRawObj.var_score),
              };
            });

            noPrevSeasonFieldIds.forEach(id => {
              preparedData[id] = {
                value: 0,
                status: FieldVariabilityStatus.noData,
              };
            });

            dispatch(addFieldsVariability(preparedData));
          })
          .catch(e => {
            // Don't dispatch a new farm status if the request got canceled by a known reason.
            if (
              e.message === RequestCancellationReason.NEW_REQUEST ||
              e.message === RequestCancellationReason.FARM_CHANGED
            ) {
              return;
            }
            reportError(`Fields variability for farm ${farmId} failed to load: ` + e);
          });
      }
    }

    // For report view only request the relevant seasons that match the date and crop type/variety.
    const targetCropType = getGetURLParam('cropType');
    const targetCropVariety = getGetURLParam('cropVariety');
    const request = Object.values(fields)
      .map(f => {
        const seasons = getFieldSeasons(f, date).filter(
          s =>
            (!targetCropType || targetCropType === s.cropType) &&
            (!targetCropVariety || targetCropVariety === s.params.cropSubType)
        );
        if (!seasons.length) {
          return;
        }

        const csgs = state.cropPerformance.farms[farmId]?.csgs?.[f.ID]?.seasons || {};

        const payload: AverageNDVIPayload[] = seasons
          .filter(season => season.cropType !== 'fallow')
          .map(season => {
            const a = moment(season.startDate).format(SERVER_FORMAT_DATE);
            const b = moment(season.endDate).format(SERVER_FORMAT_DATE);
            const seasonPayload: AverageNDVIPayload = {
              start_date: season.startDate,
              end_date: season.endDate,
              crop_type: season.cropType,
              key: season.id,
            };

            if (csgs[season.id]) {
              const csgsForSeasonAlreadyFetched = Object.keys(csgs[season.id].dates).some(date => {
                return date >= a && date <= b;
              });
              if (csgsForSeasonAlreadyFetched) {
                return null;
              }
            }
            if (season.geometry_id) {
              // use the geometry_id instead of md5 when it is a planting area
              seasonPayload.geometry_id = season.geometry_id;
            } else {
              seasonPayload.md5 = f.MD5;
            }

            return seasonPayload;
          });

        return payload;
      })
      .flat()
      .filter(x => x);

    if (!request.length) {
      return dispatch(
        setFarmCropPerformance({
          id: farmId,
          farmName,
          status: RequestStatus.Skipped,
        })
      );
    }
    dispatch(
      setFarmCropPerformance({
        id: farmId,
        farmName,
        status: RequestStatus.Loading,
      })
    );

    SeasonApi.getCropPerformanceNdviStatus(request)
      .then(r => {
        const csgs: CropPerformanceFarm['csgs'] = {};
        Object.values(fields).forEach(f => {
          const seasons = getFieldSeasons(f, date);
          const seasonsData: {
            [seasonId: string]: {
              sequence: CropStatusSequence;
              dates: {[date: string]: CSGViewModel};
            };
          } = {...state.cropPerformance.farms[farmId]?.csgs?.[f.ID]?.seasons};

          seasons.forEach(season => {
            const seasonId = season.id;

            if (!r.data[seasonId]) {
              // break if there is no the season data
              return;
            }

            const data = r.data[seasonId].data;
            if (!data) {
              console.error(`CSG message: No data for ${f.ID} ${seasonId}`);
              return;
            }
            const sequence = r.data[seasonId].status_seq_name;
            const dates: {[date: string]: CSGViewModel} = {};
            let firstImageryDate = '';
            let lastImageryDate = '';

            const fieldArea = season.geometry
              ? getArea(season.geometry) // calc field area for planting areas
              : state.map.fieldsByFarmId[farmId]?.[f.ID]?.Area; // or use field's area

            Object.keys(data)
              .sort()
              .forEach((d, index, arr) => {
                const [date, time] = d.split('T');
                const prevDate = index - 1 >= 0 ? arr[index - 1].split('T')[0] : null;

                const imagery = time !== '000000';
                lastImageryDate = imagery ? date : lastImageryDate;
                if (!firstImageryDate && imagery) {
                  firstImageryDate = date;
                }

                dates[date] = {
                  farmId,
                  farmName,
                  fieldID: f.ID,
                  fieldName: f.Name,
                  fieldArea,

                  cropType: season.cropType,
                  cropVariety: season.params?.cropSubType,
                  startDate: season.startDate,
                  endDate: season.endDate,
                  seasonId,
                  seasonName: season.name,
                  isPlantingArea: !!season.geometry_id,

                  date, // 20200830
                  lastImageryDate, // 20200830
                  imagery, // is there an imagery for this date or the values are smoothed
                  reliable: true,
                  growth: data[d].crop_growth,
                  cropStatus: data[d].crop_status,
                  smoothSatelliteNdvi: data[d].ndvi_smooth,
                  cumulativeSmoothSatelliteNdvi: toFixedFloat(
                    prevDate
                      ? dates[prevDate].cumulativeSmoothSatelliteNdvi + data[d].ndvi_smooth // prev date + current date
                      : data[d].ndvi_smooth, // means this is the first date, pick the current value
                    2
                  ),
                };
              });

            populateMissingDates(
              season.startDate,
              season.endDate,
              firstImageryDate,
              lastImageryDate,
              dates
            );

            seasonsData[seasonId] = {sequence, dates};
          });

          csgs[f.ID] = {seasons: seasonsData};
        });
        dispatch(
          setFarmCropPerformance({
            id: farmId,
            farmName,
            status: RequestStatus.Success,
            csgs: {...state.cropPerformance.farms[farmId]?.csgs, ...csgs},
          })
        );
      })
      .catch(e => {
        console.error(e);
        dispatch(
          setFarmCropPerformance({
            id: farmId,
            farmName,
            status: RequestStatus.Error,
          })
        );
      });
  });
  return;
};

export const loadStressedFields = () => async (
  dispatch: Dispatch<SetStressedFields>,
  getState: () => AppStore
) => {
  try {
    const state = getState();
    const farmIds = Object.keys(state.cropPerformance.farms).map(Number);
    const result = await Promise.all(farmIds.map(id => AnomaliesApi.getStressedFieldsPerDate(id)));
    result.map(({data}, i) => {
      dispatch({
        type: ActionType.SET_STRESSED_FIELDS,
        farmId: farmIds[i],
        stressedFields: data.result,
      } as SetStressedFields);
    });
  } catch (e) {
    reportError(`loadStressedFields ${e.messasge}`);
  }
};

export const setCropStressChartData = (
  cropStressChartData: CropStressChartData
): SetCropStressChartDataAction => ({
  type: ActionType.SET_CROP_STRESS_CHART_DATA,
  cropStressChartData,
});

export const setPeriod = (period: PeriodType): SetPeriodAction => ({
  type: ActionType.SET_PERIOD,
  period,
});

const clearCache = (state: CropPerformanceState): CropPerformanceState => {
  // Clear crop perf results when the date is changed.
  const newFarms = {...state.farms};
  Object.values(newFarms).forEach(f => {
    f.status = RequestStatus.Idle;
  });
  return {
    ...state,
    farms: newFarms,
    ndviQuartiles: {},
  };
};

const classifyVariability = (value: number): FieldVariabilityStatus => {
  switch (true) {
    case value <= 30:
      return FieldVariabilityStatus.low;
    case value > 30 && value <= 50:
      return FieldVariabilityStatus.med;
    case value > 50:
      return FieldVariabilityStatus.high;
    default:
      reportError(`Incorrect value was provided to classifyVariability() ${value}`);
  }
};

/// BENCHMARK

export const updateBenchmarkRecord = (seasonId: number, value: boolean) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const cropPerformanceBenchmarkRecords = getState().cropPerformance.benchmark.recordsToProcess;
  const numberOfProcessedRecords = Object.keys(cropPerformanceBenchmarkRecords).filter(
    seasonId => cropPerformanceBenchmarkRecords[parseInt(seasonId)] // filter removed crops from the list
  ).length;

  if (value === true && numberOfProcessedRecords >= 16) {
    // limit the number of selected crops to 20 (4 automatically picked + 16 manually)
    dispatch(cropsLimitWarning());
    return;
  }

  dispatch({
    type: ActionType.UPDATE_BENCHMARK_RECORDS,
    records: {[seasonId]: value},
  });
};

export const setRelevantBenchmarkRecords = (records: {[seasonId: number]: boolean}) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const cropPerformanceBenchmarkRecords = getState().cropPerformance.benchmark.recordsToProcess;
  if (
    (!Object.keys(records).length && !Object.keys(cropPerformanceBenchmarkRecords).length) || // prevent recursive adding an empy object
    Object.keys(records).every((
      seasonId // prevent recursive adding the same object
    ) => Object.keys(cropPerformanceBenchmarkRecords).find(searchId => searchId === seasonId))
  ) {
    return;
  }
  dispatch({
    type: ActionType.UPDATE_BENCHMARK_RECORDS,
    records,
  });
};
