import {Dispatch} from 'redux';
import {AppStore} from '../reducers';

/**
 * Responsible for dialogs, modals, popups.
 */

// Reducer.

export enum DialogType {
  Processing, // Explains that farm data is being processing and not ready yet.
  GeometriesNotAllowedToExportToAgx, // Explains that low performing anomalies are not exportable to AgX
  AddNewAnomaly, // open dialog with draw new / upload anomaly (ies)
  AddNewField, // opens draw/upload fields dialogs
  ImportSeasons, //
  IntegrationPlatforms, //
  CarbonSuccessfulSigned, //
}

export type State = {
  currentDialog?: DialogType;
};

const initialState: State = {
  currentDialog: undefined,
};

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.TOGGLE: {
      // use forceValue first
      const currentDialog =
        action.value !== undefined
          ? action.value
            ? action.dialog
            : undefined
          : state.currentDialog !== action.dialog
          ? action.dialog
          : undefined;
      return {
        ...state,
        currentDialog,
      };
    }
    default:
      return state;
  }
};

// Actions.

enum ActionType {
  TOGGLE = 'dialog/toggle',
}

type ToggleAction = {
  type: ActionType.TOGGLE;
  dialog: DialogType;
  value?: boolean;
};

type Action = ToggleAction;

export const toggleDialog = (dialog: DialogType, forceValue?: boolean) => (
  dispatch: Dispatch<ToggleAction>,
  getState: () => AppStore
) => {
  const currentDialog = getState().dialog.currentDialog;
  if (dialog !== currentDialog && forceValue === false) return; // prevent updating state in this case

  return dispatch({
    type: ActionType.TOGGLE,
    dialog,
    value: forceValue,
  });
};

export default reducer;
