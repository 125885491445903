import {Styles} from 'react-joyride';
export const baseStepStyle: Styles = {
  tooltipTitle: {
    borderBottom: '1px solid #dadada',
    fontWeight: 500,
    width: 'calc(100% + 24px)',
    margin: '0 -12px',
    padding: '0 12px 10px',
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  tooltipContent: {
    padding: '15px 0 0',
    fontSize: '15px',
  },
  buttonNext: {
    backgroundColor: '#43a047',
    color: '#fff',
    outline: 'none',
    padding: '7px 18px',
  },

  buttonBack: {
    color: '#666',
  },
  buttonSkip: {
    color: '#666',
  },
};
