import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  toggleFieldCheckbox,
  toggleAllFieldsCheckboxes,
  sortFields,
  toggleSeasonCheckbox,
  toggleAllSeasonsCheckboxes,
  setUpdateSeasonNrxData,
  setFeature,
  setCurrentFieldId,
  selectFieldSeason,
} from '../../../actions';
import {SelectionControl, FontIcon} from 'react-md';
import {
  formatDate,
  formatUnit,
  isObjectPropValuesTheSame,
  naturalSortAlphaNum,
  selectMeasurement,
  sortByDateKey,
  sortFieldsByProp,
  toFixedFloat,
} from '_utils';
import {SeasonUtils} from '../../../utils';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {
  getNrxCropTypeBySubtype,
  getNrxSowingDepthList,
  getNrxCropSubTypeLabel,
  checkSeasonStatus,
  getPrevSeasonMaxHarvestDate,
  NRxSeason,
  getLastAvailableRecommendationDate,
} from '../../../utils/nrx-utils';
import {
  CropInfo,
  CustomCheckbox,
  FluroDatePicker,
  ReadOnly,
  SortButton,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroButton,
  FluroTableBody,
} from 'components';
import {FieldTag, Field, Season} from '../../../types';
import cn from 'classnames';
import {AppStore} from 'reducers';
import {dialogToggle, DialogType} from 'modules/ui-helpers';
import Access from 'containers/access';
import {toggleNRecommendation, updateRecommendationDate} from '../../../actions/zoning-actions';
import {ColumnContentType} from 'components/fluro-table-components/fluro-table-components';

type Props = {
  onlyLatestSeasons: boolean;
  openPopUp: (popName: string, sId: number) => void;
  togglePivotPopUp: (id: number) => void;
  tableNavigationComponent: React.ReactElement;
};

const latLngContainer = (PivotCenterCoordinates: any) => (
  <div className={'coordinates-container'}>
    <div className={'coordinate'}>
      <span className={'label'}>{t({id: 'Lat'})}: &nbsp;</span>
      {toFixedFloat(PivotCenterCoordinates?.Lat, 6)}
    </div>
    <div className={'coordinate'}>
      <span className={'label'}>{t({id: 'Long'})}:</span>
      {toFixedFloat(PivotCenterCoordinates?.Lon, 6)}
    </div>
  </div>
);

const FieldsList = ({
  onlyLatestSeasons,
  tableNavigationComponent,
  togglePivotPopUp,
  openPopUp,
}: Props) => {
  const dispatch = useDispatch();

  const fields = useSelector((state: AppStore) => state.map.fields);
  const farm = useSelector((state: AppStore) => state.map.group);
  const listsData = useSelector((state: AppStore) => state.map.listsData);
  const isReadOnly = useSelector((state: AppStore) => state.map.group.readOnly);
  const measurement = useSelector((state: AppStore) => state.login.user.settings.measurement);
  const sortFieldsProps = useSelector((state: AppStore) => state.map.sortFields);
  const prevCropTypesList = listsData.prevCropTypesList;
  const sortedFields = useMemo(() => {
    const sortedFields = sortFieldsByProp(fields, sortFieldsProps.byProp, sortFieldsProps.sortType);
    return sortFieldsProps.descending ? sortedFields : sortedFields.reverse();
  }, [fields, sortFieldsProps]);

  const tableColumns: {
    title: any;
    id?: string;
    className?: string;
    sortBy?: string;
    sortable?: boolean;
    notAdjusted?: boolean;
    isNrx?: boolean;
    type?: ColumnContentType;
    defaultText?: any;
  }[] = [
    {
      id: 'Field Name',
      title: <FormattedMessage id="Field Name" />,
      className: 'table-column__field-name sticky',
    },
    {
      id: 'Field Area',
      title: <FormattedMessage id="Field Area" />,
      type: 'number',
      sortBy: 'Area',
      sortable: true,
      notAdjusted: true,
      className: 'table-column__field-area sticky d-flex align-center content-right',
    },
    {
      id: 'Region',
      title: <FormattedMessage id="Region" />,
      className: 'table-column__region',
    },
    {
      id: 'Grower',
      title: <FormattedMessage id="Grower" />,
      className: 'table-column__grower',
    },
    {
      id: 'Pivot Field',
      title: <FormattedMessage id="Pivot Field" />,
      className: 'table-column__pivot',
      notAdjusted: true,
    },
    {
      id: 'crop',
      title: (
        <>
          {t({id: 'Crop'})}
          <div className={'table-head-hint'}>{t({id: 'Click icon to select crop'})}</div>
        </>
      ),
      className: 'table-column__crop',
      notAdjusted: true,
    },
    {
      id: 'Name',
      title: <FormattedMessage id="Name" />,
      className: '',
      notAdjusted: true,
    },
    {
      isNrx: true,
      className: 'grey-cell',
      title: (
        <span>
          <FormattedMessage
            id="Recommendation Status"
            values={{
              p: (txt: string) => (
                <>
                  <br />
                  {txt}
                </>
              ),
            }}
          />
        </span>
      ),
      defaultText: <FormattedMessage id="Nrx model not activated." />,
    },
    {
      isNrx: true,
      className: 'md-text-left',
      notAdjusted: true,
      title: (
        <span>
          <FormattedMessage
            id="Crop type Variety"
            values={{
              p: (txt: string) => (
                <>
                  <br />
                  {txt}
                </>
              ),
            }}
          />
        </span>
      ),
    },
    {
      isNrx: true,
      className: 'grey-cell md-text-right',
      title: t({id: 'Irrigation'}),
    },
    {
      isNrx: true,
      className: 'grey-cell md-text-right',
      title: t({id: 'Planting density'}),
    },
    {
      isNrx: true,
      className: 'grey-cell md-text-right',
      title: <FormattedMessage id="Depth" />,
    },
    {
      isNrx: true,
      className: 'grey-cell md-text-right',
      title: <FormattedMessage id="Row spacing" />,
    },
    {
      isNrx: true,
      title: <FormattedMessage id="Soil type" />,
    },
    {
      isNrx: true,
      title: <FormattedMessage id="Soil Depth" />,
    },
    {
      isNrx: true,
      type: 'date',
      className: 'grey-cell',
      title: (
        <span>
          <FormattedMessage
            id="Previous crop type"
            values={{
              p: (txt: string) => (
                <>
                  <br />
                  {txt}
                </>
              ),
            }}
          />
        </span>
      ),
    },
    {
      notAdjusted: true,
      isNrx: true,
      className: 'grey-cell',
      title: <FormattedMessage id="Previous crop harvest" />,
    },
    {
      isNrx: true,
      className: 'grey-cell',
      title: (
        <span>
          <FormattedMessage
            id="Harvest: silage/hay"
            values={{
              p: (txt: string) => (
                <>
                  <br />
                  {txt}
                </>
              ),
            }}
          />
        </span>
      ),
    },
    {
      isNrx: true,
      className: '',
      title: (
        <span>
          <FormattedMessage
            id="Fertilizer application"
            values={{
              p: (txt: string) => (
                <>
                  <br />
                  {txt}
                </>
              ),
            }}
          />
        </span>
      ),
    },
  ];
  const onSortFields = (sortProp: string, sortType: string) => {
    dispatch(sortFields(sortProp, sortType));
  };
  const {
    fieldWithRegion,
    farmGrower,
    fieldWithNRx,
    someSeasonExists,
    allFieldsSelected,
    someSeasonsWithName,
  } = useMemo(
    () => ({
      fieldWithRegion: fields.some(f => f.CountryRegion),
      someSeasonsWithName: fields.some(f => f.Seasons?.some(s => s.name)),
      farmGrower: farm.growerName,
      fieldWithNRx: fields.some(f => f.tags && f.tags.includes(FieldTag.NRx)),
      someSeasonExists: fields.some(f => f.Seasons?.length),
      allFieldsSelected: fields.every(field => field._selected),
    }),
    [fields]
  );

  const allSeasonsChecked = useMemo(
    () =>
      fields.every((f: Field) => {
        return f.Seasons?.length
          ? onlyLatestSeasons
            ? f.Seasons[f.Seasons.length - 1]?._selected
            : f.Seasons.every(s => s._selected)
          : true;
      }),
    [fields, onlyLatestSeasons]
  );

  const onFieldInfo = useCallback((ev: React.MouseEvent<HTMLElement>, field: Field) => {
    ev.stopPropagation();
    dispatch(dialogToggle(DialogType.fieldInfo, true, field));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(sortFields('Name', 'string', true));
    };
  }, []);

  const tableHeaderRow = () => {
    const columns = tableColumns.filter(c => {
      if (!fieldWithRegion && c.id === 'Region') return false;
      if (!fieldWithNRx && c.isNrx) return false;
      if (!someSeasonsWithName && c.id === 'Name') return false;
      return !(!farmGrower && c.id === 'Grower');
    });

    return (
      <FluroTableRow className={'table-row'}>
        <FluroTableColumn adjusted={false} className={'table-column table-column__checkbox sticky'}>
          <SelectionControl
            id="select-all-fields-checkbox"
            type="checkbox"
            label=""
            name="select-all-fields-checkbox"
            inline
            onChange={value => dispatch(toggleAllFieldsCheckboxes(Boolean(value)))}
            checked={allFieldsSelected}
          />
        </FluroTableColumn>

        {columns.map((column, i) => (
          <FluroTableColumn
            key={i}
            className={`table-column ${column.className ? column.className : ''}`}
            adjusted={!column.notAdjusted}
            onClick={column.sortable ? () => onSortFields(column.sortBy, column.type) : null}
            type={column.type}
          >
            {column.title}
            {column.id === 'crop' && someSeasonExists ? (
              <CustomCheckbox
                className={'select-all-seasons'}
                hideText
                isChecked={allSeasonsChecked}
                onCheck={() =>
                  dispatch(toggleAllSeasonsCheckboxes(!allSeasonsChecked, onlyLatestSeasons))
                }
              />
            ) : null}
            {column.sortable && (
              <SortButton
                selected={sortFieldsProps.byProp === column.sortBy}
                descending={sortFieldsProps.descending}
              />
            )}
          </FluroTableColumn>
        ))}
      </FluroTableRow>
    );
  };

  const getNrxSeasonProps = (NrxSeason: NRxSeason, field: Field) => {
    return {
      isCreatedNrxSeason: NrxSeason.nrxSeasonID, // all Nrx season properties are present and saved
      NrxSeasonStatus: checkSeasonStatus(NrxSeason),
      NRxSeasonLabel: getNrxCropTypeBySubtype(NrxSeason.cropVarietyID).label,
      NrxSubtype: getNrxCropSubTypeLabel(NrxSeason.cropVarietyID),
      fertilizersApplied: field.fertilizerApplications?.length,
      NrxLastRecommendationDate: getLastAvailableRecommendationDate(NrxSeason.recommendationDates),
      // adjustedSoilTypesList: fieldsNearestSoilTypesList[NrxSeason.kmlID] || soilTypesList,
    };
  };

  const seeNRxRecommendation = (date: string, season: Season) => {
    dispatch(setCurrentFieldId(season.kmlId));
    dispatch(selectFieldSeason(season)); // user
    dispatch(setFeature('zoning'));
    dispatch(toggleNRecommendation(true, 'apsim')); // toggle NRx
    dispatch(updateRecommendationDate(date));
  };

  const onEditFieldName = useCallback((field: any) => {
    dispatch(dialogToggle(DialogType.editFieldName, true, {field, farmId: farm.id}));
  }, []);

  const sortSeasons = useCallback((seasons: Season[]) => {
    // check if all planting area seasons
    // has the same sowing date - sort by planting area name
    const pantingAreaSeasons = seasons.filter(s => s.geometry_id);
    if (pantingAreaSeasons.length && isObjectPropValuesTheSame(pantingAreaSeasons, 'startDate')) {
      return naturalSortAlphaNum(seasons, 'name');
    }

    return sortByDateKey(seasons, 'startDate', true);
  }, []);

  const tableBodyRows = () => {
    return sortedFields
      .map(field => {
        const {
          CountryRegion,
          Area,
          Name,
          MD5,
          ID,
          Seasons = [],
          Pivot,
          PivotCenterCoordinates,
          Country,
        } = field;
        const isAustralian = Country === 'Australia';

        const seasonsList = onlyLatestSeasons
          ? Seasons.length
            ? [SeasonUtils.getLastSeason(Seasons)]
            : []
          : [...Seasons];

        return seasonsList.length ? (
          sortSeasons(seasonsList).map((_s: Season, index: number) => {
            const startDate = moment(_s.startDate, GLOBAL_FORMAT_DATE);
            const endDate = moment(_s.endDate, GLOBAL_FORMAT_DATE);
            const isFirstSeasonPerField = index === 0; // used to prevent duplicates of field props for each season row
            const NrxSeason = _s.nrx;
            const {
              isCreatedNrxSeason = false,
              NrxSeasonStatus = null,
              NRxSeasonLabel = null,
              NrxSubtype = null,
              // adjustedSoilTypesList = [],
              fertilizersApplied = false,
              NrxLastRecommendationDate = '',
            } = NrxSeason ? getNrxSeasonProps(NrxSeason, field) : {};
            const automaticSoilSelection =
              isAustralian || NrxSeason?.soilTypeLabelID === 'Automatic soil selection';

            const onClickPlantingAction = () => openPopUp('plantingSoilPopUpOpen', _s.id);

            const hiddenBorder =
              field.Seasons.length > 1 && index + 1 !== field.Seasons.length ? 'hide-b-border' : '';
            const whiteB = !isFirstSeasonPerField ? 'white-background' : '';

            return (
              <FluroTableRow key={`${_s.id}`} className={'table-row'}>
                {isFirstSeasonPerField ? (
                  <FluroTableColumn
                    adjusted={false}
                    className={`table-column table-column__checkbox ${hiddenBorder}`}
                  >
                    <SelectionControl
                      id={`sel-file-sel-all-table-${MD5}`}
                      type="checkbox"
                      label=""
                      name="Select-kml-file-table"
                      className={'on-boarding-select-field'}
                      onChange={onRowToggle.bind(this, ID)}
                      checked={field._selected}
                      defaultChecked={false}
                    />
                  </FluroTableColumn>
                ) : (
                  <FluroTableColumn
                    children={null}
                    className={`table-column table-column__checkbox ${hiddenBorder} ${whiteB}`}
                  />
                )}

                <FluroTableColumn // Field name
                  adjusted={false}
                  className={`table-column table-column__field-name ${hiddenBorder} ${whiteB}`}
                >
                  {isFirstSeasonPerField && (
                    <div className="wtv-field-name global-link">
                      <Access admin>
                        <FontIcon className="info-icon" onClick={ev => onFieldInfo(ev, field)}>
                          info
                        </FontIcon>
                      </Access>
                      <div
                        className={'field-name'}
                        title={Name}
                        onClick={() => onEditFieldName(field)}
                      >
                        {Name}
                        {Pivot && (
                          <span title={t({id: 'Pivot field'})} className={'field-name-pivot-icon'}>
                            &#x25D4;
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </FluroTableColumn>

                <FluroTableColumn // field area
                  adjusted={false}
                  type={'number'}
                  className={`table-column table-column__field-area ${hiddenBorder} ${whiteB}`}
                >
                  {isFirstSeasonPerField && (selectMeasurement(Area) || '-')}
                </FluroTableColumn>

                {fieldWithRegion && (
                  <FluroTableColumn // region
                    adjusted={false}
                    title={CountryRegion}
                    className={`table-column table-column__region ${hiddenBorder} ${whiteB}`}
                  >
                    {isFirstSeasonPerField && (CountryRegion || '-')}
                  </FluroTableColumn>
                )}
                {!!farmGrower && (
                  <FluroTableColumn // grower
                    adjusted={false}
                    className={`table-column table-column__grower ${hiddenBorder} ${whiteB}`}
                  >
                    {isFirstSeasonPerField && (farmGrower || '-')}
                  </FluroTableColumn>
                )}

                <FluroTableColumn // pivot
                  adjusted={false}
                  className={`table-column table-column__pivot ${hiddenBorder} ${whiteB}`}
                >
                  {isFirstSeasonPerField && (
                    <div className={'container'}>
                      {Pivot ? 'Yes' : 'No'}
                      {Pivot ? latLngContainer(PivotCenterCoordinates) : null}

                      <ReadOnly>
                        <FluroButton
                          className={'table-edit-button'}
                          onClick={() => togglePivotPopUp(field.ID)}
                          icon
                          iconEl={<FontIcon>edit</FontIcon>}
                        />
                      </ReadOnly>
                    </div>
                  )}
                </FluroTableColumn>

                <FluroTableColumn
                  adjusted={false}
                  className={'table-column table-column__crop-info'}
                >
                  <CropInfo // crop
                    onClick={() => dispatch(toggleSeasonCheckbox(_s.id, !_s._selected))}
                    checked={_s._selected}
                    cropType={_s.cropType}
                    cropSubType={_s.params && _s.params.cropSubType}
                    startDate={startDate.format(formatDate())}
                    endDate={endDate.format(formatDate())}
                    hasGeometry={!!_s.geometry_id}
                  />
                </FluroTableColumn>

                {someSeasonsWithName && <FluroTableColumn>{_s.name}</FluroTableColumn>}

                {fieldWithNRx ? (
                  NrxSeason ? (
                    <>
                      <FluroTableColumn
                        adjusted={false}
                        className={'table-column table-column__status grey-cell'}
                      >
                        <div
                          onClick={
                            NrxLastRecommendationDate
                              ? () => seeNRxRecommendation(NrxLastRecommendationDate, _s)
                              : null
                          }
                        >
                          {NrxSeasonStatus}
                        </div>
                      </FluroTableColumn>

                      <FluroTableColumn adjusted={false}>
                        <div
                          onClick={() => openPopUp('matchCropPopUpOpen', _s.id)}
                          className={'table-column table-column__match-crop'}
                        >
                          <div className={'crop'}>
                            {NRxSeasonLabel}
                            {NrxSubtype ? <span className={'variety'}> - {NrxSubtype}</span> : null}
                          </div>
                          {!NRxSeasonLabel && (
                            <div className={'table-edit-button-label'}>Select</div>
                          )}
                        </div>
                      </FluroTableColumn>

                      <FluroTableColumn className={'grey-cell'}>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {NrxSeason.irrigated ? t({id: 'Yes'}) : t({id: 'No'})}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn type={'number'} className={'grey-cell'}>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {NrxSeason.sowingDensity ? (
                              <>
                                {NrxSeason.sowingDensity} {formatUnit(measurement, 'plants/m²')}
                              </>
                            ) : (
                              'Select'
                            )}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn type={'number'} className={'grey-cell'}>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {NrxSeason.sowingDepth ? (
                              <>
                                {NrxSeason.sowingDepth} {formatUnit(measurement, 'mm')}
                              </>
                            ) : (
                              t({id: 'Select'})
                            )}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn type={'number'} className={'grey-cell'}>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {NrxSeason.rowSpacing ? (
                              <>
                                {NrxSeason.rowSpacing} {formatUnit(measurement, 'mm')}
                              </>
                            ) : (
                              t({id: 'Select'})
                            )}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn nowrap>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {NrxSeason.soilTypeLabelID
                              ? NrxSeason.soilTypeLabelID
                              : t({id: 'Select'})}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn nowrap>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {getNrxSowingDepthList(
                              NrxSeason.soilTypeLabelID,
                              automaticSoilSelection
                            ).find(
                              item =>
                                item.value === (automaticSoilSelection ? -1 : NrxSeason.soilTypeID)
                            )?.label || t({id: 'Select'})}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn className={'grey-cell'}>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {prevCropTypesList.find(item => item.value === NrxSeason.prevCropTypeID)
                              ?.label || t({id: 'Select'})}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn
                        adjusted={false}
                        className={'table-column table-column__previous-crop-harvest grey-cell'}
                        type={'date'}
                      >
                        {NrxSeason.prevHarvestDate && (
                          <ReadOnly>
                            <FluroDatePicker
                              id={`prev-crop-harvest-${_s.id}`}
                              label={''}
                              hideFormat
                              onChange={(value: any) =>
                                onChangeSeasonProp(_s.id, {prevHarvestDate: value.format()})
                              }
                              selected={moment(NrxSeason.prevHarvestDate, GLOBAL_FORMAT_DATE)}
                              onChangeRaw={(ev: any) => ev.preventDefault()}
                              maxDate={moment(
                                getPrevSeasonMaxHarvestDate(_s.startDate, true),
                                GLOBAL_FORMAT_DATE
                              )}
                            />
                          </ReadOnly>
                        )}
                      </FluroTableColumn>

                      <FluroTableColumn className={'grey-cell'}>
                        <ReadOnly>
                          <div className={'cursor-pointer'} onClick={() => onClickPlantingAction()}>
                            {NrxSeason.haySilage ? t({id: 'Yes'}) : t({id: 'No'})}
                          </div>
                        </ReadOnly>
                      </FluroTableColumn>

                      <FluroTableColumn
                        adjusted={false}
                        className={cn(
                          'table-column table-column__fertilizer',
                          {
                            'active-disabled': !isCreatedNrxSeason && !isReadOnly,
                          } // cause even disabled this element has an action, see onClick
                        )}
                      >
                        <div>{fertilizersApplied ? t({id: 'Yes'}) : t({id: 'No'})}</div>
                        <ReadOnly>
                          <FluroButton
                            className={'table-edit-button'}
                            onClick={() =>
                              isCreatedNrxSeason
                                ? openPopUp('fertilizerPopUpOpen', _s.id)
                                : alert(t({id: 'Please set your season data before'}))
                            }
                            icon
                            iconEl={<FontIcon>edit</FontIcon>}
                          />
                        </ReadOnly>
                      </FluroTableColumn>
                    </>
                  ) : (
                    tableColumns
                      .filter(c => c.isNrx)
                      .map((c, i) => (
                        <FluroTableColumn
                          key={i}
                          adjusted={false}
                          className={`table-column ${c.defaultText ? 'default-text' : ''}`}
                        >
                          {c.defaultText ? c.defaultText : ''}
                        </FluroTableColumn>
                      ))
                  )
                ) : null}
              </FluroTableRow>
            );
          })
        ) : (
          <FluroTableRow key={field.ID} className={'table-row'}>
            <FluroTableColumn adjusted={false} className={'table-column table-column__checkbox'}>
              <SelectionControl
                id={`sel-file-sel-all-table-${MD5}`}
                type="checkbox"
                label=""
                name="Select-kml-file-table"
                className={'on-boarding-select-field'}
                onChange={onRowToggle.bind(this, ID)}
                checked={field._selected}
                defaultChecked={false}
              />
            </FluroTableColumn>

            <FluroTableColumn // Field name
              adjusted={false}
              onClick={() => onEditFieldName(field)}
              className={`table-column table-column__field-name`}
            >
              <div className="wtv-field-name global-link">
                <Access admin>
                  <FontIcon className="info-icon" onClick={ev => onFieldInfo(ev, field)}>
                    info
                  </FontIcon>
                </Access>
                <div className={'field-name'} title={Name} onClick={() => onEditFieldName(field)}>
                  {Name}
                  {Pivot && (
                    <span title={t({id: 'Pivot field'})} className={'field-name-pivot-icon'}>
                      &#x25D4;
                    </span>
                  )}
                </div>
              </div>
            </FluroTableColumn>

            <FluroTableColumn // field area
              adjusted={false}
              type={'number'}
              className={`table-column table-column__field-area`}
            >
              {selectMeasurement(Area)}
            </FluroTableColumn>

            {fieldWithRegion && (
              <FluroTableColumn adjusted={false} className={'table-column table-column__region'}>
                {CountryRegion || '-'}
              </FluroTableColumn>
            )}

            {!!farmGrower && (
              <FluroTableColumn adjusted={false} className={'table-column table-column__grower'}>
                {farmGrower || '-'}
              </FluroTableColumn>
            )}

            <FluroTableColumn // pivot
              adjusted={false}
              className={`table-column table-column__pivot`}
            >
              <div className={'container'}>
                {Pivot ? t({id: 'Yes'}) : t({id: 'No'})}
                {Pivot ? latLngContainer(PivotCenterCoordinates) : null}

                <ReadOnly>
                  <FluroButton
                    className={'table-edit-button'}
                    onClick={() => togglePivotPopUp(field.ID)}
                    icon
                    iconEl={<FontIcon>edit</FontIcon>}
                  />
                </ReadOnly>
              </div>
            </FluroTableColumn>

            <FluroTableColumn adjusted={false} className={'table-column table-column__crop-info'}>
              -
            </FluroTableColumn>
            {fieldWithNRx &&
              tableColumns
                .filter(c => c.isNrx)
                .map((_, i) => <FluroTableColumn children={null} key={i} adjusted={false} />)}
          </FluroTableRow>
        );
      })
      .flat(1);
  };

  const onRowToggle = (fieldId: number | string, value: boolean) => {
    dispatch(toggleFieldCheckbox(fieldId, value));
  };

  const onChangeSeasonProp = (seasonId: number, data: any) => {
    if (data.soilTypeLabelID)
      data.soilTypeID = getNrxSowingDepthList(data.soilTypeLabelID)[0].value;
    dispatch(setUpdateSeasonNrxData(seasonId, data));
  };

  if (!fields.length) {
    return <div className={'no-fields'}>{t({id: 'No fields, yet'})}</div>;
  }

  return (
    <>
      {tableNavigationComponent}
      <FluroDataTable className={'fields-table'} baseId={'farm-table-view'}>
        <FluroTableHeader>{tableHeaderRow()}</FluroTableHeader>
        <FluroTableBody>{tableBodyRows()}</FluroTableBody>
      </FluroDataTable>
      {/*<Table*/}
      {/*  hideScrollButtons*/}
      {/*  dataTableClassName={'fields-table'}*/}
      {/*  tableHeaderRow={tableHeaderRow()}*/}
      {/*  tableBodyRows={tableBodyRows()}*/}
      {/*  tableNavigationComponent={tableNavigationComponent}*/}
      {/*/>*/}
    </>
  );
};

export default FieldsList;
