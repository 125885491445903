import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Wrapper,
  CropItemWrapper,
  SelectedTitle,
  ZoneTableColumn,
  Line,
} from './productivity-map.styled';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {
  CropInfo,
  FluroDataTable,
  FluroTableHeader,
  FluroTableRow,
  FluroButton,
  FluroTableBody,
} from 'components';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {convertFromSquareMetersToMeasure, formatDate, sortDates, toFixedFloat} from '_utils';
import {EditSelectedSeasonsListDialog} from './edit-selected-seasons-list-dialog';
import {AsyncStatusType, dialogToggle, DialogType, Status} from 'modules/ui-helpers';
import {
  toggleProductivityMap,
  fetchData,
  PRODUCTIVITY_MAP_ASSETS_HOST,
  populateReachedPeakCanopyData,
} from './productivity-map.module';
import {DownloadIcon} from '../../../icons';
import turfArea from '@turf/area';
import {FormattedMessage, t} from 'i18n-utils';

export const ProductivityMap = () => {
  const dispatch = useDispatch();
  const currentSeasonId = useSelector((state: AppStore) => state.map.currentSeasonId);
  const field = useSelector((state: AppStore) => state.map.field);
  const measurement = useSelector((state: AppStore) => state.login.user.settings.measurement);
  const currentSensor = useSelector((state: AppStore) => state.map.currentSensor).toLowerCase();
  const _seasons = useSelector((state: AppStore) => state.map.field.Seasons);
  const productivityMapData = useSelector(
    (state: AppStore) => state.productivityMap.data[state.productivityMap.currentCacheKey]
  );
  const isLoading = useSelector(
    (state: AppStore) =>
      state.uiHelpers.asyncStatuses[AsyncStatusType.productivityMap].status === Status.Pending
  );

  const currentGroupId = useSelector((state: AppStore) => state.global.currentGroupId);
  const farms = useSelector((state: AppStore) => state.farms.list);

  const farm = useMemo(() => farms.find(f => f.id === currentGroupId), [farms, currentGroupId]);

  const seasons = useMemo(() => {
    return populateReachedPeakCanopyData(_seasons, true);
  }, [_seasons]);

  const totalArea = useMemo(
    () => (productivityMapData?.geoJsonData ? turfArea(productivityMapData.geoJsonData) : 0),
    [productivityMapData]
  );

  const [selectedSeasonsIdsObj, setSelectedSeasonsIds] = useState<{[key: number]: boolean}>({
    [currentSeasonId]: true,
  });

  const selectedSeasonsIds = useMemo(() => {
    return Object.keys(selectedSeasonsIdsObj)
      .filter(k => selectedSeasonsIdsObj[parseInt(k)])
      .map(id => parseInt(id));
  }, [selectedSeasonsIdsObj]);

  const prodMapDates = useMemo(() => {
    const dates = sortDates(
      seasons
        .filter(s => selectedSeasonsIds.includes(s.id))
        .map(s => [s.startDate, s.endDate])
        .flat(),
      GLOBAL_FORMAT_DATE
    );

    if (dates.length >= 2) {
      return [
        moment(dates[0]).format(GLOBAL_FORMAT_DATE),
        moment(dates[dates.length - 1]).format(GLOBAL_FORMAT_DATE),
      ];
    } else {
      console.warn('Cannot calc productivity map for less than two dates');
      return [];
    }
  }, [selectedSeasonsIds, seasons]);

  useEffect(() => {
    dispatch(toggleProductivityMap(true));

    dispatch(
      fetchData(
        field?.MD5,
        currentSensor,
        prodMapDates[0],
        prodMapDates[1],
        `Productivity_zone_${farm.name.replaceAll(' ', '_')}_${field.Name.replaceAll(
          ' ',
          '_'
        )}_${currentSensor}`
      )
    );

    return function offProductivityMap() {
      dispatch(toggleProductivityMap(false));
    };
  }, [prodMapDates, currentSensor, field?.MD5]);

  const onEdit = useCallback(
    () =>
      dispatch(
        dialogToggle(DialogType.editSelectedSeasonsProductivityMap, true, {
          selectedIds: selectedSeasonsIds,
          setSelected: setSelectedSeasonsIds,
        })
      ),
    [selectedSeasonsIds, setSelectedSeasonsIds]
  );

  const exportShp = useCallback(() => {
    window.open(PRODUCTIVITY_MAP_ASSETS_HOST + productivityMapData.shapefile);
  }, [productivityMapData]);

  return (
    <>
      <Wrapper>
        <div>
          <SelectedTitle>
            <FormattedMessage
              id="Crops selected"
              defaultMessage="{count, plural, one {Crop} other {Crops}} selected"
              values={{count: seasons.length}}
            />
          </SelectedTitle>

          {seasons
            .filter(s => selectedSeasonsIds.includes(s.id))
            .map(({isReachedPeakCanopy, id, cropType, startDate, endDate, geometry, params}) => (
              <CropItemWrapper key={`season-${id}`}>
                <CropInfo
                  cropType={cropType}
                  cropSubType={params?.cropSubType}
                  startDate={getCropDate(startDate)}
                  endDate={getCropDate(endDate)}
                  message={
                    isReachedPeakCanopy
                      ? ''
                      : t({
                          id:
                            "This season's crop hasn't yet reached peak green biomass, which may impact the accuracy of the productivity map reflecting fields' yield potential.",
                        })
                  }
                />
              </CropItemWrapper>
            ))}
        </div>

        {seasons.length >= 2 ? (
          <div className="buttons-block line">
            <FluroButton readonly className="element-full-width" raised onClick={onEdit}>
              {t({id: 'Edit Crop Selection'})}
            </FluroButton>
            <Line />
          </div>
        ) : null}

        {productivityMapData && !isLoading ? (
          <div className="zones-container element-full-width">
            <h3 className="zones-title">
              <FormattedMessage id="Management zones" defaultMessage="Management zones" />
            </h3>

            <FluroDataTable elevated={false} className="zones-table">
              <FluroTableHeader>
                <FluroTableRow>
                  <ZoneTableColumn>{t({id: 'Name'})}</ZoneTableColumn>
                  <ZoneTableColumn nowrap type={'number'} numeric>
                    <div>{t({id: 'Area'})}</div> <span>({t({id: measurement})})</span>
                  </ZoneTableColumn>
                  <ZoneTableColumn nowrap type={'number'} numeric>
                    <div>{t({id: 'Area'})}</div> <span>(%)</span>
                  </ZoneTableColumn>
                  <ZoneTableColumn type={'number'} className="index-avg" numeric>
                    {currentSensor}
                  </ZoneTableColumn>
                </FluroTableRow>
              </FluroTableHeader>
              <FluroTableBody>
                {productivityMapData.geoJsonData.features.map(({properties}) => (
                  <FluroTableRow key={properties.color}>
                    <ZoneTableColumn>
                      <div className="name" style={{backgroundColor: properties.color}}>
                        {properties.name}
                      </div>
                    </ZoneTableColumn>
                    <ZoneTableColumn type={'number'} numeric>
                      {toFixedFloat(
                        convertFromSquareMetersToMeasure(properties.area, measurement),
                        1
                      )}
                    </ZoneTableColumn>
                    <ZoneTableColumn type={'number'} numeric className="font300">
                      {toFixedFloat((properties.area / totalArea) * 100, 0)}%
                    </ZoneTableColumn>
                    <ZoneTableColumn type={'number'} numeric>
                      {toFixedFloat(properties?.[currentSensor + '_avg'] || 0)}
                    </ZoneTableColumn>
                  </FluroTableRow>
                ))}
              </FluroTableBody>
            </FluroDataTable>
          </div>
        ) : null}

        {productivityMapData && !isLoading ? (
          <div className="buttons-block">
            <FluroButton
              readonly
              className="btn-with-icon element-full-width"
              raised
              primary
              onClick={exportShp}
              iconEl={<DownloadIcon />}
            >
              {t({id: 'Export {index}'}, {index: 'SHP'})}
            </FluroButton>
          </div>
        ) : null}
      </Wrapper>

      <EditSelectedSeasonsListDialog />
    </>
  );
};

export const getCropDate = (date: string) => moment(date, GLOBAL_FORMAT_DATE).format(formatDate());
