import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {TLayersSource} from './types';

import './hacks/increase-tiles-by-1-px';

export const access_token =
  'pk.eyJ1IjoiZ3JlYXRvem1lbiIsImEiOiJjaXNqemZkZ2cwMDNtMnNuNTI4bnY2YXR5In0.uqqfcJnOLENaCge8RTlGJA';
const MAP_TILE_URL = `https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token=${access_token}`;

type Props = {
  trackResize: boolean;
  onRefMap?: (node: any) => void;
  layersSource: TLayersSource;
  leafletElement: L.Map;
  className?: string;
  hasFarms?: boolean;
};

export const TiledMap = ({
  children,
  layersSource,
  trackResize,
  onRefMap,
  leafletElement,
  className = '',
  hasFarms = false,
}: React.PropsWithChildren<Props>) => {
  useEffect(() => {
    if (layersSource === 'google') {
      // dirty huck to resolve the FSB-3299,3280 (google map needs to be zoomed to display the tiles)
      setTimeout(() => leafletElement.fire('zoomend'), 1000);
    }
  }, [layersSource]);
  return (
    <MapContainer
      zoomControl={false}
      trackResize={trackResize}
      cursor={true}
      id="fluro-map"
      editable={true}
      whenCreated={onRefMap}
      className={`map-v2__main ${className}`}
      // central park - NY
      center={[0, 0]}
      keyboard={false}
      zoom={hasFarms ? 14 : 3}
      // zoomDelta - https://regrow.atlassian.net/browse/FSB-5047
      zoomDelta={1.1}
      zoomSnap={0}
    >
      {layersSource === 'google' ? (
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyAvzAOadSua8fIzgBHaXlCht80zt42lP9I"
          type={'satellite'}
        />
      ) : (
        <TileLayer
          attribution="FluroSat"
          id="satellite-streets-v9"
          url={MAP_TILE_URL}
          maxZoom={20}
          subdomains={['otile1', 'otile2', 'otile3', 'otile4']}
        />
      )}
      {children}
    </MapContainer>
  );
};
