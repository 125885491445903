import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo, memo} from 'react';
import {getCurrentAppHost} from '_utils';
import Mixpanel from '_utils/mixpanel-utils';

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  external?: boolean; // if external the app host will not be added;
  blank?: boolean; // open in new tab;
  map?: boolean; // if direct to the farm or field
  onClick?(val: any): void;
};

/*
 *
 * The main reason of the component
 * to make the creating of non redux Links more declarative
 * without confused host concatenations in the HREF prop
 *
 * EXAMPLE: <Ln map blank href="24">AusCot</Ln>
 *
 * */
const Ln = memo((props: Props) => {
  const {external, map, blank, ...rest} = props;

  const href = useMemo(() => {
    if (external) {
      return props.href;
    }

    if (map) {
      return getCurrentAppHost() + '/maps/' + props.href;
    }

    return getCurrentAppHost() + props.href;
  }, [props.href]);

  const handleClick = (ev: any) => {
    rest.onClick && rest.onClick(ev);
    if (href.includes('help.flurosense.com')) {
      Mixpanel.openKnowledgeBase(href);
    }
  };

  return (
    <a {...rest} onClick={handleClick} href={href} {...(blank ? {target: '_blank'} : {})}>
      {props.children}
    </a>
  );
});

export default Ln;
