import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import moment from 'moment';

import AnomalyItem from 'components/anomalies-ui/anomaly-item';
import AnomalyLabelsDropdown from './labels-dropdown';

import {formatDate} from '_utils';

import {getPerformanceGeometries} from '../../utils';
import {userFacingArea} from './anomalies-utils';
import {togglePopup} from '../../actions';
import {toggleEditAreaOfInterest} from '../../actions/areas-of-interest-actions';

import {
  toggleLowPerformingAreas,
  changeAnyTypeGeometryProp,
  toggleAnomaliesHistory,
} from '../../actions/anomalies-actions';

import {IAnomaly} from './types';
import {IInitialMapState} from '../../types';

type Props = ConnectedProps<typeof connector>;

class AreasHistoryList extends Component<Props> {
  render() {
    const {wholeTableView, historyOpen} = this.props;
    const {historyGeometries} = getPerformanceGeometries();
    const formattedCurrentDate = moment
      .utc(this.props.currentDate, 'DD/MM/YYYY')
      .format(formatDate());

    return (
      <>
        {historyGeometries.length ? (
          <div className="anomalies-history">
            <div
              onClick={() => this.props.toggleAnomaliesHistory(!historyOpen)}
              className={`anomalies-history__btn ${historyOpen ? 'opened' : ''}`}
            >
              {t({id: 'Areas history', defaultMessage: 'Areas history'})}
            </div>
            <div className="anomalies-history__subtitle">
              {t(
                {id: 'Not detected on', defaultMessage: 'Not detected on {formattedCurrentDate}'},
                {formattedCurrentDate}
              )}
            </div>
            {(historyOpen || wholeTableView) && (
              <div className="anomalies-history__list">
                {historyGeometries.map((anomaly, i) => this.getAnomalyItem(anomaly, i))}
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  }

  getAnomalyItem = (anomaly: IAnomaly, i: number) => {
    const {
      isReadOnly,
      measurement,
      toggleEditAreaOfInterest,
      toggleLowPerformingAreas,
      openPopupId,
      togglePopup,
    } = this.props;
    const {
      area,
      checked,
      endDate,
      startDate,
      label,
      title,
      id,
      priority,
      isLowPerf,
    } = anomaly.properties;
    return (
      <AnomalyItem
        label={
          <AnomalyLabelsDropdown
            disabled={isReadOnly}
            anomaly={anomaly}
            label={label}
            miniButton
            onChange={(prop: any, value: any, geometry: IAnomaly) =>
              this.props.changeAnyTypeGeometryProp(geometry, prop, value)
            }
          />
        }
        isChecked={checked}
        area={userFacingArea(area, measurement)}
        key={`${id}-${i}`}
        startDate={startDate}
        labelName={label}
        endDate={endDate}
        title={title}
        openPopUp={openPopupId === id}
        onCheck={(value: any) => this.props.changeAnyTypeGeometryProp(anomaly, 'checked', value)}
        onView={() => togglePopup(id)}
        onEdit={() =>
          isLowPerf
            ? toggleLowPerformingAreas(true, anomaly)
            : toggleEditAreaOfInterest(true, anomaly)
        }
        isInHistory
        priority={priority}
      />
    );
  };
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: any}) => ({
  measurement: login.user.settings.measurement,
  currentDate: map.currentDate,
  isReadOnly: map.group.readOnly,
  isOnBoardingNow: login.user.settings.onboarding.fullTour,
  wholeTableView: map.wholeTableViewOpen,
  geometry: map.geometry,
  lowPerfAnomalies: map.lowPerfAnomalies.list,
  geometriesOnMap: map.geometriesOnMap,
  openPopupId: map.openPopupId,
  historyOpen: map.anomalies.historyOpen,
});

const connector = connect(mapStateToProps, {
  toggleEditAreaOfInterest,
  changeAnyTypeGeometryProp,
  toggleLowPerformingAreas,
  togglePopup,
  toggleAnomaliesHistory,
});

export default connector(AreasHistoryList);
