import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  normalizeSensorIndex,
  convertUnit,
  toFixedFloat,
  formatUnit,
  calcPlural,
  formatLocaleNumber,
  sensorView,
} from '_utils';
import {Button, SelectField} from 'react-md';
import {getGDDTillTSDate, prepareMinArea} from '../../utils';
import {
  updateNRxRecommendationProps,
  setTreeZoneParam,
  toggleRx,
  updateZoningZone,
  setZoningUnits,
  toggleNRxSettingsPopUp,
  toggleNRxTabRate,
  mergeNRxZones,
  revertMergeNRxZones,
} from '../../actions/zoning-actions';
import {InfoBlock, FluroTabs} from 'components';
import {MergedIcon} from '../../icons';
import {Zone} from '../../types';
import cn from 'classnames';
import {
  NrxTabRate,
  convertProductToTon,
  NRxZone,
  measurementUnits,
  classifyYieldUnits,
} from '../../utils/nrx-utils';
import {toggleTooltip} from '_actions';
import {AppStore} from '../../../../reducers';
import {HideWhileLoading} from 'components/simple-preloader';
import {AsyncStatusType} from 'modules/ui-helpers';
import SimplePreloader from '../../../../components/simple-preloader';
import EconomicObjectiveTabs from './nitrogen-recommendation/economic-objective-tabs';

type ZoneElementProps = {
  zone: Zone;
  nRecommendationToggled?: boolean;
  range?: number;
  index: number;
  isEnableRx: boolean;
  setZoneParam: (prop: string, index: number, value: any, isTreeAnalysis: boolean) => any;
  mergedZones?: boolean; // represents is any zone is merged
  isTreeAnalysis: boolean;
};

const ZoneElement = ({
  zone,
  index,
  nRecommendationToggled,
  range,
  isEnableRx,
  setZoneParam,
  mergedZones,
  isTreeAnalysis,
}: ZoneElementProps) => {
  const dispatch = useDispatch();
  const zoneArea = toFixedFloat(zone.area, 1);
  const zonePercent = toFixedFloat(zone.percent, zone.percent > 1 ? 0 : 1); // round to one if the percent is >1 (Kelly's request)
  const yieldGoal = false; // zone.yield_goal;
  const reduced = yieldGoal;
  const selectable = nRecommendationToggled;
  const mergedZone = zone.merged === 'merged';
  const rxValue =
    Number.isInteger(parseInt(`${zone.value}`)) || zone.value === '-' ? zone.value : '';

  return (
    <div
      onClick={
        selectable ? () => setZoneParam('selected', zone.id, !zone.selected, zone.isTree) : null
      }
      className={cn(`zoning-zone`, {selected: zone.selected, selectable})}
    >
      <div
        className={cn('zoning-zone__name', {
          merged: mergedZones,
        })}
        style={{background: zone.color}}
      >
        <input
          disabled={!isEnableRx}
          className="rx-input"
          value={zone.name !== undefined ? zone.name : t({id: 'Zone {index}'}, {index})}
          onChange={({target}) => setZoneParam('name', zone.id, target.value, zone.isTree)}
        />

        {mergedZone && (
          <MergedIcon
            data-tip={''}
            data-for={`merged-zones-${zone.id}`}
            onMouseEnter={() =>
              dispatch(
                toggleTooltip({
                  id: `merged-zones-${zone.id}`,
                  content: t({id: 'Zones were merged'}),
                  tooltipClassName: 'zoning-zone__merged-tooltip',
                  place: 'top',
                  width: 'auto',
                })
              )
            }
          />
        )}
      </div>

      {/*{!!yieldGoal && <div className={'zoning-zone__yield-goal'}>{yieldGoal}</div>}*/}

      <div className={cn('zoning-zone__data-cell', {reduced})}>
        <div className={'zone-values-top'}>
          {zone.isTree
            ? t({id: '{count} tree'}, {count: zone.number})
            : `${zoneArea < 0.1 ? '<0.1' : zoneArea}`}
        </div>
        {nRecommendationToggled && zone.percent !== undefined && (
          // add zone percent value to the column if the nrx is On (to save some space)
          <div className={'zone-values-bottom'}>
            <span className="zone-values-percent">
              ({zonePercent < 0.1 ? '<0.1' : zonePercent}%)
            </span>
          </div>
        )}
      </div>

      {!isTreeAnalysis && !nRecommendationToggled && (
        <div className={cn('zoning-zone__area-percent', {reduced})}>
          {zone.percent !== undefined && <>{zonePercent < 0.1 ? '<0.1' : zonePercent}%</>}
        </div>
      )}

      {!nRecommendationToggled && zone.mid !== undefined && (
        <div className={cn('zoning-zone__layer-value', {reduced})}>
          {zone.isTree ? zone.mid.toFixed(2) : normalizeSensorIndex(zone.mid, range)}
        </div>
      )}

      {nRecommendationToggled && (
        <div className={'zoning-zone__nitrogen-amount'}>{zone.nitrogenAmount}</div>
      )}

      <input
        disabled={!isEnableRx}
        placeholder={t({id: 'Value'})}
        className={cn('zoning-zone__rx-input', {reduced})}
        type="tel"
        pattern="[0-9\-]+"
        value={rxValue}
        maxLength={50}
        onChange={({target}) => setZoneParam('value', zone.id, target.value, zone.isTree)}
      />
    </div>
  );
};

const TreesAndProductAmount = ({
  zones,
  isNrx,
  productMeasure,
  product,
}: {
  zones: Zone[];
  isNrx: boolean;
  product?: string;
  productMeasure: string | any;
}) => {
  const locale = useSelector((state: AppStore) => state.login.user.settings.langLocale);

  const isTree = zones.every(z => z.isTree);
  const productMeasureString = productMeasure?.includes('/')
    ? productMeasure.split('/')[0]
    : productMeasure;
  const amountTrees = isTree ? zones.reduce((res, z) => res + z.number, 0) : 0;
  const amountProduct = toFixedFloat(
    zones.reduce((res, z) => {
      const area = toFixedFloat(z.area, 1);
      const rxValue = Number(z.value || 0);
      return res + (z.area !== undefined ? area * rxValue : rxValue);
    }, 0),
    0
  );
  const productAmountInTon =
    isNrx && convertProductToTon(product, amountProduct, productMeasureString);

  const productString = isNrx && product ? `${product.toLowerCase()}` : t({id: 'amount'});

  return (
    <>
      {amountTrees ? (
        <div className={'zones-entities-amount'}>
          <div className={'entity-name'}>{t({id: 'Total number of trees'})}:</div>
          <div className={'entity-value'}>{t({id: '{count} tree'}, {count: amountTrees})}</div>
        </div>
      ) : null}

      {isNrx && amountProduct ? (
        <div className={'zones-entities-amount'}>
          <div className={'entity-name'}>
            {t({id: 'Total {productString} required'}, {productString})}:
          </div>
          <div className={'entity-value'}>
            {amountProduct < 1 ? '<1' : formatLocaleNumber(amountProduct, locale)}{' '}
            {productMeasureString}
            {' - '}
            {productAmountInTon} T
          </div>
          {/*<div className={'element-full-width md-text-right entity-value'}></div>*/}
        </div>
      ) : null}
    </>
  );
};

const Zones = () => {
  const dispatch = useDispatch();
  const zoning = useSelector((s: AppStore) => s.map.zoning);
  const measurement = useSelector((s: AppStore) => s.login.user.settings.measurement);
  const isEnableRx = useSelector((s: AppStore) => s.map.zoning.isEnableRx);
  const temperatureData = useSelector((s: AppStore) => s.map.temperatureData);
  const pointsCurrentGroupDate = useSelector((s: AppStore) => s.map.pointsCurrentGroupDate);
  const histogram = useSelector((s: AppStore) => s.map.histogram);
  const currentSensor = useSelector((s: AppStore) => s.map.currentSensor);
  const layerType = useSelector((s: AppStore) => s.map.treeDetection.layerType);
  const nRecommendation = useSelector((s: AppStore) => s.map.nRecommendation);
  const wholeFarm = useSelector((s: AppStore) => s.map.wholeFarm);

  const {
    toggled: NRecommendationToggled,
    method,
    nrxResult,
    selectedObjective,
    nrxPopUpValues: {product, isLiquid, ROI_setting},
    nrxTabRate,
    settingsPopUpOpen,
  } = nRecommendation;

  const gddTillGroupDate = useMemo(() => {
    // @ts-ignore
    const gdd = dispatch(getGDDTillTSDate()) as number;
    return convertUnit(measurement, 'gdd', gdd).toFixed(0);
  }, [pointsCurrentGroupDate, temperatureData]);

  useEffect(() => {
    if (!zoning.currentUnits) {
      dispatch(setZoningUnits(measurementUnits()[measurement][0]));
    }
  }, []);

  const onToggleRx = () => dispatch(toggleRx(!isEnableRx));

  const onToggleNRxSettingsPopUp = () => dispatch(toggleNRxSettingsPopUp(!settingsPopUpOpen));

  const setZoneParam = (prop: string, id: number, value: any, isTree = false) => {
    if (prop === 'value' && !isFinite(value)) return;
    if (isTree) return dispatch(setTreeZoneParam(id, {[prop]: value}));

    if (NRecommendationToggled && method === 'apsim') {
      return dispatch(updateNRxRecommendationProps({[prop]: value, id}));
    }

    dispatch(updateZoningZone(id, prop, value));
  };

  const changeCurrentUnits = (val: string) => {
    const [currentUnits] = measurementUnits()[measurement].filter(({value}: any) => value === val);
    dispatch(setZoningUnits(currentUnits));
  };

  // const getWholeFarmTreeZones = () => {
  //   let zones: Zone[] = [];

  // Object.keys(wholeFarm.treeZoning.fields).forEach(fieldMD5 => {
  //   const currentLoopField = wholeFarm.treeZoning.fields[fieldMD5];
  //   if (currentLoopField.zones?.length && currentLoopField.selected !== false) {
  //     if (!zones.length) {
  //       zones = currentLoopField.zones.map(z => z);
  //     } else {
  //       zones = zones.map((zone, index) => ({
  //         ...zone,
  //         number: zone.number + currentLoopField.zones[index].number,
  //       }));
  //     }
  //   }
  // });
  // return zones;
  // };

  const minArea = prepareMinArea(zoning.area);
  const isNrx = NRecommendationToggled && method === 'apsim';
  const isTreeAnalysis = layerType !== 'default';

  let preparedZones: Zone[] = [];
  if (wholeFarm.isWholeFarmView) {
    // preparedZones = getWholeFarmTreeZones();
    preparedZones = wholeFarm.treeZoning.zones;
  } else {
    preparedZones = isNrx
      ? nrxResult[nrxTabRate][selectedObjective].features
        ? nrxResult[nrxTabRate][selectedObjective].features
            .filter((f: NRxZone) => f.properties.merged !== 'initial') // filter initial zones that was merged
            .map((f: NRxZone) => ({...f.properties}))
        : []
      : isTreeAnalysis
      ? zoning.treeZones
      : [...zoning.zones].reverse();
  }
  const selectedObjectiveData = isNrx && nrxResult[nrxTabRate][selectedObjective];
  const mergedZones = selectedObjectiveData.features?.filter(
    (f: NRxZone) => f.properties.merged === 'merged'
  )?.length;

  const zones: Zone[] = preparedZones.filter(({area, isTree}) => isTree || area > 0);
  const zonesLength = zones.length;
  const zonesFitSize = isNrx || isTreeAnalysis ? zones : zones.filter(z => z.area >= minArea);

  const zonesSmallSize = zones.filter(z => z.area < minArea);
  const diffZone = preparedZones.length - zones.length;
  const productMeasure = formatUnit(measurement, isLiquid ? 'l/ha' : 'kg/ha');
  const nAmountMeasure = formatUnit(measurement, 'kg/ha');
  const yieldGoal = false; // zones.some(z => z.yield_goal);
  const yieldGoalUnits = zones.find(z => z.yield_goal_units)?.yield_goal_units;
  const nrxProduct = isNrx && product;
  const nRxTabs = [
    {label: t({id: 'Variable rate'}), value: 'variable'},
    {label: t({id: 'Flat rate'}), value: 'blanket'},
  ];

  if (isNrx && zonesLength === 0) return null;

  return (
    <div className="zones-container element-full-width">
      {!isNrx && (
        <h3 className={'zones-title'}>
          {t({id: 'Management zones'})}{' '}
          <SimplePreloader
            statusKeys={[AsyncStatusType.mainZoning, AsyncStatusType.zoningImageOverlay]}
          />
        </h3>
      )}

      {isNrx && (
        <>
          <FluroTabs
            containerClassName={'nrx-rate-tabs'}
            tabs={nRxTabs}
            selectedTab={nrxTabRate}
            onTabClick={(value: NrxTabRate) => dispatch(toggleNRxTabRate(value))}
          />
          <EconomicObjectiveTabs />
        </>
      )}

      {isNrx && selectedObjectiveData.yield_potential_max && (
        <div className={'yield-potential'}>
          <div className={'fluro-subtitle'}>{t({id: 'Yield potential'})}</div>
          <span className={'units'}>
            {selectedObjectiveData.yield_potential_min} -{' '}
            {selectedObjectiveData.yield_potential_max} {yieldGoalUnits}
          </span>
        </div>
      )}

      {pointsCurrentGroupDate && NRecommendationToggled && !isNrx && (
        <div className={'gdd-container'}>
          {t({id: 'GDD'})}: {gddTillGroupDate}
        </div>
      )}

      <div className="zoning-statistics">
        {zonesLength !== 0 ? (
          <>
            <div className="zone-list-header">
              <div className={cn('zone-list-header__name', {merged: !!mergedZones})}>
                {t({id: 'Name'})}
              </div>
              {/*{yieldGoal && (*/}
              {/*  <div className="zone-list-header__yield-goal">*/}
              {/*    <FormattedMessage*/}
              {/*      id="Yield goal <span>{unit}</span>"*/}
              {/*      defaultMessage="Yield goal <span>{unit}</span>"*/}
              {/*      values={{*/}
              {/*        span: (txt: string) => <span className={'units'}>{txt}</span>,*/}
              {/*        unit: `(${yieldGoalUnits})`,*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}
              <div className={'zone-list-header__area'}>
                {isTreeAnalysis ? (
                  <>
                    <FormattedMessage id="Number <br></br> of trees" values={{br: () => <br />}} />
                  </>
                ) : (
                  <>
                    {t({id: 'Area'})} <br />
                    <span className={'units'}>({t({id: measurement})})</span>
                  </>
                )}
              </div>
              {!isTreeAnalysis && !NRecommendationToggled && (
                // Area in %
                <div className={'zone-list-header__area-percent'}>
                  {t({id: 'Area'})} <br /> <span className={'units'}>(%)</span>
                </div>
              )}

              {!NRecommendationToggled && (
                // current sensor value
                <div className={'zone-list-header__layer-value'}>{sensorView(currentSensor)}</div>
              )}

              {NRecommendationToggled && nrxProduct && (
                <div className={'zone-list-header__n-amount'}>
                  {t({id: 'N units'})} <span className={'units'}>({nAmountMeasure})</span>
                </div>
              )}
              <div className={cn('zone-list-header__rx', {reduced: yieldGoal})}>
                {t({id: 'Rx'})}{' '}
                <Button
                  id={'edit-rx-button'}
                  className={`rx-button ${isEnableRx ? 'active' : ''}`}
                  icon
                  onClick={onToggleRx}
                >
                  edit
                </Button>{' '}
                {!isNrx && (
                  <HideWhileLoading
                    statusKeys={[AsyncStatusType.mainZoning, AsyncStatusType.zoningImageOverlay]}
                    content={isLoading => (
                      <div className="unit-select">
                        <SelectField
                          id="units-selector"
                          className={'units-selector'}
                          label=""
                          menuItems={measurementUnits()[measurement]}
                          value={zoning.currentUnits?.value}
                          onChange={(value: string) => changeCurrentUnits(value)}
                          simplifiedMenu={false}
                          anchor={{
                            x: SelectField.HorizontalAnchors.INNER_RIGHT,
                            y: SelectField.VerticalAnchors.BOTTOM,
                          }}
                          disabled={isLoading}
                        />
                      </div>
                    )}
                  />
                )}
                {nrxProduct && <div className={'units'}>({productMeasure})</div>}
              </div>
            </div>
            {zonesFitSize.map((zone, index) => (
              <ZoneElement
                key={zone.id}
                index={index + 1}
                zone={zone}
                range={histogram.range}
                nRecommendationToggled={NRecommendationToggled}
                isTreeAnalysis={isTreeAnalysis}
                isEnableRx={isEnableRx}
                setZoneParam={setZoneParam}
                mergedZones={!!mergedZones}
              />
            ))}
            {zonesSmallSize.length !== 0 ? (
              <React.Fragment>
                <h4>{t({id: 'Zones too small'})}:</h4>
                {zonesSmallSize.map((zone, index) => (
                  <ZoneElement
                    key={zone.id}
                    index={index + 1}
                    zone={zone}
                    range={histogram.range}
                    nRecommendationToggled={NRecommendationToggled}
                    isTreeAnalysis={isTreeAnalysis}
                    isEnableRx={isEnableRx}
                    setZoneParam={setZoneParam}
                  />
                ))}
              </React.Fragment>
            ) : null}
            {diffZone ? (
              <span className="zoning-diff">
                {t(
                  {id: 'The selected zoning method allows a maximum of zones'},
                  {count: zones.length}
                )}
              </span>
            ) : null}

            <TreesAndProductAmount
              isNrx={!!isNrx}
              product={product}
              productMeasure={isNrx ? productMeasure : zoning.currentUnits?.label}
              zones={[...zonesFitSize, ...zonesSmallSize]}
            />
          </>
        ) : null}
      </div>

      {isNrx && zonesLength === 1 && (zones[0]?.value === 0 || zones[0]?.value === '-') && (
        <InfoBlock appearance={'warning'} className={'tab-info-block nrx-wrong-data-info'}>
          {zones[0]?.resultROISettings === 3 ? (
            <>
              <FormattedMessage
                id="We could not find a variable rate application with"
                defaultMessage="We could not find a variable rate application with <span>these settings.</span> The flat rate application is shown instead."
                values={{
                  span: (txt: string) => (
                    <span className={'global-link'} onClick={onToggleNRxSettingsPopUp}>
                      {txt}
                    </span>
                  ),
                }}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                id="Make sure <span>recommendation settings</span> are representative of your crop conditions."
                defaultMessage="Make sure <span>recommendation settings</span> are representative of your crop conditions."
                values={{
                  span: (txt: string) => (
                    <span className={'global-link'} onClick={onToggleNRxSettingsPopUp}>
                      {txt}
                    </span>
                  ),
                }}
              />
            </>
          )}
        </InfoBlock>
      )}

      {isNrx && (
        <Button
          onClick={() => dispatch(mergeNRxZones())}
          className={'element-full-width margin-bottom-10'}
          raised
          primary
          disabled={zones.filter(z => z.selected).length <= 1}
        >
          {t({id: 'Merge selected zones'})}
        </Button>
      )}
      {isNrx && mergedZones ? (
        <Button
          onClick={() => dispatch(revertMergeNRxZones())}
          className={'element-full-width margin-bottom-10'}
          raised
          primary
        >
          {t({id: 'Unmerge zones'})}
        </Button>
      ) : null}
    </div>
  );
};

export default Zones;
