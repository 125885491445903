import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {Range} from 'rc-slider';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {setDiffYears} from './optis-reducer';
import './slider-year.scss';
import {AppStore} from 'reducers';

const years = ['2015', '2016', '2017', '2018'];
export const SliderDiffYear = () => {
  const dispatch = useDispatch();
  const optis = useSelector((s: AppStore) => s.optis);

  const marks: {[percent: number]: string} = {};
  years.forEach((year, i) => {
    const percent = ((i / (years.length - 1)) * 100) | 0;
    marks[percent] = year;
  });

  const defaultValue = useMemo(
    () =>
      optis.filter.years.length === 0
        ? // In case of 0 years, take the last two ones.
          Object.keys(marks)
            .slice(-2)
            .map(Number)
        : optis.filter.years.length === 1
        ? // In case of 1 year take this year and a year - 1 values.
          Object.keys(marks)
            .map(Number)
            .filter(
              percent =>
                Number(marks[percent]) === optis.filter.years[0] - 1 ||
                Number(marks[percent]) === optis.filter.years[0]
            )
        : // In case of >= 2 years take the first and the last years.
          Object.keys(marks)
            .map(Number)
            .filter(
              percent =>
                Number(marks[percent]) === optis.filter.years[0] ||
                Number(marks[percent]) === optis.filter.years[optis.filter.years.length - 1]
            ),
    []
  );

  const onChange = useCallback(([a, b]: number[]) => {
    if (marks[a] && marks[b]) {
      dispatch(setDiffYears(Number(marks[a]), Number(marks[b])));
    }
  }, []);

  return (
    <Wrapper>
      <Range
        marks={marks}
        step={null}
        defaultValue={defaultValue}
        onChange={onChange}
        pushable={true}
        className="slider-year"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px 20px 0;
`;
