import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import {Button} from 'react-md';
import FormUploader from './upload-layers-form';
import {FluroDialog, ReadOnly, Ln} from 'components';
import {UploadIcon} from 'containers/map/icons';
import '../index.scss';
import OrderPlanetImagery from '../order-hight-resolution-imagery';

const UploadLayers = ({isReadOnly}: {isReadOnly: boolean}) => {
  const [visible, toggleVisibility] = useState(false);
  const [orderHighResolutionVisible, toggleOrderHighResolutionVisibility] = useState(false);

  useEffect(() => {
    // reset the prop
    if (orderHighResolutionVisible) toggleOrderHighResolutionVisibility(false);
  }, [orderHighResolutionVisible]);

  return (
    <>
      <FluroDialog
        id="upload-layers-pop-up"
        visible={visible}
        title={t({id: 'Upload my layers'})}
        isDraggable
        focusOnMount={false}
        onHide={() => toggleVisibility(false)}
      >
        <FormUploader />
      </FluroDialog>

      <div className="select-file-button-container">
        <ReadOnly>
          <Button
            primary
            raised
            disabled={isReadOnly}
            iconEl={<UploadIcon />}
            className="btn-with-icon md-cell md-cell--12"
            onClick={() => toggleVisibility(true)}
          >
            {t({id: 'Upload my layers'})}
          </Button>
        </ReadOnly>
      </div>

      {!isReadOnly && (
        <>
          <OrderPlanetImagery openByProps={orderHighResolutionVisible} />
          <div
            className={'order-high-resolution-imagery-btn'}
            onClick={() => toggleOrderHighResolutionVisibility(true)}
          >
            {t({id: 'Order high resolution imagery'})}
          </div>
        </>
      )}
    </>
  );
};

export default UploadLayers;
