import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {ExternalService} from 'containers/map/types';
import {ExternalServiceIconWrapper} from './header.styled';

type Props = {
  value: ExternalService;
  staticPosition?: boolean;
};

const ExternalServiceIcon = ({value, staticPosition = false}: Props) => {
  // common types are terravion, in this case we do not show the logo
  if (value === ExternalService.Terravion) return null;
  if (value === ExternalService.Agx) value = ExternalService.Proagrica; // show Proagrica logo instead

  return (
    <ExternalServiceIconWrapper staticPosition={staticPosition}>
      <img src={`/assets/integration/${value}-logo-round.svg`} alt={value} />
    </ExternalServiceIconWrapper>
  );
};

export default ExternalServiceIcon;
