import {t, FormattedMessage} from 'i18n-utils';
import React, {ReactElement} from 'react';
import {
  AdminIcon,
  AnalyticsIcon,
  CropIcon,
  FaqIcon,
  FarmIcon,
  LogOutIcon,
  ProfileIcon,
  RemoteSensingIcon,
  SustainabilityIcon,
  TSPIcon,
  ZoningIcon,
} from '../icons';
import {WarningSvg} from 'components/icons';
import {Config} from '_environment';
import {IUser} from 'containers/login/types';
import {getGetURLParam, userIsAdmin} from '_utils';
import {reportError} from 'containers/error-boundary';

export enum Permission {
  User = 'user',
  Admin = 'admin',
  UserHasFarms = 'userHasFarms', // Allowed only for users who has farms.
  ScenarioPlanningTool = 'scenarioPlanningTool',
}

export type MenuItem = {
  title: ReactElement | string;
  feature: string;
  icon: ReactElement;
  // First level array is AND conditions. Second level array is OR conditions.
  // For example,
  // permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
  // means if a user is (either admin OR user) AND has farms.
  // See _utils.userHasAccess fn.
  permissions: Permission[][];
  ln?: string;
  taggedTitle?: ReactElement | string;
  featureFlag?: string; // show the menu item only when the given feature flag is present in the URL
  topSpacer?: boolean;
  bottomSpacer?: boolean;
};

export const getFeatureLinks = (
  featurePack: Config['featurePack'],
  user: IUser,
  hasFarms: boolean
) => {
  const links = featurePack === 'carbon' ? carbonLinks : LINKS;
  const featureFlags = getGetURLParam('featureFlags')?.split(',');
  return links.filter(
    l =>
      userHasAccess(user, l.permissions, hasFarms) &&
      (!l.featureFlag || featureFlags?.includes(l.featureFlag))
  );
};

export const userHasAccess = (user: IUser, permissions: Permission[][], hasFarms: boolean) => {
  if (!permissions) return false;
  return permissions.every(orPermissions =>
    orPermissions.some(perm => {
      switch (perm) {
        case Permission.ScenarioPlanningTool:
          return user.settings.scenarioPlanningTool != null;
        case Permission.UserHasFarms:
          return hasFarms;
        case Permission.Admin:
          return userIsAdmin(user);
        case Permission.User:
          return !userIsAdmin(user);
        default:
          reportError(`Permission ${perm} is not supported by userHasAccess fn`);
          return false;
      }
    })
  );
};

export const baseLinks: MenuItem[] = [
  {
    title: <FormattedMessage id="Admin" defaultMessage="Admin" />,
    feature: 'admin',
    icon: <AdminIcon />,
    ln: '/admin/users',
    permissions: [[Permission.Admin]],
  },

  {
    title: <FormattedMessage id="User Profile" defaultMessage="User Profile" />,
    feature: 'profile',
    icon: <ProfileIcon />,
    ln: '/profile',
    permissions: [[Permission.Admin, Permission.User]],
  },

  {
    title: <FormattedMessage id="Help Center" defaultMessage="Help Center" />,
    feature: 'faq',
    icon: <FaqIcon style={{fontSize: '21px', left: '1px', position: 'relative'}} />,
    ln: '/faq',
    permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
  },

  {
    title: <FormattedMessage id="Log Out" defaultMessage="Log Out" />,
    feature: 'log-out',
    icon: <LogOutIcon viewBox="0 0 26 26" />,
    ln: '/logout',
    permissions: [[Permission.Admin, Permission.User]],
  },
];

export const LINKS: MenuItem[] = [
  {
    title: <FormattedMessage id="farm.title" defaultMessage="Farm set-up" />,
    feature: 'farm',
    icon: <FarmIcon viewBox="0 0 27 27" />,
    permissions: [[Permission.Admin, Permission.User]],
  },

  {
    title: <FormattedMessage id="Crop Performance" defaultMessage="Crop Performance" />,
    feature: 'crop-performance',
    icon: <CropIcon />,
    permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
    //features: {cropPerformance: true},
  },

  {
    title: (
      <FormattedMessage id="Sustainability Insights" defaultMessage="Sustainability insights" />
    ),
    feature: 'optis',
    icon: <SustainabilityIcon viewBox="0 0 29 27" />,
    permissions: [[Permission.Admin], [Permission.UserHasFarms]],
  },

  {
    title: <FormattedMessage id="Analytics" defaultMessage="Analytics" />,
    feature: 'analytics',
    icon: <AnalyticsIcon viewBox="0 0 26 26" />,
    permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
  },

  {
    title: <FormattedMessage id="Areas of Interest" defaultMessage="Areas of Interest" />,
    taggedTitle: <FormattedMessage id="Crop stress" defaultMessage="Crop stress" />,
    feature: 'crop',
    icon: <WarningSvg />,
    permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
  },

  {
    title: <FormattedMessage id="Zone Management" defaultMessage="Zone Management" />,
    feature: 'zoning',
    icon: <ZoningIcon viewBox="0 0 27 27" />,
    permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
  },

  {
    title: <FormattedMessage id="Data Layers" defaultMessage="Data Layers" />,
    feature: 'data-layers',
    icon: <RemoteSensingIcon viewBox="0 0 100 100" />,
    permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
  },

  {
    title: <FormattedMessage id="Points" defaultMessage="Points" />,
    feature: 'tsp',
    icon: <TSPIcon viewBox="0 0 100 100" />,
    permissions: [[Permission.Admin, Permission.User], [Permission.UserHasFarms]],
    bottomSpacer: true,
  },

  ...baseLinks,
];

export const carbonLinks: MenuItem[] = [
  {
    title: <FormattedMessage id="Carbon" defaultMessage="Carbon" />,
    feature: 'carbon',
    icon: <SustainabilityIcon viewBox="0 0 29 27" />,
    permissions: [[Permission.Admin, Permission.ScenarioPlanningTool]],
    bottomSpacer: true,
  },

  ...baseLinks.filter(item => item.feature !== 'faq'),
];
