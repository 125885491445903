import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'react-md';
import cn from 'classnames';
import {Layers} from '../icons';
import {changeLayersSource, toggleMapButton, toggleSoilMapLayer} from '../actions';
import {ButtonOnMap, TLayersSource} from '../types';
import Mixpanel from '_utils/mixpanel-utils';
import {AppStore} from 'reducers';

const ToggleLayersSource = () => {
  const dispatch = useDispatch();
  const layersSource = useSelector((state: AppStore) => state.map.layersSource);
  const soilMapLayer = useSelector((state: AppStore) => state.map.soilMapLayer);
  const fields = useSelector((state: AppStore) => state.map.fields);
  const toggledButtonOnMap = useSelector((state: AppStore) => state.map.toggledButtonOnMap);
  const thisButtonName = 'LayerSource';
  const toggled = toggledButtonOnMap === thisButtonName;

  const someFieldInUS = useMemo(() => {
    return fields.length === 0 || fields.some(f => f.Country === 'United States');
  }, [fields]);

  useEffect(
    function disableSoilMapLayer() {
      if (!someFieldInUS && soilMapLayer) {
        onToggleSoilMapLayer(false);
      }
    },
    [someFieldInUS, soilMapLayer]
  );

  const onChangeLayerSource = useCallback((layer: TLayersSource) => {
    Mixpanel.changeMapLayer(layer);
    dispatch(changeLayersSource(layer));
  }, []);

  const onToggleMapButton = useCallback((layer: ButtonOnMap) => {
    dispatch(toggleMapButton(layer));
  }, []);

  const onToggleSoilMapLayer = useCallback((value: boolean) => {
    dispatch(toggleSoilMapLayer(value));
  }, []);

  const isGoogle = layersSource === 'google';
  return (
    <div
      id={'layers-source'}
      className={cn('btn-on-map-pop-up', {
        active: toggled,
      })}
    >
      <Button
        id="toggle-map-layers-source-btn"
        title={t({id: 'Layers source', defaultMessage: 'Layers source'})}
        className={`map-toggle-button drawing`}
        name="toggle-field-info"
        iconEl={<Layers />}
        onClick={() => onToggleMapButton(thisButtonName)}
        floating
        mini
      />
      <div className={`pop-up layers`}>
        <h5 className={'pop-up__title'}>{t({id: 'Map layers', defaultMessage: 'Map layers'})}</h5>
        <div className={'layer-types'}>
          <div
            onClick={() => onChangeLayerSource('mapbox')}
            className={cn('layer-type mapbox', {disabled: isGoogle})}
          >
            <img
              src="/assets/mapbox_layer.svg"
              alt={t({id: 'Mapbox layers', defaultMessage: 'Mapbox layers'})}
            />
            <span>{t({id: 'Mapbox', defaultMessage: 'Mapbox'})}</span>
          </div>
          <div
            onClick={() => onChangeLayerSource('google')}
            className={cn('layer-type google', {disabled: !isGoogle})}
          >
            <img
              src="/assets/google_layer.png"
              alt={t({id: 'Google layers', defaultMessage: 'Google layers'})}
            />
            <span>{t({id: 'Google map', defaultMessage: 'Google map'})}</span>
          </div>
        </div>

        {someFieldInUS && (
          <>
            <h5 className={'pop-up__title'}>{t({id: 'Other layers'})}</h5>
            <div className={'layer-types'}>
              <div
                onClick={() => onToggleSoilMapLayer(!soilMapLayer)}
                className={cn('layer-type soil-map', {disabled: !soilMapLayer})}
              >
                <img src="/assets/soil_map_layer.png" alt={t({id: 'Soil map'})} />
                <span>{t({id: 'Soil map'})}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ToggleLayersSource;
