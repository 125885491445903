import activity from '../../_api/activity';

enum ActionType {
  DialogToggle = '@helpers/dialog/toggle',
  SetFilter = '@helpers/filter/set',
  SetRequestStatus = '@helpers/ui/set-request-status',
}

export enum DialogType {
  //TODO: move all dialog key strings here;
  fieldInfo = 'fieldInfo',
  confirm = 'confirm',
  alert = 'alert',
  editFieldName = 'editFieldName',
  editCloudy = 'editCloudy',
  editSelectedSeasonsProductivityMap = 'editSelectedSeasonsProductivityMap',
  exportEOS = 'exportEOS',
  cropInsightsReport = 'cropInsightsReport',
  editCrop = 'editCrop',

  carbonLogin = 'carbonLogin',
  carbonSignup = 'carbonSignup',
  carbonSignupConfirmation = 'carbonSignupConfirmation',
  carbonRestorePassword = 'carbonRestorePassword',
  editFarm = 'editFarm',
  editFarmName = 'editFarmName',
  carbonTillagePractices = 'carbonTillagePractices',
  deleteDialog = 'deleteDialog',
}

export enum Status {
  Todo,
  Pending,
  Done,
}

type AsyncStatus = {
  status?: Status;
  errors?: any;
};

export enum AsyncStatusType {
  imageLayer = 'imageLayer',

  adminDashboardCropStress = 'adminDashboardCropStress',
  adminDashboardNrx = 'adminDashboardNrx',
  farmsList = 'farms',
  loadOneFarm = 'loadOneFarm',

  fieldsData = 'fieldsData',
  allFieldsData = 'allFieldsData',
  fieldGeometries = 'fieldGeometries',

  // zoning
  mainZoning = 'mainZoning',
  loadSuggestedPoints = 'loadSuggestedPoints',
  zoningImageOverlay = 'zoningImageOverlay',
  NRxRecommendation = 'NRxRecommendation',
  NRXSettings = 'NRXSettings',

  // histogram
  histogram = 'histogram',

  // analytics
  analyticsGeometriesAndPoints = 'analyticsGeometriesAndPoints',
  analyticsFieldArableData = 'analyticsFieldArableData',
  // weather
  loadFieldWeather = 'loadFieldWeather',

  // Whole farm
  wholeFarmData = 'wholeFarmData',

  productivityMap = 'productivityMap',

  // Field boundaries
  cluFieldBoundaries = 'cluFieldBoundaries',

  // Upload fields
  parseUploadedFields = 'parseUploadedFields',
  uploadingFieldsToBackend = 'uploadingFieldsToBackend',

  // --

  cropTypesMatching = 'cropTypesMatching',

  binaryImageLayerData = 'binaryImageLayerData',

  usersActivity = 'usersActivity',

  // carbon
  guessedPractices = 'guessedPractices',
}

type DialogState = {
  visible: boolean;
  payload?: any;
};

export type UIHelpersStore = {
  asyncStatuses: {[key in AsyncStatusType]: AsyncStatus};

  dialogs: {
    statusAlert: DialogState;
    adminAssignOrganizationToFarm: DialogState;
    adminAssignSubscriptionToOrganization: DialogState;
    adminSubscriptionsCreateFarm: DialogState;
    info: DialogState;
    histogram: DialogState;
    cloudyTip: DialogState;
    assignMultipleFarms: DialogState;
    assignMultipleOrgs: DialogState;
    emailLogsSend: DialogState;
    emailLogsDiscard: DialogState;
    confirm: DialogState;
    alert: DialogState;
    fieldInfo: DialogState;
    editFieldName: DialogState;
    cropStressDetailed: DialogState;
    [DialogType.editCloudy]: DialogState;
    [DialogType.editSelectedSeasonsProductivityMap]: DialogState;
    [DialogType.exportEOS]: DialogState;
    [DialogType.cropInsightsReport]: DialogState;
    [DialogType.editCrop]: DialogState;

    // carbon
    [DialogType.carbonLogin]: DialogState;
    [DialogType.carbonSignup]: DialogState;
    [DialogType.carbonSignupConfirmation]: DialogState;
    [DialogType.carbonRestorePassword]: DialogState;
    [DialogType.editFarm]: DialogState;
    [DialogType.editFarmName]: DialogState;
    [DialogType.carbonTillagePractices]: DialogState;
    [DialogType.deleteDialog]: DialogState;
  };

  filters: {[filterName: string]: any};
};

const initialState: UIHelpersStore = {
  asyncStatuses: {
    [AsyncStatusType.imageLayer]: {},
    [AsyncStatusType.adminDashboardCropStress]: {},
    [AsyncStatusType.adminDashboardNrx]: {},
    [AsyncStatusType.farmsList]: {},
    [AsyncStatusType.loadOneFarm]: {},
    [AsyncStatusType.fieldsData]: {},
    [AsyncStatusType.allFieldsData]: {},
    [AsyncStatusType.fieldGeometries]: {},
    // zoning
    [AsyncStatusType.mainZoning]: {},
    [AsyncStatusType.loadSuggestedPoints]: {},
    [AsyncStatusType.zoningImageOverlay]: {},
    [AsyncStatusType.NRxRecommendation]: {},
    [AsyncStatusType.NRXSettings]: {},
    // histogram
    [AsyncStatusType.histogram]: {},
    // analytics
    [AsyncStatusType.analyticsGeometriesAndPoints]: {},
    [AsyncStatusType.analyticsFieldArableData]: {},
    // weather
    [AsyncStatusType.loadFieldWeather]: {},
    // whole farm
    [AsyncStatusType.wholeFarmData]: {},

    [AsyncStatusType.productivityMap]: {},

    // Field boundaries
    [AsyncStatusType.cluFieldBoundaries]: {},

    // Upload fields
    [AsyncStatusType.parseUploadedFields]: {},
    [AsyncStatusType.uploadingFieldsToBackend]: {},

    [AsyncStatusType.cropTypesMatching]: {},

    [AsyncStatusType.binaryImageLayerData]: {},
    [AsyncStatusType.usersActivity]: {},

    // carbon
    [AsyncStatusType.guessedPractices]: {},
  },

  dialogs: {
    info: {
      visible: false,
    },
    statusAlert: {
      visible: false,
    },
    adminAssignOrganizationToFarm: {
      visible: false,
    },
    adminAssignSubscriptionToOrganization: {
      visible: false,
    },
    adminSubscriptionsCreateFarm: {
      visible: false,
    },
    histogram: {
      visible: false,
    },
    cloudyTip: {
      visible: false,
    },

    assignMultipleFarms: {
      visible: false,
    },

    assignMultipleOrgs: {
      visible: false,
    },

    emailLogsSend: {
      visible: false,
    },

    emailLogsDiscard: {
      visible: false,
    },

    confirm: {
      visible: false,
    },

    alert: {
      visible: false,
    },

    fieldInfo: {
      visible: false,
    },

    editFieldName: {
      visible: false,
    },

    cropStressDetailed: {
      visible: false,
    },

    [DialogType.editCloudy]: {
      visible: false,
    },

    [DialogType.editSelectedSeasonsProductivityMap]: {
      visible: false,
    },

    [DialogType.exportEOS]: {
      visible: false,
    },

    [DialogType.cropInsightsReport]: {
      visible: false,
    },

    [DialogType.editCrop]: {
      visible: false,
    },

    [DialogType.carbonLogin]: {
      visible: false,
    },

    [DialogType.carbonSignup]: {
      visible: false,
    },

    [DialogType.carbonSignupConfirmation]: {
      visible: true,
    },

    [DialogType.carbonRestorePassword]: {
      visible: false,
    },

    [DialogType.editFarm]: {
      visible: false,
    },

    [DialogType.editFarmName]: {
      visible: false,
    },

    [DialogType.deleteDialog]: {
      visible: false,
    },

    [DialogType.carbonTillagePractices]: {
      visible: false,
    },
  },

  filters: {
    subscriptionsFarmId: 0,
    subscriptionsOrgId: 0,
    subscriptionsSubId: 0,
  },
};

export const dialogToggle = (
  dialogKey: keyof UIHelpersStore['dialogs'],
  visible: boolean,
  payload?: any
) => ({
  type: ActionType.DialogToggle,
  dialogKey,
  visible,
  payload,
});

export const setFilter = (name: string, value: any) => ({
  type: ActionType.SetFilter,
  name,
  value,
});

export type SetRequestStatusAction = {
  type: ActionType.SetRequestStatus;
  key: AsyncStatusType;
  status: Status;
  errors?: any;
};

export const setRequestStatus = (
  key: keyof UIHelpersStore['asyncStatuses'],
  status: Status,
  errors?: any
): SetRequestStatusAction => ({
  type: ActionType.SetRequestStatus,
  key,
  status,
  errors,
});

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.DialogToggle: {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.dialogKey]: {
            visible: action.visible,
            // if dialog closed clear payload
            payload: action.visible ? action.payload || null : null,
          },
        },
      };
    }

    case ActionType.SetRequestStatus: {
      return {
        ...state,
        asyncStatuses: {
          ...state.asyncStatuses,
          [action.key]: {
            status: action.status,
            errors: action.errors || null,
          },
        },
      };
    }

    case ActionType.SetFilter: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };
    }

    default:
      return state;
  }
};
