import {OptisType, OptisAreaType} from 'containers/map/features/optis/optis-types';
import Server from './server';

export default class {
  static getData(params: {
    areaType: OptisAreaType;
    areaUnit: 'ac' | 'ha';
    parentID?: number;
    from: number;
    to: number;
    include: ('tillage' | 'winter_crops' | 'meta' | 'geometry')[];
    // areaType=HUC8&areaUnit=ac&from=2007&to=2008&include=["tillage","winter_crops"]
  }) {
    return Server.get('optis', {
      params: {
        ...params,
        include: JSON.stringify(params.include),
        __skipPreloader: true,
      },
    });
  }
}
