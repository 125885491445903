import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {AppStore} from 'reducers';
import {loadFieldGeometries} from 'containers/map/actions';
import {
  loadCropPerformance,
  loadInfo,
  loadStressedFields,
  setRecords,
  setRepresentation,
  toggleCropPerformanceFarms,
  twoWeeksAgoSetRecords,
} from './reducer';
import CropPerformancePanelView from './panel-view';
import {reduceCsgsForDate} from './helpers/records';

import './index.scss';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE} from '_constants';
import {
  bulkSetGetParamToURL,
  cropSubtype2Label,
  getArrayValuesFromUrl,
  getFarmIdsFromUrl,
  getGetURLParam,
  setGetParamToURL,
} from '../../../../_utils';
import {
  CPFilterType,
  isCPFilter,
} from '../../../../_reducers/crop-performance-filter/field-filter-types';
import {toggleFilter} from '../../../../_reducers/crop-performance-filter/field-filter-reducer';

const CropPerformance = () => {
  const dispatch = useDispatch();

  // Selectors.
  const currentDate = useSelector((store: AppStore) => store.map.currentDate);
  const farmsList = useSelector((store: AppStore) => store.farms.list);
  const fieldsByFarmId = useSelector((store: AppStore) => store.map.fieldsByFarmId);
  const fieldGeometries = useSelector((store: AppStore) => store.map.fieldGeometries);
  const cropPerformance = useSelector((store: AppStore) => store.cropPerformance);
  const currentFarmId = useSelector((store: AppStore) => store.global.currentGroupId);
  const cropTypes = useSelector((s: AppStore) => s.global.cropTypes);
  const cropVarietyColors = useSelector((s: AppStore) => s.map.cropVarietyColors);
  const isReportView = useSelector((store: AppStore) => store.global.isReportView);
  const cropPerformanceFilter = useSelector((s: AppStore) => s.cropPerformanceFilter);
  const cropPerformanceLoaded = useSelector((s: AppStore) => s.cropPerformance.loaded);

  // Don't request the same geometries twice.
  const [geometriesRequest, setGeometriesRequest] = useState('');

  useEffect(
    function parseUrlParams() {
      if (!farmsList.length) return; // early return

      const farmIds = getFarmIdsFromUrl();
      const cardFromUrl = getGetURLParam('card');

      if (farmIds?.length) {
        dispatch(toggleCropPerformanceFarms(farmIds, true));
        !isReportView && setGetParamToURL('farmIds', null);
      }

      if (isCPFilter(cardFromUrl)) {
        dispatch(setRepresentation(cardFromUrl));
        !isReportView && setGetParamToURL('card', null);
      }
    },
    [farmsList]
  );

  useEffect(
    function parseUrlParams() {
      if (!cropPerformance.records.length || !cropPerformanceLoaded) {
        // wait until farms and seasons are loaded
        return;
      }
      const urlCropTypes = getArrayValuesFromUrl('cropType');
      const cropVariety = getGetURLParam('cropVariety');

      const preparedCropTypes =
        urlCropTypes?.length &&
        urlCropTypes.filter(
          cropType =>
            cropTypes[cropType] && // it is existing crop type
            cropPerformance.records.find(record => record.cropType === cropType) // and
        );

      const cropVarietySeason =
        cropVariety && cropPerformance.records.find(record => record.cropVariety === cropVariety);

      if (cropVarietySeason) {
        !isReportView && bulkSetGetParamToURL({cropType: null, cropVariety: null}); // clear URL params

        const varietyLabel = cropSubtype2Label(
          cropVarietySeason.cropType,
          cropVarietySeason.cropVariety
        );
        dispatch(
          toggleFilter({
            type: CPFilterType.CROP_TYPE,
            value: cropVarietySeason.cropType,
            color: cropTypes[cropVarietySeason.cropType].color,
            stage: 1,
          })
        );
        dispatch(
          toggleFilter({
            type: CPFilterType.CROP_VARIETY,
            value: cropVariety,
            color: cropVarietyColors[cropVariety],
            label: varietyLabel,
            stage: 1,
          })
        );
      } else if (preparedCropTypes?.length) {
        !isReportView && setGetParamToURL('cropType', null);
        preparedCropTypes.forEach(cropType => {
          if (
            cropPerformanceFilter.filters[CPFilterType.CROP_TYPE].find(
              ({value}) => value === cropType
            )
          )
            // prevent removing toggling filter off when several farms are provided
            return;

          dispatch(
            toggleFilter({
              type: CPFilterType.CROP_TYPE,
              value: cropType,
              color: cropTypes[cropType].color,
              stage: 1,
            })
          );
        });
      }
    },
    [cropPerformance.records, cropPerformanceLoaded]
  );

  // Fetch geometries.
  useEffect(() => {
    const fields = Object.keys(fieldsByFarmId)
      .filter(farmId => cropPerformance.farms[Number(farmId)])
      .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)]));
    const geometriesToRequest = fields.filter(f => !fieldGeometries[f.MD5]).map(f => f.MD5);
    const newGeometriesRequest = geometriesToRequest.join(',');
    if (!geometriesToRequest.length || newGeometriesRequest === geometriesRequest) {
      return;
    }
    setGeometriesRequest(newGeometriesRequest);
    dispatch(loadFieldGeometries(geometriesToRequest));
  }, [fieldsByFarmId, cropPerformance.farms]);

  // Fetch crop performance when date or farm is changed or crop performance mount.
  useEffect(() => {
    if (currentFarmId && currentDate && farmsList.length) {
      dispatch(loadCropPerformance());
    }
  }, [currentFarmId, currentDate, farmsList]);

  useEffect(() => {
    dispatch(loadStressedFields());
  }, [currentFarmId]);

  // Fetch crop performance info, such as crop status buckets and colors.
  useEffect(() => {
    dispatch(loadInfo());
  }, []);

  // Fulfill the crop performance "records" based on the already fetched crop performance data.
  useEffect(() => {
    const records = reduceCsgsForDate(
      cropPerformance.farms,
      moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE)
    );
    dispatch(setRecords(records));

    const twoWeeksAgoRecords = reduceCsgsForDate(
      cropPerformance.farms,
      moment(currentDate, GLOBAL_APP_DATE_FORMAT).subtract(2, 'weeks').format(SERVER_FORMAT_DATE)
    );
    dispatch(twoWeeksAgoSetRecords(twoWeeksAgoRecords));
  }, [cropPerformance.farms, currentDate]);

  return <CropPerformancePanelView />;
};

export default CropPerformance;
