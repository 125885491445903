import {t} from 'i18n-utils';
import React, {Fragment, useMemo} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'react-md';
import {setFeature, setCurrentFieldId} from '../actions';
import {getGetURLParam, isAdmin} from '_utils';
import {checkFieldTagExist, isMapFeature} from '../utils';
import {history} from 'store';
import pathToRegexp from 'path-to-regexp';
import {showNote} from '_actions';
import {getFeatureLinks} from './mini-menu-links';
import {ALLOWED_WHOLE_FARM_FEATURES} from '_constants';
import {FieldTag} from '../types';
import {TFeature} from '../../../types';
import config from '_environment';
// import NotificationSignal from 'components/NotificationSignal';
import Mixpanel from '_utils/mixpanel-utils';
import {Ln} from 'components';
import {AddingFieldsFromMapSteps, AddingFieldsPreviewSteps} from 'modules/add-fields.module';
import './mini-menu.scss';
import {useAppSelector} from '_hooks';
import {AppStore} from '../../../reducers';
import {DialogType} from '../../../_reducers/dialog';
import {CarbonUserGuideButton} from '../../carbon/panel/carbon-user-guide-button';

type Props = {
  ln: string;
};

const MiniMenu = ({ln}: Props) => {
  const dispatch = useDispatch();
  const currentGroupId = useAppSelector(store => store.global.currentGroupId);
  const feature = useAppSelector(store => store.map.feature);
  const farms = useAppSelector(store => store.farms.list);
  const mapDidMount = useAppSelector(store => store.global.mapDidMount);
  const isWholeFarmView = useAppSelector(store => store.map.wholeFarm.isWholeFarmView);
  const fields = useAppSelector(store => store.map.fields);
  const treeDetection = useAppSelector(store => store.map.treeDetection);
  // const tabNotifications = useAppSelector((store) => store.global.tabNotifications);
  const addFieldCurrentStep = useAppSelector(s => s.addFields.addFieldCurrentStep);
  const user = useAppSelector(s => s.login.user);
  const currentDialog = useAppSelector(s => s.dialog.currentDialog);

  const hasFarms = isAdmin() || farms.length > 0 || user.groupIds.length > 0;
  const links = useMemo(() => {
    return getFeatureLinks(config.featurePack, user, hasFarms);
  }, [user, hasFarms]);

  const disabledTabs = useMemo(() => {
    if (currentDialog === DialogType.CarbonSuccessfulSigned) {
      // allow only to log-out
      return links.filter(l => l.feature !== 'log-out').map(l => l.feature);
    }
    return [];
  }, [currentDialog, links]);

  const onSetFeature = (selectedFeature: TFeature, ln = '') => {
    if (selectedFeature !== feature) {
      if (feature === 'log-out') {
        Mixpanel.logout();
      } else {
        Mixpanel.tabChange(selectedFeature);
      }
    }

    if (isMapFeature(selectedFeature) && !mapDidMount) {
      history.push(`/maps/${currentGroupId ? currentGroupId : ''}`);
    }

    if (!isMapFeature(selectedFeature)) {
      const toPath = pathToRegexp.compile(ln);
      history.push(toPath({groupId: currentGroupId}));
      return;
    }

    dispatch(setFeature(selectedFeature));
  };

  const isNotWholeFarmFeature = (feature: TFeature) => {
    const includes = ALLOWED_WHOLE_FARM_FEATURES.includes(feature);
    if (feature === 'zoning' && treeDetection.layerType !== 'default') return false; // allow whole farm zoning for tree detection
    return isWholeFarmView && !includes;
  };

  const tabOnClick = (feature: TFeature, ln: string) => {
    let message = '';
    if (
      feature !== 'log-out' &&
      [...AddingFieldsFromMapSteps, ...AddingFieldsPreviewSteps].includes(addFieldCurrentStep)
    ) {
      dispatch(
        showNote({
          title: t({id: 'Not allowed'}),
          message: t({id: 'You cannot change tabs during adding fields process'}),
          level: 'warning',
        })
      );
      return;
    }

    if (isNotWholeFarmFeature(feature)) {
      dispatch(setCurrentFieldId(fields[0].ID));
      onSetFeature(feature, ln);
      message = t({id: 'You can only use this tab when one field is selected'});
    } else {
      return onSetFeature(feature, ln);
    }

    if (message) {
      dispatch(
        showNote({
          title: t({id: 'Not allowed'}),
          message,
          level: 'warning',
        })
      );
    }
  };

  const isHasAnomalyTag =
    // FSB-4061 HACK: Unblocking Victor to test anomalies wihtout the field tag, but using the url flag
    // getGetURLParam('premium-anomalies')
    checkFieldTagExist(FieldTag.AnomalyDetection) || getGetURLParam('premium-anomalies');

  return (
    <div className="map-bar__mini-menu">
      <ul className="mini-menu onboarding__tabs-nav">
        {links.map((l, i) =>
          l.feature === 'faq' ? (
            <li
              key={i}
              className={cn('mini-menu__item', {
                'mini-menu__item--active': feature === l.feature,
                'mini-menu__item--disabled': disabledTabs.includes(l.feature),
              })}
            >
              <Ln key={`mini-menu-item-${i}`} blank external href="https://help.flurosense.com/">
                <Button
                  id={`mini-menu-${l.feature}`}
                  className="tab-btn"
                  iconEl={l.icon}
                  tooltipLabel={l.title}
                  tooltipPosition="left"
                  icon
                />
              </Ln>
            </li>
          ) : (
            <Fragment key={`mini-menu-item-${i}`}>
              {l.topSpacer && <div className="mini-menu__spacer" />}
              <li
                className={cn({
                  'mini-menu__item': true,
                  'mini-menu__item--disabled': disabledTabs.includes(l.feature),
                  'mini-menu__item--active':
                    (mapDidMount && feature === l.feature) || ln.includes(l.ln),
                })}
                data-cy={`mini-menu-${l.feature}`}
              >
                {/* TODO: will remove in some time */}
                {/*{tabNotifications?.[l.feature]?.hasUpdates ? (*/}
                {/*  <NotificationSignal value={tabNotifications?.[l.feature]?.count} />*/}
                {/*) : null}*/}
                <Button
                  id={`mini-menu-${l.feature}`}
                  className="tab-btn"
                  onClick={tabOnClick.bind(this, l.feature, l.ln)}
                  iconEl={l.icon}
                  icon
                  tooltipLabel={isHasAnomalyTag && l.feature === 'crop' ? l.taggedTitle : l.title}
                  tooltipPosition="left"
                />
              </li>
              {l.bottomSpacer && <div className="mini-menu__spacer" />}
            </Fragment>
          )
        )}
      </ul>

      {config.featurePack === 'carbon' && <CarbonUserGuideButton />}
    </div>
  );
};

export default MiniMenu;
