import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GeoJSON} from 'react-leaflet';
import {genKey} from '_utils';
import {AppStore} from '../../../../../reducers';
import {Feature} from 'geojson';
import L from 'leaflet';
import {updateNRxRecommendationProps} from '../../../actions/zoning-actions';

const NRecommendationOverlay = () => {
  const dispatch = useDispatch();
  const {toggled, nrxResult, nrxTabRate, selectedObjective} = useSelector(
    (s: AppStore) => s.map.nRecommendation
  );
  const feature = useSelector((s: AppStore) => s.map.feature);

  const onEachFeature = (feature: Feature, layer: L.Path) => {
    // select a zones
    layer.on({
      click: () => {
        dispatch(
          updateNRxRecommendationProps({
            selected: !feature.properties.selected,
            id: feature.properties.id,
          })
        );
      },
    });
    // color zone
    layer.setStyle({
      fillOpacity: 1,
      color: feature.properties.color,
    });
  };
  const toShowOverlay =
    toggled && feature === 'zoning' && nrxResult[nrxTabRate][selectedObjective].type;
  if (!toShowOverlay) return null;

  const dataToDisplay = {
    ...nrxResult[nrxTabRate][selectedObjective],
    features: nrxResult[nrxTabRate][selectedObjective].features.filter(
      (f: any) => f.properties.merged !== 'initial'
    ), // filter initial merged zones
  };

  return <GeoJSON key={genKey()} onEachFeature={onEachFeature} data={dataToDisplay} />;
};

export default NRecommendationOverlay;
