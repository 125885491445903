import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {Button, FontIcon} from 'react-md';

const GoHomeBtn = ({onClick}: {onClick: () => void}) => {
  return (
    <Button
      onClick={() => onClick()}
      flat
      iconEl={<FontIcon iconClassName={'fas fa-home'} />}
      className={'locate-to-farm'}
    >
      {' '}
      <FormattedMessage id="Go to field" defaultMessage="Go to field" />
    </Button>
  );
};

export default GoHomeBtn;
