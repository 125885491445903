import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {setGlobalParam} from '_actions';
import {useAppSelector} from '_hooks';
import {isAgxFarm} from '_utils';
import {loadFields} from '../actions';

export const LoadFields: React.ComponentType<RouteComponentProps<{groupId: string}>> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const farms = useAppSelector(s => s.farms.list);
  const farmId = parseInt(match.params.groupId);
  useEffect(() => {
    let currentFarm = farms.find(g => g.id === farmId);

    if (!currentFarm) {
      return;
    }

    dispatch(loadFields(farmId));
    const growerName = isAgxFarm(currentFarm) ? currentFarm.growerName : '';
    dispatch(setGlobalParam('currentGroupId', currentFarm.id));
    dispatch(setGlobalParam('growerName', growerName));
  }, [farms, farmId]);

  return null;
};
