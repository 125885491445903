import moment from 'moment';
import {FieldSystemProp} from '../containers/map/features/farm/new-fields/types';
import {Dispatch} from 'redux';
import turfArea from '@turf/area';
import booleanEqual from '@turf/boolean-equal';
import {AppStore} from '../reducers';
import {disableEdit} from '../containers/map/utils/draw';
import {getFarmById, pointInside, removeDuplicates} from '../_utils';
import {USCoordinates} from '../_constants';
import {FarmApi, KmlApi} from '../_api';
import {t} from '../i18n-utils';
import {DialogType, toggleDialog as toggleGlobalDialog, toggleDialog} from '../_reducers/dialog';
import axios from 'axios';
import {AsyncStatusType, setRequestStatus, Status} from './ui-helpers';
import {showNote} from '../_actions';
import {parseGeometryFile} from '../containers/map/utils/file';
import {getNonPolygonMessage, handleFeatureCollection, pointInUS} from '../_utils/geometry';
import {reportError} from '../containers/error-boundary';
import {saveFarm, selectFarm} from '../containers/farm/actions';
import {Farm, Field} from '../containers/map/types';
//@ts-ignore
import tokml from 'tokml';
import config from '../_environment';
import {loadFields, setCurrentFieldId} from '../containers/map/actions';
import convert from 'convert-units';
enum ActionType {
  SetFieldPropsMapping = '@add-fields/mapping/set',
  SetFieldPropMapping = '@add-fields/mapping/prop/set',
  SetUploadFields = '@add-fields/upload-fields/set',
  RemoveUploadFields = '@add-fields/upload-fields/remove',
  SetNotUploadFields = '@add-fields/not-uploaded-fields/set',
  SetUploadedFieldFilesNames = '@add-fields/uploaded-field-files/set',
  SetFarmsFieldsAddedTo = '@add-fields/farms-fields-added-to/set',
  SetUploadedFieldsNumber = '@add-fields/uploaded-fields-number/set',
  SetUploadFieldProp = '@add-fields/upload-fields-property/set',
  BulkSetUploadFieldsProp = '@add-fields/upload-fields-property/bulk-set',
  UpdateIdsUploadFields = '@add-fields/upload-field-ids/update',
  SetUploadedFieldsValueFromMappingProps = '@add-fields/upload-fields-props-from-papping/set',
  AddNewFieldGeometry = '@add-fields/new-field-geometry/add',
  RemoveNewFieldGeometry = '@add-fields/new-field-geometry/remove',
  SetPropNewFieldGeometry = '@add-fields/prop-new-field-geometry/set',
  BulkSetPropNewFieldGeometry = '@add-fields/bulk-prop-new-field-geometry/set',
  AddFieldChangeCurrentStep = '@add-fields/change-current-step',
  SetCLUFieldBoundaries = '@add-fields/CLU-field-boundaries/set',
  RemoveCLUFieldBoundaries = '@add-fields/CLU-field-boundaries/remove',
  UpdateCLUFieldBoundariesProp = '@add-fields/CLU-field-boundary/update-prop',
  BulkUpdateCLUFieldBoundariesProp = '@add-fields/CLU-field-boundaries/bulk-update-prop',
}

export type Mapping = {[key: string]: string};

export type AddNewFieldsStore = {
  propsMapping: Mapping;
  geoJsonFiles: GeoJSON.Feature[];
  notUploadedFields: string[];
  uploadedFieldFilesNames: string[];
  farmsFieldsAddedTo: {id: number; name: string; isNew?: boolean}[];
  uploadedFieldsNumber: number;
  drewFieldsGeometries: Array<any>; // used to store drew fields
  addFieldCurrentStep: AddFieldCurrentStep;
  cluFieldBoundaries: CLUFieldBoundary[];
};

export type CLUFieldBoundary = GeoJSON.Polygon & {
  properties: CLUFieldBoundaryProps;
};

export type CLUFieldBoundaryProps = {
  id: string;
  [FieldSystemProp.FieldName]?: string;
  selectedForProcessing?: boolean; // represents if the geometry will be shown during the 'view-selected-boundaries' step
  [FieldSystemProp.Area]?: number;
  [FieldSystemProp.Checked]: boolean;
  [FieldSystemProp.FarmId]?: number;
  [FieldSystemProp.NewFarmName]?: number;
};

export type AddFieldCurrentStep =
  | 'add-fields' // the default one
  | 'search-location'
  | 'zoom-is-too-low'
  | 'select-boundaries'
  | 'view-selected-boundaries'
  | 'draw-fields'
  | 'view-drew-fields'
  | 'select-files-to-upload'
  | 'parse-uploading-files'
  | 'view-fields-from-files'
  | 'complete';

export const AddingFieldsFromMapSteps = [
  'zoom-is-too-low',
  'search-location',
  'select-boundaries',
  'draw-fields',
];

export const AddingFieldsPreviewSteps: AddFieldCurrentStep[] = [
  'view-fields-from-files',
  'view-drew-fields',
  'view-selected-boundaries',
];

export const AddingFieldsZoomThreshold = 13.5;

const initialState: AddNewFieldsStore = {
  propsMapping: {farmName: '__currentFarm__', fieldName: FieldSystemProp.FileName, growerName: ''},
  geoJsonFiles: [],
  notUploadedFields: [],
  uploadedFieldFilesNames: [],
  farmsFieldsAddedTo: [],
  uploadedFieldsNumber: 0,
  drewFieldsGeometries: [],
  addFieldCurrentStep: 'add-fields',
  cluFieldBoundaries: [],
};

export const setFieldPropMapping = (propName: string, value: string) => ({
  type: ActionType.SetFieldPropMapping,
  propName,
  value,
});

export const setFieldPropsMapping = (mapping: Mapping) => ({
  type: ActionType.SetFieldPropsMapping,
  mapping,
});

export const setUploadFields = (fields: GeoJSON.Feature[]) => ({
  type: ActionType.SetUploadFields,
  fields,
});

export const removeUploadFields = (ids: number[]) => ({
  type: ActionType.RemoveUploadFields,
  ids,
});

export const setNotUploadedFields = (fieldNames: string[]) => ({
  type: ActionType.SetNotUploadFields,
  fieldNames,
});

export const setUploadedFieldFilesNames = (names: string[]) => ({
  type: ActionType.SetUploadedFieldFilesNames,
  names,
});

export const setFarmsFieldsAddedTo = (farms: {id: number; name: string; isNew?: boolean}[]) => ({
  type: ActionType.SetFarmsFieldsAddedTo,
  farms,
});

export const setUploadedFieldsNumber = (fieldsNumber: number) => ({
  type: ActionType.SetUploadedFieldsNumber,
  fieldsNumber,
});

export const setUploadFieldProp = (id: string | number, prop: string, value: any) => ({
  type: ActionType.SetUploadFieldProp,
  id,
  prop,
  value,
});

export const bulkSetUploadFieldsProp = (ids: string[] | number[], prop: string, value: any) => ({
  type: ActionType.BulkSetUploadFieldsProp,
  ids,
  prop,
  value,
});

export const setFieldsValueFromMappingProps = (mappingProp: 'fieldName' | 'farmName') => ({
  type: ActionType.SetUploadedFieldsValueFromMappingProps,
  mappingProp,
});

export const addNewFieldGeometry = (geoJSON: any) => (
  dispatch: Dispatch<any>,
  getState: () => AppStore
) => {
  const currentFarmId = getState().map.group.id;
  geoJSON.properties[FieldSystemProp.Area] = convert(turfArea(geoJSON)).from('m2').to('ha');
  geoJSON.properties[FieldSystemProp.Checked] = true;
  geoJSON.properties[FieldSystemProp.FarmId] = currentFarmId || 0;

  dispatch({
    type: ActionType.AddNewFieldGeometry,
    geoJSON,
  });
};

export const removeNewFieldsGeometry = (ids?: number[]) => (
  dispatch: any,
  getState: () => AppStore
) => {
  disableEdit();
  //@ts-ignore
  if (window.leafletElement) {
    const drewGeometriesIds = getState().addFields.drewFieldsGeometries.map(f => f.properties.id);
    //@ts-ignore
    window.leafletElement.eachLayer(l => {
      if (ids ? ids.includes(l.fluroGeometryID) : drewGeometriesIds.includes(l.fluroGeometryID))
        //@ts-ignore
        window.leafletElement.removeLayer(l);
    });
  }
  dispatch({
    type: ActionType.RemoveNewFieldGeometry,
    ids,
  });
};

export const setPropNewFieldGeometry = (id: number, prop: any, value: any) => ({
  type: ActionType.SetPropNewFieldGeometry,
  id,
  prop,
  value,
});

export const bulkSetPropNewFieldGeometry = (ids: number[], prop: any, value: any) => ({
  type: ActionType.BulkSetPropNewFieldGeometry,
  ids,
  prop,
  value,
});

export const addFieldChangeCurrentStep = (step: AddFieldCurrentStep) => (
  dispatch: any,
  getState: () => AppStore
) => {
  if (getState().addFields.addFieldCurrentStep !== step)
    dispatch({
      type: ActionType.AddFieldChangeCurrentStep,
      step,
    });
};

export const removeCLUFieldBoundaries = (ids: number[]) => ({
  type: ActionType.RemoveCLUFieldBoundaries,
  ids,
});

export const addCLUFieldBoundaries = (fieldBoundaries: CLUFieldBoundary[]) => ({
  type: ActionType.SetCLUFieldBoundaries,
  fieldBoundaries,
});

export const clearCLUFieldBoundaries = () => ({
  type: ActionType.SetCLUFieldBoundaries,
  fieldBoundaries: [] as CLUFieldBoundary[],
});

export const updateCLUFieldBoundariesProp = (
  id: string | number,
  prop: keyof CLUFieldBoundaryProps,
  value: any
) => ({
  type: ActionType.UpdateCLUFieldBoundariesProp,
  id,
  prop,
  value,
});

export const bulkUpdateCLUFieldBoundariesProp = (
  ids: string[] | number[],
  prop: keyof CLUFieldBoundaryProps,
  value: any
) => ({
  type: ActionType.BulkUpdateCLUFieldBoundariesProp,
  ids,
  prop,
  value,
});

export const checkPointForFieldBoundaries = (lat: number, lon: number) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const addFieldDialogOpened = getState().dialog.currentDialog === DialogType.AddNewField;
  const currentFieldBoundaries = getState().addFields.cluFieldBoundaries;
  const currentFarmId = getState().map.group.id;

  const switchBackToDrawing = () => {
    const currentSelectedFieldBoundaries = getState().addFields.cluFieldBoundaries.filter(
      b => b.properties[FieldSystemProp.Checked]
    );
    if (!currentSelectedFieldBoundaries.length) {
      // switch back to drawing mode, but only if we don't have selected fields
      dispatch(addFieldChangeCurrentStep('draw-fields'));
    }
    dispatch(setRequestStatus(AsyncStatusType.cluFieldBoundaries, Status.Todo));
    !addFieldDialogOpened && dispatch(toggleDialog(DialogType.AddNewField)); // open only if closed
  };
  // dive into add a field
  if (pointInUS(lat, lon)) {
    dispatch(setRequestStatus(AsyncStatusType.cluFieldBoundaries, Status.Pending));
    KmlApi.retrieveCLUFieldsBoundaries(lat, lon)
      .then(({data}) => {
        if (data.result?.geometries?.length) {
          dispatch(setRequestStatus(AsyncStatusType.cluFieldBoundaries, Status.Done));
          const prevSelectedFieldBoundaries = currentFieldBoundaries.filter(
            boundary => boundary.properties[FieldSystemProp.Checked]
          );

          const filteredFieldPolygons: CLUFieldBoundary[] = data.result.geometries.filter(
            (newBoundary: GeoJSON.Polygon) =>
              !prevSelectedFieldBoundaries.find(oldBoundary =>
                booleanEqual(oldBoundary, newBoundary)
              )
          );

          const fieldBoundaries: CLUFieldBoundary[] = [
            ...prevSelectedFieldBoundaries,
            ...filteredFieldPolygons,
          ].map((fieldBoundary, index: number) => {
            const fieldId = `Field ${index + 1}`;
            const properties = fieldBoundary?.properties || ({} as CLUFieldBoundaryProps);
            return {
              ...fieldBoundary,
              properties: {
                id: fieldId,
                [FieldSystemProp.FieldName]: properties[FieldSystemProp.FieldName] || fieldId, // try get the the prev value first
                [FieldSystemProp.FarmId]: properties[FieldSystemProp.FarmId] // try pick the prev value
                  ? properties[FieldSystemProp.FarmId]
                  : currentFarmId || 0,
                [FieldSystemProp.Area]:
                  properties[FieldSystemProp.Area] || // try get the the prev value first
                  convert(turfArea(fieldBoundary)).from('m2').to('ha'),
                [FieldSystemProp.Checked]: properties[FieldSystemProp.Checked] || false, // try get the the prev value first
              },
            };
          });
          // set field boundaries and activate its mode
          dispatch(addCLUFieldBoundaries(fieldBoundaries));
          dispatch(addFieldChangeCurrentStep('select-boundaries'));
          !addFieldDialogOpened && dispatch(toggleDialog(DialogType.AddNewField)); // open only if closed
        } else {
          switchBackToDrawing();
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          switchBackToDrawing();
        }
      });
  } else {
    switchBackToDrawing();
  }
};

export const uploadFieldsFiles = (files: File[]) => (dispatch: any, getState: () => AppStore) => {
  const currentFarmId = getState().map.group.id;
  const okFiles = files.filter(f => f.type);
  const unsupportedFiles = files.filter(f => !f.type);
  const errors: any[] = [];

  if (unsupportedFiles.length) {
    unsupportedFiles.forEach(f => {
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t(
            {
              id: 'Unsupported file format. Try uploading a .zip version of the file instead name',
            },
            {name: f.name}
          ),
          level: 'warning',
        })
      );
    });
  }

  if (!okFiles.length) {
    return;
  }

  dispatch(setUploadFields([]));
  dispatch(setUploadedFieldFilesNames(okFiles.map(f => f.name)));
  dispatch(setRequestStatus(AsyncStatusType.parseUploadedFields, Status.Pending));

  Promise.all(okFiles.map(parseGeometryFile))
    .then(result => {
      const validGeometries: any = [];
      const tooSmallGeometries: string[] = [];
      const nonPolygonGeometries: string[] = [];

      const featureCollections = result.reduce((acc, r) => {
        return [...acc, ...(Array.isArray(r.features) ? r.features : [r.features])];
      }, []);

      featureCollections.forEach(gj => {
        if (!gj) {
          return;
        }

        try {
          if (Array.isArray(gj)) {
            gj.forEach(gj =>
              handleFeatureCollection(
                gj,
                validGeometries,
                tooSmallGeometries,
                nonPolygonGeometries,
                errors,
                currentFarmId
              )
            );
          } else {
            handleFeatureCollection(
              gj,
              validGeometries,
              tooSmallGeometries,
              nonPolygonGeometries,
              errors,
              currentFarmId
            );
          }
        } catch (e) {
          console.error(`Error uploading field files during handleFeatureCollection: ${e.message}`);
        }
      });

      const parsingErrors = result.reduce((acc, r) => [...acc, ...r.errors], []);

      parsingErrors.forEach(e => {
        dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: e,
            level: 'warning',
          })
        );
      });

      // save geometries that wasn't uploaded to display them on complete step
      dispatch(setNotUploadedFields([...nonPolygonGeometries, ...tooSmallGeometries]));

      if (nonPolygonGeometries.length) {
        const {error, note} = getNonPolygonMessage(nonPolygonGeometries, 'fields');
        reportError(error);
        dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: note,
            level: 'warning',
          })
        );
      }

      if (tooSmallGeometries.length) {
        const fields = `Field${tooSmallGeometries.length > 1 ? 's' : ''}`;
        const are = tooSmallGeometries.length > 1 ? 'are' : 'is';
        dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: `${fields} ${tooSmallGeometries.join(
              ', '
            )} ${are} too small (< 0.01 ha) and won’t be uploaded`,
            level: 'warning',
          })
        );
      }
      dispatch(setUploadFields(validGeometries));

      if (validGeometries.length) {
        // move to the next step
        dispatch(addFieldChangeCurrentStep('parse-uploading-files'));
      }

      dispatch(setRequestStatus(AsyncStatusType.parseUploadedFields, Status.Done));
    })
    .catch((errors: string[]) => {
      // Should never happen, because parseGeometryFile resolves {features, errors},
      // to avoid failing the batch of files because of one failing file.
      dispatch(setRequestStatus(AsyncStatusType.parseUploadedFields, Status.Done));
      errors.forEach(e => {
        dispatch(
          showNote({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message: e,
            level: 'warning',
          })
        );
      });
    });
};

export const uploadFields = () => async (dispatch: any, getState: () => AppStore) => {
  const {geoJsonFiles} = getState().addFields;

  dispatch(setRequestStatus(AsyncStatusType.uploadingFieldsToBackend, Status.Pending));

  await dispatch(createNotExistFarms(geoJsonFiles));

  const updatedGeoJsonFiles = getState().addFields.geoJsonFiles; // get the updated by createNotExistFarms() fields

  return FarmApi.saveFields(
    updatedGeoJsonFiles.map(field => {
      return {
        farm_id: field.properties[FieldSystemProp.FarmId],
        name: `${field.properties[FieldSystemProp.FieldName]}`,
        kml: tokml(field),
      };
    })
  )
    .then(data => dispatch(onCompleteUpload(data)))
    .catch(err => dispatch(onErrorUpload(err)));
};

const onCompleteUpload = ({data}: any) => async (dispatch: any, getState: () => AppStore) => {
  const store = getState();
  const {geoJsonFiles} = store.addFields;

  const result = data?.result || [];
  const fieldsToUploadToDifferentFarm = [] as any; // fields uploaded to a different farm, can be used to redirect a user to a different farm
  let fieldUploadedToTheCurrentFarm = null as any; // if at least one field was uploaded to the current farm, load new fields and select this field
  if (!result.length) {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'No fields were uploaded.'}),
        level: 'warning',
      })
    );
    return;
  }

  const getFieldToSwitchTo = (name: string) => {
    return result.map((f: any) => f.field).find((f: Field) => f.Name === name);
  };

  const getFieldName = (field: GeoJSON.Feature) => {
    return (
      field.properties[FieldSystemProp.FieldName] || field.properties[FieldSystemProp.FileName]
    );
  };

  geoJsonFiles.forEach(f => {
    if (
      // if field is uploaded to the current farm
      !fieldUploadedToTheCurrentFarm &&
      f.properties[FieldSystemProp.FarmId] === store.map.group.id
    ) {
      fieldUploadedToTheCurrentFarm = f;
    } else {
      // field is uploaded to a different from current farm
      fieldsToUploadToDifferentFarm.push(f);
    }
  });

  dispatch(setUploadedFieldsNumber(geoJsonFiles.length));

  if (config.featurePack === 'carbon') {
    // skip the complete step for the carbon
    dispatch(toggleGlobalDialog(DialogType.AddNewField, false)); // hide the dialog
    geoJsonFiles.length > 0 &&
      dispatch(
        showNote({
          title: t({id: 'note.success'}),
          message: t(
            {
              id: 'The {count} successfully added.',
            },
            {count: geoJsonFiles.length}
          ),
          level: 'success',
        })
      );
  }

  if (fieldUploadedToTheCurrentFarm) {
    await dispatch(loadFields(store.global.currentGroupId));
    dispatch(
      setCurrentFieldId(
        getFieldToSwitchTo(getFieldName(fieldUploadedToTheCurrentFarm))?.ID,
        false,
        true
      )
    );
  } else {
    await dispatch(selectFarm(fieldsToUploadToDifferentFarm[0].properties[FieldSystemProp.FarmId]));
    dispatch(
      setCurrentFieldId(
        getFieldToSwitchTo(getFieldName(fieldsToUploadToDifferentFarm[0]))?.ID,
        false,
        true
      )
    );
  }
  dispatch(setUploadFields([]));

  if (config.featurePack !== 'carbon') {
    dispatch(addFieldChangeCurrentStep('complete'));
  }

  dispatch(setUploadedFieldFilesNames([]));
  dispatch(setRequestStatus(AsyncStatusType.uploadingFieldsToBackend, Status.Done));
};

const onErrorUpload = (err: ErrorEvent) => (dispatch: any) => {
  reportError(`startUpload fields(), err = ${err}`);
  dispatch(setRequestStatus(AsyncStatusType.uploadingFieldsToBackend, Status.Done));
  dispatch(
    showNote({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      message: t({id: 'Fields upload failed error'}, {error: (err.message || err) as string}),
      level: 'error',
    })
  );
};

const createNotExistFarms = (fields: any[]) => async (dispatch: any) => {
  // create new farms before upload fields to them
  const notExistFarms = new Set<{id: string; name: string}>();
  const namesArr: string[] = [];
  const existFarms = [] as {id: number; name: string}[];

  fields.forEach(f => {
    // if farm not selected and farm name exist
    if (f.properties[FieldSystemProp.FarmId]) {
      const selectedFarmId = f.properties[FieldSystemProp.FarmId];
      // add farm to the list of exist farms to display on the complete step
      existFarms.push({
        id: selectedFarmId,
        name: getFarmById(selectedFarmId)?.name,
      });
    } else if (f.properties[FieldSystemProp.NewFarmName]) {
      const farmName = f.properties[FieldSystemProp.NewFarmName];

      notExistFarms.add({id: f.properties.id, name: farmName});

      if (!namesArr.includes(farmName)) {
        namesArr.push(farmName);
      }
    }
  });

  const farmNames = Array.from(notExistFarms);

  const result = await Promise.all(
    namesArr.map(name =>
      dispatch(
        saveFarm({
          id: 0,
          name,
        })
      )
    )
  );

  dispatch(
    setFarmsFieldsAddedTo([
      ...removeDuplicates(existFarms, 'name'),
      ...removeDuplicates(result, 'name').map((farm: Farm) => ({
        id: farm.id,
        name: farm.name,
        isNew: true,
      })),
    ])
  );

  farmNames.forEach((f: any) => {
    const index = namesArr.findIndex(name => name === f.name);
    // @ts-ignore //todo proper async dispatch support
    dispatch(setUploadFieldProp(f.id, FieldSystemProp.FarmId, result[index].id));
  });
};

export const saveDrawFields = () => async (dispatch: any, getState: () => AppStore) => {
  const drewFieldsGeometries = getState().addFields.drewFieldsGeometries;

  dispatch(
    setUploadFields(
      drewFieldsGeometries.map((field: GeoJSON.Feature) => {
        return {
          ...field,
          properties: {
            ...field.properties,
            [FieldSystemProp.FieldName]:
              field.properties[FieldSystemProp.FieldName] || `Field ${field.properties.id}`,
          },
        };
      })
    )
  );
  await dispatch(uploadFields());
  dispatch(removeNewFieldsGeometry());
};

export const saveSelectedBoundaries = () => async (dispatch: any, getState: () => AppStore) => {
  const cluFieldBoundaries = getState().addFields.cluFieldBoundaries;

  dispatch(
    setUploadFields(
      cluFieldBoundaries
        .filter(boundary => boundary.properties[FieldSystemProp.Checked])
        .map(field => {
          return {
            type: 'Feature',
            geometry: field,
            properties: field.properties,
          } as GeoJSON.Feature;
        })
    )
  );
  await dispatch(uploadFields());
  dispatch(clearCLUFieldBoundaries());
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.UpdateIdsUploadFields: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map((feature: GeoJSON.Feature) => {
          // search PARSED farms with the same name
          if (
            feature.properties[action.prop] === action.farmName &&
            !feature.properties[FieldSystemProp.FarmId]
          ) {
            return {
              ...feature,
              properties: {...feature.properties, [FieldSystemProp.FarmId]: action.farmId},
            };
          }

          return feature;
        }),
      };
    }

    case ActionType.SetUploadFieldProp: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map(f => {
          if (f.properties.id === action.id)
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };
    }

    case ActionType.BulkSetUploadFieldsProp: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map(f => {
          if (action.ids.includes(f.properties.id))
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };
    }

    case ActionType.SetUploadFields: {
      return {
        ...state,
        geoJsonFiles: action.fields,
      };
    }

    case ActionType.RemoveUploadFields: {
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.filter(f => !action.ids.includes(f.properties.id)),
      };
    }

    case ActionType.SetNotUploadFields: {
      return {
        ...state,
        notUploadedFields: action.fieldNames,
      };
    }

    case ActionType.SetUploadedFieldFilesNames: {
      return {
        ...state,
        uploadedFieldFilesNames: action.names,
      };
    }

    case ActionType.SetFarmsFieldsAddedTo: {
      return {
        ...state,
        farmsFieldsAddedTo: action.farms,
      };
    }

    case ActionType.SetUploadedFieldsNumber: {
      return {
        ...state,
        uploadedFieldsNumber: action.fieldsNumber,
      };
    }

    case ActionType.SetFieldPropsMapping: {
      return {
        ...state,
        propsMapping: {...action.mapping},
      };
    }

    case ActionType.SetFieldPropMapping: {
      return {
        ...state,
        propsMapping: {
          ...state.propsMapping,
          [action.propName]: action.value,
        },
      };
    }

    case ActionType.SetUploadedFieldsValueFromMappingProps: {
      // updates field values using mapping prop keys and values from the field properties
      return {
        ...state,
        geoJsonFiles: state.geoJsonFiles.map(f => {
          let propertyToUpdate = {};

          // set fields values using propsMapping values
          switch (action.mappingProp) {
            case 'fieldName':
              propertyToUpdate = {
                [FieldSystemProp.FieldName]: f.properties[state.propsMapping.fieldName] || '',
              };
              break;
            case 'farmName':
              const classifiedFarmProp = getFarmById(f.properties[state.propsMapping.farmName])
                ? FieldSystemProp.FarmId
                : FieldSystemProp.NewFarmName;
              propertyToUpdate = {
                [classifiedFarmProp]: f.properties[state.propsMapping.farmName] || '',
              };
              break;
          }
          return {...f, properties: {...f.properties, ...propertyToUpdate}};
        }),
      };
    }

    case ActionType.AddNewFieldGeometry:
      return {
        ...state,
        drewFieldsGeometries:
          state.drewFieldsGeometries.findIndex(
            field => action.geoJSON.properties.id === field.properties.id
          ) === -1
            ? [...state.drewFieldsGeometries, action.geoJSON]
            : state.drewFieldsGeometries.map(field => {
                return action.geoJSON.properties.id === field.properties.id
                  ? {
                      ...field,
                      geometry: {...action.geoJSON.geometry},
                      properties: {
                        ...field.properties,
                        ...action.geoJSON.properties,
                      },
                    }
                  : {...field};
              }),
      };

    case ActionType.RemoveNewFieldGeometry:
      return {
        ...state,
        drewFieldsGeometries: action.ids
          ? state.drewFieldsGeometries.filter(f => !action.ids.includes(f.properties.id))
          : [],
      };

    case ActionType.SetPropNewFieldGeometry:
      return {
        ...state,
        drewFieldsGeometries: state.drewFieldsGeometries.map(f => {
          if (f.properties.id === action.id)
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };

    case ActionType.BulkSetPropNewFieldGeometry:
      return {
        ...state,
        drewFieldsGeometries: state.drewFieldsGeometries.map(f => {
          if (action.ids.includes(f.properties.id))
            return {
              ...f,
              properties: {...f.properties, [action.prop]: action.value},
            };

          return f;
        }),
      };

    case ActionType.AddFieldChangeCurrentStep:
      return {
        ...state,
        addFieldCurrentStep: action.step,
      };

    case ActionType.SetCLUFieldBoundaries:
      return {
        ...state,
        cluFieldBoundaries: action.fieldBoundaries,
      };

    case ActionType.RemoveCLUFieldBoundaries:
      return {
        ...state,
        cluFieldBoundaries: state.cluFieldBoundaries.filter(f =>
          action.ids.length ? !action.ids.includes(f.properties.id) : true
        ),
      };

    case ActionType.UpdateCLUFieldBoundariesProp:
      return {
        ...state,
        cluFieldBoundaries: state.cluFieldBoundaries.map(fieldBoundary => {
          if (fieldBoundary.properties.id === action.id) {
            return {
              ...fieldBoundary,
              properties: {...fieldBoundary.properties, [action.prop]: action.value},
            };
          }
          return fieldBoundary;
        }),
      };

    case ActionType.BulkUpdateCLUFieldBoundariesProp:
      return {
        ...state,
        cluFieldBoundaries: state.cluFieldBoundaries.map(fieldBoundary => {
          if (!action.ids.length || action.ids.includes(fieldBoundary.properties.id)) {
            // !ids.length means all the boundaries
            return {
              ...fieldBoundary,
              properties: {...fieldBoundary.properties, [action.prop]: action.value},
            };
          }

          return fieldBoundary;
        }),
      };

    default:
      return state;
  }
};

export function convertPropertyValue(value: any) {
  if (typeof value === 'object') {
    if (value instanceof Date) {
      return moment(value).format('DD MM YYYY');
    }

    return '';
  }

  return value;
}
