import {t} from 'i18n-utils';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {saveFarm} from 'containers/farm/actions';
import {FluroDialog, FluroButton} from 'components';
import {useAppSelector} from '_hooks';
import {dialogToggle, DialogType} from 'modules/ui-helpers';
import {TextField} from 'react-md';
import {showNote} from '_actions';

const EditFarmNameDialog = () => {
  const dispatch = useDispatch();
  const {farmId, farmName} = useAppSelector(
    state => state.uiHelpers.dialogs[DialogType.editFarmName].payload
  );
  const [newFarmName, setNewFarmName] = useState(farmName || '');

  const onSaveFarmName = async (ev: any) => {
    ev.preventDefault();

    await dispatch(saveFarm({id: farmId, name: newFarmName}));
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: '{type} was successfully saved'}, {type: t({id: 'Name'})}),
        level: 'success',
      })
    );
    onHide();
  };
  const onHide = () => {
    dispatch(dialogToggle(DialogType.editFarmName, false));
  };

  const farmNameError = newFarmName.length > 50 || newFarmName.length < 2;

  return (
    <FluroDialog
      id="farm-edit-name--dialog"
      visible={true}
      isDraggable
      title={t({id: 'Edit farm name'})}
      initialFocus={'#farm-name'}
      focusOnMount
      portal
      onHide={onHide}
      width={400}
    >
      <form onSubmit={onSaveFarmName}>
        <TextField
          id="farm-name"
          label="Farm name"
          placeholder={t({id: 'Farm name'})}
          value={newFarmName}
          onChange={text => setNewFarmName(text)}
          error={farmNameError}
          errorText={t({id: 'Farm name must be range characters'}, {range: '2-50'})}
        />

        <FluroButton
          type={'submit'}
          className={'margin-top-10 element-full-width'}
          disabled={farmNameError}
          raised
          primary
        >
          {t({id: 'Save name'})}
        </FluroButton>
      </form>
    </FluroDialog>
  );
};

export default EditFarmNameDialog;
