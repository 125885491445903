import Server from '../server';

export default class {
  static syncV2(payload: {fieldIds: string[]; farmIds: string[]; growerIds: string[]}) {
    return Server.post(`syncv2/agx`, JSON.stringify(payload), {params: {__skipPreloader: true}});
  }

  static syncWasDone() {
    return Server.post(`events/reset`);
  }

  static getGrowers() {
    return Server.get(`syncv2/agx/growers`, {params: {__skipPreloader: true}});
  }

  static getFarms(growerId: string) {
    return Server.get(`syncv2/agx/grower/${growerId}/farms`, {params: {__skipPreloader: true}});
  }

  static getFields(growerId: string, farmId: string) {
    return Server.get(`syncv2/agx/grower/${growerId}/farm/${farmId}/fields`, {
      params: {__skipPreloader: true},
    });
  }

  static saveZonesToAgX(url: string, geoJSON: any) {
    return Server.post(`nrx/${url}`, geoJSON);
  }
}
