import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {SVGIcon} from 'react-md';
import './index.scss';

type Props = {
  className?: string;
  isChecked: boolean;
  onCheck(val: boolean): void;
  hideText?: boolean;
};

const CustomCheckbox = ({isChecked, onCheck, className = '', hideText}: Props) => {
  return (
    <div className={`custom-checkbox ${className}`} onClick={() => onCheck(!isChecked)}>
      <div className={`custom-checkbox__icon ${isChecked ? 'checked' : ''}`}>
        <SVGIcon>
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
        </SVGIcon>
      </div>
      {!hideText && <div className="custom-checkbox__label">{t({id: 'All'})}</div>}
    </div>
  );
};

export default CustomCheckbox;
