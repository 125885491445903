import {RequestStatus} from 'types';
import {IntegrationsState} from './types';
import {Action} from './actions';
import {ExternalService} from '../../map/types';

export enum ActionType {
  AGWORLD_SYNC_GROWERS = 'integrations/sync-agworld-growers',
  CLIMATE_SYNC_FIELDS = 'integrations/sync-climate-fields',
  EFC_SYNC_GROWERS = 'integrations/sync-efc-growers',
  JOHN_DEERE_SYNC_GROWERS = 'integrations/sync-john-deere-growers',
  AGX_SYNC_GROWERS = 'integrations/sync-agx-growers',
  AGX_TOGGLE_FARMS_IMPORTED = 'integrations/agx-toggle-farms-imported',
  TOGGLE_SELECT_PLATFORMS_VIEW = 'integrations/toggle-select-platforms-view',
  TOGGLE_PLATFORM_SELECTION = 'integrations/toggle-platform-selection',
}

const initialState: IntegrationsState = {
  isSelectPlatformsView: false,
  platforms: {
    [ExternalService.Agrian]: {
      syncStatus: RequestStatus.Idle,
      selected: false,
    },
    [ExternalService.Agworld]: {
      syncStatus: RequestStatus.Idle,
      selected: false,
    },
    [ExternalService.Climate]: {
      syncStatus: RequestStatus.Idle,
      selected: false,
    },
    [ExternalService.Agx]: {
      syncStatus: RequestStatus.Idle,
      farmsImported: false,
      selected: false,
    },
    [ExternalService.EFC]: {
      syncStatus: RequestStatus.Idle,
      selected: false,
    },
    [ExternalService.JohnDeere]: {
      syncStatus: RequestStatus.Idle,
      selected: false,
    },
  },
};

export default (state: IntegrationsState = initialState, action: Action): IntegrationsState => {
  switch (action.type) {
    case ActionType.AGWORLD_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Agworld]: {
            ...state.platforms[ExternalService.Agworld],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.AGX_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Agx]: {
            ...state.platforms[ExternalService.Agx],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.AGX_TOGGLE_FARMS_IMPORTED:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Agx]: {
            ...state.platforms[ExternalService.Agx],
            farmsImported: action.farmsImported,
          },
        },
      };

    case ActionType.CLIMATE_SYNC_FIELDS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.Climate]: {
            ...state.platforms[ExternalService.Climate],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.EFC_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.EFC]: {
            ...state.platforms[ExternalService.EFC],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.JOHN_DEERE_SYNC_GROWERS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [ExternalService.JohnDeere]: {
            ...state.platforms[ExternalService.JohnDeere],
            syncStatus: action.syncStatus,
          },
        },
      };

    case ActionType.TOGGLE_SELECT_PLATFORMS_VIEW:
      return {
        ...state,
        isSelectPlatformsView: action.value,
      };

    case ActionType.TOGGLE_PLATFORM_SELECTION:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [action.platform]: {
            ...state.platforms[action.platform],
            selected: action.value,
          },
        },
      };

    default:
      return state;
  }
};
