import React, {useEffect} from 'react';
import {Marker} from 'react-leaflet';
import {defaultMarkerIcon} from '../icons';
import L from 'leaflet';
import {AddingFieldsZoomThreshold} from 'modules/add-fields.module';
import {useAppSelector} from '_hooks';
import {isAdmin} from '_utils';

type Props = {
  leafletElement: L.Map;
};

const LocationMarker = ({leafletElement}: Props) => {
  const locationMarkerPosition = useAppSelector(s => s.map.locationMarkerPosition);
  const hasFarms =
    isAdmin() || useAppSelector(s => s.farms.list.length > 0 || s.login.user.groupIds.length > 0);

  useEffect(() => {
    if (leafletElement?.getZoom() < AddingFieldsZoomThreshold && hasFarms) {
      leafletElement.setZoom(AddingFieldsZoomThreshold);
    }
  }, [locationMarkerPosition, hasFarms]);

  return leafletElement && locationMarkerPosition[0] && locationMarkerPosition[1] ? (
    <Marker
      ref={l => {
        if (l) {
          //@ts-ignore
          l.fluroId = 'location-marker';
        }
      }}
      position={L.latLng(locationMarkerPosition[0], locationMarkerPosition[1])}
      icon={defaultMarkerIcon}
    />
  ) : null;
};

export default LocationMarker;
