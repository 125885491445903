import FluroSteps from 'components/fluro-steps/fluro-steps-dynamic';
import {t} from 'i18n-utils';
import * as React from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {CarbonStep, setStep} from './carbon-reducer';
import {allowToChangeToTheStep} from './utils';

export const CarbonSteps = () => {
  const dispatch = useAppDispatch();
  const carbon = useAppSelector(s => s.carbon);

  const onStepClick = (step: CarbonStep) => {
    const allowChangeStep = dispatch(allowToChangeToTheStep(step));
    if (allowChangeStep) {
      dispatch(setStep(step));
    }
  };

  return (
    <div className={'carbon-steps-container'}>
      <FluroSteps
        greenSkin
        onItemClick={step => onStepClick(step as CarbonStep)}
        selectedItem={carbon.step}
        items={[
          createItem(CarbonStep.Fields),
          createItem(CarbonStep.CropPractices),
          createItem(CarbonStep.Credits),
        ]}
      />
    </div>
  );
};

const createItem = (value: string) => ({label: t({id: value}), value});
