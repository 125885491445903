import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Button} from 'react-md';
import {Weather} from '../icons';
import {toggleMapButton} from '../actions';
import {IInitialMapState} from '../types';

type Props = ConnectedProps<typeof connector>;

const ToggleWeather = ({toggledButtonOnMap, toggleMapButton}: Props) => {
  const thisButtonName = 'Weather';
  const toggled = toggledButtonOnMap === thisButtonName;
  return (
    <Button
      id="toggle-weather-btn"
      title={t({id: 'Weather', defaultMessage: 'Weather'})}
      className={`map-toggle-button ${toggled ? 'active' : ''}`}
      name="toggle-kml"
      iconEl={<Weather viewBox="0 0 100 100" />}
      floating
      onClick={() => toggleMapButton(thisButtonName)}
      mini
    />
  );
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  toggledButtonOnMap: map.toggledButtonOnMap,
});

const connector = connect(mapStateToProps, {toggleMapButton});

export default connector(ToggleWeather);
