import {t, FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import config from '_environment';
import {ComponentPropsWithoutRef} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {LoginState} from '../login/types';

/**
 * Can be used to manually log suspucious behaviours.
 *
 * @param {string} message
 */

export function reportError(message: string) {
  // @ts-ignore
  window.Sentry && window.Sentry.captureException(new Error(message));
  console.warn(message);
}

type Props = ConnectedProps<typeof connector> &
  ComponentPropsWithoutRef<any> & {expectedError?: boolean}; // expectedError is used when you know the error, but can't fix it (CP benchmark time scale updates)

class ErrorBoundary extends React.Component<Props> {
  componentDidMount(): void {
    this.initSentry();
    this.addListeners();
  }

  componentWillUnmount(): void {
    this.removeListeners();
  }

  componentDidCatch(error: any) {
    if (this.props.expectedError) {
      console.log('Expected error', this.props.expectedError);
      return;
    }

    if (!error) {
      console.error('Something broke, but there is no error object 🤷');
      return;
    }
    if (error.name === 'ChunkLoadError') {
      window.location.reload(); // reload the page if there is a new version of the app
      return; // do not sent this error to centry (because it is known error)
    }
    this.errorHandler(error);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    // @ts-ignore
    if (this.props.userEmail !== prevProps.userEmail && window.Sentry) {
      // @ts-ignore
      window.Sentry.configureScope((scope: any) => {
        scope.setUser({email: this.props.userEmail});
        scope.setLevel('warning');
      });
    }
  }

  addListeners = () => {
    window.addEventListener('unhandledrejection', this.handleRejection);
    window.addEventListener('rejectionhandled', this.handleRejection);
  };

  removeListeners = () => {
    window.removeEventListener('unhandledrejection', this.handleRejection);
    window.removeEventListener('rejectionhandled', this.handleRejection);
  };

  initSentry = () => {
    const environment = process.env.FLURO_ENV || 'dev';
    // @ts-ignore
    if (environment !== 'local' && window.Sentry) {
      // @ts-ignore
      window.Sentry.init({
        dsn: 'https://ca1b6cdfc4874f41b48b2d26caf7c800@sentry.io/1506605',
        environment,
        release: 'flurosense-ui@' + config.app_version,
        integrations: [
          // @ts-ignore
          new window.Sentry.Integrations.Tracing(),
        ],
      });
    }
  };

  errorHandler = (event: any) => {
    // @ts-ignore
    window.Sentry && window.Sentry.captureException(event?.error);
    console.log(event.type, event.message, event.error && event.error.stack);
  };

  handleRejection = (event: any) => {
    // @ts-ignore
    window.Sentry?.captureException(
      new Error(`[promise rejection]: ${event.type}, ${event.reason}`)
    );
  };

  render() {
    return this.props.children;
  }
}

const mapStateToProps = ({login}: {login: LoginState}) => ({
  userEmail: login?.user.email,
});

const connector = connect(mapStateToProps, {});

export default connector(ErrorBoundary);
