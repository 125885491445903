import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RequestStatus} from 'types';
import {
  updateCurrentFieldMean,
  setSensor,
  setSchema,
  toggleAllFieldsCheckboxes,
  setFeature,
  setTreeLayerType,
} from '../actions';
import {FieldTag, IInitialMapState, TreesTypes} from '../types';
import {runNutrilogicRecommendation, toggleNRecommendation} from '../actions/zoning-actions';
import {
  generateLowPerfAnomalies,
  getLowPerfAnomalies,
  getPremiumAnomalies,
} from '../actions/anomalies-actions';
import {
  bulkSetGetParamToURL,
  formatDate,
  getCurrentImageName,
  getGetURLParam,
  setGetParamToURL,
} from '_utils';
import {checkFieldTagExist} from '../utils';
import moment from 'moment';
import {pointsSetGroupDate} from '../actions/sampling-points';
import {usePrevious} from '_hooks';
import EffectTemperatureData from './temperature-data';
import AnalyticEffects from './analytic';
import ZoningEffects from './zoning';
import NotificationEffects from './notification';
import CropPerformanceEffects from './crop-performance-effects';
import {useUpdateFilter} from './use-update-filters';
import {calculateLayerType} from '../utils/trees';
import {Route} from 'react-router';
import {LoadFields} from './load-fields';
import {GetParamEffects} from './get-param-effects';

type Props = ConnectedProps<typeof connector>;

/**
 * The component doesn't have any rendering, it exists just for the sake of running effects.
 */
const Index = ({
  //props
  currentSeason,
  currentSeasonId,
  currentSensor,
  currentDate,
  currentDates,
  currentFieldKml,
  currentFieldMean,
  feature,

  lowPerfAnomalies,
  premiumAnomalies,

  currentPointsGroup,
  nRecommendation,
  pointsCurrentGroupDate,
  field,
  pointsGroups,
  pointsSetGroupDate,
  colorSchema,

  //whole farm
  wholeFarm,
  currentGroupId,

  // methods
  generateLowPerfAnomalies,
  getLowPerfAnomalies,
  getPremiumAnomalies,
  updateCurrentFieldMean,
  setSensor,
  toggleNRecommendation,
  runNutrilogicRecommendation,
  toggleAllFieldsCheckboxes,
  treeDetectionLayerType,
  setTreeLayerType,
  setFeature,
}: Props) => {
  const prev = usePrevious({
    date: currentDate,
    sensor: currentSensor,
    currentPointsGroup,
    pointsCurrentGroupDate,
    wholeFarm,
    currentGroupId,
    treeDetectionLayerType,
  });

  useUpdateFilter();

  useEffect(() => {
    const fieldIsLoaded = currentDates && currentFieldKml.type;
    const dateOrSensorWasChanged = currentSensor !== prev?.sensor || currentDate !== prev?.date;
    if (!fieldIsLoaded) {
      return;
    }

    if (
      dateOrSensorWasChanged &&
      lowPerfAnomalies.isVisible &&
      !lowPerfAnomalies.list[getCurrentImageName()]
    ) {
      generateLowPerfAnomalies();
    }
    if (
      currentSeason.kmlId &&
      (((feature === 'crop' || feature === 'analytics' || feature === 'data-layers') &&
        (premiumAnomalies.status !== RequestStatus.Success || dateOrSensorWasChanged)) || // prevent loading anomalies when tab was changed and they were loaded
        (premiumAnomalies.status !== RequestStatus.Idle && dateOrSensorWasChanged)) // if the anomalies were loaded, load on any tab
    ) {
      if (
        // FSB-4061 HACK: Unblocking Victor to test anomalies wihtout the field tag, but using the url flag
        // getGetURLParam('premium-anomalies')
        (checkFieldTagExist(FieldTag.AnomalyDetection) || getGetURLParam('premium-anomalies')) &&
        premiumAnomalies.status !== RequestStatus.Loading &&
        Object.keys(currentDates).length
      ) {
        getPremiumAnomalies();
      } else if (!lowPerfAnomalies.isLoaded) {
        getLowPerfAnomalies();
      }
    }
  }, [
    currentSeason.kmlId,
    currentDates,
    currentDate,
    currentSensor,
    currentFieldKml,
    feature,
    lowPerfAnomalies,
  ]);

  // useEffect(() => {
  //   if (
  //     currentFieldKml.properties?.id !== currentFieldMean.fieldId ||
  //     currentSensor !== currentFieldMean.sensor ||
  //     currentDate !== currentFieldMean.date
  //   ) {
  //     updateCurrentFieldMean();
  //   }
  // }, [currentFieldKml.properties?.id, currentFieldMean, currentSensor, currentDate]);

  useEffect(() => {
    if (
      // toggle nutrilogic off when the last points group or last point in last points group was deleted || the new points group === 'all'
      nRecommendation.toggled &&
      nRecommendation.method === 'nutrilogic' &&
      ((prev.currentPointsGroup && !currentPointsGroup) ||
        (prev.pointsCurrentGroupDate !== 'all' && pointsCurrentGroupDate === 'all'))
    ) {
      toggleNRecommendation(false, 'nutrilogic');
    } else if (
      nRecommendation.toggled &&
      nRecommendation.method === 'nutrilogic' &&
      feature === 'zoning'
    ) {
      runNutrilogicRecommendation();
    }
  }, [currentPointsGroup]);

  useEffect(() => {
    const {generatedNMapDates = {}} = field;
    const formattedPointsDate = moment(pointsCurrentGroupDate, formatDate()).format('DD/MM/YYYY');

    if (currentSensor === 'NMAP' && (currentDate !== prev.date || currentSensor !== prev.sensor)) {
      colorSchema !== 'singleDate' && setSchema('singleDate'); // set color schema to singleDate

      const currentDateNmapPointsGroup = Object.keys(generatedNMapDates)
        .filter(k => generatedNMapDates[k] === currentDate) // get point group dates where NMAP was generated
        .map(d => moment(d, 'DD/MM/YYYY').format(formatDate()));

      const dateToSet = currentDateNmapPointsGroup.find(
        // get a points date on which NMAP was generated for the current date
        d => d !== pointsCurrentGroupDate && pointsGroups[d]
      );
      // if the NMAP is not generated using current points date, switch to appropriate one
      if (!currentDateNmapPointsGroup.includes(pointsCurrentGroupDate) && dateToSet) {
        pointsSetGroupDate(dateToSet);
      }
    }
    if (
      //  disable NMAP if current points group doesn't have generated NMAP
      currentSensor === 'NMAP' &&
      pointsCurrentGroupDate !== prev.pointsCurrentGroupDate &&
      generatedNMapDates[formattedPointsDate] !== currentDate
    ) {
      setSensor('NDVI');
    }
  }, [pointsCurrentGroupDate, currentDate, currentSensor]);

  useEffect(
    function handleWholeFarmToggle() {
      if (prev && prev.wholeFarm.isWholeFarmView && !wholeFarm.isWholeFarmView) {
        // unselect all fields if whole farm is not enabled anymore
        toggleAllFieldsCheckboxes(false);
      }
    },
    [wholeFarm.isWholeFarmView]
  );

  useEffect(
    function clearURLParams() {
      if (prev?.currentGroupId) {
        bulkSetGetParamToURL({
          'nrx-toggle': null,
          'nrx-date': null,
          message: null,
          treeDetectionLayerType: null,
        });
      }
    },
    [currentGroupId]
  );

  useEffect(
    function unsureTabUrlParamsIsSet() {
      if (!prev?.currentGroupId && !getGetURLParam('tab')) {
        setFeature(feature);
      }
    },
    [currentGroupId]
  );

  useEffect(
    function setTreeDetectionLayerTypeURL() {
      const treeLayerTypeURLValue = getGetURLParam('treeDetectionLayerType');
      if (
        prev?.treeDetectionLayerType &&
        prev?.treeDetectionLayerType !== 'default' &&
        treeDetectionLayerType === 'default'
      ) {
        return setGetParamToURL('treeDetectionLayerType', null); // clear layerType param if the new date doesn't have tree data
      }

      if (
        treeDetectionLayerType !== 'default' &&
        treeDetectionLayerType !== treeLayerTypeURLValue
      ) {
        setGetParamToURL('treeDetectionLayerType', treeDetectionLayerType);
      }
    },
    [currentDate, treeDetectionLayerType]
  );

  useEffect(
    function clearTreeDetectionLayerTypeURL() {
      const treeLayerTypeURLValue = getGetURLParam('treeDetectionLayerType');
      if (!currentSeasonId || !treeLayerTypeURLValue || !currentDates[currentDate]) return;

      if (treeLayerTypeURLValue !== 'default' && !currentDates[currentDate]?.treeData) {
        setGetParamToURL('treeDetectionLayerType', null); // clear layerType param if the date doesn't have tree data
      }
    },
    [currentDate, currentDates, currentSeasonId]
  );

  useEffect(
    function calculateTreeDetectionLayer() {
      const treeLayerTypeValue =
        (getGetURLParam('treeDetectionLayerType') as TreesTypes) || treeDetectionLayerType;
      const layerType = calculateLayerType(currentDate, treeLayerTypeValue, currentSensor);
      if (layerType !== treeDetectionLayerType) {
        setTreeLayerType(layerType);
      }
    },
    [wholeFarm.fieldsWithDates]
  );

  return (
    <>
      <Route path={`/:view/:groupId?`} component={LoadFields} />
      <EffectTemperatureData />
      <AnalyticEffects />
      <NotificationEffects />
      <CropPerformanceEffects />
      <ZoningEffects />
      <GetParamEffects />
    </>
  );
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  field: map.field,
  currentSeason: map.currentSeason,
  currentSeasonId: map.currentSeasonId,
  currentSensor: map.currentSensor,
  currentDate: map.currentDate,
  currentDates: map.currentDates,
  currentFieldKml: map.currentFieldKml,
  currentFieldMean: map.currentFieldMean,
  feature: map.feature,
  lowPerfAnomalies: map.lowPerfAnomalies,
  premiumAnomalies: map.premiumAnomalies,
  pointsGroups: map.pointsGroups,
  colorSchema: map.colorSchema,
  currentPointsGroup: map.pointsGroups[map.pointsCurrentGroupDate],
  pointsCurrentGroupDate: map.pointsCurrentGroupDate,
  nRecommendation: map.nRecommendation,
  wholeFarm: map.wholeFarm,
  currentGroupId: map.group.id,
  treeDetectionLayerType: map.treeDetection.layerType,
});

const connector = connect(mapStateToProps, {
  updateCurrentFieldMean,
  generateLowPerfAnomalies,
  getLowPerfAnomalies,
  getPremiumAnomalies,
  toggleNRecommendation,
  setSensor,
  setSchema,
  pointsSetGroupDate,
  toggleAllFieldsCheckboxes,
  runNutrilogicRecommendation,
  setFeature,
  setTreeLayerType,
});
export default connector(Index);
