import {t, FormattedMessage} from 'i18n-utils';
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import Dialog from 'components/fluro-dialog';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {UIHelpersStore, dialogToggle} from 'modules/ui-helpers';
import {setProfileSettings} from '../login/actions';
import {genKey} from '_utils';
import './info-dialog.scss';
import messages from './popups';
import {Messages} from './types';
import {IInitialMapState} from '../map/types';
import {GlobalState} from '_reducers/global_types';
import {LoginState} from '../login/types';
import DEMO_FARMS_URLS from '_constants/demo-fields-urls';

const InfoDialog = memo(() => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();
  const {dialog, currentDemoFarmsURL, onboarding, userSettings, farmId} = useSelector(
    ({
      uiHelpers,
      map,
      global,
      login,
    }: {
      uiHelpers: UIHelpersStore;
      map: IInitialMapState;
      global: GlobalState;
      login: LoginState;
    }) => ({
      dialog: uiHelpers.dialogs.info,
      onboarding: login.user.settings.onboarding,
      userSettings: login.user.settings,
      farmId: map.group.id,
      currentDemoFarmsURL:
        DEMO_FARMS_URLS[
          `${global.currentGroupId}/${map.wholeFarm.isWholeFarmView ? 'WholeFarm' : map.field.ID}`
        ],
    }),
    shallowEqual
  );

  const dialogHandler = useCallback((value: boolean) => dispatch(dialogToggle('info', value)), []);
  const [msg, setMsg] = useState('');

  // show dialog if open from (fields dropdown) /selector-fields.ts
  useEffect(() => {
    if (dialog.payload && messages[dialog.payload as keyof Messages]) {
      setMsg(dialog.payload);
    }
  }, [dialog.payload]);

  useEffect(() => {
    dialogHandler(false);
  }, [farmId]);

  const message = useMemo(() => messages?.[msg as keyof Messages], [msg, messages]);

  const close = useCallback(() => {
    if (!onboarding.demoFarmInfoBtn) {
      dispatch(
        setProfileSettings(
          {
            ...userSettings,
            onboarding: {...userSettings.onboarding, demoFarmInfoBtn: true},
          },
          false
        )
      );
    }
    dialogHandler(false);
  }, [onboarding]);

  const reset = useCallback(() => {
    currentDemoFarmsURL &&
      window.history.replaceState(
        null,
        null,
        `${window.location.origin}${window.location.pathname}${currentDemoFarmsURL}`
      );

    window.location.reload();
  }, [currentDemoFarmsURL]);

  const isOnboarding = useMemo(() => {
    return onboarding.fullTour || onboarding.quickTour || !onboarding.isOnboarded;
  }, [onboarding]);

  return (
    <Dialog
      id={`info-dialog-${id}`}
      visible={!isOnboarding && dialog.visible}
      title={message?.title || 'Info'}
      focusOnMount={false}
      isDraggable
      onHide={close}
      autosizeContent={false}
      width={message && message?.width ? message?.width : 500}
      portal
      borderedTitle
    >
      {message ? message.element({close, currentDemoFarmsURL, reset}) : msg}
    </Dialog>
  );
});

export default InfoDialog;
