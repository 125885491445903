import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import store from './store';
import './index.scss';
import 'containers/google-tag-manager';
import 'core-js/features/array/flat';
import ErrorBoundary from 'containers/error-boundary';
import 'react-intl-tel-input/dist/main.css';

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

// @ts-ignore
if (module.hot) {
  // Whenever a new version of App.js is available
  // @ts-ignore
  module.hot.accept('./App', function() {
    // Require the new version and render it instead
    const NextApp = require('./App');
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}
