import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {DroneIcon, MachineryIcon, PlaneIcon, SatelliteHRIcon} from '../../icons';

export const SENSORS = [
  {
    title: 'Bands',
    id: 'bands',
    options: {
      sourceTypes: ['airplane', 'UAV/drone', 'satellite'],
      mosaicBands: [
        'RED',
        'GREEN',
        'BLUE',
        'NIR',
        'MIR',
        'HIR',
        'R_EDGE',
        'MICASENSE',
        'CERES',
        'RGB-NIR',
      ],
    },
  },
  {
    title: 'Index',
    id: 'index',
    options: {
      sourceTypes: ['airplane', 'UAV/drone', 'satellite'],
      mosaicBands: [
        'CCCI',
        'MSAVI',
        'NDRE',
        'NDVI',
        'NDWI',
        'PGI',
        'TCI',
        'TIRS',
        'VVI',
        'PTIRS',
        'NC',
      ],
    },
  },
  {
    title: 'Layer',
    id: 'layer',
    options: {
      sourceTypes: ['machinery'],
      mosaicBands: ['EC', 'PH', 'N-application', 'YIELD', 'Elevation', 'As-planted'],
    },
  },
  // {
  //   title: 'Elevation',
  //   id: 'elevation',
  //   options: {
  //     sourceTypes: ['satellite', 'machinery'],
  //     mosaicBands: ['EC', 'PH', 'N-application', 'YIELD'],
  //   }
  // },
];
export const SOURCE_TYPES = [
  {
    value: 'airplane',
    icon: () => (
      <span>
        <PlaneIcon className="source-type-icon" />
        Plane
      </span>
    ),
  },
  {
    value: 'UAV/drone',
    icon: () => (
      <span>
        <DroneIcon className="source-type-icon" />
        Drone
      </span>
    ),
  },
  {
    value: 'satellite',
    icon: () => (
      <span>
        <SatelliteHRIcon className="source-type-icon" />
        Satellite
      </span>
    ),
  },
  {
    value: 'machinery',
    icon: () => (
      <span>
        <MachineryIcon className="source-type-icon" />
        Machinery
      </span>
    ),
  },
];
