import React, {useCallback, useMemo, useRef} from 'react';
import {CropAvatar} from 'components';
import {detectRecordsGroupTitle, UpdatedDataSet} from './helpers';
import cn from 'classnames';
import {updateBenchmarkRecord} from '../reducer';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {CSGViewModel} from '../types';
import {getCropLabelById, sortByKey, toFixedFloat} from '_utils';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {toggleTooltip} from '_actions';

type Props = {
  data: {labels: string[]; datasets: UpdatedDataSet[]};
  filteredRecords: CSGViewModel[];
  popupVisible: boolean;
  togglePopUpVisibility: (value: boolean) => void;
};

const RankingView = ({data, popupVisible, togglePopUpVisibility, filteredRecords}: Props) => {
  const dispatch = useDispatch();
  const biomassOverTimeFilter = useSelector(
    (store: AppStore) => store.cropPerformanceFilter.filters[CPFilterType.BIOMASS_OVER_TIME]
  );
  const cropPerformanceBenchmarkRecords = useSelector(
    (store: AppStore) => store.cropPerformance.benchmark.recordsToProcess
  );

  const {minValue, avgValue, maxValue} = useMemo(() => {
    // retrieves the max value of dataset to limit the yAxis to it
    let maxValue = filteredRecords[0].cumulativeSmoothSatelliteNdvi || 1;
    let minValue = filteredRecords[0].cumulativeSmoothSatelliteNdvi || 0;

    filteredRecords.forEach(({cumulativeSmoothSatelliteNdvi}) => {
      maxValue =
        cumulativeSmoothSatelliteNdvi > maxValue ? cumulativeSmoothSatelliteNdvi : maxValue;
      minValue =
        cumulativeSmoothSatelliteNdvi < minValue ? cumulativeSmoothSatelliteNdvi : minValue;
    });

    return {
      maxValue: toFixedFloat(maxValue, 0),
      minValue: toFixedFloat(minValue, 0),
      avgValue: toFixedFloat((maxValue + minValue) / 2, 0),
    };
  }, [filteredRecords]);

  const colors = useMemo(() => {
    const colors: {[seasonId: string]: string} = {};
    biomassOverTimeFilter.forEach(({value, color}) => {
      colors[value] = color;
    });
    return colors;
  }, [biomassOverTimeFilter]);

  const preparedRecords = useMemo(() => {
    const sortedRecords: CSGViewModel[] = sortByKey(
      filteredRecords,
      'cumulativeSmoothSatelliteNdvi'
    );

    return sortedRecords.map(record => {
      return {
        ...record,
        backgroundColor: colors[record.seasonId] || '#c2c2c2',
        width: toFixedFloat((record.cumulativeSmoothSatelliteNdvi / maxValue) * 100, 0) || 1,
        cumulativeSmoothSatelliteNdvi: toFixedFloat(record.cumulativeSmoothSatelliteNdvi, 0),
      };
    });
  }, [filteredRecords]);

  const onUpdateRecordsToProcess = useCallback(
    (seasonId: number) => {
      dispatch(updateBenchmarkRecord(seasonId, !cropPerformanceBenchmarkRecords[seasonId]));
    },
    [cropPerformanceBenchmarkRecords]
  );

  const showTooltip = useCallback(
    (id: number, data: CSGViewModel) => {
      dispatch(
        toggleTooltip({
          id,
          place: 'top',
          tooltipClassName: 'line-chart-tooltip',
          content: `${getCropLabelById(data.cropType)} ${
            data.cropVariety ? `[${data.cropVariety}]` : ''
          }${data.seasonName ? ', ' + data.seasonName : ''}
          - ${data.cumulativeSmoothSatelliteNdvi}`,
          width: 'auto',
        })
      );
    },
    [dispatch, toggleTooltip]
  );

  return (
    <div className={'ranking-view'}>
      <ul className={cn('legend-list')}>
        {preparedRecords.map(data => {
          return (
            <React.Fragment key={data.seasonId}>
              <RankingItem
                data={data}
                onUpdateRecordsToProcess={onUpdateRecordsToProcess}
                toggleTooltip={() => showTooltip(data.seasonId, data)}
              />
            </React.Fragment>
          );
        })}
      </ul>
      {preparedRecords.length ? (
        <div className="range-scale">
          <span>{minValue}</span>
          <span>{avgValue}</span>
          <span>{maxValue}</span>
        </div>
      ) : null}
    </div>
  );
};

type RankingItemProps = {
  data: CSGViewModel & {backgroundColor: string; width: string};
  onUpdateRecordsToProcess: (seasonId: number) => void;
  toggleTooltip: () => void;
};

const RankingItem = ({data, onUpdateRecordsToProcess, toggleTooltip}: RankingItemProps) => {
  const {seasonId, seasonName, cropType, fieldName, backgroundColor, width} = data;
  return (
    <li
      key={seasonId}
      onClick={() => onUpdateRecordsToProcess(seasonId)}
      className={cn({
        'legend-list-item': true,
        'ranking-item': true,
      })}
    >
      <span className="item-name">
        <span className={'field-season'}>
          <CropAvatar cropType={cropType} />
        </span>
        <span className={'field-name'}>
          {fieldName}
          <div
            data-tip=""
            data-for={seasonId}
            onMouseEnter={toggleTooltip}
            style={{width: `${width}%`}}
            className={cn('bar-container')}
          >
            <div style={{backgroundColor}} className={'bar-line'} />
            <span className={'bar-value'}>{data.cumulativeSmoothSatelliteNdvi}</span>
          </div>
        </span>
      </span>
    </li>
  );
};

export default RankingView;
