import {Route} from 'react-router-dom';
import Users from './users';
import React, {memo} from 'react';
import OrderPlanetImagery from './order-planet-imagery/order-planet-imagery';

const Routes = memo(() => {
  return (
    <>
      <Route path="/admin/users" component={Users} />
      <Route
        exact
        path={'/admin/user-activity'}
        component={React.lazy(() => import('containers/admin/user-activity'))}
      />
      <Route
        exact
        path={'/admin/features'}
        component={React.lazy(() => import('containers/admin/features/premium-apps'))}
      />
      <Route
        exact
        path={'/admin/features/emails'}
        component={React.lazy(
          () => import('containers/admin/features/feature-activation-by-email')
        )}
      />
      <Route
        path={'/admin/subscriptions'}
        component={React.lazy(() => import('containers/admin/subscriptions'))}
      />
      <Route
        exact
        path={'/admin/email-logs'}
        component={React.lazy(() => import('containers/admin/logs/Logs'))}
      />
      <Route
        exact
        path={'/admin/dashboard/:reportType'}
        component={React.lazy(() => import('containers/admin/dashboard/crop-stress'))}
      />
      <Route
        path={'/admin/fields-lookup'}
        component={React.lazy(() => import('containers/admin/fields-lookup/fields-lookup'))}
      />

      <Route
        path="/admin/crop"
        component={React.lazy(() => import('containers/admin/crop-types-matching'))}
      />
      <Route
        path="/admin/order-high-resolution-imagery"
        component={OrderPlanetImagery}
        // component={React.lazy(() => import('containers/admin/order-high-resolution-imagery/order-high-resolution-imagery'))}
      />
    </>
  );
});

export default Routes;
