import {
  ActionType,
  FeatureAction,
  FField,
  AdminFeaturesStore,
  SwitchBulkFeatureAction,
  SwitchFeatureAction,
} from './types';

const initialState: AdminFeaturesStore = {
  tagKeys: [],
  fields: [],
  result: [],
  entities: [],
  farms: [],
  rawFieldsData: [],
};

export default (state = initialState, action: FeatureAction) => {
  switch (action.type) {
    case ActionType.SET_TAG_KEYS:
      return {
        ...state,
        tagKeys: action.tagKeys,
      };

    case ActionType.SWITCH_FEATURE:
      return {
        ...state,
        fields: updatePremiumAppsFields(state.fields, action),
        result: updatePremiumAppsFields(state.result, action),
      };

    case ActionType.BULK_SWITCH_FEATURE:
      return {
        ...state,
        fields: [...updateBulkPremiumAppsFields(state.fields, action)],
        result: updateBulkPremiumAppsFields(state.result, action),
      };

    case ActionType.SET_SEARCH_RESULT:
      return {
        ...state,
        fields: action.fields,
      };

    // TODO: maybe redundant
    case ActionType.SET_ENTITIES:
      return {
        ...state,
        entities: action.entities,
      };

    case ActionType.SET_ACTIVATION_RESULT:
      return {
        ...state,
        result: [...action.result],
      };

    case ActionType.SET_FARMS_RESULT:
      return {
        ...state,
        farms: [...action.farms],
        rawFieldsData: action.rawFieldsData,
      };

    case ActionType.SET_EMAILS:
      return {
        ...state,
        entities: state.entities.map((entity: any) => {
          if (entity.id === action.entityId) {
            return {...entity, value: JSON.stringify({emails: action.emails})};
          }

          return entity;
        }),
        result: updateFarmsEmails(state.result, action),
        farms: updateFarmsEmails(state.farms, action),
      };

    case ActionType.EXTEND_ENTITIES:
      return {
        ...state,
        entities: [...state.entities, ...action.entities],
      };

    case ActionType.SET_SELECTOR_SEASON: {
      const entity = state.entities.find(
        (entity: any) => entity.entity_id === action.season.seasonId
      );
      let emails: string[] = [];
      let entityId = 0;

      if (entity) {
        try {
          entityId = entity.id;

          const data = JSON.parse(entity.value);
          emails = data?.emails || [];
        } catch (e) {}
      }

      return {
        ...state,
        farms: state.farms.map(r => {
          if (r.farmId === action.farmId && r.fieldId === action.fieldId) {
            return {...r, ...action.season, entityId, emails};
          }

          return r;
        }),
        result: state.result.map(r => {
          if (r.farmId === action.farmId && r.fieldId === action.fieldId) {
            return {...r, ...action.season, entityId, emails};
          }

          return r;
        }),
      };
    }

    default:
      return state;
  }
};

function updateFarmsEmails(farms: any[], action: any) {
  return farms.map((farm: any) => {
    if (farm[`${action.entityType}Id`] === action.farmOrSeasonId) {
      return {
        ...farm,
        entityId: action.entityId,
        emails: [...action.emails],
        manualReview: action.manualReview,
        header_logo_url: action.header_logo_url,
      };
    }
    return farm;
  });
}

function updatePremiumAppsFields(fields: FField[], action: SwitchFeatureAction) {
  return fields.map(field => {
    if (field.fieldId === action.fieldId) {
      field.features[action.key].id = action.keyId;
      field.features[action.key].value = action.value;

      return {...field};
    }

    return field;
  });
}

function updateBulkPremiumAppsFields(fields: FField[], action: SwitchBulkFeatureAction) {
  return fields.map((field: FField) => {
    const result = action.createdKeys.find((k: any) => field.fieldId === k.entity_id);

    if (result) {
      field.features[action.key].value = action.value;
      field.features[action.key].id = result.id;

      return {...field};
    }

    return field;
  });
}
