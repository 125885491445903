import {toggleMapBar} from '../actions';
import {t} from 'i18n-utils';
import {tsProcessor} from '_utils/ts-file-processor';
import {showNote} from '_actions';
import {ActivityApi} from '../../../_api';
import {loadFields} from '../actions';

const TS_UPLOAD_OPEN = 'ts-upload/open-dialog';
const TS_UPLOAD_SET_MARKERS = 'ts-upload/set-markers';
const TS_UPLOAD_CLEAR_MARKERS = 'ts-upload/clear-markers';
const TS_UPLOAD_SET_MAPPING_CELL = 'ts-upload-set-mapping-cell';
const TS_UPLOAD_TOGGLE_CONFIRMATION_POPUP = 'ts-upload-toggle-confirmation-popup';

const initialState = {
  isOpenDialog: false,
  markers: [],
  fileNames: [],
  confirmData: null,
  pereparedMarkers: [],
  setSubmitting: null,

  cellMapping: {
    sample_id: {
      title: t({id: 'Point ID'}),
      mappedKey: 'sample_id',
      type: 'text',
    },

    sample_date: {
      title: t({id: 'Sample Date'}),
      mappedKey: 'sample_date',
      type: 'date',
    },

    lat: {
      title: t({id: 'Lat'}),
      mappedKey: 'lat',
      type: 'number',
    },

    lng: {
      title: t({id: 'Lng'}),
      mappedKey: 'lng',
      type: 'number',
    },

    n_result: {
      title: t({id: 'Total N (%)'}),
      mappedKey: 'n_result',
      type: 'number',
    },

    n_result2: {
      title: t({id: 'Nitrate (ppm)'}),
      mappedKey: 'n_result2',
      type: 'number',
    },

    total_K: {
      title: t({id: 'Total K (%)'}),
      mappedKey: 'total_K',
      type: 'number',
    },

    total_P: {
      title: t({id: 'Total P (%)'}),
      mappedKey: 'total_P',
      type: 'number',
    },

    Ca: {
      title: 'Ca (%)',
      mappedKey: 'Ca',
      type: 'number',
    },

    Mg: {
      title: 'Mg (%)',
      mappedKey: 'Mg',
      type: 'number',
    },

    Na: {
      title: 'Na (%)',
      mappedKey: 'Na',
      type: 'number',
    },

    S: {
      title: 'S (%)',
      mappedKey: 'S',
      type: 'number',
    },

    Zn_ppm: {
      title: 'Zn (ppm)',
      mappedKey: 'Zn_ppm',
      type: 'number',
    },

    Mn_ppm: {
      title: 'Mn (ppm)',
      mappedKey: 'Mn_ppm',
      type: 'number',
    },

    Fe_ppm: {
      title: 'Fe (ppm)',
      mappedKey: 'Fe_ppm',
      type: 'number',
    },

    Cu_ppm: {
      title: 'Cu (ppm)',
      mappedKey: 'Cu_ppm',
      type: 'number',
    },

    B_ppm: {
      title: 'B (ppm)',
      mappedKey: 'B_ppm',
      type: 'number',
    },

    Cl: {
      title: 'Cl (%)',
      mappedKey: 'Cl',
      type: 'number',
    },

    Mo_ppm: {
      title: 'Mo (ppm)',
      mappedKey: 'Mo_ppm',
      type: 'number',
    },

    growthStage: {
      title: t({id: 'Growth Stage'}),
      mappedKey: 'growthStage',
      type: 'text',
    },

    samplingType: {
      title: t({id: 'Plant Material Type'}),
      mappedKey: 'samplingType',
      type: 'text',
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TS_UPLOAD_OPEN:
      return {
        ...state,
        isOpenDialog: action.value,
        cellMapping: action.value ? state.cellMapping : {...initialState.cellMapping},
      };

    case TS_UPLOAD_SET_MARKERS:
      return {
        ...state,
        markers: [...action.markers],
        fileNames: [...action.fileNames],
      };

    case TS_UPLOAD_CLEAR_MARKERS:
      return {
        ...state,
        markers: [],
        fileNames: [],
        isOpenDialog: false,
        cellMapping: {...initialState.cellMapping},
      };

    case TS_UPLOAD_SET_MAPPING_CELL:
      return {
        ...state,
        cellMapping: {
          ...state.cellMapping,
          [action.key]: {
            ...state.cellMapping[action.key],
            mappedKey: action.mappedKey,
          },
        },
        // markers,
      };

    case TS_UPLOAD_TOGGLE_CONFIRMATION_POPUP:
      return {
        ...state,
        confirmData: {...action.confirmData},
        pereparedMarkers: [...action.markers],
        setSubmitting: action.setSubmitting,
      };

    default:
      return state;
  }
};

export const handleOpenTS = (value, e) => dispatch => {
  // prevent close popup after click outside
  if (e && e.target.classList.contains('md-dialog-container')) {
    return;
  }

  value && dispatch(toggleMapBar(false));

  if (!value) {
    dispatch(clearMarkers());
    dispatch(toggleMapBar(true));
    dispatch(toggleTSPUploadConfirmationPopup(null));

    clearInputField();
  }

  dispatch({
    type: TS_UPLOAD_OPEN,
    value,
  });
};

export const handleFile = files => dispatch => {
  const samplingType = 'Petiole sample';

  tsProcessor(files).then(result => {
    let data = [];

    result.forEach(r => {
      if (r.status === 'error') {
        dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: r.message,
            level: 'warning',
          })
        );
      } else {
        data = data.concat(r.data);
      }
    });

    if (data.length) {
      dispatch(toggleMapBar(false));

      dispatch({
        type: TS_UPLOAD_OPEN,
        value: true,
      });

      data = data.map(marker => ({...marker, samplingType}));

      dispatch({
        type: TS_UPLOAD_SET_MARKERS,
        markers: data,
        fileNames: files.map(f => f.name),
      });
    } else {
      dispatch(clearMarkers());
      dispatch(toggleTSPUploadConfirmationPopup(null));
    }

    clearInputField();
  });
};

function clearInputField() {
  const tspupbtn = document.getElementById('ts-upload-btn');
  if (tspupbtn) tspupbtn.value = '';
}

export const clearMarkers = () => ({
  type: TS_UPLOAD_CLEAR_MARKERS,
});

export const saveMarkers = (markers = [], setSubmitting, shouldMerge = false) => (
  dispatch,
  getState
) => {
  const {group, selectedFieldId} = getState().map;

  return ActivityApi.saveBulk(markers, group.id, selectedFieldId, false, shouldMerge).then(() => {
    setSubmitting(false);

    dispatch(clearMarkers());
    dispatch(toggleTSPUploadConfirmationPopup(null));
    dispatch(toggleMapBar(true));

    dispatch(loadFields(group.id, selectedFieldId)).then(() => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: 'Your tissue sampling points were uploaded',
          level: 'success',
        })
      );
    });
  });
};

export const saveMarkersFromStore = (shouldMerge = false) => (dispatch, getSate) => {
  const {
    tsUpload: {pereparedMarkers, setSubmitting},
  } = getSate();
  return saveMarkers(pereparedMarkers, setSubmitting, shouldMerge)(dispatch, getSate);
};

export const testTSPMarkers = (markers = []) => (dispatch, getState) => {
  const {group, selectedFieldId} = getState().map;
  return ActivityApi.saveBulk(markers, group.id, selectedFieldId, true).then(({data}) => {
    return data;
  });
};

export const toggleTSPUploadConfirmationPopup = (result, markers = [], setSubmitting) => ({
  type: TS_UPLOAD_TOGGLE_CONFIRMATION_POPUP,
  confirmData: result,
  markers,
  setSubmitting,
});

export const onSetMapping = (key, mappedKey) => ({
  type: TS_UPLOAD_SET_MAPPING_CELL,
  mappedKey,
  key,
});
