import {t, FormattedMessage} from 'i18n-utils';
import React, {ReactElement} from 'react';
import {Button, CircularProgress, SelectionControl} from 'react-md';
import cn from 'classnames';
import {useSelector} from 'react-redux';
import {AppStore} from '../../../../reducers';
import {useAppDispatch, useAppSelector} from '../../../../_hooks';
import {togglePlatform} from '../actions';
import {IntegrationPlatform} from '../types';
import {ExternalService} from '../../../map/types';

type Props = {
  authorized: boolean; // if the prop is undefined it means we don't know yet
  expanded: boolean;
  logoSrc: string;
  logoAlt: string;
  platformName: string;
  platformCompanyName: string;
  platformInnerName: IntegrationPlatform; // used to search in store
  logoClassname?: string;
  containerClassName?: string;
  isPlatformSelected: boolean;
  onAuthorize(): void;
  onToggle(): void;
  customAuthButton?: ReactElement;
  badge?: ReactElement;
  loading?: boolean;
};

const IntegrationCard = ({
  authorized,
  onAuthorize,
  // expanded,
  onToggle,
  logoSrc,
  logoAlt,
  platformName,
  platformCompanyName,
  platformInnerName,
  logoClassname = '',
  isPlatformSelected,
  containerClassName = '',
  customAuthButton,
  badge,
  loading,
}: Props) => {
  const dispatch = useAppDispatch();
  const isSelectPlatformsView = useAppSelector(store => store.integrations.isSelectPlatformsView);

  const handleAuthorizeAction = (ev: any) => {
    ev.stopPropagation();
    onAuthorize();
  };

  const onTogglePlatform = (value: boolean) => {
    dispatch(togglePlatform(platformInnerName, value));
  };

  return (
    <div
      onClick={() => (authorized ? onToggle() : null)}
      className={cn(`integration-card ${containerClassName}`, {
        authorized,
        minimized: isSelectPlatformsView,
      })}
    >
      <div className={'integration-card__logo-container'}>
        <div className="logo">
          <img alt={logoAlt} className={`${logoClassname}`} src={logoSrc} />
          {badge && isSelectPlatformsView ? <div className="badge">{badge}</div> : null}
        </div>
        {isSelectPlatformsView && (
          <SelectionControl
            id={`select-${platformName}`}
            name={`select-${platformName}`}
            label={''}
            type={'switch'}
            className={'select-platform-checkbox'}
            checked={isPlatformSelected}
            onChange={(v: boolean) => onTogglePlatform(v)}
          />
        )}
        <div className={'names'}>
          <div className="platform-name">{platformName}</div>
          <div className="company-name">{platformCompanyName}</div>
        </div>
        {badge && !isSelectPlatformsView ? <div className="badge">{badge}</div> : null}
        {!isSelectPlatformsView && loading ? (
          <CircularProgress className={'platform-loader'} id={`${platformName}-loader`} />
        ) : null}
      </div>

      {/*{authorized && (*/}
      {/*  <Button*/}
      {/*    floating*/}
      {/*    mini*/}
      {/*    className={'integration-card__toggle-button'}*/}
      {/*    onClick={() => onToggle()}*/}
      {/*  >*/}
      {/*    {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}*/}
      {/*  </Button>*/}
      {/*)}*/}
      {isSelectPlatformsView ? null : customAuthButton ? (
        customAuthButton
      ) : authorized === undefined || authorized ? null : ( // hide the button when we don't know do we are authorized or not
        <Button
          className={'integration-card__authorize-button'}
          raised
          primary
          onClick={handleAuthorizeAction}
        >
          {t({id: 'Login'})}
        </Button>
      )}
    </div>
  );
};

export default IntegrationCard;
