import initialState from '../initial-state';
import {
  DrawingEntity,
  FieldTag,
  IAction,
  Field,
  IInitialMapState,
  Season,
  TInfoExt,
  WholeFarmTreeZoningFields,
} from 'containers/map/types';

import {ActionTypes} from '../types';
import {parseSensor} from 'types';
import {
  bulkSetGetParamToURL,
  genKey,
  getGetURLParam,
  getSourcesMeta,
  isAustralianField,
  preselectCompareDate,
  setGetParamToURL,
  sortFieldsByProp,
} from '_utils';

import {
  filterDates,
  filterDatesForRemoteSensing,
  preselectSensor,
  preselectSensors,
} from 'containers/map/utils';

export default (state = initialState, action: IAction): IInitialMapState => {
  switch (action.type) {
    case ActionTypes.RESET_FARM_SENSITIVE_PARAMS: {
      return {
        ...state,
        remoteSensingFilterProps: [], // reset source filter params if switch farm
      };
    }

    case ActionTypes.MAP_ADD_NEW_FIELD: {
      const result = (Array.isArray(action.field) ? [...action.field] : [action.field]).map(f => ({
        ...f,
        Seasons: f.Seasons || [],
      }));

      // Use the new version if the field already exists.
      const newFieldIds = result.map(f => f.ID);
      const updatedFields = [...state.fields.filter(f => !newFieldIds.includes(f.ID)), ...result];
      return {
        ...state,
        fields: updatedFields,
        field: result[0],
        remoteSensingImages: [],
        currentSeason: {} as Season,
        currentSeasonId: 0,
        currentDates: {},
        currentDate: '',
        infoExt: [],
      };
    }

    case ActionTypes.MAP_LOAD_FIELD_DATA:
      return {
        ...state,
        field: action.field,
        isAustralianFieldOrBowles:
          state.group.id === 43 ||
          state.isAustralianFieldOrBowles ||
          (state.group.id === 1480 && action.field.ID === 250372) || // specific field in Israel
          isAustralianField(action.field),
        zoning: {
          ...state.zoning,
          zones: [],
          copyZones: [],
          treeZonesImage: '',
          treeZones: [] as any,
        },
        currentSeasonId: 0,
        currentDates: {},
        currentSensors: [] as any,
        currentCompareDate: '',
        currentSensorCompare: 'NDVI',
        isRemoteSensingDialog: false,
        currentRemoteSensingImage: {} as TInfoExt,
        currentIsSetSpectra: [],
        nitrogenOverlayURL: '',
        lowPerfAnomalies: {
          list: {},
          isVisible: false,
          isLoaded: false,
        },
        analytics: initialState.analytics,
        premiumAnomalies: initialState.premiumAnomalies,
        remoteSensingImages: initialState.remoteSensingImages,
        infoExt: initialState.infoExt,
        currentSeason: {} as Season,
        preparedTemperatureData: {},
        anomalies: {
          ...state.anomalies,
          anomaliesSelectedTab:
            state.feature === 'crop'
              ? state.anomalies.anomaliesSelectedTab
              : action.field.tags.includes(FieldTag.AnomalyDetection)
              ? 'crop-stress'
              : 'areas-of-interest',
        },
      };

    case ActionTypes.MAP_SET_CROP_VARIETY_MODE:
      return {
        ...state,
        cropVarietyMode: action.cropVarietyMode,
      };

    case ActionTypes.MAP_LOAD_FIELDS:
      const fieldsById: {[fieldId: number]: Field} = {};
      action.fields.forEach(f => (fieldsById[f.ID] = f));
      return {
        ...state,
        fields: action.fields,
        group: {...action.group},
        fieldsByFarmId: {...state.fieldsByFarmId, [action.group.id]: fieldsById},
        cropVarietyColors: {...state.cropVarietyColors, ...action.cropVarietyColors},
      };

    case ActionTypes.MAP_SAVE_FIELDS:
      return {
        ...state,
        fieldsByFarmId: {...state.fieldsByFarmId, [action.farmId]: action.fieldsById},
        cropVarietyColors: {...action.cropVarietyColors, ...state.cropVarietyColors},
      };

    case ActionTypes.MAP_FARM_UPDATE:
      return {
        ...state,
        group: {...state.group, ...action.group},
      };

    case ActionTypes.MAP_FARM_DELETE:
      delete state.fieldsByFarmId[action.farmId];
      return {
        ...state,
        fieldsByFarmId: {...state.fieldsByFarmId},
      };

    case ActionTypes.MAP_SORT_FIELDS: {
      const {sortFields, fields} = state;

      const descendingSort =
        action.descending !== undefined // if the prop is set manually, use it
          ? action.descending
          : sortFields.byProp === action.byProp // otherwise calculate it automatically
          ? !sortFields.descending
          : sortFields.descending;
      const sortResult = sortFieldsByProp(fields, action.byProp, action.sortType);
      // if (descendingSort) sortResult.reverse();

      return {
        ...state,
        fields: sortResult,
        sortFields: {
          byProp: action.byProp,
          descending: descendingSort,
          sortType: action.sortType,
        },
      };
    }

    case ActionTypes.MAP_HIGHLIGHT_FIELD:
      return {
        ...state,
        highlightedFieldId: action.fieldId as number,
      };

    case ActionTypes.SET_SELECTED_FIELD_ID:
      const isWholeFarm = action.fieldId === 'WholeFarm';
      if (state.selectedFieldId) {
        // do not clear if it is init
        bulkSetGetParamToURL({
          'nrx-toggle': null,
          'nrx-date': null,
          message: null,
          treeDetectionLayerType: null,
        });
      }
      return {
        ...state,
        selectedFieldId: action.fieldId,
        wholeTableViewOpen: action.keepTableView ? state.wholeTableViewOpen : undefined,
        wholeFarm: {
          ...state.wholeFarm,
          isWholeFarmView: isWholeFarm,
        },
        zoning: {
          ...state.zoning,
          selectedByUser: false,
        },
        geometry: [],
        visibleFieldKml: action.shouldShowKmlLayer || state.visibleFieldKml,
        lowPerfAnomalies: initialState.lowPerfAnomalies,
        remoteSensingFilterProps: initialState.remoteSensingFilterProps,
        currentSeason: initialState.currentSeason,
        currentSeasonId: initialState.currentSeasonId,
        nRecommendation: {
          ...initialState.nRecommendation,
        },
      };

    case ActionTypes.MAP_DELETE_FIELD: {
      const currentFieldDeleted = action.kmlId.includes(state.selectedFieldId);
      const updatedFields = state.fields.filter(field => !action.kmlId.includes(field.ID));
      const isFieldsEmpty = !updatedFields.length;

      if (action.farmId) {
        action.kmlId.forEach((fieldId: number) => {
          delete state.fieldsByFarmId?.[action.farmId]?.[fieldId];
        });
      }

      return {
        ...state,
        fields: updatedFields,
        currentSeason: currentFieldDeleted ? ({} as Season) : state.currentSeason,
        currentSeasonId: currentFieldDeleted ? 0 : state.currentSeasonId,
        field: currentFieldDeleted ? {...initialState.field} : state.field,
        wholeFarm: {
          ...state.wholeFarm,
          fieldsWithDates: isFieldsEmpty ? {} : state.wholeFarm.fieldsWithDates,
        },
        currentFieldKml: isFieldsEmpty ? {} : state.currentFieldKml,
        fieldsByFarmId: {
          ...state.fieldsByFarmId,
        },
      };
    }

    case ActionTypes.MAP_BULK_CHANGE_FIELD_DATA: {
      const updatedFieldsInAFarm = {...state.fieldsByFarmId};
      action.selectedKmlIds.forEach(fieldId => {
        if (updatedFieldsInAFarm[action.farmId][fieldId]) {
          updatedFieldsInAFarm[action.farmId][fieldId] = {
            ...updatedFieldsInAFarm[action.farmId][fieldId],
            ...action.fieldData,
          };
        }
      });
      return {
        ...state,
        fields: state.fields.map(field => {
          if (action.selectedKmlIds.includes(field.ID)) {
            return {...field, ...action.fieldData};
          }
          return field;
        }),
        field: action.selectedKmlIds.includes(parseInt(`${state.selectedFieldId}`))
          ? {...state.field, ...action.fieldData}
          : state.field,
        fieldsByFarmId: updatedFieldsInAFarm,
      };
    }

    case ActionTypes.MAP_CHANGE_FIELD_DATA: {
      const fields = sortFieldsByProp(
        state.fields.map(field => {
          if (field.ID === action.kmlId) {
            return {...field, ...action.fieldData};
          }
          return field;
        }),
        'Name',
        'string'
      );

      return {
        ...state,
        fields,
        field:
          action.kmlId === state.selectedFieldId
            ? {...state.field, ...action.fieldData}
            : state.field,
      };
    }

    case ActionTypes.MAP_CHANGE_FIELD_NAME: {
      return {
        ...state,
        fields: state.fields.map(field => {
          if (field.ID === action.kmlId) {
            field.Name = action.name;
          }
          return field;
        }),
      };
    }

    case ActionTypes.MAP_TOGGLE_ALL_FIELDS_CHECKBOX: {
      return {
        ...state,
        fields: state.fields.map(f => ({
          ...f,
          _selected: action.items.includes(f.MD5) ? action.value : f._selected,
        })),
      };
    }

    case ActionTypes.MAP_TOGGLE_FIELD_CHECKBOX: {
      return {
        ...state,
        fields: state.fields.map(f => ({
          ...f,
          _selected: f.ID === action.fieldId ? action.value : f._selected,
        })),
      };
    }

    case ActionTypes.MAP_TOGGLE_BUTTON_ON_MAP:
      return {
        ...state,
        toggledButtonOnMap: action.value,
      };

    case ActionTypes.MAP_SET_FIELD_GEOMETRIES:
      return {
        ...state,
        fieldGeometries: {...state.fieldGeometries, ...action.fieldGeometries},
      };

    case ActionTypes.MAP_SET_WHOLE_FARM_FIELDS_GEOMETRY: {
      const clearedCloudDates = filterDates(
        action.allDates,
        {},
        {},
        state.remoteSensingCloudCover,
        state.remoteSensingFilterProps
      );

      const getParamDate = getGetURLParam('layerDate');
      const latestDate = Object.keys(clearedCloudDates)[0];

      let currentDate: string = '';

      if (getParamDate in clearedCloudDates) {
        currentDate = getParamDate;
      } else {
        currentDate = latestDate;
        setGetParamToURL('layerDate', latestDate);
      }

      const currentCompareDate = preselectCompareDate(clearedCloudDates, currentDate);

      return {
        ...state,
        fields: action.fields,
        wholeFarm: {
          isWholeFarmView: true,
          wholeFarmDates: action.allDates,
          fieldsWithDates: action.fieldsWithDates,
          allFarmTSP: action.allFarmTSP,
          treeZoning: {fields: {}, zones: []},
        },
        sourcesMeta: getSourcesMeta(Object.values(action.allDates)),
        currentDate,
        currentDates: clearedCloudDates,
        remoteSensingImages: filterDatesForRemoteSensing(
          action.allDates,
          state.remoteSensingCloudCover,
          state.remoteSensingFilterProps
        ),
        currentCompareDate,
        currentSensorCompare: preselectSensor(currentCompareDate, action.allDates, 'NDVI'),
        currentSensorsCompare: preselectSensors(currentCompareDate, action.allDates, 'default'),
        currentSensor: parseSensor(getGetURLParam('layer')) || state.currentSensor,
        featureGroupReDrawId: genKey(), // for run update analytics overlay
      };
    }

    case ActionTypes.MAP_SET_WHOLE_FARM_TREE_ZONING_FIELDS: {
      return {
        ...state,
        wholeFarm: {
          ...state.wholeFarm,
          treeZoning: action.data,
        },
      };
    }

    case ActionTypes.MAP_TOGGLE_WHOLE_FARM_TREE_ZONING_FIELD: {
      const resultFields: WholeFarmTreeZoningFields = state.wholeFarm.treeZoning.fields;

      action.fieldMD5s.forEach(fieldMD5 => {
        if (!resultFields[fieldMD5]) resultFields[fieldMD5] = {};

        resultFields[fieldMD5].selected = action.value;
      });

      return {
        ...state,
        wholeFarm: {
          ...state.wholeFarm,
          treeZoning: {
            ...state.wholeFarm.treeZoning,
            fields: {...resultFields},
          },
        },
      };
    }

    case ActionTypes.MAP_TOGGLE_DRAWING_MODE:
      return {
        ...state,
        drawControl: {
          ...state.drawControl,
          isDrawingMode: action.value,
          drawingModeLayerType: action.layerType,
          drawingEntity: (action.value ? action.entityType : '') as DrawingEntity,
        },
      };

    case ActionTypes.MAP_TOGGLE_EDITING_MODE:
      return {
        ...state,
        drawControl: {
          ...state.drawControl,
          isEditingMode: action.value,
          isDrawingMode: false,
          drawingModeLayerType: '',
          drawingEntity: '' as DrawingEntity,
          fieldId: action.value ? action.fieldId : undefined,
        },
      };

    case ActionTypes.UPDATE_IMAGE_CLOUDY: {
      const update = (images: any[]) =>
        images.map(image => {
          if (image.Date === action.date) {
            return {
              ...image,
              ...(getGetURLParam('cloudv2_test') ? {CloudV2: action.value} : {}),
              Cloud: action.value,
              cloudValueModified: true,
            };
          }

          return image;
        });

      const allDates = update(state.remoteSensingImages);

      const currentDates = filterDates(
        allDates,
        state.pointsGroups,
        state.field.generatedNMapDates,
        state.remoteSensingCloudCover,
        state.remoteSensingFilterProps
      );

      return {
        ...state,
        currentDates,
        remoteSensingImages: allDates,
        infoExt: allDates,
      };
    }

    case ActionTypes.SET_LOCATION_MARKER_COORDINATES: {
      return {
        ...state,
        locationMarkerPosition: action.markerPosition,
      };
    }

    case ActionTypes.REPLACE_FIELD_AT_FIELDS_BY_FARM_ID: {
      // remove old field
      delete state.fieldsByFarmId[action.farmId][action.fieldId as number];

      return {
        ...state,
        fieldsByFarmId: {
          ...state.fieldsByFarmId,
          [action.farmId]: {
            ...state.fieldsByFarmId[action.farmId],
            [action.field.ID]: {...action.field},
          },
        },
      };
    }

    default:
      return state;
  }
};
