import {t, FormattedMessage} from 'i18n-utils';
import {Button} from 'react-md';
import React from 'react';

type FooterProps = {
  currentDemoFarmsURL?: string;
  reset?: () => void;
  close?: () => void;
};

export const Footer = ({close, reset, currentDemoFarmsURL}: FooterProps) => {
  return (
    <div className="status-alert-dialog__btns">
      {currentDemoFarmsURL !== window.location.search ? (
        <Button onClick={reset} raised>
          RESET DEMO
        </Button>
      ) : (
        <div />
      )}
      <Button onClick={close} primary raised>
        Ok
      </Button>
    </div>
  );
};
