import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {InfoBlock} from 'components';
import {contactSupportTeam} from '../../_utils';

const InfoMessage = () => {
  return (
    <InfoBlock appearance="info" className="inside-a-pop-up" style={{marginTop: 25}}>
      In order to successfully invite someone to a farm:
      <br />
      <br />
      1. make sure that the email address is associated with an existing FluroSense account.
      <br />
      2. once invited, the user will receive an email notification, make sure the user clicked on
      “see the farm” in the invite email.
      <br />
      <br />
      If you encounter issues please {contactSupportTeam('contact us.')}
    </InfoBlock>
  );
};

export default InfoMessage;
