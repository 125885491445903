import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getGetURLParam, setGetParamToURL} from '_utils';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {togglePlatform} from '../../profile/integration/actions';
import {IntegrationPlatform} from '../../profile/integration/types';
import {useAppSelector} from '_hooks';

export const GetParamEffects = (): null => {
  const dispatch = useDispatch();
  const platforms = useAppSelector(s => s.integrations.platforms);

  useEffect(() => {
    if (getGetURLParam('integration-pop-up') === 'true') {
      // the second part is in the integration-platforms-pop-up.tsx, the mount useEffect
      dispatch(toggleDialog(DialogType.IntegrationPlatforms, true));
      const preselectedPlatforms = localStorage.getItem('selectedPlatforms');
      if (preselectedPlatforms) {
        preselectedPlatforms.split(',').forEach((platform: IntegrationPlatform) => {
          if (platforms[platform]) {
            dispatch(togglePlatform(platform, true));
          }
        });
      }
    }
  }, [location]);

  return null;
};
