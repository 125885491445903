import React from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {Ln} from '../components';
import {formatUnit} from '../_utils';

const DontHaveAccess = () => (
  <span>
    <FormattedMessage
      id="doNotHaveAccessToTheFarm"
      values={{
        a: (txt: string) => (
          <span
            onClick={() => document.getElementById('intercom-launch')?.click()}
            className={'global-link'}
          >
            {txt}
          </span>
        ),
      }}
      defaultMessage="You don’t have access to this farm, make sure the URL you entered is correct or contact <a>the support team</a>."
    />
  </span>
);

export const responsesToWarningObjects: any = {
  'User not found': () => ({
    title: t({id: 'note.wrongEmailOrPassword', defaultMessage: 'Wrong email or password.'}),
    message: t({
      id: 'tryAgainLogin',
      defaultMessage: 'Try again or click Forgot password to reset it.',
    }),
  }),

  'Email address not found': () => ({
    title: t({id: 'wrongEmail', defaultMessage: 'Wrong email'}),
    message: t({id: 'emailNotFound', defaultMessage: 'Email address not found'}),
  }),

  'This email address is already registered. You can reset your password using the sign-in page.': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: (
      <FormattedMessage
        id="note.alreadyRegistered"
        defaultMessage={'The request couldn’t be completed. Please get in touch with {email}'}
        values={{
          email: <Ln href="mailto:support@regrow.ag">support@regrow.ag</Ln>,
        }}
      />
    ),
  }),
  'Group not found': () => ({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: <DontHaveAccess />,
  }),
  'You do not have access to the farm': () => ({
    title: t({id: 'note.error', defaultMessage: 'Error'}),
    message: <DontHaveAccess />,
  }),
  'Season dates overlap': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: t({id: 'datesOverlap'}),
    level: 'warning',
  }),
  'User already exists.': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: t({id: 'userAlreadyExist'}),
    level: 'warning',
  }),
  'Could not generate second contract.': () => ({
    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
    message: (
      <FormattedMessage
        id="carbon.contractAlreadyGenerated"
        values={{
          email: <Ln href="mailto:support@regrow.ag">support@regrow.ag</Ln>,
        }}
      />
    ),
    level: 'warning',
  }),
};

export const responseWarningMessage = (response: string) => {
  let resultString = response;
  if (
    typeof response === 'string' && // because in some cases (a wrong start/end date) the back returns {msg: string, bulk_index: number, season_index: number}
    response?.includes('season dates overlap with another season')
  )
    resultString = t({id: 'datesOverlap'});

  return responsesToWarningObjects[resultString]?.();
};
