import {t} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo} from 'react';
import {DrawMarkerIcon} from '../../../../icons';
import {useDispatch, useSelector} from 'react-redux';
import {FluroButton} from 'components';
import {
  addFieldChangeCurrentStep,
  AddFieldCurrentStep,
  bulkUpdateCLUFieldBoundariesProp,
} from 'modules/add-fields.module';
import {SelectionControl} from 'react-md';
import {AppStore} from 'reducers';
import {FieldSystemProp} from '../types';

const SelectBoundariesOnMap = () => {
  const dispatch = useDispatch();
  const fieldBoundaries = useSelector((s: AppStore) => s.addFields.cluFieldBoundaries);

  const {allBoundariesSelected, selectedBoundariesLength} = useMemo(() => {
    const result = {allBoundariesSelected: false, selectedBoundariesLength: 0};
    if (!fieldBoundaries.length) return result;

    result.allBoundariesSelected = fieldBoundaries.every(
      boundary => boundary.properties[FieldSystemProp.Checked]
    );
    result.selectedBoundariesLength = fieldBoundaries.filter(
      boundary => boundary.properties[FieldSystemProp.Checked]
    ).length;

    return result;
  }, [fieldBoundaries]);

  useEffect(
    function handleAllBoundariesRemove() {
      if (!fieldBoundaries.length) {
        dispatch(addFieldChangeCurrentStep('draw-fields'));
      }
    },
    [fieldBoundaries]
  );

  const onSelectAllBoundaries = (value: boolean) => {
    dispatch(bulkUpdateCLUFieldBoundariesProp([], FieldSystemProp.Checked, value));
  };

  const drawFields = useCallback(() => dispatch(addFieldChangeCurrentStep('draw-fields')), []);

  const onConfirmSelectedBoundaries = () => {
    const selectedBoundariesIds = fieldBoundaries
      .filter(f => f.properties[FieldSystemProp.Checked])
      .map(f => f.properties.id);

    dispatch(
      // mark boundaries to process
      bulkUpdateCLUFieldBoundariesProp(selectedBoundariesIds, 'selectedForProcessing', true)
    );
    dispatch(addFieldChangeCurrentStep('view-selected-boundaries'));
  };

  return (
    <div className={'select-boundaries-container'}>
      <SelectionControl
        id={'select-all-boundaries'}
        type={'checkbox'}
        name={'select-all-boundaries'}
        label={`${t({id: 'Select all'})} ${
          fieldBoundaries.length ? `(${fieldBoundaries.length})` : ''
        }`}
        checked={allBoundariesSelected}
        onChange={(value: boolean) => onSelectAllBoundaries(value)}
      />

      {selectedBoundariesLength ? (
        <div className={'draw-fields-option'}>
          <div>{t({id: '{count} fields selected'}, {count: selectedBoundariesLength})}</div>
          <FluroButton
            id={'clear-selected-boundaries-btn'}
            blank
            onClick={() => onSelectAllBoundaries(false)}
            raised
          >
            {t({id: 'Reset selection'})}
          </FluroButton>
          <FluroButton
            id={'save-selected-boundaries-btn'}
            primary
            onClick={onConfirmSelectedBoundaries}
            raised
          >
            {t({id: 'Next'})}
          </FluroButton>
        </div>
      ) : (
        <div className="draw-fields-option">
          <div>{t({id: 'Or'})}</div>
          <FluroButton className={'draw-fields-btn'} onClick={drawFields} raised>
            {t({id: 'Draw fields'})} <DrawMarkerIcon />
          </FluroButton>
        </div>
      )}
    </div>
  );
};

export default SelectBoundariesOnMap;
