import {t} from 'i18n-utils';
import React, {useMemo, useState} from 'react';
import {
  Button,
  FontIcon,
  MenuButton,
  AccessibleFakeButton,
  SelectField,
  SelectionControlGroup,
} from 'react-md';
import AnomalyLabelsDropdown from 'containers/map/features/anomalies/labels-dropdown';
import {ReadOnly, CustomCheckbox, FluroTabs, Dropdown} from 'components';
import {IAnomaly, TAnomalyProps, TSort} from 'containers/map/features/anomalies/types';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {useDispatch} from 'react-redux';
import {onChangeAnomalyTab} from 'containers/map/actions/anomalies-actions';
import {getPerformanceGeometries} from 'containers/map/utils';
import './index.scss';
import {useAppSelector} from '_hooks';
import {setAggregation} from '../../../containers/map/features/crop-performance/reducer';
import {Aggregation} from '../../../containers/map/features/crop-performance/types';

type Props = {
  title: string;
  subtitle?: string;
  isCheckedAll: boolean;
  isReadOnly: boolean;
  labelItems?: IAnomaly[];
  onSort?(val: any, sortBy: boolean): void;
  sortBy?: TSort;
  onSelectAll(val: boolean): void;
  onChangeLabel(label: keyof TAnomalyProps, value: any, selectedAnomalies: IAnomaly[]): void;
  onSnooze?(): void;
  onDelete?(): void;
  toggleDatesPopUp?(): void;
};

const AnomalyHeader = ({
  title,
  subtitle,
  onSelectAll,
  isCheckedAll,
  onChangeLabel,
  onSnooze,
  onDelete,
  toggleDatesPopUp,
  isReadOnly,
  labelItems,
  onSort,
  sortBy,
}: Props) => {
  const sortTypes = useMemo(
    () => [
      {label: t({id: 'Size'}), value: 'size'},
      {label: t({id: 'Priority'}), value: 'priority'},
      {label: t({id: 'Date detected'}), value: 'date'},
    ],
    []
  );

  const dispatch = useDispatch();
  const anomaliesSelectedTab = useAppSelector(s => s.map.anomalies.anomaliesSelectedTab);
  const premiumAnomaliesList = useAppSelector(s => s.map.premiumAnomalies.list);
  const visibleLowPerfAnomalies = useAppSelector(s => s.map.lowPerfAnomalies.isVisible);
  const visibleHistorySection = useAppSelector(s => s.map.anomalies.historyOpen);
  const [menuOpened, toggleMenu] = useState(false);

  const isCropStressTabSelected = anomaliesSelectedTab === 'crop-stress';

  const lowPerformingAnomaliesAndRoi = visibleHistorySection
    ? [
        ...getPerformanceGeometries().geometryToShow,
        ...getPerformanceGeometries().historyGeometries,
      ]
    : getPerformanceGeometries().geometryToShow;

  const selectedAnomalies = isCropStressTabSelected
    ? premiumAnomaliesList.filter((a: any) => a.properties.checked)
    : lowPerformingAnomaliesAndRoi.filter(({properties}) => properties.checked);

  const disabled = selectedAnomalies.length === 0;

  const changeGeometryActions = useMemo(() => {
    const items = [];

    if (toggleDatesPopUp) {
      items.push(
        <AccessibleFakeButton
          disabled={disabled}
          key={`date-change`}
          className="anomaly-header__menu-item"
          onClick={() => {
            toggleDatesPopUp();
            toggleMenu(false);
          }}
        >
          EDIT DATES
        </AccessibleFakeButton>
      );
    }

    if (onSnooze) {
      items.push(
        <AccessibleFakeButton
          disabled={disabled}
          key={`1`}
          className="anomaly-header__menu-item"
          onClick={onSnooze}
        >
          SNOOZE
        </AccessibleFakeButton>
      );
    }

    items.push(
      <div key={`2`}>
        <AnomalyLabelsDropdown
          primary
          disabled={disabled}
          label="LABEL"
          iconEl={<FontIcon iconClassName={'fas fa-tag'} />}
          onChange={onChangeLabel}
          anomaly={labelItems}
          position={'br'}
          menuButtonEl={
            <AccessibleFakeButton
              className="anomaly-header__menu-item"
              disabled={disabled || isReadOnly}
            >
              LABEL
            </AccessibleFakeButton>
          }
        />
      </div>
    );

    if (onDelete) {
      items.push(
        <AccessibleFakeButton
          disabled={disabled}
          key={`3`}
          className="anomaly-header__menu-item"
          onClick={() => {
            onDelete();
            toggleMenu(false);
          }}
        >
          DELETE
        </AccessibleFakeButton>
      );
    }

    return items;
  }, [onSnooze, onDelete, disabled, isReadOnly, labelItems, toggleDatesPopUp]);

  return (
    <div className="anomaly-header">
      <FluroTabs
        onTabClick={(value: 'crop-stress' | 'areas-of-interest') =>
          dispatch(onChangeAnomalyTab(value))
        }
        selectedTab={anomaliesSelectedTab}
        tabs={[
          {label: t({id: 'Crop Stress'}), value: 'crop-stress'},
          {label: t({id: 'Areas of Interest'}), value: 'areas-of-interest'},
        ]}
      />

      {(isCropStressTabSelected
        ? !!premiumAnomaliesList.length
        : !!lowPerformingAnomaliesAndRoi.length && !visibleLowPerfAnomalies) && (
        <div className={'actions-panel'}>
          <CustomCheckbox isChecked={isCheckedAll} onCheck={() => onSelectAll(!isCheckedAll)} />

          <div>
            <div className={'d-flex'}>
              <h3 className="anomaly-header__title">{title}</h3>
              <span className="anomaly-header__subtitle">{subtitle}</span>
            </div>

            {onSort && (
              <div className="anomaly-header__sorting">
                <span>{t({id: 'Sort by'})}:</span>
                <SelectField
                  id="crop-stress-sort-select"
                  className={'select-sort-type'}
                  label=""
                  value={sortBy.type}
                  onChange={val => onSort(val, sortBy.order)}
                  menuItems={sortTypes}
                />
                <FontIcon
                  className={'order-icon'}
                  onClick={() => onSort(sortBy.type, !sortBy.order)}
                >
                  {sortBy.order ? 'arrow_drop_down' : 'arrow_drop_up'}
                </FontIcon>
              </div>
            )}
          </div>

          <div className={'actions-panel__buttons'}>
            {!isCropStressTabSelected && (
              <ReadOnly containerClass={'md-inline-block'}>
                <Button
                  id={`add-new-anomaly`}
                  className={'add-new-anomaly'}
                  primary
                  raised
                  iconEl={<FontIcon>add</FontIcon>}
                  iconBefore={false}
                  onClick={() => dispatch(toggleDialog(DialogType.AddNewAnomaly))}
                />
              </ReadOnly>
            )}

            <ReadOnly containerClass={'md-inline-block'}>
              <MenuButton
                id={`anomalies-change-menu-button`}
                className="anomaly-header__menu"
                listClassName="anomaly-header__menu-list"
                icon
                visible={menuOpened}
                onVisibilityChange={val => toggleMenu(val)}
                menuItems={changeGeometryActions}
              >
                <FontIcon>more_vert</FontIcon>
              </MenuButton>
            </ReadOnly>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnomalyHeader;
