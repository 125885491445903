import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import Yup from 'yup';
import {Button, FontIcon, SelectField} from 'react-md';
import FluroDialog from 'components/fluro-dialog';
import cn from 'classnames';
import {useForm} from 'react-hook-form';
import {FieldPayload} from '../../types';
import {Field} from '../../../../types';
import {toFixedFloat} from '_utils';
import {yupResolver} from '@hookform/resolvers/yup';

const validationSchema = Yup.object().shape({
  Pivot: Yup.boolean(),
  PivotCenterCoordinates: Yup.mixed().when(['Pivot'], {
    is: true,
    then: Yup.object().required(t({id: 'Coordinates are required for a center pivot field'})),
    otherwise: Yup.mixed(),
  }),
});

type Props = {
  field: Field;
  addPivotMarker(values: FieldPayload): void;
  onHide(): void;
  onSubmit(data: FieldPayload): void;
};

const FieldPivot = ({
  field: {Pivot, PivotCenterCoordinates},
  addPivotMarker,
  onHide,
  onSubmit,
}: Props) => {
  const {
    handleSubmit,
    formState: {errors},
    register,
    setValue,
    watch,
  } = useForm<FieldPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      Pivot,
      PivotCenterCoordinates,
    },
  });
  const values = watch();

  register('Pivot');
  register('PivotCenterCoordinates');

  return (
    <FluroDialog
      id={`pivot-pop-up`}
      visible={true}
      title={t({id: 'Pivot field'})}
      isDraggable
      onHide={onHide}
      focusOnMount={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={cn('inner-container', {
            error: values.Pivot && errors.PivotCenterCoordinates,
          })}
        >
          <SelectField
            id="field-centre-pivot"
            value={values.Pivot ? 1 : 0}
            label={t({id: 'Pivot field'})}
            menuItems={[
              {label: t({id: 'Yes'}), value: 1},
              {label: t({id: 'No'}), value: 0},
            ]}
            simplifiedMenu={true}
            onChange={value => {
              if (!value) setValue('PivotCenterCoordinates', null);
              setValue('Pivot', Boolean(value));
            }}
          />

          {values.Pivot && (
            <div className={'pivot-coordinates'}>
              <div className={'pivot-title'}>{t({id: 'Field center'})}</div>
              <div className={'coordinate-container'}>
                <span className={'label'}>{t({id: 'Lat:'})}</span>
                <span className={'value'}>{toFixedFloat(PivotCenterCoordinates?.Lat, 6)}</span>
              </div>

              <div className={'coordinate-container'}>
                <span className={'label'}>{t({id: 'Long:'})}</span>
                <span className={'value'}>{toFixedFloat(PivotCenterCoordinates?.Lon, 6)}</span>
              </div>

              <Button
                disabled={!values.Pivot}
                onClick={() => addPivotMarker(values)}
                icon
                iconEl={<FontIcon>edit</FontIcon>}
              />
            </div>
          )}
          {values.Pivot && errors.PivotCenterCoordinates && (
            <div className={'md-text-field-message-container md-text--error'}>
              {/*
              //@ts-ignore unknown error*/}
              {errors.PivotCenterCoordinates.message}
            </div>
          )}
        </div>

        <Button
          className={'save-btn'}
          type="submit"
          raised
          primary
          id="edit-pivot-settings-save-btn"
        >
          {t({id: 'Save changes'})}
        </Button>
      </form>
    </FluroDialog>
  );
};

export default FieldPivot;
