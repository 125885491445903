import {Tillage} from 'containers/map/features/optis/optis-types';
import {Season} from 'containers/map/types';
import {AppStore} from 'reducers';
import {Colors} from '_utils/colors';
import {CarbonState} from '../carbon-reducer';
import {getCropPractices, YearQuarter} from './crop-practice';
import {DSOCType} from '_api/carbon';

export enum CarbonPlanType {
  Recommended = 'Recommended plan',
  Custom = 'Custom plan',
}

export enum CarbonPractice {
  NoTillCoverCrops = 'No Till + Cover Crops',
  NoTill = 'No Till',
  CoverCrops = 'Cover Crops',
  ReducedTill = 'Reduced Till',
  ReducedTillCoverCrops = 'Reduced Till + Cover Crops',
  NoValue = '',
}

export const carbonPracticeOrder = [
  CarbonPractice.NoTillCoverCrops,
  CarbonPractice.NoTill,
  CarbonPractice.CoverCrops,
  CarbonPractice.ReducedTill,
  CarbonPractice.ReducedTillCoverCrops,
];

export const practiceColors = {
  [CarbonPractice.CoverCrops]: '#b49fda',
  [CarbonPractice.NoTill]: '#5cc5ef',
  [CarbonPractice.NoTillCoverCrops]: '#47ab95',
  [CarbonPractice.ReducedTill]: '#ffb745',
  [CarbonPractice.ReducedTillCoverCrops]: '#6aa84f',
  [CarbonPractice.NoValue]: '#999',
};

export type SeasonPayload = {
  farmId?: number;
  fieldId: number;
  seasonId?: number;
  year: number;
  summerCrop?: string;
  winterCrop?: string;
  springTillage?: string;
  fallTillage?: string;
  season?: Season;
};

export type CarbonPracticeChanges = {[fieldId: number]: {[year: number]: SeasonPayload}};

export const carbonYears = [2017, 2018, 2019, 2020, 2021];
export const carbonYearsZA = [...carbonYears].reverse();

const isValid = (value: string) => {
  return value && !value?.startsWith('low');
};

export const validatePractices = (p: ReturnType<typeof getCropPractices>, year?: number) => {
  const errors: YearQuarter[] = [];
  if (!isValid(p.springConfidence || p.spring)) errors.push(YearQuarter.SpringTillage);
  if (!isValid(p.summerConfidence || p.summer)) errors.push(YearQuarter.SummerCrop);
  if (!isValid(p.fallConfidence || p.fall) && year !== 2021) errors.push(YearQuarter.FallTillage);
  if (!isValid(p.winterConfidence || p.winter) && year !== 2021)
    errors.push(YearQuarter.WinterCrop);
  return errors;
};

/*
 *
 * Main farm validation function
 * validator behavior description: https://regrow.atlassian.net/browse/FSB-5111
 *
 * */
export const validateFarm = (
  farmId: number,
  fieldsByFarmId: AppStore['map']['fieldsByFarmId'],
  enrolledFields: AppStore['carbon']['enrolledFields'],
  guessedPractices: AppStore['carbon']['guessedPractices']
) => {
  let valid = true;

  const invalidFields: {[key: number]: boolean} = {};

  Object.values(fieldsByFarmId?.[farmId] || {}).flatMap(field => {
    if (!enrolledFields[field.ID]) {
      return null;
    }

    carbonYearsZA.forEach(year => {
      const p = getCropPractices(field.ID, year, field.Seasons, guessedPractices);
      const errors = validatePractices(p, year);

      if (errors.length) {
        invalidFields[field.ID] = true;
        valid = false;
      }
    });
  });

  return {isValidFarm: valid, invalidFields};
};

export const validateFarms = (
  fieldsByFarmId: AppStore['map']['fieldsByFarmId'],
  enrolledFields: AppStore['carbon']['enrolledFields'],
  guessedPractices: AppStore['carbon']['guessedPractices']
) => {
  const result: {
    isValidFarm: boolean;
    invalidFields: {[key: number]: boolean};
  } = {isValidFarm: true, invalidFields: {}};

  Object.keys(fieldsByFarmId)
    .map(Number)
    .forEach(farmId => {
      const v = validateFarm(farmId, fieldsByFarmId, enrolledFields, guessedPractices);
      result.invalidFields = {...result.invalidFields, ...v.invalidFields};
      // If it's already not valid, don't bother updating the value for the whole farm.
      if (result.isValidFarm) {
        result.isValidFarm = v.isValidFarm;
      }
    });

  return result;
};

export const getCarbonRewardKey = (practice: CarbonPractice) => {
  switch (practice) {
    case CarbonPractice.CoverCrops:
      return DSOCType.DSOCCoverCrop;
    case CarbonPractice.NoTill:
      return DSOCType.DSOCNoTill;
    case CarbonPractice.ReducedTill:
      return DSOCType.DSOCReducedTill;
    case CarbonPractice.NoTillCoverCrops:
      return DSOCType.DSOCNoTillCoverCrop;
    case CarbonPractice.ReducedTillCoverCrops:
      return DSOCType.DSOCReducedTillCoverCrop;
  }
};

export const carbonTillageOrder: [Tillage.NoTill, Tillage.Low, Tillage.Conventional] = [
  Tillage.NoTill,
  Tillage.Low,
  Tillage.Conventional,
];
