import {t} from 'i18n-utils';
import React, {useCallback, useEffect} from 'react';
import {
  DrawPolygonButton,
  FooterButtonGroup,
  PartFieldSeasonWrapper,
  SelectAddShapeStrategyWrapper,
  SplitFieldButton,
} from './field.styled';
import {InfoBlock} from 'components';
import {Button} from 'react-md';
import {useDispatch, useSelector} from 'react-redux';
import {showNote} from '_actions';
import {toggleDrawingMode, saveBulkCropAndSync} from 'containers/map/actions';
import {AppStore} from 'reducers';
import {
  clearSeasonNotSavedGeometries,
  CreateShapeMode,
  setCreateShapeMode as setMode,
  clearGeometries,
} from 'containers/map/features/farm/field/planting-area-season.module';
import {PolygonIcon} from '../../../icons';
import {DialogContentWrapper} from 'containers/admin/logs/email/email-logs.styled';
import PlantingAreaSeasonItem from './planting-area-season-item';
import moment from 'moment';
import Yup from 'yup';
import {validationSchema} from '../forms/edit-season-form/validation-schema';
import {PlantingAreaUploadFields} from './planting-area-upload-fields';

const schemaSeason = validationSchema(false, false, []);

const schema = Yup.array().of(schemaSeason);

const schemaName = Yup.array().of(
  Yup.object()
    .shape({
      name: Yup.string().max(50, t({id: 'Max length'}, {length: 50})),
    })
    .noUnknown(false)
);

type Props = {
  toggleEditSeason: (fieldId: number, seasonId: number) => void;
};

const PlantingAreaSeason = ({toggleEditSeason}: Props) => {
  const dispatch = useDispatch();
  const {isDrawingMode} = useSelector((s: AppStore) => s.map.drawControl);
  const currentGroupId = useSelector((s: AppStore) => s.global.currentGroupId);
  const selectedFieldId = useSelector((s: AppStore) => s.map.selectedFieldId);
  const createShapeMode = useSelector((s: AppStore) => s.partFieldSeason.createShapeMode);
  const geometry = useSelector((s: AppStore) => s.partFieldSeason.geometry);

  const setCreateShapeMode = useCallback((mode: CreateShapeMode) => {
    dispatch(setMode(mode));
  }, []);

  const notImplemented = useCallback(() => {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: `Coming soon`,
        level: 'warning',
      })
    );
  }, []);

  const onSaveCrop = useCallback(async () => {
    const sendData = {
      kml_id: selectedFieldId,
      seasons: geometry.map(({properties, geometry}) => ({
        id: null,
        name: properties.name,
        geometry,
        cropType: properties.cropType,
        startDate: properties.startDate || moment().format('YYYY-MM-DD'),
        endDate: properties.endDate || moment().add(1, 'month').format('YYYY-MM-DD'),
        params: {
          cropSubType: properties.cropSubType,
          isCustomCropSubType: properties.isCustomCropSubType,
        },
      })),
    };

    try {
      schema.validateSync(sendData.seasons);
      schemaName.validateSync(sendData.seasons);

      await dispatch(saveBulkCropAndSync(currentGroupId, [sendData], true));
      toggleEditSeason(0, 0);
      clearSeasonNotSavedGeometries();
      dispatch(clearGeometries());
    } catch (e) {
      const msg = e?.data?.result;

      dispatch(
        showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: msg ? msg : e.message,
          level: 'error',
        })
      );
    }
  }, [geometry, currentGroupId, selectedFieldId, toggleEditSeason]);

  useEffect(() => {
    const dialogNode = document.querySelector('.edit-season-dialog') as HTMLDivElement;
    dialogNode.style.display = isDrawingMode && dialogNode ? 'none' : 'block';
  }, [isDrawingMode]);

  const drawPolygon = useCallback(() => {
    dispatch(toggleDrawingMode(true, 'polygon', 'seasonGeometry'));
  }, []);

  const drawPolyline = useCallback(() => {
    dispatch(toggleDrawingMode(true, 'polyline', 'seasonGeometry'));
  }, []);

  if (geometry.length) {
    return (
      <PartFieldSeasonWrapper>
        <DialogContentWrapper>
          {geometry.map(g => (
            <PlantingAreaSeasonItem key={g.properties.id} data={g} />
          ))}
        </DialogContentWrapper>

        <div className="continue-btn-wrapper">
          <Button raised onClick={drawPolygon} iconEl={<PolygonIcon />}>
            {t({id: 'polygon'})}
          </Button>

          <Button primary raised onClick={onSaveCrop}>
            {t({id: 'Save'})}
          </Button>
        </div>
      </PartFieldSeasonWrapper>
    );
  }

  if (createShapeMode === CreateShapeMode.SelectAddShapeStrategy) {
    return (
      <PartFieldSeasonWrapper>
        <InfoBlock className={'inside-a-pop-up'} appearance="info">
          {t({
            id:
              'Select how you would like to denote your planting area in the field: draw it or upload it from a file (.shp or .kml).',
          })}
        </InfoBlock>

        <SelectAddShapeStrategyWrapper>
          <Button primary raised onClick={() => setCreateShapeMode(CreateShapeMode.SelectDrawTool)}>
            {t({id: 'Draw'})}
          </Button>
          <span>or</span>

          <Button raised onClick={() => setCreateShapeMode(CreateShapeMode.SelectFiles)}>
            {t({id: 'Upload boundaries'})}
          </Button>
        </SelectAddShapeStrategyWrapper>
      </PartFieldSeasonWrapper>
    );
  }

  if (createShapeMode === CreateShapeMode.SelectDrawTool) {
    return (
      <>
        <PartFieldSeasonWrapper>
          <DrawPolygonButton onClick={drawPolygon}>
            <i /> <span>Draw a polygon</span>
          </DrawPolygonButton>

          <SplitFieldButton onClick={drawPolyline}>
            <i /> <span>Split field</span>
          </SplitFieldButton>
        </PartFieldSeasonWrapper>
        <FooterButtonGroup>
          <Button raised onClick={() => setCreateShapeMode(CreateShapeMode.SelectAddShapeStrategy)}>
            Back
          </Button>
        </FooterButtonGroup>
      </>
    );
  }

  if (createShapeMode === CreateShapeMode.SelectFiles) {
    return (
      <>
        <PartFieldSeasonWrapper>
          <PlantingAreaUploadFields />
        </PartFieldSeasonWrapper>

        <FooterButtonGroup>
          <Button raised onClick={() => setCreateShapeMode(CreateShapeMode.SelectAddShapeStrategy)}>
            Back
          </Button>
        </FooterButtonGroup>
      </>
    );
  }

  return <PartFieldSeasonWrapper />;
};

export default PlantingAreaSeason;
