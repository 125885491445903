import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import MultiSelectChips from 'components/multi-select-chips';
import {Farm} from '../map/types';

type Props = ConnectedProps<typeof connector> & {
  selectedItems?: number[];
  skipReadOnly?: boolean;
  label?: string;
  onSelect: (values: number[]) => void;
  error?: boolean;
};

type State = {
  farmIds: number[];
};

class SelectFarmChips extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      farmIds: [],
    };
  }

  onSelect = (values: number[]) => {
    this.setState({
      farmIds: values.map((v: number) => v),
    });

    this.props.onSelect(values);
  };

  render() {
    let farms = this.props.farms;

    // skip demo farms
    if (this.props.skipReadOnly) {
      farms = farms.filter((f: Farm) => !f.readOnly);
    }

    return this.props.farms.length ? (
      <MultiSelectChips
        label={this.props.label}
        id="multi-farms-selector"
        onSelectChip={this.onSelect}
        items={farms.map((g: Farm) => ({
          label: `${g.name} #${g.id}`,
          value: g.id,
          group: g.growerName,
        }))}
        selectedItems={this.props.selectedItems || this.state.farmIds}
        hasLinksToFarms
        error={this.props.error}
      />
    ) : null;
  }
}

const mapStateToProps = ({farms}: {farms: any}) => ({
  farms: farms.list,
  demoFarmsIdsList: farms.demoFarmsIdsList,
});

const connector = connect(mapStateToProps);

export default connector(SelectFarmChips);
