import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Field} from '../../../map/types';
import Supercluster from 'supercluster';
import {Feature, Point} from 'geojson';
import {centroid, point, featureCollection} from '@turf/turf';
import {TiledMap} from '../../../map/tiled-map';
import L from 'leaflet';
import {Marker} from 'react-leaflet';
import {renderToString} from 'react-dom/server';
import {ClusterMarker} from '../../../map/features/crop-performance/map/cluster-marker';
import {log} from 'util';

type Props = {
  fields: Field[];
};

const superclusterOptions = {
  radius: 60,
  extent: 256,
  maxZoom: 11,
};
export const FieldsOnMap = ({fields}: Props) => {
  // It needs to be in ref so map.on('moveend') can always access the actual version of
  // the index instead of closured one if we'd use useState instead of useRef.
  const geospatialIndexRef = useRef<Supercluster>();
  const [leafletElement, setLeafletElement] = useState<L.Map>();
  const [clusterMarkers, setClusterMarkers] = useState<any[]>([]);

  const onRefMap = useCallback((node: any) => {
    if (node) {
      setLeafletElement(node);
    }
  }, []);

  useEffect(
    function setSupercluster() {
      if (!leafletElement) return;
      const fieldCentroids: Feature<Point>[] = fields.map(f => {
        return point([f.WestLon, f.SouthLat]);
      });

      geospatialIndexRef.current = new Supercluster(superclusterOptions).load(fieldCentroids);
      const preparedFieldsCollection = featureCollection(fieldCentroids);
      // @ts-ignore  featureCollection returns type that L.geoJSON doesn't expect, just misunderstanding between two libraries
      const preparedFieldsGeoJSON = L.geoJSON(preparedFieldsCollection);
      const preparedFieldsBounds = preparedFieldsGeoJSON.getBounds();
      if (preparedFieldsBounds.isValid()) {
        leafletElement.fitBounds(preparedFieldsBounds, {padding: [32, 32]});
      }
      updateClusterMarkers();
    },
    [fields, leafletElement]
  );

  const updateClusterMarkers = useCallback(() => {
    if (!geospatialIndexRef.current || !leafletElement) {
      return;
    }
    const bounds = leafletElement.getBounds();

    const clusters = geospatialIndexRef.current.getClusters(
      [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
      Math.round(leafletElement.getZoom())
    );

    setClusterMarkers(clusters);
  }, [fields, leafletElement]);

  useEffect(() => {
    leafletElement?.on('moveend', updateClusterMarkers);
    return () => {
      leafletElement?.off('moveend', updateClusterMarkers);
    };
  }, [updateClusterMarkers]);

  return (
    <TiledMap
      leafletElement={leafletElement}
      trackResize={true}
      layersSource={'mapbox'}
      onRefMap={onRefMap}
      className={'fields-preview-map'}
    >
      {clusterMarkers.map((m, index) => {
        const clusterId = m.properties.cluster_id;
        const point_count = m.properties.point_count || 1;
        const [lng, lat] = m.geometry.coordinates;

        return (
          <Marker
            key={`${clusterId} ${lng} ${lat}`}
            position={{lat, lng}}
            icon={L.divIcon({
              html: renderToString(
                <ClusterMarker
                  dataAndColors={{
                    data: [{id: clusterId, label: 'label', value: point_count}],
                    getColor: () => '#75BC56',
                  }}
                />
              ),
              className: `marker-cluster marker-cluster-${'medium'}`,
              iconSize: L.point(60, 60),
            })}
            eventHandlers={{
              click: (e: L.LeafletEvent) => {
                // do not try zoom to a single field
                if (point_count !== 1) {
                  // Expand clicked cluster – zoom in and change map center.
                  // @ts-ignore latlng is not exposed
                  const center = e.latlng;
                  const zoom = geospatialIndexRef.current.getClusterExpansionZoom(clusterId);
                  console.log('point_count', point_count);
                  // TODO: flyTo should take into account the side panel.
                  leafletElement.flyTo(center, zoom);
                }
              },
            }}
          />
        );
      })}
    </TiledMap>
  );
};
