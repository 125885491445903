import {FloatingContainer} from 'components/floating-container/floating-container';
import {t} from 'i18n-utils';
import * as React from 'react';
import {useState} from 'react';
import {FontIcon} from 'react-md';
import './carbon-outcomes.scss';
import {calcPlural, selectMeasurement} from '_utils';
import {FluroButton} from 'components';
import {useAppDispatch} from '_hooks';
import {CarbonStep, setStep} from '../carbon-reducer';

export type PracticeStats = {
  fieldCount: number;
  area: number;
};

export const CarbonOutcomes = ({
  totalFields,
  totalArea,
  carbonSequestered,
  moneyOutcome,
  approvePlan,
}: {
  totalFields: number;
  totalArea: number;
  carbonSequestered: number;
  moneyOutcome: number;
  approvePlan: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [isOutcomesHelpTextActive, setOutcomesHelpTextActive] = useState(false);

  return (
    <>
      <div className="carbon-outcomes">
        <h3 className={'section-title'}>
          Predicted outcomes{' '}
          <FontIcon onClick={() => setOutcomesHelpTextActive(!isOutcomesHelpTextActive)}>
            help_outline
          </FontIcon>
        </h3>
        {isOutcomesHelpTextActive && (
          <div className="description">
            Predicted volume estimation is based on modeling provided by the DNDC model, which takes
            into account properties and data such as historical crop types, tillage use, fertility
            plans, historical weather data, along soil stratification data.
            <div
              className="collapse-btn"
              onClick={() => setOutcomesHelpTextActive(!isOutcomesHelpTextActive)}
            >
              <FontIcon>keyboard_arrow_up</FontIcon>
            </div>
          </div>
        )}

        <div className="selected-fields">
          For {totalFields} {calcPlural('field', totalFields)} selected,{' '}
          {selectMeasurement(totalArea)}
        </div>
        <div className="iconed-row">
          <div className={'outcomes-label'}>
            <img src="/assets/CarbonSequestration.svg" alt="" />
            {t({id: 'Carbon sequestered and GHG reductions'})}
          </div>
          <div className="badge">
            <span className={'value'}>{carbonSequestered}</span> T CO<sub>2</sub> eq
          </div>
        </div>
        <div className="iconed-row">
          <div className={'outcomes-label'}>
            <img src="/assets/Money.svg" alt="" />
            {t({id: 'Outcomes payment'})}
          </div>
          <div className="badge">
            <span className={'value'}>{moneyOutcome} </span> USD
          </div>
        </div>
        <div className="outcomes-description">
          Calculated with carbon price $<span>20</span> MT and Cargill’s bonus $<span>5</span> MT.
        </div>
        <div className="outcomes-description">
          Above is a minimum estimation - the final amount will be based on the verification process
          conducted before the end of the contract period. The final payment amount may increase if
          you sequester more carbon upon verification.
        </div>
      </div>
      <FloatingContainer>
        <FluroButton raised blank onClick={() => dispatch(setStep(CarbonStep.CropPractices))}>
          {t({id: 'Back'})}
        </FluroButton>
        <FluroButton className={'primary-action-btn'} raised primary onClick={approvePlan}>
          {t({id: 'Approve plan'})}
        </FluroButton>
      </FloatingContainer>
    </>
  );
};
