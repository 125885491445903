import axios, {AxiosPromise} from 'axios';
import {v1 as uuidv1} from 'uuid';
import config from '_environment';

/**
 * data2 API doesn't follow the pattern baseUrl/api/v1/,
 * so we use separate axios requests instead of `./server`.
 */
export default class {
  static kml(md5: string): AxiosPromise<string> {
    return axios.get(`${config.baseUrl}data2/${md5}.kml`);
  }

  static featureCollections(
    md5s: string[]
  ): AxiosPromise<{
    data: {[key: string]: GeoJSON.FeatureCollection}; // Key is the md5 of the field.
    errors: string[];
  }> {
    const uuid = uuidv1();
    return axios.post(`${config.baseUrl}data2/fields`, JSON.stringify({md5s}), {
      headers: {
        'X-Request-ID': uuid,
        'X-Correlation-ID': uuid,
      },
      params: {
        __skipPreloader: true,
      },
    });
  }
}
