import * as React from 'react';
import {t} from 'i18n-utils';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  CircularProgress,
  DataTable,
  FontIcon,
  SelectionControl,
  TablePagination,
  TextField,
} from 'react-md';
import cn from 'classnames';
import IntegrationCard from './helpers/integration-card';
import ColoredBorder from './helpers/colored-border';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {isAdminPerm, sortByStringKey} from '_utils';
import {EFCApi} from '../../../_api';
import {
  ExpandableArea,
  ExpandableRowContainer,
  NoItemsTitle,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {RequestStatus} from '../../../types';
import Mixpanel from '_utils/mixpanel-utils';
import {ExternalService} from '../../map/types';
import {syncEFCGrowers} from './actions';
import HelpBtn from '../../help-popup/btn';
import HelpModal from '../../help-popup/modal';
import {reportError} from '../../error-boundary';
import ManageEFCUsersConnection from './integration-efc-manage-user-connection';
import {FluroDataTable, FluroTablePagination, Ln} from 'components';
import {FormattedMessage} from 'react-intl';
import {useAppSelector} from '../../../_hooks';

export const classifyHighestEntity = (data: EFCSyncRequestPayload) => {
  if (data.growers.length) {
    return t({id: 'growers'});
  } else if (data.farms.length) {
    return t({id: 'farms'});
  } else if (data.fields.length) {
    return t({id: 'fields'});
  }
};

type EFCEntity = {
  guid: string;
  name: string;
};

export type EFCSyncRequestPayload = {
  growers: string[];
  farms: string[];
  fields: string[];
};

type Props = {
  expanded: boolean;
  setExpanded(): void;
};

const NoEntities: EFCEntity[] = [{name: 'no-entities', guid: null}];

// Very similar flow to AgX v2 (send growers, farms, fields separately) + Climate (getting a job_id and checking for the sync status)

const IntegrationEFC = ({expanded, setExpanded}: Props) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((store: AppStore) => isAdminPerm(store.login.user.perm));
  const platform = useAppSelector(store => store.integrations.platforms[ExternalService.EFC]);

  const [authorized, setAuthorized] = useState(false);

  const [usersConnectionPopUpVisible, setUsersConnectionPopUpVisibility] = useState(false);

  const [growers, setGrowers] = useState<EFCEntity[]>([]);
  const [selectedGrowers, setSelectedGrowers] = useState<string[]>([]);
  const [expandedGrowers, setExpandedGrowers] = useState<{[growerId: string]: boolean}>({});

  const [farms, setFarms] = useState<{[growerId: string]: EFCEntity[]}>({});
  const [selectedFarms, setSelectedFarms] = useState<{[growerId: string]: string[]}>({});
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const [fields, setFields] = useState<{[farmId: string]: EFCEntity[]}>({});
  const [selectedFields, setSelectedFields] = useState<{[farmId: string]: string[]}>({});

  const [requests, setRequests] = useState<{[entityId: string]: RequestStatus}>({});
  const [pagination, setPagination] = useState({start: 0, perPage: 20, page: 1});
  const [searchString, onSearch] = useState('');
  const searchRef = useRef(null);

  useEffect(() => {
    getGrowers();
  }, []);

  const onOpenConnectUsersPopUp = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation(); // prevent toggle the EFC tab
    setUsersConnectionPopUpVisibility(true);
  };

  const onCurrentUserChangeConnection = (connected: boolean) => {
    if (connected) {
      getGrowers();
    } else {
      setAuthorized(false);
    }
  };

  const getGrowers = () => {
    setRequests({...requests, allGrowers: RequestStatus.Loading});
    EFCApi.getGrowers()
      .then(({data}) => {
        setRequests({...requests, allGrowers: RequestStatus.Success});
        const sortedGrowers = sortByStringKey(data || [], 'name');
        setGrowers(sortedGrowers);
        setAuthorized(true);
      })
      .catch(() => {
        setRequests({...requests, allGrowers: RequestStatus.Idle});
        setAuthorized(false);
      });
  };

  const onSearchChange = (value: string) => {
    onSearch(value);
    setPagination({start: 0, perPage: 20, page: 1}); // reset pagination
    if (searchRef?.current) {
      setTimeout(() => {
        // for some reason it doesn't work with and behavior: 'smooth'
        searchRef.current._container?.scrollIntoView({
          block: 'start',
          inline: 'start',
          behavior: 'smooth',
        });
      }, 0);
    }
  };

  const sync = () => {
    // Don't send the names, this list can be enormous.
    Mixpanel.importSync(ExternalService.EFC, []);

    dispatch(
      syncEFCGrowers({
        fields: Object.values(selectedFields).reduce((acc, v) => [...acc, ...v], []),
        // Take only selected farms that don't have fetched fields.
        farms: Object.values(selectedFarms).reduce(
          (acc, v) => [...acc, ...v.filter(farmId => !fields[farmId])],
          []
        ),
        // Take only selected growers that don't have fetched farms.
        growers: selectedGrowers.reduce(
          (acc, growerId) => (farms[growerId] ? acc : [...acc, growerId]),
          []
        ),
      })
    );
  };

  const filteredGrowers = useMemo(() => {
    const regExp = new RegExp(searchString, 'i');
    return growers.filter(grower => grower.name.match(regExp));
  }, [growers, searchString]);

  const slicedList = useMemo(() => {
    return filteredGrowers.slice(pagination.start, pagination.start + pagination.perPage);
  }, [pagination, filteredGrowers]);

  const getEFCFarms = (growerId: string) => {
    setRequests(r => ({...r, [growerId]: RequestStatus.Loading}));

    EFCApi.getFarms(growerId)
      .then(({data}) => {
        let f = sortByStringKey(data || [], 'name');

        if (!f.length) {
          f = NoEntities;
        }
        setFarms(farms => ({...farms, [growerId]: f}));
        setRequests(r => ({...r, [growerId]: RequestStatus.Success}));
        if (selectedGrowers.includes(growerId)) {
          // If the expanded grower was selected, select all the including farms automatically.
          setSelectedFarms(selectedFarms => ({
            ...selectedFarms,
            [growerId]: f.map(f => f.ID),
          }));
        }
      })
      .catch(e => {
        setFarms(farms => ({...farms, [growerId]: NoEntities}));
        setRequests(r => ({...r, [growerId]: RequestStatus.Error}));
        reportError(`EFC sync error fetching farms for grower ${growerId}: ` + e);
      });
  };

  const getEFCFields = (growerId: string, farmId: string) => {
    setRequests(r => ({...r, [farmId]: RequestStatus.Loading}));

    EFCApi.getFields(farmId)
      .then(({data}) => {
        let f = sortByStringKey(data || [], 'name');

        if (!f.length) {
          f = NoEntities;
        }

        setFields(fields => ({...fields, [farmId]: f}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Success}));
        if (selectedFarms[growerId]?.includes(farmId)) {
          // If the expanded farm was selected, select all the including fields automatically.
          setSelectedFields(selectedFields => ({
            ...selectedFields,
            [farmId]: f.map(f => f.ID),
          }));
        }
      })
      .catch(e => {
        setFields(fields => ({...fields, [farmId]: NoEntities}));
        setRequests(r => ({...r, [farmId]: RequestStatus.Error}));
        reportError(`EFC sync error fetching fields for farm ${farmId}: ` + e);
      });
  };

  const expandGrower = (growerId: string) => {
    const newValue = !expandedGrowers[growerId];
    setExpandedGrowers(x => ({
      ...x,
      [growerId]: newValue,
    }));
    if (newValue && !farms[growerId]) {
      getEFCFarms(growerId);
    }
  };

  const expandFarm = (growerId: string, farmId: string) => {
    const newValue = !expandedFarms[farmId];
    setExpandedFarms(x => ({
      ...x,
      [farmId]: newValue,
    }));
    if (newValue && !fields[farmId]) {
      getEFCFields(growerId, farmId);
    }
  };

  const toggleField = (growerId: string, farmId: string, fieldId: string, value: boolean) => {
    const oldFields = selectedFields[farmId] || [];
    const newFields = value ? [...oldFields, fieldId] : oldFields.filter(x => x !== fieldId);
    setSelectedFields(selectedFields => ({
      ...selectedFields,
      [farmId]: newFields,
    }));

    // Check if the parent (farm) needs to be updated.
    if (selectedFarms[growerId]?.includes(farmId) && !newFields.length) {
      // The farm is selected but none of the fields inside this farm is selected anymore.
      // Deselect the farm.
      const oldFarms = selectedFarms[growerId];
      const newFarms = selectedFarms[growerId].filter(id => id !== farmId);
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length > 0 && newFarms.length === 0) {
        // Some farms of the grower were selected and now none of them are selected.
        // Deselect the grower.
        setSelectedGrowers(growers => growers.filter(id => id !== growerId));
      }
    } else if (!selectedFarms[growerId]?.includes(farmId) && newFields.length) {
      // The farm is not selected but the field inside this farm is selected.
      // Select the farm.
      const oldFarms = selectedFarms[growerId] || [];
      const newFarms = [...oldFarms, farmId];
      setSelectedFarms(selectedFarms => ({
        ...selectedFarms,
        [growerId]: newFarms,
      }));

      // Check if parent (grower) needs to be updated.
      if (oldFarms.length === 0 && newFarms.length > 0) {
        // None of the farms of the grower were selected and now some of them are selected.
        // Select the grower.
        setSelectedGrowers(growers => [...growers, growerId]);
      }
    }
  };

  const toggleFarm = (growerId: string, farmId: string, value: boolean) => {
    const oldFarms = selectedFarms[growerId] || [];
    const newFarms = value ? [...oldFarms, farmId] : oldFarms.filter(x => x !== farmId);
    setSelectedFarms(selectedFarms => ({
      ...selectedFarms,
      [growerId]: newFarms,
    }));

    // Check if parent (grower) needs to be updated.
    if (oldFarms.length > 0 && newFarms.length === 0) {
      // Some farms of the grower were selected and now none of them are selected.
      // Deselect the grower.
      setSelectedGrowers(growers => growers.filter(id => id !== growerId));
    } else if (oldFarms.length === 0 && newFarms.length > 0) {
      // None of the farms of the grower were selected and now some of them are selected.
      // Select the grower.
      setSelectedGrowers(growers => [...growers, growerId]);
    }

    // If we have fields loaded for the farm, update them.
    if (fields[farmId]) {
      setSelectedFields(selectedFields =>
        value
          ? {...selectedFields, [farmId]: fields[farmId].map(f => f.guid)}
          : {...selectedFields, [farmId]: []}
      );
    }
  };

  const toggleGrower = (growerId: string, value: boolean) => {
    setSelectedGrowers(selectedGrowers => {
      return value ? [...selectedGrowers, growerId] : selectedGrowers.filter(x => x !== growerId);
    });

    // If we have farms loaded for the grower, update them.
    if (farms[growerId]) {
      setSelectedFarms(selectedFarms =>
        value
          ? {...selectedFarms, [growerId]: farms[growerId].map(f => f.guid)}
          : {...selectedFarms, [growerId]: []}
      );

      const newFields: {[farmId: string]: string[]} = {};
      farms[growerId].forEach(farm => {
        if (fields[farm.guid]) {
          newFields[farm.guid] = value ? fields[farm.guid].map(f => f.guid) : [];
        }
      });
      setSelectedFields(newFields);
    }
  };

  const toggleAllGrowers = (value: boolean) => {
    // TODO stas: update all the children (farms and fields).
    setSelectedGrowers(value ? growers.map(g => g.guid) : []);

    const newFarms: {[growerId: string]: string[]} = {};
    Object.entries(farms).forEach(([growerId, farms]) => {
      newFarms[growerId] = value ? farms.map(f => f.guid) : [];
    });
    setSelectedFarms(newFarms);

    const newFields: {[farmId: string]: string[]} = {};
    Object.entries(fields).forEach(([farmId, fields]) => {
      newFields[farmId] = value ? fields.map(f => f.guid) : [];
    });
    setSelectedFields(newFields);
  };

  return (
    <div className={cn('integration-platform efc', {expanded, selected: platform.selected})}>
      <IntegrationCard
        authorized={true}
        expanded={expanded}
        logoSrc={'/assets/integration/efc-logo-round.svg'}
        logoAlt={'logo-efc'}
        onAuthorize={() => null}
        onToggle={setExpanded}
        platformCompanyName={'EFC'}
        platformName={'FieldAlytics'}
        platformInnerName={ExternalService.EFC}
        isPlatformSelected={platform.selected}
        loading={requests['allGrowers'] && !growers.length}
        customAuthButton={
          isAdmin ? (
            <div className={'connect-disconnect-buttons'}>
              <Button
                className={'manage-connections-btn'}
                raised
                primary
                onClick={onOpenConnectUsersPopUp}
              >
                {t({id: 'Manage connections'})}
              </Button>
            </div>
          ) : null
        }
      />

      {expanded ? (
        authorized ? (
          <>
            {requests['allGrowers'] && !growers.length ? null : (
              <>
                <div className={'entities-to-import-container'}>
                  {growers.length > 1 && (
                    <>
                      <div className={'search-wrapper'}>
                        <FontIcon className={'search-icon'}>search</FontIcon>
                        <TextField
                          ref={searchRef}
                          id="search-grower"
                          label={t({id: 'Search a grower'})}
                          className="search-a-user"
                          value={searchString}
                          onChange={value => onSearchChange(`${value}`)}
                        />
                      </div>
                      <SelectionControl
                        id={'select-all-growers'}
                        name={'select-all-growers'}
                        label={t(
                          {id: 'Select all growers ({count1}/{count2})'},
                          {count1: selectedGrowers?.length || 0, count2: growers.length}
                        )}
                        type={'checkbox'}
                        className={'select-all-checkbox'}
                        checked={growers.length === selectedGrowers.length}
                        onChange={(v: boolean) => toggleAllGrowers(v)}
                      />
                    </>
                  )}
                  {slicedList.map(g => {
                    const isGrowerSelected = selectedGrowers.includes(g.guid);
                    return (
                      <React.Fragment key={g.guid}>
                        <ExpandableRowContainer
                          className={cn({
                            selected: isGrowerSelected,
                          })}
                        >
                          <SelectionControl
                            id={g.guid}
                            name={'grower selection'}
                            className={'highest-platform-entity'}
                            label={
                              g.name + selectedEntitiesNumber(farms[g.guid], selectedFarms[g.guid])
                            }
                            type={'checkbox'}
                            checked={isGrowerSelected}
                            onChange={(v: boolean) => toggleGrower(g.guid, v)}
                          />
                          <ExpandableArea
                            onClick={() => expandGrower(g.guid)}
                            expanded={expandedGrowers[g.guid]}
                          />
                        </ExpandableRowContainer>
                        {expandedGrowers[g.guid] && (
                          <SubContainer>
                            {requests[g.guid] === RequestStatus.Loading && (
                              <span className="preloader">
                                <CircularProgress centered={false} id={g.guid} />
                              </span>
                            )}
                            {farms[g.guid]?.map(f => {
                              if (farms[g.guid]?.length === 1 && f.name === 'no-entities') {
                                return <NoItemsTitle>{t({id: 'No farms found'})}</NoItemsTitle>;
                              }

                              const isFarmSelected = (selectedFarms[g.guid] || []).includes(f.guid);
                              return (
                                <React.Fragment key={f.guid}>
                                  <SubItem
                                    className={cn({
                                      selected: isFarmSelected,
                                    })}
                                  >
                                    <SelectionControl
                                      id={f.guid}
                                      name={'farm selection'}
                                      className={'medium-platform-entity'}
                                      label={
                                        f.name +
                                        selectedEntitiesNumber(
                                          fields[f.guid],
                                          selectedFields[f.guid]
                                        )
                                      }
                                      type={'checkbox'}
                                      checked={isFarmSelected}
                                      onChange={(v: boolean) => toggleFarm(g.guid, f.guid, v)}
                                    />
                                    <ExpandableArea
                                      onClick={() => expandFarm(g.guid, f.guid)}
                                      expanded={expandedFarms[f.guid]}
                                    />
                                  </SubItem>
                                  {expandedFarms[f.guid] && (
                                    <SubContainer>
                                      {requests[f.guid] === RequestStatus.Loading && (
                                        <span className="preloader">
                                          <CircularProgress centered={false} id={f.guid} />
                                        </span>
                                      )}
                                      {fields[f.guid]?.map(field => {
                                        if (
                                          fields[f.guid]?.length === 1 &&
                                          field.name === 'no-entities'
                                        ) {
                                          return (
                                            <NoItemsTitle>
                                              {t({id: 'No fields found'})}
                                            </NoItemsTitle>
                                          );
                                        }

                                        return (
                                          <SubItem key={field.guid}>
                                            <SelectionControl
                                              id={field.guid}
                                              name={'field selection'}
                                              className={'small-platform-entity'}
                                              label={field.name}
                                              type={'checkbox'}
                                              checked={(selectedFields[f.guid] || []).includes(
                                                field.guid
                                              )}
                                              onChange={(v: boolean) =>
                                                toggleField(g.guid, f.guid, field.guid, v)
                                              }
                                            />
                                          </SubItem>
                                        );
                                      })}
                                    </SubContainer>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </SubContainer>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>

                <div className="pagination">
                  {filteredGrowers.length >= 20 && (
                    <FluroDataTable elevated={false} baseId={'growers-list'}>
                      <FluroTablePagination
                        page={pagination.page}
                        rows={filteredGrowers.length}
                        rowsPerPage={pagination.perPage}
                        rowsPerPageLabel={t({id: 'Growers'})}
                        onPagination={(start: number, perPage: number, page: number) =>
                          setPagination({start, perPage, page})
                        }
                      />
                    </FluroDataTable>
                  )}
                </div>

                <div className="md-text-right">
                  {platform.syncStatus === RequestStatus.Loading && (
                    <HelpBtn
                      icon={'refresh'}
                      id="agx-sync-status"
                      className="integration-sync-status integration-platform__sync-status"
                    />
                  )}

                  <Button
                    disabled={
                      !selectedGrowers.length || platform.syncStatus === RequestStatus.Loading
                    }
                    raised
                    primary
                    onClick={sync}
                  >
                    {t({id: 'Sync'})}
                  </Button>
                </div>
              </>
            )}

            {platform.syncStatus === RequestStatus.Loading && (
              <HelpModal title="Sync in progress" id="efc-sync-status" width={400}>
                <div className="helper-text">
                  {t({id: 'You will be notified when it is ready'})}
                </div>
              </HelpModal>
            )}
          </>
        ) : requests.allGrowers === RequestStatus.Loading ? (
          <div>{t({id: 'Loading growers list...'})}</div>
        ) : (
          <>
            <div className="integration-help-text">
              <FormattedMessage
                id="Please contact us if you want to import data from EFC"
                defaultMessage="Please contact us if you want to import data from EFC, we'll need access to your account details and the list of farms you would like to import. <a>Read more about the connection process.</a>"
                values={{
                  a: (txt: string) => (
                    <Ln
                      href={
                        'https://help.flurosense.com/en/articles/4790718-how-to-connect-your-agrian-or-efc-fieldalytics-account'
                      }
                      external
                      blank
                    >
                      {txt}
                    </Ln>
                  ),
                }}
              />
            </div>

            <div className={'md-text-right'}>
              <Button
                raised
                primary={!isAdmin}
                onClick={() => document.getElementById('intercom-launch')?.click()}
              >
                {t({id: 'Contact us'})}
              </Button>
            </div>
          </>
        )
      ) : null}
      <ColoredBorder colorStart={'rgb(0, 47, 154)'} colorEnd={'rgba(43, 69, 124, 0.2)'} />

      <ManageEFCUsersConnection
        onPopupHide={() => setUsersConnectionPopUpVisibility(false)}
        visible={usersConnectionPopUpVisible}
        toggleCurrentUserConnection={(value: boolean) => onCurrentUserChangeConnection(value)}
      />
    </div>
  );
};

const selectedEntitiesNumber = (list: EFCEntity[], selected?: string[]) => {
  if (!list) {
    return '';
  }
  return ` (${selected?.length || 0}/${list.length})`;
};

export default IntegrationEFC;
