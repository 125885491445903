import {IUserSettings} from 'containers/login/types';
import {Subscription} from 'modules/subscriptions';

export type User = {
  id: string | number;
  name: string;
  surname: string;
  phone?: string;
  email: string;
  password: string;
  groupIds?: number[];
  perm: number;
  active: number;
  emailConfirmed?: number | boolean;
  firstImageryOnFieldTerravion?: boolean | string;
  groupId?: string; // DEPRECATED;
  settings?: UserSettings;
  defaultOrganizationID: number | null;
};

export type UserSettings = IUserSettings;

export type InitialState = {
  list: User[];
  user: User;
  organizations: Organization[];
  subscriptions: Subscription[];
};

export type Organization = {
  id: number;
  name: string;
  created_by: number;
  subscription_id: number;
  subscription_name: string;
  created_at: string;
  fields: number;
  farms: number;
  fields_area: number;
  direct_users: number;
  indirect_users: number;
};

export type ActionPayload = {
  type: string;
  list?: User[];
  user?: User;
  organizations?: Organization[];
  subscriptions?: Subscription[];
};

export enum ActionType {
  LOAD_USERS_LIST = 'users/load-users-list',
  LOAD_USERS_ONE = 'users/load-users-one',
  SET_ORGS = 'users/set-organizations',
  SET_SUBS = 'users/set-subscriptions',
}
