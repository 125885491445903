import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {getUsersList} from '../actions';
import {MAPPING_ROLES_TO_PROPS} from '_constants';
import {history} from 'store';
import {FontIcon, TextField} from 'react-md';

import {
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroTableBody,
  FluroTablePagination,
  FluroButton,
} from 'components';

import {calcRowsPerPageItems, rowsPerPage} from 'containers/admin';
import ImpersonateButton from 'containers/admin/users/impersonate-button';
import {useAppSelector} from '_hooks';
import {AdminContainer, AdminContentWrapper, AdminControls, AdminTitle} from '../../admin.styled';

const UserList = ({match}: RouteComponentProps) => {
  const dispatch = useDispatch();
  const rawList = useAppSelector(store => store.users.list);
  const [searchString, onSearch] = useState('');
  const [pagination, onChangePagination] = useState({start: 0, perPage: rowsPerPage, page: 1});

  const preparedList = useMemo(() => {
    return rawList.map(user => ({
      ...user,
      loweredName: (user.name || '').toLowerCase(),
      loweredSurname: (user.surname || user.settings?.surname || '').toLowerCase(),
      loweredEmail: user.email.toLowerCase(),
    }));
  }, [rawList]);

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  const handlePagination = (start: number, perPage: number, page: number) => {
    onChangePagination({start, perPage, page});
  };

  const filteredList = useMemo(() => {
    const lowerSearchString = searchString.toLowerCase();
    onChangePagination({start: 0, perPage: pagination.perPage, page: 1});
    return preparedList.filter(
      user =>
        user.loweredName.includes(lowerSearchString) ||
        user.loweredEmail.includes(lowerSearchString) ||
        user.loweredSurname.includes(lowerSearchString)
    );
  }, [preparedList, searchString]);

  const slicedList = useMemo(() => {
    return filteredList.slice(pagination.start, pagination.start + pagination.perPage);
  }, [pagination, filteredList]);

  const addNew = () => {
    history.push(`/admin/users/new`);
  };

  return (
    <AdminContainer className={'users-list'}>
      <AdminControls
        className={'header-controls'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <AdminTitle>Users</AdminTitle>
        <div className={'search-wrapper'}>
          <FontIcon>search</FontIcon>
          <TextField
            id="search-user"
            label="Search a user"
            className="search-a-user"
            value={searchString}
            onChange={value => onSearch(`${value}`)}
          />
        </div>

        <FluroButton
          id={'add-new-user-btn'}
          className={'add-button'}
          raised
          primary
          onClick={addNew}
          iconEl={<FontIcon>add</FontIcon>}
        >
          Add new user
        </FluroButton>
      </AdminControls>

      <AdminContentWrapper>
        <FluroDataTable fixedDividers={true} baseId="users-admin-list">
          <FluroTableHeader>
            <FluroTableRow>
              <FluroTableColumn type={'number'}>ID</FluroTableColumn>
              <FluroTableColumn>Email</FluroTableColumn>
              <FluroTableColumn>Name</FluroTableColumn>
              <FluroTableColumn>Permission</FluroTableColumn>
              <FluroTableColumn>Active</FluroTableColumn>
              <FluroTableColumn>Login as</FluroTableColumn>
            </FluroTableRow>
          </FluroTableHeader>

          <FluroTableBody>
            {slicedList.map((u, i) => (
              <FluroTableRow key={'u-row-' + u.id}>
                <FluroTableColumn type={'number'}>#{u.id}</FluroTableColumn>

                <FluroTableColumn>
                  <Link to={`${match.path}/${u.id}`}>{u.email}</Link>
                </FluroTableColumn>

                <FluroTableColumn>{`${u.name} ${
                  u.surname || u.settings?.surname
                }`}</FluroTableColumn>

                <FluroTableColumn>{MAPPING_ROLES_TO_PROPS[u.perm].prop}</FluroTableColumn>

                <FluroTableColumn>{u.active ? 'Yes' : 'No'}</FluroTableColumn>
                <FluroTableColumn>
                  <ImpersonateButton email={u.email} userId={u.id as number} />
                </FluroTableColumn>
              </FluroTableRow>
            ))}
          </FluroTableBody>
          <FluroTablePagination
            page={pagination.page}
            rows={filteredList.length}
            rowsPerPage={pagination.perPage}
            onPagination={handlePagination}
            rowsPerPageItems={calcRowsPerPageItems(filteredList.length)}
          />
        </FluroDataTable>
      </AdminContentWrapper>
    </AdminContainer>
  );
};

export default UserList;
