import * as React from 'react';
import {useMemo} from 'react';
import {FluroChip} from 'components/fluro-chip/fluro-chip';
import {carbonYears, validateFarms} from '../base/base';
import './carbon-panel-crop-practices-panel.scss';
import {useAppDispatch, useAppSelector} from '_hooks';
import {CarbonStep, setQuarter, setStep, setYear} from '../carbon-reducer';
import {
  countCropPracticeChartData,
  getCropPractices,
  YearQuarter,
  yearQuarterOrder,
} from '../base/crop-practice';
import {Card} from 'components/card/card';
import {
  ChartData,
  StackedSingleBarChart,
} from 'components/stacked-single-bar-chart/stacked-single-bar-chart';
import {LegendList} from 'components/legend-list/legend-list';
import {t} from 'i18n-utils';
import {Tillage, tillageColor, tillageLabels} from 'containers/map/features/optis/optis-types';
import {FluroButton} from 'components';
import {ConfirmPracticesMessage} from '../base/messages';
import {View} from './carbon-panel-crop-practices';
import {FloatingContainer} from '../../../components/floating-container/floating-container';

export const CarbonPanelCropPracticesPanel = ({selectView}: {selectView?: (v: View) => void}) => {
  const dispatch = useAppDispatch();
  const carbon = useAppSelector(s => s.carbon);
  const fieldsByFarmId = useAppSelector(s => s.map.fieldsByFarmId);
  const cropTypes = useAppSelector(s => s.global.cropTypes);

  const cropPractices = useMemo(() => {
    return Object.values(fieldsByFarmId).flatMap(fields =>
      Object.values(fields)
        .filter(f => carbon.enrolledFields[f.ID])
        .map(f => getCropPractices(f.ID, carbon.year, f.Seasons, carbon.guessedPractices))
    );
  }, [fieldsByFarmId, carbon.enrolledFields, carbon.guessedPractices, carbon.year]);

  const allFarmsAreValid = useMemo(() => {
    const v = validateFarms(fieldsByFarmId, carbon.enrolledFields, carbon.guessedPractices);
    return v.isValidFarm;
  }, [fieldsByFarmId, carbon.enrolledFields, carbon.guessedPractices]);

  const chartData: {[quarter: string]: ChartData} = {};
  yearQuarterOrder.forEach(quarter => {
    switch (quarter) {
      case YearQuarter.SpringTillage: {
        const count = countCropPracticeChartData(cropPractices, quarter);
        chartData[quarter] = {
          values: Object.values(count),
          labels: Object.keys(count).map(v => tillageLabels[v as Tillage]),
          colors: Object.keys(count).map(v => tillageColor[v as Tillage]),
        };
        break;
      }
      case YearQuarter.SummerCrop: {
        const count = countCropPracticeChartData(cropPractices, quarter);
        chartData[quarter] = {
          values: Object.values(count),
          labels: Object.keys(count),
          colors: Object.keys(count).map(v => cropTypes[v]?.color),
        };
        break;
      }
      case YearQuarter.FallTillage: {
        const count = countCropPracticeChartData(cropPractices, quarter);
        chartData[quarter] = {
          values: Object.values(count),
          labels: Object.keys(count).map(v => tillageLabels[v as Tillage]),
          colors: Object.keys(count).map(v => tillageColor[v as Tillage]),
        };
        break;
      }
      case YearQuarter.WinterCrop: {
        const count = countCropPracticeChartData(cropPractices, quarter);
        chartData[quarter] = {
          values: Object.values(count),
          labels: Object.keys(count),
          colors: Object.keys(count).map(v => cropTypes[v]?.color),
        };
        break;
      }
    }
  });

  return (
    <div className="carbon-panel__crop-practices-panel">
      <div className="description">
        Confirm cropping and tillage practices on these fields for the past 5 years. This
        information will be used to generate predicted environmental outcomes in the next step.
      </div>
      <div className="year-select">
        {carbonYears.map(year => (
          <FluroChip
            key={year}
            label={String(year)}
            active={year === carbon.year}
            onClick={() => dispatch(setYear(year))}
          />
        ))}
      </div>
      <div>
        {yearQuarterOrder.map(quarter => {
          const data = chartData[quarter];
          return (
            <Card
              key={quarter}
              title={quarter}
              active={quarter === carbon.quarter}
              onClick={() => dispatch(setQuarter(quarter))}
            >
              <StackedSingleBarChart data={data} selectedLabels={[]} />
              <div style={{height: 8}}></div>
              <LegendList
                list={data.labels.map((label, i) => ({
                  label,
                  value: label,
                  backgroundColor: data.colors[i],
                  count: t({id: '{count} crops'}, {count: data.values[i]}),
                }))}
              />
            </Card>
          );
        })}
      </div>

      {!allFarmsAreValid && (
        <ConfirmPracticesMessage
          goToTable
          onClick={() => {
            selectView?.(View.Table);
          }}
        />
      )}

      <FloatingContainer>
        <FluroButton raised blank onClick={() => dispatch(setStep(CarbonStep.Fields))}>
          {t({id: 'Back'})}
        </FluroButton>
        <FluroButton
          disabled={!allFarmsAreValid}
          className={'primary-action-btn'}
          raised
          primary
          onClick={() => dispatch(setStep(CarbonStep.Credits))}
        >
          {t({id: CarbonStep.CropPractices})}
        </FluroButton>
      </FloatingContainer>
    </div>
  );
};
