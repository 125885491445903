import * as React from 'react';
import {useCallback} from 'react';
import {ResponsiveBar} from '@nivo/bar';
import {ResponsiveLine} from '@nivo/line';
import {Card} from 'components/card/card';
import {setOpacity} from '_utils/colors';
import {LegendList} from 'components/legend-list/legend-list';
import {defaultFilterValue} from './optis-reducer';
import {
  OptisItemTillage,
  OptisState,
  OptisType,
  Tillage,
  tillageColor,
  tillageLabels,
  tillageOrder,
} from './optis-types';
import {
  createTillage,
  addTillageMut,
  sumTillage,
  formatNumber,
  getTimePeriod,
  getCategories,
  diffPctTillage,
} from './optis-utils';
import {InfoBlock} from 'components';
import {OptisChartTooltip} from './base/chart-tooltip';
import {OptisChartDescription, OptisDiffModeDescription} from './base/chart-description';
import {
  ChartData,
  StackedSingleBarChart,
} from 'components/stacked-single-bar-chart/stacked-single-bar-chart';
import {AdoptionContainer} from './base/adoption-container';

export const TillageCard = ({
  optis,
  onFilterClick,
}: {
  optis: OptisState;
  onFilterClick: (filter: {year?: number; value?: Tillage; multiselect?: boolean}) => void;
}) => {
  const onFilterToggle = useCallback(value => onFilterClick({value}), [onFilterClick]);

  const tillageData: (OptisItemTillage & {
    year: number;
  })[] = []; // Array of years, where each year is a sum of all the geometries.

  // Sum all the geometry values across years.
  Object.keys(optis.tillage)
    .map(Number)
    .filter(id => optis.filter.geometries.length === 0 || optis.filter.geometries.includes(id))
    .forEach(id => {
      const years = optis.tillage[id];
      Object.keys(years)
        .map(Number)
        // In optis.avgMode mode this data is used for adoption chart, which should show all the years.
        // .filter(year => optis.filter.years.length === 0 || optis.filter.years.includes(year))
        .forEach((year, i) => {
          if (!tillageData[i]) {
            tillageData[i] = {
              year,
              ...createTillage(),
            };
          }
          addTillageMut(tillageData[i], years[year]);
        });
    });

  // Get data for the legend before we convert it to percentage.
  const tillageDataForSelectedYearsSummed = tillageData
    .filter(t => optis.filter.years.length === 0 || optis.filter.years.includes(t.year))
    .reduce((acc, b) => addTillageMut(acc, b), createTillage());

  let pctTillage: OptisItemTillage;
  if (optis.diffMode && optis.diffYearA && optis.diffYearB) {
    const a = tillageData.find(t => t.year === optis.diffYearA);
    const b = tillageData.find(t => t.year === optis.diffYearB);
    pctTillage = diffPctTillage(a, b);
  } else {
    pctTillage = createTillage();
    const total = sumTillage(tillageDataForSelectedYearsSummed);
    tillageOrder.forEach(t => {
      pctTillage[t] = ((tillageDataForSelectedYearsSummed[t] / total) * 100) | 0;
    });
  }

  const sumDataAcrossYears = tillageData.reduce((acc, yearOfData) => {
    if (optis.filter.years.includes(yearOfData.year)) {
      tillageOrder.forEach(t => {
        acc[t] += yearOfData[t];
      });
    }
    return acc;
  }, createTillage());

  const avgYearData: ChartData = {
    values: tillageOrder.map(t => Math.round(sumDataAcrossYears[t] / tillageData.length)),
    labels: tillageOrder,
    colors: tillageOrder.map(t => tillageColor[t]),
  };

  // Convert acres to percentage.
  tillageData.forEach(data => {
    const total = sumTillage(data);
    tillageOrder.forEach(t => {
      data[t] = ((data[t] / total) * 100) | 0;
    });
  });

  const lineChartData = {
    [Tillage.Conventional]: createLineChartData(Tillage.Conventional),
    [Tillage.Low]: createLineChartData(Tillage.Low),
    // [Tillage.High]: createLineChartData(Tillage.High),
    // [Tillage.NoTill]: createLineChartData(Tillage.NoTill),
    [Tillage.Conservation]: createLineChartData(Tillage.Conservation),
  };
  tillageData.forEach(yearOfData => {
    const order = !optis.avgMode
      ? tillageOrder
      : optis.filter.type === OptisType.Tillage
      ? optis.filter.value
      : defaultFilterValue[OptisType.Tillage];
    order.forEach(t => {
      // @ts-ignore in case of optis.avgMode the list of tillages is wider than 3 tillage types
      lineChartData[t].data.push({
        x: yearOfData.year,
        y: yearOfData[t],
      });
    });
  });

  // Remove empty categories from line chart data.
  tillageOrder.forEach(key => {
    if (!lineChartData[key].data.length) {
      delete lineChartData[key];
    }
  });

  const getColor = (d: any) => {
    const selected = optis.filter.type === OptisType.Tillage && optis.filter.value.includes(d.id);
    const opacity = selected ? 1 : 0.15;
    return setOpacity(tillageColor[d.id as Tillage], opacity, true);
  };

  if (!Object.keys(lineChartData).length) {
    return null;
  }
  const timePeriod = getTimePeriod(
    optis.diffMode && optis.diffYearA && optis.diffYearB
      ? [optis.diffYearA, optis.diffYearB]
      : Object.values(tillageData)
          .map(y => y.year)
          .filter(y => optis.filter.years.includes(y))
  );
  const {verb, categories} = getCategories(optis.filter, OptisType.Tillage);

  return (
    <Card
      active={optis.filter.type === OptisType.Tillage}
      title="Tillage practices"
      info={
        optis.avgMode ? undefined : (
          <InfoBlock>Use shift+click to select multiple years / categories.</InfoBlock>
        )
      }
      onClick={() => onFilterClick({})}
      isReportView={false}
      // onFilterClear={onFilterClear}
    >
      {optis.diffMode && optis.diffYearA && optis.diffYearB ? (
        <OptisDiffModeDescription categories={categories} timePeriod={timePeriod} />
      ) : (
        <OptisChartDescription verb={verb} categories={categories} timePeriod={timePeriod} />
      )}
      {!optis.diffMode && (
        <div style={{height: optis.avgMode ? 'auto' : 200, marginBottom: 10}}>
          {optis.avgMode ? (
            <StackedSingleBarChart
              data={avgYearData}
              selectedLabels={optis.filter.value}
              onFilterToggle={onFilterToggle}
              // onShowTooltip={onShowTooltip}
            />
          ) : tillageData.length === 1 ? (
            <BarChart
              keys={tillageOrder}
              data={tillageData}
              getColor={getColor}
              onFilterClick={onFilterClick}
            />
          ) : (
            <LineChart
              data={Object.values(lineChartData)}
              getColor={getColor}
              onFilterClick={onFilterClick}
            />
          )}
        </div>
      )}
      <LegendList
        onClick={(tillage, e) =>
          onFilterClick({
            value: tillage as Tillage,
            multiselect: e.metaKey || e.shiftKey || e.ctrlKey,
          })
        }
        diffMode={optis.diffMode && optis.diffYearA != null && optis.diffYearB != null}
        list={[...tillageOrder].reverse().map(tillage => ({
          label: tillageLabels[tillage],
          value: tillage,
          deselected:
            optis.filter.type === 'tillage' &&
            optis.filter.value.length &&
            !optis.filter.value.includes(tillage),
          backgroundColor: tillageColor[tillage],
          percentage: pctTillage[tillage],
          count:
            optis.diffMode && optis.diffYearA && optis.diffYearB
              ? undefined
              : `(${formatNumber(tillageDataForSelectedYearsSummed[tillage])} ac)`,
        }))}
      />
      {optis.avgMode && (
        <AdoptionContainer
          title={
            optis.filter.type === OptisType.Tillage
              ? optis.filter.value.map(t => tillageLabels[t]).join(', ')
              : defaultFilterValue[OptisType.Tillage].map(t => tillageLabels[t]).join(', ')
          }
        >
          {tillageData.length === 1 ? (
            <BarChart keys={tillageOrder} data={tillageData} getColor={getColor} />
          ) : (
            <LineChart data={Object.values(lineChartData)} getColor={getColor} />
          )}
        </AdoptionContainer>
      )}
    </Card>
  );
};

type LineChartData = {
  id: Tillage;
  color: string;
  data: {x: number; y: number}[]; // x: year, y: value
};
const createLineChartData = (t: Tillage): LineChartData => ({
  id: t,
  color: tillageColor[t],
  data: [],
});

const LineChart = ({
  data,
  getColor,
  onFilterClick,
}: {
  data: LineChartData[];
  getColor: (d: any) => string;
  onFilterClick?: (filter: {year?: number; value?: Tillage; multiselect?: boolean}) => void;
}) => {
  return (
    <ResponsiveLine
      data={data}
      enablePoints={false}
      enableArea={true}
      areaOpacity={1}
      yScale={{type: 'linear', stacked: true}}
      colors={getColor}
      enableGridX={false}
      gridYValues={4}
      enableCrosshair={false}
      margin={{top: 20, bottom: 20, left: 30, right: 12}}
      tooltip={data => {
        const label = tillageLabels[data.point.serieId as Tillage];
        const color = tillageColor[data.point.serieId as Tillage];
        return (
          <OptisChartTooltip>
            <span className="item-color" style={{backgroundColor: color}} />
            {label}: {data.point.data.y}%
          </OptisChartTooltip>
        );
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
      }}
      axisLeft={{
        tickValues: 3,
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: (v: number) => `${v}%`,
      }}
      animate={false}
      useMesh={true}
      onClick={(bar, e) => {
        onFilterClick?.({
          value: bar.serieId as Tillage,
          multiselect: e.metaKey || e.shiftKey || e.ctrlKey,
        });
      }}
    />
  );
};

const BarChart = ({
  keys,
  data,
  getColor,
  onFilterClick,
}: {
  keys: Tillage[];
  data: (OptisItemTillage & {
    year: number;
  })[];
  getColor: (d: any) => string;
  onFilterClick?: (filter: {year?: number; value?: Tillage; multiselect?: boolean}) => void;
}) => {
  return (
    <ResponsiveBar
      keys={keys}
      indexBy="year"
      data={data}
      colors={getColor}
      // labelSkipHeight={20}
      enableLabel={false}
      enableGridX={false}
      gridYValues={4}
      margin={{top: 20, bottom: 20, left: 40}}
      tooltip={bar => {
        const label = tillageLabels[bar.id as Tillage];
        const color = tillageColor[bar.id as Tillage];
        return (
          <div>
            <span className="item-color" style={{backgroundColor: color}} />
            {label}: {bar.value}%
          </div>
        );
      }}
      borderWidth={2}
      borderColor="white"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
      }}
      axisLeft={{
        tickValues: [0, 25, 50, 75, 100],
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: (v: number) => `${v}%`,
      }}
      onClick={(bar, e) => {
        onFilterClick?.({
          value: bar.id as Tillage,
          multiselect: e.metaKey || e.shiftKey || e.ctrlKey,
        });
      }}
    />
  );
};
