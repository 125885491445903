import {hackGetState} from 'store';
import {t} from 'i18n-utils';
import {ITreeData, TInfoExt, TreesTypes, Zone} from '../types';
import {ActivityApi} from '_api';
import {getGetURLParam, sensorView, toFixedFloat} from '_utils';
import {TSensor, isSensor} from '../../../types';
import {showNote} from '_actions';
import {setSensor, setTreeLayerType} from '../actions';
import moment from 'moment';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE} from '_constants';
import {AppStore} from 'reducers';

const treeAnalysisLayerTypes = ['detection', 'focus'] as Partial<TreesTypes>[];

export const loadFieldTreeData = (farmId: number, fieldId: number) => {
  return ActivityApi.getTreeDataByField(farmId, fieldId).then(({data}) => {
    return Promise.resolve(
      data.result.filter((obj: ITreeData) =>
        Object.keys(obj).find(
          (key: keyof ITreeData) =>
            (key.includes('_tree_detection') || key.includes('_tree_focus')) && obj[key]
        )
      )
    ); // search for an object with any tree data
  });
};

export const loadFarmTreeData = (farmId: number) => {
  return ActivityApi.getBulkTreeData(farmId).then(({data}) => data?.result || {});
};

export const classifyTreesZone = (zones: any): Zone[] => {
  return Object.values(zones || {}).map((z: any, i: number) => ({
    number: parseInt(z[0]), // number of trees
    mid: toFixedFloat(z[1] || 0, 3), // avg index
    color: `rgb(${z[2][0]},${z[2][1]},${z[2][2]})`,
    id: i + 1,
    isTree: true,
  }));
};

export const getTreeDataByDate = (
  date: string,
  customCurrentDates?: {[date: string]: any}
): {[md5: string]: ITreeData} | null => {
  const state = hackGetState();
  const {
    currentDates,
    wholeFarm: {isWholeFarmView, fieldsWithDates},
    field,
  } = state.map;

  if (isWholeFarmView) {
    let fieldsTreeData: {[md5: string]: ITreeData} | null = null;
    Object.keys(fieldsWithDates).forEach(md5 => {
      const treeData = fieldsWithDates?.[md5][date]?.treeData;
      if (treeData?.tree_count && treeData?.ndvi_tree_focus) {
        fieldsTreeData = fieldsTreeData ? {...fieldsTreeData, [md5]: treeData} : {[md5]: treeData};
      }
    });

    return fieldsTreeData;
  }
  const dateObject = (customCurrentDates || currentDates)?.[date];
  const treesDataExist =
    dateObject && dateObject?.treeData?.tree_count && dateObject?.treeData?.ndvi_tree_focus;
  return treesDataExist ? {[field.MD5]: dateObject.treeData} : null;
};

export const getTreeZoningDate = (fieldMD5?: string) => {
  const state = hackGetState();
  const {currentDate, field} = state.map;

  const fieldIdentifier = fieldMD5 || field.MD5;
  const treeData = getTreeDataByDate(currentDate)?.[fieldIdentifier];
  let imageDate = '';

  if (treeData) {
    imageDate = moment.utc(treeData.sensing_date).format('YYYYMMDDTHHmmss');
  }

  return imageDate;
};

export const handleTreeAnalysisSensors = (sensor: TSensor) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {
    treeDetection: {layerType},
    currentDate,
    currentDates,
    currentSensor,
  } = getState().map;
  const treeData = layerType !== 'default' && getTreeDataByDate(currentDate);

  if (!treeData || sensor === currentSensor) return;
  const notSelectedLayerType: TreesTypes = treeAnalysisLayerTypes.find(l => l !== layerType);
  //@ts-ignore
  const currentTreeLayerPropName: keyof ITreeData = `${sensor.toLowerCase()}_tree_${layerType}`;
  //@ts-ignore
  const notSelectedTreeLayerPropName: keyof ITreeData = `${sensor.toLowerCase()}_tree_${notSelectedLayerType}`;

  let currentLayerTypeExist, notSelectedLayerTypeExist;

  Object.keys(treeData).forEach(md5 => {
    // check if any field has appropriate tree layer type
    if (treeData[md5][currentTreeLayerPropName]) {
      currentLayerTypeExist = true;
    } else if (treeData[md5][notSelectedTreeLayerPropName]) {
      notSelectedLayerTypeExist = true;
    }
  });
  if (!currentLayerTypeExist && notSelectedLayerTypeExist) {
    return dispatch(changeLayerTypeAndShowMessage(notSelectedLayerType, layerType, sensor));
  } else if (
    !currentLayerTypeExist &&
    !notSelectedLayerTypeExist &&
    currentDates[currentDate][sensor]
  ) {
    return dispatch(changeLayerTypeAndShowMessage('default', layerType, sensor));
  }
};

const changeLayerTypeAndShowMessage = (
  layerTypeToSet: TreesTypes,
  currentLayerType: TreesTypes,
  sensor: TSensor
) => (dispatch: any) => {
  dispatch(setTreeLayerType(layerTypeToSet));
  dispatch(
    showNote({
      title: t({id: 'note.info', defaultMessage: 'Info'}),
      message: t(
        {
          id: 'layerNotAvailableForTree',
        },
        {sensor: sensorView(sensor), currentLayerType, layerTypeToSet}
      ),
      level: 'info',
    })
  );
};

export const getTreeIndexes = (
  treeData: {[fieldMd5: string]: ITreeData},
  layerType = 'focus',
  regularSensors: TSensor[]
): TSensor[] => {
  const treeSensors: TSensor[] = [];
  Object.keys(treeData).forEach(md5 => {
    Object.keys(treeData[md5]).forEach((treeDataProp: keyof ITreeData) => {
      if (treeDataProp.includes(`_tree_`) && treeData[md5]?.[treeDataProp]) {
        // get both focus and detection layers, we will change the layer type here: handleTreeAnalysisSensors()
        //@ts-ignore
        treeSensors.push(treeDataProp.split('_tree_')[0].toUpperCase());
      }
    });
  });

  return [...new Set([...regularSensors, ...treeSensors])];
};

export const calculateLayerType = (
  date: string,
  currentLayerType: TreesTypes,
  currentSensor: string,
  customCurrentDates?: {[date: string]: any}
): TreesTypes => {
  if (!currentSensor) {
    return 'default';
  }

  // try to take tree detection layer tape from URL param
  currentLayerType = (getGetURLParam('treeDetectionLayerType') as TreesTypes) || currentLayerType;
  // if (!date?.includes('plane') && !date?.includes('dron')) return 'default';
  const sensor = currentSensor.toLowerCase();
  const treeData = getTreeDataByDate(date, customCurrentDates);
  const notDefault = currentLayerType !== 'default';
  if (!treeData) return 'default';

  const currentTypePropName = `${sensor}_tree_${currentLayerType}` as keyof ITreeData;
  const focusPropName = `${sensor}_tree_focus` as keyof ITreeData;
  const detectionPropName = `${sensor}_tree_detection` as keyof ITreeData;

  let currentLayerDataExist = false,
    detectionDataExist = false,
    focusDataExist = false;

  Object.keys(treeData).forEach(md5 => {
    // check each field for existing data
    if (treeData[md5]?.[currentTypePropName]) {
      currentLayerDataExist = true;
    } else if (!currentLayerDataExist && treeData[md5]?.[detectionPropName]) {
      detectionDataExist = true;
    } else if (!currentLayerDataExist && !detectionDataExist && treeData[md5]?.[focusPropName]) {
      focusDataExist = true;
    }
  });

  if (notDefault && currentLayerDataExist)
    // check for exist data for current layerType
    return currentLayerType;
  else if (detectionDataExist)
    // check for tree detection data first
    return 'detection';
  else if (focusDataExist)
    // if no detection data, check for focus data
    return 'focus';

  return 'default'; // return default if no data found
};

export const getAvailableSensor = (
  treesType: TreesTypes,
  treeData: {[fieldMd5: string]: ITreeData},
  currentSensor?: TSensor
): TSensor => {
  let resultSensor = '';
  const layerTypeString = `_tree_${treesType}`;
  if (treeData) {
    Object.keys(treeData).forEach(md5 => {
      // iterate fields
      Object.keys(treeData[md5]).forEach((key: keyof ITreeData) => {
        // iterate field tree data object props
        if (!resultSensor && key.includes(layerTypeString) && !!treeData[md5][key]) {
          const foundSensor = key.replace(layerTypeString, '').toUpperCase();
          if (isSensor(foundSensor)) resultSensor = foundSensor;
        }
      });
    });
  }
  // @ts-ignore
  return resultSensor || currentSensor;
};

export const beforeTreeLayerTypeChanged = (layer: TreesTypes) => (
  dispatch: any,
  getState: () => AppStore
) => {
  // change the sensor and how note if current doesn't have a tree data
  const {currentSensor, currentDate} = getState().map;
  const treeData = getTreeDataByDate(currentDate);
  let someFieldHasParticularData = false;
  const currentSensorToSearch = `${currentSensor.toLowerCase()}_tree_${layer}` as keyof ITreeData;

  Object.keys(treeData || {}).forEach(md5 => {
    // iterate fields
    if (!someFieldHasParticularData && treeData[md5]?.[currentSensorToSearch]) {
      someFieldHasParticularData = true;
    }
  });

  const availableSensor = getAvailableSensor(layer, treeData, currentSensor);
  if (!someFieldHasParticularData && availableSensor && availableSensor !== currentSensor) {
    dispatch(setSensor(availableSensor));
    dispatch(
      showNote({
        title: t({id: 'note.info', defaultMessage: 'Info'}),
        message: t(
          {
            id: 'layerNotAvailableForTree',
          },
          {
            sensor: sensorView(currentSensor),
            currentLayerType: layer,
            layerTypeToSet: availableSensor,
          }
        ),
        level: 'info',
      })
    );
  }
};

export const countFieldsWithTreeDataByDay = (date: TInfoExt) => {
  const dateLabel = `${moment(date.Date, SERVER_FORMAT_DATE).format(GLOBAL_APP_DATE_FORMAT)}-${
    date.Type
  }`;
  const treeData = getTreeDataByDate(dateLabel);
  let fieldsCount = 0;
  if (!treeData) return fieldsCount;

  Object.keys(treeData).forEach(fieldMd5 => {
    if (Object.keys(treeData[fieldMd5]).length) {
      fieldsCount++;
    }
  });
  return fieldsCount;
};
