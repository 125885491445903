import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {Button} from 'react-md';
import '../index.scss';
import {connect, ConnectedProps} from 'react-redux';
import {toggleLowPerformingAreas, togglePremiumAnomalies} from '../actions/anomalies-actions';
import {FieldTag, IInitialMapState} from '../types';
import {toggleMapButton} from '../actions';
import {RequestStatus} from '../../../types';
import {WarningSvg} from '../../../components/icons';

type Props = ConnectedProps<typeof connector>;

const ToggleAnomalies = ({
  visibleLowPerfAnomalies,
  toggleLowPerformingAreas,
  field,
  togglePremiumAnomalies,
  toggleMapButton,
  premiumAnomaliesLoaded,
}: Props) => {
  const premium = field.tags?.includes(FieldTag.AnomalyDetection);
  const active = premium ? premiumAnomaliesLoaded : visibleLowPerfAnomalies;
  const handleClick = () => {
    premium ? togglePremiumAnomalies(!active) : toggleLowPerformingAreas(!visibleLowPerfAnomalies);
    toggleMapButton('Empty');
  };
  return (
    <Button
      id="toggle-anomaly-btn"
      title={t({id: 'Crop stress', defaultMessage: 'Crop stress'})}
      className={`map-toggle-button ${active ? 'active' : ''}`}
      name="toggle-anomaly"
      floating
      iconEl={<WarningSvg />}
      onClick={handleClick}
      mini
    />
  );
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  visibleLowPerfAnomalies: map.lowPerfAnomalies.isVisible,
  field: map.field,
  premiumAnomaliesLoaded: map.premiumAnomalies.status !== RequestStatus.Idle,
});

const connector = connect(mapStateToProps, {
  toggleLowPerformingAreas,
  togglePremiumAnomalies,
  toggleMapButton,
});
export default connector(ToggleAnomalies);
