import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {CloudyIcon} from '../../../icons';
import {ReasonCode} from '../types';

type NoValuesReasonProps = {
  reasonCode: ReasonCode;
};

const NoValueReason = (props: NoValuesReasonProps) => {
  switch (props.reasonCode) {
    case ReasonCode.NotSown:
      return <span className="cp__no-index-value">Not Sown</span>;

    case ReasonCode.Cloudy:
      return (
        <span className="cp__no-index-value" title="Cloudy">
          <CloudyIcon />
        </span>
      );

    case ReasonCode.NoImages:
      return <span className="cp__no-index-value">No Images</span>;

    case ReasonCode.Fallow:
      return <span className="cp__no-index-value">Fallow</span>;

    default:
      return <span className="cp__no-index-value">No Images</span>;
  }
};

export default NoValueReason;
