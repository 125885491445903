import React from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import styled from 'styled-components';
type Props = {
  adminTab?: boolean;
  value?: number;
};

const NotificationSignal = styled.div`
  background: #ff2b2c;
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: ${({adminTab}: Props) => (adminTab ? '-5px' : 0)};
  right: ${({adminTab}: Props) => (adminTab ? 'auto' : '10px')};
  left: ${({adminTab}: Props) => (adminTab ? '15px' : 'auto')};
  font-size: 10px;
  line-height: 20px;
  text-align: center;

  z-index: 99;
  pointer-events: none;

  &::before {
    color: #fff;
    content: ${({value}: Props) => (value ? `'${value}'` : '')};
  }
`;

export default NotificationSignal;
