import {ActionType, ActionPayload, User} from './types';
import {Dispatch} from 'redux';
import {UsersApi, SubscriptionsApi} from '_api';
import {push, RouterAction} from 'react-router-redux';
import {InitialUser} from '../../login/types';
import {reportError} from '../../error-boundary';

export const getUsersList = () => (dispatch: Dispatch<ActionPayload>) => {
  return UsersApi.getAll()
    .then(({data}) => {
      dispatch({
        type: ActionType.LOAD_USERS_LIST,
        list: [...data.result],
      });

      return data.result;
    })
    .catch(err => reportError(`getUsersList() err = ${err}`));
};

const getNewUserModel = (): User => ({
  id: 'new',
  email: '',
  name: '',
  perm: 1,
  active: 1,
  password: '',
  groupId: '',
  groupIds: [],
  surname: '',
  emailConfirmed: 1,
  defaultOrganizationID: null,
  settings: {...InitialUser.settings},
});

export const getOne = (userId: number | string) => (
  dispatch: Dispatch<ActionPayload>
): Promise<User> => {
  if (userId === 'new') {
    return Promise.resolve(getNewUserModel());
  }

  userId = typeof userId === 'number' ? userId : parseInt(userId);

  return new Promise<User>(res => {
    UsersApi.getOne(userId as number)
      .then(({data}: any) => {
        res(data.result as User);
      })
      .catch(err => {
        reportError(`Users, getOne() err = ${err}`);
        res(getNewUserModel());
      });
  });
};

export const saveUser = (user: User) => (dispatch: Dispatch<ActionPayload>) => {
  return UsersApi[user.id === 'new' ? 'newUser' : 'saveUser'](user)
    .then(({data}) => {
      if (data && data.result && user.id === 'new') {
        dispatch(push(`/admin/users/${data.result.id}`));
      }
      return data.result;
    })
    .catch(err => {
      console.log('saveUser err', err);
    });
};

export const rmUser = (id: number) => (dispatch: Dispatch<RouterAction>) => {
  return UsersApi.rm(id)
    .then(() => {
      dispatch(push(`/admin/users`));
    })
    .catch(err => {
      console.log('rmUser err', err);
    });
};

export const loadOrganizations = () => (dispatch: Dispatch<ActionPayload>) => {
  return SubscriptionsApi.loadAllOrganizations()
    .then(({data}) => {
      dispatch({type: ActionType.SET_ORGS, organizations: data.result || []});
      return data.result || [];
    })
    .catch(err => reportError(`loadOrganizations() err = ${err}`));
};

export const loadSubscriptions = () => (dispatch: Dispatch<ActionPayload>) => {
  return SubscriptionsApi.loadSubscriptions()
    .then(({data}) => {
      dispatch({type: ActionType.SET_SUBS, subscriptions: data.result || []});
      return data.result || [];
    })
    .catch(err => reportError(`loadSubscriptions() err = ${err}`));
};
