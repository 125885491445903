import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './timeline-chartjs.plugin';
import Chart from 'chart.js';
import {isDateInRange, sortDates, createDateRange} from '_utils/pure-utils';
import moment from 'moment';

export type SeasonItem = {
  seasonId: number;
  startDate: string;
  endDate: string;
  cropType: string; // use crop label, not the crop id
  cropColor: string;
};

export type SeasonDataItem = {
  farmName: string;
  farmId: number;
  seasons: SeasonItem[];
};

type Props = {
  seasonsData: SeasonDataItem[];
  onSize?: (w: number, h: number) => void;
  maxHeight?: string | number;
  onChange?: (date: string) => void;
  dateFormat?: string;
  value?: string; // selected date
};

function drawBorder(
  ctx: any,
  xPos: number,
  yPos: number,
  width: number,
  height: number,
  thickness = 1
) {
  ctx.fillStyle = '#fff';
  ctx.fillRect(xPos - thickness, yPos - thickness, width + thickness * 2, height + thickness * 2);
}

const drawSlider = (chart: any, x: any, txt?: string) => {
  const ctx = chart.chart.ctx;
  const chartArea = chart.chartArea;

  if (!isNaN(x)) {
    ctx.save();

    drawBorder(ctx, x, chartArea.top, 2, chartArea.bottom);

    ctx.fillStyle = 'green';
    ctx.fillRect(x, chartArea.top, 2, chartArea.bottom);

    ctx.beginPath();
    ctx.moveTo(x - 9, chartArea.top - 5);
    ctx.lineTo(x + 10, chartArea.top - 5);
    ctx.lineTo(x + 1, chartArea.top + 5);
    ctx.fillStyle = 'green';
    ctx.fill();

    const text = txt ? txt : '';

    const textW = ctx.measureText(text).width;

    ctx.textAlign =
      x - chartArea.left < textW / 2
        ? 'left'
        : x + textW / 2 > chartArea.right
        ? 'right'
        : 'center';
    ctx.font = `normal 300 12px arial`;
    ctx.fillStyle = 'green';
    ctx.fillText(text, x, 18);

    ctx.restore();
  }
};

export const TimelineSeasonsChart = ({
  dateFormat = 'YYYY-MM-DD',
  seasonsData,
  onSize,
  maxHeight,
  onChange,
  value,
}: Props) => {
  const [chart, setChart] = useState<Chart | undefined>();
  const ref = useRef<HTMLCanvasElement>();
  const [size, setSize] = useState({w: 0, h: 0});
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    chart && chart.destroy();
  }, [seasonsData]);

  const preparedData = useMemo(() => {
    const labels: any = [];
    const dataset: any = [];

    const dates = sortDates(
      seasonsData.map(farm => farm.seasons.map(s => [s.startDate, s.endDate])).flat(2),
      'YYYY-MM-DD'
    );

    seasonsData.forEach(farm => {
      labels.push(farm.farmName + ' ' + farm.farmId);

      if (!farm.seasons.length) {
        return;
      }

      /*
       *
       * Add top padding under farm name
       *
       * */
      labels.push(`${farm.farmName} white space farm`);
      dataset.push({
        data: [[dates[0], dates[dates.length - 1], 'farm', ['', '']]],
      });

      /*
       *
       * Farm name bar
       *
       * */
      dataset.push({
        data: [[dates[0], dates[dates.length - 1], 'farm', [farm.farmName, farm.farmId]]],
      });

      /*
       *
       * Add bottom padding under farm name
       *
       * */
      labels.push(`${farm.farmName} white space farm`);
      dataset.push({
        data: [[dates[0], dates[dates.length - 1], 'farm', ['', '']]],
      });

      const notIntersectedSeasons: any = [];

      /*
       *
       * Merge seasons with the same dates to single
       *
       * */
      const seasonsObj: {[key: string]: SeasonItem} = {};
      const seasonsDuplicationCounter: {[key: string]: number} = {};
      farm.seasons.forEach(s => {
        const key = `${s.startDate}${s.endDate}${s.cropType}`;

        seasonsObj[key] = s;

        if (seasonsDuplicationCounter[key]) {
          seasonsDuplicationCounter[key] = seasonsDuplicationCounter[key] + 1;
        } else {
          seasonsDuplicationCounter[key] = 1;
        }
      });

      /*
       *
       * Create seasons dataset
       *
       * */
      Object.values(seasonsObj).forEach((s, i) => {
        const allOtherSeasons = farm.seasons.filter(s1 => s1.seasonId !== s.seasonId);
        const key = `${s.startDate}${s.endDate}${s.cropType}`;

        if (
          allOtherSeasons.some(
            s2 =>
              isDateInRange(s.startDate, s2.startDate, s2.endDate) ||
              isDateInRange(s.endDate, s2.startDate, s2.endDate)
          )
        ) {
          // put season to the new row
          labels.push(`${farm.farmName} Season ${i}`);
          dataset.push({
            data: [
              [
                s.startDate,
                s.endDate,
                s.cropType,
                s.cropColor,
                seasonsDuplicationCounter[key] <= 1 ? 0 : seasonsDuplicationCounter[key],
              ],
            ],
          });
        } else {
          notIntersectedSeasons.push([
            s.startDate,
            s.endDate,
            s.cropType,
            s.cropColor,
            seasonsDuplicationCounter[key] <= 1 ? 0 : seasonsDuplicationCounter[key],
          ]);
        }
      });

      if (dataset[dataset.length - 1].data?.[0]?.[2] !== 'farm') {
        dataset[dataset.length - 1].data = [
          ...dataset[dataset.length - 1].data,
          ...notIntersectedSeasons,
        ];
      } else {
        labels.push(`${farm.farmName} multiple seasons`);
        dataset.push({data: notIntersectedSeasons});
      }
    });

    const d = createDateRange(dates[0], dates[dates.length - 1]);

    const h = dataset.length * 13;
    const s = {
      w: !d.length ? 0 : d.length < 300 ? 300 : d.length,
      h: !dataset.length ? 0 : h < 100 ? 100 : h,
    };

    onSize?.(s.w, s.h);
    setSize(s);

    return {labels, dataset, dates: d};
  }, [seasonsData]);

  const onContainerScroll = useCallback(
    e => {
      chart?.update();
    },
    [chart]
  );

  // Update slider when date was changed external
  useEffect(() => {
    if (value && chart) {
      const formattedDate = moment(value).format('YYYY-MM-DD');

      const index = preparedData.dates.findIndex(d => d === formattedDate);

      if (index !== -1) {
        const pixelPerDate =
          (chart.chartArea.right - chart.chartArea.left) / preparedData.dates.length;

        //@ts-ignore
        chart.options.curSettedDate = formattedDate;
        //@ts-ignore
        chart.options.curX = index * pixelPerDate;
      } else {
        //@ts-ignore
        chart.options.curX = 0;
        //@ts-ignore
        chart.options.curSettedDate = preparedData.dates?.[0];
      }

      try {
        chart.update();
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [value, preparedData, chart]);

  const data: any = useMemo(() => {
    return {
      type: 'timeline',
      plugins: [
        {
          beforeEvent: (chart: any, e: any) => {
            chart.options.eventType = e.type;

            if (e.type === 'mousemove') {
              chart.options.x = e.x;

              const width = chart.chartArea.right - chart.chartArea.left;

              const pixelPerDate = width / preparedData.dates.length;

              chart.options.curDate = preparedData.dates[Math.floor(e.x / pixelPerDate)];

              if (!chart.getElementAtEvent(e).length) {
                chart.update();
              }
            }
          },

          afterDraw: (chart: any) => {
            const ctx = chart.chart.ctx;

            /*
             *
             * Draw farm name bar
             *
             * */
            chart.data.datasets.forEach((item: any, index: number) => {
              if (item.data[0][2] == 'farm') {
                const model = chart.getDatasetMeta(index).data[0]._model;

                ctx.save();

                const farmId = item.data[0][3][1];

                const leftScroll = containerRef?.current?.scrollLeft || 0;

                const txt = `${item.data[0][3][0]} ${farmId ? `#${farmId}` : ''}`;
                ctx.font = `normal 300 12px arial`;
                ctx.fillStyle = '#43a047';
                ctx.fillText(txt, model.x + leftScroll, model.y + 5);
                ctx.restore();
              }
            });

            /*
             *
             * Draw date slider line
             *
             * */
            if (chart.options.eventType === 'mouseout' || chart.options.eventType === undefined) {
              drawSlider(
                chart,
                chart.options.curX,
                moment(chart.options.curSettedDate).format(dateFormat)
              );
            } else {
              drawSlider(chart, chart.options.x, moment(chart.options.curDate).format(dateFormat));
            }
          },
        },
      ],
      options: {
        onClick: function (e: any) {
          /*
           *
           * calc X cursor position
           *
           * */
          this.options.curX = e.clientX - ref.current.getBoundingClientRect().left;
          this.options.curSettedDate = this.options.curDate;
          onChange?.(this.options.curSettedDate);
        },
        events: ['click', 'mousemove', 'mouseout'],
        // responsive: false,
        layout: {
          padding: {
            top: 30,
            right: 15,
            bottom: 15,
          },
        },
        tooltips: {
          filter: function (tooltipItem: any) {
            return tooltipItem.datasetIndex !== 0;
          },

          title: () => {
            return '';
          },
        },
        legend: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
                offsetGridLines: true,
                drawBorder: false,
                drawTicks: true,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              barThickness: 5,
              ticks: {
                padding: 5,
              },
            },
          ],
        },
        elements: {
          colorFunction: function (text: string, data: any[], dataset: any, index: number) {
            if (text === 'farm') {
              return '#fff';
            }

            return dataset.data[index][3];
          },
          showText: true,
          textPadding: 5,
          dateFormat,
        },
      },

      data: {
        labels: preparedData.labels,
        datasets: preparedData.dataset,
      },
    };
  }, [preparedData, ref]);

  useEffect(() => {
    if (ref.current) {
      setChart(c => {
        return new Chart(ref.current.getContext('2d'), data);
      });
    }
  }, [ref, data]);

  return (
    <div
      onScroll={onContainerScroll}
      ref={containerRef}
      style={{overflow: 'auto', maxHeight: maxHeight || 300}}
      className={'crop-timeline'}
    >
      <div style={{height: size.h, width: size.w}}>
        <canvas ref={ref} />
      </div>
    </div>
  );
};
