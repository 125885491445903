import {FormattedMessage, t} from 'i18n-utils';
import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';
import {Doughnut} from 'react-chartjs-2';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import moment from 'moment';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE} from '_constants';
import {getFieldSeasons} from '_hooks/use-current-season';
import './crop-type-chart.scss';
import {Season} from 'containers/map/types';
import {cropSubtype2Label, getArea, selectMeasurement, unreachableError} from '_utils';
import {CPFilterType} from '_reducers/crop-performance-filter/field-filter-types';
import {CropTypeCard} from './crop-type-card';
import {CropAvatar} from 'components';
import {FontIcon} from 'react-md';
import {ActionTypes} from '../../../reducer/types';
import {NO_CROP, NO_VARIETY} from '_constants';
import {Aggregation, WithFieldArea} from '../types';
import {aggregate} from '../helpers';

type Props = {
  active: boolean;
  onWidgetClick: () => void;
  onFilterClear?: () => void;
  onFilterToggle?: (
    value: string,
    color: string,
    filterType: CPFilterType.CROP_VARIETY | CPFilterType.CROP_TYPE,
    label?: string
  ) => void;
};

type CropTypeWithVarieties = {
  [cropType: string]: {count: number; varieties: {[varietyName: string]: {count: number}}};
};

export const CropTypeChart = ({active, onWidgetClick, onFilterClear, onFilterToggle}: Props) => {
  const dispatch = useDispatch();
  const chartRef = useRef();
  const fieldsByFarmId = useSelector((s: AppStore) => s.map.fieldsByFarmId);
  const currentDate = useSelector((s: AppStore) => s.map.currentDate);
  const cropVarietyMode = useSelector((store: AppStore) => store.map.cropVarietyMode);
  const cropPerformanceFilter = useSelector((s: AppStore) => s.cropPerformanceFilter);
  const cropTypes = useSelector((s: AppStore) => s.global.cropTypes);
  const cropVarietyColors = useSelector((s: AppStore) => s.map.cropVarietyColors);
  const cropPerformance = useSelector((s: AppStore) => s.cropPerformance);
  const isReportView = useSelector((store: AppStore) => store.global.isReportView);
  const [cropTypesListExpanded, toggleCropTypesListExpanded] = useState(false);
  const [varietiesListExpanded, toggleVarietiesListExpanded] = useState(false);
  const cropsToDisplay = isReportView ? 100 : 5; // don't hide crops in reports

  const selectedCropTypes = cropPerformanceFilter.filters[CPFilterType.CROP_TYPE].map(
    f => (f.value as string) || NO_CROP
  );
  const selectedVarieties = cropPerformanceFilter.filters[CPFilterType.CROP_VARIETY].map(
    f => (f.value as string) || NO_VARIETY
  );

  const fields = useMemo(
    () =>
      Object.keys(fieldsByFarmId)
        .filter(farmId => cropPerformance.farms[Number(farmId)])
        .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])),
    [fieldsByFarmId, cropPerformance.farms]
  );

  const {allCrops, cropAreas} = useMemo(() => {
    const date = moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE);
    const cropAreas: {[id: number]: WithFieldArea} = {};
    const allCrops = fields.flatMap(field => {
      const seasons = getFieldSeasons(field, date);
      const finalSeasons = seasons.length
        ? seasons
        : [{id: field.ID, startDate: '', endDate: '', cropType: ''}]; // Pass a dummy season in case of no seasons to show "no crop" fields.
      finalSeasons.forEach(s => {
        cropAreas[s.id] = {fieldArea: s.geometry ? getArea(s.geometry) : field.Area};
      });
      return finalSeasons;
    });
    return {allCrops, cropAreas};
  }, [fields, currentDate]);

  const stage0FilteredCrops = useMemo(() => {
    return allCrops.filter(
      season =>
        cropPerformanceFilter.filterStatus[season.id] !== CPFilterType.FIELDS_WITH_PLANTING_AREAS // not filtered by planting area
    );
  }, [allCrops, cropPerformanceFilter.filters]);

  const stage1FilteredCrops = useMemo(() => {
    return stage0FilteredCrops.filter(
      season =>
        cropPerformanceFilter.filterStatus[season.id] !== CPFilterType.CROP_TYPE &&
        cropPerformanceFilter.filterStatus[season.id] !== CPFilterType.CROP_VARIETY
    );
  }, [stage0FilteredCrops, cropPerformanceFilter.filters]);

  const selectedCropsCountLabel = useMemo(() => {
    return t(
      {id: '{count1} / {count2} crops selected'},
      {count1: stage1FilteredCrops.length, count2: stage0FilteredCrops.length}
    );
  }, [fields, cropPerformanceFilter.filterStatus]);

  const cropTypesWithVarieties = classifyCropTypes(
    stage0FilteredCrops,
    cropPerformance.aggregation,
    cropAreas
  );
  const filteredCropTypesWithVarieties = classifyCropTypes(
    stage1FilteredCrops,
    cropPerformance.aggregation,
    cropAreas
  );

  const sortCropTypesOrVarieties = (entities: {[cropOrVariety: string]: {count: number}} = {}) => {
    return Object.keys(entities).sort(
      (a, b) => entities[b].count - entities[a].count || a.localeCompare(b) // biggest numbers first // alphabetically
    );
  };

  useEffect(() => {
    let newCropVarietyMode = false;
    Object.keys(filteredCropTypesWithVarieties).forEach((cropType, index, arr) => {
      if (newCropVarietyMode) return;
      //  use cropTypesWithVarieties instead of filteredCropTypesWithVarieties to get list of all varieties even if we have a selected one
      const varietiesKeys = Object.keys(cropTypesWithVarieties[cropType].varieties);
      if (
        (arr.length === 1 && !filteredCropTypesWithVarieties[NO_CROP]) ||
        (arr.length === 2 && filteredCropTypesWithVarieties[NO_CROP]?.count > 0)
      ) {
        if (
          varietiesKeys.length > 1 ||
          (varietiesKeys.length === 1 &&
            !filteredCropTypesWithVarieties[cropType]?.varieties?.[NO_VARIETY])
        )
          newCropVarietyMode = true;
      }
    });
    if (cropVarietyMode !== newCropVarietyMode) {
      dispatch({
        type: ActionTypes.MAP_SET_CROP_VARIETY_MODE,
        cropVarietyMode: newCropVarietyMode,
      });
    }
  }, [stage0FilteredCrops]); // listen the stage0FilteredCrops because it affects all variables that are used in the scope above

  const theSelectedCropType =
    Object.keys(filteredCropTypesWithVarieties).find(cropType => cropType !== NO_CROP) || NO_CROP;

  const [chartLabels, entities] = useMemo(() => {
    let entities: {[cropOrVariety: string]: {count: number}} = cropTypesWithVarieties;

    if (cropVarietyMode) {
      entities = cropTypesWithVarieties[theSelectedCropType]?.varieties;
    }

    const labels = sortCropTypesOrVarieties(entities);
    return [labels, entities];
  }, [cropTypesWithVarieties, cropVarietyMode]);

  const getBackgroundColor = (entity: string, cropVariety = false, skipTransparency = false) => {
    const selectedLabels = cropVariety ? selectedVarieties : selectedCropTypes;

    const color = cropVariety
      ? entity === NO_VARIETY // In variety mode instead of no variety show the single crop type for which  we're showing the varieties.
        ? cropTypes[theSelectedCropType]?.color || '#EFEFEF'
        : cropVarietyColors[entity]
      : cropTypes[entity]?.color;

    return !selectedLabels.length || selectedLabels.includes(entity) || skipTransparency
      ? color || '#EFEFEF' // use the color if nothing is selected, or the given item is selected
      : color // for others delete the color with transparency
      ? color + '33' // by adding alpha layer #rrggbbAA
      : '#EFEFEF33';
  };

  const data = {
    labels: chartLabels.map(c => {
      // In variety mode instead of no variety show the single crop type for which
      // we're showing the varieties.
      if (cropVarietyMode) {
        return c === NO_VARIETY
          ? cropTypes[theSelectedCropType]?.label || theSelectedCropType
          : cropSubtype2Label(theSelectedCropType, c);
      }
      return t({id: cropTypes[c]?.value || c});
    }),
    keys: chartLabels,
    datasets: [
      {
        backgroundColor: chartLabels.map(c => getBackgroundColor(c, cropVarietyMode)),
        borderColor: chartLabels.map(c => {
          const color = cropVarietyMode ? cropVarietyColors[c] : cropTypes[c]?.color;
          return color === '#ffffff' ? '#dddddd' : undefined;
        }),
        data: chartLabels.map(c => entities[c].count),
      },
    ],
  };

  if (!fields.length) {
    return null;
  }

  return (
    <div onClick={onWidgetClick} className={cn({'crop-type-chart': true, active: active})}>
      <div className="crop-type-chart-title" onClick={onWidgetClick}>
        <h3>
          {cropVarietyMode
            ? `${t({id: cropTypes[theSelectedCropType]?.value || theSelectedCropType})} ${t({
                id: 'varieties',
              })}`
            : t({id: 'Crop type'})}
        </h3>
        <button
          className={'clear-filter-btn'}
          disabled={[...selectedVarieties, ...selectedCropTypes].length === 0}
          onClick={e => {
            e.stopPropagation();
            onFilterClear();
          }}
        >
          <img src="/assets/filter.svg" alt="filter" title={t({id: 'Clear filters'})} width="20" />
        </button>
      </div>
      <div className="crop-type-chart-plot">
        {cropVarietyMode ? (
          <CropAvatar cropType={theSelectedCropType} className="crop-type-chart-icon" />
        ) : (
          <img src="/assets/crops/crop.svg" alt="crop icon" className="crop-type-chart-icon" />
        )}
        <Doughnut
          ref={chartRef}
          data={data}
          getElementAtEvent={elements => {
            if (elements.length) {
              const value = elements[0]._chart.data.keys[elements[0]._index];
              const color = getBackgroundColor(value, cropVarietyMode, true);
              onFilterToggle?.(
                value,
                color,
                cropVarietyMode ? CPFilterType.CROP_VARIETY : CPFilterType.CROP_TYPE,
                cropVarietyMode ? cropSubtype2Label(theSelectedCropType, value) : undefined
              );
            }
          }}
          legend={{display: false}}
          options={{responsive: false}}
        />
      </div>
      <div className="crop-type-chart-selected-count">{selectedCropsCountLabel}</div>

      <ul className="crop-type-chart-legend">
        {sortCropTypesOrVarieties(cropTypesWithVarieties).map((cropType, index, arr) => {
          const backgroundColor = getBackgroundColor(cropType);
          const clearBackgroundColor = getBackgroundColor(cropType, false, true);
          const varieties = sortCropTypesOrVarieties(cropTypesWithVarieties[cropType].varieties);
          const deselected = selectedCropTypes.length && !selectedCropTypes.includes(cropType);
          const hasVarieties =
            varieties.length > 1 || (varieties.length === 1 && varieties[0] !== NO_VARIETY);

          if (!cropTypesListExpanded && index >= cropsToDisplay) return null;
          if (isReportView && deselected) return null; // hide deselected crop types in reports

          return (
            <React.Fragment key={cropType}>
              <LegendItem
                label={cropType}
                deselected={deselected}
                onClick={() => {
                  if (arr.length !== 1) {
                    onFilterToggle?.(cropType, clearBackgroundColor, CPFilterType.CROP_TYPE);
                  }
                }}
                backgroundColor={backgroundColor}
                count={cropTypesWithVarieties[cropType].count}
                aggregation={cropPerformance.aggregation}
                varietiesLength={hasVarieties ? varieties.length : 0}
              />
              {cropVarietyMode && varieties.length && cropType === theSelectedCropType && (
                <>
                  <ul className={'varieties-list'}>
                    {varieties.map((variety, index) => {
                      const varietyLabel = cropSubtype2Label(theSelectedCropType, variety);
                      const backgroundColor = getBackgroundColor(variety, true);
                      const clearBackgroundColor = getBackgroundColor(variety, true, true);
                      const deselected =
                        selectedVarieties.length && !selectedVarieties.includes(variety);

                      if (!varietiesListExpanded && index >= cropsToDisplay) return null;

                      return (
                        <LegendItem
                          key={variety}
                          label={varietyLabel}
                          deselected={deselected}
                          onClick={ev => {
                            ev.stopPropagation();
                            onFilterToggle?.(
                              variety,
                              clearBackgroundColor,
                              CPFilterType.CROP_VARIETY,
                              varietyLabel
                            );
                          }}
                          backgroundColor={backgroundColor}
                          count={cropTypesWithVarieties[cropType].varieties[variety].count}
                          aggregation={cropPerformance.aggregation}
                        />
                      );
                    })}
                  </ul>
                  <CropListProps
                    toggled={varietiesListExpanded}
                    onToggle={toggleVarietiesListExpanded}
                    cropTypes={varieties}
                    variety
                    cropsToDisplay={cropsToDisplay}
                  />
                </>
              )}
            </React.Fragment>
          );
        })}
      </ul>

      <CropListProps
        toggled={cropTypesListExpanded}
        onToggle={toggleCropTypesListExpanded}
        cropTypes={Object.keys(cropTypesWithVarieties)}
        cropsToDisplay={cropsToDisplay}
      />
    </div>
  );
};

const classifyCropTypes = (
  seasons: Season[],
  aggregation: Aggregation,
  cropAreas: {[id: number]: WithFieldArea}
) => {
  const cropTypes: CropTypeWithVarieties = {};

  seasons.forEach(season => {
    const cropType = season?.cropType || NO_CROP;
    const cropVariety =
      cropType !== NO_CROP ? season?.params?.cropSubType || NO_VARIETY : NO_VARIETY;

    if (!cropTypes[cropType]) {
      cropTypes[cropType] = {count: 0, varieties: {}};
    }

    if (!cropTypes[cropType].varieties[cropVariety]) {
      cropTypes[cropType].varieties[cropVariety] = {count: 0};
    }
    const aggr = aggregate(aggregation, cropAreas[season.id]);
    cropTypes[cropType].count += aggr;
    cropTypes[cropType].varieties[cropVariety].count += aggr;
  });

  return cropTypes;
};

type LegendItemProps = {
  count: number;
  aggregation: Aggregation;
  backgroundColor: string;
  label: string;
  deselected: boolean;
  onClick: (ev: React.MouseEvent<HTMLElement>) => void;
  varietiesLength?: number;
};

const LegendItem = ({
  count,
  aggregation,
  backgroundColor,
  label,
  onClick,
  deselected,
  varietiesLength,
}: LegendItemProps) => {
  return (
    <li
      key={label}
      onClick={onClick}
      className={cn({
        deselected,
      })}
    >
      <div>
        <span
          className="item-color"
          style={{
            background: backgroundColor,
            border: backgroundColor === '#ffffff' ? '1px solid black' : undefined,
          }}
        />
        <span className="item-name">
          <FormattedMessage id={label} defaultMessage={label} />
          {varietiesLength
            ? ` (${varietiesLength} ${t({
                id: varietiesLength > 1 ? 'varieties' : 'variety',
              })})`
            : null}
        </span>
      </div>
      <div>
        <span className="item-area" />
        <span className="item-count">
          {aggregation === Aggregation.COUNT ? (
            <FormattedMessage
              id="{count} crops"
              defaultMessage="{count} crops"
              values={{
                count,
              }}
            />
          ) : (
            selectMeasurement(count)
          )}
        </span>
      </div>
    </li>
  );
};

type CropListToggleProps = {
  toggled: boolean;
  onToggle(value: boolean): void;
  cropTypes: string[];
  variety?: boolean;
  cropsToDisplay: number;
};
const CropListProps = ({
  toggled,
  onToggle,
  cropTypes,
  variety,
  cropsToDisplay,
}: CropListToggleProps) => {
  if (cropTypes.length <= cropsToDisplay) return null;

  const moreCropsText =
    cropTypes.length - cropsToDisplay > 10 ? '10+' : cropTypes.length - cropsToDisplay;
  return (
    <div className={cn('crop-type-chart-view-more', {variety})} onClick={() => onToggle(!toggled)}>
      {toggled ? t({id: 'View less'}) + ' ' : t({id: '{text} more'}, {text: moreCropsText})}
      <FontIcon>{toggled ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</FontIcon>
    </div>
  );
};
