import {t, FormattedMessage} from 'i18n-utils';
import styled from 'styled-components';
import React from 'react';
import {Checkbox} from 'react-md';
import {main_info_color} from '_utils/colors';

type CardProps = {
  active: boolean;
};

export const Card = styled.div`
  background-color: #fff;
  margin: 20px 0;
  border: 1px solid ${({active}: CardProps) => (active ? 'black' : '#d6d8da')};
  border-radius: 4px;
  padding: 10px 16px 20px 16px;
  transition: border 0.5s;
  cursor: pointer;

  &:hover {
    border: 1px solid black;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
`;

export const CardTitle = styled.h3`
  margin: 0 0 8px 16px;
  white-space: normal;
`;

export const CardChartGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

type CardChartProps = {
  active: boolean;
};

export const CardChart = styled.div`
  .date {
    opacity: ${({active}: CardChartProps) => (active ? 1 : 0.3)};
  }

  > div {
    text-align: center;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
`;

export const CardChartLegendWrapper = styled.div`
  margin-top: 10px;
`;

type LegendCheckboxProps = {
  color: string;
};

export const LegendCheckbox = styled(Checkbox)`
  .md-fake-btn,
  div[role='checkbox'][aria-checked='true'] {
    color: ${({color}: LegendCheckboxProps) => color};
  }

  .md-btn--icon {
    height: 30px;
    width: 30px;
    padding: 5px;
  }

  .md-selection-control-container {
    height: 30px;
  }

  span {
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    color: ${main_info_color};
  }
`;

export const SelectedCountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  color: #349e21;
`;
