import styled from 'styled-components';
import {main_gray_600} from '_utils/colors';
import {FluroTableColumn} from 'components';

export const ZoningTabsWrapper = styled.div`
  height: 55px;
  width: 100%;

  > div {
    position: absolute;
    left: 0;
    right: 0;
  }

  .fluro-tabs-container {
    .tab {
      padding: 0 22px 7px;
      display: flex;
      align-items: center;
    }
  }

  .help-icon-tab {
    margin-left: 5px;
    display: flex;
    align-items: center;

    .md-icon {
      color: #bbbbbb;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 25px;

  .buttons-block {
    margin-top: 20px;
  }

  .zones-table {
    .md-data-table {
      border-collapse: separate;
      border-spacing: 0 10px;
    }

    .name {
      color: #fff;
      text-shadow: 0.8px 0.8px 0.8px #000;
      font-weight: 500;
      border-radius: 4px;
      width: 80px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .md-table-column--header {
      vertical-align: top;
      height: 40px;

      > span {
        font-weight: 300;
      }
    }

    .md-table-body {
      .md-table-column {
        border-top: 1px solid #d6d8da;
        border-bottom: 1px solid #d6d8da;
        padding-top: 7px;
        padding-bottom: 7px;

        &:first-child {
          border-radius: 4px 0 0 4px;
          border-left: 1px solid #d6d8da;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
          border-right: 1px solid #d6d8da;
        }
      }

      .font300 {
        font-weight: 300;
      }
    }
  }

  .zones-container {
    margin-top: 25px;
  }
`;

export const CropItemWrapper = styled.div`
  margin-top: 10px;
`;

export const SelectedTitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
`;

type CropItemCardProps = {
  selected?: boolean;
};

export const CropItemCard = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${({selected}: CropItemCardProps) => (selected ? main_gray_600 : '#EEE')};

  transition: border 0.2s;

  display: flex;
  align-items: center;

  :hover {
    border-bottom: 1px solid ${main_gray_600};
  }
`;

export const ZoneTableColumn = styled(FluroTableColumn)`
  &:first-child {
    padding-left: 7px;
  }

  &.index-avg {
    text-transform: uppercase;
  }
`;

export const Line = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
`;
