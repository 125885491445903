import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FontIcon, ListItem, MenuButton, SelectField} from 'react-md';
import cn from 'classnames';

import {SamplingPoint, SamplingPointGroup} from '../../types';

import LabelIcon from 'components/label-icon';
import {CustomCheckbox, FluroButton} from 'components';
import ReadOnly from 'components/read-only-container/index';

import {showNote} from '_actions';
import {pointsSetGroupDate, removeSamplingPoint} from '../../actions/sampling-points';
import {getPointsGroupKeys, getPointsIds} from '../../utils/sampling-points';
import SamplingPointsLabelsDropdown from './sampling-points-labels-menu';

type Props = ConnectedProps<typeof connector> & {
  isRoot?: boolean;
  isAnySelected?: boolean;
  isReadOnly: boolean;
  pointsGroups: SamplingPointGroup;
  currentGroupDate: string;
  toggleNmapPopUp(groupDate: string): void;
  bulkChangePointsProp?(prop: string, value: any): void;
  openEditGroupPopUp?(): void;
};

const PointsGroupHeader = ({
  isRoot,
  currentGroupDate,
  pointsSetGroupDate,
  isReadOnly,
  toggleNmapPopUp,
  pointsGroups,
  showNote,
  bulkChangePointsProp,
  openEditGroupPopUp,
  removeSamplingPoint,
  isAnySelected,
}: Props) => {
  const allowNmap = () => {
    return !!(
      pointsGroups[currentGroupDate]?.length &&
      pointsGroups[currentGroupDate].filter((point: SamplingPoint) => point.properties.n_result)
        .length >= 3
    );
  };

  const isAllPointsChecked = useMemo(
    () =>
      pointsGroups[currentGroupDate]?.length &&
      pointsGroups[currentGroupDate]?.every((point: SamplingPoint) => point.properties.checked),
    [pointsGroups, currentGroupDate]
  );

  const nowAllowedNmap = () =>
    showNote({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message: t({
        id: 'You need at least 3 sampling points with N(%) results to generate a Nitrogen map.',
      }),
      level: 'warning',
    });

  const getPointsGroupsMenuItems = useMemo(
    () => [
      {label: t({id: 'All Dates'}), value: 'all'},
      ...getPointsGroupKeys().map(value => ({
        key: value,
        label: value,
        value,
      })),
    ],
    [pointsGroups]
  );

  const bulkDeletePoints = () => {
    getPointsIds(currentGroupDate).forEach(removeSamplingPoint);
  };

  const getBulkMenuBtn = useMemo(
    () => (
      <MenuButton
        id={`points-bulk-action-btn-menu`}
        className={'bulk-actions-btn-menu'}
        raised
        primary={!isReadOnly}
        disabled={!isAnySelected}
        menuItems={[
          <ListItem
            key={'bulk-edit'}
            onClick={openEditGroupPopUp}
            className={'bulk-edit-item'}
            primaryText={t({id: 'Edit sampling date'})}
          />,
          <SamplingPointsLabelsDropdown
            key={'bulk-label'}
            id={'bulk-label-btn'}
            className={'element-full-width'}
            label={t({id: 'Label'})}
            onChange={val => bulkChangePointsProp('samplingPointType', val)}
            menuButtonEl={
              <ListItem
                onClick={ev => ev.stopPropagation()}
                className={'bulk-edit-item'}
                primaryText={'Label'}
              />
            }
          />,
          <ListItem
            key={'bulk-delete'}
            onClick={bulkDeletePoints}
            className={'bulk-edit-item'}
            primaryText={t({id: 'Delete'})}
          />,
        ]}
      >
        <FontIcon>more_vert</FontIcon>
      </MenuButton>
    ),
    [isAnySelected, isReadOnly]
  );

  return (
    <div className={cn('single-group-controls', {isRoot, isAll: currentGroupDate === 'all'})}>
      {isRoot ? (
        <CustomCheckbox
          onCheck={value => bulkChangePointsProp('checked', value)}
          isChecked={isAllPointsChecked}
        />
      ) : (
        <LabelIcon label={'group-points'} />
      )}

      {isRoot ? (
        <SelectField
          id={`map-sampling-date-${currentGroupDate}`}
          className="selector-date"
          label={t({id: 'Sampling points'})}
          menuItems={getPointsGroupsMenuItems}
          value={currentGroupDate}
          onChange={pointsSetGroupDate}
          simplifiedMenu={true}
        />
      ) : (
        <span className={'group-date'}>{currentGroupDate}</span>
      )}

      <ReadOnly>
        {currentGroupDate !== 'all' && (
          <FluroButton
            className={cn('add-nmap-btn btn-with-icon', {disabled: !allowNmap()})}
            secondary
            raised
            iconEl={<FontIcon>add</FontIcon>}
            onClick={ev => {
              ev.stopPropagation(); // to prevent toggling expansion panel
              allowNmap() ? toggleNmapPopUp(currentGroupDate) : nowAllowedNmap();
            }}
          >
            {t({id: 'NMAP'})}
          </FluroButton>
        )}
      </ReadOnly>

      {isRoot && getBulkMenuBtn}
    </div>
  );
};

const connector = connect(null, {
  pointsSetGroupDate,
  showNote,
  removeSamplingPoint,
});
export default connector(PointsGroupHeader);
