import initialState from '../initial-state';
import {IAction} from '../../types';

import {ActionTypes} from '../types';
import {genKey} from '../../../../_utils';

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.MAP_TOGGLE_ZONING:
      return {
        ...state,
        isZoning: !state.isZoning,
      };

    case ActionTypes.MAP_SET_ZONING:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          ...action.zoning,
          copyZones: action.zoning.zones ? action.zoning.zones : [],
          points: {},
        },
      };

    case ActionTypes.MAP_SET_ZONING_POINTS:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          points: {...action.points},
          pointsKey: genKey(),
        },
      };

    case ActionTypes.MAP_TOGGLE_ZONING_RX:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          isEnableRx: action.isEnableRx,
        },
      };

    case ActionTypes.MAP_UPDATE_ZONES_RANGE:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          zones: [
            ...state.zoning.zones.map((z, i) => {
              if (i) {
                return {...z, min: action.values[i - 1]};
              }

              return {...z, max: action.values[0]};
            }),
          ],
        },
      };

    case ActionTypes.MAP_UPDATE_COPY_ZONES_RANGE:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          copyZones: state.zoning.zones.map((z, i) => {
            if (i) {
              return {...z, min: action.values[i - 1]};
            }

            return {...z, max: action.values[0]};
          }),
        },
      };

    case ActionTypes.MAP_SET_ZONING_UNITS: {
      return {
        ...state,
        zoning: {
          ...state.zoning,
          currentUnits: action.value,
        },
      };
    }

    case ActionTypes.MAP_UPDATE_ZONE_PROP:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          zones: state.zoning.zones.map(z => {
            if (z.id === action.id) {
              return {...z, [action.prop]: action.value};
            }
            return z;
          }),
        },
      };

    default:
      return state;
  }
};
