import * as React from 'react';
import {Link} from 'react-router-dom';
import './logo.scss';
import {useAppSelector} from '_hooks';
import config from '../../_environment';

const Logo = () => {
  const viewport = useAppSelector(s => s.viewport);
  const isCarbonDomain = config.featurePack === 'carbon';

  if (viewport.width < 561 && isCarbonDomain) return null;

  return (
    <Link to="/" className="toolbar-logo">
      <img
        src={
          viewport.width < 561
            ? '/assets/fluro_logo.png' // show smaller logo on small screens
            : '/assets/FluroSat_logo_300_text.png'
        }
        alt="flurosat logo"
      />
    </Link>
  );
};

export default Logo;
