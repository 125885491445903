import {ActionTypes} from '../reducer/types';
import {t} from 'i18n-utils';
import {showNote} from '_actions';
import moment from 'moment';
import turfArea from '@turf/area';
import {
  getAnomalyLabelValue,
  getLabelDataByValue,
  getLabelNameByValue,
  GLOBAL_FORMAT_DATE,
} from '_constants';
import {ActivityApi, AnomaliesApi} from '_api';
//@ts-ignore
import {DialogType, toggleDialog} from '_reducers/dialog';
import {
  checkFieldTagExist,
  getClosestGeometryDate,
  getNearestGeometryDate,
  mapBarScrollTo,
} from '../utils';
import {AnomalyTab, IAnomaly, TAnomalyProps} from '../features/anomalies/types';
import {
  isPermanentAnomaly,
  isPermanentAnomalyLabel,
  userFacingPriority,
  prepareAllLowPerfAnomalies,
  setLowPerfAnomalyProps,
} from '../features/anomalies/anomalies-utils';
import {setDate, setFeature, setSensor, toggleFieldGeometries} from '../actions';
import {changeAreaOfInterestProp} from './areas-of-interest-actions';
import {FieldTag, IInitialMapState} from '../types';
import {
  booleanIntersectField,
  buildAnomalyUrl,
  denormalizeSensorIndex,
  getCurrentImageName,
  getDateType,
  getGetURLParam,
  isDateInRange,
  sensorView,
} from '_utils';
import {AppStore} from 'reducers';
import {reportError} from 'containers/error-boundary';
import Mixpanel from '_utils/mixpanel-utils';
import {RequestStatus} from '../../../types';
import {parseGeometryFile} from '../utils/file';

// PREMIUM ANOMALIES

export const togglePremiumAnomalies = (value: boolean) => (dispatch: any, getState: any) => {
  const {
    feature,
    geometriesOnMap,
    anomalies: {anomaliesSelectedTab},
  } = getState().map;
  if (value) {
    feature !== 'crop' && dispatch(setFeature('crop'));
    !geometriesOnMap && dispatch(toggleFieldGeometries(true));
    !geometriesOnMap && dispatch(toggleFieldGeometries(true));
    anomaliesSelectedTab !== 'crop-stress' && dispatch(onChangeAnomalyTab('crop-stress'));
  } else {
    feature === 'crop' && dispatch(setFeature('farm'));
    dispatch({
      type: ActionTypes.MAP_PREMIUM_ANOMALIES_HIDE,
    });
  }
};

export const getPremiumAnomalies = () => (
  dispatch: any,
  getState: () => {map: IInitialMapState}
) => {
  // Premium anomalies are available only for tagged fields.
  // FSB-4061 HACK: Unblocking Victor to test anomalies wihtout the field tag, but using the url flag
  // getGetURLParam('premium-anomalies')
  if (!checkFieldTagExist(FieldTag.AnomalyDetection) && !getGetURLParam('premium-anomalies')) {
    return;
  }

  const {
    group: {id: groupId},
    currentSeason,
    currentDate,
    currentDates,
    field,
  } = getState().map;
  const {kmlId} = currentSeason;
  if (kmlId !== field.ID) {
    return;
  }

  dispatch({type: ActionTypes.MAP_LOAD_PREMIUM_ANOMALIES});

  AnomaliesApi.getPremiumAnomalies(
    groupId,
    kmlId,
    currentDates[currentDate]?.Date,
    currentDates[currentDate]?.Date
  )
    .then(({data}) => {
      dispatch({
        type: ActionTypes.MAP_PREMIUM_ANOMALIES_LOADED,
        data: (data.result.features || []).map((anomaly: IAnomaly) => {
          const {anomaly_ndvi, priority, label} = anomaly.properties;
          anomaly.properties = {
            ...anomaly.properties,
            priority: userFacingPriority(priority, anomaly.properties.label),
            checked: false,
            area: turfArea(anomaly),
            startDate: anomaly.properties.first_sensing_date, // TODO remove these fields, track usages
            endDate: anomaly.properties.last_sensing_date,
            mean: Math.round(denormalizeSensorIndex(anomaly_ndvi, -1)),
            label: getAnomalyLabelValue(label),
          };
          return anomaly;
        }),
      });
    })
    .catch(err => console.error('getPremiumAnomalies ERR', err));
};

export const updatePremiumAnomaly = (data: any) => (dispatch: any, getState: any) => {
  const {group, currentSeason} = getState().map;
  const {kmlId} = currentSeason;
  const {id: groupId} = group;
  const {prop, value, anomaly} = data;
  const isNeedRequest = ['label', 'description', 'snoozed'].includes(prop);
  const resultData = {[prop]: value} as Partial<TAnomalyProps>;

  if (prop === 'label' && isPermanentAnomalyLabel(value)) {
    // if user sets a permanent anomaly label, snooze the shape
    resultData.snoozed = 1;
  }

  if (prop === 'snoozed' && !value && isPermanentAnomaly(anomaly)) {
    return dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'This shape is labeled as a permanent anomaly and can not be un-snoozed.'}),
        level: 'warning',
      })
    );
  }

  if (prop === 'label') {
    Mixpanel.labelCropStress(getLabelNameByValue(value), anomaly.properties.anomaly_id);
  }

  if (prop === 'snoozed') {
    Mixpanel.toggleCropStress(value, anomaly.properties.anomaly_id);
  }

  if (isNeedRequest) {
    const {anomaly_id} = anomaly.properties;
    const requestData = [
      {
        anomaly_id,
        snoozed: anomaly.properties.snoozed,
        label: anomaly.properties.label,
        description: anomaly.properties.description,
        // We need to pass all the other rewritable values,
        // so PUT won't rewrite them with empty values.
        ...resultData,
      },
    ];
    AnomaliesApi.update(groupId, kmlId, requestData).then(({data}) => {
      dispatch({
        type: ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_LIST,
        list: data.result?.updated || [],
      });
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were saved'}),
          level: 'success',
        })
      );
    });
  } else {
    dispatch({
      type: ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_PROP,
      data,
    });
  }
};

export const updateBulkPremiumAnomaly = (data: {
  prop: string;
  value: any;
  anomalies: IAnomaly[];
}) => (dispatch: any, getState: any) => {
  const {group, currentSeason} = getState().map;
  const {kmlId} = currentSeason;
  const {id: groupId} = group;
  const {prop, value, anomalies} = data;
  const filteredAnomalies =
    prop === 'snoozed' && !value ? anomalies.filter(a => !isPermanentAnomaly(a)) : anomalies;
  const isNeedRequest = ['label', 'description', 'snoozed'].includes(prop);

  if (!filteredAnomalies.length || anomalies.length - filteredAnomalies.length) {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({
          id: 'Some of the shapes are labeled as permanent anomalies and can not be un-snoozed.',
        }),
        level: 'warning',
      })
    );
  }

  if (isNeedRequest && filteredAnomalies.length) {
    const requestData = filteredAnomalies.map(
      ({properties: {anomaly_id, label, description, snoozed}}: any) => ({
        anomaly_id,
        snoozed,
        label,
        description,
        [prop]: value,
      })
    );
    AnomaliesApi.update(groupId, kmlId, requestData).then(({data}) => {
      dispatch({
        type: ActionTypes.MAP_UPDATE_PREMIUM_ANOMALY_LIST,
        list: data.result.updated || [],
      });
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were saved'}),
          level: 'success',
        })
      );
    });
  } else {
    dispatch({
      type: ActionTypes.MAP_BULK_UPDATE_PREMIUM_ANOMALY_PROP,
      data: {...data, anomalies: filteredAnomalies},
    });
  }
};

export const removePremiumAnomaly = (anomalyIds: number[]) => (dispatch: any) => {
  return Promise.all(anomalyIds.map(AnomaliesApi.removePremiumAnomaly)).then(() => {
    dispatch({
      type: ActionTypes.MAP_REMOVE_PREMIUM_ANOMALIES,
      anomalyIds,
    });
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'saveAnomalyPlural'}, {count: anomalyIds.length}),
        level: 'success',
      })
    );
  });
};

// Agworld

export const uploadAnomaliesToAgworld = (anomalies: IAnomaly[]) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {currentSensor, currentSeason, currentDates, currentDate} = getState().map;
  const {date} = currentDates[currentDate][currentSensor];
  ActivityApi.uploadAnomaliesToAgworld(
    `${currentSeason.id}/${currentSensor}/${date}`,
    anomalies.map(a => ({anomalyId: String(a.properties.anomaly_id)}))
  )
    .then(() => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were uploaded to Agworld.'}),
          level: 'success',
        })
      );
    })
    .catch(err => {
      reportError('Failed to export anomalies to Agworld: ' + err);
    });
};

// agX

export const uploadAnomaliesToAgX = (anomalies: IAnomaly[], type: AnomalyTab) => (
  dispatch: any,
  getState: () => {map: IInitialMapState}
) => {
  const {currentSensor, currentSeason, currentDates, currentDate} = getState().map;
  const {date} = currentDates[currentDate][currentSensor];

  let requests = [];
  if (type === 'areas-of-interest') {
    const lowPerfAnomalies = anomalies.filter(el => el.properties.isLowPerf);
    const manuallyAnomalies = anomalies.filter(el => !el.properties.isLowPerf);
    if (manuallyAnomalies.length) {
      requests.push(
        ActivityApi.uploadAnomaliesToAgX(
          `${currentSeason.id}/${currentSensor}/${date}?type=geometries`,
          manuallyAnomalies.map((s: any) => ({
            anomalyId: `${s.properties.id}`,
            ...agxLabelsPrpsPart(s.properties.label),
          }))
        )
      );
    }
    if (lowPerfAnomalies.length) {
      requests.push(
        ActivityApi.uploadAnomaliesToAgX(
          `${currentSeason.id}/${currentSensor}/${date}?type=old`,
          lowPerfAnomalies.map((s: any) => {
            const {imageID, groupID, kmlID} = s;

            return {
              imageID,
              groupID,
              kmlID,
              anomalyID: `${s.properties.id}`,
              ...agxLabelsPrpsPart(s.properties.label),
            };
          })
        )
      );
    }
  } else {
    if (anomalies.length) {
      requests.push(
        ActivityApi.uploadAnomaliesToAgX(
          `${currentSeason.id}/${currentSensor}/${date}?type=anomalies`,
          anomalies.map((s: any) => ({
            anomalyId: `${s.properties.anomaly_id}`,
            ...agxLabelsPrpsPart(s.properties.label),
          }))
        )
      );
    }
  }

  Promise.all(requests)
    .then(() => {
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Anomalies were uploaded to agX.'}),
          level: 'success',
        })
      );
    })
    .catch(err => {
      console.log(err);
    });
};

function agxLabelsPrpsPart(label: any) {
  const lbl = getLabelDataByValue(label);

  return {
    anomalyType: lbl && lbl.anomalyType ? lbl.anomalyType : '',
    subTypeId: lbl && lbl.subTypeId ? lbl.subTypeId : '',
    anomalyValue: lbl && lbl.value ? lbl.value : '',
  };
}

// SIMPLE (LOW PERF) ANOMALIES

export const toggleLowPerformingAreas = (value: boolean, anomaly?: IAnomaly) => (
  dispatch: any,
  getState: any
) => {
  const currentImageName = getCurrentImageName();
  const {
    currentDates,
    lowPerfAnomalies: {list},
    currentSensor,
    currentDate,
    currentSeasonId,
  } = getState().map;
  const currentDateAnomalies = list[currentImageName];

  if (value && (!currentDate || !currentSeasonId)) {
    return dispatch(
      showNote({
        title: !currentSeasonId ? 'Season data missing' : 'Oops',
        message: !currentSeasonId
          ? t({id: 'Set up your season data first'})
          : t({
              id: 'Your season data is empty. Please, select another season, or field.',
              defaultMessage: '',
            }),
        level: 'warning',
      })
    );
  }

  if (anomaly && value) {
    const anomalyDate = `${moment(anomaly.date, GLOBAL_FORMAT_DATE).format('DD/MM/YYYY')}-${
      anomaly.dateType
    }`;

    if (currentDates[anomalyDate] && currentDate !== currentDates[anomalyDate])
      dispatch(setDate(anomalyDate));

    if (currentSensor !== anomaly.index) dispatch(setSensor(anomaly.index));
  }

  value && mapBarScrollTo('#anomalies');

  dispatch({
    type: ActionTypes.MAP_TOGGLE_LOW_PERF_ANOMALIES,
    value,
  });

  if (!value && list.uploadedROI?.features) {
    dispatch({
      type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
      lowPerformingAreas: {uploadedROI: {}},
    });
  }

  if (!anomaly && value && !currentDateAnomalies) {
    // show anomalies component, get data from server
    return dispatch(generateLowPerfAnomalies());
  }
};

export const getLowPerfAnomalies = () => (dispatch: any, getState: any) => {
  const {group, selectedFieldId} = getState().map;
  return ActivityApi.getLowPerfAnomalies(group.id, selectedFieldId).then(({data}) => {
    dispatch({
      type: ActionTypes.MAP_SET_LOW_PERF_ANOMALIES,
      lowPerformingAreas: prepareAllLowPerfAnomalies(data.result || []),
    });
  });
};

export const generateLowPerfAnomalies = () => (dispatch: any, getState: any) => {
  const {currentSensor, group, selectedFieldId, currentDate, currentDates} = getState().map;
  const currentImageName = getCurrentImageName();
  const isCorrectSensor = !['NONE', 'TCI', 'NC'].includes(currentSensor);
  const [normalizedCurrentDate, currentDateType] = currentDate.split('-');

  if (!Object.keys(currentDates).length || !isCorrectSensor) {
    if (!isCorrectSensor) {
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t(
            {
              id: 'cannotGenerateLowPerAnomalies',
              defaultMessage:
                'We can not generate low performing areas on a {sensor} layer. Please select another layer.',
            },
            {sensor: sensorView(currentSensor)}
          ),
          level: 'warning',
        })
      );
    }
    return dispatch({
      type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
      lowPerformingAreas: {},
    });
  }

  return ActivityApi.generateLowPerfAnomalies(group.id, selectedFieldId, currentImageName)
    .then(({data}) => {
      data.result.date = moment(normalizedCurrentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);
      data.result.dateType = currentDateType;
      const lowPerformingAreas = data.result
        ? {
            ...data.result,
            key: moment().valueOf(),
            features: data.result.features
              ? setLowPerfAnomalyProps(data.result, currentSensor, true)
              : [],
          }
        : {};

      dispatch({
        type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
        lowPerformingAreas: {[currentImageName]: lowPerformingAreas},
      });
    })
    .catch(error => {
      console.warn(error);
      dispatch(
        showNote({
          title: t({id: 'Oops'}),
          message: t({
            id:
              'Something wrong with anomalies for this layer, try to change to another one or try later.',
          }),
          level: 'warning',
        })
      );
    });
};

export const changeLowPerfAnomalyProp = <K extends keyof TAnomalyProps>(
  anomalyToChange: IAnomaly[] | IAnomaly,
  prop: K,
  value: TAnomalyProps[K]
) => (dispatch: any, getState: any) => {
  const {currentDates, lowPerfAnomalies} = getState().map;
  const currentDatesLength = Object.keys(currentDates).length;
  const {list, isVisible} = lowPerfAnomalies;
  const {date, dateType, index, properties} = Array.isArray(anomalyToChange)
    ? anomalyToChange[0]
    : anomalyToChange;
  const anomalyDate = `${moment(date, GLOBAL_FORMAT_DATE).format('DD/MM/YYYY')}-${dateType}`;
  const anomalyName = properties.isLowPerf
    ? currentDates[anomalyDate] && currentDates[anomalyDate][index].name
    : 'uploadedROI';

  if (!properties.uploading && !currentDates[anomalyDate] && currentDatesLength) {
    dispatch(
      showNote({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: t({
          id: 'imgCloudyOrHidden',
          defaultMessage:
            'Your imagery is cloudy or hidden, go to the remote sensing tab to display it.',
        }),
        level: 'warning',
      })
    );
  }

  const currentDateAnomalies = list[anomalyName];

  if (!currentDateAnomalies) return false;

  const updatedAnomalies = {
    [anomalyName]: {
      ...currentDateAnomalies,
      features: currentDateAnomalies.features.map((anomaly: IAnomaly) => {
        if (
          Array.isArray(anomalyToChange)
            ? anomalyToChange.find((a: IAnomaly) => a.properties.id === anomaly.properties.id)
            : anomalyToChange.properties.id === anomaly.properties.id
        )
          return {
            ...anomaly,
            properties: {
              ...anomaly.properties,
              [prop]: value,
            },
          };

        return anomaly;
      }),
    },
  };
  dispatch({
    type: ActionTypes.MAP_UPDATE_LOW_PERF_ANOMALIES,
    lowPerformingAreas: updatedAnomalies,
  });

  if (anomalyName !== 'uploadedROI' && !isVisible && prop === 'label') {
    dispatch(saveLowPerfAnomalies(buildAnomalyUrl(anomalyDate), updatedAnomalies[anomalyName]));
  }
};

export const toggleAnomaliesHistory = (value: boolean) => ({
  type: ActionTypes.MAP_AREAS_OF_INTEREST_TOGGLE_HISTORY,
  value,
});

export const uploadAnomalies = (files: File[]) => (dispatch: any, getState: () => AppStore) => {
  const {
    currentDate,
    currentSensor,
    currentSeason,
    lowPerfAnomalies: {
      list: {uploadedROI = {} as any},
    },
  } = getState().map;
  const featureFromUploadedFile: any = {};

  Promise.all(files.map(parseGeometryFile))
    .then(result => {
      if (result.find(file => file.errors.length)) {
        const parsingErrors = result.reduce((acc, r) => [...acc, ...r.errors], []);

        parsingErrors.forEach(e => {
          dispatch(
            showNote({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              //TODO: i18n
              message: e,
              level: 'warning',
            })
          );
        });
        return;
      }
      dispatch(toggleDialog(DialogType.AddNewAnomaly));
      const features = result
        .map((f: any) => f.features[0].features)
        .flat(1)
        .map(f => ({
          ...f,
          properties: {
            ...f.properties,
            startDate: moment(currentSeason.startDate, GLOBAL_FORMAT_DATE).format(
              GLOBAL_FORMAT_DATE
            ),
            endDate: moment(currentSeason.endDate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE),
          },
        }));
      featureFromUploadedFile.features = features;
      featureFromUploadedFile.key = moment().valueOf();
      featureFromUploadedFile.dateType = currentDate ? getDateType(currentDate) : '';
      featureFromUploadedFile.savedAsRoi = true; // mark uploading geometries to identify them later
      featureFromUploadedFile.features = setLowPerfAnomalyProps(
        featureFromUploadedFile,
        currentSensor
      ).filter((anomaly: any) => booleanIntersectField(anomaly));

      if (featureFromUploadedFile.features.length) {
        const filteredDuplicates = featureFromUploadedFile.features.filter((f: any) => {
          const duplicate = uploadedROI.features
            ? uploadedROI.features.find((g: any) => g.properties.area === f.properties.area)
            : false;
          if (duplicate)
            dispatch(
              showNote({
                title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                message:
                  "You've tried to upload the already existing geometry, please select another one.",
                level: 'warning',
              })
            );

          return !duplicate;
        });

        dispatch({
          type: ActionTypes.MAP_UPLOAD_AREAS_OF_INTEREST,
          lowPerformingAreas: {
            uploadedROI: uploadedROI.features
              ? {
                  ...uploadedROI, // set or update
                  features: [...uploadedROI.features, ...filteredDuplicates],
                }
              : featureFromUploadedFile,
          },
        });

        if (features.length > featureFromUploadedFile.features.length) {
          dispatch(
            showNote({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message:
                'Some uploaded shapes do not overlap the current field, they were filtered out.',
              level: 'warning',
            })
          );
        }
      } else {
        dispatch(
          showNote({
            title: t({id: 'note.error', defaultMessage: 'Error'}),
            message:
              'None of the uploaded shapes overlap the current field. Change the field or try uploading an another file.',
            level: 'warning',
          })
        );
      }
    })
    .catch(err => console.log(err));
};

export const saveLowPerfAnomalies = (url: string, anomalies: any, isFromForm?: boolean) => (
  dispatch: any
) => {
  return ActivityApi.saveLowPerfAnomalies(url, anomalies)
    .then(() => {
      isFromForm &&
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'Anomalies were saved'}),
            level: 'success',
          })
        );
    })
    .catch(err => console.log(err));
};

/**
 * Chenge a prop of any geometry (area of interest, low perf anomaly or premium anomaly).
 */
export const changeAnyTypeGeometryProp = (
  geometry: IAnomaly,
  prop: keyof TAnomalyProps,
  value: any
) => (dispatch: any, getState: any) => {
  const {currentDate, currentDates} = getState().map;
  const isLowPerf = geometry.properties.isLowPerf;
  const isEdit = prop === 'saved';
  const isLabel = prop === 'label';

  dispatch(toToggleFieldGeometries(prop, value));

  if (isLowPerf) {
    dispatch(changeLowPerfAnomalyProp(geometry, prop, value));
  } else {
    let dateToSwitch = getNearestGeometryDate(geometry);

    const {startDate, endDate} = geometry.properties;
    const isInterSelect = isDateInRange(
      moment.utc(currentDate, 'DD/MM/YYYY').format(),
      startDate,
      endDate
    );

    if (!isInterSelect && !isEdit && !isLabel) {
      dateToSwitch = getClosestGeometryDate(geometry);

      if (!dateToSwitch && !Object.keys(currentDates)) {
        return dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message:
              'This shape cannot be displayed for the selected season. Select a season between the starting date and the end date of the region of interest.',
            level: 'warning',
          })
        );
      }
    }

    dispatch(changeAreaOfInterestProp(geometry, prop, value));

    // dateToSwitch && dispatch(setDate(dateToSwitch));
    if (prop === 'saved' && !value) {
      mapBarScrollTo(`#geometry-${geometry.properties.id}`);
    }
  }
};

export const toToggleFieldGeometries = (prop: keyof TAnomalyProps, value: any) => (
  dispatch: any,
  getState: any
) => {
  if (['openPopUp', 'checked'].includes(prop) && value && !getState().map.geometriesOnMap) {
    dispatch(toggleFieldGeometries(true));
  }
};

export const onChangeAnomalyTab = (value: AnomalyTab) => ({
  type: ActionTypes.MAP_CHANGE_ANOMALIES_TAB,
  value,
});

export const setUploadingAnomaliesStatus = (value: RequestStatus) => ({
  type: ActionTypes.MAP_UPLOAD_AREAS_OF_INTEREST_SET_STATUS,
  value,
});
