import {FarmStore, FarmAction, FarmActionTypes} from './types';
import {genKey} from '_utils';

const initialState: FarmStore = {
  list: [],
  totalCount: 0,
  reload: genKey(), // for what we need this property?
  showInvite: false,
  demoFarmsObject: {},
  demoFarmsIdsList: [],
};

export const reducer = (state = initialState, action: FarmAction): FarmStore => {
  switch (action.type) {
    case FarmActionTypes.LOAD_FARMS_LIST:
      return {
        ...state,
        list: [...(action.farms.list || [])],
        totalCount: action.farms.totalCount,
        demoFarmsObject: action.demoFarms,
        demoFarmsIdsList: Object.keys(action.demoFarms).map(id => Number(id)),
      };

    case FarmActionTypes.CREATE_FARM:
      return {
        ...state,
        list: [...state.list, action.farm],
        totalCount: state.totalCount + 1,
      };

    case FarmActionTypes.UPDATE_FARM:
      return {
        ...state,
        list: state.list.map(g => (g.id === action.farm.id ? {...g, ...action.farm} : g)),
      };

    case FarmActionTypes.REMOVE_FARM:
      const grIdx = state.list.findIndex(g => g.id === action.farmId);

      grIdx !== -1 && state.list.splice(grIdx, 1);

      return {
        ...state,
        totalCount: state.totalCount - 1,
        list: [...state.list],
      };

    case FarmActionTypes.RELOAD_FARM:
      return {
        ...state,
        reload: genKey(),
      };

    case FarmActionTypes.SHOW_USER_INVITE:
      return {
        ...state,
        showInvite: action.value,
      };

    default:
      return state;
  }
};

export * from './types';
