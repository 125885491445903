import {hackGetState} from '../../../store';
import moment from 'moment';
import {Field, SamplingPoint} from '../types';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {formatDate} from '_utils';

/**
 * sortPointsGroupsDates() returns a sorted pointsGroups object
 * @param groupsObj === state.map.pointsGroups but keys are formatted to GLOBAL_FORMAT_DATE
 */
export function sortPointsGroupsDates(groupsObj: any) {
  const dates = Object.keys(groupsObj);
  const sortedGroups: any = {};
  const sortedDates = dates.sort(function(a, b) {
    if (!a) return 1;
    if (moment.utc(a, GLOBAL_FORMAT_DATE).isBefore(moment.utc(b, GLOBAL_FORMAT_DATE))) return 1;
    if (moment.utc(a, GLOBAL_FORMAT_DATE).isAfter(moment.utc(b, GLOBAL_FORMAT_DATE))) return -1;
    return 0;
  });

  sortedDates.forEach(d => {
    const formattedDate = moment.utc(d, GLOBAL_FORMAT_DATE).format(formatDate());
    if (moment.utc(d, GLOBAL_FORMAT_DATE).isValid()) {
      sortedGroups[formattedDate] = groupsObj[d];
    }
  });

  return sortedGroups;
}

/**
 * getPointsIds() returns an array of sampling point ids
 * @param groupDate key of state.map.pointsGroups
 * @param onlyChecked is a flag to get only checked points or not
 */
export const getPointsIds = (groupDate: string, onlyChecked = true) => {
  const state: any = hackGetState();
  const {pointsGroups} = state.map;
  return (
    pointsGroups[groupDate]
      ?.filter((point: SamplingPoint) => (onlyChecked ? point.properties.checked : true))
      .map((point: SamplingPoint) => point.id) || []
  );
};

/**
 * getPointsGroupKeys() simply returns keys of pointDates, "all" group is filtered by default
 */
export const getPointsGroupKeys = (filterAll = true) => {
  const state: any = hackGetState();
  return filterAll
    ? Object.keys(state.map.pointsGroups).filter(g => g !== 'all')
    : Object.keys(state.map.pointsGroups);
};

/**
 * getSortedPointsGroup() returns sorted pointsGroups{} && calculated pointsCurrentGroupsDate
 */
export function getSortedPointsGroup(
  pointsCurrentGroupDate: string,
  samplingPoints: SamplingPoint[] = []
) {
  let pointsGroups: {[key: string]: SamplingPoint[]} = {};

  samplingPoints.forEach(ts => {
    const key = moment(ts.properties.timedate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE);
    pointsGroups[key] = Array.isArray(pointsGroups[key]) ? [...pointsGroups[key], ts] : [ts];
  });

  const sortedGroups = sortPointsGroupsDates(pointsGroups);

  sortedGroups['all'] = samplingPoints;

  pointsCurrentGroupDate = sortedGroups[pointsCurrentGroupDate]
    ? pointsCurrentGroupDate
    : Object.keys(sortedGroups)[0];

  return {pointsGroups: sortedGroups, pointsCurrentGroupDate};
}

/**
 * isDifferentDatesPoints() takes an array of points and returns if there are points for different dates/groups
 */
export const isDifferentDatesPoints = (points: SamplingPoint[]): boolean => {
  const pointDates: string[] = [];
  points.forEach(point => {
    const pointDate = moment(point.properties.timedate, GLOBAL_FORMAT_DATE).format(
      GLOBAL_FORMAT_DATE
    );
    if (!pointDates.includes(pointDate)) {
      pointDates.push(pointDate);
    }
  });
  return pointDates.length > 1;
};

/**
 * getCheckedPoints() returns list of checked points fro current points date or just filter provided list of points
 * @param points? provide a specific list of points
 */
export const getCheckedPoints = (points?: SamplingPoint[]) => {
  const state: any = hackGetState();
  const currentGroupPoints = state.map.pointsGroups[state.map.pointsCurrentGroupDate] || [];
  points = points ? points : currentGroupPoints;
  return points.filter(point => point.properties.checked);
};
/**
 * toggleMarkerPopUp() uses global leafletElement {} to trigger a pin pop-up
 */
export const toggleMarkerPopUp = (markerId: number, value: boolean) => {
  try {
    // @ts-ignore
    window.leafletElement.eachLayer(l =>
      l.fluroId === markerId && value ? l.openPopup() : l.closePopup()
    );
  } catch (e) {
    console.log(e);
  }
};
/**
 * getPointById() returns list of checked points fro current points date or just filter provided list of points
 * @param pointId id of a point to search for
 * @param points array of points
 */
export const getPointById = (pointId: number, points: SamplingPoint[]) => {
  return points.find(p => p.id === pointId);
};
/**
 * updateFieldSeasonTissueSampling() returns updated field seasons with last sampling point changes (to avoid erased changes when season is changed and then selected back)
 */
export const updateFieldSeasonTissueSampling = (
  field: Field,
  seasonId: number,
  tissueSampling: SamplingPoint[]
) => ({
  ...field,
  Seasons: field.Seasons.map(season => {
    if (season.id === seasonId) {
      return {
        ...season,
        tissueSampling,
      };
    }
    return season;
  }),
});
