import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {AppStore} from '../reducers';
import {AsyncStatusType, Status} from '../modules/ui-helpers';
import {MapLoader} from './map-loader';

export const ImageLayerPreloader = () => {
  const asyncStatuses = useSelector((state: AppStore) => state.uiHelpers.asyncStatuses);
  const loaders = useSelector((state: AppStore) => state.global.loaders);

  const listenedRequests = [AsyncStatusType.imageLayer, AsyncStatusType.cluFieldBoundaries];
  const isLoading = listenedRequests.some(r => asyncStatuses[r].status === Status.Pending);

  const hasGlobalLoaders = useMemo(() => {
    return Object.keys(loaders).length > 0;
  }, [loaders]);

  // show layer preloader only if we do not have any other global blocking screen preloader
  return <MapLoader loading={isLoading && !hasGlobalLoaders} />;
};
