import React, {PureComponent} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {connect, ConnectedProps} from 'react-redux';
import {showNote} from '_actions';
import {setCurrentFieldId, cancelDrawingPivotCenter} from 'containers/map/actions';
import {ExternalService, IInitialMapState} from 'containers/map/types';
import FluroAutocomplete from 'components/autocomplete';
import MultiKeysPressed from 'components/key-handler';
import InfoBlock from 'components/info-block';
import cn from 'classnames';
import {BrowserView, isBrowser} from 'react-device-detect';
import {GlobalState} from '../../_reducers/global_types';

import {dialogToggle} from 'modules/ui-helpers';
import {messages, Messages} from 'containers/info';
import {DemoIcon, SelectorFieldWrapper} from './header.styled';

import DEMO_FARMS_URLS from '_constants/demo-fields-urls';
import {isAdminPerm, sortFieldsByProp} from '_utils';
import {reportError} from '../error-boundary';
import ExternalServiceIcon from './external-service-icon';
import {AppStore} from '../../reducers';

const WHOLE_FARM_LABEL = 'Whole Farm';

type Props = ConnectedProps<typeof connector>;

interface IState {
  selectedFieldName: string | number;
}

type MenuItem = {
  value: number | string;
  label: JSX.Element | string;
  // The x-y-z case is so it can be added as a DOM property due to how react-md uses menuItems.
  'data-searchable-label': string; // String representation of the label, so it can be filtered.
  className?: string;
};

const ButtonsNavInstruction = (
  <li onClick={ev => ev.stopPropagation()} key="field-help-container" className="md-list-item">
    <InfoBlock>​Use Shift + Arrow Up or Arrow Down to switch fields.</InfoBlock>
  </li>
);

class SelectorField extends PureComponent<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFieldName: '',
    };
  }

  componentDidMount() {
    this.setState({selectedFieldName: this.props.field.Name});
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {field} = this.props;

    if (field.ID !== prevProps.field.ID || field.Name !== this.state.selectedFieldName) {
      this.setState({selectedFieldName: field.Name});
    }
  }

  selectField = (v: string | number) => {
    if (v === this.props.selectedFieldId) return;

    const selectedFieldName =
      v === 'WholeFarm' ? WHOLE_FARM_LABEL : this.props.fields.find(f => f.ID === v)?.Name || '';

    this.props.setCurrentFieldId(v);
    this.setState({selectedFieldName});
    this.props.cancelDrawingPivotCenter();
  };

  onAutocompleteField = (suggestion: string | number, suggestionIndex: number, matches: any) => {
    if (matches[suggestionIndex]) {
      this.selectField(suggestion);
    }
  };

  getValue = () => {
    const {fields, fieldGeometries, isWholeFarmView, wholeTableViewOpen} = this.props;
    const sFieldsAmount = fields.filter(f => f._selected && fieldGeometries[f.MD5]).length;
    return sFieldsAmount && isWholeFarmView
      ? `${
          sFieldsAmount === fields.length ? WHOLE_FARM_LABEL : `${sFieldsAmount} field(s) selected`
        }`
      : wholeTableViewOpen === 'farm' || (isWholeFarmView && !sFieldsAmount)
      ? WHOLE_FARM_LABEL
      : this.state.selectedFieldName;
  };

  getMenuItems = (): MenuItem[] | any => {
    const {selectedFieldId, fields, isAdmin} = this.props;
    const returnLabel = (
      name: string,
      classNames: string,
      index: number,
      externalService?: ExternalService
    ) => (
      <span data-index={index} className={classNames}>
        {name}

        {externalService && isAdmin ? <ExternalServiceIcon value={externalService} /> : null}
      </span>
    );

    return [
      {
        label: returnLabel(
          WHOLE_FARM_LABEL,
          cn({'selected-field': selectedFieldId === 'WholeFarm'}),
          0
        ),
        'data-searchable-label': 'Whole Farm',
        value: 'WholeFarm',
      },
      ...fields.map((f, index) => {
        return {
          label: returnLabel(
            f.Name,
            cn('field-name', {
              'selected-field': f.ID === selectedFieldId,
            }),
            index,
            f.external_service
          ),
          'data-searchable-label': f.Name,
          value: f.ID,
        };
      }),
    ];
  };

  openDemoPopup = () => {
    const {currentDemoFarmsURL, dialogToggle} = this.props;

    if (currentDemoFarmsURL) {
      const params = new URLSearchParams(currentDemoFarmsURL);
      const messageId = params.get('message') as keyof Messages;

      if (messageId && messages[messageId]) {
        dialogToggle('info', true, messageId);
      }
    }
  };

  onMenuOpen = () => {
    try {
      // scroll to selected field
      const selectedItem = document.querySelector('#selector-fields .selected-field');

      if (selectedItem) {
        const index = parseInt(selectedItem.getAttribute('data-index'));

        // do not scroll for fist 5 element
        if (index > 5) {
          selectedItem.scrollIntoView({block: 'center'});
        }
      }
    } catch (e) {
      console.warn(e);
      reportError('Cannot scroll to the selected field: ' + e.message);
    }
  };

  render() {
    const {currentDemoFarmsURL, selectedFieldId} = this.props;
    const value = this.getValue();
    let menuItems = this.getMenuItems();
    let demoPopUpOpenIcon = null;

    if (currentDemoFarmsURL) {
      demoPopUpOpenIcon = (
        <DemoIcon className={'open-demo-farm-icon'} onClick={this.openDemoPopup}>
          help_outline
        </DemoIcon>
      );
    }

    const currentIndex =
      value === WHOLE_FARM_LABEL
        ? 0
        : menuItems.findIndex((f: MenuItem) => f.value === selectedFieldId);

    if (isBrowser) {
      menuItems.push(ButtonsNavInstruction);
    }

    return this.props.fields.length && selectedFieldId ? (
      <>
        <SelectorFieldWrapper id="selector-fields">
          <FluroAutocomplete
            id={'map__select-field'}
            containerClassName={'farm-toolbar__select-field'}
            placeholder="Fields"
            onAutocomplete={this.onAutocompleteField}
            inputClassName={'onboarding__select-field'}
            value={value}
            searchKey={'data-searchable-label'}
            menuItems={menuItems}
            // pagination={1000} // the developer that set this value to 1000 (Sasha) can't explain the reason, trying to reduce it to default 50
            onMenuOpen={this.onMenuOpen}
            shouldNotResetOnFocus
          />
          {demoPopUpOpenIcon}
        </SelectorFieldWrapper>
        <BrowserView>
          <MultiKeysPressed
            callback={() => {
              currentIndex > 0 && this.selectField(menuItems[currentIndex - 1].value);
            }}
            keys={['Shift', 'ArrowUp']}
          />
          <MultiKeysPressed
            callback={() => {
              // menuItems.length - 2 cuz the last item is not field item, it is - info message block
              if (currentIndex < menuItems.length - 2) {
                this.selectField(menuItems[currentIndex + 1].value);
              } else {
                this.props.showNote({
                  title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                  message: 'This is the last field in the list.',
                  level: 'warning',
                });
              }
            }}
            keys={['Shift', 'ArrowDown']}
          />
        </BrowserView>
      </>
    ) : (
      <span className="no-fields-select">No fields</span>
    );
  }
}

const mapStateToProps = (s: AppStore) => ({
  fields: sortFieldsByProp(s.map.fields, 'Name', 'string'),
  fieldGeometries: s.map.fieldGeometries,
  field: s.map.field,
  wholeTableViewOpen: s.map.wholeTableViewOpen,
  selectedFieldId: s.map.selectedFieldId,
  isWholeFarmView: s.map.wholeFarm.isWholeFarmView,
  globalDialogs: s.global.dialogsState,
  currentDemoFarmsURL:
    DEMO_FARMS_URLS[
      `${s.global.currentGroupId}/${s.map.wholeFarm.isWholeFarmView ? 'WholeFarm' : s.map.field.ID}`
    ],
  isAdmin: isAdminPerm(s.login.user.perm),
});

const connector = connect(mapStateToProps, {
  setCurrentFieldId,
  showNote,
  cancelDrawingPivotCenter,
  dialogToggle,
});
export default connector(SelectorField);
