import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {WellDoneIcon, ProfileIcon, InfoIcon, Weather} from '../map/icons';
import {InfoBlock} from 'components';
import Ln from 'components/ln';
import {FontIcon} from 'react-md';
import {Step} from 'react-joyride';
import {WarningSvg} from '../../components/icons';

export type FluroStep = Step & {name?: string};

type Props = {
  closeOnboarding(): void;
};
export const LastStep = ({closeOnboarding}: Props): FluroStep => {
  return {
    title: 'Well Done!',
    content: (
      <div className="onboarding-last-step">
        <div className={'flex-container'}>
          <WellDoneIcon className={'done-icon'} />

          <div>
            You completed a tour of FluroSense main features.
            <br />
            <br />
            Now we would like to show you some practical applications of what FluroSense can do on
            specific <b>demo farms</b>.
            <br />
            <br />
          </div>
        </div>

        <InfoBlock>
          Remember, you can access the tours at a later stage from your <ProfileIcon />{' '}
          <Ln onClick={closeOnboarding} href={'/profile'}>
            user profile
          </Ln>
          .
        </InfoBlock>
      </div>
    ),
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    styles: {
      options: {
        width: '400px',
      },
    },
  };
};

export const FieldTools: FluroStep = {
  title: 'Field Insight tools',
  content: (
    <div>
      Get in-depth insights about crop condition in each of your fields using the following tools:
      <ul>
        <li className={'with-icon'}>
          <InfoIcon className={'icon'} />
          crop status, % crop cover, avg NDVI,
        </li>
        <li className={'with-icon'}>
          <Weather className={'icon weather'} />
          crop growth stages based on weather info,
        </li>
        <li className={'with-icon'}>
          <WarningSvg />
          areas of crop stress or in-field observations.
        </li>
      </ul>
    </div>
  ),
  target: '.onboarding__buttons-on-map',
  placement: 'right-end',
  disableBeacon: true,
  name: 'buttons-on-map',
};
