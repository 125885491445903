import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {AppStore} from 'reducers';
import {Season} from 'containers/map/types';
import {setDate, selectFieldSeason} from 'containers/map/actions';
import {showNote} from '_actions';
import {t} from 'i18n-utils';

export const useSelectSeason = () => {
  const dispatch = useDispatch();
  const currentDates = useSelector((s: AppStore) => s.map.currentDates);
  const currentDate = useSelector((s: AppStore) => s.map.currentDate);
  const remoteSensingCloudCover = useSelector((s: AppStore) => s.map.remoteSensingCloudCover);
  const isWholeFarmView = useSelector((s: AppStore) => s.map.wholeFarm.isWholeFarmView);

  const selectSeason = useCallback(
    (season: Season) => {
      if (isWholeFarmView) {
        // set date to match selected season, FSB-1682
        if (!season.infoExt || season.infoExt.length === 0) {
          return dispatch(
            showNote({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: t({id: 'There is no data available yet for this season.'}),
              level: 'warning',
            })
          );
        }

        const mCurrDate = moment(currentDate, 'DD/MM/YYYY');
        const infoExt = season.infoExt || [];
        const intersectsWithSeasonDates = infoExt.find(d =>
          moment(d.Date, 'YYYYMMDD').isSame(mCurrDate, 'day')
        );

        if (!intersectsWithSeasonDates && infoExt.length) {
          const existDate = infoExt.find(d => {
            const convertedDate = `${moment(d.Date, 'YYYYMMDD').format('DD/MM/YYYY')}-${d.Type}`;
            return (
              d.Cloud <= remoteSensingCloudCover &&
              currentDates[convertedDate] &&
              currentDates[convertedDate].Cloud <= remoteSensingCloudCover
            );
          });

          if (existDate) {
            dispatch(
              setDate(
                `${moment(existDate.Date, 'YYYYMMDD').format('DD/MM/YYYY')}-${existDate.Type}`
              )
            );
          }
        }
      }
      dispatch(selectFieldSeason(season));
    },
    [currentDates, currentDate, remoteSensingCloudCover, isWholeFarmView]
  );

  return selectSeason;
};
