import {Sticky} from 'components/sticky/sticky';
import * as React from 'react';
import './floating-container.scss';

export const FloatingContainer = ({children}: React.PropsWithChildren<{}>) => {
  return (
    <Sticky elevateStuck={false} className="floating-container">
      {children}
    </Sticky>
  );
};
