import {t} from 'i18n-utils';
import React, {useMemo} from 'react';
import {dialogToggle, DialogType} from 'modules/ui-helpers';
import {useDispatch} from 'react-redux';
import {formatDate, genKey, selectMeasurement} from '_utils';
import moment from 'moment';
import {useAppSelector} from '_hooks';
import {
  FluroDataTable,
  FluroTableBody,
  FluroTableRow,
  FluroTableColumn,
  FluroDialog,
} from 'components';

const FieldInfoDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const field = useAppSelector(store => store.uiHelpers.dialogs.fieldInfo?.payload || {});

  return (
    <FluroDialog
      id={`field-${id}`}
      visible
      title={t({id: 'Field info'})}
      focusOnMount={false}
      isDraggable
      onHide={() => dispatch(dialogToggle(DialogType.fieldInfo, false))}
      width={460}
      portal
    >
      <FluroDataTable elevated={false} responsive={false} fullWidth={true}>
        <FluroTableBody>
          <FluroTableRow>
            <FluroTableColumn>{t({id: 'Area'})}</FluroTableColumn>
            <FluroTableColumn>{selectMeasurement(field.Area)}</FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow>
            <FluroTableColumn>{t({id: 'Region'})}</FluroTableColumn>
            <FluroTableColumn>{field.CountryRegion || '—'}</FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow>
            <FluroTableColumn>Satellite tile</FluroTableColumn>
            <FluroTableColumn>{field.MgrsTile || '—'}</FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow>
            <FluroTableColumn>{t({id: 'Created'})}</FluroTableColumn>
            <FluroTableColumn>
              {moment(field.CreateTime).format(formatDate() + ', h:mm:ss')}
            </FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow>
            <FluroTableColumn>Lat/Lon</FluroTableColumn>
            <FluroTableColumn>
              {((field.NorthLat + field.SouthLat) / 2).toFixed(3)}{' '}
              {((field.WestLon + field.EastLon) / 2).toFixed(3)}
            </FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow>
            <FluroTableColumn>MD5</FluroTableColumn>
            <FluroTableColumn>{field.MD5}</FluroTableColumn>
          </FluroTableRow>

          <FluroTableRow>
            <FluroTableColumn>ID</FluroTableColumn>
            <FluroTableColumn>{field.ID}</FluroTableColumn>
          </FluroTableRow>
        </FluroTableBody>
      </FluroDataTable>
    </FluroDialog>
  );
};

export default FieldInfoDialog;
