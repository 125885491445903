import Server from './server';
import service from './service';
import {FieldsLookupSearchParams} from 'modules/fields-lookup.module';
import {UpdateCloudyPayload} from './types';
import axios from 'axios';
import config from '_environment';
import cancelTokenStore from './cancel-tokens-store';

export default class {
  static loadEmailLogs(page = 1, perPage?: number, params?: any) {
    if (!perPage) perPage = 100;

    return Server.get('admin/emails/log', {
      params: {page, per_page: perPage, ...({...params, __: null} || {})},
    });
  }

  static searchLogEmails(q: string) {
    return Server.get('admin/emails/log/search', {params: {q}});
  }

  static search(q: string, searchBy = 'all') {
    cancelTokenStore.cancel(`adminSearchEntity${searchBy}`);
    const source = cancelTokenStore.generateSource(`adminSearchEntity${searchBy}`);

    return Server.get('admin/fields/search', {
      cancelToken: source.token,
      params: {__skipPreloader: true, q, fields: searchBy},
    });
  }

  static cropStressLogs(
    fields: {
      md5: string;
      startDate: string;
      endDate: string;
    }[]
  ) {
    return service.post('anomaly/dashboard', {fields}).then(({data}) => data.fields);
  }

  static rescheduleCropStress(
    reschedule: {
      _id: string;
      sensingTime: string;
      md5: string;
    }[]
  ) {
    return Server.post('app/crop_stress/force_reschedule', {reschedule}).then(({data}) => data);
  }

  static getCropStressFields(q: string, searchBy = 'all') {
    return Server.get('app/crop_stress/dashboard', {
      params: {__skipPreloader: true, q, fields: searchBy},
    });
  }

  static emailsToReview() {
    return Server.get('admin/emails/to_review');
  }

  static emailsCheckUpdateStatus(url: string) {
    return Server.get(url, {params: {__skipPreloader: true}});
  }

  static emailLogsSetStatus(data: any[]) {
    return Server.put('admin/emails/log', data);
  }

  static downloadAdminReportBySubscription(subscriptionId: number) {
    return Server.get(`admin/reports/subscription_all_tag_imagery/${subscriptionId}`, {
      params: {__: null}, // don't show the loader
    });
  }
  static downloadAdminReportByOrganization(organizationId: number) {
    return Server.get(`admin/reports/organization_all_tag_imagery/${organizationId}`, {
      params: {__: null}, // don't show the loader
    });
  }

  static getFieldsLookup(data: FieldsLookupSearchParams) {
    return Server.post('/admin/fields/with_seasons', data);
  }

  static getRegions() {
    return Server.get('admin/regions');
  }

  static getCountries() {
    return Server.get('admin/regions');
  }

  static impersonateAsUser(userId: number) {
    return Server.get(`admin/users/${userId}/impersonate`);
  }

  static getFieldInfoByMd5(md5: string) {
    return Server.get(`admin/field_info/${md5}`);
  }

  static updateCloudyPercent(data: UpdateCloudyPayload) {
    return axios.put(`${config.baseUrl}fense/cloudy`, data);
  }

  static updateCrop(data: any) {
    return Server.post(`crops`, data);
  }
}
