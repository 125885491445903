import React, {useCallback, useEffect, useState} from 'react';
import './order-planet-imagery.scss';
import {Button} from 'react-md';
import NewOrderView from './new-order/new-order-view';
import OrdersTable from './orders-table';
import {PlanetApi} from '_api';
import config from '../../../_environment';
import {Field} from '../../map/types';
import {AdminContainer, AdminContentWrapper, AdminTitle} from '../admin.styled';

export type PlanetOrder = {
  CreatedAt: string; // YYYY-MM-DDT
  CreatedBy: number; // user id that created the order
  EndDate: string; // YYYY-MM-DDT00:00:00Z
  ID: number; // order id
  KMLs: Field[];
  ModifiedAt: string; // YYYY-MM-DDT00:00:00Z date when order was updated
  Note: string; // note by a user
  Reason: string; // unknown prop
  StartDate: string; // YYYY-MM-DDT00:00:00Z
  Status: 'new' | string;
};
const OrderPlanetImagery = () => {
  const [newOrderView, toggleNewOrderView] = useState(false);
  const [orders, setOrders] = useState<PlanetOrder[]>([]);
  const isCorrectDev =
    config.baseUrl === 'https://dev5.flurosat.com/' || location.host === 'dev5.flurosat.com'; // only dev5 back-end wan't create real orders

  const onBackToTableView = useCallback(() => toggleNewOrderView(false), []);

  useEffect(() => {
    if (isCorrectDev) {
      PlanetApi.getPlanetOrders().then(({data}) => {
        setOrders(data.result || []);
      });
    }
  }, []);

  const onAddNewOrder = useCallback((newOrder: PlanetOrder) => {
    setOrders(orders => [...orders, newOrder]);
  }, []);

  const onApproveOrder = (orderId: number, fieldsArea: string, orderCost: string) => {
    const confirmed = confirm(
      `Approve order.\nYou are about to order imagery for ${fieldsArea} and ${orderCost}$.`
    );
    if (confirmed) {
      PlanetApi.approvePlanetOrder(orderId).then(() => {
        setOrders(
          orders.map(order => {
            if (order.ID === orderId) {
              return {...order, Status: 'active'};
            }
            return order;
          }) || []
        );
      });
    }
  };

  if (!isCorrectDev) {
    return (
      <div className="admin__container order-high-resolution-imagery">
        <div className="header-part-container">
          <h3>
            This feature is in progress and allowed only on Dev5. <br />
            Only back-end of the dev5 won't create real orders.
          </h3>
        </div>
      </div>
    );
  }

  return (
    <AdminContainer className="order-high-resolution-imagery">
      <div className="header-part-container">
        {newOrderView ? (
          <Button className={'return-btn'} onClick={onBackToTableView} primary icon>
            arrow_back
          </Button>
        ) : (
          <>
            <AdminTitle>Order high resolution imagery</AdminTitle>

            <Button
              className={'new-order-btn'}
              raised
              primary
              onClick={() => toggleNewOrderView(true)}
            >
              New order
            </Button>
          </>
        )}
      </div>
      {newOrderView ? (
        <NewOrderView onAddNewOrder={onAddNewOrder} onBackToTableView={onBackToTableView} />
      ) : (
        <OrdersTable onApproveOrder={onApproveOrder} orders={orders} />
      )}
    </AdminContainer>
  );
};

export default OrderPlanetImagery;
