import {CropPerformanceState, cropStatusWeight, CSGViewModel} from '../types';
import {PeriodicData} from './periodic';

export type CropVarietyGroup = {
  cropType: CSGViewModel['cropType'];
  cropVariety: CSGViewModel['cropVariety'];
  records: CSGViewModel[];
};

/**
 * @param csgs
 * @param date YYYYMMDD
 */
export const reduceCsgsForDate = (
  farms: CropPerformanceState['farms'],
  date: string
): CSGViewModel[] => {
  const csgs: CSGViewModel[] = [];
  Object.values(farms).forEach(farm => {
    if (farm.csgs) {
      Object.keys(farm.csgs).forEach(fieldId => {
        Object.keys(farm.csgs[Number(fieldId)].seasons).forEach(seasonId => {
          csgs.push(farm.csgs[Number(fieldId)].seasons[seasonId].dates[date]);
        });
      });
    }
  });
  return csgs.filter(x => x);
};

/**
 * 1. Group records by crop variety
 * 2. Sort groups by crop variety
 * 3. Sort records inside groups
 */
export const groupAndSortRecords = (
  records: CSGViewModel[],
  periodic: PeriodicData | undefined,
  sortType: keyof CSGViewModel | 'periodic',
  sortOrder: boolean
) => {
  // Key is `${s.cropType} ${s.cropVariety}`
  const recordsGroupedByCropTypeVariety: {[key: string]: CropVarietyGroup} = {};

  // 1. Create groups.
  records.forEach(r => {
    const cropTypeVariety = `${r.cropType}_${r.cropVariety}`;
    if (!recordsGroupedByCropTypeVariety[cropTypeVariety]) {
      recordsGroupedByCropTypeVariety[cropTypeVariety] = {
        cropType: r.cropType,
        cropVariety: r.cropVariety,
        records: [],
      };
    }
    recordsGroupedByCropTypeVariety[cropTypeVariety].records.push(r);
  });

  // 2. Sort groups by crop variety.
  const sortedCropVarietyGroups = Object.entries(recordsGroupedByCropTypeVariety)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([cropTypeVariety, cropVarietyGroup]) => cropVarietyGroup);

  // 3. Sort records inside groups.
  sortRecordsMutation(sortedCropVarietyGroups, periodic, sortType, sortOrder);

  return sortedCropVarietyGroups;
};

/**
 * Mutates groups by sorting their records.
 */
const sortRecordsMutation = (
  groups: CropVarietyGroup[],
  periodic: PeriodicData | undefined,
  sortType: keyof CSGViewModel | 'periodic',
  sortOrder: boolean
) => {
  groups.forEach(g => {
    g.records.sort((a_, b_) => {
      let a;
      let b;

      // 1. Extract values to compare.
      if (sortType === 'periodic') {
        a = periodic?.diffs[a_.fieldID] || 0;
        b = periodic?.diffs[b_.fieldID] || 0;
      } else if (sortType === 'cropType') {
        // In case of crop type, sort by planting date,
        // because the crop type will be the same due to grouping.
        a = a_.startDate || 0;
        b = b_.startDate || 0;
      } else {
        a = a_[sortType] || 0;
        b = b_[sortType] || 0;
      }
      if (sortType === 'cropStatus') {
        a = cropStatusWeight[a_[sortType]];
        b = cropStatusWeight[b_[sortType]];
      }

      // 2. Actually compare the values.
      if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
      }
      if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
      }
    });

    if (!sortOrder) {
      g.records.reverse();
    }
  });
};
