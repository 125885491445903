import * as React from 'react';
import {memo} from 'react';
import styled from 'styled-components';
import {AppStore} from 'reducers';
import {OptisType, Tillage, tillageLabels, winterCropLabels} from '../optis-types';
import {
  calcTotalAndPct,
  formatNumber,
  getTimePeriod,
  getCategories,
  sumTillage,
  sumTillageAcrossYears,
  sumWinterCrop,
  sumWinterCropAcrossYears,
  diffPct,
} from '../optis-utils';
import {Popup} from 'react-leaflet';
import {centroid} from '@turf/turf';

export const OptisPopup = memo(
  ({
    geometry,
    content,
    onClose,
  }: {
    geometry: GeoJSON.Feature;
    content: JSX.Element;
    onClose: () => void;
  }) => {
    if (!geometry) {
      return null;
    }
    // @ts-ignore turf.GeoJSON is a bit different than GeoJSON in its type definition.
    const position = centroid(geometry)?.geometry.coordinates?.reverse() as [number, number];
    return (
      <Popup
        position={position}
        className={'map-popup-optis'}
        // autoPanPaddingBottomRight={[420, 20]}
        // autoPanPaddingTopLeft={[60, 10]}
        onClose={onClose}
      >
        {content}
      </Popup>
    );
  }
);

export const OptisPopupContent = ({
  id,
  name,
  optis,
  onMouseEnter,
  onMouseOut,
}: {
  id: number;
  name: string;
  optis: AppStore['optis'];
  onMouseEnter: () => void;
  onMouseOut: () => void;
}) => {
  const timePeriod = getTimePeriod(optis.filter.years);
  const {verb, categories} = getCategories(optis.filter, optis.filter.type);
  const values = calcTotalAndPct(optis, id);
  return (
    <PopupContentStyled onMouseEnter={onMouseEnter} onMouseOut={onMouseOut}>
      {timePeriod}, there was <b>{values.pct}%</b> of {formatNumber(values.total)} acres in{' '}
      {optis.areaType} {name} {verb} <b>{categories}</b>
    </PopupContentStyled>
  );
};

export const OptisDiffPopupContent = ({
  id,
  name,
  optis,
  onMouseEnter,
  onMouseOut,
}: {
  id: number;
  name: string;
  optis: AppStore['optis'];
  onMouseEnter: () => void;
  onMouseOut: () => void;
}) => {
  const timePeriod = getTimePeriod([optis.diffYearA, optis.diffYearB]);
  const {categories} = getCategories(optis.filter, optis.filter.type);
  const pct = diffPct(optis, id);
  const sign = pct < 0 ? '' : '+';
  const color = pct < 0 ? 'red' : 'green';
  return (
    <PopupContentStyled onMouseEnter={onMouseEnter} onMouseOut={onMouseOut}>
      {timePeriod}, the number of ac with <b>{categories}</b> has changed by{' '}
      <b style={{color}}>
        {sign}
        {pct}%
      </b>
    </PopupContentStyled>
  );
};

const PopupContentStyled = styled.div`
  padding: 20px;
`;
