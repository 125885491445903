import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {Avatar, AvatarProps} from 'react-md';
import {cropId2FirstChar, cropId2IconPath} from '_utils';
import cn from 'classnames';
import './index.scss';

type Props = AvatarProps & {cropType: string; iconSrc?: string; letter?: string};

export const CropAvatar = ({className = '', cropType, iconSrc, letter, ...otherProps}: Props) => {
  const src = iconSrc || cropId2IconPath(cropType);
  const cropLetter = letter || cropId2FirstChar(cropType) || '?';

  return (
    <Avatar
      {...otherProps}
      className={cn(`fluro-crop-avatar ${className}`, {
        bordered: !src && !otherProps.icon,
      })}
      suffix="white"
      src={src}
    >
      {cropLetter}
    </Avatar>
  );
};

/**
 * Can be used in storybook.
 *
 * <CropAvatarLite
 *   cropType="cotton"
 *   iconSrc="/assets/crops/cotton.png"
 *   letter="c"
 *  />
 */
export const CropAvatarLite = ({
  className = '',
  cropType,
  iconSrc,
  letter,
  ...otherProps
}: Props) => {
  return (
    <Avatar
      {...otherProps}
      className={cn(`fluro-crop-avatar ${className}`, {
        bordered: !iconSrc && !otherProps.icon,
      })}
      suffix="white"
      src={iconSrc}
    >
      {letter}
    </Avatar>
  );
};

export default CropAvatar;
