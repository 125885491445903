import * as React from 'react';
import {FontIcon, TextField} from 'react-md';
import cn from 'classnames';
import {genKey, getNodeText, onClickOutsideAndEsc} from '_utils/pure-utils';
import './fluro-select-lite.scss';
import {ReactElement, ReactNode, useState} from 'react';
import {useMemo} from 'react';
import {Waypoint} from 'react-waypoint';
import {valueContainerCSS} from 'react-select/src/components/containers';

export type FluroSelectLiteProps = {
  items: FluroSelectLiteItem[];
  selectedItem: FluroSelectLiteItem['value'];
  placeholder?: string;
  subtitle?: string;
  Button?: React.FunctionComponent<{onClick: () => void}>;
  onSelect: (value: FluroSelectLiteItem['value']) => void;
  isSearchable?: boolean;
  searchPlaceholderText?: string;
  disabled?: boolean;
  helpComponent?: ReactElement;
  selectedItemComponent?: {value: any; label: ReactNode};
};
export const FluroSelectLite = ({
  items,
  selectedItem,
  selectedItemComponent,
  placeholder,
  subtitle,
  Button,
  onSelect,
  isSearchable = false,
  searchPlaceholderText = 'Search',
  disabled,
  helpComponent,
}: FluroSelectLiteProps) => {
  const id = useMemo(() => genKey(), []);
  const dropdownRef = React.useRef();
  const [expanded, setExpanded] = React.useState(false);
  const [searchText, setSearchText] = useState<string | undefined>('');
  const [pagination, setPagination] = useState(50);

  const close = React.useCallback(() => setExpanded(false), []);

  React.useEffect(() => {
    setPagination(50);
    return onClickOutsideAndEsc(dropdownRef, expanded, close);
  }, [expanded]);

  const onSelectItem = React.useCallback(
    (value: FluroSelectLiteItem['value']) => {
      onSelect(value);
      close();
    },
    [close]
  );

  const placeholderItem = {
    value: 'unselected',
    label: placeholder,
  };

  const menuItems = items.filter(item => item.value !== selectedItem);
  const isDisabled = disabled || menuItems.length === 0;

  const filteredMenuItems = useMemo(() => {
    if (isSearchable && searchText) {
      const query = searchText.toLowerCase();
      return menuItems
        .filter(item =>
          (typeof item.label === 'string'
            ? item.label?.toLowerCase()
            : getNodeText(item.label)
          )?.includes(query)
        )
        .slice(0, pagination);
    }
    return menuItems.slice(0, pagination);
  }, [menuItems, isSearchable, searchText, pagination]);

  const loadMore = () => {
    setPagination(pagination + 50);
  };

  return (
    <div ref={dropdownRef} className={cn('fluro-select-lite', {isDisabled})}>
      <div>
        {Button ? (
          <Button onClick={() => !isDisabled && setExpanded(e => !e)} />
        ) : (
          <FluroSelectLiteItemView
            item={
              selectedItemComponent ||
              items.find(item => item.value === selectedItem) ||
              placeholderItem
            }
            subtitle={subtitle}
            rightIcon={
              helpComponent ? (
                <div className="right-icon-block">
                  {helpComponent} <FontIcon>arrow_drop_down</FontIcon>
                </div>
              ) : (
                <FontIcon className="right-icon">arrow_drop_down</FontIcon>
              )
            }
            disabled={isDisabled}
            onClick={() => !isDisabled && setExpanded(e => !e)}
          />
        )}
      </div>
      {expanded && (
        <div className={cn('list', {searchable: isSearchable})}>
          {isSearchable && (
            <div className="fluro-select-lite__search-container">
              <TextField
                id={`search-input-${id}`}
                placeholder={searchPlaceholderText}
                value={searchText}
                onChange={v => setSearchText(String(v))}
                inlineIndicator={
                  <FontIcon className="clear-search-btn" onClick={() => setSearchText('')}>
                    clear
                  </FontIcon>
                }
              />
            </div>
          )}
          {filteredMenuItems.map(item => (
            <FluroSelectLiteItemView
              key={item.value}
              item={item}
              onClick={(v, e) => {
                e.stopPropagation();
                onSelectItem(v);
              }}
            />
          ))}
          {menuItems.length > pagination && <Waypoint key="lazy-load" onEnter={loadMore} />}
        </div>
      )}
    </div>
  );
};

export type FluroSelectLiteItem = {
  value: any;
  label: string | ReactNode;
  icon?: string | JSX.Element;
};

export const FluroSelectLiteItemView = ({
  item,
  subtitle,
  rightIcon,
  disabled,
  onClick,
}: {
  item: FluroSelectLiteItem;
  subtitle?: string;
  rightIcon?: JSX.Element;
  disabled?: boolean;
  onClick?: (value: FluroSelectLiteItem['value'], e: React.MouseEvent) => void;
}) => {
  return (
    <div className={cn('fluro-select-lite-item', {disabled})} onClick={e => onClick(item.value, e)}>
      <div className="main">
        {item.icon}
        <div className="label">
          <span className="title">{item.label}</span>
          <span className="subtitle">{subtitle}</span>
        </div>
      </div>
      {rightIcon}
    </div>
  );
};

export const ColoredCircle = ({color}: {color: string}) => {
  return <div className="colored-circle" style={{backgroundColor: color}} />;
};
