import axios from 'axios';
import {v1 as uuidv1} from 'uuid';
import {t} from 'i18n-utils';
import _config from '_environment';
import {showNote} from '../_actions';
import {responseWarningMessage} from './messages';
import store from 'store';
import {requestLoading, responseLoading, responseUnathorized401} from './base';
import {isLocalhost} from '_utils';
import Mixpanel from '_utils/mixpanel-utils';
import {getAdditionalHeaders} from './api-utils';

const {baseUrl, authHeader} = _config;

if (!isLocalhost()) {
  // Add version header to each request (FSB-2932).
  axios.interceptors.request.use(config => {
    config.headers['x-fs-app-version'] = _config.app_version;
    return config;
  });
}

// add X-Request-ID, X-Correlation-ID headers with the same uniq value for each request, FSB-5053
// axios.interceptors.request.use(config => {
//   const uniqKey = Date.now() + Math.random().toString(36);
//   config.headers['x-request-id'] = uniqKey;
//   config.headers['x-correlation-id'] = uniqKey;
//   return config;
// });

axios.interceptors.request.use(requestLoading, function (error) {
  return Promise.reject(error);
});
axios.interceptors.response.use(responseLoading, function (error) {
  return Promise.reject(responseLoading(error));
});

// add speed interceptor

axios.interceptors.request.use(onUploadProgress, function (error) {
  return Promise.reject(error);
});

// ---

const Server = axios.create({
  baseURL: `${baseUrl}api/v1/`,
  validateStatus: function (status) {
    return status < 500;
  },
  headers: {
    [authHeader]: localStorage.getItem('token') || '',
    // Add version header to each request (FSB-2932).
    ...(!isLocalhost() ? {'x-fs-app-version': _config.app_version} : {}),
    ...getAdditionalHeaders(),
  },
});

Server.interceptors.request.use(requestLoading);
Server.interceptors.response.use(responseLoading, function (error) {
  return Promise.reject(responseLoading(error));
});
Server.interceptors.response.use(responseUnathorized401, function (error) {
  return Promise.reject(responseUnathorized401(error));
});

Server.interceptors.request.use(onUploadProgress, function (error) {
  return Promise.reject(error);
});

Server.interceptors.request.use(
  function (config) {
    const uuid = uuidv1();
    config.headers['X-Request-ID'] = uuid;
    config.headers['X-Correlation-ID'] = uuid;
    config.headers[authHeader] = localStorage.getItem('token') || '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const skipErrorUrlPatterns = [
  'tree_detection',
  'sync/agworld/growers', // we do this request also just to check if user is authorized
  // 'emails/to_review'
  'crop_performance', // multifarm crop perf can be requested outside the seasons, we handle it in ui
  'sync/climate', // this is request to get climate farms, if it returns 404 - it means the user is unauthorized
  'sync/efc/growers', // tries to get EFC growers by current user. returns 404 if the user is not added to EFC
  'syncv2/agx', // this is request to sync or get agx growers, it has it's own error handlers so shouldn't display the default error message
  'sync/johndeere/organizations', // returns 400 if the current user is not connected
];
const shouldSkipError = (url: string) => {
  return skipErrorUrlPatterns.some(p => url.includes(p));
};
Server.interceptors.response.use(
  response => {
    if (response.data && response.status >= 400 && response.status !== 401) {
      if (responseWarningMessage(response.data.result)) {
        const errorMessage = responseWarningMessage(response.data.result);
        store.dispatch(
          showNote({
            level: 'error',
            ...errorMessage,
          })
        );
        Mixpanel.errorMessage(errorMessage.message, response);
      } else if (!shouldSkipError(response.request.responseURL)) {
        Mixpanel.errorMessage('An error occurred, try to reload the page.', response);
        store.dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: t({
              id: 'errorTryReloadPage',
              defaultMessage: 'An error occurred, try to reload the page.',
            }),
            level: 'warning',
          })
        );
        console.error(
          'REQUEST ERR | status=',
          response.status,
          '| status text=',
          response.statusText || 'NONE',
          '| response message=',
          `${response.data ? response.data.result : 'NONE'}`
        );
      }

      return Promise.reject(response);
    }
    return response;
  },
  error => Promise.reject(error)
);

export default Server;

function onUploadProgress(config: any) {
  if (!config.onUploadProgress) return config;

  const oldProgress = config.onUploadProgress;
  config.uploaded = 0;
  config.lastUpTime = 0;

  config.onUploadProgress = function (ev: any) {
    const endTime = new Date().getTime();
    const uploadSpeed = (
      ((ev.loaded - config.uploaded) * 1000) /
      ((endTime - config.lastUpTime) * 1024)
    ).toFixed(2);
    config.uploaded = ev.loaded;
    config.lastUpTime = endTime;
    ev.fluroconfig = config;
    return oldProgress(ev, uploadSpeed);
  };

  return config;
}
