import React, {useCallback, useMemo} from 'react';
import {GeoJSON} from 'react-leaflet';
import {useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {AsyncStatusType, Status} from 'modules/ui-helpers';

export const ProductivityMapOverlay = () => {
  const isOn = useSelector((state: AppStore) => state.productivityMap.isOn);
  const currentCacheKey = useSelector((state: AppStore) => state.productivityMap.currentCacheKey);
  const data = useSelector(
    (state: AppStore) => state.productivityMap.data[state.productivityMap.currentCacheKey]
  );
  const isLoading = useSelector(
    (state: AppStore) =>
      state.uiHelpers.asyncStatuses[AsyncStatusType.productivityMap].status === Status.Pending
  );

  const onEachFeature = useCallback((feature: any, layer: any) => {
    layer.setStyle({
      stroke: false,
      fillColor: feature.properties.color,
      fill: true,
      fillOpacity: 1,
    });
  }, []);

  return isOn && data && !isLoading ? (
    <GeoJSON key={currentCacheKey} data={data.geoJsonData} onEachFeature={onEachFeature} />
  ) : null;
};

export default ProductivityMapOverlay;
