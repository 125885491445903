import React from 'react';
import {useState} from 'react';
import cn from 'classnames';
import './fluro-steps.scss';
import {ResizeObserver} from 'react-md/lib/Helpers';
import {FontIcon} from 'react-md';

export const FluroSteps = ({
  items,
  selectedItem,
  itemWidth = 150,
  containerClassName = '',
  onItemClick,
  greenSkin = false,
}: {
  containerClassName?: string;
  onItemClick(value: string): void;
  itemWidth?: number;
  selectedItem: string;
  greenSkin?: boolean;
  items: {
    label: any;
    value: string;
  }[];
}) => {
  const [sizes, setSizes] = useState(items.map(i => 0));
  const selectedIndex = items.findIndex(item => item.value === selectedItem);
  const onResize = (size: {width: number; height: number}, i: number) => {
    setSizes(sizes => {
      const newSizes = [...sizes];
      newSizes[i] = size.width;
      return newSizes;
    });
  };
  return (
    <div
      className={cn('fluro-steps-container', {
        [containerClassName]: !!containerClassName,
        greenSkin,
      })}
    >
      {items.map((item, i) => {
        const lineWidth = sizes[i - 1] / 2 + sizes[i] / 2 - iconSize + margin;
        const itemIsDone = selectedIndex > i;
        const itemIsActive = selectedItem === item.value;
        return (
          <div
            key={item.value}
            onClick={() => onItemClick(item.value)}
            className={cn('item', {active: itemIsActive, done: itemIsDone})}
            style={{marginLeft: i > 0 ? margin : 0}}
            // style={{width: itemWidth}}
          >
            {i > 0 && <span className="line" style={{width: lineWidth}} />}
            <span
              className={cn('icon', {fixStrangeIssue: i === 1})}
              style={{width: iconSize, height: iconSize}}
            >
              {(itemIsDone || itemIsActive) &&
                (itemIsDone ? <FontIcon>check</FontIcon> : <div className="active-icon" />)}
            </span>
            {item.label}
            <ResizeObserver watchWidth onResize={size => onResize(size, i)} />
          </div>
        );
      })}
    </div>
  );
};
const margin = 20;
const iconSize = 20;
export default FluroSteps;
