import Server from './server';
import {IAnomaly} from "../containers/map/features/anomalies/types";

export default class {
  static getAreasOfInterest(farmId: number, fieldId: number | string) {
    return Server.get(`/aoi/${farmId}/${fieldId}`);
  }

  static creatAreaOfInterest(farmId: number, fieldId: number | string, areaOfInterest: any) {
    return Server.post(`/aoi/${farmId}/${fieldId}`, areaOfInterest);
  }

  static updateAreaOfInterest = (anomalyId: number, areaOfInterest: IAnomaly) => {
    return Server.put(`/aoi/${anomalyId}`, areaOfInterest);
  };

  static removeAreaOfInterest = (areaOfInterestId: number) => {
    return Server.delete(`/aoi/${areaOfInterestId}`);
  };
}
