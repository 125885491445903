import * as React from 'react';
import {FormattedMessage} from 'i18n-utils';
import {InfoBlock} from 'components';
import {Switch} from 'react-md';
import {useMemo} from 'react';

export const UnreliableData = ({
  unreliableCount,
  totalCount,
  useUnreliableData,
  toggleUseUnreliableData,
}: {
  unreliableCount: number;
  totalCount: number;
  useUnreliableData: boolean;
  toggleUseUnreliableData: () => void;
}) => {
  const percent = useMemo(() => Math.round((unreliableCount / totalCount) * 100), [
    unreliableCount,
    totalCount,
  ]);

  return (
    <InfoBlock
      icon={
        <Switch
          id="toggle-compare"
          label=""
          name="toggle-compare"
          onChange={toggleUseUnreliableData}
          checked={useUnreliableData}
        />
      }
    >
      <FormattedMessage
        id="percent cloudy imagery over the last 10 days"
        defaultMessage="{percent}% of your fields had ☁️ cloudy imagery over the last 10 days."
        values={{percent}}
      />{' '}
      {useUnreliableData ? (
        <FormattedMessage
          id="[cp] included in the report"
          defaultMessage="They're <b>included</b> in the report."
          values={{
            b: (txt: string) => <b>{txt}</b>,
          }}
        />
      ) : (
        <FormattedMessage
          id="[cp] excluded from the report"
          defaultMessage="They're <b>excluded</b> from the report."
          values={{
            b: (txt: string) => <b>{txt}</b>,
          }}
        />
      )}
    </InfoBlock>
  );
};
