import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {AgworldApi, AgxApi, ClimateApi, EFCApi, JohnDeereApi} from '../../../_api';
import {setGlobalParam, showNote} from '../../../_actions';
import {Dispatch} from 'redux';
import {ShowNote} from '../../../components/alert/reducer';
import {RequestStatus} from '../../../types';
import {reportError} from '../../error-boundary';
import {getFarmsList, selectFarm} from '../../farm/actions';
import {contactSupportTeam, sortByStringKey} from '_utils';
import {AppStore} from '../../../reducers';
import {ActionType} from './reducer';
import {ExternalService} from '../../map/types';
import {Farm} from '../../farm/types';
import {classifyHighestEntity, EFCSyncRequestPayload} from './integration-efc';
import {classifyJohnDeereHighestEntity, JohnDeereRequestPayload} from './integration-john-deere';
import {IntegrationPlatform, IntegrationsState} from './types';
import {classifyAgworldHighestEntity, AgworldSyncRequestPayload} from './integration-agworld';

export type Action =
  | AgworldSyncGrowers
  | AgxSyncGrowers
  | AgxToggleFarmsImported
  | ClimateImportFields
  | EFCImportGrowers
  | JohnDeereImportGrowers
  | ToggleSelectPlatformsView
  | TogglePlatform;

type AgworldSyncGrowers = {
  type: ActionType.AGWORLD_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type EFCImportGrowers = {
  type: ActionType.EFC_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type AgxSyncGrowers = {
  type: ActionType.AGX_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type AgxToggleFarmsImported = {
  type: ActionType.AGX_TOGGLE_FARMS_IMPORTED;
  farmsImported: boolean;
};

type ClimateImportFields = {
  type: ActionType.CLIMATE_SYNC_FIELDS;
  syncStatus: RequestStatus;
};

type JohnDeereImportGrowers = {
  type: ActionType.JOHN_DEERE_SYNC_GROWERS;
  syncStatus: RequestStatus;
};

type ToggleSelectPlatformsView = {
  type: ActionType.TOGGLE_SELECT_PLATFORMS_VIEW;
  value: boolean;
};

type TogglePlatform = {
  type: ActionType.TOGGLE_PLATFORM_SELECTION;
  platform: IntegrationPlatform;
  value: boolean;
};

export const toggleSelectPlatformsView = (value: boolean) => ({
  type: ActionType.TOGGLE_SELECT_PLATFORMS_VIEW,
  value,
});

export const togglePlatform = (platform: IntegrationPlatform, value: boolean) => ({
  type: ActionType.TOGGLE_PLATFORM_SELECTION,
  platform,
  value,
});

export const importAgworldGrowers = (payload: AgworldSyncRequestPayload) => (dispatch: any) => {
  dispatch(
    showNote({
      title: t({id: 'note.info', defaultMessage: 'Info'}),
      message: t({id: 'agWorldImportStarted'}),
      level: 'info',
      autoDismiss: 10,
    })
  );
  dispatch({
    type: ActionType.AGWORLD_SYNC_GROWERS,
    syncStatus: RequestStatus.Loading,
  });

  const highestEntity = classifyAgworldHighestEntity(payload);
  AgworldApi.syncGrowers(payload)
    .then(({data}) => {
      if (data.data.status === 'ready') {
        dispatch(checkAgworldImportStatus(data.data.id, highestEntity));
      }
    })
    .catch(e => {
      reportError(`Couldn't sync Agworld growers. Error: ${e}`);
      dispatch(
        showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'agWorldSyncErr', defaultMessage: "Couldn't sync Agworld growers."}),
          level: 'error',
          autoDismiss: 60,
        })
      );
      dispatch({
        type: ActionType.AGWORLD_SYNC_GROWERS,
        syncStatus: RequestStatus.Error,
      });
    });
};

const checkAgworldImportStatus = (jobId: number, highestEntity: string) => (dispatch: any) => {
  return AgworldApi.getImportStatus(jobId)
    .then(({data}) => {
      if (data.data.status === 'succeeded') {
        // set request status
        dispatch({
          type: ActionType.AGWORLD_SYNC_GROWERS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewGroups(ExternalService.Agworld));
        // show a success message
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({
              id: 'agWorldSyncDoneOk',
              defaultMessage: `Agworld growers synchronized successfully!\n
              Check the Farm dropdown in the top left of the screen.`,
            }),
            level: 'success',
            autoDismiss: 60,
          })
        );
      } else if (['ready', 'importing'].includes(data.data.status)) {
        setTimeout(() => dispatch(checkAgworldImportStatus(jobId, highestEntity)), 5000);
      } else if (data.data.status === 'failed') {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(AgworldSyncError(`Sync status === ${data.data.status}`, highestEntity));
      }
    })
    .catch(err => dispatch(AgworldSyncError(err, highestEntity)));
};

const AgworldSyncError = (err: any, highestEntity: string) => (dispatch: any) => {
  dispatch({
    type: ActionType.AGWORLD_SYNC_GROWERS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`Agworld sync err, err= ${err}`);

  return dispatch(
    showNote({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      message: (
        <FormattedMessage
          id="agWorldSyncErr"
          values={{highestEntity, a: (txt: string) => contactSupportTeam(txt)}}
        />
      ),
      level: 'error',
    })
  );
};

export const loadNewGroups = (externalService: ExternalService) => (dispatch: any) => {
  dispatch(getFarmsList())
    .then((data: Farm[]) => {
      const sortedGroupsBySource = sortByStringKey(
        data.filter(farm => (farm.external_service || farm.srcType) === externalService),
        'name'
      );
      const newGroupId = sortedGroupsBySource.length ? sortedGroupsBySource[0].id : data[0].id;
      dispatch(selectFarm(newGroupId));
    })
    .catch((e: any) => {
      reportError(`Error loading FluroSense farms during integration sync: ` + e);
    });
};

export const syncAgxGrowersV2 = (payload: {
  fieldIds: string[];
  farmIds: string[];
  growerIds: string[];
}) => (dispatch: any, getState: () => AppStore) => {
  dispatch(
    showNote({
      title: t({id: 'note.info', defaultMessage: 'Info'}),
      message: t({id: 'agxImportStarted'}),
      level: 'info',
      autoDismiss: 10,
    })
  );
  dispatch({
    type: ActionType.AGX_SYNC_GROWERS,
    syncStatus: RequestStatus.Loading,
  });
  return AgxApi.syncV2(payload)
    .then(({data}) => {
      dispatch(loadNewGroups(ExternalService.Agx));

      dispatch(agxGrowersSynced());
      dispatch({
        type: ActionType.AGX_TOGGLE_FARMS_IMPORTED,
        farmsImported: true,
      });
    })
    .catch(() => {
      AgxApi.syncWasDone();

      dispatch(
        showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'agxSyncErr'}),
          level: 'error',
          autoDismiss: 60,
        })
      );
      dispatch({
        type: ActionType.AGX_SYNC_GROWERS,
        syncStatus: RequestStatus.Error,
      });
    });
};

export const agxGrowersSynced = () => (dispatch: Dispatch<ShowNote | AgxSyncGrowers>) => {
  AgxApi.syncWasDone();

  dispatch(
    showNote({
      title: t({id: 'note.success', defaultMessage: 'Success'}),
      message: t({id: 'agxGrowersSyncOk'}),
      level: 'success',
      autoDismiss: 60,
    })
  );
  dispatch({
    type: ActionType.AGX_SYNC_GROWERS,
    syncStatus: RequestStatus.Success,
  });
};

export const syncClimateFields = (fieldIds: string[]) => (dispatch: any) => {
  dispatch({
    type: ActionType.CLIMATE_SYNC_FIELDS,
    syncStatus: RequestStatus.Loading,
  });

  ClimateApi.syncFields(fieldIds)
    .then(({data}: any) => {
      if (data.status === 'accepted' && data.result.job_id) {
        dispatch(
          showNote({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({id: 'climateFieldsImportStarted'}),
            level: 'info',
          })
        );
        dispatch(checkClimateStatus(data.result.job_id));
      }
    })
    .catch(err => {
      dispatch(climateSyncError(err));
    });
};

const checkClimateStatus = (jobId: string) => (dispatch: any) => {
  return ClimateApi.getSyncStatus(jobId)
    .then(({data}) => {
      if (data.result.status === 'done') {
        // set request status
        dispatch({
          type: ActionType.CLIMATE_SYNC_FIELDS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewGroups(ExternalService.Climate));
        // show a success message
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'climateFieldsImportOk'}),
            level: 'success',
            autoDismiss: 60,
          })
        );
      } else if (data.result.status === 'processing') {
        setTimeout(() => dispatch(checkClimateStatus(jobId)), 5000);
      } else {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(climateSyncError(`Sync status === ${data.result.status}`));
      }
    })
    .catch(err => dispatch(climateSyncError(err)));
};

const climateSyncError = (err: any) => (dispatch: any) => {
  dispatch({
    type: ActionType.CLIMATE_SYNC_FIELDS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`Climate sync err, err= ${err}`);

  return dispatch(
    showNote({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      message: (
        <FormattedMessage
          id="climateFieldsImportErr"
          values={{a: (txt: string) => contactSupportTeam(txt)}}
        />
      ),
      level: 'error',
    })
  );
};

export const syncEFCGrowers = (payload: EFCSyncRequestPayload) => (dispatch: any) => {
  dispatch({
    type: ActionType.EFC_SYNC_GROWERS,
    syncStatus: RequestStatus.Loading,
  });

  EFCApi.syncGrowers(payload)
    .then(({data}: any) => {
      const highestEntity = classifyHighestEntity(payload);
      if (data.job_id) {
        dispatch(
          showNote({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({id: 'efcGrowersImportStarted'}, {highestEntity}),
            level: 'info',
          })
        );
        dispatch(checkEFCStatus(data.job_id, highestEntity));
      }
    })
    .catch(err => {
      dispatch(EFCSyncError(err));
    });
};

const checkEFCStatus = (jobId: string, highestEntity: string) => (dispatch: any) => {
  return EFCApi.getSyncStatus(jobId)
    .then(({data}) => {
      if (data.status === 'done') {
        // set request status
        dispatch({
          type: ActionType.EFC_SYNC_GROWERS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewGroups(ExternalService.EFC));
        // show a success message
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'efcGrowersImportOk'}, {highestEntity}),
            level: 'success',
            autoDismiss: 60,
          })
        );
      } else if (data.status === 'processing') {
        setTimeout(() => dispatch(checkEFCStatus(jobId, highestEntity)), 5000);
      } else {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(EFCSyncError(`Sync status === ${data.status}`));
      }
    })
    .catch(err => dispatch(EFCSyncError(err)));
};

const EFCSyncError = (err: any) => (dispatch: any) => {
  dispatch({
    type: ActionType.EFC_SYNC_GROWERS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`EFC sync err, err= ${err}`);

  return dispatch(
    showNote({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      message: (
        <FormattedMessage
          id="efcGrowersImportErr"
          values={{a: (txt: string) => contactSupportTeam(txt)}}
        />
      ),
      level: 'error',
    })
  );
};

export const connectEfcUsers = (userIds: number[], selectedPartner: string) => (dispatch: any) => {
  return EFCApi.connectUsers(userIds, selectedPartner).catch(() => {
    dispatch(
      showNote({
        title: t({id: 'note.error'}),
        message: t({id: 'errorTryReloadPage'}),
        level: 'error',
      })
    );
    return false;
  });
};

export const disconnectEfcUsers = (userIds: number[]) => (dispatch: any) => {
  return EFCApi.disconnectUsers(userIds).catch(() => {
    dispatch(
      showNote({
        title: t({id: 'note.error'}),
        message: t({id: 'errorTryReloadPage'}),
        level: 'error',
      })
    );
    return false;
  });
};

// John Deere

export const syncJohnDeereOrganizations = (payload: JohnDeereRequestPayload) => (dispatch: any) => {
  dispatch({
    type: ActionType.JOHN_DEERE_SYNC_GROWERS,
    syncStatus: RequestStatus.Loading,
  });

  const highestEntity = classifyJohnDeereHighestEntity(payload);
  JohnDeereApi.importData(payload)
    .then(({data}: any) => {
      if (data.data.status === 'ready') {
        dispatch(
          showNote({
            title: t({id: 'note.info', defaultMessage: 'Info'}),
            message: t({id: 'johnDeereImportStarted'}, {highestEntity}),
            level: 'info',
          })
        );
        dispatch(checkJohnDeereImportStatus(data.data.id, highestEntity));
      }
    })
    .catch(err => {
      dispatch(JohnDeereSyncError(err, highestEntity));
    });
};

const checkJohnDeereImportStatus = (jobId: number, highestEntity: string) => (dispatch: any) => {
  return JohnDeereApi.getImportStatus(jobId)
    .then(({data}) => {
      if (data.data.status === 'succeeded') {
        // set request status
        dispatch({
          type: ActionType.JOHN_DEERE_SYNC_GROWERS,
          syncStatus: RequestStatus.Success,
        });
        // load new farms
        dispatch(loadNewGroups(ExternalService.JohnDeere));
        // show a success message
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: t({id: 'johnDeereImportOk'}, {highestEntity}),
            level: 'success',
            autoDismiss: 60,
          })
        );
      } else if (['ready', 'importing'].includes(data.data.status)) {
        setTimeout(() => dispatch(checkJohnDeereImportStatus(jobId, highestEntity)), 5000);
      } else if (data.data.status === 'failed') {
        // if status is not success and processing it means some issue happened (status can be "error" or "unknown id")
        dispatch(JohnDeereSyncError(`Sync status === ${data.data.status}`, highestEntity));
      }
    })
    .catch(err => dispatch(JohnDeereSyncError(err, highestEntity)));
};

const JohnDeereSyncError = (err: any, highestEntity: string) => (dispatch: any) => {
  dispatch({
    type: ActionType.JOHN_DEERE_SYNC_GROWERS,
    syncStatus: RequestStatus.Error,
  });

  reportError(`JD sync err, err= ${err}`);

  return dispatch(
    showNote({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      message: (
        <FormattedMessage
          id="johnDeereImportErr"
          values={{highestEntity, a: (txt: string) => contactSupportTeam(txt)}}
        />
      ),
      level: 'error',
    })
  );
};
