import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {formatDate, genKey} from '_utils';
import {SelectField, FontIcon} from 'react-md';
import {ImageDateSelectorWrapper} from './edit-cloudy.styled';
import {TypeIcon} from 'components/icons';
import moment from 'moment';
import {dialogToggle, DialogType} from 'modules/ui-helpers';

const ImageDateSelector = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const remoteSensingImages = useSelector((state: AppStore) => state.map.remoteSensingImages);

  const {payload} = useSelector(
    (state: AppStore) => state.uiHelpers.dialogs[DialogType.editCloudy]
  );

  const menuItems = useMemo(
    () =>
      remoteSensingImages.map((image: any) => ({
        label: (
          <div className="date-item">
            <TypeIcon type={image.Type} className={`custom-date__item ${image.Type}`} />
            {moment(image.Date).format(formatDate())}
          </div>
        ),
        value: image.Date,
      })),
    [remoteSensingImages]
  );

  return (
    <ImageDateSelectorWrapper>
      <FontIcon>date_range</FontIcon>
      <SelectField
        id={`select-image-date-${id}`}
        label={t({id: 'Imagery date'})}
        value={payload.Date}
        onChange={(date: string) => {
          dispatch(
            dialogToggle(
              DialogType.editCloudy,
              true,
              remoteSensingImages.find(im => im.Date === date)
            )
          );
        }}
        menuItems={menuItems}
        simplifiedMenu={true}
      />
    </ImageDateSelectorWrapper>
  );
};

export default ImageDateSelector;
