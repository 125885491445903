import {t} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import * as Leaflet from 'leaflet';
import {FluroImageOverlay} from 'components';
import {FieldTag, SourceType} from '../types';
import {checkFieldTagExist, getInitZones, getZoningUrlParams} from '../utils';
import {getLayerImageUrl} from '../utils/utils';
import {setNitrogenMarker, setTreeLayerType} from '../actions';
import NitrogenMarkersOverlay, {NitrogenPopup} from './nitrogen-markers-overlay';
import BinaryReader from '../binary-reader';
import {getGetURLParam, getPlantingAreaImageURL} from '_utils';
import ZoningPoint from '../features/zoning/zoning-points';
import get from 'lodash.get';
import {showNote} from '_actions';
import {defaultMarkerIcon} from '../icons';
import config from '_environment';
import box from '@turf/bbox';
import {AsyncStatusType, setRequestStatus, Status} from 'modules/ui-helpers';

import {Marker} from 'react-leaflet';
import {AppStore} from 'reducers';
import FieldSoilMapOverlay from './field-soil-map-overlay';

type Props = ConnectedProps<typeof connector> & {
  leafletElement: Leaflet.Map;
  originalImageOverlayRef: (ref: L.ImageOverlay) => void;
};

type State = {
  nitrogenPopupToggle: boolean;
  nitrogenPopupPosition: any;
  nitrogenValue: number;
};

class MainField extends PureComponent<Props, State> {
  nitrogenHandler: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      nitrogenPopupToggle: false,
      nitrogenPopupPosition: {x: 0, y: 0},
      nitrogenValue: 0.0,
    };
    this.nitrogenHandler = null;
  }

  getPlantingAreaImageURL = () => {
    const {field, currentDate, currentDates, currentSensor, currentSeason} = this.props;
    const date = currentDates[currentDate]?.[currentSensor]?.date;

    return date && currentDates && currentSensor && currentSeason?.geometry_id
      ? getPlantingAreaImageURL(
          config.baseUrl,
          currentSensor,
          field.MD5,
          date,
          currentSeason.geometry_id
        )
      : '';
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const {selectedFieldId, isTree, currentGroupId} = this.props;

    if (
      isTree &&
      ((selectedFieldId !== prevProps.selectedFieldId &&
        !checkFieldTagExist(FieldTag.AnomalyDetection) &&
        // FSB-4061 HACK: Unblocking Victor to test anomalies wihtout the field tag, but using the url flag
        // getGetURLParam('premium-anomalies')
        !getGetURLParam('premium-anomalies')) ||
        currentGroupId !== prevProps.currentGroupId)
    ) {
      this.props.setTreeLayerType('default');
    }
  }

  onError = () => {
    const isPlanetImage = this.props.currentDate?.endsWith(SourceType.SatelliteHD);

    !isPlanetImage &&
      this.props.showNote({
        title: t({id: 'note.error', defaultMessage: 'Error'}),
        message: 'An error occurred, please try again later',
        level: 'error',
      });
  };

  prepareCustom = () => {
    const {zonesRange, zones, method} = this.props.zoning;
    return method === 'custom'
      ? !zonesRange.length
        ? getInitZones(this.props.zoning)
        : `b=${zones
            .filter((z, i: number) => i)
            .map(z => z.min)
            .join(',')}&`
      : '';
  };

  canCustomZoning = () => {
    const {zonesRange, zones} = this.props.zoning;
    return zonesRange.length === 2 && zones.length && zones[zones.length - 1].max <= zonesRange[1];
  };

  pixelHandler = (f: any) => {
    this.nitrogenHandler = f;
  };

  getSelectedFields = () => {
    const {fieldGeometries, fields, selectedFieldId} = this.props;
    return fields.find(f => f.ID === selectedFieldId)
      ? []
      : fields.filter(f => f._selected && fieldGeometries[f.MD5] && f.ID !== selectedFieldId);
  };

  getTooltipValue = (e: any) => {
    if (this.nitrogenHandler) {
      const overlayImage = e.sourceTarget.getElement();
      const imgRect = overlayImage.getBoundingClientRect();

      // Mapping client image pixels to original image pixels
      const yR = overlayImage.clientHeight / overlayImage.naturalHeight;
      const xR = overlayImage.clientWidth / overlayImage.naturalWidth;
      const x = (e.originalEvent.clientX - imgRect.left) / xR;
      const y = (e.originalEvent.clientY - imgRect.top) / yR;

      // --

      const mapNode = this.props.leafletElement.getContainer();
      const rect = mapNode.getBoundingClientRect();

      const data = this.nitrogenHandler(
        x,
        y,
        overlayImage.naturalWidth,
        overlayImage.naturalHeight
      );

      // tooltip
      this.setState({
        nitrogenValue: data,
        nitrogenPopupPosition: {
          x: e.originalEvent.clientX - rect.left + 10,
          y: e.originalEvent.clientY - rect.top + 10,
        },
      });
    } else {
      console.log('[WARN]: this.nitrogenHandler is null');
    }
  };

  toggleTooltip = (value: boolean) => this.setState({nitrogenPopupToggle: value});

  setNitrogenMarker = (e: any) => {
    this.props.setNitrogenMarker(e.latlng, this.state.nitrogenValue);
  };

  originalImageOverlayRef = (ref: any) => {
    if (ref) {
      this.props.originalImageOverlayRef(ref);
    }
  };

  getCurrentImage = () => {
    const {currentDates, currentDate, currentSensor} = this.props;
    return get(currentDates, `${currentDate}.${currentSensor}`, '');
  };

  getZoningImage = () => {
    const {zoning, isTree} = this.props;
    const {treeZonesImage, method} = zoning;

    if (isTree) return treeZonesImage;

    const currentImage = this.getCurrentImage();
    return method === 'custom' && !this.canCustomZoning()
      ? ''
      : currentImage
      ? `${config.baseUrl.slice(0, -1)}${
          currentImage.classify
        }?${this.prepareCustom()}${getZoningUrlParams(zoning)}`
      : '';
  };

  getImageUrl = () => {
    const {
      nRecommendation,
      currentDate,
      currentDates,
      currentSensor,
      field,
      selectedFieldId,
      isWholeFarmView,
    } = this.props;
    if (
      field &&
      field.SouthLat &&
      currentSensor !== 'NONE' &&
      selectedFieldId &&
      !isWholeFarmView &&
      currentDate &&
      currentDates[currentDate]
    ) {
      const isZoning = this.isZoningLayerOn();
      const isApsim =
        nRecommendation.toggled &&
        nRecommendation.method === 'apsim' &&
        nRecommendation.nrxResult[nRecommendation.nrxTabRate][nRecommendation.selectedObjective]
          .type;

      // FSB-3388 hack to test cloud masks
      if (getGetURLParam('cloud') === 'mask') {
        const md5 = field.MD5;
        const date = currentDates[currentDate].Date;
        return `https://flurosense.com/services/data-service/cloudmask/${md5}/${date}`;
      }
      // FSB-3428 hack to test cloud probability
      if (getGetURLParam('cloud') === 'proba') {
        const md5 = field.MD5;
        const date = currentDates[currentDate].Date;
        return `https://flurosense.com/services/data-service/cloudmask/${md5}/${date}?prob_mask=true`;
      }
      if (isZoning && !isApsim) {
        return this.getZoningImage();
      }
      if (!isZoning && !this.getSelectedFields().length) {
        return getLayerImageUrl();
      }
    }

    return false;
  };

  isZoningLayerOn = () => {
    const {feature, isZoning: zoningToggled} = this.props;
    return feature === 'zoning' || (feature === 'tsp' && zoningToggled);
  };

  render() {
    const {
      field,
      currentDates,
      currentDate,
      currentSensor,
      globalDialogs: {editField, editPivotTableView},
      drawingEntity,
      currentSeason,
      feature,
      isProductivityMap,
      soilMapLayer,
      setRequestStatus,
    } = this.props;
    const imageUrl =
      currentSeason?.geometry_id && feature !== 'zoning'
        ? this.getPlantingAreaImageURL()
        : this.getImageUrl();

    const pivotCoordinates =
      editField?.PivotCenterCoordinates || editPivotTableView?.PivotCenterCoordinates;
    const isNitrogenFeature = currentSensor === 'NMAP';
    const nitrogenUrl =
      field && isNitrogenFeature ? get(currentDates, `${currentDate}.NDRE`, false) : false;

    const plantingAreaBox = currentSeason.geometry_id ? box(currentSeason.geometry) : null;

    return (
      <>
        {imageUrl && !isProductivityMap ? (
          <FluroImageOverlay
            url={imageUrl}
            isZoning={this.isZoningLayerOn()}
            interactive={true}
            onMousemove={isNitrogenFeature ? this.getTooltipValue : undefined}
            onMouseover={isNitrogenFeature ? () => this.toggleTooltip(true) : undefined}
            onMouseout={isNitrogenFeature ? () => this.toggleTooltip(false) : undefined}
            onClick={isNitrogenFeature ? this.setNitrogenMarker : undefined}
            onErrorCallback={this.onError}
            lRef={this.originalImageOverlayRef}
            onStartLoadBinary={() => {
              setRequestStatus(AsyncStatusType.binaryImageLayerData, Status.Pending);
            }}
            onEndLoadBinary={() => {
              setRequestStatus(AsyncStatusType.binaryImageLayerData, Status.Done);
            }}
            bounds={
              plantingAreaBox
                ? [
                    [plantingAreaBox[1], plantingAreaBox[0]],
                    [plantingAreaBox[3], plantingAreaBox[2]],
                  ]
                : [
                    [field.SouthLat, field.WestLon],
                    [field.NorthLat, field.EastLon],
                  ]
            }
          />
        ) : null}

        {soilMapLayer && field.soilLayer && (
          <FieldSoilMapOverlay id={field.ID} data={field.soilLayer} />
        )}

        {drawingEntity === 'pivot' && pivotCoordinates && (
          <Marker
            icon={defaultMarkerIcon}
            position={Leaflet.latLng(pivotCoordinates?.Lat, pivotCoordinates?.Lon)}
          />
        )}

        {nitrogenUrl ? (
          <BinaryReader getPixelHandler={this.pixelHandler} baseSrc={nitrogenUrl.classify} />
        ) : null}

        {this.state.nitrogenPopupToggle && this.nitrogenHandler ? (
          <NitrogenPopup
            value={this.state.nitrogenValue}
            position={this.state.nitrogenPopupPosition}
          />
        ) : null}

        {/* Nitrogen tooltip markers */}
        {isNitrogenFeature ? <NitrogenMarkersOverlay /> : null}

        {/* Zoning points */}
        <ZoningPoint />
      </>
    );
  }
}

const mapStateToProps = (s: AppStore) => ({
  isTree: s.map.treeDetection.layerType !== 'default',
  field: s.map.field,
  fields: s.map.fields,
  soilMapLayer: s.map.soilMapLayer,
  fieldGeometries: s.map.fieldGeometries,
  selectedFieldId: s.map.selectedFieldId,
  currentDate: s.map.currentDate,
  currentDates: s.map.currentDates,
  currentSensor: s.map.currentSensor,
  currentSeason: s.map.currentSeason,
  feature: s.map.feature,
  isWholeFarmView: s.map.wholeFarm.isWholeFarmView,
  isZoning: s.map.isZoning,
  zoning: s.map.zoning,
  nRecommendation: s.map.nRecommendation,
  currentGroupId: s.map.group.id,
  globalDialogs: s.global.dialogsState,
  drawingEntity: s.map.drawControl.drawingEntity,
  isProductivityMap: s.productivityMap.isOn,
  currentProductivityMapData: s.productivityMap.data[s.productivityMap.currentCacheKey],
});

const connector = connect(mapStateToProps, {
  setNitrogenMarker,
  setTreeLayerType,
  showNote,
  setRequestStatus,
});
export default connector(MainField);
