export type AdminFeaturesStore = {
  fields: FField[];
  tagKeys: FFeaturesType[];
  result: any[];
  farms: any[];
  entities: any[];
  rawFieldsData: FField[];
};

export type SwitchFeatureAction = {
  type: ActionType.SWITCH_FEATURE;
  key: FFeaturesType;
  value: boolean;
  fieldId: number;
  keyId: number;
};

export type SwitchBulkFeatureAction = {
  type: ActionType.BULK_SWITCH_FEATURE;
  createdKeys: any[];
  key: FFeaturesType;
  value: boolean;
};

type SetSearchResultAction = {
  type: ActionType.SET_SEARCH_RESULT;
  fields: FField[];
};

type SetTagKeysAction = {
  type: ActionType.SET_TAG_KEYS;
  tagKeys: FFeaturesType[];
};

type SetEntitiesAction = {
  type: ActionType.SET_ENTITIES;
  entities: any[];
};

type SetActivationResultAction = {
  type: ActionType.SET_ACTIVATION_RESULT;
  result: any[];
};

type SetFarmsResultAction = {
  type: ActionType.SET_FARMS_RESULT;
  farms: any[];
  rawFieldsData?: FField[];
};

type SetEmailsAction = {
  type: ActionType.SET_EMAILS;
  farmOrSeasonId: number;
  emails: string[];
  manualReview: string;
  entityId: number;
  entityType: EntityType;
};

type ExtendEntitiesAction = {
  type: ActionType.EXTEND_ENTITIES;
  entities: any[];
};

type SetSeasonSelectorAction = {
  type: ActionType.SET_SELECTOR_SEASON;
  farmId: number;
  fieldId: number;
  season: BaseSeason;
};

export type FeatureAction =
  | SwitchFeatureAction
  | SwitchBulkFeatureAction
  | SetSearchResultAction
  | SetTagKeysAction
  | SetEntitiesAction
  | SetActivationResultAction
  | SetFarmsResultAction
  | SetEmailsAction
  | ExtendEntitiesAction
  | SetSeasonSelectorAction;

export enum ActionType {
  SWITCH_FEATURE = 'features/switch-feature',
  BULK_SWITCH_FEATURE = 'features/bulk-switch-feature',
  SET_SEARCH_RESULT = 'features/set-search-result',
  SET_TAG_KEYS = 'features/set-tag-keys',
  SET_ENTITIES = 'features/set-entities',
  SET_ACTIVATION_RESULT = 'features/set-activation-result',
  SET_FARMS_RESULT = 'features/set-farms-result',
  SET_EMAILS = 'features/set-emails',
  EXTEND_ENTITIES = 'features/extend-entities',
  SET_SELECTOR_SEASON = 'features/set-season-selector',
}

export type EntityType = 'farm' | 'field' | 'season';

export enum OneTimeReportType {
  CropStress = 'crop-stress-one-time-email',
  CropPerformance = 'one-time-email',
  PivoThermo = 'pivothermo-one-time-email',
  EndOfSeason = 'end-of-season',
  SeasonSelector = 'season-selector',
}

export type RowType = 'farm' | 'field';

export type BaseSeason = {
  seasonId: number;
  cropType: string;
  cropVariety: string;
  startDate: string;
  endDate: string;
};

export type FFeaturesType = 'anomaly_detection' | 'nrx' | 'tree_analysis' | 'pivothermo_report';

export type FFeature = {
  [key in FFeaturesType]?: {
    id: number;
    value: string | number | boolean;
    created_at: string;
  };
};

export type FTag = {
  id: number;
  key: FFeaturesType;
  value: string | number | boolean;
  created_at: string;
};

export type FField = {
  fieldName: string;
  fieldId: number;
  farmName: string;
  farmId: number;
  seasonId: number;
  cropType: string;
  cropVariety: string;
  startDate: string;
  endDate: string;
  organizationName: string;
  organizationId: number;
  subscriptionName: string;
  subscriptionId: number;
  regionName: string;
  regionId: number;
  tags: FTag[];
  features: FFeature;
};
