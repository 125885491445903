import {AppStore} from '../../../reducers';
import {FieldGeometryApi, KmlApi} from '_api';
import {reportError} from '../../error-boundary';
import {showNote} from '_actions';
import {updateEachFeatureProperties} from '_utils/geometry';
import {ActionTypes} from '../reducer/types';
import {getFieldSoilLayer, getFieldsWithAllDates} from '../utils/field';
import {getAnalyticsItemColor} from '_utils';
import {getDatesFromAllFields} from '../utils';
import {t} from 'i18n-utils';
import {FieldTag, Field, ITreeData} from '../types';
import {loadFarmTreeData} from '../utils/trees';
import {AsyncStatusType, setRequestStatus, Status} from '../../../modules/ui-helpers';
import axios from 'axios';

/**
 * Fetch both kml geometries and field data for all the fields in the farm. + try to load tree detection data
 */
export const loadWholeFarmData = () => async (dispatch: any, getState: () => AppStore) => {
  const {map} = getState();

  const fieldsToRequest = map.fields.filter(f => !f.files || !map.fieldGeometries[f.MD5]);
  const fieldsInAmerica = map.fields.filter(f => f.Country === 'United States');
  const geometriesToRequest = fieldsToRequest
    .filter(f => !map.wholeFarm.fieldsWithDates[f.MD5] || !map.fieldGeometries[f.MD5])
    .map(f => f.MD5);

  const shouldLoadTreeDetection = fieldsToRequest.find(f =>
    f.tags?.includes(FieldTag.TreeDetection)
  );

  let fieldGeometries: any,
    rawSoilMapData: string | null = null,
    fieldsResponse,
    treeDetectionData: {[fieldId: number]: ITreeData[]} = {};

  const soilMapRequest = fieldsInAmerica.length
    ? KmlApi.loadFieldSoilMap(map.fields.map(({MD5}) => MD5))
        .then(({data}) => data)
        .catch(e => {
          if (!axios.isCancel(e)) {
            reportError(`loadFieldSoilMap err=${e}`);
          }
        })
    : Promise.resolve(null);

  map.fields.forEach(field => {
    if (field.treeDetectionRawData) {
      treeDetectionData[field.FieldID] = field.treeDetectionRawData;
    }
  });

  const loadedBeforeFields = map.fields.filter(f => f.files && map.fieldGeometries[f.MD5]);
  if (fieldsToRequest.length) {
    dispatch(setRequestStatus(AsyncStatusType.wholeFarmData, Status.Pending)); // set request status Pending
    // make a call only if it is necessary
    [fieldGeometries, fieldsResponse, treeDetectionData, rawSoilMapData] = await Promise.all([
      FieldGeometryApi.featureCollections(geometriesToRequest).then(({data}) => data.data),
      KmlApi.getFileListByIDs(
        map.group.id,
        fieldsToRequest.map(f => f.ID)
      ).then(({data}) => data.result),
      shouldLoadTreeDetection ? loadFarmTreeData(map.group.id) : Promise.resolve({}),
      soilMapRequest,
    ]);
  }

  if ((!fieldGeometries || !fieldsResponse) && !loadedBeforeFields.length) {
    dispatch(setRequestStatus(AsyncStatusType.wholeFarmData, Status.Done)); // set request status Done
    const message = t({
      id: 'Failed to load some of the fields for the whole farm view.',
      defaultMessage: 'Failed to load some of the fields for the whole farm view.',
    });
    reportError(message);
    showNote({title: t({id: 'note.error', defaultMessage: 'Error'}), message, level: 'error'});
    return;
  }

  const preparedSoilLayersData = rawSoilMapData ? JSON.parse(rawSoilMapData) : null;
  const fields = fieldsResponse?.fields
    ? [...loadedBeforeFields, ...fieldsResponse.fields]
    : loadedBeforeFields;

  fields.forEach(f => {
    if (!fieldGeometries?.[f.MD5]) return;
    updateEachFeatureProperties(fieldGeometries[f.MD5], {fluro_id: f.ID});
  });

  dispatch({
    type: ActionTypes.MAP_SET_FIELD_GEOMETRIES,
    fieldGeometries,
  });

  const {fieldsObject, tsps} = getFieldsWithAllDates(fields, treeDetectionData);

  const allFarmTSP = tsps.map(tsp => {
    const currentColors = tsps.filter(tsp => tsp.properties.color).map(tsp => tsp.properties.color);
    tsp.properties.color = getAnalyticsItemColor(currentColors);
    return tsp;
  });

  dispatch({
    type: ActionTypes.MAP_SET_WHOLE_FARM_FIELDS_GEOMETRY,
    allDates: getDatesFromAllFields(fieldsObject, fields),
    fieldsWithDates: fieldsObject,
    allFarmTSP,
    fields: fields.map((f: Field) => {
      const oldField = map.fields.find((stateF: Field) => stateF.ID === f.ID) || {};
      const season = f.Seasons && f.Seasons.length && f.Seasons[f.Seasons.length - 1];

      return {
        ...oldField,
        ...f,
        _selected: false,
        files: fieldsObject[f.MD5],
        treeDetectionRawData: treeDetectionData[f.FieldID],
        GDD: season?.gdd || 0,
        NDVI: season?.ndvi || 0,
        Delta: season?.delta || 0,
        soilLayer: getFieldSoilLayer(preparedSoilLayersData, f.MD5),
      };
    }),
  });
  // especially set the status just at the end, to prevent loading regular whole farm images when there is tree detection, FSB-4297
  dispatch(setRequestStatus(AsyncStatusType.wholeFarmData, Status.Done)); // set request status Done
};
