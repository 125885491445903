import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {InfoBlock, FluroDialog, Ln} from 'components';
import {Button} from 'react-md';
import {toggleMapBar} from '../map/actions';
import {setOnboardingSeen} from '../login/actions';
import {IInitialMapState} from '../map/types';
import {LoginState} from '../login/types';
import {ProfileIcon} from '../map/icons';

type Props = ConnectedProps<typeof connector>;

const WelcomeOnboardingDialog = ({onboarding, toggleMapBar, setOnboardingSeen}: Props) => {
  useEffect(() => {
    if (onboarding.isOnboarded === false) {
      toggleMapBar(false);
    }
  }, []);

  const selectTour = (tour: 'fullTour' | 'quickTour') => {
    setOnboardingSeen({isOnboarded: true, [tour]: true});
  };

  const closeOnboarding = () => {
    setOnboardingSeen({isOnboarded: true, fullTour: false, quickTour: false});
  };

  return !onboarding.isOnboarded && !onboarding.fullTour && !onboarding.quickTour ? (
    <FluroDialog
      id="onboarding-dialog"
      className={'onboarding-dialog'}
      visible
      title={'Welcome to FluroSense'}
      isDraggable
      onHide={closeOnboarding}
      focusOnMount={false}
      borderedTitle
    >
      <div className={'info-text'}>
        Choose one of the onboarding options and we will take you through FluroSense functionalities
      </div>

      <div className={'select-a-tour-buttons'}>
        <Button raised primary className="boarding-btn" onClick={() => selectTour('quickTour')}>
          Quick tour
        </Button>

        <Button raised className="boarding-btn" onClick={() => selectTour('fullTour')}>
          Full tour
        </Button>

        <div onClick={closeOnboarding} className={'close-tour'}>
          No Tour, Let's Just Get Started
        </div>
      </div>

      <InfoBlock className={'inside-a-pop-up'}>
        Remember, you can access the tours at a later stage from your <ProfileIcon />{' '}
        <Ln onClick={closeOnboarding} href={'/profile'}>
          user profile
        </Ln>
        .
      </InfoBlock>
    </FluroDialog>
  ) : null;
};

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: LoginState}) => ({
  onboarding: login.user.settings.onboarding,
  selectedFieldId: map.selectedFieldId, // here to update component
});

const connector = connect(mapStateToProps, {
  setOnboardingSeen,
  toggleMapBar,
});
export default connector(WelcomeOnboardingDialog);
