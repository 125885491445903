import React from 'react';
import {Button, DialogContainer, DialogContainerProps} from 'react-md';
import './index.scss';
import Draggable from 'react-draggable';
import {Portal} from './portal';
import cn from 'classnames';

interface IProps extends DialogContainerProps {
  // add custom props here
  isDraggable?: boolean;
  hideOnClickOutside?: boolean;
  isClosable?: boolean;
}

const FluroDialog = ({
  onHide = (): any => null,
  onShow = (): any => null,
  className,
  dialogClassName,
  title = '',
  visible = false,
  width = '',
  height = null,
  id,
  portal,
  fullPage,
  stickyHeader,
  isDraggable = false,
  style,
  focusOnMount = false,
  initialFocus,
  autosizeContent,
  centered = true,
  contentStyle,
  borderedTitle = false,
  actions = null,
  hideOnClickOutside = true,
  isClosable = true,
  children,
}: IProps) => {
  const dialog = (
    <DialogContainer
      id={id}
      width={width}
      height={height}
      onHide={onHide}
      onShow={onShow}
      visible={visible}
      style={style}
      modal
      fullPage={fullPage}
      autosizeContent={autosizeContent}
      focusOnMount={focusOnMount}
      className={`fluro-dialog ${className}`}
      dialogClassName={dialogClassName}
      centered={centered}
      contentStyle={contentStyle}
      title={''}
      initialFocus={initialFocus}
    >
      {hideOnClickOutside && (
        <div className="fluro-dialog__custom-overlay" onClick={() => onHide()} />
      )}

      <Draggable
        handle={'.fluro-dialog__draggable-trigger'}
        key={id}
        disabled={!isDraggable}
        defaultPosition={{x: 0, y: 0}}
        position={null}
        grid={[25, 25]}
      >
        <div className="fluro-dialog__content">
          {isClosable && (
            <Button icon className="close-dialog-button" onClick={() => onHide()}>
              close
            </Button>
          )}
          {isDraggable ? <div className="fluro-dialog__draggable-trigger" /> : null}

          {title ? (
            <div
              className={cn('fluro-dialog__title', {
                sticky: stickyHeader,
                bordered: borderedTitle,
              })}
            >
              <h3>{title}</h3>
            </div>
          ) : null}

          {children}
          {actions ? <div className="fluro-dialog__footer">{actions}</div> : null}
        </div>
      </Draggable>
    </DialogContainer>
  );

  return portal ? <Portal id="dialog-root">{dialog}</Portal> : dialog;
};

export default FluroDialog;
