import chroma from 'chroma-js';
import {AppStore} from 'reducers';
import {unreachableError} from '_utils';
import {
  OptisItemTillage,
  OptisItemWinterCrop,
  OptisType,
  OptisAreaType,
  optisAreaTypeOrder,
  ResidueCover,
  Tillage,
  tillageColorScale,
  tillageOrder,
  winterCropColorScale,
  winterCropOrder,
  winterCropFullOrder,
  WinterCropType,
  tillageLabels,
  winterCropLabels,
  OptisFilter,
} from './optis-types';
import {defaultFilterValue} from './optis-reducer';

export const createTillage = (): OptisItemTillage => ({
  type: OptisType.Tillage,
  [Tillage.Conservation]: 0,
  [Tillage.High]: 0,
  [Tillage.Low]: 0,
  [Tillage.Conventional]: 0,
  [Tillage.NoTill]: 0,
});

export const createWinterCrop = (): OptisItemWinterCrop => ({
  type: OptisType.WinterCrop,
  [WinterCropType.Perennial]: 0,
  [WinterCropType.WinterCommodity]: 0,
  [WinterCropType.CoverCrop]: 0,
  [WinterCropType.NoCoverCrop]: 0,
});

export const maxTillage = (tillage: OptisItemTillage) => {
  let maxTillage: Tillage = tillageOrder[0];
  let maxValue = 0;
  tillageOrder.forEach(t => {
    if (maxValue < tillage[t]) {
      maxValue = tillage[t];
      maxTillage = t;
    }
  });
  return maxTillage;
};

export const maxWinterCrop = (winterCrop: OptisItemWinterCrop) => {
  let maxWinterCrop = winterCropOrder[0];
  let maxValue = 0;
  winterCropOrder.forEach(w => {
    if (maxValue < winterCrop[w]) {
      maxValue = winterCrop[w];
      maxWinterCrop = w;
    }
  });
  return {value: maxValue, category: maxWinterCrop};
};

export const sumTillage = (tillage: OptisItemTillage) => {
  return tillageOrder.reduce((acc, key) => acc + tillage[key], 0);
};

export const sumWinterCrop = (winterCrop: OptisItemWinterCrop, includeNoCoverCrop = true) => {
  const noCoverCrop = includeNoCoverCrop ? winterCrop[WinterCropType.NoCoverCrop] : 0;
  return (
    winterCrop[WinterCropType.Perennial] +
    winterCrop[WinterCropType.WinterCommodity] +
    winterCrop[WinterCropType.CoverCrop] +
    noCoverCrop
  );
};

export const sumOptis = (data: OptisItemTillage | OptisItemWinterCrop) => {
  switch (data.type) {
    case OptisType.Tillage:
      return sumTillage(data);
    case OptisType.WinterCrop:
      return sumWinterCrop(data);
  }
};

export const minMaxPctAcrossGeometries = (optis: AppStore['optis'], geometryIds: number[]) => {
  let minMaxPct;
  switch (optis.filter.type) {
    case OptisType.Tillage:
      minMaxPct = minMaxPctTillageAcrossGeometries(
        optis.tillage,
        geometryIds,
        optis.filter.value,
        optis.filter.years,
        optis.diffMode,
        optis.diffYearA,
        optis.diffYearB
      );
      break;
    case OptisType.WinterCrop:
      minMaxPct = minMaxPctWinterCropAcrossGeometries(
        optis.winterCrop,
        geometryIds,
        optis.filter.value,
        optis.filter.years,
        optis.diffMode,
        optis.diffYearA,
        optis.diffYearB
      );
      break;
  }
  return minMaxPct;
};

export const minMaxPctTillageAcrossGeometries = (
  tillage: AppStore['optis']['tillage'],
  geometryIds: number[],
  categories: Tillage[],
  years: number[],
  diffMode: boolean,
  diffYearA?: number,
  diffYearB?: number
) => {
  const tillagePerGeometry = geometryIds
    .filter(id => tillage[id])
    .map(id =>
      diffMode && diffYearA && diffYearB
        ? diffPctTillage(tillage[id][diffYearA], tillage[id][diffYearB])
        : sumTillageAcrossYears(tillage[id], years)
    );
  let min = Infinity;
  let max = 0;
  tillagePerGeometry.forEach(geometryTillage => {
    let pct: number;
    if (diffMode && diffYearA && diffYearB) {
      pct = roundPercentage(categories.reduce((acc, t) => acc + geometryTillage[t], 0));
    } else {
      const total = sumTillage(geometryTillage);
      const sum = categories.reduce((acc, t) => acc + geometryTillage[t], 0);
      pct = roundPercentage((sum / total) * 100);
    }
    if (min > pct) {
      min = pct;
    }
    if (max < pct) {
      max = pct;
    }
  });
  if (!isFinite(min)) {
    min = 0;
  }
  return {min, max};
};

export const minMaxPctWinterCropAcrossGeometries = (
  winterCrop: AppStore['optis']['winterCrop'],
  geometryIds: number[],
  categories: WinterCropType[],
  years: number[],
  diffMode: boolean,
  diffYearA?: number,
  diffYearB?: number
) => {
  const valueAcrossYears = geometryIds
    .filter(id => winterCrop[id])
    .map(id =>
      diffMode
        ? diffPctWinterCrop(winterCrop[id][diffYearA], winterCrop[id][diffYearB])
        : sumWinterCropAcrossYears(winterCrop[id], years)
    );
  let min = Infinity;
  let max = 0;
  valueAcrossYears.forEach(geometryWinterCrop => {
    let pct: number;
    if (diffMode) {
      pct = categories.reduce((acc, t) => acc + geometryWinterCrop[t], 0);
    } else {
      const total = sumWinterCrop(geometryWinterCrop, true);
      const sum = categories.reduce((acc, t) => acc + geometryWinterCrop[t], 0);
      pct = roundPercentage((sum / total) * 100);
    }
    if (min > pct) {
      min = pct;
    }
    if (max < pct) {
      max = pct;
    }
  });
  if (!isFinite(min)) {
    min = 0;
  }
  return {min, max};
};

export const sumTillageAcrossYears = (
  yearsTillage: {[year: number]: OptisItemTillage},
  years: number[],
  resultTillage?: OptisItemTillage // mutable
) => {
  if (years.length === 1) {
    const a = resultTillage || createTillage();
    const b = yearsTillage[years[0]];
    return b ? addTillageMut(a, b) : a;
  }

  // Recursively fulfill `tillage` with each year.
  const tillage = createTillage();
  Object.keys(yearsTillage)
    .map(Number)
    .filter(year => years.includes(year) || years.length === 0)
    .forEach(year => sumTillageAcrossYears(yearsTillage, [year], tillage));
  return tillage;
};

/**
 * Adds values across tillage. Mutates `a` as a result.
 * @param a Mutable
 * @param b
 */
export const addTillageMut = (a: OptisItemTillage, b: OptisItemTillage) => {
  tillageOrder.forEach(t => {
    a[t] += b[t];
  });
  return a;
};

export const diffPct = (optis: AppStore['optis'], id: number) => {
  let pct: number;
  switch (optis.filter.type) {
    case OptisType.Tillage: {
      const data = optis[optis.filter.type][id];
      const diff = diffPctTillage(data[optis.diffYearA], data[optis.diffYearB]);
      pct = optis.filter.value.reduce((acc, t) => acc + diff[t], 0);
      break;
    }
    case OptisType.WinterCrop: {
      const data = optis[optis.filter.type][id];
      const diff = diffPctWinterCrop(data[optis.diffYearA], data[optis.diffYearB]);
      pct = optis.filter.value.reduce((acc, t) => acc + diff[t], 0);
      break;
    }
  }
  return pct;
};

export const diffPctTillage = (a: OptisItemTillage, b: OptisItemTillage) => {
  const result = createTillage();
  const totalA = sumTillage(a);
  const totalB = sumTillage(b);
  tillageOrder.forEach(t => {
    result[t] = roundPercentage((b[t] / totalB) * 100 - (a[t] / totalA) * 100);
  });
  return result;
};

export const sumWinterCropAcrossYears = (
  yearsWinterCrop: {[year: number]: OptisItemWinterCrop},
  years: number[],
  resultWinterCrop?: OptisItemWinterCrop
) => {
  if (years.length === 1) {
    const a = resultWinterCrop || createWinterCrop();
    const b = yearsWinterCrop[years[0]];
    return b ? addWinterCropMut(a, b) : a;
  }

  // Recursively fulfill `winterCrop` with each year.
  const winterCrop = resultWinterCrop || createWinterCrop();
  Object.keys(yearsWinterCrop)
    .map(Number)
    .filter(year => years.includes(year) || years.length === 0)
    .forEach(year => sumWinterCropAcrossYears(yearsWinterCrop, [year], winterCrop));
  return winterCrop;
};

/**
 * Adds values across winter crop. Mutates `a` as a result.
 * @param a Mutable
 * @param b
 */
export const addWinterCropMut = (a: OptisItemWinterCrop, b: OptisItemWinterCrop) => {
  a[WinterCropType.Perennial] += b[WinterCropType.Perennial];
  a[WinterCropType.WinterCommodity] += b[WinterCropType.WinterCommodity];
  a[WinterCropType.CoverCrop] += b[WinterCropType.CoverCrop];
  a[WinterCropType.NoCoverCrop] += b[WinterCropType.NoCoverCrop];
  return a;
};

export const diffPctWinterCrop = (a: OptisItemWinterCrop, b: OptisItemWinterCrop) => {
  const result = createWinterCrop();
  const totalA = sumWinterCrop(a);
  const totalB = sumWinterCrop(b);
  winterCropFullOrder.forEach(w => {
    result[w] = roundPercentage((b[w] / totalB) * 100 - (a[w] / totalA) * 100);
  });
  return result;
};

export const parseOptisCsv = (data: any[], areaType: OptisAreaType) => {
  const tillage: {[id: number]: {[year: number]: OptisItemTillage}} = {};
  const winterCrop: {[id: number]: {[year: number]: OptisItemWinterCrop}} = {};

  const parser = OptisDataParser[areaType];
  data.map(parser).forEach(d => {
    if (!tillage[d.id]) {
      tillage[d.id] = {};
    }
    if (!tillage[d.id][d.year]) {
      tillage[d.id][d.year] = createTillage();
    }
    tillage[d.id][d.year][d.tillage] += d.areaAc;

    if (!winterCrop[d.id]) {
      winterCrop[d.id] = {};
    }
    if (!winterCrop[d.id][d.year]) {
      winterCrop[d.id][d.year] = createWinterCrop();
    }
    winterCrop[d.id][d.year][d.winterCrop] += d.areaAc;
  });

  return {tillage, winterCrop};
};

export const OptisDataParser = {
  State: (d: any[]) => {
    console.error('TODO Stas: Optis State parser is not implemented');
    return {
      id: Number(d[0]),
      year: Number(d[4]),
      winterCrop: d[6] as WinterCropType,
      tillage: d[9] as Tillage,
      areaAc: Number(d[10]),
      residue: 0,
      residueFall: 0,
      residueSpring: 0,
      residueSummer: 0,
    };
  },
  // County_FIPS	C ounty_Name	CRD_Name	State_Name	Year	Prv_Smr_Crp	Wntr_Crp	Cur_Smr_Crp	Res_Cvr	Tlg_Prc	Area_Ac	Area_Ha
  County: (d: any[]) => {
    return {
      id: Number(d[0]),
      year: Number(d[4]),
      winterCrop: d[6] as WinterCropType,
      tillage: d[9] as Tillage,
      areaAc: Number(d[10]),
      residue: 0,
      residueFall: 0,
      residueSpring: 0,
      residueSummer: 0,
    };
  },
  // CRD_Code	CRD_Name	State_Name	Year	Prv_Smr_Crp	Wntr_Crp	Cur_Smr_Crp	Res_Cvr	Tlg_Prc	Area_Ac	Area_Ha
  CRD: (d: any[]) => {
    return {
      id: Number(d[0]),
      year: Number(d[3]),
      winterCrop: d[5] as WinterCropType,
      tillage: d[8] as Tillage,
      areaAc: Number(d[9]),
      residue: 0,
      residueFall: 0,
      residueSpring: 0,
      residueSummer: 0,
    };
  },
  // segment_id	year	tillage	winter_crop	area_ac
  Segment: (d: any[]) => {
    return {
      id: Number(d[0]),
      year: Number(d[1]),
      winterCrop: d[2] as WinterCropType,
      tillage: d[3] as Tillage,
      areaAc: Number(d[4]),
      residue: Number(d[5]),
      residueFall: Number(d[6]),
      residueSpring: Number(d[7]),
      residueSummer: Number(d[8]),
    };
  },
  // HUC8_Code	HUC8_Name	State_Names	Year	Prv_Smr_Crp	Wntr_Crp	Cur_Smr_Crp	Res_Cvr	Tlg_Prc	Area_Ac	Area_Ha
  HUC8: (d: any[]) => {
    return {
      id: Number(d[0]),
      year: Number(d[3]),
      winterCrop: d[5] as WinterCropType,
      tillage: d[8] as Tillage,
      areaAc: Number(d[9]),
      residue: 0,
      residueFall: 0,
      residueSpring: 0,
      residueSummer: 0,
    };
  },
  HUC10: (d: any[]) => {
    return {
      id: Number(d[0]),
      year: Number(d[3]),
      winterCrop: d[5] as WinterCropType,
      tillage: d[8] as Tillage,
      areaAc: Number(d[9]),
      residue: 0,
      residueFall: 0,
      residueSpring: 0,
      residueSummer: 0,
    };
  },
  HUC12: (d: any[]) => {
    return {
      id: Number(d[0]),
      year: Number(d[3]),
      winterCrop: d[5] as WinterCropType,
      tillage: d[8] as Tillage,
      areaAc: Number(d[9]),
      residue: 0,
      residueFall: 0,
      residueSpring: 0,
      residueSummer: 0,
    };
  },
};

export const prevAreaType = (areaType: OptisAreaType) => {
  const currentAreaTypeIndex = optisAreaTypeOrder.indexOf(areaType);
  const prevAreaTypeIndex = Math.max(currentAreaTypeIndex - 1, 0);
  return optisAreaTypeOrder[prevAreaTypeIndex];
};

export const nextAreaType = (areaType: OptisAreaType) => {
  const currentAreaTypeIndex = optisAreaTypeOrder.indexOf(areaType);
  const nextAreaTypeIndex = Math.min(currentAreaTypeIndex + 1, optisAreaTypeOrder.length - 1);
  return optisAreaTypeOrder[nextAreaTypeIndex];
};

export const formatNumber = (x: number) => {
  if (x >= 1000000) {
    return `${Math.round(x / 1000000)}M`;
  }
  if (x >= 1000) {
    return `${Math.round(x / 1000)}K`;
  }
  return Math.round(x);
};

export const getColorScale = (optis: AppStore['optis'], min: number, max: number) => {
  if (optis.diffMode && optis.diffYearA && optis.diffYearB) {
    if (min < 0 && max > 0) {
      // For example, min=-20% to max=5% will be colored as
      // intense red (100% red) for -20% and
      // slight green (25% green) for 5%
      const total = max - min;
      const whiteDomain = (total - max) / total;
      const redDomain = Math.abs(min) > Math.abs(max) ? 0 : 0 - whiteDomain / 2;
      const greenDomain = Math.abs(max) > Math.abs(min) ? 1 : 1 + whiteDomain / 2;
      return chroma.scale(['red', 'white', 'green']).domain([redDomain, whiteDomain, greenDomain]);
    } else if (min < 0 && max === 0) {
      return chroma.scale(['red', 'white']);
    } else if (min === 0 && max > 0) {
      return chroma.scale(['white', 'green']);
    } else if (min > 0 && max > 0) {
      return chroma.scale(['white', 'green']);
    } else if (min < 0 && max < 0) {
      return chroma.scale(['red', 'white']);
    }
    return chroma.scale(['red', 'white', 'green']);
  }
  let scale;
  switch (optis.filter.type) {
    case OptisType.Tillage: {
      scale = tillageColorScale[optis.filter.value[0]];
      break;
    }
    case OptisType.WinterCrop: {
      scale = winterCropColorScale[optis.filter.value[0]];
      break;
    }
  }
  return chroma.scale(scale);
};

export const calcTotalAndPct = (optis: AppStore['optis'], id: number) => {
  let total;
  let pct;
  switch (optis.filter.type) {
    case OptisType.Tillage: {
      const data = optis[optis.filter.type][id];
      const acrossYears = sumTillageAcrossYears(data, optis.filter.years);
      const value = optis.filter.value.reduce((acc, t) => acc + acrossYears[t], 0);
      total = sumTillage(acrossYears);
      pct = ((value / total) * 100) | 0;
      break;
    }
    case OptisType.WinterCrop: {
      const data = optis[optis.filter.type][id];
      const acrossYears = sumWinterCropAcrossYears(data, optis.filter.years);
      const value = optis.filter.value.reduce((acc, t) => acc + acrossYears[t], 0);
      total = sumWinterCrop(acrossYears);
      pct = ((value / total) * 100) | 0;
      break;
    }
  }
  return {total, pct};
};

export const getTimePeriod = (years: number[]) => {
  const yearA = years[0];
  const yearB = years[years.length - 1];
  return years.length === 1 ? `In ${yearA}` : `Between ${yearA} and ${yearB}`;
};

export const getCategories = (filter: OptisFilter, type: OptisType) => {
  let verb;
  let categories = '';
  switch (type) {
    case OptisType.Tillage: {
      const values = type === filter.type ? filter.value : defaultFilterValue[type];

      verb = 'using';

      // The combination of No Tillage and Hight Residue Tillage is called Conservation Tillage.
      const conservation =
        values.length === 2 && values.includes(Tillage.NoTill) && values.includes(Tillage.High);
      if (conservation) {
        categories = 'Conservation Tillage';
      } else {
        values.forEach((v, i) => {
          const prefix = i === 0 ? '' : i === values.length - 1 ? ' and ' : ', ';
          categories += `${prefix}${tillageLabels[v]}`;
        });
      }

      break;
    }
    case OptisType.WinterCrop: {
      const values = type === filter.type ? filter.value : defaultFilterValue[type];

      verb = 'with';

      values.forEach((v, i) => {
        const prefix = i === 0 ? '' : i === values.length - 1 ? ' and ' : ', ';
        categories += `${prefix}${winterCropLabels[v]}`;
      });

      break;
    }
  }
  return {verb, categories};
};

export const roundPercentage = (pct: number) => {
  // For 1..n cases, just cut out the decimal part.
  if (pct >= 1 || pct <= -1) {
    return pct | 0;
  }
  // For 0..1 cases, keep 2 decimal numbers (0.05).
  return ((pct * 100) | 0) / 100;
};
