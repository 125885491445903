import {t} from 'i18n-utils';
import React, {Component, PureComponent} from 'react';
import {GeoJSON} from 'react-leaflet';
import {connect, ConnectedProps} from 'react-redux';
import L from 'leaflet';
import {COLOR_DARK_BLUE, COLOR_GREEN, COLOR_DARK_GRAY} from '_constants/geo-colors';
import MapPopup from 'components/map-popup';

import {togglePopup, setFeature} from '../../actions';
import {
  toggleEditAreaOfInterest,
  removeAreaOfInterest,
  changeAreaOfInterestProp,
} from '../../actions/areas-of-interest-actions';
import {getTrendsData} from '../../actions/analytics-actions';
import {
  changeLowPerfAnomalyProp,
  saveLowPerfAnomalies,
  toggleLowPerformingAreas,
} from '../../actions/anomalies-actions';

import {getAnalyticsItemColor} from '_utils';
import {getAllVisibleGeometries} from '../../utils';
import {showNote} from '_actions';
import {IInitialMapState} from '../../types';
import {isPermanentAnomalyLabel} from './anomalies-utils';

type Props = ConnectedProps<typeof connector>;

class GeometriesOverlay extends PureComponent<Props> {
  render() {
    const {visibleLowPerfAnomalies} = this.props;
    const geometriesToShow = visibleLowPerfAnomalies
      ? getAllVisibleGeometries()
      : getAllVisibleGeometries().filter(el =>
          el.properties.isLowPerf ? el.properties.label : true
        );

    return geometriesToShow.map((el, i) => {
      return (
        <Geometry
          key={'geometry-overlay' + i}
          {...this.props}
          getAllVisibleGeometries={getAllVisibleGeometries}
          data={el}
        />
      );
    });
  }
}

interface IGeometryProps extends Props {
  getAllVisibleGeometries: any;
  data: any;
}

class Geometry extends Component<IGeometryProps> {
  onEachFeature = (feature: any, layer: any) => {
    const {properties} = feature;
    const {feature: tabName, openPopupId} = this.props;
    const {checked, id, anomaly_id} = properties;
    const isAnalytics = tabName === 'analytics';
    const isRoi = !properties.isLowPerf;

    const color = properties.color
      ? properties.color
      : isPermanentAnomalyLabel(properties.type)
      ? COLOR_DARK_GRAY
      : COLOR_DARK_BLUE;

    if (isPermanentAnomalyLabel(properties.type)) {
      layer.setStyle({color: 'transparent', fillColor: COLOR_DARK_GRAY, fillOpacity: 0.6});
    }

    if (openPopupId === id) layer.setStyle({color: COLOR_DARK_GRAY});
    if (checked) layer.setStyle({color: COLOR_GREEN});
    if (isAnalytics)
      layer.setStyle({color: color ? color : checked ? COLOR_GREEN : COLOR_DARK_BLUE});

    layer.on({
      click: (event: any) => {
        if (tabName === 'analytics') {
          L.DomEvent.stopPropagation(event);
          const color = properties.color ? null : getAnalyticsItemColor();
          if (getAllVisibleGeometries().filter(g => g.properties.color).length >= 10 && color) {
            return this.props.showNote({
              title: t({id: 'note.warning', defaultMessage: 'Warning'}),
              message: t({
                id: 'You can select up to 10 geometries.',
                defaultMessage: 'You can select up to 10 geometries.',
              }),
              level: 'warning',
            });
          }
          isRoi
            ? this.props.changeAreaOfInterestProp(feature, 'color', color)
            : this.props.changeLowPerfAnomalyProp(feature, 'color', color);
          layer.setStyle({color: color || COLOR_DARK_BLUE});
          color && this.props.getTrendsData();
        } else {
          this.props.togglePopup(id || anomaly_id);
        }
      },
    });
  };

  onEdit = (geometry: any) => {
    this.props.feature !== 'crop' && this.props.setFeature('crop');
    geometry.properties.isLowPerf
      ? this.props.toggleLowPerformingAreas(true)
      : this.props.toggleEditAreaOfInterest(true, geometry);
  };

  onDeleteGeometry = (geometry: any) => {
    const {
      removeAreaOfInterest,
      changeLowPerfAnomalyProp,
      toggleLowPerformingAreas,
      togglePopup,
    } = this.props;

    if (geometry.properties.isLowPerf) {
      changeLowPerfAnomalyProp(geometry, 'label', '');
      togglePopup(undefined);
      return toggleLowPerformingAreas(false);
    } else {
      removeAreaOfInterest(geometry.properties.id);
    }
  };

  render() {
    const {data, currentSensor, measurement, range, openPopupId, togglePopup} = this.props;
    const {id, anomaly_id} = data.properties;
    const popup = openPopupId && (openPopupId === id || openPopupId === anomaly_id);
    return (
      <>
        <GeoJSON
          data={data}
          pane={'field-geometry'}
          onEachFeature={this.onEachFeature}
          key={new Date().getTime()}
        />
        {popup && (
          <MapPopup
            data={data}
            measurement={measurement}
            currentSensor={currentSensor}
            range={range}
            onClose={() => togglePopup(undefined)}
            onDelete={() => this.onDeleteGeometry(data)}
            onEdit={() => this.onEdit(data)}
            sectionName={t({id: 'Area of interest', defaultMessage: 'Area of interest'})}
            sensingDate={data.properties.startDate}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: any}) => ({
  geometry: map.geometry,
  currentDate: map.currentDate,
  currentDates: map.currentDates,
  currentSensor: map.currentSensor,
  lowPerfAnomalies: map.lowPerfAnomalies,
  feature: map.feature,
  measurement: login.user.settings.measurement,
  range: map.histogram.range,
  geometriesOnMap: map.geometriesOnMap,
  visibleLowPerfAnomalies: map.lowPerfAnomalies.isVisible,
  openPopupId: map.openPopupId,
});

const connector = connect(mapStateToProps, {
  changeAreaOfInterestProp,
  getTrendsData,
  changeLowPerfAnomalyProp,
  showNote,
  toggleEditAreaOfInterest,
  removeAreaOfInterest,
  toggleLowPerformingAreas,
  saveLowPerfAnomalies,
  togglePopup,
  setFeature,
});
export default connector(GeometriesOverlay);
