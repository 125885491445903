import {t, FormattedMessage} from 'i18n-utils';
import styled from 'styled-components';
export const InviteWrapper = styled.div`
  max-width: 600px;

  .invite-user__container {
    max-height: 400px;
    overflow-y: auto;
  }
`;
