import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import './index.scss';
import moment from 'moment';
import {Muted, EditIcon} from 'containers/map/icons';
import LabelIcon from '../../label-icon';
import {convertUnit, formatUnit, getMeasurement} from '_utils';
import cs from 'classnames';
import PermanentlyExcluded from '../permanently-excluded';

type TProps = {
  label: any;
  sensingDate?: string;
  startDate?: string;
  endDate?: string;
  title: string;
  labelName?: string;
  snoozed?: any;
  area: string; // Format `${number} ${measurement}`, i.e. "15 ac".
  onView(val: boolean): void;
  openPopUp: boolean;
  isInHistory?: boolean | undefined;
  isChecked: boolean;
  onCheck(val: any): void;
  onEdit?(): void;
  mean?: any;
  priority?: string;
  distanceToCenter?: number;
  excluded?: boolean;
};

const onSelect = (val: any, openPopUp: any, onCheck: Function, onView: Function) => {
  if ((!val && openPopUp) || (val && !openPopUp)) {
    onCheck(val);
    onView(val);
  } else {
    onCheck(val);
  }
};

const AnomalyItem = ({
  startDate,
  endDate,
  title,
  label,
  labelName,
  snoozed,
  area,
  onView,
  openPopUp,
  isInHistory,
  isChecked,
  onCheck,
  onEdit,
  priority,
  distanceToCenter,
  excluded,
}: TProps) => {
  const areaOrDistanceToCenter = distanceToCenter
    ? [
        parseFloat(convertUnit(getMeasurement(), 'm', distanceToCenter)).toFixed(1),
        formatUnit(getMeasurement(), 'm'),
      ].join(' ')
    : area;

  return (
    <div
      className={cs('anomaly-item', {
        'anomaly-item--opened': openPopUp,
        'anomaly-item--history': isInHistory,
      })}
    >
      <div onClick={() => onView(!openPopUp)} className="anomaly-item__back" />
      <div
        className="anomaly-item__checkbox"
        onClick={() => onSelect(!isChecked, openPopUp, onCheck, onView)}
      >
        <>{!isChecked ? <LabelIcon label={labelName} /> : <LabelIcon label={'done'} />}</>
      </div>
      <div className="anomaly-item__info">
        <div className="anomaly-item__dates">
          {endDate
            ? `${moment.utc(startDate).format('D MMM')} - ${moment.utc(endDate).format('D MMM')}`
            : `${moment.utc(startDate).format('D MMM')}`}
          &nbsp;({areaOrDistanceToCenter})
        </div>
        <div className="anomaly-item__label">{label}</div>
        <div className="anomaly-item__title">{title}</div>
      </div>
      <div className="anomaly-item__type">
        {/* For premium anomalies display priority */}
        <div className={`anomaly-item__type-priority ${priority ? priority : ''}`}>{priority}</div>
        {/* For areas of interest display edit button */}
        {onEdit ? <EditIcon className={'edit-geometry-btn edit-icon'} onClick={onEdit} /> : null}
      </div>
      {excluded || snoozed ? (
        <div className="anomaly-item__state">
          {!!snoozed && <Muted className={'snoozed'} />}
          {!!excluded && <PermanentlyExcluded />}
        </div>
      ) : null}
    </div>
  );
};

export default AnomalyItem;
