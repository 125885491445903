import {t, FormattedMessage, useLocale} from 'i18n-utils';
import React, {Component, ReactNode, useEffect} from 'react';
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
// @ts-ignore react-md.d.ts doesn't export ResizeObserver :(
import {ResizeObserver} from 'react-md';
import cn from 'classnames';
import config from '_environment';
import {useLocation, RouteProps, RouteComponentProps} from 'react-router-dom';
import GlobalLoader from 'components/global-loader';
import MiniMenu from 'containers/map/mini-menu/mini-menu';
import {resizeViewport} from '_reducers/viewport';
import {Helmet} from 'react-helmet';
import Dialogs from './dialogs/';
import Header from './header';
import IntercomComponent from 'components/intercom';
import {AppStore} from '../reducers';

type Props = {
  children: ReactNode;
};

const BaseLayout = ({children}: RouteProps & Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuth = useSelector((store: AppStore) => store.login.isAuth);
  const mapDidMount = useSelector((store: AppStore) => store.global.mapDidMount);
  const isWholeFarmView = useSelector((store: AppStore) => store.map.wholeFarm.isWholeFarmView);
  const fieldName = useSelector((store: AppStore) => store.map.field.Name);
  const farmName = useSelector((store: AppStore) => store.map.group.name);

  const externalServices = ['/reportV2'];

  useEffect(() => {
    const fixVH = () => {
      // hack for webkit VH issue
      // calc(var(--vh, 1vh) * 100)
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    fixVH();
    window.addEventListener('resize', fixVH);
  }, []);

  const handleResize = ({width, height}: {width: number; height: number}) => {
    dispatch(resizeViewport(width, height));
  };

  const renderContent = () => {
    if (!isAuth) {
      return (
        <div className="unauthorized-container md-grid">
          <div className="md-cell md-cell--12">
            {children}
            <GlobalLoader />
          </div>
        </div>
      );
    }

    if (externalServices.includes(location.pathname)) {
      // currently is used to render report service
      return <div className={'external-service'}>{children}</div>;
    }

    return (
      <div
        id="global-drawer-nav"
        className={cn({
          'map-v2': mapDidMount,
        })}
      >
        <Header />
        <div className="md-navigation-drawer-content">
          {/* Mini menu is also initialized in map-bar.tsx when mapDidMount */}
          {!mapDidMount && <MiniMenu ln={location.pathname} />}
          {children}
          <GlobalLoader />
        </div>

        <IntercomComponent />
      </div>
    );
  };

  return (
    <>
      <Helmet>
        {config.featurePack === 'carbon'
          ? [
              // disable auto scale onl;y for the crabon landing page
              !isAuth ? (
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, maximum-scale=1"
                />
              ) : null,
              <link rel="shortcut icon" href="/assets/carbon/cargill.ico" key="carbon-favicon" />,

              <title key="carbon-title">{`${farmName ? farmName + ' -' : ''} ${
                !isWholeFarmView ? (fieldName ? fieldName + ' -' : '') : 'Whole Farm -'
              } Cargill`}</title>,
            ]
          : [
              <link rel="shortcut icon" href="/favicon.png" key="flurosense-favicon" />,

              <title key="flurosense-favicon">{`${farmName ? farmName + ' -' : ''} ${
                !isWholeFarmView ? (fieldName ? fieldName + ' -' : '') : 'Whole Farm -'
              } FluroSense`}</title>,
            ]}
      </Helmet>

      {renderContent()}

      <Dialogs />

      <ResizeObserver watchWidth onResize={handleResize} />
    </>
  );
};

export default BaseLayout;
