import {t} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {FluroDialog} from 'components';
import NewFields from '../map/features/farm/new-fields/new-fields';
import {toggleDrawingMode, toggleMapBar} from '../map/actions';
import {
  setUploadFields,
  removeNewFieldsGeometry,
  addFieldChangeCurrentStep,
  AddingFieldsFromMapSteps,
  clearCLUFieldBoundaries,
} from 'modules/add-fields.module';
import cn from 'classnames';
import {FontIcon} from 'react-md';
import {useAppSelector} from '../../_hooks';
import {toggleSelectPlatformsView} from '../profile/integration/actions';

export const AddFieldDialog = () => {
  const dispatch = useDispatch();
  const visible = useAppSelector(s => s.dialog.currentDialog === DialogType.AddNewField);
  const {isDrawingMode, drawingModeLayerType} = useAppSelector(s => s.map.drawControl);
  const drewFieldsGeometries = useAppSelector(s => s.addFields.drewFieldsGeometries);
  const geoJsonFiles = useAppSelector(s => s.addFields.geoJsonFiles);
  const currentStep = useAppSelector(s => s.addFields.addFieldCurrentStep);
  const isSelectPlatformsView = useAppSelector(s => s.integrations.isSelectPlatformsView);

  useEffect(() => {
    // clear on unmount (useful during logout)
    return () => closeAddingWorkflow();
  }, []);

  useEffect(() => {
    if (visible && currentStep === 'add-fields' && drewFieldsGeometries.length) {
      dispatch(addFieldChangeCurrentStep('draw-fields'));
    }

    if (visible && isDrawingMode) {
      // disable drawing mode when the pop-up opens
      dispatch(toggleDrawingMode(false, drawingModeLayerType));
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && isSelectPlatformsView) {
      // automatically disable toggle select platforms view
      dispatch(toggleSelectPlatformsView(false));
    }
    if (visible && !isSelectPlatformsView) {
      // automatically enable toggle select platforms view
      dispatch(toggleSelectPlatformsView(true));
    }
  }, [visible, isSelectPlatformsView]);

  const onHide = () => {
    if (currentStep === 'draw-fields' && drewFieldsGeometries.length) {
      // open the bottom panel again (request by Kelly, not sure this will stay
      dispatch(setUploadFields([]));
      dispatch(removeNewFieldsGeometry());
    } else {
      closeAddingWorkflow();
    }
  };

  const closeAddingWorkflow = () => {
    dispatch(toggleDialog(DialogType.AddNewField, false));
    dispatch(setUploadFields([]));
    dispatch(removeNewFieldsGeometry());
    dispatch(addFieldChangeCurrentStep('add-fields'));
    dispatch(toggleMapBar(true));
    dispatch(toggleDrawingMode(false, 'polygon', 'farm'));
    dispatch(clearCLUFieldBoundaries());
  };

  const hasFieldsToUpload = geoJsonFiles.length || drewFieldsGeometries.length;

  const dialogTitle = useMemo(() => {
    switch (currentStep) {
      case 'add-fields':
        return t({id: 'Add fields'});

      case 'select-boundaries':
        return t({id: 'Select fields on the map'});

      case 'zoom-is-too-low':
        return t({id: 'Zoom level is too low'});

      case 'select-files-to-upload':
        return t({id: 'Upload field boundaries'});

      case 'parse-uploading-files':
        return t({id: 'Review and configure import'});

      case 'view-fields-from-files':
      case 'view-drew-fields':
      case 'view-selected-boundaries':
        return t({id: 'Name and upload your fields'});

      case 'draw-fields':
        return t({id: 'Draw your fields here or search for a location'});

      case 'complete':
        return t({id: 'Field boundaries are successfully set up'});

      default:
        return t({id: 'Add fields'});
    }
  }, [currentStep, geoJsonFiles, drewFieldsGeometries]);

  const expandPopUpWidth = hasFieldsToUpload && currentStep === 'parse-uploading-files';

  const rightAligned = [
    'view-selected-boundaries',
    'view-fields-from-files',
    'view-drew-fields',
  ].includes(currentStep);

  const bottomAligned =
    ['zoom-is-too-low', 'select-boundaries'].includes(currentStep) || 'draw-fields' === currentStep; // stick the pop-up to the bottom

  const reduceDialogSize =
    (currentStep === 'draw-fields' && !(geoJsonFiles.length || drewFieldsGeometries.length)) ||
    'complete' === currentStep;

  if (currentStep === 'search-location') return null;

  return (
    <FluroDialog
      id="field-edit--dialog"
      visible={visible && !isDrawingMode}
      isDraggable
      title={dialogTitle}
      focusOnMount={false}
      portal
      className={cn('field-edit-overlay', {
        'right-aligned': rightAligned,
        'bottom-aligned': bottomAligned,
        [currentStep]: true,
      })}
      dialogClassName={cn({'reduced-size': reduceDialogSize, 'expanded-width': expandPopUpWidth})}
      onHide={onHide}
      stickyHeader
      hideOnClickOutside={!(bottomAligned || rightAligned)}
    >
      {AddingFieldsFromMapSteps.includes(currentStep) && !drewFieldsGeometries.length ? (
        <div onClick={closeAddingWorkflow} className="cancel-adding-fields-mode-btn">
          {t({id: 'Cancel field creation'})}
          <FontIcon>close</FontIcon>
        </div>
      ) : null}

      <NewFields onHide={onHide} />
    </FluroDialog>
  );
};

export default AddFieldDialog;
