import {ResponsiveBar} from '@nivo/bar';
import {ResponsiveLine} from '@nivo/line';
import {InfoBlock} from 'components';
import {Card} from 'components/card/card';
import * as React from 'react';
import {useCallback} from 'react';
import {setOpacity} from '_utils/colors';
import {LegendList} from 'components/legend-list/legend-list';
import {OptisChartTooltip} from './base/chart-tooltip';
import {
  OptisItemWinterCrop,
  OptisState,
  OptisType,
  winterCropColor,
  winterCropLabels,
  winterCropOrder,
  WinterCropType,
} from './optis-types';
import {
  addWinterCropMut,
  createWinterCrop,
  diffPctWinterCrop,
  formatNumber,
  getCategories,
  getTimePeriod,
  sumWinterCrop,
} from './optis-utils';
import {OptisChartDescription, OptisDiffModeDescription} from './base/chart-description';
import {defaultFilterValue} from './optis-reducer';
import {
  ChartData,
  StackedSingleBarChart,
} from 'components/stacked-single-bar-chart/stacked-single-bar-chart';
import {AdoptionContainer} from './base/adoption-container';

export const WinterCropCard = ({
  optis,
  onFilterClick,
}: {
  optis: OptisState;
  onFilterClick: (filter: {year?: number; value?: WinterCropType; multiselect?: boolean}) => void;
}) => {
  const onFilterToggle = useCallback(value => onFilterClick({value}), [onFilterClick]);

  const winterCropKeys = [
    WinterCropType.Perennial,
    WinterCropType.WinterCommodity,
    WinterCropType.CoverCrop,
  ];
  const winterCropData: (OptisItemWinterCrop & {
    year: number;
  })[] = [];
  Object.keys(optis.winterCrop)
    .map(Number)
    .filter(id => optis.filter.geometries.length === 0 || optis.filter.geometries.includes(id))
    .forEach(id => {
      const years = optis.winterCrop[Number(id)];
      Object.keys(years)
        .map(Number)
        // In optis.avgMode mode this data is used for adoption chart, which should show all the years.
        // .filter(year => optis.filter.years.length === 0 || optis.filter.years.includes(year))
        .forEach((year, i) => {
          if (!winterCropData[i]) {
            winterCropData[i] = {
              year,
              ...createWinterCrop(),
            };
          }
          addWinterCropMut(winterCropData[i], years[year]);
        });
    });

  // Collect data in OptisItem format for the selected items, to show it in card legend.
  // Do it before winterCropData is converted to percents, because in legend we need both
  // acres and percentage.
  const winterCropDataForSelectedYearsSummed = winterCropData
    .filter(w => optis.filter.years.length === 0 || optis.filter.years.includes(w.year))
    .reduce((acc, b) => addWinterCropMut(acc, b), createWinterCrop());

  let pctWinterCrop: OptisItemWinterCrop;
  if (optis.diffMode && optis.diffYearA && optis.diffYearB) {
    const a = winterCropData.find(w => w.year === optis.diffYearA);
    const b = winterCropData.find(w => w.year === optis.diffYearB);
    pctWinterCrop = diffPctWinterCrop(a, b);
  } else {
    pctWinterCrop = createWinterCrop();
    const total = sumWinterCrop(winterCropDataForSelectedYearsSummed);
    winterCropOrder.forEach(w => {
      pctWinterCrop[w] = ((winterCropDataForSelectedYearsSummed[w] / total) * 100) | 0;
    });
  }

  const sumDataAcrossYears = winterCropData.reduce((acc, yearOfData) => {
    if (optis.filter.years.includes(yearOfData.year)) {
      winterCropOrder.forEach(t => {
        acc[t] += yearOfData[t];
      });
    }
    return acc;
  }, createWinterCrop());

  const avgYearData: ChartData = {
    values: winterCropOrder.map(t => Math.round(sumDataAcrossYears[t] / winterCropData.length)),
    labels: winterCropOrder,
    colors: winterCropOrder.map(t => winterCropColor[t]),
  };

  // Convert acres to percentage.
  winterCropData.forEach(data => {
    const total = sumWinterCrop(data);
    data[WinterCropType.CoverCrop] = ((data[WinterCropType.CoverCrop] / total) * 100) | 0;
    data[WinterCropType.WinterCommodity] =
      ((data[WinterCropType.WinterCommodity] / total) * 100) | 0;
    data[WinterCropType.Perennial] = ((data[WinterCropType.Perennial] / total) * 100) | 0;
  });

  const lineChartData = {
    [WinterCropType.Perennial]: createLineChartData(WinterCropType.Perennial),
    [WinterCropType.WinterCommodity]: createLineChartData(WinterCropType.WinterCommodity),
    [WinterCropType.CoverCrop]: createLineChartData(WinterCropType.CoverCrop),
  };
  winterCropData.forEach(yearOfData => {
    const order = !optis.avgMode
      ? winterCropOrder
      : optis.filter.type === OptisType.WinterCrop
      ? optis.filter.value
      : defaultFilterValue[OptisType.WinterCrop];
    order.forEach(type => {
      // @ts-ignore in case of optis.avgMode the list of winter crops is wider than 3 winter crop types
      lineChartData[type].data.push({
        x: yearOfData.year,
        y: yearOfData[type],
      });
    });
  });

  const getColor = (d: any) => {
    const selected =
      optis.filter.type === OptisType.WinterCrop && optis.filter.value.includes(d.id);
    const opacity = selected ? 1 : 0.15;
    const color = setOpacity(winterCropColor[d.id as WinterCropType], opacity, true);
    return color;
  };

  winterCropOrder.forEach(key => {
    // @ts-ignore
    if (!lineChartData[key].data.length) {
      // @ts-ignore
      delete lineChartData[key];
    }
  });

  if (!Object.keys(lineChartData).length) {
    return null;
  }

  const timePeriod = getTimePeriod(
    optis.diffMode && optis.diffYearA && optis.diffYearB
      ? [optis.diffYearA, optis.diffYearB]
      : Object.values(winterCropData).map(y => y.year)
  );
  const {verb, categories} = getCategories(optis.filter, OptisType.WinterCrop);

  const legendList = [...winterCropKeys].reverse().map(winterCrop => ({
    label: winterCropLabels[winterCrop],
    value: winterCrop,
    deselected:
      optis.filter.type === OptisType.WinterCrop &&
      optis.filter.value.length &&
      !optis.filter.value.includes(winterCrop),
    backgroundColor: winterCropColor[winterCrop],
    percentage: pctWinterCrop[winterCrop],
    count:
      optis.diffMode && optis.diffYearA && optis.diffYearB
        ? ''
        : `(${formatNumber(winterCropDataForSelectedYearsSummed[winterCrop])} ac)`,
  }));
  const percentageSum = legendList.reduce((acc, v) => acc + v.percentage, 0);

  return (
    <Card
      active={optis.filter.type === OptisType.WinterCrop}
      title="Winter crops"
      info={
        optis.avgMode ? undefined : (
          <InfoBlock>Use shift+click to select multiple years / categories.</InfoBlock>
        )
      }
      onClick={() => onFilterClick({})}
      isReportView={false}
      // onFilterClear={onFilterClear}
    >
      {optis.diffMode && optis.diffYearA && optis.diffYearB ? (
        <OptisDiffModeDescription categories={categories} timePeriod={timePeriod} />
      ) : (
        <OptisChartDescription verb={verb} categories={categories} timePeriod={timePeriod} />
      )}
      {percentageSum > 0 && !optis.diffMode && (
        <div style={{height: optis.avgMode ? 'auto' : 200, marginBottom: 10}}>
          {optis.avgMode ? (
            <StackedSingleBarChart
              data={avgYearData}
              selectedLabels={optis.filter.value}
              onFilterToggle={onFilterToggle}
              // onShowTooltip={onShowTooltip}
            />
          ) : winterCropData.length === 1 ? (
            <BarChart
              keys={winterCropKeys}
              data={winterCropData}
              getColor={getColor}
              onFilterClick={onFilterClick}
            />
          ) : (
            <LineChart
              data={Object.values(lineChartData)}
              getColor={getColor}
              onFilterClick={onFilterClick}
            />
          )}
        </div>
      )}
      <LegendList
        onClick={(winterCrop, e) => {
          onFilterClick({
            value: winterCrop as WinterCropType,
            multiselect: e.metaKey || e.shiftKey || e.ctrlKey,
          });
        }}
        diffMode={optis.diffMode && optis.diffYearA != null && optis.diffYearB != null}
        list={legendList}
      />
      {optis.avgMode && (
        <AdoptionContainer
          title={
            optis.filter.type === OptisType.WinterCrop
              ? optis.filter.value.map(t => winterCropLabels[t]).join(', ')
              : defaultFilterValue[OptisType.WinterCrop].map(t => winterCropLabels[t]).join(', ')
          }
        >
          <LineChart data={Object.values(lineChartData)} getColor={getColor} />
        </AdoptionContainer>
      )}
    </Card>
  );
};

type LineChartData = {
  id: WinterCropType;
  color: string;
  data: {x: number; y: number}[]; // x: year, y: value
};
const createLineChartData = (t: WinterCropType): LineChartData => ({
  id: t,
  color: winterCropColor[t],
  data: [],
});

const LineChart = ({
  data,
  getColor,
  onFilterClick,
}: {
  data: LineChartData[];
  getColor: (d: any) => string;
  onFilterClick?: (filter: {year?: number; value?: WinterCropType; multiselect?: boolean}) => void;
}) => {
  return (
    <ResponsiveLine
      data={data}
      enablePoints={false}
      enableArea={true}
      areaOpacity={1}
      yScale={{type: 'linear', stacked: true}}
      colors={getColor}
      enableGridX={false}
      gridYValues={4}
      enableCrosshair={false}
      margin={{top: 20, bottom: 20, left: 30, right: 12}}
      tooltip={data => {
        const value = data.point.data.y;
        const label = winterCropLabels[data.point.serieId as WinterCropType];
        const color = winterCropColor[data.point.serieId as WinterCropType];
        return (
          <OptisChartTooltip>
            <span className="item-color" style={{backgroundColor: color}} />
            {label}: {value}%
          </OptisChartTooltip>
        );
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
      }}
      axisLeft={{
        tickValues: 3,
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: (v: number) => `${v}%`,
      }}
      animate={false}
      useMesh={true}
      onClick={(bar, e) => {
        onFilterClick?.({
          value: bar.serieId as WinterCropType,
          multiselect: e.metaKey || e.shiftKey || e.ctrlKey,
        });
      }}
    />
  );
};

const BarChart = ({
  keys,
  data,
  getColor,
  onFilterClick,
}: {
  keys: WinterCropType[];
  data: (OptisItemWinterCrop & {
    year: number;
  })[];
  getColor: (d: any) => string;
  onFilterClick: (filter: {year?: number; value?: WinterCropType; multiselect?: boolean}) => void;
}) => {
  return (
    <ResponsiveBar
      keys={keys}
      indexBy="year"
      data={data}
      colors={getColor}
      // labelSkipHeight={20}
      enableLabel={false}
      enableGridX={false}
      gridYValues={4}
      margin={{top: 20, bottom: 20, left: 40}}
      tooltip={data => {
        const total = sumWinterCrop(data.data as OptisItemWinterCrop);
        const label = winterCropLabels[data.id as WinterCropType];
        const color = winterCropColor[data.id as WinterCropType];
        const value = ((data.value / total) * 100) | 0;
        return (
          <div>
            <span className="item-color" style={{backgroundColor: color}} />
            {label}: {value}%
          </div>
        );
      }}
      borderWidth={2}
      borderColor="white"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
      }}
      axisLeft={{
        tickValues: 5,
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: (v: number) => `${v}%`,
      }}
      onClick={(bar, e) => {
        onFilterClick({
          year: bar.indexValue as number,
          value: bar.id as WinterCropType,
          multiselect: e.metaKey || e.shiftKey || e.ctrlKey,
        });
      }}
    />
  );
};
