import {Field} from 'containers/map/types';

type RGBA = {
  r: number;
  g: number;
  b: number;
  a: number;
};

type HSL = {
  h: number;
  s: number;
  l: number;
};

/**
 * Sets opacity to the color.
 * Example: setOpacity('#ff0000', 0.2) => rgba(255, 0, 0, 0.2)
 *
 * @param color #ff0000
 * @param opacity 0..1
 * @param noAlpha true means the opacity will be applied to white background and the color will change,
 *                but without using alpha channel
 */
export const setOpacity = (color: string = '#ff0000', opacity: number, noAlpha = false): string => {
  const {r, g, b} = toRgbaType(color);
  const rgba = {r, g, b, a: opacity};
  return rgbaToHex(noAlpha ? applyAlpha(rgba) : rgba);
};

export const getLuma = (color: string) => {
  const {r, g, b} = toRgbaType(color);
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma;
};

export const toRgbaType = (color: string): RGBA => {
  switch (true) {
    // Parse hex: #ff0000 => {r: 255, g: 0, b: 0, a: 1}
    case color.startsWith('#') && color.length === 7: {
      const r = color.substr(1, 2);
      const g = color.substr(3, 2);
      const b = color.substr(5, 2);
      return {
        r: parseInt(r, 16),
        g: parseInt(g, 16),
        b: parseInt(b, 16),
        a: 1,
      };
    }
    case color.startsWith('rgb'): {
      const [, rgba] = captureParensRegexp.exec(color);
      const [r, g, b, a] = rgba.split(',');
      return {r: Number(r), g: Number(g), b: Number(b), a: Number(a) || 0};
    }
    default: {
      return {r: 0, g: 0, b: 0, a: 0};
    }
  }
};

export const rgbaToString = (rgba: RGBA) => {
  const {r, g, b, a} = rgba;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const rgbaToHex = (rgba: RGBA) => {
  const {r, g, b, a} = rgba;
  return `#${to16(r)}${to16(g)}${to16(b)}${to16((a * 255) | 0)}`;
};

const to16 = (x: number) => (x === 0 ? '00' : x.toString(16));

/**
 * Applies alpha channel to the color using the white background and returning a color
 * with alpha = 1, but new r, g, b values.
 */
export const applyAlpha = (rgba: RGBA): RGBA => {
  const white = {r: 255, g: 255, b: 255, a: 1};
  // const white = {r: 0, g: 0, b: 0, a: 1}; // this is black :)
  return {
    r: ((1 - rgba.a) * white.r + rgba.a * rgba.r) | 0,
    g: ((1 - rgba.a) * white.g + rgba.a * rgba.g) | 0,
    b: ((1 - rgba.a) * white.b + rgba.a * rgba.b) | 0,
    a: 1,
  };
};

/**
 * Linear interpolation of colors (gradient).
 * @param colorA
 * @param colorB
 * @param t 0..1
 */
export const lerpColor = (colorA: string, colorB: string, t: number) => {
  const A = toRgbaType(colorA);
  const B = toRgbaType(colorB);
  const r = lerp(A.r, B.r, t);
  const g = lerp(A.g, B.g, t);
  const b = lerp(A.b, B.b, t);
  return rgbaToString({r, g, b, a: 1});
};

const lerp = (a: number, b: number, t: number) => {
  return a + (b - a) * t;
};

// https://en.wikipedia.org/wiki/HSL_and_HSV
export const toHSL = (color: string) => {
  const {r, g, b} = toRgbaType(color);
  const Xmin = Math.min(r, g, b);
  const Xmax = Math.max(r, g, b);
  const V = Xmax;
  const C = Xmax - Xmin;
  const L = V - C / 2;
  let H = 0;
  switch (V) {
    case r:
      H = 60 * (0 + (g - b) / C);
      break;
    case g:
      H = 60 * (2 + (b - r) / C);
      break;
    case b:
      H = 60 * (4 + (r - g) / C);
      break;
  }
  const Sv = V === 0 ? 0 : C / V;
  const Sl = L === 0 || L === 1 ? 0 : (V - L) / Math.min(L, 1 - L);
  // Normalize lightness to 256.
  return {
    h: H,
    s: Sl < 0 ? Sl * -1 : Sl,
    l: L / 256,
  };
};

export const hslToString = (hsl: HSL) => {
  const {h, s, l} = hsl;
  return `hsl(${h}deg ${(s * 100) | 0}% ${(l * 100) | 0}%)`;
};

export const setSaturation = (color: string, s: number) => {
  const {h, l} = toHSL(color);
  return hslToString({h, s, l});
};

const captureParensRegexp = /\((.*)\)/;

// See how the colors look like in `variables.scss`
export class Colors {
  public nextColor() {
    this.index = (this.index + 1) % this.colors.length;
    return this.colors[this.index];
  }
  private index = 0;
  private colors = [
    '#dd2d44',
    '#6fcfe0',
    '#f73c3c',
    '#b49fda',
    '#ff85c6',
    '#967850',
    '#b6d7a8',
    '#9900ff',
    '#379d1f',
    '#efc436',
    '#c88b45',
    '#47ab95',
    '#d9b78b',
    '#c4663d',
    '#e8c226',
    '#46569f',
    '#0088ae',
    '#be95b6',
    '#bdcf2b',
    '#ffe8fc',
    '#bf2b20',
    '#A1C3E3',
    '#fff667',
    '#ff9100',
    '#c934ca',
    '#9ec30f',
    '#acf1c6',
    '#6aa84f',
    '#ffff00',
    '#073763',
  ];
}

const colors = new Colors();
export const populateColors = (
  fields: Field[],
  cropVarietyColors: {[cropVariety: string]: string}
) => {
  const newColors = {...cropVarietyColors};
  fields.forEach(f => {
    f.Seasons?.forEach(s => {
      const variety = s.params?.cropSubType;
      if (!newColors[variety]) {
        newColors[variety] = colors.nextColor();
      }
    });
  });
  return newColors;
};

const SOIL_TYPE_COLORS: {[key: string]: string} = {
  Clay: '#8FEDE1',
  'Clay loam': '#89C6FF',
  Loam: '#3C669D',
  'Loamy coarse sand': '#A93876',
  'Loamy fine sand': '#F68C8C',
  'Loamy sand': '#B18EB7',
  'Loamy very fine sand': '#F6E2FD',
  Sand: '#FDF3D9',
  'Sandy clay': '#EFDB73',
  'Sandy clay loam': '#F2B828',
  'Sandy loam': '#F29B28',
  'Coarse sand': '#CC6F00',
  'Coarse sandy loam': '#BA696A',
  'Fine sand': '#736F54',
  'Fine sandy loam': '#A2460E',
  'Very fine sand': '#EAEB98',
  'Very fine sandy loam': '#C2DF4E',
  Silt: '#DBF3DB',
  'Silt loam': '#94DD94',
  'Silty clay': '#39A801',
  'Silty clay loam': '#418B71',
};

export const getSoilColorByType = (soilType: string) => {
  return SOIL_TYPE_COLORS[soilType] || '#ccc';
};

// copy from Variables.scss
export const main_app_color = '#43a047';
export const main_info_color = '#546e7a';
export const regrow_dark_green = '#044827';
export const regrow_medium_green = '#96ad67';
export const regrow_blue = '#93bfbc';

export const main_gray_900 = '#000000de';
export const main_gray_800 = '#464646';
export const main_gray_700 = '#606060';
export const main_gray_600 = '#7a7a7a';
export const main_gray_500 = '#909090';
export const main_gray_400 = '#c2c2c2';
export const main_gray_200 = '#f1f1f1';
export const main_gray_100 = '#f7f7f7';

export const main_green_900 = '#005c00';
export const main_green_800 = '#1e7a14';
export const main_green_600 = '#399d2b';
export const main_green_400 = '#5fb854';
export const main_green_200 = '#a2d29b';
export const main_green_50 = '#e7f5e6';
