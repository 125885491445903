import {FormattedMessage, t} from 'i18n-utils';
import * as React from 'react';
import {Button} from 'react-md';
import cn from 'classnames';
import IntegrationCard from './helpers/integration-card';
import ColoredBorder from './helpers/colored-border';
import {Ln} from 'components';
import {ExternalService} from '../../map/types';
import {useAppSelector} from '_hooks';

type Props = {
  expanded: boolean;
  setExpanded(): void;
};

const IntegrationClimate = ({expanded, setExpanded}: Props) => {
  const platform = useAppSelector(store => store.integrations.platforms[ExternalService.Agrian]);

  return (
    <div className={cn('integration-platform agrian', {expanded, selected: platform.selected})}>
      <IntegrationCard
        authorized={true}
        expanded={expanded}
        logoSrc={'/assets/integration/agrian-logo-round.svg'}
        logoAlt={'logo-climate'}
        onAuthorize={() => null}
        onToggle={setExpanded}
        platformName={'Agrian'}
        platformCompanyName={'Agrian'}
        platformInnerName={ExternalService.Agrian}
        isPlatformSelected={platform.selected}
        customAuthButton={null}
      />

      {expanded && (
        <>
          <div className="integration-help-text">
            <FormattedMessage
              id="Please contact us if you want to import data from Agrian, we'll need access to your account details and the list of farms you would like to import. <a>Read more about the connection process.</a>"
              defaultMessage="Please contact us if you want to import data from Agrian, we'll need access to your account details and the list of farms you would like to import. <a>Read more about the connection process.</a>"
              values={{
                a: (txt: string) => (
                  <Ln
                    href={
                      'https://help.flurosense.com/en/articles/4790718-how-to-connect-your-agrian-or-efc-fieldalytics-account'
                    }
                    external
                    blank
                  >
                    {txt}
                  </Ln>
                ),
              }}
            />
          </div>

          <div className={'md-text-right'}>
            <Button
              raised
              primary
              onClick={() => document.getElementById('intercom-launch')?.click()}
            >
              {t({id: 'Contact us'})}
            </Button>
          </div>
        </>
      )}
      <ColoredBorder colorStart={'rgb(43, 69, 124)'} colorEnd={'rgba(43, 69, 124, 0.2)'} />
    </div>
  );
};

export default IntegrationClimate;
