import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {ImageOverlay} from 'leaflet';
import '../../leaflet-side-by-side';
import {FluroImageOverlay} from 'components';
import {connect, ConnectedProps} from 'react-redux';
import {getLayerImageUrl} from '../../utils/utils';
import {drawCompareTooltips} from '../../utils';
import {IInitialMapState} from '../../types';
import L from 'leaflet';

type State = {
  layerGroupKey: number;
};

type Props = ConnectedProps<typeof connector> & {
  bounds: L.LatLngBoundsExpression;
  leafletElement: L.Map;
  compareOriginalRef: any;
};

class CompareOverlay extends PureComponent<Props, State> {
  layer?: L.LayerGroup | L.ImageOverlay = null;
  sbs: any = null;
  state = {
    layerGroupKey: 0,
  };

  fieldRef = (node: ImageOverlay) => {
    const {currentSensor, currentSensorCompare, currentDate, currentCompareDate} = this.props;

    this.sbs && this.sbs.remove();

    if (!node) {
      return;
    }

    this.layer = node;
    this.sbs = L.control
      //@ts-ignore
      .sideBySide(this.props.compareOriginalRef, this.layer)
      .addTo(this.props.leafletElement);

    drawCompareTooltips(currentSensor, currentSensorCompare, currentDate, currentCompareDate);
  };

  componentWillUnmount() {
    this.layer = null;
    this.sbs && this.sbs.remove();
  }

  componentDidUpdate(prevProps: Props) {
    const {currentSensor, currentSensorCompare, currentDate, currentCompareDate} = this.props;
    drawCompareTooltips(currentSensor, currentSensorCompare, currentDate, currentCompareDate);
  }

  render() {
    const {currentSensorCompare, currentCompareDate, bounds} = this.props;

    if (currentSensorCompare === 'NONE' && !currentCompareDate) {
      return null;
    }
    return (
      <FluroImageOverlay
        isCompare
        lRef={this.fieldRef}
        bounds={bounds}
        url={getLayerImageUrl(true)}
      />
    );
  }
}

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  currentSensorCompare: map.currentSensorCompare,
  currentCompareDate: map.currentCompareDate,
  currentSensor: map.currentSensor,
  colorSchema: map.colorSchema,
  imageLayerOpacity: map.imageLayerOpacity,
  treeDetection: map.treeDetection,
  currentDate: map.currentDate,
});

const connector = connect(mapStateToProps);

export default connector(CompareOverlay);
