import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback} from 'react';
import {Button} from 'react-md';
import {AdminApi} from '_api';
import {showNote} from '_actions';
import {useDispatch} from 'react-redux';
import {KeyIcon} from 'components/icons';
import styled from 'styled-components';
import {dialogToggle} from 'modules/ui-helpers';

type Props = {
  userId: number;
  email: string;
  isProfileView?: boolean;
};

const KeyIconButton = styled(KeyIcon)`
  cursor: pointer;
  margin-left: 15px;
`;

const ImpersonateButton = ({email, isProfileView, userId}: Props) => {
  const dispatch = useDispatch();

  const onLogin = useCallback(() => {
    try {
      dispatch(
        dialogToggle('confirm', true, {
          okLabel: 'login',
          okAction: async () => {
            const {data} = await AdminApi.impersonateAsUser(userId);
            localStorage.setItem('token', data.result.token);

            //@ts-ignore;
            window.location = '/app/profile';
          },
          title: 'Login as user',
          message: (
            <span>
              Do you want to login as <b>{email}</b>?
            </span>
          ),
        })
      );
    } catch (e) {
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: e.message,
          level: 'warning',
        })
      );
    }
  }, [userId, email]);

  if (isProfileView) {
    return (
      <Button iconEl={<KeyIcon size={16} />} onClick={onLogin} raised>
        {t({id: 'Login as user'})}
      </Button>
    );
  }

  return <KeyIconButton size={16} onClick={onLogin} />;
};

export default ImpersonateButton;
