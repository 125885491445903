import {t} from 'i18n-utils';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {removeFarm} from 'containers/farm/actions';

import {FluroDialog, DeleteDialog} from 'components';
import FarmEdit from 'containers/farm/edit';
import {FarmPayload} from '../types';
import {isAdminPerm} from '_utils';
import {useAppDispatch, useAppSelector} from '_hooks';
import {dialogToggle, DialogType} from '../../../../../modules/ui-helpers';

const EditFarmDialog = () => {
  const dispatch = useDispatch();
  const userDefaultOrganization = useAppSelector(store => store.login.user.defaultOrganizationID);
  const isAdmin = isAdminPerm(useAppSelector(store => store.login.user.perm));
  const farm = useAppSelector(state => state.uiHelpers.dialogs[DialogType.editFarm].payload);

  const onSubmit = () => {
    dispatch(removeFarm(farm.id));
  };
  const onHide = () => {
    dispatch(dialogToggle(DialogType.editFarm, false));
  };

  return (
    <>
      <FluroDialog
        id="farm-edit--dialog"
        visible={true}
        isDraggable
        title={farm.id ? t({id: 'Edit farm'}) : t({id: 'Add new farm'})}
        focusOnMount={false}
        portal
        onHide={onHide}
        width={500}
      >
        <FarmEdit
          onSaveDone={onHide}
          onDelete={() => {
            dispatch(
              dialogToggle(DialogType.deleteDialog, true, {
                title: t({id: 'Delete farm?'}),
                onSubmit,
              })
            );
          }}
          farm={farm}
          userDefaultOrganization={userDefaultOrganization}
          isAdmin={isAdmin}
        />
      </FluroDialog>
    </>
  );
};

export default EditFarmDialog;
