import moment from 'moment';
import {AppStore, AppThunk, AppThunkDispatch} from 'reducers';
import {CPFilter, CPFilterState, CPFilterType} from './field-filter-types';
import {applyFilters} from './field-filter-utils';
import {GlobalActionTypes, SetGlobalParamAction} from '_reducers/global_types';
import {Field} from 'containers/map/types';
import {CSGViewModel} from 'containers/map/features/crop-performance/types';
import {GLOBAL_APP_DATE_FORMAT, SERVER_FORMAT_DATE} from '_constants';
import Mixpanel from '_utils/mixpanel-utils';

const defaultFilters: CPFilterState['filters'] = {
  [CPFilterType.CROP_TYPE]: [],
  [CPFilterType.CROP_VARIETY]: [],
  [CPFilterType.CROP_STATUS]: [],
  [CPFilterType.TREE_STATUS]: [],
  [CPFilterType.CROP_GROWTH]: [],
  [CPFilterType.FIELDS_VARIABILITY]: [],
  [CPFilterType.CROP_STRESS]: [],
  [CPFilterType.BIOMASS]: [],
  [CPFilterType.FIELDS_WITH_PLANTING_AREAS]: [],
  [CPFilterType.BIOMASS_OVER_TIME]: [],
};

const initialState: CPFilterState = {
  filters: defaultFilters,
  filterStatus: {},
};

export default (state = initialState, action: Action): CPFilterState => {
  switch (action.type) {
    case ActionType.UPDATE_FILTERS: {
      return {
        ...state,
        filters: action.filters,
        filterStatus: action.status,
      };
    }

    // Change farm.
    case GlobalActionTypes.SET_GLOBAL_PARAM: {
      if (action.propName !== 'currentGroupId') {
        return state;
      }
      return {
        ...state,
        filters: defaultFilters,
        filterStatus: {},
      };
    }

    default:
      return state;
  }
};

enum ActionType {
  UPDATE_FILTERS = 'crop-performance/update-filters',
  TOGGLE_FILTER = 'crop-performance/toggle-filter',
  CLEAR_FILTER = 'crop-performance/clear-filter',
  CLEAR_ALL_FILTERS = 'crop-performance/clear-all-filters',
}

type Action = UpdateFiltersAction | SetGlobalParamAction<'currentGroupId'>;

type UpdateFiltersAction = {
  type: ActionType.UPDATE_FILTERS;
  filters: CPFilterState['filters'];
  status: CPFilterState['filterStatus'];
};

export const toggleFilter = (filter: CPFilter): AppThunk<UpdateFiltersAction> => (
  dispatch: AppThunkDispatch<UpdateFiltersAction>,
  getState: () => AppStore
) => {
  const {cropPerformance, map, cropPerformanceFilter} = getState();
  const records = cropPerformance.records;
  const fields = Object.keys(map.fieldsByFarmId)
    .filter(farmId => cropPerformance.farms[Number(farmId)])
    .flatMap(farmId => Object.values(map.fieldsByFarmId[Number(farmId)]));

  // 1. Update filters.
  const filters = cropPerformanceFilter.filters[filter.type];
  const newFilterType = filters.filter(f => f.value !== filter.value);
  const filterIsNew = newFilterType.length === filters.length;
  let newFilters = {
    ...cropPerformanceFilter.filters,
    [filter.type]: newFilterType,
  };

  if (filterIsNew) {
    newFilterType.push(filter);
  }

  if (filter.type === CPFilterType.CROP_TYPE) {
    // clear crop variety filters if crop type filter was updated
    newFilters[CPFilterType.CROP_VARIETY] = [];
  }

  // Cut out the "-satellite" suffix.
  const date = moment(map.currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE);
  // 2. Apply new filters.
  const status = applyFilters(fields, records, newFilters, date, cropPerformance);
  // If all the filters are selected, reset them.
  // Visually it will be the same,
  // but the next filter click will be "select one" instead of "deselect one" action.
  const everythingPassed = !Object.values(status).find(v => !!v);

  if (everythingPassed && filter.type !== CPFilterType.BIOMASS_OVER_TIME) {
    newFilters[filter.type] = [];
  }

  // Mixpanel
  Mixpanel.cropPerformance_ApplyFilter(
    filter.type,
    filter.value,
    newFilterType.map(f => f.value)
  );

  dispatch({
    type: ActionType.UPDATE_FILTERS,
    filters: newFilters,
    status,
  });
};

export const onFieldsUpdated = (fields: Field[]): AppThunk<UpdateFiltersAction> => (
  dispatch: AppThunkDispatch<UpdateFiltersAction>,
  getState: () => AppStore
) => {
  const {cropPerformance, cropPerformanceFilter, map} = getState();
  const records = cropPerformance.records;
  const filters = cropPerformanceFilter.filters;

  // Cut out the "-satellite" suffix.
  const date = moment(map.currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE);
  const status = applyFilters(fields, records, filters, date, cropPerformance);

  dispatch({
    type: ActionType.UPDATE_FILTERS,
    filters,
    status,
  });
};

export const onRecordsUpdated = (records: CSGViewModel[]): AppThunk<UpdateFiltersAction> => (
  dispatch: AppThunkDispatch<UpdateFiltersAction>,
  getState: () => AppStore
) => {
  const {cropPerformance, cropPerformanceFilter, map} = getState();
  const fields = Object.values(map.fieldsByFarmId).flatMap(fields => Object.values(fields));
  let filters = cropPerformanceFilter.filters;

  // Cut out the "-satellite" suffix.
  const date = moment(map.currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE);
  const status = applyFilters(fields, records, filters, date, cropPerformance);

  dispatch({
    type: ActionType.UPDATE_FILTERS,
    filters,
    status,
  });
};

export const clearFilter = (filterType: CPFilterType): AppThunk<UpdateFiltersAction> => (
  dispatch: AppThunkDispatch<UpdateFiltersAction>,
  getState: () => AppStore
) => {
  const {cropPerformance, map, cropPerformanceFilter} = getState();
  const records = cropPerformance.records;
  const fields = Object.values(map.fieldsByFarmId).flatMap(fields => Object.values(fields));

  // 1. Update filters.
  const newFilters: CPFilterState['filters'] = {
    ...cropPerformanceFilter.filters,
    [filterType]: [],
  };

  if (filterType === CPFilterType.CROP_TYPE) {
    newFilters[CPFilterType.CROP_VARIETY] = []; // clear variety filter with the crop type
  }

  // Cut out the "-satellite" suffix.
  const date = moment(map.currentDate, GLOBAL_APP_DATE_FORMAT).format(SERVER_FORMAT_DATE);

  // 2. Apply new filters.
  const status = applyFilters(fields, records, newFilters, date, cropPerformance);

  dispatch({
    type: ActionType.UPDATE_FILTERS,
    filters: newFilters,
    status,
  });
};

export const clearAllFilters = (): AppThunk<UpdateFiltersAction> => (
  dispatch: AppThunkDispatch<UpdateFiltersAction>,
  getState: () => AppStore
) => {
  const {map} = getState();
  const status: CPFilterState['filterStatus'] = {};
  map.fields.forEach(f => (status[f.ID] = undefined));
  dispatch({
    type: ActionType.UPDATE_FILTERS,
    filters: defaultFilters,
    status,
  });
};
