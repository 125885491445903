import {t} from 'i18n-utils';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import './index.scss';
import ToogleFieldInfo from './toggle-field-info-btn';
import ToggleWeather from './toggle-field-weather';
import ToggleTreeDetection from './toggle-tree-detection';
import ToggleAnomalies from './toggle-anomalies-btn';
import L from 'leaflet';
import DrawingTools from './drawing-tools';
import ToggleLayerSource from './toggle-layers-source';
import {AppStore} from '../../../reducers';
import {AddingFieldsFromMapSteps} from '../../../modules/add-fields.module';
import cn from 'classnames';
import {LocationTracking} from '../location-tracking/location-tracking';
import {ZoomControl} from 'react-leaflet';
import LocationButtons from './location-buttons';
import LocationMarker from '../overlays/location-marker';
import Control from 'containers/map/lib/control';

const MapButtons = ({
  leafletElement,
  fitBounds,
  hasFarms = true,
}: {
  leafletElement: L.Map;
  fitBounds: () => void;
  hasFarms?: boolean;
}) => {
  const isMapBarOpen = useSelector((store: AppStore) => store.map.isMapBarOpen);
  const selectedFieldId = useSelector((store: AppStore) => store.map.selectedFieldId);
  const isWholeFarmView = useSelector((store: AppStore) => store.map.wholeFarm.isWholeFarmView);
  const feature = useSelector((store: AppStore) => store.map.feature);
  const isReadOnly = useSelector((store: AppStore) => store.map.group.readOnly);
  const addFieldCurrentStep = useSelector((s: AppStore) => s.addFields.addFieldCurrentStep);
  const {isDrawingMode, drawingModeLayerType, isEditingMode} = useSelector(
    (store: AppStore) => store.map.drawControl
  );

  const drawStatus = isDrawingMode ? drawingModeLayerType : isEditingMode ? 'edit' : '';

  useEffect(() => {
    const scaleElement = document.querySelector('.leaflet-control-scale') as HTMLElement;
    if (scaleElement) {
      scaleElement.style.right = isMapBarOpen ? '400px' : '50px';
      scaleElement.style.top = isMapBarOpen ? '21px' : '0';
    }
  }, [isMapBarOpen, hasFarms]);

  const fieldDependingAllowed = !isWholeFarmView && !!selectedFieldId;
  const featureDependingAllowed = feature !== 'crop-performance';
  const addingFieldFlowDependingAllowed = !AddingFieldsFromMapSteps.includes(addFieldCurrentStep);
  const shouldAddPadding = ['zoom-is-too-low', 'select-boundaries', 'draw-fields'].includes(
    addFieldCurrentStep
  );

  return (
    <>
      {/* Top left controls */}
      <LocationButtons leafletElement={leafletElement} fitBounds={fitBounds} />
      <LocationMarker leafletElement={leafletElement} />
      <LocationTracking leafletElement={leafletElement} />
      <ZoomControl position="topleft" />
      <MeasureAndScaleControls leafletElement={leafletElement} />

      {/* Bottom left controls */}
      {feature !== 'optis' && (
        <Control position="bottomleft">
          <div
            className={cn(`toggle-elements-map onboarding__buttons-on-map`, {
              'adding-fields': shouldAddPadding,
            })}
            style={{bottom: hasFarms ? 50 : 0}}
          >
            {addingFieldFlowDependingAllowed &&
              fieldDependingAllowed &&
              featureDependingAllowed &&
              hasFarms && <ToogleFieldInfo />}

            <DrawingTools
              drawStatus={drawStatus}
              leafletElement={leafletElement}
              isWholeFarmView={isWholeFarmView}
              isReadOnly={isReadOnly}
              selectedFieldId={selectedFieldId}
            />

            {addingFieldFlowDependingAllowed &&
              fieldDependingAllowed &&
              featureDependingAllowed && <ToggleWeather />}
            {addingFieldFlowDependingAllowed && fieldDependingAllowed && <ToggleAnomalies />}
            <ToggleLayerSource />
            {addingFieldFlowDependingAllowed && !!selectedFieldId && featureDependingAllowed && (
              <ToggleTreeDetection />
            )}
          </div>
        </Control>
      )}
    </>
  );
};

/**
 * A separate component for Measure and Scale just to make sure the init is called
 * after the location components are initilized, so the order of appearance is as intended.
 */
const MeasureAndScaleControls = ({leafletElement}: {leafletElement: L.Map}): React.ReactElement => {
  const measurement = useSelector((store: AppStore) => store.login.user.settings.measurement);
  const isMapBarOpen = useSelector((store: AppStore) => store.map.isMapBarOpen);

  useEffect(() => {
    const measureCleanup = initMeasureControl({measurement, leafletElement});
    const scaleCleanup = initScaleControl({measurement, leafletElement, isMapBarOpen});
    return () => {
      measureCleanup();
      scaleCleanup();
    };
  }, []);
  return null;
};

const initMeasureControl = ({
  measurement,
  leafletElement,
}: {
  measurement: string;
  leafletElement: L.Map;
}) => {
  const isUS = measurement === 'ac';
  const halfLimit = isUS ? 800 : 500;

  // Measure is a leaflet plugin which doesn't provide type extension for L.Control.
  // @ts-ignore
  let measureControl = new L.Control.Measure({
    position: 'topleft',
    primaryAreaUnit: isUS ? 'acres' : 'hectares',
    secondaryAreaUnit: isUS ? 'sqfeet' : 'sqmeters',
    primaryLengthUnit: isUS ? 'feet' : 'meters',
    // primaryLengthUnit: isUS ? 'miles' : 'kilometers',
    secondaryLengthUnit: '',
    activeColor: '#4e78b5',
    completedColor: '#3387fe',
  });

  measureControl.addTo(leafletElement);

  const onMeasure = ({length}: {length: number}) => {
    if (length === 0) return false;

    if (length > halfLimit) {
      measureControl.update({
        primaryLengthUnit: isUS ? 'miles' : 'kilometers',
      });
    } else {
      measureControl.update({
        primaryLengthUnit: isUS ? 'feet' : 'meters',
      });
    }
  };
  // Leaflet Measure plugin extends the event with length property.
  // @ts-ignore
  leafletElement.on('measureclick', onMeasure);

  const measureControlToggle = document.querySelector(
    '.leaflet-control-measure-toggle'
  ) as HTMLElement;
  const onMeasureToggle = () => {
    (document.querySelector('.js-start.start') as HTMLElement).click();
  };
  measureControlToggle.addEventListener('click', onMeasureToggle);
  measureControlToggle.title = t({id: 'Measure', defaultMessage: 'Measure'});
  const i = document.createElement('i');
  i.className = 'fas fa-ruler-vertical';
  measureControlToggle.appendChild(i);

  const cleanup = () => {
    i.remove();
    // @ts-ignore
    leafletElement.off('measureclick', onMeasure);
    measureControlToggle.removeEventListener('click', onMeasureToggle);
    measureControl.remove();
  };
  return cleanup;
};

const initScaleControl = ({
  measurement,
  leafletElement,
  isMapBarOpen,
}: {
  measurement: string;
  leafletElement: L.Map;
  isMapBarOpen: boolean;
}) => {
  const control = L.control
    .scale({
      position: 'bottomright',
      metric: measurement === 'ha',
      imperial: measurement === 'ac',
    })
    .addTo(leafletElement);
  const scaleElement = document.querySelector('.leaflet-control-scale') as HTMLElement;
  if (scaleElement) {
    scaleElement.style.right = isMapBarOpen ? '400px' : '50px';
    scaleElement.style.top = isMapBarOpen ? '21px' : '0';
    scaleElement.style.transition = 'right 0.5s, top 0.5s';
  }
  const cleanup = () => control.remove();
  return cleanup;
};

export default MapButtons;
