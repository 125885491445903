import {AppStore} from 'reducers';
import {CarbonPlanPayload, CarbonSequestrationPayload} from '_api/carbon';
import {CarbonPractice, carbonYearsZA} from './base';
import {PracticeStats} from './carbon-outcomes';
import {Field} from '../../map/types';
import {isCarbonAllowedRegion} from '../utils';
import {getCropPracticeForDsocParams, yearQuarterOrder, yearQuarterServer} from './crop-practice';
import {getSeasonByYear} from '_utils';

export const getPracticeStats = (
  carbon: AppStore['carbon'],
  fieldsByFarmId: AppStore['map']['fieldsByFarmId']
) => {
  const plan = carbon.plans[carbon.activePlan];

  const practiceStats: {[practice: string]: PracticeStats} = {};
  plan.carbonPractices.forEach(practice => {
    practiceStats[practice] = {fieldCount: 0, area: 0};
  });

  Object.values(fieldsByFarmId).map(fields =>
    Object.keys(fields)
      .map(Number)
      .forEach(fieldId => {
        const enrolled = carbon.enrolledFields[fieldId];
        const practice = plan.practices[fieldId];
        if (!enrolled || !practice || !practiceStats[practice]) return;
        practiceStats[practice].fieldCount += 1;
        practiceStats[practice].area += fields[fieldId].Area;
      })
  );
  return practiceStats;
};

export const getDsocParams = (
  carbon: AppStore['carbon'],
  fieldsByFarmId: AppStore['map']['fieldsByFarmId'],
  fieldGeometries: AppStore['map']['fieldGeometries']
) => {
  const dsocParams: CarbonSequestrationPayload = {};
  Object.values(fieldsByFarmId).map(fields =>
    Object.values(fields).forEach(field => {
      const id = Number(field.ID);
      if (!carbon.enrolledFields[id]) return;
      dsocParams[id] = {
        field_id: field.FieldID,
        geom: fieldGeometries[field.MD5],
        dsoc_params: {
          spring_tillage_practice: [],
          summer_crop_type: [],
          fall_tillage_practice: [],
          winter_crop_type: [],
        },
      };
      carbonYearsZA.forEach(year => {
        // Both Season and Guessed data are already in carbon-year format (not in calendar year).
        // And an endpoint is expecting carbon-year, so all good.
        const season = getSeasonByYear(field.Seasons, year);
        const guessed = carbon.guessedPractices[id]?.[year];
        yearQuarterOrder.forEach(quarter => {
          const value = getCropPracticeForDsocParams(season, guessed, quarter);
          dsocParams[id].dsoc_params[yearQuarterServer[quarter]].push(value);
        });
      });
    })
  );
  return dsocParams;
};

export const carbonPlanStateToPayload = (
  carbon: AppStore['carbon'],
  dsoc: CarbonSequestrationPayload
) => {
  const enrolledFields = Object.entries(carbon.enrolledFields)
    .filter(([fieldId, v]) => v)
    .map(([fieldId]) => Number(fieldId));

  const payload: CarbonPlanPayload = {
    id: carbon.planId,
    body: {
      step_1: enrolledFields,
      step_2: dsoc,
      step_3: carbon.targetPractices,
    },
  };

  return payload;
};

export const carbonPlanPayloadToState = (planPayload: CarbonPlanPayload, fields?: Field[]) => {
  const uniquePractices: Set<CarbonPractice> = new Set();
  const practices: {[fieldId: number]: CarbonPractice} = {};

  if (planPayload.body.step_3) {
    Object.keys(planPayload.body.step_3)
      .map(Number)
      .forEach(fieldId => {
        const p = planPayload.body.step_3[fieldId];
        if (p.practices) {
          uniquePractices.add(p.practices);
          practices[fieldId] = p.practices;
        }
      });
  }

  const enrolledFields: {[fieldId: number]: boolean} = {};
  planPayload.body.step_1?.forEach(fieldId => {
    if (fields.length) {
      const field = fields.find(f => f.ID === fieldId);

      if (field && isCarbonAllowedRegion(field.CountryRegion)) {
        enrolledFields[fieldId] = true;
      }
    } else {
      enrolledFields[fieldId] = true;
    }
  });

  const carbonPractices =
    uniquePractices.size > 0 ? [...uniquePractices] : [CarbonPractice.NoTillCoverCrops];
  const plan = {
    id: planPayload.id,
    carbonPractices,
    activeCarbonPractice: carbonPractices[0],
    practices,
  };

  return {plan, enrolledFields};
};
