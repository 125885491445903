import {t, FormattedMessage} from 'i18n-utils';
import React, {useState} from 'react';
import {FileInput, FileInputProps} from 'react-md';
import './index.scss';
import cn from 'classnames';
import {UploadIcon} from '../../containers/map/icons';

interface Props extends FileInputProps {
  isUploading?: boolean;
  accept: string;
}
const UploadFileZone = ({
  id,
  name,
  label = '',
  disabled,
  onChange,
  accept,
  icon = null,
  multiple = false,
  className = '',
  isUploading = false,
}: Props) => {
  const [isDragging, onChangeDraggingStatus] = useState(false);

  const onDragEnter = () => !isDragging && onChangeDraggingStatus(true);
  const onDragLeave = () => isDragging && onChangeDraggingStatus(false);

  return (
    <div className={'upload-file-zone-container'}>
      <div
        onDragOver={onDragEnter}
        onDragLeave={onDragLeave}
        className={cn('upload-file-zone', {
          active: isUploading || isDragging,
        })}
      >
        <FileInput
          disabled={disabled}
          id={id}
          className={`upload-file-zone__input ${className}`}
          accept={accept}
          name={name}
          icon={icon}
          label={label}
          multiple={multiple}
          onChange={onChange}
          allowDuplicates={true}
        />

        <img
          className={cn('upload-file-zone__loader', {
            hidden: !isUploading,
          })}
          alt={'loader'}
          src={'/assets/material-loader.gif'}
        />

        {!isUploading && <UploadIcon className={'upload-file-zone__icon'} />}

        <div>
          <div className={'upload-file-zone__text'}>Upload files</div>
          <div className={'upload-file-zone__hint-text'}>Drop files here or click to browse</div>
        </div>
      </div>
    </div>
  );
};

export default UploadFileZone;
