import Server from './server';
import service from './service';
import {NrxPopUpValues, NRxResultsResponse} from '../containers/map/utils/nrx-utils';
import {AxiosPromise} from 'axios';
import cancelTokenStore from '_api/cancel-tokens-store';

export default class {
  static updateSeasonNrxData(data: any) {
    return Server.put(`app/nrx/season/`, data);
  }

  static setSeasonNrxData(data: any) {
    return Server.post(`app/nrx/season/`, data);
  }

  static getCropType = (cropId?: number) => {
    return Server.get(`app/nrx/crop/${cropId ? cropId : ''}`);
  };

  static getSoilType = (soilId?: number) => {
    return Server.get(`app/nrx/soil_type/${soilId ? soilId : ''}`);
  };

  static getNearestSoilType = (fieldCoordinates: any[]) => {
    return service.post(`recommendation_date/closest_apsoils`, {
      n: 10,
      fields: fieldCoordinates,
    });
  };

  static getFertilizerParams = () => {
    return Server.get(`app/nrx/fertiliser_application_type/`);
  };

  static getNrxSeasons = (ids: string) => {
    return Server.get(`app/nrx/season/?kml_ids=${ids}`);
  };

  static getFertilizerApplication = (appId: any) => {
    return Server.get(`app/nrx/season/?kml_ids=${appId}`);
  };

  static addFertilizerApplication = (data: any) => {
    return Server.post(`app/nrx/fertiliser_application/`, data);
  };

  static updateFertilizerApplication = (data: any) => {
    return Server.put(`app/nrx/fertiliser_application/`, data);
  };

  static deleteFertilizerApplication = (fieldId: number, appId: number | string) => {
    return Server.delete(`app/nrx/fertiliser_application/${fieldId ? appId : ''}`);
  };

  static getNRxRecommendation = (
    data: any,
    sevenZones = false,
    requestIndex?: number
  ): AxiosPromise<NRxResultsResponse> => {
    cancelTokenStore.cancel(`getNRxRecommendation${requestIndex ? requestIndex : ''}`);
    const source = cancelTokenStore.generateSource(
      `getNRxRecommendation${requestIndex ? requestIndex : ''}`
    );

    return service.get(`nrx-output/${sevenZones ? 'seven_zones' : 'roi'}`, {
      cancelToken: source.token,
      params: {__skipPreloader: true, ...data, summary: true},
    });
  };

  static saveRecommendationSettings = (data: Partial<NrxPopUpValues>, seasonId: number) => {
    cancelTokenStore.cancel('saveRecommendationSettings');
    const source = cancelTokenStore.generateSource('saveRecommendationSettings');

    return Server.post(`app/nrx/settings/${seasonId}`, data, {
      params: {cancelToken: source.token, __skipPreloader: true},
    });
  };

  static updateRecommendationDate = (seasonId: number, date: string) => {
    return service.post(`recommendation_date/${seasonId}/${date}`);
  };

  static getDashboard = () => {
    return Server.get('app/nrx/dashboard', {params: {__skipPreloader: true}});
  };

  static exportToAgX = (farmId: number, fieldId: number, seasonId: number, payload: any) => {
    return Server.post(`nrx/${farmId}/${fieldId}/${seasonId}`, payload);
  };
}
