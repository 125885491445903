import {t} from 'i18n-utils';
import React, {useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import {FontIcon, SelectField, TextField, ListItem} from 'react-md';
import * as Yup from 'yup';

import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppSelector} from '_hooks';
import {escapeRegExp, getFarmById, isAdmin} from '_utils';
import MultiKeysPressed from 'components/key-handler';
import {Dropdown, FluroButton, FluroDialog} from '../../../../../components';
import {Waypoint} from 'react-waypoint';
import {boolean} from 'yup';

export const schema = Yup.object().shape({
  farmName: Yup.string()
    .min(2, 'Min. farm name is 2 characters')
    .max(200, 'Max. farm name is 200 characters')
    .required('Farm name is required'),
});

type Props = {
  farmName: string;
  onAutocompleteFarm: (farmId: number | 'add-new-farm') => void;
  onCreateNewFarmToggle: (value: boolean) => void;
  onChangeNewFarmName?: (name: string, isValid: boolean) => void;
  isNewFarm: boolean;
  setNewFarmForSelectedFields: (value: string) => void;
  disabled?: boolean;
};
const SelectFarmToUpload = ({
  setNewFarmForSelectedFields,
  onChangeNewFarmName,
  onCreateNewFarmToggle,
  isNewFarm,
  farmName,
  onAutocompleteFarm,
  disabled,
}: Props) => {
  const farms = useAppSelector(store => store.farms.list.filter(farm => farm.id !== DEMO_FARM_ID));
  const currentFarmId = useAppSelector(store => store.map.group.id);
  const [searchString, setSearchString] = useState('');
  const [pagination, setPagination] = useState<number>(PAGINATION_STEP);

  const {
    formState: {errors},
    control,
    watch,
  } = useForm<{
    farmName: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {farmName: ''},
  });

  const values = watch();

  const menuItems = useMemo(
    () => farms.filter(f => !f.readOnly).map(f => ({label: f.name, value: f.id})),
    [farms]
  );

  const filteredMenuItems = useMemo(() => {
    const r = new RegExp(escapeRegExp(searchString), 'i');
    return menuItems.filter(item => r.test(item.label)).slice(0, pagination);
  }, [menuItems, pagination, searchString]);

  const fixedToTopItems = useMemo(() => {
    const currentFarm = getFarmById(currentFarmId);
    const fixedToTopItems = [
      <ListItem
        onClick={() => onAutocompleteFarm('add-new-farm')}
        primaryText={
          <span className={'add-new-farm-option'}>
            <FontIcon>add_circle_outline</FontIcon>
            {t({id: 'Add a new farm'})}
          </span>
        }
      />,
    ];

    if (currentFarm) {
      fixedToTopItems.push(
        <ListItem
          onClick={() => onAutocompleteFarm(currentFarm.id)}
          primaryText={
            <span className={'upload-to-current-farm-option'}>
              {t({id: 'Upload to current farm {farmName}'}, {farmName: currentFarm.name})}
            </span>
          }
        />
      );
    }

    return fixedToTopItems;
  }, [currentFarmId, farms]);

  const onSubmitNewFarmName = () => {
    if (values.farmName.length < 2 || values.farmName.length > 50) return; // simulate disabled state
    setNewFarmForSelectedFields(values.farmName);
    onCreateNewFarmToggle(false);
  };

  return (
    <div className={cn('selected-farm-container')}>
      <>
        <FluroDialog
          onHide={() => onCreateNewFarmToggle(false)}
          title={t({id: 'Type new farm name'})}
          visible={isNewFarm}
          id={'add-new-farm-name'}
          portal
          focusOnMount
          initialFocus={'#add-new-farm-input-name'}
        >
          <Controller
            name="farmName"
            control={control}
            render={({field: {value, onChange}}) => (
              <TextField
                id="add-new-farm-input-name"
                label={t({id: 'Farm'})}
                placeholder="Farm name"
                errorText={errors?.farmName?.message}
                error={!!errors.farmName}
                value={value}
                onChange={(value: string) => {
                  onChange(value);
                  // async call to catch updated error state
                  setTimeout(() => {
                    onChangeNewFarmName?.(value, !errors.farmName);
                  }, 0);
                }}
              />
            )}
          />

          <div className="element-full-width margin-top-15 d-flex justify-between">
            <FluroButton raised blank>
              {t({id: 'Cancel'})}
            </FluroButton>

            <FluroButton
              disabled={values.farmName.length < 2 || values.farmName.length > 50}
              raised
              primary
              onClick={onSubmitNewFarmName}
            >
              {t({id: 'Confirm'})}
            </FluroButton>
          </div>
          <MultiKeysPressed callback={onSubmitNewFarmName} keys={['Enter']} />
        </FluroDialog>

        <Dropdown
          className={'select-farm-wrapper'}
          multiselect={false}
          button={
            <SelectField
              id={`select-farm`}
              label={t({id: 'Farm'})}
              placeholder={t({id: 'Select rows number'})}
              className={'select-farm-dropdown-btn'}
              isOpen={false}
              menuItems={[{label: farmName, value: farmName}]}
              value={farmName}
            />
          }
        >
          {menuItems.length > 5 && (
            <TextField
              id="farms-filter-input"
              className="farms-filter"
              placeholder={t({id: 'Type farm name...'})}
              value={searchString}
              onChange={(search: string) => setSearchString(search)}
              //@ts-ignore
              autoFocus
            />
          )}
          {fixedToTopItems}

          {filteredMenuItems.map(item => {
            return (
              <ListItem
                key={item.value}
                primaryText={item.label}
                onClick={() => onAutocompleteFarm(item.value)}
              />
            );
          })}
          {/* Load more farms waypoint  */}
          <Waypoint onEnter={() => setPagination(p => p + PAGINATION_STEP)} />
        </Dropdown>
      </>
    </div>
  );
};

const DEMO_FARM_ID = 136;
const PAGINATION_STEP = 25;

export default SelectFarmToUpload;
