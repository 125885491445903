import initialState from '../initial-state';
import {IAction, SamplingPoint} from '../../types';
import {ActionTypes} from '../types';
import moment from 'moment';
import {filterDates} from 'containers/map/utils';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {formatDate, genKey} from '_utils';
import {
  getSortedPointsGroup,
  getPointsGroupKeys,
  updateFieldSeasonTissueSampling,
} from 'containers/map/utils/sampling-points';

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.MAP_POINTS_SET_CURRENT: {
      let featureGroupReDrawId = initialState.featureGroupReDrawId;
      let tissueSampling = state.currentSeason.tissueSampling;

      if (action.id === 'new') {
        const groupDates = getPointsGroupKeys();
        const pointDate = (groupDates.length
          ? moment(
              state.pointsCurrentGroupDate === 'all' ? groupDates[0] : state.pointsCurrentGroupDate,
              formatDate()
            )
          : moment()
        ).format(GLOBAL_FORMAT_DATE);

        const newPoint = {
          ...initialState.currentPoint,
          geometry: {
            type: 'Point',
            coordinates: [...action.position],
          },
          properties: {
            ...initialState.currentPoint.properties,
            timedate: pointDate,
          },
        };

        if (!Array.isArray(tissueSampling)) {
          tissueSampling = [];
        }
        tissueSampling = [...tissueSampling, newPoint];
        featureGroupReDrawId = genKey();
      }

      return {
        ...state,
        featureGroupReDrawId,
        geometriesOnMap: true,
        currentPointId: action.id,
        currentPoint: tissueSampling.find(ts => ts.id === action.id),
        field: updateFieldSeasonTissueSampling(state.field, state.currentSeasonId, tissueSampling),
        currentSeason: {
          ...state.currentSeason,
          tissueSampling,
        },
        ...getSortedPointsGroup(state.pointsCurrentGroupDate, tissueSampling),
      };
    }
    case ActionTypes.MAP_POINTS_SAVE: {
      const tissueSampling = state.currentSeason.tissueSampling;

      const pointFormatDate = moment(action.point.properties.timedate, GLOBAL_FORMAT_DATE).format(
        formatDate()
      );

      tissueSampling.forEach((point: SamplingPoint, i: number) => {
        if (point.id === action.point.id || point.id === 'new') {
          tissueSampling.splice(i, 1, action.point);
        }
      });

      if (!tissueSampling.length || action.suggestedPoints) {
        tissueSampling.push(action.point);
      }

      return {
        ...state,
        currentPointId: '',
        currentPoint: {},
        currentSeason: {
          ...state.currentSeason,
          tissueSampling,
        },
        field: updateFieldSeasonTissueSampling(state.field, state.currentSeasonId, tissueSampling),
        ...getSortedPointsGroup(
          state.pointsCurrentGroupDate === 'all' ? 'all' : pointFormatDate,
          tissueSampling
        ),
      };
    }

    case ActionTypes.MAP_POINTS_UPDATE_WITH_NUTRILOGIC_DATA: // todo refactor together with the nutrilogic code
      return {
        ...state,
        pointsGroups: {
          ...state.pointsGroups,
          [action.date]: action.value,
        },
      };

    case ActionTypes.MAP_POINTS_UPDATE_POSITION: {
      const tissueSampling = state.currentSeason.tissueSampling.map(point => {
        if (action.positions[point.id]) {
          point.geometry.coordinates = [
            action.positions[point.id].lat,
            action.positions[point.id].lng,
          ];
        }
        return point;
      });

      return {
        ...state,
        currentSeason: {
          ...state.currentSeason,
          tissueSampling,
        },
        field: updateFieldSeasonTissueSampling(state.field, state.currentSeasonId, tissueSampling),
        ...getSortedPointsGroup(state.pointsCurrentGroupDate, tissueSampling),
      };
    }

    case ActionTypes.MAP_POINTS_BULK_UPDATE_PROP: {
      const tissueSampling = state.currentSeason.tissueSampling.map(point => {
        if (action.pointIds.includes(point.id)) {
          point.properties[action.pointProperty] = action.value;

          if (action.pointProperty !== 'checked') point.properties.checked = false; // uncheck updated points
        }
        return point;
      });

      return {
        ...state,
        currentSeason: {
          ...state.currentSeason,
          tissueSampling,
        },
        field: updateFieldSeasonTissueSampling(state.field, state.currentSeasonId, tissueSampling),
        ...getSortedPointsGroup(state.pointsCurrentGroupDate, tissueSampling),
      };
    }

    case ActionTypes.MAP_POINTS_REMOVE_ONE: {
      const tissueSampling = state.currentSeason.tissueSampling.filter(
        point => point.id !== action.id
      );

      const {pointsGroups, pointsCurrentGroupDate} = getSortedPointsGroup(
        state.pointsCurrentGroupDate,
        tissueSampling
      );

      return {
        ...state,
        currentPointId: '',
        currentPoint: {},
        pointsGroups,
        pointsCurrentGroupDate,
        field: updateFieldSeasonTissueSampling(state.field, state.currentSeasonId, tissueSampling),
        currentSeason: {
          ...state.currentSeason,
          tissueSampling,
        },
        currentDates: action.recalculateDates
          ? filterDates(
              state.wholeFarm.isWholeFarmView ? state.wholeFarm.wholeFarmDates : state.infoExt,
              pointsGroups,
              state.field.generatedNMapDates,
              state.remoteSensingCloudCover,
              state.remoteSensingFilterProps
            )
          : state.currentDates,
      };
    }

    case ActionTypes.MAP_POINTS_SET_GROUP_DATE:
      return {
        ...state,
        pointsCurrentGroupDate: action.value,
      };

    case ActionTypes.MAP_POINTS_UPDATE_GROUP_DATE: {
      const tissueSampling = state.currentSeason.tissueSampling.map(point => {
        const pointDate = `${moment(point.properties.timedate, GLOBAL_FORMAT_DATE).format(
          formatDate()
        )}`;

        if (state.pointsCurrentGroupDate === pointDate) {
          point.properties.timedate = action.timedate;
        }
        return point;
      });

      return {
        ...state,
        currentSeason: {
          ...state.currentSeason,
          tissueSampling,
        },
        field: updateFieldSeasonTissueSampling(state.field, state.currentSeasonId, tissueSampling),
        ...getSortedPointsGroup(
          `${moment(action.timedate, GLOBAL_FORMAT_DATE).format(formatDate())}`,
          tissueSampling
        ),
      };
    }
    default:
      return state;
  }
};
