import {t, FormattedMessage} from 'i18n-utils';
import styled from 'styled-components';
import React from 'react';
import {TableColumn, Button} from 'react-md';

export const EntityIcon = styled.div`
  margin-right: 10px;
  cursor: pointer;

  > .md-icon {
    font-size: 15px;
  }
`;

export const ActionTableColumn = styled(TableColumn)`
  display: flex;
`;

export const AssignMultipleButton = styled(Button)`
  margin-top: 12px;
  margin-left: 5px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .fluro-autocomplete {
    width: 100%;
    margin-left: 15px;
  }
`;

export const UserItem = styled.div``;

export const UserItemName = styled.div`
  color: #747474;
  font-size: 14px;
`;

export const UserItemEmail = styled.div`
  color: #333;
`;
