import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'react-md';
import * as Leaflet from 'leaflet';
import {WHOLE_TABLE_VIEW_FEATURES} from '_constants';
import {toggleMapBar, toggleDrawingMode, toggleTableView} from '../actions';
import TopControlsBar from './top-controls-bar';

import MiniMenu from '../mini-menu/mini-menu';
import MapZoning from '../features/zoning';
import MapTissueSampling from '../features/sampling-points';
import RemoteSensingList from '../features/data-layers/remote-sensing-list';
import MapCrop from '../features/anomalies';
import MapFarm from '../features/farm/index';
import Analytics from '../features/analytics/index';
import FieldsListContainer from '../features/farm/table-view';
import CropPerformance from '../features/crop-performance/crop-performance';
import Optis from '../features/optis/optis';
import {TFeature} from 'types';
import cn from 'classnames';
import {AppProcessingStatus, FluroButton} from 'components';
import Mixpanel from '_utils/mixpanel-utils';
import {AppStore} from '../../../reducers';
import {reportError} from 'containers/error-boundary';
import {Portal} from 'components/fluro-dialog/portal';
import {CarbonPanel} from 'containers/carbon/carbon-panel';
import {useAppSelector} from '_hooks';
import {AsyncStatusType, Status} from 'modules/ui-helpers';
import {isAdmin} from '_utils';
import {DialogType} from '../../../_reducers/dialog';

export const MapBar = ({
  leafletElement,
  removeGeometryFromMap,
}: {
  leafletElement: Leaflet.Map;
  removeGeometryFromMap?: (id: number) => void;
}) => {
  const dispatch = useDispatch();
  const wholeTableView = useAppSelector(s => s.map.wholeTableViewOpen);
  const isMapBarOpen = useAppSelector(s => s.map.isMapBarOpen);
  const feature = useAppSelector(s => s.map.feature);
  const viewport = useAppSelector(s => s.viewport);
  const isAddingFields = useAppSelector(s => s.dialog.currentDialog === DialogType.AddNewField);
  const carbonSuccessPopUp = useAppSelector(
    s => s.dialog.currentDialog === DialogType.CarbonSuccessfulSigned
  );
  const asyncStatuses = useAppSelector(s => s.uiHelpers.asyncStatuses);
  const hasFarms = isAdmin() || useAppSelector(s => s.farms.list.length > 0);

  const isFarmsLoading = useMemo(
    () => asyncStatuses[AsyncStatusType.farmsList].status === Status.Pending,
    [asyncStatuses[AsyncStatusType.farmsList].status]
  );

  const toggleBar = useCallback(() => dispatch(toggleMapBar(!isMapBarOpen)), [isMapBarOpen]);
  const onToggleTableView = useCallback((value: TFeature) => {
    Mixpanel.openExpandedView(value);
    dispatch(toggleTableView(value));
    dispatch(toggleDrawingMode(false, 'marker'));
  }, []);

  const disabledToggleButton = !isMapBarOpen && isAddingFields;

  const isHiddenByWholeTableView = wholeTableView && feature === 'farm';
  const isSmallScreen = viewport.width < 561;

  const showExpandButton = !wholeTableView && WHOLE_TABLE_VIEW_FEATURES.includes(feature);

  return (
    <Portal id="map-bar-root">
      <div className={isMapBarOpen ? 'map-bar--open' : ''}>
        {/* On small screens we show different topbar control */}
        {!isHiddenByWholeTableView && !isSmallScreen && <TopControlsBar />}
        {!carbonSuccessPopUp && !wholeTableView && !isAddingFields && (
          <div className="map-bar-minimize">
            <FluroButton disabled={disabledToggleButton} onClick={toggleBar} floating mini>
              {isMapBarOpen ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
            </FluroButton>
          </div>
        )}
        <MiniMenu ln={'/maps'} />
        <div
          className={cn('map-bar', {
            'whole-table-view': wholeTableView,
            'no-farms': !hasFarms && !isFarmsLoading,
          })}
        >
          {showExpandButton && hasFarms && (
            <Button
              onClick={() => onToggleTableView(feature)}
              title={'Expanded view'}
              floating
              mini
              className={'expanded-view-button onboarding__toggle-expanded-view'}
            >
              keyboard_arrow_left
            </Button>
          )}

          <div className={cn('map-bar__w', `map-bar__feature-${feature}`)}>
            <PanelComponent
              feature={!hasFarms ? 'farm' : feature}
              leafletElement={leafletElement}
              removeGeometryFromMap={removeGeometryFromMap}
              wholeTableView={!!wholeTableView}
            />
            {feature !== 'optis' && feature !== 'carbon' && hasFarms && <AppProcessingStatus />}
          </div>
        </div>
      </div>
    </Portal>
  );
};

const PanelComponent = ({
  feature,
  leafletElement,
  removeGeometryFromMap,
  wholeTableView,
}: {
  feature: AppStore['map']['feature'];
  leafletElement: Leaflet.Map;
  removeGeometryFromMap: (id: number) => void;
  wholeTableView: boolean;
}) => {
  switch (feature) {
    case 'farm':
      return wholeTableView ? <FieldsListContainer /> : <MapFarm />;

    case 'crop':
      return <MapCrop removeGeometryFromMap={removeGeometryFromMap} />;

    case 'zoning':
      return <MapZoning />;

    case 'data-layers':
      return <RemoteSensingList />;

    case 'tsp':
      return <MapTissueSampling leafletElement={leafletElement} />;

    case 'crop-performance':
      return <CropPerformance />;

    case 'optis':
      return <Optis />;

    case 'carbon':
      return <CarbonPanel leafletElement={leafletElement} />;

    case 'analytics':
      return <Analytics />;

    default:
      reportError(`Map bar: No panel component for feature "${feature}"`);
      return null;
  }
};
