import {CarbonPractice} from 'containers/carbon/base/base';
import {tillAppToGuessed, cropAppToGuessed} from 'containers/carbon/base/crop-practice';
import Server from './server';

export type CarbonPlanPayload = {
  id?: number;
  name?: string;
  created_at?: string;
  updated_at?: string;
  body: {
    step_1?: number[]; // enrolled fields
    step_2?: CarbonSequestrationPayload;
    step_3?: TargetPracticesPayload;
    step_4?: {
      address?: string;
      envelope_id?: string;
      status?: string;
    };
  };
};

export type CarbonPracticesPayload = {
  years: number[];
  geom: {[fieldId: string]: GeoJSON.Feature | GeoJSON.FeatureCollection};
};

export type CarbonPracticesResponse = {
  fall_tillage_conf: Confidence;
  fall_tillage_practice: TillageResponse;
  segment_id: string;
  spring_tillage_conf: Confidence;
  spring_tillage_practice: TillageResponse;
  summer_crop_conf: Confidence;
  summer_crop_type: string;
  winter_cover: string; // crop_type | 'no cover'
  winter_cover_conf: Confidence;
};

export type YearQuarterServer =
  | 'spring_tillage_practice'
  | 'summer_crop_type'
  | 'fall_tillage_practice'
  | 'winter_crop_type';

export type CarbonSequestrationPayload = {
  [fieldId: string]: {
    geom: GeoJSON.Feature | GeoJSON.FeatureCollection;
    dsoc_params: {[quarter in YearQuarterServer]: string[]};
    field_id: number;
  };
};

export type CarbonSequestrationResponse = {
  dsoc_fc: number;
  dsoc_rd: number;
  dsoc_both: number;
  n2od_fc: number;
  n2od_rd: number;
  n2od_both: number;
};

export enum DSOCType {
  DSOCCoverCrop = 'dsoc_c',
  DSOCNoTill = 'dsoc_nt',
  DSOCNoTillCoverCrop = 'dsoc_ntc',
  DSOCReducedTill = 'dsoc_rt',
  DSOCReducedTillCoverCrop = 'dsoc_rtc',
  N2ODCoverCrop = 'n2od_c',
  N2ODNoTill = 'n2od_nt',
  N2ODNoTillCoverCrop = 'n2od_ntc',
  N2ODReducedTill = 'n2od_rt',
  N2ODReducedTillCoverCrop = 'n2od_rtc',
}

export type TargetPracticesPayload = {[fieldId: string]: TargetPractices};

export type TargetPractices = {
  carbonPriceCents: number;
  dsoc_results: {[key in DSOCType]: number};
  gwpTons: {[key in DSOCType]: number};
  field_id: number;
  field_size: number;
  financialIncentiveCents: {[key in DSOCType]: number};
  practices?: CarbonPractice;
};

type TillageResponse = 'no till';

export enum Confidence {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

// FSSH service – Field Scale Soil Health
export class CarbonApi {
  static getGuessedPractices(payload: CarbonPracticesPayload) {
    return Server.post<CarbonPracticesResponse[]>('fssh/residue-cover', payload, {
      params: {__skipPreloader: true},
    });
  }

  static getCarbonSequestration(payload: CarbonSequestrationPayload) {
    const formatted = {...payload};
    Object.keys(formatted).forEach(fieldId => {
      const params = formatted[fieldId].dsoc_params;
      params.fall_tillage_practice = params.fall_tillage_practice.map(tillAppToGuessed);
      // Summer is not mapped.
      params.spring_tillage_practice = params.spring_tillage_practice.map(tillAppToGuessed);
      params.winter_crop_type = params.winter_crop_type.map(cropAppToGuessed);
    });
    return Server.post<{[fieldId: string]: CarbonSequestrationResponse}>('fssh/dsoc', formatted, {
      params: {__skipPreloader: true},
    });
  }

  static createCarbonPlan(payload: CarbonPlanPayload) {
    return Server.post<CarbonPlanResponse>('scenario_run', payload, {
      params: {__skipPreloader: true},
    });
  }

  static updateCarbonPlan(payload: CarbonPlanPayload) {
    return Server.put<CarbonPlanResponse>('scenario_run', payload, {
      params: {__skipPreloader: true},
    });
  }

  static getCarbonPlanList() {
    return Server.get<CarbonPlanListResponse>('scenario_run', {params: {__skipPreloader: true}});
  }

  static getCarbonPlan(id: number) {
    return Server.get(`scenario_run/${id}`, {params: {__skipPreloader: true}});
  }

  static getCarbonDocumentLink(id: number) {
    return Server.get(`cargill/redirect?scenario_id=${id}`, {params: {__skipPreloader: false}});
  }

  static generateCarbonSequestration(id: number) {
    return Server.get(`scenario_run/${id}/gen_soc`, {params: {__skipPreloader: true}});
  }
}

export default CarbonApi;

type CarbonPlanListResponse = {
  status: 'ok';
  result: CarbonPlanPayload[];
};

type CarbonPlanResponse = {
  status: 'ok';
  result: number;
};
