import React from 'react';
import {Avatar, AvatarProps} from 'react-md';
import cn from 'classnames';
import './index.scss';

type Props = AvatarProps & {cropType: string; iconSrc?: string; letter?: string};

/**
 * Can be used in storybook.
 *
 * <CropAvatarLite
 *   cropType="cotton"
 *   iconSrc="/assets/crops/cotton.png"
 *   letter="c"
 *  />
 */
export const CropAvatarLite = ({
  className = '',
  cropType,
  iconSrc,
  letter,
  ...otherProps
}: Props) => {
  return (
    <Avatar
      {...otherProps}
      className={cn(`fluro-crop-avatar ${className}`, {
        bordered: !iconSrc && !otherProps.icon,
      })}
      suffix="white"
      src={iconSrc}
    >
      {letter}
    </Avatar>
  );
};
