import {ZoningApi} from '_api';
import {AppStore} from 'reducers';
import {
  AsyncStatusType,
  setRequestStatus,
  Status,
  SetRequestStatusAction,
} from 'modules/ui-helpers';
import axios from 'axios';
import {showNote} from '_actions';
import {t} from 'i18n-utils';
import {reportError} from 'containers/error-boundary';
import {Season, TInfoExt} from '../../../types';
import moment from 'moment';
import {Dispatch} from 'redux';
import {ShowNote} from 'components/alert/reducer';

export const PRODUCTIVITY_MAP_ASSETS_HOST = 'https://storage.googleapis.com/';

export enum ActionType {
  ToggleProductivityMap = 'prod-map/ToggleProductivityMap',
  SetProductivityMapData = 'prod-map/SetProductivityMapData',
  SetCurrentCacheKey = 'prod-map/SetCurrentCacheKey',
}

export type ProductivityMapMetaData = {
  thumbnail: string;
  thumbnail_scale: string;
  thumbnail_black_scale: string;
  thumbnail_zones_black_scale: string;
  thumbnail_zones: string;
  thumbnail_zones_scale: string;
  shapefile: string;
  geojson: string;
  geoJsonData?: GeoJSON.FeatureCollection;
};

export type ProductivityMapStore = {
  isOn: boolean;
  currentCacheKey?: string;
  data: {
    [key: string]: ProductivityMapMetaData;
  };
};

const initialState: ProductivityMapStore = {
  isOn: false,
  data: {},
};

type ToggleProductivityMapAction = {
  type: ActionType.ToggleProductivityMap;
  value: boolean;
};

type SetProductivityMapDataAction = {
  type: ActionType.SetProductivityMapData;
  key: string;
  data: ProductivityMapMetaData;
};

type SetCurrentCacheKeyAction = {
  type: ActionType.SetCurrentCacheKey;
  key: string;
};

type Action = ToggleProductivityMapAction | SetProductivityMapDataAction | SetCurrentCacheKeyAction;

export const toggleProductivityMap = (value: boolean): ToggleProductivityMapAction => ({
  type: ActionType.ToggleProductivityMap,
  value,
});

export const setProductivityMapData = (
  key: string,
  data: ProductivityMapMetaData
): SetProductivityMapDataAction => ({
  type: ActionType.SetProductivityMapData,
  key,
  data,
});

export const setCurrentCacheKey = (key: string): SetCurrentCacheKeyAction => ({
  type: ActionType.SetCurrentCacheKey,
  key,
});

export const fetchData = (
  md5: string,
  sensor: string,
  startDate: string,
  endDate: string,
  shapefile_name: string
) => async (
  dispatch: Dispatch<
    SetCurrentCacheKeyAction | SetProductivityMapDataAction | SetRequestStatusAction | ShowNote
  >,
  getState: () => AppStore
) => {
  try {
    const keyOfCachedMap = `${md5}-${sensor}-${startDate}-${endDate}`;

    const cachedDate = getState().productivityMap.data[keyOfCachedMap];

    if (cachedDate) {
      dispatch(setCurrentCacheKey(keyOfCachedMap));
      return cachedDate;
    }

    dispatch(setRequestStatus(AsyncStatusType.productivityMap, Status.Pending));

    const data = await ZoningApi.productivityMap(md5, sensor, startDate, endDate, shapefile_name);

    dispatch(setProductivityMapData(keyOfCachedMap, data));
    dispatch(setRequestStatus(AsyncStatusType.productivityMap, Status.Done));
  } catch (e) {
    if (!axios.isCancel(e)) {
      dispatch(setRequestStatus(AsyncStatusType.productivityMap, Status.Done, e.message));

      dispatch(
        showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'Productivity map load error'}),
          level: 'error',
        })
      );

      reportError(`Productivity map load error: ${e.message}`);
    }
  }
};

export const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ToggleProductivityMap: {
      return {
        ...state,
        isOn: action.value,
      };
    }

    case ActionType.SetProductivityMapData: {
      return {
        ...state,
        currentCacheKey: action.key,
        data: {...state.data, [action.key]: action.data},
      };
    }

    case ActionType.SetCurrentCacheKey: {
      return {
        ...state,
        currentCacheKey: action.key,
      };
    }

    default:
      return state;
  }
};

export const isReachedPeakCanopy = (sowingDate: string, latestAvailableDate: TInfoExt) => {
  if (!latestAvailableDate) return false;
  return moment(latestAvailableDate.Date).diff(moment(sowingDate), 'days') >= 90;
};

export type ProdMapSeason = Season & {
  isReachedPeakCanopy?: boolean;
};

export const populateReachedPeakCanopyData = (
  seasons: Season[],
  filterPlantingAreas = false
): ProdMapSeason[] => {
  if (filterPlantingAreas) {
    seasons = seasons.filter(s => !s.geometry_id);
  }

  return seasons.map(s => {
    if (
      s.infoExt?.length &&
      isReachedPeakCanopy(
        s.startDate,
        s.infoExt.find(d => d.Cloud < 5)
      )
    ) {
      return {
        ...s,
        isReachedPeakCanopy: true,
      };
    }

    return s;
  });
};
