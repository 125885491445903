import {t} from 'i18n-utils';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {SelectionControl} from 'react-md';
import {setUpdateSeasonNrxData, saveFieldData, addPivotMarker} from '../../../actions';
import ButtonsPanel from './buttons-panel';
import Table from './table-body';
import TableViewHeader from 'components/table-view-header';
import FertilizerPopUp from './pop-ups/fertilizer';
import MatchCropPopUp from './pop-ups/match-crop';
import PlantingSoilParamsPopUp from './pop-ups/planting-soil-params';
import PivotFieldPopUp from './pop-ups/pivot';
import {loadNrxData, getNrxSeason} from '../../../utils/nrx-utils';
import {getFieldsSeasons} from '../../../utils';
import {getFieldById} from 'containers/map/utils/field';
import './index.scss';
import {toggleGlobalDialog} from '_actions';
import {Season} from '../../../types';
import {FieldPayload} from '../types';
import FieldsInfoLine from '../fields-info-line';
import {useAppSelector} from '_hooks';

const FieldsListContainer = () => {
  const dispatch = useDispatch();

  const farmId = useAppSelector(s => s.global.currentGroupId);
  const fields = useAppSelector(s => s.map.fields);
  const listsData = useAppSelector(s => s.map.listsData);
  const {editPivotTableView} = useAppSelector(s => s.global.dialogsState);
  const measurement = useAppSelector(s => s.login.user.settings.measurement);

  const [farmChanged, setFarmChanged] = useState(false);
  const [onlyLatestSeasons, toggleOnlyLatestSeasons] = useState(false);
  const [particularSeasonId, setParticularSeasonId] = useState(0);
  const [openedPopUp, setOpenedPopUp] = useState('');

  useEffect(() => {
    dispatch(loadNrxData());
  }, []);

  useEffect(() => {
    setFarmChanged(true); // to wait until fields load
    togglePopUp('', false);
  }, [farmId]);

  useEffect(() => {
    setFarmChanged(false);
    dispatch(loadNrxData());
  }, [farmChanged, fields]);

  const togglePopUp = (popUp: string, value: boolean, seasonID?: number) => {
    setParticularSeasonId(value ? seasonID : 0);
    setOpenedPopUp(value ? popUp : '');
  };

  const onSetUpdateSeasonNrxData = (seasonID: number, seasonData: any) => {
    return dispatch(setUpdateSeasonNrxData(seasonID, seasonData));
  };

  const togglePivotPopUp = (fieldId: number) => {
    dispatch(
      toggleGlobalDialog(
        'editPivotTableView',
        {
          visible: !editPivotTableView?.visible,
          fieldId,
        },
        true
      )
    );
  };

  const savePivotFieldData = (data: FieldPayload) => {
    dispatch(saveFieldData(data, farmId, editPivotTableView?.fieldId));
    togglePivotPopUp(0);
  };

  const selectedSeasons = getFieldsSeasons().filter((s: Season) => s._selected);
  const particularSeason = getNrxSeason(particularSeasonId);
  const selectedNRXSeasons = selectedSeasons.length
    ? selectedSeasons.filter((s: Season) => s.nrx).map((s: Season) => s.nrx)
    : [particularSeason];
  const pivotField = {...getFieldById(editPivotTableView?.fieldId), ...editPivotTableView};

  return (
    <div className={'fields-table-view'}>
      <TableViewHeader
        tabTitle={
          <div>
            <div className={'tab-title'}>{t({id: 'farm.title'})}</div>
            <div className={'fields-info'}>
              <FieldsInfoLine />
            </div>
          </div>
        }
        buttons={
          <ButtonsPanel
            togglePopUp={togglePopUp}
            selectedSeasons={selectedSeasons}
            selectedNRXSeasons={selectedNRXSeasons}
          />
        }
      />

      <Table
        openPopUp={(popUp: string, seasonID: number) => togglePopUp(popUp, true, seasonID)}
        togglePivotPopUp={togglePivotPopUp}
        onlyLatestSeasons={onlyLatestSeasons}
        tableNavigationComponent={
          <div className="fields-table-view__add-controls">
            <SelectionControl
              id="toggle-only-selected-crops"
              type="switch"
              label={t({id: 'Show only latest crops'})}
              name="toggle-only-selected-crops"
              onChange={(v: boolean) => toggleOnlyLatestSeasons(v)}
              checked={onlyLatestSeasons}
            />
          </div>
        }
      />

      {openedPopUp === 'fertilizerPopUpOpen' && (
        <FertilizerPopUp
          togglePopUp={togglePopUp}
          selectedSeasons={selectedNRXSeasons}
          particularSeason={particularSeason}
          nrxListsData={listsData}
          fertilizerApplications={getFieldById(particularSeason.kmlID)?.fertilizerApplications}
          measurement={measurement}
        />
      )}

      {openedPopUp === 'matchCropPopUpOpen' && (
        <MatchCropPopUp
          togglePopUp={togglePopUp}
          selectedSeasons={selectedNRXSeasons}
          particularSeason={particularSeason}
          setUpdateSeasonNrxData={onSetUpdateSeasonNrxData}
          nrxListsData={listsData}
        />
      )}

      {openedPopUp === 'plantingSoilPopUpOpen' && (
        <PlantingSoilParamsPopUp
          togglePopUp={togglePopUp}
          selectedSeasons={selectedNRXSeasons}
          setUpdateSeasonNrxData={onSetUpdateSeasonNrxData}
          nrxListsData={listsData}
          measurement={measurement}
        />
      )}

      {editPivotTableView?.visible && (
        <PivotFieldPopUp
          field={pivotField}
          onSubmit={(data: FieldPayload) => savePivotFieldData(data)}
          addPivotMarker={data => dispatch(addPivotMarker(data, true))}
          onHide={() => togglePivotPopUp(0)}
        />
      )}
    </div>
  );
};

export default FieldsListContainer;
