import {ActivityApi, AreasOfInterestApi} from '_api';
import {t} from 'i18n-utils';
import {ActionTypes} from '../reducer/types';
import {getAnomalyLabelValue, GLOBAL_FORMAT_DATE} from '_constants';
import {getImageUrl} from '../utils';
import {getCurrentImage, getGeometryMeanIndex, getImagePath} from '_utils';
import moment from 'moment';
import {AppStore} from '../../../reducers';
import {IAnomaly, TAnomalyProps} from '../features/anomalies/types';
import turfArea from '@turf/area';
import {showNote} from '_actions';
import {reportError} from '../../error-boundary';

const propertiesShouldCallRequest: (keyof TAnomalyProps)[] = ['label', 'endDate', 'startDate'];

export const createAreaOfInterest = (shape: Partial<IAnomaly>, isBulk?: boolean) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {group, selectedFieldId} = getState().map;
  return AreasOfInterestApi.creatAreaOfInterest(group.id, selectedFieldId, shape)
    .then(({data}) => {
      dispatch(addAreaOfInterest(data.result));
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'AOIUploadOk'}, {count: isBulk ? 2 : 1}),
          level: 'success',
        })
      );
    })
    .catch(() => reportError(`createAreaOfInterest err, = ${shape}`));
};

export const addAreaOfInterest = (areaOfInterest: Partial<IAnomaly>) => ({
  type: ActionTypes.MAP_AOI_ADD,
  shape: areaOfInterest,
});

export const addDraftAreaOfInterest = (AOI: IAnomaly) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {
    currentSensor,
    currentSeason: {endDate, startDate},
  } = getState().map;

  const getSeasonFormattedDate = (date: string) =>
    moment(date, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE); // small helper

  AOI.properties.area = turfArea(AOI); // meters
  AOI.properties.type = 'trial';
  AOI.properties.startDate = AOI.properties.startDate || getSeasonFormattedDate(startDate);
  AOI.properties.endDate = AOI.properties.endDate || getSeasonFormattedDate(endDate);

  if (currentSensor !== 'NONE' && currentSensor !== 'TCI' && currentSensor !== 'NC') {
    const png = getCurrentImage()?.url || '';
    const imagePath = getImagePath(png);

    return getGeometryMeanIndex(imagePath, AOI)
      .then(({data}) => {
        AOI.properties.mean = data.Means[AOI.properties.id];
        dispatch(toggleEditAreaOfInterest(true, AOI));
      })
      .catch(err => {
        console.log('err', err);
        dispatch(toggleEditAreaOfInterest(true, AOI));
      });
  }

  dispatch(toggleEditAreaOfInterest(true, AOI));

  return Promise.resolve();
};

export const updateAreaOfInterest = (areaOfInterest: IAnomaly, isBulk?: boolean) => (
  dispatch: any
) => {
  AreasOfInterestApi.updateAreaOfInterest(areaOfInterest.properties.id, areaOfInterest).then(
    ({data}) => {
      dispatch({
        type: ActionTypes.MAP_AOI_UPDATE,
        geometry: data.result,
        id: areaOfInterest.properties.id, // provide the previous AOI id because back-end can create a new AOI with new id after updating
      });

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'AOIUpdateOk'}, {count: isBulk ? 2 : 1}),
          level: 'success',
        })
      );
    }
  );
};

export const toggleEditAreaOfInterest = (state: boolean, geometry = {}) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_AOI_EDIT_TOGGLE,
    state,
    geometry,
  });
};

export const bulkUpdateAreasOfInterest = (
  geometries: IAnomaly[],
  prop: keyof TAnomalyProps,
  value: any
) => (dispatch: any) => {
  if (propertiesShouldCallRequest.includes(prop)) {
    return geometries.forEach(g => {
      // @ts-ignore // because of the propertiesShouldCallRequest, couldn't configure it to avoid the warning
      g.properties[prop] = value;
      dispatch(updateAreaOfInterest(g, geometries.length > 1));
    });
  }

  dispatch({
    type: ActionTypes.MAP_AOIs_CHANGE_PROP,
    geometries,
    value,
    prop,
  });
};

export const changeAreaOfInterestProp = (geometry: any, prop: keyof TAnomalyProps, value: any) => (
  dispatch: any
) => {
  if (propertiesShouldCallRequest.includes(prop)) {
    geometry.properties[prop] = value;
    return dispatch(updateAreaOfInterest(geometry));
  }

  dispatch({
    type: ActionTypes.MAP_AOI_CHANGE_PROP,
    data: {
      geometry,
      prop,
      value,
    },
  });
};

export const getAreasOfInterest = (fieldId: number | string) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {group} = getState().map;

  return AreasOfInterestApi.getAreasOfInterest(group.id, fieldId)
    .then(({data}) => {
      if (data.result && Array.isArray(data.result)) {
        data.result.forEach((geometry: IAnomaly) =>
          dispatch({
            type: ActionTypes.MAP_AOI_ADD,
            shape: {
              ...geometry,
              properties: {
                ...geometry.properties,
                id: geometry.properties.id,
                label: getAnomalyLabelValue(geometry.properties.label), // transform geometry label to label value
                saved: true, // reset risky props
                checked: false, // reset risky props
                color: '', // reset risky props
              },
            },
          })
        );
        dispatch(getAndSetAreasOfInterestMeanIndex());
      }
    })
    .catch(err => {
      console.log('getGeometries err', err);

      return Promise.reject('action getGeometries');
    });
};

export const removeAreaOfInterest = (id: number, isBulk?: boolean) => (dispatch: any) => {
  AreasOfInterestApi.removeAreaOfInterest(id).then(result => {
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'AOIDeleteOk'}, {count: isBulk ? 2 : 1}),
        level: 'success',
      })
    );
    dispatch({
      type: ActionTypes.MAP_AOI_REMOVE,
      id,
    });
  });
};

export const getAndSetAreasOfInterestMeanIndex = () => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {currentSensor, geometry} = getState().map;
  if (currentSensor === 'TCI' || currentSensor === 'NONE' || currentSensor === 'NC') {
    return;
  }
  const coordinates: any = {};
  geometry.forEach(({geometry, properties}) => {
    switch (geometry.type) {
      case 'MultiPolygon':
        coordinates[properties.id] = geometry?.coordinates?.[0]?.flat();
        break;
      default:
        coordinates[properties.id] = geometry?.coordinates?.[0];
    }
  });
  ActivityApi.getMeanIndex(getImageUrl(), coordinates)
    .then(({data}) => {
      dispatch({
        type: ActionTypes.MAP_AOI_UPDATE_MEAN_INDEX,
        data,
      });
    })
    .catch(err => console.log('err', err));
};
