import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {useSelector} from 'react-redux';
import CircularProgresslink from 'react-md/lib/Progress/CircularProgress';
import {LoaderWrapper} from './global-loader.styled';
import {AppStore} from 'reducers';

const GlobalLoader = () => {
  const loaders = useSelector((state: AppStore) => state.global.loaders);
  const isAuth = useSelector((state: AppStore) => state.login.isAuth);
  const lKeys = Object.keys(loaders);
  let uniqLoaders = {} as any;

  lKeys.forEach(k => {
    uniqLoaders[loaders[k]] = loaders[k];
  });

  return (
    <LoaderWrapper isAuth={isAuth} visible={!!lKeys.length}>
      <div className="loader">
        <div>
          {Object.keys(uniqLoaders).map((k, i) => (
            <div className="loader-content" key={k + i}>
              <CircularProgresslink id="global-loader" /> <b>{k}</b>{' '}
            </div>
          ))}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default GlobalLoader;
