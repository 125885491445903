import React from 'react';
import {Redirect} from 'react-router-dom';
import {useAppSelector} from '../_hooks';

const BasicAllocation = () => {
  const user = useAppSelector(s => s.login.user);
  const farms = useAppSelector(s => s.farms.list);
  const farmId = user.groupIds[0] || farms[0]?.id;

  return <Redirect to={`/maps${farmId ? `/${farmId}` : ''}` + location.search} />;
};

export default BasicAllocation;
