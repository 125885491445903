import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {
  LeafIcon,
  TreeFocus,
  DroneIcon,
  MachineryIcon,
  PlaneIcon,
  SatelliteIcon,
  EyeHiddenIcon,
  SatelliteHRIcon,
} from 'containers/map/icons';
import {SVGIconProps, SVGIcon} from 'react-md';

export const AppIcons = ({
  appIcons,
  className = '',
}: {
  appIcons: {name: string; count?: number}[];
  className?: string;
}) => {
  return appIcons ? (
    <>
      {appIcons.map(app => {
        switch (app.name) {
          case 'tree_analysis':
            return (
              <span key={'tree'} className={'app-type-icon'}>
                <TreeFocus className={className} />
                {app.count > 1 && <span className={'count'}>x {app.count}</span>}
              </span>
            );

          case 'nrx':
            return <LeafIcon className={className} key={'nrx'} />;

          default:
            return null;
        }
      })}
    </>
  ) : null;
};

type TypeIconProps = SVGIconProps & {
  type: string;
};

export const TypeIcon = ({type, ...iconProps}: TypeIconProps) => {
  switch (type) {
    case 'eye-hidden':
      return <EyeHiddenIcon {...iconProps} />;

    case 'plane':
      return <PlaneIcon {...iconProps} />;

    case 'satellite_hd':
      return <SatelliteHRIcon {...iconProps} />;

    case 'dron':
      return <DroneIcon {...iconProps} />;

    case 'machinery':
      return <MachineryIcon {...iconProps} />;

    default:
      return <SatelliteIcon {...iconProps} />;
  }
};

export const WarningSvg = (props: SVGIconProps) => (
  <SVGIcon viewBox="0 0 31 27" {...props} className={`warning-icon ${props.className || ''}`}>
    <path
      d="M16.9252 1.60077L30.0371 24.0276C30.7619 25.2645 29.8724 26.8269 28.3899 26.8269H2.16601C0.716445 26.8269 -0.173062 25.2645 0.551719 24.0276L13.6637 1.60077C14.3885 0.363872 16.2004 0.363872 16.9252 1.60077Z"
      fill="currentColor"
    />
    <g>
      <path
        d="M14.9372 9.71366C15.6406 9.55554 16.344 9.87178 16.6664 10.4516C16.7836 10.6624 16.8129 10.8996 16.7836 11.1104C16.725 11.7692 16.6957 12.4281 16.6664 13.1132C16.6078 14.141 16.5198 15.1952 16.4612 16.2229C16.4319 16.5392 16.4319 16.8554 16.4026 17.198C16.3733 17.7514 15.9044 18.1731 15.2889 18.1731C14.7027 18.1731 14.2045 17.7514 14.1752 17.2244C14.0873 15.6168 13.97 14.0093 13.8821 12.4017C13.8528 11.9801 13.8235 11.532 13.7942 11.1104C13.7942 10.4779 14.2631 9.87178 14.9372 9.71366Z"
        fill="white"
      />
      <circle cx="15.2942" cy="21.6731" r="1.5" fill="white" />
    </g>
  </SVGIcon>
);

export const KeyIcon = (props: SVGIconProps) => (
  <SVGIcon viewBox="0 0 15 15" {...props}>
    <path
      d="M7.65909 1.31408C8.94867 2.60365 9.29075 4.48541 8.68205 6.08636L14.7251 12.1294L15 14.7387L11.5526 14.1483L11.4084 12.562L9.82217 12.4179L9.67795 10.8316L8.09171 10.6874L6.08636 8.68204C4.48542 9.29077 2.60366 8.94867 1.31409 7.6591C-0.43803 5.90698 -0.438028 3.06621 1.31409 1.3141C3.06621 -0.438024 5.90698 -0.438035 7.65909 1.31408V1.31408ZM4.34239 2.61192C3.86454 2.13407 3.08978 2.13408 2.61193 2.61194C2.13408 3.08978 2.13408 3.86454 2.61193 4.34239C3.08978 4.82024 3.86454 4.82023 4.34239 4.34238C4.82024 3.86453 4.82024 3.08977 4.34239 2.61192V2.61192Z"
      fill="#747474"
    />
  </SVGIcon>
);

export const CheckIcon = (props: SVGIconProps) => (
  <SVGIcon viewBox="0 0 20 21" {...props}>
    <circle cx="10" cy="10.5" r="9.5" fill="white" stroke="#399D2B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 15L4 10.6731L5.26 9.46154L8.5 12.5769L15.34 6L16.6 7.21154L8.5 15Z"
      fill="#399D2B"
    />
  </SVGIcon>
);

export const DropDownArrowIcon = (props: SVGIconProps) => (
  <SVGIcon viewBox="0 0 19 19" {...props}>
    <circle cx="9.5" cy="9.5" r="9.5" fill="#fff" />
    <circle cx="9.5" cy="9.5" r="9.5" stroke="#C2C2C2" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1742 13.4558C9.99328 13.6459 9.75281 13.75 9.49995 13.75C9.24709 13.75 9.00662 13.6459 8.82565 13.4558L4.77986 9.20761C4.50717 8.92127 4.42536 8.48918 4.57163 8.11693C4.72037 7.74209 5.06743 7.5 5.45167 7.5H13.5483C13.9326 7.5 14.2796 7.74209 14.4284 8.11693C14.5746 8.48917 14.4928 8.92126 14.2201 9.20761L10.1743 13.4558H10.1742Z"
      fill="#C2C2C2"
    />
  </SVGIcon>
);
