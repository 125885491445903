import {t, FormattedMessage} from 'i18n-utils';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getGetURLParam, setGetParamToURL} from '_utils';
import {toggleNRecommendation} from '../../../actions/zoning-actions';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {NRecommendationMethod} from '../../../types';

type Props = {
  calculatedMethod: NRecommendationMethod;
  isNRXRecommendationAllowed: boolean;
  recommendationDates: string[];
  updateRecommendationDate: (value: string) => void;
  lastAvailableRecommendationDate: string;
  recommendationDate: string;
  currentMethod: string;
  hasTemperatureData: boolean;
  nRecommendationToggled: boolean;
};

const URLParamsEffect = ({
  recommendationDates,
  isNRXRecommendationAllowed,
  calculatedMethod,
  lastAvailableRecommendationDate,
  recommendationDate,
  updateRecommendationDate,
  currentMethod,
  hasTemperatureData,
  nRecommendationToggled,
}: Props): null => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentMethod && currentMethod !== 'apsim') {
      setGetParamToURL('nrx-date', null);
    }

    if (isNRXRecommendationAllowed) {
      const isOn = getGetURLParam('nrx-toggle') === 'true';
      const dateParam = getGetURLParam('nrx-date');
      const date = moment(dateParam, GLOBAL_FORMAT_DATE);

      if (
        date &&
        date.isValid() &&
        recommendationDates.includes(dateParam) &&
        recommendationDate !== dateParam
      ) {
        updateRecommendationDate(dateParam);
      } else {
        lastAvailableRecommendationDate && setGetParamToURL('nrx-date', recommendationDate);
      }

      if (!nRecommendationToggled && isOn) {
        dispatch(toggleNRecommendation(isOn, calculatedMethod));
      }
    } else {
      setGetParamToURL('nrx-toggle', null);
      setGetParamToURL('nrx-date', null);
    }
  }, [
    calculatedMethod,
    isNRXRecommendationAllowed,
    lastAvailableRecommendationDate,
    recommendationDate,
    recommendationDates,
    currentMethod,
    hasTemperatureData,
    nRecommendationToggled,
  ]);

  return null;
};

export default URLParamsEffect;
