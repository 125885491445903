import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent, ReactNode} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Button, FontIcon, SelectField, SelectionControl} from 'react-md';
import {LeafIcon} from '../../../icons';
import './index.scss';
import {setFeature, toggleTableView} from '../../../actions';
import {
  toggleNRecommendation,
  runNutrilogicRecommendation,
  updateRecommendationSettings,
  updateRecommendationDate,
  toggleNRxSettingsPopUp,
} from '../../../actions/zoning-actions';
import {pointsSetGroupDate} from '../../../actions/sampling-points';
import {HelpBtn, HelpModal} from '../../../../help-popup';
import RecommendationSettingsPopUp from './n-recommendation-settings-pop-up';
import {
  checkSeasonStatus,
  getNrxSeason,
  getLastAvailableRecommendationDate,
} from '../../../utils/nrx-utils';
import FluroDatePicker, {FluroCalendarContainer} from 'components/fluro-date-picker';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {FieldTag, IInitialMapState, NRecommendationMethod} from '../../../types';
//@ts-ignore
import {CalendarContainer} from 'react-datepicker';
import {LoginState} from '../../../../login/types';
import URLParamsEffect from './URLParamsEffect';
import {formatDate, setGetParamToURL} from '../../../../../_utils';

export type NitrogenRecommendationMethods = 'nutrilogic' | 'apsim' | '';

type Props = ConnectedProps<typeof connector>;

const recommendationMethods = [
  {label: <FormattedMessage id="NutriLogic" defaultMessage="NutriLogic" />, value: 'nutrilogic'},
  {
    label: <FormattedMessage id="APSIM-based N Rx" defaultMessage="APSIM-based N Rx" />,
    value: 'apsim',
  },
];

class NRecommendation extends PureComponent<Props> {
  componentDidMount() {
    const {nRecommendation} = this.props;
    if (
      nRecommendation.toggled &&
      nRecommendation.method === 'nutrilogic' &&
      this.props.currentSeason.cropType === 'cotton'
    ) {
      this.props.runNutrilogicRecommendation();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const isNutrilogicAllowed = this.isNutrilogicAllowed();
    const isNrxAllowed = this.isNrxAllowed();
    const {
      nRecommendation: {toggled, method},
    } = this.props;

    if (toggled && method === 'nutrilogic' && !isNutrilogicAllowed) {
      //reset the method if conditions changed
      this.changeMethod((isNrxAllowed && 'apsim') || '');
    }
    if (toggled && method === 'apsim' && !isNrxAllowed) {
      //reset the method if conditions changed
      this.changeMethod((isNutrilogicAllowed && 'nutrilogic') || '');
    }

    if (toggled && prevProps.nRecommendation.method !== 'nutrilogic' && method === 'nutrilogic') {
      this.props.runNutrilogicRecommendation();
    }
  }

  isNutrilogicAllowed = () => {
    const {currentSeason, isAustralianFieldOrBowles, infoExt} = this.props;
    return Boolean(
      infoExt &&
        infoExt.length &&
        isAustralianFieldOrBowles &&
        currentSeason.cropType === 'cotton' &&
        !this.props.currentPointId &&
        currentSeason.tissueSampling &&
        currentSeason.tissueSampling.length
    );
  };

  isNrxAllowed = () => {
    const {field, infoExt} = this.props;
    return Boolean(
      infoExt &&
        infoExt.length &&
        field.tags &&
        field.tags.includes(FieldTag.NRx) &&
        field.Seasons.length
    );
  };

  changeMethod = (value: NitrogenRecommendationMethods) => {
    this.props.updateRecommendationSettings({method: value});
  };

  calculateMethod = (isNutrilogicAllowed: boolean, isNrxAllowed: boolean) => {
    const {method} = this.props.nRecommendation;
    return isNutrilogicAllowed && isNrxAllowed
      ? method
      : isNutrilogicAllowed
      ? 'nutrilogic'
      : isNrxAllowed
      ? 'apsim'
      : method;
  };

  goToFarmTableView = () => {
    this.props.setFeature('farm');
    this.props.toggleTableView('farm');
  };

  getMaxDate = () => {
    const mSeasonEndDate = moment(this.props.currentSeason.endDate);
    const mNow = moment();
    return mSeasonEndDate.isBefore(mNow) ? mSeasonEndDate : mNow;
  };

  datePickerProps = ({className, children}: {className?: string; children: ReactNode}) => {
    return (
      <FluroCalendarContainer defaultCalendar={children} className={className}>
        <div className={'calendar-colors-info'}>
          <div className={'calendar-colors-info__item calendar-colors-info__item_gray'}>
            {t({id: 'Processing'})}
          </div>
          <div className={'calendar-colors-info__item calendar-colors-info__item_green'}>
            {t({id: 'Results ready'})}
          </div>
        </div>
      </FluroCalendarContainer>
    );
  };

  render() {
    const {
      nRecommendation,
      currentSeason,
      temperatureDataExist,
      toggleNRxSettingsPopUp,
    } = this.props;
    const isNutrilogicAllowed = this.isNutrilogicAllowed();
    const isNrxAllowed = this.isNrxAllowed();
    const calculatedMethod = this.calculateMethod(isNutrilogicAllowed, isNrxAllowed);
    const currentMethod = nRecommendation.method;
    const recommendationMaxDate = this.getMaxDate();
    const nrxSeason = getNrxSeason(currentSeason.id);
    const lastAvailableRecommendationDate = getLastAvailableRecommendationDate(
      nrxSeason.recommendationDates
    );
    const recommendationDate = nRecommendation.nrxPopUpValues.recommendation_date
      ? moment(nRecommendation.nrxPopUpValues.recommendation_date, GLOBAL_FORMAT_DATE)
      : moment(lastAvailableRecommendationDate, GLOBAL_FORMAT_DATE);

    const apsimModelStatus = isNrxAllowed
      ? checkSeasonStatus(nrxSeason, this.goToFarmTableView, true)
      : null;

    const pointsGroupsValues = Object.keys(this.props.pointsGroups)
      .map(value => ({
        label: value,
        value,
      }))
      .filter(el => el.value !== 'all' && el.value !== 'add');

    const highlightDates = [
      {
        'recommendation-ready': nrxSeason.recommendationDates.map((d: string) =>
          moment(d, GLOBAL_FORMAT_DATE).toDate()
        ),
      },
    ];

    return (
      <>
        <URLParamsEffect
          isNRXRecommendationAllowed={isNutrilogicAllowed || isNrxAllowed}
          calculatedMethod={calculatedMethod}
          currentMethod={currentMethod}
          recommendationDates={nrxSeason.recommendationDates}
          updateRecommendationDate={this.props.updateRecommendationDate}
          recommendationDate={recommendationDate.format(GLOBAL_FORMAT_DATE)}
          lastAvailableRecommendationDate={lastAvailableRecommendationDate}
          hasTemperatureData={temperatureDataExist}
          nRecommendationToggled={nRecommendation.toggled}
        />

        {isNutrilogicAllowed || isNrxAllowed ? (
          <div className={'nutrilogics-container'}>
            <div className={'header-part'}>
              <LeafIcon className={'nutrilogic-leaflet'} />

              <span>{t({id: 'Nitrogen Recommendation'})}</span>

              {currentMethod === 'apsim' && (
                <Button
                  id={'edit-nrx-season-settings'}
                  className={`edit-nrx-season-settings`}
                  icon
                  onClick={this.goToFarmTableView}
                >
                  edit
                </Button>
              )}

              <SelectionControl
                id="nutrologic-toggle"
                type="switch"
                label={''}
                labelBefore={true}
                name="nutrologic-toggle"
                className="nutrologic-toggle"
                onChange={value => this.toggleNRecommendation(Boolean(value), calculatedMethod)}
                checked={nRecommendation.toggled}
              />

              {isNutrilogicAllowed && currentMethod === 'nutrilogic' ? (
                <>
                  <HelpBtn id="nutrilogic-help" className="help-method-icon" />

                  <HelpModal title="Nitrogen Recommendation" id="nutrilogic-help" width={400}>
                    <div className="helper-text">
                      {t({
                        id:
                          'The tool uses your TSP nitrate (NO3) results to elaborate tailored N recommendation. The recommendation is extrapolated to the zone your TSP(s) belong too. When exporting you recommendations, remember to adapt the N values according to the type of fertiliser you are using.',
                      })}
                    </div>
                  </HelpModal>
                </>
              ) : null}
            </div>

            {nRecommendation.toggled && (
              <>
                {isNutrilogicAllowed && isNrxAllowed ? (
                  <SelectField
                    id="select-recommendation-method"
                    placeholder={t({id: 'Select method'})}
                    className="element-full-width"
                    label={t({id: 'Select recommendation method'})}
                    menuItems={recommendationMethods}
                    value={currentMethod}
                    onChange={this.changeMethod}
                    simplifiedMenu={true}
                  />
                ) : null}

                {isNutrilogicAllowed && currentMethod === 'nutrilogic' ? (
                  <SelectField
                    id="map-tissue-sampling-date"
                    placeholder={t({id: 'Tissue Sampling Group'})}
                    className="element-full-width"
                    label={t({id: 'Tissue Sampling Group'})}
                    menuItems={pointsGroupsValues}
                    value={this.props.pointsCurrentGroupDate}
                    onChange={this.selectTSDate}
                    simplifiedMenu={true}
                  />
                ) : null}

                {isNrxAllowed && currentMethod === 'apsim' && lastAvailableRecommendationDate ? (
                  <>
                    <div className="date-setting-container">
                      <div className={'nrx-model-status-date'}>
                        <span>{t({id: 'Recommendation date'})}</span>
                        <FluroDatePicker
                          id="set-recommendation-date"
                          label=""
                          onChange={(val: any) =>
                            this.props.updateRecommendationDate(val.format(GLOBAL_FORMAT_DATE))
                          }
                          onChangeRaw={(ev: any) => ev.preventDefault()}
                          selected={recommendationDate}
                          hideFormat
                          highlightDates={highlightDates}
                          minDate={moment(currentSeason.startDate)}
                          maxDate={recommendationMaxDate}
                          calendarContainer={this.datePickerProps}
                        />
                      </div>
                      <Button
                        id={'edit-recommendation-settings'}
                        className={'btn-with-icon edit-nrx-recommendations'}
                        raised
                        iconEl={<FontIcon>edit</FontIcon>}
                        iconBefore={false}
                        primary
                        onClick={() => toggleNRxSettingsPopUp(!nRecommendation.settingsPopUpOpen)}
                      >
                        {t({id: 'rx settings'})}
                      </Button>
                    </div>
                  </>
                ) : null}
                {apsimModelStatus}

                <RecommendationSettingsPopUp />
              </>
            )}
          </div>
        ) : null}
      </>
    );
  }

  toggleNRecommendation = (value: boolean, method: NRecommendationMethod) => {
    this.props.toggleNRecommendation(value, method);
  };

  selectTSDate = (value: string) => {
    setGetParamToURL('nrx-date', moment(value, formatDate()).format('YYYY-MM-DD'));
    this.props.pointsSetGroupDate(value);
    this.props.runNutrilogicRecommendation();
  };
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: LoginState}) => ({
  nRecommendation: map.nRecommendation,
  pointsGroups: map.pointsGroups,
  currentSeason: map.currentSeason,
  infoExt: map.infoExt,
  currentPointId: map.currentPointId,
  pointsCurrentGroupDate: map.pointsCurrentGroupDate,
  isAustralianFieldOrBowles: map.isAustralianFieldOrBowles,
  measurement: login.user.settings.measurement,
  field: map.field,
  fields: map.fields,
  temperatureDataExist: !!map.temperatureData.length,
});

const connector = connect(mapStateToProps, {
  pointsSetGroupDate,
  toggleNRecommendation,
  runNutrilogicRecommendation,
  updateRecommendationSettings,
  updateRecommendationDate,
  toggleNRxSettingsPopUp,
  setFeature,
  toggleTableView,
});
export default connector(NRecommendation);
