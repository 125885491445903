import React, {useCallback, useEffect, useState} from 'react';
import {t} from 'i18n-utils';
import {useDispatch} from 'react-redux';
import {Button, SelectionControl} from 'react-md';
import config from '_environment';
import {showNote} from '_actions';
import {ClimateApi} from '_api';
import {RequestStatus} from 'types';
import {ClimateFarm} from './types';
import {syncClimateFields} from './actions';
import Mixpanel from '_utils/mixpanel-utils';
import {ExternalService} from '../../map/types';
import cn from 'classnames';
import HelpBtn from '../../help-popup/btn';
import HelpModal from '../../help-popup/modal';
import IntegrationCard from './helpers/integration-card';
import ColoredBorder from './helpers/colored-border';
import {
  ExpandableArea,
  ExpandableRowContainer,
  SubContainer,
  SubItem,
} from 'components/expandable-table-items/expandable-table-items';
import {useAppSelector} from '../../../_hooks';
import {FluroButton} from '../../../components';

/**
 * Climate integration flow:
 *
 * 1. Fetch farms list
 * -> if success – show the growers list
 *
 * 2. A user can select farms or fields to import from Climate to FluroSense.
 * -> As request payload we are always using field ids, farm selection is just a visual effect
 */
type Props = {
  expanded: boolean;
  setExpanded(): void;
};

const IntegrationClimate = ({expanded, setExpanded}: Props) => {
  const dispatch = useDispatch();
  const platform = useAppSelector(store => store.integrations.platforms[ExternalService.Climate]);

  const [authorized, setAuthorized] = useState<boolean>(undefined);
  const [farms, setFarms] = useState<ClimateFarm[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectedFarms, setSelectedFarms] = useState<string[]>([]);
  const [expandedFarms, setExpandedFarms] = useState<{[farmId: string]: boolean}>({});

  const getFarms = useCallback(() => {
    setLoading(true);
    ClimateApi.getFarms()
      .then(({data}) => {
        setAuthorized(true);
        const resultFarms = Object.keys(data.result.farms).map(farmId => {
          return {
            id: farmId,
            name: data.result.farms[farmId][0].farm_name,
            fields: data.result.farms[farmId].map(({field_name, field_id}) => ({
              name: field_name,
              id: field_id,
            })),
          };
        });

        setFarms(resultFarms);
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        setAuthorized(false); // Assume a user isn't authorized if no farms returned.
        showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'fetchClimateFarmsErr', defaultMessage: "Couldn't fetch Climate farms."}),
          level: 'error',
        });
      });
  }, []);

  useEffect(getFarms, []);

  useEffect(() => {
    if (expanded && !farms.length && !loading) {
      getFarms();
    }
  }, [expanded]);

  useEffect(
    function calculateSelectedFarms() {
      const selectedFarms = farms
        .filter(f => f.fields.every(f => selectedFields.includes(f.id))) // get farms with all selected fields inside
        .map(({id}) => id); // return farm ids

      setSelectedFarms(selectedFarms);
    },
    [selectedFields]
  );

  const toggleField = (fieldId: string, value: boolean) => {
    setSelectedFields(selectedFields => {
      return value ? [...selectedFields, fieldId] : selectedFields.filter(id => id !== fieldId);
    });
  };

  const toggleFarm = (farmId: string, value: boolean) => {
    const fieldIds = farms.find(f => f.id === farmId).fields.map(f => f.id);
    setSelectedFields(selectedFields => {
      return value
        ? [...new Set([...selectedFields, ...fieldIds])]
        : selectedFields.filter(fieldId => !fieldIds.includes(fieldId));
    });
  };

  const toggleAllFarms = (value: boolean) => {
    setSelectedFields(value ? farms.map(farm => farm.fields.map(field => field.id)).flat() : []);
  };

  const onExpandFarm = (farmId: string) => {
    setExpandedFarms(expandedFarms => ({...expandedFarms, [farmId]: !expandedFarms[farmId]}));
  };

  const onImportFields = () => {
    Mixpanel.importSync(ExternalService.Climate, selectedFields);
    dispatch(syncClimateFields(selectedFields));
  };

  return (
    <div className={cn('integration-platform climate', {expanded, selected: platform.selected})}>
      <IntegrationCard
        authorized={authorized}
        expanded={expanded}
        logoSrc={'/assets/integration/climate-logo-round.svg'}
        logoAlt={'logo-climate'}
        platformCompanyName={'Climate'}
        platformName={'Climate FieldView'}
        onAuthorize={authorize}
        onToggle={setExpanded}
        platformInnerName={ExternalService.Climate}
        isPlatformSelected={platform.selected}
        loading={loading && !farms.length}
      />

      {expanded && authorized && (
        <>
          {loading && !farms.length ? null : (
            <>
              <div className="integration-platform__description">
                {t({id: 'Selected farms will be imported to FluroSense.'})}
              </div>

              <div className={'entities-to-import-container climate'}>
                {farms.length > 1 && (
                  <SelectionControl
                    id={'select-all-climate-farms'}
                    name={'select-all-climate-farms'}
                    label={t(
                      {id: 'Select all farms ({count1}/{count2})'},
                      {count1: selectedFarms.length, count2: farms.length}
                    )}
                    type={'checkbox'}
                    className={'select-all-checkbox'}
                    checked={selectedFarms.length === farms.length}
                    onChange={(v: boolean) => toggleAllFarms(v)}
                  />
                )}
                {farms.map(farm => {
                  const farmIsSelected = selectedFarms.includes(farm.id);
                  return (
                    farm.fields.length !== 0 && (
                      <React.Fragment key={farm.id}>
                        <ExpandableRowContainer className={cn({selected: farmIsSelected})}>
                          <SelectionControl
                            id={farm.id}
                            name={'farm selection'}
                            className={'highest-platform-entity'}
                            label={farm.name}
                            type={'checkbox'}
                            checked={farmIsSelected}
                            onChange={(v: boolean) => toggleFarm(farm.id, v)}
                          />

                          <ExpandableArea
                            onClick={() => onExpandFarm(farm.id)}
                            expanded={expandedFarms[farm.id]}
                          />
                        </ExpandableRowContainer>

                        {expandedFarms[farm.id] && (
                          <SubContainer>
                            {farm.fields.map(field => (
                              <SubItem>
                                <SelectionControl
                                  id={field.id}
                                  className={'medium-platform-entity'}
                                  name={'field selection'}
                                  label={field.name}
                                  type={'checkbox'}
                                  checked={selectedFields.includes(field.id)}
                                  onChange={(v: boolean) => toggleField(field.id, v)}
                                />
                              </SubItem>
                            ))}
                          </SubContainer>
                        )}
                      </React.Fragment>
                    )
                  );
                })}
              </div>

              <div className="action-buttons-holder">
                <FluroButton blank raised onClick={authorize}>
                  {t({id: 'Re-authorize'})}
                </FluroButton>

                <div className="md-text-right">
                  {platform.syncStatus === RequestStatus.Loading && (
                    <>
                      <HelpBtn
                        icon={'refresh'}
                        id="climate-sync-status"
                        className="integration-platform__sync-status"
                      />
                      <HelpModal title="Sync in progress" id="climate-sync-status" width={400}>
                        <div className="helper-text">
                          {t({id: 'You will be notified when it is ready'})}
                        </div>
                      </HelpModal>
                    </>
                  )}

                  <Button
                    raised
                    primary
                    className="md-btn--right"
                    disabled={
                      !selectedFields.length || platform.syncStatus === RequestStatus.Loading
                    }
                    onClick={onImportFields}
                  >
                    {platform.syncStatus === RequestStatus.Loading
                      ? t({id: 'Importing...'})
                      : t({id: 'Import'})}
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <ColoredBorder colorStart={'rgb(234, 162, 47)'} colorEnd={'rgba(234, 162, 47, 0.2)'} />
    </div>
  );
};

const authorize = () => {
  // @ts-ignore
  window.location = `${config.baseUrl}login/climate`;
};

export default IntegrationClimate;
