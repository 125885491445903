import {routerMiddleware} from 'react-router-redux';
import googleTagManagerMiddleware from 'containers/google-tag-manager';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {createBrowserHistory} from 'history';
import rootReducer, {AppStore} from './reducers';
import config from '_environment';
import {configureStore, MiddlewareArray} from '@reduxjs/toolkit';

const logger = createLogger({
  collapsed: true,
});

export const history = createBrowserHistory({
  basename: '/app',
});

const initialState = {};

const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (config.env === 'production' && typeof googleTagManagerMiddleware === 'function') {
  middleware.push(googleTagManagerMiddleware);
} else {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  middleware.push(logger);

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: getDefaultMiddleware =>
    // We already have at least "some" bad calls in _api/base re dispatching stuff at the wrong time,
    // so disable immutableCheck.
    getDefaultMiddleware({immutableCheck: false, serializableCheck: false}).concat(middleware),
  enhancers: enhancers,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

/*
 *
 * Relating to new version of redux in which was added isDispatching checking
 * https://github.com/reduxjs/redux/issues/1668
 *
 * this is dirty hack for avoiding exception when calling Store.getState() direct
 *
 *
 * */
let _state = store.getState();
export const hackGetState = (): AppStore => _state;
function handleChange() {
  _state = store.getState();
}
store.subscribe(handleChange);

if (module.hot) {
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
