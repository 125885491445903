import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {dialogToggle} from 'modules/ui-helpers';
import {Button, SelectionControl} from 'react-md';
import Dialog from 'components/fluro-dialog';
import {genKey} from '_utils';
import {DialogFooter} from 'containers/admin/admin.styled';
import {CommonDialogContentWrapper} from './dialogs.styled';
import {FluroButton} from 'components';

const ConfirmDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();
  const {visible, payload} = useSelector((state: AppStore) => state.uiHelpers.dialogs.confirm);

  const [isAgree, setAgree] = useState(false);

  useEffect(() => {
    if (payload.agreementsMessage) {
    }
  }, [payload.agreementsMessage]);

  const title = useMemo(() => {
    return payload?.title || 'Confirm';
  }, [payload]);

  const okLabel = useMemo(() => {
    return payload?.okLabel || 'Ok';
  }, [payload]);

  const message = useMemo(() => {
    return (
      <>
        <div>{payload?.message || 'Are you sure?'}</div>
        {payload?.agreementsMessage ? (
          <div className="agreements-message">
            <SelectionControl
              id={`checkbox-${id}`}
              name={`checkbox-${id}`}
              type={'checkbox'}
              onChange={() => setAgree(!isAgree)}
              checked={isAgree}
              label={payload.agreementsMessage}
            />
          </div>
        ) : null}
      </>
    );
  }, [payload, isAgree]);

  const onCancel = useCallback(() => dispatch(dialogToggle('confirm', false)), []);
  const onOk = useCallback(() => {
    if (payload?.okAction) {
      payload.okAction();
      onCancel();
    }
  }, [payload]);

  return (
    <Dialog
      id={`confirm-dialog-${id}`}
      visible={visible}
      title={title}
      focusOnMount={false}
      isDraggable
      onHide={onCancel}
      width={400}
      portal
      actions={
        <DialogFooter fullWidth>
          <FluroButton blank raised onClick={onCancel} style={{marginLeft: -16}}>
            {t({id: 'Cancel'})}
          </FluroButton>
          <Button primary raised onClick={onOk} disabled={payload?.agreementsMessage && !isAgree}>
            {okLabel}
          </Button>
        </DialogFooter>
      }
    >
      <CommonDialogContentWrapper>{message}</CommonDialogContentWrapper>
    </Dialog>
  );
};

export default ConfirmDialog;
