import {t, FormattedMessage} from 'i18n-utils';
import React, {useMemo, useState, memo, useEffect, useCallback, useRef} from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardChartGroup,
  CardChart,
  SelectedCountWrapper,
} from './crop-stress-card.styled';
import {Doughnut} from 'react-chartjs-2';
import {ClearFilterButton} from 'containers/map/features/crop-performance/helpers';
import {useDispatch, useSelector} from 'react-redux';
import CardChartLegend from './card-chart-legend';
import {AppStore} from 'reducers';
import moment from 'moment';
import {formatDate} from '_utils';
import {CropStressChartData, CSGViewModel} from '../types';
import {FieldTag, Field} from '../../../types';
import {setCropStressChartData} from '../reducer';
import {GLOBAL_APP_DATE_FORMAT} from '_constants';
import {ChartComponentProps} from 'react-chartjs-2';
import {StressedFieldsPerDate} from 'containers/map/features/anomalies/types';
import {cropStressLabelColors, CropStressChartLabels as Labels} from '../types';

type CropStressCardFilter = {
  label: Labels;
  color: string;
};

type LegendDataItem = {
  label: Labels;
  count: number;
  color: string;
  fieldIds: number[];
};

type Props = {
  active: boolean;
  recordsBeforeFiltration: CSGViewModel[];
  recordsAfterFiltration: CSGViewModel[];
  twoWeeksAgoRecordsBeforeFiltration: CSGViewModel[];
  twoWeeksAgoRecordsAfterFiltration: CSGViewModel[];

  onFilterToggle: (f: CropStressCardFilter) => void;
  onWidgetClick: () => void;
  onFilterClear: () => void;
};

enum StressChartType {
  Current = 'current',
  TwoWeeksAgo = 'twoWeeksAgo',
}

type ChartSettings = {
  current: ChartComponentProps;
  twoWeeksAgo: ChartComponentProps;
};

const CHART_WIDTH = 140;

const CropStressCard = memo(
  ({
    twoWeeksAgoRecordsBeforeFiltration,
    twoWeeksAgoRecordsAfterFiltration,
    recordsBeforeFiltration,
    recordsAfterFiltration,
    active,
    onWidgetClick,
    onFilterClear,
    onFilterToggle,
  }: Props) => {
    const dispatch = useDispatch();

    const currentDate = useSelector((s: AppStore) => s.map.currentDate);
    const fields = useSelector((s: AppStore) => s.map.fields);
    const csFilters = useSelector((s: AppStore) => s.cropPerformanceFilter.filters.cropStress);
    const currentFarmId = useSelector((store: AppStore) => store.global.currentGroupId);
    const stressedFields = useSelector((store: AppStore) => store.cropPerformance.stressedFields);

    const [chartActive, setChartActive] = useState<StressChartType>(StressChartType.Current);

    const passedRecords = recordsBeforeFiltration;

    const getSelectedItem = useCallback((label: Labels) => csFilters.find(f => f.value === label), [
      csFilters,
    ]);

    const passedFieldIds: {[fieldId: number]: boolean} = {};
    recordsBeforeFiltration.forEach(r => (passedFieldIds[r.fieldID] = true));

    const prevDate = moment(currentDate, GLOBAL_APP_DATE_FORMAT)
      .subtract(2, 'weeks')
      .format(formatDate());

    const curDate = moment(currentDate, GLOBAL_APP_DATE_FORMAT).format(formatDate());

    const legendData = useMemo((): {current: LegendDataItem[]; twoWeeksAgo: LegendDataItem[]} => {
      return {
        current: getDataByDate(
          currentDate,
          currentFarmId,
          stressedFields,
          passedRecords,
          fields,
          passedFieldIds
        ),

        twoWeeksAgo: getDataByDate(
          moment(currentDate, GLOBAL_APP_DATE_FORMAT)
            .subtract(2, 'weeks')
            .format(GLOBAL_APP_DATE_FORMAT),
          currentFarmId,
          stressedFields,
          twoWeeksAgoRecordsBeforeFiltration,
          fields,
          passedFieldIds
        ),
      };
    }, [passedRecords, fields, currentFarmId, stressedFields, twoWeeksAgoRecordsBeforeFiltration]);

    useEffect(() => {
      let data: CropStressChartData = {};

      legendData[chartActive].forEach(item => {
        item.fieldIds.forEach(id => {
          data[id] = item.label;
        });
      });

      dispatch(setCropStressChartData(data));
    }, [legendData, chartActive]);

    const setFilterByClickOnSector = useCallback(
      (chartType: StressChartType, event, activeElements: {_model: {label: Labels}}[]) => {
        if (!activeElements.length) {
          return;
        }

        const label = activeElements[0]?._model?.label;
        const item = legendData[chartType].find(item => item.label === label);

        if (!item) {
          return;
        }

        if (chartActive !== chartType) {
          setActiveChart(chartType);
        }

        const filterItem: CropStressCardFilter = {
          label: item.label,
          color: item.color,
        };

        onFilterToggle(filterItem);
      },
      [legendData, chartActive]
    );

    const getOneChartProps = useCallback(
      (chartType: StressChartType, data: LegendDataItem[]): ChartComponentProps => {
        return {
          legend: {
            display: false,
          },
          options: {
            responsive: false,
            onClick: (event, activeElements: {_model: {label: Labels}}[]) => {
              setFilterByClickOnSector(chartType, event, activeElements);
            },
          },
          data: {
            datasets: [
              {
                data: data.map(item => item.count),
                backgroundColor: data.map(item =>
                  (getSelectedItem(item.label) || !csFilters.length) && chartActive === chartType
                    ? item.color
                    : item.color + '33'
                ),
              },
            ],

            labels: data.map(item => t({id: item.label})),
          },
        };
      },
      [getSelectedItem, csFilters, chartActive, setFilterByClickOnSector]
    );

    const chartSettings = useMemo((): ChartSettings => {
      return {
        current: getOneChartProps(StressChartType.Current, legendData.current),
        twoWeeksAgo: getOneChartProps(StressChartType.TwoWeeksAgo, legendData.twoWeeksAgo),
      };
    }, [legendData, getOneChartProps]);

    const setActiveChart = useCallback(
      (value: StressChartType) => {
        if (chartActive !== value) {
          onFilterClear();
        }

        setChartActive(value);
      },
      [chartActive, onFilterClear]
    );

    const recordsNumberPassed = useMemo(
      () =>
        (chartActive === StressChartType.Current
          ? recordsAfterFiltration
          : twoWeeksAgoRecordsAfterFiltration
        ).length,
      [chartActive, recordsAfterFiltration, twoWeeksAgoRecordsAfterFiltration]
    );

    const recordsNumber = useMemo(
      () =>
        (chartActive === StressChartType.Current
          ? recordsBeforeFiltration
          : twoWeeksAgoRecordsBeforeFiltration
        ).length,
      [chartActive, recordsBeforeFiltration, twoWeeksAgoRecordsBeforeFiltration]
    );

    return (
      <Card
        active={active}
        onClick={() => {
          onWidgetClick();
        }}
      >
        <CardHeader>
          <CardTitle>{t({id: 'Crop stress'})}</CardTitle>

          <ClearFilterButton disabled={!csFilters.length} onClick={onFilterClear} />
        </CardHeader>

        <CardChartGroup>
          <CardChart
            onClick={() => setActiveChart(StressChartType.TwoWeeksAgo)}
            active={chartActive === StressChartType.TwoWeeksAgo}
          >
            <div className="date">{prevDate}</div>
            <Doughnut
              width={CHART_WIDTH}
              data={chartSettings.twoWeeksAgo.data}
              legend={chartSettings.twoWeeksAgo.legend}
              options={chartSettings.twoWeeksAgo.options}
            />
          </CardChart>
          <CardChart
            onClick={() => setActiveChart(StressChartType.Current)}
            active={chartActive === StressChartType.Current}
          >
            <div className="date">{curDate}</div>
            <Doughnut
              width={CHART_WIDTH}
              data={chartSettings.current.data}
              legend={chartSettings.current.legend}
              options={chartSettings.current.options}
            />
          </CardChart>
        </CardChartGroup>

        <SelectedCountWrapper>
          {t(
            {id: '{count1} / {count2} crops selected'},
            {count1: recordsNumberPassed, count2: recordsNumber}
          )}
        </SelectedCountWrapper>

        <CardChartLegend
          data={legendData[chartActive].map(item => {
            return {
              color: item.color,
              label: item.label,
              count: item.count,
              selected: !!getSelectedItem(item.label),
            };
          })}
          onChange={(item, value: boolean) => {
            const filterItem: CropStressCardFilter = {
              label: item.label,
              color: item.color,
            };
            onFilterToggle(filterItem);
          }}
        />
      </Card>
    );
  }
);

function getDataByDate(
  currentDate: string,
  currentFarmId: number,
  stressedFields: {[farmId: number]: StressedFieldsPerDate[]},
  passedRecords: CSGViewModel[],
  fields: Field[],
  passedFieldIds: {[fieldId: number]: boolean}
): LegendDataItem[] {
  const stressed = stressedFields?.[currentFarmId] || [];

  const currentStress = stressed.find(stress =>
    moment(stress.sensing_date).isSame(moment(currentDate, GLOBAL_APP_DATE_FORMAT), 'day')
  );

  const status: {[fieldId: number]: Labels} = {};

  fields.forEach(f => {
    if (passedFieldIds[f.ID]) {
      if (!f.tags.includes(FieldTag.AnomalyDetection)) {
        status[f.ID] = Labels.NotActivated;
      }
    }
  });

  (currentStress?.fields || []).forEach(f => {
    if (passedFieldIds[f.kml_id]) {
      status[f.kml_id] = Labels.StressDetected;
    }
  });

  passedRecords.forEach(r => {
    if (!r.reliable) {
      status[r.fieldID] = Labels.NoImages;
      return;
    }
    // This check should be the very last, we need to check if any other status is applied first.
    if (!status[r.fieldID]) {
      status[r.fieldID] = Labels.NoStressDetected;
    }
  });

  const noImagesFields = passedRecords.filter(r => !r.reliable);
  const notActivatedFields = fields.filter(
    f => !f.tags.includes(FieldTag.AnomalyDetection) && passedFieldIds[f.ID]
  );
  const stressDetectedFields = (currentStress?.fields || []).filter(
    field => passedFieldIds[field.kml_id]
  );

  const noStressExcludeFieldIds = [
    ...noImagesFields.map(f => f.fieldID),
    ...notActivatedFields.map(f => f.ID),
    ...stressDetectedFields.map(f => f.kml_id),
  ];

  const noStressFields = passedRecords.filter(r => !noStressExcludeFieldIds.includes(r.fieldID));

  return [
    {
      label: Labels.StressDetected,
      count: stressDetectedFields.length,
      color: cropStressLabelColors[Labels.StressDetected],
      fieldIds: stressDetectedFields.map(f => f.kml_id),
    },
    {
      label: Labels.NoStressDetected,
      count: noStressFields.length,
      color: cropStressLabelColors[Labels.NoStressDetected],
      fieldIds: noStressFields.map(f => f.fieldID),
    },
    {
      label: Labels.NotActivated,
      count: notActivatedFields.length,
      color: cropStressLabelColors[Labels.NotActivated],
      fieldIds: notActivatedFields.map(f => f.ID),
    },
    {
      label: Labels.NoImages,
      count: noImagesFields.length,
      color: cropStressLabelColors[Labels.NoImages],
      fieldIds: noImagesFields.map(f => f.fieldID),
    },
  ];
}

export default CropStressCard;
