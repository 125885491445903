import {unreachableError} from '_utils';
import {aggregate} from '../helpers';
import {cropGrowthColors, CropGrowth, cropGrowthLabels, CSGViewModel, Aggregation} from '../types';

export const getCropGrowthChartData = (
  records: CSGViewModel[],
  useUnreliableData: boolean,
  aggregation: Aggregation
) => {
  const values: {[growth: string]: number} = {};
  records.forEach(r => {
    const label =
      r.cropStatus === 'No images' || (!r.reliable && !useUnreliableData) ? -10 : r.growth;
    if (!values[label]) {
      values[label] = 0;
    }
    values[label] += aggregate(aggregation, r);
  });
  const labels = Object.keys(values)
    .map(g => Number(g))
    .sort((a, b) => b - a) as CropGrowth[];
  return {
    labels: labels.map(s => cropGrowthLabels[s]),
    values: labels.map(s => values[s]),
    colors: labels.map(s => cropGrowthColors[s]),
  };
};

export const getCropGrowthMapChartData = (records: CSGViewModel[], useUnreliableData: boolean) => {
  const cropGrowthCount: {[growth: string]: number} = {};
  records.forEach(r => {
    const label =
      r.cropStatus === 'No images' || (!r.reliable && !useUnreliableData) ? -10 : r.growth;
    cropGrowthCount[label] = (cropGrowthCount[label] || 0) + 1;
  });
  const labels = Object.keys(cropGrowthCount)
    .map(g => Number(g))
    .sort((a, b) => b - a) as CropGrowth[];
  const data = labels.map(label => ({
    id: label,
    label: cropGrowthLabels[label],
    value: cropGrowthCount[label],
  }));
  const getColor = (slice: any) => cropGrowthColors[slice.id as CropGrowth];
  return {data, getColor};
};
