import {ShpJSBuffer, FeatureCollectionWithFilename} from 'shpjs';
import {reportError} from 'containers/error-boundary';
import {kmlToGeoJSON} from '_utils/geometry';

enum AllowedFileType {
  Kml = 'kml',
  Kmz = 'kmz',
  Zip = 'zip',
  GeoJSON = 'geojson',
}

/**
 * Parses geometries from the file.
 * Returns successfully parsed geometries along with possible errors.
 * It never rejects, it resolves {features, errors} object.
 *
 * Can parse:
 * - kml
 * - kmz
 * - zip
 *   - of shp
 *   - of kml
 *   - of kmz
 *   - of zip
 */
export const parseGeometryFile = (
  file: File
): Promise<{
  features: FeatureCollectionWithFilename | FeatureCollectionWithFilename[];
  errors: string[];
}> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    const nameParts = file.name.split('.');
    const fileExtension = nameParts.pop();
    const fileName = nameParts.join('.');

    reader.onload = async (e: any) => {
      const features: FeatureCollectionWithFilename[] = [];
      const errors: string[] = [];
      await parse(features, errors, fileName, e.target.result, fileExtension);
      resolve({features, errors});
    };

    if (
      rABS &&
      (fileExtension === AllowedFileType.Kml || fileExtension === AllowedFileType.GeoJSON)
    ) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }

    /**
     * Recursive parsing function.
     * - Can handle an infinite depth of zip files
     * - Collects any supported files throughout this traversing
     * - Collect errors for unsupported files
     * - Collect errors for the supported files it couldn't parse
     */
    const parse = async (
      results: FeatureCollectionWithFilename[], // collect results into a flat array
      errors: string[], // collect errors into a flat array
      fileName: string,
      data: string | Blob | Uint8Array | ArrayBuffer,
      fileExtension: AllowedFileType | string
    ) => {
      switch (fileExtension) {
        case AllowedFileType.Kml:
          try {
            results.push(addFileName(kmlToGeoJSON(data as string), fileName));
          } catch (e) {
            const message = `Could not parse the ${fileName} file`;
            reportError(`${message}: ${e}`);
            errors.push(message);
          }
          break;

        case AllowedFileType.GeoJSON: {
          // TODO (Stas): never tested. Had too big files, wasn't able to parse them in a browser.
          const collection = JSON.parse(data as string);
          results.push(...collection.features);
          break;
        }

        case AllowedFileType.Zip:
        case AllowedFileType.Kmz:
          const JSZip_ = (await import('jszip')).default;
          const zip_ = await new JSZip_().loadAsync(data);
          const files_ = zip_
            .file(/.+/)
            .filter(f => !f.name.includes('__MACOSX') && !f.name.includes('.DS_Store')); // filter out macos meta files

          // If there is no shp files, try to parse them again.
          if (!files_.some(f => f.name.endsWith('.shp'))) {
            for (let f of files_) {
              const nameParts = f.name.split('.');
              const fileExtension = nameParts.pop();
              const fileName = nameParts.join('.');
              let data;
              switch (fileExtension) {
                case AllowedFileType.Kml:
                  data = await f.async('text');
                  break;
                case AllowedFileType.Kmz:
                case AllowedFileType.Zip:
                  data = await f.async('arraybuffer');
                  break;
                default:
                  errors.push(`Unsupported file type: ${fileName}`);
                  continue;
              }
              await parse(results, errors, fileName, data, fileExtension);
            }
            return;
          }

          // Assuming that shp files should be uploaded via zip archive.
          const shpjs = (await import('shpjs')).default;
          const binData: ShpJSBuffer = data as Buffer | ArrayBuffer;
          try {
            const featureCollection = await shpjs(binData);
            if (featureCollection instanceof Array) {
              results.push(
                ...featureCollection.map(f => {
                  const names = f.fileName.split('/');
                  return {...f, fileName: names[names.length - 1]};
                })
              );
            } else {
              results.push(featureCollection);
            }
          } catch (e) {
            const message = [
              `Archive ${file.name} contains invalid shape file format.`,
              `Make sure the archive does not contain another archive.`,
            ].join(' ');
            reportError(`${message}: ${e}`);
            errors.push(message);
          }
          break;

        default:
          errors.push(`Unsupported file type: ${fileName}`);
      }
    };
  });
};

const addFileName = (
  f: GeoJSON.FeatureCollection,
  fileName: string
): FeatureCollectionWithFilename => {
  const withName = f as FeatureCollectionWithFilename;
  // Add fileName to FeatureCollection, since shpjs does it under the hood for shp files.
  // for archived files in folders - remove path from the name EXAMPLE: /path/to/file/test.kml -> test.kml
  const names = fileName.split('/');
  withName.fileName = names[names.length - 1];
  return withName;
};
