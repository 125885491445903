import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {GeoJSON} from 'react-leaflet';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {point as turfPoint} from '@turf/helpers';
import L, {LatLng, Marker} from 'leaflet';
import {IInitialMapState} from '../../types';
import {Feature} from 'geojson';
type Props = ConnectedProps<typeof connector>;

const ZoningPoints = ({currentFieldKml, feature, zoning}: Props) => {
  const onEachFeature = (feature: Feature, layer: L.Path) => {
    layer.on('dragend', ({target}) => {
      const {
        _latlng: {lat, lng},
      } = target;
      const point = turfPoint([lng, lat]);
      if (booleanPointInPolygon(point, currentFieldKml)) {
        target.feature.geometry.coordinates[0] = lng;
        target.feature.geometry.coordinates[1] = lat;
      }
    });
  };

  const pointToLayer = (feature: Feature, latlng: LatLng) => {
    // @ts-ignore
    return new Marker.SVGMarker(latlng, {
      draggable: true,
      iconOptions: {
        circleFillColor: feature.properties.color,
        circleColor: '#fff',
        fillColor: '#fff',
        fillOpacity: 1,
        color: 'rgba(255, 255, 255, .5)',
        circleWeight: 0.5,
      },
    });
  };

  return (feature === 'zoning' || feature === 'tsp') && Object.keys(zoning.points).length ? (
    <GeoJSON
      key={zoning.pointsKey}
      onEachFeature={onEachFeature}
      pointToLayer={pointToLayer}
      data={zoning.points}
    />
  ) : null;
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  feature: map.feature,
  zoning: map.zoning,
  currentFieldKml: map.currentFieldKml,
});

const connector = connect(mapStateToProps, {});
export default connector(ZoningPoints);
