import Server from './server';
import axios from 'axios';
import config from '_environment';
import {SamplingPoint} from '../containers/map/types';
import {reportError} from '../containers/error-boundary';
import cancelTokenStore from './cancel-tokens-store';

export default class {
  static updateSamplingPoint(kmlId: number | string, seasonId: number, point: SamplingPoint) {
    const isNew = point.id === 'new';
    return Server[isNew ? 'post' : 'put'](
      `tissue_sampling/${kmlId}/${seasonId}${isNew ? '' : '/' + point.id}`,
      point
    );
  }

  static removeSamplingPoint(kmlId: number | string, seasonId: number, point: number) {
    return Server.delete(`tissue_sampling/${kmlId}/${seasonId}/${point}`);
  }

  static saveBulk(
    points: Array<SamplingPoint>,
    groupId: number,
    fieldID: number,
    isTest: boolean,
    shouldMerge = false
  ) {
    return Server.post(
      `tissue_sampling/${groupId}?kmlId=${fieldID}${isTest ? '&test=1' : ''}${
        shouldMerge ? '&merge=1' : ''
      }`,
      points
    );
  }

  // anomalies
  static getLowPerfAnomalies(groupId: number, fieldId: number) {
    return Server.get(`anomaly/${groupId}/${fieldId}`);
  }

  static generateLowPerfAnomalies(groupId: number, fieldId: number, imageName: string) {
    return Server.get(`anomaly/${groupId}/${fieldId}/${imageName}?generate=1`);
  }

  static saveLowPerfAnomalies(url: string, data: any) {
    return Server.post(`anomaly/${url}`, data);
  }

  static updateAnomalyProp(url: any, data: any) {
    return Server.post(`anomaly/${url}`, data);
  }

  static updateBulkAnomalyProp(url: string, data: any) {
    return Server.post(`anomalies/${url}`, data);
  }

  static uploadAnomaliesToAgX(url: string, data: any) {
    return Server.post(`anomalies/agxV2/${url}`, data);
  }

  static uploadAnomaliesToAgworld(url: string, data: any) {
    return Server.post(`anomalies/agworld/${url}`, data);
  }

  static getHistogram(png: any, bins: any, range: any) {
    return axios.get(`${config.baseUrl}histogram`, {
      params: {png, bins, range, __skipPreloader: true},
    });
  }

  static getPointsSilverIndex(path: string, arrayPoints: Array<any>) {
    return axios.post(`${config.baseUrl}histogram/vals?png=${path}`, arrayPoints);
  }

  static getMeanIndex(path: string, coordinates: any, wholeFarm?: boolean) {
    if (!path) {
      return new Promise(resolve =>
        setTimeout(() => {
          resolve({data: {Mean: 0}});
        }, 0)
      );
    }

    return axios.post(
      `${config.baseUrl}mean?png=${path}${wholeFarm ? `&wholeFarm=${wholeFarm}` : ''}`,
      {
        ...coordinates,
      }
    );
  }

  static getProductTypes() {
    return Server.get(`agxdev/productTypes/`);
  }

  static getProducts(productType: string) {
    return Server.get(`agxdev/productType/${productType}/`);
  }

  static getApplicationMethods() {
    return Server.get(`agxdev/applicationMethods/`);
  }

  static getApplicationTiming() {
    return Server.get(`agxdev/applicationTimings/`);
  }

  static getApplicationRei() {
    return Server.get(`agxdev/reiUnits/`);
  }

  // static getTreeDataByDate(groupId: number, fieldId: number, currentDate: string) {
  //   return Server.get(`app/tree_detection/${groupId}/${fieldId}/${currentDate}Z`);
  // }

  static getTreeDataByField(groupId: number, fieldId: number) {
    return Server.get(`app/tree_detection/${groupId}/${fieldId}`, {
      params: {__skipPreloader: true},
    });
  }

  static getBulkTreeData(groupId: number) {
    return Server.get(`app/tree_detection/${groupId}`, {params: {__skipPreloader: true}});
  }

  static getCropTypesList(lang?: string) {
    return Server.get(`crops`, {params: {lang}});
  }

  static getCloudyV2Data(md5: string) {
    return axios
      .get(`${config.baseUrl}fense/avg/${md5}`)
      .then(({data}) => data)
      .catch(err => {
        reportError(err);
      });
  }

  static getTrendsData(data: any, index: number) {
    cancelTokenStore.cancel(`loadAnalyticsData-${index}`);
    const source = cancelTokenStore.generateSource(`loadAnalyticsData-${index}`);

    return axios.post(`${config.baseUrl}trend?type=GPS`, data, {
      cancelToken: source.token,
      params: {__skipPreloader: true},
    });
  }

  static getArableDevicesData(fieldId: number) {
    cancelTokenStore.cancel('loadFieldArableData');
    const source = cancelTokenStore.generateSource('loadFieldArableData');

    return Server.get(`arable/${fieldId}/daily`, {
      cancelToken: source.token,
      params: {__skipPreloader: true},
    })
      .then(({data}) => data.result || []) // result can be null
      .catch(err => {
        if (!axios.isCancel(err)) {
          reportError(`getArableDevicesData err = ${err}`);
        }
        return [];
      });
  }
}
