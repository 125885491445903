import {t} from 'i18n-utils';

export enum CreateShapeMode {
  SelectAddShapeStrategy = 'selectAddShapeStrategy',
  SelectDrawTool = 'selectDrawTool',
  SelectFiles = 'selectFiles',
  Drawing = 'drawing',
}

enum ActionType {
  SetCreateShapeMode = 'part-field-season/create-shape-mode/set',
  SetGeometry = 'part-field-season/geometry/set',
  ClearGeometries = 'part-field-season/geometries/clear',
  UpdateGeometry = 'part-field-season/geometry/update',
  RemoveGeometry = 'part-field-season/geometry/remove',
}

export type PartOfFieldSeasonStore = {
  createShapeMode: CreateShapeMode;
  geometry: GeoJSON.Feature[];
};

const initialState: PartOfFieldSeasonStore = {
  createShapeMode: CreateShapeMode.SelectAddShapeStrategy,
  geometry: [],
};

type SetGeometryAction = {
  type: ActionType.SetGeometry;
  geometry: GeoJSON.Feature;
};

type SetCreateShapeModeAction = {
  type: ActionType.SetCreateShapeMode;
  mode: CreateShapeMode;
};

type ClearGeometries = {
  type: ActionType.ClearGeometries;
};

type UpdateGeometry = {
  type: ActionType.UpdateGeometry;
  id: string;
  key: string;
  value: string | number | boolean;
};

type RemoveGeometry = {
  type: ActionType.RemoveGeometry;
  id: string;
};

type Action =
  | SetGeometryAction
  | SetCreateShapeModeAction
  | ClearGeometries
  | UpdateGeometry
  | RemoveGeometry;

export const setCreateShapeMode = (mode: CreateShapeMode): SetCreateShapeModeAction => ({
  type: ActionType.SetCreateShapeMode,
  mode,
});

export const setGeometry = (geometry: GeoJSON.Feature): SetGeometryAction => ({
  type: ActionType.SetGeometry,
  geometry,
});

export const clearGeometries = (): ClearGeometries => ({
  type: ActionType.ClearGeometries,
});

export const updateGeometry = (
  id: string,
  key: string,
  value: string | number | boolean
): UpdateGeometry => ({
  type: ActionType.UpdateGeometry,
  id,
  key,
  value,
});

export const removeGeometry = (id: string): RemoveGeometry => ({
  type: ActionType.RemoveGeometry,
  id,
});

export const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SetCreateShapeMode: {
      return {
        ...state,
        createShapeMode: action.mode,
      };
    }

    case ActionType.SetGeometry: {
      return {
        ...state,
        geometry: [
          ...state.geometry,
          {
            ...action.geometry,
            properties: {
              ...action.geometry.properties,
              name: `${t({id: 'Area'})} ${state.geometry.length + 1}`,
            },
          },
        ],
      };
    }

    case ActionType.UpdateGeometry: {
      return {
        ...state,
        geometry: state.geometry.map(g => {
          if (g.properties.id === action.id) {
            return {
              ...g,
              properties: {
                ...g.properties,
                [action.key]: action.value,
              },
            };
          }

          return g;
        }),
      };
    }

    case ActionType.RemoveGeometry: {
      return {
        ...state,
        geometry: state.geometry.filter(g => g.properties.id !== action.id),
      };
    }

    case ActionType.ClearGeometries: {
      return {
        ...state,
        geometry: [],
      };
    }

    default:
      return state;
  }
};

export const clearSeasonNotSavedGeometries = (id?: string) => {
  //@ts-ignore;
  window.leafletElement.eachLayer((l: any) => {
    if (id) {
      if (l.fluroGeometryID === id) {
        //@ts-ignore;
        window.leafletElement.removeLayer(l);
      }
    } else {
      if (l.isSeasonGeometry) {
        //@ts-ignore;
        window.leafletElement.removeLayer(l);
      }
    }
  });
};
