import {t, FormattedMessage} from 'i18n-utils';
import React, {useRef, CSSProperties, useCallback, useState} from 'react';
import {GeoJSON, MapContainer} from 'react-leaflet';
import {Layer} from 'leaflet';
import * as geojson from 'geojson';
import {Map} from 'leaflet';
import {MAIN_SHAPE_COLOR} from '../../_constants';

type Props = {
  data: GeoJSON.GeoJsonObject;
  style?: CSSProperties;
  size?: [number, number];
  onEachFeature?(feature: geojson.Feature, layer: Layer): void;
  isSeasonGeometry?: boolean;
  onMouseEnter?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
};

const GeometryPreview = ({
  isSeasonGeometry = false,
  size,
  data,
  style = {},
  onEachFeature,
  onMouseEnter = null,
  onMouseLeave = null,
}: Props) => {
  const [map, setMap] = useState<Map>();

  const _onEachFeature = useCallback(
    (feature: any, layer: any) => {
      if (isSeasonGeometry) {
        layer.setStyle({
          color: feature?.properties?.fill || MAIN_SHAPE_COLOR,
          fillOpacity: 0.8,
          strokeWidth: '2px',
        });

        layer.on('click', () => {
          const bounds = layer.getBounds();
          try {
            //@ts-ignore
            window.leafletElement.fitBounds(bounds);
          } catch (e) {
            console.log(e.message);
          }
        });
      }

      onEachFeature?.(feature, layer);
    },
    [isSeasonGeometry]
  );

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <MapContainer
        zoomControl={false}
        scrollWheelZoom={false}
        dragging={false}
        touchZoom={false}
        doubleClickZoom={false}
        boxZoom={false}
        keyboard={false}
        tap={false}
        attributionControl={false}
        whenCreated={map => setMap(map)}
        zoomSnap={0.5}
        style={{zIndex: 1, width: size?.[0] || 100, height: size?.[1] || 100, ...style}}
      >
        <GeoJSON
          data={data}
          onEachFeature={_onEachFeature}
          ref={(n: any) => {
            if (n && map) {
              map.fitBounds(n.getBounds());
            }
          }}
        />
      </MapContainer>
    </div>
  );
};

export default GeometryPreview;
