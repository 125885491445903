import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, memo, useMemo} from 'react';
import {CropSelectMd, FluroDatePicker, GeometryPreview} from 'components';
import {
  updateGeometry,
  clearSeasonNotSavedGeometries,
  removeGeometry,
} from './planting-area-season.module';
import {FontIcon, SelectField, TextField} from 'react-md';
import {GeometryItem} from './field.styled';
import {useDispatch} from 'react-redux';
import {cropId2Subtypes} from '_utils';
import moment, {Moment} from 'moment';
import {TrashIcon} from 'containers/map/icons';

type Props = {
  data: GeoJSON.Feature;
};

const PlantingAreaSeasonItem = memo(({data}: Props) => {
  const dispatch = useDispatch();

  const id = useMemo(() => data.properties.id, [data.properties.id]);

  const subTypesList = useMemo(() => cropId2Subtypes(data.properties.cropType), [
    data.properties.cropType,
  ]);

  const clearGeometry = useCallback((id: string) => {
    clearSeasonNotSavedGeometries(id);
    dispatch(removeGeometry(id));
  }, []);

  return (
    <GeometryItem>
      <div className="first-block">
        <input
          placeholder={t({id: 'name (opt.)'})}
          className="md-text md-text-field"
          id={`geom-name-${id}`}
          value={data.properties.name || ''}
          maxLength={50}
          onChange={e => {
            const value = e.target.value;
            dispatch(updateGeometry(id, 'name', value));
          }}
        />
        {data.properties.name.length > 50 && (
          <div className="name-error md-text-field-message-container md-text--error">
            {t({id: 'Max length'}, {length: 50})}
          </div>
        )}
      </div>

      <div className="second-block">
        <div className="preview">
          <GeometryPreview
            isSeasonGeometry
            size={[50, 50]}
            data={data}
            onMouseEnter={() => {
              dispatch(updateGeometry(id, 'fillOpacity', 1));
            }}
            onMouseLeave={() => {
              dispatch(updateGeometry(id, 'fillOpacity', 0.5));
            }}
          />
        </div>

        <div className="season-settings">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <CropSelectMd
              cropType={data.properties.cropType}
              onChange={(value: string) => {
                dispatch(updateGeometry(id, 'cropType', value));
                // clear variety when switch crop
                dispatch(updateGeometry(id, 'cropSubType', ''));
              }}
              className="crop-select"
            />

            {subTypesList.length ? (
              <SelectField
                id={`crop-sub-type-${id}`}
                label={t({id: 'Variety'})}
                placeholder={t({id: 'Variety'})}
                menuItems={subTypesList}
                simplifiedMenu={false}
                onChange={(value: string) => {
                  dispatch(updateGeometry(id, 'cropSubType', value));
                  dispatch(updateGeometry(id, 'isCustomCropSubType', true));
                }}
                value={data.properties.cropSubType}
                className="sub-type"
              />
            ) : (
              <TextField
                id={`crop-sub-type-${id}`}
                onChange={(value: string) => {
                  dispatch(updateGeometry(id, 'cropSubType', value));
                  dispatch(updateGeometry(id, 'isCustomCropSubType', false));
                }}
                value={data.properties.cropSubType}
                label={t({id: 'Variety'})}
                lineDirection="center"
                placeholder={t({id: 'Variety'})}
                className="sub-type"
              />
            )}
          </div>

          <div style={{display: 'flex', alignItems: 'center'}}>
            <FluroDatePicker
              hideFormat
              id={`part-field-season-start-date-${data.properties.id}`}
              name="part-field-season-start-date"
              label={t({id: 'Sowing/Planting Date'})}
              selected={moment(data.properties.startDate || undefined)}
              onChange={(value: Moment) => {
                dispatch(
                  updateGeometry(data.properties.id, 'startDate', value.format('YYYY-MM-DD'))
                );
              }}
              autoComplete="off"
              className="date-select"
              portal
            />

            <FluroDatePicker
              hideFormat
              id={`part-field-season-end-date-${data.properties.id}`}
              name="part-field-season-end-date"
              label={t({id: 'Est. harvest Date'})}
              selected={
                data.properties.endDate ? moment(data.properties.endDate) : moment().add(6, 'month')
              }
              onChange={(value: Moment) => {
                dispatch(updateGeometry(data.properties.id, 'endDate', value.format('YYYY-MM-DD')));
              }}
              autoComplete="off"
              className="date-select date-select-end-date"
              portal
            />
          </div>
        </div>

        <div
          onClick={() => clearGeometry(data.properties.id)}
          className="delete-btn-wrapper"
          title={t({id: 'Delete this geometry'})}
        >
          <TrashIcon />
        </div>
      </div>
    </GeometryItem>
  );
});

export default PlantingAreaSeasonItem;
