import React, {useEffect, useState} from 'react';
import {FluroDialog} from 'components';
import {getGetURLParam, setGetParamToURL} from '_utils';
import {DialogContentWrapper} from 'containers/admin/logs/email/email-logs.styled';

const OrderPlanetImagery = ({openByProps = false}) => {
  const [visible, toggleVisibility] = useState(false);

  const visibilityHandler = () => {
    if (visible) {
      createForm();
    }
  };

  useEffect(() => {
    if (getGetURLParam('order-imagery') === 'true') {
      setGetParamToURL('order-imagery', null);
      toggleVisibility(true);
    }
  }, []);

  useEffect(visibilityHandler, [visible]);

  useEffect(
    function visibilityFromPropsHandler() {
      if (openByProps) {
        toggleVisibility(true);
      }
    },
    [openByProps]
  );

  const createForm = () => {
    // @ts-ignore
    if (window.hbspt && !document.getElementById('form-container')?.childElementCount) {
      // @ts-ignore
      window.hbspt.forms.create({
        portalId: '4430549',
        formId: '95d7d6e4-3a3e-4c73-beb3-e66e5dfcda15',
        target: '#form-container',
      });
    }
  };

  useEffect(function createHubspotForm() {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/shell.js';
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      visibilityHandler();
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <FluroDialog
      id={'order-high-resolution-imagery'}
      visible={visible}
      onHide={() => toggleVisibility(false)}
      dialogClassName="fix-dialog-zoom-issue"
    >
      <DialogContentWrapper>
        <div id={'form-container'} />
      </DialogContentWrapper>
    </FluroDialog>
  );

  // return (
  //   <div className={'user-settings-inner-container high-resolution-imagery'}>
  //     <div className={'user-settings-container__header-container'}>
  //       <div className={'concatenate'}>
  //         <div className={'images-label'}>
  //           <SatelliteHRIcon />
  //           <div>High-resolution satellite imagery</div>
  //         </div>
  //
  //         <Button raised primary onClick={() => toggleVisibility(true)}>
  //           Order imagery
  //         </Button>
  //
  //
  //       </div>
  //     </div>
  //
  //     <FluroDialog
  //       id={'order-high-resolution-imagery'}
  //       visible={visible}
  //       onHide={() => toggleVisibility(false)}
  //       dialogClassName="fix-dialog-zoom-issue"
  //     >
  //       <DialogContentWrapper>
  //         <div id={'form-container'} />
  //       </DialogContentWrapper>
  //     </FluroDialog>
  //   </div>
  // );
};

export default OrderPlanetImagery;
