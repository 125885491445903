import moment from 'moment';
import reduceReducers from 'reduce-reducers';

import {deepCopy, getGetURLParam, setGetParamToURL, isAgxField, toFixedFloat} from '_utils';

import {
  preselectSensor,
  preselectSensors,
  getClosestDate,
  filterDates,
  filterDatesForRemoteSensing,
  preselectRange,
} from 'containers/map/utils';

import {calculateLayerType} from 'containers/map/utils/trees';

import {SENSORS, WHOLE_TABLE_VIEW_FEATURES, RAW_DATA} from '_constants';

import {IAction, IInitialMapState, TInfoExt} from '../types';

import {ActionTypes} from './types';
import initialState from './initial-state';

// Reducer functions
import seasonReducer from './season';
import fieldReducer from './field';
import treeReducer from './tree';
import zoningReducer from './zoning';
import nrxReducer from './nrx';
import analyticsReducer from './analytics';
import anomaliesReducer from './anomalies';
import tspReducer from './sampling-points';
import areasOfInterestReducer from './areas-of-interest';

const mapReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.MAP_TOGGLE_BAR:
      return {
        ...state,
        isMapBarOpen: action.value,
        wholeTableViewOpen: !action.value ? action.value : state.wholeTableViewOpen,
      };

    case ActionTypes.MAP_HARD_CLEAR_STATE:
      if (action.setInitialState) {
        return deepCopy(initialState);
      }

      return {
        ...deepCopy(initialState),
        fieldGeometries: state.fieldGeometries,
        fieldsByFarmId: state.fieldsByFarmId,
        layersSource: state.layersSource,
        currentSensor: state.currentSensor,
        feature: state.feature,
        wholeTableViewOpen: state.wholeTableViewOpen,
        isMapBarOpen: state.isMapBarOpen,
        group: state.group,
        sortFields:
          state.wholeTableViewOpen && state.feature === 'farm'
            ? state.sortFields
            : initialState.sortFields,
      };

    case ActionTypes.GENERATE_NITROGEN_MAP:
      return {
        ...state,
        field: {
          ...state.field,
          generatedNMapDates: action.date,
        },
      };

    case ActionTypes.MAP_SET_COMPARE_DATE:
      return {
        ...state,
        currentCompareDate: action.date,
        currentSensorsCompare: preselectSensors(
          action.date,
          state.currentDates,
          state.treeDetection.layerType
        ),
        currentSensorCompare: preselectSensor(
          action.date,
          state.currentDates,
          state.currentSensorCompare
        ),
      };

    case ActionTypes.MAP_SET_SENSOR_COMPARE: {
      const currentDatesNumber = Object.keys(state.currentDates).length;

      return {
        ...state,
        currentSensorCompare:
          currentDatesNumber === 1
            ? 'TCI'
            : preselectSensor(state.currentCompareDate, state.currentDates, action.sensor),
      };
    }

    case ActionTypes.MAP_TOGGLE_COMPARE:
      const currentDatesNumber = Object.keys(state.currentDates).length;

      return {
        ...state,
        isCompareOn: action.value,
        currentSensorCompare:
          currentDatesNumber === 1
            ? 'TCI'
            : preselectSensor(state.currentCompareDate, state.currentDates, 'NDVI'),
      };

    case ActionTypes.MAP_TOGGLE_GEOMETRIES_ON_MAP:
      return {
        ...state,
        geometriesOnMap: action.value,
      };

    case ActionTypes.MAP_TOGGLE_FIELD_KML:
      return {
        ...state,
        visibleFieldKml: action.value,
      };

    case ActionTypes.MAP_SET_FEATURE:
      let wholeTableViewOpenState = state.wholeTableViewOpen;
      return {
        ...state,
        feature: action.feature,
        isMapBarOpen: true,
        wholeTableViewOpen:
          wholeTableViewOpenState && WHOLE_TABLE_VIEW_FEATURES.includes(action.feature)
            ? action.feature
            : undefined,
        geometriesOnMap:
          (action.feature === 'tsp' && !!Object.keys(state.pointsGroups).length) ||
          state.geometriesOnMap,
      };

    case ActionTypes.MAP_SET_TEMPERATURE_DATA: {
      let weatherObj: any = {};

      (action.data || []).forEach((w: any) => {
        weatherObj[moment(w.date).format('YYYY-MM-DD')] = {...w};
      });
      return {
        ...state,
        temperatureData: action.data,
        preparedTemperatureData: weatherObj,
      };
    }

    case ActionTypes.MAP_SET_HISTOGRAM_DATA: {
      return {
        ...state,
        histogram: {
          ...state.histogram,
          categories: [...action.data.categories.map((cat: string) => toFixedFloat(cat, 2))],
          series: [...action.data.series[0].data],
          statistics: [...action.data.statistics],
          bins: action.bins,
          range: action.range,
        },
      };
    }

    case ActionTypes.MAP_SET_HISTOGRAM_OPTIONS:
      return {
        ...state,
        histogram: {
          ...state.histogram,
          [action.t]: parseInt(action.value, 10),
        },
      };

    case ActionTypes.MAP_SET_COLOR_SCHEMA:
      return {
        ...state,
        colorSchema: action.value,
      };

    case ActionTypes.MAP_SET_IMAGE_OVERLAY_OPACITY:
      return {
        ...state,
        imageLayerOpacity: action.value,
      };

    case ActionTypes.MAP_SET_NITROGEN_SCALE_URL:
      return {
        ...state,
        nitrogen: {
          ...state.nitrogen,
          scaleUrl: action.url,
        },
      };

    case ActionTypes.MAP_SET_NITROGEN_MARKER:
      var markers = [];

      if (!state.currentDate) {
        return state;
      }

      if (state.nitrogen.markers[state.currentDate]) {
        state.nitrogen.markers[state.currentDate].push({
          position: action.position,
          value: action.value,
        });
        markers = [...state.nitrogen.markers[state.currentDate]];
      } else {
        markers = [{position: action.position, value: action.value}];
      }

      return {
        ...state,
        nitrogen: {
          ...state.nitrogen,
          markers: {
            ...state.nitrogen.markers,
            [state.currentDate]: [...markers],
          },
        },
      };

    case ActionTypes.MAP_REMOVE_NITROGEN_MARKER:
      return {
        ...state,
        nitrogen: {
          ...state.nitrogen,
          markers: {
            ...state.nitrogen.markers,
            [state.currentDate]: [
              ...state.nitrogen.markers[state.currentDate].slice(0, action.index),
              ...state.nitrogen.markers[state.currentDate].slice(action.index + 1),
            ],
          },
        },
      };

    case ActionTypes.MAP_SET_FIELD_KML:
      return {
        ...state,
        currentFieldKml: action.data,
      };

    case ActionTypes.MAP_UPDATE_FIELD_MEAN:
      return {
        ...state,
        currentFieldMean: action.mean,
      };

    case ActionTypes.MAP_REMOTE_SENSING_TOGGLE_DIALOG:
      return {
        ...state,
        isRemoteSensingDialog: action.value,
      };

    case ActionTypes.MAP_REMOTE_SENSING_SET_CURRENT:
      const currentIsSetSpectra = SENSORS.filter(
        spectraKey =>
          !RAW_DATA.includes(spectraKey) && action.image[spectraKey] && action.image[spectraKey].url
      );

      let remoteSensingLayersSelected: any = {};

      currentIsSetSpectra.forEach(layer => {
        remoteSensingLayersSelected[layer] = {
          checked: true,
        };
      });

      return {
        ...state,
        currentRemoteSensingImage: {...action.image},
        currentIsSetSpectra,
        remoteSensingLayersSelected,
      };

    case ActionTypes.MAP_REMOTE_SENSING_ON_CHANGE_LAYER:
      if (action.layer === 'all') {
        Object.keys(state.remoteSensingLayersSelected).forEach(key => {
          state.remoteSensingLayersSelected[key].checked = action.value;
        });

        return {
          ...state,
          remoteSensingLayersOptions: {
            ...state.remoteSensingLayersOptions,
            all: action.value,
          },

          remoteSensingLayersSelected: {
            ...state.remoteSensingLayersSelected,
          },
        };
      }

      var rsEsSelectedState = {
        ...state.remoteSensingLayersSelected,
        [action.layer]: {
          checked: action.value,
        },
      };

      var remoteSensingLayersSelectedKeys = Object.keys(rsEsSelectedState || {});

      const selectedRSes = remoteSensingLayersSelectedKeys.filter(
        key => rsEsSelectedState[key].checked
      );

      return {
        ...state,
        remoteSensingLayersOptions: {
          ...state.remoteSensingLayersOptions,
          all: selectedRSes.length === remoteSensingLayersSelectedKeys.length,
        },

        remoteSensingLayersSelected: rsEsSelectedState,
      };

    case ActionTypes.MAP_REMOTE_SENSING_SET_CLOUD_COVER: {
      let currentDate = state.currentDate;
      const currentDates: any = filterDates(
        state.wholeFarm.isWholeFarmView ? state.wholeFarm.wholeFarmDates : state.infoExt,
        state.pointsGroups,
        state.field.generatedNMapDates,
        action.value,
        state.remoteSensingFilterProps
      );
      if (!currentDates[currentDate]) {
        currentDate = getClosestDate(state.currentDate, currentDates);
      }

      return {
        ...state,
        remoteSensingCloudCover: action.value,
        currentDate,
        currentDates,
        currentSensor: preselectSensor(
          currentDate,
          currentDates,
          state.currentSensor === 'NONE' &&
            !Object.keys(state.currentDates).length && // didn't have dates, but now will have
            Object.keys(currentDates).length
            ? 'NDVI'
            : state.currentSensor
        ),
        currentSensors: preselectSensors(currentDate, currentDates, state.treeDetection.layerType),
        remoteSensingImages: filterDatesForRemoteSensing(
          state.wholeFarm.isWholeFarmView ? state.wholeFarm.wholeFarmDates : state.infoExt,
          action.value,
          state.remoteSensingFilterProps
        ),
      };
    }

    case ActionTypes.MAP_REMOTE_SENSING_TOGGLE_HIDDEN: {
      if (state.wholeFarm.isWholeFarmView) {
        const updatedDates: any = {};
        const filteredDates: any = {};
        let isCurrentDateHidden: boolean | string = false;

        Object.keys(state.wholeFarm.wholeFarmDates).forEach(dataKey => {
          if (state.wholeFarm.wholeFarmDates[dataKey].Date === action.date) {
            state.wholeFarm.wholeFarmDates[dataKey].Hidden = action.value;
            isCurrentDateHidden = !isCurrentDateHidden
              ? state.currentDate === dataKey
                ? dataKey
                : null
              : isCurrentDateHidden;
          }
          updatedDates[dataKey] = state.wholeFarm.wholeFarmDates[dataKey];
        });

        if (isCurrentDateHidden) {
          Object.keys(updatedDates).forEach(dataKey => {
            const currentDay = updatedDates[dataKey];
            if (
              isCurrentDateHidden !== dataKey &&
              !currentDay.Hidden &&
              currentDay.Cloud <= state.remoteSensingCloudCover
            ) {
              filteredDates[dataKey] = currentDay;
            }
          });
        }

        const newCurrentDate = isCurrentDateHidden
          ? getClosestDate(state.currentDate, filteredDates)
          : state.currentDate;

        return {
          ...state,
          wholeFarm: {
            ...state.wholeFarm,
            wholeFarmDates: updatedDates,
          },
          currentDate: newCurrentDate,
          remoteSensingImages: filterDatesForRemoteSensing(
            {...updatedDates},
            state.remoteSensingCloudCover,
            state.remoteSensingFilterProps
          ),
        };
      }

      const index = state.infoExt.findIndex(im => im.Date === action.date);
      state.infoExt[index].Hidden = action.value;
      const currentDates = filterDates(
        state.infoExt,
        state.pointsGroups,
        state.field.generatedNMapDates,
        state.remoteSensingCloudCover,
        state.remoteSensingFilterProps
      );

      const currentDate = getClosestDate(
        state.currentDate,
        filterDates(
          state.infoExt,
          state.pointsGroups,
          state.field.generatedNMapDates,
          state.remoteSensingCloudCover,
          state.remoteSensingFilterProps
        )
      );

      return {
        ...state,
        infoExt: [...state.infoExt],
        currentDates,
        currentDate,
        currentSensors: preselectSensors(currentDate, currentDates, 'default', currentDates),
        remoteSensingImages: filterDatesForRemoteSensing(
          state.infoExt,
          state.remoteSensingCloudCover,
          state.remoteSensingFilterProps
        ),
      };
    }

    case ActionTypes.MAP_TOGGLE_POPUP:
      return {
        ...state,
        openPopupId: state.openPopupId === action.id ? undefined : action.id,
      };

    case ActionTypes.MAP_REMOTE_SENSING_TOGGLE_OPTION:
      return {
        ...state,
        remoteSensingLayersOptions: {
          ...state.remoteSensingLayersOptions,
          [action.prop]: action.value,
        },
      };

    case ActionTypes.MAP_REMOTE_SENSING_SET_FILTER: {
      const currentFilter = state.remoteSensingFilterProps;
      const currentDatesList = state.wholeFarm.isWholeFarmView // create a single list for whole farm or a single field
        ? (Object.values(state.wholeFarm.wholeFarmDates) as TInfoExt[])
        : state.infoExt;
      const calcFilterProps = action.toAdd
        ? currentFilter.indexOf(action.filter) === -1
          ? [...currentFilter, action.filter]
          : currentFilter
        : currentFilter.filter(p => p !== action.filter);

      setGetParamToURL(
        'filterSources',
        calcFilterProps.length
          ? calcFilterProps.map(el => el.replace(/^dron$/, 'drone')).join(',')
          : null
      );

      const currentDates = filterDates(
        currentDatesList,
        state.pointsGroups,
        state.field.generatedNMapDates,
        state.remoteSensingCloudCover,
        calcFilterProps
      );
      const currentDate = getClosestDate(
        getGetURLParam('layerDate') || state.currentDate,
        currentDates
      );

      const currentSensor = preselectSensor(
        currentDate,
        currentDates,
        state.currentSensor === 'NONE' &&
          !Object.keys(state.currentDates).length &&
          Object.keys(currentDates).length
          ? 'NDVI'
          : state.currentSensor
      );

      const layerType = calculateLayerType(
        currentDate,
        state.treeDetection.layerType,
        currentSensor,
        currentDates
      );

      const range = preselectRange(currentSensor);

      return {
        ...state,
        remoteSensingFilterProps: calcFilterProps,
        remoteSensingImages: filterDatesForRemoteSensing(
          state.wholeFarm.isWholeFarmView ? state.wholeFarm.wholeFarmDates : state.infoExt,
          state.remoteSensingCloudCover,
          calcFilterProps
        ),
        currentDates: currentDates,
        currentDate: currentDate,
        currentSensors: preselectSensors(currentDate, currentDates, state.treeDetection.layerType),
        currentSensor,
        treeDetection: {
          ...state.treeDetection,
          layerType: layerType,
        },

        histogram: {
          ...state.histogram,
          range,
        },
      };
    }

    case ActionTypes.MAP_SET_NITROGEN_OVERLAY_URL:
      return {
        ...state,
        nitrogenOverlayURL: action.url,
      };

    case ActionTypes.MAP_TOGGLE_WHOLE_TABLE_VIEW:
      return {
        ...state,
        wholeTableViewOpen: action.value,
      };

    case ActionTypes.MAP_TOGGLE_N_RECOMMENDATION:
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          toggled: action.value,
          method: action.nRecommendationMethod,
        },
      };

    case ActionTypes.MAP_UPDATE_N_DATA:
      return {
        ...state,
        nRecommendation: {
          ...state.nRecommendation,
          zonesWithNData: action.data,
        },
      };

    case ActionTypes.MAP_TOGGLE_LAYER_SOURCE:
      return {
        ...state,
        layersSource: action.value,
      };
    case ActionTypes.MAP_TOGGLE_SOIL_MAP_LAYER:
      return {
        ...state,
        soilMapLayer: action.value,
      };

    case ActionTypes.MAP_SET_APP_PROCESSING_STATUS:
      return {
        ...state,
        appProcessingStatus: action.value,
      };

    case ActionTypes.CLEAR_ORIGINAL_LAYER_DATE_FROM_URL: {
      return {
        ...state,
        originalLayerDateFormURL: '',
      };
    }

    default:
      return state;
  }
};

export default reduceReducers(
  mapReducer,
  fieldReducer,
  seasonReducer,
  treeReducer,
  zoningReducer,
  nrxReducer,
  analyticsReducer,
  anomaliesReducer,
  tspReducer,
  areasOfInterestReducer
);
