import Chart from 'chart.js';
import moment from 'moment';

const helpers = Chart.helpers;

const _color = Chart.helpers.color;

const TimelineConfig = {
  position: 'bottom',

  tooltips: {
    mode: 'nearest',
  },
  adapters: {},
  time: {
    parser: false, // false == a pattern string from http://momentjs.com/docs/#/parsing/string-format/ or a custom callback that converts its argument to a moment
    format: false, // DEPRECATED false == date objects, moment object, callback or a pattern string from http://momentjs.com/docs/#/parsing/string-format/
    unit: false, // false == automatic or override with week, month, year, etc.
    round: false, // none, or override with week, month, year, etc.
    displayFormat: false, // DEPRECATED
    isoWeekday: false, // override week start day - see http://momentjs.com/docs/#/get-set/iso-weekday/
    minUnit: 'day',
    distribution: 'linear',
    bounds: 'data',

    // defaults to unit's corresponding unitFormat below or override using pattern string from http://momentjs.com/docs/#/displaying/format/
    displayFormats: {
      millisecond: 'h:mm:ss.SSS a', // 11:20:01.123 AM,
      second: 'h:mm:ss a', // 11:20:01 AM
      minute: 'h:mm a', // 11:20 AM
      hour: 'hA', // 5PM
      day: 'YYYY-MM-DD', // Sep 4
      week: 'll', // Week 46, or maybe "[W]WW - YYYY" ?
      month: 'MMM YYYY', // Sept 2015
      quarter: '[Q]Q - YYYY', // Q3
      year: 'YYYY', // 2015
    },
  },
  ticks: {
    autoSkip: true,
  },
};

/**
 * Convert the given value to a moment object using the given time options.
 * @see http://momentjs.com/docs/#/parsing/
 */
function momentify(value, options) {
  const parser = options.parser;
  const format = options.parser || options.format;

  if (typeof parser === 'function') {
    return parser(value);
  }

  if (typeof value === 'string' && typeof format === 'string') {
    return moment(value, format);
  }

  if (!(value instanceof moment)) {
    value = moment(value);
  }

  if (value.isValid()) {
    return value;
  }

  // Labels are in an incompatible moment format and no `parser` has been provided.
  // The user might still use the deprecated `format` option to convert his inputs.
  if (typeof format === 'function') {
    return format(value);
  }

  return value;
}

function parse(input, scale) {
  if (helpers.isNullOrUndef(input)) {
    return null;
  }

  const options = scale.options.time;
  const value = momentify(scale.getRightValue(input), options);
  if (!value.isValid()) {
    return null;
  }

  if (options.round) {
    value.startOf(options.round);
  }

  return value.valueOf();
}

function arrayUnique(items) {
  const hash = {};
  const out = [];
  let i, ilen, item;

  for (i = 0, ilen = items.length; i < ilen; ++i) {
    item = items[i];
    if (!hash[item]) {
      hash[item] = true;
      out.push(item);
    }
  }

  return out;
}

const MIN_INTEGER = Number.MIN_SAFE_INTEGER || -9007199254740991;
const MAX_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991;

const TimelineScale = Chart.scaleService.getScaleConstructor('time').extend({
  determineDataLimits: function () {
    const me = this;
    const chart = me.chart;
    const timeOpts = me.options.time;
    const elemOpts = me.chart.options.elements;
    let min = MAX_INTEGER;
    let max = MIN_INTEGER;
    const timestamps = [];
    const timestampobj = {};
    const datasets = [];
    let i, j, ilen, jlen, data, timestamp0, timestamp1;

    // Convert data to timestamps
    for (i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
      if (chart.isDatasetVisible(i)) {
        data = chart.data.datasets[i].data;
        datasets[i] = [];

        for (j = 0, jlen = data.length; j < jlen; ++j) {
          timestamp0 = parse(data[j][elemOpts.keyStart], me);
          timestamp1 = parse(data[j][elemOpts.keyEnd], me);
          if (timestamp0 > timestamp1) {
            [timestamp0, timestamp1] = [timestamp1, timestamp0];
          }
          if (min > timestamp0 && timestamp0) {
            min = timestamp0;
          }
          if (max < timestamp1 && timestamp1) {
            max = timestamp1;
          }
          datasets[i][j] = [timestamp0, timestamp1, data[j][elemOpts.keyValue]];
          if (Object.prototype.hasOwnProperty.call(timestampobj, timestamp0)) {
            timestampobj[timestamp0] = true;
            timestamps.push(timestamp0);
          }
          if (Object.prototype.hasOwnProperty.call(timestampobj, timestamp1)) {
            timestampobj[timestamp1] = true;
            timestamps.push(timestamp1);
          }
        }
      } else {
        datasets[i] = [];
      }
    }

    if (timestamps.size) {
      timestamps.sort(function (a, b) {
        return a - b;
      });
    }

    min = parse(timeOpts.min, me) || min;
    max = parse(timeOpts.max, me) || max;

    // In case there is no valid min/max, const's use today limits
    min = min === MAX_INTEGER ? +moment().startOf('day') : min;
    max = max === MIN_INTEGER ? +moment().endOf('day') + 1 : max;

    // Make sure that max is strictly higher than min (required by the lookup table)
    me.min = Math.min(min, max);
    me.max = Math.max(min + 1, max);

    // PRIVATE
    me._horizontal = me.isHorizontal();
    me._table = [];
    me._timestamps = {
      data: timestamps,
      datasets: datasets,
      labels: [],
    };
  },
});

Chart.scaleService.registerScaleType('timeline', TimelineScale, TimelineConfig);

Chart.controllers.timeline = Chart.controllers.bar.extend({
  getBarBounds: function (bar) {
    const vm = bar._view;
    let x1, x2, y1, y2;

    x1 = vm.x;
    x2 = vm.x + vm.width;
    y1 = vm.y;
    y2 = vm.y + vm.height;

    return {
      left: x1,
      top: y1,
      right: x2,
      bottom: y2,
    };
  },

  update: function (reset) {
    const me = this;
    const meta = me.getMeta();
    const chartOpts = me.chart.options;
    if (
      chartOpts.textPadding ||
      chartOpts.minBarWidth ||
      chartOpts.showText ||
      chartOpts.colorFunction
    ) {
      const elemOpts = me.chart.options.elements;
      elemOpts.textPadding = chartOpts.textPadding || elemOpts.textPadding;
      elemOpts.minBarWidth = chartOpts.minBarWidth || elemOpts.minBarWidth;
      elemOpts.colorFunction = chartOpts.colorFunction || elemOpts.colorFunction;
      elemOpts.minBarWidth = chartOpts.minBarWidth || elemOpts.minBarWidth;
      if (Chart._tl_depwarn !== true) {
        console.log('Timeline Chart: Configuration deprecated. Please check document on Github.');
        Chart._tl_depwarn = true;
      }
    }

    helpers.each(
      meta.data,
      function (rectangle, index) {
        me.updateElement(rectangle, index, reset);
      },
      me
    );
  },

  updateElement: function (rectangle, index, reset) {
    const me = this;
    const meta = me.getMeta();
    const xScale = me.getScaleForId(meta.xAxisID);
    const yScale = me.getScaleForId(meta.yAxisID);
    const dataset = me.getDataset();
    const data = dataset.data[index];
    const custom = rectangle.custom || {};
    const datasetIndex = me.index;
    const opts = me.chart.options;
    const elemOpts = opts.elements || Chart.defaults.timeline.elements;
    const rectangleElementOptions = elemOpts.rectangle;
    const textPad = elemOpts.textPadding;
    const minBarWidth = elemOpts.minBarWidth;

    rectangle._xScale = xScale;
    rectangle._yScale = yScale;
    rectangle._datasetIndex = me.index;
    rectangle._index = index;

    const text = data[elemOpts.keyValue];
    const mergedCount = data[elemOpts.countValue];

    const ruler = me.getRuler(index);

    const x = xScale.getPixelForValue(data[elemOpts.keyStart]);
    const end = xScale.getPixelForValue(data[elemOpts.keyEnd]);

    const y = yScale.getPixelForValue(data, datasetIndex, datasetIndex);

    const width = end - x;
    let height = me.calculateBarHeight(ruler);

    const color = _color(elemOpts.colorFunction(text, data, dataset, index));
    const showText = elemOpts.showText;

    let font = elemOpts.font;

    if (!font) {
      font = 'normal 300 11px arial';
    }

    // This one has in account the size of the tick and the height of the bar, so we just
    // divide both of them by two and subtract the height part and add the tick part
    // to the real position of the element y. The purpose here is to place the bar
    // in the middle of the tick.
    let boxY = y - height / 2;

    let backgroundColor = color.rgbaString();

    rectangle._model = {
      x: reset ? x - width : x, // Top left of rectangle
      y: boxY, // Top left of rectangle
      width: Math.max(width, minBarWidth),
      height: height,
      base: x + width,
      backgroundColor,
      borderSkipped: custom.borderSkipped
        ? custom.borderSkipped
        : rectangleElementOptions.borderSkipped,
      borderColor: custom.borderColor
        ? custom.borderColor
        : helpers.getValueAtIndexOrDefault(
            dataset.borderColor,
            index,
            rectangleElementOptions.borderColor
          ),
      borderWidth: custom.borderWidth
        ? custom.borderWidth
        : helpers.getValueAtIndexOrDefault(
            dataset.borderWidth,
            index,
            rectangleElementOptions.borderWidth
          ),
      // Tooltip
      label: me.chart.data.labels[index],
      datasetLabel: dataset.label,
      text: text,
      mergedCount: mergedCount,
      textColor: '#000000', //color.luminosity() > 0.5 ? '#000000' : '#ffffff',
    };

    rectangle.draw = function () {
      const ctx = this._chart.ctx;
      const vm = this._view;
      const oldAlpha = ctx.globalAlpha;
      const oldOperation = ctx.globalCompositeOperation;

      // Draw new rectangle with Alpha-Mix.
      ctx.fillStyle = vm.backgroundColor;
      ctx.lineWidth = vm.borderWidth;
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillRect(vm.x, vm.y, vm.width, vm.height);

      ctx.globalAlpha = 0.5;
      ctx.globalCompositeOperation = 'source-over';
      ctx.fillRect(vm.x, vm.y, vm.width, vm.height);

      ctx.globalAlpha = oldAlpha;
      ctx.globalCompositeOperation = oldOperation;
      if (showText && vm.mergedCount > 1) {
        ctx.beginPath();
        const textRect = ctx.measureText(vm.mergedCount);
        if (textRect.width > 0 && textRect.width + textPad + 2 < vm.width) {
          ctx.font = font;
          ctx.fillStyle = vm.textColor;
          ctx.lineWidth = 0;
          //ctx.strokeStyle = vm.textColor;
          ctx.textBaseline = 'middle';
          ctx.fillText(vm.mergedCount, vm.x + vm.width + 3, vm.y + vm.height / 2);
        }
        ctx.fill();
      }
    };

    rectangle.inXRange = function (mouseX) {
      const bounds = me.getBarBounds(this);
      return mouseX >= bounds.left && mouseX <= bounds.right;
    };
    rectangle.tooltipPosition = function () {
      const vm = this.getCenterPoint();
      return {
        x: vm.x,
        y: vm.y,
      };
    };

    rectangle.getCenterPoint = function () {
      const vm = this._view;
      let x, y;
      x = vm.x + vm.width / 2;
      y = vm.y + vm.height / 2;

      return {
        x: x,
        y: y,
      };
    };

    rectangle.inRange = function (mouseX, mouseY) {
      let inRange = false;

      if (this._view) {
        const bounds = me.getBarBounds(this);
        inRange =
          mouseX >= bounds.left &&
          mouseX <= bounds.right &&
          mouseY >= bounds.top &&
          mouseY <= bounds.bottom;
      }
      return inRange;
    };

    rectangle.pivot();
  },

  getBarCount: function () {
    const me = this;
    let barCount = 0;
    helpers.each(
      me.chart.data.datasets,
      function (dataset, datasetIndex) {
        const meta = me.chart.getDatasetMeta(datasetIndex);
        if (meta.bar && me.chart.isDatasetVisible(datasetIndex)) {
          ++barCount;
        }
      },
      me
    );
    return barCount;
  },

  // draw
  draw: function (ease) {
    const easingDecimal = ease || 1;
    let i, len;
    const metaData = this.getMeta().data;
    for (i = 0, len = metaData.length; i < len; i++) {
      metaData[i].transition(easingDecimal).draw();
    }
  },

  // From controller.bar
  calculateBarHeight: function (ruler) {
    const me = this;
    const yScale = me.getScaleForId(me.getMeta().yAxisID);
    if (yScale.options.barThickness) {
      return yScale.options.barThickness;
    }
    return yScale.options.stacked ? ruler.categoryHeight : ruler.barHeight;
  },

  removeHoverStyle: function (e) {
    // TODO
  },

  setHoverStyle: function (e) {
    // TODO: Implement this
  },
});

Chart.defaults.timeline = {
  elements: {
    colorFunction: function () {
      return _color('black');
    },
    showText: true,
    textPadding: 4,
    minBarWidth: 1,
    keyStart: 0,
    keyEnd: 1,
    keyValue: 2,
    countValue: 4,
  },

  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },

  legend: {
    display: false,
  },

  scales: {
    xAxes: [
      {
        type: 'timeline',
        position: 'bottom',
        distribution: 'linear',
        categoryPercentage: 0.8,
        barPercentage: 0.9,

        gridLines: {
          display: true,
          offsetGridLines: true,
          drawBorder: true,
          drawTicks: true,
        },
        ticks: {
          maxRotation: 0,
        },
        unit: 'year',
      },
    ],
    yAxes: [
      {
        type: 'category',
        position: 'left',
        barThickness: 20,
        categoryPercentage: 0.8,
        barPercentage: 0.9,
        offset: true,
        gridLines: {
          display: true,
          offsetGridLines: true,
          drawBorder: true,
          drawTicks: true,
        },
      },
    ],
  },
  tooltips: {
    // enabled: false,
    callbacks: {
      title: function (tooltipItems, data) {
        // var elemOpts = this._chart.options.elements;
        // var d = data.labels[tooltipItems[0].datasetIndex];
        return null;
      },
      label: function (tooltipItem, data) {
        const elemOpts = this._chart.options.elements;
        const d = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return [
          `${d[elemOpts.keyValue]}: ${moment(d[elemOpts.keyStart]).format(
            this._chart.options.elements.dateFormat
          )} / ${moment(d[elemOpts.keyEnd]).format(this._chart.options.elements.dateFormat)}`,
        ];
      },
    },
  },
};
