import {
  CropType,
  GlobalActionTypes,
  SessionExpiredRequest,
  SetCropTypesAction,
  ToggleGlobalTooltipProps,
} from '_reducers/global_types';
import {ActivityApi} from '_api';
import {Dialogs, RequestMethod} from 'types';

import {ActionType as NoteActionType, Note, ShowNote} from 'components/alert/reducer';
import axios from 'axios';
import {Dispatch} from 'react';
import {AppStore} from '../reducers';
import {Locales} from '../i18n-utils';
import {isAdminPerm} from '../_utils';

export const loadCropTypes = () => (
  dispatch: Dispatch<SetCropTypesAction>,
  getState: () => AppStore
) => {
  const langLocale = getState().login.user.settings.langLocale;
  const isAdmin = isAdminPerm(getState().login.user.perm);

  const setCropTypes = ({data}: {data: {result: CropType[]}}) => {
    const cropTypes: SetCropTypesAction['cropTypes'] = {};

    data.result.forEach(crop => {
      if (langLocale !== Locales.EnUS) {
        cropTypes[crop.value] = {
          ...crop,
          label:
            crop.label || (crop[`label_${langLocale.toLowerCase()}` as keyof CropType] as string),
        };
      } else {
        cropTypes[crop.value] = crop;
      }
    });

    dispatch({
      type: GlobalActionTypes.SET_CROP_TYPES,
      cropTypes,
    });
  };

  return ActivityApi.getCropTypesList(isAdmin ? undefined : langLocale.toLowerCase())
    .then(setCropTypes)
    .catch(() => {
      axios
        .get(`/assets/crops/crop-types.json`)
        .then(setCropTypes)
        .catch(() => /* TODO: handle no crop constants */ undefined);
    });
};

export const showNote = (note: Note): ShowNote => ({
  type: NoteActionType.SHOW_NOTE,
  note,
});

export const toggleHeader = () => ({
  type: GlobalActionTypes.TOGGLE_HEADER,
});

export const showLoading = (pattern: string = '', method: RequestMethod = 'get', key?: string) => ({
  type: GlobalActionTypes.ADD_LOADING,
  URLPattern: pattern,
  method,
  key: pattern === 'data2/:md5' ? '' : key,
});

export const hideLoading = (pattern: string = '', method: RequestMethod = 'get', key?: string) => ({
  type: GlobalActionTypes.REMOVE_LOADING,
  URLPattern: pattern,
  method,
  key: pattern === 'data2/:md5' ? '' : key,
});

export const toggleMapView = (value: boolean) => ({
  type: GlobalActionTypes.TOGGLE_MAP_VIEW,
  value,
});

export const setGlobalParam = (propName: string, value: string | number) => ({
  type: GlobalActionTypes.SET_GLOBAL_PARAM,
  propName,
  value,
});

export const toggleGlobalDialog = (
  dialog: Dialogs,
  properties: any = {},
  resetDialogState = false
) => ({
  type: GlobalActionTypes.TOGGLE_DIALOG,
  dialog,
  properties,
  resetDialogState,
});

export const toggleSessionExpired = (sessionExpired: boolean) => ({
  type: GlobalActionTypes.TOGGLE_SESSION_EXPIRED,
  sessionExpired,
});

export const addSessionExpiredRequest = (request: SessionExpiredRequest) => ({
  type: GlobalActionTypes.ADD_SESSION_EXPIRED_REQUEST,
  request,
});

export const setTabNotification = (tabKey: string, tabData: any) => ({
  type: GlobalActionTypes.SET_TAB_NOTIFICATION,
  tabKey,
  tabData,
});

export const toggleTooltip = (data: ToggleGlobalTooltipProps) => ({
  type: GlobalActionTypes.TOGGLE_GLOBAL_TOOLTIP,
  data,
});

export const updateCropType = (crop: CropType) => ({
  type: GlobalActionTypes.UPDATE_CROP_TYPE,
  crop,
});

export const addCropType = (crop: CropType) => ({
  type: GlobalActionTypes.ADD_CROP_TYPE,
  crop,
});

export const setReportView = (value: boolean) => ({
  type: GlobalActionTypes.SET_REPORT_VIEW,
  value,
});
