import ShrinkButton from '../shrink-table-view-btn';
import React from 'react';
import './index.scss';
import {FluroTableControls} from 'components';

type Props = {
  tabTitle: string | React.ReactNode;
  buttons?: React.ReactNode;
  justify?: 'end' | 'start' | 'around' | 'between';
};

const TableViewHeader = ({tabTitle, buttons, justify}: Props) => {
  return (
    <FluroTableControls id={'holder'} className={'sticky-header'}>
      <div className="table-view-header">
        {tabTitle}
        <ShrinkButton />
      </div>

      <div className={`header-buttons-container ${justify ? 'justify-' + justify : 'justify-end'}`}>
        {buttons}
      </div>
    </FluroTableControls>
  );
};

export default TableViewHeader;
