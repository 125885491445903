import React, {useEffect, useMemo, useState} from 'react';
import {
  BulkDeleteIcon,
  FieldsCountLabel,
  ListWrapper,
  ParsedDataTableWrapper,
  ToolbarWrapper,
} from '../field-properties-parser/field-properties-parser.styled';
import {Button, SelectionControl} from 'react-md';
import {t} from 'i18n-utils';
import {
  bulkSetUploadFieldsProp,
  removeUploadFields,
  setPropNewFieldGeometry,
  bulkSetPropNewFieldGeometry,
  setUploadFieldProp,
  removeNewFieldsGeometry,
  addFieldChangeCurrentStep,
  saveDrawFields,
  uploadFields,
  bulkUpdateCLUFieldBoundariesProp,
  saveSelectedBoundaries,
  updateCLUFieldBoundariesProp,
  removeCLUFieldBoundaries,
} from 'modules/add-fields.module';
import {FieldSystemProp} from '../types';
import {AutoSizer, List} from 'react-virtualized';
import {genKey, selectMeasurement} from '_utils';
import NewField from '../new-field-preview';
import {aNewFieldSizeLimitHa} from '_constants';
import {useAppSelector, usePrevious} from '_hooks';
import {Farm} from '../../../../types';
import {useDispatch} from 'react-redux';
import SelectFarmToUpload from '../select-farm-component';
import {FluroButton, ReadOnly} from 'components';
import {AsyncStatusType, Status} from 'modules/ui-helpers';
import {showNote} from '_actions';
import {GeoJSON} from 'react-leaflet';

/**
 * Important! things too keep in mind
 * currently, there are tree sources to get fields for this component.
 * all main fields .properties{} are unified through all sources using SystemProps
 * there is a fieldsToProcess() which classifies fields to display/update etc.
 * have a glance at the whole file structure before adding something new, thank you
 */

const FieldsListPreview = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();
  const geoJsonFiles = useAppSelector(state => state.addFields.geoJsonFiles);
  const drewFieldsGeometries = useAppSelector(state => state.addFields.drewFieldsGeometries);
  const cluFieldBoundaries = useAppSelector(state => state.addFields.cluFieldBoundaries);
  const farms = useAppSelector(state => state.farms.list);
  const currentStep = useAppSelector(state => state.addFields.addFieldCurrentStep);
  const isEditingMode = useAppSelector(state => state.map.drawControl.isEditingMode);
  const currentFarmId = useAppSelector(state => state.global.currentGroupId);
  const isUploading = useAppSelector(
    state =>
      state.uiHelpers.asyncStatuses[AsyncStatusType.uploadingFieldsToBackend].status ===
      Status.Pending
  );

  const [isAddNewFarm, setIsAddNewFarmState] = useState(false);
  const [errors, setErrors] = useState<Array<any>>([]);
  const [displayFarmWarning, setFarmWarningVisibility] = useState(false);

  const prev = usePrevious({currentFarmId});

  const farmsNamesById = useMemo(() => {
    const farmsNamesById: {[farmId: number]: string} = {};
    farms
      .filter((f: Farm) => !f.readOnly)
      .forEach(f => {
        farmsNamesById[f.id] = f.name;
      });
    return farmsNamesById;
  }, [farms]);

  const validateName = (id: number, name: string) => {
    const error = name.length < 2 || name.length > 50;
    setErrors(errors =>
      error ? (errors.find(e => e === id) ? errors : [...errors, id]) : errors.filter(e => e !== id)
    );
    return !error; // true = valid
  };

  const fieldsToProcess = useMemo(() => {
    switch (currentStep) {
      case 'view-fields-from-files':
        return geoJsonFiles;

      case 'view-drew-fields':
        return drewFieldsGeometries;

      case 'view-selected-boundaries':
        return cluFieldBoundaries.filter(f => f.properties.selectedForProcessing);
    }
  }, [currentStep, geoJsonFiles, drewFieldsGeometries, cluFieldBoundaries]);

  const selectedFieldIds = useMemo(() => {
    return fieldsToProcess
      .filter(f => f.properties[FieldSystemProp.Checked])
      .map(f => f.properties.id);
  }, [fieldsToProcess]);

  const allFieldsHasFarms = useMemo(() => {
    return fieldsToProcess.every(getFieldFarmName);
  }, [fieldsToProcess]);

  useEffect(
    function handleAllFieldsRemovingCase() {
      if (!fieldsToProcess.length && !isUploading) {
        switch (currentStep) {
          case 'view-fields-from-files':
            dispatch(addFieldChangeCurrentStep('select-files-to-upload'));
            break;
          case 'view-drew-fields':
            dispatch(addFieldChangeCurrentStep('draw-fields'));
            break;
          case 'view-selected-boundaries':
            dispatch(addFieldChangeCurrentStep('select-boundaries'));
            break;
        }
      }
    },
    [fieldsToProcess.length, isUploading]
  );

  useEffect(
    function onCurrentFarmChange() {
      /**
       * update field's farmId prop for associated with the current farm fields
       */
      const fieldsWithCurrentFarmId = fieldsToProcess.filter(
        f => f.properties[FieldSystemProp.FarmId] === prev?.currentFarmId
      );

      if (prev?.currentFarmId && currentFarmId && fieldsWithCurrentFarmId.length) {
        setFarmForFields(
          currentFarmId,
          fieldsWithCurrentFarmId.map(f => f.properties.id)
        );
      }
    },
    [currentFarmId]
  );

  const farmsSelectorValue = useMemo(() => {
    /**
     * classify the value for <SelectFarmToUpload /> autocomplete
     */
    const farmNames: {[farmName: string]: boolean} = {};
    fieldsToProcess.forEach(f => {
      if (f.properties[FieldSystemProp.Checked]) {
        const fieldFarmName = getFieldFarmName(f) || '';
        farmNames[fieldFarmName] = true;
      }
    });

    const farmNamesKeys = Object.keys(farmNames);

    switch (true) {
      case !farmNamesKeys.length || farmNamesKeys.includes(''):
        return t({id: 'Select farm name'});

      case farmNamesKeys.length === 1:
        return farmNamesKeys[0]; // all fields has the same value and it !== to '' because of the prev check

      case farmNamesKeys.length > 1:
        return t({id: 'Multiple farms selected'});

      default:
        return t({id: 'Select farm name'});
    }
  }, [fieldsToProcess]);

  const areAllFieldNamesValid = () => {
    let areFieldNamesValid = true;
    fieldsToProcess.forEach(f => {
      const valid = validateName(f.properties.id, f.properties[FieldSystemProp.FieldName]);
      if (areFieldNamesValid && !valid) {
        areFieldNamesValid = false;
      }
    });
    return areFieldNamesValid;
  };

  const onChangeFieldName = (id: number, value: number | string) => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(setUploadFieldProp(id, FieldSystemProp.FieldName, value));
        break;

      case 'view-drew-fields':
        dispatch(setPropNewFieldGeometry(id, FieldSystemProp.FieldName, value));
        break;

      case 'view-selected-boundaries':
        dispatch(updateCLUFieldBoundariesProp(id, FieldSystemProp.FieldName, value));
        break;
    }

    validateName(id, `${value}`);
  };

  const onSelectField = (id: number, value: boolean) => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(setUploadFieldProp(id, FieldSystemProp.Checked, value));
        break;
      case 'view-drew-fields':
        dispatch(setPropNewFieldGeometry(id, FieldSystemProp.Checked, value));
        break;
      case 'view-selected-boundaries':
        dispatch(updateCLUFieldBoundariesProp(id, FieldSystemProp.Checked, value));
    }
  };

  const onSelectAllFields = (value: boolean) => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(
          bulkSetUploadFieldsProp(
            geoJsonFiles.map(f => f.properties.id),
            FieldSystemProp.Checked,
            value
          )
        );
        break;
      case 'view-drew-fields':
        dispatch(
          bulkSetPropNewFieldGeometry(
            drewFieldsGeometries.map(f => f.properties.id),
            FieldSystemProp.Checked,
            value
          )
        );
        break;

      case 'view-selected-boundaries':
        dispatch(bulkUpdateCLUFieldBoundariesProp([], FieldSystemProp.Checked, value));
    }
  };

  const bulkDelete = () => {
    onRemoveField(selectedFieldIds);
  };

  const onRemoveField = (ids: number | number[]) => {
    const classifiedIds = Array.isArray(ids) ? ids : [ids];
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(removeUploadFields(classifiedIds));
        break;

      case 'view-drew-fields':
        dispatch(removeNewFieldsGeometry(classifiedIds));
        break;

      case 'view-selected-boundaries':
        dispatch(removeCLUFieldBoundaries(classifiedIds));
        break;
    }
  };

  const setFarmForFields = (farmId: number | 'add-new-farm', forceFieldIds?: number[]) => {
    if (farmId === 'add-new-farm') {
      setIsAddNewFarmState(true);
    }
    const fieldIdsToProcess = forceFieldIds || selectedFieldIds;
    const selectedFarm: Farm = farms.find(f => f.id === farmId);
    if (selectedFarm) {
      switch (currentStep) {
        case 'view-fields-from-files':
          dispatch(bulkSetUploadFieldsProp(fieldIdsToProcess, FieldSystemProp.NewFarmName, '')); // reset possible custom farm name value
          dispatch(bulkSetUploadFieldsProp(fieldIdsToProcess, FieldSystemProp.FarmId, farmId));
          break;

        case 'view-drew-fields':
          dispatch(bulkSetPropNewFieldGeometry(fieldIdsToProcess, FieldSystemProp.NewFarmName, '')); // reset possible custom farm name value
          dispatch(bulkSetPropNewFieldGeometry(fieldIdsToProcess, FieldSystemProp.FarmId, farmId));
          break;
        case 'view-selected-boundaries':
          dispatch(
            bulkUpdateCLUFieldBoundariesProp(fieldIdsToProcess, FieldSystemProp.NewFarmName, '')
          ); // reset possible custom farm name value
          dispatch(
            bulkUpdateCLUFieldBoundariesProp(fieldIdsToProcess, FieldSystemProp.FarmId, farmId)
          );
          break;
      }
    }
  };

  const setNewFarmForSelectedFields = (farmName: string) => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(bulkSetUploadFieldsProp(selectedFieldIds, FieldSystemProp.FarmId, 0)); // reset farm ID when set a custom farm name
        dispatch(bulkSetUploadFieldsProp(selectedFieldIds, FieldSystemProp.NewFarmName, farmName)); //in uploadFields() will create a new farm
        break;

      case 'view-drew-fields':
        dispatch(bulkSetPropNewFieldGeometry(selectedFieldIds, FieldSystemProp.FarmId, 0)); // reset farm ID when set a custom farm name
        dispatch(
          bulkSetPropNewFieldGeometry(selectedFieldIds, FieldSystemProp.NewFarmName, farmName)
        );
        break;
      case 'view-selected-boundaries':
        dispatch(bulkUpdateCLUFieldBoundariesProp(selectedFieldIds, FieldSystemProp.FarmId, 0)); // reset farm ID when set a custom farm name
        dispatch(
          bulkUpdateCLUFieldBoundariesProp(selectedFieldIds, FieldSystemProp.NewFarmName, farmName)
        );
        break;
    }
  };

  const onMoveToThePrevStep = () => {
    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(
          // reset possible farmId values
          bulkSetUploadFieldsProp(
            geoJsonFiles.map(f => f.properties.id),
            FieldSystemProp.FarmId,
            0
          )
        );
        dispatch(addFieldChangeCurrentStep('parse-uploading-files'));
        break;
      case 'view-drew-fields':
        // dispatch(removeNewFieldsGeometry([])); // remove all drew geometry
        dispatch(addFieldChangeCurrentStep('draw-fields'));
        break;
      case 'view-selected-boundaries':
        dispatch(addFieldChangeCurrentStep('select-boundaries'));
    }
  };

  const showEditModeWarning = () => {
    return dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: '`Please save or cancel your changes before saving'}),
        level: 'warning',
      })
    );
  };

  const showFarmsSetUpWarning = () => {
    return dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'Make sure you select a farm first.'}),
        level: 'warning',
      })
    );
  };

  const showInvalidFieldsNameWarning = () => {
    return dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'Some fields have invalid names, please correct.'}),
        level: 'warning',
      })
    );
  };

  const tryUploadFields = async () => {
    const fieldNamesValid = areAllFieldNamesValid();
    setFarmWarningVisibility(!allFieldsHasFarms);

    if (!fieldNamesValid) {
      showInvalidFieldsNameWarning();
      return;
    }

    if (!allFieldsHasFarms) {
      showFarmsSetUpWarning();
      return;
    }

    if (isEditingMode) {
      showEditModeWarning();
      return;
    }

    switch (currentStep) {
      case 'view-fields-from-files':
        dispatch(uploadFields());
        break;
      case 'view-drew-fields':
        dispatch(saveDrawFields());
        break;
      case 'view-selected-boundaries':
        dispatch(saveSelectedBoundaries());
    }
  };

  function getFieldFarmName(field: GeoJSON.Feature) {
    return field.properties[FieldSystemProp.FarmId]
      ? farmsNamesById[field.properties[FieldSystemProp.FarmId]] ||
          field.properties[FieldSystemProp.FarmId]
      : field.properties[FieldSystemProp.NewFarmName];
  }

  const renderRow = ({index, style}: {index: number; style: any}) => {
    const field = fieldsToProcess[index];
    const fieldArea = selectMeasurement(field.properties[FieldSystemProp.Area]);

    const fieldFarm = getFieldFarmName(field);
    const fieldNameValue = field.properties[FieldSystemProp.FieldName];
    const helpText = `${fieldArea} ${fieldFarm ? `- ${fieldFarm}` : ''}`;
    const errorText =
      displayFarmWarning && !fieldFarm // display farm waring first
        ? t({id: 'Missing farm'})
        : !!errors.includes(field.properties.id) &&
          t({id: 'Field name must be range characters'}, {range: '2-50'});

    return (
      <div key={field.properties.id} style={style}>
        <NewField
          selected={field.properties[FieldSystemProp.Checked]}
          onSelect={(value: boolean) => onSelectField(field.properties.id, value)}
          preview={field}
          sizeWarning={field.properties[FieldSystemProp.Area] > aNewFieldSizeLimitHa}
          name={fieldNameValue}
          id={field.properties.id}
          key={id}
          helpText={<span title={helpText}>{helpText}</span>}
          error={!!errorText}
          errorText={errorText}
          onChangeName={onChangeFieldName}
          removeField={() => onRemoveField(field.properties.id)}
        />
      </div>
    );
  };

  return (
    <ParsedDataTableWrapper className={'preview-fields'}>
      <ToolbarWrapper>
        <>
          <SelectionControl
            label={''}
            id="parser-select-all-fields"
            name="parser-select-all-fields"
            type="checkbox"
            checked={selectedFieldIds.length === fieldsToProcess.length}
            onChange={(value: boolean) => onSelectAllFields(value)}
          />

          <FieldsCountLabel>
            {t({id: '{count} fields selected'}, {count: selectedFieldIds.length})}
          </FieldsCountLabel>
        </>

        <BulkDeleteIcon
          //@ts-ignore
          title={t({id: 'Delete this field'})}
          iconClassName="fas fa-trash"
          onClick={bulkDelete}
          disabled={!selectedFieldIds.length}
        />

        <SelectFarmToUpload
          onAutocompleteFarm={(farmId: number | 'add-new-farm') => setFarmForFields(farmId)}
          farmName={farmsSelectorValue}
          isNewFarm={isAddNewFarm}
          onCreateNewFarmToggle={setIsAddNewFarmState}
          setNewFarmForSelectedFields={setNewFarmForSelectedFields}
          disabled={!selectedFieldIds.length}
        />
      </ToolbarWrapper>

      <ListWrapper>
        <AutoSizer>
          {({width, height}) => {
            return (
              <List
                createFolder
                width={width}
                height={height}
                rowHeight={75}
                rowRenderer={renderRow}
                rowCount={fieldsToProcess.length}
                overscanRowCount={10}
              />
            );
          }}
        </AutoSizer>
      </ListWrapper>

      <div className="new-fields-nav-container sticky in-right-panel">
        <FluroButton
          id={currentStep === 'view-fields-from-files' ? 'back-btn' : 'add-more-fields'}
          raised
          blank
          noPadding
          onClick={onMoveToThePrevStep}
        >
          {currentStep === 'view-fields-from-files' ? t({id: 'Back'}) : t({id: 'Add more fields'})}
        </FluroButton>

        <ReadOnly>
          <Button
            id={'save-new-fields'}
            raised
            primary
            onClick={tryUploadFields}
            disabled={!!errors.length || isUploading}
          >
            {t({id: 'Upload {count} field'}, {count: fieldsToProcess.length})}
          </Button>
        </ReadOnly>
      </div>
    </ParsedDataTableWrapper>
  );
};

export default FieldsListPreview;
