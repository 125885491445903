import {Season} from 'containers/map/types';
import {AppStore} from 'reducers';

export const getCropTypeMapChartData = ({
  cropsToShow,
  cropVarietyMode,
  cropTypes,
  cropVarietyColors,
}: {
  cropsToShow: Season[];
  cropVarietyMode: boolean;
  cropTypes: AppStore['global']['cropTypes'];
  cropVarietyColors: AppStore['map']['cropVarietyColors'];
}) => {
  const types = countCropTypes(cropsToShow);
  const varieties = countCropVarieties(cropsToShow);

  const count = cropVarietyMode ? varieties : types;

  const cropType = Object.keys(types).find(cropType => cropType !== 'No crop');

  const labels = Object.keys(count).sort(
    (a, b) => count[b] - count[a] || a.localeCompare(b) // biggest numbers first // alphabetically
  );

  const data = labels.map(label => ({
    id: cropTypes[label]?.label || label,
    label,
    value: count[label],
  }));

  const getColor = (slice: any) =>
    (cropVarietyMode
      ? slice.label === 'No variety'
        ? cropTypes[cropType]?.color
        : cropVarietyColors[slice.label]
      : cropTypes[slice.label]?.color) || '#EFEFEF';

  return {data, getColor};
};

const countCropTypes = (seasons: Season[]) => {
  const count: {[cropType: string]: number} = {};
  seasons.forEach(season => {
    const cropType = season?.cropType || 'No crop';
    if (!count[cropType]) {
      count[cropType] = 0;
    }
    count[cropType]++;
  });
  return count;
};

const countCropVarieties = (seasons: Season[]) => {
  const count: {[cropVariety: string]: number} = {};
  seasons.forEach(season => {
    const cropVariety = season?.cropType ? season?.params?.cropSubType || 'No variety' : 'No crop';
    if (!count[cropVariety]) {
      count[cropVariety] = 0;
    }
    count[cropVariety]++;
  });
  return count;
};
