import {t} from 'i18n-utils';
import React, {ReactElement, useCallback, useMemo} from 'react';
import {FontIcon, SelectionControl, TextField} from 'react-md';
import {genKey, getMeasurement} from '_utils';
import {aNewFieldSizeLimitHa, aNewFieldSizeLimitAc, MAIN_SHAPE_COLOR} from '_constants';
import {GeometryPreview, FluroButton} from 'components';

type Props = {
  sizeWarning?: boolean;
  selected?: boolean;
  onSelect?: (value: boolean) => void;
  name: any;
  id: number;
  size?: number;
  error: boolean;
  preview?: any;
  helpText?: string | ReactElement;
  errorText?: string | ReactElement;
  removeField(): void;
  onBlur?(): void;
  onFocus?(): void;
  onChangeName(id: string | number, value: number | string): void;
};

const NewFieldPreview = ({
  selected,
  onSelect,
  sizeWarning,
  name,
  id,
  size,
  error,
  onChangeName,
  removeField,
  onBlur,
  onFocus,
  preview,
  helpText,
  errorText,
}: Props) => {
  const uniqId = useMemo(() => id || genKey(), []);
  const limitSize = useMemo(
    () => (getMeasurement() === 'ha' ? `${aNewFieldSizeLimitHa} ha` : `${aNewFieldSizeLimitAc} ac`),
    []
  );

  const onEachFeature = useCallback((feature: any, layer: any) => {
    layer.setStyle({color: MAIN_SHAPE_COLOR, weight: 1});
  }, []);

  return (
    <div className="field-upload-item">
      {onSelect ? (
        <SelectionControl
          id={`select-${id}-field`}
          name="select-${id}-field"
          type="checkbox"
          label=""
          checked={!!selected}
          onChange={(value: boolean) => onSelect(value)}
        />
      ) : null}
      {preview ? (
        <div className="field-upload-item__preview">
          <GeometryPreview onEachFeature={onEachFeature} size={[40, 40]} data={preview} />
        </div>
      ) : null}

      <div className="field-upload-item__controls">
        <TextField
          id={`field-name-${uniqId}`}
          label=""
          lineDirection="center"
          className={'field-upload-item__field-name'}
          placeholder={t({id: 'Field name'})}
          value={name}
          error={error}
          onChange={(value: string) => onChangeName(id, value)}
          errorText={errorText}
          onBlur={onBlur}
          onFocus={onFocus}
          helpText={helpText}
        />
      </div>

      <FontIcon
        {...{title: t({id: 'Delete this field'})}}
        className={'delete-field-icon'}
        iconClassName={'fas fa-trash'}
        onClick={() => removeField()}
      />

      {size && (
        <div className={'field-area'}>
          {sizeWarning && (
            <FluroButton
              className={'warning-icon'}
              icon
              tooltipLabel={t({id: 'Area is bigger than limitSize'}, {limitSize})}
              tooltipPosition={'top'}
            >
              {t({id: 'info'})}
            </FluroButton>
          )}
        </div>
      )}
    </div>
  );
};

export default NewFieldPreview;
