import {t} from 'i18n-utils';
import * as React from 'react';
import {useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '_hooks';
import {getCarbonRewardKey} from '../base/base';
import {CarbonOutcomes} from '../base/carbon-outcomes';
import {CarbonPlan} from '../base/carbon-plan';
import {getPracticeStats} from '../base/base-carbon-plan';
import {CarbonStep, redirectToContract, saveCarbonPlan} from '../carbon-reducer';
import './carbon-panel-get-credits.scss';
import {toFixedFloat} from '_utils';
import {showNote} from '_actions';
import {dialogToggle} from '../../../modules/ui-helpers';
import colors from '../../../variables.scss';

export const CarbonPanelGetCredits = () => {
  const dispatch = useAppDispatch();
  const carbon = useAppSelector(s => s.carbon);
  const fieldsByFarmId = useAppSelector(s => s.map.fieldsByFarmId);
  const plan = carbon.plans[carbon.activePlan];

  const practiceStats = useMemo(() => {
    return getPracticeStats(carbon, fieldsByFarmId);
  }, [carbon, fieldsByFarmId]);

  let carbonSequestered = 0;
  let reward = 0;
  Object.keys(plan.practices).forEach(fieldId => {
    const estimations = carbon.targetPractices[fieldId];
    const practice = plan.practices[fieldId];
    if (!practice || !estimations) {
      return;
    }
    const key = getCarbonRewardKey(practice);
    if (estimations.gwpTons[key]) {
      carbonSequestered += estimations.gwpTons[key];
    }
    if (estimations.financialIncentiveCents[key]) {
      reward += estimations.financialIncentiveCents[key];
    }
  });
  carbonSequestered = toFixedFloat(carbonSequestered, 1);
  reward = Math.round(reward);

  let totalFields = 0;
  let totalArea = 0;
  plan.carbonPractices.forEach(p => {
    totalFields = totalFields + practiceStats[p].fieldCount;
    totalArea = totalArea + practiceStats[p].area;
  });

  const onApprovePlan = async () => {
    if (!totalFields) {
      dispatch(
        showNote({
          title: t({id: 'note.warning', defaultMessage: 'Warning'}),
          message: t({
            id: 'Please select fields and practices first.',
          }),
          level: 'warning',
        })
      );
      return;
    }

    dispatch(
      dialogToggle('confirm', true, {
        okLabel: 'Approve plan',
        okAction: async () => {
          await dispatch(saveCarbonPlan());
          dispatch(redirectToContract());
        },
        title: 'Approve plan',
        agreementsMessage: (
          <>I agree that the information entered is accurate to the best of my knowledge.</>
        ),
        message: (
          <div style={{color: colors['main-gray-900']}}>
            The information entered will be used to compile a legally binding contract.
          </div>
        ),
      })
    );
  };

  return (
    <div className="carbon-panel__get-credits">
      <h3 className="tab-title">{t({id: CarbonStep.Credits})}</h3>
      <div className="description">
        Use the scenario planner below to match fields and practices to be implemented in fall 2021
        and spring 2022. Predicted outcomes will change as you make your selections. Approve plan
        when finished.
      </div>
      <div className="description">
        Outcomes modeled for practices beginning fall 2021 through fall 2022.
      </div>
      <CarbonPlan practiceStats={practiceStats} />
      <hr className="line-separator" />
      <CarbonOutcomes
        totalFields={totalFields}
        totalArea={totalArea}
        carbonSequestered={toFixedFloat(carbonSequestered, 1)}
        moneyOutcome={toFixedFloat(reward, 1)}
        approvePlan={onApprovePlan}
      />
    </div>
  );
};
