import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {NrxApi} from '_api';
//@ts-ignore
import {
  capitalizeFirstLetter,
  formatDate,
  formatUnit,
  getMeasurement,
  convertUnit,
  toFixedFloat,
  clamp,
  isAustralianField,
} from '_utils';
import {hackGetState} from 'store';
import {FieldTag, Field, Season, Zone} from '../types';
import {GLOBAL_FORMAT_DATE} from '_constants';
import moment from 'moment';
import {NitrogenRecommendationMethods} from '../features/zoning/nitrogen-recommendation';
import {getFieldById} from './field';
import {FeatureCollection, Geometry} from 'geojson';
import {ActionTypes} from '../reducer/types';
import {FluroStatusIcon, InfoBlock} from 'components';
import {AppearanceValues} from '../../../components/info-block/types';
import {AppStore} from '../../../reducers';

// interfaces

export interface FertilizerApplication {
  date: string;
  fieldID?: number;
  id?: number;
  kmlID?: number;
  option: any;
  quantity: number;
  seasonID?: any;
  nrxSeasonID?: number;
  type?: string;
  typeID?: number;
  nitrogenPercentage?: number;
}

export interface NrxPopUpValues {
  n_percentage: number;
  crop_price: number;
  N_price: number;
  season_outlook: number;
  N_constrain: number;
  ROI_setting: number;
  recommendation_date?: string;
  product: string;
  isLiquid?: boolean; // type of fertilizer product
  specific_gravity?: number; // specific gravity value of fertilizer if it's liquid
  method?: NitrogenRecommendationMethods;
  min_yield_goal?: number; // only for specific list of fields
  max_yield_goal?: number;
  historical_yield_avg?: number;
  units?: 'ha' | 'ac';
}

export type NrxTabRate = 'variable' | 'blanket';

export type NRxObjectiveType = 'balanced' | 'max_roi' | 'max_yield';

export type NRxObjectiveResponseObject = {
  avg_nrx: number;
  avg_yield_incr: number;
  yield_potential_max: number; // comes in kg/ha
  yield_potential_min: number; // comes in kg/ha
  map: {
    type: 'FeatureCollection';
    features: NRxObjectiveResponseFeature[];
  };
};

export type NRxObjectiveResponseFeature = GeoJSON.Feature & {
  properties: {
    display_rank: number;
    val: number;
    yield_goal: number;
    yield_pcnt_incr: number;
  };
};

export type NRxResultsResponse = {
  balanced: NRxObjectiveResponseObject;
  max_roi: NRxObjectiveResponseObject;
  max_yield: NRxObjectiveResponseObject;
};
export type NRxZonesCollection = {
  type: 'FeatureCollection';
  avgNrx: number;
  yieldIncrease: number;
  yield_potential_max?: number; // converted value depending on the crop type
  yield_potential_min?: number; // converted value depending on the crop type
  yield_potential_units?: string; // converted units depending on the crop type
  features: NRxZone[];
};
export type NRxZone = {geometry: Geometry; properties: Zone};

export interface NRxSeason {
  cropSubtype: string;
  cropType: string;
  cropVarietyID: number;
  endDate: string;
  fertiliserApplied?: number;
  haySilage: number;
  kmlID: number;
  nrxSeasonID: number;
  prevCropTypeID: number;
  prevHarvestDate: string;
  recommendationDates: any;
  rowSpacing: number;
  seasonID: number;
  soilTypeID: number;
  soilTypeLabelID?: string;
  sowingDensity: number;
  sowingDepth: number;
  startDate: string;
  status?: string;
  cropID?: number;
  seven_zones?: boolean; // custom tag to run another endpoint to get NRx results
  roiSettings?: Partial<NrxPopUpValues>; // it is possible to save ROI setting per NRx season
  irrigated?: boolean;
}

export type SoilType = {
  apsoilID: number;
  apsoilNumber: string;
  depth: 'string';
  id: number;
  initialNdepth: number;
  type: string;
  distance?: string;
};

export type TNrxFertilizerListItem = {
  typeID: number;
  type: string; // label
  nitrogenPercentage: number; // nitrogen percentage value
  isLiquid?: boolean; // type of fertilizer product
  specific_gravity?: number; // specific gravity value of fertilizer if it's liquid
};

export type TNrxSeasonProp = {
  label: string;
  value: number;
};

export type TFieldsNearestSoilTypesList = {
  [key: string]: TNrxSeasonProp[];
};

export type NrxListsData = {
  cropTypesList: TNrxSeasonProp[];
  prevCropTypesList: TNrxSeasonProp[];
  soilTypesList: TNrxSeasonProp[];
  soilTypesRawList: [];
  rowSpacingList: TNrxSeasonProp[];
  silageHayList: TNrxSeasonProp[];
  irrigationList: TNrxSeasonProp[];
  sowingDensityList: TNrxSeasonProp[];
  sowingDepthList: TNrxSeasonProp[];
  fertilizerTypes: TNrxFertilizerListItem[];
  fertilizerDosesList: TNrxSeasonProp[];
  liquidFertilizerDosesList: TNrxSeasonProp[];
  fieldsNearestSoilTypesList: TFieldsNearestSoilTypesList;
};

/// constants

export const ZONES_COLOURS = [
  'rgb(246, 230, 32)',
  'rgb(96, 198, 104)',
  'rgb(31, 160, 136)',
  'rgb(39, 126, 142)',
  'rgb(55, 91, 141)',
  'rgb(70, 50, 126)',
  'rgb(68, 1, 84)',
];

export const defaultNrxSeason: NRxSeason = {
  cropSubtype: '',
  cropType: '',
  cropVarietyID: 0,
  endDate: '',
  haySilage: 0,
  kmlID: 0,
  nrxSeasonID: 0,
  prevCropTypeID: 0,
  prevHarvestDate: '',
  recommendationDates: [],
  rowSpacing: 0,
  seasonID: 0,
  soilTypeID: 0,
  sowingDensity: 0,
  sowingDepth: 0,
  startDate: '',
  cropID: 0,
  irrigated: false,
};

export const defaultSowingParams = (id: number): Partial<NRxSeason> => {
  const measurement = getMeasurement();
  const types: {[id: number]: Partial<NRxSeason>} = {
    [NRxCropTypes.Maize]: {
      sowingDepth: 30,
      rowSpacing: 900,
      sowingDensity: 4,
      irrigated: false,
    },
    [NRxCropTypes.Sorghum]: {
      sowingDepth: 35,
      rowSpacing: 1000,
      sowingDensity: 5,
      irrigated: false,
    },
    [NRxCropTypes.Cotton]: {
      sowingDepth: 40,
      rowSpacing: 950,
      sowingDensity: 9,
      irrigated: false,
    },
    [NRxCropTypes.Potato]: {
      sowingDepth: 100,
      rowSpacing: 850,
      sowingDensity: 50,
      irrigated: true,
    },
    [NRxCropTypes.Wheat]: {
      sowingDepth: 45,
      rowSpacing: 250,
      sowingDensity: 100,
      irrigated: false,
    },
  };
  if (!types[id]) return {};
  if (measurement === 'ha') return types[id];

  return {
    sowingDepth: toFixedFloat(convertUnit('ac', 'mm', types[id].sowingDepth), 0),
    sowingDensity: toFixedFloat(convertUnit('ac', 'm2', types[id].sowingDensity), 0),
    rowSpacing: toFixedFloat(convertUnit('ac', 'mm', types[id].rowSpacing), 0),
    irrigated: id === NRxCropTypes.Potato,
  };
};

export const RISK_LEVELS = {
  1: () => t({id: 'nrx.High'}),
  2: () => t({id: 'nrx.Moderate'}),
  3: () => t({id: 'nrx.Low'}),
};

export const riskLevelToLabel = (riskLevel: any) => {
  //@ts-ignore
  return RISK_LEVELS[riskLevel]?.() || '';
};

export const measurementUnits: any = () => ({
  ha: [
    {label: t({id: 'kg / ha'}), value: 'lb'},
    {label: t({id: 'L / ha'}), value: 'gal'},
    {label: t({id: 'seeds / ha'}), value: 'seeds'},
  ],
  ac: [
    {label: t({id: 'lb / ac'}), value: 'lb'},
    {label: t({id: 'gal / ac'}), value: 'gal'},
    {label: t({id: 'seeds / ac'}), value: 'seeds'},
  ],
});

export const ObjectiveLabels = {
  balanced: 'BALANCED',
  max_roi: 'MAX ROI',
  max_yield: 'MAX YIELD',
};

export const getDefaultNSettingValues = (nrxSeason?: NRxSeason): NrxPopUpValues => {
  let additionalProps: Partial<NrxPopUpValues> = {};
  let crop_price = 250;
  let N_price = 500;

  if (nrxSeason) {
    const measurement = getMeasurement();
    const isUS = measurement !== 'ha';

    crop_price = isUS ? getDefaultCropPrice(nrxSeason.cropID) : crop_price;
    N_price = isUS ? 365 : N_price;

    if (nrxSeason.seven_zones) {
      additionalProps.min_yield_goal = 30;
      additionalProps.max_yield_goal = 60;
    }
    if (nrxSeason.roiSettings) {
      const productData = getNrxFertilizerListItemData(nrxSeason.roiSettings.product);
      additionalProps = {
        ...additionalProps,
        ...convertUSValues(
          {
            ...nrxSeason.roiSettings,
            crop_price: toFixedFloat(
              nrxSeason.roiSettings.crop_price,
              measurement === 'ha' ? 0 : 1
            ),
          },
          nrxSeason.cropID,
          'ha'
        ),
        isLiquid: !!productData?.isLiquid,
        specific_gravity: productData?.specific_gravity || 1,
      };
    }
  }

  return {
    crop_price,
    N_price,
    n_percentage: 46,
    season_outlook: 10,
    N_constrain: 0,
    ROI_setting: 2,
    product: 'Urea',
    isLiquid: false,
    specific_gravity: 1,
    historical_yield_avg: 0,
    ...additionalProps,
  };
};

const metricRatio = 0.907185;

export const convertUSValues = (
  data: Partial<NrxPopUpValues>,
  cropType: number,
  measurement: string,
  forRequest = false
): Partial<NrxPopUpValues> => {
  const resultData = {...data};
  if (measurement !== 'ha') {
    resultData.crop_price = covertCropPriceFromUS(cropType, resultData.crop_price);
    resultData.N_price = toFixedFloat(resultData.N_price * metricRatio, 0);
    resultData.N_constrain = clamp(0, resultData.N_constrain * metricRatio, 1000);
  }

  if (resultData.min_yield_goal !== undefined) {
    resultData.min_yield_goal = toFixedFloat(resultData.min_yield_goal || 0, 0);
    resultData.max_yield_goal = toFixedFloat(resultData.max_yield_goal || 0, 0);
  }
  // quick win, will be replaced by form in n-recommendation component
  resultData.N_price = toFixedFloat(resultData.N_price || 0, 0);
  resultData.N_constrain = toFixedFloat(resultData.N_constrain || 0, 0);
  resultData.crop_price = toFixedFloat(resultData.crop_price || 0, forRequest ? 0 : 1);
  resultData.historical_yield_avg = toFixedFloat(resultData.historical_yield_avg);

  return resultData;
};

/**
 * convertNRxSeasonValues converts US values to Metric and vise-versa (if needs)
 */
export const convertNRxSeasonValues = (data: Partial<NRxSeason>, fromServer = false) => {
  const measurement = getMeasurement();
  if (measurement === 'ha') return data;
  const propsToConvert = [
    {prop: 'sowingDepth', units: 'mm'},
    {prop: 'sowingDensity', units: 'm2'},
    {prop: 'rowSpacing', units: 'mm'},
  ] as {prop: keyof NRxSeason; units: string}[];

  propsToConvert.forEach(({prop, units}) => {
    if (data[prop] === undefined) return;

    // @ts-ignore
    data[prop] = data[prop]
      ? toFixedFloat(convertUnit(measurement, units, data[prop], !fromServer), 0)
      : data[prop];
  });

  return data;
};

/**
 * prepareFertilizers filter fertilizer list from duplicates and convert quantity value (if needs). FSB-3251
 */

export const convertFertilizerValues = (fertilizer: FertilizerApplication, fromServer = false) => {
  const measurement = getMeasurement();
  const isLiquid =
    fertilizer.typeID !== 0 && getNrxFertilizerListItemData(fertilizer.typeID)?.isLiquid;
  fertilizer.quantity = toFixedFloat(
    convertUnit(measurement, isLiquid ? 'gal/ac' : 'lb/ac', fertilizer.quantity, !fromServer),
    0
  );
  return fertilizer;
};

const prepareFertilizers = (fertilizers: FertilizerApplication[]) => {
  const measurement = getMeasurement();
  const fertilizerIds: number[] = [];
  return fertilizers
    .filter(f => {
      if (!fertilizerIds.includes(f.id)) {
        fertilizerIds.push(f.id);
        return true;
      }
      return false;
    })
    .map(f => {
      if (measurement === 'ha') return f;
      return convertFertilizerValues(f, true);
    });
};

/// requests

export const loadNrxData = () => (dispatch: any, getState: () => AppStore) => {
  const {
    map: {fields, listsData},
  } = getState();
  if (
    fields.some(
      (f: Field) => f.tags && f.tags.includes(FieldTag.NRx) && f.Seasons.length && !f.Seasons[0].nrx
    )
  ) {
    const australianFields = fields.filter(
      (f: Field) => f.Country === 'Australia' && f.tags.includes(FieldTag.NRx)
    );
    return Promise.all([loadFertilizerTypes(), loadCropTypes(), loadSoilParams(australianFields)])
      .then(listsData => {
        const dataAsObject = listsData.reduce(
          (res: any, currentList) => ({...res, ...currentList}),
          listsData[0]
        );
        dispatch({
          type: ActionTypes.MAP_NRX_SET_LISTS_DATA,
          lists: dataAsObject,
        });

        return loadNrxSeasonsData().then((fields: Field[]) => {
          dispatch({
            type: ActionTypes.MAP_NRX_LOAD_SEASONS_DATA,
            fields,
          });
        });
      })
      .catch(err => console.log('loadNrxData err', err));
  }
  return Promise.resolve();
};

export const loadCropTypes = () => {
  return NrxApi.getCropType().then(({data}) => {
    const prepareCropObjects = data.result.map((cropType: any) => ({
      value: cropType.id,
      label: capitalizeFirstLetter(cropType.name),
      variety: (cropType.name === 'potato'
        ? cropType.variety.filter((v: any) => v.name === 'RussetBurbank')
        : cropType.variety
      ).map((variety: any) => ({
        value: variety.id,
        label: variety.name,
      })),
    }));

    return {
      cropTypesList: prepareCropObjects.filter((cT: any) =>
        currentlyAllowedCropTypes.includes(cT.label.toLowerCase())
      ),
      prevCropTypesList: prepareCropObjects.filter((cT: any) =>
        currentlyAllowedPrevCropTypes.includes(cT.label.toLowerCase())
      ),
    };
  });
};

export const loadSoilParams = (fields: Field[]) => {
  if (fields.length) {
    const fieldCoordinates = fields.map(f => ({
      id: f.ID,
      coords: [f.SouthLat, f.WestLon],
    }));
    // FSB-2760 request specific endpoint to get nearest crop types for Australian fields
    return NrxApi.getNearestSoilType(fieldCoordinates)
      .then(({data}) => modifySoilParams(data.result))
      .catch(err => console.log(err));
  }

  return NrxApi.getSoilType()
    .then(({data}) => modifySoilParams(data.result))
    .catch(err => console.log(err));
};

const modifySoilParams = (data: any) => {
  const {
    soilType,
    options: {rowSpacing, sowingDensity, sowingDepth},
    fields = {},
  } = data;
  const measurement = getMeasurement();

  return {
    fieldsNearestSoilTypesList: Object.keys(fields).reduce(
      (result, key) => ({
        ...result,
        [key]: prepareSoilMenuLists(
          'type',
          soilType.filter((soilType: SoilType) => fields[key].includes(soilType.apsoilID))
        ),
      }),
      {}
    ),
    rowSpacingList: rowSpacing.map((spacing: number) => ({
      label: `${convertUnit(measurement, 'mm', spacing)} ${formatUnit(measurement, 'mm')} `,
      value: spacing,
    })),
    sowingDepthList: sowingDepth.map((depth: number) => ({
      label: `${convertUnit(measurement, 'mm', depth)} ${formatUnit(measurement, 'mm')} `,
      value: depth,
    })),
    silageHayList: [
      {value: 1, label: 'Yes'},
      {value: 0, label: 'No'},
    ],
    irrigationList: [
      {value: 1, label: 'Yes'},
      {value: 0, label: 'No'},
    ],
    soilTypesRawList: soilType,
    soilTypesList: prepareSoilMenuLists('type', soilType),
    sowingDensityList: sowingDensity.map((density: number) => ({
      value: density,
      label: (
        <span>
          {convertUnit(measurement, 'm', density).toFixed(0)} plants /{' '}
          {formatUnit(measurement, 'm')} <sup>2</sup>
        </span>
      ),
    })),
  };
};

const prepareSoilMenuLists = (prop: keyof SoilType, list: SoilType[]) => {
  return list.reduce(
    (result: Array<any>, soilT: SoilType) =>
      result.find(v => v.label === soilT[prop])
        ? result
        : [
            ...result,
            {
              value: soilT[prop],
              label: soilT[prop],
            },
          ],
    []
  );
};

const prepareFertilizerDosesList = (list: number[], measurement: string, isLiquid: boolean) =>
  list.map(d => ({
    value: d,
    label: `${toFixedFloat(
      convertUnit(measurement, isLiquid ? 'gal/ac' : 'lb/ac', d),
      0
    )} ${formatUnit(measurement, isLiquid ? 'l / ha' : 'kg / ha')}`,
  }));

export const loadFertilizerTypes = () => {
  const measurement = getMeasurement();
  return NrxApi.getFertilizerParams()
    .then(({data}) => {
      const dosesRawList = data.result?.doseApplied || [];
      return {
        fertilizerTypes: [
          ...(data.result?.types || []).map((t: any) => ({
            typeID: t.id,
            type: t.name,
            nitrogenPercentage: t.nitrogen_percentage,
            isLiquid: t.liquid,
            specific_gravity: t.specific_gravity,
          })),
          {
            typeID: 0,
            type: 'Other (solid)',
            nitrogenPercentage: 0,
          },
        ],
        fertilizerDosesList: prepareFertilizerDosesList(dosesRawList, measurement, false),
        liquidFertilizerDosesList: prepareFertilizerDosesList(dosesRawList, measurement, true),
      };
    })
    .catch(err => console.log('getFertilizerTypes err', err));
};

export const loadNrxSeasonsData = () => {
  const state: any = hackGetState();
  const {
    map: {
      fields,
      listsData: {fieldsNearestSoilTypesList},
    },
  } = state;
  return NrxApi.getNrxSeasons(
    fields
      .filter((f: Field) => f.tags.includes(FieldTag.NRx))
      .map((f: Field) => f.ID)
      .join(',')
  ).then(({data}) => {
    return fields.map((currentF: Field) => {
      const isNrxField = currentF.tags && currentF.tags.includes(FieldTag.NRx);
      const isAustralian = isAustralianField(currentF);
      const nearestCropTypes = fieldsNearestSoilTypesList[currentF.ID];

      if (isNrxField && currentF.Seasons.length) {
        const {
          seasons,
          fertilizerApplications = [],
        }: {
          seasons: Array<NRxSeason>;
          fertilizerApplications: Array<FertilizerApplication>;
        } = data.result[currentF.ID];
        return {
          ...currentF,
          fertilizerApplications: prepareFertilizers(fertilizerApplications),
          Seasons: currentF.Seasons
            ? currentF.Seasons.map(currentS => {
                let nrxSeason =
                  seasons.find((s: NRxSeason) => s.seasonID === currentS.id) || defaultNrxSeason;
                if (nrxSeason.seasonID) {
                  const momentStartDate = moment(nrxSeason.startDate, GLOBAL_FORMAT_DATE);
                  const momentEndDate = moment(nrxSeason.endDate, GLOBAL_FORMAT_DATE);
                  nrxSeason.cropID = getNrxCropTypeBySubtype(nrxSeason.cropVarietyID).value;
                  nrxSeason.soilTypeID = nrxSeason.soilTypeID
                    ? nrxSeason.soilTypeID
                    : isAustralian
                    ? getSoilTypeIdByLabel(nearestCropTypes[0]?.value)
                    : 3404; //Automatic soil detection

                  nrxSeason.soilTypeLabelID = getSoilTypeLabelBySoilDepth(nrxSeason.soilTypeID);
                  nrxSeason.recommendationDates =
                    nrxSeason.recommendationDates?.filter(
                      // we might have recommendation date out of the season, filter such dates
                      (recommendationDate: string) =>
                        moment(recommendationDate).isBetween(momentStartDate, momentEndDate),
                      null,
                      '[]' // this means including the start/end dates too
                    ) || [];
                }

                nrxSeason.irrigated = classifyNRxSeasonIrrigation(currentF, nrxSeason);

                return {
                  ...currentS,
                  nrx: {...nrxSeason, ...convertNRxSeasonValues(nrxSeason, true)},
                };
              })
            : currentF.Seasons,
        };
      }
      return currentF;
    });
  });
};

export const getAllNrxFieldsSeasons = () => {
  const state = hackGetState();
  const {
    map: {fields},
  } = state;
  return fields.reduce((result: Array<any>, field: Field) => {
    if (Array.isArray(field.Seasons)) {
      return [...result, ...field.Seasons.map((s: Season) => s.nrx || {})];
    }
    return result;
  }, []);
};

export const checkForPreparedSeasons = (seasonsIds: Array<number> | number, seasonData: any) => {
  return Array.isArray(seasonsIds)
    ? getAllNrxFieldsSeasons()
        .filter((s: NRxSeason) => seasonsIds.includes(s.seasonID))
        .every((s: NRxSeason) => isAllNrxSeasonPropsSet(s, seasonData))
    : isAllNrxSeasonPropsSet(getNrxSeason(seasonsIds), seasonData);
};

export const isAllNrxSeasonPropsSet = (season: any, seasonData: any) => {
  const seasonToCheck = {...season, ...seasonData};
  return (
    season.nrxSeasonID ||
    [
      'sowingDensity',
      'sowingDepth',
      'rowSpacing',
      'soilTypeID',
      'cropVarietyID',
      'prevCropTypeID',
      'prevHarvestDate',
      'soilTypeLabelID',
    ].every(prop => seasonToCheck[prop])
  );
};

export const checkSeasonStatus = (season: NRxSeason, action?: any, infoBlock = false) => {
  if (!season) return {};
  const field = getFieldById(season.kmlID);
  const allPropertiesSet = Boolean(
    isSetNrxSeasonCropMatch(season) &&
      isSetNrxSeasonSowingData(season) &&
      field.fertilizerApplications?.length
  );
  const sowingDataMissing = isSetNrxSeasonCropMatch(season) && !isSetNrxSeasonSowingData(season);
  const fertilizerDataMissing =
    isSetNrxSeasonCropMatch(season) &&
    isSetNrxSeasonSowingData(season) &&
    !field.fertilizerApplications?.length;

  const pleaseAddSettings = action ? (
    <span>
      , please{' '}
      <span onClick={action} className={'global-link'}>
        add
      </span>
    </span>
  ) : null;

  const InfoBlockContainer = ({
    text,
    action = true,
    appearance = 'warning',
  }: {
    text: string;
    action?: boolean;
    appearance?: AppearanceValues;
  }) => {
    return (
      <InfoBlock className={'nrx-season-status tab-info-block'} appearance={appearance}>
        {text}
        {action && pleaseAddSettings}
      </InfoBlock>
    );
  };

  const RegularStatusContainer = ({
    text,
    action = true,
    icon,
  }: {
    text: string;
    action?: boolean;
    icon: React.ReactElement;
  }) => {
    return (
      <div className={'season-status'}>
        {icon}
        {text}
        {action && pleaseAddSettings}
      </div>
    );
  };

  if (sowingDataMissing)
    return infoBlock ? (
      <InfoBlockContainer text={'Missing soil settings'} />
    ) : (
      <RegularStatusContainer
        text={'Missing soil settings'}
        icon={<FluroStatusIcon status={'yellow'} />}
      />
    );

  if (fertilizerDataMissing)
    return infoBlock ? (
      <InfoBlockContainer text={'Missing fertiliser application'} />
    ) : (
      <RegularStatusContainer
        text={'Missing fertiliser application'}
        icon={<FluroStatusIcon status={'yellow'} />}
      />
    );

  if (!allPropertiesSet)
    return infoBlock ? (
      <InfoBlockContainer text={'Missing settings'} />
    ) : (
      <RegularStatusContainer
        text={'Missing settings'}
        icon={<FluroStatusIcon status={'yellow'} />}
      />
    );

  if (allPropertiesSet && !season.recommendationDates.length)
    return infoBlock ? (
      <InfoBlockContainer text={'Processing'} appearance={'processing'} action={false} />
    ) : (
      <RegularStatusContainer
        text={'Processing'}
        icon={<FluroStatusIcon status={'gray'} />}
        action={false}
      />
    );

  if (allPropertiesSet && season.recommendationDates.length)
    return infoBlock ? null : (
      <div className={'season-status'}>
        <FluroStatusIcon status={'green'} />
        <span>
          Results ready {!action && <br />}{' '}
          <span className={'date'}>{`${moment(
            getLastAvailableRecommendationDate(season.recommendationDates),
            GLOBAL_FORMAT_DATE
          ).format(formatDate())}`}</span>
        </span>
      </div>
    );

  return infoBlock ? (
    <InfoBlockContainer text={'Missing settings'} />
  ) : (
    <RegularStatusContainer
      text={'Missing settings'}
      icon={<FluroStatusIcon status={'yellow'} />}
    />
  );
};

const checkSeasons = (season: any, checkFunc: any) => {
  if (typeof season === 'object' && !Array.isArray(season)) return checkFunc(season);
  // single season
  else if (typeof season === 'number') return checkFunc(getNrxSeason(season));
  // single season id
  else if (Array.isArray(season) && season.every(s => typeof s === 'number'))
    return season.every(s => checkFunc(getNrxSeason(s)));
  // array of season ids
  else if (Array.isArray(season) && season.every(s => typeof s === 'object'))
    return season.every(s => checkFunc(s)); // array of seasons
};

export const isSetNrxSeasonCropMatch = (season: NRxSeason | number[]) => {
  return checkSeasons(season, (season: NRxSeason) => season.nrxSeasonID || season.cropVarietyID);
};

export const isSetNrxSeasonSowingData = (season: any) => {
  const checkFunc = (season: any) =>
    season.nrxSeasonID ||
    [
      'sowingDensity',
      'sowingDepth',
      'rowSpacing',
      'soilTypeID',
      'prevCropTypeID',
      'prevHarvestDate',
      'soilTypeLabelID',
    ].every(prop => season[prop]);

  return checkSeasons(season, checkFunc);
};

export const getPropertiesFromSeasons = (seasons: Array<any> = []) => {
  const result: any = {};
  if (Array.isArray(seasons)) {
    if (seasons.length === 1) return seasons[0];
    seasons.forEach((season, i, arr) => {
      Object.keys(season).forEach(key => {
        result[key] = result[key]
          ? result[key].includes(season[key])
            ? result[key]
            : [...result[key], season[key]]
          : [season[key]];

        if (arr.length === i + 1 && result[key].length === 1) {
          result[key] = result[key][0];
        }
      });
    });
  }
  return result;
};

export const getNrxCropTypeBySubtype = (varietyId: number) => {
  const state: any = hackGetState();
  const {
    map: {
      listsData: {cropTypesList},
    },
  } = state;
  const foundCropType = cropTypesList.find((cropType: any) =>
    cropType.variety.find((v: any) => v.value === varietyId)
  );
  return foundCropType || {};
};

export const getNrxCropSubTypeLabel = (subTypeId: number) => {
  const state = hackGetState();
  const {
    map: {
      listsData: {cropTypesList},
    },
  } = state;
  let varietyLabel = '';
  cropTypesList.forEach((cropType: any) =>
    cropType.variety.forEach(
      (v: any) => (varietyLabel = v.value === subTypeId ? v.label : varietyLabel)
    )
  );
  return varietyLabel;
};

export const getNrxCropSubTypes = (cropTypeId = 0) => {
  const state: any = hackGetState();
  const {
    map: {
      listsData: {cropTypesList},
    },
  } = state;
  const foundCropType = cropTypesList.find((cropType: any) => cropType.value === cropTypeId);
  return foundCropType ? foundCropType.variety : [];
};

export const getNrxSowingDepthList = (
  soilTypeId = '',
  isAustralian = false
): {value: number; label: string}[] => {
  const state: any = hackGetState();
  const {
    map: {
      listsData: {soilTypesRawList},
    },
  } = state;
  const preparedList = soilTypesRawList
    .filter((soilT: any) => soilT.type === soilTypeId)
    .map((t: any) => ({
      value: t.id,
      label: t.depth,
    }));
  if (isAustralian) {
    preparedList.push({
      value: -1,
      label: 'Automatically calculated',
    });
  }

  return preparedList;
};

export const getSoilTypeLabelBySoilDepth = (soilDepthId: number) => {
  const state: any = hackGetState();
  const {
    map: {
      listsData: {soilTypesRawList},
    },
  } = state;
  const result = soilTypesRawList.find((t: any) => t.id === soilDepthId);
  return result ? result.type : '';
};
export const getSoilTypeIdByLabel = (label: string) => {
  const state: any = hackGetState();
  const {
    map: {
      listsData: {soilTypesRawList},
    },
  } = state;
  const result = soilTypesRawList.find((t: any) => t.type === label);
  return result ? result.id : 0;
};

export const getNrxCropTypeLabel = (id: number) => {
  const state: any = hackGetState();
  const {
    map: {
      listsData: {cropTypesList},
    },
  } = state;
  const cropType = cropTypesList.find((c: any) => c.value === id);
  return cropType ? cropType.label : '';
};

export const getNrxFertilizerListItemData = (key: number | string): TNrxFertilizerListItem => {
  const state = hackGetState();
  const {
    map: {
      listsData: {fertilizerTypes},
    },
  } = state;
  return fertilizerTypes.find(f => (typeof key === 'number' ? f.typeID === key : f.type === key));
};

export const getNrxSeason = (seasonId: number, customFields?: Field[]): NRxSeason => {
  try {
    const state: any = hackGetState();
    const {
      map: {fields},
    } = state;
    let season = defaultNrxSeason;
    (customFields || fields).forEach((f: Field) =>
      f.Seasons.forEach((s: Season) => {
        if (s.nrx && s.nrx.seasonID === seasonId) season = s.nrx;
      })
    );
    return season;
  } catch (err) {
    return defaultNrxSeason;
  }
};

export const prepareSeasonUpdateRequests = (
  seasonId: number | number[],
  data: Partial<NRxSeason>
) => {
  const seasons = getAllNrxFieldsSeasons();
  if (Array.isArray(seasonId)) {
    return seasonId.map((id: number) => {
      const season = seasons.find((s: any) => s.seasonID === id);
      const payloadData = convertNRxSeasonValues({...season, ...data});
      return isNrxSeasonExist(season)
        ? NrxApi.updateSeasonNrxData(payloadData)
        : NrxApi.setSeasonNrxData(payloadData);
    });
  }
  const season = seasons.find((s: any) => s.seasonID === seasonId);
  const payloadData = convertNRxSeasonValues({...season, ...data});
  return [
    isNrxSeasonExist(season)
      ? NrxApi.updateSeasonNrxData(payloadData)
      : NrxApi.setSeasonNrxData(payloadData),
  ];
};

export const isNrxSeasonExist = (s: any) => {
  return s && s.nrxSeasonID;
};

export const getPrevSeasonMaxHarvestDate = (
  seasonStartDate: Array<string> | string,
  doNotSubtract: boolean = false
) => {
  const latestDate = moment(
    Array.isArray(seasonStartDate)
      ? seasonStartDate.reduce(
          (latestDate: string, date: string) =>
            moment(latestDate, GLOBAL_FORMAT_DATE).isAfter(moment(date, GLOBAL_FORMAT_DATE))
              ? latestDate
              : date,
          seasonStartDate[0]
        ) // get latest day from array
      : seasonStartDate
  );

  return doNotSubtract
    ? latestDate.format(GLOBAL_FORMAT_DATE)
    : latestDate.subtract(1, 'day').format(GLOBAL_FORMAT_DATE);
};

export const getLastAvailableRecommendationDate = (dates: Array<string> = []): string => {
  return dates.reduce(
    (lastDate: string, d) =>
      !lastDate || moment(lastDate, GLOBAL_FORMAT_DATE).isBefore(moment(d, GLOBAL_FORMAT_DATE))
        ? d
        : lastDate,
    ''
  );
};

export const getNrxDefaultSowingPropsByCropID = (seasonId: number | number[], cropID: number) => {
  const isCropTypeChanged = Array.isArray(seasonId)
    ? seasonId.some(sId => getNrxSeason(sId).cropID !== cropID)
    : getNrxSeason(seasonId).cropID !== cropID;
  return isCropTypeChanged ? defaultSowingParams(cropID) : {};
};

export const prepareApplication = (application: FertilizerApplication, measurement: string) => {
  const {
    date,
    seasonID,
    fieldID,
    quantity,
    option,
    typeID,
    nitrogenPercentage,
    nrxSeasonID,
  } = application;
  const isCustom = typeID === 0;
  const result = {
    date,
    seasonID,
    fieldID,
    quantity: measurement === 'ha' ? quantity : convertFertilizerValues(application).quantity,
    option,
    nitrogenPercentage,
    [isCustom ? 'nitrogenPercentage' : 'typeID']: isCustom ? nitrogenPercentage : typeID, // if it is custom product ({type: Other, typeID: 0}) send nitrogenPercentage otherwise send onl;y typeID
  };
  if (application.id) result.id = application.id;
  if (!isCustom) result.typeID = typeID;
  if (nrxSeasonID) result.nrxSeasonID = nrxSeasonID;

  return result;
};

/**
 * areFieldsInAustralia check are fields are in Australia
 * @param ids = field ids to check
 */

export const areFieldsInAustralia = (ids: number[]) => (
  dispatch: any,
  getState: () => AppStore
) => {
  const {fields} = getState().map;
  return !!ids.every(id => fields.find((f: Field) => f.ID === id && f.Country === 'Australia'));
};
/// APSIM Crop types
const currentlyAllowedCropTypes = ['wheat', 'potato', 'cotton', 'sorghum', 'maize', 'barley'];
const currentlyAllowedPrevCropTypes = [
  'barley',
  'canola',
  'chickpea',
  'irrigated cotton',
  'rainfed cotton',
  'maize',
  'mungbean',
  'oats',
  'peanut',
  'potato',
  'sorghum',
  'wheat',
  'other',
];

export const getCropPriceUnit = (cropID: number) => {
  const cropLabel = getNrxCropLabelById(cropID);
  switch (cropLabel) {
    case 'Wheat':
    case 'Maize':
    case 'Sorghum':
    case 'Barley':
      return 'bu';
    case 'Potato':
      return 'cwt';
    case 'Cotton':
      return 'lb';

    default:
      return 'T';
  }
};

const covertCropPriceFromUS = (cropID: number, priceValue: number) => {
  let resultPrice = priceValue;
  const cropLabel = getNrxCropLabelById(cropID);
  if (['Wheat', 'Barley', 'Maize', 'Sorghum'].includes(cropLabel)) {
    const lb_per_bu = cropLabel === 'Wheat' ? 60 : cropLabel === 'Barley' ? 48 : 58;
    resultPrice = priceValue * (1000 / (lb_per_bu * 0.453592));
  } else if (cropLabel === 'Potato') {
    resultPrice = priceValue * 22.0462;
  } else if (cropLabel === 'Cotton') {
    resultPrice = priceValue * 2204.62;
  }
  return toFixedFloat(resultPrice);
};

const covertCropPriceToUS = (cropID: number, priceValue: number) => {
  let resultPrice = priceValue;
  const cropLabel = getNrxCropLabelById(cropID);
  if (['Wheat', 'Barley', 'Maize', 'Sorghum'].includes(cropLabel)) {
    const lb_per_bu = cropLabel === 'Wheat' ? 60 : cropLabel === 'Barley' ? 48 : 58;
    resultPrice = priceValue / (1000 / (lb_per_bu * 0.453592));
  } else if (cropLabel === 'Potato') {
    resultPrice = priceValue / 22.0462;
  } else if (cropLabel === 'Cotton') {
    resultPrice = priceValue / 2204.62;
  }
  return toFixedFloat(resultPrice, 0);
};

enum NRxCropTypes {
  Maize = 1,
  Sorghum = 2,
  Cotton = 3,
  Potato = 4,
  Wheat = 5,
  Other = 13,
  Barley = 14,
}

const NrxCropTypeLabels: {[key: number]: string} = {
  // data is taken form api/v1/app/nrx/crop/
  [NRxCropTypes.Maize]: 'Maize', // Corn
  [NRxCropTypes.Sorghum]: 'Sorghum',
  [NRxCropTypes.Cotton]: 'Cotton',
  [NRxCropTypes.Potato]: 'Potato',
  [NRxCropTypes.Wheat]: 'Wheat',
  [NRxCropTypes.Other]: 'Other',
  [NRxCropTypes.Barley]: 'Barley',
};

export const getNrxCropLabelById = (cropId: number) => NrxCropTypeLabels[cropId] || '';

const getDefaultCropPrice = (cropID: number): number => {
  switch (
    cropID // FSB-3012 (comments)
  ) {
    case NRxCropTypes.Maize:
      return 4;
    case NRxCropTypes.Sorghum:
      return 3.5;
    case NRxCropTypes.Cotton:
      return 0.6;
    case NRxCropTypes.Potato:
      return 9;
    case NRxCropTypes.Wheat:
      return 5;
    case NRxCropTypes.Other:
      return 5;
    case NRxCropTypes.Barley:
      return 5;
    default:
      return 5;
  }
};
/// APSIM crop types end  ////
export const checkResponseForHighRoi = (response: NRxResultsResponse) => {
  return Object.keys(response).some(
    (objective: NRxObjectiveType) =>
      response[objective].map.features?.length > 1 ||
      response[objective].map.features[0]?.properties?.val > 0
  );
};

export const getTotalNitrogenFromValue = (
  // calculates total "pure" nitrogen for a zone
  value: number,
  nitrogenPercent: number,
  isLiquid: boolean,
  specificGravity: number,
  measurement: string
) => {
  if (value === 0) return '-';
  let resultValue = (value * (nitrogenPercent / 100)) as any; // get "clear" nitrogen amount

  if (measurement === 'ac' && isLiquid) {
    // make a correction for the US units
    resultValue = resultValue * 9.35396 * 0.892179;
  }

  resultValue = toFixedFloat(isLiquid ? resultValue * specificGravity : resultValue, 0); // add modifications related to specific gravity

  return resultValue;
};

export const convertProductToTon = (
  product: string,
  productValue: number,
  productMeasure: string
) => {
  const {specific_gravity = 1} = getNrxFertilizerListItemData(product);

  switch (productMeasure) {
    case 'kg':
    case 'l':
      return toFixedFloat((productValue * specific_gravity) / 1000, 1);
    case 'lb':
      return toFixedFloat(productValue / 2000, 1);
    case 'gal':
      return toFixedFloat(((productValue * specific_gravity) / 1000) * 1.10231, 1);
    default:
      return toFixedFloat(productValue / 1000, 1);
  }
};

export const classifyYieldGoal = (
  value: number,
  measurement: string,
  cropId: number,
  reverse?: boolean
) => {
  if (!value) return value;

  if (measurement === 'ha') {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
      case NRxCropTypes.Wheat:
      case NRxCropTypes.Barley:
        return reverse ? toFixedFloat(value * 1000, 1) : toFixedFloat(value / 1000, 1);

      case NRxCropTypes.Potato:
        return reverse ? toFixedFloat(value / 0.0197, 0) : toFixedFloat(value * 0.0197, 0);

      case NRxCropTypes.Cotton:
        return toFixedFloat(value, 0);
    }
  } else {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
        return reverse ? toFixedFloat(value / 0.0159, 0) : toFixedFloat(value * 0.0159, 0);

      case NRxCropTypes.Wheat:
        return reverse ? toFixedFloat(value / 0.0149, 0) : toFixedFloat(value * 0.0149, 0);

      case NRxCropTypes.Barley:
        return reverse ? toFixedFloat(value / 0.0186, 0) : toFixedFloat(value * 0.0186, 0);

      case NRxCropTypes.Potato:
        return reverse ? toFixedFloat(value / 0.007966, 0) : toFixedFloat(value * 0.007966, 0);

      case NRxCropTypes.Cotton:
        return reverse ? toFixedFloat(value / 0.892179, 0) : toFixedFloat(value * 0.892179, 0);
    }
  }
};

export const classifyYieldUnits = (measurement: string, cropId: number) => {
  if (measurement === 'ha') {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
      case NRxCropTypes.Wheat:
      case NRxCropTypes.Barley:
        return t({id: 'T/ha'});

      case NRxCropTypes.Potato:
        return t({id: 'cwt/ha'});

      case NRxCropTypes.Cotton:
        return t({id: 'kg/ha'});
    }
  } else {
    switch (cropId) {
      case NRxCropTypes.Maize:
      case NRxCropTypes.Sorghum:
      case NRxCropTypes.Wheat:
      case NRxCropTypes.Barley:
        return t({id: 'bu/ac'});

      case NRxCropTypes.Potato:
        return t({id: 'cwt/ac'});

      case NRxCropTypes.Cotton:
        return t({id: 'lb/ac'});
    }
  }
};

export const NRxAutomaticallyChangedROISettingsMessage = (
  prevROIValue: number,
  currentROIValue: number,
  onToggleNRxSettingsPopUp: () => void
) => (
  <FormattedMessage
    id="NRxAutomaticallyChangedROISettingsMessage"
    defaultMessage="We couldn’t find a {prevRiskLabel} risk nitrogen application with <span>these settings.</span> {currentRiskLabel} risk application is shown instead."
    values={{
      prevRiskLabel: riskLevelToLabel(prevROIValue).toLowerCase(),
      currentRiskLabel: riskLevelToLabel(currentROIValue),
      span: (txt: string) => (
        <span className={'global-link'} onClick={onToggleNRxSettingsPopUp}>
          {txt}
        </span>
      ),
    }}
  />
);

/**
 * if the irrigated prop is set - use it, otherwise calc default value - FSB-4652
 */
export const classifyNRxSeasonIrrigation = (field: Field, season: NRxSeason) => {
  return season.nrxSeasonID && season.irrigated !== undefined
    ? season.irrigated
    : field.Pivot || season.cropID === NRxCropTypes.Potato;
};
