import {t, FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import {useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import NotificationSystem, {System} from 'react-notification-system';
import {AppStore} from 'reducers';

export default () => {
  const notificationSystemRef = useRef<System>();

  const alertsList = useSelector((store: AppStore) => store.alertsList);

  useEffect(() => {
    alertsList.forEach(a => {
      // Message is used as uid to avoid showing duplicate messages.
      notificationSystemRef.current.addNotification({...a, uid: String(a.message)});
    });
  }, [alertsList]);

  return <NotificationSystem ref={notificationSystemRef} />;
};
