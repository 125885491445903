import Server from './server';
import {UserSettings} from 'containers/admin/users/types';

export default class {
  static getProfile() {
    return Server.get('profile');
  }

  static update(data: UserSettings) {
    // TODO this endpoint is used to update settings, need to split this functionalities
    return Server.put('profile', data);
  }

  static updatePassword(data: any) {
    return Server.put('profile/updatePassword', data);
  }

  static updateProfileSettings(settings: UserSettings) {
    return Server.put('profile', settings);
  }

  static updateDemoFarmsList(demoFarms: number[]) {
    return Server.put('demoFarms', demoFarms);
  }

  static accessRoles(id: number) {
    return Server.get(`users/${id}/access_roles`);
  }
}
