import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import './index.scss';

import UserList from './list';
import {UserEdit} from './edit/user-edit';

type Props = RouteComponentProps;

const Users = ({match}: Props) => {
  return (
    <div className="admin__container">
      <Route exact path={match.path} component={UserList} />
      <Route exact path={`${match.path}/:userId`} component={UserEdit} />
    </div>
  );
};

export default Users;
