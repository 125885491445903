import * as React from 'react';
import {useState} from 'react';
import {FontIcon} from 'react-md';
import styled from 'styled-components';

export const AdoptionContainer = ({children, title}: React.PropsWithChildren<{title: string}>) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div style={{marginTop: 10}}>
      <Title onClick={() => setExpanded(e => !e)}>
        {title} adoption{' '}
        <FontIcon
          style={{transition: 'transform 0.2s', transform: expanded ? 'rotate(90deg)' : ''}}
        >
          keyboard_arrow_right
        </FontIcon>
      </Title>
      {expanded && <div style={{height: 100, marginBottom: 10}}>{children}</div>}
    </div>
  );
};

const Title = styled.h4`
  display: flex;
  align-items: center;
`;
