import Server from './server';

type FarmPayload = {
  farm_id: number;
  organization_id: number;
};

export default class {
  static loadSubscriptions() {
    return Server.get('subs');
  }

  static loadOrganizations(subsId?: number) {
    return Server.get(subsId ? `subs/${subsId}/orgs` : `orgs`);
  }

  static loadFarms(orgId?: number) {
    return Server.get(orgId ? `orgs/${orgId}/farms` : 'farms/access_metrics');
  }

  static createOrganization(subscriptionId: number, data: any) {
    return Server.post(`subs/${subscriptionId}/orgs`, data);
  }

  static updateOrganization(organizationId: number, data: any) {
    return Server.put(`orgs/${organizationId}`, data);
  }

  static createSubscription(data: any) {
    return Server.post(`subs`, data);
  }

  static updateSubscription(subscriptionId: number, data: any) {
    return Server.put(`subs/${subscriptionId}`, data);
  }

  static addFarm(farms: FarmPayload[]) {
    return Server.put(`farms/orgs`, farms);
  }

  static loadUsers(entityType: string, entityId: number) {
    return Server.get(`${entityType}/${entityId}/access`);
  }

  static updateUserAccess(userId: number, accessRoles: any) {
    return Server.post(`users/${userId}/access_roles`, accessRoles);
  }

  static deleteUserAccess(userId: number, accessRoles: any) {
    return Server.delete(`users/${userId}/access_roles`, {data: accessRoles});
  }

  static getUserAccess(userId: number) {
    return Server.get(`users/${userId}/access_roles`);
  }

  static loadAllOrganizations() {
    return Server.get(`orgs`);
  }
}
