import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';

import {setSensor} from '../actions';
import {setZoning, loadZoningData, toggleNRecommendation} from '../actions/zoning-actions';
import {getLayerImageUrl} from 'containers/map/utils/utils';
import {RegularZoningMethods, TreesZoningMethods} from '../features/zoning/zoning-constants';
import {usePrevious} from '../../../_hooks';
import {IZoning} from '../types';

/**
 * The component doesn't have any rendering, it exists only to handle when to call the analytic request.
 */
const ZoningEffects = (): null => {
  const [shouldLoadZoning, setShouldLoadZoning] = useState(false);
  const dispatch = useDispatch();

  // store props
  const nRecommendation = useSelector((state: AppStore) => state.map.nRecommendation);
  const feature = useSelector((state: AppStore) => state.map.feature);
  const zoning = useSelector((state: AppStore) => state.map.zoning);
  const currentSensor = useSelector((state: AppStore) => state.map.currentSensor);
  const farm = useSelector((state: AppStore) => state.map.group);
  const currentImageName = getLayerImageUrl();
  const layerType = useSelector((state: AppStore) => state.map.treeDetection.layerType);
  const wholeFarm = useSelector((state: AppStore) => state.map.wholeFarm);
  const userSavedZoning = useSelector(
    (state: AppStore) => state.login.user.settings.zoning.favoriteZoningMethod?.[farm.id]
  );
  const isTreeAnalysis = layerType !== 'default';
  const prev = usePrevious({nRecommendation, farm});
  useEffect(() => {
    if (shouldLoadZoning && currentImageName && feature === 'zoning' && !nRecommendation.toggled) {
      setShouldLoadZoning(false);
      setTimeout(() => dispatch(loadZoningData(true)), 1000); // for some reason the loadZoning is called before the state is updated
    }
  }, [shouldLoadZoning, currentImageName, feature, nRecommendation]);

  useEffect(() => {
    if (feature === 'zoning') {
      checkFavoriteZoningMethod();
      if (currentSensor === 'TCI' || currentSensor === 'NC') dispatch(setSensor('CCCI'));
    }
  }, [feature]);

  useEffect(
    function NRxRelatedEffects() {
      if (feature === 'zoning') {
        const {
          toggled: NRxToggled,
          method,
          nrxResult,
          nrxTabRate,
          selectedObjective,
        } = nRecommendation;

        if (NRxToggled && method === 'apsim' && !nrxResult[nrxTabRate][selectedObjective].type) {
          dispatch(toggleNRecommendation(true, 'apsim'));
        }

        if (!zoning.zones.length && !NRxToggled && prev?.nRecommendation?.toggled) {
          // load zoning if it wan't loaded because of n-recommendation enabled
          setShouldLoadZoning(true);
        }
      }
    },
    [feature]
  );

  useEffect(
    function handleLayerTypeChanges() {
      if (isTreeAnalysis) {
        const method = 'Intervals';
        if (zoning.method !== method) dispatch(setZoning({method: method}));
      }
    },
    [isTreeAnalysis]
  );

  useEffect(
    function handleTreesZoning() {
      if (feature === 'zoning' && !currentMethodFits) {
        dispatch(setZoning({method: isTreeAnalysis ? 'Intervals' : RegularZoningMethods[0].value}));
        setShouldLoadZoning(true);
      }
      // else if (
      //   feature === 'zoning' &&
      //   wholeFarm.isWholeFarmView &&
      //   !Object.keys(wholeFarm.treeZoning.fields).length
      // ) {
      //   console.log('THE NEW CASE');
      //   setShouldLoadZoning(true);
      // }
    },
    [feature]
  );

  const currentMethodFits = (isTreeAnalysis ? TreesZoningMethods : RegularZoningMethods).find(
    m => m.value === zoning.method // check is current selected method fits for the zoning methods according to the zoning type (regular, tree)
  );
  useEffect(
    function handleLayerChange() {
      if (currentImageName) {
        if (!currentMethodFits) {
          //WARNING: isTreeAnalysis is not at deps ???
          dispatch(setZoning({method: isTreeAnalysis ? 'Records' : RegularZoningMethods[0].value}));
        }
        setShouldLoadZoning(true);
      }
    },
    [currentImageName]
  );

  const checkFavoriteZoningMethod = () => {
    if (
      userSavedZoning?.method &&
      Object.keys(userSavedZoning).some(
        // check if the values are different do prevent setting the same
        (key: keyof IZoning) => zoning[key] !== userSavedZoning[key]
      ) &&
      !zoning.selectedByUser
    ) {
      dispatch(
        setZoning({
          ...userSavedZoning,
          isFavoriteMethod: true,
        })
      );
      setShouldLoadZoning(true);
    }
  };
  useEffect(
    function setUserSavedZoningMethod() {
      if (feature === 'zoning' && prev?.farm?.id !== farm.id) {
        checkFavoriteZoningMethod();
      }
    },
    [farm, feature]
  );

  return null;
};

export default ZoningEffects;
