import {t, FormattedMessage} from 'i18n-utils';
import React, {PureComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {FontIcon, ListItem, MenuButton} from 'react-md';
import {ReadOnly, FluroButton} from 'components';
import axios from 'axios';
import {DialogType, toggleDialog as toggleGlobalDialog} from '_reducers/dialog';

import config from '_environment';
import {deleteBulkSeason, removeField} from '../../../actions';
import {DownloadIcon, UploadIcon} from '../../../icons';
import {showNote} from '_actions';
import {downloadFile} from '_utils';
import {CloneFieldPopup} from './clone-field';
import EditBulkSeason from './pop-ups/edit-bulk-season';
import {FieldTag, IInitialMapState, Season} from 'containers/map/types';
import {GlobalState} from '_reducers/global_types';
import {LoginState} from '../../../../login/types';
import {dialogToggle, DialogType as HelpersDialogType} from 'modules/ui-helpers';

type Props = ConnectedProps<typeof connector> & {
  togglePopUp: (key: string, value: boolean, seasonId?: number) => void;
  selectedSeasons: Season[];
  selectedNRXSeasons: any[];
};

type State = {
  deleteDialogOpen: boolean;
  isOpenClonePopup: boolean;
  isOpenCropPopUp: string;
};

class ButtonsPanel extends PureComponent<Props> {
  state: State = {
    deleteDialogOpen: false,
    isOpenClonePopup: false,
    isOpenCropPopUp: '',
  };

  getSeasonsButtonsForPanel = () => {
    const {togglePopUp, fields, selectedSeasons, isReadOnly} = this.props;
    const hasNrxField = fields.filter(f => f.tags && f.tags.includes(FieldTag.NRx)).length;
    const isDisabled = !selectedSeasons.length || isReadOnly;
    const selectedNRXSeasons = selectedSeasons.filter(s => s.nrx);
    return (
      <div className={'season-buttons'}>
        <span className={'category-name'}>{t({id: 'Selected crops:'})} </span>
        {hasNrxField ? (
          <MenuButton
            id="nrx-menu-button"
            className={'menu-button btn-with-icon edit-crop-button'}
            raised
            primary
            iconEl={<FontIcon inherit>keyboard_arrow_down</FontIcon>}
            disabled={isDisabled}
            menuItems={[
              <React.Fragment key={'unique-key'}>
                <ListItem
                  key={0}
                  onClick={() => this.toggleDialog('isOpenCropPopUp', 'edit')}
                  primaryText={t({id: 'Crop data'})}
                  className="edit-crop-button__crop-data"
                />
                <ListItem
                  key={1}
                  onClick={() => togglePopUp('matchCropPopUpOpen', true)}
                  disabled={!selectedNRXSeasons.length}
                  primaryText={t({id: 'Crop type variety'})}
                />
                <ListItem
                  key={2}
                  onClick={() => togglePopUp('plantingSoilPopUpOpen', true)}
                  disabled={!selectedNRXSeasons.length}
                  primaryText={t({id: 'Planting / soil parameters'})}
                />
              </React.Fragment>,
            ]}
          >
            <FormattedMessage id="Edit crop" />
          </MenuButton>
        ) : (
          <FluroButton
            primary
            raised
            onClick={() => this.toggleDialog('isOpenCropPopUp', 'edit')}
            iconEl={<FontIcon className={'edit-icon'}>edit</FontIcon>}
            className={'edit-crop-button'}
            disabled={isDisabled}
          >
            <FormattedMessage id="edit crop" />
          </FluroButton>
        )}
        <ReadOnly>
          <FluroButton
            raised
            onClick={() => this.onDeleteCrops()}
            iconEl={<FontIcon>delete</FontIcon>}
            disabled={isDisabled}
          >
            <FormattedMessage id="delete crop" />
          </FluroButton>
        </ReadOnly>
      </div>
    );
  };

  render() {
    const {isReadOnly, isOnBoardingNow, selectedSeasons, selectedFields} = this.props;
    const {length: selectedFieldsAmount} = selectedFields;
    const {deleteDialogOpen, isOpenCropPopUp, isOpenClonePopup} = this.state;
    const fieldsControlsDisabled = !selectedFieldsAmount || isReadOnly;

    return (
      <div className={'fields-table-view__header-buttons'}>
        <ReadOnly>
          <FluroButton
            id="goFile"
            raised
            primary
            onClick={() => this.props.toggleGlobalDialog(DialogType.AddNewField, true)}
            iconEl={<FontIcon>add</FontIcon>}
            className={'add-field'}
            disabled={isReadOnly}
          >
            <FormattedMessage id="Add fields" />
          </FluroButton>
        </ReadOnly>

        <ReadOnly>
          <FluroButton
            id="import-seasons-btn"
            raised
            primary
            onClick={() => this.props.toggleGlobalDialog(DialogType.ImportSeasons)}
            iconEl={<UploadIcon />}
            className={'upload-seasons'}
          >
            <FormattedMessage id="Import crops" />
          </FluroButton>
        </ReadOnly>

        <span className={'category-name'}>{t({id: 'Selected fields'})}: </span>

        <ReadOnly>
          <FluroButton
            raised
            primary
            onClick={() => this.toggleDialog('isOpenCropPopUp', 'add')}
            iconEl={<FontIcon>add</FontIcon>}
            className={'on-boarding-add-bulk-crop'}
            disabled={fieldsControlsDisabled && !isOnBoardingNow}
          >
            <FormattedMessage id="Add crop" />
          </FluroButton>
        </ReadOnly>

        {/*<ReadOnly>*/}
        {/*  <Button*/}
        {/*    raised*/}
        {/*    onClick={() => this.toggleDialog('deleteDialogOpen', true)}*/}
        {/*    iconEl={<FontIcon>delete</FontIcon>}*/}
        {/*    className={'btn-with-icon delete-field-button'}*/}
        {/*    disabled={fieldsControlsDisabled}*/}
        {/*  >*/}
        {/*    {t({id: 'delete field'})}*/}
        {/*  </Button>*/}
        {/*</ReadOnly>*/}

        {/*<ReadOnly>*/}
        {/*  <Button*/}
        {/*    raised*/}
        {/*    secondary*/}
        {/*    onClick={() => this.downloadZip()}*/}
        {/*    disabled={!selectedFieldsAmount || isReadOnly}*/}
        {/*    iconEl={<DownloadIcon />}*/}
        {/*    className="btn-with-icon"*/}
        {/*  >*/}
        {/*    Kml*/}
        {/*  </Button>*/}
        {/*</ReadOnly>*/}

        <ReadOnly>
          <MenuButton
            id="edit-field-settings"
            raised
            primary
            className="dots-button"
            disabled={!selectedFieldsAmount || isReadOnly}
            menuItems={[
              <ListItem
                key={1}
                onClick={() => this.toggleDialog('isOpenClonePopup', true)}
                leftIcon={<FontIcon>content_copy</FontIcon>}
                primaryText="Copy field"
              />,
              <ListItem
                key={3}
                onClick={() => this.downloadZip()}
                leftIcon={<DownloadIcon />}
                primaryText="Kml"
              />,
              <ListItem
                className={'delete-field-btn'}
                key={2}
                leftIcon={<FontIcon>delete</FontIcon>}
                onClick={() =>
                  this.props.dialogToggle(HelpersDialogType.deleteDialog, true, {
                    title: t({id: 'Delete field?'}),
                    onSubmit: this.deleteFields,
                  })
                }
                primaryText={t({id: 'Delete field'})}
              />,
            ]}
          >
            <FontIcon inherit>more_vert</FontIcon>
          </MenuButton>
        </ReadOnly>

        {this.getSeasonsButtonsForPanel()}

        <CloneFieldPopup
          togglePopUp={(v: boolean) => this.toggleDialog('isOpenClonePopup', v)}
          visible={isOpenClonePopup}
        />

        <EditBulkSeason
          selectedSeasons={selectedSeasons}
          visible={!!isOpenCropPopUp}
          type={isOpenCropPopUp}
          onDeleteCrops={() => this.onDeleteCrops()}
          onClose={() => this.toggleDialog('isOpenCropPopUp', '')}
        />
      </div>
    );
  }

  deleteFields = () => {
    const fIDs = this.props.fields.filter((el: any) => el._selected).map((el: any) => el.ID);
    this.props.removeField(this.props.groupId, fIDs).catch(err => console.warn(err));
  };

  toggleDialog = (dialog: keyof State, value: boolean | string) =>
    this.setState<never>({
      [dialog]: value,
    });

  downloadZip() {
    const md5s = this.props.fields.filter((el: any) => el._selected).map((el: any) => el.MD5);
    const form = new FormData();
    //@ts-ignore
    form.set('md5', md5s.join(','));

    axios({
      method: 'post',
      url: `${config.baseUrl}api/v1/files/kml/${
        this.props.groupId
      }/download?name=Boundary_${encodeURIComponent(this.props.groupName)}`,
      headers: {[config.authHeader]: localStorage.getItem('token') || ''},
      data: form,
      responseType: 'blob',
    })
      .then(({data}) => {
        downloadFile(data, `Boundary_${this.props.groupName}.zip`, 'application/zip');
      })
      .catch(err => {
        this.props.showNote({
          title: t({id: 'note.error', defaultMessage: 'Error'}),
          message: t({id: 'Cannot download KMLs'}),
          level: 'error',
        });

        console.warn(err);
      });

    if ((window as any).Cypress) {
      setTimeout(() => window.location.reload(), 3000);
    }
  }

  onDeleteCrops() {
    const {groupId, selectedSeasons, fields, deleteBulkSeason} = this.props;
    if (
      window.confirm(
        t({id: 'Are you sure you want to delete crop?'}, {count: selectedSeasons.length})
      )
    ) {
      deleteBulkSeason(groupId, selectedSeasons, fields).then(() =>
        this.toggleDialog('isOpenCropPopUp', '')
      );
    }
  }
}

const mapStateToProps = ({
  global,
  map,
  login,
}: {
  global: GlobalState;
  map: IInitialMapState;
  login: LoginState;
}) => ({
  groupId: global.currentGroupId,
  groupName: map.group.name,
  fields: map.fields,
  selectedFields: map.fields.filter(f => f._selected),
  isReadOnly: map.group.readOnly,
  isOnBoardingNow: login.user.settings.onboarding.quickTour,
});

const connector = connect(mapStateToProps, {
  removeField,
  showNote,
  deleteBulkSeason,
  toggleGlobalDialog,
  dialogToggle,
});

export default connector(ButtonsPanel);
