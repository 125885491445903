import styled from 'styled-components';

export const Wrapper = styled.div`  
  .g-name {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    margin-bottom: 10px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }

  .md-chip {
    margin-right: 2px;
    margin-bottom: 2px;
    background: #f1f1f1;
  }
`;
