import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Users from './users';
import './index.scss';
import NavPanel, {TabObj} from 'components/right-nav-panel';
import Routes from './routes';
import {
  UsersCircleIcon,
  StartIcon,
  EnvelopeIcon,
  MarkListIcon,
  ChartIcon,
  EmailLogsIcon,
  DashboardIcon,
  ReportsIcon,
  FieldLookupIcon,
  CropIcon,
  SatelliteHRIcon,
} from 'containers/map/icons/';
import useEmailToReviewNotification from 'containers/admin/use-email-to-review-notification';

/*
 *
 * Tabs configuration object
 *
 * */

const configTabs: TabObj[] = [
  {
    title: 'Users',
    link: 'admin/users',
    icon: <UsersCircleIcon />,
  },
  {
    title: 'Premium Apps',
    link: 'admin/features',
    icon: <StartIcon className={'star-icon'} />,
    exact: true,
  },
  {
    title: 'Email Activation',
    link: 'admin/features/emails',
    icon: <EnvelopeIcon className={'envelope-icon'} />,
  },
  {
    title: 'Subscriptions',
    link: 'admin/subscriptions',
    icon: <MarkListIcon />,
  },
  {
    title: 'User Activity',
    link: 'admin/user-activity',
    icon: <ChartIcon className={'chart-icon'} />,
  },
  {
    title: 'Email Logs',
    link: 'admin/email-logs',
    icon: <EmailLogsIcon className={'logs-icon'} />,
    exact: true,
  },
  {
    title: 'Dashboard',
    link: 'admin/dashboard/crop-stress',
    icon: <DashboardIcon />,
    exact: false,
  },
  {
    title: 'Reports',
    link: 'admin/dashboard/reports',
    icon: <ReportsIcon className={'reports-icon'} />,
  },
  {
    title: 'Fields Lookup',
    link: 'admin/fields-lookup',
    icon: <FieldLookupIcon className={'lookup-icon'} />,
  },
  {
    title: 'Crop types manager',
    link: 'admin/crop/edit-crop',
    icon: <CropIcon className={'crop-icon'} />,
  },
  {
    title: 'Order high resolution imagery',
    link: 'admin/order-high-resolution-imagery',
    icon: <SatelliteHRIcon className={'satellite-icon'} />,
  },
];

const Admin = ({location}: RouteComponentProps) => {
  useEmailToReviewNotification();

  return (
    <div className={'admin-panel'}>
      <Routes />
      <NavPanel locationPathname={location.pathname} configTabs={configTabs} />
    </div>
  );
};

export const rowsPerPageItems: any[] = [
  {label: 10, value: 10},
  {label: 50, value: 50},
  {label: 100, value: 100},
  {label: 500, value: 500},
];
export const calcRowsPerPageItems = (totalItems: number) => {
  const _r = rowsPerPageItems.filter(el => el.value < totalItems || el.value === 50);

  if (totalItems <= 1000) {
    return [..._r, {label: 'All', value: totalItems}];
  }

  return _r;
};

export const rowsPerPage = 50;

export default Admin;
