import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {reportError} from 'containers/error-boundary';
import {SamplingPoint, SourceType} from '../containers/map/types';
import {TSensor} from '../types';
import colors from '../variables.scss';

type LabelObj = {
  value: string;
  label: string;
  icon?: string;
  subTypeId?: number | string;
  anomalyType?: number | string;
  menuListId: number;
};

export const MAPPING_ROLES_TO_PROPS: {[key: string]: any} = {
  '0': {prop: 'user', access: 0},
  '1': {prop: 'agronomist', access: 1},
  '2': {prop: 'farmer', access: 2},
  '3': {prop: 'admin', access: 3},
};

export const GLOBAL_FORMAT_DATE = 'YYYY-MM-DD';
export const SERVER_FORMAT_DATE = 'YYYYMMDD';
export const SERVER_FORMAT_TIME = 'YYYYMMDDTHHMMSS';
export const GLOBAL_APP_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_DATE_FORMAT = 'DD MMM YYYY';

export const SENSORS: TSensor[] = [
  'MSAVI',
  'NDRE',
  'NDVI',
  'CCCI',
  'TCI',
  'VVI',
  'PGI',
  'DIAMETER',
  'TIRS',
  'EC',
  'ELEVATION',
  'PH',
  'YIELD',
  'N-application',
  'PTIRS',
  'NC',
];

export const RAW_DATA = ['DIAMETER']; // should not allow to download this types

export const COLORS = [
  '#00E0FF',
  '#EC8600',
  '#28EF1A',
  '#9900FF',
  '#FE0229',
  '#056F83',
  '#B49FDA',
  '#E7D000',
  '#1B512D',
  '#FC40A4',
  '#073763',
  '#89043D',
  '#9A6D33',
  '#379D1F',
  '#381005',
  '#00FFB2',
  '#536E7A',
  '#B0FE76',
  '#8D3B72',
];

const OBSERVATION_TYPE_LABELS = (): LabelObj[] => [
  {
    subTypeId: 23,
    anomalyType: 4,
    label: t({id: 'unknown_damage'}),
    value: 'unknown_damage',
    menuListId: 1,
  },
  {
    subTypeId: '',
    anomalyType: '',
    label: t({id: 'emergence_issue'}),
    value: 'emergence_issue',
    menuListId: 1,
  },
  {
    subTypeId: '',
    anomalyType: 4,
    label: t({id: 'irrigation_issue'}),
    value: 'irrigation_issue',
    menuListId: 1,
  },
  {
    subTypeId: 1,
    anomalyType: 4,
    label: t({id: 'hail_damage'}),
    value: 'hail_damage',
    menuListId: 1,
  },
  {
    subTypeId: 2,
    anomalyType: 4,
    label: t({id: 'Wind Damage'}),
    value: 'wind_damage',
    menuListId: 1,
  },
  {
    subTypeId: 3,
    anomalyType: 4,
    value: 'water_logging',
    icon: '/assets/anomaly-labels/water_logging.svg',
    label: t({id: 'water_logging'}),
    menuListId: 1,
  },
  {
    subTypeId: 4,
    anomalyType: 4,
    label: t({id: 'Drought Damage'}),
    value: 'drought_damage',
    menuListId: 1,
  },
  {
    subTypeId: 5,
    anomalyType: 4,
    label: t({id: 'fire_damage'}),
    value: 'fire_damage',
    menuListId: 1,
  },
  {
    subTypeId: 6,
    anomalyType: 4,
    value: 'frost',
    icon: '/assets/anomaly-labels/frost.svg',
    label: t({id: 'frost'}),
    menuListId: 1,
  },
  {
    subTypeId: 8,
    anomalyType: 4,
    label: t({id: 'greensnap'}),
    value: 'greensnap',
    menuListId: 1,
  },
  {
    subTypeId: 9,
    anomalyType: 4,
    label: t({id: 'winterkill'}),
    value: 'winterkill',
    menuListId: 1,
  },
  {
    subTypeId: 10,
    anomalyType: 4,
    label: t({id: 'lodging'}),
    value: 'lodging',
    menuListId: 1,
  },
  {
    subTypeId: 11,
    anomalyType: 4,
    label: t({id: 'animal_damage'}),
    value: 'animal_damage',
    menuListId: 1,
  },
  {
    subTypeId: 14,
    anomalyType: 4,
    label: t({id: 'mechanical_damage'}),
    value: 'mechanical_damage',
    menuListId: 1,
  },
  {
    subTypeId: 15,
    anomalyType: 4,
    label: t({id: 'chemical_damage'}),
    value: 'chemical_damage',
    menuListId: 1,
  },
  {
    subTypeId: 16,
    anomalyType: 4,
    label: t({id: 'drown-out_damage'}),
    value: 'drown-out_damage',
    menuListId: 1,
  },
  {
    subTypeId: 20,
    anomalyType: 4,
    label: t({id: 'compaction_damage'}),
    value: 'compaction_damage',
    menuListId: 1,
  },
  {
    subTypeId: 21,
    anomalyType: 4,
    label: t({id: 'summer_scald_damage'}),
    value: 'summer_scald_damage',
    menuListId: 1,
  },
  {
    subTypeId: 22,
    anomalyType: 4,
    label: t({id: 'heavy_rain_damage'}),
    value: 'heavy_rain_damage',
    menuListId: 1,
  },
  {
    subTypeId: 17,
    anomalyType: 4,
    value: 'pest',
    icon: '/assets/anomaly-labels/pest.svg',
    label: t({id: 'pest'}),
    menuListId: 1,
  },
  {
    subTypeId: '',
    anomalyType: 1,
    label: t({id: 'weed'}),
    value: 'weed',
    icon: '/assets/anomaly-labels/weed.svg',
    menuListId: 1,
  },
  {
    subTypeId: '',
    anomalyType: 3,
    label: t({id: 'disease'}),
    value: 'disease',
    icon: '/assets/anomaly-labels/disease.svg',
    menuListId: 1,
  },
  {
    subTypeId: '',
    anomalyType: 4,
    label: t({id: 'spray_drift'}),
    value: 'spray_drift',
    icon: '/assets/anomaly-labels/spray_drift.svg',
    menuListId: 1,
  },

  {
    subTypeId: '',
    anomalyType: 4,
    label: t({id: 'nutrient_deficiency'}),
    value: 'nutrient_deficiency',
    icon: '/assets/anomaly-labels/nutrient_deficiency.svg',
    menuListId: 1,
  },
  {
    subTypeId: 7,
    anomalyType: 4,
    label: t({id: 'heat_damage'}),
    value: 'heat_damage',
    menuListId: 1,
  },
  {
    subTypeId: 13,
    anomalyType: 4,
    label: t({id: 'sunburn'}),
    value: 'sunburn',
    menuListId: 1,
  },
  {
    label: t({id: 'weed'}),
    value: 'weed',
    anomalyType: 1,
    subTypeId: '',
    menuListId: 3,
  },
  {
    label: t({id: 'insect'}),
    value: 'insect',
    anomalyType: 2,
    subTypeId: '',
    menuListId: 3,
  },
];

// ano labels
export const ANOMALY_LABELS = (): LabelObj[] => [
  ...OBSERVATION_TYPE_LABELS(),
  {
    label: t({id: 'disease'}),
    value: 'disease',
    anomalyType: 3,
    subTypeId: 0,
    menuListId: 3,
  },
  {
    subTypeId: '',
    anomalyType: 4,
    label: t({id: 'permanent_feature'}),
    value: 'permanent_feature',
    menuListId: 4,
  },
  {
    subTypeId: '',
    anomalyType: 4,
    label: t({id: 'soil_issue'}),
    value: 'soil_issue',
    icon: '/assets/anomaly-labels/soil_issue.svg',
    menuListId: 4,
  },

  {
    label: t({id: 'centre_pivot'}),
    value: 'centre_pivot',
    anomalyType: '',
    subTypeId: '',
    menuListId: 4,
  },

  {
    label: t({id: 'farm_boundary'}),
    value: 'farm_boundary',
    anomalyType: '',
    subTypeId: '',
    menuListId: 4,
  },

  {
    label: t({id: 'road'}),
    value: 'road',
    anomalyType: '',
    subTypeId: '',
    menuListId: 4,
  },

  {
    label: t({id: 'trial'}),
    value: 'trial',
    anomalyType: '',
    subTypeId: '',
    menuListId: 5,
  },

  {
    label: t({id: 'management_block'}),
    value: 'management_block',
    anomalyType: '',
    subTypeId: '',
    menuListId: 5,
  },

  {
    label: t({id: 'poor_establishment'}),
    value: 'poor_establishment',
    anomalyType: '',
    subTypeId: '',
    menuListId: 4,
  },

  {
    label: t({id: 'creek'}),
    value: 'creek',
    anomalyType: '',
    subTypeId: '',
    menuListId: 4,
  },
  {
    label: t({id: 'drain'}),
    value: 'drain',
    anomalyType: '',
    subTypeId: '',
    menuListId: 4,
  },

  {
    label: t({id: 'harvested'}),
    value: 'harvested',
    anomalyType: '',
    subTypeId: '',
    menuListId: 5,
  },
];

export const PERMANENT_ANOMALIES = [
  'drain',
  'creek',
  'road',
  'farm_boundary',
  'centre_pivot',
  'soil_issue',
  'management_block',
  'permanent_feature',
];

export const getLabelNameByValue = (val: string): string => {
  if (val === '' || val === 'No Label') {
    return t({id: 'No label'});
  }
  const anomaly = ANOMALY_LABELS().find(l => l.value === val);
  if (!anomaly) {
    reportError(`No anomaly label info found for the value "${val}"`);
    return `${val}`.replace(/_/g, ' ');
  }
  return anomaly.label;
};

export const getAnomalyLabelValue = (value: string): string => {
  if (value === '' || value === 'No Label' || value === 'no label') {
    return '';
  }
  let anomaly = ANOMALY_LABELS().find(l => l.label === value);

  if (!anomaly) {
    anomaly = ANOMALY_LABELS().find(l => l.value === value);
  }

  if (!anomaly) {
    reportError(`No anomaly value info found for the value "${value}"`);
    return `${value}`.replace(/_/g, ' ');
  }
  return anomaly.value;
};

export const getLabelDataByValue = (value: any) => {
  if (value === '') {
    return {
      subTypeId: '',
      anomalyType: 4,
      label: 'Select a label',
      value: '',
    };
  }

  const lbl = ANOMALY_LABELS().find(lbl => lbl.value === value);

  if (lbl) return lbl;

  return {
    subTypeId: '',
    anomalyType: 4,
    label: value,
    value: value,
  };
};

export const ALLOWED_WHOLE_FARM_FEATURES = [
  'farm',
  'data-layers',
  'imagery-home',
  'admin',
  'profile',
  'log-out',
  'crop-performance',
];

export const WHOLE_TABLE_VIEW_FEATURES = ['farm', 'crop', 'nrx', 'crop-performance'];

export const SAMPLING_POINTS_LABELS_TOP: {title: string; icon?: string; menuListId: number}[] = [
  {
    title: 'Sampling point',
    icon: 'sampling-point',
    menuListId: 1,
  },
  {
    title: 'Crop damage',
    icon: 'crop_damage',
    menuListId: 2,
  },
  {
    title: 'Object',
    icon: 'Object',
    menuListId: 3,
  },
];
export const SAMPLING_POINTS_LABELS = (): LabelObj[] => [
  ...OBSERVATION_TYPE_LABELS().map(({label, value}) => ({label, value, menuListId: 2})),
  {
    label: t({id: 'Leaf sample'}),
    value: 'Leaf sample',
    menuListId: 1,
  },
  {
    label: t({id: 'Petiole sample'}),
    value: 'Petiole sample',
    menuListId: 1,
  },
  {
    label: t({id: 'Soil sample'}),
    value: 'Soil sample',
    menuListId: 1,
  },
  {
    label: t({id: 'Pasture cut'}),
    value: 'Pasture cut',
    menuListId: 1,
  },
  {
    // Object
    label: t({id: 'Soil moisture probe'}),
    value: 'Soil moisture probe',
    menuListId: 3,
  },
  {
    label: t({id: 'Sensor'}),
    value: 'Sensor',
    menuListId: 3,
  },
  {
    label: t({id: 'Rain gauge'}),
    value: 'Rain gauge',
    menuListId: 3,
  },
  {
    label: t({id: 'Weather station'}),
    value: 'Weather station',
    menuListId: 3,
  },
  {
    label: t({id: 'Beehive'}),
    value: 'Beehive',
    menuListId: 3,
  },
  {
    label: t({id: 'Pest trap'}),
    value: 'Pest trap',
    menuListId: 3,
  },
];

type LoadingMessage = {
  [key: string]: {
    // Url pattern
    messages?: {
      get?: string;
      post?: string;
      put?: string;
      delete?: string;
    };
  };
};

export const urlsToReason: LoadingMessage = {
  'geometry/:groupID/:fieldID': {
    messages: {
      get: 'Loading field geometry...',
      post: 'Saving field geometry...',
    },
  },

  'kml/:groupID/:fieldID': {
    messages: {
      get: 'Loading field data...',
      put: 'Updating field data...',
      post: '',
    },
  },

  profile: {
    messages: {
      get: 'Loading profile...',
      put: 'Saving profile settings..',
    },
  },

  'kml/:groupID': {
    messages: {
      get: 'Loading farm data...',
      post: '',
    },
  },

  histogram: {
    messages: {
      get: 'Loading histogram...',
      post: '',
    },
  },

  'field_weather/:fieldMD5': {
    messages: {
      get: 'Loading weather data...',
      post: '',
    },
  },

  login: {
    messages: {
      get: '',
      post: 'Login...',
    },
  },

  'data2/:md5': {
    messages: {
      post: '',
      get: 'Loading field geometry...',
    },
  },

  'mean?skip': {
    messages: {
      post: 'Loading vegetation indices...',
      get: '',
    },
  },

  'season/:groupID/:fieldID': {
    messages: {
      get: 'Loading season data...',
      post: 'Saving season data...',
    },
  },
  trend: {
    messages: {
      get: 'Loading analytics...',
      post: 'Loading analytics...',
    },
  },
  'services/classification/:md5/:date/:imageLayer': {
    messages: {
      get: 'Loading management zones...',
      post: '',
    },
  },

  'services/data-service/canopy_cover/:md5/:datetime?skip': {},

  'sync/agworld?skip': {
    messages: {
      get: 'Loading Agworld growers list...',
      post: 'Importing Agworld growers...',
    },
  },

  'admin/emails/to_review?skip': {
    messages: {
      post: '',
      get: '',
    },
  },
};

export const LABEL_TOP_MENU_ITEMS = (): {
  title: string;
  icon: string;
  menuListId: number;
  value?: any;
}[] => [
  {
    title: t({id: '[top lvl] crop_damage'}),
    icon: 'crop_damage',
    menuListId: 1,
  },
  {
    title: t({id: '[top lvl] scouting'}),
    icon: 'scouting',
    menuListId: 3,
  },
  {
    title: t({id: '[top lvl] permanent_anomalies'}),
    icon: 'permanent_anomalies',
    menuListId: 4,
  },
  {
    title: t({id: '[top lvl] management_block'}),
    icon: 'management_block',
    menuListId: 5,
  },
  {
    title: t({id: 'No Label'}),
    value: '',
    icon: '',
    menuListId: 6,
  },
];

export const aNewFieldSizeLimitHa = 300;
export const aNewFieldSizeLimitAc = 800;

export const SOURCES: SourceType[] = [
  SourceType.Satellite,
  SourceType.SatelliteHD,
  SourceType.Plane,
  SourceType.Drone,
  SourceType.Machinery,
];

export const USCoordinates = [
  [-125.468072, 49.263537],
  [-95.470882, 49.142552],
  [-89.082591, 48.287357],
  [-84.360812, 46.469502],
  [-79.824199, 46.405699],
  [-75.935675, 45.3096],
  [-71.399063, 45.3096],
  [-69.177049, 47.480208],
  [-67.603122, 47.292155],
  [-66.677283, 44.851983],
  [-75.380171, 37.256015],
  [-75.195004, 34.55686],
  [-80.935207, 30.419467],
  [-79.36128, 25.682518],
  [-80.194535, 24.086817],
  [-82.601717, 24.002267],
  [-83.62014, 29.133753],
  [-86.953161, 29.939261],
  [-90.378766, 28.403354],
  [-94.267291, 28.728607],
  [-95.748634, 25.932567],
  [-97.692896, 25.765926],
  [-99.544574, 27.585856],
  [-101.025917, 29.295367],
  [-103.062763, 29.214592],
  [-104.63669, 29.778674],
  [-106.580952, 31.767209],
  [-108.432631, 31.372803],
  [-113.802498, 32.394773],
  [-117.320687, 32.472915],
  [-120.653708, 34.633074],
  [-123.523809, 37.916309],
  [-124.912568, 40.709311],
  [-125.468072, 49.263537],
];

export const NO_CROP = 'No crop';
export const NO_VARIETY = 'No variety';

export const CARBON_ALLOWED_REGIONS = [
  'illinois',
  'indiana',
  'ohio',
  'missouri',
  'arkansas',
  'tennessee',
];


export const MAIN_SHAPE_COLOR = colors['main-shape-color'];
