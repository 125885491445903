import * as React from 'react';
import moment from 'moment';
import {reportError} from 'containers/error-boundary';
import {Tillage} from 'containers/map/features/optis/optis-types';
import {Season} from 'containers/map/types';
import {t} from 'i18n-utils';
import {AppStore} from 'reducers';
import {CarbonPracticesResponse, Confidence, YearQuarterServer} from '_api/carbon';
import {getCropSrc, getSeasonByYear, naturalSortAlphaNum} from '_utils';
import {CropType} from '_reducers/global_types';
import {CropAvatarLite} from 'components/crop-avatar/crop-avatar-lite';
import {FluroSelectLiteItem} from 'components/fluro-select-lite/fluro-select-lite';
import {SeasonPayload} from './base';
import {GLOBAL_FORMAT_DATE} from '_constants';

export enum YearQuarter {
  SpringTillage = 'Spring tillage',
  SummerCrop = 'Summer crop',
  FallTillage = 'Fall tillage',
  WinterCrop = 'Winter crop',
}

export const yearQuarterIsTillage = (quarter: YearQuarter) =>
  quarter === YearQuarter.FallTillage || quarter === YearQuarter.SpringTillage;

export const yearQuarterOrder = [
  YearQuarter.SpringTillage,
  YearQuarter.SummerCrop,
  YearQuarter.FallTillage,
  YearQuarter.WinterCrop,
];

export const yearQuarterProp: {[key in YearQuarter]: 'spring' | 'summer' | 'fall' | 'winter'} = {
  [YearQuarter.SpringTillage]: 'spring',
  [YearQuarter.SummerCrop]: 'summer',
  [YearQuarter.FallTillage]: 'fall',
  [YearQuarter.WinterCrop]: 'winter',
};

export const yearQuarterServer: {
  [key in YearQuarter]: YearQuarterServer;
} = {
  [YearQuarter.SpringTillage]: 'spring_tillage_practice',
  [YearQuarter.SummerCrop]: 'summer_crop_type',
  [YearQuarter.FallTillage]: 'fall_tillage_practice',
  [YearQuarter.WinterCrop]: 'winter_crop_type',
};

export const countCropPracticeChartData = (
  cropPractices: ReturnType<typeof getCropPractices>[],
  quarter: YearQuarter
) => {
  const count: {[key: string]: number} = {};
  cropPractices.forEach(practice => {
    const v = practice[yearQuarterProp[quarter]];
    if (!v) return;
    if (!count[v]) count[v] = 0;
    count[v] += 1;
  });
  return count;
};

//
// Guessed data is structured in a carbon year instead of a calendar year.
//
// Calendar year 2019 (starts with spring, because winter started in a previous calendar year):
// Spring 2019, Summer 2019, Fall 2019, Winter 2019
//
// Carbon year 2019 (ends with summer harvest and starts with fall last year):
// Fall 2018, Winter 2018, Spring 2019, Summer 2019
//
// Carbon year is structured this way because to understand the carbon sequstration
// we need to see the winter crop and the tillage before,
// which happened to be in a previous calendar year.
//
// In UI we show a table with a calendar year. We don't mention the carbon year to not confuse users.
// And this function takes carbon year data
// and returns the calendar data according to a calendar year + quarter.
//
export const getGuessed = (
  guessed: {[year: number]: CarbonPracticesResponse},
  quarter: YearQuarter,
  year: number
) => {
  switch (quarter) {
    case YearQuarter.SpringTillage:
    case YearQuarter.SummerCrop:
      return guessed?.[year];
    case YearQuarter.FallTillage:
    case YearQuarter.WinterCrop:
      return guessed?.[year + 1];
  }
};

export const getSeason = (seasons: Season[], quarter: YearQuarter, year: number) => {
  switch (quarter) {
    case YearQuarter.SpringTillage:
    case YearQuarter.SummerCrop:
      return getSeasonByYear(seasons, year);
    case YearQuarter.FallTillage:
    case YearQuarter.WinterCrop:
      return getSeasonByYear(seasons, year + 1);
  }
};

export const getCropPractices = (
  fieldId: number,
  year: number,
  seasons: Season[],
  guessedPractices: AppStore['carbon']['guessedPractices']
) => {
  const guessed = guessedPractices[fieldId];

  const spring = getCropPractice(seasons, guessed, year, YearQuarter.SpringTillage);
  const springConfidence = getConfidence(seasons, guessed, year, YearQuarter.SpringTillage);

  const summer = getCropPractice(seasons, guessed, year, YearQuarter.SummerCrop);
  const summerConfidence = getConfidence(seasons, guessed, year, YearQuarter.SummerCrop);

  const fall = getCropPractice(seasons, guessed, year, YearQuarter.FallTillage);
  const fallConfidence = getConfidence(seasons, guessed, year, YearQuarter.FallTillage);

  const winter = getCropPractice(seasons, guessed, year, YearQuarter.WinterCrop);
  const winterConfidence = getConfidence(seasons, guessed, year, YearQuarter.WinterCrop);

  return {
    spring,
    springConfidence,
    summer,
    summerConfidence,
    fall,
    fallConfidence,
    winter,
    winterConfidence,
  };
};

export const getCropPractice = (
  seasons: Season[],
  guessed: {[year: number]: CarbonPracticesResponse},
  year: number,
  quarter: YearQuarter
) => {
  const g = getGuessed(guessed, quarter, year);
  const s = getSeason(seasons, quarter, year);
  switch (quarter) {
    case YearQuarter.SpringTillage:
      return s?.params?.springTillage || tillGuessedToApp(g?.spring_tillage_practice);
    case YearQuarter.SummerCrop:
      return s?.cropType && s?.cropType !== 'unknown' ? s?.cropType : g?.summer_crop_type;
    case YearQuarter.FallTillage:
      return s?.params?.fallTillage || tillGuessedToApp(g?.fall_tillage_practice);
    case YearQuarter.WinterCrop:
      return s?.params?.winterCrop || cropGuessedToApp(g?.winter_cover);
  }
};

export const getCropPracticeForDsocParams = (
  s: Season,
  g: CarbonPracticesResponse,
  q: YearQuarter
) => {
  switch (q) {
    case YearQuarter.SpringTillage:
      return tillAppToGuessed(s?.params?.springTillage) || g?.spring_tillage_practice;
    case YearQuarter.SummerCrop:
      return s?.cropType && s?.cropType !== 'unknown' ? s?.cropType : g?.summer_crop_type;
    case YearQuarter.FallTillage:
      return tillAppToGuessed(s?.params?.fallTillage) || g?.fall_tillage_practice;
    case YearQuarter.WinterCrop:
      return s?.params?.winterCrop || g?.winter_cover;
  }
};

export const getConfidence = (
  seasons: Season[],
  guessed: {[year: number]: CarbonPracticesResponse},
  year: number,
  quarter: YearQuarter
) => {
  const g = getGuessed(guessed, quarter, year);
  const s = getSeason(seasons, quarter, year);
  switch (quarter) {
    case YearQuarter.SpringTillage:
      return s?.params?.springTillage ? '' : confidenceTranslation(g?.spring_tillage_conf);
    case YearQuarter.SummerCrop:
      return s?.cropType && s?.cropType !== 'unknown'
        ? ''
        : confidenceTranslation(g?.summer_crop_conf);
    case YearQuarter.FallTillage:
      return s?.params?.fallTillage ? '' : confidenceTranslation(g?.fall_tillage_conf);
    case YearQuarter.WinterCrop:
      return s?.params?.winterCrop ? '' : confidenceTranslation(g?.winter_cover_conf);
  }
};

const tillGuessedToApp = (till: string) => {
  if (!till) return;
  switch (till) {
    case 'no till':
      return Tillage.NoTill;
    case 'reduced till':
      return Tillage.Low;
    case 'conventional till':
      return Tillage.Conventional;
    default:
      reportError(`Guessed tillage mapping not implemented for ${till}`);
      return till;
  }
};

export const tillAppToGuessed = (till: string) => {
  if (!till) return;
  switch (till) {
    case Tillage.NoTill:
      return 'no till';
    case Tillage.Low:
      return 'reduced till';
    case Tillage.Conventional:
      return 'conventional till';
    default:
      reportError(`Guessed tillage mapping not implemented for ${till}`);
      return till;
  }
};

const cropGuessedToApp = (crop: string) => {
  switch (crop) {
    case 'no cover':
      return 'fallow';
    case 'commodity crop':
      return 'winter_commodity_crop';
    case 'full cover':
      return 'full_season_cover_crop';
    case 'cover crop':
      return 'full_season_cover_crop';
    default:
      return crop;
  }
};

export const cropAppToGuessed = (crop: string) => {
  switch (crop) {
    case 'fallow':
      return 'no cover';
    case 'winter_commodity_crop':
      return 'commodity crop';
    case 'full_season_cover_crop':
      return 'full cover';
    default:
      return crop;
  }
};

const confidenceTranslation = (confidence: Confidence) => {
  if (!confidence) return;
  switch (confidence) {
    case Confidence.High:
      return t({id: 'carbon.high-confidence', defaultMessage: 'high confidence'});
    case Confidence.Medium:
      return t({id: 'carbon.medium-confidence', defaultMessage: 'medium confidence'});
    case Confidence.Low:
      return t({id: 'carbon.low-confidence', defaultMessage: 'low confidence'});
    default:
      reportError(`Confidence translation is not implemented for ${confidence}`);
      return confidence;
  }
};

export const getCarbonCrops = (crops: AppStore['global']['cropTypes']) => {
  const values = Object.values(crops);
  const sorted = naturalSortAlphaNum(values, 'label') as CropType[];

  const cropItems = sorted.map(crop => ({
    ...crop,
    icon: (
      <CropAvatarLite
        className="icon"
        cropType={crop.value}
        iconSrc={getCropSrc(crop.icon)}
        letter={crop.value.charAt(0).toUpperCase()}
      />
    ),
  })) as FluroSelectLiteItem[];
  // Super inefficient map + search here, but it's done once, so tolerable.
  const summerCropItems = summerCrops
    .map(crop => cropItems.find(c => c.value === crop))
    .filter(Boolean);
  const winterCropItems = winterCrops
    .map(crop => cropItems.find(c => c.value === crop))
    .filter(Boolean);

  // Patch the labels.
  winterCropItems.forEach(crop => {
    if (crop.value === 'full_season_cover_crop') {
      crop.label = 'Cover crop';
    }
    if (crop.value === 'winter_commodity_crop') {
      crop.label = 'Winter Wheat';
    }
  });

  return {summerCropItems, winterCropItems};
};

const winterCrops = ['fallow', 'winter_commodity_crop', 'full_season_cover_crop'];

const summerCrops = [
  'corn',
  'soybeans',
  'fallow',
  'alfalfa',
  'hay',
  'cotton',
  'rice',
  'sorghum',
  'sweet_corn',
  'popcorn',
  'wheat_spring',
  'rye',
  'oats',
  'sugarbeets', // we don't have it
  'sunflower', // we don't have it
  'dry_beans',
  'potatoes',
  'peas',
  'other',
];

export const getSeasonPayload = (p: {[fieldId: number]: {[year: number]: SeasonPayload}}) => {
  return Object.keys(p)
    .map(Number)
    .map(fieldId => {
      const years = p[fieldId];
      const seasons = Object.keys(years)
        .map(Number)
        .map(year => getSeasonData(years[year]));
      const payload = {kml_id: fieldId, seasons};
      return payload;
    });
};

export const getSeasonData = (payload: SeasonPayload) => {
  const {year, season, summerCrop, winterCrop, fallTillage, springTillage} = payload;
  return {
    id: season?.id || null,
    cropType: summerCrop || season?.cropType,
    imagery_activated: false,
    startDate: season?.startDate ? formatDate(season?.startDate) : `${year}-06-01`,
    endDate: season?.endDate ? formatDate(season?.endDate) : `${year}-09-30`,
    params: {
      cropSubType: season?.params?.cropSubType,
      isCustomCropSubType: season?.params?.isCustomCropSubType,
      winterCrop: winterCrop || season?.params?.winterCrop,
      fallTillage: fallTillage || season?.params?.fallTillage,
      springTillage: springTillage || season?.params?.springTillage,
    },
  };
};

export const guessedToPayload = (
  farmId: number,
  fieldId: number,
  year: number,
  season?: Season,
  guessed?: CarbonPracticesResponse
): {changed: boolean; payload: SeasonPayload} => {
  const payload: SeasonPayload = {
    farmId,
    fieldId,
    year,
  };
  let changed = false;
  if (
    !season?.params.springTillage &&
    guessed?.spring_tillage_practice &&
    guessed?.spring_tillage_conf !== Confidence.Low
  ) {
    payload.springTillage = tillGuessedToApp(guessed.spring_tillage_practice);
    changed = true;
  }
  if (
    !season?.cropType &&
    guessed?.summer_crop_type &&
    guessed?.summer_crop_conf !== Confidence.Low
  ) {
    payload.summerCrop = guessed.summer_crop_type;
    changed = true;
  }
  if (
    !season?.params.fallTillage &&
    guessed?.fall_tillage_practice &&
    guessed?.fall_tillage_conf !== Confidence.Low
  ) {
    payload.fallTillage = tillGuessedToApp(guessed.fall_tillage_practice);
    changed = true;
  }
  if (
    !season?.params.winterCrop &&
    guessed?.winter_cover &&
    guessed?.winter_cover_conf !== Confidence.Low
  ) {
    payload.winterCrop = cropGuessedToApp(guessed.winter_cover);
    changed = true;
  }
  return {changed, payload};
};

const formatDate = (date: string) => moment(date).format(GLOBAL_FORMAT_DATE);
