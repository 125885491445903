import React, {Component} from 'react';
import {t, FormattedMessage} from 'i18n-utils';
import {connect, ConnectedProps} from 'react-redux';
import moment from 'moment';

import AnomalyHeader from 'components/anomalies-ui/anomalies-header';
import AnomalyItem from 'components/anomalies-ui/anomaly-item';
import AnomalyLabelsDropdown from './labels-dropdown';
import LabelIcon from 'components/label-icon';
import {Button, FontIcon} from 'react-md';
import SortButton, {SortButtonContainer} from 'components/sort-button';
import {getLabelNameByValue} from '_constants';

import {isDateInRange, sortAnomalies, normalizeSensorIndex, getImageStatus} from '_utils';
import {DialogType, toggleDialog} from '_reducers/dialog';

import {getPerformanceGeometries} from '../../utils';
import {sumAreas, userFacingArea} from './anomalies-utils';
import {showNote} from '_actions';
import {togglePopup} from '../../actions';

import {
  bulkUpdateAreasOfInterest,
  removeAreaOfInterest,
  toggleEditAreaOfInterest,
  updateAreaOfInterest,
} from '../../actions/areas-of-interest-actions';

import {
  changeLowPerfAnomalyProp,
  toggleLowPerformingAreas,
  changeAnyTypeGeometryProp,
} from '../../actions/anomalies-actions';

import {IInitialMapState} from '../../types';
import {TSort, IAnomaly, SortTypes, TAnomalyProps} from './types';
import {
  InfoBlock,
  CustomCheckbox,
  FluroTableRow,
  FluroTableColumn,
  FluroDataTable,
  FluroTableHeader,
  FluroTableBody,
} from 'components';
import {WarningSvg} from '../../../../components/icons';
import ChangeAreasOfInterestDates from './change-geometry-dates';
import {ColumnContentType} from '../../../../components/fluro-table-components/fluro-table-components';
import {VigorIcon} from '../../icons';

interface IState {
  sortBy: TSort;
  editDatesPopUpVisible: boolean;
}

type Props = ConnectedProps<typeof connector>;

class AreasList extends Component<Props, IState> {
  state = {
    editDatesPopUpVisible: false,
    sortBy: {
      type: 'ndvi' as SortTypes,
      order: true,
    },
  };

  getAnomalyItem = (anomaly: IAnomaly, i: number) => {
    const {isReadOnly, measurement, openPopupId, togglePopup} = this.props;
    const {area, checked, endDate, startDate, label, title, id, priority} = anomaly.properties;
    return (
      <AnomalyItem
        label={
          <AnomalyLabelsDropdown
            disabled={isReadOnly}
            anomaly={anomaly}
            label={label}
            miniButton
            onChange={(prop: keyof TAnomalyProps, value: any, geometry: IAnomaly) =>
              this.props.changeAnyTypeGeometryProp(geometry, prop, value)
            }
          />
        }
        isChecked={checked}
        area={userFacingArea(area, measurement)}
        key={`${id}-${i}`}
        startDate={startDate}
        labelName={label}
        endDate={endDate}
        title={title}
        openPopUp={openPopupId === id}
        onCheck={(value: any) => this.props.changeAnyTypeGeometryProp(anomaly, 'checked', value)}
        onView={() => togglePopup(id)}
        onEdit={() => this.toggleEditAnomaly(anomaly, true)}
        priority={priority}
      />
    );
  };

  toggleEditAnomaly = (anomaly: IAnomaly, value: boolean) => {
    if (anomaly.properties.isLowPerf) {
      return this.props.toggleLowPerformingAreas(value);
    }
    this.props.toggleEditAreaOfInterest(value, anomaly);
  };

  getTableHeader = () => {
    const {order, type} = this.state.sortBy;
    const dataTypes: {
      type?: ColumnContentType;
      name: any;
      property: SortTypes;
      sorted?: boolean;
    }[] = [
      {
        name: <FormattedMessage id="[anomaly] Label" defaultMessage="Label" />,
        property: 'label',
        sorted: true,
      },
      {
        name: <FormattedMessage id="Title" defaultMessage="Title" />,
        property: 'title',
        sorted: true,
      },
      {
        type: 'number',
        name: <FormattedMessage id="Area" defaultMessage="Area" />,
        property: 'size',
        sorted: true,
      },
      {
        type: 'date',
        name: <FormattedMessage id="Dates detected" defaultMessage="Dates detected" />,
        property: 'date',
        sorted: true,
      },
      {
        type: 'number',
        name: <FormattedMessage id="Avg NDVI" defaultMessage="Avg NDVI" />,
        property: 'ndvi',
        sorted: true,
      },
    ];

    return (
      <FluroTableRow>
        {dataTypes.map(el => (
          <FluroTableColumn type={el.type} key={`element-${el.property}`}>
            <SortButtonContainer onClick={() => (el.sorted ? this.sortTable(el.property) : null)}>
              {el.name}
              {el.sorted && (
                <SortButton
                  selected={el.property === type}
                  descending={type === el.property ? order : false}
                />
              )}
            </SortButtonContainer>
          </FluroTableColumn>
        ))}
      </FluroTableRow>
    );
  };

  getTableHeaderTitle = (isChecked: boolean, geometryToShow: IAnomaly[]) => {
    return (
      <div className={'anomaly-table-header-title'}>
        <CustomCheckbox
          isChecked={isChecked}
          onCheck={(value: boolean) =>
            this.onAllChangeGeometryProp('checked', value, geometryToShow)
          }
        />
        <h4 className={'anomaly-table__title'}>{t({id: 'Areas of Interest'})}</h4>
      </div>
    );
  };

  getTableRows = (data: IAnomaly[]) => {
    const {measurement, range} = this.props;
    return data.map((anomaly: IAnomaly, i: number) => {
      const {label, title, area, checked, startDate, endDate, mean, type} = anomaly.properties;
      const meanIndex = mean ? normalizeSensorIndex(mean, range) : null;

      return (
        <FluroTableRow
          onClick={() => this.props.changeAnyTypeGeometryProp(anomaly, 'checked', !checked)}
          selected={checked}
          key={i}
        >
          <FluroTableColumn>
            <div className="label-container">
              {checked ? <LabelIcon label={'done'} /> : <LabelIcon label={label} />}
              <span>{getLabelNameByValue(label)}</span>
            </div>
          </FluroTableColumn>
          <FluroTableColumn>{title}</FluroTableColumn>
          <FluroTableColumn type={'number'}>{userFacingArea(area, measurement)}</FluroTableColumn>
          <FluroTableColumn type={'date'}>
            {`${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format(
              'DD MMM YYYY'
            )}`}
          </FluroTableColumn>
          <FluroTableColumn type={'number'}>
            <span className="anomaly-ndvi-value">
              <VigorIcon type={type} />
              {meanIndex}
            </span>
          </FluroTableColumn>
        </FluroTableRow>
      );
    });
  };

  onAllChangeGeometryProp = (prop: keyof TAnomalyProps, value: any, geometries: IAnomaly[]) => {
    geometries.forEach(geometry => {
      const {startDate, endDate, isLowPerf} = geometry.properties;
      const isInterSelect = isLowPerf
        ? true
        : // isDateInRange(moment.utc(this.props.currentDate, 'DD/MM/YYYY').format(), geometry.date, geometry.date)
          isDateInRange(
            moment.utc(this.props.currentDate, 'DD/MM/YYYY').format(),
            startDate,
            endDate
          );
      if (!isInterSelect && !Object.keys(this.props.currentDates).length) {
        return this.cantDisplayGeometryWarning();
      }
      isLowPerf
        ? this.props.changeLowPerfAnomalyProp(geometry, prop, value)
        : this.props.changeAnyTypeGeometryProp(geometry, prop, value);
    });
  };

  cantDisplayGeometryWarning = () => {
    return this.props.showNote({
      title: t({id: 'note.warning', defaultMessage: 'Warning'}),
      message:
        'This shape cannot be displayed for the selected season. Select a season between the starting date and the end date of the region of interest.',
      level: 'warning',
    });
  };

  bulkUpdateProp = (prop: keyof TAnomalyProps, value: any, selectedShapes: IAnomaly[]) => {
    if (!['startDate', 'endDate', 'label'].includes(prop)) {
      return;
    }

    const lowPerfAnomalies = selectedShapes.filter(
      shape => shape.properties && shape.properties.isLowPerf
    );
    const ROI = selectedShapes.filter(shape => shape.properties && !shape.properties.isLowPerf);

    if (lowPerfAnomalies.length) {
      this.props.changeLowPerfAnomalyProp(lowPerfAnomalies, prop, value);
      if (!value) {
        this.props.togglePopup(undefined);
      }
    }

    if (ROI.length) {
      this.props.bulkUpdateAreasOfInterest(ROI, prop, value);
    }
  };

  deleteGeometries = (geometries: IAnomaly[]) => {
    const lowPerformingAnomalies = geometries.filter(({properties}) => properties.isLowPerf);
    const AOIs = geometries.filter(({properties}) => !properties.isLowPerf);

    if (lowPerformingAnomalies.length) {
      // "remove" low performing
      this.props.changeLowPerfAnomalyProp(lowPerformingAnomalies, 'label', '');
    }

    if (AOIs.length) {
      // remove AOI
      AOIs.forEach(aoi => this.props.removeAreaOfInterest(aoi.properties.id, AOIs.length > 1));
    }
  };

  bulkChangeAreasOfInterestDates = (
    startDate: string,
    endDate: string,
    selectedAOI: IAnomaly[]
  ) => {
    selectedAOI.forEach(AOI => {
      // update all AOI one by one
      this.props.updateAreaOfInterest(
        {...AOI, properties: {...AOI.properties, startDate, endDate}},
        selectedAOI.length > 1
      );
    });
    this.toggleDatesPopUp(false);
  };

  toggleDatesPopUp = (value: boolean) => this.setState({editDatesPopUpVisible: value});

  sortTable = (val: SortTypes) => {
    this.setState({sortBy: {type: val, order: !this.state.sortBy.order}});
  };

  openAddNewAnomalyPopUp = () => {
    this.props.toggleDialog(DialogType.AddNewAnomaly);
  };

  render() {
    const {
      wholeTableView,
      lowPerfAnomalies: {isVisible: visibleLowPerfAnomalies},
      imageStatus,
      measurement,
      anomaliesHistoryOpen,
    } = this.props;
    const {geometryToShow, historyGeometries} = getPerformanceGeometries();
    const visibleGeometries = anomaliesHistoryOpen
      ? [...geometryToShow, ...historyGeometries]
      : geometryToShow; // to allow actions on history geometries FSB-3896
    const filteredGeometries = sortAnomalies(geometryToShow);
    const tableHeader = this.getTableHeader();
    const tableBody = this.getTableRows(filteredGeometries);
    const isCheckedAll = geometryToShow.every(({properties}) => properties.checked);
    const selectedGeometries = visibleGeometries.filter(({properties}) => properties.checked);
    const selectedAOI = selectedGeometries.filter(({properties}) => !properties.isLowPerf);
    const {isNotImagery} = imageStatus;

    return (
      <>
        {this.state.editDatesPopUpVisible && (
          <ChangeAreasOfInterestDates
            onHide={() => this.toggleDatesPopUp(false)}
            saveNewDates={(startDate, endDate) =>
              this.bulkChangeAreasOfInterestDates(startDate, endDate, selectedAOI)
            }
          />
        )}

        {!wholeTableView && (
          <AnomalyHeader
            isCheckedAll={isCheckedAll}
            title={t(
              {id: '{count} {plural, count, one {area} other {areas}}'},
              {count: geometryToShow.length}
            )}
            subtitle={sumAreas(geometryToShow, measurement)}
            isReadOnly={this.props.isReadOnly}
            onSelectAll={(value: any) =>
              this.onAllChangeGeometryProp('checked', value, geometryToShow)
            }
            onChangeLabel={this.bulkUpdateProp}
            labelItems={selectedGeometries}
            onDelete={() => this.deleteGeometries(selectedGeometries)}
            toggleDatesPopUp={selectedAOI.length ? () => this.toggleDatesPopUp(true) : null}
          />
        )}
        {geometryToShow.length && !visibleLowPerfAnomalies ? (
          <>
            {wholeTableView ? (
              <>
                {this.getTableHeaderTitle(isCheckedAll, geometryToShow)}
                <FluroDataTable
                  baseId={'ROI-table-view'}
                  selectableRows={false}
                  className={`anomaly-table`}
                >
                  <FluroTableHeader>{tableHeader}</FluroTableHeader>
                  <FluroTableBody>{tableBody}</FluroTableBody>
                </FluroDataTable>
              </>
            ) : (
              geometryToShow.map((anomaly, i) => this.getAnomalyItem(anomaly, i))
            )}
          </>
        ) : (
          !visibleLowPerfAnomalies &&
          !isNotImagery && (
            <div className={'no-AOI-detection'}>
              <div className={'header-part'}>
                <div>{t({id: 'No current areas of interest'})}</div>
                <Button
                  id={'add-farm-btn'}
                  className="add-button" // add-button - global class for buttons with + icon
                  onClick={this.openAddNewAnomalyPopUp}
                  raised
                  primary
                  iconEl={<FontIcon>add</FontIcon>}
                >
                  {t({id: 'Add'})}
                </Button>
              </div>
              <InfoBlock appearance={'info'} className={'tab-info-block'}>
                <div className={'align-center flex-wrap'}>
                  <span>
                    <FormattedMessage
                      id="Or use the <icon></icon> button on the left hand side"
                      defaultMessage="Or use the <icon></icon> button on the left hand side
                    of the map to view low performing areas for this image."
                      values={{icon: () => <WarningSvg className={'info-color'} />}}
                    />
                  </span>
                </div>
              </InfoBlock>
            </div>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: any}) => ({
  measurement: login.user.settings.measurement,
  currentDate: map.currentDate,
  isReadOnly: map.group.readOnly,
  isOnBoardingNow: login.user.settings.onboarding.fullTour,
  wholeTableView: map.wholeTableViewOpen,
  geometry: map.geometry,
  geometriesOnMap: map.geometriesOnMap,
  currentDates: map.currentDates,
  field: map.field,
  range: map.histogram.range,
  lowPerfAnomalies: map.lowPerfAnomalies,
  openPopupId: map.openPopupId,
  imageStatus: getImageStatus(map),
  anomaliesHistoryOpen: map.anomalies.historyOpen,
});

const connector = connect(mapStateToProps, {
  removeAreaOfInterest,
  showNote,
  bulkUpdateAreasOfInterest,
  toggleEditAreaOfInterest,
  updateAreaOfInterest,
  changeLowPerfAnomalyProp,
  toggleLowPerformingAreas,
  changeAnyTypeGeometryProp,
  togglePopup,
  toggleDialog,
});

export default connector(AreasList);
