import {t, FormattedMessage} from 'i18n-utils';
import React, {Component, MouseEvent} from 'react';
import Yup from 'yup';
import {withFormik, FormikProps} from 'formik';
import moment from 'moment';
import {
  normalizeSensorIndex,
  convertFromSquareMetersToMeasure,
  convertUnit,
  getLocaleDateFormat,
} from '_utils';
import {getLabelDataByValue} from '_constants';
import {Button, TextField} from 'react-md';
import FluroDatePicker from 'components/fluro-date-picker';
import ReadOnly from 'components/read-only-container';
import AnomalyLabelsDropdown from './labels-dropdown';

import {TSensor} from 'types';
import {Season} from '../../types';
import {IAnomaly} from './types';
import {cropMinDateParams} from '../../utils';
import Mixpanel from '_utils/mixpanel-utils';

interface IProps {
  geometry: any;
  measurement: string;
  currentSensor: TSensor;
  currentSeason: Season;
  currentDate: string;
  onClose: (event: MouseEvent<HTMLDivElement>) => void;
  onEdit: (event: MouseEvent<HTMLDivElement>) => void;
  onDelete: (event: MouseEvent<HTMLDivElement>) => void;
  onSubmit: (geometry: IAnomaly) => Promise<any>;
  range: number;
}

type FormValues = {
  label: string;
  title: string;
  startDate: string;
  endDate: string;
};

class EditGeometryForm extends Component<IProps & FormikProps<FormValues>> {
  getGeometrySize = (area: number) => {
    const {measurement}: {measurement: string} = this.props;
    return (
      <div className={'geometry-edit-form__area'}>
        <div className="geometry-edit-form__area--prop">Area:</div>
        <div className="geometry-edit-form__area--value">
          {convertUnit(measurement, 'ac', convertFromSquareMetersToMeasure(area))}{' '}
          {t({id: measurement})}
        </div>
      </div>
    );
  };

  getGeometryMeanIndex = (meanIndex = 0) => {
    const {currentSensor, currentDate} = this.props;
    const formatDate = moment(currentDate, 'DD/MM/YYYY').format('DD MMMM YYYY');
    const isShowed = meanIndex > 0 && currentSensor !== 'TCI' && currentSensor !== 'NC';
    return (
      isShowed && (
        <div className={'geometry-edit-form__mean'}>
          <div className="geometry-edit-form__mean--prop">{currentSensor}:</div>
          <div className="geometry-edit-form__mean--value">
            {normalizeSensorIndex(meanIndex, this.props.range)} ({formatDate})
          </div>
        </div>
      )
    );
  };

  render() {
    const {geometry, values, errors, onClose, onDelete, onEdit, handleSubmit} = this.props;
    const {area, mean, saved} = geometry.properties;
    const isNewGeometry = saved === undefined;

    return (
      <div className={'geometry-edit-form'}>
        <h3 className="geometry-edit-form__title">
          {isNewGeometry
            ? t({id: 'Create area of interest', defaultMessage: 'Creat earea of interest'})
            : t({
                id: 'Edit area of interest',
                defaultMessage: 'Edit area of interest',
              })}
        </h3>
        <form className="geometry-edit-form__container" onSubmit={handleSubmit}>
          <div className={'geometry-edit-form__body'}>
            {this.getGeometrySize(area)}
            {this.getGeometryMeanIndex(mean)}

            <TextField
              id="geometry-edit-form-title"
              lineDirection="center"
              label={t({id: 'Title', defaultMessage: 'Title'})}
              value={values.title || ''}
              onChange={value => this.onChangeValue('title', value)}
              placeholder={t({id: 'Title', defaultMessage: 'Title'})}
              error={!!errors.title}
              errorText={errors.title}
            />

            <div className="geometry-edit-form__label">
              <div className="geometry-edit-form__label--title">Label</div>
              <AnomalyLabelsDropdown
                id={`geometry-edit-form-label`}
                anomaly={geometry}
                width={272}
                label={values.label}
                onChange={(field: string, value: any) => this.onChangeValue(field, value)}
              />
            </div>

            <FluroDatePicker
              id="geometry-edit-form-start-date"
              label={t({id: 'Starting date', defaultMessage: 'Starting date'})}
              hideFormat
              onChange={this.onNativeDateSet.bind(this, 'startDate')}
              selected={moment.utc(values.startDate, 'YYYY-MM-DD')}
              error={!!errors.startDate}
              errorText={errors.startDate}
            />

            <FluroDatePicker
              id="geometry-edit-form-end-date"
              label={t({id: 'Est. end date', defaultMessage: 'Est. end date'})}
              hideFormat
              minDate={moment.utc(values.startDate, 'YYYY-MM-DD')}
              onChange={this.onNativeDateSet.bind(this, 'endDate')}
              selected={moment.utc(values.endDate, 'YYYY-MM-DD')}
              error={!!errors.endDate}
              errorText={errors.endDate}
            />
          </div>

          <div className={'geometry-edit-form__action-buttons'}>
            {isNewGeometry ? (
              <ReadOnly>
                <Button
                  id="geometry-edit-form-edit"
                  raised
                  className="geometry-edit-form__btn element-full-width"
                  onClick={onEdit}
                >
                  {t({id: 'Edit shape', defaultMessage: 'Edit shape'})}
                </Button>
              </ReadOnly>
            ) : null}

            <ReadOnly>
              <Button
                id="geometry-edit-form-delete"
                raised
                className="geometry-edit-form__btn"
                onClick={onDelete}
              >
                {t({id: 'Delete', defaultMessage: 'Delete'})}
              </Button>
            </ReadOnly>

            <ReadOnly>
              <Button
                id="geometry-edit-form-submit"
                raised
                primary
                className="geometry-edit-form__btn"
                type="submit"
              >
                {t({id: 'Save', defaultMessage: 'Save'})}
              </Button>
            </ReadOnly>
          </div>
        </form>
      </div>
    );
  }

  onChangeValue = (field: string, value: any) => this.props.setFieldValue(field, value);
  onNativeDateSet = (field: string, value: any) =>
    value.isValid() && this.props.setFieldValue(field, value.format('YYYY-MM-DD'));
}

const FormikForm = withFormik<IProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({geometry, currentSeason}) => {
    const {title = '', startDate, endDate, label = 'Select a label'} = geometry.properties;
    return {
      title,
      label,
      startDate: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : moment(currentSeason.startDate).format('YYYY-MM-DD'),
      endDate: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : moment(currentSeason.endDate).format('YYYY-MM-DD'),
    };
  },
  validationSchema: (props: IProps) => {
    const formatStr = getLocaleDateFormat();
    const yupDateFormat = 'MM/DD/YYYY';

    const {date: minDate, msg: minMsg}: any = cropMinDateParams();
    const maxDate = moment().add(1, 'years');

    return Yup.object().shape({
      title: Yup.string().max(
        500,
        t({id: 'Maximum length 500', defaultMessage: 'Maximum length 500'})
      ),
      startDate: Yup.date()
        .min(minDate.format(yupDateFormat), minMsg)
        .max(
          maxDate.format(yupDateFormat),
          t(
            {id: 'Maximal date is', defaultMessage: 'Maximal date is {maxDate}'},
            {maxDate: maxDate.format(formatStr)}
          )
        )
        .required(t({id: 'Date is required', defaultMessage: 'Date is required'}))
        .test('start-date', '', function(value) {
          const startDate = moment(value || null).startOf('day');
          const endDate = moment(this.parent.endDate || null).startOf('day');

          if (endDate.isValid() && startDate.isValid() && endDate.diff(startDate, 'days') < 1) {
            return this.createError({
              path: this.path,
              message: t({
                id: 'Minimal range between dates is 1 day',
                defaultMessage: 'Minimal range between dates is 1 day',
              }),
            });
          }

          return true;
        }),

      endDate: Yup.date()
        .min(minDate.format(yupDateFormat), minMsg)
        .max(
          maxDate.format(yupDateFormat),
          t(
            {id: 'Maximal date is', defaultMessage: 'Maximal date is {maxDate}'},
            {maxDate: maxDate.format(formatStr)}
          )
        )
        .required(t({id: 'Date is required', defaultMessage: 'Date is required'}))
        .test('end-date', '', function(value) {
          const endDate = moment(value || null).startOf('day');
          const startDate = moment(this.parent.startDate || null).startOf('day');

          if (endDate.isValid() && startDate.isValid() && endDate.diff(startDate, 'days') < 1) {
            return this.createError({
              path: 'startDate',
              message: t({
                id: 'Minimal range between dates is 1 day',
                defaultMessage: 'Minimal range between dates is 1 day',
              }),
            });
          }

          return true;
        }),
    });
  },
  handleSubmit: async (values: any, {props: {onSubmit, geometry}, setSubmitting}: any) => {
    if (!geometry.properties.saved) {
      Mixpanel.addGeometry('Draw');
    }

    const newGeometry: IAnomaly = {
      ...geometry,
      properties: {
        ...geometry.properties,
        ...values,
        saved: true,
        label: values.label !== 'Select a label' ? values.label : '',
      },
    };
    setSubmitting(true);
    await onSubmit(newGeometry);
    setSubmitting(false);
  },
  displayName: 'EditGeometryForm',
})(EditGeometryForm);

export default FormikForm;
