import {t, FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setTabNotification} from '_actions';
import {AdminApi} from '_api';
import {isAdminPerm} from '_utils';
import {AppStore} from '../../reducers';

/**
 * Every 30 minutes fetches 'admin/emails/to_review'
 * to check if there are a new emails to review.
 */

let toReviewInterval: ReturnType<typeof setTimeout>;

const useEmailToReviewNotification = () => {
  const dispatch = useDispatch();
  const perm = useSelector((state: AppStore) => state.login?.user?.perm);
  const isAuth = useSelector((state: AppStore) => state.login.isAuth);

  const refreshData = useCallback(() => {
    AdminApi.emailsToReview().then(({data}) => {
      const hasUpdates = data?.result?.to_review;
      dispatch(setTabNotification('admin', {hasUpdates: !!hasUpdates, count: hasUpdates}));
      toReviewInterval = setTimeout(refreshData, 60 * 1000 * 30);
    });
  }, []);

  useEffect(() => {
    if (!isAdminPerm(perm) || !isAuth) {
      return;
    }

    refreshData();

    return () => {
      clearTimeout(toReviewInterval);
    };
  }, [perm, isAuth]);
};

export default useEmailToReviewNotification;
