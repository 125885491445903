import {t} from 'i18n-utils';
import {CropAvatar, FluroAutocomplete} from '../index';
import {genKey, getCropLabelById, getCropTypesList} from '_utils';
import React, {useMemo} from 'react';
import {CropType} from '_reducers/global_types';
import './index.scss';

type Props = {
  cropTypes?: Partial<CropType>[];
  cropType: string;
  errorMessage?: string;
  label?: string;
  onChange(crop: string): void;
  className?: string;
  simplifiedMenu?: boolean;
};

const CropSelectMd = ({
  className,
  errorMessage,
  cropType,
  label = 'Crop Type',
  cropTypes,
  onChange,
  simplifiedMenu = false,
}: Props) => {
  const id = useMemo(() => genKey(), []);
  const cropTypesList = cropTypes ? cropTypes : getCropTypesList();
  const formattedValue = () => {
    if (cropType === 'all') {
      return 'All crops';
    }
    return getCropLabelById(cropType) || cropType;
  };

  const _cropTypesList = useMemo(() => {
    return cropTypesList.map(item => ({...item, label: t({id: item.value})}));
  }, [cropTypesList]);

  return (
    <div className={`crop-type-select ${className || ''}`}>
      <CropAvatar
        className={'crop-type-select__avatar'}
        style={{marginRight: 10}}
        cropType={cropType}
      />

      <FluroAutocomplete
        id={`crop-type-selector-${id}`}
        label={t({id: label})}
        placeholder={t({id: 'Crop type'})}
        title={t({id: cropType})}
        menuItems={_cropTypesList}
        value={formattedValue()}
        onAutocomplete={(val: string) => onChange(val)}
        className={`crop-type-select__autocomplete ${cropType ? '' : 'without-crop'}`}
        error={!!errorMessage}
        errorText={errorMessage}
        simplifiedMenu={simplifiedMenu}
      />
    </div>
  );
};

export default CropSelectMd;
