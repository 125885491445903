import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {FontIcon} from 'react-md';
import {IInitialMapState} from 'containers/map/types';
import {DialogType, toggleDialog} from '_reducers/dialog';
import {dialogToggle} from 'modules/ui-helpers';
import {getImageStatus} from '_utils';

const NoData = () => {
  const dispatch = useDispatch();

  const {remoteSensingFilterProps, imageStatus, seasonId, appProcessingStatus} = useSelector(
    ({map}: {map: IInitialMapState}) => ({
      appProcessingStatus: map.appProcessingStatus,
      remoteSensingFilterProps: map.remoteSensingFilterProps,
      imageStatus: getImageStatus(map),
      seasonId: map.currentSeasonId,
    }),
    shallowEqual
  );

  const {isNotProcessing, isNotImagery, isFuture} = imageStatus;

  if (!isNotProcessing) {
    return (
      <div className="only-cloudy">
        <div>{isFuture ? 'No imagery yet' : 'Processing data...'}</div>
        {!isFuture && (
          <FontIcon onClick={() => dispatch(toggleDialog(DialogType.Processing))}>
            help_outline
          </FontIcon>
        )}
      </div>
    );
  }

  if ((isNotImagery && !remoteSensingFilterProps.length) || !seasonId) {
    return (
      <div className="only-cloudy">
        <div>No imagery</div>
      </div>
    );
  }

  if (appProcessingStatus === 'manually-hidden') {
    return (
      <div className="only-cloudy">
        <div>Hidden imagery</div>
      </div>
    );
  }

  return (
    <div className="only-cloudy">
      <div>{remoteSensingFilterProps.length ? 'Imagery is filtered' : 'Cloudy imagery only'}</div>
      <FontIcon onClick={() => dispatch(dialogToggle('cloudyTip', true))}>help_outline</FontIcon>
    </div>
  );
};

export default NoData;
