import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {toggleGlobalDialog} from '_actions';
import {GlobalState} from '_reducers/global_types';
import {FluroDialog} from 'components';
import {Avatar, Button} from 'react-md';
import {LoginState} from '../login/types';
import cn from 'classnames';
import {FarmStore} from '../farm/types';
import {updateUserDemoFarms, setProfileSettings} from '../login/actions';

type Props = ConnectedProps<typeof connector>;

const DemoFarmsDialog = ({
  globalDialogs,
  toggleGlobalDialog,
  user,
  demoFarmsIdsList,
  demoFarmsObject,
  updateUserDemoFarms,
  setProfileSettings,
}: Props) => {
  const [selectedDemoFarms, onSelectDemoFarm] = useState(
    (user?.groupIds || []).filter(farmId => demoFarmsIdsList.includes(farmId)) || []
  );

  const visible = !!globalDialogs?.demoFarms?.visible;

  useEffect(() => {
    onSelectDemoFarm(
      (user?.groupIds || []).filter(farmId => demoFarmsIdsList.includes(farmId)) || []
    );
  }, [demoFarmsIdsList]);

  const hideDialog = () => toggleGlobalDialog('demoFarms', {visible: false});

  const handleDemoFarmClick = useMemo(
    () => (selectedId: number) => {
      selectedDemoFarms.includes(selectedId)
        ? onSelectDemoFarm(selectedDemoFarms.filter(id => id !== selectedId))
        : onSelectDemoFarm([...selectedDemoFarms, selectedId]);
    },
    [selectedDemoFarms]
  );

  useEffect(() => {
    if (visible && !user.settings.showDemoFarms) {
      setProfileSettings({...user.settings, showDemoFarms: true}, false);
    }
  }, [visible]);

  const updateUserDemoFarmsList = () => {
    updateUserDemoFarms(selectedDemoFarms);
    hideDialog();
  };

  const demoFarmsElements = useMemo(() => {
    return (
      <div className="demo-farm-items">
        {demoFarmsIdsList.map(id => {
          const demoFarm = demoFarmsObject[id];
          return (
            <div
              onClick={() => handleDemoFarmClick(id)}
              key={id}
              className={cn('demo-farm-items__item', {selected: selectedDemoFarms.includes(id)})}
            >
              <Avatar
                className="demo-farm-items__item__avatar"
                suffix="white"
                src={`/assets/crops/${demoFarm.icon}`}
              />
              <div className="demo-farm-items__item__crop-name">{demoFarm.name}</div>
            </div>
          );
        })}
      </div>
    );
  }, [selectedDemoFarms, demoFarmsIdsList]);

  return (
    <FluroDialog
      id={'select-demo-farm'}
      title={'Select demo farm(s)'}
      visible={visible}
      className={'select-demo-farm-dialog'}
      onHide={hideDialog}
      borderedTitle
    >
      <div className={'info-text'}>
        Select the crops you grow to customize your demo experience:
      </div>

      {demoFarmsElements}

      <div className={'dialog-nav'}>
        <Button raised onClick={hideDialog}>
          Cancel
        </Button>

        <Button primary raised onClick={updateUserDemoFarmsList}>
          Save
        </Button>
      </div>
    </FluroDialog>
  );
};

const mapStateToProps = ({
  global,
  login,
  farms,
}: {
  global: GlobalState;
  login: LoginState;
  farms: FarmStore;
}) => ({
  globalDialogs: global.dialogsState,
  user: login.user,
  demoFarmsIdsList: farms.demoFarmsIdsList,
  demoFarmsObject: farms.demoFarmsObject,
});

const connector = connect(mapStateToProps, {
  toggleGlobalDialog,
  updateUserDemoFarms,
  setProfileSettings,
});
export default connector(DemoFarmsDialog);
