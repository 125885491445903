import moment from 'moment';
import {t} from 'i18n-utils';
//@ts-ignore
import tokml from 'tokml';
import {featureCollection} from '@turf/helpers';

import {ActionTypes} from '../reducer/types';
import {SamplingPoint, SamplingPointProps} from '../types';
import {ActivityApi, KmlApi} from '_api';

import {GLOBAL_FORMAT_DATE} from '_constants';
import SAMPLING_GROWTH_STAGES from '_constants/growth-stages';

import {showNote} from '_actions';
import {downloadFile, formatDate, getAnalyticsItemColor} from '_utils';
import {changeSeason, setSensor} from '../actions';
import {isDifferentDatesPoints, getPointById} from '../utils/sampling-points';
import {reportError} from '../../error-boundary';
import Mixpanel from '_utils/mixpanel-utils';

const NON_REQUEST_PROPS = ['checked']; // send request only if it is necessary

export const setCurrentMarker = (id: any, position: Array<any> = []) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_POINTS_SET_CURRENT,
    id,
    position,
  });
};

export const savePoint = (point: SamplingPoint, suggestedPoints = false) => (
  dispatch: any,
  getState: any
) => {
  const {currentSeason, pointsGroups} = getState().map;
  const pointBeforeUpdate = getPointById(point.id, pointsGroups.all);

  return ActivityApi.updateSamplingPoint(currentSeason.kmlId, currentSeason.id, point)
    .then(({data}) => {
      const isNew = point.id === 'new';

      if (isNew) {
        point.id = data;
        point.properties.color = getAnalyticsItemColor();
        Mixpanel.addNewPoint(point);
      }

      dispatch({
        type: ActionTypes.MAP_POINTS_SAVE,
        point,
        suggestedPoints,
      });

      if (!suggestedPoints) {
        dispatch(
          showNote({
            title: t({id: 'note.success', defaultMessage: 'Success'}),
            message: isNew ? t({id: 'Point was saved.'}) : t({id: 'Point was updated.'}),
            level: 'success',
          })
        );
      }
      if (
        isNew
          ? point.properties.n_result
          : pointBeforeUpdate.properties.n_result !== point.properties.n_result
      ) {
        // show message is N result was changed
        dispatch(checkNmapchanges());
      }

      try {
        // @ts-ignore
        window.leafletElement.closePopup();
      } catch (e) {
        console.log(e);
      }
    })
    .catch(console.log);
};

export const updatePointProps = <K extends keyof SamplingPointProps>(
  point: SamplingPoint,
  properties: {[key in keyof SamplingPointProps]: SamplingPointProps[K]}
) => (dispatch: any, getState: any) => {
  const {currentSeason} = getState().map;

  const updatedPoint = {
    ...point,
    properties: {
      ...point.properties,
      ...properties,
    },
  };

  dispatch({
    type: ActionTypes.MAP_POINTS_SAVE,
    point: updatedPoint,
  });

  if (Object.keys(properties).some(p => !NON_REQUEST_PROPS.includes(p))) {
    return ActivityApi.updateSamplingPoint(
      currentSeason.kmlId,
      currentSeason.id,
      updatedPoint
    ).then(() => {
      if (properties.n_result !== undefined) {
        dispatch(checkNmapchanges());
      }
      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Point was updated.'}),
          level: 'success',
        })
      );
    });
  }
};
export const removeSamplingPoint = (pointId: number | 'new') => async (
  dispatch: any,
  getState: any
) => {
  const {
    group: {readOnly},
    currentSeason,
    pointsCurrentGroupDate,
    pointsGroups,
    currentSensor,
  } = getState().map;

  const shouldDisableNmap = // disable NMAP if not enough points with n_result
    pointsGroups[pointsCurrentGroupDate]?.filter(
      (p: SamplingPoint) => p.id !== pointId && p.properties.n_result > 0
    )?.length < 3;

  if (readOnly) {
    dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({id: 'readOnlyFarm'}),
        level: 'warning',
      })
    );
    return;
  }

  if (pointId !== 'new') {
    await ActivityApi.removeSamplingPoint(currentSeason.kmlId, currentSeason.id, pointId).catch(
      console.log
    );
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Point was deleted.'}),
        level: 'success',
      })
    );
    if (currentSensor === 'NMAP' && shouldDisableNmap) {
      dispatch(setSensor('NDVI'));
    } else {
      dispatch(checkNmapchanges());
    }
  }

  dispatch({
    type: ActionTypes.MAP_POINTS_REMOVE_ONE,
    id: pointId,
    recalculateDates: shouldDisableNmap, // to remove NMAP from available layers
  });
};

export const updateTSMarkersPosition = (positions: any) => (dispatch: any, getState: any) => {
  const {currentSeason} = getState().map;

  dispatch({
    type: ActionTypes.MAP_POINTS_UPDATE_POSITION,
    positions,
  });

  if (!currentSeason) {
    return;
  }

  const pointsToUpdate = currentSeason.tissueSampling
    .filter((ts: SamplingPoint) => positions[ts.id] && ts.id !== 'new')
    .map((point: SamplingPoint) =>
      ActivityApi.updateSamplingPoint(currentSeason.kmlId, currentSeason.id, point)
    );

  Promise.all(pointsToUpdate).then(() => {
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Your points position was updated.'}),
        level: 'success',
      })
    );

    dispatch(checkNmapchanges());
  });
};

const checkNmapchanges = () => (dispatch: any, getState: any) => {
  // show message if the nmap related things were changed
  const {currentSensor} = getState().map;
  if (currentSensor === 'NMAP') {
    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'The nitrogen map was updated.'}),
        level: 'success',
      })
    );
  }
};

export const pointsSetGroupDate = (value: string) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.MAP_POINTS_SET_GROUP_DATE,
    value,
  });

  dispatch({
    type: ActionTypes.MAP_UPDATE_N_DATA,
    data: [],
  });
};

export const bulkUpdatePointsProp = (
  pointIds: number[],
  pointProperty: keyof SamplingPointProps,
  value: any
) => async (dispatch: any, getState: any) => {
  const {currentSeason} = getState().map;

  if (!currentSeason) {
    return;
  }

  dispatch({
    type: ActionTypes.MAP_POINTS_BULK_UPDATE_PROP,
    pointIds,
    pointProperty,
    value,
  });

  if (!NON_REQUEST_PROPS.includes(pointProperty)) {
    const preparedPointsToUpdate = currentSeason.tissueSampling
      .filter((point: SamplingPoint) => pointIds.includes(point.id))
      .map((point: SamplingPoint) => {
        const updatedPoint = {
          ...point,
          properties: {
            ...point.properties,
            [pointProperty]: value,
          },
        };
        return ActivityApi.updateSamplingPoint(currentSeason.kmlId, currentSeason.id, updatedPoint);
      });

    await Promise.all(preparedPointsToUpdate).catch(console.log);

    dispatch(
      showNote({
        title: t({id: 'note.success', defaultMessage: 'Success'}),
        message: t({id: 'Your points group was updated.'}),
        level: 'success',
      })
    );
  }
};

export const generateNitrogenMap = (
  groupdId: number,
  fieldId: number | string,
  UpdatedDates: any
) => (dispatch: any, getState: any) => {
  KmlApi.updateNitrogenDates(groupdId, fieldId, UpdatedDates)
    .then(() => {
      dispatch({
        type: ActionTypes.GENERATE_NITROGEN_MAP,
        date: UpdatedDates,
      });

      dispatch(
        showNote({
          title: t({id: 'note.success', defaultMessage: 'Success'}),
          message: t({id: 'Nitrogen map was generated.'}),
          level: 'success',
        })
      );
      dispatch(changeSeason(getState().map.currentSeasonId, 'NMAP'));
    })
    .catch(err => reportError(`generateNitrogenMap() err = ${err}`));
};

export const exportPointsToKml = () => (dispatch: any, getState: any) => {
  const {field, group, currentSeason, pointsGroups, pointsCurrentGroupDate} = getState().map;
  const fileName = `${group.name}_${field.Name}`;
  const pointsToExport = [] as any;
  const checkedPoints = pointsGroups[pointsCurrentGroupDate]?.filter(
    (point: SamplingPoint) => point.properties.checked
  );
  const isMixedDates = pointsCurrentGroupDate === 'all' && isDifferentDatesPoints(checkedPoints);
  const groupFormattedDate = isMixedDates
    ? 'mixed_dates'
    : pointsCurrentGroupDate === 'all'
    ? moment(checkedPoints[0].properties.timedate, GLOBAL_FORMAT_DATE).format(GLOBAL_FORMAT_DATE) // if all is selected but there are points only for one date
    : moment(pointsCurrentGroupDate, formatDate()).format(GLOBAL_FORMAT_DATE);

  checkedPoints.forEach((point: SamplingPoint, index: number) => {
    let _point = {
      ...point,
      geometry: {
        type: 'Point',
        coordinates: [...point.geometry.coordinates],
      },
    };

    _point.geometry.coordinates = [_point.geometry.coordinates[1], _point.geometry.coordinates[0]];
    const {title, n_result, n_result2, timedate} = _point.properties;
    _point.properties = {
      fieldName: field.Name,
      farmName: group.name,
      Sample_ID: title,
      Nitrogen: n_result,
      Nitrate_PPM: n_result2 || '',
      GroupDate: moment(timedate).format('YYYY-MM-DD'),
      Classes: _point.properties.classes || 0,
      SamplingType: _point.properties.samplingType || '',
      Description: _point.properties.description || '',
    };
    //@ts-ignore
    if (currentSeason.cropType && SAMPLING_GROWTH_STAGES[currentSeason.cropType]) {
      //@ts-ignore
      const gs = SAMPLING_GROWTH_STAGES[currentSeason.cropType].find(
        (el: string) => el === point.properties.growthStage
      );
      _point.properties.Growth_Stage = gs || '';
    }

    pointsToExport.push(_point);
  });

  downloadFile(
    tokml(featureCollection(pointsToExport)),
    `Sampling_Points_${fileName}_${groupFormattedDate}.kml`
  );
};
