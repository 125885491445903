import {t, FormattedMessage} from 'i18n-utils';
import React, {memo, useMemo} from 'react';
import {FontIcon} from 'react-md';
import {cropSubtype2Label, genKey, getCropLabelById} from '_utils';
import './index.scss';
import {CropAvatar} from '../index';
import {PartField} from 'containers/map/icons';
import {toggleTooltip} from '../../_actions';
import {useDispatch} from 'react-redux';

type Props = {
  cropType?: string;
  cropSubType?: string;
  showDate?: boolean;
  startDate?: string;
  endDate?: string;
  inlineDate?: boolean;
  onlyHarvest?: boolean;
  onlyPlanting?: boolean;
  dateText?: string;
  checked?: boolean;
  onClick?: () => void;
  hasGeometry?: boolean;

  messageAppearance?: 'info' | 'warning';
  message?: string;
};

const CropInfo = memo(
  ({
    cropType = '',
    cropSubType = '',
    showDate = true,
    startDate = '',
    endDate = '',
    inlineDate = false,
    onlyHarvest = false,
    onlyPlanting = false,
    dateText = '',
    checked = false,
    onClick = null,
    hasGeometry = false,
    messageAppearance = 'warning',
    message,
  }: Props) => {
    const id = useMemo(() => genKey(), []);
    const dispatch = useDispatch();

    const dates =
      !startDate && !endDate ? null : (
        <>
          {onlyHarvest ? '' : <span>{startDate}</span>} {onlyHarvest || onlyPlanting ? '' : ' - '}
          {onlyPlanting ? '' : <span>{endDate}</span>}
        </>
      );

    return (
      <div className={'crop-info'}>
        <div className="crop-info__avatar-wrapper">
          {hasGeometry && <PartField className="crop-info__geometry" size={14} />}
          <CropAvatar
            icon={checked ? <FontIcon iconClassName={'fas fa-check-circle'} /> : null}
            onClick={onClick}
            className={`crop-info__avatar ${checked ? 'checked' : ''}`}
            cropType={checked ? '' : cropType}
          />
        </div>
        <div className="crop-info__right-block">
          <div>
            <div className={`crop-info__crop-type ${inlineDate ? 'inline' : ''}`}>
              {cropType
                ? t({id: cropType, defaultMessage: getCropLabelById(cropType)})
                : getCropLabelById(cropType)}
              {cropSubType ? (
                <span className={`crop-info__crop-type__subtype`}>
                  &nbsp;-&nbsp;
                  {t({id: cropSubType, defaultMessage: cropSubtype2Label(cropType, cropSubType)})}
                </span>
              ) : null}

              {showDate && inlineDate ? (
                <div className={`crop-info__dates-range inline`}>
                  {dateText}
                  {dates}
                </div>
              ) : null}
            </div>
            {showDate && !inlineDate ? (
              <div className="crop-info__dates-range">
                {dateText}
                {dates}
              </div>
            ) : null}
          </div>
          {message ? (
            <div
              className="crop-info__msg"
              data-tip=""
              data-for={id}
              onMouseEnter={() =>
                dispatch(
                  toggleTooltip({
                    id,
                    content: message,
                    place: 'left',
                  })
                )
              }
            >
              <FontIcon>info_outline</FontIcon>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);
export default CropInfo;
