import {t} from 'i18n-utils';
import React from 'react';
import {LegendCheckbox, CardChartLegendWrapper} from './crop-stress-card.styled';
import cn from 'classnames';
import {getGetURLParam, sum} from '_utils';
import {CropStressChartLabels} from '../types';

type LegendItem = {
  label: CropStressChartLabels;
  count: number;
  color: string;
  selected: boolean;
};

type Props = {
  data: LegendItem[];
  onChange: (item: LegendItem, value: boolean) => void;
};

const CardChartLegend = ({data, onChange}: Props) => {
  if (getGetURLParam('cpCheckBx')) {
    // test new checkbox view of legend
    return (
      <CardChartLegendWrapper>
        {data.map(item => (
          <LegendCheckbox
            onChange={(value: boolean) => {
              onChange(item, value);
            }}
            checked={item.selected}
            color={item.color}
            id={item.label}
            key={item.label}
            name={item.label}
            label={`${item.label} (${item.count})`}
          />
        ))}
      </CardChartLegendWrapper>
    );
  }

  return (
    <ul className="line-chart-legend">
      {data.map((item, i) => (
        <li
          key={'legend-item' + item.label}
          onClick={() => onChange(item, !item.selected)}
          className={cn({
            'line-chart-legend-item': true,
            deselected: !item.selected && !data.every(it => !it.selected),
          })}
        >
          <div>
            <span className="item-color" style={{backgroundColor: item.color}} />
            <span className="item-name">{t({id: item.label})}</span>
          </div>
          <div>
            <span className="item-percentage">
              {Math.round((item.count / sum(data.map(label => label.count))) * 100)}%
            </span>
            <span className="item-count">({t({id: '{count} crops'}, {count: item.count})})</span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CardChartLegend;
