import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import L from 'leaflet';
import './index.sass';
import {GeoJSON} from 'react-leaflet';
import {connect, ConnectedProps} from 'react-redux';
import moment from 'moment';
import AnomalyLabelsDropdown from '../labels-dropdown';

import {setDate, togglePopup} from '../../../actions';
import {removePremiumAnomaly, updatePremiumAnomaly} from 'containers/map/actions/anomalies-actions';
import {getTrendsData} from 'containers/map/actions/analytics-actions';
import {showNote} from '_actions';
import MapPopup from 'components/map-popup';
import {getAnalyticsItemColor} from '_utils';
import {COLOR_GRAY, COLOR_BLUE, COLOR_DARK_BLUE} from '_constants/geo-colors';
import {IInitialMapState} from 'containers/map/types';
import {IAnomaly, TAnomalyProps, AnomalyHistoryItem} from '../types';
import {Feature} from 'geojson';
import {LoginState} from '../../../../login/types';
import {RequestStatus} from '../../../../../types';
import Mixpanel from '_utils/mixpanel-utils';

type AnomaliesOverlayProps = ConnectedProps<typeof connector>;

class AnomaliesOverlay extends Component<AnomaliesOverlayProps> {
  render() {
    const {
      geometriesOnMap,
      premiumAnomalies: {list, status},
    } = this.props;
    const sortedAnomaliesRings = [
      ...list.filter(a => !a.properties.clogged_nozzle),
      ...list.filter(a => a.properties.clogged_nozzle),
    ];

    if (!geometriesOnMap || status === RequestStatus.Idle) {
      return null;
    }
    return sortedAnomaliesRings.map((anomaly, i) => (
      <Anomaly key={'anomaly-overlay' + i} anomaly={anomaly} {...this.props} />
    ));
  }
}

type AnomalyProps = ConnectedProps<typeof connector> & {
  anomaly: IAnomaly;
};

class Anomaly extends Component<AnomalyProps> {
  onChangeProp<K extends keyof TAnomalyProps>(prop: K, value: TAnomalyProps[K], anomaly: IAnomaly) {
    this.props.updatePremiumAnomaly({
      id: anomaly.properties.anomaly_id,
      prop,
      value,
      anomaly,
    });
  }

  openAnomalyDate(anomaly: AnomalyHistoryItem) {
    const anomalyDate = moment(anomaly.sensing_date, 'YYYY-MM-DD');
    const existingDate = Object.keys(this.props.currentDates).find(date => {
      return moment(date, 'DD/MM/YYYY').isSame(anomalyDate, 'day');
    });
    if (!existingDate) {
      return;
    }
    this.props.setDate(existingDate);
    this.props.togglePopup(anomaly.id);
  }

  onEachFeature = (feature: Feature, layer: L.Path) => {
    const {anomaly_id, snoozed, checked, label, color, priority} = feature.properties;
    const {feature: tabName, openPopupId} = this.props;
    layer.setStyle({
      fillOpacity: 0.8,
    });

    if (priority === 'new') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#435DA0',
      });
    }

    if (priority === 'low') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#43A047',
      });
    }

    if (priority === 'med') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#F1CF1E',
      });
    }

    if (priority === 'high') {
      layer.setStyle({
        color: '#30614F',
        fillColor: '#FA6767',
      });
    }

    if (label === 'irrigation_issue') {
      layer.setStyle({
        color: '#db03fc',
        weight: 1.6,
        fillOpacity: 0,
      });
    }

    if (snoozed) {
      layer.setStyle({
        color: COLOR_GRAY,
        fillOpacity: 0.2,
      });
    }

    if (openPopupId === anomaly_id) {
      layer.setStyle({
        color: COLOR_DARK_BLUE,
        fillColor: COLOR_BLUE,
      });
    }

    if (checked) {
      layer.setStyle({
        color: COLOR_DARK_BLUE,
        fillColor: COLOR_BLUE,
      });
    }

    if (!label) {
      layer.setStyle({
        dashArray: '4',
      });
    }

    const isAnalitycs = tabName === 'analytics';
    if (isAnalitycs && color) layer.setStyle({color});

    layer.on({
      click: e => {
        const anomaly = e.target.feature;
        if (isAnalitycs) {
          //@ts-ignore
          L.DomEvent.stopPropagation(e);
          const {
            properties: {color, anomaly_id},
          } = anomaly;
          const layerColor = !color ? getAnalyticsItemColor() : null;
          this.props.updatePremiumAnomaly({
            id: anomaly_id,
            prop: 'color',
            value: layerColor,
            anomaly,
          });
          if (layerColor) {
            Mixpanel.displayCropStressOnAnalytics(anomaly_id);
            this.props.getTrendsData();
          }
        } else {
          this.props.togglePopup(anomaly_id);
        }
      },
    });
  };

  onDeleteAnomaly = (anomalyId: number) => {
    this.props.removePremiumAnomaly([anomalyId]);
  };

  render() {
    const {
      anomaly,
      range,
      currentSensor,
      measurement,
      readOnly,
      openPopupId,
      togglePopup,
    } = this.props;
    const {anomaly_id} = anomaly.properties;
    const popup = openPopupId && openPopupId === anomaly_id;
    return (
      <>
        <GeoJSON
          data={anomaly}
          pane={'field-geometry'}
          onEachFeature={this.onEachFeature}
          key={new Date().getTime()}
        />
        {popup && (
          <MapPopup
            isAnomaly
            data={anomaly}
            measurement={measurement}
            currentSensor={currentSensor}
            type={'low'}
            range={range}
            sectionName={t({id: 'Stress detected', defaultMessage: 'Stress detected'})}
            onClose={() => togglePopup(undefined)}
            onDelete={() => this.onDeleteAnomaly(anomaly.properties.anomaly_id)}
            onSnooze={() =>
              this.props.updatePremiumAnomaly({
                id: anomaly.properties.anomaly_id,
                prop: 'snoozed',
                value: anomaly.properties.snoozed ? 0 : 1,
                anomaly,
              })
            }
            LabelDropdown={({label}) => (
              <AnomalyLabelsDropdown
                miniButton
                disabled={readOnly}
                anomaly={anomaly}
                label={label}
                onChange={this.onChangeProp.bind(this)}
              />
            )}
            sensingDate={anomaly.properties.sensing_date}
            onHistoryItemClick={(anomaly: AnomalyHistoryItem) => {
              this.openAnomalyDate(anomaly);
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: LoginState}) => ({
  currentDate: map.currentDate,
  currentDates: map.currentDates,
  currentSensor: map.currentSensor,
  geometriesOnMap: map.geometriesOnMap,
  measurement: login.user.settings.measurement,
  feature: map.feature,
  range: map.histogram.range,
  premiumAnomalies: map.premiumAnomalies,
  readOnly: map.group.readOnly,
  openPopupId: map.openPopupId,
});

const connector = connect(mapStateToProps, {
  showNote,
  updatePremiumAnomaly,
  setDate,
  getTrendsData,
  togglePopup,
  removePremiumAnomaly,
});

export default connector(AnomaliesOverlay);
