import Yup from 'yup';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  name: Yup.string().required('Name is required'),
  surname: Yup.string().required('Surname is required'),
  password: Yup.string().test('password', '', function() {
    return this.parent.id === 'new' && !this.parent.password
      ? this.createError({path: this.path, message: 'Password is required'})
      : true;
  }),
  perm: Yup.string().required('Permission is required'),
  groupIds: Yup.array().of(Yup.number()),
  phone: Yup.string().test('phone', '', function() {
    return this.parent.isPhoneValid
      ? true
      : this.createError({path: this.path, message: 'Invalid phone number'});
  }),
});
