export type Config = {
  baseUrl: string;
  authHeader: string;
  env: 'production' | 'development';
  version: number;
  app_version: string;
  branch: string;
  featurePack: 'main' | 'carbon';

  metriks: {
    yMetrika: number;
    gAnalytics: string;
    gtmId?: string;
  };
  captcha: {
    sitekey: string;
    theme: string;
  };
  intercomSettings: {
    app_id: string;
    alignment: string;
  };
  hubspot: {
    portalId: string;
    formGuid: string;
  };

  mp_token?: string;
};

const carbonPort = 5000;
const carbonFeaturePack =
  Number(window?.location.port) === carbonPort || window?.location.host.includes('cargil');

const config: Config = {
  baseUrl: 'https://dev.flurosat.com/',
  authHeader: 'x-access-token',
  env: 'development',
  version: 0,
  app_version: '__APP_VERSION__',
  branch: '__BRANCH__',
  featurePack: carbonFeaturePack ? 'carbon' : 'main',

  metriks: {
    yMetrika: 49021181,
    gAnalytics: 'UA-126135971-1',
  },
  captcha: {
    sitekey: '6LfN0DoUAAAAAIIoVzv6YGdeHOXNjonAaJXCP13a',
    theme: 'light',
  },
  intercomSettings: {
    app_id: 'gvmhgf3m',
    alignment: 'left',
  },
  hubspot: {
    portalId: '4430549',
    formGuid: 'e3f6fe2a-31d1-49ac-b3f4-b4b14c93bf64',
  },
};

//@ts-ignore
const c: Config = {...config, ...(window.FLUROSENSE_CONFIG || {})};

console.log('VERSION: ', c.version);

export default c;
