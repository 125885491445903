import {t, FormattedMessage} from 'i18n-utils';
import React, {Component, FunctionComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import moment from 'moment';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {IInitialMapState, SamplingPoint, SamplingPointProps} from '../../types';
import L from 'leaflet';

// Sampling point components
import AllPointsGroupsList from './list-groups';
import PointsList from './points-list';
import EditGroupPopUp from './edit-group-pop-up';
import EditPointPopUp from './edit-point-pop-up';
import PointsGroupHeader from './group-header';
import NmapPopUp from './nmap-pop-up';

// helper components
import {Button, FontIcon, SelectionControl} from 'react-md';
import {DownloadIcon} from '../../icons';
import {ReadOnly, Ln} from 'components';

// utils
import {formatDate, getImageStatus} from '_utils';
import {
  getPointsIds,
  getCheckedPoints,
  isDifferentDatesPoints,
  getPointsGroupKeys,
} from '../../utils/sampling-points';

//actions
import {saveSuggestedPoints, toggleZoning} from '../../actions/zoning-actions';
import {
  setCurrentMarker,
  pointsSetGroupDate,
  bulkUpdatePointsProp,
  exportPointsToKml,
  removeSamplingPoint,
} from '../../actions/sampling-points';

//styles
import './index.scss';
import {LoginState} from '../../../login/types';
import {AsyncStatusType} from 'modules/ui-helpers';
import SimplePreloader from 'components/simple-preloader';

type Props = ConnectedProps<typeof connector> & {
  leafletElement: L.Map;
};

type State = {
  editGroup: string;
  editPointPopUpOpen: boolean;
  nmapGroupDateToProcess: string;
};

type ContainerProps = {
  isZoning?: boolean;
  children?: any;
};

const Container = ({isZoning, children}: ContainerProps) => (
  <div className={'section-container sampling-points-tab-container onboarding-step__tsp-tab'}>
    <h3 className={'tab-title'}>
      <div>
        {t({id: 'Points'})}
        {isZoning && (
          <SimplePreloader
            statusKeys={[AsyncStatusType.mainZoning, AsyncStatusType.zoningImageOverlay]}
          />
        )}
      </div>
      {/*<Ln*/}
      {/*  blank*/}
      {/*  external*/}
      {/*  href={'https://flurosense.com/knowledge-base/?epkb_post_type_1=tissue-sampling-points'}*/}
      {/*>*/}
      {/*  <FontIcon>help_outline</FontIcon>*/}
      {/*</Ln>*/}
    </h3>
    {children}
  </div>
);

class MapTissueSampling extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editPointPopUpOpen: false,
      nmapGroupDateToProcess: '',
      editGroup: '',
    };
  }

  toggleAddMarkerPopUp = (value: boolean) => {
    const {currentPointId} = this.props;
    if (currentPointId === 'new') {
      this.props.removeSamplingPoint('new');
    }
    if (currentPointId) this.props.setCurrentMarker('');

    this.setState({editPointPopUpOpen: value});
  };

  closeEditMarker = () => {
    this.toggleAddMarkerPopUp(false);
    this.props.setCurrentMarker('');
    try {
      this.props.leafletElement.closePopup();
    } catch (e) {
      console.warn(e.message);
    }
  };

  calcEditGroupDate = () => {
    const {pointsCurrentGroupDate} = this.props;
    const checkedPoints = getCheckedPoints();
    const mostRecentGroupDate = getPointsGroupKeys()[0];

    const calculatedDate =
      isDifferentDatesPoints(checkedPoints) || this.isAllGroups()
        ? mostRecentGroupDate
        : pointsCurrentGroupDate;

    return moment(calculatedDate, formatDate()).format(GLOBAL_FORMAT_DATE);
  };

  toggleEditGroupPopUp = (groupDate?: string) => {
    this.setState({
      editGroup: groupDate !== undefined ? groupDate : this.calcEditGroupDate(),
    });
  };

  savePointsGroup = (date: string) => {
    this.toggleEditGroupPopUp('');

    this.props.bulkUpdatePointsProp(
      getPointsIds(this.props.pointsCurrentGroupDate),
      'timedate',
      date
    );
    this.props.pointsSetGroupDate(moment(date, GLOBAL_FORMAT_DATE).format(formatDate()));
  };

  bulkChangePointsProp = (prop: keyof SamplingPointProps, value: any) => {
    this.props.bulkUpdatePointsProp(
      getPointsIds(this.props.pointsCurrentGroupDate, prop !== 'checked'),
      prop,
      value
    );
  };

  isAllGroups = () => this.props.pointsCurrentGroupDate === 'all';

  isAnyPointSelected = () =>
    this.props.pointsGroups[this.props.pointsCurrentGroupDate]?.some(
      (point: SamplingPoint) => point.properties.checked
    );

  toggleNmapPopUp = (value: string) => {
    this.setState({nmapGroupDateToProcess: value});
  };

  render() {
    const {
      isReadOnly,
      pointsGroups,
      pointsCurrentGroupDate,
      currentPointId,
      drawControl,
      imageStatus,
    } = this.props;

    const {editGroup} = this.state;
    const isEditOrDraw = drawControl.isDrawingMode || drawControl.isEditingMode;
    const isAllGroups = this.isAllGroups();

    const isAnySelected = this.isAnyPointSelected();
    const {isNotImagery} = imageStatus;

    if (isNotImagery) {
      return <Container />;
    }

    return (
      <Container isZoning={this.props.isZoning}>
        <SelectionControl
          id="toggle-zoning-tsp"
          type="switch"
          label={t({id: 'Toggle Zoning'})}
          labelBefore={true}
          name="toggle-zoning-tsp"
          disabled={this.props.currentSensor === 'TCI' || this.props.currentSensor === 'NC'}
          onChange={this.props.toggleZoning}
          checked={this.props.isZoning}
          style={{marginBottom: '10px'}}
        />
        {Object.keys(this.props.zoningPoints).length ? (
          <Button
            onClick={this.props.saveSuggestedPoints}
            className="element-full-width mt-5 save-points"
            raised
            primary
          >
            {t({id: 'Save points'})}
          </Button>
        ) : null}
        <EditPointPopUp
          onHide={this.closeEditMarker}
          visible={!isEditOrDraw && (this.state.editPointPopUpOpen || !!currentPointId)}
          currentPointId={currentPointId}
          currentPoint={this.props.currentPoint}
          isReadOnly={isReadOnly}
        />
        <EditGroupPopUp
          onHide={() => this.toggleEditGroupPopUp('')}
          visible={!!editGroup}
          groupDate={editGroup}
          updatePointsGroupDate={this.savePointsGroup}
        />
        <NmapPopUp
          onHide={() => this.toggleNmapPopUp('')}
          visible={!!this.state.nmapGroupDateToProcess}
          pointsGroupDateToGenerate={this.state.nmapGroupDateToProcess}
        />
        <div className={'buttons-wrapper'}>
          <ReadOnly>
            <Button
              id="add-new-point"
              className="add-button btn-with-icon"
              primary
              disabled={isReadOnly}
              raised
              onClick={() => this.toggleAddMarkerPopUp(true)}
              iconEl={<FontIcon>add</FontIcon>}
            >
              {t({id: 'Add new'})}
            </Button>
          </ReadOnly>

          <ReadOnly>
            <Button
              id="tsp-download-group"
              primary
              disabled={!isAnySelected}
              onClick={this.props.exportPointsToKml}
              className="export-group-btn btn-with-icon"
              raised
              iconEl={<DownloadIcon />}
            >
              {t({id: 'Export'})}
            </Button>
          </ReadOnly>
        </div>
        <PointsGroupHeader
          isRoot={true}
          pointsGroups={pointsGroups}
          currentGroupDate={pointsCurrentGroupDate}
          isReadOnly={isReadOnly}
          toggleNmapPopUp={(val: string) => this.toggleNmapPopUp(val)}
          bulkChangePointsProp={this.bulkChangePointsProp}
          isAnySelected={isAnySelected}
          openEditGroupPopUp={() => this.toggleEditGroupPopUp()}
        />
        {!isAllGroups && pointsGroups?.[pointsCurrentGroupDate]?.length ? (
          <PointsList currentGroupPoints={pointsGroups[pointsCurrentGroupDate]} />
        ) : (
          <AllPointsGroupsList
            isReadOnly={isReadOnly}
            pointsGroups={pointsGroups}
            toggleNmapPopUp={this.toggleNmapPopUp}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({map, login}: {map: IInitialMapState; login: LoginState}) => ({
  currentSensor: map.currentSensor,
  currentPointId: map.currentPointId,
  currentPoint: map.currentPoint,
  zoningPoints: map.zoning.points,
  pointsGroups: map.pointsGroups,
  pointsCurrentGroupDate: map.pointsCurrentGroupDate,
  isZoning: map.isZoning,
  isReadOnly: map.group.readOnly && !login.user.settings.onboarding.fullTour,
  drawControl: map.drawControl,
  imageStatus: getImageStatus(map),
});

const connector = connect(mapStateToProps, {
  setCurrentMarker,
  pointsSetGroupDate,
  saveSuggestedPoints,
  bulkUpdatePointsProp,
  toggleZoning,
  exportPointsToKml,
  removeSamplingPoint,
});
export default connector(MapTissueSampling);
