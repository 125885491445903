import {
  Biomass,
  CropGrowth,
  CropStatus,
  TreeStatus,
  CropStressChartLabels,
  FieldVariabilityStatus,
} from 'containers/map/features/crop-performance/types';

export type CPFilterState = {
  filters: {[type in CPFilterType]: CPFilter[]};
  filterStatus: {[seasonId: number]: CPFilterType | undefined};
};

export enum CPFilterType {
  CROP_TYPE = 'cropType',
  CROP_VARIETY = 'cropVariety',
  FIELDS_VARIABILITY = 'fieldsVariability',
  CROP_STATUS = 'cropStatus',
  TREE_STATUS = 'treeStatus',
  CROP_GROWTH = 'cropGrowth',
  CROP_STRESS = 'cropStress',
  BIOMASS = 'biomass',
  FIELDS_WITH_PLANTING_AREAS = 'fieldsWidthPlantingAreas',
  BIOMASS_OVER_TIME = 'biomassOverTime',
}

export const isCPFilter = (maybeFieldFilter: string): maybeFieldFilter is CPFilterType => {
  return Object.values(CPFilterType).includes(maybeFieldFilter as CPFilterType);
};

export type CPFilter =
  | CropTypeFilter
  | CropVarietyFilter
  | CropStatusFilter
  | TreeStatusFilter
  | CropGrowthFilter
  | CropStressFilter
  | BiomassFilter
  | FieldsVariabilityFilter
  | FieldsWithPlantingAreasFilter
  | BenchmarkFilter;

export type FieldsWithPlantingAreasFilter = {
  type: CPFilterType.FIELDS_WITH_PLANTING_AREAS;
  stage: 0;
  value: number;
  color: string;
};

export type CropTypeFilter = {
  type: CPFilterType.CROP_TYPE;
  stage: 1;
  value: string;
  color: string;
};

export type CropVarietyFilter = {
  type: CPFilterType.CROP_VARIETY;
  stage: 1;
  value: string;
  label: string; // use label because the app sees subtypes as raw string and we should toa user beauty one
  color: string;
};

export type FieldsVariabilityFilter = {
  type: CPFilterType.FIELDS_VARIABILITY;
  stage?: 2;
  value: FieldVariabilityStatus;
  color: string;
};

export type CropStatusFilter = {
  type: CPFilterType.CROP_STATUS;
  stage: 3;
  value: CropStatus;
  color: string;
};

export type TreeStatusFilter = {
  type: CPFilterType.TREE_STATUS;
  stage: 3;
  value: TreeStatus;
  color: string;
};

export type CropGrowthFilter = {
  type: CPFilterType.CROP_GROWTH;
  stage: 4;
  value: CropGrowth;
  color: string;
};

export type CropStressFilter = {
  type: CPFilterType.CROP_STRESS;
  color: string;
  value: CropStressChartLabels;
  stage: 4;
};

export type BiomassFilter = {
  type: CPFilterType.BIOMASS;
  stage: 4;
  value: Biomass;
  color: string;
};
export type BenchmarkFilter = {
  type: CPFilterType.BIOMASS_OVER_TIME;
  stage: 4;
  value: number;
  color: string;
};

// Field based filters (season).
export const stage0Filters = [CPFilterType.FIELDS_WITH_PLANTING_AREAS];
export const stage1Filters = [CPFilterType.CROP_TYPE, CPFilterType.CROP_VARIETY];
export const stage2Filters = [CPFilterType.FIELDS_VARIABILITY];
// Record based filters (cs&g, crop performance).
export const stage3Filters = [CPFilterType.TREE_STATUS, CPFilterType.CROP_STATUS];
export const stage4Filters = [
  CPFilterType.CROP_GROWTH,
  CPFilterType.BIOMASS,
  CPFilterType.CROP_STRESS,
  CPFilterType.BIOMASS_OVER_TIME,
];
