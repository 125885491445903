import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 10px 20px;

  h3 {
    font-size: 17px;
    margin-bottom: 5px;
  }

  @media (min-width: 415px) {
    h3 {
      font-size: 20px;
    }
  }
  .fluro-sticky {
    background-color: #fff;
    padding: 10px;
    display: flex;
    width: calc(100% + 38px);
    margin-left: -19px;
    margin-right: -19px;
  }
  .close-btn {
    margin-right: auto;
  }
`;

export const MainTitleBlock = styled.div`
  @media (min-width: 415px) {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  > h3 {
    margin-bottom: 0;
    font-weight: 100;
    font-size: 25px;
  }
`;

export const CardBlock = styled.div`
  border: 1px solid #d6d8da;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px 24px;

  .access-table {
    th,
    td {
      padding-left: 0;
    }
  }
`;

export const CardBlockTwoColumn = styled(CardBlock)`
  justify-content: space-between;
  width: 100%;

  @media (min-width: 415px) {
    display: flex;

    > div {
      width: 45%;
    }
  }
`;

export const InlineBlock = styled.div`
  align-items: center;

  @media (min-width: 415px) {
    display: flex;
  }
`;

export const ConfirmEmailWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BackWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  transition all 0.3s;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    .md-icon {
      transform: translateX(10px);
    }
  }

  .md-icon {
    color: #399d2b;
    transition all 0.3s;
  }
`;
