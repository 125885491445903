import {t, FormattedMessage} from 'i18n-utils';
import React, {ReactElement} from 'react';
import {hackGetState} from 'store';
import moment from 'moment';
import get from 'lodash.get';
import {GLOBAL_FORMAT_DATE, GLOBAL_APP_DATE_FORMAT} from '_constants';
import {AppIcons, TypeIcon} from 'components/icons';
import {getDateType, getGetURLParam, getImagePath} from '_utils';
import {parseSensor, TFeature, TSensor} from 'types';
import {DatesObj, SamplingPoint, TreesTypes} from '../types';
import {CloudyText} from 'components/clouds/clouds.styled';
import Clouds from 'components/clouds/Clouds';
import {Tooltipped, FontIcon} from 'react-md';
import cn from 'classnames';
import {isBrowser} from 'react-device-detect';

import {LeafIcon} from '../icons';

import {
  formatDate,
  sortDates,
  getCurrentImageName,
  isDateInRange,
  setGetParamToURL,
  getFieldFormattedDates,
  sensorView,
  getDateFromKey,
} from '_utils';

import {countFieldsWithTreeDataByDay, getTreeDataByDate, getTreeIndexes} from './trees';
import {showNote} from '_actions';
import {convertFromMesureToSquareMeters, getMeasurement} from '_utils';

import InfoBlock from 'components/info-block';
import {FieldTag, Field, IZoning, SourceType, TInfoExt, Zone} from '../types';
import {IAnomaly, LowPerformingAnomaliesStore} from '../features/anomalies/types';
import {AppStore} from '../../../reducers';
import {reportError} from '../../error-boundary';

export {default as SeasonUtils} from './season';

type DrawDateIconProps = {
  type?: SourceType | '';
  date: string;
  nmap?: boolean;
  children?: string | ReactElement;
  appIcons?: {name: string; count?: number}[];
  cloudy?: number;
};

export const drawDateIcon = ({
  type,
  date = '',
  nmap = false,
  children = '',
  appIcons = [],
  cloudy = 0,
}: DrawDateIconProps) => {
  const d = moment(date, GLOBAL_APP_DATE_FORMAT).format(formatDate());
  type = type || getDateType(date) || SourceType.Satellite;

  const NMapIcon = () =>
    nmap ? <LeafIcon className={appIcons.length ? 'custom-date__item' : ''} /> : null;

  return (
    <div className={'custom-date'}>
      <TypeIcon type={type} className={`custom-date__item ${type}`} />
      <span
        data-date={`${d} - ${type}`}
        className={`date-container ${nmap || appIcons.length ? 'custom-date__item' : ''}`}
      >
        {d}
        {children}
      </span>
      <NMapIcon />
      <AppIcons appIcons={appIcons} className={'custom-date__item app'} />
      <Clouds percent={cloudy} />
    </div>
  );
};

type DrawRemoteSensingDateIconProps = {
  type: SourceType | 'eye-hidden';
  date: string;
  nmap: boolean;
  events?: {labelEvent?(): void; iconEvent?(): void; iconLabel?: string; onEditCloudy?: () => void};
  appIcons?: {name: string; count?: number}[];
  cloudy: number;
  cloudV2?: number;
  isAdmin?: boolean;
  isCloudValueModified?: boolean;
};

export const drawRemoteSensingDateIcon = ({
  type = SourceType.Satellite,
  date,
  nmap,
  events,
  appIcons,
  cloudy = 0,
  cloudV2,
  isAdmin,
  isCloudValueModified = false,
}: DrawRemoteSensingDateIconProps) => {
  const d = moment(date, GLOBAL_APP_DATE_FORMAT).format(formatDate());
  const {labelEvent = null, iconEvent = null, iconLabel = null, onEditCloudy} = events;
  const sourceIcon = (
    <div className={'tooltip-holder'}>
      <TypeIcon
        className={`source-type-icon ${type}`}
        type={type}
        onClick={ev => {
          ev.stopPropagation();
          iconEvent();
        }}
      />
    </div>
  );
  return (
    <div className={'remote-sensing-date-container'} onClick={labelEvent}>
      <div className="sub-block">
        {iconLabel ? (
          <Tooltipped label={iconLabel} position={'right'}>
            {sourceIcon}
          </Tooltipped>
        ) : (
          sourceIcon
        )}
        <span className={`date date-type-${type}`}>{d}</span>
        {nmap ? <LeafIcon className={'leaf-icon'} /> : null}
        <AppIcons appIcons={appIcons} className={'app-icon'} />
      </div>

      <div className="sub-block">
        <Clouds percent={cloudy} />
        {type?.includes('satellite') ? (
          <CloudyText className={cn({modified: isCloudValueModified && isAdmin})}>
            {cloudy}%
          </CloudyText>
        ) : null}
        {type?.includes('satellite') && cloudV2 !== undefined ? (
          <b style={{paddingLeft: '5px'}}>({cloudV2}%)</b>
        ) : null}

        {isAdmin && type?.includes('satellite') && (
          <Tooltipped label="Edit cloudy %" position="left">
            <div className="tooltip-holder">
              <FontIcon
                className="edit-cloudy-btn"
                onClick={ev => {
                  ev.stopPropagation();
                  onEditCloudy?.();
                }}
              >
                edit
              </FontIcon>
            </div>
          </Tooltipped>
        )}
      </div>
    </div>
  );
};

export const getZones = (zones: Zone[] = []) => {
  return `b=${zones
    .filter((z, i) => i)
    .map(z => z.min)
    .join(',')}&`;
};

export const getInitZones = (zoning: IZoning) => {
  if (zoning.method === 'custom') {
    const max = zoning.zonesRange[1];

    const classes = parseInt(zoning.classes, 10);
    const c = Math.floor(max / classes);
    let points = [];

    for (let x = 1; x <= classes; x++) {
      points.push(c * x);
    }

    return `b=${points.filter((z, i) => i).join(',')}&`;
  }

  return '';
};

export const prepareMinArea = (value: string | number) => {
  if (typeof value === 'string') {
    return value !== '' ? parseFloat(value) : 0;
  }
  return isFinite(value) && !isNaN(value) ? value : 0;
};

export const getImageUrl = () => {
  const {currentDate, currentDates, currentSensor} = hackGetState().map;

  const url = get(currentDates, `${currentDate}.${currentSensor}.url`, '');
  if (!url) return '';
  const png = url.replace('.png', '');
  return getImagePath(png);
};

export const getZoningUrlParams = (zoning: IZoning) => {
  const {classes, method, smoothing, area, bufferValue, isMask} = zoning;
  const measurement = getMeasurement();
  const areaParam = `&area=${convertFromMesureToSquareMeters(area, measurement)}`;
  const bufferParam = bufferValue ? `&buf=${bufferValue}` : '';

  return `&c=${classes}&m=${method}&s=${smoothing}${areaParam}${
    isMask ? '&mask=2' : ''
  }${bufferParam}`;
};

export const getDatesFromAllFields = (
  fieldsWithDates: {[md5: string]: DatesObj},
  fields: Field[]
) => {
  try {
    let allDatesToShow = {} as {
      [date: string]: TInfoExt & {Areas: {Cloud: number; Area: number}[]};
    };
    Object.keys(fieldsWithDates).forEach(fieldMD5 => {
      const f = fields.find(f => f.MD5 === fieldMD5);

      Object.keys(fieldsWithDates[fieldMD5]).forEach(dayDate => {
        if (!allDatesToShow[dayDate]) {
          allDatesToShow[dayDate] = {
            ...fieldsWithDates[fieldMD5][dayDate],
            Areas: [{Cloud: fieldsWithDates[fieldMD5][dayDate].Cloud, Area: f.Area}],
          };
        } else {
          allDatesToShow[dayDate] = {
            ...allDatesToShow[dayDate],
            ...fieldsWithDates[fieldMD5][dayDate],
            Areas: [
              ...allDatesToShow[dayDate].Areas,
              {Cloud: fieldsWithDates[fieldMD5][dayDate].Cloud, Area: f.Area},
            ],
          };
        }
      });
    });

    // clouds cover weight average FORMULA: ((fieldArea * fieldCloudCoverPercent) + n ) / (fieldArea + n)
    Object.keys(allDatesToShow).forEach(k => {
      allDatesToShow[k].Cloud = Math.floor(
        allDatesToShow[k].Areas.reduce(
          (a: number, b: {Area: number; Cloud: number}) => a + b.Area * b.Cloud,
          0
        ) /
          allDatesToShow[k].Areas.reduce(
            (a: number, b: {Area: number; Cloud: number}) => a + b.Area,
            0
          )
      );
    });

    const sortedDates = sortDates(Object.keys(allDatesToShow)).reverse();
    const result = {} as {[date: string]: TInfoExt & {Areas: {Cloud: number; Area: number}[]}};
    sortedDates.forEach(date => {
      result[date] = {...allDatesToShow[date]};
    });

    return result;
  } catch (err) {
    console.log(err);
  }
};

export const showWarning = (feature: TFeature, key: string) => (dispatch: any) => {
  const message = getMessage(key);
  if (feature !== 'zoning') return;

  return dispatch(
    showNote({
      title: t({id: 'note.error', defaultMessage: 'Error'}),
      level: 'warning',
      message,
    })
  );
};

const getMessage = (key: string) => {
  switch (key) {
    case 'empty-points':
      return t({
        id: 'empty-points',
        defaultMessage:
          'To generate the recommendation, you need at least 1 TSP with a Nitrate value on your field.',
      });

    case 'no-weather':
      return t({
        id: 'no-weather',
        defaultMessage: 'No weather data available for the selected season.',
      });

    case 'no-tsp-group':
      return t({
        id: 'no-tsp-group',
        defaultMessage:
          'To generate the recommendation, you need at least 1 TSP with a Nitrate value on your field.',
      });

    case 'weather-missing':
      return t({id: 'weather-missing', defaultMessage: 'No weather data available.'});

    default:
      return '';
  }
};

/**
 * @param container takes a selector string example: #map;
 */
export const mapBarScrollTo = (container: string) => {
  setTimeout(() => {
    const elementToScroll = document.querySelector(container);
    if (elementToScroll) elementToScroll.scrollIntoView({block: 'start', behavior: 'smooth'});
  }, 100);
};

export const getAllVisibleGeometries = () => {
  const {
    lowPerfAnomalies,
    geometry,
    currentDate,
    currentDates,
    geometriesOnMap,
    wholeFarm: {isWholeFarmView},
  } = hackGetState().map;
  const geometriesToShow = geometriesOnMap
    ? getGeometriesToShow(geometry, currentDate, currentDates, isWholeFarmView)
    : [];
  const anomaliesToShow =
    geometriesOnMap || lowPerfAnomalies.isVisible ? getAnomaliesToShow(lowPerfAnomalies) : [];

  return [...geometriesToShow, ...anomaliesToShow];
};

export const getAnomaliesToShow = (lowPerfAnomalies: LowPerformingAnomaliesStore) => {
  const currentImageName = getCurrentImageName();
  const currentDateAnomalies = lowPerfAnomalies.list.uploadedROI?.features
    ? lowPerfAnomalies.list.uploadedROI
    : lowPerfAnomalies.list[currentImageName];

  return currentDateAnomalies ? currentDateAnomalies.features : [];
};

export const getGeometriesToShow = (
  geometry: IAnomaly[],
  currentDate: string,
  currentDates: TInfoExt[],
  isWF: boolean
) => {
  const currentDatesLength = Object.keys(currentDates).length;
  geometry = geometry.filter(
    g =>
      (!currentDatesLength || currentDate) &&
      g.properties.saved !== undefined &&
      g.properties.saved !== 'edit'
  );
  return isWF
    ? geometry
    : geometry.filter(
        g =>
          !currentDatesLength ||
          (moment(currentDate, GLOBAL_APP_DATE_FORMAT).isSameOrBefore(
            moment(g.properties.endDate, GLOBAL_FORMAT_DATE)
          ) &&
            moment(currentDate, GLOBAL_APP_DATE_FORMAT).isSameOrAfter(
              moment(g.properties.startDate, GLOBAL_FORMAT_DATE)
            ))
      );
};

export const getPerformanceGeometries = () => {
  const {
    lowPerfAnomalies,
    geometry,
    currentSeason,
    currentSensor,
    currentDate,
  } = hackGetState().map;

  try {
    const allDatesLowPerfAnomalies = Object.values(lowPerfAnomalies.list)
      .map(featureCollection => {
        if (!featureCollection.features) return false;

        const labeledFeaturesInCurrentSeason = featureCollection.features.filter(
          feature =>
            isDateInRange(feature.date, currentSeason.startDate, currentSeason.endDate) && // should be in this season
            feature.index === currentSensor && // sensor should be equal
            feature.properties.label // anomaly should have label
        );

        return (
          !featureCollection.savedAsRoi &&
          labeledFeaturesInCurrentSeason.length &&
          labeledFeaturesInCurrentSeason
        );
      })
      .filter(feature => feature)
      .flat(1);

    const geometriesToShow = geometry.filter(
      g => g.properties.saved !== undefined && g.properties.saved !== 'edit'
    );

    const isSameCurrentDate = (anomaly: IAnomaly) => {
      const {date, dateType} = anomaly;
      const anomalyDate = `${moment(date, GLOBAL_FORMAT_DATE).format(
        GLOBAL_APP_DATE_FORMAT
      )}-${dateType}`;
      return currentDate === anomalyDate;
    };

    const isInterSelect = (anomaly: IAnomaly) => {
      const {startDate, endDate} = anomaly.properties;
      return isDateInRange(
        moment.utc(currentDate, GLOBAL_APP_DATE_FORMAT).format(),
        startDate,
        endDate
      );
    };

    return {
      geometryToShow: [
        ...geometriesToShow.filter(el => isInterSelect(el)),
        ...allDatesLowPerfAnomalies.filter(
          el => el !== false && isSameCurrentDate(el) && el.index === currentSensor
        ),
      ],
      historyGeometries: [
        ...geometriesToShow.filter(el => !isInterSelect(el)),
        ...allDatesLowPerfAnomalies.filter(
          el => el !== false && !isSameCurrentDate(el) && el.index === currentSensor
        ),
      ],
    };
  } catch (err) {
    console.warn(err);
    return {geometryToShow: [], historyGeometries: []};
  }
};

export const getClosestGeometryDate = (geometry: IAnomaly) => {
  const {currentDates, currentDate} = hackGetState().map;
  const geometryStartDate = moment(geometry.properties.startDate, GLOBAL_FORMAT_DATE);
  const geometryEndDate = moment(geometry.properties.endDate, GLOBAL_FORMAT_DATE);
  const interSelectDates = Object.keys(currentDates).filter(date =>
    moment(date, GLOBAL_APP_DATE_FORMAT).isBetween(geometryStartDate, geometryEndDate)
  );
  // if(!interSelectDates.length) return false;

  const {0: lastDay, length: l, [l - 1]: firstDay} = interSelectDates;
  const firstDiff = getDiffDurationAsDay(firstDay, currentDate);
  const lastDiff = getDiffDurationAsDay(lastDay, currentDate);

  return firstDiff < lastDiff ? firstDay : lastDay;
};

export const getDiffDurationAsDay = (firstDate: string, lastDate: string) => {
  const count = moment
    .duration(
      moment(firstDate, GLOBAL_APP_DATE_FORMAT).diff(moment(lastDate, GLOBAL_APP_DATE_FORMAT))
    )
    .asDays();
  return Math.abs(count);
};

export const getNearestGeometryDate = (geometry: IAnomaly) => {
  const {currentDates, currentDate} = hackGetState().map;
  const dates = Object.keys(currentDates);
  const geometryStartDate = moment.utc(geometry.properties.startDate, GLOBAL_FORMAT_DATE);
  const geometryEndDate = moment.utc(geometry.properties.endDate, GLOBAL_FORMAT_DATE);
  const currentMomentDate = moment.utc(currentDate, GLOBAL_APP_DATE_FORMAT);
  let nearest = null as {diff: number; date: string; index: number};
  if (
    currentMomentDate.isSame(geometryStartDate, 'day') ||
    currentMomentDate.isSame(geometryEndDate, 'day')
  )
    return currentDate;

  if (!dates.length || currentMomentDate.isBetween(geometryStartDate, geometryEndDate)) return '';

  if (currentMomentDate.isSameOrBefore(geometryStartDate)) {
    // get closest date to anomaly start date
    dates.forEach((date, index) => {
      const diff = geometryStartDate.diff(moment(date, GLOBAL_APP_DATE_FORMAT));
      if (!nearest) nearest = {diff, date, index};

      if (Math.abs(nearest.diff) > Math.abs(diff)) nearest = {diff, date, index};
    });

    if (moment(nearest.date, GLOBAL_APP_DATE_FORMAT).isSameOrBefore(geometryStartDate))
      nearest.date = dates[nearest.index - 1] ? dates[nearest.index - 1] : nearest.date;
  } else {
    // get closest date to anomaly end date
    dates.forEach((date, index) => {
      const diff = geometryEndDate.diff(moment(date, GLOBAL_APP_DATE_FORMAT));
      if (!nearest) nearest = {diff, date, index};

      if (Math.abs(nearest.diff) > Math.abs(diff)) nearest = {diff, date, index};
    });

    if (moment.utc(nearest.date, GLOBAL_APP_DATE_FORMAT).isSameOrAfter(geometryEndDate))
      nearest.date = dates[nearest.index + 1] ? dates[nearest.index + 1] : nearest.date;
  }

  return nearest.date === currentDate ? '' : nearest.date;
};

export const checkFieldTagExist = (tag: FieldTag) => {
  const {field} = hackGetState().map;
  return field.tags && Array.isArray(field.tags) && field.tags.includes(tag);
};

export const createDatesMenuList = (
  datesObject: DatesObj,
  isCompare = false,
  isWholeFarmView = false
) => {
  if (!datesObject) return [];
  const menuItems = Object.keys(datesObject)
    .filter(el => !datesObject[el].Hidden)
    .map(date => {
      const nmap = isCompare ? false : !!datesObject[date].NMAP;
      const fieldsWithTreeData = isWholeFarmView && countFieldsWithTreeDataByDay(datesObject[date]);

      const appIcons = fieldsWithTreeData
        ? [{name: 'tree_analysis', count: fieldsWithTreeData}]
        : datesObject[date].appName?.map(app => ({name: app, count: 0}));

      return {
        label: drawDateIcon({
          type: getDateType(datesObject[date]),
          date,
          nmap,
          appIcons,
          cloudy: datesObject[date].Cloud,
        }),
        value: date,
      };
    });

  return isCompare || menuItems.length === 0
    ? menuItems
    : [
        ...menuItems,
        ...(isBrowser
          ? [
              <li key={'date-help-container'} className="md-list-item">
                <InfoBlock>
                  ​Use Shift + &lt; or &gt; <br /> to switch dates.
                </InfoBlock>
              </li>,
            ]
          : []),
      ];
};

export const getLayersItems = () => {
  const {
    wholeFarm: {isWholeFarmView, wholeFarmDates},
    feature,
    isZoning,
    currentSensors,
    currentDate,
    treeDetection: {layerType},
  } = hackGetState().map;
  const treeData = getTreeDataByDate(currentDate);

  const getWholeFarmLayers = () => {
    if (!currentDate) return [];
    return [
      ...Object.keys(wholeFarmDates[currentDate] || {}).filter(
        key => !wholeFarmDates[currentDate].Hidden && wholeFarmDates[currentDate][key].type
      ),
    ] as TSensor[];
  };

  let layers: TSensor[] = !isWholeFarmView
    ? [
        ...(feature === 'zoning' || (isZoning && feature === 'tsp')
          ? currentSensors.filter(s => s !== 'NC' && s !== 'TCI' && s !== 'TIRS')
          : currentSensors),
      ]
    : getWholeFarmLayers();

  if (layerType !== 'default' && treeData) layers = getTreeIndexes(treeData, layerType, layers);

  return ['NONE', ...layers.map(sensorView).sort()];
};

export const getGDDTillTSDate = () => (dispatch: any, getState: () => AppStore) => {
  const state = getState().map;
  let dayDegrees = 0;

  if (state.temperatureData.length) {
    state.temperatureData.forEach(date => {
      if (moment(date.date).isSame(moment(state.pointsCurrentGroupDate, formatDate()), 'day')) {
        dayDegrees += date.dayDegrees;
      }
    });
  }

  return dayDegrees;
};

export const preselectSensor = (
  currentDate: string,
  dates: DatesObj,
  currentSensor = 'NDVI',
  isTree?: string
) => {
  const {
    treeDetection: {layerType},
  } = hackGetState().map;

  if (Object.keys(dates).length && currentSensor !== 'NONE') {
    const treeData = getTreeDataByDate(currentDate);
    let sensors = Object.keys(dates[currentDate] || {})
      .sort()
      // @ts-ignore  // can't find ?.type from string | number | ...
      .filter((sensor: keyof TInfoExt) => dates[currentDate]?.[sensor]?.type) as TSensor[];
    if ((isTree || layerType) !== 'default' && treeData)
      sensors = getTreeIndexes(treeData, isTree || layerType, sensors);

    return sensors.find(s => s === currentSensor) || sensors.find(s => s === 'NDVI') || sensors[0];
  } else if (currentSensor === 'NONE') return 'NONE';

  return parseSensor(getGetURLParam('layer'));
};

export const preselectSensors = (
  current: string,
  dates: DatesObj,
  layerType: TreesTypes,
  customCurrentDates?: {[date: string]: any}
) => {
  if (Object.keys(dates).length) {
    const treeData = getTreeDataByDate(current, customCurrentDates);
    let sensors = getDateSensors(dates[current]);
    if (layerType !== 'default' && treeData) sensors = getTreeIndexes(treeData, layerType, sensors);
    return sensors.sort();
  }

  return [];
};

export const getDateSensors = (date: TInfoExt): TSensor[] => {
  if (date && typeof date === 'object') {
    // @ts-ignore
    return Object.keys(date).filter(key => date[key]?.type);
  }

  return [];
};

export function getClosestDate(d: string, dates: {[date: string]: any}, shouldSetToURL = true) {
  const datesKeys = Object.keys(dates).filter(d => dates[d]);

  let dateObj = moment(d, GLOBAL_APP_DATE_FORMAT);

  const datesSortedKeys = sortDates(datesKeys, GLOBAL_APP_DATE_FORMAT);
  if (!datesSortedKeys.length) return d;

  // if (!/^(\d{1,2})\/(\d{1,2})\/(\d{1,4})-/.test(d)) {
  //   d = datesSortedKeys[datesSortedKeys.length - 1];
  //   dateObj = moment(d, GLOBAL_APP_DATE_FORMAT);
  // }

  // have the same date
  if (datesSortedKeys.includes(d)) {
    shouldSetToURL && setGetParamToURL('layerDate', d);
    return d;
  }

  // find closest
  let _closest = datesSortedKeys[datesSortedKeys.length - 1];
  let hasAfter = false;

  datesSortedKeys.forEach(_d => {
    const _dObj = moment(_d, GLOBAL_APP_DATE_FORMAT);

    if (dateObj.isSameOrAfter(_dObj)) {
      _closest = _d;
      hasAfter = true;
    }
  });

  const result = d
    ? hasAfter
      ? _closest
      : datesSortedKeys[0]
    : datesSortedKeys[datesSortedKeys.length - 1];
  d !== result && shouldSetToURL && setGetParamToURL('layerDate', result);
  return result;
}

export function filterDates(
  dates: TInfoExt[] | DatesObj = [],
  tissueSampling: {[date: string]: SamplingPoint[]} = {},
  generatedNMapDates = {} as {[pointGroupDate: string]: string},
  selectedCloudValue = 0,
  excludedTypes: SourceType[] = [],
  withHidden = false
) {
  if (!Array.isArray(dates)) dates = [...Object.values(dates)];

  const nMapDates = [] as string[];
  dates = dates.filter(d => {
    // get not hidden and with allowed cloudCover value
    const cloudValue = d.Cloud ? d.Cloud <= selectedCloudValue : true;
    const isHidden = withHidden ? false : d.Hidden;

    return !isHidden && cloudValue && !excludedTypes.includes(d.Type);
  });

  Object.keys(generatedNMapDates).forEach(gDate => {
    Object.keys(tissueSampling).forEach(tsDate => {
      if (gDate === moment(tsDate, formatDate()).format(GLOBAL_APP_DATE_FORMAT)) {
        const tspWithNResult = tissueSampling[tsDate].filter((t: SamplingPoint) =>
          parseFloat(t.properties.n_result)
        );

        if (tspWithNResult.length && tspWithNResult.length >= 3)
          nMapDates.push(generatedNMapDates[gDate]);
      }
    });
  });
  const formattedDates = getFieldFormattedDates(dates);
  if (nMapDates.length) {
    nMapDates.forEach(d => {
      if (formattedDates[d] && formattedDates[d].NDRE)
        formattedDates[d].NMAP = {...formattedDates[d].NDRE, sensor: 'NMAP'};
    });
  }
  return formattedDates;
}

export function filterDatesForRemoteSensing(
  dates: TInfoExt[] | DatesObj = [],
  cloudCoverPercent: number,
  excludedTypes: SourceType[] = []
) {
  if (typeof dates === 'object' && !Array.isArray(dates)) {
    const datesKeys = Object.keys(dates || {});
    return datesKeys.length
      ? [
          ...datesKeys
            .filter(dateLabel => {
              dates[dateLabel].Cloud = dates[dateLabel].Cloud || 0;
              dates[dateLabel].Cloud = dates[dateLabel].Cloud > 100 ? 100 : dates[dateLabel].Cloud; // normalize percents (the python script hello)

              return (
                !excludedTypes.includes(dates[dateLabel].Type) &&
                dates[dateLabel].Cloud <= cloudCoverPercent
              );
            })
            .map(dateLabel => dates[dateLabel]),
        ]
      : [];
  }

  return dates.filter(im => {
    im.Cloud = im.Cloud || 0;
    // normalize percents (the python script hello)
    im.Cloud = im.Cloud > 100 ? 100 : im.Cloud;
    return !excludedTypes.includes(im.Type) && im.Cloud <= cloudCoverPercent;
  });
}

export const getFieldsSeasons = () => {
  return hackGetState().map.fields.reduce((result, field) => {
    if (Array.isArray(field.Seasons)) {
      return [...result, ...field.Seasons];
    }
    return result;
  }, []);
};

export const getSingleDateSchema = (range = ['0.3', '0.9'], hideLabel = false) => {
  const min = parseFloat(range[0]);
  const max = parseFloat(range[1]);
  const step = (max - min) / 5;
  const arr = [...Array(5)].reduce(arr => [...arr, parseFloat(arr[arr.length - 1]) + step], [min]);

  return (
    <>
      {hideLabel ? null : <span className="color-schema-label">Single Date</span>}
      <div className={'calculated-color-schema'}>
        <img src={`/assets/schemas/single_date.png`} alt={'single date'} />
        <div className={'values'}>
          {arr.map((val: number, i: number) => (
            <div key={i} className={'value'}>
              {Math.floor(val * 100) / 100}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const preselectRange = (currentSensor: TSensor) => {
  // COMMENT TO REMOVE:
  // -1 for NDVI, MSAVI, NDRE
  // 0 for CCCI
  // const range = ['CCCI'].includes(currentSensor) ? 0 : -1;

  console.log('Current scale:', -1);

  return -1;
};

export const cropMinDateParams = () => {
  const momentObj = moment.utc('01 Jul 2015', 'DD MMM YYYY');
  return {
    date: momentObj,
    msg: `Min date is ${momentObj.format(
      'DD MMM YYYY'
    )} because there is no Sentinel data available before that date`,
  };
};

export const drawCompareTooltips = (
  currentSensor: TSensor,
  currentSensorCompare: TSensor,
  currentDate: string,
  currentCompareDate: string
) => {
  const leftSensor = sensorView(currentSensor);
  const rightSensor = sensorView(currentSensorCompare);

  const parent = document.querySelector('.leaflet-sbs-divider');

  if (parent) {
    parent.classList.add('tooltip');
    parent.innerHTML = `
        <span class="compare-date-tooltip left-side">${
          getDateFromKey(currentDate).date
        } ${leftSensor}</span>
        <span class="compare-date-tooltip right-side">${
          getDateFromKey(currentCompareDate).date
        } ${rightSensor}</span>
    `;
  }
};

export const moveLayerToTop = (layer: L.Path) => {
  // this hacky function moves a leaflet layer to the end of its parent, so it is always at the top
  // useful when have several layers that overlaps each other and we need to move some element to the top
  try {
    // @ts-ignore
    let htmlElement = layer._path || layer._image;
    if (htmlElement) {
      const parentElement = htmlElement.parentNode;
      const parentChildrenCount = parentElement.childNodes.length - 1;
      let elementIndexPosition = [...htmlElement.parentElement.childNodes].indexOf(htmlElement);
      if (parentChildrenCount > elementIndexPosition) {
        parentElement.appendChild(htmlElement);
      }
    }
  } catch (err) {
    reportError(`Error during moving the layer to top`);
  }
};

export const isMapFeature = (feature: TFeature) =>
  [
    'farm',
    'crop',
    'data-layers',
    'compare',
    'zoning',
    'tsp',
    'analytics',
    'crop-performance',
    'optis',
    'carbon',
  ].includes(feature);
