import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  setCurrentMarker,
  removeSamplingPoint,
  pointsSetGroupDate,
} from '../../actions/sampling-points';
import moment from 'moment';
import {setFeature} from '../../actions';

import {Marker, Popup} from 'react-leaflet';
import {formatDate, truncStr} from '_utils';
import {AnalyticsPinImage} from '../../icons';
import {SelectionControl} from 'react-md/es';
import {GLOBAL_FORMAT_DATE} from '_constants';
import {disableEdit} from '../../utils/draw';
import {Button, FontIcon} from 'react-md';
import {showNote} from '_actions';
import LabelIcon from 'components/label-icon';
import {getGeometryLabelByValue} from '_utils';
import {t} from 'i18n-utils';

class TSPResult extends Component {
  state = {isFullResultOn: false};

  constructor(props) {
    super(props);

    this.mainNutrientsKeys = ['n_result', 'n_result2', 'total_K', 'total_P', ''];
  }

  toggleResult = () => this.setState(state => ({...state, isFullResultOn: !state.isFullResultOn}));

  render() {
    const cellMappingKeysFilterdNotZero = this.props.cellMappingKeysFilterd.filter(
      key => this.props.ts.properties[key] && !this.mainNutrientsKeys.includes(key)
    );

    return (
      <div>
        <ul className="tsp-elements-list tsp-elements-list--main">
          {this.mainNutrientsKeys.map(key =>
            this.props.ts.properties[key] ? (
              <li key={`main-nut-elem-${key}`}>
                <span>{this.props.cellMapping[key].title}:</span>
                <span>{this.props.ts.properties[key]}</span>
              </li>
            ) : null
          )}
        </ul>

        {cellMappingKeysFilterdNotZero.length ? (
          <SelectionControl
            id={`tsp-result-toggle-${this.props.id}`}
            type="switch"
            label={t({id: 'Display all nutrients'})}
            labelBefore={true}
            name={`tsp-result-toggle-${this.props.id}`}
            className="element-full-width"
            onChange={this.toggleResult}
            checked={this.state.isFullResultOn}
          />
        ) : null}

        {this.state.isFullResultOn ? (
          <ul className="tsp-elements-list">
            {cellMappingKeysFilterdNotZero.map(key =>
              !this.mainNutrientsKeys.includes(key) ? (
                <li key={`elem-${key}`}>
                  <span>{this.props.cellMapping[key].title}:</span>
                  <span>{this.props.ts.properties[key] ? this.props.ts.properties[key] : '-'}</span>
                </li>
              ) : null
            )}
          </ul>
        ) : null}
      </div>
    );
  }
}

TSPResult.propTypes = {
  id: PropTypes.string.isRequired,
};

class TissueSamplingOverlay extends Component {
  removeSamplingPoint = id => this.props.removeSamplingPoint(id);

  setActiveMarker(id) {
    //id !== this.props.currentPointId && this.props.setCurrentMarker(id);
  }

  setFeature() {
    this.props.setFeature('nitrogen');
  }

  edit = (id, key) => {
    disableEdit();
    if (id === 'new') return;

    this.props.feature !== 'tsp' && this.props.setFeature('tsp');

    this.props.pointsSetGroupDate(key);

    this.props.setCurrentMarker(id);
  };

  drawTissueSampling = () => {
    const {isWholeFarmView, allFarmTSP} = this.props.wholeFarm;

    if (isWholeFarmView) {
      return allFarmTSP.map((ts, i) => this.drawOneMarker(ts, i));
    }

    return this.props.pointsGroups[this.props.pointsCurrentGroupDate] &&
      this.props.pointsCurrentGroupDate !== 'add'
      ? this.props.pointsGroups[this.props.pointsCurrentGroupDate]
          .filter(ts => ts.id !== 'new')
          .map((ts, i) => this.drawOneMarker(ts, i))
      : null;
  };

  drawOneMarker = (ts, i, prefix = 'default') => {
    const pointColor =
      this.props.feature === 'analytics'
        ? ts.properties.color
        : ts.properties.checked
        ? '#43a047'
        : '#3388ff';
    const hidden = this.props.feature === 'analytics' ? ts.properties.visible === false : false;
    const customMarkerIcon = AnalyticsPinImage(pointColor, hidden, 'tsp');

    const cellMappingKeys = Object.keys(this.props.cellMapping || {});
    const cellMappingKeysFilterd = cellMappingKeys.filter(
      key => ts.properties[key] && !['samplingType', 'growthStage'].includes(key)
    );

    return (
      <Marker
        ref={l => {
          if (l) {
            l.fluroId = ts.id;
          }
        }}
        key={`map-ts-marker-${i}-${prefix}`}
        position={{
          lat: ts.geometry.coordinates[0],
          lon: ts.geometry.coordinates[1],
        }}
        onClick={this.setActiveMarker.bind(this, ts.id)}
        icon={customMarkerIcon}
      >
        <Popup className={'tsp-popup-elements map-popup'}>
          <div className="map-popup__container">
            <div className="map-popup__header">
              <div className="map-popup__label">{t({id: 'Point'})}</div>{' '}
              {/*{ts.properties.samplingPointType}*/}
              <div className="map-popup__id">{truncStr(ts.properties.title, 30)}</div>
              <div className="map-popup__title">
                Sampling Date:{' '}
                {moment(ts.properties.timedate).isValid()
                  ? moment(ts.properties.timedate).format(formatDate())
                  : moment(ts.properties.timedate, GLOBAL_FORMAT_DATE).format(formatDate())}
                <br />
                {ts.properties.growthStage
                  ? `${t({id: 'Growth stage'})}: ${ts.properties.growthStage.replace(/_/g, ' ')}`
                  : null}
                {ts.properties.description
                  ? `${t({id: 'Description'})}: ${ts.properties.description}`
                  : null}
              </div>
            </div>

            <div className="map-popup__item__primary-row">
              <div className="map-popup__item__icon">
                <LabelIcon label={ts.properties.samplingPointType} />
              </div>
              <div className="map-popup__item__info">
                <div className="map-popup__item__label">
                  {getGeometryLabelByValue(ts.properties.samplingPointType)}
                </div>
              </div>
            </div>

            <div className="map-popup__info">
              <TSPResult
                id={`tsp-res-${i}`}
                cellMappingKeysFilterd={cellMappingKeysFilterd}
                cellMapping={this.props.cellMapping}
                ts={ts}
              />
            </div>

            <div className="map-popup__actions">
              <Button
                raised
                iconEl={<FontIcon iconClassName={'fas fa-trash'} />}
                onClick={this.removeSamplingPoint.bind(this, ts.id)}
                className="map-popup__actions__item"
              >
                {t({id: 'Delete'})}
              </Button>

              <Button
                raised
                primary
                iconEl={<FontIcon iconClassName={'fas fa-edit'} />}
                onClick={this.edit.bind(
                  this,
                  ts.id,
                  moment(ts.properties.timedate).isValid()
                    ? moment(ts.properties.timedate, GLOBAL_FORMAT_DATE).format(formatDate())
                    : moment().format(formatDate())
                )}
                className="map-popup__actions__item"
              >
                {t({id: 'Edit'})}
              </Button>
            </div>
          </div>
        </Popup>
      </Marker>
    );
  };

  render() {
    let newMarker = null;

    if (
      this.props.feature === 'tsp' &&
      this.props.currentSeasonId &&
      this.props.currentSeason.tissueSampling.length
    ) {
      newMarker = this.props.currentSeason.tissueSampling.find(ts => ts.id === 'new');
    }

    return [
      newMarker ? this.drawOneMarker(newMarker, '_new_one', '_new_one') : null,
      this.props.geometriesOnMap &&
      this.props.currentSeasonId &&
      this.props.currentSeason.tissueSampling.length
        ? this.drawTissueSampling()
        : null,
    ];
  }
}

const mapStateToProps = state => ({
  feature: state.map.feature,
  currentSeasonId: state.map.currentSeasonId,
  currentSeason: state.map.currentSeason,

  currentPointId: state.map.currentPointId,
  pointsGroups: state.map.pointsGroups,
  pointsCurrentGroupDate: state.map.pointsCurrentGroupDate,
  geometriesOnMap: state.map.geometriesOnMap,
  cellMapping: state.tsUpload.cellMapping,
  isReadOnly: state.map.group.readOnly,
  wholeFarm: state.map.wholeFarm,
});

export default connect(mapStateToProps, {
  setCurrentMarker,
  removeSamplingPoint,
  setFeature,
  pointsSetGroupDate,
  showNote,
})(TissueSamplingOverlay);
