import {t, FormattedMessage} from 'i18n-utils';
import React, {memo} from 'react';
import {setOpacity} from '_utils/colors';
import {cropGrowthColors, cropGrowthLabels, CropGrowth} from '../types';

type Props = {
  value: CropGrowth;
};

export default memo(({value}: Props) => (
  <div
    className={`cp__crop-growth cp__crop-growth--${value}`}
    style={{background: `${setOpacity(cropGrowthColors[value], 0.1, true)}`}}
  >
    {t({id: cropGrowthLabels[value]}) || ''}
  </div>
));
