import * as React from 'react';
import {useState, useEffect, ReactElement} from 'react';
import {
  FluroSelectLite,
  FluroSelectLiteProps,
} from 'components/fluro-select-lite/fluro-select-lite';
import cn from 'classnames';
import './carbon-select.scss';

export const CarbonSelect = ({
  error,
  selectedItem,
  items,
  placeholder,
  subtitle,
  onSelect,
  isSearchable,
  confirmComponent,
}: {confirmComponent?: ReactElement; error?: boolean} & FluroSelectLiteProps) => {
  const [localItem, setLocalItem] = useState(selectedItem);
  const [dirty, setDirty] = useState(false);
  const [dirtyError, setDirtyError] = useState(false);
  useEffect(() => setLocalItem(selectedItem), [selectedItem]);

  useEffect(() => {
    setDirtyError(error);
  }, [error]);

  return (
    <div
      className={cn('carbon-select-wrapper', {
        'request-changes': dirtyError,
      })}
    >
      <FluroSelectLite
        items={items}
        isSearchable={isSearchable}
        selectedItem={localItem}
        placeholder={placeholder}
        helpComponent={
          confirmComponent ? (
            <div
              onClick={e => {
                e.stopPropagation();
                setDirtyError(false);
              }}
            >
              {confirmComponent}
            </div>
          ) : null
        }
        subtitle={dirty ? '' : subtitle}
        onSelect={value => {
          setLocalItem(value);
          setDirty(true);
          setDirtyError(false);
          onSelect(value);
        }}
      />
    </div>
  );
};
