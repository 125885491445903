import React, {ReactNode, useCallback, useEffect} from 'react';
import {useLocation} from 'react-router';
type Props = {
  children: ReactNode;
};
const ScrollToTop = ({children}: Props) => {
  const location = useLocation();
  const scrollToTop = useCallback(() => {
    window.setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
