import {AppDispatch} from './store';
import {AppStore} from './reducers';

export enum RequestStatus {
  Idle,
  Loading,
  Skipped,
  Error,
  Success,
}

export type RequestMethod = 'get' | 'post' | 'put' | 'delete';

export type TFeature =
  | 'farm'
  | 'crop' // anomalies, crop stress
  | 'data-layers'
  | 'crop-performance'
  | 'optis'
  | 'carbon'
  | 'analytics'
  | 'zoning'
  | 'tsp'
  | 'nrx'
  | 'profile'
  | 'faq'
  | 'log-out';

export const isFeature = (feature: string): feature is TFeature => {
  switch (feature) {
    case 'farm':
    case 'crop':
    case 'data-layers':
    case 'crop-performance':
    case 'optis':
    case 'carbon':
    case 'analytics':
    case 'zoning':
    case 'tsp':
    case 'nrx':
    case 'profile':
      return true;
    default:
      return false;
  }
};

export type TSensor =
  | 'CCCI'
  | 'MSAVI'
  | 'NDRE'
  | 'NMAP'
  | 'NDVI'
  | 'NDWI'
  | 'PGI'
  | 'TCI'
  | 'RGB'
  | 'TIRS'
  | 'VVI'
  | 'DIAMETER'
  | 'NONE'
  | 'EC'
  | 'ELEVATION'
  | 'PH'
  | 'YIELD'
  | 'N-application'
  | 'PTIRS'
  | 'NC'
  | 'TIRS-P'
  | 'RGB-S';

export const isSensor = (sensor: string): sensor is TSensor => {
  switch (sensor) {
    case 'CCCI':
    case 'MSAVI':
    case 'NDRE':
    case 'NMAP':
    case 'NDVI':
    case 'NDWI':
    case 'PGI':
    case 'TCI':
    case 'RGB':
    case 'TIRS':
    case 'VVI':
    case 'DIAMETER':
    case 'EC':
    case 'ELEVATION':
    case 'PH':
    case 'YIELD':
    case 'N-application':
    case 'PTIRS':
    case 'NC':
    case 'NONE':
      return true;
    default:
      return false;
  }
};

export function parseSensor(sensor: string): TSensor {
  switch (sensor) {
    case 'CCCI':
      return 'CCCI';
    case 'MSAVI':
      return 'MSAVI';
    case 'NDRE':
      return 'NDRE';
    case 'NDVI':
      return 'NDVI';
    case 'NDWI':
      return 'NDWI';
    case 'PGI':
      return 'PGI';
    case 'TCI':
      return 'TCI';
    case 'TIRS':
      return 'TIRS';
    case 'VVI':
      return 'VVI';
    case 'DIAMETER':
      return 'DIAMETER';
    case 'NONE':
      return 'NONE';
    case 'PTIRS':
      return 'PTIRS';
    case 'NC':
      return 'NC';
    default:
      return 'NONE';
  }
}

export type Dialogs = 'editField' | 'editFarm' | 'editSeason' | 'editPivotTableView' | 'demoFarms';
