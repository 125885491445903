import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {genKey} from '_utils';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {dialogToggle, DialogType} from 'modules/ui-helpers';
import {DialogFooter} from 'containers/admin/admin.styled';
import {Button, SelectionControl} from 'react-md';
import {CommonDialogContentWrapper} from 'components/common-dialogs/dialogs.styled';
import Dialog from 'components/fluro-dialog';
import {CropItemCard, SelectedTitle} from './productivity-map.styled';
import {CropInfo} from 'components';
import {getCropDate} from './productivity-map';
import {populateReachedPeakCanopyData} from './productivity-map.module';
import {FormattedMessage} from 'react-intl';
import {t} from 'i18n-utils';

export const EditSelectedSeasonsListDialog = () => {
  const id = useMemo(() => genKey(), []);
  const dispatch = useDispatch();

  const [selectedSeasonsIdsObj, setSelectedSeasonsIds] = useState<{[key: number]: boolean}>({});

  const selectedSeasonsIds = useMemo(() => {
    return Object.keys(selectedSeasonsIdsObj)
      .filter(k => selectedSeasonsIdsObj[parseInt(k)])
      .map(id => parseInt(id));
  }, [selectedSeasonsIdsObj]);

  const {visible, payload} = useSelector(
    (state: AppStore) => state.uiHelpers.dialogs[DialogType.editSelectedSeasonsProductivityMap]
  );

  useEffect(() => {
    const ids: number[] = payload?.selectedIds || [];

    let obj: {[key: number]: boolean} = {};

    ids.forEach(id => {
      obj[id] = true;
    });

    setSelectedSeasonsIds(obj);
  }, [payload]);

  const _seasons = useSelector((state: AppStore) => state.map.field.Seasons);

  const seasons = useMemo(() => {
    return populateReachedPeakCanopyData(_seasons, true);
  }, [_seasons]);

  const onCancel = useCallback(
    () => dispatch(dialogToggle(DialogType.editSelectedSeasonsProductivityMap, false)),
    []
  );

  const onSave = useCallback(async () => {
    payload?.setSelected({...selectedSeasonsIdsObj});

    setSelectedSeasonsIds({});
    onCancel();
  }, [payload, selectedSeasonsIdsObj]);

  const toggleSelectedSeasons = useCallback(
    (id: number) => {
      setSelectedSeasonsIds(s => ({
        ...s,
        [id]: !s[id],
      }));
    },
    [setSelectedSeasonsIds]
  );

  return (
    <Dialog
      id={`edit-prod-map-seasons--dialog-${id}`}
      visible={visible}
      title={t({id: 'Crop selection'})}
      focusOnMount={false}
      isDraggable
      onHide={onCancel}
      width={350}
      portal
      actions={
        <DialogFooter fullWidth>
          <Button className="element-full-width" primary raised onClick={onSave}>
            {t({id: 'Generate map'})}
          </Button>
        </DialogFooter>
      }
    >
      <CommonDialogContentWrapper>
        <SelectedTitle>
          <FormattedMessage
            id="Select 1 or multiple crops to generate the map"
            defaultMessage="Select 1 or crops crops to generate the map"
          />
          :
        </SelectedTitle>

        {seasons.map(({id, cropType, startDate, endDate, geometry, params}) => (
          <CropItemCard key={`season-${id}`}>
            <SelectionControl
              id={`select-crop-${id}`}
              name={`select-crop-${id}`}
              label=""
              type="checkbox"
              checked={selectedSeasonsIds.includes(id)}
              onChange={() => toggleSelectedSeasons(id)}
            />

            <CropInfo
              cropType={cropType}
              cropSubType={params?.cropSubType}
              startDate={getCropDate(startDate)}
              endDate={getCropDate(endDate)}
              hasGeometry={!!geometry}
            />
          </CropItemCard>
        ))}
      </CommonDialogContentWrapper>
    </Dialog>
  );
};
