import {t, FormattedMessage} from 'i18n-utils';
import React, {useCallback, useState, memo} from 'react';
import L from 'leaflet';
import SearchLocation from '../search-location';
import GoHomeBtn from './go-home-btn';
import cn from 'classnames';

import './index.scss';
import {Button, FontIcon} from 'react-md';
import ReadOnly from 'components/read-only-container';
import {AddingFieldsFromMapSteps} from 'modules/add-fields.module';
import Control from 'containers/map/lib/control';
import {useAppSelector} from '_hooks';
import config from '_environment';

type Props = {
  leafletElement: L.Map;
  fitBounds: (bounds?: L.LatLngBounds) => void;
};

export const LocationButtons = memo(({leafletElement, fitBounds}: Props) => {
  const [searchActive, setSearchActive] = useState(false);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const addFieldsCurrentStep = useAppSelector(s => s.addFields.addFieldCurrentStep);
  const isActiveFieldAddingFlow = AddingFieldsFromMapSteps.includes(addFieldsCurrentStep);
  const isCarbonDomain = config.featurePack === 'carbon';

  const toggleSearch = useCallback(
    (value: boolean) => {
      setSearchActive(value);
      if (value) {
        setTimeout(() => {
          document.getElementById('toolbar-search') &&
            document.getElementById('toolbar-search').focus();
        }, 200);
      }
    },
    [setSearchActive, addFieldsCurrentStep]
  );

  if (!leafletElement) {
    return null;
  }

  return (
    <Control position="topleft">
      <div className={'onboarding__map-tools'} />
      <div className={'location-buttons'}>
        {!searchActive && (
          <FontIcon
            onClick={() => {
              setOpenMenu(!isOpenMenu);
            }}
            className={'location-button'}
          >
            near_me
          </FontIcon>
        )}

        {searchActive && (
          <FontIcon
            onClick={() => {
              toggleSearch(false);
            }}
            className={cn('location-button', {active: isActiveFieldAddingFlow})}
          >
            search
          </FontIcon>
        )}

        {searchActive && (
          <SearchLocation toggleSearch={toggleSearch} leafletElement={leafletElement} />
        )}

        <div className={cn('location-tools', {active: isOpenMenu && !searchActive})}>
          <h5 className={'location-tools__title'}>
            <FormattedMessage id="Location" defaultMessage="Location" />
          </h5>

          <ReadOnly>
            <Button
              id={'search-on-map-btn'}
              className={'search-location'}
              onClick={() => {
                setOpenMenu(false);
                toggleSearch(true);
              }}
              flat
              iconEl={<FontIcon>search</FontIcon>}
            >
              {' '}
              <FormattedMessage id="Search location" defaultMessage="Search location" />
            </Button>
          </ReadOnly>

          {!isCarbonDomain && (
            <GoHomeBtn
              onClick={() => {
                fitBounds();
                setOpenMenu(false);
              }}
            />
          )}
        </div>
      </div>
    </Control>
  );
});

export default LocationButtons;
