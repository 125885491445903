import {FluroButton, FluroDialog, Ln} from 'components';
import * as React from 'react';
import {useAppSelector} from '_hooks';
import {isAdminPerm} from '_utils';
import config from '_environment';
import {t} from '../../i18n-utils';
import {Button} from 'react-md';
import {DialogFooter} from '../admin/admin.styled';
import {useDispatch} from 'react-redux';
import {logout} from '../login/actions';
import {DialogType, toggleDialog} from "../../_reducers/dialog";

const CarbonSuccessfulSignedPopUp = ({onHide}: {onHide: () => void}) => {
  const dispatch = useDispatch();
  const user = useAppSelector(s => s.login.user);
  const isAdmin = useAppSelector(s => isAdminPerm(s.login.user.perm));
  const {envelope_id} = useAppSelector(s => s.carbon.signedDocumentData);

  const documentLink = `${config.baseUrl}api/v1/cargill/pdf?envelope_id=${envelope_id}`;

  return (
    <FluroDialog
      title={'Contract signed'}
      visible={true}
      id={'success-pop-up'}
      className={'carbon-success-pop-up'}
      onHide={() => (isAdmin ? onHide() : null)}
      width={530}
      isClosable={false}
      actions={
        <DialogFooter>
          <Button
            primary
            raised
            onClick={() => {
              dispatch(toggleDialog(DialogType.CarbonSuccessfulSigned, false));
              dispatch(logout());
            }}
          >
            {t({id: 'Log out'})}
          </Button>
        </DialogFooter>
      }
    >
      <div className={'main-text-container'}>
        <h2>
          Thank you for your enrollment,{' '}
          <Ln blank external href={documentLink}>
            click here
          </Ln>{' '}
          to download your contract
        </h2>
        <p>Your contract was also send by email to {user.email}.</p>
        <p>
          <i>
            If you need to make changes to the contract, please contact{' '}
            <Ln href="mailto:support@regrow.ag">support@regrow.ag</Ln>. We will delete your contract
            and you will be able to issue a new one.
          </i>
        </p>
      </div>

      <div className={'secondary-text-container'}>
        <h4>What to expect next?</h4>

        <p>
          A partial payment will be issued by the end of the year. The payment may be reduced if the
          participant fails to comply with the obligations of this Agreement.
        </p>
      </div>
    </FluroDialog>
  );
};

export default CarbonSuccessfulSignedPopUp;
