export enum ActionTypes {
  // Main data manipulation
  MAP_FARM_UPDATE = 'map/farm-update',
  MAP_FARM_DELETE = 'map/farm-delete',
  MAP_LOAD_FIELDS = 'map/load-fields',
  MAP_SAVE_FIELDS = 'map/save-fields',
  MAP_LOAD_FIELD_DATA = 'map/load-field-data',
  MAP_HARD_CLEAR_STATE = 'map/hard-clear-state',
  MAP_SET_APP_PROCESSING_STATUS = 'map/set-app-processing-status',
  MAP_TOGGLE_DRAWING_MODE = 'map/toggle-drawing-mode',
  MAP_TOGGLE_EDITING_MODE = 'map/toggle-edit-mode',
  MAP_SET_FIELD_GEOMETRIES = 'map/set-field-geometries',

  //Main bar actions
  MAP_SET_MAPBAR_REF = 'map/set-mapbar-ref',
  MAP_HIGHLIGHT_FIELD = 'map/highlight-field',
  SET_SELECTED_FIELD_ID = 'map/set-selected-field-id',
  MAP_SET_CURRENT_DATE = 'map/set-current-date',
  MAP_SET_SENSOR = 'map/set-sensor',
  MAP_SET_COMPARE_DATE = 'map/set-compare-date',
  MAP_SET_SENSOR_COMPARE = 'map/set-sensor-compare',
  MAP_TOGGLE_COMPARE = 'map/toggle-compare',
  MAP_SET_FEATURE = 'map/set-feature',
  MAP_TOGGLE_BAR = 'map/toggle-map-bar',
  MAP_TOGGLE_WHOLE_TABLE_VIEW = 'map/toggle-whole-table-view',

  //Season
  SET_CURRENT_SEASON = 'map/set-current-season',
  UPDATE_SEASON = 'map/update-season',
  MAP_CHANGE_FIELD_SEASON = 'map/change-field-season',
  MAP_SYNC_FIELD_SEASONS = 'map/sync-field-season',
  MAP_DELETE_SEASON = 'map/delete-season',
  MAP_ADD_SEASON = 'map/add-season',
  MAP_SEASON_TOGGLE = 'map/season-toggle-checkbox',
  MAP_SEASONS_TOGGLE = 'map/all-seasons-toggle-checkbox',
  MAP_SET_CROP_VARIETY_MODE = 'map/set-crop-variety-mode',

  //Zoning
  MAP_SET_ZONING = 'map/set-zoning',
  MAP_SET_ZONING_POINTS = 'map/set-zoning-points',
  MAP_TOGGLE_ZONING = 'map/tsp-toggle-zoning',
  MAP_TOGGLE_ZONING_RX = 'map/toggle-zoning-rx',
  MAP_UPDATE_ZONES_RANGE = 'map/update-zone-ranges',
  MAP_UPDATE_COPY_ZONES_RANGE = 'map/update-copy-zones-range',
  MAP_UPDATE_ZONE_PROP = 'map/update-zone-prop',
  MAP_SET_ZONING_UNITS = 'map/set-zoning-units',

  // Histogram
  MAP_SET_HISTOGRAM_DATA = 'map/set-histogram-data',
  MAP_SET_HISTOGRAM_OPTIONS = 'map/set-histogram-options',
  MAP_SET_COLOR_SCHEMA = 'map/set-color-schema',
  MAP_SET_IMAGE_OVERLAY_OPACITY = 'map/set-image-overlay-opacity',

  // Toggle buttons on map
  MAP_TOGGLE_BUTTON_ON_MAP = 'map/toggle-button',
  MAP_TOGGLE_GEOMETRIES_ON_MAP = 'map/toggle-field-geometries',
  MAP_TOGGLE_FIELD_KML = 'map/toggle-field-kml',
  MAP_TOGGLE_LAYER_SOURCE = 'map/toggle-layers-source',
  MAP_TOGGLE_SOIL_MAP_LAYER = 'map/toggle-soil-map-layer',

  // Field
  MAP_TOGGLE_FIELD_CHECKBOX = 'map/toggle-field-checkbox',
  MAP_TOGGLE_ALL_FIELDS_CHECKBOX = 'map/toggle-fields-all-checkbox',
  MAP_CHANGE_FIELD_NAME = 'map/change-field-name',
  MAP_CHANGE_FIELD_DATA = 'map/change-field-data',
  MAP_BULK_CHANGE_FIELD_DATA = 'map/bulk-change-field-data',
  MAP_ADD_NEW_FIELD = 'map/add-new-field',
  MAP_DELETE_FIELD = 'map/delete-field',
  MAP_TOGGLE_FIELD_MEAN = 'map/analytic-toggle-field-mean',
  MAP_TOGGLE_SMOOTHED_DATA = 'map/analytic-toggle-smoothed-data',
  MAP_SORT_FIELDS = 'map/sort-fields',
  REPLACE_FIELD_AT_FIELDS_BY_FARM_ID = 'map/REPLACE_FIELD_AT_FIELDS_BY_FARM_ID',

  // Field geometry
  MAP_UPDATE_FIELD_MEAN = 'map/update-field-mean',
  MAP_SET_FIELD_KML = 'map/set-field-kml',

  //Weather
  MAP_SET_TEMPERATURE_DATA = 'map/set-temperature-data',

  //Remote sensing
  MAP_REMOTE_SENSING_SET_CURRENT = 'map/remote-sensing-image-set-current',
  MAP_REMOTE_SENSING_TOGGLE_DIALOG = 'map/remote-sensing-image-toggle-dialog',
  MAP_REMOTE_SENSING_ON_CHANGE_LAYER = 'map/remote-sensing-on-change-layer',
  MAP_REMOTE_SENSING_SET_CLOUD_COVER = 'map/remote-sensing-set-cloud-cover',
  MAP_REMOTE_SENSING_TOGGLE_HIDDEN = 'map/remote-sensing-toggle-hidden',
  MAP_REMOTE_SENSING_TOGGLE_OPTION = 'map/remote-sensing-toggle-option',
  MAP_REMOTE_SENSING_SET_FILTER = 'map/remote-sensing-set-filter',

  // Popup – for areas of interest and for both low perf and premium anomalies.
  MAP_TOGGLE_POPUP = 'map/toggle-popup',

  // Areas of interest
  MAP_AOI_ADD = 'map/AOI-add',
  MAP_AOI_UPDATE = 'map/AOI-update',
  MAP_AOI_REMOVE = 'map/AOI-remove',
  MAP_AOI_EDIT_TOGGLE = 'map/AOI-edit-toggle',
  MAP_AOI_CHANGE_PROP = 'map/AOI-change-prop',
  MAP_AOIs_CHANGE_PROP = 'map/AOIs-change-prop',
  MAP_AOI_UPDATE_MEAN_INDEX = 'map/AOI-update-mean-index-value',

  // Sampling points
  MAP_POINTS_SAVE = 'map/points-save',
  MAP_POINTS_UPDATE_GROUP_DATE = 'map/points-update-group-date',
  MAP_POINTS_SET_CURRENT = 'map/points-set-current-point',
  MAP_POINTS_REMOVE_ONE = 'map/points-remove-one',
  MAP_POINTS_BULK_UPDATE_PROP = 'map/points-bulk-update-prop',
  MAP_POINTS_UPDATE_WITH_NUTRILOGIC_DATA = 'map/points-add-nutrilogics-data',
  MAP_POINTS_UPDATE_POSITION = 'map/points-update-positions',
  MAP_POINTS_SET_GROUP_DATE = 'map/points-set-group-date',

  // Nitrogen
  GENERATE_NITROGEN_MAP = 'map/generate-nitrogen-map',
  MAP_SET_NITROGEN_OVERLAY_URL = 'map/set-nitrogen-overlay-url',
  MAP_SET_NITROGEN_SCALE_URL = 'map/set-nitrogen-scale-url',
  MAP_SET_NITROGEN_MARKER = 'map/set-nitrogen-marker',
  MAP_REMOVE_NITROGEN_MARKER = 'map/remove-nitrogen-marker',

  // whole farm
  MAP_SET_WHOLE_FARM_FIELDS_GEOMETRY = 'map/set-whole-farm-fields-geometry',
  MAP_SET_WHOLE_FARM_TREE_ZONING_FIELDS = 'map/set-whole-farm-tree-zoning-fields',
  MAP_TOGGLE_WHOLE_FARM_TREE_ZONING_FIELD = 'map/toggle-whole-farm-tree-zoning-field',
  MAP_WHOLE_FARM_SET_CROP_PERF = 'map/set-whole-farm-crop-perf',
  MAP_TOGGLE_CROP_PERF_FARM = 'map/toggle-crop-perf-farm',

  // anomalies tab
  MAP_CHANGE_ANOMALIES_TAB = 'map/change-anomalies-tab',

  // premium anomalies
  MAP_LOAD_PREMIUM_ANOMALIES = 'map/load-premium-anomaly',
  MAP_PREMIUM_ANOMALIES_LOADED = 'map/premium-anomalies-loaded',
  MAP_UPDATE_PREMIUM_ANOMALY_PROP = 'map/update-premium-anomaly-prop',
  MAP_UPDATE_PREMIUM_ANOMALY_LIST = 'map/update-premium-anomaly-list',
  MAP_BULK_UPDATE_PREMIUM_ANOMALY_PROP = 'map/bulk-update-premium-anomaly-prop',
  MAP_PREMIUM_ANOMALIES_HIDE = 'map/premium-anomalies-hide',
  MAP_REMOVE_PREMIUM_ANOMALIES = 'map/remove-premium-anomaly',

  // simple (low performing) anomalies
  MAP_SET_LOW_PERF_ANOMALIES = 'map/set-low-perf-anomalies',
  MAP_UPDATE_LOW_PERF_ANOMALIES = 'map/update-low-perf-anomalies',
  MAP_TOGGLE_LOW_PERF_ANOMALIES = 'map/toggle-low-perf-anomalies',
  MAP_UPLOAD_AREAS_OF_INTEREST = 'map/upload-areas-of-interest',
  MAP_UPLOAD_AREAS_OF_INTEREST_SET_STATUS = 'map/upload-areas-of-interest-set-status',
  MAP_AREAS_OF_INTEREST_TOGGLE_HISTORY = 'map/areas-of-interest-toggle-history',

  // nitrogen recommendation
  MAP_TOGGLE_N_RECOMMENDATION = 'map/toggle-n-recommendation',
  MAP_UPDATE_N_DATA = 'map/update-n-recommendation-data',

  // NRX
  MAP_NRX_SET_LISTS_DATA = 'map/nrx-set-lists-data',
  MAP_NRX_LOAD_SEASONS_DATA = 'map/nrx-load-seasons-data',
  MAP_NRX_UPDATE_SEASON_NRX_DATA = 'map/nrx-update-season-data',
  MAP_NRX_FERTILIZER_APP_ADD = 'map/nrx-fertilizer-add',
  MAP_NRX_FERTILIZER_APP_REMOVE = 'map/nrx-fertilizer-remove',
  MAP_NRX_FERTILIZER_APP_UPDATE = 'map/nrx-fertilizer-update',
  MAP_NRX_UPDATE_POP_UP_SETTINGS = 'map/nrx-update-pop-up-settings',
  MAP_NRX_SET_RECOMMENDATION_RESULT = 'map/nrx-set-recommendation-result',
  MAP_NRX_UPDATE_RECOMMENDATION_PROPS = 'map/nrx-update-recommendation-props',
  MAP_NRX_TOGGLE_SETTINGS_POP_UP = 'map/nrx-toggle-settings-pop-up',
  MAP_NRX_TOGGLE_RATE_TAB = 'map/nrx-toggle-rate-tab',
  MAP_NRX_SELECT_OBJECTIVE = 'map/nrx-select-objective',
  MAP_NRX_MERGE_ZONES = 'map/nrx-merge-zones',
  MAP_NRX_REVERT_MERGE_ZONES = 'map/nrx-revert-merge-zones',

  // analytics
  MAP_ADD_ANALYTIC_POINT = 'map/map_add_analytic_point',
  MAP_UPDATE_ANALYTIC_POINT = 'map/map_update_analytic_point',
  MAP_REMOVE_ANALYTIC_POINT = 'map/map_remove_analytic_point',
  MAP_TOGGLE_ANALYTIC_POINT_VISIBILITY = 'map/map_toggle_analytic_point_visibiltoggle-tspity',
  MAP_TOGGLE_TSP_POINT_VISIBILITY = 'map/map_toggle_TSP_point_visibility',
  MAP_ADD_ANALYTIC_TRENDS_DATA = 'map/map_add_analytic_trends_data',
  MAP_SET_ARABLE_SENSOR_DATA = 'map/set-arable-sensors-data',

  //tree detection
  MAP_SET_TREE_LAYER_TYPE = 'map/set-tree-layer-type',
  MAP_SET_TREE_ZONE_PARAM = 'map/set-tree-zone-param',

  RESET_FARM_SENSITIVE_PARAMS = 'map/reset-farm-sensitive-params',

  // other
  CLEAR_ORIGINAL_LAYER_DATE_FROM_URL = 'map/clear-Original-LayerDate-Form-URL',
  UPDATE_IMAGE_CLOUDY = 'map/update-image-cloudy',

  // Location
  SET_LOCATION_MARKER_COORDINATES = 'map/set-location-marker-coordinates',
}

export type UpdateSeasonNrxDataAction = {
  type: ActionTypes.MAP_NRX_UPDATE_SEASON_NRX_DATA;
  seasonID: number | number[];
  data: any;
};
