import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useMemo} from 'react';
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import MapGeometry from './geometry';
import LowPerformingAnomalies from './low-perf-anomalies';
import StressDetectedList from './stress-detected-list';
import AreasList from './areas-list';
import AreasHistoryList from './areas-history-list';
import AnomaliesPanel from './anomalies-panel/index';
import {checkFieldTagExist, getPerformanceGeometries} from './../../utils';

import {ExternalService, FieldTag} from '../../types';

import './index.scss';
import {toggleFieldGeometries} from '../../actions';
import {getGetURLParam, isAgxField} from '_utils';
import {ReadOnly} from 'components';
import {AccessibleFakeButton, MenuButton, SelectField} from 'react-md';
import {DownloadIcon} from '../../icons';
import {
  exportAnomaliesToKml,
  exportAnomaliesToShp,
  onExportToAgworld,
  onExportToAgX,
} from './anomalies-utils';
import {useAppSelector} from '_hooks';
import {Sticky} from '../../../../components/sticky/sticky';

type Props = {
  removeGeometryFromMap: (id: number) => void;
};

const MapCrop = ({removeGeometryFromMap}: Props) => {
  const dispatch = useDispatch();
  const field = useAppSelector(s => s.map.field);
  const wholeTableView = useAppSelector(s => s.map.wholeTableViewOpen);
  const geometriesOnMap = useAppSelector(s => s.map.geometriesOnMap);
  const anomaliesSelectedTab = useAppSelector(s => s.map.anomalies.anomaliesSelectedTab);
  const premiumAnomaliesList = useAppSelector(s => s.map.premiumAnomalies.list);
  const visibleHistorySection = useAppSelector(s => s.map.anomalies.historyOpen);
  const isAgworld = field.external_service === ExternalService.Agworld;
  const isAgx = isAgxField(field);
  const isCropStressTabSelected = anomaliesSelectedTab === 'crop-stress';
  const lowPerfAnomalies = useAppSelector(s => s.map.lowPerfAnomalies);
  const geometry = useAppSelector(s => s.map.geometry);

  useEffect(() => {
    if (!geometriesOnMap) {
      dispatch(toggleFieldGeometries(true));
    }
  }, []);

  const lowPerformingAnomaliesAndRoi = useMemo(() => {
    return visibleHistorySection
      ? [
          ...getPerformanceGeometries().geometryToShow,
          ...getPerformanceGeometries().historyGeometries,
        ]
      : getPerformanceGeometries().geometryToShow;
  }, [lowPerfAnomalies, geometry, visibleHistorySection]);

  const selectedAnomalies = isCropStressTabSelected
    ? premiumAnomaliesList.filter((a: any) => a.properties.checked)
    : lowPerformingAnomaliesAndRoi.filter(({properties}) => properties.checked);

  const disabled = selectedAnomalies.length === 0;

  const displayExportButton =
    !wholeTableView &&
    !!(isCropStressTabSelected ? premiumAnomaliesList.length : lowPerformingAnomaliesAndRoi.length);

  const exportMenuItems = useMemo(() => {
    const items = [];

    items.push(
      <AccessibleFakeButton
        key={`export-to-kml`}
        className="anomaly-header__menu-item export-kml"
        onClick={() => dispatch(exportAnomaliesToKml(selectedAnomalies))}
        disabled={disabled}
      >
        To KML
      </AccessibleFakeButton>
    );

    if (isAgworld && isCropStressTabSelected)
      items.push(
        <AccessibleFakeButton
          key={`export-to-agworld`}
          className="anomaly-header__menu-item export-to-agworld"
          onClick={() => dispatch(onExportToAgworld(selectedAnomalies))}
          disabled={disabled}
        >
          To Agworld
        </AccessibleFakeButton>
      );

    if (isAgx)
      items.push(
        <AccessibleFakeButton
          key={`export-to-agx`}
          className="anomaly-header__menu-item export-to-agx"
          onClick={() => dispatch(onExportToAgX(selectedAnomalies, anomaliesSelectedTab))}
          disabled={disabled}
        >
          To agX
        </AccessibleFakeButton>
      );

    items.push(
      <AccessibleFakeButton
        key={`export-to-shp`}
        className="anomaly-header__menu-item export-to-shape"
        onClick={() => dispatch(exportAnomaliesToShp(selectedAnomalies))}
        disabled={disabled}
      >
        To SHP
      </AccessibleFakeButton>
    );
    return items;
  }, [selectedAnomalies, disabled, isAgx, isAgworld, isCropStressTabSelected]);

  const tabTitle =
    // FSB-4061 HACK: Unblocking Victor to test anomalies wihtout the field tag, but using the url flag
    // getGetURLParam('premium-anomalies')
    checkFieldTagExist(FieldTag.AnomalyDetection) || getGetURLParam('premium-anomalies')
      ? t({id: 'Crop Stress', defaultMessage: 'Crop Stress'})
      : t({id: 'Areas of Interest', defaultMessage: 'Areas of Interest'});
  const cropStressTabSelected = anomaliesSelectedTab === 'crop-stress';

  return (
    <div className={'section-container anomalies-tab'}>
      {!wholeTableView ? <h3 className="tab-title">{tabTitle}</h3> : null}
      <MapGeometry removeGeometryFromMap={removeGeometryFromMap} />

      {wholeTableView && <AnomaliesPanel tabTitle={tabTitle} />}
      {(wholeTableView || !cropStressTabSelected) && <AreasList />}
      {(wholeTableView || cropStressTabSelected) && <StressDetectedList />}

      {displayExportButton && (
        <Sticky className={'sticky-export-btn-container'}>
          <ReadOnly containerClass={'md-inline-block'}>
            <MenuButton
              id={`export-anomaly`}
              primary
              className="btn-with-icon"
              listClassName="anomaly-header__menu-list"
              raised
              disabled={disabled}
              iconEl={<DownloadIcon />}
              menuItems={exportMenuItems}
              simplifiedMenu={false}
              anchor={{
                x: SelectField.HorizontalAnchors.CENTER,
                y: SelectField.VerticalAnchors.TOP,
              }}
            >
              {t({id: 'Export'})}
            </MenuButton>
          </ReadOnly>
        </Sticky>
      )}

      {!wholeTableView && !cropStressTabSelected ? (
        <>
          <LowPerformingAnomalies />
          <AreasHistoryList />
        </>
      ) : null}
    </div>
  );
};

export default MapCrop;
