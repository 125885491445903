import {t, FormattedMessage} from 'i18n-utils';
import {
  booleanIntersect,
  convertFromSquareMetersToMeasure,
  downloadFile,
  genKey,
  prepareFeaturesForExport,
} from '_utils';
import {showNote} from '_actions';
import {DialogType, toggleDialog} from '_reducers/dialog';
//@ts-ignore
import tokml from 'tokml';
import {getAnomalyLabelValue, GLOBAL_FORMAT_DATE, PERMANENT_ANOMALIES} from '_constants';
import Mixpanel from '_utils/mixpanel-utils';
import {SeasonApi} from '_api';
import moment from 'moment';
import {IInitialMapState, SourceType} from '../../types';
import {featureCollection} from '@turf/helpers';
import {
  updateBulkPremiumAnomaly,
  uploadAnomaliesToAgworld,
  uploadAnomaliesToAgX,
} from '../../actions/anomalies-actions';
import turfArea from '@turf/area';
import {TSensor} from '../../../../types';
import {IAnomaly, Priority, AnomalyTab} from './types';
import {ReactElement} from 'react';

export const userFacingPriority = (priority: Priority, label: string) => {
  if (PERMANENT_ANOMALIES.includes(label)) {
    return '—';
  }
  if (priority === 'medium') {
    return 'med';
  }
  if (!priority) {
    return 'new';
  }
  return priority;
};

/**
 * @param area in square meters
 * @param measurement 'ha' | 'ac'
 */
export const userFacingArea = (area: number, measurement: string) => {
  if (!area) {
    return '';
  }
  const a = Math.abs(convertFromSquareMetersToMeasure(area, measurement));
  return `${a.toFixed(2)} ${t({id: measurement})}`;
};

export const isPermanentAnomaly = (anomaly: IAnomaly) => {
  return isPermanentAnomalyLabel(anomaly?.properties?.label);
};
export const isPermanentAnomalyLabel = (label = '') => {
  return PERMANENT_ANOMALIES.includes(label);
};

const getFileNameToExport = (fileType: string) => (dispatch: any, getState: any) => {
  const {field, group, currentDate}: IInitialMapState = getState().map;
  const fileName = `${group.name}_${field.Name}`;
  const formattedCurrentDate = moment(currentDate, 'DD/MM/YYYY').format(GLOBAL_FORMAT_DATE);
  return `Anomalies_${fileName}_${formattedCurrentDate}.${fileType}`;
};

export const exportAnomaliesToShp = (anomalies: IAnomaly[]) => (dispatch: any) => {
  if (!anomalies.length) {
    dispatch(
      showNote({
        title: t({id: 'note.info', defaultMessage: 'Info'}),
        message: t({id: 'Please select AOI or Stress Detected object.'}),
        level: 'info',
      })
    );

    return false;
  }

  const preparedGeoJsonObjects = prepareFeaturesForExport(anomalies);

  Mixpanel.exportAnomalies('SHP');
  SeasonApi.downloadShapefile(preparedGeoJsonObjects).then(({data}) => {
    downloadFile(data, dispatch(getFileNameToExport('zip')));
  });
};

export const exportAnomaliesToKml = (anomalies: IAnomaly[]) => (dispatch: any) => {
  const preparedGeoJsonObjects: any = prepareFeaturesForExport(anomalies);
  Mixpanel.exportAnomalies('KML');
  //@ts-ignore
  downloadFile(
    tokml(featureCollection(preparedGeoJsonObjects)),
    dispatch(getFileNameToExport('kml'))
  );
};

export const selectedPremiumAnomaliesChangeProp = (prop: string, val?: any) => (
  dispatch: any,
  getState: any
) => {
  const {
    premiumAnomalies: {list},
  }: IInitialMapState = getState().map;
  const anomalies = list.filter((el: any) => el.properties.checked);
  const value = prop === 'snoozed' ? (anomalies.every(a => a.properties.snoozed) ? 0 : 1) : val;
  dispatch(
    updateBulkPremiumAnomaly({
      anomalies,
      prop,
      value,
    })
  );
};

export const onExportToAgX = (anomalies: IAnomaly[], type: AnomalyTab) => (dispatch: any) => {
  if (type === 'areas-of-interest') {
    const isLowPerfPersist = anomalies.some((g: any) => g.properties.isLowPerf);
    if (isLowPerfPersist)
      return dispatch(toggleDialog(DialogType.GeometriesNotAllowedToExportToAgx));
  }

  let booleanIntersectAny = false;
  anomalies.forEach((feature1, index) => {
    if (!booleanIntersectAny) {
      anomalies.forEach((feature2, internalIndex) => {
        if (internalIndex !== index) {
          booleanIntersectAny = booleanIntersect(feature1, feature2);
        }
      });
    }
  });

  if (booleanIntersectAny) {
    return dispatch(
      showNote({
        title: t({id: 'note.warning', defaultMessage: 'Warning'}),
        message: t({
          id:
            'agX does not support overlapping crop stress. Please select not overlapping crop stress to upload.',
        }),
        level: 'warning',
      })
    );
  }
  Mixpanel.exportAnomalies('AgX');
  return dispatch(uploadAnomaliesToAgX(anomalies, type));
};

export const onExportToAgworld = (anomalies: IAnomaly[]) => (dispatch: any) => {
  if (!anomalies.length) {
    dispatch(
      showNote({
        title: t({id: 'note.info', defaultMessage: 'Info'}),
        message: t({id: 'Please select some anomalies to export to Agworld.'}),
        level: 'info',
      })
    );
    return;
  }
  Mixpanel.exportAnomalies('Agworld');
  dispatch(uploadAnomaliesToAgworld(anomalies));
};

export const sumAreas = (anomaliesList: IAnomaly[], measurement: string): string | undefined => {
  if (!anomaliesList.length) {
    return;
  }

  let areasSum = 0;
  anomaliesList.forEach(a => {
    areasSum += a.properties.area;
  });
  if (!areasSum) {
    return;
  }

  return userFacingArea(areasSum, measurement);
};

type SetLowPerfAnomalyProps = {
  features: IAnomaly[];
  date: string;
  dateType: SourceType;
  savedAsRoi: boolean;
};

export const setLowPerfAnomalyProps = (
  {features = [], date, dateType, savedAsRoi}: SetLowPerfAnomalyProps,
  currentSensor: TSensor,
  isLowPerf = false // it can be an uploading ROI geometry
) => {
  return features.map(geometry => {
    const {properties} = geometry;
    // get label value, cause when we export anomaly we save the label, not the value and then
    // we catch the error - No anomaly label info found for the value "some anomaly label"
    const anomalyLabel = getAnomalyLabelValue(properties.label || '');
    if (!isLowPerf) {
      geometry.properties.uploading = true;
    }
    return {
      ...geometry,
      date,
      dateType,
      index: currentSensor,
      savedAsRoi,
      properties: {
        ...geometry.properties,
        area: turfArea(geometry),
        id: geometry.properties.id || genKey(),
        isLowPerf, // to recognize low perf
        startDate: geometry.properties.startDate || date, // for low performing start - end date is the same, cause they are generated for each sensing day
        endDate: geometry.properties.endDate || date,
        checked: false, // reset to default, prevent side effects
        label: anomalyLabel,
      },
    };
  });
};

export const prepareAllLowPerfAnomalies = (lowPerformingAnomalies: any) => {
  const lowPerformingAreas = {} as any;
  lowPerformingAnomalies
    .filter((a: any) => a.GeoJSON.features)
    .forEach((feature: any) => {
      const featureSensor = feature.ImageID.split('_')
        .pop()
        .toUpperCase();
      feature.GeoJSON.features = setLowPerfAnomalyProps(feature.GeoJSON, featureSensor, true);

      lowPerformingAreas[`${feature.ImageID}.png`] = {
        ...feature.GeoJSON,
        date: feature.Date,
        dateType: feature.Type,
      };
    });
  return lowPerformingAreas;
};
