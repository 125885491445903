import {t, FormattedMessage} from 'i18n-utils';
import {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {loadWeatherData} from '../actions';
import {IInitialMapState} from '../types';
type Props = ConnectedProps<typeof connector>;

/**
 * The component doesn't have any rendering, it exists only to handle when to load the weather.
 */
const EffectTemperatureData = ({
  //props
  isNutrilogicToggled,
  isWeatherToggled,
  isFieldInfoToggled,
  hasTemperatureData,
  loadWeatherData,
  feature,
  currentSeason,
}: Props): null => {
  const [shouldLoadWeather, setShouldLoadWeather] = useState(false);

  useEffect(() => {
    if (shouldLoadWeather && currentSeason.startDate && currentSeason.endDate) {
      setShouldLoadWeather(false);
      loadWeatherData();
    }
  }, [shouldLoadWeather, currentSeason]);

  useEffect(
    function checkWeatherAndLoadIfNecessary() {
      if (hasTemperatureData) return;

      if (
        isNutrilogicToggled ||
        isWeatherToggled ||
        isFieldInfoToggled ||
        ['analytics'].includes(feature)
      ) {
        setShouldLoadWeather(true);
      }
    },
    [
      isNutrilogicToggled,
      isWeatherToggled,
      feature,
      isFieldInfoToggled,
      hasTemperatureData,
      currentSeason, // to handle when the season was changed
    ]
  );

  return null;
};

const mapStateToProps = ({map}: {map: IInitialMapState}) => ({
  currentSeason: map.currentSeason,
  isNutrilogicToggled: map.nRecommendation.toggled && map.nRecommendation.method === 'nutrilogic',
  isWeatherToggled: map.toggledButtonOnMap === 'Weather',
  isFieldInfoToggled: map.toggledButtonOnMap === 'FieldInfo',
  feature: map.feature,
  hasTemperatureData: !!map.temperatureData.length,
});

const connector = connect(mapStateToProps, {
  loadWeatherData,
});
export default connector(EffectTemperatureData);
