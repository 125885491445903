import {centroid, featureCollection} from '@turf/turf';
import * as React from 'react';
import {useState, useEffect, useMemo, useCallback, memo} from 'react';
import {FeatureGroup, GeoJSON as ReactLeafletGeoJSON, Popup} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {
  Tillage,
  tillageColorScale,
  tillageLabels,
  WinterCropType,
  winterCropColorScale,
  OptisType,
  geometryIdMap,
  tillageColor,
  winterCropColor,
  winterCropLabels,
  winterCropOrder,
  OptisItemTillage,
  OptisItemWinterCrop,
} from './optis-types';
import chroma from 'chroma-js';
import {
  maxTillage,
  maxWinterCrop,
  nextAreaType,
  sumTillage,
  sumTillageAcrossYears,
  sumWinterCrop,
  sumWinterCropAcrossYears,
  getColorScale,
  formatNumber,
  minMaxPctAcrossGeometries,
  roundPercentage,
  diffPctTillage,
  diffPctWinterCrop,
} from './optis-utils';
import {setFilterGeometry, setAreaType} from './optis-reducer';
import styled from 'styled-components';
import './optis-overlay.scss';
import {OptisDiffPopupContent, OptisPopup, OptisPopupContent} from './base/map-popup';
import {RequestStatus} from 'types';

/**
 * Optis map overlay.
 */
export const OptisOverlay = ({fitBounds}: {fitBounds: (bounds?: L.LatLngBounds) => void}) => {
  const dispatch = useDispatch();
  const [rerenderKey, setRerenderKey] = useState(0);
  const [popupId, setPopupId] = useState(null);
  const [closePopupTimeout, setClosePopupTimeout] = useState<ReturnType<typeof setTimeout>>(
    undefined
  );
  const closePopup = useCallback(() => setPopupId(null), []);
  const optis = useSelector((s: AppStore) => s.optis);

  const popupContent = useMemo(() => {
    if (!popupId) return null;
    const onMouseEnter = () => clearTimeout(closePopupTimeout);
    const onMouseOut = () => setClosePopupTimeout(setTimeout(closePopup, 250));
    const Content =
      optis.diffMode && optis.diffYearA && optis.diffYearB
        ? OptisDiffPopupContent
        : OptisPopupContent;
    return (
      <Content
        id={popupId}
        name={optis.meta[optis.areaType]?.[popupId]?.name || popupId}
        optis={optis}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
      />
    );
  }, [popupId, optis, closePopupTimeout]);

  const geometryIds = useMemo(() => {
    return Object.keys(optis.geometries)
      .map(Number)
      .filter(id => optis[optis.filter.type][id]);
  }, [optis.geometries, optis[optis.filter.type], optis.filter.type]);

  useEffect(() => {
    if (!geometryIds.length) {
      return;
    }
    const geometries = geometryIds.map(id => optis.geometries[id]);
    const collection = featureCollection(geometries);
    // @ts-ignore featureCollection returns type that L.geoJSON doesn't expect, just misunderstanding between two libraries
    const geoJSON = L.geoJSON(collection);
    const bounds = geoJSON.getBounds();
    fitBounds(bounds);
    setRerenderKey(k => k++);
  }, [geometryIds]);

  const {min, max} = minMaxPctAcrossGeometries(optis, geometryIds);
  const getColor = getColorScale(optis, min, max);

  return (
    <>
      {optis.status !== RequestStatus.Loading && (
        <OptisPopup
          geometry={optis.geometries[popupId]}
          content={popupContent}
          onClose={closePopup}
        />
      )}
      <FeatureGroup key={rerenderKey}>
        {geometryIds.map(id => {
          const feature = optis.geometries[id];
          let color = '#777';
          let opacity = 0;
          let showGeometry =
            optis[optis.filter.type][id] &&
            (optis.filter.geometries.length === 0 || optis.filter.geometries.includes(id));
          if (showGeometry) {
            switch (optis.filter.type) {
              case OptisType.Tillage: {
                const data = optis[optis.filter.type][id];

                let tillage: OptisItemTillage;
                let pct: number;
                if (optis.diffMode && optis.diffYearA && optis.diffYearB) {
                  tillage = diffPctTillage(data[optis.diffYearA], data[optis.diffYearB]);
                  pct = optis.filter.value.reduce((acc, t) => acc + tillage[t], 0);
                } else {
                  tillage = sumTillageAcrossYears(data, optis.filter.years);
                  const total = sumTillage(tillage);
                  const value = optis.filter.value.reduce((acc, t) => acc + tillage[t], 0);
                  pct = roundPercentage((value / total) * 100);
                }

                // Find the value percentage inside the min..max range.
                opacity = min === max ? 1 : (pct - min) / (max - min);
                if (optis.areaType === 'Segment') {
                  const t = maxTillage(tillage);
                  color = optis.filter.value.includes(t) ? tillageColor[t] : 'white';
                } else {
                  color = getColor(opacity).css();
                }
                break;
              }
              case OptisType.WinterCrop: {
                const data = optis[optis.filter.type][id];

                let winterCrop: OptisItemWinterCrop;
                let pct: number;
                if (optis.diffMode && optis.diffYearA && optis.diffYearB) {
                  winterCrop = diffPctWinterCrop(data[optis.diffYearA], data[optis.diffYearB]);
                  pct = optis.filter.value.reduce((acc, t) => acc + winterCrop[t], 0);
                } else {
                  winterCrop = sumWinterCropAcrossYears(data, optis.filter.years);
                  const total = sumWinterCrop(winterCrop, true);
                  const value = optis.filter.value.reduce((acc, t) => acc + winterCrop[t], 0);
                  pct = roundPercentage((value / total) * 100);
                }

                // Find the value percentage inside the min..max range.
                opacity = max === min ? 1 : (pct - min) / (max - min);
                if (optis.areaType === 'Segment') {
                  const w = maxWinterCrop(winterCrop);
                  color =
                    w.value > 0 && optis.filter.value.includes(w.category)
                      ? winterCropColor[w.category]
                      : 'white';
                  if (w.value === 0) {
                    showGeometry = false;
                  }
                } else {
                  color = getColor(opacity).css();
                }
                break;
              }
            }
          }
          return (
            <ReactLeafletGeoJSON
              key={id}
              pathOptions={{
                weight: 1,
                opacity: !showGeometry ? 0.5 : 1,
                fillColor: color,
                fillOpacity: !showGeometry ? 0.5 : 1,
                color: !showGeometry ? '#999' : 'black',
              }}
              // fillColor={color}
              // fillOpacity={!showGeometry ? 0.5 : 1}
              // color={!showGeometry ? '#999' : 'black'}
              // opacity={!showGeometry ? 0.5 : 1}
              // weight={1}
              data={feature}
              eventHandlers={{
                dblclick: () => {
                  clicked = 0;
                  dispatch(setAreaType(nextAreaType(optis.areaType), id));
                },
                mouseover: () => {
                  clearTimeout(closePopupTimeout);
                  setPopupId(id);
                },
                mouseout: () => {
                  setClosePopupTimeout(setTimeout(closePopup, 250));
                },
                click: e => {
                  clicked += 1;
                  setTimeout(() => {
                    if (clicked === 1) {
                      clicked = 0;
                      const multiselect =
                        e.originalEvent.metaKey ||
                        e.originalEvent.shiftKey ||
                        e.originalEvent.ctrlKey;
                      dispatch(setFilterGeometry(id, multiselect));
                    }
                  }, dblClickCheckTime);
                },
              }}
              // ondblclick={e => {
              //   clicked = 0;
              //   dispatch(setAreaType(nextAreaType(optis.areaType), id));
              // }}
              // onmouseover={e => {
              //   clearTimeout(closePopupTimeout);
              //   setPopupId(id);
              // }}
              // onmouseout={e => {
              //   setClosePopupTimeout(setTimeout(closePopup, 250));
              // }}
              // onclick={e => {
              //   clicked += 1;
              //   setTimeout(() => {
              //     if (clicked === 1) {
              //       clicked = 0;
              //       const multiselect =
              //         e.originalEvent.metaKey ||
              //         e.originalEvent.shiftKey ||
              //         e.originalEvent.ctrlKey;
              //       dispatch(setFilterGeometry(id, multiselect));
              //     }
              //   }, dblClickCheckTime);
              // }}
            />
          );
        })}
      </FeatureGroup>
    </>
  );
};
let clicked = 0;
const dblClickCheckTime = 250;
