import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import EditGeometryForm from './edit-geometry-form';
import {toggleEditingMode} from '../../actions';
import {
  removeAreaOfInterest,
  changeAreaOfInterestProp,
  toggleEditAreaOfInterest,
  updateAreaOfInterest,
  createAreaOfInterest,
} from '../../actions/areas-of-interest-actions';
import {showNote} from '_actions';
import {State as ViewportState} from '_reducers/viewport';
import FluroDialog from 'components/fluro-dialog';
import {IInitialMapState} from '../../types';
import {IAnomaly} from './types';

class MapGeometry extends Component<any> {
  remove = (geometry: IAnomaly) => {
    const {toggleEditAreaOfInterest, removeGeometryFromMap, removeAreaOfInterest} = this.props;
    if (removeGeometryFromMap) {
      removeGeometryFromMap(geometry.properties.id);
      removeAreaOfInterest(geometry.properties.id);
      toggleEditAreaOfInterest(false);
    }
  };

  removeOnClose = (geometry: IAnomaly) => {
    const {toggleEditAreaOfInterest, removeGeometryFromMap} = this.props;
    toggleEditAreaOfInterest(false);
    if (geometry.properties.id === 0) {
      // it is a draft
      removeGeometryFromMap(geometry.properties.id);
    }
  };

  saveGeometry = async (geometry: IAnomaly) => {
    const {
      toggleEditAreaOfInterest,
      removeGeometryFromMap,
      updateAreaOfInterest,
      createAreaOfInterest,
    } = this.props;

    if (geometry.properties.id === 0) {
      await createAreaOfInterest(geometry);
    } else {
      await updateAreaOfInterest(geometry);
    }

    removeGeometryFromMap(geometry.properties.id); // remove geometry from map, because it will appear as geojson shape
    toggleEditAreaOfInterest(false);
  };

  enableEditGeometry = (geometry: IAnomaly) => {
    this.props.changeAreaOfInterestProp(geometry, 'saved', 'edit');
    this.props.toggleEditAreaOfInterest(false, geometry);
    this.props.toggleEditingMode(true);
  };

  render() {
    const {state, geometry}: {state: boolean; geometry: IAnomaly} = this.props.editGeometry;
    const {measurement, currentSeason, currentSensor, currentDate, range, viewport} = this.props;

    return (
      <FluroDialog
        id="geometry-show-pop-up"
        portal
        isDraggable
        fullPage={viewport.width < 561}
        centered={viewport.width >= 561}
        visible={state}
        onHide={() => this.removeOnClose(geometry)}
      >
        <EditGeometryForm
          geometry={geometry}
          measurement={measurement}
          currentSensor={currentSensor}
          currentSeason={currentSeason}
          currentDate={currentDate}
          onClose={() => this.removeOnClose(geometry)}
          onEdit={() => this.enableEditGeometry(geometry)}
          onDelete={() => this.remove(geometry)}
          onSubmit={this.saveGeometry}
          range={range}
        />
      </FluroDialog>
    );
  }
}

const mapStateToProps = ({
  map,
  login,
  viewport,
}: {
  map: IInitialMapState;
  login: any;
  viewport: ViewportState;
}) => ({
  range: map.histogram.range,
  field: map.field,
  editGeometry: map.editGeometry,
  measurement: login.user.settings.measurement,
  currentSensor: map.currentSensor,
  currentSeason: map.currentSeason,
  currentDate: map.currentDate,
  viewport,
});

export default connect(mapStateToProps, {
  changeAreaOfInterestProp,
  removeAreaOfInterest,
  showNote,
  toggleEditAreaOfInterest,
  updateAreaOfInterest,
  toggleEditingMode,
  createAreaOfInterest,
})(MapGeometry);
