import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {GeoJSON, useMapEvent} from 'react-leaflet';
import {Feature} from 'geojson';
import L from 'leaflet';
import {
  updateCLUFieldBoundariesProp,
  addFieldChangeCurrentStep,
  checkPointForFieldBoundaries,
  AddingFieldsZoomThreshold,
} from 'modules/add-fields.module';
import {DialogType} from '_reducers/dialog';
import {FieldSystemProp} from '../features/farm/new-fields/types';
import {MAIN_SHAPE_COLOR} from "../../../_constants";

type Props = {
  fitBounds: (bounds?: L.LatLngBounds) => void;
  leafletElement: L.Map;
};

const CLUFieldBoundariesOverlay = ({fitBounds, leafletElement}: Props) => {
  const dispatch = useDispatch();
  const fieldBoundaries = useSelector((s: AppStore) => s.addFields.cluFieldBoundaries);
  const drewFieldsGeometries = useSelector((s: AppStore) => s.addFields.drewFieldsGeometries);
  const addFieldCurrentStep = useSelector((s: AppStore) => s.addFields.addFieldCurrentStep);
  const addFieldDialogOpened = useSelector(
    (s: AppStore) => s.dialog.currentDialog === DialogType.AddNewField
  );
  // provide a ref to access to prop in closure functions invoked by leaflet methods
  const searchBoundariesStateRef = useRef(null);
  const [geometryKey, setGeometryKey] = useState(1);
  const searchBoundariesState =
    ['zoom-is-too-low', 'search-location', 'select-boundaries'].includes(addFieldCurrentStep) ||
    ('draw-fields' === addFieldCurrentStep && !drewFieldsGeometries.length);

  useEffect(
    function provideARefForSearchBoundariesState() {
      searchBoundariesStateRef.current = searchBoundariesState;
    },
    [searchBoundariesState]
  );

  useEffect(() => {
    return () => {
      dispatch(addFieldChangeCurrentStep('add-fields'));
      //TODO: remove code below
      // dispatch(toggleDialog(DialogType.AddNewField, false));
    };
  }, []);

  const onMapPanned = useCallback(() => {
    if (!searchBoundariesStateRef.current) return; // ensure we are not doing things without accurate app state | can be refactored once move to react-leaflet >3.0

    const leafletZoom = leafletElement.getZoom();
    if (leafletZoom >= AddingFieldsZoomThreshold) {
      // request boundaries only if zoom level is more than 14
      const center = leafletElement.getCenter();
      dispatch(checkPointForFieldBoundaries(center.lat, center.lng));
    } else {
      checkZoomLevel();
    }
  }, []);

  const checkZoomLevel = () => {
    if (!searchBoundariesStateRef.current) return; // ensure we are not doing things without accurate app state

    const zoomLevel = leafletElement.getZoom();
    if (zoomLevel < AddingFieldsZoomThreshold) {
      dispatch(addFieldChangeCurrentStep('zoom-is-too-low'));
    }
  };

  useEffect(
    function searchBoundariesOnceStartedAddFlow() {
      if (addFieldCurrentStep === 'search-location') {
        onMapPanned();
      }
    },
    [addFieldCurrentStep]
  );

  useMapEvent('moveend', () => {
    if (searchBoundariesState) {
      onMapPanned();
    }
  });

  useMapEvent('zoomend', () => {
    if (addFieldDialogOpened && searchBoundariesState) {
      checkZoomLevel();
    }
  });

  useEffect(
    function updateGeoJsonKey() {
      setGeometryKey(geometryKey + 1);
    },
    [fieldBoundaries, addFieldCurrentStep]
  );

  const onEachFeature = (feature: Feature, layer: L.Path) => {
    const selected = feature.properties[FieldSystemProp.Checked];
    layer.setStyle({
      color: '#fff',
      fillColor: selected ? MAIN_SHAPE_COLOR : 'transparent',
      className: `field-boundary-shape ${selected ? 'selected' : ''}`, // add the class for cypress needs
    });

    layer.on({
      click: e => {
        const properties = e?.target?.feature?.geometry?.properties;
        if (properties?.id) {
          dispatch(
            updateCLUFieldBoundariesProp(
              properties?.id,
              FieldSystemProp.Checked,
              !properties?.[FieldSystemProp.Checked]
            )
          );
          if (addFieldCurrentStep === 'draw-fields') {
            // switch back to select-boundary mode
            dispatch(addFieldChangeCurrentStep('select-boundaries'));
          }
        }
      },
      mouseover: e => {
        // highlight on hover
        const layer = e?.target;
        layer?.setStyle?.({fillColor: MAIN_SHAPE_COLOR});
      },
      mouseout: e => {
        // remove highlighting on mouse out
        const layer = e?.target;
        const selected = layer?.feature?.geometry?.properties?.[FieldSystemProp.Checked];
        layer?.setStyle?.({fillColor: selected ? MAIN_SHAPE_COLOR : 'transparent'});
      },
    });
  };

  return searchBoundariesState ? (
    <GeoJSON
      key={geometryKey}
      fillOpacity={1}
      weight={2}
      //@ts-ignore
      data={fieldBoundaries}
      onEachFeature={onEachFeature}
    />
  ) : null;
};

export default CLUFieldBoundariesOverlay;
