import {t, FormattedMessage} from 'i18n-utils';
import React from 'react';
import {Button} from 'react-md';
import {TExternalUser, TUser} from './types';
import SimplePreloader from 'components/simple-preloader';
import {AsyncStatusType} from 'modules/ui-helpers';

type IProps = {
  users: TUser[];
  externalUsers: TExternalUser[];
  isReadOnly: boolean;
  user: TUser;
  removeUserEmail: (id: number) => void;
};

const InviteUserList = ({
  users = [],
  externalUsers = [],
  isReadOnly,
  removeUserEmail,
  user,
}: IProps) => {
  return (
    <div className="invite-user-list">
      <h3>Users invited to the farm</h3>

      <div className="invite-user-list__container">
        <SimplePreloader statusKeys={[AsyncStatusType.loadOneFarm]} loadingContent={'Loading...'} />

        {users.length ? (
          <div className={'users-like-table'}>
            <div className={'email-side invite-user-list__title'}>Email</div>
            <div className={'action-side invite-user-list__title'}>Action</div>
            {users.map(u => (
              <div className={'row'} key={u.id}>
                <div className={'email-side'} title={u.email}>
                  {u.email}
                </div>
                <div className={'action-side'}>
                  {!isReadOnly && (
                    <Button
                      onClick={() => removeUserEmail(u.id)}
                      disabled={u.id === user.id}
                      tooltipLabel="Delete from farm"
                      tooltipPosition="left"
                      icon
                      primary
                    >
                      close
                    </Button>
                  )}
                </div>
              </div>
            ))}

            <div className={'element-full-width'}>
              {externalUsers.map((extUser, i) => {
                const {Id, type, TerravionId, FirstName, LastName, Email, Growers = []} = extUser;

                if (!Id) return null;
                return (
                  <div key={`agr-${i}`}>
                    <h3 style={{marginTop: 10}}>{type}</h3>

                    <h4 style={{marginBottom: 0}}>
                      <b>Agronomist</b>
                    </h4>
                    <div className="row" data-terravion-id={TerravionId}>
                      <div className={'email-side subscribers'} title={Email}>
                        {FirstName} {LastName} ({Email})
                      </div>
                    </div>

                    <h4 style={{marginBottom: 0}}>
                      <b>Growers</b>
                    </h4>
                    {Array.isArray(Growers) &&
                      Growers.map((u: any) => (
                        <div className="row" data-terravion-id={u.TerravionId} key={u.Id}>
                          <div className={'email-side subscribers'} title={u.Email}>
                            {u.FirstName} {u.LastName} ({u.Email})
                          </div>
                        </div>
                      ))}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <b>No users</b>
        )}
      </div>
    </div>
  );
};

export default InviteUserList;
