import React, {ReactChildren, ReactElement, ReactNode} from 'react';
import {Wrapper, WrapperGroup, CPBadgeWrapper} from './badge.styled';
import variables from 'variables.scss';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';

type Appearance = 'success' | 'warning' | 'alert';

type Props = {
  value: number | string | ReactChildren;
  appearance?: Appearance;
};

const COLORS: {[key in Appearance]: string} = {
  success: '#43a047',
  warning: variables['orange-papaya'],
  alert: '#f2405d',
};

export const Badge = ({appearance = 'warning', value}: Props) => (
  <Wrapper style={{backgroundColor: COLORS[appearance]}}>{value}</Wrapper>
);

export const BadgeGroup = ({children}: {children: ReactElement[]}) => (
  <WrapperGroup>{children}</WrapperGroup>
);

type CPBadgeProps = {
  backgroundColor: string;
  color: string;
  children: ReactNode;
  actionable?: boolean; // adds cursor: pointer;
};

export const CPBadge = ({backgroundColor, color, children, actionable}: CPBadgeProps) => (
  <CPBadgeWrapper
    className={cn({'fluro-cp-badge': true, actionable})}
    style={{
      backgroundColor,
      color: color,
    }}
  >
    {children}
  </CPBadgeWrapper>
);
