import {t, FormattedMessage} from 'i18n-utils';
import {useState, useEffect, ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadCropTypes} from '_actions';
import {checkAuth} from './login/actions';
import {getFarmsList} from 'containers/farm/actions';
import {AppStore} from 'reducers';
type Props = {
  children: ReactElement;
};
const BootAuthContainer = ({children}: Props) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(true);
  const sessionExpired = useSelector((s: AppStore) => s.global.sessionExpired);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(checkAuth());

        await dispatch(loadCropTypes());

        dispatch(getFarmsList());
      } catch (e) {}

      setFetching(false);
    })();
  }, []);

  // The weirdest magic here: for some reason in the pr https://github.com/flurosat/flurosense-ui/pull/1425
  // when auth returns 401 the code outside try above doesn't run and `fetching` does not get reset.
  useEffect(() => {
    if (sessionExpired) {
      setFetching(false);
    }
  }, [sessionExpired]);

  return fetching ? null : children;
};

export default BootAuthContainer;
