import {
  MAPPING_ROLES_TO_PROPS,
  COLORS,
  ANOMALY_LABELS,
  GLOBAL_FORMAT_DATE,
  SOURCES,
  SAMPLING_POINTS_LABELS,
  CARBON_ALLOWED_REGIONS,
} from '_constants';

import Moment from 'moment';
import {hackGetState} from '../store';
//@ts-ignore
import Yup from 'yup';
import {getAllVisibleGeometries} from 'containers/map/utils/';
import convert from 'convert-units';
import moment from 'moment';

// import {extendMoment} from 'moment-range';
import pathToRegexp from 'path-to-regexp';
//@ts-ignore
import booleanOverlap from '@turf/boolean-overlap';
import booleanContains from '@turf/boolean-contains';
import {feature as turfFeature} from '@turf/helpers';
import turfArea from '@turf/area';
import lineToPolygon from '@turf/line-to-polygon';
import {ActivityApi} from '../_api';
//@ts-ignore

import {
  Field,
  SamplingPoint,
  Season,
  TInfoExt,
  IInitialMapState,
  SourceMeta,
  TDateLayer,
  GeoJsonType,
  Farm,
  SourceType,
  IterisTemperatureDay,
} from 'containers/map/types';

import get from 'lodash.get';
import {TSensor, Dialogs} from 'types';
import {CropType, GlobalState} from '../_reducers/global_types';
import React from 'react';
import {IAnomaly} from 'containers/map/features/anomalies/types';
import L from 'leaflet';
import config from '_environment';

import {getGetURLParam, setGetParamToURL, toFixedFloat, sortDates} from './pure-utils';
export * from './pure-utils';
export * from './map-utils';
export * from './farm-utils';

//@ts-ignore
// const moment = extendMoment(Moment);

import {DEFAULT_LOCALE, t} from 'i18n-utils';
import {IUser} from 'containers/login/types';
import {Permission} from 'containers/map/mini-menu/mini-menu-links';
import {reportError} from 'containers/error-boundary';

export const FeetToMeter = (ft: number) => {
  return ft === 0 ? 0 : (ft / 3.2808).toFixed(2);
};

export const isAdmin = () => {
  const store = hackGetState();
  return userIsAdmin(store.login.user);
};

export const userIsAdmin = (user: IUser) => {
  const props = MAPPING_ROLES_TO_PROPS[user.perm];
  return Boolean(props?.prop === 'admin');
};

export const isAdminPerm = (perm: number) => {
  return Boolean(MAPPING_ROLES_TO_PROPS[perm] && MAPPING_ROLES_TO_PROPS[perm].prop === 'admin');
};

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function debounce(func: any, wait: number, immediate: boolean) {
  let timeout: any;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const convertTileDateToNormal = (str: string, serverFormat = false) =>
  moment(str).format(serverFormat ? 'DD/MM/YYYY' : formatDate());

export function removeDuplicates(myArr: any[], prop: number | string) {
  return myArr.filter((obj, pos, arr) => {
    return arr.findIndex(currentObj => currentObj?.[prop] === obj?.[prop]) === pos;
  });
}

// crop utils
export function cropId2IconPath(id: string) {
  const crops = getCropTypes();
  return crops[id] && crops[id].icon ? `/assets/crops/${crops[id].icon}` : null;
}

export function cropId2FirstChar(id: string) {
  const crops = getCropTypes();
  return crops[id] ? crops[id].label.charAt(0).toUpperCase() : (id || '').charAt(0).toUpperCase();
}

export function cropId2Subtypes(id: string) {
  const crops = getCropTypes();
  return crops[id] && crops[id].subtypes ? [...crops[id].subtypes] : [];
}

export const getCropLabelById = (id: string) => {
  const crops = getCropTypes();
  return crops[id] ? crops[id].label : id;
};
export const getCropTypeById = (id: string) => {
  const crops = getCropTypes();
  return crops[id] ? crops[id].type : '';
};
export const getCropColorById = (id: string) => {
  const crops = getCropTypes();
  return crops[id] ? crops[id].color : '#efefef';
};

export const getCropTypes = (): GlobalState['cropTypes'] => {
  const store = hackGetState();
  return store.global.cropTypes;
};

export const getCropTypesList = (): CropType[] => {
  return sortByKey(Object.values(getCropTypes()), 'label');
};

export function cropSubtype2Label(crop: string, cropSubType: string) {
  const subtypes = cropId2Subtypes(crop.toLowerCase());
  const subtype = subtypes.length ? subtypes.find(s => s.value === cropSubType) : null;
  return subtype ? subtype.label : cropSubType;
}

// sort functions
export function sortByKey(array: Array<any> = [], key: string | number, keySensitive = true) {
  return array.sort(function(a, b) {
    let x = get(a, key);
    let y = get(b, key);
    if (!keySensitive && typeof x === 'string' && typeof y === 'string') {
      x = x.toLowerCase();
      y = y.toLowerCase();
    }
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function sortByStringKey(array: Array<any>, key: string | number) {
  const isCropTypeSort = key === 'CropType';
  return array.sort(function(a, b) {
    const x = isCropTypeSort ? a[key] || 'W' : a[key].toUpperCase();
    const y = isCropTypeSort ? b[key] || 'W' : b[key].toUpperCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function naturalSortAlphaNum(array: any[], key: string | number) {
  return array.sort((a, b) =>
    a[key]?.localeCompare(b[key], undefined, {numeric: true, sensitivity: 'base'})
  );
}

export function sortByDateKey(array: Array<any>, key: string | number, descent = false) {
  return array.sort(function(a, b) {
    if (!a[key]) return -1;
    if (!b[key]) return 1;
    if (moment(a[key]).isBefore(moment(b[key]))) return descent ? 1 : -1;
    if (moment(a[key]).isAfter(moment(b[key]))) return descent ? -1 : 1;
    return 0;
  });
}

export function sortFieldsByProp(
  fields: Field[],
  byProp: string,
  sortType: number | string
): Field[] {
  switch (sortType) {
    case 'date': {
      return sortByDateKey(fields, byProp);
    }
    case 'string': {
      return [...naturalSortAlphaNum(fields, byProp)];
    }
    case 'number': {
      return sortByKey(fields, byProp);
    }

    default:
      return fields;
  }
}

export function isDateBeforeNow(date: string) {
  return moment(date, GLOBAL_FORMAT_DATE).isSameOrBefore(moment({hour: 0}));
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function ha2ac(ha: number) {
  return (ha * 2.4711).toFixed(2);
}

export function equalTo(ref: any, msg: string) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || `${ref.path} must be the same as ${ref.key}`,
    params: {
      reference: ref.path,
    },
    test: function(value: any) {
      return value === this.resolve(ref);
    },
  });
}

export function selectMeasurement(area: any) {
  const store: any = hackGetState();
  return `${convertUnit(store.login.user.settings.measurement, 'ac', area || 0)} ${t({
    id: store.login.user.settings.measurement,
  })}`;
}

// date formats
export const formats: {[key: string]: string} = {
  // "en-AU": "DD/MM/YYYY",
  // "ru-RU": "DD.MM.YYYY",
  'en-US': 'MM/DD/YYYY',
  'uk-UA': 'DD MMM YYYY',
};

export function getLocaleDateFormat(): string {
  const state: any = hackGetState();
  return formats[state.login.user.settings.locale] || 'DD MMM YYYY';
}

export function formatDate() {
  return getLocaleDateFormat();
}

export const formatLocaleNumber = (value: number, locale?: string) => {
  try {
    //TODO: made NumberFormat param variable
    return Intl.NumberFormat(locale || DEFAULT_LOCALE).format(value);
  } catch (e) {
    console.warn(e.message);
    return value;
  }
};

export function download(filename: string, data: any, isImage = false) {
  const element = document.createElement('a');
  const href = isImage ? data : 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
  element.setAttribute('href', href);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

// convert to square meters
export const convertFromMesureToSquareMeters = (value: number, measure = 'ha') => {
  switch (measure) {
    case 'ha':
      return value * 10000;

    case 'ac':
      return value * 4046.86;

    default:
      console.warn('Unknown measure at convertFromMesureToSquareMeters func');
      return 0;
  }
};

// convert from square meters to mesure
export const convertFromSquareMetersToMeasure = (value: number, measure: string = 'ha') => {
  switch (measure) {
    case 'ha':
      return value / 10000;

    case 'ac':
      return value / 4046.86;

    default:
      console.warn('Unknown measure at convertFromSquareMetersToMeasure func');
      return 0;
  }
};

export const deepCopy = (value: any | null) => JSON.parse(JSON.stringify(value));

export const parseNumber = (n: any) => {
  if (n === null || n === undefined) return 0;
  n = n + ''.trim();
  return isFinite(n) ? (/\.{1}/.test(n) ? parseFloat(n) : n === '' ? n : parseInt(n, 10)) : n;
};

/**
 * Normalizes the value to the `range`, given that value is of the range 0..255.
 *
 * value  range  result
 * 0..255,  0 ->  0..1
 * 0..255, -1 -> -1..1
 *
 * @param range The beginning of the range: 0..1 or -1..1. Could be [0|-1]
 */
export const normalizeSensorIndex = (value: number, range: number, roundDigits = 2) => {
  switch (range) {
    case 0:
      return toFixedFloat(value / 255, roundDigits);

    case -1:
      return toFixedFloat((value * 2) / 255 - 1, roundDigits);

    default:
      return value.toFixed(0);
  }
};

/**
 * Demormalizes the value using the range.
 *
 * value range  result
 *  0..1,  0 -> 0..255
 * -1..1, -1 -> 0..255
 *
 * @param range The range was used to normalize the value. Could be [0|-1]
 */
export const denormalizeSensorIndex = (value: string | number, range: number) => {
  switch (range) {
    case 0:
      return +value * 255;

    case -1:
      return ((+value + 1) / 2) * 255;

    default:
      return +value;
  }
};

/* eslint-disable */
export const pointInside = (point: [number, number], vs: Array<any>) => {
  const x = point[0],
    y = point[1];
  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};
/* eslint-enable */

export const checkForGeometryCollection = (feature: any): boolean | undefined => {
  return feature?.geometry?.type && feature?.geometry?.type === 'GeometryCollection';
};

export class EmptyStringNumber extends Yup.number {
  _typeCheck(value: any) {
    //@ts-ignore
    return value === '' || super._typeCheck(value);
  }

  required(message = 'Required String') {
    //@ts-ignore
    return this.test({
      message,
      name: 'requiredString',
      test: function(value: any) {
        return value !== '';
      },
    });
  }
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getAnalyticsItemColor = (additionalArray: Array<any> = []) => {
  const state: any = hackGetState();
  const {
    currentSeason,
    analytics,
    premiumAnomalies: {list},
  }: IInitialMapState = state.map;

  const geometriesColors = getAllVisibleGeometries()
    .filter(g => g.properties.color)
    .map(g => g.properties.color);
  const currentColorsTSP = currentSeason.tissueSampling
    ? currentSeason.tissueSampling
        .filter((tsp: SamplingPoint) => tsp.properties.color)
        .map((tsp: SamplingPoint) => tsp.properties.color)
    : [];
  const currentColorsAnalytics = analytics.points
    ? analytics.points.filter(point => point.color).map(point => point.color)
    : [];

  const premiumAnomaliesColors = list.map(anomaly => anomaly.properties.color);
  const currentColors = [
    ...currentColorsTSP,
    ...currentColorsAnalytics,
    ...geometriesColors,
    ...additionalArray,
    ...premiumAnomaliesColors,
  ];
  return getTheNextColorFromPredefinedList(currentColors);
};

export const getTheNextColorFromPredefinedList = (alreadyTakenColors: string[]) => {
  const colorToReturn = COLORS.find(color => {
    return !alreadyTakenColors.includes(color);
  });

  return colorToReturn || getRandomColor();
};

export const getCurrentImageName = () => {
  const currentImage = getCurrentImage();
  return currentImage ? currentImage.name : '';
};

export const getCurrentImage = (mapState?: IInitialMapState) => {
  const store = hackGetState();
  const {currentDates, currentDate, currentSensor} = mapState || store.map;
  return get(currentDates, `${currentDate}.${currentSensor}`, '');
};

export const getCurrentDateTime = () => {
  const store = hackGetState();
  const {currentDates, currentDate} = store.map;
  return get(currentDates, `${currentDate}.Date`, '');
};

export const getMeasurement = () => {
  const {login} = hackGetState();
  return login.user.settings.measurement || 'ha';
};

export const getWeatherObjectByDay = (searchedDay: string = '') => {
  const {map} = hackGetState();
  return map.preparedTemperatureData[searchedDay] || ({} as IterisTemperatureDay);
};

/*
 *
 * KEY FORMAT: DD/MM/YYYY-source
 *
 * EXAMPLE: 20/12/1991-satellite
 *
 * */
export const getDateFromKey = (key = '') => {
  return {
    date: moment(key, 'DD/MM/YYYY').format(formatDate()),
    type: key.split('-')[1],
  };
};

export const getFieldFormattedDates = (infoExt: Array<any> = []): {[date: string]: TInfoExt} => {
  const currentDates: any = {};
  infoExt.forEach(d => {
    Object.keys(d).forEach(key => {
      const sensorDate = d[key].display;
      const sensor = d[key].sensor;
      const sourceType = d.Type;
      if (!sensorDate || !sensor || !sourceType) {
        return;
      }

      let date = currentDates[`${sensorDate}-${sourceType}`] || {};

      date[sensor] = d[key];
      date[sensor].Cloud = d.Cloud;
      date[sensor].Hidden = d.Hidden;
      date[sensor].type = d.Type;
      date.appName = d.appName || [];

      // normalization date object format between WholeFarm dates and single field dates
      date.Cloud = d.Cloud;
      date.Hidden = d.Hidden;
      date.Type = d.Type;
      date.Date = d.Date;
      // date.avgNdvi = d.avgNdvi; - DEPRECATED

      date = {
        ...d,
        ...date,
      };

      currentDates[`${sensorDate}-${sourceType}`] = date;
    });
  });
  return currentDates;
};

export const buildAnomalyUrl = (particularDate?: string) => {
  const store: any = hackGetState();
  const {
    group: {id},
    selectedFieldId,
    currentDate,
    currentDates,
    currentSensor,
  } = store.map;
  const dateToUpdate = particularDate || currentDate;

  if (
    currentDates?.[dateToUpdate]?.[currentSensor] &&
    (currentSensor !== 'NONE' || currentSensor === 'TCI')
  ) {
    return `${id}/${selectedFieldId}/${currentDates[dateToUpdate][currentSensor].name}`;
  }
  return '';
};

export const getGeometryLabelByValue = (value = '') => {
  const item = ANOMALY_LABELS().find(l => l.value === value.toLowerCase());
  return item?.label || value || 'No label';
};

// https://github.com/watson/nearest-date
export function findClosestDate(target: any, dates: Array<any>) {
  target = target.valueOf();

  let nearest = Infinity;
  let winner = -1;

  dates.forEach(function(date, index) {
    if (date instanceof Date) date = date.valueOf();
    const distance = Math.abs(date - target);
    if (distance < nearest) {
      nearest = distance;
      winner = index;
    }
  });

  return winner;
}

export const getNextClosestDate = (
  target: Moment.Moment,
  dates: string[]
): Moment.Moment | null => {
  let closest: Moment.Moment = undefined;

  dates.forEach(dateString => {
    const d = moment(dateString);
    if (d.isBefore(target)) {
      return;
    }
    if (!closest || d.isBefore(closest)) {
      closest = d;
      return;
    }
  });

  return closest;
};

export const getSensorFromImage = (string: string) => {
  const partsOfString = string.split('_');
  const neededString = partsOfString[partsOfString.length - 1].split('.')[0];
  return neededString.toUpperCase();
};

export const getDateType = (date: string | TInfoExt): SourceType | '' => {
  if (typeof date === 'object') {
    return date?.Type || '';
  }
  return ((date || '').split('-')?.[1] as SourceType) || '';
};

export const getLocationPath = () => {
  const getPathString = window.location.pathname.split('/');
  getPathString.pop();
  getPathString.pop();
  getPathString.shift();
  getPathString.shift();
  return getPathString.join('/');
};

/**
 * Takes the last 3 sections of url.
 *
 * Example:
 * https://storage.googleapis.com/flurosat-au/processed-v1/md5/date/date_TERRAV_PLN_ndvi.png
 * ->
 * md5/date/date_TERRAV_PLN_ndvi.png
 */
export const getImagePath = (url = '') =>
  url
    .split('/')
    .slice(-3)
    .join('/');

export function escapeRegExp(string = '') {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/*
 * Convert to current system units system
 * @type string (HA - metrics, AC - U. S. Units)
 *
 * */
// https://github.com/ben-ng/convert-units

const _units: {[ket: string]: string} = {
  kg: 'lb',
  mm: 'in',
  C: 'F',
  'km/h': 'mph',
  l: 'gal',
  ml: 'fl-oz',
  g: 'oz',
  m: 'ft',
  ha: 'ac',
  AUD: 'USD',
  'kg / ha': 'lb / ac',
  'kg/ha': 'lb/ac',
  'l/ha': 'gal/ac',
  'l / ha': 'gal / ac',
  'BU / HA': 'BU / AC',
  'plants/m²': 'plants/ac',
};

/**
 * Converts `value` from metric to imperial system.
 * @param type 'ha' | 'ac', where 'ha' means metric and 'ac' means imperial
 * @param unit what's being measured – distance, weight, temperature, etc – takes the metric unit ('ha', 'kg', 'C')
 * @param value
 * @param reverse if true converts imperial to metric
 * @returns
 */
export function convertUnit(type = 'ha', unit = 'none', value = 0, reverse = false) {
  // Assuming that the `value` is already in metric and target system is metric, we just return the value.
  if (type === 'ha') {
    if (unit === 'ac') {
      return toFixedFloat(value, 1);
    }
    return value;
  }

  // Convert metric to imperial units.
  switch (unit) {
    case 'kg': {
      return convert(value)
        .from('kg')
        .to('lb'); // lb (pound)
    }

    case 'mm': {
      if (reverse) {
        return convert(value)
          .from('in')
          .to('mm');
      }
      return toFixedFloat(
        convert(value)
          .from('mm')
          .to('in'),
        1
      ); // in (inches)
    }

    case 'C': {
      return toFixedFloat(
        convert(value)
          .from('C')
          .to('F'),
        2
      ); // F (Fahrenheit)
    }

    case 'km/h': {
      return convert(value)
        .from('km/h')
        .to('m/h'); // mph (miles per hour)
    }

    case 'l': {
      return convert(value)
        .from('l')
        .to('gal'); // gal (gallon)
    }

    case 'ml': {
      return convert(value)
        .from('ml')
        .to('fl-oz'); // fl oz (fluid ounce)
    }

    case 'g': {
      return convert(value)
        .from('g')
        .to('oz'); // oz (ounce)
    }

    case 'm': {
      return convert(value)
        .from('m')
        .to('ft'); // ft (feet)
    }

    case 'gdd': {
      return Math.round(value / (5 / 9));
    }

    case 'ac': {
      return toFixedFloat(
        convert(value)
          .from('ha')
          .to('ac'),
        1
      ); // acre
    }

    case 'ft2': {
      return toFixedFloat(
        convert(value)
          .from('m2')
          .to('ft2'),
        2
      );
    }

    case 'lb/ac': {
      if (reverse) return value * 1.12085;
      return value / 1.12085;
    }
    case 'gal/ac': {
      if (reverse) return value * 9.35396;
      return value / 9.35396;
    }

    case 'm2': {
      if (reverse) return value / 4046.86;
      return value * 4046.86;
    }

    default: {
      return value;
    }
  }
}

export const booleanIntersect = (feature1: any, feature2: any) => {
  return booleanOverlap(feature1, feature2) || booleanContains(feature1, feature2);
};

export function formatUnit(type = 'ha', unit = 'none') {
  if (type === 'ha') {
    return unit;
  }

  return _units[unit] ? _units[unit] : '';
}

export function convertTemperatureModel(
  units: any,
  temperatureData: IterisTemperatureDay[]
): IterisTemperatureDay[] {
  return temperatureData && temperatureData.length
    ? temperatureData.map(t => {
        return {
          ...t,
          AvgTemp: parseFloat(convertUnit(units, 'C', t.AvgTemp)),
          maxTemperature: parseFloat(convertUnit(units, 'C', t.maxTemperature)),
          minTemperature: parseFloat(convertUnit(units, 'C', t.minTemperature)),
          rainFall: parseFloat(convertUnit(units, 'mm', t.rainFall)),
          dayDegrees: parseFloat(convertUnit(units, 'gdd', t.dayDegrees)),
        };
      })
    : [];
}

export function getDataSource(data: TDateLayer) {
  if (!data) return false;
  return data.url.includes('TERRAV') ? `Terravion-${data.type}` : data.type;
}

// export const isDateInRange = (date: string, startDate: string, endDate: string) => {
//   const startDateM = moment(startDate, GLOBAL_FORMAT_DATE);
//   const endDateM = moment(endDate, GLOBAL_FORMAT_DATE);
//   const currentDateM = moment(date, GLOBAL_FORMAT_DATE);
//   const range = moment().range(startDateM, endDateM);
//   return (
//     range.contains(currentDateM) || startDateM.isSame(currentDateM) || endDateM.isSame(currentDateM)
//   );
// };

// export const toFixedFloat = (val, num) => val && (typeof val === "number") ? parseFloat((val || 0).toFixed(num)) : 0;

export const isTerravionType = (imageType: string) => imageType === 'plane';

export const isTerravionDate = (dateKay: string) => isTerravionType(getDateFromKey(dateKay).type);

export const isAustralianField = (field: Field) => field.Country === 'Australia';

export function getFieldIDFromURL(pathname = '') {
  const re = pathToRegexp('/app/maps/:groupID/:fieldID');
  const result = re.exec(pathname || window.location.pathname);

  if (result) {
    return {
      farmId: parseInt(result[1]),
      fieldId: result[2] === 'WholeFarm' ? result[2] : parseInt(result[2]),
    };
  }

  return {farmId: 0, fieldId: 0};
}

/**
 * If the values is less than the minimum, returns the minimum.
 * If the values is more than the maximum, returns the maximum.
 * Otherwise just returns the value.
 */
export function clamp(min: number, val: number, max: number) {
  return Math.max(min, Math.min(val, max));
}

export const booleanIntersectField = (geometry: GeoJSON.Feature<GeoJSON.GeometryCollection>) => {
  const store: any = hackGetState();
  const {currentFieldKml} = store.map;
  const fieldGeometry: GeoJSON.Feature<any> = currentFieldKml;
  const isFieldGeometryCollection = checkForGeometryCollection(fieldGeometry);

  if (isFieldGeometryCollection === undefined) {
    return false; // catch when there is no field for farm
  }

  if (!isFieldGeometryCollection) {
    return intersect(fieldGeometry, geometry);
  }

  return fieldGeometry.geometry.geometries.some((fieldG: any) => {
    return intersect(fieldG, geometry);
  });
};

const intersect = (a: any, b: any) => {
  return checkForGeometryCollection(b)
    ? b.geometry.geometries.every((g: any) => booleanGeometriesDeepIntersect(a, g))
    : booleanGeometriesDeepIntersect(a, b);
};

const booleanGeometriesDeepIntersect = (geometry1: any, geometry2: any) => {
  return booleanOverlap(geometry1, geometry2) || booleanContains(geometry1, geometry2);
};

export function prepareFeaturesForExport(
  features: Array<GeoJSON.Feature | GeoJSON.FeatureCollection | GeoJSON.GeometryCollection>
) {
  return features
    .map(gj => {
      if (gj && gj.type === 'FeatureCollection') {
        return {
          type: 'FeatureCollection',
          features:
            gj.features && gj.features.length
              ? gj.features.map((f: any) => clearFeatureBeforeExport(f, 'Stress Detected'))
              : [],
        };
      }

      if (gj && gj.type === 'Feature') {
        return clearFeatureBeforeExport(
          gj,
          gj.properties.isLowPerf ? 'Low performing area' : 'ROI'
        );
      }

      return null;
    })
    .filter(gj => gj);
}

const clearFeatureBeforeExport = (feature: any, info: any) => {
  const store = hackGetState();
  const {
    map: {group, field, currentDate},
    global: {growerName},
    login: {user},
  } = store;
  const fieldName = field.Name;
  const measurement = user.settings.measurement;
  const farmName = group.name;

  // use turfFeature for cleaning props from trash params
  let tf = turfFeature({
    type: feature?.geometry?.type || 'Polygon',
    geometries: feature?.geometry?.type === 'GeometryCollection' ? feature.geometry.geometries : [],
    coordinates: feature?.geometry?.coordinates ? feature.geometry.coordinates : [],
  });

  const lbl = ANOMALY_LABELS().find(a => a.value === feature.properties.label);
  tf.properties = {
    farmName,
    fieldName,
    label: lbl ? lbl.label : 'No Label',
    area:
      convertUnit(
        measurement,
        'ac',
        convert(turfArea(tf))
          .from('m2')
          .to('ha')
      ) + formatUnit(measurement, 'ha'),
    info,
    endDate: feature.properties.endDate || moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    startDate:
      feature.properties.startDate || moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    growerName: growerName || '-',
  };

  return tf;
};

// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
export function downloadFile(data: any, filename: string, mime?: string, bom?: any) {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}

export function truncStr(str: string, limit = 100, ending = '...') {
  return str.length >= limit ? str.substring(0, limit - ending.length) + ending : str;
}

export function geoJsonObjectToPolygon(geoJson: Array<any>, toPolygons?: any) {
  return geoJson.map((el: any) => {
    if (el.geometry.type === 'LineString') return lineToPolygon(el);

    if (toPolygons) {
      return el.geometry;
    }
    return el;
  });
}

export const getGeometryMeanIndex = (url: string, shape?: any, isWholeFarm?: boolean) => {
  const coordinates: any = {};
  const id = shape.properties.id !== undefined ? shape.properties.id : shape.properties.fluro_id;
  if (shape.geometry.type === 'GeometryCollection') {
    shape.geometry.geometries.map((g: any, i: number) => {
      switch (g.type) {
        case 'Polygon':
          coordinates[`${id}_${i}`] = g.coordinates[0];
          break;
        case 'FeatureCollection':
          // TODO (stas): support feature collections, we have it on the field app/maps/39/921
          break;
        default:
          console.error('getGeometryMeanIndex error: Unsupported geometry type');
      }
    });
    return ActivityApi.getMeanIndex(url, coordinates, isWholeFarm).then(({data: {Means}}) => {
      const arrAvg = (arr: number[]): number =>
        Math.round(arr.reduce((a, b) => a + b, 0) / arr.length);
      return new Promise(resolve => resolve({data: {Means: {[id]: arrAvg(Object.values(Means))}}}));
    });
  } else {
    coordinates[id] = shape.geometry.coordinates[0];
    return ActivityApi.getMeanIndex(url, coordinates, isWholeFarm);
  }
};

export function getDataFromRedirectUrl() {
  const urlQueryObj = new URLSearchParams(window.location.search);
  const redirect = urlQueryObj.get('redirect') || localStorage.getItem('redirectUrl');
  return getFieldIDFromURL(redirect);
}

export const sortAnomalies = (anomalies: IAnomaly[], type?: string, order?: boolean) => {
  // toDO move to anomalies utils
  const sortOrder = ['high', 'med', 'low', 'new'];
  let sortedItems = [...anomalies];
  if (type === 'priority') {
    sortedItems = sortedItems.sort(
      (a, b) => sortOrder.indexOf(a.properties.priority) - sortOrder.indexOf(b.properties.priority)
    );
  }
  if (type === 'label') {
    sortedItems = sortedItems.sort((a, b) => {
      return a.properties.label.localeCompare(b.properties.label);
    });
  }
  if (type === 'modality') {
    sortedItems = sortedItems.sort((a, b) => {
      return `${a.properties.modality}`.localeCompare(`${b.properties.modality}`);
    });
  }
  if (type === 'date') {
    sortedItems = sortedItems.sort((a, b) => {
      // @ts-ignore 'start date' is not in the model, but it was used before premium anomalies
      const dateA = a['start date'] || a.properties.sensing_date;
      // @ts-ignore 'start date' is not in the model, but it was used before premium anomalies
      const dateB = b['start date'] || b.properties.sensing_date;
      if (moment(dateA).isBefore(moment(dateB))) return -1;
      if (moment(dateA).isAfter(moment(dateB))) return 1;
      return 0;
    });
  }
  if (type === 'size') {
    sortedItems = sortByKey(sortedItems, 'properties.area');
  }
  if (type === 'ndvi') {
    sortedItems = sortedItems.sort((a, b) => {
      const meanA = a.properties.mean || a.properties.anomaly_ndvi;
      const meanB = b.properties.mean || b.properties.anomaly_ndvi;
      return meanA - meanB;
    });
  }

  sortedItems = order ? sortedItems : sortedItems.reverse();

  if (sortedItems.some(el => el.properties.snoozed !== undefined)) {
    return [
      ...sortedItems.filter(el => !el.properties.snoozed),
      ...sortedItems.filter(el => el.properties.snoozed),
    ];
  }

  return sortedItems;
};

export const searchFeaturesToObject = (search: string) => {
  const searchObj = new URLSearchParams(search);
  const featuresString = searchObj.get('f');

  if (featuresString) {
    let result: any = {};
    featuresString.split(',').forEach(value => {
      const param = value.split(':');

      if (param.length && param[0]) {
        result[param[0]] = param[1] === 'true';
      }
    });

    return result;
  }

  return {};
};

export const capitalizeFirstLetter = (string: string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const detectImageProcessing = (map: IInitialMapState) => {
  const {
    field,
    wholeFarm: {isWholeFarmView},
    fields,
    currentSeason,
  } = map;
  const {startDate, infoExt} = currentSeason;

  let isImagesProcessing;
  let isFuture = false;
  if (isWholeFarmView) {
    isImagesProcessing = fields.find(
      (f: Field) => f.Seasons?.length && f.Seasons.every((s: Season) => !s.infoExt)
    );
  } else if (field.Seasons?.length) {
    isImagesProcessing = startDate ? !infoExt?.length : false;
    isFuture = !moment(startDate).isBefore(moment());
  }

  return {
    isImagesProcessing,
    isFuture,
  };
};

export const getImageStatus = (map: IInitialMapState) => {
  const {isImagesProcessing, isFuture} = detectImageProcessing(map);
  const {
    wholeFarm: {isWholeFarmView, wholeFarmDates},
    infoExt,
  } = map;

  const isNotProcessing =
    !isImagesProcessing ||
    (isWholeFarmView && (!isImagesProcessing || !!Object.keys(wholeFarmDates).length));

  const isNotImagery = isWholeFarmView
    ? !Object.keys(wholeFarmDates || {}).length
    : !infoExt.length;

  return {
    isNotProcessing,
    isNotImagery,
    isFuture,
  };
};

export const isCloudy = (map: IInitialMapState): boolean => {
  const {infoExt, currentDates} = map;
  return infoExt.length && !Object.keys(currentDates).length;
};

export const normalizeFirstLastPointOfGeometry = (
  feature: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon | GeoJSON.GeometryCollection>
) => {
  // the first and last positions in coordinates must be the same

  //TODO: add support of GeoJSON.MultiPolygon and GeoJSON.GeometryCollection
  if (feature.geometry.type !== GeoJsonType.Polygon) {
    return;
  }

  if (!Array.isArray(feature.geometry.coordinates) || !feature.geometry.coordinates.length) return;

  const firstCord = feature.geometry.coordinates[0][0];
  const lastCord = feature.geometry.coordinates[0][feature.geometry.coordinates[0].length - 1];

  if (Array.isArray(firstCord) && Array.isArray(lastCord)) {
    if (firstCord[0] !== lastCord[0] || firstCord[1] !== lastCord[1]) {
      feature.geometry.coordinates[0].push([...firstCord]);
    }
  }
};

/**
 * Prepares sensor to be shown to users.
 */
export const sensorView = (sensor: string) => {
  switch (sensor) {
    case 'TCI':
      return 'RGB';
    case 'PTIRS':
      return 'TIRS-P';
    case 'NC':
      return 'RGB-S';
    default:
      return sensor;
  }
};

/**
 * Prepares sensor to be stored and transferred.
 */
export const sensorModel = (sensor: TSensor) => {
  // return sensor === 'RGB' ? 'TCI' : sensor;
  switch (sensor) {
    case 'RGB':
      return 'TCI';
    case 'TIRS-P':
      return 'PTIRS';
    case 'RGB-S':
      return 'NC';
    default:
      return sensor;
  }
};

/*
 * Type helper. check prop exist in object
 * */
export function checkExistProp<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

// It formats from 10000000.00 to 100,000,000.00
export const formatBigNumber = (n: number) => {
  try {
    return n.toLocaleString();
  } catch (e) {
    return n;
  }
};

export const getCsvFromArray = <C extends any[]>(headerArr: string[], contentArr: C) => {
  const csvHeader = `${headerArr.join(',')}\n`;
  const csvContent = contentArr
    .map(row => JSON.stringify(Object.values(row)))
    .join('\n')
    .replace(/(^\[)|(\]$)/gm, '');

  return csvHeader + csvContent;
};

/*
 * EXAMPLE:
 *
 * Input: data =  [
 *   {key: 1, foo: 2},
 *   {key: 1, foo: 3},
 *   {key: 10, foo: 2}
 * ]
 *
 * Output: data = [
 *   {key: 1, foo: 2},
 *   {key: 10, foo: 2}
 * ]
 *
 * Execute:
 *
 * getUniqArrayOfObjectsByProp(data, 'key');
 *
 * */
export const getUniqArrayOfObjectsByProp = (data: any[], key: string) => {
  // data deduplication, (not sure about time complexity of this filter with findIndex inside)
  return data.filter(
    (item: any, i: number, _data: any[]) => _data.findIndex(f => f[key] === item[key]) === i
  );
};

export const getGlobalDialog = (dialogName: Dialogs) => {
  const store: any = hackGetState();
  return store.global.dialogsState[dialogName];
};

/*
 *
 * Return current host with protocol and /app path
 *
 * */
export const getCurrentAppHost = (): string =>
  window.location.protocol + '//' + window.location.host + '/app';

/*
 *
 * Filter any needed sources from seasons
 *
 * */
export const filterSourcesFromSeasons = (seasons: Season[], sources: string[]) => {
  return seasons.map((s: Season) => {
    return {...s, infoExt: s.infoExt.filter(date => sources.includes(date.Type))};
  });
};

export const getFilterSourcesFromUrl = (sourcesMeta: SourceMeta[]) => {
  const paramSources = (getGetURLParam('filterSources') || '')
    .split(',')
    .map((el: any) => el.replace(/^drone$/, 'dron'))
    .filter(el => SOURCES.includes(el));

  const isFilteredAll = sourcesMeta
    .filter(s => s.available)
    .every(s => paramSources.includes(s.source));

  if (!isFilteredAll) {
    setGetParamToURL('filterSources', paramSources.length ? paramSources.join(',') : null);
    return paramSources;
  }

  const newParams = sourcesMeta.filter(s => s.available).map(s => s.source);

  // remove one filtered item  cuz we do not allow filter all sources
  newParams.shift();

  // set updated params
  setGetParamToURL('filterSources', newParams.length ? newParams.join(',') : null);

  return newParams;
};

export const validateBounds = (bounds: L.LatLngBounds) => {
  let valid = false;
  const validCoordinate = (val: number) => typeof val === 'number' && isFinite(val);
  if (bounds) {
    try {
      valid =
        bounds.isValid() &&
        validCoordinate(bounds.getEast()) &&
        validCoordinate(bounds.getNorth()) &&
        validCoordinate(bounds.getSouth()) &&
        validCoordinate(bounds.getWest());
    } catch (err) {
      valid = false;
    }
  }
  return valid ? bounds : undefined;
};

export const isSameBounds = (bounds1: L.LatLngBounds, bounds2: L.LatLngBounds) => {
  if ((!bounds1 && bounds2) || (bounds1 && !bounds2)) return false;
  if (
    bounds1.getEast() !== bounds2.getEast() ||
    bounds1.getWest() !== bounds2.getWest() ||
    bounds1.getNorth() !== bounds2.getNorth() ||
    bounds1.getSouth() !== bounds2.getSouth()
  ) {
    return false;
  }

  return true;
};

/*
 *
 * return array like [{source: 'drone': available: true}, ...]
 * if the satellite_hd source is present, filter one of the not available source type
 * to keep 4 source types filter items (FSB-1901, Anastasia's comment)
 * * */
export const getSourcesMeta = (images: TInfoExt[]): SourceMeta[] => {
  let resultSources = SOURCES.map(source => ({
    source,
    available: images.some(i => i.Type === source),
  }));

  if (resultSources.find(s => s.source === 'satellite_hd' && s.available)) {
    if (resultSources.find(s => s.source === 'plane' && !s.available))
      return resultSources.filter(s => s.source !== 'plane');
    else if (resultSources.find(s => s.source === 'dron' && !s.available))
      return resultSources.filter(s => s.source !== 'dron');
    else if (resultSources.find(s => s.source === 'machinery' && !s.available))
      return resultSources.filter(s => s.source !== 'machinery');
    else if (
      // if all the sources are present, don't filter them, show all
      ['machinery', 'dron', 'plane'].every(sourceLabel =>
        resultSources.find(st => st.source === sourceLabel && st.available)
      )
    )
      return resultSources;
  }

  return resultSources.filter(s => s.source !== 'satellite_hd');
};

export const sortObjectDates = (dates: any) => {
  let result: any = {};
  const keys = sortDates(Object.keys(dates || {})).reverse();

  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = dates[keys[i]];
  }

  return result;
};

/*
 *  check current host is local
 * */
export function isLocalhost() {
  return window.location.host.startsWith('localhost');
}

export const getFarmById = (id: number): Farm | null => {
  return hackGetState().farms.list.find(f => f.id === id) || null;
};

export const hasTrendsData = (analytics: any) => {
  let hasData = false;
  Object.keys(analytics.trendsData || {}).forEach(
    key => (hasData = hasData || (key !== 'NDVI' && analytics.trendsData[key]?.categories?.length)) // !== NDVI because smoothed data is not count
  );
  return hasData;
};

/*
 *
 * Convert current sensor to avgSensor
 * Example: NDVI => avgNdvi
 *
 * */
export function getAvgProp(sensor: TSensor) {
  let _sensor = sensor + '';
  return `avg${capitalizeFirstLetter(_sensor.toLowerCase())}`;
}

// @ts-ignore
export const runIntercom = () => document.querySelector('#intercom-launch').click();

export const getPlantingAreaImageURL = (
  baseUrl: string,
  sensor: TSensor,
  md5: string,
  sensingDate: string,
  geometryId: string
) =>
  `${baseUrl}services/data-service/indices/${sensor.toLowerCase()}/${md5}/${sensingDate}?geometryId=${geometryId}`;

export const isProd = () => config.env === 'production';

export const fitBoundCurrentEditFieldKml = (withPanelPadding = false) => {
  //@ts-ignore
  if (!window.leafletElement) {
    return;
  }
  try {
    //@ts-ignore
    window.leafletElement.eachLayer(layer => {
      //@ts-ignore
      if (layer.__fluroLayerToEdit) {
        //@ts-ignore
        window.leafletElement.fitBounds(
          layer.getBounds(),
          withPanelPadding
            ? {
                paddingTopLeft: [32, 32],
                paddingBottomRight: [432, 32],
              }
            : {padding: [50, 50]}
        );
      }
    });
  } catch (e) {
    reportError('Cannot fit bounds current edit field kml');
  }
};
