import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {getUsersList} from 'containers/admin/users/actions';
import {FluroAutocomplete, FluroDialog, InfoBlock} from 'components';
import {t} from 'i18n-utils';
import {Button, Chip, SelectField} from 'react-md';
import {connectEfcUsers, disconnectEfcUsers} from './actions';
import {EFCApi} from '_api';
import {InputLabel} from 'components/reusable-conponents.styled';
import {removeDuplicates} from '_utils';
import {showNote} from '_actions';

type ConnectAUserProps = {
  visible: boolean;
  onPopupHide(): void;
  toggleCurrentUserConnection(value: boolean): void;
};

type SimpleUser = {
  label: string | ReactElement;
  id: number;
};
const ManageEFCUsersConnection = ({
  visible,
  onPopupHide,
  toggleCurrentUserConnection,
}: ConnectAUserProps) => {
  const dispatch = useDispatch();
  const usersList = useSelector((store: AppStore) => store.users.list);
  const currentUserId = useSelector((store: AppStore) => store.login.user.id);
  const [selectedPartner, setSelectedPartner] = useState<string>();
  const [partners, setPartners] = useState<{label: string; value: string}[]>([]);
  const [usersToDisconnect, setUsersToDisconnect] = useState<number[]>([]);
  const [usersToConnect, setUsersToConnect] = useState<SimpleUser[]>([]);
  const [connectedUsers, setConnectedUsers] = useState<SimpleUser[]>([]);
  const [selectedRawUsersData, setSelectedRawUsersData] = useState<
    {fs_user_id: number; label: string | ReactElement}[]
  >([]);

  useEffect(() => {
    if (visible) {
      !usersList.length && dispatch(getUsersList());

      !partners.length &&
        EFCApi.getPartners().then(({data}) => {
          setPartners(
            data.map(({partner_name}: {partner_name: string}) => ({
              label: partner_name,
              value: partner_name,
            }))
          );
        });
    } else {
      setUsersToConnect([]); // reset
    }
  }, [visible]);

  useEffect(() => {
    // get actual users data
    if (selectedPartner && visible) {
      EFCApi.getConnectedUsers(selectedPartner).then(({data}) => {
        setSelectedRawUsersData(data);
      });
    }
  }, [selectedPartner, visible]);

  const preparedUsersList = useMemo(() => {
    return usersList.map(user => ({
      label: (
        <>
          {user.name} {user.surname} <span className={'id-string'}>#{user.id}</span>
        </>
      ),
      value: user.id,
      search: `${user.name} ${user.surname} ${user.id} ${user.email}`.toLowerCase(),
    }));
  }, [usersList]);

  const filteredUsersList = useMemo(() => {
    const usersToConnectIds = usersToConnect.map(u => u.id);
    const connectedUsersIds = connectedUsers.map(u => u.id);
    const listAllUsersToFilter = [...connectedUsersIds, ...usersToDisconnect, ...usersToConnectIds];
    return preparedUsersList.filter(u => !listAllUsersToFilter.includes(u.value as number));
  }, [preparedUsersList, usersToDisconnect, usersToConnect, connectedUsers]);

  useEffect(() => {
    if (!selectedPartner && partners.length) {
      setSelectedPartner(partners[0].value);
    }
  }, [partners]);

  const onFilter = (value: string) => {
    const preparedSearchString = value?.toLowerCase();
    return filteredUsersList.filter(user => user.search.includes(preparedSearchString));
  };

  const onSelectUser = (userId: number) => {
    const userLabel = preparedUsersList.find(user => user.value === userId).label;
    setUsersToConnect([...usersToConnect, {id: userId, label: userLabel}]);
    setConnectedUsers([...connectedUsers, {id: userId, label: userLabel}]);
  };

  useEffect(() => {
    if (!selectedRawUsersData.length || !usersList.length) return;
    setUsersToDisconnect([]);

    setConnectedUsers(
      removeDuplicates(selectedRawUsersData, 'fs_user_id')
        .map(({fs_user_id}: any) => {
          const foundUser = usersList.find(user => user.id === fs_user_id);
          if (!foundUser) return null;
          return {
            id: parseInt(`${foundUser.id}`), // convert the type to a number
            label: (
              <>
                {foundUser.name} {foundUser.surname}{' '}
                <span className={'id-string'}>#{foundUser.id}</span>
              </>
            ),
          };
        })
        .filter(Boolean)
    );
  }, [usersList, selectedRawUsersData, visible]);

  const onRemoveUser = (userId: number) => {
    setConnectedUsers(connectedUsers.filter(user => user.id !== userId));
    setUsersToDisconnect([...usersToDisconnect, userId]);
  };

  const onSaveUsersState = async () => {
    let requestsPassed = true;

    let newSelectedUsersRawList = selectedRawUsersData;

    if (usersToDisconnect.length) {
      requestsPassed = requestsPassed
        ? !!(await dispatch(disconnectEfcUsers(usersToDisconnect)))
        : false;

      // update the raw list
      newSelectedUsersRawList = newSelectedUsersRawList.filter(
        u => !usersToDisconnect.includes(u.fs_user_id)
      );

      if (usersToDisconnect.includes(currentUserId as number)) {
        toggleCurrentUserConnection(false); // unauthorize current user without reloading
      }
    }

    if (usersToConnect.length) {
      const usersToConnectIds = usersToConnect.map(u => u.id);
      requestsPassed = requestsPassed
        ? !!(await dispatch(connectEfcUsers(usersToConnectIds, selectedPartner)))
        : false;

      // update the raw list
      newSelectedUsersRawList = [
        ...newSelectedUsersRawList,
        ...usersToConnect.map(u => ({fs_user_id: u.id, label: u.label})),
      ];

      if (usersToConnectIds.includes(currentUserId as number)) {
        // load growers without page reloading
        toggleCurrentUserConnection(true);
      }
    }

    if (requestsPassed) {
      dispatch(
        showNote({
          title: t({id: 'note.success'}),
          message: t({id: "The user's connection was successfully updated."}),
          level: 'success',
        })
      );
    }

    setSelectedRawUsersData(newSelectedUsersRawList);

    // onPopupHide();
  };

  return (
    <FluroDialog
      id={'manage-efc-users-pop-up'}
      title={t({id: 'Manage connections'})}
      dialogClassName={'manage-efc-users-pop-up'}
      visible={visible}
      onHide={onPopupHide}
      portal
    >
      <SelectField
        id="select-a-partner"
        label={t({id: 'Partner'})}
        menuItems={partners}
        className="element-full-width"
        value={selectedPartner}
        disabled={partners.length === 1}
        onChange={(value: string) => setSelectedPartner(value)}
        simplifiedMenu={true}
      />

      <FluroAutocomplete
        id="select-a-user-to-connect"
        placeholder={t({id: 'Select a user'})}
        label={t({id: 'Connect a user'})}
        menuItems={filteredUsersList}
        value={''}
        onFilter={onFilter}
        onAutocomplete={onSelectUser}
      />

      {connectedUsers.length ? (
        <>
          <InputLabel className={'fake-input-label'}>{t({id: 'Users connected:'})}</InputLabel>
          {connectedUsers?.map(user => (
            <Chip
              key={`user-${user.id}`}
              onClick={() => onRemoveUser(user.id)}
              removable
              label={user.label}
            />
          ))}
        </>
      ) : null}

      <InfoBlock appearance={'info'} className={'inside-a-pop-up'}>
        {t({id: 'efcConnectAUserPopUp.info-text'})}
      </InfoBlock>

      <div className="md-text-right">
        <Button
          className={'disconnect-a-user-btn'}
          raised
          primary
          disabled={!usersToDisconnect?.length && !usersToConnect.length}
          onClick={onSaveUsersState}
        >
          {t({id: 'Save'})}
        </Button>
      </div>
    </FluroDialog>
  );
};

export default ManageEFCUsersConnection;
