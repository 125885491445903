import {t, FormattedMessage} from 'i18n-utils';
import React, {Component} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import ReactDOM from 'react-dom';
import {FieldTools, LastStep, FluroStep} from './common-steps';
import Joyride, {ACTIONS, EVENTS, STATUS, CallBackProps} from 'react-joyride';
import {baseStepStyle} from './style';
import {setOnboardingSeen} from '../login/actions';
import {toggleGlobalDialog} from '_actions';
import {setFeature, toggleMapBar, prepareToOnBoarding} from '../map/actions';
import {IInitialMapState} from '../map/types';
import {LoginState} from '../login/types';
import {TFeature} from '../../types';

type Props = ConnectedProps<typeof connector>;

type State = {
  steps: FluroStep[];
  run: boolean | null;
  stepIndex: number;
};

class DemoFarmOnBoard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      steps: [
        {
          title: 'List of farms',
          content: (
            <span>
              Create and browse farms for each of your growers. Our analytics work best when{' '}
              <b>farms </b>
              have their fields grouped <b>close together geographically</b>.
            </span>
          ),
          target: '.onboarding__select-farm',
          placement: 'bottom',
          disableBeacon: true,
          name: 'farm-selector',
        },
        {
          title: 'List of fields',
          content: (
            <span>
              A field is a management unit of land where your crops grow. Multiple fields make up a
              Farm. Each field should have their <b>individual boundary and</b> an associated
              <b> crop type </b>
              growing within it.
            </span>
          ),
          target: '.onboarding__select-field',
          placement: 'bottom',
          disableBeacon: true,
          name: 'field-selector',
        },
        {
          title: 'Observation dates',
          content:
            'We automatically acquire new satellite imagery for your farms every 3-5 days. Here you can also see your drone or airplane imagery!',
          target: '.onboarding__selector-dates',
          placement: 'bottom',
          disableBeacon: true,
          name: 'date-selector',
        },
        {
          title: 'Crop health layers',
          content: `Each vegetation index gives you a different insight into the health of your field representing parameters such as chlorophyll, water stress and crop vigour.`,
          target: '.onboarding__select-layers',
          placement: 'bottom',
          disableBeacon: true,
          name: 'layers-selector',
        },
        {
          title: 'Map navigation tools',
          content: (
            <div>
              Use map navigation to:
              <ul>
                <li>search for your address, or farm location,</li>
                <li>locate yourself in the field when scouting,</li>
                <li>measure distances in the field.</li>
              </ul>
            </div>
          ),
          target: '.onboarding__map-tools',
          placement: 'right',
          disableBeacon: true,
          name: 'map-tools',
        },
        FieldTools, // Field tools
        {
          title: 'Legend switch',
          content: (
            <div>
              Switch the type of legend to visualise crop health:
              <ul>
                <li>
                  <b>Whole Season:</b> a standardised legend to compare one image against another in
                  the season.
                </li>
                <li>
                  <b>Single Date:</b> a legend to highlight the variance within a particular image.
                </li>
              </ul>
            </div>
          ),
          target: '.onboarding-color-schema',
          placement: 'auto',
          disableBeacon: true,
          name: 'color-schema',
        },
        {
          title: 'Main menu with Apps',
          content: (
            <div>
              <div className={'step-paragraph'}>
                Use the main menu to navigate between the different sections of the platform.
              </div>
              Set up your fields and crops, perform analysis on individual layers, manage your
              profile and access any of the Apps that FluroSense offers including:
              <ul>
                <li>
                  <b>Crop Performance</b>
                </li>
                <li>
                  <b>Crop Stress</b>
                </li>
                <li>
                  <b>Crop Nutrition</b> and more.
                </li>
              </ul>
            </div>
          ),
          target: '.onboarding__tabs-nav',
          placement: 'left',
          disableBeacon: true,
          name: 'tabs-nav',
        },

        {
          title: 'Expanded view',
          content: (
            <div>
              <div className={'step-paragraph'}>
                Certain sections within FluroSense have an <b>expanded view</b> offering more
                information.
              </div>
              While navigating through the site, if you see this icon, click it to expand the
              feature into <b>full screen mode</b> and access more functionality and a greater
              insight into the section you are looking at.
            </div>
          ),
          target: '.onboarding__toggle-expanded-view',
          placement: 'left',
          disableBeacon: true,
          name: 'expanded-view',
        },
        {
          title: 'Farm set-up',
          content: (
            <div>
              <div className={'step-paragraph'}>
                <b>Create your farms and fields</b> by drawing your boundaries using our mapping
                tools or by uploading a .kml, .kmz or .shp file.
              </div>
              Once your fields are set up,<b> assign crops and planting dates</b> to gain access to
              the imagery, kick off the analytics and unlock the power of FluroSense.
            </div>
          ),
          target: '#mini-menu-farm',
          placement: 'left',
          disableBeacon: true,
          name: 'farm-tab',
        },
        {
          title: 'Crop performance tab',
          content: (
            <div>
              <div className={'step-paragraph'}>
                The crop performance tab shows you the Crop Status and Biomass at a glance. Use it
                to compare all your fields of a particular crop type, or to track performance of a
                particular field/variety over time.
              </div>
              Expand this tab to also see Growing Degree Days, Seasonal Rainfall, and Growth Stages.
            </div>
          ),
          target: '#mini-menu-crop-performance',
          placement: 'left',
          disableBeacon: true,
          name: 'crop-performance-tab',
        },
        {
          title: 'Analytics tab',
          content:
            'Visualise the performance of your crops over time with detailed time series analysis by selecting any acre or management zone to analyse.',
          target: '#mini-menu-analytics',
          placement: 'left',
          disableBeacon: true,
          name: 'analytics-tab',
        },
        {
          title: 'Areas of interest tab',
          content:
            'Here you will find the areas of crop stress detected automatically and see how they develop over time.',
          target: '#mini-menu-crop',
          placement: 'left',
          disableBeacon: true,
          name: 'AOI-tab',
        },
        {
          title: 'Zone management tab',
          content:
            ' Generate management zones in just a few clicks. You can set up buffer, minimal area, and select the Zoning method to generate VR application files.',
          target: '#mini-menu-zoning',
          placement: 'left',
          disableBeacon: true,
          name: 'zoning-tab',
        },
        {
          title: 'Data layers tab',
          content:
            'Here you can see all your imagery, control the cloud cover filter, compare your layers, and upload your own drone or machinery data.',
          target: '#mini-menu-data-layers',
          placement: 'left',
          disableBeacon: true,
          name: 'data-layers-tab',
        },
        {
          title: 'Sampling points tab',
          content:
            'Get suggestions on where to collect samples in your field, and create Nitrogen maps using test results.',
          target: '#mini-menu-tsp',
          placement: 'left',
          disableBeacon: true,
          name: 'sample-points-tab',
        },
        {
          title: 'User profile tab',
          content:
            'Manage your email notifications and connect other platforms  to import data here.',
          target: '#mini-menu-profile',
          placement: 'left',
          disableBeacon: true,
          name: 'profile-tab',
        },
        {
          title: 'Knowledge Base',
          content: 'Learn more about FluroSense tools here.',
          target: '#mini-menu-faq',
          placement: 'left',
          disableBeacon: true,
          name: 'faq-btn',
        },
        {
          title: 'Log out',
          content: '',
          target: '#mini-menu-log-out',
          placement: 'left',
          disableBeacon: true,
          name: 'log-out-btn',
        },
        LastStep({
          closeOnboarding: () => this.props.setOnboardingSeen({isOnboarded: true, fullTour: false}),
        }),
      ],
      run: this.props.showOnBoarding,
      stepIndex: 0,
    };
  }

  componentDidUpdate() {
    if (this.props.showOnBoarding && !this.state.run && this.state.run !== null) {
      this.props.prepareToOnBoarding();
      this.setState({
        run: true,
        stepIndex: 0,
      });
    }
  }

  prepareTabToTour = (feature: TFeature, index: number) => {
    this.setState({run: null});
    this.props.setFeature(feature);
    setTimeout(
      () =>
        this.setState({
          run: true,
          stepIndex: index,
        }),
      600
    );
  };

  closeOnBoarding = (rememberMessage = false) => {
    this.setState({run: null});
    return this.props.setOnboardingSeen({isOnboarded: true, fullTour: false}, rememberMessage);
  };

  openDemoFarmsDialog = () => {
    this.props.toggleGlobalDialog('demoFarms', {visible: true});
  };

  callback = (data: CallBackProps) => {
    const {action, type, index, status} = data;
    const calculatedIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    const nextStepToShow = this.state.steps[calculatedIndex];

    if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
      this.closeOnBoarding(true);
    }

    if (STATUS.FINISHED === status) {
      this.closeOnBoarding().then(() => this.openDemoFarmsDialog());
    } else {
      if (
        action === ACTIONS.PREV &&
        nextStepToShow?.name === 'date-selector' &&
        this.props.feature !== 'farm'
      ) {
        // to be sure that the date selector is visible (it is hidden on the zoning tab as example)
        this.prepareTabToTour('farm', calculatedIndex);
      } else if (type === EVENTS.STEP_AFTER && nextStepToShow?.name === 'expanded-view') {
        this.props.toggleMapBar(true);
        this.prepareTabToTour('farm', calculatedIndex);
      }

      if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        this.setState({stepIndex: calculatedIndex});
      }
    }
  };

  render() {
    const {stepIndex, run, steps} = this.state;

    return ReactDOM.createPortal(
      this.props.showOnBoarding ? (
        <Joyride
          steps={steps}
          stepIndex={stepIndex}
          run={run}
          showSkipButton={true}
          continuous
          callback={this.callback}
          styles={baseStepStyle}
          disableOverlayClose
          locale={{back: 'Previous', last: 'See demo farms'}}
        />
      ) : null,
      document.querySelector('body')
    );
  }
}

const mapStateToProps = ({login, map}: {login: LoginState; map: IInitialMapState}) => ({
  userSettings: login.user.settings,
  showOnBoarding: login.user.settings.onboarding.fullTour,
  feature: map.feature,
  isMapBarOpen: map.isMapBarOpen,
});
const connector = connect(mapStateToProps, {
  setFeature,
  toggleMapBar,
  prepareToOnBoarding,
  setOnboardingSeen,
  toggleGlobalDialog,
});
export default connector(DemoFarmOnBoard);
