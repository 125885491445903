import {FormattedMessage, t} from 'i18n-utils';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from 'reducers';
import {biomassLabels, cropGrowthLabels} from 'containers/map/features/crop-performance/types';
import {
  clearAllFilters,
  toggleFilter,
} from '_reducers/crop-performance-filter/field-filter-reducer';
import {CPBadge} from 'components';
import {
  CPFilter,
  CPFilterType,
  stage0Filters,
  stage1Filters,
  stage2Filters,
  stage3Filters,
  stage4Filters,
} from '_reducers/crop-performance-filter/field-filter-types';
import './crop-performance-filters-description.scss';
import {NO_VARIETY} from '../../_constants';

// skip the next filters during checking for selected filter
const filtersToSkip: CPFilterType[] = [CPFilterType.BIOMASS_OVER_TIME];

export const CropPerformanceFiltersDescription = () => {
  const dispatch = useDispatch();
  const cropPerformanceFilter = useSelector((s: AppStore) => s.cropPerformanceFilter);
  const cropTypes = useSelector((s: AppStore) => s.global.cropTypes);
  const fieldsByFarmId = useSelector((s: AppStore) => s.map.fieldsByFarmId);
  const cropPerformance = useSelector((s: AppStore) => s.cropPerformance);
  const fieldNames = useMemo(() => {
    const fieldNames: {[seasonId: number]: string} = {};
    Object.keys(fieldsByFarmId)
      .filter(farmId => cropPerformance.farms[Number(farmId)]) // get all CP farms
      .flatMap(farmId => Object.values(fieldsByFarmId[Number(farmId)])) // get all farms fields
      .forEach(f => (fieldNames[f.ID] = f.Name)); // iterate over the fields
    return fieldNames;
  }, [fieldsByFarmId, cropPerformance.farms]);

  const getViewModel = (f: CPFilter) => {
    let label: string;
    let backgroundColor = f.color;
    let foregroundColor = 'white';
    switch (f.type) {
      case CPFilterType.FIELDS_WITH_PLANTING_AREAS:
        label = fieldNames[f.value];
        backgroundColor = f.color;
        foregroundColor = 'black';
        break;
      case CPFilterType.CROP_TYPE:
        label = f.value || t({id: 'No crop'});
        backgroundColor = f.color;
        foregroundColor = backgroundColor === '#ffffff' ? 'black' : 'white';
        break;
      case CPFilterType.CROP_VARIETY:
        label = f.label || f.value || NO_VARIETY;
        break;
      case CPFilterType.CROP_STATUS:
      case CPFilterType.TREE_STATUS:
      case CPFilterType.FIELDS_VARIABILITY:
      case CPFilterType.CROP_STRESS:
        label = f.value;
        break;
      case CPFilterType.CROP_GROWTH:
        label = cropGrowthLabels[f.value];
        break;
      case CPFilterType.BIOMASS:
        label = biomassLabels[f.value];
        break;

      case CPFilterType.BIOMASS_OVER_TIME:
        label = '';
        break;
      default:
        throw new Error('Filter description: filter type is not implemented');
    }
    return {label, backgroundColor, foregroundColor};
  };
  const filtersExist = Object.values(cropPerformanceFilter.filters).some(
    f => !filtersToSkip.includes(f?.[0]?.type) && f.length
  );
  if (!filtersExist) {
    return null;
  }
  return (
    <div className="crop-performance-filters-description">
      <button className={'clear-filter-btn'} onClick={() => dispatch(clearAllFilters())}>
        <img src="/assets/filter.svg" alt="filter" title="Clear filters" width="20" />
      </button>
      {[
        ...stage0Filters,
        ...stage1Filters,
        ...stage2Filters,
        ...stage3Filters,
        ...stage4Filters,
      ].map(filterType => {
        const f = cropPerformanceFilter.filters[filterType];
        if (!f.length) {
          return null;
        }
        return f.map(f => {
          const {label, backgroundColor, foregroundColor} = getViewModel(f);
          if (!label) return null;
          return (
            <div key={label} onClick={() => dispatch(toggleFilter(f))}>
              <CPBadge actionable backgroundColor={backgroundColor} color={foregroundColor}>
                <FormattedMessage id={label} defaultMessage={label} />
              </CPBadge>
            </div>
          );
        });
      })}
    </div>
  );
};
