import {t} from 'i18n-utils';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Compare from './compare';
import {drawRemoteSensingDateIcon as drawDateIcon} from '../../utils';
import {FontIcon, SelectionControl, Slider} from 'react-md';
import {
  Ln,
  MultiKeysPressed,
  ReadOnly,
  FluroTableColumn,
  FluroTableHeader,
  FluroTableRow,
  FluroDataTable,
  FluroTableBody,
} from 'components';
import {
  getImageStatus,
  convertTileDateToNormal,
  removeDuplicates,
  isAdminPerm,
  isProd,
  getGetURLParam,
} from '_utils';
import {DownloadIcon, HistogramIcon} from '../../icons';
import {
  hideDateImage,
  imageSensingSetCurrent,
  imageSensingToggleDialog,
  onChangeLayerOption,
  remoteSensingOnChangeLayer,
  remoteSensingSetCloudCoverFilter,
  setDate,
  setDatesFilterByType,
  toggleCompare,
} from '../../actions';

import UploadLayers from './upload-layers/upload-layers';

import {SourceType, TInfoExt} from '../../types';
import cn from 'classnames';

import {TypeIcon} from 'components/icons';

import RemoteSensingImageDialog from './remote-sensing-image-dialog';

import {dialogToggle, DialogType} from 'modules/ui-helpers';

import {showNote} from '_actions';
import {countFieldsWithTreeDataByDay} from '../../utils/trees';
import {AppStore} from 'reducers';

const RemoteSensingList = () => {
  const dispatch = useDispatch();

  const currentRemoteSensingImage = useSelector((s: AppStore) => s.map.currentRemoteSensingImage);
  const isRemoteSensingDialog = useSelector((s: AppStore) => s.map.isRemoteSensingDialog);
  const currentIsSetSpectra = useSelector((s: AppStore) => s.map.currentIsSetSpectra);
  const remoteSensingLayersSelected = useSelector(
    (s: AppStore) => s.map.remoteSensingLayersSelected
  );
  const currentDates = useSelector((s: AppStore) => s.map.currentDates);
  const currentDate = useSelector((s: AppStore) => s.map.currentDate);
  const isCompareOn = useSelector((s: AppStore) => s.map.isCompareOn);
  const wholeFarm = useSelector((s: AppStore) => s.map.wholeFarm);
  const remoteSensingLayersOptions = useSelector((s: AppStore) => s.map.remoteSensingLayersOptions);
  const remoteSensingCloudCover = useSelector((s: AppStore) => s.map.remoteSensingCloudCover);
  const remoteSensingFilterProps = useSelector((s: AppStore) => s.map.remoteSensingFilterProps);
  const isReadOnly = useSelector((s: AppStore) => s.map.group.readOnly);
  const isOnBoardingNow = useSelector((s: AppStore) => s.login.user.settings.onboarding.fullTour);
  const imageStatus = useSelector((s: AppStore) => getImageStatus(s.map));
  const sourcesMeta = useSelector((s: AppStore) => s.map.sourcesMeta);
  const isAdmin = useSelector((s: AppStore) => isAdminPerm(s.login.user.perm));

  const rsi = useSelector((s: AppStore) => s.map.remoteSensingImages);
  const remoteSensingImages = useMemo(() => removeDuplicates(rsi, 'Date'), [rsi]);

  // const [isShowAll, setIsShowAll] = useState(false);

  const openHistogramDialog = () => {
    dispatch(dialogToggle('histogram', true));
  };

  const openTile = (im: TInfoExt) => {
    dispatch(imageSensingSetCurrent(im));
    dispatch(imageSensingToggleDialog(im));
  };

  const onHideDialog = () => {
    dispatch(imageSensingToggleDialog(false));
  };

  const onChangeLayer = (layer: string, value: boolean) => {
    dispatch(remoteSensingOnChangeLayer(layer, value));
  };
  const onChangeFileFormat = (layer: string, value: boolean) => {
    dispatch(onChangeLayerOption(layer, value));
  };

  const getNumberOfSelectedLayers = () => {
    return Object.keys(remoteSensingLayersSelected).filter(
      key => remoteSensingLayersSelected[key].checked
    ).length;
  };

  const downloadGeotiff = () => {
    if (currentRemoteSensingImage[currentIsSetSpectra[0]]) {
      let url = currentRemoteSensingImage[currentIsSetSpectra[0]].url;

      url = url.replace('https://storage.googleapis.com/flurosat-au/processed-v1', '/load');

      window.open(url + '?layers=' + buildGetParam());
    }
  };

  const buildGetParam = () => {
    let layers: string[] = [];
    Object.keys(remoteSensingLayersSelected).forEach(layer => {
      if (remoteSensingLayersSelected[layer] && remoteSensingLayersSelected[layer].checked) {
        layers.push(`${layer}`);
      }
    });

    const options = [
      `formatPng=${remoteSensingLayersOptions.formatPng}`,
      `formatGeotiff=${remoteSensingLayersOptions.formatGeotiff}`,
      `rangeGrey=${remoteSensingLayersOptions.rangeGrey}`,
      `rangeFloat=${remoteSensingLayersOptions.rangeFloat}`,
    ];

    return layers.join(',') + '&' + options.join('&');
  };

  const setRemoteSensingImage = (image: TInfoExt) => {
    const date = getImageDate(image);
    dispatch(imageSensingSetCurrent(image));
    currentDates[date] && dispatch(setDate(date));
  };

  const setImageDate = (direction: string) => {
    const list = remoteSensingImages.filter((im: any) => !im.Hidden);
    const currentImageIndex = list.findIndex((img: any) => currentDate === getImageDate(img)) || 0;
    const currentImage =
      list.find((img: any) => currentDate === getImageDate(img)) || remoteSensingImages[0];
    const nextImage = list[currentImageIndex - 1] || currentImage;
    const prevImage = list[currentImageIndex + 1] || currentImage;

    if (direction === 'next') setRemoteSensingImage(nextImage);
    if (direction === 'prev') setRemoteSensingImage(prevImage);
  };
  const setNextDate = () => setImageDate('next');
  const setPrevDate = () => setImageDate('prev');

  const getImageDate = (image: any) => `${convertTileDateToNormal(image.Date, true)}-${image.Type}`;

  // const showMore = () => {
  //   setIsShowAll(true);
  // };
  //
  // const showLess = () => {
  //   setIsShowAll(false);
  // };

  const checkNmap = (date: any, type: string) => {
    const sameDate = currentDates[`${convertTileDateToNormal(date, true)}-${type}`];
    return sameDate ? sameDate.NMAP : false;
  };

  const filterDatesByType = (type: SourceType, toAdd: boolean) => {
    if (toAdd) {
      const emulateFinalFilter = [...remoteSensingFilterProps, type];
      if (sourcesMeta.filter(s => s.available).every(s => emulateFinalFilter.includes(s.source))) {
        dispatch(
          showNote({
            title: t({id: 'note.warning', defaultMessage: 'Warning'}),
            message: t({
              id: 'You need to have at least one imagery source selected.',
            }),
            level: 'warning',
          })
        );

        return;
      }
    }

    dispatch(setDatesFilterByType(type, toAdd));
  };

  const formatSourceLabel = (source: string) => {
    switch (source) {
      case 'dron':
        return t({id: 'DRONE'});
      case 'satellite_hd':
        return t({id: 'SATELLITE HR'});
      default:
        return t({id: source.replace('_', ' ').toUpperCase()});
    }
  };

  const openHistogram = (image: any) => {
    setRemoteSensingImage(image);
    openHistogramDialog();
  };

  const numberSelectedLayers = getNumberOfSelectedLayers();
  const {isNotImagery} = imageStatus;

  // const slicedImages = useMemo(
  //   () =>
  //     isShowAll || !remoteSensingImages.length
  //       ? remoteSensingImages
  //       : remoteSensingImages.slice(0, 5),
  //   [remoteSensingImages, isShowAll]
  // );

  return (
    <div className={'section-container remote-sensing-feature'}>
      <h3 className={'tab-title'}>{t({id: 'Data layers'})}</h3>

      {!isNotImagery ? (
        <div className={'toggle-compare'}>
          <SelectionControl
            id="toggle-compare"
            type="switch"
            label={t({id: 'Compare / Split view'})}
            name="toggle-compare"
            onChange={(val: boolean) => dispatch(toggleCompare(val))}
            checked={isCompareOn}
          />
          <Ln
            blank
            external
            href={'https://help.flurosense.com/en/articles/4484904-upload-your-data-layers'}
          >
            <FontIcon>help_outline</FontIcon>
          </Ln>
        </div>
      ) : null}

      {!wholeFarm.isWholeFarmView && !isCompareOn ? <UploadLayers isReadOnly={isReadOnly} /> : null}

      {!isCompareOn && !isNotImagery ? (
        <Slider
          id="remote-sensor-cloud-slider"
          className={'cloud-cover-slider'}
          label={t({id: 'Cloud Cover {count}%'}, {count: remoteSensingCloudCover})}
          defaultValue={100}
          max={100}
          min={0}
          discreteTicks={25}
          step={5}
          tickWidth={2}
          value={remoteSensingCloudCover}
          onChange={value => dispatch(remoteSensingSetCloudCoverFilter(value))}
          discrete
        />
      ) : null}

      {Object.keys(currentDates).length ? (
        <div className={'remote-dates-filter'}>
          {sourcesMeta.map((option: any) => {
            const disabled = !option.available;

            const active = !remoteSensingFilterProps.includes(option.source);
            const allSources = sourcesMeta.length === 5;

            return (
              <div
                id={`filter-item-${option.source}`}
                key={option.source}
                className={cn('filter-item', {active, disabled, allSources})}
                onClick={() => (disabled ? null : filterDatesByType(option.source, active))}
              >
                <TypeIcon type={option.source} />
                <div className={'filter-item__name'}>{formatSourceLabel(option.source)}</div>
              </div>
            );
          })}
        </div>
      ) : null}

      {isCompareOn && <Compare />}

      {isCompareOn ? null : remoteSensingImages.length ? (
        <React.Fragment>
          <FluroDataTable
            className="remote-sensing-list-table element-full-width"
            baseId="remote-sensing-list"
            elevated={false}
          >
            <FluroTableHeader>
              <FluroTableRow>
                <FluroTableColumn className="h-column">
                  <b>{t({id: 'Date'})}</b>
                  <b>{t({id: 'Cloud cover'})}</b>
                </FluroTableColumn>
                <FluroTableColumn children={null} />
              </FluroTableRow>
            </FluroTableHeader>

            <MultiKeysPressed callback={setNextDate} keys={['Shift', 'ArrowRight']} />
            <MultiKeysPressed callback={setPrevDate} keys={['Shift', 'ArrowLeft']} />
            <FluroTableBody>
              {remoteSensingImages.map((im: TInfoExt, i: number) => {
                const fieldsWithTreeData =
                  wholeFarm.isWholeFarmView && countFieldsWithTreeDataByDay(im);

                const appIcons = fieldsWithTreeData
                  ? [{name: 'tree_analysis', count: fieldsWithTreeData}]
                  : im.appName?.map(app => ({name: app, count: 0}));

                return (
                  <FluroTableRow
                    className={cn('remote-sensing-item', {
                      'md-table-row--hover': getImageDate(im) === currentDate,
                      hidden: im.Hidden,
                    })}
                    key={`remote-sensing-list-item-${i}`}
                  >
                    <FluroTableColumn>
                      {drawDateIcon({
                        type: im.Hidden ? 'eye-hidden' : im.Type,
                        date: convertTileDateToNormal(im.Date, true),
                        nmap: checkNmap(im.Date, im.Type),
                        events: {
                          iconEvent: () => dispatch(hideDateImage(im.Date, im.Hidden)),
                          labelEvent: im.Hidden ? null : () => setRemoteSensingImage(im),
                          iconLabel: im.Hidden ? t({id: 'Show imagery'}) : t({id: 'Hide imagery'}),
                          onEditCloudy: () =>
                            isProd() || getGetURLParam('testTeam')
                              ? dispatch(dialogToggle(DialogType.editCloudy, true, im))
                              : dispatch(
                                  showNote({
                                    title: t({id: 'note.warning', defaultMessage: 'Warning'}),
                                    message: t({
                                      id: 'note.editCloudyNotAllow',
                                      defaultMessage:
                                        'You can only edit cloud cover % on production.',
                                    }),
                                    level: 'warning',
                                  })
                                ),
                        },
                        appIcons: appIcons,
                        cloudy: im.Cloud,
                        cloudV2: im.CloudV2,
                        isAdmin,
                        isCloudValueModified: im.cloudValueModified,
                      })}
                    </FluroTableColumn>

                    <FluroTableColumn>
                      <div className="dl__item-controls">
                        {!wholeFarm.isWholeFarmView && (
                          <>
                            <div
                              title="Download Geotiff"
                              className="dl__item-control dl__geo-tiff-btn"
                            >
                              <ReadOnly>
                                <DownloadIcon
                                  onClick={!im.Hidden ? openTile.bind(this, im) : null}
                                  className={cn({
                                    'dl__btn-disabled': isReadOnly && !isOnBoardingNow,
                                  })}
                                />
                              </ReadOnly>
                            </div>

                            <div
                              title="Histogram"
                              onClick={() => openHistogram(im)}
                              className="dl__item-control dl__histogram-btn"
                            >
                              <HistogramIcon viewBox="0 0 50 50" />
                            </div>
                          </>
                        )}
                      </div>
                    </FluroTableColumn>
                  </FluroTableRow>
                );
              })}
            </FluroTableBody>
          </FluroDataTable>
        </React.Fragment>
      ) : null}

      {/*{!isCompareOn && remoteSensingImages.length && remoteSensingImages.length > 5 ? (*/}
      {/*  <Button*/}
      {/*    raised*/}
      {/*    onClick={isShowAll ? showLess : showMore}*/}
      {/*    className="element-full-width show-more"*/}
      {/*  >*/}
      {/*    {isShowAll ? 'Show less' : 'Show more'}*/}
      {/*  </Button>*/}
      {/*) : null}*/}

      {!isCompareOn && (
        <RemoteSensingImageDialog
          tile={currentRemoteSensingImage}
          isRemoteSensingDialog={isRemoteSensingDialog}
          isSetSpectra={currentIsSetSpectra}
          remoteSensingLayersSelected={remoteSensingLayersSelected}
          remoteSensingLayersOptions={remoteSensingLayersOptions}
          onHide={onHideDialog}
          onChangeLayer={onChangeLayer}
          onChangeFileFormat={onChangeFileFormat}
          numberSelectedLayers={numberSelectedLayers}
          downloadGeoTiff={downloadGeotiff}
        />
      )}
    </div>
  );
};

export default RemoteSensingList;
