import {t, FormattedMessage} from 'i18n-utils';
import * as React from 'react';
import {toFixedFloat} from '_utils';
import {CSGViewModel, CSGAvgViewModel} from './types';
import {PanelViewAverageCard} from './panel-view-card';
import {calcAverage} from './helpers/average';

type Props = {
  records: CSGViewModel[];
};
export const PanelViewCardAverage = ({records}: Props) => {
  const avg = calcAverage(records);
  return <PanelViewAverageCard record={avg} />;
};
