import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {elementContainsTarget} from '_utils';
import './dropdown.scss';

type Props = {button: React.ReactNode; anchor?: Anchor; multiselect?: boolean; className?: string};
type Anchor = 'right';

/**
 * Dropdown component that doesn't close itself when we select something (as opposed to react-md's one).
 */
export const Dropdown: React.ComponentType<Props> = ({
  button,
  children,
  anchor,
  multiselect = true,
  className = '',
}) => {
  const dropdownRef = useRef();
  const [expanded, setExpanded] = useState(false);

  // Close on click outisde the dropdown.
  useEffect(() => {
    if (!expanded) return;
    const onMouseDown = (e: MouseEvent) => {
      if (expanded) {
        if (!multiselect || !elementContainsTarget(dropdownRef.current, e.target as HTMLElement)) {
          // let the onItemClick fires first, then close the dropdown
          setTimeout(() => setExpanded(false), 100);
        }
      }
    };
    const closeOnEscPress = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setExpanded(false);
      }
    };
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('keydown', closeOnEscPress);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, [expanded]);

  return (
    <div ref={dropdownRef} className={`fluro-dropdown ${className}`}>
      <div className="button" onClick={() => setExpanded(expanded => !expanded)}>
        {button}
      </div>
      {expanded && (
        <div className="content" style={{right: anchor === 'right' ? 0 : 'auto'}}>
          {children}
        </div>
      )}
    </div>
  );
};
