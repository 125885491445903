import {t, FormattedMessage} from 'i18n-utils';
import React, {useEffect, useMemo, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {SelectionControl, FontIcon, Button} from 'react-md';
import cn from 'classnames';
import {convertUnit, isAgxField} from '_utils';
import {showNote} from '_actions';
import {Farm, Field} from 'containers/map/types';
import {toggleFieldCheckbox} from 'containers/map/actions';
import {SeasonList} from './season-list';
import {Ln} from 'components';
import {RegionIcon} from '../farm.styled';
import {useAppSelector} from '_hooks';

type Props = {
  field: Field;
  farm: Farm;
  highlighted: boolean;
  showOnlySelectedCrops: boolean;
  toggleEditFieldPopup: (value: boolean, fieldId?: number) => void;
  toggleEditSeasonPopup: (fieldId: number, seasonId?: number) => void;
  currentFarmRegions: string[];
};

export const FieldItem = ({
  field,
  farm,
  highlighted,
  showOnlySelectedCrops,
  toggleEditFieldPopup,
  toggleEditSeasonPopup,
  currentFarmRegions,
}: Props) => {
  const dispatch = useDispatch();
  const isWholeFarmView = useAppSelector(s => s.map.wholeFarm.isWholeFarmView);
  const currentFarmId = useAppSelector(s => s.map.group.id);
  const currentDate = useAppSelector(s => s.map.currentDate);
  const currentSensor = useAppSelector(s => s.map.currentSensor);
  const isReadOnly = useAppSelector(s => s.map.group.readOnly);
  const measurement = useAppSelector(s => s.login.user.settings.measurement);
  const farmGrower = useAppSelector(s => s.map.group.growerName);

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (highlighted && ref.current) {
      ref.current.scrollIntoView({block: 'center', behavior: 'smooth'});
    }
  }, [highlighted]);

  const _isAgxField = isAgxField(field);
  const isMissDates =
    !field.SeasonID || (field.SeasonID && !(field.PlantingDate || field.HarvestDate));
  const hasSeasons = field.Seasons.length !== 0;

  const displayFieldRegion = field.CountryRegion && currentFarmRegions.length > 1;
  const displayFieldGrower = field.Grower && farmGrower !== field.Grower;

  return (
    <div
      className={cn('farm-feature__field-item', {
        highlighted: isWholeFarmView && highlighted,
      })}
      key={field.ID}
      // todo: scrollTo
      ref={ref}
    >
      <div className={'field-item__container'}>
        <div
          onClick={() =>
            isWholeFarmView && dispatch(toggleFieldCheckbox(field.ID, !field._selected))
          }
          className={`field-item__info`}
        >
          <div className="field-item__field-info">
            {isWholeFarmView && (
              <SelectionControl
                label=""
                id={`select-field-${field.ID}`}
                type="checkbox"
                name="select-field"
                className={`field-item__checkbox`}
                inline
                onChange={val => dispatch(toggleFieldCheckbox(field.ID, Boolean(val)))}
                onClick={ev => ev.stopPropagation()}
                checked={field._selected}
              />
            )}

            <div className="field-item__title">
              <span
                title={field.Name}
                className={cn('field-item__name', {'whole-farm': isWholeFarmView})}
              >
                {field.Name}
                {field.Pivot ? (
                  <span title={t({id: 'Pivot field'})} className={'field-name-pivot-icon'}>
                    &#x25D4;
                  </span>
                ) : (
                  ''
                )}
              </span>
              <span className="field-item__area">
                ({convertUnit(measurement, 'ac', field.Area)} {t({id: measurement})})
              </span>
            </div>

            <div onClick={ev => ev.stopPropagation()} className={`field-item__info-controls`}>
              {!isReadOnly && isMissDates ? (
                <FontIcon
                  onClick={() =>
                    dispatch(
                      showNote({
                        title: t({id: 'note.info', defaultMessage: 'Info'}),
                        message: t({
                          id: 'Set up your sowing and harvest dates to start the analysis.',
                        }),
                        level: 'info',
                      })
                    )
                  }
                  className="field-item__error"
                >
                  error_outline
                </FontIcon>
              ) : null}

              {!isReadOnly && (
                <Button
                  icon
                  className="field-item__edit"
                  onClick={() => toggleEditFieldPopup(true, field.ID)}
                >
                  edit
                </Button>
              )}
            </div>
          </div>

          {displayFieldRegion ? (
            <span className="field-item__meta region">
              <RegionIcon />
              <span className={'value'}>{field.CountryRegion}</span>
            </span>
          ) : null}
          {displayFieldGrower ? (
            <span className="field-item__meta grower">
              <FormattedMessage
                id="Grower name"
                defaultMessage="Grower: {growerName}"
                values={{
                  growerName: <b className={'value'}>{displayFieldGrower}</b>,
                }}
              />
            </span>
          ) : null}
        </div>

        <SeasonList
          isReadOnly={isReadOnly}
          seasons={field.Seasons}
          externalService={field.external_service}
          selectedSeasonId={field.SeasonID}
          showOnlySelectedCrops={showOnlySelectedCrops}
          toggleEditSeasonPopup={(sID: number) => toggleEditSeasonPopup(field.ID, sID)}
        />
      </div>
      <div className="field-item__controls">
        {isWholeFarmView && (
          <Ln
            className={'field-item__go-to-field-link'}
            map
            blank
            href={`${currentFarmId}/${field.ID}?layerDate=${currentDate}&layer=${currentSensor}`}
          >
            <Button raised className="field-item__go-to-field-btn">
              {t({id: 'Go to field'})}
            </Button>{' '}
          </Ln>
        )}
        {isReadOnly ? null : (
          <Button
            primary={!(hasSeasons || _isAgxField)}
            raised
            onClick={() => toggleEditSeasonPopup(field.ID)}
            className="field-item__add-crop"
          >
            {t({id: 'Add a crop'})}
          </Button>
        )}
      </div>
    </div>
  );
};
