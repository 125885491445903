import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DialogType, toggleDialog} from '_reducers/dialog';
import ProcessingDataDialog from './processing-data';
import NotAllowedGeometriesToExportToAgx from './anomalies-to-agx';
import AddNewAnomaly from './add-new-anomaly';
import ImportSeasons from './import-seasons';
import {AddFieldDialog} from './add-field';
import IntegrationsPlatformsPopUp from './integration-platforms-pop-up';
import CarbonSuccessfulSignedPopUp from './carbon-successfuly-signed';

import {AppStore} from '../../reducers';

const Dialogs = () => {
  const currentDialog = useSelector((state: AppStore) => state.dialog.currentDialog);
  const dispatch = useDispatch();
  const hideDialog = (dialogType: DialogType) => dispatch(toggleDialog(dialogType, false));
  return (
    <>
      {currentDialog === DialogType.Processing && (
        <ProcessingDataDialog onHide={() => hideDialog(DialogType.Processing)} />
      )}

      {currentDialog === DialogType.GeometriesNotAllowedToExportToAgx && (
        <NotAllowedGeometriesToExportToAgx
          onHide={() => hideDialog(DialogType.GeometriesNotAllowedToExportToAgx)}
        />
      )}
      {currentDialog === DialogType.AddNewAnomaly && (
        <AddNewAnomaly onHide={() => hideDialog(DialogType.AddNewAnomaly)} />
      )}
      {currentDialog === DialogType.ImportSeasons && (
        <ImportSeasons onHide={() => hideDialog(DialogType.ImportSeasons)} />
      )}
      {currentDialog === DialogType.AddNewField && <AddFieldDialog />}

      {currentDialog === DialogType.IntegrationPlatforms && <IntegrationsPlatformsPopUp />}

      {currentDialog === DialogType.CarbonSuccessfulSigned && (
        <CarbonSuccessfulSignedPopUp onHide={() => hideDialog(DialogType.CarbonSuccessfulSigned)} />
      )}
    </>
  );
};

export default Dialogs;
